import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import {
    certificateNumberEdit,
    getSchools,
} from "../../../../utils/fetchRequest/Urls";
import Forms from "../../../modules/Form/Forms";
import message from "../../../modules/message";

const EditModal = ({ onClose, certificateId, schoolId, onFinish }) => {
    const { t } = useTranslation();
    const form = useRef();
    const dispatch = useDispatch();
    const languages = useSelector(
        (state) => state?.languages?.languages || []
    ).filter((lang) => lang.code !== "mn");

    const [schools, setSchools] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [data, setData] = useState(null);
    const [selectedSchoolId, setSelectedSchoolId] = useState(null);
    const [selectedTemplateId, setSelectedTemplateId] = useState(null);

    const [fieldChanged, setFieldChanged] = useState(false)

    useEffect(() => {
        getData();
    }, []);

    const onSchoolChange = (id, option, template = null) => {
        if (id) {
            setSelectedSchoolId(id);
            setSelectedTemplateId(template)
            setTemplates(() => {
                const tempData = []
                option.templates.map(obj => {
                    tempData.push({
                        value: obj.id,
                        text: obj.templateName
                    })
                })
                return tempData
            })
            setFieldChanged(prev => !prev)
        }
    };

    const getData = () => {
        const [formValid, formValue] = form.current.validate();
        if (formValid) {
            const params = {
                school: schoolId,
                certificateConfig: certificateId,
            };
            dispatch(setLoading(true));
            fetchRequest(certificateNumberEdit, "GET", params)
                .then((res) => {
                    if (res.success) {
                        const { data: {
                            certificateConfigData = {},
                            schools = []
                        } } = res;
                        setData(certificateConfigData);
                        const options = [];
                        schools.map((obj) => {
                            if (obj.id == certificateConfigData.school) {
                                onSchoolChange(obj.id, {
                                    value: obj.id,
                                    text: obj.longName,
                                    templates: obj.templates
                                }, certificateConfigData.template)
                            }
                            options.push({
                                value: obj.id,
                                text: obj.longName,
                                templates: obj.templates
                            });
                        });
                        setSchools(options);
                        setFieldChanged(prev => !prev)
                    } else {
                        message(res?.data?.message || t("errorMessage.title"));
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t("errorMessage.title"));
                });
        }
    };

    const fields = [
        {
            label: t("menu.school"),
            value: selectedSchoolId,
            type: "dropdown",
            inputWidth: 300,
            labelWidth: 230,
            required: true,
            key: "school",
            options: schools,
            onChange: onSchoolChange,
        },
        {
            label: t("enroll.certificateTemplate"),
            value: selectedTemplateId,
            type: "dropdown",
            inputWidth: 300,
            labelWidth: 230,
            required: true,
            key: "template",
            options: templates,
        },
        {
            label: t("enroll.certificateNo"),
            value: data != null ? data.start : "",
            type: "text",
            inputWidth: 300,
            labelWidth: 230,
            required: true,
            key: "start",
        },
        {
            label: t("enroll.issueNumber"),
            value: "",
            value: data != null ? data.issueNumber : "",
            type: "number",
            inputWidth: 300,
            labelWidth: 230,
            required: true,
            required: false,
            key: "issueNumber",
        },
    ];

    const onSaveClick = () => {
        const [formValid, formValue] = form.current.validate();
        if (formValid) {
            const params = {
                certificateConfig: certificateId,
                school: formValue?.[0].value,
                template: formValue?.[1].value,
                start: formValue?.[2].value,
                issueNumber: formValue?.[3].value,
            };

            dispatch(setLoading(true));
            fetchRequest(certificateNumberEdit, "POST", params, false)
                .then((response) => {
                    if (response.success) {
                        message(response.data.message, true);

                        onClose();
                        onFinish();
                    } else {
                        message(response.data.message, false);
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                });
        } else {
            console.log("form invalid");
        }
    };

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: "#4a70ae", fontSize: "1.1rem" }}>
                        {t("enroll.editCeritificateNumber").toUpperCase()}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Forms ref={form} fields={fields} fieldChanged={fieldChanged} />
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className="btn btn-link bolder">
                    {t("common.back")}
                </button>
                <Button variant="success btn-shadow" onClick={onSaveClick}>
                    {t("common.save")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditModal;
