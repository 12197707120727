import { cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";
import { Card, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../../../../redux/action";
import { fetchRequest } from "../../../../../../utils/fetchRequest";
import { getProgramDetail, getProgramSegmentSave, getDtlSegments, getProgramSegmentDelete, getProgramSegmentEdit } from "../../../../../../utils/fetchRequest/Urls";
import DTable from "app/modules/DataTable/DTable";
import Select from "app/modules/Form/Select";
import message from "app/modules/message";
import DeleteModal from "app/modules/DeleteModal";
import AddModal from "./modals/addModal";
import EditModal from "./modals/editModal";

const CriteriaDtl = ({enrollId}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [list, setList] = useState([]);
    const [oldList, setOldList] = useState([]);
    const [enrollDtls, setEnrollDtls] = useState([]);
    const [segments, setSegments] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [index, setIndex] = useState(null);
    const [enrollDtlId, setEnrollDtlId] = useState(null);
    const [selectedEnrollDtl, setSelectedEnrollDtl] = useState(null);
    const [deleteId, setDeleteId] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);

    useEffect(() => {
        getConfig();
    }, []);

    const getConfig = () => {
        dispatch(setLoading(true));
        const params = {
            enroll: enrollId,
        };

        fetchRequest(getProgramDetail, "GET", params)
            .then((res) => {
                if (res.success) {
                    const { data } = res;
                    setEnrollDtls(data?.programs || [])
                } else {
                    message(res?.data?.message || t("errorMessage.title"));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
            });
    };

    const columns = [
        {
            dataField: "segmentName",
            text: "Аймаг",
            sort: false,
        },
        {
            dataField: "totalNumber",
            text: "Хяналтын нийт тоо",
            sort: false,
            style: { textAlign: "right", padding: 3 },
            formatter: (cell, row, rowIndex) => {
                if(row.isEdit){
                    return (
                        <input
                            autoFocus={index == rowIndex ? true : false}
                            value={row.totalNumber}
                            type="text"
                            style={{width: '100%', borderRadius: 3, border: '1px solid #ebedf2', height: 'fit-content', padding: 11}}
                            onChange={(e) => {handleInputChange(e, 'totalNumber', row, rowIndex)}}
                        />
                    )
                } else {
                    return cell
                }
            }
        },
        // {
        //     dataField: "mNumber",
        //     text: "Эрэгтэй",
        //     sort: false,
        //     style: { textAlign: "right", padding: 3 },
        //     formatter: (cell, row, rowIndex) => {
        //         if(row.isEdit){
        //             return (
        //                 <input
        //                     autoFocus={index == rowIndex ? true : false}
        //                     value={row.mNumber}
        //                     type="text"
        //                     style={{width: '100%', borderRadius: 3, border: '1px solid #ebedf2', height: 'fit-content', padding: 11}}
        //                     onChange={(e) => {handleInputChange(e, 'mNumber', row, rowIndex)}}
        //                 />
        //             )
        //         } else {
        //             return cell
        //         }
        //     }
        // },
        // {
        //     dataField: "fNumber",
        //     text: "Эмэгтэй",
        //     sort: false,
        //     style: { textAlign: "right", padding: 3 },
        //     formatter: (cell, row, rowIndex) => {
        //         if(row.isEdit){
        //             return (
        //                 <input
        //                     autoFocus={index == rowIndex ? true : false}
        //                     value={row.fNumber}
        //                     type="text"
        //                     style={{width: '100%', borderRadius: 3, border: '1px solid #ebedf2', height: 'fit-content', padding: 11}}
        //                     onChange={(e) => {handleInputChange(e, 'fNumber', row, rowIndex)}}
        //                 />
        //             )
        //         } else {
        //             return cell
        //         }
        //     }
        // },
        {
            dataField: "icons",
            text: "",
            sort: false,
            headerStyle: () => {
                return {
                    width: 90
                }
            },
            style: { textAlign: "center", padding: 3 },
            formatter: (cell, row) => {
                return (
                    <div>
                        <Button onClick={() => {handleEdit(cell, row)}} className="btn btn-purple m-btn m-btn--icon m-btn--icon-only m-btn--circle-30 mr-1">
                            <i className="flaticon-edit"> </i>
                        </Button>
                        <Button onClick={() => {handleDelete(cell, row)}} className="btn btn-danger m-btn m-btn--icon m-btn--icon-only m-btn--circle-30">
                            <i className="flaticon2-cross"> </i>
                        </Button>
                    </div>
                );
            },
        },
    ];

    const handleInputChange = (e, column, row, index) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            let cloneData = [...list];
            cloneData[index][column] = e.target.value;
            setList(cloneData);
            setIndex(index);
        }
    }

    const handleAddButton = () => { 
        dispatch(setLoading(true));
        const params = {
            enroll: enrollId,
            dtl: enrollDtlId
        };

        fetchRequest(getProgramSegmentSave, "GET", params)
            .then((res) => {
                if (res.success) {
                    const { data } = res;
                    setSegments(data?.segments || []);
                    setShowModal(true)
                } else {
                    message(res?.data?.message || t("errorMessage.title"));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
            });
    };

    const handleBackButton = () => {
        setIsEdit(false)
        let cloneData = cloneDeep(oldList);

        if(cloneData && cloneData.length > 0){
            for(let i = 0; i < cloneData.length; i++){
                cloneData[i].isEdit = false;
            }
        }

        setList(cloneData);
    }

    const onEnrollDtlChange = (value) => {
        let existing = enrollDtls.find(data => data.id == value)

        if(existing){
            setSelectedEnrollDtl(existing)
        }

        const params = {
            enroll: enrollId,
            dtl: value,
        };

        dispatch(setLoading(true));
        fetchRequest(getDtlSegments, "GET", params)
            .then((res) => {
                if (res.success) {
                    const { data } = res;
                    if (data?.list) {
                        setList(data.list);
                    }
                    setEnrollDtlId(value);
                } else {
                    message(res?.data?.message || t("errorMessage.title"));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t("errorMessage.title"));
            });
    };

    const handleEdit = (cell, row) => {
        setSelectedRow(row);
        setShowEditModal(true);
    };

    const handleDelete = (cell, row) => {
        setDeleteId(row.id);
        setDeleteModal(true);
    };

    const onModalClose = () => {
        setShowModal(false);
    };

    const onEditClose = () => {
        setShowEditModal(false);
    };

    const saveSegmentHandler = (params) => {
        dispatch(setLoading(true));
        fetchRequest(getProgramSegmentSave, "POST", params, false)
            .then((response) => {
                if (response.success) {
                    const { data } = response;
                    if (data?.list) {
                        setList(data.list);
                    }

                    message(response.data.message, true);
                    onModalClose();
                } else {
                    message(response.data.message, false);
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
            });
    }

    const editSegmentHandler = (params) => {
        dispatch(setLoading(true));
        fetchRequest(getProgramSegmentEdit, "POST", params, false)
            .then((response) => {
                if (response.success) {
                    const { data } = response;
                    if (data?.list) {
                        setList(data.list);
                    }

                    message(response.data.message, true);
                    onEditClose();
                } else {
                    message(response.data.message, false);
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
            });
    }

    const onDelete = () => {
        const params = {
            id: deleteId,
            dtl: selectedEnrollDtl.id,
        };

        dispatch(setLoading(true));
        fetchRequest(getProgramSegmentDelete, "POST", params, false)
            .then((response) => {
                if (response.success) {
                    const { data } = response;
                    if (data?.list) {
                        setList(data.list);
                    }

                    setDeleteId(null);
                    setDeleteModal(false);
                } else {
                    message(response.data.message, false);
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t("errorMessage.title"));
            });
    };

    return (
        <Card>
            <div className="">
                <Card.Body>
                    <div style={{ display: 'flex', marginTop: '2rem' }}>
                        <label
                            style={{
                                display: 'flex',
                                flex: 1,
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                marginRight : 10,
                                marginBottom: 0,
                                width: 'auto',
                            }}
                        >
                            {'Мэргэжил'}
                        </label>
                        <div
                            style={{
                                display: 'flex',
                                flex: 1,
                                flexDirection: 'column',
                                marginLeft: 10,
                                width: 'auto',
                            }}
                        >
                            <Select
                                value={enrollDtlId}
                                searchable
                                onChange={onEnrollDtlChange}
                                options={enrollDtls}
                            />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flex: 0.8,
                                flexDirection: 'column',
                                marginLeft: 10,
                                width: 'auto',
                            }}
                        />
                    </div>
                    {
                        selectedEnrollDtl?.academicName ?
                        <div style={{ display: 'flex', marginTop: '1rem' }}>
                            <label
                                style={{
                                    display: 'flex',
                                    flex: 1,
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                    marginRight : 10,
                                    marginBottom: 0,
                                    width: 'auto',
                                }}
                            >
                                {'Хөтөлбөрийн нэр'}
                            </label>
                            <div
                                style={{
                                    display: 'flex',
                                    flex: 1,
                                    flexDirection: 'column',
                                    marginLeft: 10,
                                    width: 'auto',
                                }}
                            >
                                {selectedEnrollDtl.academicName}
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flex: 0.8,
                                    flexDirection: 'column',
                                    marginLeft: 10,
                                    width: 'auto',
                                }}
                            />
                        </div> : null
                    }
                    {
                        selectedEnrollDtl?.totalNumber && parseInt(selectedEnrollDtl?.totalNumber) > 0 ?
                        <div style={{ display: 'flex' }}>
                            <label
                                style={{
                                    display: 'flex',
                                    flex: 1,
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                    marginRight : 10,
                                    marginBottom: 0,
                                    width: 'auto',
                                }}
                            >
                                {'Хяналтын нийт тоо'}
                            </label>
                            <div
                                style={{
                                    display: 'flex',
                                    flex: 1,
                                    flexDirection: 'column',
                                    marginLeft: 10,
                                    width: 'auto',
                                }}
                            >
                                {selectedEnrollDtl.totalNumber}
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flex: 0.8,
                                    flexDirection: 'column',
                                    marginLeft: 10,
                                    width: 'auto',
                                }}
                            />
                        </div> : null
                    }
                    {
                        selectedEnrollDtl?.mNumber && parseInt(selectedEnrollDtl?.mNumber) > 0 ?
                        <div style={{ display: 'flex' }}>
                            <label
                                style={{
                                    display: 'flex',
                                    flex: 1,
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                    marginRight : 10,
                                    marginBottom: 0,
                                    width: 'auto',
                                }}
                            >
                                {'Эрэгтэй'}
                            </label>
                            <div
                                style={{
                                    display: 'flex',
                                    flex: 1,
                                    flexDirection: 'column',
                                    marginLeft: 10,
                                    width: 'auto',
                                }}
                            >
                                {selectedEnrollDtl.mNumber}
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flex: 0.8,
                                    flexDirection: 'column',
                                    marginLeft: 10,
                                    width: 'auto',
                                }}
                            />
                        </div> : null
                    }
                    {
                        selectedEnrollDtl?.fNumber && parseInt(selectedEnrollDtl?.fNumber) > 0 ?
                        <div style={{ display: 'flex' }}>
                            <label
                                style={{
                                    display: 'flex',
                                    flex: 1,
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                    marginRight : 10,
                                    marginBottom: 0,
                                    width: 'auto',
                                }}
                            >
                                {'Эмэгтэй'}
                            </label>
                            <div
                                style={{
                                    display: 'flex',
                                    flex: 1,
                                    flexDirection: 'column',
                                    marginLeft: 10,
                                    width: 'auto',
                                }}
                            >
                                {selectedEnrollDtl.fNumber}
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flex: 0.8,
                                    flexDirection: 'column',
                                    marginLeft: 10,
                                    width: 'auto',
                                }}
                            />
                        </div> : null
                    }
                    <DTable
                        config={{
                            excelExport: true,
                            showPagination: false,
                            showLeftButton: selectedEnrollDtl ? true : false,
                            leftButtonText: t("common.add"),
                            leftButtonClassName: "btn btn-success btn-shadow",
                        }}
                        data={list}
                        columns={columns}
                        onLeftButtonClick={handleAddButton}
                    />
                </Card.Body>
            </div>
            {showModal && 
                <AddModal 
                    onClose={onModalClose} 
                    enrollId={enrollId} 
                    segments={segments}
                    enrollDtl={selectedEnrollDtl}
                    onSave={saveSegmentHandler}
                />}
            {showEditModal &&
                <EditModal
                    enrollDtl={selectedEnrollDtl}
                    selectedRow={selectedRow}
                    onClose={onEditClose}
                    enrollId={enrollId}
                    segments={segments}
                    onEdit={editSegmentHandler}
                />
            }
            {deleteModal && deleteId &&
                <DeleteModal
                    onClose={onModalClose}
                    onDelete={onDelete}
                    title={"Устгах"}
                >
                    {t("warning.delete_confirmation")}
                    <br />
                    <br />
                    {t("warning.delete_confirmation_description")}
                </DeleteModal>
            }
        </Card>
    );
};

export default CriteriaDtl;
