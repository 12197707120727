import React, { useRef, useState, useEffect } from 'react';
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Forms from "../../../modules/Form/Forms";
import { getFlatTreeData } from "../../../../utils/utils";
import { useSelector, useDispatch } from "react-redux";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import { hrSchoolPositionsAdd } from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";

const PositionAddModal = ({
    onClose,
    departments,
    allPositions = [],
    depId,
    chosenPositions
}) => {
    const { t } = useTranslation();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const departmentList = getFlatTreeData(departments).map(dep => ({ value: dep.key, text: dep.title }));
    const [filteredPosition, setFilteredPosition] = useState([]);
    const [positions, setPositions] = useState(allPositions);

    useEffect(() => {
        if(chosenPositions && chosenPositions.length > 0 && positions && positions.length > 0)
        {
            for( let i = positions.length - 1; i >= 0; i--)
            {
                for( let j = 0; j < chosenPositions.length; j++)
                {
                    if(positions[i] && positions[i]['value'] === chosenPositions[j]['key'])
                    {
                        positions.splice(i, 1);
                    }
                }
            }
        }

        setFilteredPosition(positions);
    }, []);

    useEffect(() => {
        form?.current?.updateFields && form.current.updateFields(fields);
    }, [departments, positions, filteredPosition]);

    const form = useRef();
    const dispatch = useDispatch();

    const fields = [
        {
            label: t('department.title'),
            value: depId ? [depId] : [],
            // errorMessage: 'Error message',
            required: true,
            type: 'dropdown',
            options: departmentList,
            multiple: true,
            searchable: true,
            inputWidth: 350,
            clearable: true
        },
        {
            label: t('department.position'),
            value: [],
            // errorMessage: 'Error message',
            required: true,
            type: 'dropdown',
            options: filteredPosition,
            multiple: true,
            searchable: true,
            inputWidth: 350,
        },
    ];

    const onSaveClick = () => {
        const [ isValid, values ] = form.current.validate();
        if (isValid) {
            const params = {
                school: schoolId,
                department: depId,
                departments: JSON.stringify(values?.[0]?.value),
                positions: JSON.stringify(values?.[1]?.value),
            }
            // Api is not allowing multiple for now
            dispatch(setLoading(true));
            fetchRequest(hrSchoolPositionsAdd, 'POST', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res;
                        message(res?.data?.message || t('common.success'), true);
                        onClose?.(data?.positions || []);
                    } else {
                        message(res?.data?.message || t('errorMessage.title'));
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    message(t('errorMessage.title'));
                    dispatch(setLoading(false));
                })
        }
    }

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('department.addPosition')}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='row'>
                    <div className='col-9'>
                        <Forms
                            ref={form}
                            fields={fields}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className='btn btn-link bolder'>{t('common.back')}</button>
                <Button variant='success btn-shadow' onClick={onSaveClick}>{t('common.save')}</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default PositionAddModal;