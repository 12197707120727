import React, {useEffect, useState} from 'react'
import DTable from '../../../modules/DataTable/DTable'
import { useTranslation } from "react-i18next";
import DeleteModal from '../../../modules/DeleteModal';
import {AddModal} from "./AddModal";
import {studentAnketEducation, studentAnketFamily, studentAnketRewards} from "../../../../utils/fetchRequest/Urls";
import {setLoading} from "../../../../redux/action";
import {fetchRequest} from "../../../../utils/fetchRequest";
import message from "../../../modules/message";
import {useDispatch, useSelector} from "react-redux";

export const AwardInformation = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [rewards, setRewards] = useState([]);
    const [addModal, setAddModal] = useState(false);
    const [deleteModal,setDeleteModal] = useState(false);
    const [tempId,setTempId] = useState(null);
    const [types, setTypes] = useState([]);

    useEffect(() => {
        if(schoolId){
            dispatch(setLoading(true));
            fetchRequest(studentAnketRewards, 'GET', [])
                .then(res => {
                    if (res.success) {
                        const { data } = res;
                        setRewards(data?.rewards);
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'));
                })
        }
    }, []);

    const tableContextItem = [
        {
            key: 'edit',
            title: t('common.edit'),
            icon: <i className='las la-edit dt-cm-item-icon'/>
        },
        {
            key: 'delete',
            title: t('action.delete'),
            icon: <i className='las la-trash-alt dt-cm-item-icon'/>
        },
    ];

    const config ={
        showPagination: false,
        showFilter: false,
    };

    const columns = [
        {
            dataField: 'rewardName',
            text: t('questionnaire.reward'),
            sort: true,
        },
        {
            dataField: 'rewardOrganization',
            text: t('questionnaire.organization'),
            sort: true,
        },
        {
            dataField: 'year',
            text: t('questionnaire.organizationDate'),
            sort: true,
        },
    ];

    const fields = [
        {
            key: 'name',
            label: t('questionnaire.awardName') + '*',
            value: '',
            errorMessage: t('errorMessage.enterValue'),
            required: true,
            type: 'text',
        },
        {
            key: 'organization',
            label: t('questionnaire.organization') + '*',
            value: '',
            errorMessage: t('errorMessage.enterValue'),
            required: true,
            type: 'text',
        },
        {
            key: 'year',
            label: t('questionnaire.organizationDate') + '*',
            value: '',
            errorMessage: t('errorMessage.selectDate'),
            required: true,
            type: 'date',
            dateCustomButton: false,
        },
    ];

    const handleContextMenuClick = (id, key) => {
        if(id && key) {
            if(key === 'delete'){
                setDeleteModal(true);
                setTempId(id)
                
            }
            if(key === 'edit'){
                setTempId(id)
            }
        }
    };

    const onAddClose = () =>{
        setAddModal(false);
    };

    const onClose = () =>{
        setDeleteModal(false);
        setTempId(null)
    };

    const onDelete = () =>{
        if(tempId){
        }
    };

    const onSubmit = (params) => {
        dispatch(setLoading(true));
        fetchRequest(studentAnketRewards, 'POST', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setRewards(data.rewards);
                    onAddClose();
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            });
    };

    return (
        <div style={{border: '1px solid #4a70ae'}}>
            <div className='m-8'>
                <button className='btn btn-primary btn-shadow' onClick={()=>setAddModal(true)}>{t('common.add')}</button>

                <DTable
                    columns={columns}
                    config={config}
                    data={rewards}
                    remote
                    showOrdering={false}
                    contextMenus={tableContextItem}
                    onContextMenuItemClick={handleContextMenuClick}
                    selectMode={'radio'}
                />
            </div>
            <AddModal
                open={addModal}
                close={onAddClose}
                onSubmit={onSubmit}
                fields={fields}
                title={t('questionnaire.awardTitle')}
            />
            {
                deleteModal
                &&
                <DeleteModal 
                    onClose={onClose} 
                    onDelete={onDelete}
                    title={t('questionnaire.deleteAward')}
                >
                    {t('warning.delete_confirmation')}
                    <br/>
                    <br/>
                    {t('warning.delete_confirmation_description')}
                </DeleteModal>
            }
        </div>
    )
};
