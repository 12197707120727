import React, {useEffect, useState} from 'react'
import DTable from 'app/modules/DataTable/DTable'
import {Button, Card} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {setLoading} from "../../../../redux/action";
import {fetchRequest} from "../../../../utils/fetchRequest";
import {teacherTimeSheetInit} from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import Select from "../../../modules/Form/Select";
import ViewModal from "./viewModal";

export const TeacherTimeSheet = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [ teacherTimeSheetSeasonIndex ] = useState('teacher_time_sheet_season_index');

    const [list, setList] = useState([]);
    const [years, setYears] = useState([]);
    const [yearId, setYearId] = useState(localStorage.getItem(teacherTimeSheetSeasonIndex) || null);
    const [selectedSeasonId, setSelectedSeasonId] = useState(null);
    const [viewModal, setViewModal] = useState(false);

    useEffect(() => {
        const params = {
            school: schoolId,
            season: yearId
        };
        init(params)
    }, []);

    const init = (params) => {
        dispatch(setLoading(true));
        fetchRequest(teacherTimeSheetInit, 'GET', params)
            .then(res => {
                dispatch(setLoading(false));
                if (res.success) {
                    const { data } = res;

                    if(!yearId){
                        setYearId(data?.parentSeasonId || null);
                    }
                    
                    setYears(data?.parentSeasons || [])
                    setList(data?.list || [])
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const config = {
        showPagination: false,
        showFilter: false,
        tableMarginLess: true
    };

    const columns = [
        {
            dataField: 'seasonName',
            text: t('subject.season'),
            sort: false,
        },
        {
            dataField: 'day',
            text: t('common.day'),
            sort: false,
        },
        {
            dataField: 'time',
            text: t('common.time'),
            sort: false,
            headerStyle: () => {
                return {
                    width: 170
                }
            },
        },
        {
            dataField: 'studentCount',
            text: t('movement.class.studentCount'),
            sort: false,
        }
    ];

    const contextMenus = [
        {
            key: 'view',
            title: t('common.view'),
        }
    ];

    const handleContextMenuClick = (id, key) => {
        if (id && key) {
            if(key == 'view'){
                setViewModal(true)
                setSelectedSeasonId(id)
            }
        }
    };

    const onYearChange = (value) => {
        setYearId(value)

        localStorage.setItem(teacherTimeSheetSeasonIndex, value);

        const params = {
            school: schoolId,
            season: value
        };
        init(params)
    }

    return (
        <div className='sm-container'>
            <Card>
                <Card.Body>
                    <div className='row'>
                        <div className='col-12'>
                            <label className='mr-2'>{t('school.season.title')}</label>
                            <div style={{ width: 200, display: 'inline-flex'}}>
                                <Select
                                    className={'default-input-border w-100'}
                                    value={yearId}
                                    searchable
                                    onChange={value => onYearChange(value)}
                                    options={years}
                                />
                            </div>
                        </div>
                        <div className='col-12'>
                            <DTable
                                config={config}
                                data={list}
                                columns={columns}
                                selectMode={'radio'}
                                contextMenus={contextMenus}
                                onContextMenuItemClick={handleContextMenuClick}
                            />
                        </div>
                    </div>
                </Card.Body>
            </Card>
            {
                viewModal &&
                <ViewModal
                    seasonId={selectedSeasonId}
                    onClose={()=>{
                        setViewModal(false)
                        setSelectedSeasonId(null)
                    }}
                />
            }
        </div>
    )
};
