import React, {useRef, useEffect, useState} from 'react';
import {Button, Card} from "react-bootstrap";
import Forms from "../../../../modules/Form/Forms";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {teacherOnlineLessonContent} from "../../../../../utils/fetchRequest/Urls";
import { setLoading } from "../../../../../redux/action";
import { fetchRequest } from "../../../../../utils/fetchRequest";
import message from "../../../../modules/message";
import {Link, useHistory} from "react-router-dom";

const style = {
    cardBody: {
        position: 'relative',
        bottom: '4px',
        zIndex: 10
    }
}

export const Content = ({
    id,
}) => {
    const { t } = useTranslation();
    const formRef = useRef();
    const history = useHistory();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [courseData, setCourseData] = useState([]);

    useEffect(() => {
        const params = {
            school: schoolId,
            course: id
        };

        dispatch(setLoading(true));
        fetchRequest(teacherOnlineLessonContent, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setCourseData(data?.courseData || []);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }, []);

    useEffect(() => {
        formRef?.current?.updateFields && formRef.current?.updateFields(fields);
    }, [courseData]);

    const fields = [
        {
            key: 'title',
            label: t('common.shortDescription'),
            value: courseData.title || '',
            type: 'textArea',
            labelAlign: 'top',
            rows: 3
        },
        {
            key: 'description',
            label: t('questionnaire.mainTitle'),
            value: courseData.description || '',
            type: 'textArea',
            labelAlign: 'top',
            rows: 8
        },
    ];

    const handlerOnSaveClick = () => {
        const [ isValid, states, values ] = formRef.current.validate();
        if (isValid) {
            const params = {
                school: schoolId,
                course: id,
                ...values,
            };
    
            dispatch(setLoading(true));
            fetchRequest(teacherOnlineLessonContent, 'POST', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res;
                        setCourseData(data?.courseData || []);
                        message(res?.data?.message, true)
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        }
    };

    return (
        <div className='sm-container'>
            <Card style={style.cardBody}>
                <Card.Body>
                    <Forms
                        ref={formRef}
                        fields={fields}
                    />
                </Card.Body>
                <Card.Footer>
                    <div className='col-12 text-center'>
                        <Link
                            to='/teacher/online/lesson'
                            className="btn btn-link bolder"
                        >
                            {t('common.backTo_list')}
                        </Link>
                        <Button variant="success btn-shadow" onClick={handlerOnSaveClick}>{t('common.save')}</Button>
                    </div>
                </Card.Footer>
            </Card>
        </div>
    );
};