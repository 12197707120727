import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DTable from "../../../modules/DataTable/DTable";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import { healthList } from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";

const EnrollHealth = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchValues, setSearchValues] = useState(null);
  const [list, setList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const schoolId = useSelector((state) => state?.selectedSchool?.id || null);

  useEffect(() => {
    const params = {
      school: schoolId,
    };
    getList(params);
  }, []);

  const getList = (params) => {
    dispatch(setLoading(true));
    fetchRequest(healthList, "GET", params)
      .then((res) => {
        if (res.success) {
          const { data } = res;
          if (data?.list) {
            setList(data.list);
            setTotalCount(data.totalCount);
          }
        } else {
          message(res?.data?.message || t("errorMessage.title"));
        }
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        message(t("errorMessage.title"));
      });
  };

  const columns = [
    {
      dataField: "candidateId",
      text: "ID",
      sort: true,
    },
    {
      dataField: "registrationNumber",
      text: "Регистрийн дугаар",
      sort: true,
    },
    {
      dataField: "gender",
      text: "Хүйс",
      sort: true,
    },
    {
      dataField: "statusName",
      text: "Үзүүлэлт",
      sort: false,
    },
  ];
  const onUserInteraction = (object) => {
    let params = {
      ...object,
    };
    params.school = schoolId;
    setSearchValues(object);
    getList(params);
  };
  const handleClick = () => {};
  // const dataSource = [
  //   {
  //     id: "1",
  //   },
  // ];

  const handleButton = () => {
    //  excelExport: true,
  };

  return (
    <Card>
      <div className="w-75">
        <Card.Body>
          <button className="btn btn-primary btn-shadow" onClick={handleButton}>
            Татах
          </button>
          <button
            className="btn btn-success btn-shadow ml-4"
            onClick={handleClick}
          >
            {t("common.send")}
          </button>
          <DTable
            data={list}
            totalDataSize={totalCount}
            columns={columns}
            config={{
              excelExport: true,
              excelFileName: "Эрүүл мэнд",
              excelFileRemoteUrl: "candidate-health/excel",
              showPagination: true,
              showFilter: true,
              params: {
                school: schoolId,
                ...searchValues,
              },
            }}
            showOrdering={true}
            remote
            selectMode={"radio"}
            onInteraction={onUserInteraction}
          />
        </Card.Body>
      </div>
    </Card>
  );
};

export default EnrollHealth;
