import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import {
    certificateAdd,
    certificateEdit,
} from "../../../../utils/fetchRequest/Urls";
import Forms from "../../../modules/Form/Forms";
import message from "../../../modules/message";
import Editor from "../../../modules/textEditor";

const ViewModal = ({ onClose, schoolId, certificateId }) => {
    const { t } = useTranslation();
    const form = useRef();
    const dispatch = useDispatch();
    const languages = useSelector(
        (state) => state?.languages?.languages || []
    ).filter((lang) => lang.code !== "mn");
    const [richTextValue, setrichTextValue] = useState("");
    const [templateName, setTemplateName] = useState('');
    const [phases, setPhases] = useState([]);
    const [selectedPhaseId, setSelectedPhaseId] = useState(null);
    const [programs, setPrograms] = useState(null)
    const [selectedProgram, setSelectedProgram] = useState(null)
    const [enroll, setEnroll] = useState(null)
    const [selectedEnroll, setSelectedEnroll] = useState(null)
    const [fieldChanged, setFieldChanged] = useState(false)

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        const [formValid, formValue] = form.current.validate();
        if (formValid) {
            const params = {
                certificateTemplate: certificateId,
            };

            dispatch(setLoading(true));
            fetchRequest(certificateEdit, "GET", params)
                .then((res) => {
                    if (res.success) {
                        const { data: {
                            certificateData: {
                                textData = '',
                                phase = null,
                                program = null,
                                enroll = null,
                                templateName = ''
                            } = {},
                            phases = [],
                            programs = [],
                            enrolls = []
                        } } = res;
                        setrichTextValue(textData);
                        setSelectedPhaseId(phase);
                        setSelectedProgram(program);
                        setSelectedEnroll(enroll)
                        setTemplateName(templateName);
                        setPhases(() => {
                            const phasesOptions = [];
                            phases.map((phase) => {
                                phasesOptions.push({ value: phase.phaseId, text: phase.phaseName });
                            });
                            return phasesOptions
                        });
                        setPrograms(() => {
                            const tempData = [];
                            programs.map((obj) => {
                                tempData.push({ value: obj.programId, text: obj.programName });
                            });
                            return tempData
                        })

                        if (enrolls && enrolls.length > 0) {
                            setEnroll(() => {
                                const tempData = [];
                                enrolls.map((obj) => {
                                    tempData.push({
                                        value: obj.id,
                                        text: obj.name + ' ' + obj.educationLevel
                                    });
                                });
                                return tempData
                            })
                        } else {
                            setEnroll([])
                        }
                        
                        setFieldChanged(prev => !prev)
                    } else {
                        message(res?.data?.message || t("errorMessage.title"));
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t("errorMessage.title"));
                });
        }
    };

    const fields = [
        {
            label: t("enroll.enrollmentType"),
            value: selectedEnroll ?? null,
            type: "dropdown",
            upperCase: true,
            required: false,
            labelWidth: 250,
            inputWidth: 300,
            options: enroll,
            key: "enroll",
            editable: false,
            disabled: true,
        },
        {
            label: t("enroll.certificateType"),
            value: templateName,
            type: "text",
            upperCase: true,
            required: false,
            labelWidth: 250,
            inputWidth: 300,
            key: "templateName",
            disabled: true,
        },
        {
            label: t("enroll.phase"),
            value: selectedPhaseId ?? null,
            type: "dropdown",
            upperCase: true,
            required: false,
            labelWidth: 250,
            inputWidth: 300,
            options: phases,
            key: "phaseName",
            editable: false,
            disabled: true,
        },
        {
            label: t("curriculum.profession"),
            value: selectedProgram ?? null,
            type: "dropdown",
            upperCase: true,
            required: false,
            labelWidth: 250,
            inputWidth: 300,
            options: programs,
            key: "program",
            editable: false,
            disabled: true,
        },
        {
            type: "jsx",
            value: (
                <div key={"separator"} className="separator separator-dashed my-7" />
            ),
        },
    ];

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: "#4a70ae", fontSize: "1.1rem" }}>
                        {t("enroll.viewCertificateModel").toUpperCase()}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <Forms ref={form} fields={fields} fieldChanged={fieldChanged} />
                </div>
                <div className="w-75 mx-auto mt-6">
                    <Editor textValue={richTextValue} onTextChange={setrichTextValue} />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className="btn btn-link bolder">
                    {t("common.back")}
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default ViewModal;
