import React, { useState, useEffect, useRef } from 'react';
import { Card, Button, ButtonToolbar, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import Forms from "../../../../modules/Form/Forms";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { fetchRequest } from "../../../../../utils/fetchRequest";
import { setLoading } from "../../../../../redux/action";
import { useDispatch,useSelector } from "react-redux";
import { teacherOnlineLessonAnnouncementEdit } from "../../../../../utils/fetchRequest/Urls";
import message from "../../../../modules/message";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

export const TeacherAnnouncementEdit = ({
    location: { state: { newsId = null } = {} } = {},
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const [languages, setLanguages] = useState(useSelector(state => state?.languages?.languages || []).filter(lang => lang.code !== 'mn'));
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const formRef = useRef();
    const formPhotoRef = useRef();
    const formCoverPhotoRef = useRef();

    const [isSchool, setIsSchool] = useState(true);
    const [isLanguage, setIsLanguage] = useState(false);

    const [photos, setPhotos] = useState({});
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [subjectData, setSubjectData] = useState([]);
    const [newsData, setNewsData] = useState([]);
    const [coverPhoto, setCoverPhoto] = useState(null);
    const [newCoverPhoto, setNewCoverPhoto] = useState(null);
    const [isCoverPhotoDeleted, setIsCoverPhotoDeleted] = useState(false);
    const [isPhotoDelete, setIsPhotoDelete] = useState(false);

    useEffect(() => {
        if(languages && languages.length > 0){
            for(let i = 0; i < languages.length; i++){
                languages[i].title = '';
                languages[i].body = EditorState.createEmpty();
            }
        }
    }, []);

    useEffect(() => {
        const params = {
            school: schoolId,
            new: newsId
        };

        dispatch(setLoading(true));
        fetchRequest(teacherOnlineLessonAnnouncementEdit, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setSubjectData(data?.subjectData || []);
                    setNewsData(data?.newsData || []);
                    const blocksFromHtml = htmlToDraft(data?.newsData?.body);
                    const { contentBlocks, entityMap } = blocksFromHtml;
                    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                    const editorState = EditorState.createWithContent(contentState);
                    setEditorState(editorState);

                    if(data?.newsData?.isCoverPhoto){
                        setCoverPhoto({
                            fileName: data?.newsData?.fileName,
                            path: data?.newsData?.isCoverPhoto
                        })
                    }
                    if(data?.newsData?.files && data?.newsData?.files.length > 0){
                        setPhotos(data?.newsData?.files || []);
                    }
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }, []);

    const updateTextDescription = async (state) => {
        setEditorState(state);
    };

    const updateLangTextDescription = async (state, index) => {
        let cloneLanguage = [...languages];
        if(cloneLanguage && cloneLanguage.length > 0){
            cloneLanguage[index].body = state;
        }
        setLanguages(cloneLanguage)
    };

    useEffect(() => {
        formRef?.current?.updateFields && formRef.current?.updateFields(fields);
    }, [subjectData, newsData]);

    const fields = [
        {
            label: t('subject.title'),
            name: 'subject',
            value: subjectData[0]?.value || null,
            errorMessage: '',
            required: true,
            type: 'dropdown',
            options: subjectData,
            align: 'left',
            labelWidth: 120,
            inputWidth: 350
        },
        {
            label: t('common.title'),
            name: 'title',
            value: newsData?.title || '',
            errorMessage: '',
            required: true,
            type: 'text',
            labelWidth: 120,
            inputStyle: {maxWidth: 350}
        }
    ];

    const onCheckboxSchoolChange = (val) => {
        setIsSchool(val)
    };

    const onCheckboxLanguageChange = (val) => {
        if(!val){
            let cloneLanguage = [...languages];
            if(cloneLanguage && cloneLanguage.length > 0){
                for(let i = 0; i < cloneLanguage.length; i++){
                    cloneLanguage[i].title = '';
                    cloneLanguage[i].body = EditorState.createEmpty();
                }
            }
            setLanguages(cloneLanguage);
        }
        setIsLanguage(val)
    };

    const onFileChange = (file, evt, action) => {
        if(action == 'clear'){
            setNewCoverPhoto({})
        } else {
            setNewCoverPhoto(file[0])
        }
    };

    const onMultipleFileChange = (files, evt, action) => {
        if(action == 'clear'){
            setPhotos([]);
        } else {
            let fileArray = [];
            if(files && files.length){
                for(let i = 0; i < files.length; i++){
                    fileArray.push(files[i])
                }
            }
            setPhotos(fileArray)
        }
    };

    const fieldCoverPhoto = [
        {
            key: 'coverPhoto',
            label: t('common.coverPhoto'),
            value: "",
            type: 'fileUpload',
            isExtendedButton: true,
            isExtendedButtonText: t('newsfeed.chooseFile'),
            isExtendedButtonClass: 'btn btn-outline-success ml-2',
            accept: 'image/*',
            clearButton: true,
            labelWidth: 120,
            inputStyle: {maxWidth: 350},
            onChange: onFileChange
        }
    ];

    const fieldPhotos = [
        {
            key: 'otherPhoto',
            label: t('common.otherPhoto'),
            value: "",
            type: 'fileUpload',
            isExtendedButton: true,
            isExtendedButtonText: t('newsfeed.chooseFile'),
            isExtendedButtonClass: 'btn btn-outline-success ml-2',
            accept: 'image/*',
            multiple: true,
            clearButton: true,
            labelWidth: 120,
            inputStyle: {maxWidth: 350},
            onChange: onMultipleFileChange
        },
        {
            key: 'isLanguage',
            label: 'Бусад хэлээр мэдээ оруулах',
            value: false,
            type: 'checkbox',
            inputWidth: 300,
            labelWidth: 120,
            onChange: onCheckboxLanguageChange,
        },
    ];

    const onInputChange = (e, index) => {
        let cloneLanguage = [...languages];
        if(cloneLanguage && cloneLanguage.length > 0){
            cloneLanguage[index].title = e.target.value;
        }
        setLanguages(cloneLanguage)
    };

    const renderTranslation = () => {
        let translationHtml = [];
        for(let i = 0; i < languages.length; i++){
            translationHtml.push(
                <div key={'language_' + i}>
                    <div key={'translation_' + i} style={{ display: 'flex', marginTop: '0.8rem' }}>
                        <label
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginRight : 10,
                                marginBottom: 0,
                                width: 140,
                                justifyContent: 'flex-end',
                            }}
                        >
                            {t('common.title') + ` - ${languages[i].name} (${languages[i].code})`}
                        </label>
                        <div
                            style={{
                                display: 'flex',
                                flex: 1,
                                flexDirection: 'column',
                                marginLeft: 10,
                                maxWidth: 350,
                            }}
                        >
                            <input
                                type='text'
                                className='form-control'
                                value={languages[i].title}
                                onChange={(e) => {onInputChange(e, i)}}
                                style={{border: '1px solid #919bc0'}}
                            />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flex: 0.8,
                                flexDirection: 'column',
                                marginLeft: 10,
                                width:'auto',
                            }}
                        />
                    </div>
                    <p/>
                    <Editor
                        editorState={languages[i].body}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={(e) => updateLangTextDescription(e, i)}
                        editorStyle={{ border: "1px solid rgba(0, 0, 0, .2)"}}
                    />
                </div>
            )
        }
        return translationHtml;
    };

    const handleSaveClick = (isPublish) => {
        const [formsValid, formValues] = formRef.current.validate();
        if(editorState.getCurrentContent()){
            const bodyRaw = convertToRaw(editorState.getCurrentContent());
            const bodyHtml = draftToHtml(bodyRaw);
            if (formsValid && bodyRaw.blocks[0].text.trim().length !== 0) {
                let translations = [];
                if(languages && languages.length > 0){
                    for(let i = 0; i < languages.length; i++){
                        if(languages[i].body.getCurrentContent()){
                            let bodyTransRaw = convertToRaw(languages[i].body.getCurrentContent());
                            const bodyTransHtml = draftToHtml(bodyTransRaw);
                            if(bodyTransHtml){
                                translations.push({
                                    id: languages[i].id,
                                    langCode: languages[i].code,
                                    title: languages[i].title,
                                    body: bodyTransHtml,
                                })
                            }
                        }
                    }
                }

                const formData = new FormData();
                formData.append('new', newsId);
                formData.append('title', formValues?.[1]?.value);
                formData.append('body', bodyHtml);
                formData.append('publish', isPublish);
                formData.append('school', schoolId);

                formData.append('isCoverDeleted', isCoverPhotoDeleted ? 1 : 0);
                formData.append('isPhotoDelete', isPhotoDelete ? 1 : 0);
                formData.append('translations', JSON.stringify(translations));
                formData.append('cover', newCoverPhoto);
                if(photos && photos.length > 0){
                    for(let i = 0; i < photos.length; i++){
                        formData.append('photos[]', photos[i]);
                    }
                }

                dispatch(setLoading(true));
                fetchRequest(teacherOnlineLessonAnnouncementEdit, 'POST', formData, false, true)
                    .then(res => {
                        if (res.success) {
                            history.push('/teacher/announcement');
                        } else {
                            message(res?.data?.message);
                            dispatch(setLoading(false));
                        }
                    })
                    .catch(() => {
                        dispatch(setLoading(false));
                        message(t('errorMessage.title'))
                    })
            }
        }
    };

    const onFileUploadClearButtonHandler = (type) => {
        if(type == 'cover'){
            setCoverPhoto(null);
            setIsCoverPhotoDeleted(true);
        } else if (type == 'photo'){
            setPhotos([]);
            setIsPhotoDelete(true);
        }
    };

    return (
        <>
            <Card>
                <Card.Header>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <span className='french-blue fs-10'>
                            <b>{t('newsfeed.title').toUpperCase()} {t('common.add').toUpperCase()}</b>
                        </span>
                    </div>
                </Card.Header>
                <Card.Body>
                    <div className='row announcement-style'>
                        <div className='col-12'>
                            <div className='col-12'>
                                <Forms
                                    ref={formRef}
                                    fields={fields}
                                />
                            </div>
                            <p/>
                            <div className='col-12 newfeed-style'>
                                <Editor
                                    editorState={editorState}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    onEditorStateChange={updateTextDescription}
                                    editorStyle={{ border: "1px solid rgba(0, 0, 0, .2)"}}
                                />
                            </div>
                            <div className='col-12'>
                                {
                                    coverPhoto 
                                    ? 
                                        <div style={{display: 'flex', marginTop: '0.8rem'}}>
                                            <label
                                                style={{
                                                    display: 'flex',
                                                    flex: undefined,
                                                    justifyContent: 'flex-end',
                                                    alignItems: 'center',
                                                    marginRight : 10,
                                                    marginBottom: 0,
                                                    width: 120,
                                                }}
                                            >
                                                {t('common.coverPhoto')}
                                            </label>
                                            <div
                                                style={{
                                                    flexDirection: 'column',
                                                    marginLeft: 10,
                                                }}
                                            >
                                                {coverPhoto.fileName}
                                                <Button onClick={() => {onFileUploadClearButtonHandler('cover')}} className="btn btn-danger m-btn m-btn--icon m-btn--icon-only m-btn--circle-28 ml-2">
                                                    <i className="flaticon2-cross"> </i>
                                                </Button>
                                            </div>
                                        </div>
                                    :
                                        <Forms
                                            ref={formCoverPhotoRef}
                                            fields={fieldCoverPhoto}
                                        />
                                }
                                {
                                    photos && photos.length > 0 
                                    ?
                                        <div style={{display: 'flex', marginTop: '0.8rem'}}>
                                            <label
                                                style={{
                                                    display: 'flex',
                                                    flex: undefined,
                                                    justifyContent: 'flex-end',
                                                    alignItems: 'center',
                                                    marginRight : 10,
                                                    marginBottom: 0,
                                                    width: 120,
                                                }}
                                            >
                                                {t('common.coverPhoto')}
                                            </label>
                                            <div
                                                style={{
                                                    flexDirection: 'column',
                                                    marginLeft: 10,
                                                }}
                                            >
                                                {
                                                    photos.map((photo, index) => {
                                                        return(
                                                            <div key={'photo_' + index}>
                                                                {photo.fileName || photo.name}
                                                            </div>
                                                        )
                                                    })
                                                }
                                                <Button onClick={() => {onFileUploadClearButtonHandler('photo')}} className="btn btn-danger m-btn m-btn--icon m-btn--icon-only m-btn--circle-28 ml-2">
                                                    <i className="flaticon2-cross"> </i>
                                                </Button>
                                            </div>
                                        </div>
                                    : 
                                        <Forms
                                            ref={formPhotoRef}
                                            fields={fieldPhotos}
                                        />
                                }
                            </div>
                            <div className="separator separator-dashed my-7" />
                            <div className='col-12'>
                                {
                                    languages && languages.length > 0 && isLanguage
                                    ?
                                        renderTranslation()
                                    : null
                                }
                            </div>
                        </div>
                    </div>
                </Card.Body>
                <Card.Footer>
                    <div className='col-12 text-center'>
                        <Link
                            to='/teacher/announcement'
                            className="btn btn-link bolder"
                        >
                            {t('common.back')}
                        </Link>
                        <Button variant="success btn-shadow newsfeed-btn mr-2" onClick={() => handleSaveClick(0)}>{t('common.save')}</Button>
                        <Button variant="success btn-shadow " onClick={() => handleSaveClick(1)} >{t('action.post')}</Button>
                    </div>
                </Card.Footer>
            </Card>
        </>
    )
};

