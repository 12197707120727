import React, {useEffect, useState} from 'react'
import {Button, Card} from 'react-bootstrap'
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import DTable from "../../../../modules/DataTable/DTable";
import {setLoading} from "../../../../../redux/action";
import {fetchRequest} from "../../../../../utils/fetchRequest";
import {merchantRequests} from "../../../../../utils/fetchRequest/Urls";
import message from "../../../../modules/message";
import DatePickerRange from "../../../../modules/Form/DatePickerRange";

export const IncomeCard = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    const [payments, setPayments] = useState([]);
    const [bankInfo, setBankInfo] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    useEffect(() => {
        const params = {
            school: schoolId,
        };
        init(params)
    }, []);

    const init = (params) => {
        dispatch(setLoading(true));
        fetchRequest(merchantRequests, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    let payments = data.payments;

                    if(payments && payments.length){
                        for(let i = 0; i < payments.length; i++){
                            payments[i]['tranDate'] = payments[i]['tranDate'].date.substring(0, 10);
                            payments[i]['typeName'] = 'typeName';
                            payments[i]['studentCode'] = 'studentCode';
                            payments[i]['studentName'] = 'studentName';
                        }
                    }

                    setBankInfo(data.bankInfos);
                    setPayments(payments);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const handleButtonsClick = () => {
        const params = {
            school: schoolId,
            start: startDate,
            end: endDate,
            bank: bankInfo && bankInfo.length > 0 ? bankInfo[0].id : null
        };
        init(params);
    };

    const config = {
        showPagination: true,
        showFilter: true,
    };

    const columns = [
        {
            dataField: "tranDate",
            text: t('finance.transactionDate'),
            sort: true,
        },
        {
            dataField: "amount",
            text: t('finance.incomeAmount'),
            style: {textAlign: 'right'},
            sort: true,
        },
        {
            dataField: "transactionNumber",
            text: t('finance.transactionNumber'),
            sort: true,
        },
        {
            dataField: "typeName",
            text: t('common.type'),
            sort: true
        },
        {
            dataField: "studentCode",
            text: t('student.code'),
            sort: true,
            formatter(cell, row, index) {
                return (
                    <div className='msue-dt-clickable-cell' onClick={() => studentCodeClick(cell, row, index)}>
                        {cell}
                    </div>
                )
            },
        },
        {
            dataField: "studentName",
            text: t('student.name'),
            sort: true,
        },
    ];

    const handlerRangePicker = (dates) => {
        if(dates && dates.length > 0){
            setStartDate(dates[0].startDate ? dates[0].startDate : null);
            setEndDate(dates[0].endDate ? dates[0].endDate : null);
        }
    };

    const studentCodeClick = (cell, row, index) => {
        const url = `student/view?student=` + row['id'] + '&url=/finance/income/card';
        window.open(url);
    };

    return (
        <div className='sm-container'>
            <Card className='mb-4'>
                <Card.Body>
                    <label className="text-lg-right">
                        {t('finance.account') + ': '}
                        <input type="text" disabled className='account ml-2' value={bankInfo && bankInfo.length > 0 ? bankInfo[0].accountNumber : ''}/>
                        <span />
                    </label>
                    <label
                        style={{marginBottom: 0}}
                        className="text-lg-right ml-10">
                        <span className='mr-3'>{t('common.date')}</span>
                        <DatePickerRange
                            onChange={handlerRangePicker}
                            firstPlaceHolder={t('common.startDate')}
                            lastPlaceHolder={t('common.endDate')}
                        />
                    </label>
                    <Button
                        className={"btn btn-warning-custom active btn-shadow ml-3"}
                        onClick={() => handleButtonsClick()}
                    >
                        <b>{t('common.see').toUpperCase()}</b>
                    </Button>
                </Card.Body>
            </Card>
            <Card>
                <Card.Body>
                    <div className='row'>
                        <div className='col-12'>
                            <DTable
                                columns={columns}
                                config={config}
                                data={payments}
                                selectMode={'radio'}
                            />
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </div>
    )
};
