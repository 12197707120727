import React, {useState, useEffect, useRef} from 'react';
import {Card, Button, Modal} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {Link, useHistory, useLocation} from "react-router-dom";
import Forms from "../../../modules/Form/Forms";
import {setLoading} from "../../../../redux/action";
import {fetchRequest} from "../../../../utils/fetchRequest";
import {academicProgramAdd} from "../../../../utils/fetchRequest/Urls";
import qs from "qs";
import {useDispatch, useSelector} from "react-redux";
import i18n from "i18next";
import message from "../../../modules/message";
import { values } from 'lodash';

const AcademicNew = ({}) => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const selectedSchool = useSelector(state => state?.selectedSchool || []);
    const languages = useSelector(state => state?.languages?.languages || []);
    const [ helperModal, setHelperModal ] = useState(false);
    const [departments, setDepartments] = useState([]);
    const [selectedDepartmentId] = useState(location.state && location.state.id ? location.state.id : null);

    const form = useRef();
    const translateForm = useRef();

    useEffect(() => {
        dispatch(setLoading(true));
        let params = {
            school: selectedSchool.id
        };

        fetchRequest(academicProgramAdd + '?' + qs.stringify(params), 'GET')
            .then(response => {
                if (response.success) {
                    if(response.data.departments && response.data.departments.length){
                        setDepartments(response.data.departments);
                    }
                } else {
                    message(response?.data?.message, false);
                }
                dispatch(setLoading(false));
            });
    }, []);

    useEffect(() => {
        form?.current?.updateFields && form.current?.updateFields(forms);
    }, [departments]);

    const forms = [
        {
            key: 'department',
            label: t('school.parentDepartment') + '*',
            value: selectedDepartmentId ? selectedDepartmentId : null,
            errorMessage: t('errorMessage.selectRelatedDepartment'),
            required: true,
            type: 'dropdown',
            options: departments,
            multiple: false,
            clearable: true
        },
        {
            key: 'academicCode',
            label: t('curriculum.code') + '*',
            value: '',
            errorMessage: t('errorMessage.enterIndex'),
            required: true,
            type: 'nonCryllic',
            upperCase: true,
        },
        {
            key: 'name',
            label: t('curriculum.name') + '*',
            value: '',
            errorMessage: t('errorMessage.enterName'),
            required: true,
            type: 'text',
        },
    ];

    const handleHelperModal = () => {
        setHelperModal(true)
    };

    const handleHelperModalClose = () => {
        setHelperModal(false)
    };

    const getFormTranslations = () => {
        const array = [];
        for (const language of languages) {
            if(language.code !== 'mn'){
                array.push(
                    {
                        label: `${t('curriculum.name')} - ${language.name} (${language.code})`,
                        value: '',
                        required: false,
                        type: 'text',
                        langCode: language.code,
                        key: 'name',
                        inputStyle: language.code == 'tr' ? {fontFamily: 'Urga'} : {}
                    }
                );
            }
        }
        return array;
    };

    const handlerOnSaveClick = (status) => {
        const [ formValid, formValue, values ] = form.current.validate();
        const [ translateValid, translateValues ] = translateForm.current.validate();

        if (formValid) {
            const params = {
                school: selectedSchool.id,
                ...values,
                status: status
            };

            if (formValue?.[0]?.value) {
                params.department = formValue[0].value;
            }

            let translations = [];
            if(languages && languages.length > 0){
                languages.map(language => {
                    if(language.code !== 'mn'){
                        const name = translateValues.find(val => val.key === 'name' && val.langCode === language.code && val.value);

                        if (name) {
                            translations.push({
                                langCode: language.code,
                                name: name.value
                            })
                        }
                    }
                });

                params.translations = JSON.stringify(translations);
            }

            dispatch(setLoading(true));
            fetchRequest(academicProgramAdd, 'POST', params, false)
                .then(response => {
                    if (response.success) {
                        history.push('/school/academic')
                    } else {
                        message(response?.data?.message, false);
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                })
        }
    };

    return (
        <div className='row'>
            <div className='col-12'>
                <Card>
                    <Card.Body>
                        <div className='col-12 text-right'>
                            <Button className='bg-white border-white d-inline btn-hover-less' onClick={handleHelperModal}>
                                <i className="icon-2x text-danger flaticon-questions-circular-button d-inline"/>
                                <p className='text-danger d-inline'><b>{t('common.helper')}</b></p>
                            </Button>
                        </div>
                        <div className='col-12'>
                            <Forms
                                ref={form}
                                fields={forms}
                            />
                        </div>
                        <div className="separator separator-dashed my-7"/>
                        <div className='col-12'>
                            <Forms
                                ref={translateForm}
                                fields={getFormTranslations()}
                            />
                        </div>
                    </Card.Body>
                    <Card.Footer>
                        <div className='col-12 text-center'>
                            <Link
                                to='/school/academic'
                                className="btn btn-link bolder"
                            >
                                {t('common.back')}
                            </Link>
                            <Button variant="success btn-shadow" onClick={() => {handlerOnSaveClick(0)}}>{t('common.save')}</Button>
                            <Button variant="publish btn-shadow ml-3" onClick={() => {handlerOnSaveClick(1)}}>{t('action.publish')}</Button>
                        </div>
                    </Card.Footer>
                </Card>
            </div>
            <Modal
                show={helperModal}
                onHide={handleHelperModalClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Modal heading
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Centered Modal</h4>
                    <p>
                        Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
                        dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta
                        ac consectetur ac, vestibulum at eros.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleHelperModalClose}>{t('common.close')}</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
};

export default AcademicNew;