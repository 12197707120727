import React, { useEffect, useRef, useState } from 'react';
import { Card, Button, Row, Col, Nav, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Forms from 'app/modules/Form/Forms';
import { Link, useHistory, useParams } from 'react-router-dom';
import { fetchRequest } from 'utils/fetchRequest';
import { surveyQuestionReOrdering, surveyQuestionCreate, surveyCreate, surveyQuestionDelete, surveyInfoRoles, surveyEdit, surveyPublish } from 'utils/fetchRequest/Urls';
import Checkbox from 'app/modules/Form/Checkbox';
import showMessage from 'app/modules/message';
import { useSelector, useDispatch } from 'react-redux';
import { setLoading } from 'redux/action';
import { BorderColorTwoTone, DeleteTwoTone, AddCircleOutline } from '@mui/icons-material';
import EditQuestion from 'app/pages/officer/survey/components/questions/EditQuestion';
import NewQuestion from 'app/pages/officer/survey/components/questions/NewQuestion';
import LowPriorityRoundedIcon from '@mui/icons-material/LowPriorityRounded';
import DeleteModal from 'app/modules/DeleteModal';
import ChangeOrderModal from './ChangeOrderModal';

const EditSurveyContainer = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const formRef = useRef();
    const formQuestionnaireRef = useRef();

    const { selectedSchool } = useSelector(state => state || null);

    const [addAgain, setAddAgain] = useState(false);
    const [saved, setSave] = useState({});
    const [view, setView] = useState('list');
    const [surveyData, setSurveyData] = useState();
    const [categories, setCategories] = useState([]);
    const [showDelete, setShowDelete] = useState(false);
    const [selectedData, setSelectedData] = useState();
    const [showOrder, setShowOrder] = useState(false);
    const [questionTypes, setQuestionTypes] = useState([]);
    const [tabKey, setTabKey] = useState('questionnaire');
    const [surveyId] = useState(history?.location?.state?.survey || null);

    const fields = [
        {
            key: 'code',
            value: surveyData?.code || '',
            label: `${t('common.code')}*`,
            type: 'nonCryllic',
            upperCase: true,
            required: true,
            placeHolder: t('errorMessage.enterCode'),
            errorMessage: t('errorMessage.enterCode'),
            labelBold: true,
        },
        {
            key: 'name',
            value: surveyData?.name || '',
            label: `${t('survey.name')}*`,
            type: 'text',
            required: true,
            placeHolder: t('errorMessage.enterValue'),
            errorMessage: t('errorMessage.enterValue'),
            labelBold: true,
        },
        {
            key: 'category',
            value: surveyData?.categoryId || '',
            label: `${t('survey.category')}*`,
            type: 'dropdown',
            required: true,
            errorMessage: t('errorMessage.enterValue'),
            labelBold: true,
            options: categories,
            disabled: true
        },
        {
            key: 'date',
            label: `${t('survey.date')}*`,
            type: 'daterange',
            selectedStartDate: surveyData?.startDate || null,
            selectedEndDate: surveyData?.endDate || null,
            errorMessage: t('errorMessage.enterValue'),
            labelBold: true,
            hidden: surveyData?.categoryCode == 'SUBJECT_SURVEY' ? false : true,
        },
        {
            key: 'subject',
            value: surveyData?.subjectName || null,
            label: t('subject.title') + '*',
            type: 'text',
            errorMessage: t('errorMessage.enterValue'),
            labelBold: true,
            searchable: true,
            disabled: true,
            hidden: surveyData?.categoryCode == 'SUBJECT_SURVEY' ? false : true
        },
        {
            key: 'purpose',
            value: surveyData?.purpose || '',
            label: `${t('survey.goal')}`,
            type: 'textArea',
            required: false,
            labelBold: true,
        },
    ];

    const deleteItem = async (questionId) => {
        let params = {
            school: selectedSchool.id,
            survey: surveyId,
            question: questionId
        }

        dispatch(setLoading(true));
        fetchRequest(surveyQuestionDelete, 'POST', params)
            .then((res) => {
                if (res.success) {
                    const {data} = res;

                    surveyDataUpdate(data.questions)
                } else {
                    showMessage(res.data.message || t('errorMessage.title'));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                showMessage(t('errorMessage.title'));
            });
    };

    useEffect(() => {
        init()
    }, []);

    useEffect(() => {
        if (formRef.current) {
            const values = formRef.current?.getValues();
            formRef.current.updateFields(fields?.map((f) => ({ ...f, value: values[f.key] })));
        }
    }, [categories]);

    const init = () => {
        let params = {
            school: selectedSchool?.id,
            survey: surveyId,
        }

        dispatch(setLoading(true));
        fetchRequest(surveyEdit, 'GET', params)
            .then((res) => {
                if (res.success) {
                    const {data} = res;

                    setSurveyData(data?.survey || null)
                    setCategories(data?.categories || [])
                    setQuestionTypes(data?.questionTypes || [])
                } else {
                    showMessage(res.data.message || t('errorMessage.title'));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                showMessage(t('errorMessage.title'));
            });
    }

    const onSaveClick = () => {
        dispatch(setLoading(true));
        if (tabKey === 'general') {
            const [isValid,, values] = formRef.current.validate();

            const [{ startDate, endDate }] = values?.date || [];
            if (isValid) {
                const postData = {
                    school: selectedSchool?.id,
                    survey: surveyId,
                    code: values.code,
                    name: values?.name,
                    start: startDate,
                    end: endDate,
                    category: values?.category,
                    purpose: values?.purpose,
                };

                fetchRequest(surveyEdit, 'POST', postData)
                    .then((res) => {
                        if (res.success) {
                            const {data} = res;
                            setSurveyData(data.survey)
                            showMessage(res.data.message, res.success);
                            setTabKey('questionnaire');
                        } else {
                            showMessage(res.data.message || t('errorMessage.title'));
                        }
                        dispatch(setLoading(false));
                    })
                    .catch(() => {
                        dispatch(setLoading(false));
                        showMessage(t('errorMessage.title'));
                    });
            } else {
                dispatch(setLoading(false));
            }
        } else if (view === 'form-view' || view === 'form-new') {
            const [isValid, , values] = formQuestionnaireRef.current.validate();

            if (isValid) {
                let ordering = 1;
                if(selectedData){
                    ordering = selectedData.orderNumber
                } else {
                    ordering = surveyData && surveyData.questions.length > 0 ? surveyData.questions.length + 1 : '1'
                }

                const postData = {
                    school: selectedSchool?.id || null,
                    survey: surveyId,
                    questions: [
                        {
                            ...saved,
                            ...(selectedData ? { id: selectedData.id } : {}),
                            isRequired: values.isRequired ? 1 : 0,
                            isMultiAnswer: saved.isMultiAnswer ? 1 : 0,
                            question: values?.question,
                            orderNumber: ordering,
                            description: values?.description,
                            type: values?.type,
                        },
                    ],
                };

                fetchRequest(surveyQuestionCreate, 'post', postData)
                    .then((res) => {
                        if (res.success) {
                            const {data} = res

                            if (addAgain) {
                                setTabKey('questionnaire');
                                setSelectedData(null);
                            } else {
                                setView('list');
                                setTabKey('questionnaire');
                                setSelectedData(null);
                                setAddAgain(false);
                            }

                            surveyDataUpdate(data.questions)
                        } else {
                            showMessage(res.data.message || t('errorMessage.title'));
                        }
                        dispatch(setLoading(false));
                    })
                    .catch(() => {
                        dispatch(setLoading(false));
                        showMessage(t('errorMessage.title'));
                    });
            } else {
                dispatch(setLoading(false));
            }
        } else {
            dispatch(setLoading(false));
            history.push('/survey/list');
        }
    };

    const menu = [
        {
            key: 'order',
            title: t('survey.orderAction'),
            icon: <LowPriorityRoundedIcon className="color-info" />,
        },
        {
            key: 'edit',
            title: t('common.edit'),
            icon: <BorderColorTwoTone className="color-info" />,
        },
        {
            key: 'delete',
            title: t('common.delete'),
            icon: <DeleteTwoTone className="color-info" />,
        },
    ];

    const actionToPublish = () => {
        dispatch(setLoading(true));
        fetchRequest(surveyPublish, 'POST', {
            school: selectedSchool.id,
            survey: surveyId,
            status: tabKey,
        })
            .then((res) => {
                const { message = null, success = false } = res;
                if (success) {
                    history.replace('/survey/list');
                    showMessage(message, success);
                } else {
                    showMessage(message || t('errorMessage.title'));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                showMessage(t('errorMessage.title'));
            });
    };

    const actionToOrder = (postData) => {
        postData.school = selectedSchool.id;
        postData.survey = surveyId;
        
        dispatch(setLoading(true));
        fetchRequest(surveyQuestionReOrdering, 'POST', postData)
            .then((res) => {
                if (res.success) {
                    const {data} = res;

                    surveyDataUpdate(data.questions)
                    setShowOrder(false);
                    showMessage(res.data.message, res.success);
                } else {
                    showMessage(res.data.message || t('errorMessage.title'));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                showMessage(t('errorMessage.title'));
            });
    };

    const surveyDataUpdate = (questions) => {
        let cloneSurveyData = {...surveyData}

        if(cloneSurveyData){
            cloneSurveyData.questions = questions
        }

        setSurveyData(cloneSurveyData)
    }

    return (
        <>
            <Card>
                <Card.Header>
                    <div className='row'>
                        <div className='col-12'>
                            <Nav
                                variant="tabs"
                                activeKey={tabKey}
                                onSelect={(key, e) => setTabKey(key, e)}
                            >
                                <Nav.Item key={'type_' + 1}>
                                    <Nav.Link eventKey="general">{t('survey.general')}</Nav.Link>
                                </Nav.Item>
                                <Nav.Item key={'type_' + 2}>
                                    <Nav.Link eventKey="questionnaire">{t('survey.questionnaire')}</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                    </div>
                </Card.Header>
                <Card.Body className="px-8">
                    {
                        tabKey === 'general' &&
                        <Forms
                            ref={formRef}
                            fields={fields}
                        />
                    }
                    {
                        tabKey === 'questionnaire' && (
                        <>
                            {view === 'list' ? (
                                <>
                                    <Button
                                        onClick={() => {
                                            setSelectedData(null);
                                            setView('form-new');
                                        }}
                                        type="button"
                                        variant="primary"
                                        size="sm"
                                        className="br-8"
                                    >
                                        <span className="m-0 font-weight-bold d-flex align-items-center">
                                            <AddCircleOutline className="w-19" />
                                            &nbsp;{t('survey.questionnaire')}&nbsp;
                                            {t('common.create')}
                                        </span>
                                    </Button>
                                    {
                                        surveyData && surveyData.questions.length > 0 && surveyData.questions.map((q, i) => {
                                            return (
                                                <div className="custom-container mb-4 mt-2" key={`questions-${surveyData?.questions?.length}-${i}`}>
                                                    <OverlayTrigger
                                                        trigger="click"
                                                        placement="left"
                                                        rootClose
                                                        overlay={
                                                            <Popover id={`popover-${i}`} className="custom-popover">
                                                                {menu?.map((m) => (
                                                                    <div
                                                                        className="dt-cm-item"
                                                                        key={m.key}
                                                                        onClick={() => {
                                                                            if (m.key === 'delete') {
                                                                                setSelectedData(q);
                                                                                setShowDelete(true);
                                                                                setAddAgain(false);
                                                                            } else if (m.key === 'edit') {
                                                                                setSelectedData(q);
                                                                                setView('form-view');
                                                                                setAddAgain(false);
                                                                            } else if (m.key === 'order') {
                                                                                setShowOrder(true);
                                                                                setAddAgain(false);
                                                                            }
                                                                        }}
                                                                    >
                                                                        <div>{m.icon ? m.icon : null}</div>
                                                                        <span className="grey-color">{m.title}</span>
                                                                    </div>
                                                                ))}
                                                            </Popover>
                                                        }
                                                    >
                                                        <div className="btn-more-container">
                                                            <button type="button" className="btn-more">
                                                                <MoreVertIcon className="w-19" />
                                                            </button>
                                                        </div>
                                                    </OverlayTrigger>
                                                    <div className="custom-q">
                                                        <span>№{i + 1}.</span>
                                                        <h4>Асуумж: {q?.question}</h4>
                                                        <p className="mb-3">Тайлбар: {q?.description}</p>
                                                        <div className="text-red mb-2">{q.isRequired ? 'Заавал хариулт авна' : ''}</div>
                                                        <div className="mb-2">
                                                            Асуумжийн төрөл: <span className="font-w-bold">{q?.typeName}</span>
                                                        </div>
                                                        <div className="d-flex flex-column px-4">
                                                            {q.answers?.map((a, j) => (
                                                                <label className="mb-2" key={`qa-${i}-${j}`}>
                                                                    {q?.isMultiAnswer ? <input type="checkbox" className='mr-2' disabled name={`q-${i}-${j}`} /> : <input type="radio" className='mr-2' disabled name={`q-${i}-${j}`} />}
                                                                    {a?.answer}
                                                                </label>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </>
                            ) : view === 'form-new' ? (
                                <NewQuestion
                                    ref={formQuestionnaireRef}
                                    key={`questionnaire-form-${surveyData?.questions?.length}`}
                                    questionTypes={questionTypes}
                                    save={setSave}
                                    selectedData={selectedData}
                                />
                            )
                              :
                            (
                                <EditQuestion
                                    ref={formQuestionnaireRef}
                                    key={`questionnaire-form-${surveyData?.questions?.length}`}
                                    questionTypes={questionTypes}
                                    save={setSave}
                                    selectedData={selectedData}
                                />
                            )}
                        </>
                    )}
                </Card.Body>
                <Card.Footer>
                    <Row>
                        <Col xs={3}>
                            {view === 'form-view' || view === 'form-new' && (
                                <Checkbox
                                    checked={addAgain}
                                    className="custom-cbox"
                                    label={t('survey.addAgain')}
                                    onChange={() => {
                                        setAddAgain(!addAgain);
                                    }}
                                />
                            )}
                        </Col>
                        <Col xs={2}></Col>
                        <Col>
                            {
                                view === 'form-view' || view === 'form-new' ? (
                                    <Button
                                        size="sm"
                                        variant="link"
                                        onClick={() => {
                                            setView('list');
                                        }}
                                    >
                                        {t('common.back')}
                                    </Button>
                                ) : (
                                    <Link to="/survey/list">
                                        <Button size="sm" variant="link">
                                            {t('common.back')}
                                        </Button>
                                    </Link>
                                )
                            }
                            <Button variant="success" className="br-8 ps-4 pe-4" size="sm" onClick={onSaveClick}>
                                {t('common.save')}
                            </Button>
                            {view === 'list' && (
                                <Button
                                    size="sm"
                                    variant="success"
                                    onClick={actionToPublish}
                                    className="br-8 ps-4 pe-4 custom-green-bg"
                                    style={{ marginLeft: 5 }}
                                >
                                    {t('action.publish')}
                                </Button>
                            )}
                        </Col>
                    </Row>
                </Card.Footer>
            </Card>
            {showOrder && surveyData && (
                <ChangeOrderModal
                    backdropClassName="custom-nested-modal"
                    dialogClassName="custom-nested-modal"
                    className="custom-nested-modal"
                    show={showOrder}
                    setShow={setShowOrder}
                    questions={surveyData?.questions || []}
                    survey={surveyData?.survey}
                    onSubmit={actionToOrder}
                />
            )}
            {showDelete && selectedData && (
                <DeleteModal
                    onClose={() => {
                        setShowDelete(false);
                        setSelectedData(null);
                    }}
                    onDelete={() => {
                        setShowDelete(false);
                        setSelectedData(null);
                        deleteItem(selectedData.id);
                    }}
                    title={t('warning.delete')}
                    modalSize="md"
                    backdropClassName="custom-nested-modal"
                    dialogClassName="custom-nested-modal"
                    className="custom-nested-modal"
                >
                    <p className="font-pd text-black text-center mb-0">
                        {t('warning.delete_confirmation')} {t('warning.delete_confirmation_description')}
                    </p>
                </DeleteModal>
            )}
        </>
    );
};

export default EditSurveyContainer;