import React, {useEffect, useState} from 'react'
import {Button, Card, Modal} from 'react-bootstrap'
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import DTable from "../../../../modules/DataTable/DTable";
import {setLoading} from "../../../../../redux/action";
import {fetchRequest} from "../../../../../utils/fetchRequest";
import {
    financeProgramPriceUpdate,
    getSubjectListByCode
} from "../../../../../utils/fetchRequest/Urls";
import message from "../../../../modules/message";
import Checkbox from "../../../../modules/Form/Checkbox";
import { cloneDeep } from 'lodash';

export const SubjectPrice = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    const [programs, setPrograms] = useState([]);
    const [oldPrograms, setOldPrograms] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const [isSameCredit, setIsSameCredit] = useState(false);
    const [isSameAssessment, setIsSameAssessment] = useState(false);
    const [errorSearchInput, setErrorSearchInput] = useState(false);
    const [index, setIndex] = useState(null);
    const [subjectId, setSubjectId] = useState(null);

    const parentSeason = useSelector(state => {
            let schoolList = state?.school?.schoolList;
            if(schoolList && schoolList.length > 0){
                for (let i = 0; i < schoolList.length; i++){
                    if(schoolId == schoolList[i]['id']){
                        return schoolList[i]['parentSeason'];
                    }
                }
            }
        }
    );

    const config = {
        showPagination: false,
        showFilter: false,
        showAllData: true,
    };

    const columns = [
        {
            dataField: "academicProgramCode",
            text: t('curriculum.code'),
            editable: false,
        },
        {
            dataField: "academicProgramName",
            text: t('curriculum.name'),
            sort: true,
            editable: false,
        },
        {
            dataField: "programCode",
            text: t('curriculum.programCode'),
            sort: true,
            editable: false,
        },
        {
            dataField: "programName",
            text: t('curriculum.programName'),
            sort: true,
            editable: false,
        },
        {
            dataField: "credit",
            text: t('subject.credit'),
            style: isEdit ? {padding: 1, verticalAlign: 'middle'}  : {verticalAlign: 'middle'},
            formatter: (cell, row, rowIndex) => {
                if(isEdit){
                    return (
                        <input
                            autoFocus={index == rowIndex ? true : false}
                            value={row.credit}
                            type="text"
                            style={{width: '100%', borderRadius: 3, border: '1px solid #ebedf2', height: 'fit-content', padding: 11}}
                            onChange={(e) => {handleInputChange(e, 'credit', row, rowIndex)}}
                        />
                    );
                } else {
                    return row.credit
                }
            },
        },
        {
            dataField: "totalCreditPrice",
            text: t('assessment.totalAssessment'),
            style: isEdit ? {padding: 1, verticalAlign: 'middle'}  : {verticalAlign: 'middle'},
            formatter: (cell, row, rowIndex) => {
                if(isEdit){
                    return (
                        <input
                            autoFocus={index == rowIndex ? true : false}
                            value={row.totalCreditPrice}
                            type="text"
                            style={{width: '100%', borderRadius: 3, border: '1px solid #ebedf2', height: 'inherit', padding: 11}}
                            onChange={(e) => {handleInputChange(e, 'totalCreditPrice', row, rowIndex)}}
                        />
                    );
                } else {
                    return row.totalCreditPrice;
                }
            },
        },
    ];

    const handleSearchClick = () => {
        if(searchValue){
            let params = {
                school: schoolId,
                code: searchValue
            };

            dispatch(setLoading(true));
            fetchRequest(getSubjectListByCode, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res;
                        let programs = data.programs;
                        if(programs && programs.length > 0){
                            for (let i = 0; i < programs.length; i++){
                                programs[i].checked = false;
                            }

                            setPrograms(programs);
                            setOldPrograms(cloneDeep(programs));
                            setSubjectId(data.subjectId)
                        } else {
                            setPrograms([]);
                            setOldPrograms([]);
                            message(t('subject.notFound'))
                        }
                    } else {
                        message(t('subject.notFound'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        } else {
            message(t('errorMessage.enterSubjectCode'));
            setErrorSearchInput(true);
        }
    };

    const handleChangeClick = () => {
        setIsEdit(true);
    };

    const handleBackClick = () => {
        let cloneOldData = cloneDeep(oldPrograms);
        setPrograms(cloneOldData);
        setIsEdit(false);
        setIsSameCredit(false);
        setIsSameAssessment(false);
    };

    const handleInputChange = (e, column, row, index) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            let cloneData = [...programs];

            if(row.checked && column == 'credit'){
                if(cloneData && cloneData.length > 0){
                    for(let i = 0; i < cloneData.length; i++){
                        cloneData[i][column] = e.target.value;
                    }
                }
            } else if(row.checked && column == 'totalCreditPrice'){
                if(cloneData && cloneData.length > 0){
                    for(let i = 0; i < cloneData.length; i++){
                        cloneData[i][column] = e.target.value;
                    }
                }
            } else {
                cloneData[index][column] = e.target.value;
            }

            setPrograms(cloneData);
            setIndex(index);
        }
    };

    const handleSearchChange = (event) => {
        setSearchValue(event.target.value);
        setErrorSearchInput(false);
    };

    const onSubmit = params => {
        let cloneData = [...programs];
        let dtlArray = [];

        if(cloneData && cloneData.length > 0){
            for (let i = 0; i < cloneData.length; i++){
                dtlArray.push({
                    id: cloneData[i].id,
                    credit: cloneData[i].credit,
                    totalPrice: cloneData[i].totalCreditPrice,
                })
            }
        }

        const bodyParams = {
            subject: subjectId,
            programDtls: JSON.stringify(dtlArray),
        };

        dispatch(setLoading(true));
        fetchRequest(financeProgramPriceUpdate, 'POST', bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    message(data?.message || t('common.success'), true);
                    setIsEdit(false);
                    setIsSameCredit(false);
                    setIsSameAssessment(false);
                    setPrograms(data.programs);
                    setOldPrograms(cloneDeep(programs));
                } else {
                    message(res?.data?.message || t('errorMessage.title'));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const handleCheckBoxChange = (checked, type) => {
        let cloneData = [...programs];
        for (let i = 0; i < cloneData.length; i++){
            cloneData[i].checked = checked;
        }

        if(type == 'credit'){
            setIsSameCredit(checked)
        } else {
            setIsSameAssessment(checked)
        }
    };

    return (
        <div className='sm-container'>
            <Card>
                <Card.Body>
                    <div className='row justify-content-center config-subject'>
                        <div className='col-auto config-subject-price-container'>
                            <label>
                                {t('subject.code')}
                            </label>
                            <input
                                type="text"
                                autoFocus={true}
                                placeholder={t('errorMessage.enterSubjectCode')}
                                value={searchValue}
                                onChange={handleSearchChange}
                                className={errorSearchInput ? 'form-control is-invalid' : 'form-control'}
                            />
                            <button
                                className='btn btn-warning-custom'
                                onClick={() => handleSearchClick()}
                            >
                                <i className="flaticon-search d-inline fs-11"/>
                                {t('common.search').toUpperCase()}
                            </button>
                        </div>
                        <div className='col-12'>
                            {
                                isEdit
                                ?
                                    <>
                                        <button
                                            className='btn btn-success'
                                            onClick={() => onSubmit()}
                                        >
                                            {t('common.save')}
                                        </button>
                                        <button
                                            className='btn btn-link bolder'
                                            onClick={() => handleBackClick()}
                                        >
                                            {t('common.back')}
                                        </button>
                                    </>
                                :
                                    programs && programs.length > 0
                                    ?
                                        <button
                                            className='btn btn-purple'
                                            onClick={() => handleChangeClick()}
                                        >
                                            {t('common.change')}
                                        </button>
                                    : null
                            }
                        </div>
                        {
                            isEdit
                            ?
                                <div className='col-12 ds-flex mt-5'>
                                    <Checkbox
                                        label={t('subject.isSameCredit')}
                                        className='flex-center'
                                        onChange={() => handleCheckBoxChange(!isSameCredit, 'credit')}
                                        checked={!!isSameCredit}
                                    />
                                    <Checkbox
                                        label={t('subject.isSameAssessment')}
                                        className='flex-center ml-10'
                                        onChange={() => handleCheckBoxChange(!isSameAssessment, 'assessment')}
                                        checked={!!isSameAssessment}
                                    />
                                </div>
                            : null
                        }
                        <div className='col-12'>
                            <DTable
                                columns={columns}
                                config={config}
                                data={programs}
                                selectMode={'radio'}
                            />
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </div>
    )
};
