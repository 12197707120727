import React,  { useState, useEffect } from 'react';
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DTable from "../../../modules/DataTable/DTable";
import { fetchRequest } from "../../../../utils/fetchRequest";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../../redux/action";
import { addRole, editRole, deleteRole, getRoles } from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import RoleAddModal from "./Add";
import DeleteModal from "../../../modules/DeleteModal";

const SystemRole = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [ roles, setRoles ] = useState([]);
    const [ showModal, setShowModal ] = useState(false);
    const [ sysRoles, setSysRoles ] = useState([]);
    const [ roleId, setRoleId ] = useState(null);
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    useEffect(() => {
        fetchRoles();
    }, []);

    const fetchRoles = () => {
        const params = {
            school: schoolId,
        };
        dispatch(setLoading(true));
        fetchRequest(getRoles, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    if (data?.roles?.length) {
                        setRoles(data.roles);
                    }
                    if (data?.sysRoles?.length) {
                        const systemRoles = data.sysRoles.map(role => {
                            return {
                                value: role.id,
                                text: `${role.name} (${role.code})`,
                            }
                        });
                        setSysRoles(systemRoles);
                    }
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            });
        onModalClose();
    };

    const config = {
        showPagination: false,
        showFilter: false,
        showAllData: true
    };

    const columns = [
        {
            dataField: "code",
            text: t('common.code'),
        },
        {
            dataField: "name",
            text: t('system.systemRole'),
        },
        {
            dataField: "sysRole",
            text: t('system.menuRole'),
        },
    ];

    const contextMenus = [
        {
            key: 'edit',
            title: t('common.edit'),
            icon: <i className='las la-edit dt-cm-item-icon'/>
        },
        {
            key: 'delete',
            title: t('common.delete'),
            icon: <i className='las la-trash-alt dt-cm-item-icon' />,
        },
    ];

    const handleContextMenuClick = (id, key) => {
        if (id && key) {
            if (key === 'edit') {
                setRoleId(id);
                setShowModal(true);
            }
            if (key === 'delete') {
                setRoleId(id);
            }
        }
    };

    const onModalClose = () => {
        setShowModal(false);
        setRoleId(null);
    };

    const onSubmit = params => {
        const url = roleId ? editRole : addRole;

        if (roleId) {
            params.role = roleId;
        }
        dispatch(setLoading(true));
        fetchRequest(url, 'POST', { ...params, school: schoolId })
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    message(res?.data?.message || t('common.success'), true);

                    fetchRoles();
                } else {
                    message(res?.data?.message || t('errorMessage.title'));
                    dispatch(setLoading(false));
                }
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            })
    };

    const onDelete = () => {
        const params = {
            school: schoolId,
            role: roleId
        };
        dispatch(setLoading(true));
        fetchRequest(deleteRole, 'POST', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    message(res?.data?.message || t('common.success'), true);
                    setRoles(data?.roles || []);
                    onModalClose();
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            })
    };

    return (
        <Card>
            <Card.Body>
                <button
                    className='btn btn-primary'
                    onClick={() => {
                        setShowModal(true);
                    }}
                >
                    {t('common.add')}
                </button>
                <DTable
                    data={roles}
                    config={config}
                    columns={columns}
                    contextMenus={contextMenus}
                    onContextMenuItemClick={handleContextMenuClick}
                    selectMode={'radio'}
                />
                {
                    showModal && (
                        <RoleAddModal
                            onClose={onModalClose}
                            onSubmit={onSubmit}
                            roleId={roleId}
                            systemRoles={sysRoles}
                        />
                    )
                }
                {
                    !showModal && roleId && (
                        <DeleteModal
                            onClose={onModalClose}
                            onDelete={onDelete}
                            title={t('system.systemRole')}
                        >
                            {t('warning.delete_confirmation')}
                            <br/>
                            <br/>
                            {t('warning.delete_confirmation_description')}
                        </DeleteModal>
                    )
                }
            </Card.Body>
        </Card>
    );
};

export default SystemRole;