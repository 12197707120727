import React, { useState, useEffect } from 'react'
import DTable from 'app/modules/DataTable/DTable'
import {Card, Button, Modal} from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import message from "app/modules/message";
import { fetchRequest } from 'utils/fetchRequest';
import { setLoading, setPersonInfo } from "redux/action";
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getStudentSurvey, getStudentSurveyStart, getStudentSurveySubmit, studentSurveyList } from 'utils/fetchRequest/Urls';
import SurveyModal from "./SurveyModal";

export const StudentSurvey = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { selectedSchool } = useSelector(state => state || null);

    const [helperModal, setHelperModal] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [surveyData, setSurveyData]= useState();
    const [showSurveyModal, setShowSurveyModal]= useState(false);
    const [selectedRow, setSelectedRow] = useState([]);

    const config = {
        showPagination: true,
        showFilter: true,
    };

    var date = new Date();

    const columns = [
        {
            dataField: 'statusName',
            text: t('common.status'),
            sort: true,
            style: {padding: 3, width: 150, verticalAlign: 'middle'},
            formatter(cell, row) {
                var endDate = new Date(row.endDate);
                var todayMilliseconds = date.getTime(); 
                var endDateMilliseconds = endDate.getTime(); 

                if(endDate && (endDateMilliseconds > todayMilliseconds)){
                    if (cell) {
                        return (
                            <div className='text-center'>
                                <div style={{background: + ' ' + row?.statusColor, color: 'white', padding: 5, borderRadius: 5, width: '100%'}}>{cell}</div>
                            </div>
                        )
                    }
                } else {
                    if (cell) {
                        return (
                            <div className='text-center'>
                                <div style={{background: + ' ' + '#464E5F', color: 'white', padding: 5, borderRadius: 5, width: '100%'}}>{t('onlineExam.inactive')}</div>
                            </div>
                        )
                    }
                }
                
            },
        },
        {
            dataField: 'duration',
            text: t('survey.date'),
            sort: true,
        },
        {
            dataField: 'name',
            text: t('survey.name'),
            sort: true,
        },
        {
            dataField: 'statusCode',
            text: '',
            headerStyle: () => {
                return {
                    width: 150
                }
            },
            style: {padding: 3, width: 150, verticalAlign: 'middle'},
            formatter(cell, row) {
                var endDate = new Date(row.endDate);
                var todayMilliseconds = date.getTime(); 
                var endDateMilliseconds = endDate.getTime(); 

                if(endDate && (endDateMilliseconds > todayMilliseconds)){
                    if (cell == 'NEW') {
                        return (
                            <div className='flex-center'>
                                <button className='btn btn-success' onClick={() => handlerSurveyClick(row)} style={{width: '100%'}}>{t('survey.fillOut')}</button>
                            </div>
                        )
                    } else if(cell == 'IN_PROGRESS'){
                        return (
                            <div className='flex-center'>
                                <button className='btn btn-success' onClick={() => handlerSurveyClick(row)} style={{width: '100%'}}>{row.statusName}</button>
                            </div>
                        )
                    } else {
                        return null;
                    }
                } else {
                    return null;
                }
            },
        },
    ];

    useEffect(() => {
        init()
    }, []);

    const init = () => {
        let params = {
            school: selectedSchool?.id
        }

        dispatch(setLoading(true));
        fetchRequest(studentSurveyList, 'GET', params)
            .then((res) => {
                if (res.success) {
                    const {data} = res;

                    setTableData(data?.surveyList || [])
                } else {
                    message(res.data.message || t('errorMessage.title'));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            });
    }

    const handleHelperModal = () => {
        setHelperModal(true)
    };

    const handleHelperModalClose = () => {
        setHelperModal(false)
    };

    const onSurveyModalClose = () => {
        setShowSurveyModal(false);
    };

    const handlerSurveyClick = (row) => {
        if(row.id){
            let params = {
                school: selectedSchool.id,
                survey: row.id,
            }
    
            dispatch(setLoading(true));
            fetchRequest(getStudentSurvey, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res;
                        setSurveyData(data?.surveyData || {});
                        setSelectedRow(row);
                        setShowSurveyModal(true);
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        } else {
            message(t('survey.notFound'))
        }
    }

    const surveySubmit = (questions) => {
        let params = {
            school: selectedSchool.id,
            survey: selectedRow.id,
            questions: JSON.stringify(questions),
        }

        dispatch(setLoading(true));
        fetchRequest(getStudentSurveySubmit, 'POST', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    const params = {
                        school: selectedSchool.id,
                    };
            
                    init(params)
                    onSurveyModalClose()

                    dispatch(setPersonInfo(data.user))
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }

    const onStartSubmit = (data) => {
        let params = {
            school: selectedSchool.id,
            survey: selectedRow.id,
        }

        dispatch(setLoading(true));
        fetchRequest(getStudentSurveyStart, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setSurveyData(data?.surveyData || {});
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }

    return (
        <div className='sm-container'>
            <Card>
                <Card.Body>
                    <div className='col-12 text-right'>
                        <Button className='bg-white border-white d-inline btn-hover-less' onClick={handleHelperModal}>
                            <i className="icon-2x text-danger flaticon-questions-circular-button d-inline"/>
                            <p className='text-danger d-inline'><b>{t('common.helper')}</b></p>
                        </Button>
                    </div>
                    <DTable
                        config={config}
                        data={tableData}
                        columns={columns}
                    />
                </Card.Body>
            </Card>
            {
                showSurveyModal
                    ?
                    <SurveyModal
                        show={showSurveyModal}
                        onClose={onSurveyModalClose}
                        onSubmit={surveySubmit} 
                        onStartSubmit={onStartSubmit} 
                        data={surveyData}
                        selectedRow={selectedRow}
                    />
                    : null
            }
            <Modal
                show={helperModal}
                onHide={handleHelperModalClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Modal heading
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Centered Modal</h4>
                    <p>
                        
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleHelperModalClose}>{t('common.close')}</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
};
