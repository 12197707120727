import React, { useEffect, useMemo, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import DTable from "../../../modules/DataTable/DTable";
import Select from "app/modules/Form/Select";
import { setLoading } from "redux/action";
import { fetchRequest } from "utils/fetchRequest";
import { scholarshipIndex } from "utils/fetchRequest/Urls";
import message from "app/modules/message";

const dataSource = [
    {
        id: 1,
        a: "asdf",
        b: "sdf",
        c: "sfda",
        d: "asfas",
        e: "asdfas",
        f: "sdf",
        g: "sdfa",
        h: "sdfas",
    },
];

const EnrollScholarship = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    const [list,setList] = useState([...dataSource])
    const [totalCount,setTotalCount] = useState(0)
    const [filter,setFilter] = useState({})
    const [tableFilter,setTableFilter] = useState({})

    const [schoolOptions,setSchoolOptions] = useState([])
    const [seasonOptions,setSeasonOptions] = useState([])
    const [enrollOptions,setEnrollOptions] = useState([])
    const [programOptions,setProgramOptions] = useState([])

    const onFilterChange = (key, value) => {
        setFilter(prev => {
            let tempFilter = { ...prev }
            
            if(key == 'subSchool') {
                tempFilter = {
                    subSchool: value
                }   
            } else {
                tempFilter[key] = value
            }
            init(tempFilter, tableFilter)
            return tempFilter
        })
    }

    const onInteraction = params => {
        setTableFilter(params)
        init(filter, params)
    }

    const init = (filter = {}, tableFilter = {}) => {
        try {
            dispatch(setLoading(true));
            const params = {
                school: schoolId,
                ...filter,
                ...tableFilter
            }
            fetchRequest(scholarshipIndex, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const { data: {
                            schools = [],
                            programs = [],
                            enrolls = [],
                            seasons = [],
                            totalCount = 0,
                            list = []
                        } = {} } = res || {}
                        setSchoolOptions(schools)
                        setEnrollOptions(()=>{
                            const tempData = []
                            enrolls.map(obj=>{
                                tempData.push({
                                    value: obj.id,
                                    text: obj.name + ' (' + obj.educationLevelName + ')'
                                })
                            })
                            return tempData
                        })
                        setSeasonOptions(()=>{
                            const tempData = []
                            seasons.map(obj=>{
                                tempData.push({
                                    value: obj.id,
                                    text: obj.name + ' (' + obj.educationName + ')'
                                })
                            })
                            return tempData
                        })
                        setProgramOptions(()=>{
                            const tempData = []
                            programs.map(obj=>{
                                tempData.push({
                                    value: obj.id,
                                    text: obj.programName
                                })
                            })
                            return tempData
                        })
                        setList(list)
                        setTotalCount(totalCount)
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                })
                .catch(() => {
                    message(t('errorMessage.title'))
                })
                .finally(() => dispatch(setLoading(false)))
        } catch (e) {
            message(t('errorMessage.title'))
            console.log(e)
        }
    }

    useEffect(()=>{
        init()
    },[])

    const columns = useMemo(()=>[
        {
            dataField: "candidateNo",
            text: t("enroll.id"),
            sort: false,
        },
        {
            dataField: "registrationNumber",
            text: t("person.registrationNumber"),
            sort: false,
        },
        {
            dataField: "lastName",
            text: t("person.lastName"),
            sort: false,
        },
        {
            dataField: "firstName",
            text: t("person.firstName"),
            sort: true,
        },
        {
            dataField: "gender",
            text: "Хүйс",
            colType: "gender",
            sort: true,
            formatter: cell => {
                if(cell == 'F') {
                    return t('common.female')
                } else {
                    return t('common.male')
                }
            }
        },
        {
            dataField: "primeSubject",
            text: t("enroll.basicCourse"),
            sort: true,
        },
        {
            dataField: "primeScore",
            text: t("enroll.basicScore"),
            sort: true,
        },
        {
            dataField: "name",
            text: t("enroll.appendix"),
            sort: true,
        },
        {
            dataField: "score",
            text: t("enroll.appendixScore"),
            sort: true,
        }
    ],[]);

    const config = useMemo(()=>{
        return {
            showPagination: true,
            showFilter: true,
            excelExport: true,
            excelFileName: "Тэтгэлэг",
            params: {
                school: schoolId,
                ...filter,
            },
        }
    },[filter])

    const filterArray = useMemo(()=>{
        return [,
            {
                key: 'subSchool',
                text: t('school.title'),
                options: schoolOptions
            },
            {
                key: 'season',
                text: t('school.season.title'),
                options: seasonOptions
            },
            {
                key: 'enroll',
                text: t('enroll.enrollmentType'),
                options: enrollOptions
            },
            {
                key: 'program',
                text: t('curriculum.profession'),
                options: programOptions
            }
        ]
    },[
        schoolOptions,
        seasonOptions,
        programOptions,
        enrollOptions
    ])



    const renderFilter = () => {
        return filterArray.map((item, index) => {
            return <Col key={index} md={3} className='mb-2'>
                <Row className='d-flex align-items-center justify-content-between'>
                    <Col md={4}>
                        <label className='mr-2'>{item.text}</label>
                    </Col>
                    <Col md={8}>
                        <Select
                            value={filter[item.key]}
                            onChange={value => onFilterChange(item.key, value)}
                            options={item.options}
                            searchable
                        />
                    </Col>
                </Row>
            </Col>
        })
    }

    return (
        <>
            <Card>
                <Card.Body>
                    <Row>
                        {renderFilter()}
                    </Row>
                </Card.Body>
            </Card>
            <div className="mx-auto mt-3">
                <Card>
                    <Card.Body>
                        <DTable
                            remote
                            data={list}
                            columns={columns}
                            config={config}
                            onInteraction={onInteraction}
                            totalDataSize={totalCount}
                        />
                    </Card.Body>
                </Card>
            </div>
        </>
    );
};

export default EnrollScholarship;
