import React, { useState, useEffect, useRef } from 'react';
import { Card, Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Forms from "../../../modules/Form/Forms";
import { fetchRequest } from "../../../../utils/fetchRequest";
import { schoolInit, schoolAdd, schoolEdit } from "../../../../utils/fetchRequest/Urls";
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../../redux/action";
import message from "../../../modules/message";

const SchoolComponentNew = ({
    location: { state: { school = null } = {} } = {},
}) => {
    const { t } = useTranslation();
    const [ schools, setSchools ] = useState([]);
    const [ helperModal, setHelperModal ] = useState(false);

    const form = useRef();
    const translateForm = useRef();
    const history = useHistory();
    const dispatch = useDispatch();

    const languages = useSelector(state => state?.languages?.languages || []).filter(lang => lang.code !== 'mn');

    useEffect(() => {
        if (school) {
            const params = {
                school,
            };
            dispatch(setLoading(true));
            fetchRequest(schoolEdit, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res;

                        if (data?.school) {
                            form?.current?.updateFields && form.current.updateFields([
                                {
                                    label: t('school.code') + '*',
                                    value: data.school.code || '',
                                    errorMessage: t('errorMessage.enterSchoolCode'),
                                    required: true,
                                    type: 'nonCryllic',
                                    upperCase: true,
                                },
                                {
                                    label: t('school.shortName') + '*',
                                    value: data.school.shortName || '',
                                    errorMessage: t('errorMessage.enterSchoolShortName'),
                                    required: true,
                                    type: 'text',
                                },
                                {
                                    label: t('school.name') + '*',
                                    value: data.school.longName || '',
                                    errorMessage: t('errorMessage.enterSchoolName'),
                                    required: true,
                                    type: 'text',
                                },
                                {
                                    label: t('school.parentSchool'),
                                    value: data.school.parentId || null,
                                    errorMessage: t('errorMessage.selectRelatedSchool'),
                                    // required: true,
                                    type: 'dropdown',
                                    options: data?.schools,
                                    multiple: false,
                                    searchable: true,
                                    clearable: true
                                },
                            ]);

                            const translations = data.school.translations || [];

                            const array = [];
                            for (const language of languages) {
                                const translation = translations.find(tr => tr.langCode === language.code);
                                const longName = translation?.longName || '';
                                const shortName = translation?.shortName || '';

                                array.push({
                                    label: `${t('school.shortName')} - ${language.name}(${language.code})`,
                                    value: shortName,
                                    required: false,
                                    type: 'text',
                                    langCode: language.code,
                                    key: 'shortName',
                                });

                                array.push({
                                    label: `${t('school.name')} - ${language.name}(${language.code})`,
                                    value: longName,
                                    required: false,
                                    type: 'text',
                                    langCode: language.code,
                                    key: 'longName',
                                });
                            }
                            translateForm?.current?.updateFields && translateForm.current.updateFields(array);
                        }
                    } else {
                        message(res?.data?.message || t('errorMessage.title'));
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'));
                })
        } else {
            dispatch(setLoading(true));
            fetchRequest(schoolAdd, 'GET')
                .then(res => {
                    dispatch(setLoading(false));
                    if (res.success) {
                        form?.current?.updateFields && form.current.updateFields([
                            {
                                label: t('school.code') + '*',
                                value: '',
                                errorMessage: t('errorMessage.enterSchoolCode'),
                                required: true,
                                type: 'nonCryllic',
                                upperCase: true,
                            },
                            {
                                label: t('school.shortName') + '*',
                                value: '',
                                errorMessage: t('errorMessage.enterSchoolShortName'),
                                required: true,
                                type: 'text',
                            },
                            {
                                label: t('school.name') + '*',
                                value: '',
                                errorMessage: t('errorMessage.enterSchoolName'),
                                required: true,
                                type: 'text',
                            },
                            {
                                label: t('school.parentSchool'),
                                value: null,
                                errorMessage: t('errorMessage.selectRelatedSchool'),
                                // required: true,
                                type: 'dropdown',
                                options: res.data.schools,
                                multiple: false,
                                searchable: true,
                                clearable: true
                            },
                        ]);
                        setSchools(res.data.schools);
                    }
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'));
                });
        }
    }, []);

    // useEffect(() => {
    //     form?.current?.updateFields && form.current.updateFields(forms);
    // }, [schools])

    const getFlatSchoolList = (source, array) => {
        for (const school of source) {
            array.push({
                value: school.key,
                text: school.title
            });
            if (school.children?.length) {
                getFlatSchoolList(school.children, array)
            }
        }
    };

    const forms = [
        {
            label: t('school.code') + '*',
            value: '',
            errorMessage: t('errorMessage.enterSchoolCode'),
            required: true,
            type: 'nonCryllic',
            upperCase: true,
        },
        {
            label: t('school.shortName') + '*',
            value: '',
            errorMessage: t('errorMessage.enterSchoolShortName'),
            required: true,
            type: 'text',
        },
        {
            label: t('school.name') + '*',
            value: '',
            errorMessage: t('errorMessage.enterSchoolName'),
            required: true,
            type: 'text',
        },
        {
            label: t('school.parentSchool'),
            value: null,
            errorMessage: t('errorMessage.selectRelatedSchool'),
            // required: true,
            type: 'dropdown',
            options: schools,
            multiple: false,
            searchable: true,
            clearable: true
        },
    ];

    const getFormTranslations = () => {
        const array = [];
        for (const language of languages) {
            array.push({
                label: `${t('school.shortName')} - ${language.name}(${language.code})`,
                value: '',
                required: false,
                type: 'text',
                langCode: language.code,
                key: 'shortName',
            });

            array.push({
                label: `${t('school.name')} - ${language.name}(${language.code})`,
                value: '',
                required: false,
                type: 'text',
                langCode: language.code,
                key: 'longName',
            });
        }
        return array;
    };

    const handleHelperModal = () => {
        setHelperModal(true)
    };

    const handleHelperModalClose = () => {
        setHelperModal(false)
    };

    const onSaveClick = () => {
        const [ formValid, formValue ] = form.current.validate();
        const [ translateValid, translateValues ] = translateForm.current.validate();
        if (formValid && translateValid) {
            dispatch(setLoading(true));
            const params = {
                code: formValue?.[0]?.value,
                shortName: formValue?.[1]?.value,
                longName: formValue?.[2]?.value,
            };
            if (formValue?.[3]?.value) {
                params.parent = formValue[3].value;
            }
            const translations = languages.map(language => {
                const translation = {
                    langCode: language.code,
                };
                const shortNameObject = translateValues.find(val => val.key === 'shortName' && val.langCode === language.code);

                if (shortNameObject) {
                    translation.shortName = shortNameObject.value;
                }

                const longNameObject = translateValues.find(val => val.key === 'longName' && val.langCode === language.code);

                if (longNameObject) {
                    translation.longName = longNameObject.value;
                }
                return translation;
            });
            params.translations = JSON.stringify(translations);
            const url = school ? schoolEdit : schoolAdd;
            if (school) {
                params.school = school;
            }
            fetchRequest(url, 'POST', params)
                .then(res => {
                    if (res.success) {
                        history.push('/structure/component')
                    } else {
                        message(res?.data?.message)
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                })
        }
    };

    return (
        <div className='row'>
            <div className='col-12'>
                <Card>
                    <Card.Body>
                        <div className='col-12 text-right'>
                            <Button className='bg-white border-white d-inline btn-hover-less'
                                    onClick={handleHelperModal}>
                                <i className="icon-2x text-danger flaticon-questions-circular-button d-inline"/>
                                <p className='text-danger d-inline'><b>{t('common.helper')}</b></p>
                            </Button>
                        </div>
                        <div className='col-12'>
                            <Forms
                                fields={forms}
                                ref={form}
                            />
                        </div>
                        <div className="separator separator-dashed my-7"/>
                        <div className='col-12'>
                            <Forms
                                fields={getFormTranslations()}
                                ref={translateForm}
                            />
                        </div>
                    </Card.Body>
                    <Card.Footer>
                        <div className='col-12 text-center'>
                            <Link
                                to='/structure/component'
                                className="btn btn-link bolder"
                            >
                                {t('common.back')}
                            </Link>
                            <Button
                                variant="success btn-shadow"
                                onClick={onSaveClick}
                            >
                                {t('common.save')}
                            </Button>
                        </div>
                    </Card.Footer>
                </Card>
            </div>
            <Modal
                show={helperModal}
                onHide={handleHelperModalClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Modal heading
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Centered Modal</h4>
                    <p>
                        Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
                        dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta
                        ac consectetur ac, vestibulum at eros.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleHelperModalClose}>{t('common.close')}</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
};

export default SchoolComponentNew;