import React, { useState, useEffect } from 'react'
import DTable from '../../../../modules/DataTable/DTable'
import {Button, ButtonToolbar, Card, Row, Col} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import DeleteModal from "../../../../modules/DeleteModal";
import {setLoading} from "../../../../../redux/action";
import {fetchRequest, fetchRequestFile} from "../../../../../utils/fetchRequest";
import {teacherFileDelete, teacherFileInit, teacherFileGet} from "../../../../../utils/fetchRequest/Urls";
import message from "../../../../modules/message";
import {Link} from "react-router-dom";
import { saveAs } from 'file-saver';
import Select from '../../../../modules/Form/Select';

export const TeacherOnlineFile = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [files, setFiles] = useState([]);
    const [fileId, setFileId] = useState(null);
    const [subjects, setSubjects] = useState([]);
    const [viewModal, setViewModal] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false);
    const [fileName, setFileName] = useState("");
    const [search, setSearch] = useState('');
    const [subject, setSubject] = useState(0);
    const [fileType, setFileType] = useState(0);
    const [subjectOption,setSubjectOption] = useState([]);
    const [typeOption,setTypeOption] = useState([]);
    const [totalData, setTotalData] = useState(1);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const config = {
        showPagination: true,
        showFilter: true,
    };

    const fetchData = (params) => {
        const bodyParams = {
            ...params,
            school: schoolId,
            page,
            pageSize,
            search
        }
        dispatch(setLoading(true));
        fetchRequest(teacherFileInit, 'GET', bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    if(data.files){
                        setFiles(data.files);
                    }
                    if(data.total){
                        setTotalData(data.total);
                    }
                    if (data?.subjects?.length) {
                        setSubjects(data.subjects);
                        const ss = []
                        data.subjects.map(subj=>{
                            ss.push({
                                'text': subj['name'],
                                'value': subj['id']
                            })
                        });
                        setSubjectOption(ss);
                    }
                    if(data?.types?.length){
                        const types = []
                        data.types.map(type=>{
                            types.push({
                                'text': type['name'],
                                'value': type['id']
                            })
                        });
                        setTypeOption(types);
                    }
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            });
    }

    const inputStyle = {
        border: 'solid 1px #cccccc',
        borderRadius: '4px',
        alignItems: 'center',
        minHeight: '38px',
        width: '100%',
        outline: '0',
        padding: '2px 8px'
    }

    const handleTableChange = params =>{
        setPage(params.page);
        setPageSize(params.pageSize);
        setSearch(params.search);
    }

    useEffect(() => {
        fetchData();        
    },[page, pageSize, search]);
    const columns = [
        {
            dataField: 'name',
            text: t('file.title'),
            sort: true,
            formatter: (cell, row) => {
                return (
                    <div className='underline-button' onClick={() => viewHandler(row.id)}>{cell}</div>
                )
            }
        },
        {
            dataField: 'fileType',
            text: t('file.type'),
            sort: true,
        },
        {
            dataField: 'subjectCode',
            text: t('subject.code'),
            sort: true,
        },
        {
            dataField: 'firstName',
            text: t('common.createdUser'),
            sort: true,
        },
        {
            dataField: 'createdDate.date',
            text: t('common.createdDate'),
            sort: true,
            formatter(cell,row){
                if(cell && cell.length > 19){
                    return cell.substring(0,19)
                }else{
                    return cell
                }
            }
        },
    ];

    const onSearch = () =>{
        const params = {
            subject,fileType,fileName
        }
        fetchData(params)
    }

    const contextMenus = [
        {
            key: 'view',
            title: t('common.view'),
            icon: <i className='las la-search dt-cm-item-icon'/>
        },
        {
            key: 'delete',
            title: t('common.delete'),
            icon: <i className='las la-trash-alt dt-cm-item-icon' />,
        },
    ];

    const handleContextMenuClick = (id, key, row) => {
        if(key === 'view'){
            onDownload(id);
        } else if (key === 'delete'){
            setFileId(row.fileId);
            setDeleteModal(true)    
        }
    };

    const viewHandler = (id) => {
        onDownload(id)
    };

    const onModalClose = () => {
        setDeleteModal(false);
    };

    const onClear = () =>{
        setSubject(0)
        setFileType(0)
        setFileName('')
    }

    const onDownload = (id) => {
        const bodyParams = {
            file: id
        };

        dispatch(setLoading(true));
        fetchRequest(teacherFileGet, 'POST', bodyParams)
            .then(res => {
                if(res.success){
                    fetchRequestFile(teacherFileGet, 'GET', bodyParams)
                        .then(res => {
                            if(res.status != 200){
                                dispatch(setLoading(false));
                                message(res?.data?.message || t('errorMessage.title'));
                            }
                            else{
                                return res.blob();
                            }
                        })
                        .then(blob => {
                            dispatch(setLoading(false));
                            if(blob){
                                saveAs(blob, res.data?.name);
                            }
                        })
                        .catch((e) => {
                            dispatch(setLoading(false));
                            message(t('errorMessage.title'));
                        });
                }
            })
            .catch((e) => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            });        
    };

    const onDelete = () => {
        const bodyParams = {
            school: schoolId,
            file: fileId,
            page,
            pageSize
        };

        dispatch(setLoading(true));
        fetchRequest(teacherFileDelete, 'POST', bodyParams)
            .then(res => {
                dispatch(setLoading(false));
                if (res.success) {
                    const { data } = res;
                    message(data?.message || t('common.success'), true);
                    if(data.files){
                        setFiles(data.files);
                        setTotalData(data.files.length);
                    }
                    if (data?.subjects?.length) {
                        setSubjects(data.subjects);
                    }
                    onModalClose();
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            })
    };
    
    <iframe title='download' name="downloadIframe" id="downloadIframe" style={{display: 'none'}}></iframe>

    return (
        
        <div className='sm-contsainer'>
            <Card className='mb-4'>
                <Card.Body>
                    <Row>
                        <Col md={4}>
                            <div className='w-100'>
                                <label>{t('subject.title')}</label>
                            </div>
                            <Select
                                value={subject}
                                onChange={value=>setSubject(value)}
                                options={subjectOption}
                                searchable
                            />
                        </Col>
                        <Col md={4}>
                            <div className='w-100'>
                                <label>{t('file.type')}</label>
                            </div>
                            <Select
                                value={fileType}
                                onChange={value=>setFileType(value)}
                                options={typeOption}
                                searchable
                            />
                        </Col>
                        <Col md={4}>
                            <div className='w-100'>
                                <label>{t('file.title')}</label>
                            </div>
                            <input
                                type='text'
                                value={fileName}
                                onChange={e=>setFileName(e.target?.value || '')}
                                style={inputStyle}
                            />
                        </Col>
                        
                    </Row>
                </Card.Body>
                <Card.Footer>
                    <div className='text-center'>
                        <button className='btn btn-link bolder' onClick={onClear}>
                            {t('common.clear').toUpperCase()}
                        </button>
                        <button className='btn btn-warning m-btn--icon m-btn--icon-only btn-shadow' onClick={onSearch}>
                            <i className="flaticon-search-1" style={{color: '#000'}}></i>
                            <span style={{color: '#000'}}>
                                {t('common.search').toUpperCase()}
                            </span>
                        </button>
                    </div>
                </Card.Footer>
            </Card>
            <Card>
                <Card.Body>
                    <div className='row'>
                        
                        <div className='col-12'>
                            <ButtonToolbar className='d-inline'>
                                <Link
                                    to={{
                                        pathname: '/teacher/file-add',
                                    }}
                                    params = {{
                                        subjects: subjects
                                    }}
                                    className="btn btn-primary btn-shadow"
                                >
                                    {t('file.add')}
                                </Link>
                            </ButtonToolbar>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12'>
                            <DTable
                                data={files}
                                columns={columns}
                                config={config}
                                totalDataSize={totalData}
                                remote
                                showOrdering={true}
                                contextMenus={contextMenus}
                                onInteraction={handleTableChange}
                                onContextMenuItemClick={handleContextMenuClick}
                                selectMode={'radio'}
                            />
                        </div>
                    </div>
                </Card.Body>
            </Card>
            {
                deleteModal && fileId && (
                    <DeleteModal
                        onClose={onModalClose}
                        onDelete={onDelete}
                        title={t('warning.delete')}>
                        {t('warning.delete_confirmation')}
                        <br />
                        <br />
                        {t('warning.delete_confirmation_description')}
                    </DeleteModal>
                )
            }
        </div>
        
         
    )
};
