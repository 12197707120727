import React, { useEffect, useRef, useState } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import { getSchoolEnrolls } from "../../../../utils/fetchRequest/Urls";
import Forms from "../../../modules/Form/Forms";
import message from "../../../modules/message";

const ScholarshipSearch = ({ onSearch }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const form = useRef();
    const schoolId = useSelector((state) => state?.selectedSchool?.id || null);
    const [enrolls, setEnrolls] = useState([]);
    const languages = useSelector(
        (state) => state?.languages?.languages || []
    ).filter((lang) => lang.code !== "mn");

    useEffect(() => {
        getEnrolls();
    }, []);

    useEffect(() => {
        form?.current?.updateFields && form.current.updateFields(fields);
    }, [enrolls]);

    const getEnrolls = () => {
        const params = {
            school: schoolId,
        };

        dispatch(setLoading(true));
        fetchRequest(getSchoolEnrolls, "GET", params)
            .then((res) => {
                if (res.success) {
                    const { data } = res;
                    const enrollOptions = [];
                    data?.list.forEach((enrolls) => {
                        enrollOptions.push({ value: enrolls.id, text: enrolls.name });
                    });

                    setEnrolls(enrollOptions);
                } else {
                    message(res?.data?.message || t("errorMessage.title"));
                }
                dispatch(setLoading(false));
            })
            .catch((e) => {
                dispatch(setLoading(false));
                message(t("errorMessage.title"));
            });
    };

    const fields = [
        {
            label: "Элсэлт",
            value: "",
            type: "dropdown",
            inputWidth: 180,
            required: false,
            key: "name",
            options: enrolls,
        },
    ];

    const handleSearch = () => {
        const [formValid, formValue] = form.current.validate();
        if (formValid) {
            const params = {
                enroll: formValue?.[0].value,
            };
            Object.keys(params).forEach((key) => {
                if (
                    params[key] === undefined ||
                    params[key] === null ||
                    params[key] === ""
                ) {
                    delete params[key];
                }
            });
            onSearch(params);
        }
    };

    return (
        <Card>
            <Card.Body>
                <div className="d-flex justify-content-center mr-19">
                    <div className="">
                        <Forms ref={form} fields={fields} />
                    </div>

                    <div className="pl-10 mt-2">
                        <button
                            variant="text"
                            className="btn bg-warning"
                            style={{ width: 120, height: 35, position: 'relative', top: 4 }}
                            onClick={handleSearch}
                        >
                            <i className="flaticon-search-1" style={{ color: "#000" }}></i>

                            <span style={{ color: "#000" }}>
                                {t("common.search").toUpperCase()}
                            </span>
                        </button>
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
};
export default ScholarshipSearch;
