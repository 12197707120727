import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import message from "../../../modules/message";
import TreeView from "../../../modules/TreeView";
import React, { useEffect, useState } from "react";
import { setLoading } from "../../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import CriteriaDtl from "./criteria/common/criteriaDtl";
import CriteriaAge from "./criteria/special/criteriaAge";
import CriteriaBody from "./criteria/special/criteriaBody";
import { fetchRequest } from "../../../../utils/fetchRequest";
import CriteriaCVConfig from "./criteria/cv/criteriaCVConfig";
import CriteriaHealth from "./criteria/special/criteriaHealth";
import CriteriaEyeshValue from "./criteria/common/criteriaEyeshValue";
import CriteriaPunishment from "./criteria/special/criteriaPunishment";
import CriteriaPsychology from "./criteria/special/criteriaPsychology";
import CriteriaGraduatedSchool from "./criteria/common/criteriaGraduatedSchool";
import {
    getCVConfig,
    getUpdateCVConfig,
    getEditProgram,
    getSchoolProgram,
    getAcademicProgram,
} from "../../../../utils/fetchRequest/Urls";
const Indicator = ({ enrollId, attributes }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [nodeId, setNodeId] = useState(null);
    const [nodeCode, setNodeCode] = useState(null);
    const [cvConfig, setCvConfig] = useState(null);
    const [anket, setAnket] = useState([]);
    const [commonPhases, setCommonPhases] = useState([]);
    const [specialPhases, setSpecialPhases] = useState([]);
    const [phaseTypes, setPhaseTypes] = useState([]);

    useEffect(() => {
        initializeNodes();
    }, [nodeId, attributes]);

    useEffect(() => {
        getConfig();
    }, []);

    useEffect(() => { }, [cvConfig]);

    const initializeNodes = () => {
        if (attributes) {
            const anketList = [];
            attributes.anket.forEach((anket) =>
                anketList.push({
                    key: anket.code,
                    id: anket.id,
                    title: anket.name,
                })
            );
            setAnket(anketList);

            const commonPhaseList = [];
            attributes.commonPhases.forEach((attribute) =>
                commonPhaseList.push({
                    key: attribute.code,
                    id: attribute.id,
                    title: attribute.name,
                })
            );
            setCommonPhases(commonPhaseList);

            const specialPhaseList = [];
            attributes.specialPhases.forEach((attribute) =>
                specialPhaseList.push({
                    key: attribute.code,
                    id: attribute.id,
                    title: attribute.name,
                })
            );
            setSpecialPhases(specialPhaseList);
            setPhaseTypes(attributes.selectedPhaseTypes)
        }
    };

    const getConfig = () => {
        dispatch(setLoading(true));
        const params = {
            enroll: enrollId,
        };

        fetchRequest(getCVConfig, "GET", params)
            .then((res) => {
                if (res.success) {
                    const { data } = res;
                    setCvConfig(data?.config || []);  
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
            });
    };

    async function updateConfig(params) {
        dispatch(setLoading(true));

        fetchRequest(getUpdateCVConfig, "POST", params)
            .then((res) => {
                if (res.success) {
                    // message(res.data.message, true);
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
            });
    }

    const handleTreeClick = (id, data) => {
        if(id && id.length > 0){
            setNodeId(id[0]);
            setNodeCode(data.node.phaseCode)
        }
    };

    const handleCheck = (value) => {
        const params = {
            value: value.value ? 1 : 0,
            column: value.column,
            enroll: enrollId,
        };
        updateConfig(params);
    };

    const handleSelect = (value) => {
        const params = {
            value: value.value,
            column: value.column,
            enroll: enrollId,
        };
        updateConfig(params);
    };

    function showComponent() {
        switch (nodeCode) {
            case "CV":
                return (
                    <CriteriaCVConfig
                        onChange={handleCheck}
                        configs={
                            [
                                {
                                    column: "isOrgodol",
                                    value: cvConfig && cvConfig.length > 0 ? cvConfig[0].isOrgodol : false,
                                    name: "Өргөдөл",
                                },
                                {
                                    column: "isEssay",
                                    value: cvConfig && cvConfig.length > 0 ? cvConfig[0].isEssay : false,
                                    name: "Эссэ",
                                },
                                {
                                    column: "isAdditionContact",
                                    value: cvConfig && cvConfig.length > 0 ? cvConfig[0].isAdditioncontact : false,
                                    name: "Яаралтай үед холбоо барих",
                                },
                                {
                                    column: "isWork",
                                    value: cvConfig && cvConfig.length > 0 ? cvConfig[0].isWork : false,
                                    name: "Ажлын газар",
                                },
                            ]
                        }
                    />
                );
            case "ENROLL_NO":
                return <CriteriaDtl enrollId={enrollId} />;
            case "EYESH":
                return <CriteriaEyeshValue enrollId={enrollId} />;
            case "GRADUATION":
                return (
                    <CriteriaGraduatedSchool
                        enrollId={enrollId}
                        onChange={handleCheck}
                        onChangeGPA={handleSelect}
                    />
                );
            case "AGE":
                return (
                    <CriteriaAge
                        enrollId={enrollId}
                        onChange={handleSelect}
                        mYear={cvConfig && cvConfig.length > 0 ? cvConfig[0].mYear : null}
                        fYear={cvConfig && cvConfig.length > 0 ? cvConfig[0].fYear : null}
                    />
                );
            case "CONVICTION":
                return (
                    <CriteriaPunishment
                        onChange={handleCheck}
                        columnName={"isPunishment"}
                        value={cvConfig && cvConfig.length > 0 ? cvConfig[0].isPunishment || false : false}
                    />
                );
            case "HEALT":
                return <CriteriaHealth />;
            case "EXAM":
                return <CriteriaBody enrollId={enrollId} />;
            case "PSYCHOLOGY":
                return <CriteriaPsychology />;
        }
    }

    return (
        <div className="row p-6">
            <div className="col-3 pr-0">
                {
                    phaseTypes && phaseTypes.length > 0 &&
                    phaseTypes.map((type, index) => {
                        return (
                            <Card key={'tree_' + index} className="mt-4">
                                <Card.Body>
                                    <TreeView
                                        selectedNodes={[nodeId]}
                                        treeData={[type]}
                                        defaultExpandAll={true}
                                        onSelect={(e, data) => handleTreeClick(e, data)}
                                    />
                                </Card.Body>
                            </Card>            
                        )
                    })
                }
            </div>
            <div className="col-9 align-items-start p-6 pl-4">{showComponent()}</div>
        </div>
    );
};

export default Indicator;
