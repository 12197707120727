import React, { useState, useEffect } from 'react';
import { Card, Tabs, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import { examTypeAdd, examTypeDelete, examTypeEdit } from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import DTable from "../../../modules/DataTable/DTable";
import ExamTypeAdd from "./ExamTypeAdd";
import edit from "../../school/subject/edit";
import ExamTypeConfig from "./ExamTypeConfig";
import DeleteModal from "../../../modules/DeleteModal";
import { getLastOrderNumber } from "../../../../utils/utils";

const ExamType = () => {
    const [ examTypes, setExamTypes ] = useState([]);
    const [ showModal, setShowModal ] = useState(false);
    const [ tabKey, setTabKey ] = useState('type');
    const [ examTypeId, setExamTypeId ] = useState(null);
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        fetchExamTypes();
    }, []);

    const fetchExamTypes = () => {
        const params = {
            school: schoolId
        };
        dispatch(setLoading(true));
        fetchRequest(examTypeAdd, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;

                    setExamTypes(data?.examTypes || []);

                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const handleAddClick = () => {
        setShowModal(true);
    };

    const onModalClose = () => {
        setShowModal(false);
        setExamTypeId(null);
    };

    const config = {
        showPagination: false,
        showFilter: false,
        showAllData: true
    };

    const columns = [
        {
            dataField: "code",
            text: t('common.code'),
        },
        {
            dataField: "name",
            text: t('examType.title'),
            sort: true
        },
        {
            dataField: "ordering",
            text: t('common.ordering'),
            style: {textAlign: 'right'},
            sort: true
        },
    ];

    const contextMenus = [
        {
            key: 'edit',
            title: t('common.edit'),
            icon: <i className='las la-edit dt-cm-item-icon'/>
        },
        {
            key: 'delete',
            title: t('common.delete'),
            icon: <i className='las la-trash-alt dt-cm-item-icon' />,
        },
    ];

    const onSubmit = params => {
        const url = examTypeId ? examTypeEdit : examTypeAdd;
        const bodyParams = {
            ...params,
            school: schoolId
        };

        if (examTypeId) {
            bodyParams.examType = examTypeId;

            const examType = examTypes.find(type => type.id === examTypeId);
            bodyParams.ordering = examType?.ordering || getLastOrderNumber(examTypes) + 1;
        } else {
            bodyParams.ordering = getLastOrderNumber(examTypes) + 1;
        }

        dispatch(setLoading(true));
        fetchRequest(url, 'POST', bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res;

                    setExamTypes(data?.examTypes ||[]);

                    message(data?.message || t('common.success'), true);

                    onModalClose();
                } else {
                    message(res?.data?.message || t('errorMessage.title'));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const renderTab = () => {
        if (tabKey === 'type') {
            return (
                <>
                    <button
                        className='btn btn-primary'
                        onClick={handleAddClick}
                    >
                        {t('common.add')}
                    </button>
                    <DTable
                        columns={columns}
                        config={config}
                        data={examTypes}
                        contextMenus={contextMenus}
                        onContextMenuItemClick={handleContextMenuClick}
                        selectMode={'radio'}
                    />
                </>
            )
        }
        return (
            <ExamTypeConfig/>
        )
    };

    const onTabChange = key => {
        if (key !== tabKey) {
            setTabKey(key);
            if (key === 'type') {
                fetchExamTypes();
            }
        }
    };

    const handleContextMenuClick = (id, key) => {
        if (id && key) {
            setExamTypeId(id);
            if (key === 'edit') {
                setShowModal(true);
            }
        }
    };

    const onDelete = () => {
        const params = {
            school: schoolId,
            examType: examTypeId
        };
        dispatch(setLoading(true));
        fetchRequest(examTypeDelete, 'POST', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setExamTypes(data?.examTypes ||[]);
                    message(data?.message || t('common.success'), true);
                    onModalClose();
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            })
    };

    return (
        <Card>
            <Card.Header>
                <Tabs
                    id={'config-exam-types'}
                    activeKey={tabKey}
                    className='msue-tab'
                    onSelect={onTabChange}
                >
                    <Tab title={t('examType.title')} eventKey={'type'}/>
                    <Tab title={t('examType.setting')} eventKey={'config'}/>
                </Tabs>
            </Card.Header>
            <Card.Body>
                {
                    renderTab()
                }
                {
                    showModal && (
                        <ExamTypeAdd
                            onClose={onModalClose}
                            onSubmit={onSubmit}
                            id={examTypeId}
                        />
                    )
                }
                {
                    !showModal && examTypeId && (
                        <DeleteModal
                            onClose={onModalClose}
                            onDelete={onDelete}
                            title={t('warning.delete').toUpperCase()}
                        >
                            {t('warning.delete_confirmation')}
                            <br/>
                            <br/>
                            {t('warning.delete_confirmation_description')}
                        </DeleteModal>
                    )
                }
            </Card.Body>
        </Card>
    );
};

export default ExamType;