import React from "react";
import { Radio as MUIRadio } from "@material-ui/core";
import styles from './questionTypes.module.scss';
import cloneDeep from 'lodash/cloneDeep'
import clsx from "clsx";
import { ROOT_URL } from "../../../../../utils/fetchRequest";

const Radio = ({
     onChange,
     value
 }) => {

    const handleCheck = index => {
        const clone = cloneDeep(value);
        const correctIndex = clone.findIndex(row => row.isCorrect);
        if (correctIndex < 0) {
            clone[0].isCorrect = true;
        } else {
            if (correctIndex !== index) {
                clone[correctIndex].isCorrect = false;
                clone[index].isCorrect = true;
            }
        }
        onChange(clone)
    }

    const handleAnswerChange = (index, answerValue) => {
        const clone = cloneDeep(value);
        clone[index].answer = answerValue;
        onChange(clone);
    }

    const handleFileChange = (event, index) => {
        if (event.target.files.length) {
            const file = event.target.files[0];
            const clone = cloneDeep(value);
            clone[index].filePath = null;
            clone[index].file = file;            
            onChange(clone);
        }
        event.target.value = null;
    }

    const addRow = () => {
        const clone = cloneDeep(value);
        clone.push({
            isCorrect: false,
            answer: '',
            file: null,
        })
        onChange(clone);
    }

    const removeRow = index => {
        const clone = cloneDeep(value);
        if (clone[index].isCorrect) {
            clone[index - 1].isCorrect = true;
        }
        clone.splice(index, 1);
        onChange(clone);
    }

    const removeImage = index => {
        const clone = cloneDeep(value);
        clone[index].file = null;
        onChange(clone);
    }

    const removeUrlImage = index => {
        const clone = cloneDeep(value);
        clone[index].filePath = null;
        onChange(clone);
    }
    
    function toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
        var reader = new FileReader();
        reader.onloadend = function() {
        callback(reader.result);
        }
        reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
    }

    function toBase64(url){
        return toDataURL(url, function(dataUrl) {
            return dataUrl;
        })
    }
    
    return (
        <div style={{ width: 'min-content' }}>
            {
                value.map((row, index) => {
                    const id = `radio-file-${index}`;
                    return (
                        
                        <div key={index}>
                            <div className={styles.rowContainer}>
                                <MUIRadio
                                    checked={row.isCorrect}
                                    onChange={() => handleCheck(index)}
                                />
                                <input
                                    className={clsx('form-control', styles.radioInput)}
                                    onChange={(e) => handleAnswerChange(index, e.target.value)}
                                    value={row.answer}
                                />
                                <input
                                    id={id}
                                    style={{ display: 'none' }}
                                    onChange={(e) => handleFileChange(e, index)}
                                    type='file'
                                    accept='image/png, image/gif, image/jpeg'
                                />
                                <label htmlFor={id} className={'btn btn-success mx-5'}>
                                    <i className='las la-image p-0' style={{ fontSize: 22 }} />
                                </label>
                                {
                                    index > 0
                                        ?   (
                                            <button className='btn btn-danger row-action-button' onClick={() => removeRow(index)}>
                                                <i className='las la-times' style={{ fontSize: 20 }} />
                                            </button>
                                        )
                                        :   <div style={{ width: 36 }}/>
                                }
                            </div>
                            {
                                row.file && (
                                    <div className={styles.imageContainer}>
                                        {
                                            <img
                                                className={styles.imagePreview}
                                                src={URL.createObjectURL(row.file)}
                                                alt=''
                                            />
                                        }
                                        <button className={clsx('btn btn-danger row-action-button mt-5')} onClick={() => removeImage(index)}>
                                            <i className='las la-trash' style={{ fontSize: 20 }} />
                                        </button>
                                    </div>
                                )
                            }
                            {
                                row.filePath && (
                                    <div className={styles.imageContainer}>
                                        {
                                            <img
                                                className={styles.imagePreview}
                                                src={ ROOT_URL + '/' + row.filePath}
                                                alt=''
                                            />
                                        }
                                        <button className={clsx('btn btn-danger row-action-button mt-5')} onClick={() => removeUrlImage(index)}>
                                            <i className='las la-trash' style={{ fontSize: 20 }} />
                                        </button>
                                    </div>
                                )
                            }
                        </div>
                    )
                })
            }
            <div className='text-right pr-1'>
                <button className='btn btn-primary row-action-button mr-1' onClick={addRow}>
                    <i className='las la-plus' style={{ fontSize: 20 }} />
                </button>
            </div>
        </div>
    );
};

export default Radio;