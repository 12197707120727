/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React, {useEffect, useState} from "react";
import { useHistory } from "react-router-dom";
import {toAbsoluteUrl} from "../../../../_helpers";
import store from "../../../../../redux/store";
import { useTranslation } from "react-i18next";
import {Button} from "@material-ui/core";
import {useSelector} from "react-redux";
import { ChangePassword } from "./ChangePassword";
import { ChangeProfile } from "./ChangeProfile";

export function QuickUser() {
    const history = useHistory();
    const person = useSelector(state => state?.person || []);
    const { t } = useTranslation();
    const [personData, setPersonData] = useState([]);
    const [togglePasswordModal , setTogglePasswordModal] = useState(false);
    const [toggleProfileModal , showToggleProfileModal] = useState(false);

    const logoutClick = () => {
        const toggle = document.getElementById("kt_quick_user_toggle");
        if (toggle) {
            toggle.click();
        }
        history.push("/logout");
    };

    const OpenModalPassword=()=>{
        setTogglePasswordModal(true);
    };

    const onModalPassClose=()=>{
        setTogglePasswordModal(false);
    };

    const OpenModalProfile=()=>{
        showToggleProfileModal(true);
    };

    const onModalProfileClose=()=>{
        showToggleProfileModal(false);
    };

    const onService=()=>{
        window.open('https://forms.office.com/Pages/ResponsePage.aspx?id=4msBOs13lE-rQjHVR0F-5kal9Cu8wnxLgWNwUcSahXRUQTIzQUtDWFFTUVJBVzJaUFdCSzVFMDhEMS4u&embed=true');
    };

    useEffect(() => {
        setPersonData(person);
    }, [person]);

    return (
        <div id="kt_quick_user" className="offcanvas offcanvas-right offcanvas p-10">
            <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
                <h3 className="font-weight-bold m-0">
                </h3>
                <a href="#" className="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_user_close">
                    <i className="ki ki-close icon-xs text-muted"/>
                </a>
            </div>

            <div className="offcanvas-content pr-5 mr-n5">
                <div className="d-flex align-items-center mt-5">
                    <div
                        className="symbol symbol-100 mr-5"
                    >
                        <div className="symbol-label" style={{
                            backgroundImage: `url(${toAbsoluteUrl(
                                personData.avatar
                            )})`
                        }}/>
                        <i className="symbol-badge bg-success"/>
                    </div>
                    <div className="d-flex flex-column">
                        <div className="font-weight-bold font-size-h6 text-uppercase">
                            <b>{personData.firstName}</b>
                        </div>
                        <div className="font-weight-bold font-size-h6 mb-4 text-capitalize">
                            <b>{personData.lastName}</b>
                        </div>

                        <div className="font-size-h6">
                            {personData.email ? personData.email : ' '}
                        </div>
                        <div className="font-size-h6">
                            {personData.phone ? personData.phone : ' '}
                        </div>
                    </div>
                </div>

                <div className="separator separator-dashed mt-3 mb-3"/>
                <Button variant="text" className="text-transform-none fs-11" onClick={OpenModalProfile}>
                    {t('system.profile')}
                </Button>
                <div className="separator separator-dashed mt-3 mb-3"/>
                <Button variant="text" className="text-transform-none fs-11" onClick={OpenModalPassword}>
                    {t('common.changePassword')}
                </Button>
                {/* <div className="separator separator-dashed mt-3 mb-3"/>
                <Button variant="text" className="text-transform-none fs-11" onClick={onService}>
                    {t('menu.psychologicalServices')}
                </Button> */}
                <div className="separator separator-dashed mt-3 mb-6"/>
                <div className="text-center">
                    <button className="btn btn-danger btn-bold fs-11" onClick={logoutClick}>{t('system.quit')}</button>
                </div>
                {
                    togglePasswordModal &&
                    (
                        <ChangePassword
                            onClose={onModalPassClose}
                        />
                    )
                }
                {
                    toggleProfileModal &&
                    (
                        <ChangeProfile
                            onClose={onModalProfileClose}/>
                    )
                }
            </div>
        </div>
    );
}
