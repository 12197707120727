import React, {useEffect, useState} from 'react'
import {Button, Card} from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import {setLoading} from "../../../../redux/action";
import {fetchRequest} from "../../../../utils/fetchRequest";
import {lmsStudentTranscriptCheck} from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import {useDispatch, useSelector} from "react-redux";

export const StudentQrCode = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [studentData, setStudentData] = useState([]);
    const [schoolData, setSchoolData] = useState([]);
    const [mainSchoolData, setMainSchoolData] = useState([]);
    const [qrCode, setQrCode] = useState([]);

    useEffect(() => {
        const studentCode = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1); 
        let params = {
            code: studentCode
        };

        dispatch(setLoading(true));
        fetchRequest(lmsStudentTranscriptCheck, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setStudentData(data?.studentData);
                    setMainSchoolData(data?.mainSchoolData || []);
                    setSchoolData(data?.schoolData || []);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            });
    }, []);

    return (
        <div className='sm-container'>
            <Card>
                <Card.Body>
                    {
                        mainSchoolData && mainSchoolData.longName &&
                        <>
                            <div>
                                <div style={{textAlign: 'center', fontFamily: 'Times New Roman', fontSize: '24px', fontWeight: 'bold'}}>
                                    {mainSchoolData.longName.toUpperCase()}
                                </div>
                                <div style={{textAlign: 'center', fontFamily: 'Times New Roman', fontSize: '22px', fontWeight: 'bold'}}>
                                    {schoolData && schoolData.longName ? schoolData.longName.toUpperCase() : ''}
                                </div>
                                <div style={{textAlign: 'center', fontFamily: 'Times New Roman', fontSize: '14px'}}>
                                    {mainSchoolData.address}
                                </div>
                                <div style={{textAlign: 'center', fontFamily: 'Times New Roman', fontSize: '14px'}}>
                                    {mainSchoolData.contact + ', (веб) ' + mainSchoolData.website}
                                </div>
                            </div>

                            <hr></hr>

                            <div style={{textAlign: 'center', fontFamily: 'Times New Roman', fontSize: '19px', fontWeight: 'bold', margin: '26px 60px 26px 50px'}}> 
                                Суралцагчийн тодорхойлолт
                            </div>

                            <div style={{textAlign: 'center', fontFamily: 'Times New Roman', fontSize: '14px', margin: 0}}>
                                <span><b>{studentData.lastName}</b> овогтой <b>{studentData.firstName}</b> нь тус сургуулийн <b>{studentData.academicProgramName}</b> хөтөлбөрөөр сурдаг нь үнэн болно.</span>
                            </div>

                            <div style={{textAlign: 'center', fontFamily: 'Times New Roman', fontSize: '14px', marginBottom: 25}}>
                                <span> Суралцагчийн дугаар: <b>{studentData.studentCode}</b> Регистрийн дугаар: <b>{studentData.registerNumber}</b></span>
                            </div>
                        </>
                    }
                </Card.Body>
            </Card>
        </div>
    )
};
