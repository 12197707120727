import React, {useRef, useEffect, useState} from 'react';
import {Button, ButtonToolbar, Card} from "react-bootstrap";
import Forms from "../../../../modules/Form/Forms";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {teacherOnlineLessonStudentList} from "../../../../../utils/fetchRequest/Urls";
import { setLoading } from "../../../../../redux/action";
import { fetchRequest } from "../../../../../utils/fetchRequest";
import message from "../../../../modules/message";
import {Link, useHistory} from "react-router-dom";
import DTable from "../../../../modules/DataTable/DTable";

const style = {
    cardBody: {
        position: 'relative',
        bottom: '4px',
        zIndex: 10
    }
}

export const Students = ({
    id
}) => {
    const { t } = useTranslation();
    const formRef = useRef();
    const history = useHistory();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [groups, setGroups] = useState([]);

    useEffect(() => {
        const params = {
            school: schoolId,
            course: id,
        };

        dispatch(setLoading(true));
        fetchRequest(teacherOnlineLessonStudentList, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setGroups(data?.groups || []);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }, []);

    const config = {
        showFilter: true,
        showAllData: true,
        showPagination: false,
    }

    const columns = [
        {
            dataField: "code",
            text: t('student.code'),
        },
        {
            dataField: "firstName",
            text: t('person.firstName'),
            sort: true
        },
        {
            dataField: "lastName",
            text: t('person.lastName'),
            sort: true
        },
    ];

    const contextMenus = [
        {
            key: 'edit',
            title: t('common.edit'),
            icon: <i className='las la-edit dt-cm-item-icon'/>
        },
        {
            key: 'delete',
            title: t('common.delete'),
            icon: <i className='las la-trash-alt dt-cm-item-icon' />,
        },
        {
            key: 'report',
            title: t('report.view'),
            icon: <i className='flaticon-graphic-1 dt-cm-item-icon' />,
        },
        {
            key: 'close',
            title: t('common.close'),
            icon: <i className='la la-eye-slash dt-cm-item-icon' />,
        },
    ];

    const handleContextMenuClick = (id, key, record) => {
        if(key === 'view'){

        } else if (key === 'delete'){

        } else if (key === 'report'){
            history.push({
                pathname: '/teacher/online/lesson-edit/discussion-view',
                state: {
                    id: 10
                }
            })
        } else if (key === 'change'){

        } else if (key === 'close'){

        }
    };

    const handlerOnSaveClick = () => {
        const [ isValid, states, values ] = formRef.current.validate();
        if (isValid) {
            const params = {
                ...values,
            };
        }
        history.push({
            pathname: '/teacher/online/lesson-edit/id=' + 11,
            state: {
                id: 11,
            }
        });
    };

    return (
        <div className='sm-container'>
            <Card style={style.cardBody}>
                <Card.Body>
                    {/* <ButtonToolbar className='d-inline'>
                        <button
                            className="btn btn-primary btn-shadow mb-4"
                        >
                            {t('discussion.downloadStudents')}
                        </button>
                    </ButtonToolbar> */}
                    {
                        groups && groups.length > 0
                        ?
                            groups.map((group, index) => {
                                return (
                                    <div key={'group_' + index} style={{marginBottom: 10}}>
                                        <b>{group['name'] + ', ' + group['typeName']}</b>
                                        <DTable
                                            data={group['studentList']}
                                            columns={columns}
                                            config={config}
                                            selectMode={'radio'}
                                        />
                                    </div>
                                )
                            })
                        : null 
                    }
                </Card.Body>
            </Card>
        </div>
    );
};