import React, { useState, useEffect } from 'react';
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import {menuInit, menuAdd, menuEdit} from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import DTable from "../../../modules/DataTable/DTable";
import MenuAdd from "./MenuAdd";
import MenuEdit from "./MenuEdit";
import DeleteModal from "../../../modules/DeleteModal";

const MenuConfig = () => {
    const [ menus, setMenus ] = useState([]);
    const [ showModal, setShowModal ] = useState(false);
    const [ showModalEdit, setShowModalEdit ] = useState(false);
    const [ menuId, setMenuId ] = useState(null);
    const [ totalCount, setTotalCount ] = useState(null);

    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setLoading(true));
        fetchRequest(menuInit, 'GET', [])
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    if (data?.menus?.length) {
                        setMenus(data.menus);
                        setTotalCount(data.totalCount);
                    }
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }, []);

    const handleAddClick = () => {
        setShowModal(true);
    };

    const onModalClose = () => {
        setShowModal(false);
        setShowModalEdit(false);
        setMenuId(null);
    };

    const config = {
        showPagination: true,
        showFilter: true,
    };

    const columns = [
        {
            dataField: "isActive",
            text: t('common.status'),
            headerStyle: () => ({
                width: 80,
            }),
            style: {textAlign: 'center'},
            formatter: (cell, row) => {
                if(cell === true){
                    return (
                        <div><span><i className="icon-1_5x main-green fa fa-circle"/></span></div>
                    )
                } else {
                    return (
                        <div><span><i className="icon-1_5x text-dark-50 fa fa-circle"/></span></div>
                    )
                }
            }
        },
        {
            dataField: "code",
            text: t('common.code'),
        },
        {
            dataField: "name",
            text: t('common.name'),
            sort: true
        },
        {
            dataField: "parentName",
            text: t('menu.parent'),
            sort: true
        },
        {
            dataField: "positionName",
            text: t('menu.position'),
            sort: true
        },
        {
            dataField: "roleCount",
            text: t('common.role'),
            sort: true,
            style: {textAlign: 'right'},
        },
        {
            dataField: "path",
            text: 'URL',
            sort: true
        },
        {
            dataField: "iconName",
            text: 'Icon',
            sort: true
        },
        {
            dataField: "ordering",
            text: t('common.ordering'),
            style: {textAlign: 'right'},
            sort: true
        },
    ];

    const contextMenus = [
        {
            key: 'edit',
            title: t('common.edit'),
            icon: <i className='las la-edit dt-cm-item-icon'/>
        },
    ];

    const handleContextMenuClick = (id, key) => {
        if (id && key) {
            setMenuId(id);
            if (key === 'edit') {
                setShowModalEdit(true);
            }
        }
    };

    const onSubmit = params => {
        const url = menuId ? menuEdit : menuAdd;
        const bodyParams = {
            ...params,
        };

        if (menuId) {
            bodyParams.menu = menuId;
        }

        dispatch(setLoading(true));
        fetchRequest(url, 'POST', bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setMenus(data?.menus || []);

                    message(data?.message || t('common.success'), true);
                    onModalClose();
                } else {
                    message(res?.data?.message || t('errorMessage.title'));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const onUserInteraction = (object) => {
        let params = {
            ...object,
        };

        dispatch(setLoading(true));
        fetchRequest(menuInit, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setMenus(data.menus);
                    setTotalCount(data.totalCount);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    // const onDelete = () => {
    //     const params = {
    //         school: schoolId,
    //         movementCause: menuId,
    //     };
    //     dispatch(setLoading(true));
    //     fetchRequest(movementCauseDelete, 'POST', params)
    //         .then(res => {
    //             if (res.success) {
    //                 const { data } = res;
    //                 setMenus(data?.menus || []);
    //                 message(data?.message || t('common.success'), true);
    //                 onModalClose();
    //             } else {
    //                 message(res?.data?.message || t('errorMessage.title'))
    //             }
    //             dispatch(setLoading(false));
    //         })
    //         .catch(err => {
    //             dispatch(setLoading(false));
    //             message(t('errorMessage.title'))
    //         })
    // };

    return (
        <Card>
            <Card.Body>
                <button
                    className='btn btn-primary'
                    onClick={handleAddClick}
                >
                    {t('common.add')}
                </button>
                <DTable
                    columns={columns}
                    config={config}
                    data={menus}
                    contextMenus={contextMenus}
                    onContextMenuItemClick={handleContextMenuClick}
                    selectMode={'radio'}
                    totalDataSize={totalCount}
                    showOrdering={true}
                    remote
                    onInteraction={onUserInteraction}
                />
                {
                    showModal && (
                        <MenuAdd
                            onClose={onModalClose}
                            onSubmit={onSubmit}
                        />
                    )
                }
                {
                    showModalEdit && (
                        <MenuEdit
                            onClose={onModalClose}
                            onSubmit={onSubmit}
                            id={menuId}
                        />
                    )
                }
                {/*{*/}
                {/*    !showModal && menuId && (*/}
                {/*        <DeleteModal*/}
                {/*            onClose={onModalClose}*/}
                {/*            onDelete={onDelete}*/}
                {/*            title={t('movement.cause')}*/}
                {/*        >*/}
                {/*            {t('warning.delete_confirmation')}*/}
                {/*            <br/>*/}
                {/*            <br/>*/}
                {/*            {t('warning.delete_confirmation_description')}*/}
                {/*        </DeleteModal>*/}
                {/*    )*/}
                {/*}*/}
            </Card.Body>
        </Card>
    );
};

export default MenuConfig;