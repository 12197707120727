import React, { useRef, useState } from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Forms from '../../../../../modules/Form/Forms';
// import Forms from 'app/modules/Form/Forms';
// import 'css/addInvoice.css';

const EditModal = ({ show, setShow, onSubmit, categories, data, codes }) => {
    const { t } = useTranslation();
    const formRef = useRef();

    const fields = [
        {
            key: 'code',
            label: t('common.code'),
            value: data?.code || '',
            type: 'dropdown',
            required: true,
            options: codes,
        },
        {
            key: 'name',
            value: data?.title || '',
            label: `${t('survey.category')}*`,
            type: 'text',
            required: true,
            placeHolder: t('survey.enterCategory'),
            errorMessage: t('survey.enterCategory'),
            labelBold: true,
        },
        {
            key: 'parent',
            value: data?.parent || null,
            label: `${t('survey.parentCategory')}`,
            type: 'dropdown',
            required: false,
            placeHolder: t('survey.parentCategoryPlaceholder'),
            labelBold: true,
            searchable: true,
            clearable: true,
            options: categories,
        },
    ];

    const onSaveClick = () => {
        const [isValid, , values] = formRef.current.validate();
        if (isValid) {
            onSubmit({
                ...values,
            });
            formRef.current.updateFields(fields);
        }
    };

    return (
        <Modal centered show={show} onHide={() => setShow(false)} size="xl">
            <Modal.Header closeButton>
                <Modal.Title className="fs-16">{t('survey.surveyCategory')}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-0">
                <Forms ref={formRef} fields={fields} />
            </Modal.Body>
            <Modal.Footer>
                <div className="text-center">
                    <Button onClick={() => setShow(false)} size="sm" variant="link">
                        {t('common.back')}
                    </Button>
                    <Button variant="success" className="br-8 ps-4 pe-4" size="sm" onClick={onSaveClick}>
                        {t('common.save')}
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default EditModal;
