import React, {useEffect, useRef, useState} from 'react';
import { Card, Tab, Tabs } from 'react-bootstrap';
import TreeView from "../../../modules/TreeView";
import { useTranslation } from "react-i18next";
import DTable from "../../../modules/DataTable/DTable";
import { LinearProgress } from "@material-ui/core";
import Select from "../../../modules/Form/Select";
import './selection.scss';
import { useHistory } from "react-router-dom";
import {setLoading} from "../../../../redux/action";
import {fetchRequest} from "../../../../utils/fetchRequest";
import {
    selectionFindStudents,
    selectionGetStudentData,
    selectionGetSubjectData,
    selectionInit
} from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import {useDispatch, useSelector} from "react-redux";
import Forms from "../../../modules/Form/Forms";

const Two = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    const [ seasons, setSeasons ] = useState([]);
    const [ tabKey, setTabKey ] = useState('class');
    const [ selectedTreeId, setSelectedTreeId ] = useState(null);
    const [ classes, setClasses ] = useState([]);
    const [ subjects, setSubjects ] = useState([]);
    const [ subjectId, setSubjectId ] = useState([]);
    const [ subjectData, setSubjectData ] = useState([]);
    const [ studentData, setStudentData ] = useState([]);
    const [ selectionData, setSelectionData ] = useState([]);
    const [ students, setStudents ] = useState([]);
    const [ studentId, setStudentId ] = useState(null);
    const [ totalCount, setTotalCount ] = useState(0);
    const [ totalStudentCount, setTotalStudentCount ] = useState(0);
    const [ selectedStudentCount, setSelectedStudentCount ] = useState(0);
    const [ value, setValue ] = useState('');
    const [ days, setDays ] = useState([]);
    const [ times, setTimes ] = useState([]);
    const [ timetables, setTimetables ] = useState([]);

    const [ treeIndex ] = useState('selection_two_tree_index');
    const [ tabIndex ] = useState('selection_two_tab_index');
    const formRef = useRef();

    const config = {
        showPagination: true,
        showFilter: true,
    };

    const studentConfig = {
        showPagination: false,
        showFilter: false,
        showAllData: true
    };

    useEffect(() => {
        const treeId = localStorage.getItem(treeIndex);
        const tab = localStorage.getItem(tabIndex);

        if(treeId){
            setSelectedTreeId(treeId);
        }

        if(tab){
            setTabKey(tab)
        }

        const params = {
            school: schoolId,
            season: treeId ? treeId : selectedTreeId,
            selection: 2,
            type: tab ? tab : tabKey,
            pageSize: 10,
            page: 1
        };
        fetchData(params);
    }, []);

    useEffect(() => {
        formRef?.current?.updateFields && formRef.current.updateFields(fields);
    }, [ subjects ]);

    const fetchData = params => {
        dispatch(setLoading(true));
        fetchRequest(selectionInit, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setClasses(data.classes);
                    setSeasons(data.seasons);
                    setSubjects(data.subjects);
                    setTotalCount(data?.totalCount ? data.totalCount : 0);

                    if(data.classes && data.classes.length > 0){
                        let totalStudentCount = 0;
                        let selectedStudentCount = 0;
                        for(let i = 0; i < data.classes.length; i++){
                            totalStudentCount += parseInt(data.classes[i].studentsCount);
                            selectedStudentCount += data.classes[i].totalSelectionCount;
                        }
                        setTotalStudentCount(totalStudentCount);
                        setSelectedStudentCount(selectedStudentCount);
                    }
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const fetchSubjectData = params => {
        dispatch(setLoading(true));
        fetchRequest(selectionGetSubjectData, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setSubjectData(data.subjectData);
                    setTotalCount(data?.totalCount ? data.totalCount : 0);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const fetchStudentData = params => {
        dispatch(setLoading(true));
        fetchRequest(selectionGetStudentData, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setSelectionData(data.selectionData);
                    setTimetables(data.timetables);
                    setDays(data.days);
                    setTimes(data.times);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const byClassColumns = [
        {
            dataField: "parentSeasonName",
            text: t('Элссэн жил'),
            sort: true
        },
        {
            dataField: "academicProgramName",
            text: t('curriculum.title'),
            sort: true
        },
        {
            dataField: "programName",
            text: t('curriculum.profession'),
            sort: true
        },
        {
            dataField: "teacherFirstName",
            text: t('movement.class.consultantTeacher'),
            sort: true
        },
        {
            dataField: "studentsCount",
            text: t('movement.class.studentCount'),
            sort: true,
            align: 'right',
        },
        {
            dataField: "totalSelectionCount",
            text: t('selection.selected'),
            sort: true,
            align: 'right',
        },
    ];

    const bySubjectColumns = [
        {
            dataField: "subjectType",
            text: t('subject.type'),
            sort: true
        },
        {
            dataField: "teacher",
            text: t('subject.teacher'),
            sort: true
        },
        {
            dataField: "time",
            text: t('subject.time'),
        },
        {
            dataField: "capacity",
            text: t('school.classroom.seatCount'),
            align: 'right',
        },
        {
            dataField: "groupStudentCount",
            text: t('selection.student'),
            align: 'right',
        },
    ];

    const handleTreeClick = array => {
        if (array.length) {
            const [ id ] = array;
            const split = id.toString().split('_');
            if (split.length === 4) {
                setSelectedTreeId(id);

                localStorage.setItem(treeIndex, id);

                const params = {
                    school: schoolId,
                    season: id,
                    selection: 2,
                    type: tabKey
                };
                fetchData(params);
            }
        }
    };

    const handleTabChange = key => {
        setTabKey(key);

        localStorage.setItem(tabIndex, key);

        const params = {
            school: schoolId,
            season: selectedTreeId,
            selection: 2,
            type: key
        };
        fetchData(params);
    };

    const onSubjectChange = (id, evt) => {
        const params = {
            school: schoolId,
            season: selectedTreeId,
            selection: 2,
            subject: id
        };
        setSubjectId(id);
        fetchSubjectData(params);
    };

    const fields = [
        {
            label: t('subject.title') + '*',
            value: null,
            type: 'dropdown',
            searchable: true,
            inputWidth: 200,
            required: true,
            options: subjects,
            key: 'type',
            onChange: onSubjectChange,
        }
    ];

    const onUserInteraction = (object) => {
        if(tabKey === 'class'){
            if(selectedTreeId && tabKey){
                let params = {
                    school: schoolId,
                    season: selectedTreeId,
                    selection: 2,
                    type: tabKey,
                    ...object,
                };

                fetchData(params)
            }
        } else if (tabKey === 'subject'){
            if(selectedTreeId && subjectId){
                const params = {
                    school: schoolId,
                    season: selectedTreeId,
                    selection: 2,
                    subject: subjectId,
                    ...object,
                };
                fetchSubjectData(params);
            }
        } else if (tabKey === 'student'){
            const params = {
                school: schoolId,
                season: selectedTreeId,
                selection: 2,
                student: studentId,
                ...object,
            };
            fetchStudentData(params);
        }
    };

    const onStudentChange = (value) => {
        setStudentId(value);
        const params = {
            school: schoolId,
            season: selectedTreeId,
            selection: 2,
            student: value
        };
        fetchStudentData(params)
    };

    useEffect(() => {
        let timeout;
        if (value) {
            timeout = setTimeout(() => {
                if(selectedTreeId){
                    const params = {
                        school: schoolId,
                        season: selectedTreeId,
                        selection: 2,
                        text: value
                    };
                    dispatch(setLoading(true));
                    fetchRequest(selectionFindStudents, 'GET', params)
                        .then(res => {
                            if (res.success) {
                                const { data } = res;
                                setStudents(data.students);
                            } else {
                                message(res?.data?.message || t('errorMessage.title'))
                            }
                            dispatch(setLoading(false));
                        })
                        .catch(() => {
                            dispatch(setLoading(false));
                            message(t('errorMessage.title'))
                        })
                } else {
                    message(t('errorMessage.selectSeason'))
                }
            }, 1000);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [value]);

    const onStudentInputChange = (inputValue, actionMeta) => {
        if(inputValue){
            setValue(inputValue);
        }
    };

    const getCellSubjects = (day, period) => {
        if(timetables && timetables.length > 0){
            return timetables.map(data => {
                if(data.timeId === period && day === data.dayId){
                    if(data.pTimeCode.toLowerCase() === 'laboratory'){
                        return (
                            <div
                                key={'day_' + day + '_time_' + period + '_id_' + data.id}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: 6,
                                    backgroundColor: 'rgba(245, 175, 4, 0.5)',
                                    marginBottom: 5
                                }}
                            >
                                <span>{data.subjectCode}</span>
                                <span>{data.subjectName}</span>
                                <span>{data.teacherFirstName}</span>
                                <span>{data.pTimeName}</span>
                                <span>{data.roomNumber}</span>
                            </div>
                        )
                    } else if (data.pTimeCode.toLowerCase() === 'seminar') {
                        return (
                            <div
                                key={'day_' + day + '_time_' + period + '_id_' + data.id}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: 6,
                                    backgroundColor: 'rgba(54, 163, 247, 0.5)',
                                    marginBottom: 5
                                }}
                            >
                                <span>{data.subjectCode}</span>
                                <span>{data.subjectName}</span>
                                <span>{data.teacherFirstName}</span>
                                <span>{data.pTimeName}</span>
                                <span>{data.roomNumber}</span>
                            </div>
                        )
                    } else {
                        return (
                            <div
                                key={'day_' + day + '_time_' + period + '_id_' + data.id}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: 6,
                                    backgroundColor: '#d8d8d8',
                                    marginBottom: 5
                                }}
                            >
                                <span>{data.subjectCode}</span>
                                <span>{data.subjectName}</span>
                                <span>{data.teacherFirstName}</span>
                                <span>{data.pTimeName}</span>
                                <span>{data.roomNumber}</span>
                            </div>
                        )
                    }
                }
            })
        } else {
            return null;
        }
    };

    const renderTab = () => {
        if (tabKey === 'class') {
            return (
                <div>
                    <div className='row mr-1'>
                        <div className='col-6 mb-3'>
                            <Card>
                                <Card.Body>
                                    <div style={{ fontWeight: 700, marginBottom: 6 }}>{`${t('common.total')}: ${totalStudentCount}`}</div>
                                    <LinearProgress variant='determinate' value={selectedStudentCount > 0 ? ((100 * selectedStudentCount)/totalStudentCount) : 0} className='msue-selection-progress' />
                                    <div style={{ display: 'flex', justifyContent: 'space-between', color: '#299f72', fontWeight: 700 }}>
                                        <span>{selectedStudentCount > 0 ? ((100 * selectedStudentCount)/totalStudentCount).toFixed(2) + '%' : 0}</span>
                                        <span>{selectedStudentCount}</span>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                    <DTable
                        config={config}
                        data={classes}
                        columns={byClassColumns}
                        showOrdering={false}
                        totalDataSize={totalCount}
                        selectMode={'radio'}
                    />
                </div>
            )
        }
        if (tabKey === 'subject') {
            return (
                <div>
                    <div className='row'>
                        <div className='col-7'>
                            <Forms
                                ref={formRef}
                                fields={fields}
                            />
                        </div>
                        <div className='col-5'/>
                    </div>
                    <DTable
                        config={config}
                        data={subjectData}
                        columns={bySubjectColumns}
                        showOrdering={true}
                        totalDataSize={totalCount}
                        remote
                        onInteraction={onUserInteraction}
                        selectMode={'radio'}
                    />
                </div>
            )
        }
        if(tabKey === 'student'){
            return (
                <div>
                    <div className='row'>
                        <div className='col-4' style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            {t('student.title') + '*'}
                        </div>
                        <div className='col-6'>
                            <Select
                                value={studentId}
                                searchable
                                onChange={onStudentChange}
                                onInputChange={onStudentInputChange}
                                options={students}
                            />
                        </div>
                    </div>
                    <table className='student-selection-details'>
                        <tbody>
                        <tr>
                            <td>{t('selection.date')}</td>
                            <th>{selectionData?.createdDate}</th>
                        </tr>
                        <tr>
                            <td>{t('total.credit')}</td>
                            <th>{selectionData?.totalCredit}</th>
                        </tr>
                        <tr>
                            <td>{t('total.price')}</td>
                            <th>{selectionData?.totalPrice}</th>
                        </tr>
                        <tr>
                            <td>{t('selection.paidDate')}</td>
                            <th>{selectionData?.paidDate}</th>
                        </tr>
                        </tbody>
                    </table>
                    <div className={'mb-3'}>
                        <button
                            className='btn btn-purple'
                        >
                            {t('common.change')}
                        </button>
                    </div>
                    <table className='table table-bordered'>
                        <thead>
                        <tr>
                            <th>{t('subject.time')}</th>
                            {
                                days.map(day => {
                                    return (
                                        <th key={day.id}>
                                            {day.day}
                                        </th>
                                    )
                                })
                            }
                        </tr>
                        </thead>
                        <tbody>
                        {
                            times && times.length > 0
                                ?
                                times.map(period => {
                                    const time = `${period.startTime}-${period.endTime}` + ' (' + period.name + ')';
                                    return (
                                        <tr key={period.id}>
                                            <td>{time}</td>
                                            {
                                                days.map(day => {
                                                    return (
                                                        <td key={`${day.id}-${period.id}`}>
                                                            {getCellSubjects(day.id, period.id)}
                                                        </td>
                                                    )
                                                })
                                            }
                                        </tr>
                                    )
                                })
                                : null
                        }
                        </tbody>
                    </table>
                </div>
            );
        }
    };

    return (
        <div className='row'>
            <div className='col-4 pr-0'>
                <Card>
                    <Card.Body>
                        <TreeView
                            treeData={seasons}
                            selectedNodes={[selectedTreeId]}
                            onSelect={handleTreeClick}
                            defaultExpandAll={true}
                        />
                    </Card.Body>
                </Card>
            </div>
            <div className='col-8'>
                <Card>
                    <Card.Header>
                        <Tabs
                            id={'msue-selection'}
                            activeKey={tabKey}
                            className='msue-tab'
                            onSelect={handleTabChange}
                        >
                            <Tab eventKey={'class'} title={t('selection.byClass')}/>
                            <Tab eventKey={'subject'} title={t('selection.bySubject')}/>
                            <Tab eventKey={'student'} title={t('selection.byStudent')}/>
                        </Tabs>
                    </Card.Header>
                    <Card.Body>
                        {renderTab()}
                    </Card.Body>
                </Card>
            </div>
        </div>
    );
};

export default Two;