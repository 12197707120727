import React, {useEffect, useState} from 'react'
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';

export const TranscriptSelf = React.forwardRef((props, ref) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {studentInfo, mainSchoolData, schoolData, qrCode} = props

    const mainSchoolName =  mainSchoolData && mainSchoolData.longName ? mainSchoolData.longName.substring(0,1).toUpperCase() + mainSchoolData.longName.substring(1) : '';
    const schoolName = schoolData && schoolData.longName ? schoolData.longName.substring(0,1).toUpperCase() + schoolData.longName.substring(1) : '';

    const capitalizeFirstLetter = (string) => {
        if(string){
            let sliceString = string.slice(1);
            return string.charAt(0).toUpperCase() + sliceString.toLowerCase();    
        }

        return null;
    }

    return (
        <div 
            ref={ref} 
            style={{marginTop: 30, padding: 20}}
        >
            {
                studentInfo && mainSchoolData &&
                <div>
                    <div style={{textAlign: 'center', marginTop: '30px'}}>
                        <div>
                            <div style={{fontFamily: 'Times New Roman', fontSize: 26, fontWeight: 'bold', textAlign: 'center'}}>
                                {mainSchoolName.toUpperCase()}
                            </div>
                            <div style={{fontFamily: 'Times New Roman', fontSize: 24, fontWeight: 'bold', textAlign: 'center', lineHeight: 0.5}}>
                                {schoolName.toUpperCase()}
                            </div>
                        </div>
                    </div>

                    <hr style={{border: '1px solid #000', margin: '30px 30px 0 30px'}}/>

                    <div style={{textAlign: 'right', fontFamily: 'Times New Roman', fontSize: 16, fontWeight: 'bold', margin: '10px 45px 30px 50px'}}> {t('transcript.ulaanbaatar')}</div>

                    <div style={{textAlign: 'center', fontFamily: 'Times New Roman', fontSize: 22, fontWeight: 'bold', margin: '0 60px 30px 50px'}}> {t('description.title')}</div>

                    <div className='col-12' style={{fontFamily: 'Times New Roman', margin: '20px 0 20px 30px'}}>
                        <table style={{fontWeight: 'bold', lineHeight: 3, fontSize: 16}}>
                            <tbody>
                                <tr>
                                    <td>Оюутны овог <span style={{float: 'right'}}>:</span></td>
                                    <td style={{paddingLeft: 10, position: 'relative', top: 1}}>{studentInfo.lastName}</td>
                                </tr>
                                <tr>
                                    <td>Оюутны нэр <span style={{float: 'right'}}>:</span></td>
                                    <td style={{paddingLeft: 10, position: 'relative', top: 1}}>{studentInfo.firstName}</td>
                                </tr>
                                <tr>
                                    <td>Регистр <span style={{float: 'right'}}>:</span></td>
                                    <td style={{paddingLeft: 10, position: 'relative', top: 1}}>{studentInfo.registerNumber}</td>
                                </tr>
                                <tr>
                                    <td>Хүйс <span style={{float: 'right'}}>:</span></td>
                                    <td style={{paddingLeft: 10, position: 'relative', top: 1}}>{studentInfo?.gender == 'F' ? 'ЭМЭГТЭЙ' : 'ЭРЭГТЭЙ'}</td>
                                </tr>
                                <tr>
                                    <td>Оюутны код <span style={{float: 'right'}}>:</span></td>
                                    <td style={{paddingLeft: 10, position: 'relative', top: 1}}>{studentInfo.studentCode}</td>
                                </tr>
                                <tr>
                                    <td>Суралцаж буй мэргэжил <span style={{float: 'right', paddingLeft: 5}}>:</span></td>
                                    <td style={{paddingLeft: 10, position: 'relative', top: 1}}>{studentInfo.academicProgramName ? capitalizeFirstLetter(studentInfo.academicProgramName) : '-'}</td>
                                </tr>
                                <tr>
                                    <td>Үнэлгээний голч дүн <span style={{float: 'right'}}>:</span></td>
                                    <td style={{paddingLeft: 10, position: 'relative', top: 1}}>{studentInfo.avgGpa ? studentInfo.avgGpa.toFixed(2) : ''}</td>
                                </tr>
                                <tr>
                                    <td>Нийт кредит <span style={{float: 'right'}}>:</span></td>
                                    <td style={{paddingLeft: 10, position: 'relative', top: 1}}>{studentInfo.totalCredit}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className='col-12' style={{fontFamily: 'Times New Roman', fontSize: 18, paddingLeft: 19, paddingRight: 19, textAlign: 'center'}}>
                        <span><b>Тус оюутан нь МОНГОЛ УЛСЫН БОЛОВСРОЛЫН ИХ СУРГУУЛИЙН {schoolName.toUpperCase()}-д суралцдаг нь үнэн болно.</b></span>
                    </div>

                    <div className='col-12' style={{textAlign: 'right', marginBottom: 30, marginTop: 30}}>
                        <img style={{width: 150, height: 150}} src={qrCode}/>
                    </div>

                    <div style={{textAlign: 'left', marginBottom: 25, marginLeft: 10, padding: '0 10px'}}>
                        <b><div className='col-6' style={{fontFamily: 'Times New Roman', fontSize: 14}}>Хэвлэсэн онгоо: {studentInfo.createdDate}</div></b>
                    </div>

                    <hr style={{border: '1px solid #000', margin: 30}}/>

                    <div style={{textAlign: 'center', fontFamily: 'Times New Roman', fontSize: 18, marginTop: 15, lineHeight: 1}}>
                        <b>{mainSchoolData.address}</b>
                    </div>

                    <div style={{textAlign: 'center', fontFamily: 'Times New Roman', fontSize: 18, marginTop: 20, lineHeight: 1}}>
                        <b>{mainSchoolData.contact + ', (веб) ' + mainSchoolData.website}</b>
                    </div>
                </div>
            }
        </div>
    )
});
