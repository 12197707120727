import React, { useState } from "react";
import Checkbox from "../../../../../modules/Form/Checkbox";
import { useTranslation } from "react-i18next";

const CriteriaPunishment = ({ onChange, value, columnName }) => {
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState(value);

  const handleChange = (value) => {
    setIsChecked(!value);
    onChange({ column: columnName, value: !value });
  };

  return (
    <div className="row p-6 ">
      <div className="">
        <div className="m-auto text-center" style={{ width: "fit-content" }}>
          <Checkbox
            key={0}
            label={t("enroll.checkPunishment")}
            // className="flex-center"
            onChange={handleChange}
            checked={isChecked}
          />
        </div>
      </div>
    </div>
  );
};

export default CriteriaPunishment;
