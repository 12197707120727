import { useTranslation } from "react-i18next";
import React, { useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Forms from "../../../modules/Form/Forms";
import { useSelector, useDispatch } from "react-redux";
import Editor from "../../../modules/textEditor/index";
import { fetchRequest } from "../../../../utils/fetchRequest";
import { certificateAdd } from "../../../../utils/fetchRequest/Urls";
import { setLoading } from "../../../../redux/action";
import message from "../../../modules/message";

const AddModal = ({ onClose, schoolId, onFinish, filter = {} }) => {
    const [bgImage, setBgImage] = useState(
        "http://192.168.0.132:8000/image/certificate/background_file.jpg"
    );
    const [richTextValue, setrichTextValue] = useState("");

    const form = useRef();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const languages = useSelector(
        (state) => state?.languages?.languages || []
    ).filter((lang) => lang.code !== "mn");

    const fields = [
        {
            label: t("common.name"),
            type: "text",
            upperCase: true,
            value: "",
            required: false,
            labelWidth: 250,
            inputWidth: 300,
            key: "templateName",
        },
        {
            type: "jsx",
            value: (
                <div key={"separator"} className="separator separator-dashed my-7" />
            ),
        },
    ];

    const translationFields = languages.map((lang) => {
        return {
            label: `${t("common.name")} - ${lang.name} (${lang.code})`,
            value: "",
            langCode: lang.code,
            type: "text",
            inputWidth: 300,
            labelWidth: 230,
        };
    });

    const onSaveClick = () => {
        const [formValid, formValue] = form.current.validate();
        if (formValid) {
            const params = {
                school: schoolId,
                templateName: formValue?.[0].value,
                textData: richTextValue,
                ...filter
            };
            dispatch(setLoading(true));
            fetchRequest(certificateAdd, "POST", params, false).then((res) => {
                if (res.success) {
                    message(res.data.message, true);

                    onClose();
                    onFinish();
                } else {
                    message(res.data.message, false);
                }
                dispatch(setLoading(false));
            });
        } else {
            console.log("Form invalid");
        }
    };

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append("file", file);

        fetch("/upload", {
            method: "POST",
            body: formData,
        })
            .then((response) => response.json())
            .then((data) => {
                setBgImage(data.url);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: "#4a70ae", fontSize: "1.1rem" }}>
                        {t("enroll.createCertificateModel")}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <Forms ref={form} fields={fields} />
                </div>
                <div className=" mx-auto">
                    <p><b>Дугаар: %certificationNumber%</b></p>
                    <p><b>Элсэгчийн нэр: %firstName%</b></p>
                    <p><b>Эцэг эхийн нэр: %lastName%</b></p>
                    <p><b>Мэргэжил нэр: %programName%</b></p>
                    <p><b>Сургуулийн нэр: %schoolName%</b></p>
                    <p><b>Хөтөлбөрийн нэр: %academicProgramName%</b></p>
                    <Editor
                        textValue={richTextValue}
                        bgImage={bgImage}
                        onTextChange={setrichTextValue}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className="btn btn-link bolder">
                    {t("common.back")}
                </button>
                <Button variant="success btn-shadow" onClick={onSaveClick}>
                    {t("common.save")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddModal;
