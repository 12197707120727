import { useTranslation } from "react-i18next";
import message from "../../../modules/message";
import Forms from "../../../modules/Form/Forms";
import { setLoading } from "../../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import React, { useRef, useEffect, useState } from "react";
import { fetchRequest } from "../../../../utils/fetchRequest";
import { Button, Modal, View, Row, Col } from "react-bootstrap";
import {candidateProfileEdit} from "../../../../utils/fetchRequest/Urls";

const ProfileEdit = ({ onClose, onSubmit, id, programId }) => {
    const form = useRef();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [candidateDetail, setCandidateDetail] = useState(null);

    useEffect(() => {
        form?.current?.updateFields && form.current?.updateFields(forms);
    }, [candidateDetail]);

    useEffect(() => {
        const params = {
            user: id,
            program: programId
        };

        dispatch(setLoading(true));
        fetchRequest(candidateProfileEdit, "GET", params)
            .then((res) => {
                if (res.success) {
                    const { data } = res;
                    setCandidateDetail(data?.candidateData || null);
                } else {
                    message(res?.data?.message || t("errorMessage.title"));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t("errorMessage.title"));
            });
    }, []);

    const onSaveClick = () => {
        const [formValid, formValues] = form.current.validate();
        if (formValid) {
            const params = {
                user: id,
                email: formValues?.[0]?.value,
                registerNumber: formValues?.[1]?.value,
                phoneNumber: formValues?.[2]?.value,
                description: formValues?.[3]?.value,
            };

            onSubmit(params)
        }
    };

    const forms = [
        {
            label: "И-Мэйл",
            required: true,
            type: "text",
            multiple: false,
            clearable: true,
            inputWidth: 250,
            labelWidth: 200,
            value: candidateDetail?.email || "",
            key: "email",
        },
        {
            label: "Регистрийн дугаар",
            required: true,
            type: "text",
            multiple: false,
            clearable: true,
            inputWidth: 250,
            labelWidth: 200,
            value: candidateDetail?.registerNumber || "",
            key: "registrationNumber",
        },
        {
            label: "Утасны дугаар",
            required: true,
            type: "text",
            multiple: false,
            clearable: true,
            inputWidth: 250,
            labelWidth: 200,
            value: candidateDetail?.phoneNumber || "",
            key: "registrationNumber",
        },
        {
            label: "Тайлбар",
            type: "textArea",
            required: true,
            clearable: true,
            inputWidth: 250,
            labelWidth: 200,
            key: "description",
            value: '',
        },
    ];

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div
                        style={{
                            color: "#4a70ae",
                            fontSize: "1.1rem",
                            textTransform: "uppercase",
                        }}
                    >
                        Засах
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="d-flex justify-content-end col-3 ml-10">
                        {
                            candidateDetail &&
                            <img
                                className="d-flex justify-content-end"
                                style={{
                                    objectFit: "contain",
                                    height: "105px",
                                    width: "93px",
                                    margin: "auto",
                                    marginTop: "0.5px",
                                    marginLeft: "10rem",
                                }}
                                src={
                                    candidateDetail.avatar == null ||
                                        candidateDetail.avatar === "" ||
                                        candidateDetail.avatar === undefined ||
                                        candidateDetail.avatar === "NULL"
                                        ? "/media/users/avatar_male.png"
                                        : "https://darkhanapi.nomch.mn/" + candidateDetail.avatar
                                }
                            />
                        }
                    </div>
                    <ul className="col-7" style={{ lineHeight: 0.5 }}>
                        <li className="d-flex justify-content-center row">
                            <div className="col">
                                <p
                                    style={{
                                        color: "#185BC5",
                                        fontWeight: "bold",
                                        fontSize: "normal",
                                    }}
                                    className=" d-flex justify-content-end"
                                >
                                    Бүртгэлийн дугаар
                                </p>
                            </div>
                            <div className="col justify-content-end">
                                <span style={{ fontWeight: "bold" }} className="text-dark">
                                    {candidateDetail?.requestNo || ''}
                                </span>
                            </div>
                        </li>
                        <li className="d-flex justify-content-center row pb-3">
                            <div className="col">
                                <p
                                    style={{
                                        color: "#185BC5",
                                        fontWeight: "bold",
                                        fontSize: "normal",
                                    }}
                                    className=" d-flex justify-content-end"
                                >
                                    ЭЕШ бүртгэлийн дугаар
                                </p>
                            </div>
                            <div className="col justify-content-end">
                                <span style={{ fontWeight: "bold" }} className="text-dark">
                                    {candidateDetail?.butName || ''}
                                </span>
                            </div>
                        </li>
                        <li className="d-flex justify-content-center row">
                            <div className="col">
                                <p
                                    style={{
                                        color: "#185BC5",
                                        fontWeight: "bold",
                                    }}
                                    className=" d-flex justify-content-end"
                                >
                                    Овог
                                </p>
                            </div>
                            <div className="col justify-content-end">
                                <span style={{ fontWeight: "bold" }} className="text-dark">
                                    {candidateDetail?.lastName || ''}
                                </span>
                            </div>
                        </li>
                        <li className="d-flex justify-content-center row">
                            <div className="col">
                                <p
                                    style={{
                                        color: "#185BC5",
                                        fontWeight: "bold",
                                        fontSize: "normal",
                                    }}
                                    className=" d-flex justify-content-end"
                                >
                                    Нэр
                                </p>
                            </div>
                            <div className="col justify-content-end">
                                <span style={{ fontWeight: "bold" }} className="text-dark">
                                    {candidateDetail?.firstName || ''}
                                </span>
                            </div>
                        </li>
                        <li className="d-flex justify-content-center row">
                            <div className="col">
                                <p
                                    style={{
                                        color: "#185BC5",
                                        fontWeight: "bold",
                                        fontSize: "normal",
                                    }}
                                    className=" d-flex justify-content-end"
                                >
                                    Регистрийн дугаар
                                </p>
                            </div>
                            <div className="col justify-content-end">
                                <span style={{ fontWeight: "bold" }} className="text-dark">
                                    {candidateDetail?.registerNumber || ''}
                                </span>
                            </div>
                        </li>
                        {
                            candidateDetail && candidateDetail.gender &&
                            <li className="d-flex justify-content-center row">
                                <div className="col">
                                    <p
                                        style={{
                                            color: "#185BC5",
                                            fontWeight: "bold",
                                            fontSize: "normal",
                                        }}
                                        className=" d-flex justify-content-end"
                                    >
                                        Хүйс
                                    </p>
                                </div>
                                <div className="col justify-content-end">
                                    <span style={{ fontWeight: "bold" }} className="text-dark">
                                        {candidateDetail.gender === "M" ? "Эрэгтэй" : "Эмэгтэй"}
                                    </span>
                                </div>
                            </li>
                        }
                        <li className="d-flex justify-content-center row">
                            <div className="col">
                                <p
                                    style={{
                                        color: "#185BC5",
                                        fontWeight: "bold",
                                        fontSize: "normal",
                                    }}
                                    className=" d-flex justify-content-end"
                                >
                                    И-мэйл
                                </p>
                            </div>
                            <div className="col justify-content-end">
                                <span style={{ fontWeight: "bold" }} className="text-dark">
                                    {candidateDetail?.email || ''}
                                </span>
                            </div>
                        </li>
                        <li className="d-flex justify-content-center row">
                            <div className="col">
                                <p
                                    style={{
                                        color: "#185BC5",
                                        fontWeight: "bold",
                                        fontSize: "normal",
                                    }}
                                    className=" d-flex justify-content-end"
                                >
                                    Утасны дугаар
                                </p>
                            </div>
                            <div className="col justify-content-end">
                                <span style={{ fontWeight: "bold" }} className="text-dark">
                                    {candidateDetail?.phoneNumber || ''}
                                </span>
                            </div>
                        </li>
                    </ul>
                </div>

                <div className="d-flex justify-content-center">
                    <Forms ref={form} fields={forms} />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className="btn btn-link bolder">
                    {t("common.back")}
                </button>
                <Button variant="success btn-shadow" onClick={onSaveClick}>
                    {t("common.save")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ProfileEdit;
