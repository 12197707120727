import React, {useRef, useEffect, useState} from 'react';
import {Button, Card} from "react-bootstrap";
import Forms from "../../../../modules/Form/Forms";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {teacherOnlineLessonEdit} from "../../../../../utils/fetchRequest/Urls";
import { setLoading } from "../../../../../redux/action";
import { fetchRequest } from "../../../../../utils/fetchRequest";
import message from "../../../../modules/message";
import {Link, useHistory} from "react-router-dom";

const style = {
    cardBody: {
        position: 'relative',
        bottom: '4px',
        zIndex: 10
    }
}

export const Information = ({
    id,
}) => {
    const { t } = useTranslation();
    const formRef = useRef();
    const history = useHistory();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [seasonName, setSeasonName] = useState(null);
    const [parentSeasonName, setParentSeasonName] = useState(null);
    const [groups, setGroups] = useState([]);
    const [courseTypes, setCourseTypes] = useState([]);
    const [courseData, setCourseData] = useState(null);

    useEffect(() => {
        const params = {
            school: schoolId,
            course: id
        };

        dispatch(setLoading(true));
        fetchRequest(teacherOnlineLessonEdit, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setGroups(data?.groups || []);
                    setSeasonName(data?.seasonName || []);
                    setParentSeasonName(data?.parentSeasonName || []);
                    setCourseTypes(data?.courseTypes || []);
                    setCourseData(data?.courseData || []);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }, []);

    useEffect(() => {
        formRef?.current?.updateFields && formRef.current?.updateFields(fields);
    }, [seasonName, parentSeasonName, courseData, courseTypes, groups]);

    const fields = [
        {
            key: 'year',
            label: t('school.season.title'),
            value: parentSeasonName || '',
            type: 'text',
            disabled: true
        },
        {
            key: 'season',
            label: t('subject.season'),
            value: seasonName || '',
            type: 'text',
            disabled: true
        },
        {
            key: 'subject',
            label: t('subject.title'),
            value: courseData?.subjectName || '',
            type: 'text',
            disabled: true
        },
        {
            key: 'courseType',
            label: t('subject.type'),
            value: courseData?.typeId || null,
            type: 'dropdown',
            required: true,
            clearable: true,
            searchable: true,
            options: courseTypes
        },
        {
            key: 'groups',
            label: t('group.groups'),
            value: courseData?.groupIds || [],
            type: 'dropdown',
            required: true,
            clearable: true,
            searchable: true,
            multiple: true,
            options: groups
        }
    ];

    const handlerOnSaveClick = () => {
        const [ isValid, states, values ] = formRef.current.validate();
        if (isValid) {
            const params = {
                school: schoolId,
                course: id,
                courseType: values.courseType,
                subject: values.subject,
                groups: JSON.stringify(values.groups),
            };
            
            dispatch(setLoading(true));
            fetchRequest(teacherOnlineLessonEdit, 'POST', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res;
                        // history.push({
                        //     pathname: '/teacher/online/lesson-edit/id=' + id,
                        //     state: {
                        //         id: id,
                        //     }
                        // });
                        message(res?.data?.message, true);
                    } else {
                        message(res?.data?.message || t('errorMessage.title'));
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        }
    };

    return (
        <div className='sm-container'>
            <Card style={style.cardBody}>
                <Card.Body>
                    <div className='col-12'>
                        <Forms
                            ref={formRef}
                            fields={fields}
                        />
                    </div>
                </Card.Body>
                <Card.Footer>
                    <div className='col-12 text-center'>
                        <Link
                            to='/teacher/online/lesson'
                            className="btn btn-link bolder"
                        >
                            {t('common.backTo_list')}
                        </Link>
                        <Button variant="success btn-shadow" onClick={handlerOnSaveClick}>{t('common.save')}</Button>
                    </div>
                </Card.Footer>
            </Card>
        </div>
    );
};