import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { useDispatch } from 'react-redux';
import { setLoading } from 'redux/action'
import { MAIN_URL, fetchRequest } from 'utils/fetchRequest'
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import { graduationMbHomeContent } from 'utils/fetchRequest/Urls'
import { useScreenshot } from "use-screenshot-hook";
import message from 'app/modules/message'
import { isArray } from 'lodash';
import 'app/css/mbDiploma.css';

const MbHomeModal = ({
    open = false,
    onClose = () => { },
    school = null,
    content = {},
    onSubmit = () => { }
}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const mbImageRef = useRef(null);
    let { image, takeScreenshot, isLoading, clear } = useScreenshot({ ref: mbImageRef });

    const [homeContent, setHomeContent] = useState(() => EditorState.createEmpty())
    const [submitting, setSubmitting] = useState(false);
    const [diplomaRegistrationNo, setDiplomaRegistrationNo] = useState(content?.diplomaRegistrationNo)


    useEffect(() => {
        const contentState = ContentState.createFromBlockArray(
            convertFromHTML(content?.template).contentBlocks,
            convertFromHTML(content?.template).entityMap
        );
        setHomeContent(() => EditorState.createWithContent(contentState))
    }, [open])

    useEffect(() => {
        if (submitting && image) {
            submitMbHomeModal(content?.id, image)
        }
    }, [image])

    const submitMbHomeModal = (id, base64) => {
        dispatch(setLoading(true));
        const params = {
            school,
            id,
            snapshot: base64
        }
        fetchRequest(graduationMbHomeContent, 'POST', params)
            .then(res => {
                if (res?.success) {
                    onSubmit(res?.data)
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(e => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            });
    }

    return (
        <Modal
            show={open}
            onHide={onClose}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {t('graduation.infoApprovePage')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ overflow: 'auto' }}>
                <div ref={mbImageRef} className="mb-renderer text-center" style={{ width: 1600, height: 1000 }}>
                    <Editor
                        editorState={homeContent}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="diploma-content-container"
                        editorClassName="editorClassName"
                        onEditorStateChange={null}
                        editorStyle={{ fontFamily: 'Urga' }}
                        toolbar={{
                            options: []
                        }}
                        wrapperStyle={{
                            marginRight: 30
                        }}
                        toolbarStyle={{
                            display: 'none'
                        }}
                    />

                    {
                        content?.signatures && content?.signatures.length > 0 && (
                            content?.signatures.map((signatureObj, s) => {
                                if (signatureObj.isHome) {
                                    return <Editor
                                        key={'sign_' + s}
                                        editorState={EditorState.createWithContent(ContentState.createFromBlockArray(
                                            convertFromHTML(signatureObj.mbSignature).contentBlocks,
                                            convertFromHTML(signatureObj.mbSignature).entityMap
                                        ))}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="diploma-content-container text-center"
                                        editorClassName="text-center"
                                        onEditorStateChange={null}
                                        editorStyle={{ fontFamily: 'Urga' }}
                                        toolbar={{
                                            options: []
                                        }}
                                        toolbarStyle={{
                                            display: 'none'
                                        }}
                                    />
                                }

                            })
                        )
                    }

                    <Editor
                        editorState={EditorState.createWithContent(ContentState.createFromBlockArray(
                            convertFromHTML("Folegeebegdo* Hoda                        Birioehe&amp;amp; oa dogf* <span>" + diplomaRegistrationNo + "</span>").contentBlocks,
                            convertFromHTML("Folegeebegdo* Hoda                        Birioehe&amp;amp; oa dogf* <span>" + diplomaRegistrationNo + "</span>").entityMap
                        ))}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="diploma-content-container"
                        editorClassName="editorClassName"
                        onEditorStateChange={null}
                        editorStyle={{ fontFamily: 'Urga' }}
                        toolbar={{
                            options: []
                        }}
                        wrapperStyle={{
                            marginLeft: 30,
                            marginRight: 10,
                        }}
                        toolbarStyle={{
                            display: 'none'
                        }}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className="btn btn-link bolder">
                    {t("common.back").toLocaleUpperCase()}
                </button>

                <button
                    disabled={isLoading}
                    className={'btn btn-success bolder'}
                    onClick={() => {
                        setSubmitting(true)
                        takeScreenshot('jpg', {
                            width: 1600,
                            height: 1000,
                            quality: 1,
                            backgroundColor: 'white'
                        })
                    }}>
                    {"Баталгаажуулах"}
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default MbHomeModal
