import React, { useEffect, useRef } from 'react';
import { Button, Modal } from "react-bootstrap";
import Forms from "../../../modules/Form/Forms";
import { useTranslation } from "react-i18next";
import {useHistory} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import message from "../../../modules/message";
import { stItemEdit } from "../../../../utils/fetchRequest/Urls";

const DiscountAdd = ({
    onClose,
    academicProgramId,
    programId,
    student,
    studentCode,
}) => {
    const { t } = useTranslation();
    const history = useHistory();

    const onSaveClick = () => {
        history.push({
            pathname: '/scholarship',
            state: {
                academicProgramId,
                programId,
                student,
                studentCode,
            }
        });
        
    };

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop='static'
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('scholarship.title')}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Тухайн оюутан 60% хүрч байна, та тэтгэлэг бүртгэх үү?
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className='btn btn-link bolder'>{t('common.back')}</button>
                <Button variant='success btn-shadow' onClick={onSaveClick}>{t('common.register')}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DiscountAdd;