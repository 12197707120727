import React, { useState, useEffect } from 'react';
import { Button, Card } from "react-bootstrap";
import Instruction from "../../modules/instruction";
import { useTranslation } from "react-i18next";
import Checkbox from "../../modules/Form/Checkbox";
import DTable from "../../modules/DataTable/DTable";
import { useDispatch, useSelector } from "react-redux";
import {timetableDelete, timetableEdit, timetableEditPotok, timetableMultiDelete} from "../../../utils/fetchRequest/Urls";
import { setLoading } from "../../../redux/action";
import message from "../../modules/message";
import { fetchRequest } from "../../../utils/fetchRequest";
import TimetableAddModal from "./AddModal";
import {Link, useHistory} from "react-router-dom";
import EditModal from "./EditModal";
import DeleteModal from '../../modules/DeleteModal';
import EditPotokModal from './EditPotokModal';
import TimetableAddPotokModal from './AddPotokModal';

const TimetableAdd = ({
    location: { state :{
        seasonId = null,
        selectedGroupId,
        dayId
    } = {} } = {},
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    const [ shiftId, setShiftId ] = useState(null);
    const [ shiftName, setShiftName ] = useState(null);
    const [ subjectId, setSubjectId ] = useState(null);
    const [ pTimes, setPTimes ] = useState([]);
    const [ otherPTimes, setOtherPTimes ] = useState([]);
    const [ showModal, setShowModal ] = useState(false);
    const [ programs, setPrograms ] = useState([]);
    const [ selectionCount, setSelectionCount ] = useState(0);
    const [ isOnline, setIsOnline ] = useState(false);
    const [ isSelective, setIsSelective ] = useState(false);
    const [ timetables, setTimetables ] = useState([]);
    const [ otherTimetables, setOtherTimetables ] = useState([]);
    const [ editIndex, setEditIndex ] = useState(null);
    const [ groupData, setGroupData ] = useState(null);
    const [ groupId, setGroupId ] = useState(null);
    const [ selectedTimetableId, setSelectedTimetableId ] = useState(null);
    const [ showDeleteModal, setShowDeleteModal ] = useState(false);
    const [ isPotok, setIsPotok ] = useState(false);
    const [ editPotokId, setEditPotokId ] = useState(null);
    const [ selectedPotokIds, setSelectedPotokIds] = useState([]);
    const [ showPotokModal, setShowPotokModal ] = useState(false);
    const [ description, setDescription] = useState('');

    const [ selectedTimetableIds, setSelectedTimetableIds ] = useState(null);
    const [ showMultiDeleteModal, setMultiShowDeleteModal ] = useState(false);

    useEffect(() => {
        const params = {
            school: schoolId,
            season: seasonId,
            group: selectedGroupId,
            dayId: dayId
        };

        editInit(params)
    }, []);

    const editInit = (params) => {
        dispatch(setLoading(true));
        fetchRequest(timetableEdit, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;

                    if(data.timetables.length > 0){
                        if (data?.pTimes?.length) {
                            setPTimes(data.pTimes);
                            setOtherPTimes(data.otherPTimes);
                        }
    
                        setTimetables(data.timetables);
                        setOtherTimetables(data.otherTimetables);
                        if(data?.groupData){
                            setGroupData(data?.groupData);
                            setSubjectId(data?.groupData?.subjectId);
                        }
                        if(data.timetables && data.timetables.length > 0){
                            setShiftId(data.timetables[0].shiftId);
                            setShiftName(data.timetables[0].shiftName);
                            setDescription(data.timetables[0].description);
                        }
    
                        setIsPotok(data?.isPotok || false);
                        setIsSelective(data.isSelective);
                    } else {
                        history.push({
                            pathname: '/timetable',
                        });
                    }
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            });
        dispatch(setLoading(true));
    }

    const handleAddClick = () => {
        setShowModal(true);
    };

    const onModalClose = () => {
        setShowModal(false);
        setShowDeleteModal(false);
        setEditIndex(null);
        setEditPotokId(null);
        setShowPotokModal(false);
    };
    
    const onTimetableSave = timetableData => {
        const newTimetables = [...timetables, ...timetableData];
        setTimetables(newTimetables.map((tt, index) => ({...tt, id: index, index})));
        onModalClose();
    };

    const onEditRowClick = (index) => {
        if(isSelective){
            let selectedIndex = null;
            if(timetables && timetables.length > 0){
                for(let i = 0; i < timetables.length; i++){
                    if(index == timetables[i].id){
                        selectedIndex = i;
                    }
                }
            }

            setEditIndex(selectedIndex);
        } else {
            setEditIndex(index);
        }

    };

    const onRemoveRowClick = ({ id }, array) => {
        if(id){
            setSelectedTimetableId(id);
            setShowDeleteModal(true);
        }
    };

    const onEditPotokRowClick = (potokId) => {
        setEditPotokId(potokId);
    };

    const onRemovePotokRowClick = (potokId) => {
        if(timetables && timetables.length > 0){
            const object = timetables.find(tt => tt.potokId == potokId);

            if(object.timetableId){
                let filterObjects = timetables.filter(tt => tt.potokId == potokId);

                let deleteIds = [];
                if(filterObjects && filterObjects.length > 0){
                    for(let i = 0; i < filterObjects.length; i++){
                        if(filterObjects[i].timetableId){
                            deleteIds.push(filterObjects[i].timetableId)
                        }
                    }

                    if(deleteIds && deleteIds.length > 0){
                        setSelectedTimetableIds(deleteIds)
                        setMultiShowDeleteModal(true)
                    }
                }
            } else {
                let filterObjects = timetables.filter(tt => tt.potokId !== object.potokId);

                if(filterObjects && filterObjects.length > 0){
                    setTimetables(filterObjects.map((tt, index) => ({...tt, id: index, index})));
                } else {
                    setIsPotok(false);
                    setTimetables(filterObjects.map((tt, index) => ({...tt, id: index, index})));
                }
            }
        } else {
            setIsPotok(false);
        }
    };

    const onPotokEditTimetableSave = updated => {
        const newTimetables = [...updated];
        if(timetables && timetables.length > 0){
            let leftTimetable = timetables.filter((timetable) => (timetable.potokId !== newTimetables[0].potokId))

            const mergedArray = [...leftTimetable, ...newTimetables];
            const sortedArray = mergedArray.sort(function(a, b){return a.name-b.name});
            setTimetables(sortedArray);
        }
        onModalClose();
    };

    const getColumns = () => {
        if (isSelective) {
            return [
                {
                    dataField: "dayName",
                    text: t('common.weekday'),
                },
                {
                    dataField: "timeName",
                    text: t('subject.time'),
                },
                {
                    dataField: "teacherName",
                    text: t('subject.teacher'),
                },
                {
                    dataField: "roomName",
                    text: t('school.classroom.title'),
                },
                {
                    dataField: "capacity",
                    text: t('school.classroom.seatCount'),
                    align: 'right',
                },
                {
                    dataField: "seatCount",
                    text: t('timetable.seatCount'),
                    align: 'right',
                },
                {
                    dataField: "timetableTypeName",
                    text: t('subject.studyType'),
                },
                {
                    isDummyField: true,
                    dataField: "actions",
                    text: '',
                    formatExtraData: timetables,
                    style: {padding: 3, width: 90, verticalAlign: 'middle'},
                    formatter: (cell, row, rowIndex, extraData) => {
                        return (
                            <div>
                                <button className='btn btn-primary row-action-button ml-2' style={{ backgroundColor: '#716aca', borderColor: 'transparent' }} onClick={() => onEditRowClick(row.id)}>
                                    <i className='las la-pen' style={{ fontSize: 20 }} />
                                </button>
                                <button className='btn btn-danger row-action-button ml-2' onClick={() => onRemoveRowClick(row, extraData)}>
                                    <i className='las la-times' style={{ fontSize: 20 }} />
                                </button>
                            </div>
                        )
                    }
                },
            ];
        }
        return [
            {
                dataField: "dayName",
                text: t('common.weekday'),
            },
            {
                dataField: "timeName",
                text: t('subject.time'),
            },
            {
                dataField: "pTimeName",
                text: t('subject.type'),
            },
            {
                dataField: "teacherName",
                text: t('subject.teacher'),
            },
            {
                dataField: "roomName",
                text: t('school.classroom.title'),
            },
            {
                dataField: "capacity",
                text: t('school.classroom.seatCount'),
                align: 'right',
            },
            {
                dataField: "seatCount",
                text: t('timetable.seatCount'),
                align: 'right',
            },
            {
                dataField: "timetableTypeName",
                text: t('subject.studyType'),
            },
            {
                isDummyField: true,
                dataField: "actions",
                text: '',
                formatExtraData: timetables,
                style: {padding: 3, width: 90, verticalAlign: 'middle'},
                formatter: (cell, row, rowIndex, extraData) => {
                    return (
                        <div>
                            <button className='btn btn-primary row-action-button ml-3' style={{ backgroundColor: '#716aca', borderColor: 'transparent' }} onClick={() => onEditRowClick(row.index)}>
                                <i className='las la-pen' style={{ fontSize: 20 }} />
                            </button>
                            <button className='btn btn-danger row-action-button ml-3' onClick={() => onRemoveRowClick(row, extraData)}>
                                <i className='las la-times' style={{ fontSize: 20 }} />
                            </button>
                        </div>
                    )
                }
            },
        ];
    };

    const getOtherColumns = () => {
        if (isSelective) {
            return [
                {
                    dataField: "dayName",
                    text: t('common.weekday'),
                },
                {
                    dataField: "timeName",
                    text: t('subject.time'),
                },
                {
                    dataField: "teacherName",
                    text: t('subject.teacher'),
                },
                {
                    dataField: "roomName",
                    text: t('school.classroom.title'),
                },
                {
                    dataField: "capacity",
                    text: t('school.classroom.seatCount'),
                    align: 'right',
                },
                {
                    dataField: "seatCount",
                    text: t('timetable.seatCount'),
                    align: 'right',
                },
                {
                    dataField: "timetableTypeName",
                    text: t('subject.studyType'),
                }
            ];
        }
        return [
            {
                dataField: "dayName",
                text: t('common.weekday'),
            },
            {
                dataField: "timeName",
                text: t('subject.time'),
            },
            {
                dataField: "pTimeName",
                text: t('subject.type'),
            },
            {
                dataField: "teacherName",
                text: t('subject.teacher'),
            },
            {
                dataField: "roomName",
                text: t('school.classroom.title'),
            },
            {
                dataField: "capacity",
                text: t('school.classroom.seatCount'),
                align: 'right',
            },
            {
                dataField: "seatCount",
                text: t('timetable.seatCount'),
                align: 'right',
            },
            {
                dataField: "timetableTypeName",
                text: t('subject.studyType'),
            }
        ];
    };

    const potokColumns = [
        {
            dataField: "dayName",
            text: t('common.weekday'),
        },
        {
            dataField: "timeName",
            text: t('subject.time'),
        },
        {
            dataField: "pTimeName",
            text: t('subject.type'),
        },
        {
            dataField: "teacherName",
            text: t('subject.teacher'),
        },
        {
            dataField: "roomName",
            text: t('school.classroom.title'),
        },
        {
            dataField: "capacity",
            text: t('school.classroom.seatCount'),
            align: 'right',
        },
        {
            dataField: "seatCount",
            text: t('timetable.seatCount'),
            align: 'right',
        },
        {
            dataField: "timetableTypeName",
            text: t('subject.studyType'),
        },
    ];

    const renderTables = () => {
        if(isPotok){
            const groups = [];
            for (const timetable of timetables) {
                if (!groups.includes(timetable.name)) {
                    groups.push(timetable.name)
                }
            }

            return groups.map((group, index) => {
                const data = timetables.filter(tt => tt.name === group);

                const groupByCategory = data.reduce((group, product) => {
                    const { potokId } = product;
                    group[potokId] = group[potokId] ?? [];
                    group[potokId].push(product);
                    return group;
                }, {});

                let renderData = [
                    <div key={'group_potok_' + index}>
                        <b>{group}</b>
                    </div>
                ];

                for( let key in groupByCategory ){
                    renderData.push(
                        <Card
                            key={'potok_' + index + '_' + key}
                            className='mt-3'
                        >
                            <Card.Body>
                                <div className='col-12'>
                                    <div style={{display: 'inline-block'}}>
                                        {groupByCategory[key][0]?.potokName || ''}
                                    </div>
                                    <div style={{display: 'inline-block'}}>
                                        <button className='btn btn-primary row-action-button ml-2' style={{ backgroundColor: '#716aca', borderColor: 'transparent' }} onClick={() => onEditPotokRowClick(key)}>
                                            <i className='las la-pen' style={{ fontSize: 20 }} />
                                        </button>
                                        <button className='btn btn-danger row-action-button ml-2' onClick={() => onRemovePotokRowClick(key)}>
                                            <i className='las la-times' style={{ fontSize: 20 }} />
                                        </button>
                                    </div>
                                </div>
                                <DTable
                                    key={'table_' + index + '_' + key}
                                    data={groupByCategory[key]}
                                    config={{
                                        showPagination: false,
                                        showFilter: false,
                                        showAllData: true,
                                    }}
                                    columns={potokColumns}
                                    selectMode={'radio'}
                                />
                            </Card.Body>
                        </Card>
                    )
                }

                return renderData;
            })
        } else {
            if (isSelective) {
                return pTimes.map(pTime => {
                    const data = timetables.filter(ttable => {
                        return ttable.pTime == pTime.id
                    });
    
                    return (
                        <div key={pTime.id}>
                            <div>{pTime.name}</div>
                            <DTable
                                data={data}
                                config={{
                                    showPagination: false,
                                    showFilter: false,
                                }}
                                columns={getColumns()}
                                selectMode={'radio'}
                            />
                        </div>
                    )
                })
            }
    
            return (
                <div>
                    <div>{timetables[0]?.groupName || ''}</div>
                    <DTable
                        data={timetables}
                        config={{
                            showPagination: false,
                            showFilter: false,
                        }}
                        columns={getColumns()}
                        selectMode={'radio'}
                    />
                </div>
            )
        } 
    };

    const renderOtherTables = () => {
        if(isPotok){
            const groups = [];
            for (const timetable of otherTimetables) {
                if (!groups.includes(timetable.name)) {
                    groups.push(timetable.name)
                }
            }

            return groups.map((group, index) => {
                const data = otherTimetables.filter(tt => tt.name === group);

                const groupByCategory = data.reduce((group, product) => {
                    const { potokId } = product;
                    group[potokId] = group[potokId] ?? [];
                    group[potokId].push(product);
                    return group;
                }, {});

                let renderData = [
                    <div key={'group_potok_' + index}>
                        <b>{group}</b>
                    </div>
                ];

                for( let key in groupByCategory ){
                    renderData.push(
                        <Card
                            key={'potok_' + index + '_' + key}
                            className='mt-3'
                        >
                            <Card.Body>
                                <div className='col-12'>
                                    <div style={{display: 'inline-block'}}>
                                        {groupByCategory[key][0]?.potokName || ''}
                                    </div>
                                    {/* <div style={{display: 'inline-block'}}>
                                        <button className='btn btn-primary row-action-button ml-2' style={{ backgroundColor: '#716aca', borderColor: 'transparent' }} onClick={() => onEditPotokRowClick(key)}>
                                            <i className='las la-pen' style={{ fontSize: 20 }} />
                                        </button>
                                        <button className='btn btn-danger row-action-button ml-2' onClick={() => onRemovePotokRowClick(key)}>
                                            <i className='las la-times' style={{ fontSize: 20 }} />
                                        </button>
                                    </div> */}
                                </div>
                                <DTable
                                    key={'table_' + index + '_' + key}
                                    data={groupByCategory[key]}
                                    config={{
                                        showPagination: false,
                                        showFilter: false,
                                        showAllData: true,
                                    }}
                                    columns={potokColumns}
                                    selectMode={'radio'}
                                />
                            </Card.Body>
                        </Card>
                    )
                }

                return renderData;
            })
        } else {
            if (isSelective) {
                let tables = []
                if(otherPTimes && otherPTimes.length > 0){
                    for(let i = 0; i < otherPTimes.length; i++){
                        let child = otherPTimes[i];
                        
                        for(let c = 0; c < child.length; c++){
                            let tableData = []; 
                            let selectedId = null;
                            for(let t = 0; t < otherTimetables.length; t++){
                                if(child[c].id == otherTimetables[t].pTime){
                                    tableData.push(otherTimetables[t])
                                    selectedId = otherTimetables[t].pTime;
                                }
                            }

                            if(tableData && tableData.length > 0 && child[c].id == selectedId){
                                tables.push(
                                    <div key={child[c].id}>
                                        <div>{child[c].name}</div>
                                        <DTable
                                            data={tableData}
                                            config={{
                                                showPagination: false,
                                                showFilter: false
                                            }}
                                            columns={getOtherColumns()}
                                            selectMode={'radio'}
                                        />
                                    </div>
                                )
                            }
                        }
                        
                        // return otherPTimes[i].map(pTime => {
                        //     const otherData = otherTimetables.filter(ttable => (
                        //         ttable.pTime == pTime.id
                        //     ));
        
                        //     if(otherData && otherData.length > 0){
                        //         return (
                        //             <div key={pTime.id}>
                        //                 <div>{pTime.name}</div>
                        //                 <DTable
                        //                     data={otherData}
                        //                     config={{
                        //                         showPagination: false,
                        //                         showFilter: false,
                        //                     }}
                        //                     columns={getOtherColumns()}
                        //                     selectMode={'radio'}
                        //                 />
                        //             </div>
                        //         )
                        //     }
                        // })
                    }
                }

                return tables
            }

            return (
                <div>
                    <DTable
                        data={otherTimetables}
                        config={{
                            showPagination: false,
                            showFilter: false,
                        }}
                        columns={getOtherColumns()}
                        selectMode={'radio'}
                    />
                </div>
            )
        } 
    };

    const onSaveClick = () => {
        const params = {
            school: schoolId,
            season: seasonId,
            isOnline: isOnline ? 1 : 0,
            isSelective: isSelective ? 1: 0,
            subject: subjectId,
            schoolShift: shiftId,
            dayId: dayId,
            description: description,
        };

        if(isPotok){
            const groups = [];

            for (const timetable of timetables) {
                if (!groups.includes(timetable.name)) {
                    groups.push(timetable.name)
                }
            }

            const timetableData = groups.map(group => {
                const data = timetables.filter(tt => tt.name === group);

                return {
                    name: group,
                    datas: data.map(tt => {
                        return {
                            timetableId: tt.timetableId ? tt.timetableId : null,
                            group: tt.groupId,
                            teacher: tt.teacher,
                            day: tt.day,
                            time: tt.time,
                            pTime: tt.pTime,
                            room: tt.room,
                            potokId: tt.potokId,
                            timetableType: tt.timetableType,
                            seatCount: tt.seatCount,
                            isMain: tt.isMain,
                            isGroup: tt.isGroup ? 1 : 0,
                        }
                    })
                }
            });

            params.timetables = JSON.stringify(timetableData);
            if(timetables && timetables.length > 0){
                params.max = timetables[0]?.seatCount;
            }

            dispatch(setLoading(true));
            fetchRequest(timetableEditPotok, 'POST', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res;

                        history.push({
                            pathname: '/timetable',
                        });
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'));
                })
        } else {
            if (isSelective) {
                const timetableData = [];
                for (const pTime of pTimes) {
                    const data = timetables.filter(tt => tt.pTime === pTime.id);
                    if (data?.length) {
                        timetableData.push({
                            groupId: data[0]?.groupId || null,
                            pTimeId: pTime.id,
                            datas: data.map(tt => {
                                return {
                                    groupId: tt.groupId ? tt.groupId : null,
                                    timetableId: tt.timetableId ? tt.timetableId : null,
                                    day: tt.day,
                                    time: tt.time,
                                    teacher: tt.teacher,
                                    room: tt.room,
                                    timetableType: tt.timetableType,
                                    seatCount: tt.seatCount
                                }
                            })
                        })
                    }
                }
                params.timetables = JSON.stringify(timetableData);
            } else {
                const groups = [];
                let tts = [];
    
                if(timetables[0].timetables){
                    tts = timetables[0].timetables;
                }
                else{
                    tts = timetables;
                }
                
                for (const timetable of tts) {
                    let isNew = true;
                    for(const group of groups){
                        if(group.id === timetable.groupId && group.name === timetable.name){
                            isNew = false;
                            break;
                        }
                    }

                    if(isNew){
                        groups.push({
                            'id': timetable.groupId,
                            'name': timetable.name
                        })
                    }
                }   
                
                const timetableData = groups.map(group => {
                    const data = timetables.filter(tt => tt.name === group.name && tt.groupId === group.id);
                    let index = 0;
                    return {
                        groupId: group.id,
                        name: groupData ? groupData.name : group.name,
                        teacher: data?.[0]?.teacher,
                        
                        datas: data.map(tt => {
                            let ttid = null;
                            if(tt.timetables){
                                for(const i of tt.timetables){
                                    //if(tt.index == i.index + tt.timetables.length){
                                    if(tt.index == i.index){
                                        ttid = i.timetableId;
                                        break;
                                    }
                                }
                            }
                            else{
                                ttid = tt.timetableId;
                            }
                            
                            return {
                                timetableId: ttid,
                                teacher: tt.teacher,
                                day: tt.day,
                                time: tt.time,
                                pTime: tt.pTime,
                                room: tt.room,
                                seatCount: tt.seatCount,
                                timetableType: tt.timetableType,
                            }
                        })
                    }
                });
    
                params.timetables = JSON.stringify(timetableData);
            }

            dispatch(setLoading(true));
            fetchRequest(timetableEdit, 'POST', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res;
                        history.push({
                            pathname: '/timetable',
                        });
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'));
                })
        }
    };

    const onEditSave = (updated) => {
        setTimetables(updated);
        onModalClose()
    };

    const onDelete = () => {
        let params = {
            school: schoolId,
            timetable: selectedTimetableId,
        };

        dispatch(setLoading(true));
        fetchRequest(timetableDelete, 'POST', params)
            .then(response => {
                if (response.success) {
                    const initParams = {
                        school: schoolId,
                        group: selectedGroupId,
                        dayId: dayId
                    };

                    editInit(initParams);
                    setShowDeleteModal(false);
                    message(response?.data?.message, true)
                } else {
                    message(response?.data?.message, false)
                }
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            });
    };

    const onMultiDelete = () => {
        let params = {
            school: schoolId,
            timetableIds: JSON.stringify(selectedTimetableIds),
        };

        dispatch(setLoading(true));
        fetchRequest(timetableMultiDelete, 'POST', params)
            .then(response => {
                if (response.success) {
                    const initParams = {
                        school: schoolId,
                        group: selectedGroupId,
                        dayId: dayId
                    };

                    editInit(initParams);
                    setMultiShowDeleteModal(false);
                    message(response?.data?.message, true)
                } else {
                    message(response?.data?.message, false)
                }
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            });
    };

    const onIsOnlineChange = () => {
        setIsOnline(!isOnline);
    };

    const handlePotokClick = () => {
        if(timetables && timetables.length > 0){
            let cloneSelectedPotokIds = [...selectedPotokIds];

            for(let i = 0; i < timetables.length; i++){
                if(cloneSelectedPotokIds && cloneSelectedPotokIds.length > 0){
                    if(!cloneSelectedPotokIds.includes(timetables[i].potokId)){
                        cloneSelectedPotokIds.push(timetables[i].potokId)
                    }
                } else {
                    cloneSelectedPotokIds.push(timetables[i].potokId)
                }
            }

            setSelectedPotokIds(cloneSelectedPotokIds)
        }
        setShowPotokModal(true);
    };

    const onPotokTimetableSave = timetableData => {
        if(timetables && timetables.length > 0){
            let error = false;

            for(let i = 0; i < timetables.length; i++){
                if(parseInt(timetables[i].potokId) == parseInt(timetableData[0].potokId)){
                    error = true;
                }
            }

            if(error){
                message('Тухайн бүлэг бүртгэгдсэн байна, та засах дарж орж засна уу')
            } else {
                const newTimetables = [...timetables, ...timetableData];
                setTimetables(newTimetables.map((tt, index) => ({...tt, id: index, index})));
                setIsPotok(true);
                onModalClose();
            }
        } else {
            const newTimetables = [...timetables, ...timetableData];
            setTimetables(newTimetables.map((tt, index) => ({...tt, id: index, index})));
            setIsPotok(true);
            onModalClose();
        }
    };

    const onDescriptionChange = (e) => {
        setDescription(e.target.value)
    };

    return (
        <Card>
            <Card.Body className={'timetable-add'}>
                <div style={{ textAlign: 'right' }}>
                    <Instruction/>
                </div>
                <div className='row' style={{ display: 'flex', alignItems: 'center' }}>
                    <div className='col-3 text-right'>
                        {t('subject.title') + '*'}
                    </div>
                    <div className='col-9'>
                        <span>
                            {
                                groupData && groupData.subjectName + ' (' + groupData.subjectCode + ')'
                            }
                        </span>
                    </div>
                </div>
                {
                    <div className='mt-3 row' style={{ display: 'flex', alignItems: 'center' }}>
                        <div className='col-3 text-right'>
                            {t('school.schoolShift') + '*'}
                        </div>
                        <div className='col-9'>
                            <span>
                                {
                                    shiftName || ''
                                }
                            </span>
                        </div>
                    </div>
                }
                <div className='mt-3 row' style={{ display: 'flex', alignItems: 'center' }}>
                    <div className='col-4 text-right'>
                        Тайлбар
                    </div>
                    <div className='col-8'>
                        <textarea
                            style={{border: '1px solid #CCCCCC', borderRadius: 4}}
                            className={'w-250'}
                            type='text'
                            onChange={(e) => onDescriptionChange(e)}
                            value={description}
                        />
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-3'/>
                    <div className='col-9'>
                        Сонголт №1 <span style={{color: '#026bca', fontWeight: 900}}>{selectionCount}</span>
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-3'/>
                    <div className='col-9'>
                        {
                            programs.map((program, index) => {
                                const className = index > 0 ? 'timetable-add-subject-programs mt-3' : 'timetable-add-subject-programs';
                                return (
                                    <table key={index} className={className}>
                                        <tbody>
                                            <tr>
                                                <td>{t('curriculum.code')}</td>
                                                <th>{program.code || '-'}</th>
                                            </tr>
                                            <tr>
                                                <td>{t('curriculum.name')}</td>
                                                <th>{program.name || '-'}</th>
                                            </tr>
                                            <tr>
                                                <td>{t('curriculum.profession')}</td>
                                                <th>{program.academicProgramName || '-'}</th>
                                            </tr>
                                        </tbody>
                                    </table>
                                )
                            })
                        }
                    </div>
                </div>
                <div className='row mt-3'>
                   <div className='col-3'/>
                   <div className='col-9'>
                       <Checkbox
                            checked={isOnline}
                            label={t('timetable.isOnline')}
                            onChange={onIsOnlineChange}
                        />
                    </div>
                </div>
                {
                    isSelective &&
                    <button
                        className='btn btn-primary mb-3'
                        onClick={handleAddClick}
                    >
                        {t('common.add')}
                    </button>
                }
                {
                    subjectId && isPotok
                    ?
                        <button
                            className='btn btn-primary mb-3 ml-2'
                            onClick={handlePotokClick}
                        >
                            Поток оруулах
                        </button>
                    : null
                }
                {
                    renderTables()
                }
                {
                    otherTimetables && otherTimetables.length > 0 &&
                    renderOtherTables()
                }
            </Card.Body>
            <Card.Footer>
                <div className='text-center'>
                    <Link
                        className='btn btn-link bolder'
                        to='/timetable'
                    >
                        {t('common.back')}
                    </Link>
                    <Button variant='success btn-shadow' onClick={onSaveClick}>
                        {t('common.save')}
                    </Button>
                </div>
            </Card.Footer>
            {
                showModal && <TimetableAddModal
                    onClose={onModalClose}
                    isSelective={isSelective}
                    subject={subjectId}
                    shift={shiftId}
                    onSave={onTimetableSave}
                />
            }
            {
                showPotokModal && <TimetableAddPotokModal
                    onClose={onModalClose}
                    isSelective={isSelective}
                    subject={subjectId}
                    shift={shiftId}
                    seasonId={seasonId}
                    onSave={onPotokTimetableSave}
                    selectedPotokIds={selectedPotokIds}
                    groupData={groupData}
                    isEdit={true}
                />
            }
            {
                editIndex !== null && (
                    <EditModal
                        onClose={onModalClose}
                        isSelective={isSelective}
                        subject={subjectId}
                        shift={shiftId}
                        onSave={onEditSave}
                        timetables={timetables}
                        index={editIndex}
                        groupId={groupId}
                        groupData={groupData}
                    />
                )
            }
            {
                showDeleteModal && selectedTimetableId && (
                    <DeleteModal
                        onClose={onModalClose}
                        onDelete={onDelete}
                        title={t('warning.delete')}>
                        {t('warning.delete_confirmation')}
                        <br />
                        <br />
                        {t('warning.delete_confirmation_description')}
                    </DeleteModal>
                )
            }
            {
                showMultiDeleteModal && selectedTimetableIds && (
                    <DeleteModal
                        onClose={onModalClose}
                        onDelete={onMultiDelete}
                        title={t('warning.delete')}>
                        {t('warning.delete_confirmation')}
                        <br />
                        <br />
                        {t('warning.delete_confirmation_description')}
                    </DeleteModal>
                )
            }
            {
                editPotokId !== null && (
                    <EditPotokModal
                        onClose={onModalClose}
                        isSelective={isSelective}
                        subject={subjectId}
                        shift={shiftId}
                        onSave={onPotokEditTimetableSave}
                        timetables={timetables}
                        editPotokId={editPotokId}
                        name={groupData.name}
                    />
                )
            }
        </Card>
    );
};

export default TimetableAdd;