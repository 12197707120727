import React, {useEffect, useState} from 'react'
import DTable from '../../../modules/DataTable/DTable'
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {setLoading} from "../../../../redux/action";
import {fetchRequest} from "../../../../utils/fetchRequest";
import {
    getJournalDetail,
    journalTopicDelete,
    journalTopicSave,
    journalTopicEdit
} from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import AddTopic from "./addTopic"
import {Button, Modal} from "react-bootstrap";

const Topic = ({
    timetableId
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    const [addModal, setAddModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [topics, setTopics] = useState([]);
    const [topicId, setTopicId] = useState(null);

    useEffect(() => {
        let params = {
            school: schoolId,
            timetable: timetableId,
            tab: 'TOPIC'
        };
        init(params)
    }, []);

    const init = (params) => {
        dispatch(setLoading(true));
        fetchRequest(getJournalDetail, 'GET', params)
            .then(res => {
                dispatch(setLoading(false));
                if (res.success) {
                    const { data } = res;
                    let topics = data.topics;
                    if(topics && topics.length > 0){
                        for(let i = 0; i < topics.length; i++){
                            if(topics[i].createdDate){
                                topics[i].createdDate = topics[i].createdDate.date.substring(0, 19);
                            }
                            if(topics[i].date){
                                topics[i].date = topics[i].date.date.substring(0, 10);
                            }
                        }
                    }
                    setTopics(topics);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const config = {
        showPagination: false,
        showFilter: true,
        showAllData: true
    };

    const columns = [
        {
            dataField: 'weekName',
            text: t('teacher.week'),
            sort: true,
            headerStyle: () => {
                return {
                    width: 180
                }
            },
        },
        {
            dataField: 'date',
            text: t('common.date'),
            sort: true,
            style: {textAlign: 'right'},
            headerStyle: () => {
                return {
                    width: 150
                }
            },
        },
        {
            dataField: 'topicName',
            text: t('teacher.topic'),
            sort: true,
        },
        {
            dataField: 'time',
            text: t('common.time'),
            sort: true,
        },
    ];

    const contextMenus = [
        {
            key: 'edit',
            title: t('common.edit'),
            icon: <i className='las la-edit dt-cm-item-icon'/>
        },
        {
            key: 'delete',
            title: t('common.delete'),
            icon: <i className='las la-trash-alt dt-cm-item-icon' />,
        },
    ];

    const handleContextMenuClick = (id, key) => {
        if (id && key) {
            if (key === 'edit') {
                setAddModal(true)
            } else if (key === 'delete'){
                setDeleteModal(true);
            }
            setTopicId(id);
        }
    };

    const handlerAddModal = () => {
        setAddModal(true);
        setTopicId(null);
    };

    const closeAddModal = () => {
        setAddModal(false)
    };

    const handleDeleteModalClose = () => {
        setDeleteModal(false);
        setTopicId(null);
    };

    const handlerOnDeleteClick = () => {
        const params = {
            school: schoolId,
            timetable: timetableId,
            topic: topicId,
        };

        dispatch(setLoading(true));
        fetchRequest(journalTopicDelete, 'POST', params, false)
            .then(response => {
                if (response.success) {
                    const { data } = response;
                    let topics = data.topics;
                    if(topics && topics.length > 0){
                        for(let i = 0; i < topics.length; i++){
                            if(topics[i].createdDate){
                                topics[i].createdDate = topics[i].createdDate.date.substring(0, 19);
                            }
                            if(topics[i].date){
                                topics[i].date = topics[i].date.date.substring(0, 10);
                            }
                        }
                    }
                    setTopics(topics);
                    setDeleteModal(false);
                    message(response.data.message, true);
                } else {
                    message(response.data.message, false);
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const onSubmit = params => {
        const bodyParams = {
            school: schoolId,
            timetable: timetableId,
            ...params,
        };

        dispatch(setLoading(true));
        fetchRequest(topicId ? journalTopicEdit : journalTopicSave, 'POST', bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    message(data?.message || t('common.success'), true);
                    let topics = data.topics;
                    if(topics && topics.length > 0){
                        for(let i = 0; i < topics.length; i++){
                            if(topics[i].createdDate){
                                topics[i].createdDate = topics[i].createdDate.date.substring(0, 19);
                            }
                            if(topics[i].date){
                                topics[i].date = topics[i].date.date.substring(0, 10);
                            }
                        }
                    }
                    setTopics(topics);
                    setAddModal(false);
                    setTopicId(null);
                } else {
                    message(res?.data?.message || t('errorMessage.title'));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    return (
        <div className='sm-container'>
            <div className='row'>
                <div className='col-12'>
                    <button className='btn btn-primary btn-shadow' onClick={handlerAddModal}>{t('teacher.addTopic')}</button>
                </div>
                <div className='col-12'>
                    <DTable
                        data={topics}
                        columns={columns}
                        config={config}
                        remote
                        contextMenus={contextMenus}
                        onContextMenuItemClick={handleContextMenuClick}
                        selectMode={'radio'}
                    />
                </div>
            </div>
            {
                addModal &&
                <AddTopic
                    timetableId={timetableId}
                    topicId={topicId}
                    onClose={closeAddModal}
                    onSubmit={onSubmit}
                />
            }
            <Modal
                show={deleteModal}
                onHide={handleDeleteModalClose}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t('warning.delete')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {t('warning.delete_confirmation')}
                        <br/>
                        <br/>
                        {t('warning.delete_confirmation_description')}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="btn btn-link bolder" onClick={handleDeleteModalClose}>{t('common.cancel')}</Button>
                    <Button variant="success btn-shadow" onClick={handlerOnDeleteClick}>{t('warning.delete')}</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
};

export default Topic;