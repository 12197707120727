import React, {useRef, useEffect, useState} from 'react';
import {Button, Card, Tab, Tabs} from "react-bootstrap";
import Forms from "../../../../modules/Form/Forms";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {regulationEdit} from "../../../../../utils/fetchRequest/Urls";
import { setLoading } from "../../../../../redux/action";
import { fetchRequest } from "../../../../../utils/fetchRequest";
import message from "../../../../modules/message";
import {Link, useLocation} from "react-router-dom";
import {Information} from "./information";
import {Content} from "./content";
import {Topic} from "./topic";
import {Homework} from "./homework";
import {Discussion} from "./discussion";
import {Students} from "./students";
import {Result} from "./result";

const style = {
    cardHeader: {
        zIndex: 100
    }
}

export const TeacherOnlineLessonEdit = ({
    onSubmit,
}) => {
    const { t } = useTranslation();
    const formRef = useRef();
    const dispatch = useDispatch();
    const location = useLocation();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [regulationData, setRegulationData] = useState([]);
    const [tabKey, setTabKey] = useState('')
    const [tabIndex] = useState('teacher_online_lesson_tab_index');
    const [ courseId ] = useState(location.state && location.state.courseId ? location.state.courseId : null);

    useEffect(() => {
        let selectedTabIndex = localStorage.getItem(tabIndex);
        if(selectedTabIndex){
            setTabKey(selectedTabIndex);
        } else {
            setTabKey('content');
        }
    }, []);

    const handleTabChange = key => {
        if (key !== tabKey) {
            setTabKey(key);
        }

        localStorage.setItem(tabIndex, key);
    };

    const renderTabs = () => {
        if (tabKey === 'information') {
            return (
                <Information
                    id={courseId}
                />
            )
        } else if (tabKey === 'content'){
            return (
                <Content
                    id={courseId}
                />
            )
        } else if (tabKey === 'topic'){
            return (
                <Topic
                    id={courseId}
                />
            )
        } else if (tabKey === 'homework'){
            return (
                <Homework
                    id={courseId}
                />
            )
        } else if (tabKey === 'discussion'){
            return (
                <Discussion
                    id={courseId}
                />
            )
        } else if (tabKey === 'students'){
            return (
                <Students
                    id={courseId}
                />
            )
        } else if (tabKey === 'result'){
            return (
                <Result
                    id={courseId}
                />
            )
        }
    };

    const handlerOnSaveClick = () => {
        const [ isValid, states, values ] = formRef.current.validate();
        if (isValid) {
            const params = {
                ...values,
            };
            onSubmit(params);
        }
    };

    return (
        <div className='sm-container'>
            <Card style={style.cardHeader}>
                <Card.Header>
                    <Tabs
                        activeKey={tabKey}
                        className='msue-tab'
                        onSelect={handleTabChange}
                    >
                        <Tab eventKey={'information'} title={t('menu.generalInformation')}/>
                        <Tab eventKey={'content'} title={t('teacher.content')}/>
                        <Tab eventKey={'topic'} title={t('teacher.topic')}/>
                        <Tab eventKey={'homework'} title={t('teacher.homework')}/>
                        <Tab eventKey={'discussion'} title={t('teacher.discussion')}/>
                        <Tab eventKey={'students'} title={t('teacher.students')}/>
                        <Tab eventKey={'result'} title={t('teacher.result')}/>
                    </Tabs>
                </Card.Header>
            </Card>
            {renderTabs()}
        </div>
    );
};