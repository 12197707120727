import React, {useRef, useEffect, useState} from 'react';
import {Button, ButtonToolbar, Card, Row, Col, Image} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {teacherOnlineLessonNewsfeedView} from "../../../../../utils/fetchRequest/Urls";
import { setLoading } from "../../../../../redux/action";
import { fetchRequest } from "../../../../../utils/fetchRequest";
import message from "../../../../modules/message";
import {Link, useHistory} from "react-router-dom";
import RenderTableList from "../../../../modules/RenderTableList";
import {toAbsoluteUrl} from "../../../../../_metronic/_helpers";

const style = {
    imgResponsive: {
        backgroundColor: '#ffffff',
        border: '1px solid #E5EAEE',
        borderRadius: '0.42rem',
        boxShadow: '0 1px 2px rgb(0 0 0 / 8%)',
        maxWidth: '100%',
        height: 'auto',
    }
}

export const TeacherOnlineLessonEditDiscussionView = ({
    location: {state: {id = null, newId = null} = {}} = {},
}) => {
    const { t } = useTranslation();
    const formRef = useRef();
    const history = useHistory();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [labels, setLabels] = useState([]);
    const [discussions, setDiscussions] = useState([]);

    useEffect(() => {
        const params = {
            school: schoolId,
            course: id,
            new: newId
        };

        dispatch(setLoading(true));
        fetchRequest(teacherOnlineLessonNewsfeedView, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setDiscussions(data?.discussion || []);

                    let labelArray = [];
                    labelArray.push(
                        {
                            code: 'header',
                            name: t('discussion.header'),
                            value: data?.newsData?.name || ''
                        },
                        {
                            code: 'description',
                            name: t('discussion.description'),
                            value: data?.newsData?.body || '',
                            type: 'html'
                        },
                        {
                            code: 'date',
                            name: t('common.date'),
                            value: data?.newsData?.duration || ''
                        },
                    );
                    setLabels(labelArray);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }, []);

    return (
        <div className='sm-container'>
            <Card>
                <Card.Header>
                    <span className='french-blue fs-10'>
                        <b>{t('discussion.title').toUpperCase()}</b>
                    </span>
                    <div style={{float: 'right'}}>
                        <Link
                            to={{
                                pathname: '/teacher/online/lesson-edit',
                                state: {
                                    courseId: id,
                                }
                            }}
                            className="btn btn-link bolder"
                        >
                            {t('common.back')}
                        </Link>
                    </div>
                </Card.Header>
                <Card.Body>
                    <Card>
                        <Card.Body>
                            <RenderTableList
                                stringArray={labels}
                                leftStyle={{width: 200, verticalAlign: 'top'}}
                            />
                        </Card.Body>
                    </Card>

                    <Card className='mt-4'>
                        <Card.Header>
                            <span className='french-blue fs-10'>
                                <b>{t('discussion.students').toUpperCase()}</b>
                            </span>
                        </Card.Header>
                        <Card.Body>
                            {
                                discussions && discussions.length > 0 &&
                                discussions.map((discussion, index) => {
                                    return (
                                        <Card key={'discussion_' + index} className='mb-3'>
                                            <Card.Body>
                                                <div className="d-flex align-items-center mb-2">
                                                    <div className="symbol symbol-50 mr-5">
                                                    {
                                                        discussion && discussion.studentPhoto 
                                                        ?
                                                            <span className="symbol-label">
                                                                <img 
                                                                    style={style.imgResponsive} 
                                                                    width={50}
                                                                    height={55} 
                                                                    src={discussion.studentPhoto} alt='avatar'
                                                                />
                                                            </span>
                                                        : 
                                                            <span className="symbol-label">
                                                                <img 
                                                                    style={style.imgResponsive} 
                                                                    src={toAbsoluteUrl("/media/users/avatar_male.png")} 
                                                                    alt='avatar'
                                                                    width={50}
                                                                    height={55} 
                                                                />
                                                            </span>
                                                    }
                                                    </div>
                                                    <div className="d-flex flex-column flex-grow-1 line-height-md">
                                                        <span>{discussion.studentCode}</span>
                                                        <span className="text-dark text-hover-primary">
                                                            <b>{discussion.lastName + ', ' + discussion.firstName}</b>
                                                        </span>
                                                        <span>{discussion.publishedDate}</span>
                                                    </div>
                                                </div>
                                                <div className='mt-4'>
                                                    {discussion.description}
                                                </div>
                                                {
                                                    discussion.comments && discussion.comments.length > 0 && 
                                                    <div className='w-100 my-3'>
                                                        {
                                                            discussion.comments.map((comment, cIndex) => {
                                                                return (
                                                                    <Row key={'discussion_' + index + '_comment_' + cIndex} className='align-items-center'>
                                                                        {
                                                                            cIndex == 0
                                                                            ? 
                                                                                <Col md={12} className="w-100">
                                                                                    <div className="separator separator-dashed my-2"></div>
                                                                                </Col>
                                                                            : null
                                                                        }
                                                                        <Col md={1} className="w-100 text-right pr-0">
                                                                            {
                                                                                comment && comment.studentPhoto 
                                                                                ?
                                                                                    <Image
                                                                                        src={comment.studentPhoto}
                                                                                        width={40}
                                                                                        height={45}
                                                                                        style={{borderRadius: '50%', objectFit: 'fill'}}
                                                                                    />
                                                                                : 
                                                                                    <Image
                                                                                        src={toAbsoluteUrl("/media/users/avatar_male.png")}
                                                                                        width={40}
                                                                                        height={45}
                                                                                        style={{borderRadius: '50%', objectFit: 'fill'}}
                                                                                    />
                                                                            }
                                                                        </Col>
                                                                        <Col md={10} className="w-100">
                                                                            <div className='w-100'>
                                                                                <span>
                                                                                    <b>{comment.lastName + ', ' + comment.firstName}</b>
                                                                                </span>
                                                                            </div>
                                                                            <div className='w-100'>
                                                                                <span>{comment?.publishedDate || ''}</span>
                                                                            </div>
                                                                            
                                                                        </Col>
                                                                        <Col md={12} className="w-100 mt-2">
                                                                            <span style={{marginLeft: 25}}>{comment.description}</span>
                                                                        </Col>
                                                                        <Col md={12} className="w-100">
                                                                            <div className="separator separator-dashed my-2"></div>
                                                                        </Col>
                                                                    </Row>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                }
                                            </Card.Body>
                                        </Card>
                                    )
                                })
                            }
                        </Card.Body>
                    </Card>
                </Card.Body>
                <Card.Footer>
                    <div className='col-11 text-center'>
                        <Link
                            to={{
                                pathname: '/teacher/online/lesson-edit',
                                state: {
                                    courseId: id,
                                }
                            }}
                            className="btn btn-link bolder"
                        >
                            {t('common.back')}
                        </Link>
                    </div>
                </Card.Footer>
            </Card>

        </div>
    );
};