import React, { useEffect, useRef } from 'react';
import { Button, Modal } from "react-bootstrap";
import Forms from "../../../modules/Form/Forms";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { examTypeConfigEdit } from "../../../../utils/fetchRequest/Urls";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import message from "../../../modules/message";

const ExamTypeConfigAdd = ({
    onClose,
    onSubmit,
    seasonName,
    options,
    id
}) => {
    const { t } = useTranslation();
    const forms = useRef();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    useEffect(() => {
        forms?.current?.updateFields && forms.current.updateFields(fields);
    }, [ seasonName, options ]);

    useEffect(() => {
        if (id) {
            const params = {
                school: schoolId,
                season: id
            }
            dispatch(setLoading(true));
            fetchRequest(examTypeConfigEdit, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res;

                        if (data?.selectedTypes?.length) {
                            const examTypes = data.selectedTypes;

                            if (examTypes) {
                                forms?.current?.updateFields && forms.current.updateFields([
                                    {
                                        label: t('subject.season'),
                                        value: seasonName,
                                        type: 'text',
                                        inputWidth: 200,
                                        labelWidth: 300,
                                        required: true,
                                        disabled: true,
                                    },
                                    {
                                        label: t('examType.title'),
                                        value: examTypes,
                                        type: 'dropdown',
                                        inputWidth: 200,
                                        labelWidth: 300,
                                        required: true,
                                        key: 'examTypes',
                                        options: options,
                                        searchable: true,
                                        multiple: true,
                                    },
                                ]);
                            }
                        }
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        }
    }, []);

    const fields = [
        {
            label: t('subject.season'),
            value: seasonName,
            type: 'text',
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            disabled: true,
        },
        {
            label: t('examType.title'),
            value: [],
            type: 'dropdown',
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            key: 'examTypes',
            options: options,
            searchable: true,
            multiple: true,
        },
    ];

    const onSaveClick = () => {
        const [ isValid, , values ] = forms.current.validate();
        if (isValid) {
            onSubmit(values);
        }
    }

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('examType.title').toUpperCase()}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Forms
                    ref={forms}
                    fields={fields}
                />
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className='btn btn-link bolder'>{t('common.back')}</button>
                <Button variant='success btn-shadow' onClick={onSaveClick}>{t('common.save')}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ExamTypeConfigAdd;