import React,{ useState,useRef,useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import Forms from '../../../modules/Form/Forms'
import {useTranslation} from "react-i18next";
import {setLoading} from "../../../../redux/action";
import {fetchRequest} from "../../../../utils/fetchRequest";
import {studentRequestView} from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import {useDispatch, useSelector} from "react-redux";
export const ViewModal = ({
    requestId,
    close,
    onSubmit
}) => {
    const formRef = useRef();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [request, setRequest] = useState(null);
    const [requestLogs, setRequestLogs] = useState([]);

    useEffect(() => {
        let params = {
            school: schoolId,
            id: requestId
        };

        dispatch(setLoading(true));
        fetchRequest(studentRequestView, 'GET', params)
            .then(res => {
                dispatch(setLoading(false));
                if (res.success) {
                    const data = res.data;
                    setRequest(data?.request || null);
                    setRequestLogs(data?.logs || []);
                } else {
                    message(res?.data?.message || t('errorMessage.title'));
                }
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            })
    }, []);

    useEffect(() => {
        formRef?.current?.updateFields && formRef.current?.updateFields(fields);
    }, []);

    const fields = [
        {
            key: 'requestType',
            label: 'Хүсэлтийн төрөл',
            value: '',
            type: 'dropdown',
            // options: requestTypes,
            inputWidth: '180px',
            labelWidth: '30%',
            required: true,
            clearable: true
        },
        {
            key: 'content',
            label: 'Шалтгаан',
            value: '',
            type: 'textArea',
            inputWidth: '300px',
            labelWidth: '30%',
            rows: 5,
            required: true,
        },
        {
            key: 'contact',
            label: 'Утасны дугаар',
            value: '',
            type: 'numberOnly',
            inputWidth: '220px',
            labelWidth: '30%',
            inputClassName: 'gray-background-phone-number-input',
            required: true,
            disabled: false
        },
    ];

    const onSave = () =>{
        const [isValid, states, values] = formRef.current.validate();
        if(isValid){
            const params = {
                ...values
            };

            onSubmit(params);
        }
    };

    const renderStatus = (statusId, isComplete) => {
        let selectedLog = null;
        if(statusId){
            if(requestLogs && requestLogs.length > 0){
                for(let i = 0; i < requestLogs.length; i++){
                    if(statusId == requestLogs[i].toStatusId){
                        selectedLog = requestLogs[i];
                    }
                }
            }
        }

        return selectedLog ?
            <span
                style={isComplete ? {padding: '5px 10px', color: 'white', background: '#299f72', borderRadius: 8} : {padding: '5px 10px', background: '#d8d8d8', borderRadius: 8}}
            >
                {selectedLog.toStatus}
            </span> : '';
    };

    const renderDescription = (statusId) => {
        let selectedLog = null;
        if(statusId){
            if(requestLogs && requestLogs.length > 0){
                for(let i = 0; i < requestLogs.length; i++){
                    if(statusId == requestLogs[i].toStatusId){
                        selectedLog = requestLogs[i];
                    }
                }
            }
        }

        return selectedLog ?
            <span>
                {selectedLog.description}
            </span> : '';
    };

    return (
        <Modal
            show={true}
            onHide={close}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('common.view').toUpperCase()}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='row justify-content-center'>
                    <div className='col-2'/>
                    <div className='col-8'>
                        <table style={{lineHeight: 2}}>
                            <thead>
                                <tr className='pt-3'>
                                    <th className='text-right' style={{width: 150}}>{t('common.status')}</th>
                                    <td className='pl-3'>{request ? renderStatus(request.statusId, request.isComplete) : ''}</td>
                                </tr>
                                <tr className='pt-5'>
                                    <th className='text-right' style={{verticalAlign: "top"}}>{request ? t('common.requestType') : ''}</th>
                                    <td className='pl-3'>{request ? request.requestType : ''}</td>
                                </tr>
                                {
                                    request && request.isComplete
                                    ?
                                        <tr className='pt-5'>
                                            <th className='text-right' style={{verticalAlign: "top"}}>{t('common.answer')}</th>
                                            <td className='pl-3'>{request ? renderDescription(request.statusId) : ''}</td>
                                        </tr>
                                    :
                                        <>
                                            <tr className='pt-5'>
                                                <th className='text-right' style={{verticalAlign: "top"}}>{request ? t('common.cause') : ''}</th>
                                                <td className='pl-3'>{request ? request.content : ''}</td>
                                            </tr>
                                        </>
                                }
                            </thead>
                        </table>
                    </div>
                    <div className='col-2'/>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className='btn btn-link' onClick={close}>{t('common.back')}</button>
            </Modal.Footer>
        </Modal>
    )
};
