import React,  { useState, useEffect } from 'react';
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DTable from "../../../modules/DataTable/DTable";
import { fetchRequest } from "../../../../utils/fetchRequest";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../../redux/action";
import {
    regulationTypes,
    regulationTypeAdd,
    regulationTypeEdit,
    regulationTypeDelete,
} from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import ManualSettingAdd from "./ManualSettingAdd";
import DeleteModal from "../../../modules/DeleteModal";

const ManualSettings = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [ showModal, setShowModal ] = useState(false);
    const [ manualTypes, setManualTypes ] = useState([]);
    const [ typeId, setTypeId ] = useState(null);
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    useEffect(() => {
        let params = {
            school: schoolId
        };

        dispatch(setLoading(true));
        fetchRequest(regulationTypes, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setManualTypes(data?.types || []);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            });
    }, []);

    const config = {
        showPagination: false,
        showFilter: false,
        showAllData: true
    };

    const columns = [
        {
            dataField: "code",
            text: t('common.code'),
        },
        {
            dataField: "name",
            text: t('common.name'),
        },
        {
            dataField: "ordering",
            text: t('common.ordering'),
            style: {textAlign: 'right'},
        },
    ];

    const contextMenus = [
        {
            key: 'edit',
            title: t('common.edit'),
            icon: <i className='las la-edit dt-cm-item-icon'/>
        },
        {
            key: 'delete',
            title: t('common.delete'),
            icon: <i className='las la-trash-alt dt-cm-item-icon' />,
        },
    ];

    const handleContextMenuClick = (id, key) => {
        if (id && key) {
            setTypeId(id);
            if (key === 'edit') {
                setShowModal(true);
            }
        }
    };

    const onModalClose = () => {
        setShowModal(false);
        setTypeId(null);
    };

    const onSubmit = params => {
        const url = typeId ? regulationTypeEdit : regulationTypeAdd;

        const bodyParams = {
            ...params,
            school: schoolId,
        };

        if (typeId) {
            bodyParams.type = typeId;
        }

        dispatch(setLoading(true));
        fetchRequest(url, 'POST', bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setManualTypes(data?.types || []);
                    onModalClose();
                    message(res?.data?.message || t('common.success'), true);
                } else {
                    message(res?.data?.message || t('errorMessage.title'));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            })
    };

    const onDelete = () => {
        const params = {
            school: schoolId,
            type: typeId
        };
        dispatch(setLoading(true));
        fetchRequest(regulationTypeDelete, 'POST', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setManualTypes(data?.types || []);
                    onModalClose();
                    message(res?.data?.message || t('common.success'), true);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    return (
        <Card>
            <Card.Body>
                <button
                    className='btn btn-primary'
                    onClick={() => {
                        setShowModal(true);
                    }}
                >
                    {t('common.add')}
                </button>
                <DTable
                    data={manualTypes}
                    config={config}
                    columns={columns}
                    contextMenus={contextMenus}
                    onContextMenuItemClick={handleContextMenuClick}
                    selectMode={'radio'}
                />
                {
                    showModal && (
                        <ManualSettingAdd
                            onClose={onModalClose}
                            onSubmit={onSubmit}
                            id={typeId}
                        />
                    )
                }
                {
                    !showModal && typeId && (
                        <DeleteModal
                            onClose={onModalClose}
                            onDelete={onDelete}
                            title={t('curriculum.education')}
                        >
                            {t('warning.delete_confirmation')}
                            <br/>
                            <br/>
                            {t('warning.delete_confirmation_description')}
                        </DeleteModal>
                    )
                }
            </Card.Body>
        </Card>
    );
};

export default ManualSettings;