import React, { useRef, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
// import Forms from 'app/modules/Form/Forms';
import { Link } from 'react-router-dom';
import { formatISO } from 'date-fns';
import Forms from '../../../../../modules/Form/Forms';

const ChangeDateModal = ({ show, setShow, onSubmit, survey }) => {
    const { t } = useTranslation();
    const formRef = useRef();

    const [startDate, setStartDate] = useState(survey?.startDate || null);
    const [endDate, setEndDate] = useState(survey?.endDate || null);

    const onDateRangeChange = (dates) => {
        if(dates && dates.length > 0){
            if(dates[0].startDate){
                setStartDate(dates[0].startDate)
            }
            if(dates[0].endDate){
                setEndDate(dates[0].endDate)
            }
        }
    };

    const fields = [
        {
            key: 'date',
            value: null,
            label: `${t('survey.date')}*`,
            type: 'daterange',
            required: true,
            errorMessage: t('errorMessage.enterName'),
            labelBold: true,
            selectedStartDate: startDate,
            selectedEndDate: endDate,
            onChange: onDateRangeChange,
        },
    ];

    const onSaveClick = () => {
        const [isValid, , values] = formRef.current.validate();
        if (isValid) {
            const postData = {
                start: startDate,
                end: endDate,
            };
            onSubmit({
                ...postData,
                survey: survey?.id,
            });
            formRef.current.updateFields(fields);
        }
    };

    return (
        <Modal
            show={show}
            onClose={() => {
                setShow(false);
            }}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size="lg"
        >
            <Modal.Header>
                <Modal.Title className="fs-16 d-flex justify-content-between w-100 align-items-center">
                    <span>{survey?.name}</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-0">
                <Forms key="change-date-range" ref={formRef} fields={fields} />
            </Modal.Body>
            <Modal.Footer>
                <div className="text-center">
                    <Button size="sm" variant="link" onClick={() => setShow(false)}>
                        {t('common.back')}
                    </Button>
                    <Button variant="success" className="br-8 ps-4 pe-4" size="sm" onClick={onSaveClick}>
                        {t('common.save')}
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default ChangeDateModal;
