import React, {useEffect, useState} from 'react'
import DTable from '../../../../modules/DataTable/DTable'
import {Card} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {setLoading} from "../../../../../redux/action";
import {fetchRequest} from "../../../../../utils/fetchRequest";
import {lmsStudentSearch, regulationIndex, studentRegulationInit} from "../../../../../utils/fetchRequest/Urls";
import message from "../../../../modules/message";
import {useDispatch, useSelector} from "react-redux";
import Select from "../../../../modules/Form/Select";
import StudentGradeView from "./view";

export const OfficerStudentGrade = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [studentId, setStudentId] = useState(null);
    const [value, setValue] = useState('');
    const [students, setStudents] = useState([]);
    const [viewModal, setViewModal] = useState(false);

    const onStudentChange = (value) => {
        if(value){
            setStudentId(value);
            setViewModal(true);
        }
    };

    const onStudentInputChange = (inputValue) => {
        if(inputValue){
            setValue(inputValue);
        }
    };

    useEffect(() => {
        let timeout;
        if (value) {
            timeout = setTimeout(() => {
                const params = {
                    school: schoolId,
                    code: value,
                    select: 1
                };
                dispatch(setLoading(true));
                fetchRequest(lmsStudentSearch, 'GET', params)
                    .then(res => {
                        if (res.success) {
                            const { data } = res;
                            setStudents(data.students);
                        } else {
                            message(res?.data?.message || t('errorMessage.title'))
                        }
                        dispatch(setLoading(false));
                    })
                    .catch(() => {
                        dispatch(setLoading(false));
                        message(t('errorMessage.title'))
                    })
            }, 1000);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [value]);

    const onCloseModal = () => {
        setViewModal(false);
        setStudentId(null);
    };

    return (
        <Card>
            <Card.Body>
                <div className='mt-3' style={{ display: 'flex', marginTop: '0.8rem' }}>
                    <label
                        style={{
                            display: 'flex',
                            flex: 1,
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            marginRight : 10,
                            marginBottom: 0,
                            width: 'auto',
                        }}
                    >
                        {t('student.title')}
                    </label>
                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'column',
                            marginLeft: 10,
                            width: 'auto',
                        }}
                    >
                        <Select
                            value={studentId}
                            searchable
                            onChange={onStudentChange}
                            onInputChange={onStudentInputChange}
                            options={students}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flex: 0.8,
                            flexDirection: 'column',
                            marginLeft: 10,
                            width: 'auto',
                        }}
                    />
                </div>
            </Card.Body>
            {
                studentId && viewModal &&
                <StudentGradeView
                    studentId={studentId}
                    onOpen={false}
                    onClose={onCloseModal}
                />
            }
        </Card>
    )
};
