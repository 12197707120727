import { Card, ProgressBar } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../../redux/action";
import DTable from "../../../modules/DataTable/DTable";
import Chart from "chart.js";
import { fetchRequest } from "../../../../utils/fetchRequest";
import { useHistory } from "react-router-dom";
import { dashboardDetailsList } from "../../../../utils/fetchRequest/Urls";

const DashboardDetails = () => {
    const history = useHistory();
    const [details, setDetails] = useState([]);
    const [enroll, setEnroll] = useState(null);
    const [prevSchoolId, setPrevSchoolId] = useState(
        history?.location?.state?.school || null
    );
    const [dashboardId, setDashboardId] = useState(
        history?.location?.state?.id || null
    );

    const school = useSelector((state) => state?.selectedSchool?.id || null);
    const dId = useSelector((state) => state?.selectedEnroll?.id || null);

    const [totalCandidates, setTotalCandidates] = useState(0);

    const dispatch = useDispatch();
    useEffect(() => {
        handleRouteChange();
        dispatch(setLoading(true));
        const params = {
            details: dId,
            enroll: dashboardId,
        };

        fetchRequest(dashboardDetailsList, "GET", params)
            .then((res) => {
                if (res.success) {
                    const { data } = res;
                    if (data?.details?.length) {
                        setDetails(data.details);
                    }
                    if (data?.enroll != null) {
                        setEnroll(data.enroll);
                    }
                    setTotalCandidates(data?.totalCandidate || []);
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
            });
    }, []);

    function handleRouteChange() {
        if (school !== prevSchoolId) {
            history.push({
                pathname: "/enroll/dashboard",
            });
        }
    }
    const columns = [
        {
            dataField: "schoolName",
            text: "Салбар сургууль",
            sort: true,
            footer: '',
        },
        {
            dataField: "programCode",
            text: "Хөтөлбөрийн индекс",
            sort: false,
            footer: '',
        },
        {
            dataField: "academicProgramName",
            text: "Хөтөлбөрийн нэр",
            sort: false,
            footer: '',
        },
        {
            dataField: "programName",
            text: "Мэргэжил",
            sort: false,
            footer: '',
        },
        {
            dataField: "mNumber",
            text: "Эрэгтэй",
            sort: false,
            footer: '',
        },
        {
            dataField: "regMNum",
            text: "Бүртгүүлсэн эрэгтэй",
            sort: false,
            style: (cell, row, rowIndex, colIndex) => {
                if (Number(row.regMNum) > Number(row.mNumber)) {
                  return {
                    backgroundColor: "#299F724D",
                    textAlign: "end",
                  };
                }
                return {
                  backgroundColor: "#F4516B33",
                  textAlign: "end",
                };
            },
            footer: '',
            footerAlign: 'right',
            footerType: 'sum'
        },
        {
            dataField: "passMNum",
            text: "Тэнцсэн эрэгтэй",
            sort: false,
            footer: '',
            footerAlign: 'right',
            footerType: 'sum'
        },
        {
            dataField: "apprMNum",
            text: "Баталгаажуулсан эрэгтэй",
            sort: false,
            footer: '',
            footerAlign: 'right',
            footerType: 'sum'
        },
        {
            dataField: "fNumber",
            text: "Эмэгтэй",
            sort: false,
            footer: '',
        },
        {
            dataField: "regFNum",
            text: "Бүртгүүлсэн эмэгтэй",
            sort: false,
            style: (cell, row, rowIndex, colIndex) => {
                if (Number(row.regFNum) > Number(row.fNumber)) {
                  return {
                    backgroundColor: "#299F724D",
                    textAlign: "end",
                  };
                }
                return {
                  backgroundColor: "#F4516B33",
                  textAlign: "end",
                };
            },
            footer: '',
            footerAlign: 'right',
            footerType: 'sum'
        },
        {
            dataField: "passFNum",
            text: "Тэнцсэн эмэгтэй",
            sort: false,
            footer: '',
            footerAlign: 'right',
            footerType: 'sum'
        },
        {
            dataField: "apprFNum",
            text: "Баталгаажуулсан эмэгтэй",
            sort: false,
            footer: '',
            footerAlign: 'right',
            footerType: 'sum'
        },
    ];
    
    const handleClick = () => {
        history.push({
            pathname: "/enroll/dashboard",
        });
    };

    // const dataSource = [{}];
    // useEffect(() => {
    //   let ctx = document.getElementById("myCanvas").getContext("2d");
    //   let options = {
    //     scales: {
    //       xAxes: [{}],
    //     },
    //   };
    //   var myChart = new Chart(ctx, {
    //     type: "bar",
    //     data: {
    //       labels: [
    //         "Сургууль 1",
    //         "Сургууль 1",
    //         "Сургууль 1",
    //         "Сургууль 1",
    //         "Сургууль 1",
    //         "Сургууль 1",
    //         "Сургууль 1",
    //         "Сургууль 1",
    //         "Сургууль 1",
    //       ],
    //       datasets: [
    //         {
    //           label: "My Data",
    //           data: [19, 3, 5, 2, 3, 18, 19, 20, 21],
    //           backgroundColor: [
    //             "rgba(41, 159, 114, 1)",
    //             "rgba(41, 159, 114, 1)",
    //             "rgba(41, 159, 114, 1)",
    //             "rgba(41, 159, 114, 1)",
    //             "rgba(41, 159, 114, 1)",
    //             "rgba(41, 159, 114, 1)",
    //             "rgba(41, 159, 114, 1)",
    //             "rgba(41, 159, 114, 1)",
    //             "rgba(41, 159, 114, 1)",
    //           ],
    //         },
    //       ],
    //     },

    //     options: {
    //       legend: {
    //         display: false,
    //       },
    //       scales: {
    //         xAxes: [
    //           {
    //             gridLines: {
    //               display: false,
    //             },
    //             barPercentage: 0.5,
    //             categoryPercentage: 0.8,
    //             responsive: true,
    //           },
    //         ],
    //         yAxes: [
    //           {
    //             responsive: true,
    //             gridLines: {
    //               display: false,
    //               drawBorder: true,
    //             },
    //             ticks: {
    //               beginAtZero: true,
    //               display: false,
    //             },
    //           },
    //         ],
    //       },
    //     },
    //     plugins: [
    //       {
    //         afterDraw: function(chart) {
    //           var ctx = chart.chart.ctx;
    //           ctx.beginPath();
    //           ctx.moveTo(chart.chartArea.left, chart.chartArea.bottom);
    //           ctx.lineTo(chart.chartArea.right, chart.chartArea.bottom);
    //           ctx.strokeStyle = "#000000";
    //           ctx.stroke();
    //         },
    //       },
    //     ],
    //   });
    // }, []);

    return (
        <Card style={{padding: 30}}>
            {enroll != null ? (
                <div className="row d-flex">
                    <div className="col-4">
                        <button
                            type="button"
                            className={`btn btn-${enroll.isOpen === "1" ? "success" : "secondary"
                                }`}
                        >
                            {enroll.isOpen == 1 ? "Нээгдсэн" : "Хаагдсан"}
                        </button>

                        <p className="text-lg mt-4" style={{fontSize: 14}}><b>Үндсэн {enroll.name}ийн элсэлт</b></p>
                        <p>
                            {enroll.startDate} - {enroll.endDate}
                        </p>
                        <p>
                            {" "}
                            {enroll.totalProgramNumber ? enroll.totalProgramNumber : "0"}{" "}
                            хөтөлбөр | {enroll.candidateCnt ? enroll.candidateCnt : "0"}{" "}
                            элсэгч
                        </p>
                        <ProgressBar
                            now={(enroll.candidateCnt / totalCandidates) * 100}
                            variant="success"
                        />
                        <p>{totalCandidates} бүртгүүлэгч</p>
                    </div>
                    <div className="col-8">
                        <div className="d-flex justify-content-end">
                            <button
                                onClick={handleClick}
                                className="btn btn-link d-flex justify-content-end text-end"
                            >
                                БУЦАХ
                            </button>
                        </div>

                        <canvas id="myCanvas" width={200} height={50}></canvas>
                    </div>
                </div>
            ) : (
                <></>
            )}

            <DTable
                columns={columns}
                config={{ 
                    showPagination: false, 
                    excelExport: true,
                    showAllData: true,
                    footer: true,
                }}
                data={details}
            />
        </Card>
    );
};

export default DashboardDetails;
