import React from 'react';
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const QuestionPreviewModal = ({
    onClose = () => {},
    question,
    description
}) => {
    const { t } = useTranslation();

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('exam.assignment')}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div
                    dangerouslySetInnerHTML={{ __html: question }}
                />
                <div className='mt-4'
                    dangerouslySetInnerHTML={{ __html: description }}
                />
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className='btn btn-link bolder'>{t('common.close')}</button>
            </Modal.Footer>
        </Modal>
    );
};

export default QuestionPreviewModal;