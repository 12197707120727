import React, {useEffect, useState} from 'react'
import {useTranslation} from "react-i18next";
import { timeDateFormat } from 'utils/utils';

export const AllStudentsPrint = React.forwardRef((props, ref) => {
    const { t } = useTranslation();
    const { teacherData = null, mainSchoolData = null, schoolData = null, seasonData = null, subjectData = null, list = [], templates = [], scoreTypes = []} = props;

    let totalListCount = 0;

    const mainSchoolName =  mainSchoolData && mainSchoolData.longName ? mainSchoolData.longName.substring(0,1).toUpperCase() + mainSchoolData.longName.substring(1) : '';
    const schoolName = schoolData && schoolData.longName ? schoolData.longName.substring(0,1).toUpperCase() + schoolData.longName.substring(1) : '';

    const shiftRow = totalListCount > 75 ? totalListCount/2 : 38;
    let totalRow = 0;
    let stopArrRow = null;
    let stopSubRow = null;

    if (stopArrRow == null && stopSubRow == null) {
        stopArrRow = shiftRow;
        stopSubRow = shiftRow;
    }

    const renderBody = (students, stTemplateDetails) => {
        for(let s = 0; s < scoreTypes.length; s++){
            scoreTypes[s].count = 0;
        }

        let tdObj = [];
        if(students && students.length > 0){
            for(let i = 0; i < students.length; i++){
                let studentTotalScore = 0;

                for(let s = 0; s < scoreTypes.length; s++){
                    if(scoreTypes[s].code == students[i].scoreTypeCode){
                        scoreTypes[s].count += 1;
                    }
                }

                tdObj.push(
                    <tr key={'score_' + i}>
                        <td>{i + 1}</td>
                        <td>{students[i].code}</td>
                        <td>{students[i].lastName}</td>
                        <td>{students[i].firstName}</td>
                        {
                            students[i].details && stTemplateDetails && stTemplateDetails.length > 0
                                ?
                                    stTemplateDetails.map((details, dIndex) => {
                                        studentTotalScore = studentTotalScore + students[i].details[details.id]
                                        return (
                                            <td key={'student_score_' + dIndex} style={{textAlign: 'right'}}>
                                                {students[i].details[details.id]}
                                            </td>
                                        )
                                    })
                                : null
                        }
                        <td className='text-right'>{studentTotalScore || 0}</td>
                        <td>{students[i].scoreTypeName || ''}</td>
                    </tr>
                );
            }
        }

        return tdObj;
    };

    let totalScore = 0;
    let date = new Date();

    return (
        <div 
            ref={ref} 
            style={{marginTop: 20, color: '#000', fontFamily: 'Times New Roman'}}
        >
            <div className='d-flex justify-content-between'>
                <div>{timeDateFormat(date)}</div>
                <div>Багшийн веб</div>
            </div>
            {
                teacherData && mainSchoolData && seasonData && subjectData && schoolData &&
                <div>
                    <div className='col-12 mb-4' style={{textAlign: 'center', borderBottom: '1px solid #ECF0F3', padding: 20}}>
                        <div style={{fontFamily: 'Times New Roman', fontSize: 14, textTransform: 'uppercase'}}>
                            {mainSchoolName}
                        </div>
                        <div style={{textAlign: 'center', fontFamily: 'Times New Roman', fontSize: 14, fontWeight: 'bold', textTransform: 'uppercase'}}>
                            УЛИРЛЫН ДҮНГИЙН ХУУДАС
                        </div>
                    </div>

                    <div style={{fontFamily: 'Times New Roman', fontSize: 12}}>
                        <div className='col-12 pl-0 mb-4' style={{display: 'flex'}}>
                            <div className='col pl-0'>
                                <div style={{lineHeight: 1.3}}>
                                    <b>{seasonData.parentSeasonName + ' оны хичээлийн жил, ' + seasonData.seasonName}</b>
                                </div>
                                <div style={{display: 'flex', lineHeight: 1.3}}>
                                    <b>Бүрэлдэхүүн сургууль:</b> <div style={{textTransform: 'uppercase'}}>&ensp;{schoolData.longName}&ensp;</div> <b>Суралцах хэлбэр:</b>&ensp;{subjectData.lmType}
                                </div>
                                <div style={{display: 'flex', lineHeight: 1.3}}>
                                    <b>Хичээлийн нэр:</b> <div>&ensp;{subjectData.name + ', '}</div>&ensp;<b>индекс:</b>&ensp;{subjectData.code + ', '}&ensp;<b>кредит:</b>&ensp;{subjectData.credit}
                                </div>
                                <div style={{display: 'flex', lineHeight: 1.3}}>
                                    <b>Багшийн нэр:</b> <div>&ensp;{teacherData.firstName + ', '}</div>&ensp;<b>Зэрэг цол:</b>&ensp;{teacherData.position}
                                </div>
                            </div>
                        </div>
                        <div className='mb-2'>
                            <table className='table table-bordered teacher-time-sheet-main-table'>
                                <thead>
                                    <tr>
                                        <th rowSpan={2}>№</th>
                                        <th rowSpan={2}>{t('student.code')}</th>
                                        <th rowSpan={2}>{t('student.lastName')}</th>
                                        <th rowSpan={2}>{t('student.name')}</th>
                                        {
                                            templates && templates.length > 0
                                            ?
                                                templates.map((template, index) => {
                                                    let title = '';

                                                    if(template.code == 'attendance'){
                                                        title = 'Ирц, явц идвэх 10%'
                                                    } else if(template.code == 'independent_work'){
                                                        title = 'БДА, даалгавар'
                                                    } else if(template.code == 'final_exam'){
                                                        title = 'Явцын шалгалт, даалгавар сорил'
                                                    }

                                                    return (
                                                        <th style={{width: 110}} key={'item_' + index}>{title}</th>
                                                    )
                                                })
                                            : null
                                        }
                                        <th style={{width: 100}}>{'Ерөнхий үнэлгээ 100%'}</th>
                                        <th style={{width: 85}}>{'Үсгэн үнэлгээ'}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {renderBody(list, templates)}
                                </tbody>
                            </table>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                Дүгнэгдвэл зохих оюутан:... Үүнээс өвтэй:..., чөлөөтэй:..., ороогүй:...,
                            </div>
                            <div className='col-12'>
                                {
                                    scoreTypes && scoreTypes.length > 0 &&
                                    scoreTypes.map((scoreType, index) => {
                                        if(index <= 8){
                                            return(
                                                <div style={{display: 'inline-block'}} key={'score_type_' + index}>{scoreType.code + '=' + scoreType.count + ','}</div>
                                            )
                                        }
                                    })
                                }
                            </div>
                            <div className='col-12'>
                                {
                                    scoreTypes && scoreTypes.length > 0 &&
                                    scoreTypes.map((scoreType, index) => {
                                        if(index > 8){
                                            if(scoreTypes.length == (index + 1)){
                                                return(
                                                    <div style={{display: 'inline-block'}} key={'score_type_' + index}>{scoreType.code + '=' + scoreType.count}</div>
                                                )
                                            } else {
                                                return(
                                                    <div style={{display: 'inline-block'}} key={'score_type_' + index}>{scoreType.code + '=' + scoreType.count + ','}</div>
                                                )
                                            }
                                        }
                                    })
                                }
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <b>Багшийн гарын үсэг:______________________________________</b>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <b>Улиралын дүнгийн хуудсыг хөтөлбөрийн албанд хүлээлгэн өгсөн он,сар,өдөр:_________</b>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <b>Хүлээн авсан сургалтын албаны мэргэжилтэн:____________________________</b>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
});
