import React, { useEffect, useState} from "react";
import {useTranslation} from 'react-i18next';
import {Button, Card, Modal} from "react-bootstrap";
import Select from "../../../modules/Form/Select";
import {fetchRequest} from "../../../../utils/fetchRequest";
import {setLoading} from "../../../../redux/action";
import {useDispatch, useSelector} from "react-redux";
import {AddSubjectStudentSelection, schoolClassDelete, studentSelectionInit} from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import Approved from "./Approved";
import Penalty from "./Penalty";
import HasConfig from "./HasConfig";
import CanSelect from "./CanSelect";
import Payment from "./Payment";

export function SelectionTwo() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    const [helperModal, setHelperModal] = useState(false);
    const [totalCredit, setTotalCredit] = useState(null);
    const [totalSubjectCount, setTotalSubjectCount] = useState(null);
    const [totalCreditPrice, setTotalCreditPrice] = useState(null);
    const [subjects, setSubjects] = useState([]);
    const [selectionData, setSelectionData] = useState([]);
    const [teacherId, setTeacherId] = useState(null);
    const [subjectData, setSubjectData] = useState([]);
    const [approved, setApproved] = useState(false);
    const [penalty, setPenalty] = useState(false);
    const [canSelect, setCanSelect] = useState(false);
    const [hasConfig, setHasConfig] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [payment, setPayment] = useState(null);
    const [invoiceId, setInvoiceId] = useState(null);

    useEffect(() => {
        dispatch(setLoading(true));
        let params = {
            school: schoolId,
            selection: 2
        };

        fetchRequest(studentSelectionInit, 'GET', params)
            .then(response => {
                const { data } = response;
                if (response.success) {
                    let subjects = data?.selectionData?.subjects;
                    let dataStructure = [];

                    setApproved(data?.approved);
                    setPenalty(data?.hasPenalty);
                    setCanSelect(data?.canSelect);
                    setHasConfig(data?.hasConfig);

                    setTotalCredit(data?.selectionData?.totalCredit);
                    setTotalSubjectCount(data?.selectionData?.totalSubjectCount);
                    setTotalCreditPrice(data?.selectionData?.totalCreditPrice);
                    setInvoiceId(data?.selectionData?.invoiceId);
                    setSubjects(subjects);
                    setSelectionData(data?.selectionData);

                    if(subjects && subjects.length > 0){
                        for(let i = 0; i < subjects.length; i++){
                            dataStructure.push({
                                'dtlId': subjects[i].id,
                                'subjectId': subjects[i].subjectId,
                                'code': subjects[i].code,
                                'name': subjects[i].name,
                                'credit': subjects[i].credit,
                                'pTimes': []
                            });

                            let pTime = subjects[i].pTimes;
                            if(pTime && pTime.length > 0){
                                for(let p = 0; p < pTime.length; p++){
                                    let timetables = pTime[p].timetables;

                                    if(timetables && timetables.length > 0){
                                        for (let t = 0; t < timetables.length; t++){
                                            timetables[t]['value'] = timetables[t].id;
                                            timetables[t]['text'] = (timetables[t].lastName).charAt(0) + '.' + timetables[t].firstName
                                                + ' - ' + timetables[t].day + ' (' + timetables[t].startTime + '-' + timetables[t].endTime + ')';
                                        }
                                    }

                                    dataStructure[i]['pTimes'].push({
                                        'id': pTime[p].timeId,
                                        'timeName': pTime[p].timeName,
                                        'timetables': timetables,
                                        'timetable': null,
                                        'error': false
                                    });
                                }
                            }
                        }
                    }

                    setSubjectData(dataStructure);
                } else {
                    setPayment(data?.payment);
                    message(response.data.message, false);
                }
                setIsLoading(true);
                dispatch(setLoading(false));
            })
            .catch(() => {
                setIsLoading(true);
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            });
    }, []);

    const handleHelperModalClose = () => {
        setHelperModal(false);
    };

    const handlerApproved = (value) => {
        setApproved(value);
    };

    return(
        <div className='row'>
            <div className='col-12'>
                <Card>
                    {
                        isLoading
                        ?
                            payment
                            ?
                                <Payment
                                    invoiceId={invoiceId}
                                    payment={payment}
                                />
                            :
                            approved === true
                                ?
                                <Approved
                                    selectionData={selectionData}
                                    totalCredit={totalCredit}
                                    totalSubjectCount={totalSubjectCount}
                                    totalCreditPrice={totalCreditPrice}
                                />
                                :
                                hasConfig === false
                                    ?
                                    <HasConfig
                                        selectionData={selectionData}
                                    />
                                    :
                                    hasConfig === true && canSelect === true
                                        ?
                                        <CanSelect
                                            subjects={subjectData}
                                            totalSubjectCount
                                            totalCredit
                                            handlerApproved={handlerApproved}
                                        />
                                        :
                                        penalty === false && hasConfig === true
                                            ?
                                            <Penalty/>
                                            :
                                            penalty === true
                                                ?
                                                <Penalty/>
                                                :
                                                null
                        : null
                    }
                </Card>
            </div>
            <Modal
                show={helperModal}
                onHide={handleHelperModalClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Modal heading
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Centered Modal</h4>
                    <p>
                        Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
                        dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta
                        ac consectetur ac, vestibulum at eros.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleHelperModalClose}>{t('common.close')}</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default SelectionTwo;