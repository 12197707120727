import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";

import { Home } from "./pages/home/index";
import { Calendar } from "./pages/calendar";
import { EventAdd } from "./pages/calendar/EventAdd";
import { EventEdit } from "./pages/calendar/EventEdit";
import { Curriculum } from "./pages/Curriculum";
import { Evaluation } from "./pages/Evaluation";
import { Homework } from "./pages/Homework";
import { Exam } from './pages/Exam'
import FormTest from "./pages/test/FormTest";
import AcademicProgram from './pages/school/academic/index';
import AcademicProgramNew from './pages/school/academic/new';
import AcademicProgramEdit from './pages/school/academic/edit';
import SchoolLoan from './pages/school/loan/index';
import SchoolScholarship from './pages/school/scholarship/index';
import SchoolSigning from './pages/school/signing/index';

//Study pages
import { StudentGrade } from "./pages/student/scoreTable";
import { Payments } from "./pages/student/payment";

//Request
import {OfficerRequest} from "./pages/officer/request/index";
import {StudentRequest} from "./pages/student/request";
import {OfficerManual} from "./pages/officer/manual";
import {OfficerRegulation} from "./pages/officer/regulation";
import {OfficerScholarship} from "./pages/officer/scholarship";
import {OfficerStudentList} from "./pages/officer/students/list";
import {OfficerStudentPayment} from "./pages/officer/students/payment";
import {OfficerStudentGrade} from "./pages/officer/students/grade";
import {StudentAnket} from "./pages/officer/students/anket";
import {OfficerStudentDescription} from "./pages/officer/description";

//Form
import { Anket } from './pages/student/anket';
import { Anket1 } from './pages/officer/anket';
//Survey
import {OfficerSurveyPage} from "./pages/officer/survey/pages";
import {OfficerSurveyCreatePage} from "./pages/officer/survey/pages/Create";
import {OfficerSurveyViewPage} from "./pages/officer/survey/pages/View";
import {OfficerSurveyEditPage} from "./pages/officer/survey/pages/Edit";
import {OfficerSurveyReportPage} from "./pages/officer/survey/pages/Report";

// Student survey
import {StudentSurveyIndex} from "./pages/officer/survey/pages/Report";

//Additional
import Program from './pages/school/program/index';
import ProgramAdd from './pages/school/program/new';
import ProgramEdit from './pages/school/program/edit';
import Subject from './pages/school/subject/index';
import SubjectNew from './pages/school/subject/new';
import SubjectEdit from './pages/school/subject/edit';
import SchoolSubjectPrice from './pages/school/subjectPrice/index';
import SchoolSubjectConfig from './pages/school/subjectConfig/index';
import StudentSelectionOne from './pages/student/selection/SelectionOne';
import StudentSelectionTwo from './pages/student/selection/SelectionTwo';
import StudentSelection from './pages/student/selection/Selection';
import Teacher from './pages/school/teacher/index';
import TeacherNew from './pages/school/teacher/new';
import TeacherEdit from './pages/school/teacher/edit';
import SchoolComponents from "./pages/structure/schoolComponent/index";
import SchoolComponentNew from "./pages/structure/schoolComponent/new";
import Department from "./pages/structure/department/index";
import DepartmentNew from "./pages/structure/department/new";
import Roles from "./pages/structure/roles/index";
import RoleNew from "./pages/structure/roles/new";
import RoleEdit from "./pages/structure/roles/edit";
import SchoolAbout from "./pages/structure/about/index";
import DataTableTest from "./pages/test/DataTableTest";
import DragTest from "./pages/test/DragTest";
// import {Home} from "./pages/Home";
import Timetable from "./pages/timetable";
import One from './pages/school/selection/One';
import OneView from './pages/school/selection/oneView';
import Two from './pages/school/selection/Two';
import ClassSelection from './pages/school/selection/Class';
import SchoolPenalty from "./pages/school/selection/Penalty";
//Teacher
import {TeacherHome} from "./pages/teacher/home";
import {TeacherCalendar} from "./pages/teacher/calendar";
import {TeacherJournal} from "./pages/teacher/journal";
import {TeacherTimeSheet} from "./pages/teacher/timeSheet";
import {TeacherTimeAdditional} from "./pages/teacher/timeAdditional";
import {TeacherPrintGrade} from "./pages/teacher/print/grade";
import {JournalView} from "./pages/teacher/journal/view";
import {TeacherTimetable} from "./pages/teacher/timetable/index";
import {TeacherAnnouncement} from "./pages/teacher/online/announcement/index";
import {TeacherAnnouncementAdd} from "./pages/teacher/online/announcement/add";
import {TeacherAnnouncementEdit} from "./pages/teacher/online/announcement/edit";
import {TeacherOnlineLesson} from "./pages/teacher/online/lesson/index";
import {TeacherOnlineLessonAdd} from "./pages/teacher/online/lesson/add";
import {TeacherOnlineLessonEdit} from "./pages/teacher/online/lesson/edit";
import {TeacherOnlineLessonEditTopicAdd} from "./pages/teacher/online/lesson/topicAdd";
import {TeacherOnlineLessonEditTopicEdit} from "./pages/teacher/online/lesson/topicEdit";
import {TeacherOnlineLessonEditTopicView} from "./pages/teacher/online/lesson/topicView";
import {TeacherOnlineLessonEditHomeworkAdd} from "./pages/teacher/online/lesson/homeworkAdd";
import {TeacherOnlineLessonEditHomeworkEdit} from "./pages/teacher/online/lesson/homeworkEdit";
import {TeacherOnlineLessonEditHomeworkView} from "./pages/teacher/online/lesson/homeworkView";
import {TeacherOnlineLessonEditDiscussionAdd} from "./pages/teacher/online/lesson/discussionAdd";
import {TeacherOnlineLessonEditDiscussionEdit} from "./pages/teacher/online/lesson/discussionEdit";
import {TeacherOnlineLessonEditDiscussionView} from "./pages/teacher/online/lesson/discussionView";
import {TeacherOnlineFile} from "./pages/teacher/online/file/index";
import {TeacherOnlineFileAdd} from "./pages/teacher/online/file/add";
import {TeacherQuestions} from "./pages/teacher/exam/questions/index";
import {TeacherQuestionAdd} from "./pages/teacher/exam/questions/Add";
import TeacherOnlineExam from "./pages/teacher/online/exam";
import TeacherOnlineExamCreate from "./pages/teacher/online/exam/Create";
import TeacherOnlineExamView from "./pages/teacher/online/exam/View";
import {TeacherExamDifficulty} from "./pages/teacher/online/difficulty/index"

// Movement
import ClassIndex from "./pages/movement/class";
import ClassAdd from "./pages/movement/class/Add";
import AddStudent from "./pages/movement/class/AddStudent";
import ClassView from "./pages/movement/class/view";
import ClassEdit from "./pages/movement/class/edit";
import InternalMovement from "./pages/movement/internal";
import AddInternalMovement from "./pages/movement/internal/AddInternalMovement";
import MovementBetweenAdd from "./pages/movement/internal/AddBetween";
import SchoolMovementOut from "./pages/movement/out";
import AddOutStudent from "./pages/movement/out/AddOutStudent";
import ExcusedMovement from "./pages/movement/excused";
import AddExcusedStudent from "./pages/movement/excused/AddExcusedStudent";
import MissingMovement from "./pages/movement/missing";
import AddMissingStudent from "./pages/movement/missing/AddMissingStudent";

import {ToastContainer} from "react-toastify";
// Newsfeed
import {NewsfeedAdd} from "./pages/home/NewsfeedAdd";
import {NewsfeedEdit} from "./pages/home/NewsfeedEdit";
//finance
import {FinanceHome} from "./pages/finance/home";
import {FinanceCalendar} from "./pages/finance/calendar";
import {Invoice} from "./pages/finance/invoice";
import {Account} from "./pages/finance/config/account";
import {InvoiceDiscount} from "./pages/finance/config/discount";
import {InvoiceType} from "./pages/finance/config/type";
import {IncomeBank} from "./pages/finance/income/bank";
import {IncomeCard} from "./pages/finance/income/card";
import {IncomeUndefined} from "./pages/finance/income/undefined";
import {SaleLoan} from "./pages/finance/config/saleLoan";
import {ScholarshipType} from "./pages/finance/config/scholarshipType";
import {SubjectPrice} from "./pages/finance/config/subjectPrice";
import {GovernmentLoan} from "./pages/finance/governmentLoan";
import {Ebarimt} from "./pages/finance/ebarimt";
import {Scholarship} from "./pages/finance/scholarship";
import StudentView from "./pages/finance/studentView";
import Success from "./pages/finance/success";
//Setting
import AttendanceType from './pages/setting/school/AttendanceType'
import CurriculumApproach from './pages/setting/curriculum/Approach'
import CurriculumCategory from './pages/setting/curriculum/Category'
import CurriculumPType from './pages/setting/curriculum/PType'
import CurriculumStatus from './pages/setting/curriculum/Status'
import CurriculumSubjectType from './pages/setting/curriculum/SubjectType'
import CurriculumTime from './pages/setting/curriculum/Time'
import CurriculumType from './pages/setting/curriculum/Type'
import SystemRole from "./pages/setting/systemRole";
import ActiveTypes from "./pages/setting/teacher/ActiveTypes";
import EmployeeStatus from "./pages/setting/hr/EmployeeStatus";
import EducationDegree from "./pages/setting/reference/EducationDegree";
import ManualSettings from "./pages/setting/reference/ManualSettings";
import Regulations from "./pages/setting/reference/Regulations";
import RequestStatus from "./pages/setting/reference/RequestStatus";
import TranscriptType from "./pages/setting/reference/TranscriptType";
import RequestType from "./pages/setting/reference/RequestType";
import Country from "./pages/setting/reference/Country";
import SchoolShifts from "./pages/setting/school/Shfits";
import TimeTemplate from "./pages/setting/school/TimeTemplate";
import EducationLevel from "./pages/setting/school/EducationLevel";
import SeasonType from "./pages/setting/school/SeasonType";
import Season from "./pages/setting/school/Season";
import AddressSegment from "./pages/setting/reference/AddressSegment";
import ClassType from "./pages/setting/class/ClassType";
import GroupType from "./pages/setting/class/GroupType";
import ClassStatus from "./pages/setting/class/ClassStatus";
import StudentStatus from './pages/setting/student/Status'
import StudentStatusConfig from "./pages/setting/student/StatusConfig";
import MovementType from "./pages/setting/movement/Type";
import MovementCause from "./pages/setting/movement/Cause";
import Weekdays from "./pages/setting/timetable/Weekdays";
import TimetableType from './pages/setting/timetable/Type';
import Selection from "./pages/setting/timetable/Selection";
import SystemLanguage from "./pages/setting/language/SystemLanguage";
import Assessment from "./pages/setting/assessment/Assessment";
import ContactType from "./pages/setting/reference/ContactType";
import ExamType from "./pages/setting/assessment/ExamType";
import StItem from "./pages/setting/assessment/StItem";
import StTemplate from "./pages/setting/assessment/StTemplate";
import Classroom from "./pages/structure/classroom";
import TimetableAdd from "./pages/timetable/TimetableAdd";
import TimetableEdit from "./pages/timetable/TimetableEdit";
import MenuConfig from "./pages/setting/menu/MenuConfig";
import MenuPositions from "./pages/setting/menu/MenuPositions";
// Student pages
import {StudentHome} from "./pages/student/home";
import {StudentCalendar} from "./pages/student/calendar";
import {StudentCurrent} from "./pages/student/current";
import {StudentDescription} from "./pages/student/description";
import {StudentManual} from "./pages/student/manual";
import {StudentScholarship} from "./pages/student/scholarship";
import {StudentRegulation} from "./pages/student/regulation";
import {StudentSurvey} from "./pages/student/survey";
import {ComingSoon} from "./pages/student/comingSoon";
import {TeacherGuides} from "./pages/teacher/guides";
import StudentOnlineExam from "./pages/student/onlineExam";
import OnlineExamStart from "./pages/student/onlineExam/OnlineExamStart";
import OnlineExamResult from "./pages/student/onlineExam/OnlineExamResult";
import StudentOnlineLesson from "./pages/student/onlineLesson";
import OnlineLessonDtl from "./pages/student/onlineLesson/OnlineLessonDtl";
import OnlineLessonViewModule from "./pages/student/onlineLesson/OnlineLessonViewModule";
import OnlineLessonViewHomework from "./pages/student/onlineLesson/OnlineLessonViewHomework";
import { StudentQrCode } from "./pages/officer/description/check";

// Enrollment
import EnrollDashboard from "./pages/enroll/dashboard";

import DashboardDetails from "./pages/enroll/dashboard/dashboardDetails";
import List from "./pages/enroll/setting/list";
import AddEntry from "./pages/enroll/setting/addEntry";
import EditEntry from "./pages/enroll/setting/editEntry";
import Registrants from "./pages/enroll/registrants/registrants";
import NonRegistrationUser from "./pages/enroll/registrants/nonRegistrationUser";
import CandidateProfile from "./pages/enroll/registrants/candidateProfile";
import EnrollCertificate from "./pages/enroll/enrollCertificate";
import EnrollCertificateNumber from "./pages/enroll/enrollCertificateNumber";
import EnrollCertificateModel from "./pages/enroll/enrollCertificateModel";
import EnrollBody from "./pages/enroll/enrolBody/enrollBody";
import EnrollHealth from "./pages/enroll/enrollHealth";
import EnrollPsychology from "./pages/enroll/enrollPsychology";
import EnrollScholarship from "./pages/enroll/enroll-scholarship/index";
import EnrollMessageReport from "./pages/enroll/messageReport/index";
import EnrollEmailReport from "./pages/enroll/emailReport";
import Eyesh from "./pages/enroll/passed/eyesh";
import EnrollRegistrationPayment from "./pages/enroll/registrationPayment";

// Exam
import { ExamQuestionList, QuestionForm, QuestionEdit } from './pages/exam/question';

// const GoogleMaterialPage = lazy(() =>
//   import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
// );
// const ReactBootstrapPage = lazy(() =>
//   import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
// );
// const ECommercePage = lazy(() =>
//   import("./modules/ECommerce/pages/eCommercePage")
// );import Graduation from './pages/graduation/index';

//Grads

import StudentsGradution from './pages/graduation/students';
import GraduationTemplate from './pages/graduation/template/index';
import GraduationConfig from './pages/graduation/config/index';
import AdditionalInformation from './pages/graduation/information/index';


export default function BasePage() {
    // useEffect(() => {
    // }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect
    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                <ContentRoute exact path="/home" component={Home} />
                <ContentRoute exact path="/teacher/home" component={TeacherHome} />
                <ContentRoute exact path="/student/home" component={StudentHome} />
                <ContentRoute exact path="/finance/home" component={FinanceHome} />
                <ContentRoute exact path="/officer/home" component={Home} />
                <ContentRoute exact path="/home" component={Home} />
                <ContentRoute exact path="/newsfeed/add" component={NewsfeedAdd} />
                <ContentRoute exact path="/newsfeed/edit" component={NewsfeedEdit} />
                <ContentRoute path="/calendar" component={Calendar} />
                <ContentRoute path="/teacher/calendar" component={TeacherCalendar} />
                <ContentRoute path="/student/calendar" component={StudentCalendar} />
                <ContentRoute path="/finance/calendar" component={FinanceCalendar} />
                <ContentRoute path="/event/add" component={EventAdd} />
                <ContentRoute path="/event/edit" component={EventEdit} />
                <ContentRoute path="/regulation" component={StudentRegulation} />
                <ContentRoute path="/curriculum" component={Curriculum} />
                <ContentRoute path="/evaluation" component={Evaluation} />
                <ContentRoute exact path="/exam" component={Exam} />
                <ContentRoute path="/homework" component={Homework} />
                <ContentRoute path="/school/academic" component={AcademicProgram} />
                <ContentRoute path="/school/academic_new" component={AcademicProgramNew} />
                <ContentRoute path="/school/academic_edit" component={AcademicProgramEdit} />
                <ContentRoute path="/school/program" component={Program} />
                <ContentRoute path="/school/add" component={ProgramAdd} />
                <ContentRoute path="/school/edit" component={ProgramEdit} />
                <ContentRoute path="/school/subject" component={Subject} />
                <ContentRoute path="/school/subject-price" component={SchoolSubjectPrice} />
                <ContentRoute path="/school/subject-config" component={SchoolSubjectConfig} />
                <ContentRoute path="/school/subject_new" component={SubjectNew} />
                <ContentRoute path="/school/subject_edit" component={SubjectEdit} />
                <ContentRoute path="/school/teacher" component={Teacher} />
                <ContentRoute path="/school/teacher_new" component={TeacherNew} />
                <ContentRoute path="/school/teacher_edit" component={TeacherEdit} />
                <Route path="/structure/component" component={SchoolComponents} />
                <Route path="/structure/component_new" component={SchoolComponentNew} />
                <Route path="/structure/department" component={Department} />
                <Route path="/structure/department_new" component={DepartmentNew} />
                <Route path="/structure/roles" component={Roles} />
                <Route path="/structure/role_new" component={RoleNew} />
                <Route path="/structure/role_edit" component={RoleEdit} />
                <Route path="/structure/classroom" component={Classroom} />
                <Route path="/structure/about" component={SchoolAbout} />
                <ContentRoute exact path="/movement/class" component={ClassIndex} />
                <ContentRoute path="/movement/class/add" component={ClassAdd} />
                <ContentRoute path="/movement/class/edit" component={ClassEdit} />
                <ContentRoute path="/movement/class/student/add" component={AddStudent} />
                <ContentRoute path="/movement/class/view" component={ClassView} />
                <ContentRoute exact path="/timetable" component={Timetable} />
                <ContentRoute path="/timetable/add" component={TimetableAdd} />
                <ContentRoute path="/timetable/edit" component={TimetableEdit} />

                <ContentRoute exact path="/setting/curriculum/subject-type" component={CurriculumSubjectType} />
                <ContentRoute exact path="/setting/curriculum/status" component={CurriculumStatus} />
                <ContentRoute exact path="/setting/curriculum/type" component={CurriculumType} />
                <ContentRoute exact path="/setting/curriculum/approach" component={CurriculumApproach} />
                <ContentRoute exact path="/setting/curriculum/category" component={CurriculumCategory} />
                <ContentRoute exact path="/setting/curriculum/ptype" component={CurriculumPType} />
                <ContentRoute exact path="/setting/curriculum/time" component={CurriculumTime} />
                <ContentRoute exact path="/setting/role" component={SystemRole} />
                <ContentRoute exact path="/setting/teacher/status" component={ActiveTypes} />
                <ContentRoute exact path="/setting/hr/status" component={EmployeeStatus} />
                <ContentRoute exact path="/setting/r/country" component={Country} />
                <ContentRoute exact path="/setting/r/contact-type" component={ContactType} />
                <ContentRoute exact path="/setting/r/education" component={EducationDegree} />
                <ContentRoute exact path="/setting/r/manual-type" component={ManualSettings} />
                <ContentRoute exact path="/setting/r/manuals" component={Regulations} />
                <ContentRoute exact path="/setting/r/request/type" component={RequestType} />
                <ContentRoute exact path="/setting/r/request/status" component={RequestStatus} />
                <ContentRoute exact path="/setting/r/transcript/type" component={TranscriptType} />
                <ContentRoute exact path="/setting/sc/attendance-type" component={AttendanceType} />
                <ContentRoute exact path="/setting/sc/shifts" component={SchoolShifts} />
                <ContentRoute exact path="/setting/sc/timetemplate" component={TimeTemplate} />
                <ContentRoute exact path="/setting/sc/edu" component={EducationLevel} />
                <ContentRoute exact path="/setting/sc/season-type" component={SeasonType} />
                <ContentRoute exact path="/setting/sc/seasons" component={Season} />
                <ContentRoute exact path="/setting/r/address-segment" component={AddressSegment} />
                <ContentRoute exact path="/setting/c/class-type" component={ClassType} />
                <ContentRoute exact path="/setting/c/class-status" component={ClassStatus} />
                <ContentRoute exact path="/setting/c/group-type" component={GroupType} />
                <ContentRoute exact path="/setting/s/status" component={StudentStatus} />
                <ContentRoute exact path="/setting/s/config-status" component={StudentStatusConfig} />
                <ContentRoute exact path="/setting/mv/type" component={MovementType} />
                <ContentRoute exact path="/setting/mv/cause" component={MovementCause} />
                <ContentRoute exact path="/setting/tt/weekdays" component={Weekdays} />
                <ContentRoute exact path="/setting/tt/type" component={TimetableType} />
                <ContentRoute exact path="/setting/tt/selection" component={Selection} />
                <ContentRoute exact path="/setting/lang/sys" component={SystemLanguage} />
                <ContentRoute exact path="/setting/as/assessment" component={Assessment} />
                <ContentRoute exact path="/setting/as/exam-type" component={ExamType} />
                <ContentRoute exact path="/setting/as/st-item" component={StItem} />
                <ContentRoute exact path="/setting/as/st-template" component={StTemplate} />
                <ContentRoute exact path="/setting/menu/config" component={MenuConfig} />
                <ContentRoute exact path="/setting/menu/positions" component={MenuPositions} />
                <ContentRoute exact path="/selection/1" component={One} />
                <ContentRoute exact path="/selection/1/view" component={OneView} />
                <ContentRoute exact path="/selection/2" component={Two} />
                <ContentRoute exact path="/selection/class" component={ClassSelection} />
                <ContentRoute exact path="/selection/penalty" component={SchoolPenalty} />
                <ContentRoute exact path="/movement/int" component={InternalMovement} />
                <ContentRoute exact path="/movement/add-int" component={AddInternalMovement} />
                <ContentRoute exact path="/movement/add-ext" component={MovementBetweenAdd} />
                <ContentRoute exact path="/movement/out" component={SchoolMovementOut} />
                <ContentRoute exact path="/movement/out/add" component={AddOutStudent} />
                <ContentRoute exact path="/movement/excused" component={ExcusedMovement} />
                <ContentRoute exact path="/movement/excused/add" component={AddExcusedStudent} />
                <ContentRoute exact path="/movement/missing" component={MissingMovement} />
                <ContentRoute exact path="/movement/missing/add" component={AddMissingStudent} />
                <ContentRoute exact path="/student/home" component={StudentHome} />
                <ContentRoute path="/student/calendar" component={StudentCalendar} />
                <ContentRoute path="/student/selection/one" component={StudentSelectionOne} />
                <ContentRoute path="/student/selection/two" component={StudentSelectionTwo} />
                <ContentRoute path="/student/selection/index" component={StudentSelection} />
                <ContentRoute path="/student/score/table" component={StudentGrade} />
                <ContentRoute path="/student/current" component={StudentCurrent} />
                <ContentRoute path="/student/description" component={StudentDescription} />
                <ContentRoute path="/student/payment" component={Payments} />
                <ContentRoute path="/student/request" component={StudentRequest} />
                <ContentRoute path="/student/anket" component={Anket} />
                <ContentRoute path="/student/survey" component={StudentSurvey} />
                <ContentRoute path="/student/manual" component={StudentManual} />
                <ContentRoute path="/student/scholarship" component={StudentScholarship} />
                <ContentRoute path="/student/regulation" component={StudentRegulation} />
                <ContentRoute exact path="/student/online-exam" component={StudentOnlineExam} />
                <ContentRoute exact path="/student/online-exam/start" component={OnlineExamStart} />
                <ContentRoute exact path="/student/online-exam/result" component={OnlineExamResult} />
                <ContentRoute exact path="/student/online-lesson" component={StudentOnlineLesson} />
                <ContentRoute exact path="/student/online-lesson/detail" component={OnlineLessonDtl} />
                <ContentRoute exact path="/student/online-lesson/view-module" component={OnlineLessonViewModule} />
                <ContentRoute exact path="/student/online-lesson/view-homework" component={OnlineLessonViewHomework} />
                <ContentRoute exact path="/survey/list" component={OfficerSurveyPage} />
                <ContentRoute exact path="/survey/create" component={OfficerSurveyCreatePage} />
                <ContentRoute exact path="/survey/view" component={OfficerSurveyViewPage} />
                <ContentRoute exact path="/survey/edit" component={OfficerSurveyEditPage} />
                <ContentRoute exact path="/survey/report" component={OfficerSurveyReportPage} />
                <ContentRoute path="/survey/result" component={ComingSoon} />
                <ContentRoute path="/officer/student/list" component={OfficerStudentList} />
                <ContentRoute path="/officer/anket" component={Anket1} />
                <ContentRoute path="/officer/student/discount" component={ComingSoon} />
                <ContentRoute path="/officer/student/anket" component={StudentAnket} />
                <ContentRoute path="/officer/student/description" component={OfficerStudentDescription} />
                <ContentRoute path="/payment/student/list" component={OfficerStudentPayment} />
                <ContentRoute path="/result/student/info" component={OfficerStudentGrade} />
                <ContentRoute path="/request" component={OfficerRequest} />
                <ContentRoute path="/officer/rules" component={OfficerRegulation} />
                <ContentRoute path="/officer/manual" component={OfficerManual} />
                <ContentRoute path="/officer/scholarship" component={OfficerScholarship} />
                <ContentRoute path="/loan" component={SchoolLoan} />
                <ContentRoute path="/scholarship" component={SchoolScholarship} />
                <ContentRoute path="/signing" component={SchoolSigning} />
                <ContentRoute path="/teacher/timetable" component={TeacherTimetable} />
                <ContentRoute path="/teacher/journal/view" component={JournalView} />
                <ContentRoute path="/teacher/journal" component={TeacherJournal} />
                <ContentRoute path="/teacher/content" component={ComingSoon} />
                <ContentRoute path="/teacher/timesheet" component={TeacherTimeSheet} />
                <ContentRoute path="/teacher/additional" component={TeacherTimeAdditional} />
                <ContentRoute path="/teacher/print/grade" component={TeacherPrintGrade} />
                <ContentRoute path="/teacher/result" component={ComingSoon} />
                <ContentRoute path="/teacher/students" component={ComingSoon} />
                <ContentRoute path="/teacher/suggestions" component={ComingSoon} />
                <ContentRoute path="/teacher/manual" component={TeacherGuides} />
                <ContentRoute path="/teacher/announcement" component={TeacherAnnouncement} />
                <ContentRoute path="/teacher/announcement-add" component={TeacherAnnouncementAdd} />
                <ContentRoute path="/teacher/announcement-edit" component={TeacherAnnouncementEdit} />
                <ContentRoute path="/teacher/online/lesson" component={TeacherOnlineLesson} />
                <ContentRoute path="/teacher/online/lesson-add" component={TeacherOnlineLessonAdd} />
                <ContentRoute path="/teacher/online/lesson-edit/topic-add" component={TeacherOnlineLessonEditTopicAdd} />
                <ContentRoute path="/teacher/online/lesson-edit/topic-edit" component={TeacherOnlineLessonEditTopicEdit} />
                <ContentRoute path="/teacher/online/lesson-edit/topic-view" component={TeacherOnlineLessonEditTopicView} />
                <ContentRoute path="/teacher/online/lesson-edit/homework-add" component={TeacherOnlineLessonEditHomeworkAdd} />
                <ContentRoute path="/teacher/online/lesson-edit/homework-edit" component={TeacherOnlineLessonEditHomeworkEdit} />
                <ContentRoute path="/teacher/online/lesson-edit/homework-view" component={TeacherOnlineLessonEditHomeworkView} />
                <ContentRoute path="/teacher/online/lesson-edit/discussion-add" component={TeacherOnlineLessonEditDiscussionAdd} />
                <ContentRoute path="/teacher/online/lesson-edit/discussion-edit" component={TeacherOnlineLessonEditDiscussionEdit} />
                <ContentRoute path="/teacher/online/lesson-edit/discussion-view" component={TeacherOnlineLessonEditDiscussionView} />
                <ContentRoute path="/teacher/online/lesson-edit" component={TeacherOnlineLessonEdit} />
                <ContentRoute path="/teacher/file" component={TeacherOnlineFile} />
                <ContentRoute path="/teacher/file-add" component={TeacherOnlineFileAdd} />
                <ContentRoute path="/teacher/questions" component={TeacherQuestions} />
                <ContentRoute path="/teacher/question-add" component={TeacherQuestionAdd} />
                <ContentRoute exact path="/teacher/online/exam" component={TeacherOnlineExam} />
                <ContentRoute exact path="/teacher/online/exam-create" component={TeacherOnlineExamCreate} />
                <ContentRoute exact path="/teacher/online/exam-view" component={TeacherOnlineExamView} />
                <ContentRoute exact path="/teacher/online/difficulty" component={TeacherExamDifficulty} />
                <ContentRoute path="/finance/dashboard" component={ComingSoon} />
                <ContentRoute path="/finance/income/bank" component={IncomeBank} />
                <ContentRoute path="/finance/income/card" component={IncomeCard} />
                <ContentRoute path="/finance/income/undefined" component={IncomeUndefined} />
                <ContentRoute path="/finance/invoice" component={Invoice} />
                <ContentRoute path="/finance/government/loan" component={GovernmentLoan} />
                <ContentRoute path="/finance/back" component={ComingSoon} />
                <ContentRoute path="/finance/debt" component={ComingSoon} />
                <ContentRoute path="/finance/ebarimt" component={Ebarimt} />
                <ContentRoute path="/finance/loan" component={ComingSoon} />
                <ContentRoute path="/finance/scholarship" component={Scholarship} />
                <ContentRoute path="/finance/result" component={ComingSoon} />
                <ContentRoute path="/finance/config/account" component={Account} />
                <ContentRoute path="/finance/config/type" component={InvoiceType} />
                <ContentRoute path="/finance/config/discount" component={InvoiceDiscount} />
                <ContentRoute path="/finance/config/scholarship/type" component={ScholarshipType} />
                <ContentRoute path="/finance/config/subject/price" component={SubjectPrice} />
                <ContentRoute path="/finance/config/sale/loan" component={SaleLoan} />
                <ContentRoute exact path="/finance/income/student/view" component={StudentView} />
                <ContentRoute exact path="/finance/student/view" component={StudentView} />
                <ContentRoute exact path="/finance/check/invoice" component={Success} />

                <ContentRoute exact path="/exam/q/list" component={ExamQuestionList} />
                <ContentRoute exact path="/exam/q/add" component={QuestionForm} />
                <ContentRoute exact path="/exam/q/edit" component={QuestionEdit} />
                <ContentRoute path="/student/qrcode/check" component={StudentQrCode} />

                <ContentRoute path="/test/form" component={FormTest} />
                <ContentRoute path="/test/table" component={DataTableTest} />
                <ContentRoute path="/test/drag" component={DragTest} />

                <ContentRoute exact path="/enroll/setting" component={List} />
                <ContentRoute exact path="/enroll/setting/addEntry" component={AddEntry} />
                <ContentRoute exact path="/enroll/setting/editEntry" component={EditEntry} />
                <ContentRoute exact path="/enroll/dashboard" component={EnrollDashboard} />
                <ContentRoute exact path="/enroll/dashboard/details" component={DashboardDetails} />
                <ContentRoute exact path="/enroll/scholarship" component={EnrollScholarship} />
                <ContentRoute exact path="/enroll/candidate" component={Registrants} />
                <ContentRoute exact path="/enroll/non-registered" component={NonRegistrationUser} />
                <ContentRoute exact path="/enroll/candidate/profile" component={CandidateProfile} />
                <ContentRoute exact path="/enroll/report/sms" component={EnrollMessageReport} />
                <ContentRoute exact path="/enroll/report/email" component={EnrollEmailReport} />
                <ContentRoute exact path="/enroll/report/payment" component={EnrollRegistrationPayment} />

                <ContentRoute exact path="/enroll/certificate" component={EnrollCertificate} />
                <ContentRoute exact path="/enroll/certificate/number" component={EnrollCertificateNumber} />
                <ContentRoute exact path="/enroll/certificate/setting" component={EnrollCertificateModel} />
                <ContentRoute exact path="/enroll/exam" component={EnrollBody} />
                <ContentRoute exact path="/enroll/health" component={EnrollHealth} />
                <ContentRoute exact path="/enroll/psychology" component={EnrollPsychology} />
                <ContentRoute exact path="/enroll/passed/eyesh" component={Eyesh} />
                <ContentRoute exact path="/enroll/process" component={Eyesh} />

                <ContentRoute exact path="/graduation/student" component={StudentsGradution} />
                <ContentRoute exact path="/graduation/template" component={GraduationTemplate} />
                <ContentRoute exact path="/graduation/config" component={GraduationConfig} />
                <ContentRoute exact path="/graduation/information" component={AdditionalInformation } />
                {/* <ContentRoute exact path="/pdf" component={Pdf } /> */}
                {/*<Redirect exact from="/" to="/home" />*/}
                {/*<Redirect exact from="/student" to="/student/home" />*/}
                {/*<Redirect to="/error" />*/}
            </Switch>
        </Suspense>
    );
}
