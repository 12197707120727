import React, {useEffect, useState} from 'react'
import {Button, Card, Modal} from 'react-bootstrap'
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import DTable from "../../../../modules/DataTable/DTable";
import {setLoading} from "../../../../../redux/action";
import {fetchRequest} from "../../../../../utils/fetchRequest";
import {
    financeSaleTypeLoanConfigDelete,
    financeSaleTypeLoanConfigs,
    invoiceTypeInit
} from "../../../../../utils/fetchRequest/Urls";
import message from "../../../../modules/message";
import AddScholarshipType from "./add";

export const SaleLoan = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    const [showAddModal, setShowAddModal] = useState(false);
    const [configs, setConfigs] = useState([]);
    const [configId, setConfigId] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false);

    const parentSeason = useSelector(state => {
            let schoolList = state?.school?.schoolList;
            if(schoolList && schoolList.length > 0){
                for (let i = 0; i < schoolList.length; i++){
                    if(schoolId == schoolList[i]['id']){
                        return schoolList[i]['parentSeason'];
                    }
                }
            }
        }
    );

    useEffect(() => {
        const params = {
            school: schoolId,
            season: parentSeason,
        };
        init(params)
    }, []);

    const init = (params) => {
        dispatch(setLoading(true));
        fetchRequest(financeSaleTypeLoanConfigs, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setConfigs(data.configs);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const handleAddClick = () => {
        setShowAddModal(true);
    };

    const config = {
        showPagination: true,
        showFilter: true,
    };

    const columns = [
        {
            dataField: "name",
            text: t('invoiceType.paymentType'),
        },
        {
            dataField: "maxBalance",
            text: t('finance.upperLimit'),
            sort: true
        },
        {
            dataField: "",
            text: '',
            sort: false,
            headerStyle: () => ({
                width: 60,
            }),
            formatter(cell, row, index) {
                return (
                    <Button onClick={() => {deleteClick(cell, row, index)}} className="btn-danger m-btn m-btn--icon m-btn--icon-only m-btn--circle-28">
                        <i className="mini flaticon2-cross"> </i>
                    </Button>
                )
            },
        },
    ];

    const deleteClick = (cell, row, index) => {
        setConfigId(row.id);
        setDeleteModal(true);
    };

    const onModalClose = () => {
        setShowAddModal(false);
        setConfigId(null);
    };

    const onSubmit = params => {
        const bodyParams = {
            school: schoolId,
            season: parentSeason,
            ...params,
        };

        dispatch(setLoading(true));
        fetchRequest(financeSaleTypeLoanConfigs, 'POST', bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    message(data?.message || t('common.success'), true);
                    onModalClose();
                    setConfigs(data.configs)
                } else {
                    message(res?.data?.message || t('errorMessage.title'));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const handleDeleteModalClose = () => {
        setDeleteModal(false);
        setConfigId(null);
    };

    const handlerOnDeleteClick = () => {
        const params = {
            school: schoolId,
            id: configId,
        };

        dispatch(setLoading(true));
        fetchRequest(financeSaleTypeLoanConfigDelete, 'POST', params, false)
            .then(response => {
                if (response.success) {
                    setDeleteModal(false);
                    setConfigs(response.data.configs);
                    message(response.data.message, true);
                } else {
                    message(response.data.message, false);
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    return (
        <div className='sm-container'>
            <Card>
                <Card.Body>
                    <div className='row'>
                        <div className='col-12'>
                            <button
                                className='btn btn-primary'
                                onClick={handleAddClick}
                            >
                                {t('common.add')}
                            </button>
                        </div>
                        <div className='col-12'>
                            <DTable
                                columns={columns}
                                config={config}
                                data={configs}
                                selectMode={'radio'}
                            />
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <Modal
                show={deleteModal}
                onHide={handleDeleteModalClose}
                size="sm"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t('warning.delete')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {t('warning.delete_confirmation')}
                        <br/>
                        <br/>
                        {t('warning.delete_confirmation_description')}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="btn btn-link bolder" onClick={handleDeleteModalClose}>{t('common.cancel')}</Button>
                    <Button variant="success btn-shadow" onClick={handlerOnDeleteClick}>{t('warning.delete')}</Button>
                </Modal.Footer>
            </Modal>
            {
                showAddModal && (
                    <AddScholarshipType
                        onClose={onModalClose}
                        onSubmit={onSubmit}
                    />
                )
            }
        </div>
    )
};
