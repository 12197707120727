import React,{useState,useEffect} from 'react'
import { Card,Row,Col,Tabs,Tab,Button,Image,Dropdown,DropdownButton } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useSelector,useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { TextareaAutosize } from '@material-ui/core'
import Checkbox from '../../../../modules/Form/Checkbox'
import Select from '../../../../modules/Form/Select'
import DatePickerRange from '../../../../modules/Form/DatePickerRange'
import DTable from '../../../../modules/DataTable/DTable'
import ReactPlayer from 'react-player'
import { htmlDecode } from '../../../../../utils/utils'
import { teacherOnlineExamCreate,teacherOnlineExamTopic,teacherOnlineExamQuestion, teacherOnlineExamGroups, examTypeConfigAdd, teacherOnlineExamRandom, teacherOnlineExamRQuestion} from '../../../../../utils/fetchRequest/Urls'
import { fetchRequest, ROOT_URL } from '../../../../../utils/fetchRequest'
import message from '../../../../modules/message'
import { setLoading } from '../../../../../redux/action'
import Remove from './modals/Remove'
import AddTopic from './modals/AddTopic'
import AddQuestion from './modals/AddQuestion'
import OrderQuestion from './modals/OrderQuestion'
import { TrueOrFalse } from '../../../exam/question/types'
import clsx from "clsx";
import DragHandle from '../../../../modules/DataTable/DragHandle'
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import CreateQuestion from './modals/CreateQuestion'
import DateTimePickerComp from '../../../../modules/Form/DateTimePicker'
import format from 'date-fns/format';
import AddRandomQuestion from './modals/AddRandomQuestion'
import ViewRandomQuestion from './modals/ViewRandomQuestion'

const TeacherOnlineExamCreate = ({
    location: { state: {
        examId
    } = {} } = {}, }) => {

    const {t} = useTranslation()
    const dispatch = useDispatch()
    const school = useSelector(state=>state.selectedSchool?.id || null)

    const [subject,setSubject] = useState()
    const [groups,setGroups] = useState([])
    const [subjectOptions,setSubjectOptions] = useState([])
    const [groupOptions,setGroupOptions] = useState([])
    const [testName,setTestName] = useState('')
    const [duration,setDuration] = useState(0)
    const [isRepeat,setIsRepeat] = useState(false)
    const [description,setDescription] = useState('')
    
    const [tabKey,setTabKey] = useState('info')

    const [exam,setExam] = useState({})
    const [topics,setTopics] = useState([])
    const [totalTopics,setTotalTopics] = useState(1)
    const [topicQuestions, setTopicQuestions] = useState([]);
    const [questions,setQuestions] = useState([])
    const [question,setQuestion] = useState({})
    const [tempId,setTempId] = useState()
    const [tempTableData,setTempTableData] = useState({})

    const [viewDeleteModal,setViewDeleteModal] = useState(false)
    const [viewAddTopicModal,setViewAddTopicModal] = useState(false)
    const [viewAddQuestionModal,setViewAddQuestionModal] = useState(false)
    const [createQuestionModal,setCreateQuestionModal] = useState(false)
    const [viewRemoveQuestionModal,setViewRemoveQuestionModal] = useState(false)
    const [viewQuestionOrderModal,setViewQuestionOrderModal] = useState(false)
    const [subjectId, setSubjectId] = useState();
    const [tempExamId, setTempExamId] = useState();
    const history = useHistory();
    const [startDateTime,setStartDateTime] = useState(new Date())
    const [endDateTime,setEndDateTime] = useState(new Date())
    const [showAnswer,setShowAnswer] = useState(true)
    const [types, setTypes] = useState([]);
    const [type, setType] = useState(null);
    const [viewRandomModal, setViewRandomModal] = useState(false);
    const [viewRandomDeleteModal, setViewRandomDeleteModal] = useState(false);
    const [viewRandomQuestionModal, setViewRandomQuestionModal] = useState(false);

    let typeCode = '';
    //const [examId, setExamId] = useState(null);
    const topicTableCols = [
        {
            dataField: 'level',
            text: t('common.level'),
            sort: true
        },
        {
            dataField: 'topic',
            text: t('teacher.topic'),
            sort: true
        },
        {
            dataField: 'questionNumber',
            text: t('onlineExam.questionNumber'),
            sort: true
        },
        {
            dataField: 'id',
            text: '',
            sort: true,
            headerStyle: () => ({
                width: 60,
            }),
            formatter(cell,row){
                return <div className='text-center'>
                    <Button
                        className="btn-danger m-btn m-btn--icon m-btn--icon-only m-btn--circle-28"
                        onClick={()=>{
                            setViewDeleteModal(true)
                            setTempId(cell)
                        }}
                    >
                        <i className="mini flaticon2-cross"/>
                    </Button>
                </div>
            }
        }
    ]

    const randomTopicTableCols = [
        {
            dataField: 'level',
            text: t('common.level'),
            sort: true
        },
        {
            dataField: 'topic',
            text: t('teacher.topic'),
            sort: true
        },
        {
            dataField: 'type',
            text: t('common.type'),
            sort: true
        },
        {
            dataField: 'questionNumber',
            text: t('onlineExam.questionNumber'),
            sort: true
        },
        {
            dataField: 'id',
            text: '',
            sort: true,
            headerStyle: () => ({
                width: 60,
            }),
            formatter(cell,row){
                return <div className='text-center'>
                    <Button
                        className="btn-danger m-btn m-btn--icon m-btn--icon-only m-btn--circle-28"
                        onClick={()=>{
                            setViewRandomDeleteModal(true)
                            setTempId(cell)
                        }}
                    >
                        <i className="mini flaticon2-cross"/>
                    </Button>
                </div>
            }
        }
    ]

    Date.prototype.addHours = function(h) {
        this.setTime(this.getTime() + (h*60*60*1000));
        return this;
      }

    const onTableSelect = (row, isSelect, rowIndex, e) =>{
        setTempTableData({...row})
    }
    
    const filteredQuestions = () =>{
        if(questions.length){
            if(tempTableData.id && tempTableData.levelId){
                return questions.filter(q=>q.level === tempTableData.levelId && q.topic === tempTableData.id)
            }else{
                return questions
            }
        }else{
            return []
        }
    }

    const onNextQuestion = () =>{
        if(filteredQuestions().length){
            let questionList = [...filteredQuestions()]
            const index = questionList.findIndex(q=>q.id === question.id)
            if(questionList[index+1]){
                setQuestion(questionList[index+1])
            }else{
                setQuestion(questionList[0])
            }
        }
    }

    const onPrevQuestion = () =>{
        if(filteredQuestions().length){
            let questionList = [...filteredQuestions()]
            const index = questionList.findIndex(q=>q.id === question.id)
            if(questionList[index-1]){
                setQuestion(questionList[index-1])
            }else{
                setQuestion(questionList.at(-1))
            }
        }
    }

    const onQuestionMenuSelect = (key) =>{
        if(key){
            if(key === 'remove'){
                setViewRemoveQuestionModal(true)
                setTempId(question.id)
            }else if(key === 'order'){
                setViewQuestionOrderModal(true)
            }
            else if(key === 'edit'){
                setTempId(question.id);
                setCreateQuestionModal(true);
            }
        }
    }

    const onStartTimeChange = (value,date) =>{
        const startTime = date.getTime() / 60000
        const endTime = new Date(endDateTime).getTime() / 60000
        if((endTime - startTime) >= 0){
            setDuration(Math.floor(endTime - startTime))
            setStartDateTime(value)
        }
    }

    const onEndTimeChange = (value,date) =>{
        const startTime = new Date(startDateTime).getTime() / 60000
        const endTime = date.getTime() / 60000
        if((endTime - startTime) >= 0){
            setDuration(Math.ceil(endTime - startTime))
            setEndDateTime(value)
        }
    }

    const closeModals = () =>{
        if(viewDeleteModal) setViewDeleteModal(false)
        if(viewRemoveQuestionModal) setViewRemoveQuestionModal(false)
        if(viewAddTopicModal) setViewAddTopicModal(false)
        if(viewRandomDeleteModal) setViewRandomDeleteModal(false)
        if(viewRandomModal) setViewRandomModal(false);
    }

    const subjectChange = (value) =>{
        setSubject(value);
        const bodyParams = {
            'school': school,
            'subject': value
        }
        dispatch(setLoading(true));
        fetchRequest(teacherOnlineExamGroups, 'GET', bodyParams)
            .then(res => {
                dispatch(setLoading(false));
                if (res.success) {
                    const { data } = res
                    if(data?.groups?.length){
                        const sGroups = []
                        data.groups.map(group =>{
                            sGroups.push({
                                'text': group['name'],
                                'value': group['id']
                            })
                        });
                        setGroupOptions(sGroups)
                    }
                }
                  
            });
        

    } 

    const renderAnswers = answer =>{
        if(question.type === 'MULTICHOICE'){
            if(answer.filePath){
                return (
                <div className='p-4'>
                    <Checkbox
                        disabled
                        label={answer.answer}
                        className='ml-10'
                        checked={answer.isCorrect}
                    />
                    <Image
                        src={ROOT_URL+ '/' + answer.filePath}
                        height={'160px'}
                        style={
                            answer.isCorrect
                            ? 
                                {opacity:'1',cursor:'pointer'}
                            : {opacity:'.5',cursor:'pointer'}
                        }
                    />
                </div>)
            }else{
                return (
                    <Checkbox
                        disabled
                        label={answer.answer}
                        className='ml-10'
                        checked={answer.isCorrect}
                    />
                )
            }
        }else if(question.type === 'TRUEFALSE'){
            return (
                <TrueOrFalse onChange={() => {}} disabled value={answer.answer}/>
                
            )            
        }else if(question.type === 'RADIO'){
            if(answer.filePath){
                
                return (
                <div className='p-4'>
                    <Checkbox
                        disabled
                        label={answer.answer}
                        className='ml-10'
                        checked={answer.isCorrect}
                    />
                    <Image
                        src={ROOT_URL +'/'+ answer.filePath}
                        height={'160px'}
                        style={
                            answer.id === question.answer?.id
                            ? 
                                {opacity:'1',cursor:'pointer'}
                            : {opacity:'.2',cursor:'pointer'}
                        }
                    />
                </div>
                )
            }else{
                return (
                    <Checkbox
                        disabled
                        label={answer.answer}
                        className='ml-10'
                        checked={answer.isCorrect}
                    />
                )
            }
            
        }else if(question.type === 'NUMBER'){
            return (
                <span>
                    {answer.answer}
                </span>
            )
        }else if(question.type === 'CONNECT'){
            return (
                <div
                    style={{
                        marginTop:'5px',
                        display: 'flex',
                        alignItems: 'center',

                    }}>
                    <input disabled
                        className={clsx('form-control mr-5')}
                        style={{width: '250px'}}
                        value={answer.premise}
                        placeholder={t('exam.premise')}
                    />
                    <input disabled
                        className={clsx('form-control')}
                        style={{width: '250px'}}
                        value={answer.response}
                        placeholder={t('exam.premiseResponse')}
                    />
                    <input disabled
                        className={clsx('form-control mr-5')}
                        style={{width: '70px', marginLeft:'10px'}}
                        value={answer.score}
                        placeholder={t('exam.score')}
                    />
                </div>
            )
        }else if(question.type === 'ORDER'){
            return (
                <DndProvider backend={HTML5Backend}>
                    <div 
                        style={{
                            marginTop:'5px',
                            display: 'flex',
                            alignItems: 'center',
                            width: 'min-content'
                        }}>
                        <DragHandle 
                            rowClass={`order-row`}
                            data={{ ...answer }}
                            onStart={null}
                            onDrop={null}
                        />
                        <input disabled
                            className={clsx('form-control')}
                            style={{width: '250px'}}
                            value={answer.answer}
                            placeholder={t('exam.premiseResponse')}
                        />
                        <input disabled
                            className={clsx('form-control mr-5')}
                            style={{width: '70px', marginLeft:'10px'}}
                            value={answer.score}
                            placeholder={t('exam.score')}
                        />
                    </div>
                </DndProvider>
                
            )
        }else if(question.type === 'TEXTINPUT'){
            return (
                <div className='align-items-center w-100 my-2'>
                    <TextareaAutosize disabled
                        className={clsx('form-control')}
                        placeholder={t('exam.premiseResponse')}
                        rowsMin={4}
                        cols={4}
                        value={answer?.answer}
                    />
                </div>
            )
        }else if(question.type === 'NUMBERINPUT'){
            return (
                <div className='align-items-center w-100 my-2'>
                    <Row>
                        <Col md={3}>
                            <input
                                type='number' disabled
                                className={clsx('form-control')}
                                placeholder={t('exam.premiseResponse')}
                                value={answer?.answer}
                            />
                        </Col>
                        <Col md={3}>
                            {
                                answer?.answer2 ?
                                    <input
                                        type='number' disabled
                                        className={clsx('form-control')}
                                        placeholder={t('exam.premiseResponse')}
                                        value={answer?.answer2}
                                    />
                                    : null
                            }
                        </Col>
                    </Row>
                    
                    
                </div>
            )
        }else{
            return null
        }
    }

    const renderFile = (url) =>{
        if(typeof url == 'string'){
            if(/\.(jpg|jpeg|png|gif)$/.test(url)){
                return( 
                    <Image
                        src={url}
                        height={'160px'}
                    />
                )
            }else{
                return (
                    <ReactPlayer
                        width={'320px'}
                        height={'160px'}
                        url={url}
                    />
                )
            }
        }else{
            return null
        }
    }

    const handleContextMenuClick = (id, key, record) => {
        if(id){
            if(key === 'view'){
                setTempId(id);
                setViewRandomQuestionModal(true);
            }
        }
    }

    const renderTab = () =>{
        
        const tabs = {
            info: (
                <Card.Body>
                    <Row className='align-items-center my-4'>
                        <Col md={4} className='text-right'>
                            <span>
                                {t('subject.title')}
                            </span>
                        </Col>
                        <Col md={6}>
                            <Select
                                value={subject}
                                onChange={value => subjectChange(value)}
                                options={subjectOptions}
                                searchable
                                disabled={examId ? true : false}
                                required
                            />
                        </Col>
                    </Row>
                    <Row className='align-items-center my-4'>
                        <Col md={4} className='text-right'>
                            <span>
                                {t('onlineExam.groups')}
                            </span>
                        </Col>
                        <Col md={6}>
                            <Select
                                value={groups}
                                onChange={value=>setGroups(value)}
                                options={groupOptions}
                                searchable
                                multiple
                                disabled={examId ? true : false}
                            />
                        </Col>
                    </Row>
                    <Row className='align-items-center my-4'>
                        <Col md={4} className='text-right'>
                            <span>
                                {t('onlineExam.testName')}
                            </span>
                        </Col>
                        <Col md={6}>
                            <input
                                type='text'
                                value={testName}
                                onChange={e=>setTestName(e.target?.value || '')}
                                style={inputStyle}
                            />
                        </Col>
                    </Row>
                    <Row className='align-items-center my-4'>
                        <Col md={4} className='text-right'>
                            <span>
                                {t('onlineExam.date')}
                            </span>
                        </Col>
                        <Col md={3}>
                            <DateTimePickerComp
                                value={startDateTime}
                                onChange={onStartTimeChange}
                                className='w-100'
                                dateFormat="d MMM yyyy"
                            />
                        </Col>
                        <Col md={3}>
                            <DateTimePickerComp
                                value={endDateTime}
                                onChange={onEndTimeChange}
                                dateFormat="d MMM yyyy"
                                className='w-100'
                            />
                        </Col>
                    </Row>
                    <Row className='align-items-center my-4'>
                        <Col md={4} className='text-right'>
                            <span>
                                {t('onlineExam.testDuration')}
                            </span>
                        </Col>
                        <Col md={6}>
                            <input
                                type={'number'}
                                value={duration}
                                onChange={e=>setDuration(e.target?.value)}
                                style={inputStyle}
                            />
                        </Col>
                    </Row>
                    <Row className='align-items-center my-4'>
                        <Col md={4} className='text-right'>
                            <span>
                                {t('onlineExam.type')}
                            </span>
                        </Col>
                        <Col md={6}>
                            <Select
                                value={type}
                                onChange={value => setType(value)}
                                options={types}
                                searchable
                                disabled={examId ? true : false}
                                required
                            />
                        </Col>
                    </Row>
                    <Row className='align-items-center my-4'>
                        <Col md={4}></Col>
                        <Col md={6}>
                            <Checkbox
                                label={t('onlineExam.isRepeatAble')}
                                onChange={()=>setIsRepeat(!isRepeat)}
                                checked={!!isRepeat}
                            />
                        </Col>
                    </Row>
                    <Row className='align-items-center my-4'>
                        <Col md={4}></Col>
                        <Col md={6}>
                            <Checkbox
                                label={t('onlineExam.showAnswer')}
                                onChange={()=>setShowAnswer(!showAnswer)}
                                checked={!!showAnswer}
                            />
                        </Col>
                    </Row>
                    <Row className='align-items-start my-4'>
                        <Col md={4} className='text-right'>
                            <span>
                                {t('common.description')}
                            </span>
                        </Col>
                        <Col md={6}>
                            <TextareaAutosize
                                className='msue-textarea-description'
                                value={description}
                                onChange={e => {
                                    setDescription(e.target.value)
                                }}
                                rows={5}
                            />
                        </Col>
                    </Row>
                </Card.Body>
            ),
            random: (
                <Card.Body>
                    <Row className='align-items-center'>
                        <Col md={9}>
                            <button style={{minWidth:'190px'}} className='btn btn-primary btn-shadow' onClick={()=>setViewRandomModal(true)}>
                                <b>{t('onlineExam.selectFromQuestionStore')}</b>
                            </button>
                        </Col>
                        <Col md={3} className='text-right'>
                            <b>{t('common.total') + ': ' + (exam.questionNumber || 0) + ', ' + (exam.totalScore || 0)}</b>
                        </Col>
                    </Row>
                    <Card className='my-6'>
                        <Card.Header>
                            <span style={{ color: '#29569f',fontSize:'14px'}}>
                                <b>{t('onlineExam.add').toLocaleUpperCase()}</b>
                            </span>
                        </Card.Header>
                        <Card.Body>
                            <DTable
                                data={topicQuestions || []}
                                columns={randomTopicTableCols}
                                totalDataSize={totalTopics}
                                selectMode={'radio'}
                                onSelect={onTableSelect}
                                contextMenus={[{
                                    key: 'view',
                                    title: t('common.view'),
                                    icon: <i className='las la-file-alt dt-cm-item-icon'/>
                                }]}
                                onContextMenuItemClick={handleContextMenuClick}
                                individualContextMenus
                            />
                        </Card.Body>
                    </Card>
                </Card.Body>
            ), 
            question: (
                <Card.Body>
                    <Row className='align-items-center'>
                        <Col md={9}>
                            <button style={{minWidth:'190px'}} className='btn btn-primary btn-shadow mr-6' onClick={()=>{setTempId(null); setCreateQuestionModal(true)}}>
                                <b>{t('onlineExam.addNewQuestion')}</b>
                            </button>
                            <button style={{minWidth:'190px'}} className='btn btn-primary btn-shadow mr-6' onClick={()=>setViewAddQuestionModal(true)}>
                                <b>{t('onlineExam.addFromQuestionStore')}</b>
                            </button>
                            <button style={{minWidth:'190px'}} className='btn btn-primary btn-shadow' onClick={()=>setViewAddTopicModal(true)}>
                                <b>{t('onlineExam.selectFromQuestionStore')}</b>
                            </button>
                        </Col>
                        <Col md={3} className='text-right'>
                            <b>{t('common.total') + ': ' + (exam.questionNumber || 0) + ', ' + (exam.totalScore || 0)}</b>
                        </Col>
                    </Row>
                    <Card className='my-6'>
                        <Card.Header>
                            <span style={{ color: '#29569f',fontSize:'14px'}}>
                                <b>{t('onlineExam.add').toLocaleUpperCase()}</b>
                            </span>
                        </Card.Header>
                        <Card.Body>
                            <DTable
                                data={topicQuestions || []}
                                columns={topicTableCols}
                                totalDataSize={totalTopics}
                                selectMode={'radio'}
                                onSelect={onTableSelect}
                            />
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col md={6}>
                                    <span style={{ fontSize:'14px'}}>
                                        <b>{t('teacher.score') + ': ' + (question?.score || 0)}</b>
                                    </span>
                                </Col>
                                <Col md={6} className='text-right'>
                                    <Dropdown className="d-inline" onSelect={onQuestionMenuSelect}>
                                        <Dropdown.Toggle 
                                            className='btn-warning m-btn m-btn--icon m-btn--icon-only m-btn--circle-28 btn-shadow'
                                        >
                                            <i className='fas fa-ellipsis-v pl-1 pb-1' style={{color:'#000000'}}/>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                        <Dropdown.Item eventKey="edit" className='align-items-center' hidden={question.isEdit !== 0 ? true : false}>
                                            <i className='las la-edit dt-cm-item-icon'/>
                                            <span>
                                                {t('onlineExam.questionEdit')}
                                            </span>
                                        </Dropdown.Item>
                                        <Dropdown.Item eventKey="remove" className='align-items-center'>
                                            <i className='las la-trash-alt dt-cm-item-icon'/>
                                            <span>
                                                {t('onlineExam.removeFromTest')}
                                            </span>
                                        </Dropdown.Item>
                                        <Dropdown.Item eventKey="order" className='align-items-center'>
                                            <i className='las la-list dt-cm-item-icon'/>
                                            <span>
                                                {t('onlineExam.orderChange')}
                                            </span>
                                        </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            {
                                question.question
                                ?
                                    <div className='w-100' style={{fontSize:'18px'}}>
                                        <div dangerouslySetInnerHTML={{ __html: question.question }}></div>
                                    </div>
                                : null
                            }
                            <div className='w-100 ml-5'>
                                <span style={{fontSize:'14px'}}>
                                    {question.description || null}
                                </span>
                            </div>
                            <div className='w-100 mt-5' style={{maxHeight:'160px'}}>
                                {
                                    question.path ? renderFile(ROOT_URL+ '/'  +question.path) : null
                                }
                            </div>
                            {
                                question.type === 'TEXTINPUT' || question.type === 'NUMBERINPUT'
                                ?
                                    <div className='w-100 my-4'>
                                        {renderAnswers(question.answers[0])}
                                    </div>
                                : 
                                    (question.answers?.length
                                    ?
                                        question.answers.map(a=>{
                                            return (
                                                <div 
                                                    key={a.id}
                                                    className='w-100 my-2'
                                                >
                                                    {renderAnswers(a)}
                                                </div>
                                            )
                                        })
                                    : null)
                            }
                        </Card.Body>
                    </Card>
                    <Row className='my-3'>
                        <Col md={6}>
                            <Button className="btn btn-outline-success" hidden={questions.length > 1 ? false : true} onClick={onPrevQuestion}>{'<< ' + t('onlineExam.before')}</Button>
                        </Col>
                        <Col md={6} className='text-right'>
                            <Button className="btn btn-outline-success" hidden={questions.length > 1 ? false : true} onClick={onNextQuestion}>{t('onlineExam.next') + ' >>'}</Button>
                        </Col>
                    </Row>
                </Card.Body>
            )
        }

        return tabs[tabKey]
    }

    const onTopicRemove = (row) =>{
        const params = {
            topic: tempId,
            action: 'remove',
            level: tempTableData.difficultyId,
            topic: tempTableData.topicId

        }
        fetchTopic(params)
        setQuestions(filteredQuestions());
        setQuestion(questions.length > 0 ? questions[0] : null);
    }

    const onRandomTopicRemove = (row) =>{
        const params = {
            action: 'remove',
            level: tempTableData.difficultyId,
            topic: tempTableData.topicId,
            detail: tempId
        }
        fetchRandomTopic(params)
        setQuestions(filteredQuestions());
        setQuestion(questions.length > 0 ? questions[0] : null);
    }

    const onQuestionRemove = () =>{
        const params = {
            question: question.id,
            examQuestion: question.examQuestionId,
            action: 'remove'
        }
        fetchQuestions(params)
        setQuestion(questions.length > 0 ? questions[0] : null);
    }

    const onPublish = () =>{
        const params = {
            exam: examId ? examId : tempExamId,
            action: 'publish',
            isPublish: 1
        }
        fetchData(params,'POST')
        // (
        //     <Link
        //         to={{
        //             pathname: '/teacher/online/exam',
        //         }}
        //         className='btn btn-link bolder'
        //     ></Link>
        // )
    }

    const onSave = () =>{
        const params = {
            action: examId ? 'edit' : 'add',
            testName,
            duration,
            subject,
            groups: JSON.stringify(groups),
            startDate: startDateTime,
            endDate: endDateTime,
            isRepeat: isRepeat ? 1 : 0,
            showAnswer: showAnswer ? 1 : 0,
            description,
            type
        }
        fetchData(params,'POST')
    }

    const fetchData = (params,method = 'GET') =>{
        
        const bodyParams = {
            ...params,
            school,
            exam: examId ? examId : tempExamId
        }
        dispatch(setLoading(true));
        fetchRequest(teacherOnlineExamCreate, method, bodyParams)
            .then(res => { 
                if (res.success) {
                    const { data } = res
                    if(bodyParams.action === 'publish'){
                        message((res?.data?.message || t('common.success')),true);
                        history.push('/teacher/online/exam');
                    }
                    // if(data?.topics?.length){
                    //     // const tpc = []
                    //     // data.topics.map(tp=>{
                    //     //     tpc.push({
                    //     //         'text': tp['name'],
                    //     //         'value': tp['id']
                    //     //     })
                    //     // });
                    //     // setTopics(tpc)
                    //     setTopicQuestions(data.topics);
                    // }
                    if(data.topics?.length){
                        const tempTopics = []
                        data.topics.map(tpc=>{
                            tempTopics.push({
                                ...tpc,
                                contextMenuKeys: tpc.isCollect ? ['view'] : null
                            })
                        })
                        setTopicQuestions(tempTopics);
                    }
                    
                    if(data?.questions?.length){
                        setQuestions(data.questions)
                        setQuestion(data.questions[0])
                    } else{
                        setQuestions([])
                        setQuestion({})
                    }
                    if(data?.subjects?.length){
                        const subjects = []
                        data.subjects.map(subj=>{
                            subjects.push({
                                'text': subj['code'] + ' ' + subj['name'],
                                'value': subj['id']
                            })
                        });
                        setSubjectOptions(subjects);
                    }
                    if(data?.types?.length){
                        const types = []
                        data.types.map(type=>{
                            types.push({
                                'text': type['name'],
                                'value': type['id']
                            })
                        });
                        setTypes(types);
                    }

                    if(data?.totalTopics){
                        setTotalTopics(data.totalTopics)
                    }
                    if(data?.exam && typeof data.exam === 'object'){
                        setExam(data.exam)
                        setDescription(data.exam.description || '')
                        setTestName(data.exam.name || '')
                        setSubject(data.exam.subject)
                        if(data.exam.subject){
                            setSubjectId(data.exam.subject)
                        }
                            
                        subjectChange(data.exam.subject)
                        if(data.exam.groups){
                            const gp = [];
                            data.exam.groups.map(g => {
                                gp.push(g['id']);
                            })
                            setGroups(gp);
                        }
                        //setGroups(data.exam.groups)
                        setIsRepeat(data.exam.isRepeat);
                        if(typeof data.exam.showAnswer !== 'undefined'){
                            setShowAnswer(data.exam.showAnswer);    
                        }                        
                        setDuration(data.exam.duration || '');
                        if(data.exam.startDate){
                            setStartDateTime(new Date(data.exam?.startDate));
                        } 
                        if(data.exam.endDate){
                            setEndDateTime(new Date(data.exam?.endDate));
                        }
                        if(data.exam.typeCode){
                            typeCode = data.exam.typeCode;
                        }
                        if(data.exam.typeId){
                            setType(data.exam.typeId);
                        }
                    }
                    if(data?.examid){
                        examId = data.examid;// setExamId(data.exam)
                        setTempExamId(data.examid);
                    }
                    if(data?.subject){
                        setSubjectId(data.subject);
                    }
                    if(typeCode == '' && data.type){
                        typeCode = data.type;
                    }
                    if(tabKey === 'info' && method === 'POST'){                      
                        if(typeCode == 'RANDOM'){
                            setTabKey('random')    
                        } else{
                            setTabKey('question')
                        }                        
                    }
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message('errorMessage.title')
            })
    }

    const fetchRandomData = (params,method = 'GET') =>{
        
        const bodyParams = {
            ...params,
            school,
            exam: examId ? examId : tempExamId
        }
        dispatch(setLoading(true));
        fetchRequest(teacherOnlineExamCreate, method, bodyParams)
            .then(res => { 
                if (res.success) {
                    const { data } = res
                    if(bodyParams.action === 'publish'){
                        message((res?.data?.message || t('common.success')),true);
                        history.push('/teacher/online/exam');
                    }
                    // if(data?.topics?.length){
                    //     // const tpc = []
                    //     // data.topics.map(tp=>{
                    //     //     tpc.push({
                    //     //         'text': tp['name'],
                    //     //         'value': tp['id']
                    //     //     })
                    //     // });
                    //     // setTopics(tpc)
                    //     setTopicQuestions(data.topics);
                    // }
                    if(data.topics?.length){
                        const tempTopics = []
                        data.topics.map(tpc=>{
                            tempTopics.push({
                                ...tpc,
                                contextMenuKeys: tpc.isCollect ? ['view'] : null
                            })
                        })
                        setTopicQuestions(tempTopics);
                    }
                    if(data?.questions?.length){
                        setQuestions(data.questions)
                        setQuestion(data.questions[0])
                    } else{
                        setQuestions([])
                        setQuestion({})
                    }
                    if(data?.subjects?.length){
                        const subjects = []
                        data.subjects.map(subj=>{
                            subjects.push({
                                'text': subj['code'] + ' ' + subj['name'],
                                'value': subj['id']
                            })
                        });
                        setSubjectOptions(subjects);
                    }
                    if(data?.types?.length){
                        const types = []
                        data.types.map(type=>{
                            types.push({
                                'text': type['name'],
                                'value': type['id']
                            })
                        });
                        setTypes(types);
                    }

                    if(data?.totalTopics){
                        setTotalTopics(data.totalTopics)
                    }
                    if(data?.exam && typeof data.exam === 'object'){
                        setExam(data.exam)
                        setDescription(data.exam.description || '')
                        setTestName(data.exam.name || '')
                        setSubject(data.exam.subject)
                        if(data.exam.subject){
                            setSubjectId(data.exam.subject)
                        }
                            
                        subjectChange(data.exam.subject)
                        if(data.exam.groups){
                            const gp = [];
                            data.exam.groups.map(g => {
                                gp.push(g['id']);
                            })
                            setGroups(gp);
                        }
                        //setGroups(data.exam.groups)
                        setIsRepeat(data.exam.isRepeat);
                        if(typeof data.exam.showAnswer !== 'undefined'){
                            setShowAnswer(data.exam.showAnswer);    
                        }                        
                        setDuration(data.exam.duration || '');
                        if(data.exam.startDate){
                            setStartDateTime(new Date(data.exam?.startDate));
                        } 
                        if(data.exam.endDate){
                            setEndDateTime(new Date(data.exam?.endDate));
                        }
                        if(data.exam.typeCode){
                            typeCode = data.exam.typeCode;
                        }
                        if(data.exam.typeId){
                            setType(data.exam.typeId);
                        }
                    }
                    if(data?.examid){
                        examId = data.examid;// setExamId(data.exam)
                        setTempExamId(data.examid);
                    }
                    if(data?.subject){
                        setSubjectId(data.subject);
                    }
                    if(typeCode == '' && data.type){
                        typeCode = data.type;
                    }
                    if(tabKey === 'info' && method === 'POST'){
                        if(typeCode == 'RANDOM'){
                            setTabKey('random')    
                        } else{
                            setTabKey('question')
                        }   
                    }
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message('errorMessage.title')
            })
    }

    const fetchTopic = (
        params = {},
        method = 'POST',
        setTopics =()=>{},
        setLevels =()=>{}
    ) =>{
        const bodyParams = {
            ...params,
            school,
            exam: examId ? examId : tempExamId,
            subject: subjectId ? subjectId : subject
        }
        dispatch(setLoading(true));
        fetchRequest(teacherOnlineExamTopic, method, bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res

                    if(data.levels?.length){
                        const levels = [];
                        data.levels.map(lvl =>{
                            levels.push({
                                'text': lvl['name'],
                                'value': lvl['id']
                            })
                        })
                        setLevels(levels)
                    }

                    if(data.topics?.length){
                        const tpc = [];
                        data.topics.map(t =>{
                            tpc.push({
                                'text': t['name'],
                                'value': t['id']
                            })
                        })
                        setTopics(tpc);
                    }
                    fetchData({exam:examId ? examId : tempExamId})
                    if(method === 'POST'){
                        closeModals()
                    }
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }

    const fetchRandomTopic = (
        params = {},
        method = 'POST',
        setTopics =()=>{},
        setLevels =()=>{},
        setQuestionList =()=>{}
    ) =>{
        const bodyParams = {
            ...params,
            school,
            exam: examId ? examId : tempExamId,
            subject: subjectId ? subjectId : subject
        }
        dispatch(setLoading(true));
        fetchRequest(teacherOnlineExamRandom, method, bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res

                    if(data.levels?.length){
                        const levels = [];
                        data.levels.map(lvl =>{
                            levels.push({
                                'text': lvl['name'],
                                'value': lvl['id']
                            })
                        })
                        setLevels(levels)
                    }

                    if(data.topics?.length){
                        const tpc = [];
                        data.topics.map(t =>{
                            tpc.push({
                                'text': t['name'],
                                'value': t['id']
                            })
                        })
                        setTopics(tpc);
                    }
                    if(data.questions?.length){
                        setQuestionList(data.questions)
                    }
                    
                    if(method === 'POST'){
                        closeModals()
                        fetchRandomData({exam:examId ? examId : tempExamId})
                    }
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }
    const fetchQuestions = (
        params = {
            exam: examId ? examId : tempExamId,
            school: school,
            subject: subjectId
        },
        method = 'POST',
        setTopics =()=>{},
        setLevels =()=>{},
        setQuestionList =()=>{}
    ) =>{
        const bodyParams = {
            ...params,
            school: school,
            exam: examId ? examId : tempExamId,
            subject: subjectId
        }
        dispatch(setLoading(true));
        fetchRequest(teacherOnlineExamQuestion, method, bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res

                    if(data.levels?.length){
                        const levels = [];
                        data.levels.map(lvl =>{
                            levels.push({
                                'text': lvl['name'],
                                'value': lvl['id']
                            })
                        })
                        setLevels(levels)
                    }
                    if(data.topics?.length){
                        const tpc = [];
                        data.topics.map(t =>{
                            tpc.push({
                                'text': t['name'],
                                'value': t['id']
                            })
                        })
                        setTopics(tpc)
                    }
                    if(data.questions?.length){
                        setQuestionList(data.questions)
                    }
                    if(method === 'POST'){
                        message((res?.data?.message || t('common.success')),true)
                        closeModals()
                        fetchData({exam:examId})                        
                    }
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }

    const fetchRandomQuestions = (
        params = {
            exam: examId ? examId : tempExamId,
            school: school,
            subject: subjectId,
            detail: tempId
        },
        method = 'GET',
        setQuestionList =()=>{}
    ) =>{
        const bodyParams = {
            ...params,
            school: school,
            exam: examId ? examId : tempExamId,
            subject: subjectId,
            detail: tempId
        }
        dispatch(setLoading(true));
        fetchRequest(teacherOnlineExamRQuestion, method, bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res;

                    if(data.questions?.length){
                        setQuestionList(data.questions)
                    }
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }

    useEffect(()=>{
        if(examId){
            fetchData({exam:examId, action:'init'}, 'POST')
        } else {
            fetchData({exam:examId})        
        }
    },[subjectId, tempExamId, typeCode])

    return (
        <Card>
            <Card.Header>
                {
                    examId
                    ?
                        <Tabs
                            id={'msue-sm-online-lesson'}
                            activeKey={tabKey}
                            className='msue-tab'
                            onSelect={key=>setTabKey(key)}
                        >
                            <Tab eventKey={'info'} title={t('menu.generalInformation')}/>
                            {
                                typeCode === 'CHOOSE' ? 
                                    <Tab eventKey={'question'} title={t('onlineExam.question')}/> 
                                : <Tab eventKey={'random'} title={t('onlineExam.question')}/> 
                            } 
                        </Tabs>
                    : <span style={{ color: '#29569f',fontSize:'14px'}}>
                        <b>{t('onlineExam.add').toLocaleUpperCase()}</b>
                    </span>
                }
            </Card.Header>
            {renderTab()}
            <Card.Footer>
                <div className='w-100 text-center'>
                    <Link
                        to={{
                            pathname: '/teacher/online/exam',
                        }}
                        className='btn btn-link bolder'
                    >
                        {t('common.backTo_list').toLocaleUpperCase()}
                    </Link>
                    {
                        tabKey === 'question' || tabKey === 'random'
                        ?
                            <Button style={deepGbtnStyle} onClick={onPublish}>{t('action.publish').toLocaleUpperCase()}</Button>
                        : <Button style={btnStyle} onClick={onSave}>
                            {
                                examId
                                ?
                                    t('common.save').toLocaleUpperCase()
                                : t('common.create').toLocaleUpperCase()
                            }
                        </Button>
                    }
                </div>
            </Card.Footer>
            {
                viewDeleteModal
                ?
                    <Remove
                        onClose={()=>{
                            setViewDeleteModal(false)
                            setTempId(null)
                        }}
                        title={t('onlineExam.removeTopic')}
                        children={(
                            <>
                                <div className='w-100'>
                                    <span className='w-100'>{t('onlineExam.topicRemoveConfirmation')}</span>
                                </div>
                                <div>
                                    <span className='w-100'>{t('onlineExam.topicRemoveWarning')}</span>
                                </div>
                            </>
                        )}
                        onRemove={onTopicRemove}
                    />
                : null
            }
            {
                viewRandomDeleteModal
                ?
                    <Remove
                        onClose={()=>{
                            setViewRandomDeleteModal(false)
                            setTempId(null)
                        }}
                        title={t('onlineExam.removeTopic')}
                        children={(
                            <>
                                <div className='w-100'>
                                    <span className='w-100'>{t('onlineExam.topicRemoveConfirmation')}</span>
                                </div>
                                <div>
                                    <span className='w-100'>{t('onlineExam.topicRemoveWarning')}</span>
                                </div>
                            </>
                        )}
                        onRemove={onRandomTopicRemove}
                    />
                : null
            }
            {
                viewRemoveQuestionModal
                ?
                    <Remove
                        onClose={()=>{
                            setViewRemoveQuestionModal(false)
                            setTempId(null)
                        }}
                        title={t('onlineExam.removeFromTest')}
                        children={(
                            <>
                                <div className='w-100'>
                                    <span className='w-100'>{t('onlineExam.questionRemoveConfirmation')}</span>
                                </div>
                            </>
                        )}
                        onRemove={onQuestionRemove}
                    />
                : null
            }
            {
                viewAddTopicModal
                ?
                    <AddTopic
                        onClose={()=>setViewAddTopicModal(false)}
                        fetchData={fetchTopic}
                        random={typeCode == 'RANDOM' ? true : false}
                    />
                : null
            }
            {
                viewRandomModal
                ?
                    <AddRandomQuestion
                        onClose={()=>setViewRandomModal(false)}
                        fetchData={fetchRandomTopic}
                        //examId={exam.id?exam.id : examId}
                        subjectId={subjectId}
                    />
                : null
            }
            {
                viewRandomQuestionModal
                ?
                    <ViewRandomQuestion
                        onClose={()=>setViewRandomQuestionModal(false)}
                        fetchData={fetchRandomQuestions}
                        //examId={exam.id?exam.id : examId}
                        subjectId={subjectId}
                    />
                : null
            }
            {
                viewAddQuestionModal
                ?
                    <AddQuestion
                        onClose={()=>{
                            setViewAddQuestionModal(false)
                            fetchData({exam:examId ? examId : tempExamId})
                        }}
                        fetchData={fetchQuestions}
                        //examId={exam.id?exam.id : examId}
                        subjectId={subjectId}
                    />
                : null
            }
            {
                createQuestionModal
                ?
                    <CreateQuestion
                        onClose={()=>{
                            setCreateQuestionModal(false)
                            fetchData({exam:examId ? examId : tempExamId})
                        }}
                        //fetchData={fetchQuestions}
                        examId={examId ? examId : tempExamId}
                        subjectId={subjectId}
                        questionId={tempId}
                    />
                : null
            }
            {
                viewQuestionOrderModal
                ?
                    <OrderQuestion
                        onClose={()=>{
                            setViewQuestionOrderModal(false)
                            fetchData({exam:examId ? examId : tempExamId})
                        }}
                        questions={questions}
                        fetchData={fetchQuestions}
                    />
                : null
            }
        </Card>
    )
}

const inputStyle = {
    border: 'solid 1px #919bc0',
    borderRadius: '4px',
    alignItems: 'center',
    minHeight: '38px',
    width: '100%',
    outline: '0',
    padding: '2px 8px'
}

const btnStyle = {
    backgroundColor: '#299f72',
    border: 'none',
    borderRadius: '6px',
    color: '#ffffff',
    boxShadow: '0 2px 10px 0 #299f7233'
}

const deepGbtnStyle = {
    backgroundColor: '#014812',
    border: 'none',
    borderRadius: '6px',
    color: '#ffffff',
    boxShadow: '0 2px 10px 0 #014812'
}

export default TeacherOnlineExamCreate