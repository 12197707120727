import React, { useEffect, useRef, useState } from 'react';
import { Modal, Button, Row, Col, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { format, formatISO } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { getSubjectListBySearchValue, selectionGetSubjectData, surveyCreate, surveyInfoRoles } from '../../../../../../utils/fetchRequest/Urls';
import { fetchRequest } from '../../../../../../utils/fetchRequest';
import { setLoading } from "../../../../../../redux/action";
import message from "../../../../../modules/message";
import useLocalStorage from '../../hooks/useLocalStorage';
import DTable from '../../../../../modules/DataTable/DTable';
import DeleteModal from '../../../../../modules/DeleteModal'
import Forms from '../../../../../modules/Form/Forms';

const CreateSurveyContainer = ({ show, setShow, onSubmit }) => {
    const history = useHistory();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const formRef = useRef();
    const { selectedSchool } = useSelector(state => state || null);

    const [categories, setCategories] = React.useState([]);
    const [selectedCode, setSelectedCode] = React.useState([]);
    const [subjects, setSubjects] = React.useState([]);

    const [code, setCode] = useState('');
    const [name, setName] = useState('');
    const [categoryId, setCategoryId] = useState(null);
    const [subjectId, setSubjectId] = useState(null);
    const [purpose, setPurpose] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [subjectValue, setSubjectValue] = useState('');

    const fetch = () => {
        dispatch(setLoading(true));
        const postData = {
            school: selectedSchool?.id,
        };

        fetchRequest(surveyCreate, 'GET', postData)
            .then((res) => {
                const { success = false, data } = res;

                if (success) {
                    setCategories(data?.categories || []);
                } else {
                    message(data.message || t('errorMessage.title'));
                }

                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            });
    };

    useEffect(() => {
        fetch();
    }, []);

    const onCodeChange = (evt) => {
        setCode(evt.target.value)
    };

    const onNameChange = (evt) => {
        setName(evt.target.value)
    };

    const handlerCategory = (id, evt) => {
        setCategoryId(id)
        if(evt.code == 'SUBJECT_SURVEY'){
            setSelectedCode(evt.code)
        }
    };

    const handlerSubject = (id, evt) => {
        setSubjectId(id)
    };

    const handlerInputSubject = (value) => {
        setSubjectValue(value)
    }

    useEffect(() => {
        let timeout;
        if (subjectValue) {
            timeout = setTimeout(() => {
                let params = {
                    text: subjectValue
                };

                dispatch(setLoading(true));
                fetchRequest(getSubjectListBySearchValue, 'GET', params)
                    .then(res => {
                        if (res.success) {
                            const { data } = res;
                            setSubjects(data.subjects);
                        } else {
                            message(res?.data?.message || t('errorMessage.title'))
                        }
                        dispatch(setLoading(false));
                    })
                    .catch(() => {
                        dispatch(setLoading(false));
                        message(t('errorMessage.title'))
                    })
            }, 1000);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [subjectValue]);

    const handlerPurpose = (evt) => {
        setName(evt.target.value)
    };

    const onDateRangeChange = (dates) => {
        if(dates && dates.length > 0){
            if(dates[0].startDate){
                setStartDate(dates[0].startDate)
            }
            if(dates[0].endDate){
                setEndDate(dates[0].endDate)
            }
        }
    };

    const fields = [
        {
            key: 'code',
            value: code || '',
            label: `${t('common.code')}*`,
            type: 'nonCryllic',
            upperCase: true,
            required: true,
            placeHolder: t('errorMessage.enterCode'),
            errorMessage: t('errorMessage.enterCode'),
            labelBold: true,
            onChange: onCodeChange,
        },
        {
            key: 'name',
            value: name || '',
            label: `${t('survey.name')}*`,
            type: 'text',
            required: true,
            placeHolder: t('errorMessage.enterName'),
            errorMessage: t('errorMessage.enterValue'),
            labelBold: true,
            onChange: onNameChange,
        },
        {
            key: 'category',
            value: categoryId || null,
            label: t('survey.category') + '*',
            type: 'dropdown',
            required: true,
            errorMessage: t('errorMessage.enterValue'),
            labelBold: true,
            options: categories,
            onChange: handlerCategory,
        },
        {
            key: 'date',
            label: `${t('survey.date')}*`,
            type: 'daterange',
            selectedStartDate: startDate ? startDate : null,
            selectedEndDate: endDate ? endDate : null,
            errorMessage: t('errorMessage.enterValue'),
            labelBold: true,
            hidden: selectedCode == 'SUBJECT_SURVEY' ? false : true,
            onChange: onDateRangeChange,
        },
        {
            key: 'subject',
            value: subjectId || null,
            label: t('subject.title') + '*',
            type: 'dropdown',
            required: selectedCode == 'SUBJECT_SURVEY' ? true : false,
            errorMessage: t('errorMessage.enterValue'),
            labelBold: true,
            options: subjects,
            onChange: handlerSubject,
            onInputChange: handlerInputSubject,
            searchable: true,
            hidden: selectedCode == 'SUBJECT_SURVEY' ? false : true
        },
        {
            key: 'purpose',
            value: purpose || '',
            label: `${t('survey.goal')}`,
            type: 'textArea',
            required: false,
            labelBold: true,
            onChange: handlerPurpose,
        },
    ];

    useEffect(() => {
        formRef?.current?.updateFields && formRef.current?.updateFields(fields);
    }, [categories, selectedCode, subjects]);

    const onSaveClick = () => {
        const [isValid, , values] = formRef.current.validate();
        if (isValid) {
            const postData = {
                school: selectedSchool?.id,
                code: code || null,
                name: name || null,
                purpose: purpose || null,
                category: categoryId || null,
                subject: subjectId || null,
                start: startDate || null,
                end: endDate || null,
            };

            dispatch(setLoading(true));
            fetchRequest(surveyCreate, 'POST', postData)
                .then((res) => {
                    const { success = false, data } = res;
                    if (success) {
                        history.push("/survey/edit", {
                            survey: data.id
                        });
                    } else {
                        message(data.message || t('errorMessage.title'));
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'));
                });
        }
    };

    return (
        <Card>
            <Card.Header>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <span className='french-blue fs-10'>
                        <span>{t('survey.title')}</span>
                    </span>
                </div>
            </Card.Header>
            <Card.Body className="px-0">
                <Forms ref={formRef} fields={fields} />
            </Card.Body>
            <Card.Footer>
                <div className="text-center">
                    <Link to="/survey/list">
                        <Button size="sm" variant="link">
                            {t('common.back')}
                        </Button>
                    </Link>
                    <Button variant="success" className="br-8 ps-4 pe-4" size="sm" onClick={onSaveClick}>
                        {t('survey.save')}
                    </Button>
                </div>
            </Card.Footer>
        </Card>
    );
};

export default CreateSurveyContainer;
