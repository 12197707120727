import React, {useEffect, useRef, useState} from 'react';
import {Button, ButtonToolbar, Card, Modal} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import Select from "../../../modules/Form/Select";
import {setLoading} from "../../../../redux/action";
import {fetchRequest} from "../../../../utils/fetchRequest";
import {AddSubjectStudentSelection} from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import DTable from "../../../modules/DataTable/DTable";

const Approved = ({
    selectionData,
    totalCredit,
    totalSubjectCount,
    totalCreditPrice
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const languages = useSelector(state => state?.languages?.languages || []).filter(lang => lang.code !== 'mn');
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [ timetables, setTimetables ] = useState([]);
    const [ days, setDays ] = useState([]);
    const [ times, setTimes ] = useState([]);

    useEffect(() => {
        dispatch(setLoading(true));
        const params = {
            school: schoolId,
            selection: 2,
        };
        fetchRequest(AddSubjectStudentSelection, 'GET', params)
            .then(res => {
                const { data } = res;
                if (res.success) {
                    setTimetables(data.timetables);
                    setDays(data.days);
                    setTimes(data.times);
                } else {
                    message(data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
            });
    }, []);

    const getCellSubjects = (day, period) => {
        return timetables.map(data => {
            if(data.timeId === period && day === data.dayId){
                if(data.pTimeCode.toLowerCase() === 'laboratory'){
                    return (
                        <div
                            key={'day_' + day + '_time_' + period + '_id_' + data.id}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: 6,
                                backgroundColor: 'rgba(245, 175, 4, 0.5)',
                                marginBottom: 5
                            }}
                        >
                            <span>{data.subjectCode}</span>
                            <span>{data.subjectName}</span>
                            <span>{data.teacherFirstName}</span>
                            <span>{data.pTimeName}</span>
                            <span>{data.roomNumber}</span>
                        </div>
                    )
                } else if (data.pTimeCode.toLowerCase() === 'seminar') {
                    return (
                        <div
                            key={'day_' + day + '_time_' + period + '_id_' + data.id}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: 6,
                                backgroundColor: 'rgba(54, 163, 247, 0.5)',
                                marginBottom: 5
                            }}
                        >
                            <span>{data.subjectCode}</span>
                            <span>{data.subjectName}</span>
                            <span>{data.teacherFirstName}</span>
                            <span>{data.pTimeName}</span>
                            <span>{data.roomNumber}</span>
                        </div>
                    )
                } else {
                    return (
                        <div
                            key={'day_' + day + '_time_' + period + '_id_' + data.id}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: 6,
                                backgroundColor: '#d8d8d8',
                                marginBottom: 5
                            }}
                        >
                            <span>{data.subjectCode}</span>
                            <span>{data.subjectName}</span>
                            <span>{data.teacherFirstName}</span>
                            <span>{data.pTimeName}</span>
                            <span>{data.roomNumber}</span>
                        </div>
                    )
                }
            }
        })
    };


    return (
        <Card.Body>
            <div className="col-12 pl-0">
                <div className='col-12 pink-red'>
                    <b>
                        {
                            selectionData
                            ?
                                selectionData.parentSeasonName + ' ' + t('common.year') + ', ' + selectionData.seasonName + ' ' + t('selection.selectionTwoApprovedText')
                            : null
                        }
                    </b>
                </div>
            </div>
            <div className='mt-5'>
                <Card>
                    <Card.Body>
                        <div className='row'>
                            <div className='col-12'>
                                <h6 className='dark-blue text-uppercase'>{t('subject.selected')}</h6>
                            </div>
                            <div className='col-12 mb-4'>
                                <table className='ml-12'>
                                    <tbody>
                                    <tr>
                                        <td className='text-right'>{t('total.subject')}:</td>
                                        <td className='pl-4 strong-blue font-w-bold'>{totalSubjectCount || 0}</td>
                                    </tr>
                                    <tr>
                                        <td className='text-right'>{t('total.credit')}:</td>
                                        <td className='pl-4 strong-blue font-w-bold'>{totalCredit || 0}</td>
                                    </tr>
                                    <tr>
                                        <td className='text-right'>{t('total.price')}:</td>
                                        <td className='pl-4 strong-blue font-w-bold'>{totalCreditPrice || 0}₮</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className='col-12 font-w-bold dark-blue'>
                                {t('subject.generalAcademicSubject')}
                            </div>
                        </div>
                        <table className='table table-bordered'>
                            <thead>
                            <tr>
                                <th>{t('subject.time')}</th>
                                {
                                    days.map(day => {
                                        return (
                                            <th key={day.id}>
                                                {day.day}
                                            </th>
                                        )
                                    })
                                }
                            </tr>
                            </thead>
                            <tbody>
                            {
                                times && times.length > 0
                                ?
                                    times.map(period => {
                                        const time = `${period.startTime}-${period.endTime}` + ' (' + period.name + ')';
                                        return (
                                            <tr key={period.id}>
                                                <td>{time}</td>
                                                {
                                                    days.map(day => {
                                                        return (
                                                            <td key={`${day.id}-${period.id}`}>
                                                                {getCellSubjects(day.id, period.id)}
                                                            </td>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        )
                                    })
                                : null
                            }
                            </tbody>
                        </table>
                    </Card.Body>
                </Card>
            </div>
        </Card.Body>
    );
};

export default Approved;