import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Card,Row,Col,Button,Tabs,Tab } from 'react-bootstrap'
import DTable from '../../../../modules/DataTable/DTable'
import { teacherOnlineExamExcel, teacherOnlineExamView } from '../../../../../utils/fetchRequest/Urls'
import { fetchRequest, fetchRequestFile, MAIN_URL } from '../../../../../utils/fetchRequest'
import message from '../../../../modules/message'
import { setLoading } from '../../../../../redux/action'
import { useSelector,useDispatch } from 'react-redux'
import { saveAs } from 'file-saver'
import ViewAnswer from './modals/ViewAnswer'

const TeacherOnlineExamView = ({
    location: { state: {
        examId = null
    } = {} } = {}, }) => {

    const {t} = useTranslation()
    const dispatch = useDispatch()

    const school = useSelector(state=>state.selectedSchool?.id || null)

    const [examObj,setExamObj] = useState({})
    const [studentList,setStudentList] = useState([])
    const [tabKey,setTabKey] = useState('')
    const [viewQuestionModal, setViewQuestionModal] = useState(false);
    const [studentId, setStudentId] = useState(0);

    const examRows = [
        {
            key: 'date',
            text: t('onlineExam.examDate'),
            table: 1
        },
        {
            key: 'name',
            text: t('onlineExam.testName'),
            table: 1
        },
        {
            key: 'description',
            text: t('onlineExam.testDescription'),
            table: 1
        },
        {
            key: 'totalStudents',
            text: t('onlineExam.totalStudents'),
            table: 1
        },
        {
            key: 'examStudents',
            text: t('onlineExam.testStudents'),
            table: 1
        },
        {
            key: 'notStarted',
            text: t('onlineExam.notStarted'),
            table: 2
        },
        {
            key: 'inProgress',
            text: t('onlineExam.inProgress'),
            table: 2
        },
        {
            key: 'sent',
            text: t('attendance.sent'),
            table: 2
        }
    ]   

    const tableCols = [
        {
            dataField: 'code',
            text: t('student.code'),
            sort: true,
            csvFormatter(cell,row,rowIndex) {
                return cell || '-'
            }
        },
        {
            dataField: 'lastName',
            text: t('student.lastName'),
            sort: true,
            csvFormatter(cell,row,rowIndex) {
                return cell || '-'
            }
        },
        {
            dataField: 'firstName',
            text: t('student.name'),
            sort: true,
            csvFormatter(cell,row,rowIndex) {
                return cell || '-'
            }
        },
        {
            dataField: 'status',
            text: t('common.status'),
            sort: true,
            formatter(cell,row){
                if(cell == 'NOTSTARTED'){
                    return <Button className='btn-secondary'>{t('onlineExam.notStarted')}</Button>
                }else if(cell == 'SENT'){
                    return <Button className='btn-success'>{t('attendance.sent')}</Button>
                }else{
                    return <Button className='btn-primary'>{t('onlineExam.inProgress')}</Button>
                }
            },
            csvFormatter(cell,row,rowIndex) {
                if(cell == 'NOTSTARTED'){
                    return t('onlineExam.notStarted')
                }else if(cell == 'SENT'){
                    return t('attendance.sent')
                }else{
                    return t('onlineExam.inProgress')
                }
            }
        },
        {
            dataField: 'takenScore',
            text: t('teacher.score'),
            sort: true,
            csvFormatter(cell,row,rowIndex) {
                return cell || '-'
            }
        },
        {
            dataField: 'progress',
            text: t('onlineExam.progress'),
            sort: true,
            csvFormatter(cell,row,rowIndex) {
                return cell || '-'
            }
        }
    ]

    const handleContextMenuClick = (id, key, record) => {
        if(id){
            if(key === 'activate'){
                fetchData({student: id},'POST')
            } else if(key == 'question'){
                setViewQuestionModal(true);
                setStudentId(id);
            }
        }
    }

    const handleExport = (evt) => {
        fetchDataExcel();
    }

    const onTabChange = key =>{
        if(examObj.groups?.length){
            const group = examObj.groups.find(data=>data.id == key)
            if(group){
                setStudentList(group.students || [])
            }
        }

        setTabKey(key)
    }

    const renderTabs = () =>{
        if(examObj.groups?.length){
            return examObj.groups.map(group=>{
                return <Tab key={group.id} eventKey={group.id} title={group.name}/>
            })
        }else{
            return null
        }
    }

    const renderTr = index =>{
        if(examObj.id){
            return examRows.map(row=>{
                if(index === row.table){
                    return <tr key={row.key}>
                        <td>{row.text}</td>
                        <th>{examObj[row.key]}</th>
                    </tr>
                }else{
                    return null
                }
            })
        }else{
            return null
        }
    }

    const fetchData = (params={},method='GET') =>{
        const bodyParams = {
            ...params,
            school,
            exam:examId
        }
        dispatch(setLoading(true));
        fetchRequest(teacherOnlineExamView, method, bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res
                    if(method == 'POST'){
                        message(t('common.success'),true);
                    }
                    if(data?.examObj){
                        setExamObj(data.examObj)
                        if(data.examObj.groups?.length){
                            setTabKey(data.examObj.groups[0]?.id)
                            if(data.examObj.groups[0]?.students?.length){
                                setStudentList(data.examObj.groups[0]?.students)
                            }
                        }
                    }
                    
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }

    const fetchDataExcel = (params={},method='GET') =>{
        const bodyParams = {
            ...params,
            school,
            exam:examId
        }

        //window.open(MAIN_URL + teacherOnlineExamExcel + '/?exam=' + examId)
        fetchRequest(teacherOnlineExamExcel, 'POST', bodyParams)
        .then(res => {
            if(res.success){
                fetchRequestFile(teacherOnlineExamExcel, 'GET', bodyParams)
                    .then(res => {
                        return res.blob();
                    })
                    .then(blob => {
                        dispatch(setLoading(false));
                        if(blob){
                            saveAs(blob, res.data?.name);
                        }
                    })
                    .catch((e) => {
                        dispatch(setLoading(false));
                        message(t('errorMessage.title'));
                    });
            }
        })
        .catch((e) => {
            dispatch(setLoading(false));
            message(t('errorMessage.title'));
        });
    }
    let cmenu = [];
    if(examObj.isRepeat){
        cmenu = [
            {
                key: 'question',
                title: t('onlineExam.check'),
                icon: <i className='la la-list-alt dt-cm-item-icon'/>
            } ,
            {
                key: 'activate',
                title: t('onlineExam.activate'),
                icon: <i className='la la-sync-alt dt-cm-item-icon'/>
            
            }
        ];
    } else{
        cmenu = [
            {
                key: 'question',
                title: t('onlineExam.check'),
                icon: <i className='la la-list-alt dt-cm-item-icon'/>
            } 
        ];
    }
    
    
    useEffect(()=>{
        fetchData()
    },[])

    return (
        <div className='w-100'>
            <Card className='mb-4'>
                <Card.Header>
                    <span style={{ color: '#29569f',fontSize:'14px'}}>
                        <b>{examObj.name}</b>
                    </span>
                </Card.Header>
                <Card.Body>
                    <Row className='align-items-center'>
                        <Col md={6}>
                            <table className='info-table w-100'>
                                <tbody>
                                    {renderTr(1)}
                                </tbody>
                            </table>
                        </Col>
                        <Col md={6}>
                            <table className='info-table w-100'>
                                <tbody>
                                    {renderTr(2)}
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Card>
                <Card.Header>
                    <Tabs
                        id={'msue-sm-online-lesson'}
                        activeKey={tabKey}
                        className='msue-tab'
                        onSelect={onTabChange}
                    >
                        {renderTabs()}
                    </Tabs>
                </Card.Header>
                <Card.Body>
                    <DTable
                        data={studentList || []}
                        columns={tableCols}
                        config={{
                            showPagination: false,
                            showFilter: true,
                            defaultSort: [
                                {
                                    dataField: 'firstName',
                                    order: 'asc'
                                },
                            ],
                            showAllData: true,
                            customExport: true
                        }}
                        onExport= {handleExport}
                        selectMode={'ROW_SELECT_DISABLED'}
                        contextMenus={cmenu}
                        onContextMenuItemClick={handleContextMenuClick}
                    />
                </Card.Body>
                {
                viewQuestionModal
                ?
                    <ViewAnswer
                        onClose={()=>{
                            setViewQuestionModal(false)
                            setStudentId(0);
                            fetchData();
                        }}
                        exam={examId}
                        school={school}
                        student={studentId}
                    />
                : null
            }
            </Card>
        </div>
    )
}

export default TeacherOnlineExamView