import React, {useRef, useEffect, useState} from 'react';
import {Button, ButtonToolbar, Card} from "react-bootstrap";
import Forms from "../../../../modules/Form/Forms";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {teacherOnlineLessonHomeworkEdit} from "../../../../../utils/fetchRequest/Urls";
import { setLoading } from "../../../../../redux/action";
import { fetchRequest } from "../../../../../utils/fetchRequest";
import message from "../../../../modules/message";
import {Link, useHistory} from "react-router-dom";

export const TeacherOnlineLessonEditHomeworkEdit = ({
    location: {state: {id = null, homeworkId = null} = {}} = {},
}) => {
    const { t } = useTranslation();
    const formRef = useRef();
    const formFileRef = useRef();
    const history = useHistory();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [types, setTypes] = useState([]);
    const [file, setFile] = useState([]);
    const [newFile, setNewFile] = useState([]);
    const [homeworkData, setHomeworkData] = useState([]);

    useEffect(() => {
        const params = {
            school: schoolId,
            course: id,
            homework: homeworkId
        };

        dispatch(setLoading(true));
        fetchRequest(teacherOnlineLessonHomeworkEdit, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setTypes(data?.types || []);
                    setHomeworkData(data?.homeworkData);

                    if(data?.homeworkData?.fileId){
                        setFile([{
                            id: data?.homeworkData.fileId,
                            name: data?.homeworkData.fileName,
                        }]);
                    }
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }, []);

    useEffect(() => {
        formRef?.current?.updateFields && formRef.current?.updateFields(fields);
    }, [types, homeworkData]);

    const onFileChange = (file, evt, action) => {
        if(action == 'clear'){
            setNewFile({})
        } else {
            setNewFile(file[0])
        }
    };

    const fields = [
        {
            key: 'name',
            label: t('homework.name'),
            labelWidth: 300,
            value: homeworkData?.name || '',
            type: 'text',
            required: true,
        },
        {
            key: 'description',
            label: t('homework.description'),
            labelWidth: 300,
            value: homeworkData?.description ||  '',
            type: 'textArea',
            rows: 3,
            required: true,
        },
        {
            key: 'score',
            label: t('homework.score'),
            labelWidth: 300,
            labelAlign: 'top',
            value: homeworkData?.score || '',
            type: 'number',
            required: true,
        },
        {
            key: 'type',
            label: t('homework.answerType'),
            labelWidth: 300,
            labelAlign: 'top',
            value: homeworkData?.typeIds || null,
            type: 'dropdown',
            searchable: true,
            clearable: true,
            multiple: true,
            options: types || [],
            required: true,
        },
        {
            key: 'startDate',
            label: t('homework.startDateTime'),
            labelWidth: 300,
            value: homeworkData?.startDate || '',
            type: 'datetime',
            clearable: true,
            searchable: true,
            multiple: true,
            required: true,
        },
        {
            key: 'endDate',
            label: t('homework.endDateTime'),
            labelWidth: 300,
            value: homeworkData?.endDate || '',
            type: 'datetime',
            clearable: true,
            searchable: true,
            multiple: true,
            required: true,
        }
    ];

    const fileFields = [
        {
            key: 'file',
            label: t('homework.file'),
            labelWidth: 300,
            value: undefined,
            type: 'fileUpload',
            IsExtendedButtonWithIcon: true,
            isExtendedButtonWithIconClass: 'btn btn-primary m-btn m-btn--icon m-btn--icon-only topic-add-icon-style',
            iconNameClass: 'la la-youtube-play',
            clearButton: true,
            clearable: true,
            searchable: true,
            multiple: false,
            onChange: onFileChange
        }
    ]

    const handlerOnSaveClick = (active) => {
        const [ isValid, states, values ] = formRef.current.validate();
        if (isValid) {
            const formData = new FormData();
            formData.append('school', schoolId);
            formData.append('course', id);
            formData.append('homework', homeworkId);
            formData.append('name', values.name);
            formData.append('description', values.description);
            formData.append('score', values.score);
            formData.append('type', JSON.stringify(values.type));
            formData.append('start', values.startDate);
            formData.append('end', values.endDate);
            formData.append('file', newFile);
            formData.append('active', active);

            if(file && file.length > 0){
                formData.append('fileId', file[0].id);
            }

            dispatch(setLoading(true));
            fetchRequest(teacherOnlineLessonHomeworkEdit, 'POST', formData, false, true)
                .then(res => {
                    if (res.success) {
                        const { data } = res;

                        history.push({
                            pathname: '/teacher/online/lesson-edit',
                            state: {
                                courseId: id,
                            }
                        });
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        }
    };

    const onFileUploadClearButtonHandler = () => {
        setFile();
    };

    return (
        <div className='sm-container'>
            <Card>
                <Card.Header>
                    <span className='french-blue fs-10'>
                        <b>{t('homework.add').toUpperCase()}</b>
                    </span>
                </Card.Header>
                <Card.Body>
                    <Forms
                        ref={formRef}
                        fields={fields}
                    />
                    {
                        file && file.length > 0
                        ?
                            <div style={{display: 'flex', marginTop: '0.8rem'}}>
                                <label
                                    style={{
                                        display: 'flex',
                                        flex: undefined,
                                        justifyContent: 'flex-end',
                                        alignItems: 'center',
                                        marginRight : 10,
                                        marginBottom: 0,
                                        width: 300,
                                    }}
                                >
                                    {t('teacher.subjectFile')}
                                </label>
                                <div
                                    style={{
                                        flexDirection: 'column',
                                        marginLeft: 10,
                                    }}
                                >
                                    {file[0].name || ''}
                                    <Button onClick={() => {onFileUploadClearButtonHandler()}} className="btn btn-danger m-btn m-btn--icon m-btn--icon-only m-btn--circle-28 ml-2">
                                        <i className="flaticon2-cross"/>
                                    </Button>
                                </div>
                            </div>
                        :
                            <Forms
                                ref={formFileRef}
                                fields={fileFields}
                            />
                    }
                </Card.Body>
                <Card.Footer>
                    <div className='col-11 text-center'>
                        <Link
                            to={{
                                pathname: '/teacher/online/lesson-edit',
                                state: {
                                    courseId: id,
                                }
                            }}
                            className="btn btn-link bolder"
                        >
                            {t('common.back')}
                        </Link>
                        <Button variant="success btn-shadow" onClick={() => handlerOnSaveClick(0)}>{t('common.save')}</Button>
                        <Button variant="publish btn-shadow ml-2" onClick={() => handlerOnSaveClick(1)}>{t('common.open')}</Button>
                    </div>
                </Card.Footer>
            </Card>
        </div>
    );
};