import React, { useState } from 'react'
import {Card, Col, Nav, Row} from 'react-bootstrap'
import { AwardInformation } from './AwardInformation'
import { EducationInformation } from './EducationInformation'
import { ExtendedFamilyInformation } from './ExtendedFamilyInformation'
import { FamilyInformation } from './FamilyInformation'
import { HighSchoolInformation } from './HighSchoolInformation'
import { MainInformation } from './MainInformation'
import { WorkExperienceInformation } from './WorkExperienceInformation'
import Instruction from "../../../modules/instruction";
import {useTranslation} from "react-i18next";

export const Anket = () => {
    const { t } = useTranslation();
    const [navKey,setNavKey] = useState('main');

    const renderNavs = () =>{
        const navs = {
            main: <MainInformation />,
            family: <FamilyInformation />,
            extended: <ExtendedFamilyInformation />,
            education: <EducationInformation />,
            award: <AwardInformation/>,
            highschool: <HighSchoolInformation/>,
            work: <WorkExperienceInformation />,
        };
        return navs[navKey]
    };

    const onNavChange  = key =>{
        setNavKey(key)
    };

    const onPrint = () => {
    };

    return (
        <>
            <Card>
                <Card.Body>
                    <div className='ds-flex justify-content-between mb-5'>
                        <div>
                            <button className='btn btn-warning-custom' onClick={() => onPrint()}>{t('questionnaire.print')}</button>
                        </div>
                        <Instruction/>
                    </div>
                    <div className='row'>
                        <div className='col-3 pr-0'>
                            <div className='student-anket-navigation'>
                                <Nav activeKey={navKey} onSelect={onNavChange} className="flex-column" navbar='m-menu__link-text'>
                                    <Nav.Link eventKey="main">{t('questionnaire.mainTitle')}</Nav.Link>
                                    <Nav.Link eventKey="family">{t('questionnaire.familyTitle')}</Nav.Link>
                                    <Nav.Link eventKey="extended">{t('questionnaire.relativesTitle')}</Nav.Link>
                                    <Nav.Link eventKey="education">{t('questionnaire.educationTitle')}</Nav.Link>
                                    <Nav.Link eventKey="award">{t('questionnaire.awardTitle')}</Nav.Link>
                                    <Nav.Link eventKey="highschool">{t('questionnaire.highSchoolTitle')}</Nav.Link>
                                    <Nav.Link eventKey="work">{t('questionnaire.workTitle')}</Nav.Link>
                                </Nav>
                            </div>
                        </div>
                        <div className='col-9 pl-0'>
                            {renderNavs()}
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </>
    )
};
