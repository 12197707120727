import DTable from 'app/modules/DataTable/DTable';
import React, { useEffect, useMemo, useState } from 'react'
import { Button, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import AddModal from './modals/AddModal'
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from 'redux/action';
import { fetchRequest } from 'utils/fetchRequest';
import { graduationAdditionalIndex } from 'utils/fetchRequest/Urls';
import message from 'app/modules/message';

const AdditionalInformation = () => {
    const {t} = useTranslation()

    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const dispatch = useDispatch()

    const [list,setList] = useState([])
    const [totalCount,setTotalCount] = useState(0)
    const [viewModal,setViewModal] = useState(false)

    const [tempCode,setTempCode] = useState(null)

    const onContextMenuItemClick = (id, key, row) => {
        if (key === "edit") {
            setViewModal(true)
            setTempCode(row.code)
        }
    }
    
    const onInteraction = params => {
        init(params)
    }

    const setData = (list=[],count=0) => {
        setList(list)
        setTotalCount(count)
    }

    const init = (tableFilter = {}) => {
        try {
            dispatch(setLoading(true));
            const params = {
                ...tableFilter,
                school: schoolId
            }
            fetchRequest(graduationAdditionalIndex, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const { data: {
                            students = [],
                            totalCount = 0
                        } = {}} = res || {}
                        setData(students,totalCount)
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                })
                .catch(() => {
                    message(t('errorMessage.title'))
                })
                .finally(()=>dispatch(setLoading(false)))
        } catch (e) {
            message(t('errorMessage.title'))
        }
    }

    useEffect(()=>{
        init()
    },[])

    const tableConfig = useMemo(()=>{
        return { 
            showPagination: true, 
            excelExport: false,
        }
    },[])

    const contextMenus = useMemo(()=>[
        {
          key: "edit",
          title: t("common.edit"),
          icon: <i className="las la-edit dt-cm-item-icon" />,
        }
    ],[])

    const columns = useMemo(()=>[
        {
            dataField: "schoolName",
            text: t('graduation.subSchool'),
            sort: true,
        },
        {
            dataField: "programName",
            text: t('graduation.subject'),
            sort: false,
        },
        {
            dataField: "code",
            text: t("student.code"),
            sort: false,
        },
        {
            dataField: "lastName",
            text: t("student.lastName"),
            sort: false,
        },
        {
            dataField: "firstName",
            text: t("student.name"),
            sort: false,
        },
        {
            dataField: "diplomaNumber",
            text: t("graduation.diplomNumber"),
            sort: false,
        },
        {
            dataField: "diplomaRegistrationNo",
            text: t("graduation.registerNumber"),
            sort: false,
        },
        {
            dataField: "createdUser",
            text: t("common.registered"),
            sort: true,
        }
    ],[])

    return (
        <Card>
            <Card.Body>
                <Button variant="primary btn-shadow align-self-end" onClick={()=>setViewModal(true)}>
                    {t('graduation.addInformation')}
                </Button>
                <DTable
                    data={list}
                    config={tableConfig}
                    contextMenus={contextMenus}
                    onContextMenuItemClick={onContextMenuItemClick}
                    onInteraction={onInteraction}
                    columns={columns}
                    totalDataSize={totalCount}
                />
            </Card.Body>
            <AddModal open={viewModal} onClose={()=>{
                setTempCode(null)
                setViewModal(false)
            }} setData={setData} code={tempCode}/>
        </Card>
    )
}

export default AdditionalInformation
