import React, {useEffect, useState} from 'react'
import {Card, Button} from 'react-bootstrap'
import DTable from '../../../modules/DataTable/DTable'
import Instruction from "../../../modules/instruction";
import {useTranslation} from "react-i18next";
import {setLoading} from "../../../../redux/action";
import {fetchRequest} from "../../../../utils/fetchRequest";
import {
    studentRequestIndex,
    studentRequestView,
    studentRequestEdit,
    studentRequestDelete,
    studentRequestCreate,
} from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import {useDispatch, useSelector} from "react-redux";
import {AddModal} from './AddModal'
import {ViewModal} from './ViewModal'
import {EditModal} from './EditModal'
import DeleteModal from "../../../modules/DeleteModal";

export const StudentRequest = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [requestId, setRequestId] = useState(null);
    const [requests, setRequests] = useState([]);
    const [types, setTypes] = useState([]);
    const [addModal, setAddModal] = useState(false);
    const [viewModal, setViewModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);

    useEffect(() => {
        let params = {
            page: 1,
            pageSize: 10,
        };

        init(params);
    }, []);

    const init = (params) => {
        dispatch(setLoading(true));
        fetchRequest(studentRequestIndex, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    let requestObj = data?.requests || [];
                    if(requestObj && requestObj.length > 0){
                        for(let i = 0; i < requestObj.length; i++){
                            if(requestObj[i].createdDate && requestObj[i].createdDate.date){
                                requestObj[i].createdDate = requestObj[i].createdDate.date.substring(0, 19)
                            }
                        }
                    }
                    setRequests(requestObj);
                    setTypes(data?.requestTypes || []);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            });
    };

    const config = {
        showPagination: false,
        showFilter: false,
    };

    const columns = [
        {
            dataField: 'statusName',
            text: t('common.status'),
            sort: true,
        },
        {
            dataField: 'typeName',
            text: t('common.type'),
            sort: true,
        },
        {
            dataField: 'createdDate',
            text: t('common.date'),
            sort: true,
        },
        {
            dataField: 'sent',
            text: '',
            headerStyle: () => {
                return {
                    width: 150
                }
            },
            formatter(cell, row, index) {
                return (
                    row.isComplete === true
                    ?
                        <div className='request-icons-style'>
                            <Button onClick={() => {viewClick(row, index)}} className="btn-primary btn-shadow m-btn m-btn--icon m-btn--icon-only m-btn--circle-30 mr-2">
                                <i className='far fa-eye'/>
                            </Button>
                        </div>
                    :
                        <div className='request-icons-style'>
                            <Button onClick={() => {viewClick(row, index)}} className="btn-primary btn-shadow m-btn m-btn--icon m-btn--icon-only m-btn--circle-30 mr-2">
                                <i className='far fa-eye'/>
                            </Button>
                            <Button onClick={() => {editClick(row, index)}} className="btn-edit btn-shadow m-btn m-btn--icon m-btn--icon-only m-btn--circle-30 mr-2">
                                <i className='flaticon-edit'/>
                            </Button>
                            <Button onClick={() => {deleteClick(row, index)}} className="btn-danger btn-shadow m-btn m-btn--icon m-btn--icon-only m-btn--circle-30">
                                <i className='flaticon2-cross'/>
                            </Button>
                        </div>
                )
            },
        },
    ];

    const viewClick = (row) => {
        setRequestId(row.id);
        setViewModal(true);
    };

    const editClick = (row) => {
        setRequestId(row.id);
        setEditModal(true);
    };

    const deleteClick = (row) => {
        setRequestId(row.id);
        setDeleteModal(true);
    };

    const onModalClose = () =>{
        setAddModal(false);
        setViewModal(false);
        setEditModal(false);
        setDeleteModal(false);
        setRequestId(null);
    };

    const onDelete = () => {
        const bodyParams = {
            school: schoolId,
            id: requestId
        };
        dispatch(setLoading(true));
        fetchRequest(studentRequestDelete, 'POST', bodyParams)
            .then(res => {
                dispatch(setLoading(false));
                if (res.success) {
                    message(res?.data?.message || t('common.success'), true);
                    onModalClose();
                    let params = {
                        page: 1,
                        pageSize: 10,
                    };

                    init(params);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            })
    };

    const onSubmit = params => {
        const url = requestId ? studentRequestEdit : studentRequestCreate;

        const bodyParams = {
            ...params,
            school: schoolId,
        };

        dispatch(setLoading(true));
        fetchRequest(url, 'POST', bodyParams)
            .then(res => {
                dispatch(setLoading(false));
                if (res.success) {
                    onModalClose();
                    message(res?.data?.message || t('common.success'), true);

                    let params = {
                        page: 1,
                        pageSize: 10,
                    };

                    init(params);
                } else {
                    message(res?.data?.message || t('errorMessage.title'));
                }
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            })
    };

    return (
        <Card>
            <Card.Body>
                <div className='ds-flex justify-content-between'>
                    <button className='btn btn-primary btn-shadow' onClick={() => setAddModal(true)}>{t('common.add')}</button>
                    <Instruction/>
                </div>
                <DTable
                    data={requests}
                    config={config}
                    columns={columns}
                    selectMode={'radio'}
                />
                {
                    addModal &&
                    <AddModal
                        requestId={requestId}
                        types={types}
                        close={onModalClose}
                        onSubmit={onSubmit}
                    />
                }
                {
                    viewModal &&
                    <ViewModal
                        requestId={requestId}
                        close={onModalClose}
                        onSubmit={onSubmit}
                    />
                }
                {
                    editModal &&
                    <EditModal
                        requestId={requestId}
                        close={onModalClose}
                        onSubmit={onSubmit}
                    />
                }
                {
                    deleteModal && requestId && (
                        <DeleteModal
                            onClose={onModalClose}
                            onDelete={onDelete}
                            title={t('warning.delete')}
                        >
                            {t('warning.delete_confirmation')}
                            <br/>
                            <br/>
                            {t('warning.delete_confirmation_description')}
                        </DeleteModal>
                    )
                }
            </Card.Body>
        </Card>
    )
};
