import React, {useState, useEffect, useRef} from 'react';
import {Card, Button, Modal} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {Link, useHistory, useLocation} from "react-router-dom";
import Forms from "../../../modules/Form/Forms";
import {setLoading} from "../../../../redux/action";
import {fetchRequest} from "../../../../utils/fetchRequest";
import {programAdd, academicProgramList} from "../../../../utils/fetchRequest/Urls";
import qs from "qs";
import {useDispatch, useSelector} from "react-redux";
import message from "../../../modules/message";
import i18n from "i18next";

const ProgramAdd = ({
    location: { state: { departmentId = null } = {} } = {},
}) => {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const selectedSchool = useSelector(state => state?.selectedSchool || []);
    const languages = useSelector(state => state?.languages?.languages || []);
    const [ helperModal, setHelperModal ] = useState(false);
    const [departments, setDepartments] = useState([]);
    const [types, setType] = useState([]);
    const [educationLevels, setEducationLevels] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [approaches, setApproaches] = useState([]);
    const [scoreTypes, setScoreTypes] = useState([]);
    // const [templates, setTemplates] = useState([]);
    const [selectedProgram, setSelectedProgram] = useState([]);

    const form = useRef();
    const translateForm = useRef();

    useEffect(() => {
        dispatch(setLoading(true));
        let params = {
            school: selectedSchool.id
        };

        fetchRequest(programAdd, 'GET', params)
            .then(response => {
                if (response.success) {
                    if(response.data.departments && response.data.departments.length){
                        setDepartments(response.data.departments);
                    }
                    if(response.data.types && response.data.types.length){
                        setType(response.data.types.map(type => ({ value: type.id, text: type.name })));
                    }
                    if(response.data.educationLevels && response.data.educationLevels.length){
                        setEducationLevels(response.data.educationLevels.map(level => ({ value: level.id, text: level.name })));
                    }
                    if(response.data.approaches && response.data.approaches.length){
                        setApproaches(response.data.approaches.map(approach => ({ value: approach.id, text: approach.name })));
                    }
                    if(response.data.scoreTypes && response.data.scoreTypes.length){
                        setScoreTypes(response.data.scoreTypes.map(scoreType => ({value: scoreType.id, text: scoreType.name})));
                    }
                    // if(response.data.templates && response.data.templates.length){
                    //     setTemplates(response.data.templates.map(template => ({value: template.id, text: template.name})));
                    // }
                    dispatch(setLoading(false));
                    if(response.data.selectedProgram && response.data.selectedProgram.length > 0){
                        let selectedProgram = response.data.selectedProgram;
                        setSelectedProgram(selectedProgram);
                        if(selectedProgram && selectedProgram.length > 0){
                            onDepartmentChange(selectedProgram[0]['departmentId'], false);
                        }
                    } else if (departmentId){
                        onDepartmentChange(departmentId, false);
                    }
                } else {
                    message(response.data.message, false);
                    dispatch(setLoading(false));
                }
            })
            .catch(() => {
                dispatch(setLoading(false));
            });
    }, []);

    useEffect(() => {
        form?.current?.updateFields && form.current.updateFields(forms);
    }, [departments, types, educationLevels, scoreTypes, approaches, selectedProgram]);

    useEffect(() => {
        form?.current?.updateChildField && form.current.updateChildField(forms, 'academicProgram', 3);
    }, [programs]);

    const onDepartmentChange = (id, evt) => {
        let params = {
            school: selectedSchool.id,
            department: id,
            status: 'publish'
        };

        dispatch(setLoading(true));
        fetchRequest(academicProgramList, 'GET',params)
            .then(response => {
                if (response.success) {
                    if(response.data.programs){
                        setPrograms(response.data.programs.map(program => ({value: program.id, text: program.name })));
                    }
                } else {
                    message(response.data.message, false);
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
            });
    };

    const forms = [
        {
            label: t('school.parentDepartment') + '*',
            value: selectedProgram && selectedProgram.length > 0 ? selectedProgram[0]['departmentId'] : departmentId,
            errorMessage: t('errorMessage.selectRelatedDepartment'),
            required: true,
            type: 'dropdown',
            options: departments,
            multiple: false,
            clearable: true,
            name: 'department',
            onChange: onDepartmentChange
        },
        {
            label: t('curriculum.curriculumType') + '*',
            value: selectedProgram && selectedProgram.length > 0 ? selectedProgram[0]['typeId'] : null,
            errorMessage: t('errorMessage.selectCurriculumType'),
            required: true,
            type: 'dropdown',
            options: types,
            multiple: false,
            clearable: true
        },
        {
            label: t('curriculum.educationLevel') + '*',
            value: selectedProgram && selectedProgram.length > 0 ? selectedProgram[0]['educationLevelId'] : null,
            errorMessage: t('errorMessage.selectEducationLevel'),
            required: true,
            type: 'dropdown',
            options: educationLevels,
            multiple: false,
            clearable: true
        },
        {
            label: t('curriculum.name') + '*',
            value: selectedProgram && selectedProgram.length > 0 ? selectedProgram[0]['academicId'] : null,
            errorMessage: t('errorMessage.selectCurriculumName'),
            required: true,
            type: 'dropdown',
            options: programs,
            multiple: false,
            name: 'academicProgram',
            clearable: true
        },
        {
            key: 'code',
            label: t('common.code') + '*',
            value: selectedProgram && selectedProgram.length > 0 ? selectedProgram[0]['code'] : '',
            errorMessage: t('errorMessage.enterCode'),
            required: true,
            type: 'nonCryllic',
            upperCase: true,
        },
        {
            key: 'studentCodePrefix',
            label: t('student.code') + '*',
            value: selectedProgram && selectedProgram.length > 0 ? selectedProgram[0]['studentCodePrefix'] : '',
            errorMessage: t('errorMessage.enterStudentCode'),
            required: true,
            type: 'nonCryllic',
            upperCase: true,
        },
        {
            label: t('curriculum.profession') + '*',
            value: selectedProgram && selectedProgram.length > 0 ? selectedProgram[0]['name'] : '',
            errorMessage: t('errorMessage.enterProfession'),
            required: true,
            type: 'text',
        },
        {
            label: t('curriculum.previousEducationLevel') + '*',
            value: selectedProgram && selectedProgram.length > 0 ? selectedProgram[0]['previousEduLevelId'] : null,
            errorMessage: t('errorMessage.selectPreEducationLevel'),
            required: true,
            type: 'dropdown',
            options: educationLevels,
            multiple: false,
            clearable: true
        },
        // {
        //     label: t('curriculum.scoreTemplate') + '*',
        //     value: selectedProgram && selectedProgram.length > 0 ? selectedProgram[0]['templateId'] : null,
        //     errorMessage: t('errorMessage.selectScoreTemplate'),
        //     required: true,
        //     type: 'dropdown',
        //     options: templates,
        //     multiple: false,
        // },
        {
            label: t('curriculum.scoreType') + '*',
            value: selectedProgram && selectedProgram.length > 0 ? selectedProgram[0]['scoreTypeId'] : null,
            errorMessage: t('errorMessage.selectScoreType'),
            required: true,
            type: 'dropdown',
            options: scoreTypes,
            multiple: false,
            clearable: true
        },
        {
            label: t('curriculum.approach') + '*',
            value: selectedProgram && selectedProgram.length > 0 ? selectedProgram[0]['approachId'] : null,
            errorMessage: t('errorMessage.selectApproach'),
            required: true,
            type: 'dropdown',
            options: approaches,
            multiple: false,
            clearable: true
        },
    ];

    const handleHelperModal = () => {
        setHelperModal(true)
    };

    const handleHelperModalClose = () => {
        setHelperModal(false)
    };

    const getFormTranslations = () => {
        const array = [];
        for (const language of languages) {
            if(language.code !== 'mn'){
                array.push(
                    {
                        label: `${t('common.name')} - ${language.name} (${language.code})`,
                        value: '',
                        required: false,
                        type: 'text',
                        langCode: language.code,
                        key: 'name',
                        inputStyle: language.code == 'tr' ? {fontFamily: 'Urga'} : {}
                    }
                );
            }
        }
        return array;
    };

    const handlerOnSaveClick = (saveOrNext) => {
        const [ formValid, formValue ] = form.current.validate();
        const [ translateValid, translateValues ] = translateForm.current.validate();

        if (formValid) {
            const params = {
                school: selectedSchool.id,
                department: formValue?.[0]?.value,
                type: formValue?.[1]?.value,
                educationLevel: formValue?.[2]?.value,
                academicProgram: formValue?.[3]?.value,
                code: formValue?.[4]?.value,
                studentCodePrefix: formValue?.[5]?.value,
                profession: formValue?.[6]?.value,
                preEducationLevel: formValue?.[7]?.value,
                // stTemplate: formValue?.[6]?.value,
                scoreType: formValue?.[8]?.value,
                approach: formValue?.[9]?.value,
                status: 'unpublish'
            };

            let translations = [];
            if(languages && languages.length > 0){
                languages.map(language => {
                    if(language.code !== 'mn'){
                        const nameObject = translateValues.find(val => val.key === 'name' && val.langCode === language.code && val.value);

                        if (nameObject) {
                            translations.push({
                                langCode: language.code,
                                name: nameObject.value
                            })
                        }
                    }
                });

                params.translations = JSON.stringify(translations);
            }

            dispatch(setLoading(true));
            fetchRequest(programAdd, 'POST', params, false)
                .then(response => {
                    if (response.success) {
                        if(saveOrNext === 'save')
                        {
                            message(response.data.message, true);
                            history.push({
                                pathname: '/school/program',
                            });
                        } else if(saveOrNext === 'next')
                        {
                            history.push({
                                pathname: '/school/edit',
                                state: {
                                    id: response.data.id
                                }
                            });
                        }
                    } else {
                        message(response.data.message, false)
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                });
        }
    };

    return (
        <div className='row'>
            <div className='col-12'>
                <Card>
                    <Card.Body>
                        <div className='col-12 text-right'>
                            <Button className='bg-white border-white d-inline btn-hover-less' onClick={handleHelperModal}>
                                <i className="icon-2x text-danger flaticon-questions-circular-button d-inline"/>
                                <p className='text-danger d-inline'><b>{t('common.helper')}</b></p>
                            </Button>
                        </div>
                        <div className='col-12'>
                            <Forms
                                ref={form}
                                fields={forms}
                            />
                        </div>
                        <div className="separator separator-dashed my-7"/>
                        <div className='col-12'>
                            <Forms
                                ref={translateForm}
                                fields={getFormTranslations()}
                            />
                        </div>
                    </Card.Body>
                    <Card.Footer>
                        <div className='col-12 text-center'>
                            <Link
                                to='/school/program'
                                className="btn btn-link bolder"
                            >
                                {t('common.back')}
                            </Link>
                            <Button variant="success btn-shadow" onClick={() => handlerOnSaveClick('save')}>{t('common.save')}</Button>
                            <Button variant="next btn-shadow ml-3" onClick={() => handlerOnSaveClick('next')}>{t('action.next')}</Button>
                        </div>
                    </Card.Footer>
                </Card>
            </div>
            <Modal
                show={helperModal}
                onHide={handleHelperModalClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Modal heading
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Centered Modal</h4>
                    <p>
                        Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
                        dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta
                        ac consectetur ac, vestibulum at eros.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleHelperModalClose}>{t('common.close')}</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
};

export default ProgramAdd;