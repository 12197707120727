import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DTable from "../../../modules/DataTable/DTable";
import PsychologyModal from "./modalPsychology";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import { psychologyList } from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import { useDispatch, useSelector } from "react-redux";
import PsychologyEdit from "./editPsychology";

const EnrollPsychology = () => {
  const { t } = useTranslation();
  const [tableStoreData] = useState("enroll_non_register_table_index");
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const [id, setId] = useState(null);
  const [list, setList] = useState([]);
  const [candidateIdd, setCandidateIdd] = useState(null);
  const [showViewModal, setShowViewModal] = useState(false);
  const schoolId = useSelector((state) => state?.selectedSchool?.id || null);
  const [totalCount, setTotalCount] = useState(0);
  const [tableState, setTableState] = useState(
    JSON.parse(localStorage.getItem(tableStoreData)) || {
      page: 1,
      pageSize: 10,
      search: "",
      sort: "",
      order: "asc",
    }
  );

  useEffect(() => {
    const params = {
      school: schoolId,
    };
    getData(params);
  }, []);

  const getData = (params) => {
    dispatch(setLoading(true));
    fetchRequest(psychologyList, "GET", params)
      .then((res) => {
        if (res.success) {
          const { data } = res;
          if (data?.list) {
            setList(data.list);
            setTotalCount(data.totalCount);
          }
        } else {
          message(res?.data.message || t("errorMessage.title"));
        }
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        message(t("errorMessage.title"));
      });
  };

  const columns = [
    {
      dataField: "candidateId",
      text: "ID",
      sort: false,
      editable: false,
    },
    {
      dataField: "registrationNumber",
      text: t("person.registrationNumber"),
      sort: false,
      editable: false,
    },
    {
      dataField: "gender",
      text: t("common.gender"),
      sort: false,
      editable: false,
    },
    {
      dataField: "statusName",
      text: t("common.status"),
      sort: false,
    },
    {
      dataField: "description",
      text: t("common.description"),
      sort: false,
    },
    {
      dataField: "icons",
      sort: false,
      text: "",
      formatter: (cell, row) => {
        return (
          <div>
            <button
              className="btn btn-sm"
              onClick={() => handleEditClick(cell, row)}
            >
              <i
                className="icon-1_4x text-white fa fa-pen mr-2 p-3 fs-5"
                style={{
                  backgroundColor: "#716ACA",
                  borderRadius: "50%",
                }}
              />
            </button>
          </div>
        );
      },
    },
  ];

  const handleEditClick = (cell, row) => {
    setCandidateIdd(row.candidateId);
    setId(row.id);
    setShowViewModal(true);
  };

  const onModalClose = () => {
    setShowModal(false);
  };

  const onModalEditClose = () => {
    setShowViewModal(false);
  };
  const handleClick = () => {};
  const handleAddClick = () => {
    setShowModal(true);
  };

  const onUserInteraction = (object) => {
    let params = {
      ...object,
      page: object.page,
      pageSize: object.pageSize,
      search: object.search,
      sort: object.sort,
      order: object.order,
    };
    setTableState(params);
    params.school = schoolId;
    getData(params);
  };

  const handleOnFinish = () => {
    const params = {
      school: schoolId,
      page: tableState.page,
      pageSize: tableState.pageSize,
      search: tableState.search,
      sort: tableState.sort,
      order: tableState.order,
    };
    getData(params);
  };

  return (
    <Card>
      <Card.Body>
        {showModal && (
          <PsychologyModal onClose={onModalClose} onFinish={handleOnFinish} />
        )}
        <div className="w-75">
          <button
            className="btn btn-primary btn-shadow"
            onClick={handleAddClick}
          >
            {t("common.add")}
          </button>
          <button
            className="btn btn-success btn-shadow ml-4"
            onClick={handleClick}
          >
            {t("common.send")}
          </button>
          <DTable
            columns={columns}
            data={list}
            totalDataSize={totalCount}
            sort
            config={{
              showFilter: false,
              showPagination: true,
              showLefttonText: "Илгээх",
            }}
            showOrdering={true}
            remote
            onInteraction={onUserInteraction}
            selectMode={"radio"}
          />

          {showViewModal ? (
            <PsychologyEdit
              canId={candidateIdd}
              id={id}
              onClose={onModalEditClose}
              onFinish={handleOnFinish}
            />
          ) : null}
        </div>
      </Card.Body>
    </Card>
  );
};

export default EnrollPsychology;
