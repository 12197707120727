import React, { useEffect, useRef } from 'react';
import { Button, Modal } from "react-bootstrap";
import Forms from "../../../modules/Form/Forms";
import { useTranslation } from "react-i18next";
import { systemLanguageEdit } from "../../../../utils/fetchRequest/Urls";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import message from "../../../modules/message";

const LanguageAdd = ({
    onClose,
    onSubmit,
    id
}) => {
    const { t } = useTranslation();
    const forms = useRef();
    const dispatch = useDispatch();

    useEffect(() => {
        if (id) {
            const params = {
                lang: id
            };
            dispatch(setLoading(true));
            fetchRequest(systemLanguageEdit, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res;

                        if (data?.languages?.length) {
                            const language = data.languages.find(lang => lang.id === id);
                            if (language) {
                                forms?.current?.updateFields && forms.current.updateFields([
                                    {
                                        label: t('common.code'),
                                        value: language.code || '',
                                        type: 'text',
                                        inputWidth: 200,
                                        labelWidth: 300,
                                        required: true,
                                        key: 'code',
                                    },
                                    {
                                        label: t('setting.language.system'),
                                        value: language.name || '',
                                        type: 'text',
                                        inputWidth: 200,
                                        labelWidth: 300,
                                        required: true,
                                        key: 'name',
                                    },
                                ]);
                            }
                        }
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        }
    }, []);

    const fields = [
        {
            label: t('common.code'),
            value: '',
            type: 'text',
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            key: 'code',
        },
        {
            label: t('setting.language.system'),
            value: '',
            type: 'text',
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            key: 'name',
        },
    ];

    const onSaveClick = () => {
        const [ isValid, , values ] = forms.current.validate();
        if (isValid) {
            onSubmit(values);
        }
    };

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('setting.language.system').toUpperCase()}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Forms
                    ref={forms}
                    fields={fields}
                />
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className='btn btn-link bolder'>{t('common.back')}</button>
                <Button variant='success btn-shadow' onClick={onSaveClick}>{t('common.save')}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default LanguageAdd;