import React, {useState, useRef, useEffect} from 'react'
import DTable from '../../../../modules/DataTable/DTable'
import { useTranslation } from "react-i18next";
import DeleteModal from '../../../../modules/DeleteModal';
import { AddModal } from './AddModal';
import {useDispatch, useSelector} from "react-redux";
import {setLoading} from "../../../../../redux/action";
import {fetchRequest} from "../../../../../utils/fetchRequest";
import {lmsAnketEyesh} from "../../../../../utils/fetchRequest/Urls";
import message from "../../../../modules/message";

export const HighSchoolInformation = ({
    studentId
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [eyeshs, setEyeshs] = useState([]);
    const [deleteModal,setDeleteModal] = useState(false);
    const [viewModal,setViewModal] = useState(false);
    const [tempId,setTempId] = useState(null);

    useEffect(() => {
        if(schoolId){
            let params = {
                school: schoolId,
                id: studentId
            };

            dispatch(setLoading(true));
            fetchRequest(lmsAnketEyesh, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res;
                        setEyeshs(data?.eyeshScores);
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'));
                })
        }
    }, []);

    const tableContextItem = [
        // {
        //     key: 'edit',
        //     title: t('common.edit'),
        //     icon: <i className='las la-edit dt-cm-item-icon'/>
        // },
        // {
        //     key: 'delete',
        //     title: t('action.delete'),
        //     icon: <i className='las la-trash-alt dt-cm-item-icon'/>
        // },
    ];

    const config ={
        showPagination: false,
        showFilter: false,
    };

    const columns = [
        {
            dataField: 'lesson',
            text: t('subject.name'),
            sort: true,
        },
        {
            dataField: 'grade',
            text: 'Дүн/ Хувь',
            sort: true,
        },
    ];

    const handleContextMenuClick = (id, key) => {
        if(id && key) {
            if(key === 'delete'){
                setDeleteModal(true);
                setTempId(id)
                
            }
            if(key === 'edit'){
                setTempId(id)
            }
        }
    };

    const onClose = () =>{
        setDeleteModal(false);
        setViewModal(false);
        setTempId(null)
    };

    const onDelete = () =>{
        if(tempId){
        }
    };

    const fields = [
        {
            label: 'Хичээлийн нэр',
            value: '',
            type: 'text',
            inputWidth: '300px',
            labelWidth: '30%',
            required: true,
            key: 'lesson',
        },
        {
            label: 'Дүн/ Хувь',
            value: '',
            type: 'text',
            inputWidth: '300px',
            labelWidth: '30%',
            required: true,
            key: 'grade',
        },
    ];

    return (
        <div style={{border: '1px solid #4a70ae'}}>
            <div className='m-8'>
                {/*<button className='btn btn-primary btn-shadow' onClick={()=>setViewModal(true)}>{t('common.add')}</button>*/}

                <DTable
                    columns={columns}
                    config={config}
                    data={eyeshs}
                    remote
                    showOrdering={false}
                    contextMenus={tableContextItem}
                    onContextMenuItemClick={handleContextMenuClick}
                    selectMode={'radio'}
                />
            </div>
            <AddModal open={viewModal} close={onClose} fields={fields} title={'Бүрэн дунд боловсрол'}/>
            {
                deleteModal
                &&
                <DeleteModal 
                    onClose={onClose} 
                    onDelete={onDelete}
                    title={'Бүрэн дунд боловсролын мэдээлэл усгах'}
                >
                    {t('warning.delete_confirmation')}
                    <br/>
                    <br/>
                    {t('warning.delete_confirmation_description')}
                </DeleteModal>
            }
        </div>
    )
};
