import React, { useEffect, useMemo, useState } from 'react'
import { Card, Button, Tabs, Tab, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import Select from 'app/modules/Form/Select'
import StudentList from './StudentList';
import ReportList from './ReportList';
import { useDispatch, useSelector } from 'react-redux';
import { MAIN_URL, fetchRequest } from 'utils/fetchRequest';
import message from 'app/modules/message';
import { graduationIndex, graduationMbHomeContent, graduationMbAttachmentContent, graduationCalculateResult } from 'utils/fetchRequest/Urls';
import { setLoading } from 'redux/action';
import MbHomeModal from './modals/MbHomeModal';
import MbAttachmentModal from './modals/MbAttachmentModal';

const StudentsGradution = () => {
    const { t } = useTranslation()

    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const dispatch = useDispatch();

    const [tabKey, setTabKey] = useState('init')
    const [filter, setFilter] = useState({})
    const [levelOptions, setLevelOptions] = useState([])
    const [parentOptions, setParentOptions] = useState([])
    const [seasonOptions, setSeasonOptions] = useState([])
    const [curriculumOptions, setCurriculumOptions] = useState([])
    const [statusOptions, setStatusOptions] = useState([])

    const [studentList, setStudentList] = useState([])
    const [reportList, setReportList] = useState([])
    const [totalCount, setTotalCount] = useState(0)

    const [tableFilter, setTableFilter] = useState({})
    const [mbHomeModal, setMbHomeModal] = useState(false)
    const [mbHomeModalContent, setMbHomeModalContent] = useState(null)
    const [mbAttachmentModal, setMbAttachmentModal] = useState(false)
    const [mbAttachmentModalContent, setMbAttachmentModalContent] = useState(null)

    const [avgGpa, setAvgGpa] = useState(null)

    const [updateView, setUpdateView] = useState(false)

    const onFilterChange = (key, value) => {
        if (key == 'parentSeason') {
            setSeasonOptions(() => {
                const parent = parentOptions.find(obj => obj.value == value)
                if (parent && parent.children) {
                    return parent.children
                }
                return []
            })
        }
        setFilter(prev => {
            let tempFilter = { ...prev }
            tempFilter[key] = value
            init(tabKey == 'init' ? 'STUDENT' : 'REPORT', tempFilter, tableFilter)
            return tempFilter
        })
        setAvgGpa(null)
    }

    const onTableFilterChange = (params) => {
        setTableFilter(params)
        init(tabKey == 'init' ? 'STUDENT' : 'REPORT', filter, params)
    }

    const onTabChange = key => {
        if (key !== tabKey) {
            setTabKey(key)
            setFilter({})
        
            init(key == 'init' ? 'STUDENT' : 'REPORT', filter, tableFilter)
        }
    }

    const init = (type = 'STUDENT', filter = {}, tableFilter = {}) => {
        try {
            dispatch(setLoading(true));
            const params = {
                school: schoolId,
                type,
                ...filter,
                ...tableFilter
            }
            fetchRequest(graduationIndex, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const { data: {
                            academicPrograms = [],
                            educationLevels = [],
                            lmStatuses = [],
                            parentSeasons = [],
                            students = [],
                            totalCount = 0,
                            list = []
                        } = {} } = res || {}
                        setParentOptions(parentSeasons)
                        setStatusOptions(() => {
                            const tempList = []
                            lmStatuses.map(obj => {
                                tempList.push({
                                    value: obj.id,
                                    text: obj.name
                                })
                            })
                            return tempList
                        })
                        setCurriculumOptions(() => {
                            const tempList = []
                            academicPrograms.map(obj => {
                                tempList.push({
                                    value: obj.id,
                                    text: obj.name
                                })
                            })
                            return tempList
                        })
                        setLevelOptions(() => {
                            const tempList = []
                            educationLevels.map(obj => {
                                tempList.push({
                                    value: obj.key,
                                    text: obj.title
                                })
                            })
                            return tempList
                        })
                        setStudentList(() => {
                            const tempData = []
                            students.map(obj => {
                                let contexKeys = ['approve', 'remove']
                                if (obj.isConfirmed) {
                                    contexKeys = []
                                }
                                tempData.push({
                                    ...obj,
                                    contextMenuKeys: contexKeys
                                })
                            })
                            return tempData
                        })
                        setReportList(list)
                        setTotalCount(totalCount)
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                })
                .catch(() => {
                    message(t('errorMessage.title'))
                })
                .finally(() => dispatch(setLoading(false)))
        } catch (e) {
            message(t('errorMessage.title'))
        }
    }

    const studentFilterList = useMemo(() => [
        {
            key: 'educationLevel',
            text: t('curriculum.educationLevel'),
            options: levelOptions
        },
        {
            key: 'parentSeason',
            text: t('school.season.title'),
            options: parentOptions
        },
        {
            key: 'season',
            text: t('subject.season'),
            options: seasonOptions
        },
        {
            key: 'academicProgram',
            text: t('curriculum.title'),
            options: curriculumOptions
        },
        {
            key: 'status',
            text: t('common.status'),
            options: statusOptions
        },
    ], [
        levelOptions,
        parentOptions,
        seasonOptions,
        curriculumOptions,
        statusOptions
    ])

    const reportFilterList = useMemo(() => [
        {
            key: 'educationLevel',
            text: t('curriculum.educationLevel'),
            options: levelOptions
        },
        {
            key: 'parentSeason',
            text: t('school.season.title'),
            options: parentOptions
        },
        {
            key: 'season',
            text: t('subject.season'),
            options: seasonOptions
        }
    ], [
        levelOptions,
        parentOptions,
        seasonOptions
    ])

    const renderFilter = (list = []) => {
        return list.map((item, index) => {
            return <Col key={index} md={3} className='mb-2'>
                <Row className='d-flex align-items-center justify-content-between'>
                    <Col md={4}>
                        <label className='mr-2'>{item.text}</label>
                    </Col>
                    <Col md={8}>
                        <Select
                            value={filter[item.key]}
                            onChange={value => onFilterChange(item.key, value)}
                            options={item.options}
                            searchable
                        />
                    </Col>
                </Row>
            </Col>
        })
    }

    const showMbHomeModal = (id) => {
        dispatch(setLoading(true));
        const params = {
            school: schoolId,
            id
        }
        fetchRequest(graduationMbHomeContent, 'GET', params)
            .then(res => {
                if (res?.success) {
                    setMbHomeModalContent({
                        id,
                        template: res?.data?.template,
                        signatures: res?.data?.signatures,
                        diplomaRegistrationNo: res?.data?.diplomaRegistrationNo
                    })
                    setMbHomeModal(true)

                    setUpdateView(!updateView)
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(e => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            });
    }

    const showMbAttachmentModal = (id) => {
        dispatch(setLoading(true));
        const params = {
            school: schoolId,
            id
        }
        fetchRequest(graduationMbAttachmentContent, 'GET', params)
            .then(res => {
                if (res?.success) {
                    setMbAttachmentModalContent(res?.data)
                    setMbAttachmentModal(true)

                    setUpdateView(!updateView)
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(e => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            });
    }

    const onMbHomeModalClose = () => {
        setMbHomeModalContent(null)
        setMbHomeModal(false)
    }

    const onMbAttachmentModalClose = () => {
        setMbAttachmentModalContent(null)
        setMbAttachmentModal(false)
    }

    
    const calculateResult = (educationLevel = null, season = null) => {
        dispatch(setLoading(true));
        const params = {
            school: schoolId,
            season,
            educationLevel
        }
        fetchRequest(graduationCalculateResult, 'POST', params)
            .then(res => {
                if (res?.success) {
                    setAvgGpa(res?.data?.avgGpa)
                    setUpdateView(!updateView)
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(e => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            });
    }

    const onCalculateAvg = () => {
        if (filter && filter?.educationLevel) {
            if (filter.season) {
                calculateResult(filter?.educationLevel, filter?.season)
            } else {
                message(t('errorMessage.selectSeason'))
            }
            // 
        } else {
            message(t('errorMessage.selectEducationLevel'))
        }
    }

    const onHomeSubmit = (data = null) => {
        if (data && data?.id) {
            const clone = [...studentList]
            for (let i = 0; i < clone.length; i++) {
                if (clone[i].id === data?.id) {
                    clone[i].mbHomeContent = data?.mbHomeContent;
                    break;
                }
            }
            setStudentList(clone)
            setUpdateView(!updateView)
            setMbHomeModal(false)
            showMbAttachmentModal(data?.id)
        }
    }

    const onAttachmentSubmit = (data = null) => {
        if (data && data?.id) {
            const clone = [...studentList]
            for (let i = 0; i < clone.length; i++) {
                if (clone[i].id === data?.id) {
                    clone[i].mbAttachmentContent = data?.mbAttachmentContent;
                    break;
                }
            }
            setStudentList(clone)
            setUpdateView(!updateView)
            setMbAttachmentModal(false)
            pdfDownload(data?.id)
        }
    }

    const pdfDownload = (id) => {
        window.open(MAIN_URL + 'pdf/diploma/print?id=' + id + '&school=' + schoolId, "_blank", "noreferrer");
    }

    return (
        <>
            <Card>
                <Card.Header>
                    <Tabs
                        id={'msue-selection'}
                        activeKey={tabKey}
                        className='msue-tab'
                        onSelect={onTabChange}
                    >
                        <Tab eventKey={'init'} title={t('graduation.students')} />
                        <Tab eventKey={'report'} title={t('graduation.report')} />
                    </Tabs>
                </Card.Header>
                <Card.Body>
                    <Row className='d-flex justify-content-between'>
                        {renderFilter(tabKey == 'init' ? studentFilterList : reportFilterList)}
                        <Col className='d-flex justify-content-end align-items-center'>
                            <Button variant="primary btn-shadow align-self-end" onClick={() => { }}>
                                {t('common.search')}
                            </Button>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            {
                tabKey == 'init'
                    ? <StudentList
                        schoolId={schoolId}
                        onTableFilterChange={onTableFilterChange}
                        list={studentList}
                        totalCount={totalCount}
                        showMbHome={showMbHomeModal}
                        showMbAttachment={showMbAttachmentModal}
                        onCalculateAvg={onCalculateAvg}
                        avgGpa={avgGpa}
                    />
                    : <ReportList list={reportList} />
            }

            {mbHomeModal && <MbHomeModal school={schoolId} open={mbHomeModal} onClose={onMbHomeModalClose} content={mbHomeModalContent} onSubmit={onHomeSubmit}/>}
            {mbAttachmentModal && <MbAttachmentModal school={schoolId} open={mbAttachmentModal} onClose={onMbAttachmentModalClose} content={mbAttachmentModalContent} onSubmit={onAttachmentSubmit} />}
        </>
    )
}

export default StudentsGradution
