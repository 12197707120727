import * as React from 'react';
import { useTranslation } from 'react-i18next';
import '../styles/app.css';
import SurveyReportContainer from '../containers/Survey/Report';

export const OfficerSurveyReportPage = (props) => {
    const { t } = useTranslation();

    return (
        <div>
            <SurveyReportContainer />
        </div>
    );
};