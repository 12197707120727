import React,{useState,useEffect} from 'react'
import { Modal,Button,Row,Col,CloseButton,Card,Image } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Select from '../../../../../modules/Form/Select'
import { htmlDecode } from '../../../../../../utils/utils'
import Checkbox from '../../../../../modules/Form/Checkbox'
import ReactPlayer from 'react-player'
import { ROOT_URL } from '../../../../../../utils/fetchRequest'
import clsx from "clsx";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import { teacherOnlineExamResult, teacherOnlineExamAnswer } from '../../../../../../utils/fetchRequest/Urls'
import { setLoading } from '../../../../../../redux/action'
import { fetchRequest } from '../../../../../../utils/fetchRequest'
import message from '../../../../../modules/message'
import { useDispatch, useSelector } from "react-redux";
import styles from '../../../../exam/question/types/questionTypes.module.scss';
import { Radio as MUIRadio, TextareaAutosize } from "@material-ui/core";
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory, { PaginationProvider, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import { sortCaret } from '../../../../../../_metronic/_helpers'
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit'
import PaginationLinks from '../../../../../modules/DataTable/PaginationLinks'
import { useRef } from 'react'

const ViewAnswer = ({
    onClose = () =>{},
    exam,
    school,
    student
}) => {
    const {t} = useTranslation();


    const [questionList,setQuestionList] = useState([]);
    
    const types = [
        {
            value: 'true',
            label: 'Үнэн',
        },
        {
            value: 'false',
            label: 'Худал',
        },
    ]

    const { SearchBar } = Search;
    const dispatchGlobally = useDispatch();

    const [ pageNumber, setPageNumber ] = useState(1);
    const [ sizePerPage, setSizePerPage ] = useState(10);
    const tableRef = useRef (null);

    useEffect(()=>{
        fetch();
    },[])

    const fetch = () => {
        const params = {
            exam: exam,
            school: school,
            student: student,
        };
        
        dispatchGlobally(setLoading(true));
        fetchRequest(teacherOnlineExamResult, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res

                    if(data?.questions?.length){
                        setQuestionList(data?.questions || [])
                        //questionList = data.questions
                    }
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatchGlobally(setLoading(false));
            })
            .catch(() => {
                dispatchGlobally(setLoading(false));
                message(t('errorMessage.title'))
            })
    }
    const htmlFormatter = cell => {
        var div = document.createElement("div");
        div.innerHTML = cell;
        var text = div.textContent || div.innerText || "";
        div.remove();
        return shorten(text);
    };

    const shorten = (value) =>{
        if(value && value.length > 30){
            var trimmedString = value.substr(0, 30);
            trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")));
            return trimmedString;
        }
        return value;
    }

    const columns = [{
        dataField: 'id',
        text: 'questionId',
        hidden: true,
        editable: false
      }, 
      {
        isDummyField: true,
        text: '№',
        dataField: 'dt-row-order-number',
        formatter(cell, row, rowIndex) {
            return rowIndex + 1;
        },
        
        headerAlign: 'center',
        style: {
            textAlign: 'right',
        },
        editable: false,
        csvFormatter(cell,row,rowIndex) {
            return rowIndex + 1;
        }
      },
      {
        dataField: 'question',
        text: t('exam.assignment'),
        formatter: htmlFormatter,
        editable: false,
        sort: true,
        sortCaret: sortCaret
      }, 
      {
        dataField: 'questionTypeName',
        text: t('exam.questionType'),
        editable: false,
        sort: true,
        sortCaret: sortCaret
      },
      {
        dataField: 'difficultyName',
        text: t('common.level'),
        editable: false,
        sort: true,
        sortCaret: sortCaret
      },
      {
        dataField: 'topicName',
        text: t('teacher.topic'),
        editable: false,
        sort: true,
        sortCaret: sortCaret
      },
      {
        dataField: 'score',
        text: t('assessment.score'),
        editable: false,
        sort: true,
        sortCaret: sortCaret
      },
      {
        dataField: 'takenScore',
        text: t('assessment.takenScore'),
        sort: true,
        sortCaret: sortCaret,
        //type: 'string',
        editable: (content, row, rowIndex, columnIndex) => row.type == 'TEXTINPUT'
      }
    ];
      const renderFile = (url) =>{
        if(typeof url == 'string'){
            if(/\.(jpg|jpeg|png|gif)$/.test(url)){
                return( 
                    <Image
                        src={ROOT_URL+ '/' +url}
                        height={'160px'}
                    />
                )
            }else{
                return (
                    <ReactPlayer
                        width={'320px'}
                        height={'160px'}
                        url={ROOT_URL+ '/' +url}
                    />
                )
            }
        }else{
            return null
        }
    }
    
    const renderAnswers = (row, answer) =>{
        if(row.type === 'MULTICHOICE'){
            const answerArr = row.answer || []
            if(answer.filePath){
                return <div className='p-4'>
                    <Image
                        src={ROOT_URL+ '/' +answer.filePath}
                        height={'160px'}
                        style={
                            answerArr.includes(answer.id)
                            ? 
                                (answer.isCorrect 
                                ?
                                    {opacity:'1',cursor:'pointer',boxShadow: '0 0 5px 5px #299f72'}
                                : answer.isCorrect == false ? {opacity:'1',cursor:'pointer'} : null)
                            : (answer.isCorrect 
                                ?
                                    {opacity:'.5',cursor:'pointer',boxShadow: '0 0 5px 5px #299f72'}
                                : answer.isCorrect == false ? {opacity:'.5',cursor:'pointer'} : null)
                        }
                    />
                    <Checkbox
                        disabled
                        label={answer.answer}
                        className='ml-10'
                        checked={answerArr?.includes(answer.id)}
                    />
                </div>
            }else{
                return (
                    <div style={answer.isCorrect ? correctBg : null}>
                        <Checkbox
                            disabled
                            label={answer.answer}
                            className='ml-10'
                            checked={answerArr?.includes(answer.id)}
                        />
                    </div>
                )
            }
        }
        else if(row.type === 'TRUEFALSE'){
            return (
                <div>
                    {
                        types.map(type => {
                            return (
                                <div
                                    key={type.value}
                                    className={styles.rowContainer}
                                >
                                    <MUIRadio
                                        disabled
                                        checked={answer.answer === type.value}
                                        className='ml-10'
                                    />
                                    <div className={clsx('form-control', styles.readOnly)}>
                                        {type.label}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            )
        }
        else if(row.type === 'RADIO'){
            const answerArr = row.answer || []
            if(answer.filePath){
                return (
                <div className='p-4'>
                    <Image
                        src={ROOT_URL +'/'+ answer.filePath}
                        height={'160px'}
                        style={
                            answerArr.includes(answer.id)
                            ? 
                                (answer.isCorrect 
                                ?
                                    {opacity:'1',cursor:'pointer',boxShadow: '0 0 5px 5px #299f72'}
                                : {opacity:'1',cursor:'pointer'})
                            : (answer.isCorrect 
                                ?
                                    {opacity:'.5',cursor:'pointer',boxShadow: '0 0 5px 5px #299f72'}
                                : {opacity:'.5',cursor:'pointer'})
                        }
                    />
                    <Checkbox
                        label={answer.answer}
                        className='ml-10'
                        checked={answerArr?.includes(answer.id)}
                    />                    
                </div>
                )
            }else{
                return (
                    <Checkbox
                        label={answer.answer}
                        className='ml-10'
                        checked={answerArr?.includes(answer.id)}
                    />
                )
            }
            
        }else if(row.type === 'CONNECT'){
            return (
                <Row className='align-items-center' >
                    <Col md={3} className='text-center' style={answer.isCorrect ? correctBg : answer.isCorrect == false ? wrongBg : null}>
                        <span style={{fontSize: '16px'}}>
                            <b>{answer.premise}</b>
                        </span>
                    </Col>
                    <Col md={3} className='text-center' style={answer.isCorrect ? correctBg : answer.isCorrect == false ? wrongBg : null}>
                        <span className='mx-2' style={{fontSize: '16px'}}>
                            {answer.response[1] || '-'}
                        </span>
                    </Col>
                    
                </Row>
            )
        }else if(row.type === 'ORDER'){
            return (
                <Row className='align-items-center' >
                    <Col md={3} className='text-center' style={answer.isCorrect ? correctBg : answer.isCorrect == false ? wrongBg : null}>
                        <span className='mx-2' style={{fontSize: '16px'}}>
                            {answer.answer || '-'}
                        </span>
                    </Col>
                </Row>
            )
        }
        else if(row.type === 'TEXTINPUT'){
            return (
                <div className='align-items-center w-100 my-2'>
                    <TextareaAutosize disabled
                        className={clsx('form-control')}
                        placeholder={t('exam.premiseResponse')}
                        rowsMin={4}
                        cols={4}
                        value={row?.answer}
                    />
                </div>
            )
        }else if(row.type === 'NUMBERINPUT'){
            return (
                <div className='align-items-center w-100 my-2'>
                    <input
                        type='number' disabled
                        className={clsx('form-control')}
                        placeholder={t('exam.premiseResponse')}
                        value={row?.answer}
                    />
                    {
                        row?.answer2 ?
                            <input
                                type='number' disabled
                                className={clsx('form-control')}
                                placeholder={t('exam.premiseResponse')}
                                value={answer?.answer2}
                            />
                            : null
                    }
                </div>
            )
        }else{
            return null
        }
    }

    const selectRow = {
        mode: 'radio',
        clickToExpand: true,
        clickToSelect: true,
        clickToEdit: true,
        style: { backgroundColor: '#c8e6c9' },     
    };

    const expandRow = {
        onlyOneExpanding: false,
        renderer: row => {
            return (
                row.type === 'TEXTINPUT' || row.type === 'NUMBERINPUT'
                ?
                <div className='w-100 my-4'>
                    {renderAnswers(row, row.answers[0])}
                </div>
                : 
                (row.answers?.length
                ?
                row.answers.map(a=>{
                    return (
                        <div 
                            key={a.id}
                            className='w-100 my-2'
                        >
                            {renderAnswers(row,a)}
                        </div>
                    )
                })
                : null)
            )
        }
    };

    const afterSave = (oldValue, newValue, row, column) =>{
        if(!/^[0-9]*(\.[0-9]{0,2})?$/.test(newValue)){
            message(t('exam.error.scoreNotNumber'), false);
            newValue = oldValue;
            row.takenScore = 0;
            return;
        }
        if(parseFloat(newValue) > parseFloat(row.score)){
            message('Даалгаварын онооноос их байна', false);
            row.takenScore = 0;
            return;
        }
        newValue = parseFloat(newValue);
        row.takenScore = newValue;
    }

    const onSave = () => {
        let param = [];
        for(let i=0; i<questionList.length; i++){
            if(questionList[i].type == 'TEXTINPUT'){
                let tmp = [];
                if(questionList[i].esId){
                    tmp['esId'] = questionList[i].esId;
                    tmp['score'] = questionList[i].takenScore
                    param.push(tmp);
                }
            }
        }
        if(param.length > 0){
            const params = {
                exam: exam,
                student: student,
                dtl: param
            };
            
            dispatchGlobally(setLoading(true));
            fetchRequest(teacherOnlineExamAnswer, 'POST', params)
                .then(res => {
                    if (res.success) {
                        message(t('common.success'), true);
                        dispatchGlobally(setLoading(false));
                        onClose();
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                    dispatchGlobally(setLoading(false));
                })
                .catch(() => {
                    dispatchGlobally(setLoading(false));
                    message(t('errorMessage.title'))
                })
        }
    }

    const pgOptions = {
        custom: true,
        paginationSize: 5,
        sizePerPageList: [ 10, 25, 50, 100 ],
        totalSize: questionList && questionList.length > 0 ? questionList.length : 0,
        page: pageNumber,
        sizePerPage
    };

    const getUserInteraction = (object) => {
        let tableState = {};
        if(tableRef.current){
            const { page, sizePerPage: pageSize, sortField, sortOrder } = tableRef.current.getNewestState();
            tableState = {
                page: page ? page : 1,
                pageSize,
                sort: sortField,
                order: sortOrder,
                filter: object
            };
        }
    };

    const handleTableChange = (type, newState) => {
        switch (type) {
            case 'filter': {
                break;
            }
            case 'pagination': {
                if (newState.page !== pageNumber) {
                    setPageNumber(newState.page);
                }
                if (newState.sizePerPage !== sizePerPage) {
                    setSizePerPage(newState.sizePerPage);
                    // getUserInteraction({ pageSize: newState.sizePerPage });
                }
                getUserInteraction({
                    page: newState.page ? newState.page : 1,
                    pageSize: newState.sizePerPage
                });
                break;
            }
            case 'sort': {
                getUserInteraction ({
                    sort: newState.sortField,
                    order: newState.sortOrder
                });
                break;
            }
            case 'cellEdit': {
                break;
            }
            default: {
                return;
            }
        }
    };

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('onlineExam.enterScore')}
                    </div>
                </Modal.Title>
                <CloseButton onClick={onClose}/>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={12}>
                        <PaginationProvider pagination={paginationFactory(pgOptions)}>
                        {
                            ({
                                paginationProps,
                                paginationTableProps
                            }) => (
                            <div>
                                <ToolkitProvider
                                    keyField="id"
                                    data={ questionList }
                                    columns={ columns }
                                    search
                                >
                                    {
                                        props => (
                                        <div>
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                marginBottom: 10,
                                                marginLeft: 'auto'
                                            }}>
                                                <SizePerPageDropdownStandalone
                                                    className={`custom-size-per-page`}
                                                    {...paginationProps}
                                                />
                                                <SearchBar { ...props.searchProps } />
                                            </div>
                                            <BootstrapTable
                                                keyField='id'
                                                data={ questionList }
                                                columns={ columns }
                                                cellEdit={cellEditFactory({
                                                    mode: 'click',
                                                    afterSaveCell: afterSave
                                                })}
                                                onTableChange={handleTableChange}
                                                expandRow={ expandRow }
                                                {...paginationTableProps}
                                                { ...props.baseProps }                     
                                            />
                                        </div>
                                        )
                                    }
                                </ToolkitProvider>
                                {
                                    (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div style={{ flex: 1 }}>
                                                <PaginationLinks paginationProps={paginationProps}/>
                                            </div>
                                            <div>{`Нийт ${paginationProps.totalSize} утга`}</div>
                                        </div>
                                    )
                                }
                                </div>
                            )}
                        </PaginationProvider>
                    </Col>                    
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className='btn btn-link bolder'>{t('common.cancel')}</button>
                <Button className="btn btn-outline-success w-25" onClick={onSave}>{t('common.save')}</Button>
            </Modal.Footer>
        </Modal>
    )
}
const correctBg = {
    backgroundColor: '#299f7233',
    border: 'none',
    borderRadius: '6px'
}

const wrongBg = {
    backgroundColor: '#f64e6033',
    border: 'none',
    borderRadius: '6px'
}
export default ViewAnswer