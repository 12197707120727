import React, { useRef, useEffect, useState } from "react";
import { Button, Modal, View, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
    getAthlethes,
    postAthlethes,
} from "../../../../utils/fetchRequest/Urls";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import message from "../../../modules/message";
import Forms from "../../../modules/Form/Forms";

const ChangeAthlete = ({ onClose, onFinish, id, phase, enroll }) => {
    const { t } = useTranslation();
    const [candidateDetail, setCandidateDetail] = useState([]);
    const [athlete, setAthlete] = useState(null);
    const [dtl, setDtl] = useState(null);
    const [candidateAtheltes, setCandidateAtheltes] = useState([]);
    const [candidateLogDesc, setCandidateLogDesc] = useState([]);
    const dispatch = useDispatch();
    const [inputRefs, setInputRefs] = useState([]);
    const form = useRef();

    const arrayAthlete = () => { };

    useEffect(() => {
        getData();

        // getSchool();
    }, []);
    const getData = () => {
        const params = {
            enroll: enroll,
            candidate: id,
            phase: phase,
        };
        dispatch(setLoading(true));
        fetchRequest(getAthlethes, "GET", params)
            .then((res) => {
                if (res.success) {
                    const { data } = res;
                    if (data?.candidateDetail) {
                        setCandidateDetail(data.candidateDetail);
                    }
                    if (data?.candidateAtheltes?.length) {
                        setCandidateAtheltes(data.candidateAtheltes);
                    }
                    if (data?.candidateLogDesc != null) {
                        setCandidateLogDesc(data.candidateLogDesc);
                    }
                } else {
                    message(res?.data?.message || t("errorMessage.title"));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t("errorMessage.title"));
            });
    };

    useEffect(() => {
        form?.current?.updateFields && form.current.updateFields(forms);
    }, [candidateLogDesc, candidateDetail, candidateAtheltes]);

    const onSaveClick = () => {
        const [formValid, formValues] = form.current.validate();
        if (formValid) {
            const criteriaList = [];
            candidateAtheltes.forEach((item) => {
                const myInput = document.getElementById(item.criteriaId);
                const inputValue = myInput.value;
                criteriaList.push({
                    criteriaId: item.criteriaId,

                    criteriaScore: item.criteriaScore,
                    score: inputValue,
                });
            });
            const params = {
                candidate: id,
                description: formValues?.[0]?.value,
                criterias: criteriaList,
                enroll: enroll,
                phase: phase,
            };
            dispatch(setLoading(true));
            fetchRequest(getAthlethes, "POST", params)
                .then((res) => {
                    if (res.success) {
                        const { data } = res;
                        const params1 = {
                            candidateProgram: data.candidateProgram,
                            candidate: data.candidate,
                            dtl: data.dtl,
                        };
                        fetchRequest(postAthlethes, "POST", params1)
                            .then((res) => {
                                if (res.success) {
                                    message(res?.data?.message, true);
                                    onFinish();
                                    dispatch(setLoading(false));
                                } else {
                                    message(res.data.message, false);
                                }
                                dispatch(setLoading(false));
                            })
                            .catch(() => {
                                dispatch(setLoading(false));
                                message(t("errorMessage.title"));
                            });

                        // dispatch(setLoading(false));
                        // const { data } = res;
                        // if (data) {
                        //   setDtl(data);
                        // }
                        // onFinish();
                    } else {
                        message(res.data.message, false);
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t("errorMessage.title"));
                });
        }
    };
    console.log("dd", dtl);

    const forms = [
        {
            label: "Тайлбар*",
            type: "textArea",
            inputWidth: 200,
            labelWidth: 200,
            maxLength: 160,
            key: "description",
            required: true,
            value: candidateLogDesc.description,
        },
    ];

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: "#4a70ae", fontSize: "1.1rem" }}>Засах</div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="d-flex justify-content-end col-3 ml-10">
                        <img
                            class="d-flex justify-content-end"
                            style={{
                                objectFit: "contain",
                                height: "105px",
                                width: "93px",
                                margin: "auto",
                                marginTop: "0.5px",
                                marginLeft: "10rem",
                            }}
                            src={
                                candidateDetail.avatar === null ||
                                    candidateDetail.avatar === "" ||
                                    candidateDetail.avatar === undefined ||
                                    candidateDetail.avatar === "NULL"
                                    ? "/media/users/avatar_male.png"
                                    : "https://darkhanapi.nomch.mn/" + candidateDetail.avatar
                            }
                        />
                    </div>
                    <ul className="col-7" style={{ lineHeight: 0.5 }}>
                        <li class="d-flex justify-content-center row">
                            <div class="col">
                                <p
                                    style={{
                                        color: "#185BC5",
                                        fontWeight: "bold",
                                        fontSize: "normal",
                                    }}
                                    class=" d-flex justify-content-end"
                                >
                                    Бүртгэлийн дугаар
                                </p>
                            </div>
                            <div class="col justify-content-end">
                                <span style={{ fontWeight: "bold" }} class="text-dark">
                                    {candidateDetail.id}
                                </span>
                            </div>
                        </li>
                        <li class="d-flex justify-content-center row pb-3">
                            <div class="col">
                                <p
                                    style={{
                                        color: "#185BC5",
                                        fontWeight: "bold",
                                        fontSize: "normal",
                                    }}
                                    class=" d-flex justify-content-end"
                                >
                                    ЭЕШ бүртгэлийн дугаар
                                </p>
                            </div>
                            <div class="col justify-content-end">
                                <span style={{ fontWeight: "bold" }} class="text-dark">
                                    {candidateDetail.butName}
                                </span>
                            </div>
                        </li>
                        <li class="d-flex justify-content-center row">
                            <div class="col">
                                <p
                                    style={{
                                        color: "#185BC5",
                                        fontWeight: "bold",
                                    }}
                                    class=" d-flex justify-content-end"
                                >
                                    Овог
                                </p>
                            </div>
                            <div class="col justify-content-end">
                                <span style={{ fontWeight: "bold" }} class="text-dark">
                                    {candidateDetail.lastName}
                                </span>
                            </div>
                        </li>
                        <li class="d-flex justify-content-center row">
                            <div class="col">
                                <p
                                    style={{
                                        color: "#185BC5",
                                        fontWeight: "bold",
                                        fontSize: "normal",
                                    }}
                                    class=" d-flex justify-content-end"
                                >
                                    Нэр
                                </p>
                            </div>
                            <div class="col justify-content-end">
                                <span style={{ fontWeight: "bold" }} class="text-dark">
                                    {candidateDetail.firstName}
                                </span>
                            </div>
                        </li>
                        <li class="d-flex justify-content-center row">
                            <div class="col">
                                <p
                                    style={{
                                        color: "#185BC5",
                                        fontWeight: "bold",
                                        fontSize: "normal",
                                    }}
                                    class=" d-flex justify-content-end"
                                >
                                    Регистрийн дугаар
                                </p>
                            </div>
                            <div class="col justify-content-end">
                                <span style={{ fontWeight: "bold" }} class="text-dark">
                                    {candidateDetail.registrationNumber}
                                </span>
                            </div>
                        </li>
                        <li class="d-flex justify-content-center row">
                            <div class="col">
                                <p
                                    style={{
                                        color: "#185BC5",
                                        fontWeight: "bold",
                                        fontSize: "normal",
                                    }}
                                    class=" d-flex justify-content-end"
                                >
                                    Хүйс
                                </p>
                            </div>
                            <div class="col justify-content-end">
                                <span style={{ fontWeight: "bold" }} class="text-dark">
                                    {candidateDetail.gender === "M" ? "Эрэгтэй" : "Эмэгтэй"}
                                </span>
                            </div>
                        </li>
                        <li class="d-flex justify-content-center row">
                            <div class="col">
                                <p
                                    style={{
                                        color: "#185BC5",
                                        fontWeight: "bold",
                                        fontSize: "normal",
                                    }}
                                    class=" d-flex justify-content-end"
                                >
                                    И-мэйл
                                </p>
                            </div>
                            <div class="col justify-content-end">
                                <span style={{ fontWeight: "bold" }} class="text-dark">
                                    {candidateDetail.email}
                                </span>
                            </div>
                        </li>
                        <li class="d-flex justify-content-center row">
                            <div class="col">
                                <p
                                    style={{
                                        color: "#185BC5",
                                        fontWeight: "bold",
                                        fontSize: "normal",
                                    }}
                                    class=" d-flex justify-content-end"
                                >
                                    Утасны дугаар
                                </p>
                            </div>
                            <div class="col justify-content-end">
                                <span style={{ fontWeight: "bold" }} class="text-dark">
                                    {candidateDetail.gMobileNumber}
                                </span>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="d-flex justify-content-center">
                    <table style={{ width: 400 }} class="table table-bordered  ">
                        {/* <table style={{ width: 300 }}> */}

                        {candidateAtheltes.map((item) => (
                            <tbody key={item.code}>
                                <tr>
                                    <th class="col-4" scope="row">
                                        {item.name}
                                    </th>
                                    <td class="col-4">{item.criteriaScore}</td>
                                    <td class="col-4">
                                        {/* <Forms
                      className="w-full d-flex justify-content"
                      ref={form}
                      fields={forms1}
                      id={item.id}
                    /> */}
                                        <input
                                            style={{
                                                width: "100%",

                                                border: "1px solid",
                                                borderColor: "#EBEDF2",
                                                borderRadius: "4px",
                                            }}
                                            type="text"
                                            id={item.criteriaId}
                                            defaultValue={item.score}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        ))}

                        {/* </table> */}
                    </table>
                </div>
                <div class="d-flex justify-content-center">
                    <Forms ref={form} fields={forms} />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className="btn btn-link bolder">
                    {t("common.back")}
                </button>
                <Button variant="success btn-shadow" onClick={onSaveClick}>
                    {t("common.save")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ChangeAthlete;
