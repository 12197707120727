import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Forms from "app/modules/Form/Forms";
import { EditorState, ContentState, convertToRaw, convertFromHTML } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "_metronic/_assets/plugins/urga/urga.css";
import draftToHtml from 'draftjs-to-html';
import { useDispatch } from 'react-redux';
import { setLoading } from 'redux/action';
import { fetchRequest } from 'utils/fetchRequest';
import message from 'app/modules/message';
import { graduationTemplateDelete, graduationTemplateEdit } from 'utils/fetchRequest/Urls';

const EditModal = ({
    open = false,
    onClose = () => {},
    id = null,
    setDatas = () => {},
    action = 'edit'
}) => {
    const {t} = useTranslation()

    const ref = useRef();
    const dispatch = useDispatch()
    
    const [name,setName] = useState('')
    const [textValue,setTextValue] = useState(() => EditorState.createEmpty());
    const [textValueEn,setTextValueEn] = useState(() => EditorState.createEmpty());
    const [textValueTr,setTextValueTr] = useState(() => EditorState.createEmpty());
    
    const fields = useMemo(()=>[
        {   
            label: t('graduation.templateName'),
            value: name,
            onChange: e=>setName(e.target.value),
            type: 'text',
            required: true,
            key: 'name',
        }
    ],[name])
    
    const init = () => {
        try {
            dispatch(setLoading(true));
            fetchRequest(graduationTemplateEdit, 'GET', {id})
                .then(res => {
                    if (res.success) {
                        const {data : { 
                            name = '',
                            mb = null,
                            mn = null,
                            en = null
                        } = {}} = res
                        setName(name)
                        const mnState = ContentState.createFromBlockArray(
                            convertFromHTML(mn).contentBlocks,
                            convertFromHTML(mn).entityMap
                        );
                        const enState = ContentState.createFromBlockArray(
                            convertFromHTML(en).contentBlocks,
                            convertFromHTML(en).entityMap
                        );
                        const trState = ContentState.createFromBlockArray(
                            convertFromHTML(mb).contentBlocks,
                            convertFromHTML(mb).entityMap
                        );
                        
                        setTextValue(()=>EditorState.createWithContent(mnState))
                        setTextValueTr(()=>EditorState.createWithContent(trState))
                        setTextValueEn(()=>EditorState.createWithContent(enState))
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                })
                .catch(() => {
                    message(t('errorMessage.title'))
                })
                .finally(()=>dispatch(setLoading(false)))
        } catch (e) {
            message(t('errorMessage.title'))
        }
    }

    const onSave = (publish = 0) => {
        const [ formValid, formValue ] = ref.current.validate();

        if(formValid && formValue && formValue.length) {
            dispatch(setLoading(true));
            
            const mnRaw = convertToRaw(textValue.getCurrentContent());
            const mn = draftToHtml(mnRaw);
            const enRaw = convertToRaw(textValueEn.getCurrentContent());
            const en = draftToHtml(enRaw);
            const trRaw = convertToRaw(textValueTr.getCurrentContent());
            const tr = draftToHtml(trRaw); 
            const params = {
                id,
                name: formValue[0]?.value,
                mn, 
                en, 
                mb: tr,
                publish
            }
            fetchRequest(graduationTemplateEdit, 'POST', params)
                .then(res => {
                    if (res.success) {
                        const { data: {
                            templates = [],
                            totalCount = 0
                        } = {}} = res || {}
                        setDatas(templates,totalCount)
                        onClose()
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                })
                .catch(() => {
                    message(t('errorMessage.title'))
                })
                .finally(()=>dispatch(setLoading(false)))
        }
    }

    const onDelete = () => {
        try {
            dispatch(setLoading(true));
            const params = {
                id
            }
            fetchRequest(graduationTemplateDelete, 'POST', params)
                .then(res => {
                    if (res.success) {
                        const { data: {
                            templates = [],
                            totalCount = 0
                        } = {}} = res || {}
                        setDatas(templates,totalCount)
                        onClose()
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                })
                .catch(() => {
                    message(t('errorMessage.title'))
                })
                .finally(()=>dispatch(setLoading(false)))
        } catch (e) {
            message(t('errorMessage.title'))
        }
    }

    useEffect(()=>{
        if(id) {
            init()
        }
    },[id])

    const editAction = () => {
        return <Modal.Body>
            <Forms
                ref={ref}
                fields={fields}
                fieldChanged={name}
            />
            <div className='mb-5' />
            <h5 className="font-weight-bold">Монгол</h5>
            <Editor
                editorState={textValue}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={setTextValue}
                editorStyle={{ border: "1px solid rgba(0, 0, 0, .2)", fontFamily: 'Arial'}}
                stripPastedStyles={true}
                toolbar={{
                    options: ['inline', 'textAlign', 'history'],
                    inline: { 
                        options: ['bold', 'italic'],
                    }
                }}
            />
            <div style={dottedLine}/>
            <h5 className="font-weight-bold">English</h5>
            <Editor
                editorState={textValueEn}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={setTextValueEn}
                editorStyle={{ border: "1px solid rgba(0, 0, 0, .2)", fontFamily: 'Arial'}}
                stripPastedStyles={true}
                toolbar={{
                    options: ['inline', 'textAlign', 'history'],
                    inline: { 
                        options: ['bold', 'italic'],
                    }
                }}
            />
            <div style={dottedLine}/>
            <h5 className="font-weight-bold">Монгол бичиг</h5>
            <Editor
                editorState={textValueTr}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={setTextValueTr}
                editorStyle={{ border: "1px solid rgba(0, 0, 0, .2)", fontFamily: 'Urga'}}
                stripPastedStyles={true}
                toolbar={{
                    options: ['inline', 'textAlign', 'history'],
                    inline: { 
                        options: ['bold', 'italic'],
                    }
                }}
            />
        </Modal.Body>
    }

    const deleteAction = () => {

        return <Modal.Body>
            {t('warning.delete_confirmation')}
            <br />
            <br />
            {t('warning.delete_confirmation_description')}
        </Modal.Body>
    }

    return (
        <Modal
            show={open}
            onHide={onClose}
            size={action == 'edit' ? "xl" : 'sm'}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {t('graduation.template')}
                </Modal.Title>
            </Modal.Header>
            {
                action == 'edit'
                ? editAction()
                : deleteAction()
            }
            <Modal.Footer>
                <button onClick={onClose} className="btn btn-link bolder mr-2">
                    {t("common.close").toLocaleUpperCase()}
                </button>
                <Button variant="success btn-shadow mr-2" onClick={()=>{
                    if(action == 'edit') {
                        onSave()
                    } else {
                        onDelete()
                    }
                }}>
                    {action == 'edit' ? t('common.save') : t('action.delete')}
                </Button>
                {
                    action == 'edit'
                    ?
                        <Button variant="success btn-shadow mr-2" onClick={()=>onSave(1)}>
                            {t('action.publish')}
                        </Button>
                    : null
                }
            </Modal.Footer>
        </Modal>
    )
}

const dottedLine = {
    borderTop: '1px dashed #000000',
    height: '1px',
    margin: '1rem'
}

export default EditModal
