import React, {useRef, useEffect, useState} from 'react';
import {Button, ButtonToolbar, Card} from "react-bootstrap";
import Forms from "../../../../modules/Form/Forms";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {teacherOnlineLessonNewsfeedAdd} from "../../../../../utils/fetchRequest/Urls";
import { setLoading } from "../../../../../redux/action";
import { fetchRequest } from "../../../../../utils/fetchRequest";
import message from "../../../../modules/message";
import {Link, useHistory} from "react-router-dom";
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';

export const TeacherOnlineLessonEditDiscussionAdd = ({
    location: {state: {id = null} = {}} = {},
}) => {
    const { t } = useTranslation();
    const formRef = useRef();
    const history = useHistory();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [types, setTypes] = useState([]);

    useEffect(() => {
        const params = {
            school: schoolId,
            course: id
        };

        dispatch(setLoading(true));
        fetchRequest(teacherOnlineLessonNewsfeedAdd, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setTypes(data?.types || [])
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }, []);

    useEffect(() => {
        formRef?.current?.updateFields && formRef.current?.updateFields(fields);
    }, [types]);

    const fields = [
        {
            key: 'title',
            label: t('discussion.header'),
            labelWidth: 300,
            value: '',
            type: 'text',
            required: true,
        },
        {
            key: 'type',
            label: t('discussion.type'),
            labelWidth: 300,
            value: '',
            required: true,
            type: 'dropdown',
            options: types,
        },
        {
            key: 'start',
            label: t('homework.startDateTime'),
            labelWidth: 300,
            value: '',
            type: 'datetime',
            clearable: true,
            searchable: true,
            multiple: true,
            required: true,
        },
        {
            key: 'end',
            label: t('homework.endDateTime'),
            labelWidth: 300,
            value: '',
            type: 'datetime',
            clearable: true,
            searchable: true,
            multiple: true,
            required: true,
        },
    ];

    const updateTextDescription = async (state) => {
        setEditorState(state);
    };

    const handlerOnSaveClick = (active) => {
        const [ isValid, states, values ] = formRef.current.validate();
        if (isValid) {
            if(editorState.getCurrentContent()){
                const bodyRaw = convertToRaw(editorState.getCurrentContent());
                const bodyHtml = draftToHtml(bodyRaw);

                const formData = new FormData();

                formData.append('school', schoolId);
                formData.append('course', id);
                formData.append('title', values.title );
                formData.append('description', bodyHtml);
                formData.append('start', values.start);
                formData.append('end', values.end);
                formData.append('active', active);
                formData.append('type', values.type);

                dispatch(setLoading(true));
                fetchRequest(teacherOnlineLessonNewsfeedAdd, 'POST', formData, false, true)
                    .then(res => {
                        if (res.success) {
                            const { data } = res;

                            history.push({
                                pathname: '/teacher/online/lesson-edit',
                                state: {
                                    courseId: id,
                                }
                            });
                        } else {
                            message(res?.data?.message || t('errorMessage.title'))
                        }
                        dispatch(setLoading(false));
                    })
                    .catch(() => {
                        dispatch(setLoading(false));
                        message(t('errorMessage.title'))
                    })
            }
        }
    };

    return (
        <div className='sm-container'>
            <Card>
                <Card.Header>
                    <span className='french-blue fs-10'>
                        <b>{t('discussion.create').toUpperCase()}</b>
                    </span>
                </Card.Header>
                <Card.Body>
                    <div className='col-12'>
                        <Forms
                            ref={formRef}
                            fields={fields}
                        />
                    </div>
                    <p/>
                    <div className='col-12 newfeed-style'>
                        <div className='mb-2'>{t('discussion.description')}</div>
                        <Editor
                            editorState={editorState}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={updateTextDescription}
                            editorStyle={{ border: "1px solid rgba(0, 0, 0, .2)"}}
                        />
                    </div>
                </Card.Body>
                <Card.Footer>
                    <div className='col-11 text-center'>
                        <Link
                            to={{
                                pathname: '/teacher/online/lesson-edit',
                                state: {
                                    courseId: id,
                                }
                            }}
                            className="btn btn-link bolder"
                        >
                            {t('common.back')}
                        </Link>
                        <Button variant="success btn-shadow" onClick={() => handlerOnSaveClick(0)}>{t('common.save')}</Button>
                        <Button variant="publish btn-shadow ml-2" onClick={() => handlerOnSaveClick(1)}>{t('common.open')}</Button>
                    </div>
                </Card.Footer>
            </Card>
        </div>
    );
};