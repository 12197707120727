import React, {useRef, useEffect, useState} from 'react';
import { Button, Modal } from "react-bootstrap";
import Forms from "../../../../modules/Form/Forms";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {financeConfigScholarshipTypes} from "../../../../../utils/fetchRequest/Urls";
import { setLoading } from "../../../../../redux/action";
import { fetchRequest } from "../../../../../utils/fetchRequest";
import message from "../../../../modules/message";

const AddScholarshipType = ({
    onClose,
    onSubmit,
}) => {
    const { t } = useTranslation();
    const formRef = useRef();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [codes, setCodes] = useState([]);
    const [code, setCode] = useState('');
    const [name, setName] = useState('');
    const [minScore, setMinScore] = useState('');
    const [amount, setAmount] = useState('0');
    const [isAmount, setIsAmount] = useState(false);
    const [isActive, setIsActive] = useState(true);
    const [selectedCode, setSelectedCode] = useState(null);

    useEffect(() => {
        const params = {
            school: schoolId,
        };
        dispatch(setLoading(true));
        fetchRequest(financeConfigScholarshipTypes, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    let codes = data.discountTypes;
                    if(codes && codes.length > 0){
                        let codesArray = [];
                        for(let i = 0; i < codes.length; i++)
                        {
                            codesArray.push({
                                value: codes[i].CODE,
                                text: codes[i].NAME
                            });
                        }
                        setCodes(codesArray)
                    }
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })

    }, []);

    const onInputCodeChange = (event) => {
        if(event){
            setCode((event.target.value).toUpperCase())
        }
    };

    const onInputNameChange = (event) => {
        if(event){
            setName(event.target.value)
        }
    };

    const onInputScoreChange = (event) => {
        if(event){
            setMinScore(event.target.value)
        }
    };

    const onCodeChange = (code) => {
        if(code === 'PERCENT' || code === 'AMOUNT'){
            setIsAmount(true);
        } else if(code === 'INCONSTANT') {
            setIsAmount(false);
        }
        setSelectedCode(code)
    };

    const onCheckboxChange = (val) => {
        if(val === false){
            setAmount(0);
        }
        setIsActive(val)
    };

    useEffect(() => {
        formRef?.current?.updateFields && formRef.current?.updateFields(fields);
    }, [codes, isAmount, selectedCode]);

    const fields = [
        {
            label: t('common.code'),
            value: code || '',
            type: 'nonCryllic',
            upperCase: true,
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            key: 'code',
            onChange: onInputCodeChange
        },
        {
            label: t('common.name'),
            value: name || '',
            type: 'text',
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            key: 'name',
            onChange: onInputNameChange
        },
        {
            label: t('scholarship.minScore'),
            value: minScore || '',
            type: 'number',
            inputWidth: 200,
            labelWidth: 300,
            key: 'minScore',
            onChange: onInputScoreChange
        },
        {
            label: t('common.type'),
            value: selectedCode || null,
            type: 'dropdown',
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            key: 'scholarshipType',
            options: codes,
            onChange: onCodeChange
        },
        {
            label: t('common.amount'),
            value: amount || '',
            type: 'numberOnly',
            inputWidth: 200,
            labelWidth: 300,
            required: isAmount,
            key: 'amount',
            validValue: selectedCode == 'PERCENT' ?
                [{
                    'min': 0,
                    'max': 100
                }]
                : 0,
            errorMessage: selectedCode == 'PERCENT' ? t('errorMessage.enterMinAndMax') : t('errorMessage.enterMinAmount'),
            hidden: !isAmount
        },
        {
            label: t('common.isPublish'),
            value: true,
            type: 'checkbox',
            inputWidth: 200,
            labelWidth: 300,
            key: 'isActive',
            onChange: onCheckboxChange
        },
    ];

    const onSaveClick = () => {
        const [ isValid, states, values ] = formRef.current.validate();
        if (isValid) {
            if(values.isActive){
                values.isActive = 1
            } else {
                values.isActive = 0
            }
            const params = {
                ...values,
            };
            onSubmit(params);
        }
    };

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('scholarship.type').toUpperCase()}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Forms
                    ref={formRef}
                    fields={fields}
                />
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className='btn btn-link bolder'>{t('common.back')}</button>
                <Button variant='success btn-shadow' onClick={onSaveClick}>{t('common.save')}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddScholarshipType;