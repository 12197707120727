import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Button, Card, ButtonToolbar, Modal } from "react-bootstrap";
import DTable from "../../../modules/DataTable/DTable";
import { fetchRequest } from "../../../../utils/fetchRequest";
import { setLoading } from "../../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import {
    studentSelectionInit,
    AddSubjectStudentSelection,
    studentSelectionIndex,
    studentSelectionPayment
} from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import { cloneDeep } from "lodash";
import Payment from "./Payment";
import CanSelect from "./CanSelect";
import ReactPlayer from 'react-player/youtube';

const StudentSelection = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [ helperModal, setHelperModal ] = useState(false);
    const [ addModal, setAddModal ] = useState(false);
    const [ deleteModal, setDeleteModal ] = useState(false);
    const [ programDetails, setProgramDetails ] = useState([]);
    const [ selectedIds, setSelectedIds ] = useState([]);
    const [ selectedDetails, setSelectedDetails ] =  useState([]);
    const [ configId, setSelectedConfigId ] = useState(null);
    const [ isPayment, setIsPayment] = useState(null);

    const [ selection1s, setSelection1s ] = useState([]);
    const [ selectionData, setSelectionData ] = useState([]);
    const [ payment1, setPayment1 ] = useState([]);
    const [ payment2, setPayment2 ] = useState([]);
    const [ payment2Penalties, setPayment2Penalties ] = useState([]);

    const [updateView, setUpdateView] = useState(false);

    useEffect(() => {
        const params = {
            school: schoolId,
            selection: 1
        };
        dispatch(setLoading(true));
        fetchRequest(studentSelectionIndex, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    let selection1Data = data.selection1s;
                    setSelectionData(data.selectionData);

                    if(selection1Data && selection1Data.length > 0){
                        if(data.selectionData && data.selectionData.length > 0){
                            let details = data.selectionData;
                            for (let i = 0; i < selection1Data.length; i++){
                                for(let d = 0; d < details.length; d++){
                                    if(selection1Data[i].seasonId == details[d].seasonId){
                                        selection1Data[i].selectionData = details[d];

                                        const groupByCategory = details[d].subjects.reduce((group, product) => {
                                            const { categoryName } = product;
                                
                                            group[categoryName] = group[categoryName] ?? [];
                                            group[categoryName].push(product);
                                
                                            return group;
                                        }, {});
                                
                                        for (var categories in groupByCategory){
                                            const typeNameList = groupByCategory[categories].reduce((group, product) => {
                                                const { typeName } = product;
                                    
                                                group[typeName] = group[typeName] ?? [];
                                                group[typeName].push(product);
                                    
                                                return group;
                                            }, {});
                                
                                            groupByCategory[categories] = typeNameList;
                                        }

                                        selection1Data[i].dtls = groupByCategory;
                                        selection1Data[i].totalCount = details[d].totalSubjectCount;
                                        selection1Data[i].totalCredit = details[d].totalCredit;
                                        selection1Data[i].totalCreditPrice = details[d].totalCreditPrice;
                                    }
                                }
                            }
                        }

                        setSelection1s(selection1Data)
                    }

                    setPayment1(data.payment1);
                    setPayment2(data.payment2);
                    setIsPayment(data.payment);
                    if(data.selection2Penalties && data.selection2Penalties.length > 0){
                        setPayment2Penalties(data.selection2Penalties);
                    }
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            })
    }, []);

    const handlerIcon = (action, content, row, rowIndex) => {
        let cloneSelectionData = [...selection1s];
        let credits = 0;
        let price = 0;
        let count = 0;

        if(cloneSelectionData && cloneSelectionData.length > 0){
            for(let i = 0; i < cloneSelectionData.length; i++){
                Object.keys(cloneSelectionData[i].dtls).map((category, ci) => { 
                    Object.keys(cloneSelectionData[i].dtls[category]).map((type, ti) => {
                        for(let typeList of cloneSelectionData[i].dtls[category][type]){
                            if(typeList.id == row.id){
                                cloneSelectionData[i].dtls[category][type].splice(rowIndex, 1);
                            }
                        }
                    })
                })
            }
        }

        if(cloneSelectionData && cloneSelectionData.length > 0){
            for(let i = 0; i < cloneSelectionData.length; i++){
                Object.keys(cloneSelectionData[i].dtls).map((category, ci) => { 
                    Object.keys(cloneSelectionData[i].dtls[category]).map((type, ti) => {
                        for(let typeList of cloneSelectionData[i].dtls[category][type]){
                            credits = credits + parseInt(typeList.credit);
                            price = price + parseInt(typeList.totalPrice);
                            count += 1;
                        }
                    })
                })

                cloneSelectionData[i]['totalCredit'] = credits;
                cloneSelectionData[i]['totalCreditPrice'] = price;
                cloneSelectionData[i]['totalCount'] = count;
            }
        }

        setSelection1s(cloneSelectionData);
        let isUpdateView = updateView;
        setUpdateView(!isUpdateView);
    };

    const iconFormatter = (cell, row, rowIndex, extraData) => {
        return (
            <Button onClick={() => handlerIcon('delete', cell, row, rowIndex, extraData)} className="btn btn-danger m-btn m-btn--icon m-btn--icon-only m-btn--circle-28">
                <i className="mini flaticon2-cross"> </i>
            </Button>
        );
    };

    const config = {
        showPagination: false,
        showFilter: false,
        showAllData: true,
        tableMarginLess: true
    };

    const getMainColumns = (approved) => {
        let columns = [];
        if(approved){
            columns = [
                {
                    dataField: "status",
                    text: t('common.status'),
                    sort: true,
                },
                {
                    dataField: "subjectCode",
                    text: t('subject.index'),
                    sort: false,
                },
                {
                    dataField: "subjectName",
                    text: t('subject.name'),
                    sort: false,
                },
                {
                    dataField: "credit",
                    text: t('subject.credit'),
                    sort: false,
                },
                {
                    dataField: "beforeSubjectName",
                    text: t('subject.previous'),
                    sort: false,
                },
                /*
                {
                    dataField: "maxLimit",
                    text: t('subject.time'),
                    sort: false,
                },
                {
                    dataField: "totalPrice",
                    text: t('assessment.tuition'),
                    sort: false,
                    align: 'right',
                }
                */
            ]
        } else {
            columns = [
                {
                    dataField: "status",
                    text: t('common.status'),
                    sort: true,
                },
                {
                    dataField: "subjectCode",
                    text: t('subject.index'),
                    sort: false,
                },
                {
                    dataField: "subjectName",
                    text: t('subject.name'),
                    sort: false,
                },
                {
                    dataField: "credit",
                    text: t('subject.credit'),
                    sort: false,
                },
                {
                    dataField: "beforeSubjectName",
                    text: t('subject.previous'),
                    sort: false,
                },
                /*
                {
                    dataField: "maxLimit",
                    text: t('subject.time'),
                    sort: false,
                },
                {
                    dataField: "totalPrice",
                    text: t('assessment.tuition'),
                    sort: false,
                    align: 'right',
                },
                */
                {
                    isDummyField: true,
                    dataField: "action",
                    text: "",
                    sort: false,
                    attrs: Attribute, //formatter ашиглаж байгаа бол attrs заавал дуудах ёстой.
                    formatter: !approved ? iconFormatter : null,
                }
            ]
        }
        return columns;
    };

    const Attribute = (cell, row, rowIndex, colIndex) => {
    };

    const columns = [
        // {
        //     dataField: "status",
        //     text: t('common.status'),
        //     sort: true,
        // },
        {
            dataField: "subjectCode",
            text: t('subject.index'),
            sort: false,
        },
        {
            dataField: "subjectName",
            text: t('subject.name'),
            sort: false,
        },
        {
            dataField: "credit",
            text: t('subject.credit'),
            sort: false,
        },
        {
            dataField: "beforeSubjectName",
            text: t('subject.previous'),
            sort: false,
        },
        /*
        {
            dataField: "registrationNumber",
            text: t('subject.time'),
            sort: false,
        },
        {
            dataField: "totalPrice",
            text: t('assessment.tuition'),
            sort: false,
        },
        */
        {
            dataField: "action",
            text: '',
            sort: false,
            style: {padding: 3},
            headerStyle: () => {
                return {
                    width: 120
                }
            },
            formatter(cell, row) {
                if(row.status && row.success){
                    return <div style={{padding: 7}}>{row.status}</div>
                } else if(row.status && row.isShow){
                    return <div style={{display: 'flex'}}>
                        <div style={{padding: 7}}><b>{row.status}</b></div>
                        <button style={{width: '100%'}} className='btn btn-success' onClick={() => handlerHideButton(row)}>
                            {t('action.back')}
                        </button>
                    </div>
                } else if(row.status){
                    return <button style={{width: '100%'}} className='btn btn-success' onClick={() => handlerViewButton(row)}>
                        {t('assessment.view')}
                    </button>;
                }

                return ''
            },
        },
    ];

    const handlerViewButton = (row) => {
        let cloneProgramDtl = [...programDetails];

        for(let i = 0; i < cloneProgramDtl.length; i++){
            for(let d = 0; d < cloneProgramDtl[i].details.length; d++){
                for(let l = 0; l < cloneProgramDtl[i].details[d].list.length; l++){
                    for(let t = 0; t < cloneProgramDtl[i].details[d].list[l].typeList.length; t++){
                        for(let s = 0; s < cloneProgramDtl[i].details[d].list[l].typeList[t].subjectList.length; s++){
                            if(row.id == cloneProgramDtl[i].details[d].list[l].typeList[t].subjectList[s].id){
                                cloneProgramDtl[i].details[d].list[l].typeList[t].subjectList[s].isShow = true;
                            }
                        }
                    }
                }
            }
        }

        setProgramDetails(cloneProgramDtl)
    }

    const handlerHideButton = (row) => {
        let cloneProgramDtl = [...programDetails];

        for(let i = 0; i < cloneProgramDtl.length; i++){
            for(let d = 0; d < cloneProgramDtl[i].details.length; d++){
                for(let l = 0; l < cloneProgramDtl[i].details[d].list.length; l++){
                    for(let t = 0; t < cloneProgramDtl[i].details[d].list[l].typeList.length; t++){
                        for(let s = 0; s < cloneProgramDtl[i].details[d].list[l].typeList[t].subjectList.length; s++){
                            if(row.id == cloneProgramDtl[i].details[d].list[l].typeList[t].subjectList[s].id){
                                cloneProgramDtl[i].details[d].list[l].typeList[t].subjectList[s].isShow = false;
                            }
                        }
                    }
                }
            }
        }

        setProgramDetails(cloneProgramDtl)
    }

    const handleHelperModal = () => {
        setHelperModal(true);
    };

    const handleHelperModalClose = () => {
        setHelperModal(false);
    };

    const handleAddModalClose = () => {
        setAddModal(false);
        setSelectedIds([]);
    };

    const handleAddModalSubmit = () => {
        const cloneSelection1s = [...selection1s];

        let dtlArray = [];
        if(cloneSelection1s && cloneSelection1s.length > 0){
            if(cloneSelection1s && cloneSelection1s.length > 0){
                for (let i = 0; i < cloneSelection1s.length; i++){
                    if(configId == cloneSelection1s[i].id){
                        if(cloneSelection1s[i].dtls){
                            Object.keys(cloneSelection1s[i].dtls).map((category, ci) => {
                                Object.keys(cloneSelection1s[i].dtls[category]).map((type, ti) => {
                                    for(let typeList of cloneSelection1s[i].dtls[category][type]){
                                        dtlArray.push(typeList)
                                    }
                                })
                            })
                        }
                    }
                }
            }
        }

        for(let i = 0; i < programDetails.length; i++){
            for(let d = 0; d < programDetails[i].details.length; d++){
                for(let l = 0; l < programDetails[i].details[d].list.length; l++){
                    for(let t = 0; t < programDetails[i].details[d].list[l].typeList.length; t++){
                        for(let s = 0; s < programDetails[i].details[d].list[l].typeList[t].subjectList.length; s++){
                            if(selectedIds.includes(programDetails[i].details[d].list[l].typeList[t].subjectList[s].id)){
                                dtlArray.push(programDetails[i].details[d].list[l].typeList[t].subjectList[s]);
                            }
                        }
                    }
                }
            }
        }

        let credits = 0;
        let price = 0;
        if(dtlArray && dtlArray.length)
        {
            for(let i = 0; i < dtlArray.length; i++)
            {
                credits += parseInt(dtlArray[i].credit);
                if(parseInt(dtlArray[i].totalPrice) > 0){
                    price += parseInt(dtlArray[i].totalPrice);
                }
            }
        }

        const groupByCategory = dtlArray.reduce((group, product) => {
            const { categoryName } = product;

            group[categoryName] = group[categoryName] ?? [];
            group[categoryName].push(product);

            return group;
        }, {});

        for (var categories in groupByCategory){
            const typeNameList = groupByCategory[categories].reduce((group, product) => {
                const { typeName } = product;
    
                group[typeName] = group[typeName] ?? [];
                group[typeName].push(product);
    
                return group;
            }, {});

            groupByCategory[categories] = typeNameList;
        }

        if(configId){
            for(let i = 0; i < selection1s.length; i++){
                if(configId == selection1s[i].id){
                    selection1s[i].dtls = groupByCategory;
                    selection1s[i].totalCount = dtlArray.length;
                    selection1s[i].totalCredit = credits;
                    selection1s[i].totalCreditPrice = price;
                }
            }
        }

        handleAddModalClose();
        setSelectedConfigId(null);
    };

    const handleDeleteModalClose = () => {
        setDeleteModal(false);
    };

    const onSelect = (row, isSelect, rowIndex) => {
        let ids = [ ...selectedIds ];
        if (row.id)
        {
            if (isSelect)
            {
                ids.push(row.id)
            } else {
                for (let i = 0; i < ids.length; i++)
                {
                    if (parseInt(ids[i]) === parseInt(row.id))
                    {
                        ids.splice(i, 1);
                    }
                }
            }
        }

        setSelectedIds(ids);
    };

    const onSelectAll = (isSelect, rows) => {
        let ids = [ ...selectedIds ];
        if (isSelect) {
            for (let i = 0; i < rows.length; i++) {
                ids.push(rows[i].id);
            }
        } else {
            for (let i = 0; i < rows.length; i++) {
                for (let s = 0; s < ids.length; s++) {
                    if (parseInt(ids[s]) === parseInt(rows[i].id)) {
                        ids.splice(s, 1);
                    }
                }
            }
        }

        setSelectedIds(ids);
    };

    const handlerOnDeleteClick = () => {
        // const params = {
        //     school: selectedSchool.id,
        //     teacher: selectedRecord ? selectedRecord.id : null,
        // }

        // fetchRequest(teacherDelete, 'POST', params, false)
        //     .then(response => {
        //         if (response.success) {
        //             setDeleteModal(false);
        //             setTeachers(response.data.teachers);
        //             message(response.data.message, true);
        //         } else {
        //             message(response.data.message, false);
        //         }
        //     })
        //     .catch(err => {
        //     })
    };

    const handlerSubjectAddClick = (configId) => {
        let dtls = [];
        setProgramDetails([]);

        if(selection1s && selection1s.length > 0){
            for (let i = 0; i < selection1s.length; i++){
                if(configId == selection1s[i].id){
                    if(selection1s[i].dtls){
                        Object.keys(selection1s[i].dtls).map((category, ci) => {
                            Object.keys(selection1s[i].dtls[category]).map((type, ti) => {
                                for(let typeList of selection1s[i].dtls[category][type]){
                                    dtls.push(typeList.id)
                                }
                            })
                        })
                    }
                }
            }
        }

        const params = {
            school: schoolId,
            config: configId,
            dtls: JSON.stringify(dtls)
        };

        dispatch(setLoading(true));
        fetchRequest(AddSubjectStudentSelection, 'GET', params)
            .then(response => {
                if (response.success) {
                    setProgramDetails(response.data.programDetails);
                    setAddModal(true);
                    setSelectedConfigId(configId)
                } else {
                    message(response.data.message, false);
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
            })
    };

    const handlerOnPublishClick = (configId) => {
        onSaveClick(1, configId);
    };

    const renderDetails = (details, index) => {
        let row = [];
        if (details && details.length > 0) {
            details.map(detail => {
                let typeRow = [];
                if(detail['list'] && detail['list'].length > 0){
                    detail['list'].map(type => {
                        let subjectRow = [];
                        if(type['typeList'] && type['typeList'].length > 0){
                            type['typeList'].map(subject => {
                                let nonSelectableIds = [];
                                for(let s = 0; s < subject['subjectList'].length; s++){
                                    if(subject['subjectList'][s].disabled){
                                        nonSelectableIds.push(parseInt(subject['subjectList'][s].id))
                                        nonSelectableIds.push(String(subject['subjectList'][s].id))
                                    }
                                }

                                subjectRow.push(
                                    <div key={'subject_' + index + '_' + subject['subjectTypeId']} className='student-selection-area'>
                                        <div className='col-12 p-0 font-w-bold mt-2' style={{color: '#000'}}>
                                            {subject.subjectTypeName}
                                        </div>
                                        <DTable
                                            data={subject['subjectList']}
                                            columns={columns}
                                            config={config}
                                            showOrdering={true}
                                            isSelect={true}
                                            onSelect={onSelect}
                                            onSelectAll={onSelectAll}
                                            nonSelectableIds={nonSelectableIds}
                                        />
                                    </div>
                                )
                            })
                        }
                        typeRow.push(
                            <div key={'type_' + index + '_' + type['typeId']}>
                                <div className='col-12 p-0 font-w-bold dark-blue'>
                                    &emsp;&emsp;&emsp;&emsp; {' - ' + type.typeName + ' - '}
                                    <hr/>
                                </div>
                                <div className='mt-2'>{subjectRow}</div>
                            </div>
                        )
                    })
                }

                row.push(
                    <div key={'detail_' + index + '_' + detail['categoryId']}>
                        <div className='col-12 p-0 font-w-bold dark-blue mt-1'>
                            <ol type="I" className="mb-0">
                                <li>{detail.categoryName.toUpperCase() + ' ' + (t('subject.subjects')).toUpperCase()}</li>
                            </ol>
                            <div>{typeRow}</div>
                        </div>
                    </div>
                );
            })
        }

        return row
    };

    const onSaveClick = (publish = 0, configId) => {
        let selectedDtls = [];
        let studentTotalCredit = 0;
        if(configId){
            if(selection1s && selection1s.length > 0){
                for(let i = 0; i < selection1s.length; i++){
                    if(configId == selection1s[i].id){
                        if(selection1s[i].dtls){
                            Object.keys(selection1s[i].dtls).map((category, ci) => {
                                Object.keys(selection1s[i].dtls[category]).map((type, ti) => {
                                    for(let typeList of selection1s[i].dtls[category][type]){
                                        studentTotalCredit = studentTotalCredit + parseInt(typeList.credit);
                                        selectedDtls.push(typeList.id)
                                    }
                                })
                            })
                        }
                    }
                }
            }
        }

        if(studentTotalCredit <= 21){
            const params = {
                school: schoolId,
                config: configId,
                ids: JSON.stringify(selectedDtls),
                approved: publish
            };
    
            dispatch(setLoading(true));
            fetchRequest(AddSubjectStudentSelection, 'POST', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res;
                        let cloneSelection1s = [...selection1s];
    
                        if(cloneSelection1s && cloneSelection1s.length > 0) {
                            for (let i = 0; i < cloneSelection1s.length; i++) {
                                if (data?.selectionData?.seasonId == cloneSelection1s[i].seasonId) {
                                    cloneSelection1s[i].selectionData = data.selectionData;
                                }
                            }
                        }
                        getMainColumns(data?.selectionData?.approved1);
                        // setSelection1s(cloneSelection1s);
                        message(res?.data?.message, true)
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'));
                })
        } else {
            message('Нийт кредит 21 нь кредитээс их байж болохгүй');
        }
    };

    const onPaymentClick = (configId) => {
        const bodyParams = {
            school: schoolId,
            config: configId
        };

        dispatch(setLoading(true));
        fetchRequest(studentSelectionPayment, 'GET', bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    if(data?.redirectUrl){
                        localStorage.setItem('student_selection_two_back_url', '/student/selection/two');
                        window.location.href = data?.redirectUrl;
                    }
                } else {
                    message(res?.data?.message || t('errorMessage.title'));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    return (
        <div className='row'>
            {
                payment2Penalties && payment2Penalties.length > 0 &&
                payment2Penalties.map((payment, index) => (
                    <div
                        className='col-12 mb-5'
                        key={'payment_1_' + index}
                    >
                        <Card>
                            <Card.Body>
                                <Button
                                    variant='danger'
                                    size='lg'
                                    onClick={() => onPaymentClick(payment.id)}
                                >
                                    {t('selection.payFine')}
                                </Button>
                                <div className='mt-3'>
                                    {payment.seasonName + ' ' + t('selection.payFine2Description')}
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                ))
            }
            {
                isPayment && payment2 && payment2.length > 0 &&
                payment2.map((data, index) => (
                    <Payment
                        key={'payment_' + index}
                        // invoiceId={invoiceId}
                        payment={isPayment}
                        paymentData={data}
                    />
                ))
            }
            {
                payment1 && payment1.length > 0
                ?
                    payment1.map((payment, index) => (
                        <div
                            className='col-12 mb-5'
                            key={'payment_1_' + index}
                        >
                            <Card>
                                <Card.Body>
                                    <Button
                                        variant='danger'
                                        size='lg'
                                        onClick={() => onPaymentClick(payment.id)}
                                    >
                                        {t('selection.payFine')}
                                    </Button>
                                    <div className='mt-3'>
                                        {payment.seasonName + ' ' + t('selection.payFine1Description')}
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    ))
                : null
            }
            {
                selection1s && selection1s.length > 0
                ?
                    <div className='col-12'>
                        <Card className='mb-4'>
                            <Card.Body>
                                <div
                                    className='col-12 text-right'
                                    style={{position: 'relative', bottom: 10}}
                                >
                                    <Button className='bg-white border-white d-inline btn-hover-less' onClick={handleHelperModal}>
                                        <i className="icon-2x text-danger flaticon-questions-circular-button d-inline"/>
                                        <p className='text-danger d-inline'><b>{t('common.helper')}</b></p>
                                    </Button>
                                </div>
                                {
                                    selection1s && selection1s.length > 0
                                    ?
                                        selection1s.map((selection, index) => (
                                            <Card
                                                key={'main_' + index}
                                                className='mb-7'
                                            >
                                                {
                                                    selection.selectionData && selection.selectionData.approved1 === true
                                                        ?
                                                        <div className='col-12 pink-red mt-7 ml-5'>
                                                            <b>{selection.selectionData.parentSeasonName + ' ' + t('common.year') + ', ' + selection.selectionData.seasonName + ' ' + t('selection.selectionOneApprovedText')}</b>
                                                        </div>
                                                        :
                                                        <ButtonToolbar className='d-inline mt-7 ml-5'>
                                                            <Button
                                                                key={'subject_index_' + index}
                                                                className="btn btn-primary btn-shadow ml-3"
                                                                onClick={() => handlerSubjectAddClick(selection.id)}
                                                            >
                                                                {selection.seasonName + ' ' + t('subject.selectSubject').toLowerCase()}
                                                            </Button>
                                                            <Button
                                                                className='ml-3'
                                                                variant='success'
                                                                onClick={() => onSaveClick(0, selection.id)}
                                                            >
                                                                {t('common.reminder')}
                                                            </Button>
                                                            <Button
                                                                key={'publish_index_' + index}
                                                                className='ml-3'
                                                                variant='publish'
                                                                onClick={() => handlerOnPublishClick(selection.id)}
                                                            >
                                                                {t('action.selectionOnePublish')}
                                                                {/*selection.seasonName + ' ' + t('action.publish').toLowerCase()*/}
                                                            </Button>
                                                        </ButtonToolbar>
                                                }
                                                <Card.Body>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <h6 className='dark-blue text-uppercase'>{t('subject.selected')}</h6>
                                                        </div>
                                                        <div className='col-12 mb-4'>
                                                            <table className='ml-12'>
                                                                <tbody>
                                                                <tr>
                                                                    <td className='text-right'>{t('total.subject')}:</td>
                                                                    <td className='pl-4 strong-blue font-w-bold'>{selection?.totalCount || 0}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='text-right'>{t('total.credit')}:</td>
                                                                    <td className='pl-4 strong-blue font-w-bold'>{selection?.totalCredit || 0}</td>
                                                                </tr>
                                                                <tr>
                                                                    {/*
                                                                    <td className='text-right'>{t('total.price')}:</td>
                                                                    <td className='pl-4 strong-blue font-w-bold'>{selection?.totalCreditPrice || 0}₮</td>
                                            */}
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    {
                                                        selection.dtls && Object.keys(selection.dtls).map((categoryKey, ci) => { 
                                                            let renderArea = [];

                                                            renderArea.push(
                                                                <div key={'category_' + ci}>
                                                                    <b>{categoryKey}</b>
                                                                </div>
                                                            )

                                                            Object.keys(selection.dtls[categoryKey]).map((typeKey, ti) => { 
                                                                renderArea.push(
                                                                    <div key={'category_' + ci + '_type_' + ti}>
                                                                        <b>{' - ' + typeKey + ' - '}</b>
                                                                        <div>
                                                                            <DTable
                                                                                data={selection.dtls[categoryKey][typeKey]}
                                                                                columns={getMainColumns(selection?.selectionData?.approved1 || false)}
                                                                                config={config}
                                                                                updateTable={updateView}
                                                                                selectMode={'radio'}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })

                                                            return renderArea
                                                        })
                                                    }
                                                </Card.Body>
                                            </Card>
                                        ))
                                    : null
                                }
                            </Card.Body>
                        </Card>
                    </div>
                :
                    null
            }
            {
                selectionData && selectionData.length > 0
                ?
                    selectionData.map((data, index) => (
                        <CanSelect
                            key={'canSelect_' + index}
                            configId={data.configId}
                            selectionData={data}
                            days={data.days}
                            times={data.times}
                            timetableData={data.timetables}
                        />
                    ))
                : null
            }
            <Modal
                show={helperModal}
                onHide={handleHelperModalClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Хичээл сонголт хийх заавар
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <h4>Хичээл сонголт 1 хийх заавар</h4>
                        <div>
                            <ReactPlayer
                                width={'100%'}
                                height={'400px'}
                                url='https://www.youtube.com/watch?v=bQ1-2Zq-tYg'
                            />
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleHelperModalClose}>{t('common.close')}</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={addModal}
                onHide={handleAddModalClose}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t('subject.selectSubject')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        programDetails && programDetails.length > 0
                            ?
                            programDetails.map((detail, index) => {
                                return (
                                    <Card key={'detail_' + index} className='mb-3'>
                                        <Card.Body>
                                            <div className='col-12 p-0 font-w-bold dark-blue'>
                                                {detail['year'] + ' ' + (detail && detail['seasonType'] ? (detail['seasonType']).toUpperCase() : '')}
                                            </div>
                                            {/* <div className='col-12 p-0 font-w-bold dark-blue mt-1'>
                                                {t('subject.generalAcademicSubject')}
                                            </div> */}
                                            {renderDetails(detail['details'], index)}
                                        </Card.Body>
                                    </Card>
                                )
                            })
                            : null
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='link' onClick={handleAddModalClose}>{t('common.close')}</Button>
                    <Button variant='next' onClick={handleAddModalSubmit}>{t('common.select')}</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={deleteModal}
                onHide={handleDeleteModalClose}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t('warning.delete')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {t('warning.delete_confirmation')}
                        <br/>
                        <br/>
                        {t('warning.delete_confirmation_description')}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="btn btn-link bolder" onClick={handleDeleteModalClose}>{t('common.cancel')}</Button>
                    <Button variant="success btn-shadow" onClick={handlerOnDeleteClick}>{t('warning.delete')}</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
};

export default StudentSelection;