import React, { useRef, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Forms from "app/modules/Form/Forms";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
    getSchools,
    getAddProgram,
    getEditProgram,
    getSchoolProgram,
    getAcademicProgram,
} from "utils/fetchRequest/Urls";
import { setLoading } from "redux/action";
import { fetchRequest } from "utils/fetchRequest";
import message from "app/modules/message";

const EditModal = ({ onClose, onEdit, enrollId, selectedRow, enrollDtl }) => {
    const form = useRef();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const languages = useSelector(
        (state) => state?.languages?.languages || []
    ).filter((lang) => lang.code !== "mn");

    const [program, setProgram] = useState(null);
    const [schools, setSchools] = useState([]);
    const [schoolPrograms, setSchoolPrograms] = useState([]);
    const [academicPrograms, setAcademicPrograms] = useState([]);
    const [isGenderRequired, setIsGenderRequired] = useState(false);

    useEffect(() => {
        if(!selectedRow.mNumber && !selectedRow.fNumber){
            setIsGenderRequired(false)
        } else {
            setIsGenderRequired(true)
        }
    }, [selectedRow]);

    useEffect(() => {
        form?.current?.updateFields && form.current.updateFields(forms);
    }, [schools, academicPrograms, schoolPrograms, isGenderRequired, program, enrollDtl, selectedRow]);

    const onGenderChange = (value) => {
        setIsGenderRequired(value);
    };

    const forms = [
        {
            label: "Мэргэжил",
            value: enrollDtl.text ?? '',
            type: "text",
            inputWidth: 300,
            labelWidth: 230,
            required: true,
            key: "program",
            clearable: true,
            searchable: true,
            disabled: true
        },
        {
            label: "Аймаг",
            value: selectedRow.segmentName || '',
            type: "text",
            inputWidth: 300,
            labelWidth: 230,
            required: true,
            key: "academicProgram",
            disabled: true
        },
        
        {
            label: "Нийт",
            value: selectedRow?.totalNumber || "",
            type: "text",
            inputWidth: 300,
            labelWidth: 230,
            required: true,
            // options: positionArray,
            key: "totalNumber",
            clearable: true,
        },
        // {
        //     label: "Хүйсээр ялгах эсэх",
        //     type: "checkbox",
        //     value: isGenderRequired,
        //     inputWidth: 300,
        //     labelWidth: 230,
        //     key: "gender",
        //     onChange: onGenderChange,
        // },
        // {
        //     label: "Эрэгтэй*",
        //     value: !isGenderRequired ? '' : (selectedRow?.mNumber || ""),
        //     type: "text",
        //     inputWidth: 300,
        //     labelWidth: 230,
        //     required: false,
        //     // options: roleArray,
        //     key: "mNumber",
        //     multiple: true,
        //     searchable: true,
        //     disabled: !isGenderRequired,
        // },
        // {
        //     label: "Эмэгтэй*",
        //     value: !isGenderRequired ? '' : (selectedRow?.fNumber || ""),
        //     type: "text",
        //     inputWidth: 300,
        //     labelWidth: 230,
        //     required: false,
        //     key: "fNumber",
        //     multiple: true,
        //     searchable: true,
        //     disabled: !isGenderRequired,
        // }
    ];

    const onSaveClick = () => {
        const [formValid, formValue] = form.current.validate();
        if (formValid) {
            const params = {
                id: selectedRow.id,
                enroll: enrollId,
                dtl: enrollDtl.value,
                segment: formValue?.[1].value,
                totalNumber: formValue?.[2].value,
                // mNumber: formValue?.[4].value,
                // fNumber: formValue?.[5].value,
            };

            onEdit(params)
        } else {
        }
    };

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: "#4a70ae", fontSize: "1.1rem" }}>
                        Хяналтын тоо засах
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Forms
                    ref={form}
                    fields={forms}
                />
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className="btn btn-link bolder">
                    {t("common.back")}
                </button>
                <Button variant="success btn-shadow" onClick={onSaveClick}>
                    {t("common.save")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditModal;
