import AddModal from "./addBodyModal";
import EditModal from "./editBodyModal";
import { Button, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import DeleteModal from "../../../../../modules/DeleteModal";
import message from "../../../../../modules/message";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../../../../redux/action";
import DTable from "../../../../../modules/DataTable/DTable";
import { fetchRequest } from "../../../../../../utils/fetchRequest";
import Select from "app/modules/Form/Select";
import {
    getAthleteCriteria,
    getAddAthleteCriteria,
    getEditAthleteCriteria,
    getDeleteAthleteCriteria,
} from "../../../../../../utils/fetchRequest/Urls";

const CriteriaBody = ({ enrollId }) => {
    const [list, setList] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [programId, setProgramId] = useState(null);
    const [deleteId, setDeleteId] = useState(null);
    const [selectedId, setSelectedId] = useState(-1);
    const [showModal, setShowModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        const params = {
            enroll: enrollId,
            program: programId,
        };
        dispatch(setLoading(true));
        fetchRequest(getAthleteCriteria, "GET", params)
            .then((res) => {
                if (res.success) {
                    const { data } = res;
                    if (data?.list) {
                        setList(data.list);
                    }
                    setPrograms(data?.programs || [])
                } else {
                    message(res?.data?.message || t("errorMessage.title"));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t("errorMessage.title"));
            });
    };

    const addAthlete = (params) => {
        dispatch(setLoading(true));
        params.program = programId;
        fetchRequest(getAddAthleteCriteria, "POST", params)
            .then((res) => {
                if (res.success) {
                    const { data } = res;
                    if (data?.list) {
                        setList(data.list);
                    }
                } else {
                    message(res?.data?.message || t("errorMessage.title"));
                }
                dispatch(setLoading(false));
                onModalClose();
                getData();
            })
            .catch(() => {
                dispatch(setLoading(false));
                onModalClose();
                message(t("errorMessage.title"));
            });
    };

    const editAthlete = (params) => {
        dispatch(setLoading(true));
        params.program = programId
        fetchRequest(getEditAthleteCriteria, "POST", params)
            .then((res) => {
                if (res.success) {
                    const { data } = res;
                    if (data?.list) {
                        setList(data.list);
                    }
                } else {
                    message(res?.data?.message || t("errorMessage.title"));
                }
                dispatch(setLoading(false));
                onEditClose();
                getData();
            })
            .catch(() => {
                dispatch(setLoading(false));
                onEditClose();
                message(t("errorMessage.title"));
            });
    };

    const handleEdit = (cell, row) => {
        setSelectedId(row.id);
        setShowEditModal(true);
    };

    const handleDelete = (cell, row) => {
        setDeleteId(row.id);
        setDeleteModal(true);
    };

    const handleAddClick = () => {
        setShowModal(true);
    };

    const onModalClose = () => {
        setShowModal(false);
    };

    const onEditClose = () => {
        setShowEditModal(false);
    };

    const handleDeleteModalClose = () => {
        setDeleteModal(false);
    };

    const handlerOnDeleteClick = () => {
        const params = {
            // school: schoolId,
            athleteCriteria: deleteId,
        };

        dispatch(setLoading(true));
        fetchRequest(getDeleteAthleteCriteria, "POST", params, false)
            .then((response) => {
                if (response.success) {
                    const { data } = response;
                    setDeleteModal(false);
                    message(data.message, true);
                    getData();
                    // setTimeout(() => {
                    //   window.location.reload();
                    // }, 100);
                } else {
                    message(response.data.message, false);
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t("errorMessage.title"));
            });
    };

    const columns = [
        {
            dataField: "name",
            text: "Үзүүлэлт",
            sort: false,
        },
        {
            dataField: "mMinScore",
            text: "Эрэгтэй",
            sort: false,
        },
        {
            dataField: "fMinScore",
            text: "Эмэгтэй",
            sort: false,
        },
        {
            dataField: "icons",
            text: "",
            sort: false,
            headerStyle: () => {
                return {
                    width: 90
                }
            },
            style: { textAlign: "center", padding: 3 },
            formatter: (cell, row) => {
                return (
                    <div>
                        <Button onClick={() => {handleEdit(cell, row)}} className="btn btn-purple m-btn m-btn--icon m-btn--icon-only m-btn--circle-30 mr-1">
                            <i className="flaticon-edit"> </i>
                        </Button>
                        <Button onClick={() => {handleDelete(cell, row)}} className="btn btn-danger m-btn m-btn--icon m-btn--icon-only m-btn--circle-30">
                            <i className="flaticon2-cross"> </i>
                        </Button>
                    </div>
                );
            },
        },
    ];

    const onStudentChange = (value) => {
        const params = {
            enroll: enrollId,
            program: value,
        };
        dispatch(setLoading(true));
        fetchRequest(getAthleteCriteria, "GET", params)
            .then((res) => {
                if (res.success) {
                    const { data } = res;
                    if (data?.list) {
                        setList(data.list);
                    }
                    setProgramId(value);
                } else {
                    message(res?.data?.message || t("errorMessage.title"));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t("errorMessage.title"));
            });
    };

    return (
        <Card>
            <div className="">
                <div style={{ display: 'flex', marginTop: '2rem' }}>
                    <label
                        style={{
                            display: 'flex',
                            flex: 1,
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            marginRight : 10,
                            marginBottom: 0,
                            width: 'auto',
                        }}
                    >
                        {'Мэргэжил'}
                    </label>
                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'column',
                            marginLeft: 10,
                            width: 'auto',
                        }}
                    >
                        <Select
                            value={programId}
                            searchable
                            onChange={onStudentChange}
                            options={programs}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flex: 0.8,
                            flexDirection: 'column',
                            marginLeft: 10,
                            width: 'auto',
                        }}
                    />
                </div>
                <Card.Body>
                    <DTable
                        config={{
                            excelExport: true,
                            showPagination: false,
                            showLeftButton: true,
                            leftButtonText: t("common.add"),
                            leftButtonClassName: "btn btn-primary btn-shadow",
                        }}
                        data={list}
                        columns={columns}
                        onLeftButtonClick={handleAddClick}
                    />
                    {showModal && (
                        <AddModal
                            onClose={onModalClose}
                            enrollId={enrollId}
                            onSubmit={addAthlete}
                        />
                    )}
                    {showEditModal ? (
                        <EditModal
                            onClose={onEditClose}
                            enrollId={enrollId}
                            id={selectedId}
                            onSubmit={editAthlete}
                        />
                    ) : null}

                    {!showModal && deleteModal && (
                        <DeleteModal
                            onClose={handleDeleteModalClose}
                            // title={t("setting.teacherStatus")}
                            title="Бие бялдарын үзүүлэлт устгах"
                            onDelete={handlerOnDeleteClick}
                        >
                            {t("warning.delete_confirmation")}
                            <br />
                            <br />
                            {t("warning.delete_confirmation_description")}
                        </DeleteModal>
                    )}
                </Card.Body>
            </div>
        </Card>
    );
};

export default CriteriaBody;
