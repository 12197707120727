/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from "react";
import {useLocation} from "react-router";
import {checkIsActive} from "../../../../_helpers";
import {Badge} from "react-bootstrap";
import {connect} from "react-redux";
import {MenuItem, Select} from "@material-ui/core";
import {setSelectedSchool} from "../../../../../redux/action";

function HeaderMenu({ schoolList, selectedSchoolId, layoutProps, changeSelectedSchoolDispatch }) {
    const location = useLocation();
    const [schoolId, setSchoolId] = useState(selectedSchoolId? selectedSchoolId : '');
    const [schools, setSchools] = useState(selectedSchoolId? selectedSchoolId : '');

    const getMenuItemActive = (url) => {
        return checkIsActive(location, url) ? "menu-item-active" : "";
    };

    useEffect(() => {
        setSchoolId(selectedSchoolId);
    }, [selectedSchoolId]);

    useEffect(() => {
        setSchools(schoolList);
    }, [schoolList]);

    const handleChange = (e) => {
        let localdata = Object.keys(localStorage);
        let indexArray = [];
        setSchoolId(e.target.value);
        if(localdata && localdata.length > 0){
            for (let i = 0; i < localdata.length; i++){
                let splitArray = localdata[i].split("_");

                if(splitArray.pop() === 'index'){
                    indexArray.push(localdata[i])
                }
            }
        }

        if(indexArray && indexArray.length > 0){
            for (let i = 0; i < indexArray.length; i++) {
                localStorage.removeItem(indexArray[i])
            }
        }

        for(let i = 0; i < schools.length; i++){
            if(schools[i]['id'] === e.target.value){
                changeSelectedSchoolDispatch(schools[i]);
            }
        }
        setTimeout(() => {
            window.location.reload();
        }, 100);
    };

    return <div
        id="kt_header_menu"
        className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
        {...layoutProps.headerMenuAttributes}
    >
        {/*begin::Header Nav*/}
        <Select
            value={schoolId}
            onChange={handleChange}
        >
            {
                schools && schools.length > 0
                ?
                    schools.map(school => {
                        return(
                            <MenuItem key={'school_key_' + school.id} value={school.id}>{school.shortName}</MenuItem>
                        )
                    })
                : null
            }
        </Select>
        <h3><Badge variant="danger">10:00</Badge></h3>
        {/*<ul className={`menu-nav ${layoutProps.ulClasses}`}>*/}
        {/*    <li className={`menu-item menu-item-rel`}>*/}
        {/*        <b className={'header-title'}>2020-2021 он, Хаврын улирал, 12-р долоо хоног</b>*/}
        {/*    </li>*/}
        {/*</ul>*/}
        {/*end::Header Nav*/}
    </div>;
}

const mapStateToProps = state => {
    return {
        schoolList: state && state.school && state.school.schoolList ? state.school.schoolList : [],
        selectedSchoolId: state.selectedSchool && state.selectedSchool.id ? state.selectedSchool.id : null,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        changeSelectedSchoolDispatch: (selectedSchool) => dispatch(setSelectedSchool(selectedSchool)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderMenu);
