import React,{useState,useEffect} from 'react'
import { Modal,Button,Row,Col,CloseButton,Card,Image } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Select from '../../../../../modules/Form/Select'
import { htmlDecode } from '../../../../../../utils/utils'
import Checkbox from '../../../../../modules/Form/Checkbox'
import ReactPlayer from 'react-player'
import { ROOT_URL } from '../../../../../../utils/fetchRequest'
import { TrueOrFalse } from '../../../../exam/question/types'
import { cloneDeep } from 'lodash'
import clsx from "clsx";
import DragHandle from '../../../../../modules/DataTable/DragHandle'
import { Input, TextareaAutosize } from "@material-ui/core";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import DTable from '../../../../../modules/DataTable/DTable'
import message from '../../../../../modules/message'

const AddRandomQuestion = ({
    onClose = () =>{},
    fetchData = () =>{},
    examId,
    subjectId    
}) => {
    const {t} = useTranslation();

    const [topic,setTopic] = useState();
    const [level,setLevel] = useState();
    const [topicOption,setTopicOption] = useState([]);
    const [levelOption,setLevelOption] = useState([]);
    const [questions,setQuestions] = useState([]);
    const [chooseQuestion, setChooseQuestion] = useState(false);
    const [questionNumber, setQuestionNumber] = useState(0);
    const [temp, setTemp] = useState([]);
    let addQuestions = [];

    // const onNextQuestion = () =>{
    //     if(questions.length){
    //         const index = questions.findIndex(q=>q.id === question.id)
    //         if(questions[index+1]){
    //             setQuestion(questions[index+1])
    //         }else{
    //             setQuestion(questions[0])
    //         }
    //     }
    // }

    // const onPrevQuestion = () =>{
    //     if(questions.length){
    //         const index = questions.findIndex(q=>q.id === question.id)
    //         if(questions[index-1]){
    //             setQuestion(questions[index-1])
    //         }else{
    //             setQuestion(questions.at(-1))
    //         }
    //     }
    // }

    const questionList = (tmp = null) =>{
        if(tmp){
            if(tmp.length){
                const tempQuestions = []
                tmp.map(q=>{
                    if(q.difficultyId === level && q.topicId === topic){
                        tempQuestions.push({...q})
                    }
                })
                return tempQuestions
            }else{
                return []
            }
        } else{
            if(questions.length){
                const tempQuestions = []
                questions.map(q=>{
                    if(q.difficultyId === level && q.topicId === topic){
                        tempQuestions.push({...q})
                    }
                })
                return tempQuestions
            }else{
                return []
            }
        }        
    }

    const renderAnswers = (row, answer) =>{
        if(row.type === 'MULTICHOICE'){
            if(answer.filePath){
                return <div className='p-4'>
                    <Checkbox
                        disabled
                        label={answer.answer}
                        className='ml-10'
                        checked={answer.isCorrect}
                    />
                    <Image
                        src={ROOT_URL + '/' + answer.filePath}
                        height={'160px'}
                        style={
                            answer.isCorrect
                            ? 
                                {opacity:'1',cursor:'pointer'}
                            : {opacity:'.5',cursor:'pointer'}
                        }
                    />
                </div>
            }else{
                return (
                    <Checkbox
                        disabled
                        label={answer.answer}
                        className='ml-10'
                        checked={answer.isCorrect}
                    />
                )
            }
        }else if(row.type === 'TRUEFALSE'){
            return (
                <TrueOrFalse onChange={() => {}} disabled value={answer.answer}/>
                
            )            
        }else if(row.type === 'RADIO'){
            if(answer.filePath){
                return (
                    <div className='p-4'>
                        <Checkbox
                            disabled
                            label={answer.answer}
                            className='ml-10'
                            checked={answer.id === row.answer?.id}
                        />
                        <Image
                            src={ROOT_URL + '/' + answer.filePath}
                            height={'160px'}
                            style={
                                answer.id === row.answer?.id
                                ? 
                                    {opacity:'1',cursor:'pointer'}
                                : {opacity:'.2',cursor:'pointer'}
                            }
                        />
                    </div>
                )
                
            }else{
                return (
                    <Checkbox
                        disabled
                        label={answer.answer}
                        className='ml-10'
                        checked={answer.id === row.answer?.id}
                    />
                )
            }
            
        }else if(row.type === 'CONNECT'){
            return (
                <div
                    style={{
                        marginTop:'5px',
                        display: 'flex',
                        alignItems: 'center',

                    }}>
                    <input disabled
                        className={clsx('form-control mr-5')}
                        style={{width: '250px'}}
                        value={answer.premise}
                        placeholder={t('exam.premise')}
                    />
                    <input disabled
                        className={clsx('form-control')}
                        style={{width: '250px'}}
                        value={answer.response}
                        placeholder={t('exam.premiseResponse')}
                    />
                    <input disabled
                        className={clsx('form-control mr-5')}
                        style={{width: '70px', marginLeft:'10px'}}
                        value={answer.score}
                        placeholder={t('exam.score')}
                    />
                </div>
            )
        }else if(row.type === 'ORDER'){
            return (
                <DndProvider backend={HTML5Backend}>
                    <div 
                        style={{
                            marginTop:'5px',
                            display: 'flex',
                            alignItems: 'center',
                            width: 'min-content'
                        }}>
                        <DragHandle 
                            rowClass={`order-row`}
                            data={{ ...answer }}
                            onStart={null}
                            onDrop={null}
                        />
                        <input disabled
                            className={clsx('form-control')}
                            style={{width: '250px'}}
                            value={answer.answer}
                            placeholder={t('exam.premiseResponse')}
                        />
                        <input disabled
                            className={clsx('form-control mr-5')}
                            style={{width: '70px', marginLeft:'10px'}}
                            value={answer.score}
                            placeholder={t('exam.score')}
                        />
                    </div>
                </DndProvider>
                
            )
        }else if(row.type === 'TEXTINPUT'){
            return (
                <div className='align-items-center w-100 my-2'>
                    <TextareaAutosize disabled
                        className={clsx('form-control')}
                        placeholder={t('exam.premiseResponse')}
                        rowsMin={4}
                        cols={4}
                        value={answer?.answer}
                    />
                </div>
            )
        }else if(row.type === 'NUMBERINPUT'){
            return (
                <div className='align-items-center w-100 my-2'>
                    <input
                        type='number' disabled
                        className={clsx('form-control')}
                        placeholder={t('exam.premiseResponse')}
                        value={answer?.answer}
                    />
                    {
                        answer?.answer2 ?
                            <input
                                type='number' disabled
                                className={clsx('form-control')}
                                placeholder={t('exam.premiseResponse')}
                                value={answer?.answer2}
                            />
                            : null
                    }
                </div>
            )
        }else{
            return null
        }
    }

    const renderFile = (url) =>{
        if(typeof url == 'string'){
            if(/\.(jpg|jpeg|png|gif)$/.test(url)){
                return( 
                    <Image
                        src={url}
                        height={'160px'}
                    />
                )
            }else{
                return (
                    <ReactPlayer
                        width={'320px'}
                        height={'160px'}
                        url={url}
                    />
                )
            }
        }else{
            return null
        }
    }

    const onAdd = () =>{
        if(topic && level){
            if(addQuestions.length == 0 && temp.length > 0)
                addQuestions = temp;
            if(questionNumber == 0){
                message('Асуултын тоо оруул',false);
                return;
            }
            if(chooseQuestion && addQuestions.length < questionNumber){
                message('Нэмэх асуултын тоо сонгосон асуултаас их байна',false);
                return;
            }
            const params = {
                topic,
                level,
                action: 'add',
                question: addQuestions,
                isCollect: chooseQuestion,
                questionNumber: questionNumber
            }
            fetchData(params,'POST');
            setQuestionNumber(0);
            const clone = cloneDeep(questions);
            addQuestions.map((t) => {
                clone.map((q, index) => {
                    if(q.id == t){
                        clone.splice(index,1);
                    }
                });
            })
            addQuestions = [];
            setQuestions(clone);
            setLevel(level);
            setTopic(topic);            
        }
    }

    useEffect(()=>{
        const params = {
            exam: examId,
            subject: subjectId
        };
        fetchData(params,'GET',setTopicOption,setLevelOption,setQuestions)
    },[])

    const htmlFormatter = cell => {
        var div = document.createElement("div");
        div.innerHTML = cell;
        var text = div.textContent || div.innerText || "";
        div.remove();
        return shorten(text);
    };

    const shorten = (value) =>{
        if(value && value.length > 30){
            var trimmedString = value.substr(0, 30);
            trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")));
            return trimmedString;
        }
        return value;
    }

    const columns = [{
        dataField: 'id',
        text: 'questionId',
        hidden: true
      }, {
        dataField: 'question',
        text: t('onlineExam.testName'),
        formatter: htmlFormatter
      }, {
        dataField: 'description',
        text: t('onlineExam.description')
      },
      {
        dataField: 'questionTypeName',
        text: t('exam.questionType')
      },
      {
        dataField: 'score',
        text: t('exam.score')
      }];

    const selectRow = {
        mode: 'checkbox',
        clickToExpand: true,
        clickToSelect: true,
        
        style: { backgroundColor: '#c8e6c9' },
        onSelect: (row, isSelect, rowIndex, e) => {
            if(row){
                
                if(isSelect){
                    const index = addQuestions.indexOf(row.id);
                    if(index == -1){
                        addQuestions.push(row.id);
                    }                    
                }
                else{
                    const index = addQuestions.indexOf(row.id);
                    if (index > -1) { 
                        addQuestions.splice(index, 1);
                    }
                }
            }
        },
        onSelectAll: (isSelected, rows) => {
            if (isSelected) {
                for (let i = 0; i < rows.length; i++) {
                    addQuestions.push(rows[i].id);
                }
            }
            else{
                addQuestions = [];
            }   
        }
        
    };

    const expandRow = {
        onlyOneExpanding: false,
        renderer: row => {
            return (

                row.type === 'TEXTINPUT' || row.type === 'NUMBERINPUT'
                ?
                <div className='w-100 my-4'>
                    {renderAnswers(row, row.answers[0])}
                </div>
                : 
                (row.answers?.length
                ?
                row.answers.map(a=>{
                        return (
                            <div 
                                key={a.id}
                                className='w-100 my-2'
                            >
                                {renderAnswers(row,a)}
                            </div>
                        )
                    })
                : null)
            )
        }
    };

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('onlineExam.questionFromBlueprint')}
                    </div>
                </Modal.Title>
                <CloseButton onClick={onClose}/>
            </Modal.Header>
            <Modal.Body>
                <Row className='align-items-center mb-4'>
                    <Col className='text-right' md={4}>
                        <span>
                            <b>{t('common.level')}</b>
                        </span>
                    </Col>
                    <Col md={6}>
                        <Select
                            value={level}
                            onChange={value=>setLevel(value)}
                            options={levelOption}
                            searchable
                            required
                        />
                    </Col>
                </Row>
                <Row className='align-items-center mb-4'>
                    <Col className='text-right' md={4}>
                        <span>
                            <b>{t('teacher.topic')}</b>
                        </span>
                    </Col>
                    <Col md={6}>
                        <Select
                            value={topic}
                            onChange={value=>setTopic(value)}
                            options={topicOption}
                            searchable
                            required
                        />
                    </Col>
                </Row>
                <Row className='align-items-center mb-4'>
                    <Col className='text-right' md={4}>
                        <span>
                            <b>{t('onlineExam.studentQNumber')}</b>
                        </span>
                    </Col>
                    <Col md={6}>
                        <input
                            type='number'
                            value={questionNumber}
                            className={clsx('form-control')}
                            onChange={e => {
                                if(addQuestions.length > 0){
                                    setTemp(addQuestions);
                                    addQuestions = temp;
                                }
                                setQuestionNumber(e.target?.value); 
                                
                              }}
                            required
                        />
                    </Col>
                </Row>
                <Row className='align-items-center mb-4'>
                    <Col className='text-right' md={4}>
                    </Col>
                    <Col md={6}>
                        <Checkbox
                            label={t('onlineExam.chooseQuestion')}
                            checked={chooseQuestion}
                            onChange={value=>{setChooseQuestion(!chooseQuestion)}}
                        />
                    </Col>
                </Row>
                {
                    chooseQuestion ? 
                    (
                        <Row>
                            <Col md={12}>
                                <DTable
                                    keyField='id'
                                    data={ questionList() }
                                    columns={ columns }
                                    expandRow={ expandRow }
                                    customSelectRow={ selectRow }
                                />
                            </Col>
                        </Row>
                    ) : ''
                }
                <Row className='my-3'>
                    <Col md={12} className='text-center'>
                        
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>        
                <button onClick={onClose} className='btn btn-link bolder'>{t('common.cancel')}</button>
                <Button className="btn btn-outline-success w-25" onClick={onAdd}>{t('common.add')}</Button>
            </Modal.Footer>
        </Modal>
    )
}

const checkIsActive = {
    width: '30px',
    height: '30px',
    borderRadius: '15px',
    backgroundColor: '#299f72',
    color: '#ffffff',
    boxShadow: '0 5px 15px 0 #299f7233',
    border: 'none'
}

export default AddRandomQuestion