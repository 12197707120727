import React, {useRef, useEffect, useState} from 'react';
import {Button, Card} from "react-bootstrap";
import Forms from "../../../../modules/Form/Forms";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {regulationEdit} from "../../../../../utils/fetchRequest/Urls";
import { setLoading } from "../../../../../redux/action";
import { fetchRequest } from "../../../../../utils/fetchRequest";
import message from "../../../../modules/message";
import {Link, useHistory} from "react-router-dom";
import {Editor} from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import Checkbox from "../../../../modules/Form/Checkbox";
import {TrueFalse} from "./TrueFalse";
import {Radio} from "./Radio";
import MultiSelect from './MultiSelect';
import Connect from './Connect';
import Order from './Order';

export const TeacherQuestionAdd = ({
    onSubmit,
}) => {
    const { t } = useTranslation();
    const formRef = useRef();
    const history = useHistory();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [isReCreate, setIsReCreate] = useState(true);
    const [typeId, setTypeId] = useState(null);
    const [typeCode, setTypeCode] = useState(null);
    const [types, setTypes] = useState([
        {
            value: 1,
            text: 'Үнэн худал',
            code: 'trueFalse'
        },
        {
            value: 2,
            text: 'Олноос нэгийг сонгох',
            code: 'radio'
        },
        {
            value: 3,
            text: 'Олноос зөвийг сонгох',
            code: 'multi'
        },
        {
            value: 4,
            text: 'Тохируулах',
            code: 'connect'
        },
        {
            value: 4,
            text: 'Дараалал',
            code: 'order'
        }
    ]);
    const [rows, setRows] = useState([]);
    const [checkRows, setCheckRows] = useState(0);

    useEffect(() => {
        const params = {
            school: schoolId,
        };

        dispatch(setLoading(true));
        fetchRequest(regulationEdit, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }, []);

    useEffect(() => {
        formRef?.current?.updateFields && formRef.current?.updateFields(fields);
    }, []);

    const onTypeChange = (values, evt) => {
        if(values){
            setTypeId(values.id);
            setTypeCode(evt.code);
            setRows([]);
        } else {
            setTypeId(null);
            setTypeCode(null);
            setRows([]);
        }
    };

    const fields = [
        {
            key: 'help',
            label: t('question.helpfulQuestion'),
            labelAlign: 'top',
            labelWidth: 280,
            value: '',
            type: 'textArea',
            rows: 3,
        },
        {
            key: 'file',
            label: t('file.add'),
            labelWidth: 280,
            value: undefined,
            type: 'fileUpload',
            IsExtendedButtonWithIcon: true,
            isExtendedButtonWithIconClass: 'btn btn-primary m-btn m-btn--icon m-btn--icon-only topic-add-icon-style ml-2',
            iconNameClass: 'la la-youtube-play',
            clearButton: true,
            clearable: true,
            searchable: true,
        },
        {
            key: 'level',
            label: t('common.level'),
            labelWidth: 280,
            value: null,
            type: 'dropdown',
            required: true,
            errorMessage: t('errorMessage.selectLevel'),
            clearable: true,
            searchable: true,
        },
        {
            key: 'topic',
            label: t('teacher.topic'),
            labelWidth: 280,
            value: null,
            type: 'dropdown',
            errorMessage: t('errorMessage.selectTopic'),
            required: true,
            clearable: true,
            searchable: true,
        },
        {
            key: 'type',
            label: t('question.type'),
            labelWidth: 280,
            value: null,
            type: 'dropdown',
            required: true,
            errorMessage: t('errorMessage.selectQuestionType'),
            clearable: true,
            searchable: true,
            options: types,
            onChange: onTypeChange,
        },
        {
            key: 'score',
            label: t('question.score'),
            labelWidth: 280,
            value: '',
            type: 'numberOnly',
            required: true,
            errorMessage: t('errorMessage.enterQuestionScore'),
        },
    ];

    const updateQuestionEditor = async (state) => {
        setEditorState(state);
    };

    const handleCheckboxClick = (checked) => {
        setIsReCreate(!checked);
    };

    const handlerOnSaveClick = () => {
        const [ isValid, states, values ] = formRef.current.validate();
        if (isValid) {
            const params = {
                ...values,
            };
        }
        let error = false;
        if(rows && rows.length > 0){
            if(typeCode == 'trueFalse'){
                for(let i = 0; i < rows.length; i++){
                    if(rows[i].input == '' || rows[i].input == undefined || rows[i].input == null){
                        rows[i].inputError = true;
                        error = true;
                    }
                }
            } else if (typeCode == 'radio'){
                let isRadioTrue = false;
                for(let i = 0; i < rows.length; i++){
                    if(rows[i].input == '' || rows[i].input == undefined || rows[i].input == null){
                        rows[i].inputError = true;
                        error = true;
                    }
                    if(rows[i].radio === true){
                        isRadioTrue = true;
                    }
                }

                if(!isRadioTrue){
                    message(t('question.checkCorrectQuestion'), false);
                    error = true;
                }
            }

            if(!error){
                // TODO success api
            } else {
                setCheckRows(checkRows + 1);
                message(t('warning.completeInformation'), false)
            }
        } else {
            message(t('question.insert'), false)
        }
    };

    const onHandlerTrueFalse = (rowData) => {
        setRows(rowData);
    };

    const onHandlerRadio = (rowData) => {
        setRows(rowData);
    };

    return (
        <div className='sm-container'>
            <Card>
                <Card.Header>
                    <span className='french-blue fs-10'>
                        <b>{t('teacher.createOnlineLesson').toUpperCase()}</b>
                    </span>
                </Card.Header>
                <Card.Body>
                    <div className='col-12 newfeed-style'>
                        <div className='mb-2'><span className='fs-11 font-w-bold'>{t('question.title')}</span></div>
                        <Editor
                            editorState={editorState}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName mb-6"
                            editorClassName="editorClassName"
                            onEditorStateChange={updateQuestionEditor}
                            editorStyle={{ border: "1px solid rgba(0, 0, 0, .2)"}}
                        />
                    </div>
                    <div className='col-12'>
                        <Forms
                            ref={formRef}
                            fields={fields}
                        />
                    </div>
                    <div className='col-12'>
                        {
                            typeCode == 'trueFalse'
                            ?
                                <TrueFalse
                                    checkRows={checkRows}
                                    onChange={onHandlerTrueFalse}
                                />
                            :
                            typeCode == 'radio'
                            ?
                                <Radio
                                    checkRows={checkRows}
                                    onChange={onHandlerRadio}
                                />
                            :
                            typeCode == 'multi'
                            ?
                                <MultiSelect
                                    onChange={rows=>setRows(rows)}
                                />
                            :
                            typeCode == 'connect'
                            ?
                                <Connect
                                    onChange={rows=>setRows(rows)}
                                />

                            :
                            typeCode == 'order'
                            ?
                                <Order
                                    onChange={rows=>setRows(rows)}
                                />
                            :
                            null
                        }
                    </div>
                </Card.Body>
                <Card.Footer>
                    <div className='col-12 text-center ds-flex justify-content-center'>
                        <Link
                            to='/teacher/questions'
                            className="btn btn-link bolder"
                        >
                            {t('common.back')}
                        </Link>
                        <Button variant="success btn-shadow" onClick={handlerOnSaveClick}>{t('common.save')}</Button>
                        <Checkbox
                            className='custom-cbox ml-4'
                            checked={isReCreate}
                            onChange={(e) => handleCheckboxClick(e)}
                            label={t('question.reCreate')}
                        />
                    </div>
                </Card.Footer>
            </Card>
        </div>
    );
};