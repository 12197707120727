import React, {useState, useEffect, useRef} from 'react';
import {Card, Button, ButtonToolbar, Modal} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {Link, useHistory} from "react-router-dom";
import Forms from "../../../modules/Form/Forms";
import {setLoading} from "../../../../redux/action";
import {fetchRequest} from "../../../../utils/fetchRequest";
import {teacherEdit} from "../../../../utils/fetchRequest/Urls";
import {useDispatch, useSelector} from "react-redux";
import message from "../../../modules/message";

const TeacherEdit = ({
    location: { state: {
        teacherId = null,
        departmentId = null,
        depPositionId = null
    } = {} } = {},
}) => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const selectedSchool = useSelector(state => state?.selectedSchool || []);
    const languages = useSelector(state => state?.languages?.languages || []).filter(lang => lang.code !== 'mn');
    const [helperModal, setHelperModal] = useState(false);
    const [teacherData, setTeacherData] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [selectedDepartmentId, setSelectedDepartmentId] = useState(departmentId);
    const [selectedPositionId, setSelectedPositionId] = useState(depPositionId);
    const [departmentArray, setDepartmentArray] = useState([]);
    const [positions, setPositions] = useState([]);
    const [countries, setCountries] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [statusList, setStatusList] = useState([]);
    const gender = [
        {text: 'Эрэгтэй', value: 'm'},
        {text: 'Эмэгтэй', value: 'f'},
    ];

    const form = useRef();
    const translateForm = useRef();
    const getFormTranslations = (translations) => {
        const array = [];
        for (let i = 0; i < languages.length; i++) {
            let language = languages[i];

            if(translations && translations != undefined){
                array.push(
                    {
                        label: `${t('common.familyName')} - ${language.name} (${language.code})`,
                        value:  translations && translations.length > 0 && translations.filter(lang => lang.langCode === language.code).length > 0 ? translations.filter(lang => lang.langCode === language.code)[0].familyName : '',
                        required: false,
                        type: 'text',
                        langCode: language.code,
                        key: 'familyName',
                    },
                    {
                        label: `${t('common.lastName')} - ${language.name} (${language.code})`,
                        value: translations && translations.length > 0 && translations.filter(lang => lang.langCode === language.code).length > 0 ? translations.filter(lang => lang.langCode === language.code)[0].lastName : '',
                        required: false,
                        type: 'text',
                        langCode: language.code,
                        key: 'lastName',
                    },
                    {
                        label: `${t('common.firstName')} - ${language.name} (${language.code})`,
                        value: translations && translations.length > 0 && translations.filter(lang => lang.langCode === language.code).length > 0 ? translations.filter(lang => lang.langCode === language.code)[0].firstName : '',
                        required: false,
                        type: 'text',
                        langCode: language.code,
                        key: 'firstName',
                    },
                );
            }
        }
        return array;
    };

    useEffect(() => {
        dispatch(setLoading(true));
        let params = {
            school: selectedSchool.id,
            teacher: teacherId,
            department: departmentId
        };

        fetchRequest(teacherEdit, 'GET', params)
            .then(response => {
                if (response.success) {
                    let departments = response.data.departments;
                    let subjects = [];
                    if(response.data.countries && response.data.countries.length){
                        setCountries(response.data.countries.map(country => ({ value: country.id, text: country.name })));
                    }
                    if(response.data.subjects && response.data.subjects.length){
                        subjects = response.data.subjects.map(subject => ({ value: subject.id, text: subject.name }));
                        setSubjects(subjects);
                    }
                    if(response.data.statusList && response.data.statusList.length){
                        setStatusList(response.data.statusList.map(status => ({value: status.id, text: status.name})));
                    }
                    setTeacherData(response.data.teacherData);

                    if(departments && departments.length){
                        setDepartmentArray(departments);
                        setDepartments(departments.map(depart => ({ value: depart.id, text: depart.title })));

                        if(departments && departments.length){
                            setDepartmentArray(departments);
                            setDepartments(departments.map(depart => ({ value: depart.id, text: depart.title })));
                            if(departmentId)
                            {
                                for(let i = 0; i < departments.length; i++)
                                {
                                    if(departmentId === departments[i]['id'])
                                    {
                                        let positions = departments[i].positions;
                                        if(positions && positions.length > 0){
                                            setPositions(positions.map(position => ({ value: position.id, text: position.name, code: position.code })));
                                        }
                                    }
                                }
                            }
                        }
                    }
                } else {
                    message(response.data.message, false);
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }, []);

    useEffect(() => {
        if(positions && positions.length > 0){
            for(let p = 0; p < positions.length; p++){
                if(selectedPositionId == positions[p].value && positions[p].code.toLowerCase() == 'teacher'){
                    forms.push({
                        name: 'subject',
                        label: t('common.subject') + '*',
                        value: teacherData.subjectIds && teacherData.subjectIds.length > 0 ? teacherData.subjectIds : [],
                        errorMessage: t('errorMessage.selectSubject'),
                        required: true,
                        type: 'dropdown',
                        options: subjects,
                        multiple: true,
                        searchable: true
                    });
                }
            }
        }

        form?.current?.updateFields && form.current.updateFields(forms);
    }, [departments, positions, countries, subjects, statusList, teacherData, selectedPositionId]);

    useEffect(() => {
        translateForm?.current?.updateFields && translateForm.current.updateFields(getFormTranslations(teacherData.translations));
    }, [teacherData]);

    const onDepartmentChange = (id) => {
        if(id)
        {
            for(let i = 0; i < departmentArray.length; i++)
            {
                if(id === departmentArray[i]['id'])
                {
                    setPositions(departmentArray[i].positions.map(position => ({ value: position.id, text: position.name, code: position.code })));
                }
            }
            setSelectedDepartmentId(id)
        }
    };

    const onPositionChange = (id) => {
        if(id)
        {
            if(positions && positions.length > 0){
                for(let i = 0; i < positions.length; i++)
                {
                    if(id == positions[i]['value'])
                    {
                        if((positions[i]['code']).toLowerCase() === 'teacher'){
                            let isHereSubject = false;
                            if(forms && forms.length > 0){
                                for(let i = 0; i < forms.length; i++){
                                    if(forms[i]['name'] === 'subject'){
                                        isHereSubject = true;
                                    }
                                    if(forms[i]['name'] === 'position'){
                                        forms[i]['value'] = id;
                                    }
                                }
                            }

                            if(!isHereSubject){
                                forms.push({
                                    name: 'subject',
                                    label: t('common.subject') + '*',
                                    value: null,
                                    errorMessage: t('errorMessage.selectSubject'),
                                    required: true,
                                    type: 'dropdown',
                                    options: subjects,
                                    multiple: true,
                                    searchable: true
                                });
                            }
                        } else {
                            if(forms && forms.length > 0){
                                for(let i = 0; i < forms.length; i++){
                                    if(forms[i]['name'] === 'position'){
                                        forms[i]['value'] = id;
                                    }
                                    if(forms[i]['name'] === 'subject'){
                                        forms.splice(i, 1);
                                    }
                                }
                            }
                        }
                        setSelectedPositionId(id);

                        form?.current?.updateFields && form.current.updateFields(forms);
                    }
                }
            }
        }
    };

    const forms = [
        {
            label: t('school.parentDepartment') + '*',
            value: selectedDepartmentId ? selectedDepartmentId : null,
            errorMessage: t('errorMessage.selectRelatedDepartment'),
            required: true,
            type: 'dropdown',
            options: departments,
            multiple: false,
            searchable: true,
            onChange: onDepartmentChange,
            clearable: true
        },
        {
            label: t('menu.roles') + '*',
            value: selectedPositionId ? selectedPositionId : null,
            errorMessage: t('errorMessage.selectRoles'),
            required: true,
            type: 'dropdown',
            options: positions,
            multiple: false,
            searchable: true,
            onChange: onPositionChange,
            clearable: true
        },
        {
            label: t('common.status') + '*',
            value: teacherData?.statusId,
            errorMessage: t('errorMessage.selectStatus'),
            required: true,
            type: 'dropdown',
            options: statusList,
            multiple: false,
            searchable: true,
            clearable: true
        },
        {
            label: t('common.country') + '*',
            value: teacherData?.countryId,
            errorMessage: t('errorMessage.selectCountry'),
            required: true,
            type: 'dropdown',
            options: countries,
            multiple: false,
            searchable: true,
            clearable: true
        },
        {
            key: 'code',
            label: t('common.code') + '*',
            value: teacherData?.code || '',
            errorMessage: t('errorMessage.enterCode'),
            required: true,
            type: 'nonCryllic',
            upperCase: true,
        },
        {
            label: t('common.familyName') + '*',
            value: teacherData?.familyName || '',
            errorMessage: t('errorMessage.enterFamilyName'),
            required: true,
            type: 'text',
        },
        {
            label: t('common.lastName') + '*',
            value: teacherData?.lastName || '',
            errorMessage: t('errorMessage.enterLastName'),
            required: true,
            type: 'text',
        },
        {
            label: t('common.firstName') + '*',
            value: teacherData?.firstName || '',
            errorMessage: t('errorMessage.enterFirstName'),
            required: true,
            type: 'text',
        },
        {
            label: t('common.registerNumber') + '*',
            value: teacherData?.registerNumber || '',
            errorMessage: t('errorMessage.enterRegisterNumber'),
            required: true,
            type: 'text',
        },
        {
            label: t('common.gender') + '*',
            value: teacherData?.gender ? (teacherData?.gender).toLowerCase() : '',
            errorMessage: t('errorMessage.selectGender'),
            required: true,
            type: 'dropdown',
            options: gender,
            multiple: false,
        },
        {
            label: t('common.phoneNumber'),
            value: teacherData?.phoneNumber || '',
            errorMessage: t('errorMessage.enterPhoneNumber'),
            required: true,
            type: 'numberOnly',
        },
        {
            label: t('common.email') + '*',
            value: teacherData?.email || '',
            errorMessage: t('errorMessage.enterEmail'),
            required: true,
            type: 'text',
        },
        {
            label: t('common.employmentDate') + '*',
            value: teacherData?.employmentDate,
            errorMessage: t('errorMessage.selectEmploymentDate'),
            required: true,
            type: 'date',
        }
    ];

    const handleHelperModal = () => {
        setHelperModal(true)
    };

    const handleHelperModalClose = () => {
        setHelperModal(false)
    };

    const handlerOnSaveClick = () => {
        const [ formValid, formValue ] = form.current.validate();
        const [ translateValid, translateValues ] = translateForm.current.validate();

        if (formValid) {
            const params = {
                school: selectedSchool.id,
                teacher: teacherId,
                code: formValue?.[4]?.value,
                familyName: formValue?.[5]?.value,
                lastName: formValue?.[6]?.value,
                firstName: formValue?.[7]?.value,
                registerNumber: formValue?.[8]?.value,
                gender: formValue?.[9]?.value,
                phone: formValue?.[10]?.value,
                email: formValue?.[11]?.value,
                startDate: formValue?.[12]?.value,
            };

            if (formValue?.[0]?.value) {
                params.department = formValue[0].value;
            }
            if(formValue?.[1]?.value){
                params.depPosition = formValue[1].value
            }
            if(formValue?.[2]?.value){
                params.status = formValue[2].value
            }
            if(formValue?.[3]?.value){
                params.country = formValue[3].value
            }
            if(formValue[13]?.value){
                params.subjects = formValue[13].value
            }

            let translations = [];
            if(languages && languages.length > 0){
                languages.map(language => {
                    if(language.code !== 'mn'){
                        const familyNameObject = translateValues.find(val => val.key === 'familyName' && val.langCode === language.code && val.value);
                        const lastNameObject = translateValues.find(val => val.key === 'lastName' && val.langCode === language.code && val.value);
                        const firstNameObject = translateValues.find(val => val.key === 'firstName' && val.langCode === language.code && val.value);

                        if (familyNameObject) {
                            translations.push({
                                langCode: language.code,
                                familyName: familyNameObject.value,
                                lastName: lastNameObject.value,
                                firstName: firstNameObject.value,
                            })
                        }
                    }
                });

                params.translations = JSON.stringify(translations);
            }

            dispatch(setLoading(true));
            fetchRequest(teacherEdit, 'POST', params, false)
                .then(response => {
                    if (response.success) {
                        history.push('/school/teacher')
                    } else {
                        message(response.data.message, false)
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                })
        }
    };

    return (
        <div className='row'>
            <div className='col-12'>
                <Card>
                    <Card.Body>
                        <div className='col-12 text-right'>
                            <Button className='bg-white border-white d-inline btn-hover-less' onClick={handleHelperModal}>
                                <i className="icon-2x text-danger flaticon-questions-circular-button d-inline"/>
                                <p className='text-danger d-inline'><b>{t('common.helper')}</b></p>
                            </Button>
                        </div>
                        <div className='col-12'>
                            <Forms
                                ref={form}
                                fields={forms}
                            />
                        </div>
                        <div className="separator separator-dashed my-7"/>
                        <div className='col-12'>
                            <Forms
                                ref={translateForm}
                                fields={getFormTranslations(teacherData.translations)}
                            />
                        </div>
                    </Card.Body>
                    <Card.Footer>
                        <div className='col-12 text-center'>
                            <Link
                                to='/school/teacher'
                                className="btn btn-link bolder"
                            >
                                {t('common.back')}
                            </Link>
                            <Button variant="success btn-shadow" onClick={handlerOnSaveClick}>{t('common.save')}</Button>
                        </div>
                    </Card.Footer>
                </Card>
            </div>
            <Modal
                show={helperModal}
                onHide={handleHelperModalClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Modal heading
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Centered Modal</h4>
                    <p>
                        Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
                        dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta
                        ac consectetur ac, vestibulum at eros.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleHelperModalClose}>{t('common.close')}</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
};

export default TeacherEdit;