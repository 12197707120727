import React, {useRef, useEffect, useState} from 'react';
import { Button, Modal } from "react-bootstrap";
import Forms from "../../../modules/Form/Forms";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {regulationEdit} from "../../../../utils/fetchRequest/Urls";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import message from "../../../modules/message";

const RegulationAdd = ({
    id,
    typeId,
    regulationTypes,
    onClose,
    onSubmit,
}) => {
    const { t } = useTranslation();
    const forms = useRef();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [types, setTypes] = useState(regulationTypes && regulationTypes.length > 0 ? regulationTypes.map((type) => ({value: type.key, text: type.title, code: type.code})) : []);

    const [regulationData, setRegulationData] = useState([]);

    useEffect(() => {
        if (id) {
            const params = {
                school: schoolId,
                id: id
            };

            dispatch(setLoading(true));
            fetchRequest(regulationEdit, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res;
                        setRegulationData(data?.regulationData || []);
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        }
    }, []);

    useEffect(() => {
        forms?.current?.updateFields && forms.current?.updateFields(fields);
    }, [types, regulationData, id]);

    const fields = [
        {
            key: 'type',
            label: t('common.type'),
            value: regulationData.code || typeId ? typeId : null,
            type: 'dropdown',
            required: true,
            options: types,
            clearable: true,
            searchable: true,
        },
        {
            label: t('common.name'),
            value: regulationData.name || '',
            type: 'text',
            required: true,
            key: 'name',
        },
        {
            label: t('common.date'),
            value: regulationData.date || '',
            type: 'date',
            dateCustomButton: false,
            required: true,
            key: 'date',
        }
    ];

    const onSaveClick = () => {
        const [ isValid, states, values ] = forms.current.validate();
        if (isValid) {
            if(values.type){
                let selectedType = types.find((type) => (type.value == values.type));
                if(selectedType){
                    values.type = selectedType.code;
                }
            }
            const params = {
                ...values,
            };
            onSubmit(params);
        }
    };

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('menu.regulation').toUpperCase()} {id ? t('common.edit').toUpperCase() : t('common.add').toUpperCase()}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Forms
                    ref={forms}
                    fields={fields}
                />
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className='btn btn-link bolder'>{t('common.back')}</button>
                <Button variant='success btn-shadow' onClick={onSaveClick}>{t('common.save')}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default RegulationAdd;