import React from 'react';
import {Card} from "react-bootstrap";
import { useTranslation } from "react-i18next";

const HasConfig = ({}) => {
    const { t } = useTranslation();
    return (
        <Card.Body>
            <div className="col-12 pl-0">
                <div className='col-12'>
                    {t('selection.selectionTwoHasConfigText')}
                </div>
            </div>
        </Card.Body>
    );
};

export default HasConfig;