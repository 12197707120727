import React from 'react'
import { PaymentTable } from './PaymentTable'

export const Payments = () => {
    return (
        <div className='sm-container'>
            <PaymentTable/>
        </div>
    )
};
