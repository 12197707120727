import React, { useEffect, useRef } from 'react';
import { Button, Modal } from "react-bootstrap";
import Forms from "../../../modules/Form/Forms";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { contactTypeEdit } from "../../../../utils/fetchRequest/Urls";
import { fetchRequest } from "../../../../utils/fetchRequest";
import { setLoading } from "../../../../redux/action";
import message from "../../../modules/message";

const ContactTypeAdd = ({
    onClose,
    onSubmit,
    id,
    contactTypesCode
}) => {
    const { t } = useTranslation();
    const forms = useRef();
    const dispatch = useDispatch();
    const languages = useSelector(state => state?.languages?.languages || []).filter(lang => lang.code !== 'mn');

    useEffect(() => {
        if (id) {
            const params = {
                contactType: id,
            }
            dispatch(setLoading(true));
            fetchRequest(contactTypeEdit, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res;

                        const translations = data?.contactType.translations || [];

                        const trFields = languages.map(lang => {
                            let value = '';
                            const translation = translations.find(tr => tr.langCode === lang.code);

                            if (translation?.name) {
                                value = translation.name;
                            }

                            return {
                                label: `${t('common.name')} - ${lang.name} (${lang.code})`,
                                value,
                                langCode: lang.code,
                                type: 'text',
                                labelWidth: 300,
                                inputWidth: 200,
                            }
                        });

                        if (data?.contactType) {
                            forms?.current?.updateFields && forms.current.updateFields([
                                {
                                    label: t('common.code'),
                                    value: data.contactType?.code || '',
                                    type: 'dropdown',
                                    inputWidth: 200,
                                    labelWidth: 300,
                                    required: true,
                                    key: 'code',
                                    options:contactTypesCode
                                },
                                {
                                    label: t('common.name'),
                                    value: data.contactType?.name || '',
                                    type: 'text',
                                    inputWidth: 200,
                                    labelWidth: 300,
                                    required: true,
                                    key: 'name',
                                },
                                {
                                    type: 'jsx',
                                    value: <div key={'separator'} className="separator separator-dashed my-7"/>,
                                },
                                ...trFields
                            ]);
                        }

                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(err => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        }
    }, []);

    const translationFields = languages.map(lang => {
        return {
            label: `${t('common.name')} - ${lang.name} (${lang.code})`,
            value: '',
            langCode: lang.code,
            type: 'text',
            labelWidth: 300,
            inputWidth: 200,
        }
    });

    const fields = [
        {
            label: t('common.code'),
            value: '',
            type: 'dropdown',
            upperCase: true,
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            key: 'code',
            options:contactTypesCode
        },
        {
            label: t('common.name'),
            value: '',
            type: 'text',
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            key: 'name',
        },
        {
            type: 'jsx',
            value: <div key={'separator'} className="separator separator-dashed my-7"/>,
        },
        ...translationFields
    ];

    const onSaveClick = () => {
        const [ isValid, states, values ] = forms.current.validate();
        if (isValid) {
            const translations = [];
            for (let i = 0; i < states.length; i++) {
                const val = states[i];
                if (i >= 3) {
                    translations.push({
                        langCode: val.langCode,
                        name: val.value,
                    });
                }
            }
            const params = {
                ...values,
                translations: JSON.stringify(translations)
            }
            onSubmit(params);
        }
    }

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('setting.contactType.title').toUpperCase()}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Forms
                    ref={forms}
                    fields={fields}
                />
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className='btn btn-link bolder'>{t('common.back')}</button>
                <Button variant='success btn-shadow' onClick={onSaveClick}>{t('common.save')}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ContactTypeAdd;