import React, { useState, useEffect } from 'react'
import { GradeTable } from './GradeTable'
import {Card} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
// import StudentScoreViewModal from "./StudentScoreViewModal";
import DTable from '../../../../modules/DataTable/DTable';
import message from "../../../../modules/message";
import { lmsGradeScore } from '../../../../../utils/fetchRequest/Urls';
import {setLoading} from "../../../../../redux/action";
import {fetchRequest} from "../../../../../utils/fetchRequest";

export const StudentGradeTable = ({studentId}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [viewModal, setViewModal] = useState(false);
    const [tableData, setTableData]= useState([]);

    useEffect(() => {
        const params = {
            school: schoolId,
            id: studentId
        };

        init(params)
    }, []);

    const init = (params) => {
        dispatch(setLoading(true));
        fetchRequest(lmsGradeScore, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setTableData(data.details || []);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const column=[
        {
            dataField: 'subjectCode',
            text: t('subject.code'),
            sort: true,
            footer: '',
        },
        {
            dataField: 'subjectName',
            text: t('subject.name'),
            sort: true,
            footer: 'Нийт',
        },
        {
            dataField: 'credit',
            text: t('subject.credit'),
            sort: true,
            align: 'right',
            footerAlign: 'right',
            footer: columnData => columnData.reduce((acc, item) => acc + item, 0)
        },
        {
            dataField: 'studentGpa',
            text: t('ranking.gpaScore'),
            sort: true,
            align: 'right',
            footerAlign: 'right',
            footerType: 'avg',
            footer: ''
        },
        {
            dataField: 'studentScore',
            text: t('assessment.title'),
            sort: true,
            align: 'right',
            headerAttrs: { 'colSpan': 2 },
            footer: '',
            headerStyle: {width: 120},
            footerType: 'sum'
        },
        {
            dataField: 'scoreType',
            text: '',
            sort: true,
            headerStyle: { display: 'none' },
            footer: '',
        },
        {
            dataField: 'teacherCode',
            text: t('teacher.code'),
            sort: true,
            footer: '',
            key:'',
        },
        {
            dataField: 'teacherName',
            text: t('teacher.name'),
            sort: true,
            footer: '',
            key:'',
        }
    ];

    const config = {
        showPagination: false,
        showFilter: false,
        footer: true,
        footerStyle: {backgroundColor: '#ebecf5'}
    };

    const contentRender = () =>{
        return tableData.map((data, index) =>
            <React.Fragment key={index}>
                <div className='table-title mb-4'>
                    <b className='ml-2'>{data.parentSeasonName + ', ' + data.seasonName}</b>
                </div>
                <div className='table-wrap'>
                    <DTable 
                        config={config}
                        data={data.list}
                        columns={column}
                    />
                </div>
            </React.Fragment>
        )
    };

    const onModalClose = () => {
        setViewModal(false);
    };

    return (
        <Card>
            <Card.Body>
                <button className='btn btn-warning-custom mb-4'>
                    <b>{t('student.getScoreDescription')}</b>
                </button>
                {contentRender()}
            </Card.Body>
            {/*{*/}
            {/*    viewModal*/}
            {/*        ?*/}
            {/*        <StudentScoreViewModal*/}
            {/*            onClose={onModalClose}*/}
            {/*            subjectId={11}*/}
            {/*        />*/}
            {/*        : null*/}
            {/*}*/}
        </Card>
    )
};
