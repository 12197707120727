import React, {useEffect, useState} from 'react'
import {Card} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import DatePicker from "../../../modules/Form/DatePicker";
import {Link} from "react-router-dom";
import DeleteModal from "../../../modules/DeleteModal";
import {setLoading} from "../../../../redux/action";
import {fetchRequest} from "../../../../utils/fetchRequest";
import {eventDelete, financeEventInit} from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import {useDispatch, useSelector} from "react-redux";

export const BranchSchoolCal = ({
    otherEvents,
    selectedMonth
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [showModal, setShowModal] = useState();
    const [eventId, setEventId] = useState();
    const [events, setEvents] = useState();
    const [month, setMonth] = useState();
    const [isMount, setIsMount] = useState(true);
    const [isEvent, setIsEvent] = useState(true);
    const [ otherMonthIndex ] = useState('other_month_index');

    useEffect(() => {
        if(isMount && selectedMonth){
            setMonth(selectedMonth);
            setIsMount(false);
        }
    }, [selectedMonth]);

    useEffect(() => {
        if(isEvent && otherEvents && otherEvents.length > 0){
            setEvents(otherEvents);
            setIsEvent(false);
        }
    }, [otherEvents]);

    const handlerDeleteClick = (id) => {
        setShowModal(true);
        setEventId(id);
    };

    const onModalClose = () => {
        setShowModal(false);
        setEventId(null);
    };

    const onDelete = () => {
        const bodyParams = {
            event: eventId
        };
        dispatch(setLoading(true));
        fetchRequest(eventDelete, 'POST', bodyParams)
            .then(res => {
                dispatch(setLoading(false));
                if (res.success) {
                    message(res?.data?.message || t('common.success'), true);
                    onModalClose();

                    let params = {
                        school: schoolId,
                        other_month: month
                    };

                    dispatch(setLoading(true));
                    fetchRequest(financeEventInit, 'GET', params)
                        .then(res => {
                            if (res.success) {
                                const { data } = res;
                                setEvents(data?.otherSchoolEvents || []);
                            } else {
                                message(res?.data?.message || t('errorMessage.title'))
                            }
                            dispatch(setLoading(false));
                        })
                        .catch(() => {
                            dispatch(setLoading(false));
                            message(t('errorMessage.title'))
                        })
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            })
    };

    const renderEvents = () =>{
        return events.map((data, index) =>
            <div
                key={'other_event_' + index}
            >
                <div className='row event-style' key={data.id}>
                    <div className='col-3'>
                        <span className='date ml-3' style={{color: '#29569f'}}><b>{data.startDate + ' - ' + data.endDate}</b></span>
                    </div>
                    <div className='col-9 ds-flex justify-content-between'>
                        <div>
                            <span className='title'><b>{data.name}</b></span>
                        </div>
                        <div>
                            {/*<Link*/}
                            {/*    to={{*/}
                            {/*        pathname: '/event/edit',*/}
                            {/*        state: {*/}
                            {/*            eventId: data.id*/}
                            {/*        }*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    <button className='btn btn-purple m-btn--icon m-btn--icon-only ml-2'>*/}
                            {/*        <i className='flaticon-edit fs-10'/>*/}
                            {/*    </button>*/}
                            {/*</Link>*/}
                            {/*<button*/}
                            {/*    className='btn btn-danger m-btn--icon m-btn--icon-only ml-2'*/}
                            {/*    onClick={() => {handlerDeleteClick(data.id)}}*/}
                            {/*>*/}
                            {/*    <i className='flaticon2-cross fs-06'/>*/}
                            {/*</button>*/}
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-3'/>
                    <div className='col-9'>
                        {data.description}
                    </div>
                </div>
                <div className="separator separator-dashed my-7" />
            </div>
        )
    };

    const handleDateChange = (date) => {
        setMonth(date);
        localStorage.setItem(otherMonthIndex, date);
        let params = {
            school: schoolId,
            other_month: date.substring(0, 7)
        };

        dispatch(setLoading(true));
        fetchRequest(financeEventInit, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setEvents(data?.otherSchoolEvents || []);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    return (
        <Card className='mb-4'>
            <Card.Header>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <span className='french-blue fs-10'>
                        <b>{t('newsfeed.branchCalendar').toUpperCase()}</b>
                    </span>
                </div>
            </Card.Header>
            <Card.Body>
                <div style={{ display: 'flex'}}>
                    <label
                        style={{
                            display: 'flex',
                            flex: 0.7,
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            marginRight : 10,
                            marginBottom: 0,
                            width: 'auto',
                        }}
                    >
                        {t('common.month')}
                    </label>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginLeft: 10,
                            width: 180,
                        }}
                    >
                        <DatePicker
                            value={month}
                            onChange={handleDateChange}
                            showMonthYearPicker
                            showFullMonthYearPicker
                            isCustomButton={false}
                            dateFormat={'yyyy-MM'}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'column',
                            marginLeft: 10,
                            width: 'auto',
                        }}
                    />
                </div>
                {
                    events && events.length > 0 &&
                    <>
                        <div className="separator separator-dashed my-7" />
                        {renderEvents()}
                    </>
                }
            </Card.Body>
            {
                showModal && eventId && (
                    <DeleteModal
                        onClose={onModalClose}
                        onDelete={onDelete}
                        title={t('warning.delete')}>
                        {t('warning.delete_confirmation')}
                        <br />
                        <br />
                        {t('warning.delete_confirmation_description')}
                    </DeleteModal>
                )
            }
        </Card>
    )
};
