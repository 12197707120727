import React, {useEffect, useState} from 'react';
import { useTranslation } from "react-i18next";
import {Card} from "react-bootstrap";
import {setLoading} from "../../../redux/action";
import {fetchRequest} from "../../../utils/fetchRequest";
import {studentPaymentCheck} from "../../../utils/fetchRequest/Urls";
import message from "../../modules/message";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";

const Success = ({}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [isPaid, setIsPaid] = useState(null);
    const [count, setCount] = useState(0);
    const [startCounter, setStartCounter] = useState(0);
    const loading = useSelector(state => state?.loading || false);
    const history = useHistory();

    useEffect(() => {
        let orderId = null;
        let searchUrl = window.location.search;
        let paramString = searchUrl.split('?')[1];
        let paramsArr = paramString.split('&');
        for(let i = 0; i < paramsArr.length; i++) {
            let item = paramsArr[i].split('=');
            if(item[0] == 'orderId'){
                orderId = item[1];
            }
        }
        const params = {
            orderId: orderId
        };

        init(params)
    }, []);

    useEffect(() => {
        if(startCounter){
            if(count < 10){
                setTimeout(() => {
                    let cloneCount = count;
                    cloneCount = cloneCount + 1;
                    setCount(cloneCount);
                }, 1000);
            } else {
                let backUrl = localStorage.getItem('student_selection_two_back_url');
                if(backUrl){
                    history.push(backUrl);
                }
            }
        }
    }, [startCounter, count]);

    const init = (params) => {
        dispatch(setLoading(true));
        fetchRequest(studentPaymentCheck, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setIsPaid(data.paid);
                    setStartCounter(true);
                    setCount(0);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const onBackClick = () => {
        let backUrl = localStorage.getItem('student_selection_two_back_url');
        if(backUrl){
            history.push(backUrl);
        }
    };

    return (
        <>
            <Card>
                <Card.Body>
                    <div style={{display: 'inline-flex'}}>
                        {
                            !loading
                            ?
                                isPaid
                                ?
                                    <p style={{color: '#299f72'}}><b>{t('finance.transactionSuccess')}</b></p>
                                :
                                    <p style={{color: '#EE2D41'}}><b>{t('finance.transactionFailed')}</b></p>
                            : null
                        }
                        <div className='ml-2'>
                            {
                                !loading
                                ?
                                    isPaid
                                    ?
                                        <p style={{color: '#299f72'}}><b>{count}</b></p>

                                    :
                                        <p style={{color: '#EE2D41'}}><b>{count}</b></p>
                                : null
                            }
                        </div>
                        <div className='ml-2 remove-btn-padding'>
                            {
                                !loading
                                    ?
                                    <button style={{padding: '0'}} className='btn btn-link' onClick={onBackClick}>{t('common.back')}</button>
                                    : null
                            }
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </>
    );
};

export default Success;