import React, {useRef, useEffect, useState} from 'react';
import { Button, Modal } from "react-bootstrap";
import Forms from "../../../modules/Form/Forms";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {transcriptTypeEdit} from "../../../../utils/fetchRequest/Urls";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import message from "../../../modules/message";

const TranscriptTypeAdd = ({
    onClose,
    onSubmit,
    id
}) => {
    const { t } = useTranslation();
    const forms = useRef();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [types, setTypes] = useState([]);
    const [transcriptType, setTranscriptType] = useState([]);

    useEffect(() => {
        if (id) {
            const params = {
                school: schoolId,
                id: id
            };

            dispatch(setLoading(true));
            fetchRequest(transcriptTypeEdit, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res;
                        if(data.transcriptType){
                            setTranscriptType(data.transcriptType);
                        }
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        }
    }, []);

    useEffect(() => {
        forms?.current?.updateFields && forms.current?.updateFields(fields);
    }, [transcriptType, id]);

    const fields = [
        {
            key: 'code',
            label: t('common.code'),
            value: transcriptType.code || '',
            type: 'nonCryllic',
            upperCase: true,
            required: true,
        },
        {
            key: 'name',
            label: t('common.name'),
            value: transcriptType.name || '',
            type: 'text',
            required: true,
        },
        {
            key: 'template',
            label: t('transcript.template'),
            value: transcriptType.template || '',
            type: 'text',
            required: true,

        },
        {
            key: 'price',
            label: t('transcript.price'),
            value: transcriptType.price || '',
            type: 'numberOnly',
            required: true,
        },
        {
            key: 'valid',
            label: t('transcript.valid'),
            value: transcriptType.validDay || '',
            type: 'numberOnly',
            required: true,
        },
    ];

    const onSaveClick = () => {
        const [ isValid, states, values ] = forms.current.validate();
        if (isValid) {
            const params = {
                ...values,
            };
            onSubmit(params);
        }
    };

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('transcript.type').toUpperCase()} {id ? t('common.edit').toUpperCase() : t('common.add').toUpperCase()}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Forms
                    ref={forms}
                    fields={fields}
                />
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className='btn btn-link bolder'>{t('common.back')}</button>
                <Button variant='success btn-shadow' onClick={onSaveClick}>{t('common.save')}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default TranscriptTypeAdd;