import React, { useState, useEffect } from 'react';
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import { addressSegmentAdd, addressSegmentDelete, addressSegmentEdit } from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import AddressSegmentAdd from "./AddressSegmentAdd";
import TreeView from "../../../modules/TreeView";
import DeleteModal from "../../../modules/DeleteModal";

const AddressSegment = () => {
    const [ showModal, setShowModal ] = useState(false);
    const [ addressSegments, setAddressSegments ] = useState([]);
    const [ flatAddressSegments, setFlatAddressSegments ] = useState([]);
    const [ nodeId, setNodeId ] = useState(null);
    const [ addressSegmentId, setAddressSegmentId ] = useState(null);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setLoading(true));
        fetchRequest(addressSegmentAdd, 'GET', [])
            .then(res => {
                if (res.success) {
                    const { data } = res;

                    setAddressSegments(data.addressSegments || []);

                    if (data?.flatAddressSegments?.length) {
                        setFlatAddressSegments(formatFlatAddressSegments(data.flatAddressSegments))
                    }

                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            })
    }, []);

    const handleAddClick = () => {
        setShowModal(true);
    };

    const onModalClose = () => {
        setShowModal(false);
        setAddressSegmentId(null);
    };

    const handleContextMenuClick = (id, key) => {
        if (id && key) {
            setAddressSegmentId(id);
            if (key === 'EDIT') {
                setShowModal(true);
            }
        }
    };

    const onSubmit = params => {
        const url = addressSegmentId ? addressSegmentEdit : addressSegmentAdd;
        const bodyParams = {
            ...params,
        };

        if (addressSegmentId) {
            bodyParams.addressSegment = addressSegmentId;
        }
        dispatch(setLoading(true));
        fetchRequest(url, 'POST', bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setAddressSegments(data?.addressSegments || []);
                    setFlatAddressSegments(data?.flatAddressSegments ? formatFlatAddressSegments(data.flatAddressSegments) : []);
                    message(data?.message || t('common.success'), true);

                    onModalClose();
                } else {
                    message(res?.data?.message || t('errorMessage.title'));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const formatFlatAddressSegments = list => {
        return list.map(item => {
            return {
                value: item.id,
                text: item.name,
            }
        })
    };

    const handleNodeClick = array => {
        if (array?.length) {
            const [ id ] = array;
            setNodeId(id);
        }
    };

    const onDelete = () => {
        const params = {
            addressSegment: addressSegmentId,
        };
        dispatch(setLoading(true));
        fetchRequest(addressSegmentDelete, 'POST', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setAddressSegments(data?.addressSegments || []);
                    setFlatAddressSegments(data?.flatAddressSegments ? formatFlatAddressSegments(data.flatAddressSegments) : []);
                    message(data?.message || t('common.success'), true);
                    onModalClose();
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            })
    };

    const handleDrop = ({ event, node, dragNode }) => {
        if (node.parent === dragNode.parent) {
            if (node.dragOverGapTop) {
                // const params = {
                //     courseId: this.params,
                //     topicId: node.key,
                //     draggedTopicId: dragNode.key,
                //     position: 'top',
                // }
                // this._rearrangeSyllabus(params);
            } else if (node.dragOverGapBottom) {
                // const params = {
                //     courseId: this.params,
                //     topicId: node.key,
                //     draggedTopicId: dragNode.key,
                //     position: 'bottom',
                // }
                // this._rearrangeSyllabus(params);
            }
        }
        //copied code BTW.
    };

    return (
        <Card>
            <Card.Body>
                <button
                    className='btn btn-primary'
                    onClick={handleAddClick}
                >
                    {t('common.add')}
                </button>
                <div className='mt-3'>
                    <TreeView
                        treeData={addressSegments}
                        selectedNodes={[nodeId]}
                        onSelect={handleNodeClick}
                        defaultExpandAll={true}
                        contextMenus={{
                            addressSegment: [
                                {
                                    key: 'EDIT',
                                    iconClassName: 'las la-edit rc-tree-la-edit',
                                    text: t('common.edit'),
                                },
                                {
                                    key: 'DELETE',
                                    iconClassName: 'flaticon2-trash ml3px',
                                    text: t('common.delete'),
                                }
                            ]
                        }}
                        contextMenuKey={'addressSegment'}
                        onContextMenuClick={handleContextMenuClick}
                        draggable
                        onDrop={handleDrop}
                    />
                </div>
                {
                    showModal && (
                        <AddressSegmentAdd
                            onClose={onModalClose}
                            onSubmit={onSubmit}
                            addressSegments={flatAddressSegments}
                            id={addressSegmentId}
                        />
                    )
                }
                {
                    !showModal && addressSegmentId && (
                        <DeleteModal
                            onClose={onModalClose}
                            title={t('setting.addressSegment.title')}
                            onDelete={onDelete}
                        >
                            {t('warning.delete_confirmation')}
                            <br/>
                            <br/>
                            {t('warning.delete_confirmation_description')}
                        </DeleteModal>
                    )
                }
            </Card.Body>
        </Card>
    );
};

export default AddressSegment;