import React, { useEffect, useState} from "react";
import {useTranslation} from 'react-i18next';
import {Link, Redirect, useHistory} from "react-router-dom";
import {Button, Card, Nav, ButtonToolbar, Modal, Form, Row, Col} from "react-bootstrap";
import TreeView from '../../../../app/modules/TreeView/index';
import DTable from "../../../modules/DataTable/DTable";
import {fetchRequest} from "../../../../utils/fetchRequest";
import {setLoading} from "../../../../redux/action";
import {useDispatch} from "react-redux";
import qs from 'qs';
import { academicProgramInit, academicProgramChangeStatus, academicProgramDelete } from "../../../../utils/fetchRequest/Urls";
import store from "../../../../redux/store";
import message from "../../../modules/message";

export function Academic() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {selectedSchool} = store.getState();
    const [statuses, setStatuses] = useState([]);
    const [selectedTreeId, setSelectedTreeId] = useState(null);
    const [key, setKey] = useState("#active");
    const [departments, setDepartments] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [helperModal, setHelperModal] = useState(false);
    const [programs, setPrograms] = useState([]);
    const [viewModal, setViewModal] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState([]);
    const [treeIndex] = useState('school_academic_tree_index');
    const [tabIndex] = useState('school_academic_tab_index');
    const history = useHistory();

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [search, setSearch] = useState('');
    const [tableTotalSize, setTableTotalSize] = useState(0);

    useEffect(() => {
        let tabKey = localStorage.getItem(tabIndex);
        let treeId = localStorage.getItem(treeIndex);

        if(tabKey){
            setKey(tabKey);
        }
        if(treeId){
            setSelectedTreeId([parseInt(treeId)]);
        }

        dispatch(setLoading(true));
        let params = {
            school: selectedSchool.id,
            code: tabKey ? tabKey.substring(1, tabKey.length) : '',
            department: treeId,
            page,
            pageSize,
            search
        };

        fetchRequest(academicProgramInit, 'GET', params)
            .then(response => {
                if (response.success) {
                    if (response.data.statuses && response.data.statuses.length)
                    {
                        setStatuses(response.data.statuses);
                        const status = response.data.statuses.find(status => status.id === response.data.selectedStatusId);
                        setSelectedStatus(status);
                    }
                    if(response.data.departments && response.data.departments.length)
                    {
                        setDepartments(response.data.departments);
                    }
                    setPrograms(response.data.programs);
                    setTableTotalSize(response.data.totalPage);
                } else {
                    message(response.data?.message, false);
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }, [page, pageSize, search]);

    const handleChange = (idArray) => {
        if(idArray && idArray.length > 0){
            let params = {
                school: selectedSchool.id,
                department: idArray[0],
                status: selectedStatus ? selectedStatus.id : '',
                pageSize,
                page,
                search,
                sort: [],
                order: 'asc'
            };

            dispatch(setLoading(true));
            fetchRequest(academicProgramInit,  'GET', params)
                .then(response => {
                    if (response.success) {
                        setPrograms(response.data.programs);
                        setSelectedTreeId(idArray);
                        setTableTotalSize(response.data.totalPage);
                    } else {
                        message(response?.data?.message, false);
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })

            localStorage.setItem(treeIndex, idArray[0]);
        }
    };

    const handleTab = (key) => {
        let selectedType = [];
        setKey(key);
        localStorage.setItem(tabIndex, key);

        if(statuses && statuses.length > 0){
            for(let i = 0; i < statuses.length; i++){
                if(key === ('#' + statuses[i]['code'])){
                    selectedType = statuses[i];
                }
            }
        }

        if(selectedType){
            let params = {
                school: selectedSchool.id,
                department: selectedTreeId && selectedTreeId.length > 0 ? selectedTreeId[0] : null,
                status: selectedType.id,
                pageSize,
                page,
                search,
                sort: [],
                order: 'asc'
            };

            dispatch(setLoading(true));
            fetchRequest(academicProgramInit, 'GET', params)
                .then(response => {
                    if (response.success) {
                        setPrograms(response.data.programs && response.data.programs.length > 0 ? response.data.programs : []);
                        setTableTotalSize(response.data.totalPage);
                    } else {
                        message(response?.data?.message, false);
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        }

        setSelectedStatus(selectedType);
    };

    const config = {
        showPagination: true,
    };

    const columns = [
        {
            dataField: "academicCode",
            text: t('curriculum.code'),
            sort: true,
        },
        {
            dataField: "name",
            text: t('curriculum.name'),
            sort: true,
        },
    ];

    const contextMenusActive = [
        {
            key: 'view',
            title: t('common.view'),
            icon: <i className='las la-search dt-cm-item-icon'/>
        },
        {
            key: 'edit',
            title: t('common.edit'),
            icon: <i className='las la-edit dt-cm-item-icon'/>
        },
        {
            key: 'unpublish',
            title: t('action.setInactive'),
            icon: <i className='las la-eye-slash dt-cm-item-icon'/>
        },
    ];

    const contextMenusInActive = [
        {
            key: 'view',
            title: t('common.view'),
            icon: <i className='las la-search dt-cm-item-icon'/>
        },
        {
            key: 'edit',
            title: t('common.edit'),
            icon: <i className='las la-edit dt-cm-item-icon'/>
        },
        {
            key: 'delete',
            title: t('common.delete'),
            icon: <i className='las la-trash-alt dt-cm-item-icon' />,
        },
        {
            key: 'publish',
            title: t('action.setActive'),
            icon: <i className='las la-eye dt-cm-item-icon'/>
        },
    ];

    const handleContextMenuClick = (id, key, record) => {
        if(key === 'view')
        {
            setSelectedRecord(record);
            setViewModal(true);
        } else if (key === 'unpublish' || key === 'publish')
        {
            let toStatusId = null;
            if(statuses && statuses.length > 0){
                for (let i = 0; i < statuses.length; i++){
                    if(key === (statuses[i].code).toLowerCase()){
                        toStatusId = statuses[i].id;
                    }
                }
            }

            let params = {
                school: selectedSchool.id,
                department: selectedTreeId && selectedTreeId.length > 0 ? selectedTreeId[0] : null,
                status: selectedStatus.id,
                to_status: toStatusId,
                program: record.id,
            };

            dispatch(setLoading(true));
            fetchRequest(academicProgramChangeStatus, 'POST', params, false)
                .then(response => {
                    if (response.success) {
                        setPrograms(response.data.programs || []);
                        setTableTotalSize(response.data.totalPage);
                        message(response.data.message, true);
                    } else {
                        message(response?.data?.message, false);
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        } else if (key === 'edit'){
            history.push({
                pathname: '/school/academic_edit',
                state: {
                    id: record.id,
                }
            });
        } else if (key === 'delete'){
            let params = {
                school: selectedSchool.id,
                academic: record.id,
                status: selectedStatus.id,
                department: selectedTreeId && selectedTreeId.length > 0 ? selectedTreeId[0] : null,
            };

            dispatch(setLoading(true));
            fetchRequest(academicProgramDelete, 'POST', params, false)
                .then(response => {
                    if (response.success) {
                        setPrograms(response.data.programs);
                        setTableTotalSize(response.data.totalPage);
                        message(response.data.message, true);
                    } else {
                        message(response?.data?.message, false);
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        }
    };

    const handleTableInteraction = (object) => {
        setPage(object.page);
        setPageSize(object.pageSize);
        setSearch(object.search);
    };

    const handleHelperModal = () => {
        setHelperModal(true);
    };

    const handleHelperModalClose = () => {
        setHelperModal(false);
    };

    const handleViewModalClose = () => {
        setViewModal(false);
    };

    return (
        <div className='row'>
            <div className='col-4 pr-0'>
                <Card>
                    <Card.Body>
                        <TreeView
                            treeData={departments}
                            selectedNodes={selectedTreeId}
                            onSelect={(e, data) => handleChange(e, data)}
                            defaultExpandAll={true}
                        />
                    </Card.Body>
                </Card>
            </div>
            <div className='col-8'>
                <Card className='card-header-with-tab'>
                    <Card.Header>
                        {
                            statuses && statuses.length > 0
                                ?
                                    <Nav
                                        variant="tabs"
                                        defaultActiveKey={key}
                                        onSelect={(key, e) => handleTab(key, e)}
                                    >
                                        {
                                            statuses.map(status => {
                                                return (
                                                    <Nav.Item key={'type_' + status.id}>
                                                        <Nav.Link href={`#${status.code}`}>{status.name}</Nav.Link>
                                                    </Nav.Item>
                                                )
                                            })
                                        }
                                    </Nav>
                                : null
                        }
                    </Card.Header>
                    <Card.Body>
                        {
                            key.toLowerCase() === '#publish'
                            ?
                                <>
                                    <ButtonToolbar className='d-inline'>
                                        <Link
                                            to={{
                                                pathname: '/school/academic_new',
                                                state:{
                                                    id: selectedTreeId && selectedTreeId.length > 0 ? selectedTreeId[0] : null
                                                }
                                            }}
                                            className="btn btn-primary btn-shadow"
                                        >
                                            {t('common.add')}
                                        </Link>
                                    </ButtonToolbar>
                                    <Button className='bg-white border-white d-inline btn-hover-less' onClick={handleHelperModal}>
                                        <i className="icon-2x text-danger flaticon-questions-circular-button d-inline"/>
                                        <p className='text-danger d-inline'><b>{t('common.helper')}</b></p>
                                    </Button>
                                    <DTable
                                        remote
                                        data={programs}
                                        columns={columns}
                                        config={config}
                                        onInteraction={handleTableInteraction}
                                        totalDataSize={tableTotalSize}
                                        showOrdering={true}
                                        contextMenus={contextMenusActive}
                                        onContextMenuItemClick={handleContextMenuClick}
                                        selectMode={'radio'}
                                    />
                                </>
                            : null
                        }
                        {
                            key.toLowerCase() === '#unpublish'
                            ?
                                <>
                                    <DTable
                                        remote
                                        data={programs}
                                        columns={columns}
                                        config={config}
                                        onInteraction={handleTableInteraction}
                                        totalDataSize={tableTotalSize}
                                        showOrdering={true}
                                        contextMenus={contextMenusInActive}
                                        onContextMenuItemClick={handleContextMenuClick}
                                        selectMode={'radio'}
                                    />
                                </>
                            : null
                        }
                    </Card.Body>
                </Card>
            </div>
            <Modal
                show={helperModal}
                onHide={handleHelperModalClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Modal heading
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Centered Modal</h4>
                    <p>
                        Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
                        dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta
                        ac consectetur ac, vestibulum at eros.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleHelperModalClose}>{t('common.close')}</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={viewModal}
                onHide={handleViewModalClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t('common.view')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        selectedRecord
                            ?
                            <Form>
                                <Form.Group as={Row}>
                                    <Form.Label column sm="4" className='text-right'>
                                        {t('curriculum.code')}
                                    </Form.Label>
                                    <Col sm="8">
                                        <div className='form-label col-form-label'>{selectedRecord.code}</div>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label column sm="4" className='text-right'>
                                        {t('curriculum.name')}
                                    </Form.Label>
                                    <Col sm="8">
                                        <div className='form-label col-form-label'>{selectedRecord.name}</div>
                                    </Col>
                                </Form.Group>
                            </Form>
                            : null
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleViewModalClose}>{t('common.close')}</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Academic;