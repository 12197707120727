export const CandidateProfileInfo = ({
  title,
  text,
  func,
  hasLink = false,
}) => {
  return (
    <li className="d-flex justify-content-center row">
      <div className="d-flex justify-content-end col text-dark">
        <p
          className="m-0"
          style={{
            textAlign: "right",
            color: "#185BC5",
            fontSize: "15px",
          }}
        >
          {title}
        </p>
      </div>
      <div className="col">
        {hasLink ? (
          <a onClick={() => func()} className="text-dark">
            <span
              className="break-word text-break"
              style={{
                textDecoration: "underline",
                fontWeight: "bold",
                color: "#716ACA",
                fontSize: "15px",
              }}
            >
              {text}
            </span>
          </a>
        ) : (
          <p
            className="m-0 break-word text-break"
            style={{
              fontWeight: "bold",
              fontSize: "15px",
            }}
          >
            {text}
          </p>
        )}
      </div>
    </li>
  );
};
