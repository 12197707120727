export const mainInit = 'main/init';

export const adminNewsfeedInit = 'admin/newsfeed/init';
export const adminNewsfeedAdd = 'admin/newsfeed/add';
export const adminNewsfeedEdit = 'admin/newsfeed/edit';
export const adminNewsfeedDelete = 'admin/newsfeed/delete';

export const academicProgramInit = 'lms/program/academic-init';
export const academicProgramAdd = 'lms/program/academic-add';
export const academicProgramEdit = 'lms/program/academic-edit';
export const academicProgramList = 'lms/program/academic-programs';
export const programList = 'lms/program/list';
export const lmsStudentSearch = 'lms/student/search';
export const academicProgramChangeStatus = 'lms/program/academic-change-status';
export const academicProgramDelete = 'lms/program/academic-delete';
export const programInit = 'lms/program/init';
export const programAdd = 'lms/program/add';
export const programEdit = 'lms/program/edit';
export const programChangeStatus = 'lms/program/program-change-status';
export const programChangeName = 'lms/program/program-change-name';
export const schoolInit = 'admin/school/init';
export const schoolEdit = 'admin/school/edit';
export const schoolDelete = 'admin/school/delete';
export const getSystemLanguages = 'admin/config/lang';
export const systemLanguageEdit = 'admin/config/lang-edit';
export const systemLanguageDelete = 'admin/config/lang-delete';
export const schoolAdd = 'admin/school/add';
export const departmentInit = 'admin/department/init';
export const departmentAdd = 'admin/department/add';
export const departmentEdit = 'admin/department/edit';
export const departmentDelete = 'admin/department/delete';
export const teacherInit = 'hr/teacher/init';
export const teacherList = 'hr/teacher/list';
export const teacherAdd = 'hr/teacher/add';
export const teacherEdit = 'hr/teacher/edit';
export const teacherDelete = 'hr/teacher/delete';
export const teacherChangeType = 'hr/teacher/change-activeType';
export const teacherMovement = 'hr/teacher/movement';
export const teacherChangePassword = 'hr/teacher/change-password';
export const teacherInvite = 'hr/teacher/invite';
export const findTeachers = 'hr/teacher/find-teachers';
export const subjectInit = 'lms/subject/init';
export const subjectList = 'lms/subject/list';
export const subjectAdd = 'lms/subject/add';
export const subjectPriceAdd = 'lms/subject/add-price';
export const subjectPriceConfig = 'lms/subject/price-config';
export const subjectEdit = 'lms/subject/edit';
export const subjectDelete = 'lms/subject/delete';
export const hrInit = 'admin/hr/init';
export const hrAdd = 'admin/hr/add';
export const hrEdit = 'admin/hr/edit';
export const hrDelete = 'admin/hr/delete';
export const hrSchoolPositions = 'admin/hr/school-positions';
export const hrSchoolPositionsAdd = 'admin/hr/school-positions-add';
export const hrSchoolPositionsDelete = 'admin/hr/school-positions-delete';
export const schoolClassInit = 'school/class/init';
export const schoolClassView = 'school/class/view';
export const schoolClassDelete = 'school/class/delete';
export const schoolClassesBySeasons = 'school/class/classes-by-seasons';
export const getCurriculum = 'lms/program/curriculum';
export const curriculumAdd = 'lms/program/curriculum-add';
export const curriculumDownloadProgramDtl = 'lms/program/curriculum-download-program-dtl';
export const curriculumDownloadGetProgram = 'lms/program/curriculum-download-get-program';
export const curriculumEdit = 'lms/program/program-dtl-edit';
export const curriculumReorder = 'lms/program/program-dtl-reorder';
export const curriculumDeLete = 'lms/program/program-dtl-delete';
export const curriculumPublish = 'lms/program/program-publish';
export const getTeachersBySubject = 'hr/teacher/get-teacher-by-subject';
export const getSchoolClassAdd = 'school/class/add';
export const getSchoolClassEdit = 'school/class/edit';
export const getSchoolClassAddStudent = 'school/class/add-student';
export const addSchoolClassAddStudent = 'school/class/add-student';
export const studentApiSearch = 'lms/student/student-api-search';
export const studentSearchByRegister = 'lms/student/student-search';
export const getAcademicPrograms = 'school/class/academic-programs';
export const getPrograms = 'school/class/programs';
export const getSubjectListByType = 'lms/subject/list-by-type';
export const getSubjectListByCode = 'lms/subject/list-by-code';
export const getSubjectListBySearchValue = 'lms/subject/search';
export const schoolDiscountInit = 'lms/student/discount';
export const schoolScholarshipInit = 'lms/student/scholarship';
export const schoolScholarshipDelete = 'lms/student/scholarship-delete';
export const schoolDecisionInit = 'lms/student/decision';
export const lmsStudentList = 'lms/student/list';
export const lmsStudentChangeName = 'lms/student/change-student-name';
export const lmsGradeIndex = 'lms/grade/index';
export const lmsGradeScore = 'lms/grade/score';
export const lmsAnketInit = 'lms/anket/init';
export const lmsAnketFamily = 'lms/anket/family';
export const lmsAnketRelatives = 'lms/anket/relatives';
export const lmsAnketEducation = 'lms/anket/education';
export const lmsAnketRewards = 'lms/anket/rewards';
export const lmsAnketEyesh = 'lms/anket/eyesh';
export const lmsAnketExperience = 'lms/anket/experience';

export const roomAdd = 'admin/school/room-add';
export const roomEdit = 'admin/school/room-edit';
export const roomDelete = 'admin/school/room-delete';
export const roomSearch = 'admin/school/room-search';
export const getStudentInfo = 'school/student/info';

export const passwordSave = 'auth/change-password';
export const userProfile = 'user/profile';

// Teacher
export const getJournalInit = 'teacher/journal/init';
export const getJournalDetail = 'teacher/journal/detail';
export const getAttendance = 'teacher/journal/attendance';
export const studentAttendanceSave = 'teacher/journal/attendance';
export const attendanceSave = 'teacher/journal/attendance-save';
export const journalResultSave = 'teacher/journal/result-save';
export const journalResultUnpublish = 'teacher/journal/result-unpublish';
export const journalTopicSave = 'teacher/journal/topic-save';
export const journalTopicEdit = 'teacher/journal/topic-edit';
export const journalTopicDelete = 'teacher/journal/topic-delete';
export const teacherTimetableInit = 'teacher/timetable/init';
export const teacherRegulationInit = 'teacher/regulation/init';
export const teacherEventInit = 'teacher/event/init';
export const teacherTeams = 'teacher/journal/teams';
export const teacherFileInit = 'teacher/file/init';
export const teacherFileAdd = 'teacher/file/add';
export const teacherFileDelete = 'teacher/file/delete';
export const teacherFileGet = 'teacher/file/get';
export const teacherDifficultyInit = 'teacher/difficulty/init';
export const teacherDifficultyAdd = 'teacher/difficulty/add';
export const teacherDifficultyDelete = 'teacher/difficulty/delete';
export const teacherTimeSheetInit = 'teacher/time-sheet/init';
export const teacherTimeSheetView = 'teacher/time-sheet/view';
export const teacherTimeSheetPrint = 'teacher/time-sheet/print';
export const teacherTimeAdditionalInit = 'teacher/time-sheet/additional-init';
export const teacherTimeAdditionalAdd = 'teacher/time-sheet/additional-add';
export const teacherTimeAdditionalEdit = 'teacher/time-sheet/additional-edit';
export const teacherTimeAdditionalDelete = 'teacher/time-sheet/additional-delete';
export const teacherTimeAdditionalSubjectSearch = 'teacher/time-sheet/search';
// teacher grade
export const teacherGradeInit = 'teacher/grade/init';
export const teacherGradeLog = 'teacher/grade/log';
export const teacherGradeLogView = 'teacher/grade/log-view';

// Student
export const studentSelectionInit = 'student/selection/init';
export const studentSelectionIndex = 'student/selection/index';
export const AddSubjectStudentSelection = 'student/selection/add';
export const studentPaymentInit = 'student/payment/init';
export const studentAnketInit = 'student/anket/init';
export const studentAnketFamily = 'student/anket/family';
export const studentAnketRelatives = 'student/anket/relatives';
export const studentAnketEducation = 'student/anket/education';
export const studentAnketRewards = 'student/anket/rewards';
export const studentAnketEyesh = 'student/anket/eyesh';
export const studentAnketExperience = 'student/anket/experience';
export const studentPaymentSubmit = 'student/payment/submit';
export const studentSelectionPayment = 'student/selection/payment';
export const studentSelectionPaymentCheck = 'student/payment/qpay-check';
export const studentPaymentCheck = 'student/payment/check';
export const studentTimetableIndex = 'student/timetable/index';
export const studentTimetableProgress = 'student/timetable/progress';
export const studentGradeIndex = 'student/grade/index';
export const studentGradeScore = 'student/grade/score';
export const studentRegulationInit = 'student/regulation/init';
export const studentRequestIndex = 'student/request/index';
export const studentRequestView = 'student/request/view';
export const studentRequestEdit = 'student/request/edit';
export const studentRequestDelete = 'student/request/delete';
export const studentRequestCreate = 'student/request/create';
export const studentEventInit = 'student/event/init';
export const studentNewsfeedInit = 'student/newsfeed/init';
export const studentTranscriptInit = 'student/transcript/init';
export const studentTranscriptView = 'student/transcript/view';
export const studentTranscriptCreate = 'student/transcript/create';
export const newsfeedStudentTranscriptView = 'student/newsfeed/transcript-view';

// Student survey
export const getStudentSurvey = 'student/survey/init';
export const getStudentSurveyStart = 'student/survey/start';
export const getStudentSurveySubmit = 'student/survey/submit';
export const studentSurveyList = 'student/survey/list';

export const officerAnketInit = 'lms/anket1/init';
export const officerAnketFamily = 'lms/anket1/family';
export const officerAnketRelatives = 'lms/anket1/relatives';
export const officerAnketEducation = 'lms/anket1/education';
export const officerAnketRewards = 'lms/anket1/rewards';
export const officerAnketEyesh = 'lms/anket1/eyesh';
export const officerAnketExperience = 'lms/anket1/experience';

// Finance
export const financeEventInit = 'finance/event/init';
export const bankInfoInit = 'finance/bank-info/init';
export const bankInfoCreate = 'finance/bank-info/create';
export const bankInfoEdit = 'finance/bank-info/edit';
export const bankInfoDelete = 'finance/bank-info/delete';
export const invoiceTypeInit = 'finance/invoice-type/init';
export const invoiceTypeCreate = 'finance/invoice-type/create';
export const invoiceTypeEdit = 'finance/invoice-type/edit';
export const invoiceTypeDelete = 'finance/invoice-type/delete';
export const invoiceInit = 'finance/invoice/init';
export const invoiceCreate = 'finance/invoice/create';
export const statementInit = 'finance/statements/init';
export const statementConnect = 'finance/statements/connect';
export const studentSearch = 'finance/student/search';
export const merchantRequests = 'finance/payment/merchant-requests';
export const studentFinanceProfile = 'finance/student/info';
export const financeSaleTypeLoanConfigs = 'finance/sale-type/loan-configs';
export const financeSaleTypeLoanConfigDelete = 'finance/sale-type/loan-config-delete';
export const financeConfigScholarshipTypes = 'finance/config/scholarship-types';
export const financeConfigScholarshipEdit = 'finance/config/scholarship-edit';
export const financeConfigScholarshipDelete = 'finance/config/scholarship-delete';
export const financeConfigScholarshipActive = 'finance/config/scholarship-active';
export const financeConfigDiscounts = 'finance/config/discounts';
export const financeDiscountEdit = 'finance/config/discount-edit';
export const financeDiscountDelete = 'finance/config/discount-delete';
export const financeDiscountActive = 'finance/config/discount-active';
export const financeProgramPriceUpdate = 'finance/program/price-update';
export const financeScholarshipInit = 'finance/scholarship/init';
export const financeDiscountInit = 'finance/discount/init';
export const financeDiscountAdd = 'finance/discount/create';

// Timetable
export const timetableInit = 'lms/timetable/init';
export const timetableAdd = 'lms/timetable/add';
export const timetableAddPotok = 'lms/timetable/add-potok';
export const timetableEditPotok = 'lms/timetable/edit-potok';
export const timetableEdit = 'lms/timetable/edit';
export const timetableGroups = 'lms/timetable/get-groups';
export const timetableIdsDelete = 'lms/timetable/timetable-ids-delete';
export const timetableDelete = 'lms/timetable/delete';
export const timetableMultiDelete = 'lms/timetable/delete-timetables';
export const timetableSubjectInfo = 'lms/timetable/subject-info';
export const timetableAddDatas = 'lms/timetable/add-datas';
export const requestIndex = 'lms/request/index';
export const requestUpdate = 'lms/request/update';
export const regulationIndex = 'lms/regulation/index';
export const regulationCreate = 'lms/regulation/create';
export const regulationEdit = 'lms/regulation/edit';
export const regulationDelete = 'lms/regulation/delete';

// Selection
export const selectionInit = 'lms/selection/init';
export const selectionViewOne = 'lms/selection/view-one';
export const selectionStudentIndex = 'lms/selection/student-index';
export const selectionStudentAdd = 'lms/selection/student-add';
export const selectionGetSubjectData = 'lms/selection/get-subject-data';
export const selectionFindStudents = 'lms/selection/find-students';
export const selectionGetStudentData = 'lms/selection/get-student-data';
export const selectionPenaltyIndex = 'lms/penalty/index';
export const selectionPenaltyCreate = 'lms/penalty/create';
export const lmsTranscriptInit = 'lms/transcript/init';
export const lmsTranscriptCreate = 'lms/transcript/create';
export const lmsTranscriptView = 'lms/transcript/view';
export const lmsStudentTranscriptCheck = 'lms/transcript/check';

// Movement
export const movementInit = 'school/movement/init';
export const movementBetween = 'school/movement/between';
export const movementBetweenView = 'school/movement/between-view';
export const movementBetweenUndo = 'school/movement/between-undo';
export const movementSchoolBetweenAdd = 'school/movement/school-between';
export const movementSchoolBetweenEdit = 'school/movement/school-between-edit';
export const movementSchoolBetweenView = 'school/movement/school-between-view';
export const movementSchoolBetweenUndo = 'school/movement/school-between-undo';
export const movementOut = 'school/movement/out-index';
export const movementOutAdd = 'school/movement/out';
export const movementOutView = 'school/movement/out-view';
export const movementOutUndo = 'school/movement/out-undo';
export const movementMissing = 'school/movement/missing-index'
export const movementMissingAdd = 'school/movement/missing-add'
export const movementMissingView = 'school/movement/missing-view'
export const movementMissingUndo = 'school/movement/missing-undo'
export const movementMissingChangeType = 'school/movement/missing-change-status'
export const movementExcuse = 'school/movement/excuse-index'
export const movementExcuseAdd = 'school/movement/excuse-add'
export const movementExcuseView = 'school/movement/excuse-view'
export const movementExcuseUndo = 'school/movement/excuse-undo'
export const movementExcuseChangeType = 'school/movement/excuse-change-status'

// Newsfeed
export const teacherNewsfeedInit = 'teacher/newsfeed/init';
export const financeNewsfeedInit = 'finance/newsfeed/init';
export const eventInit = 'admin/event/init';
export const eventAdd = 'admin/event/add';
export const eventEdit = 'admin/event/edit';
export const eventDelete = 'admin/event/delete';
export const eventDrag = 'admin/event/drag';

// Setting
export const getAttendanceTypes = 'admin/school/attendance-type-add';
export const addAttendanceTypes = 'admin/school/attendance-type-add';
export const editAttendanceTypes = 'admin/school/attendance-type-edit';
export const deleteAttendanceTypes = 'admin/school/attendance-type-delete';
export const getSystemRoles = 'admin/config/role';
export const getRoles = 'admin/school/roles';
export const addRole = 'admin/school/roles';
export const editRole = 'admin/school/roles-edit';
export const deleteRole = 'admin/school/roles-delete';
export const getTeacherActiveTypes = 'admin/config/teacher-active-type-add';
export const teacherActiveTypeADd = 'admin/config/teacher-active-type-add';
export const teacherActiveTypeEdit = 'admin/config/teacher-active-type-edit';
export const teacherActiveTypeDelete = 'admin/config/teacher-active-type-delete';
export const teacherActiveTypeReorder = 'admin/config/teacher-active-type-reorder';
export const getHrStatusList = 'admin/hr/hr-status-add';
export const addHrStatus = 'admin/hr/hr-status-add';
export const hrStatusEdit = 'admin/hr/hr-status-edit';
export const hrStatusDelete = 'admin/hr/hr-status-delete';
export const educationLevelAdd = 'admin/school/education-level-add';
export const educationLevelEdit = 'admin/school/education-level-edit';
export const educationLevelDelete = 'admin/school/education-level-delete';
export const educationLevelReorder = 'admin/school/education-level-reorder';
export const educationDegreeAdd = 'admin/config/education-degree-add';
export const educationDegreeEdit = 'admin/config/education-degree-edit';
export const educationDegreeDelete = 'admin/config/education-degree-delete';
export const educationDegreeReorder = 'admin/config/education-degree-reorder';
export const regulationTypes = 'admin/config/regulation-types';
export const regulationTypeAdd = 'admin/config/regulation-type-add';
export const regulationTypeEdit = 'admin/config/regulation-type-edit';
export const regulationTypeDelete = 'admin/config/regulation-type-delete';
export const requestTypeAdd = 'admin/config/request-type-add';
export const requestTypeEdit = 'admin/config/request-type-edit';
export const requestTypeDelete = 'admin/config/request-type-delete';
export const requestStatusAdd = 'admin/config/request-status-add';
export const requestStatusEdit = 'admin/config/request-status-edit';
export const requestStatusDelete = 'admin/config/request-status-delete';
export const transcriptTypeAdd = 'admin/config/transcript-type-add';
export const transcriptTypeEdit = 'admin/config/transcript-type-edit';
export const transcriptTypeDelete = 'admin/config/transcript-type-delete';

export const addCountry = 'admin/config/country-add';
export const countryEdit = 'admin/config/country-edit';
export const countryDelete = 'admin/config/country-delete';
export const countryReorder = 'admin/config/country-reorder';
export const schoolShiftAdd = 'admin/school/school-shift-add';
export const schoolShiftEdit = 'admin/school/school-shift-edit';
export const schoolShiftDelete = 'admin/school/school-shift-delete';
export const subjectTypeAdd = 'admin/school/subject-type-add';
export const subjectTypeEdit = 'admin/school/subject-type-edit';
export const subjectTypeDelete = 'admin/school/subject-type-delete';
export const subjectTypeReorder = 'admin/school/subject-type-reorder';
export const LmStatusAdd = 'admin/school/lm-status-add';
export const lmStatusEdit = 'admin/school/lm-status-edit';
export const lmStatusDelete = 'admin/school/lm-status-delete';
export const LmTypeAdd = 'admin/lms/add-lm-type';
export const lmTypeEdit = 'admin/lms/edit-lm-type';
export const lmTypeDelete = 'admin/lms/delete-lm-type';
export const LmApproachAdd = 'admin/school/approach-add';
export const lmApproachEdit = 'admin/school/approach-edit';
export const lmApproachDelete = 'admin/school/approach-delete';
export const LmCategoryAdd = 'admin/lms/add-lm-pcategory';
export const lmCategoryEdit = 'admin/lms/edit-lm-pcategory';
export const lmCategoryDelete = 'admin/lms/delete-lm-pcategory';
export const lmCategoryReorder = 'admin/lms/lm-pcategory-reorder';
export const LmPTypeAdd = 'admin/lms/add-lm-ptype';
export const lmPTypeEdit = 'admin/lms/edit-lm-ptype';
export const lmPTypeDelete = 'admin/lms/delete-lm-ptype';
export const lmPTypeReorder = 'admin/lms/lm-ptype-reorder';
export const LmPTimeAdd = 'admin/school/ptime-add';
export const lmPTimeEdit = 'admin/school/ptime-edit';
export const lmPTimeDelete = 'admin/school/ptime-delete';
export const lmPTimeReorder = 'admin/school/ptime-reorder';
export const timeTemplateAdd = 'admin/school/time-template-add';
export const timeTemplateEdit = 'admin/school/time-template-edit';
export const timeTemplateDelete = 'admin/school/time-template-delete';
export const timeTemplateReorder = 'admin/school/time-template-reorder';
export const getSeasonTypes = 'admin/config/season-type';
export const seasonTypeAdd = 'admin/config/season-type';
export const seasonTypeEdit = 'admin/config/season-type-edit';
export const seasonTypeDelete = 'admin/config/season-type-delete';
export const addressSegmentAdd = 'admin/config/address-segment-add';
export const addressSegmentEdit = 'admin/config/address-segment-edit';
export const addressSegmentDelete = 'admin/config/address-segment-delete';
export const classTypeAdd = 'admin/school/classtype-add';
export const classTypeEdit = 'admin/school/classtype-edit';
export const classTypeDelete = 'admin/school/classtype-delete';
export const classStatusAdd = 'admin/config/class-status-add';
export const classStatusEdit = 'admin/config/class-status-edit';
export const classStatusDelete = 'admin/config/class-status-delete';
export const groupTypeAdd = 'admin/school/group-type-add';
export const groupTypeEdit = 'admin/school/group-type-edit';
export const groupTypeDelete = 'admin/school/group-type-delete';
export const studentStatusAdd = 'admin/school/student-status-add';
export const studentStatusEdit = 'admin/school/student-status-edit';
export const studentStatusDelete = 'admin/school/student-status-delete';
export const studentStatusReorder = 'admin/school/student-status-reorder';
export const studentStatusConfigAdd = 'admin/school/stu-status-config-add';
export const studentStatusConfigEdit = 'admin/school/stu-status-config-edit';
export const studentStatusConfigDelete = 'admin/school/stu-status-config-delete';
export const movementTypeAdd = 'admin/config/movement-type-add';
export const movementTypeEdit = 'admin/config/movement-type-edit';
export const movementTypeDelete = 'admin/config/movement-type-delete';
export const movementCauseAdd = 'admin/school/movement-cause-add';
export const movementCauseEdit= 'admin/school/movement-cause-edit';
export const movementCauseDelete = 'admin/school/movement-cause-delete';
export const configDayAdd = 'admin/config/day-add';
export const configDayEdit = 'admin/config/day-edit';
export const configDayDelete= 'admin/config/day-delete';
export const configDayReorder = 'admin/config/day-reorder';
export const timetableTypeAdd = 'admin/config/timetable-type-add';
export const timetableTypeEdit = 'admin/config/timetable-type-edit';
export const timetableTypeDelete= 'admin/config/timetable-type-delete';
export const selectionConfigAdd = 'admin/school/selection-config-add';
export const selectionConfigEdit = 'admin/school/selection-config-edit';
export const selectionConfigDelete = 'admin/school/selection-config-delete';
export const scoreTypeAdd = 'admin/school/scoretype-add';
export const scoreTypeEdit = 'admin/school/scoretype-edit';
export const scoreTypeDelete= 'admin/school/scoretype-delete';
export const seasonAdd = 'admin/school/season-add';
export const seasonEdit = 'admin/school/season-edit';
export const seasonDelete = 'admin/school/season-delete';
export const examTypeAdd = 'admin/school/exam-type-add';
export const examTypeEdit = 'admin/school/exam-type-edit';
export const examTypeDelete = 'admin/school/exam-type-delete';
export const examTypeConfigAdd = 'admin/school/exam-type-config-add';
export const examTypeConfigEdit = 'admin/school/exam-type-config-edit';
export const stItemAdd = 'admin/school/st-item-add';
export const stItemEdit = 'admin/school/st-item-edit';
export const stItemDelete = 'admin/school/st-item-delete';
export const stTemplateAdd = 'admin/school/st-template-add';
export const stTemplateEdit = 'admin/school/st-template-edit';
export const stTemplateDelete = 'admin/school/st-template-delete';
export const contactTypeAdd = 'admin/config/contact-type-add';
export const contactTypeEdit = 'admin/config/contact-type-edit';
export const contactTypeDelete = 'admin/config/contact-type-delete';
export const menuInit = 'admin/menu/list';
export const menuAdd = 'admin/menu/add';
export const menuEdit = 'admin/menu/update';
export const menuPositionInit = 'admin/menu/position-list';
export const menuPositionAdd = 'admin/menu/position-add';
export const menuPositionEdit = 'admin/menu/position-edit';
export const menuPositionDelete = 'admin/menu/position-delete';

// Online exam
export const studentOnlineExamListInit = 'student/online-exam/init'
export const studentOnlineExamStart = 'student/online-exam/start'
export const studentOnlineExamAnswer = 'student/online-exam/answer'
export const studentOnlineExamFinish = 'student/online-exam/finish'
export const studentOnlineExamResult = 'student/online-exam/result'
export const teacherOnlineExam = 'teacher/online-exam/init'
export const teacherOnlineExamChangeTime = 'teacher/online-exam/change-time'
export const teacherOnlineExamCreate = 'teacher/online-exam/create'
export const teacherOnlineExamTopic = 'teacher/online-exam/topic'
export const teacherOnlineExamRandom = 'teacher/online-exam/randomTopic'
export const teacherOnlineExamQuestion = 'teacher/online-exam/question'
export const teacherOnlineExamRQuestion = 'teacher/online-exam/rquestion'
export const teacherOnlineExamView = 'teacher/online-exam/view'
export const teacherOnlineExamExcel = 'teacher/online-exam/excel'
export const teacherOnlineExamGroups = 'teacher/online-exam/groups'
export const teacherOnlineExamResult = 'teacher/online-exam/result'
export const teacherOnlineExamAnswer = 'teacher/online-exam/checkAnswer'

// Online lesson teacher
export const teacherOnlineLessonInit = 'teacher/online-lesson/init'
export const teacherOnlineLessonAdd = 'teacher/online-lesson/add'
export const teacherOnlineLessonEdit = 'teacher/online-lesson/edit'
export const teacherOnlineLessonContent = 'teacher/online-lesson/content'
export const teacherOnlineLessonTopicList = 'teacher/online-lesson/topic-list'
export const teacherOnlineLessonTopicAdd = 'teacher/online-lesson/topic-add'
export const teacherOnlineLessonTopicEdit = 'teacher/online-lesson/topic-edit'
export const teacherOnlineLessonTopicDelete = 'teacher/online-lesson/topic-delete'
export const teacherOnlineLessonTopicView = 'teacher/online-lesson/topic-view'
export const teacherOnlineLessonTopicActive = 'teacher/online-lesson/topic-active'
export const teacherOnlineLessonGetGroups = 'teacher/online-lesson/get-groups'
export const teacherOnlineLessonInformationEdit = 'teacher/online-lesson/get-groups'
export const teacherOnlineLessonHomeworkList = 'teacher/online-lesson/homework-list'
export const teacherOnlineLessonHomeworkAdd = 'teacher/online-lesson/homework-add'
export const teacherOnlineLessonHomeworkEdit = 'teacher/online-lesson/homework-edit'
export const teacherOnlineLessonHomeworkDelete = 'teacher/online-lesson/homework-delete'
export const teacherOnlineLessonHomeworkView = 'teacher/online-lesson/homework-view'
export const teacherOnlineLessonHomeworkActive = 'teacher/online-lesson/homework-active'
export const teacherOnlineLessonNewsfeedList = 'teacher/online-lesson/newsfeed-list'
export const teacherOnlineLessonNewsfeedAdd = 'teacher/online-lesson/newsfeed-add'
export const teacherOnlineLessonNewsfeedEdit = 'teacher/online-lesson/newsfeed-edit'
export const teacherOnlineLessonNewsfeedDelete = 'teacher/online-lesson/newsfeed-delete'
export const teacherOnlineLessonNewsfeedView = 'teacher/online-lesson/newsfeed-view'
export const teacherOnlineLessonNewsfeedActive = 'teacher/online-lesson/newsfeed-active'
export const teacherOnlineLessonAnnouncementList = 'teacher/online-lesson/announcement-list'
export const teacherOnlineLessonAnnouncementAdd = 'teacher/online-lesson/announcement-add'
export const teacherOnlineLessonAnnouncementEdit = 'teacher/online-lesson/announcement-edit'
export const teacherOnlineLessonAnnouncementDelete = 'teacher/online-lesson/announcement-delete'
export const teacherOnlineLessonStudentList = 'teacher/online-lesson/student-list'
export const teacherOnlineLessonResult = 'teacher/online-lesson/result'
export const teacherOnlineLessonResultSave = 'teacher/online-lesson/result-save'
export const teacherOnlineLessonResultRecalculate = 'teacher/online-lesson/result-recalculate'
// Online lesson student
export const studentOnlineLessonInit = 'student/online-lesson/init'
export const studentOnlineLessonNewsfeed = 'student/online-lesson/newsfeed'
export const studentOnlineLessonTopic = 'student/online-lesson/topic'
export const studentOnlineLessonTopicView = 'student/online-lesson/topic-view'
export const studentOnlineLessonHw = 'student/online-lesson/homework'
export const studentOnlineLessonHomeworkView = 'student/online-lesson/homework-view'
export const studentOnlineLessonDiscussion = 'student/online-lesson/discussion'
export const studentOnlineLessonCourseRate = 'student/online-lesson/course-rate'
export const studentOnlineLessonGetFile = 'student/online-lesson/get-file'

// Teacher questions

export const teacherQuestionsInit = 'teacher/questions/init'
// Online Exam
// Question Bank
export const questionBankInit = 'question-bank/init';
export const questionBankList = 'question-bank/list';
export const questionBankAdd =  'question-bank/add';
export const questionBankDeactivate = 'question-bank/deactivate';
export const questionBankDelete = 'question-bank/delete';
export const questionBankEdit = 'question-bank/edit';

// enrollment

// #################### setting ###########################
export const enrollSettingList = "enroll/settings/list";
export const getAddSetting = "enroll/settings/add";
export const getEditSetting = "enroll/settings/edit";
export const getDeleteSetting = "enroll/settings/delete";
export const getInActiveSetting = "enroll/settings/approved";
export const getAddProgram = "enroll/settings/add-program";
export const getEditProgram = "enroll/settings/edit-program";
export const getDeleteProgram = "enroll/settings/delete-program";
export const getAddEnrollPhase = "enroll/settings/add-enroll-phase";
export const getEditEnrollPhase = "enroll/settings/edit-enroll-phase";
export const getDeleteEnrollPhase = "enroll/settings/delete-enroll-phase";
export const getOrderingEnrollPhase = "enroll/settings/ordering-enroll-phase";
export const getUpdateCVConfig = "enroll/settings/update-cv-config";
export const getCVConfig = "enroll/settings/get-cv-config";
export const getProgramDetail = "enroll/settings/program/detail";
export const getProgramDetailSave = "enroll/settings/program/detail/save";
export const getDtlSegments = "enroll/settings/get-dtl-segments";
export const getProgramSegmentSave = "enroll/settings/program/segment/save";
export const getProgramSegmentEdit = "enroll/settings/program/segment/edit";
export const getProgramSegmentDelete = "enroll/settings/program/segment/delete";
export const getProgramAddEyeshValue = "enroll/settings/add-eyesh-value";
export const getAthleteCriteria = "enroll/settings/athlete-criteria";
export const getAddAthleteCriteria = "enroll/settings/add-athlete-criteria";
export const getEditAthleteCriteria = "enroll/settings/edit-athlete-criteria";
export const getDeleteAthleteCriteria = "enroll/settings/delete-athlete-criteria";
// #################### candidate-admin ###########################
export const getCandidateListByPhase = "candidate-admin/stage";
export const candidateAdminEnrollPhaseIsShow = "candidate-admin/enroll-phase-is-show";
export const candidateAdminDownloadPassedCandidate = "candidate-admin/download-passed-students";
export const editCandidateStatus = "candidate-admin/change-status";
export const editCandidateProgram = "candidate-admin/change-program";
export const getChangeInfo = "candidate-admin/change-info";
export const candidateProfileEdit = "candidate-admin/profile-edit";
export const registeredCandidatesList = "candidate-admin/registered";
export const registeredCandidatesDownloadEyesh = "candidate-admin/download-eyesh";
export const nonRegisteredCandidatesList = "candidate-admin/non-registered";
export const CandidateProfileList = "candidate-admin/profile";
export const editStatus = "candidate-admin/change-status";
export const candidateFinalStatus = "candidate-admin/change-final-status"
export const candidateChangeAthleteScore = "candidate-admin/change-athlete-score"
export const candidateMakeStudent = "candidate-admin/make-student"
export const candidateRegistrationPayments = "candidate-admin/registration-payments";
// #################### candidate-athlethes ###########################
export const getAthlethes = "candidate-athlete/edit";
export const postAthlethes = "candidate-athlete/eyesh-values/save";
export const getPsychology = "candidate-psychology/change";
export const getAthleteList = "candidate-athlete/list";
export const getAthleteChange = "candidate-athlete/change";
export const getAthleteConfirm = "candidate-athlete/confirm";
export const psychologyAdd = "candidate-psychology/add";
export const psychologyEdit = "candidate-psychology/edit";
export const psychologyDesc = "candidate-psychology/get-log";
// #################### enroll-common ###########################
export const enrollProfessionList = "admin/enrolls/detail-list";
export const healthList = "candidate-health/list";
export const psychologyList = "candidate-psychology/list";
export const getCandidateHealth = "candidate-health/list";
export const getSchoolSeasons = "enroll-common/school-seasons";
export const getSchools = "enroll-common/schools";
export const getSchoolEnrolls = "enroll-common/enrolls";
export const getAcademicProgram = "enroll-common/school-academic-programs";
export const getSchoolProgram = "enroll-common/school-programs";
export const getSchoolPrograms = "enroll-common/enroll-programs-by-params";
export const getProgramsBySchool = "enroll-common/programs-by-school";
export const getStageList = "enroll-common/candidate/phase";
export const getSeasonList = "enroll-common/school-seasons";
export const getProgramList = "enroll-common/school-programs";
export const getEnrollList = "enroll-common/enrolls";
export const dashboardList = "enroll-dashboard/dashboard";
export const dashboardDetailsList = "enroll-dashboard/enroll-detail";
export const candidateCommon = "enroll-common/candidate-detail";
export const getEducationSeasons = "enroll-common/education-seasons";
// #################### enroll-certificate ###########################
export const certificateNumberList = "certificate-number/settings/list";
export const certificateNumberAdd = "certificate-number/settings/add";
export const certificateNumberDelete = "certificate-number/settings/delete";
export const certificateNumberEdit = "certificate-number/settings/edit";
export const certificateCandidate = "candidate-certificate/index";
export const certificateCandidateSubmit = "candidate-certificate/submit";
export const certificateCandidateSubmitAll = "candidate-certificate/submit-all";
export const certificateCandidateEmailSend = "candidate-certificate/send";
export const certificateList = "certificate/settings/list";
export const certificateDelete = "certificate/settings/delete";
export const certificateAdd = "certificate/settings/add";
export const certificateEdit = "certificate/settings/edit";
// #################### enroll-report ###########################
export const messageList = "candidate-sms/list";
export const emailList = "candidate-email/list"; 
export const sendEmail = "candidate-admin/send-email";
export const sendMessage = "candidate-admin/send-sms";

// ----------survey category-------------
export const surveyCategoryIndex = 'lms/survey/category/list';
export const surveyCategoryCreate = 'lms/survey/category/create';
export const surveyCategoryEdit = 'lms/survey/category/edit';
export const surveyCategoryDelete = 'lms/survey/category/delete';

// ----------survey-------------
export const surveyIndex = 'lms/survey/list';
export const surveyView = 'lms/survey/view';
export const surveyInfo = 'lms/survey/info';
export const surveyInfoRoles ='lms/survey/info/role';
export const surveyCreate = 'lms/survey/create';
export const surveyEdit = 'lms/survey/edit';
export const surveyDelete = 'lms/survey/delete';
export const surveyPublish = 'lms/survey/publish';
export const surveyUnpublish = 'lms/survey/unpublish';
export const surveyChangeDate = 'lms/survey/change-date';
export const surveyDuplicate = 'lms/survey/duplicate';

// ----------survey questions-------------
export const surveyQuestionEdit = 'lms/survey/question/edit';
export const surveyQuestionCreate = 'lms/survey/question/create';
export const surveyQuestionUpdate = 'lms/survey/question/create';
export const surveyQuestionDelete = 'lms/survey/question/delete';
export const surveyQuestionReOrdering = 'lms/survey/question/order';
export const surveyQuestionFileDelete = 'lms/survey/question/file-delete';

// ----------survey reports-------------
export const surveyResultReport = 'lms/survey/result/report';
export const surveyResultList = 'lms/survey/result/list';
export const surveyResultClassName = 'lms/survey/result/report/class-name';

// ----------graduations reports-------------
export const graduationIndex = 'diploma/index';
export const graduationMbHomeContent = 'diploma/mb_home_content';
export const graduationMbAttachmentContent = 'diploma/mb_attachment_content';
export const graduationCalculateResult = 'diploma/calculate_result';
export const graduationMbContent = 'diploma/mb_graduation_content';
export const graduationStudents = 'diploma/student_search';
export const graduationCreate = 'diploma/create';
export const graduationConfirm = 'diploma/confirm';
export const graduationRemove = 'diploma/remove';
export const graduationConfig = 'diploma/config/index'
export const graduationConfigCreate = 'diploma/config/create'
export const graduationConfigDelete = 'diploma/config/delete'
export const graduationConfigView = 'diploma/config/view'
export const graduationTemplate = 'diploma-template/index';
export const graduationTemplateCreate = 'diploma-template/create';
export const graduationTemplateEdit = 'diploma-template/edit';
export const graduationTemplateDelete = 'diploma-template/delete';
export const graduationTemplateInActive = 'diploma-template/set-inactive';
export const graduationAdditionalIndex = 'diploma/additional/index';
export const graduationAdditionalCreate = 'diploma/additional/create';
export const graduationSign = 'diploma/sign/index';
export const graduationSignDelete = 'diploma/sign/delete';
export const graduationSignCreate = 'diploma/sign/create';

// ----------enrollment scholarship-------------
export const scholarshipIndex = 'scholarship/index';
export const scholarshipSubmit = 'scholarship/submit';