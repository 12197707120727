import React, { useRef, useEffect, useState } from "react";
import { Button, Modal, View, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
    candidateChangeAthleteScore
} from "../../../../utils/fetchRequest/Urls";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import message from "../../../modules/message";

const ChangeAthleteScore = ({ onClose, onSaveClick, data }) => {
    const { t } = useTranslation();
    const [candidateDetail, setCandidateDetail] = useState([]);
    const dispatch = useDispatch();

    const [avatarUrl, setAvatarUrl] = useState(null)
    const [eyeshCode, setEyeshCode] = useState(null)
    const [gender, setGender] = useState(null)
    const [description, setDescription] = useState('')
    const [criterias, setCriterias] = useState([])
    const [candidateAthletes, setCandidateAthletes] = useState([])

    const [updateView, setUpdateView] = useState(false)

    useEffect(() => {
        finalStatus();
    }, [data]);

    const finalStatus = () => {
        let params = {
            candidate: data?.candidateNo,
            program: data?.programId
        }

        dispatch(setLoading(true));
        fetchRequest(candidateChangeAthleteScore, "GET", params)
            .then((res) => {
                if (res.success) {
                    const { data } = res;

                    setAvatarUrl(data?.avatar)
                    setEyeshCode(data?.eyeshNumber)
                    setCriterias(data?.criterias)
                    setGender(data?.gender)
                    setCandidateAthletes(data?.candidateAthletes)

                } else {
                    message(res?.data?.message || t("errorMessage.title"));
                }
            })
            .catch(() => {
                message(t("errorMessage.title"));
            })
            .finally(() => {
                dispatch(setLoading(false));
            })
    }

    const filterCriteriaScore = (criteriaId = null) => {
        const candidateScores = [...candidateAthletes]
        let selectedCriteria = null;
        for (let c = 0; c < candidateScores.length; c++) {
            if ((candidateScores[c]?.criteriaId || '').toString() === (criteriaId || '').toString()) {
                selectedCriteria = candidateScores[c]
                break;
            }
        }
        return selectedCriteria?.score || ''
    }

    const onScoreChange = (criteriaId = null, value = null) => {
        const clone = [...criterias]
        for (let c = 0; c < clone.length; c++) {
            if ((clone[c]?.id || '').toString() === (criteriaId || '').toString()) {
                clone[c].tempScore = value && value.toString().length > 0 ? parseInt(value) : '';
                break;
            }
        }
        setCriterias(clone)

        setUpdateView(!updateView)
    }

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: "#4a70ae", fontSize: "1.1rem" }}>Засах</div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-4 text-right">
                        {
                            avatarUrl && <img src={'https://darkhanapi.nomch.mn/' + avatarUrl} style={{ width: 100, height: 100 }} />
                        }

                    </div>
                    <div className="col-md-8">
                        <div className="row" style={{ marginBottom: 0 }}>
                            <label className="col-lg-3 col-form-label text-right" style={{ color: '#185BC5', padding: 0 }}>
                                Бүртгэлийн дугаар
                            </label>
                            <div className="col-lg-9 col-xl-4">
                                <b>{data?.id}</b>
                            </div>
                        </div>
                        <div className="row">
                            <label className="col-lg-3 col-form-label text-right" style={{ color: '#185BC5', padding: 0 }}>
                                ЭЕШ бүртгэлийн дугаар
                            </label>
                            <div className="col-lg-9 col-xl-4">
                                <b>{eyeshCode}</b>
                            </div>
                        </div>
                        <div className="row" style={{ marginBottom: 0, marginTop: 20 }}>
                            <label className="col-lg-3 col-form-label text-right" style={{ color: '#185BC5', padding: 0 }}>
                                Овог
                            </label>
                            <div className="col-lg-9 col-xl-4">
                                <b>{data?.lastName}</b>
                            </div>
                        </div>
                        <div className="row" style={{ marginBottom: 0 }}>
                            <label className="col-lg-3 col-form-label text-right" style={{ color: '#185BC5', padding: 0 }}>
                                Нэр
                            </label>
                            <div className="col-lg-9 col-xl-4">
                                <b>{data?.firstName}</b>
                            </div>
                        </div>
                        <div className="row" style={{ marginBottom: 0 }}>
                            <label className="col-lg-3 col-form-label text-right" style={{ color: '#185BC5', padding: 0 }}>
                                Регистрийн дугаар
                            </label>
                            <div className="col-lg-9 col-xl-4">
                                <b>{data?.registrationNumber}</b>
                            </div>
                        </div>
                        <div className="row" style={{ marginBottom: 0 }}>
                            <label className="col-lg-3 col-form-label text-right" style={{ color: '#185BC5', padding: 0 }}>
                                Хүйс
                            </label>
                            <div className="col-lg-9 col-xl-4">
                                <b>{data?.gender === 'F' ? 'Эмэгтэй' : (data?.gender === 'M' ? 'Эрэгтэй' : '')}</b>
                            </div>
                        </div>
                        <div className="row" style={{ marginBottom: 0 }}>
                            <label className="col-lg-3 col-form-label text-right" style={{ color: '#185BC5', padding: 0 }}>
                                И-мэйл
                            </label>
                            <div className="col-lg-9 col-xl-4">
                                <b>{data?.userEmail}</b>
                            </div>
                        </div>
                        <div className="row" style={{ marginBottom: 0 }}>
                            <label className="col-lg-3 col-form-label text-right" style={{ color: '#185BC5', padding: 0 }}>
                                Утасны дугаар
                            </label>
                            <div className="col-lg-9 col-xl-4">
                                <b>{data?.phoneNumber}</b>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="row" style={{ marginTop: 10 }}>
                    <div className="col-2" />
                    <div className="col-8">
                        <table className="table table-bordered table-striped">
                            <tbody>
                                {
                                    criterias && criterias.map(criteriaObj => {
                                        return <tr key={'criteria_' + criteriaObj?.id}>
                                            <td>{criteriaObj?.name}</td>
                                            <td>{gender === 'M' ? criteriaObj?.mMinScore : (gender === 'F' ? criteriaObj?.fMinScore : '')}</td>
                                            <td>
                                                <input
                                                    value={'tempScore' in criteriaObj
                                                        ? (criteriaObj.tempScore?.toString().length > 0
                                                            ? parseInt(criteriaObj.tempScore) : '')
                                                        : filterCriteriaScore(criteriaObj?.id)}
                                                    onChange={(e) => {
                                                        onScoreChange(criteriaObj?.id, e.target.value)
                                                    }}
                                                    className="form-control"
                                                />
                                            </td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="row" style={{ marginTop: 10 }}>
                    <label className="col-md-5 col-form-label text-right" style={{ padding: 0 }}>
                        Тайлбар
                    </label>
                    <div className="col-md-4">
                        <textarea
                            value={description}
                            onChange={(e) => {
                                setDescription(e.target.value)
                            }}
                            style={{ minHeight: 100, maxHeight: 150, width: '100%' }}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className="btn btn-link bolder">
                    {t("common.back")}
                </button>
                <Button variant="success btn-shadow" onClick={() => onSaveClick(criterias, description)}>
                    {t("common.save")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ChangeAthleteScore;
