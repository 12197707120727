import React, { useRef, useEffect } from 'react';
import { Button, Modal } from "react-bootstrap";
import Forms from "../../../modules/Form/Forms";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { fetchRequest } from "../../../../utils/fetchRequest";
import { setLoading } from "../../../../redux/action";
import message from "../../../modules/message";
import { teacherActiveTypeEdit } from "../../../../utils/fetchRequest/Urls";

const ActiveTypeAddModal = ({
    onClose,
    onSubmit,
    id
}) => {
    const { t } = useTranslation();
    const forms = useRef();
    const dispatch = useDispatch();
    const languages = useSelector(state => state?.languages?.languages || []).filter(lang => lang.code !== 'mn');

    useEffect(() => {
        if (id) {
            const params = {
                activeType: id,
            };
            dispatch(setLoading(true));
            fetchRequest(teacherActiveTypeEdit, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res;
                        const translations = data?.activeType?.translations;

                        if(translationFields && translationFields.length > 0 && translations && translations.length > 0){
                            for(let i = 0; i < translationFields.length; i++){
                                for(let t = 0; t < translations.length; t++)
                                    if(translationFields[i]['langCode'] == translations[t]['langCode']){
                                        translationFields[i]['value'] = translations[t]['name'];
                                    }
                            }
                        }

                        forms?.current?.updateFields && forms.current.updateFields([
                            {
                                label: t('common.code'),
                                type: 'nonCryllic',
                                upperCase: true,
                                value: data?.activeType?.code || '',
                                required: true,
                                labelWidth: 250,
                                inputWidth: 300,
                                key: 'code',
                            },
                            {
                                label: t('setting.teacherStatus'),
                                type: 'text',
                                value: data?.activeType?.name || '',
                                required: true,
                                labelWidth: 250,
                                inputWidth: 300,
                                key: 'name',
                            },
                            {
                                type: 'jsx',
                                value: <div key={'separator'} className="separator separator-dashed my-7"/>,
                            },
                            ...translationFields
                        ]);
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        }
    }, []);

    const translationFields = languages.map(lang => {
        return {
            label: `${t('setting.teacherStatus')} - ${lang.name} (${lang.code})`,
            value: '',
            langCode: lang.code,
            type: 'text',
            labelWidth: 250,
            inputWidth: 300,
        }
    });

    const fields = [
        {
            label: t('common.code'),
            type: 'nonCryllic',
            upperCase: true,
            value: '',
            required: true,
            labelWidth: 250,
            inputWidth: 300,
            key: 'code',
        },
        {
            label: t('setting.teacherStatus'),
            type: 'text',
            value: '',
            required: true,
            labelWidth: 250,
            inputWidth: 300,
            key: 'name',
        },
        {
            type: 'jsx',
            value: <div key={'separator'} className="separator separator-dashed my-7"/>,
        },
        ...translationFields
    ];

    const onSaveClick = () => {
        const [ isValid, state, values ] = forms.current.validate();
        if (isValid) {
            const translations = [];

            for (let i = 0; i < state.length; i++) {
                const val = state[i];
                if (i >= 3) {
                    translations.push({
                        langCode: val.langCode,
                        name: val.value,
                    });
                }
            }

            const params = {
                ...values,
                translations: JSON.stringify(translations),
            };
            onSubmit(params);
        }
    };

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('setting.teacherStatus').toUpperCase()}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Forms
                    ref={forms}
                    fields={fields}
                />
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className='btn btn-link bolder'>{t('common.back')}</button>
                <Button variant='success btn-shadow' onClick={onSaveClick}>{t('common.save')}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ActiveTypeAddModal;