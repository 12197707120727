import React, { useEffect, useState } from 'react';
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { setLoading } from "../../../redux/action";
import { fetchRequest } from "../../../utils/fetchRequest";
import message from "../../modules/message";
import Select from "../../modules/Form/Select";
import { timetableAddDatas, timetableDelete } from "../../../utils/fetchRequest/Urls";
import DeleteModal from '../../modules/DeleteModal';

const TimetableEditPotokModal = ({
    onClose,
    subject,
    shift,
    onSave,
    timetables,
    editPotokId,
    name
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    const [ days, setDays ] = useState([]);
    const [ teachers, setTeachers ] = useState([]);
    const [ pTimes, setPTimes ] = useState([]);
    const [ times, setTimes ] = useState([]);
    const [ rooms, setRooms ] = useState([]);
    const [ timetableTypes, setTimetableTypes ] = useState([]);
    const [ potokId, setPotokId ] = useState(null);
    const [ potoks, setPotoks ] = useState([]);
    const [ showDeleteModal, setShowDeleteModal ] = useState(false);
    const [ selectedTimetableId, setSelectedTimetableId ] = useState(null);
    const [ selectedIndex, setSelectedIndex ] = useState(null);
    const [ isGroupCheckValue, setIsGroupCheckValue ] = useState(false);

    const [ rows, setRows ] = useState([{
        timetableId: null,
        teacher: null,
        pTime: null,
        day: null,
        time: null,
        room: null,
        timetableType: null,
        seatCount: 0,
        isMain: false,
        isGroup: false,
    }]);

    useEffect(() => {
        let object = timetables.find(tt => tt.potokId == editPotokId);

        if (object) {
            let rowObject = [];
            let potokArray = [];

            potokArray.push({
                value: object.potokId,
                text: object.potokName
            })

            if(timetables && timetables.length > 0) {
                timetables.map((obj) => {
                    if(obj.potokId == editPotokId){
                        rowObject.push({
                            timetableId: obj.timetableId,
                            teacher: obj.teacher,
                            pTime: obj.pTime,
                            day: obj.day,
                            time: obj.time,
                            room: obj.room,
                            timetableType: obj.timetableType,
                            groupId: obj.groupId,
                            shiftId: obj.shiftId,
                            seatCount: obj.seatCount,
                            isMain: obj.isMain,
                            isGroup: obj.isGroup,
                        })
                    }
                });
            }

            setRows(rowObject);
            setPotoks(potokArray);
            setPotokId(object.potokId);
            setIsGroupCheckValue(object.isGroup);
        }

        const params = {
            school: schoolId,
            schoolShift: shift,
            subject
        };
        dispatch(setLoading(true));
        fetchRequest(timetableAddDatas, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;

                    if (data?.teachers?.length) {
                        setTeachers(data.teachers);
                    }

                    if (data?.lmPTimes?.length) {
                        setPTimes(data.lmPTimes.map(pTime => {
                            return {
                                ...pTime,
                                value: pTime.id,
                                text: pTime.name,
                            }
                        }))
                    }

                    if (data?.times?.length) {
                        setTimes(data.times.map(time => {
                            let stringTime = '';
                            if (time.startTime?.date) {
                                stringTime = `${time.startTime.date.substring(11, 16)} - `;
                            }
                            if (time.endTime?.date) {
                                stringTime = `${stringTime}${time.endTime.date.substring(11, 16)}`;
                            }

                            if(time.schoolName){
                                return {
                                    ...time,
                                    value: time.id,
                                    text: stringTime + ' - ' + time.schoolName,
                                }
                            } else {
                                return {
                                    ...time,
                                    value: time.id,
                                    text: stringTime + ' (' + time.name + ')',
                                }
                            }
                            
                        }));
                    }

                    if (data?.days?.length) {
                        setDays(data.days.map(day => ({
                            ...day,
                            text: day.day,
                            value: day.id,
                        })));
                    }

                    if (data?.rooms?.length) {
                        setRooms(data.rooms.map(room => {
                            return {
                                ...room,
                                value: room.id,
                                text: room.roomNumber,
                            }
                        }));
                    }

                    if (data?.timetableTypes?.length) {
                        setTimetableTypes(data.timetableTypes.map(type => {
                            return {
                                ...type,
                                value: type.id,
                                text: type.name
                            }
                        }))
                    }

                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }, []);

    const removeRow = index => {
        const clone = [...rows];

        let isTimetable = false;
        if(clone && clone.length > 0){
            for(let i = 0; i < clone.length; i++){
                if(index == i){
                    if(clone[i].timetableId){
                        isTimetable = true;
                        setSelectedTimetableId(clone[i].timetableId)
                    }
                }
            }
        }
        
        if(isTimetable){
            setSelectedIndex(index);
            setShowDeleteModal(true);
        } else {
            clone.splice(index, 1);
            setRows(clone);
        }
    };

    const addRow = () => {
        setRows([...rows, {
            timetableId: null,
            teacher: rows && rows.length > 0 ? rows[rows.length - 1].teacher : null,
            pTime: rows && rows.length > 0 ? rows[rows.length - 1].pTime : null,
            day: rows && rows.length > 0 ? rows[rows.length - 1].day : null,
            time: rows && rows.length > 0 ? rows[rows.length - 1].time : null,
            room: rows && rows.length > 0 ? rows[rows.length - 1].room : null,
            timetableType: rows && rows.length > 0 ? rows[rows.length - 1].timetableType : null,
            seatCount: rows && rows.length > 0 ? rows[rows.length - 1].seatCount : null,
            isMain: false,
            isGroup: isGroupCheckValue ? 1 : 0
        }]);
    };

    const handleSelectChange = (key, value, index) => {
        const clone = [...rows];
        const row = { ...clone[ index ] };

        if(key == 'isMain'){
            if(clone && clone.length > 0){
                for(let i = 0; i < clone.length; i++){
                    clone[i].isMain = false;
                }
            }

            row[key] = true;
        } else {
            row[key] = value;
        }
        
        clone[index] = row;
        setRows(clone);
    };

    const onSaveClick = () => {
        let error = false;
        let errorIsMain = true;
        
        const data = rows.map((row, index) => {
            const teacher = teachers.find(t => t.value === row.teacher);
            const potok = potoks.find(t => t.value === potokId);
            const day = days.find(d => d.value === row.day);
            const pTime = pTimes.find(pt => pt.value === row.pTime);
            const room = rooms.find(r => r.value === row.room);
            const time = times.find(t => t.value === row.time);
            const timetableType = timetableTypes.find(type => type.value === row.timetableType);

            if(!potok){
                error = true;
                return message('Бүлэг сонгоно уу');
            }

            if(row.isMain){
                errorIsMain = false;
            }

            return {
                ...row,
                rowId: index + 1,
                dayName: day?.text || '-',
                pTimeName: pTime?.text || '-',
                roomName: room?.text || '-',
                timeName: time?.text || '-',
                timetableTypeName: timetableType?.text || '-',
                teacher: teacher?.value || '-',
                teacherName: teacher?.text || '-',
                capacity: room?.capacity || 0,
                potokId: potok.value,
                potokName: potok.text,
                isGroup: row.isGroup ? 1 : 0,
                name,
            }
        });

        for(let i = 0; i < data.length; i++){
            let existingData = data.find(dt => data[i].rowId != dt.rowId && data[i].day == dt.day && data[i].time == dt.time && data[i].room == dt.room && data[i].timetableType == dt.timetableType)

            if(existingData){
                return message('Мэдээлэл давхардаж байна, Давхардаж байгаа мэдээлэл (' + existingData.dayName + ' ' + existingData.timeName + ' ' + existingData.roomName + ' ' + existingData.timetableTypeName);
            }
        }

        if(data && data.length > 0){
            if(error){

            } else {
                if(errorIsMain){
                    return message('Дагуулах хичээл сонгоно уу');
                } else {
                    onSave(data);
                }
            }
        }
    };

    const handlePotokChange = val => {
        setPotokId(val);
    };

    const onModalClose = () => {
        setShowDeleteModal(false);
    };

    const onDelete = () => {
        let params = {
            school: schoolId,
            timetable: selectedTimetableId,
        };

        dispatch(setLoading(true));
        fetchRequest(timetableDelete, 'POST', params)
            .then(response => {
                if (response.success) {
                    const clone = [...rows];
                    clone.splice(selectedIndex, 1);
                    setRows(clone);
                    
                    setShowDeleteModal(false);
                } else {
                    message(response?.data?.message, false)
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            });
    };

    const onCheckBoxChange = (e) => {
        const clone = [...rows];
        if(clone && clone.length > 0){
            for(let i = 0; i < clone.length; i++){
                clone[i].isGroup = e.target.checked;
            }
        }
        
        setRows(clone);

        setIsGroupCheckValue(e.target.checked);
    };

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName='modal-90w'
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('timetable.edit').toUpperCase()}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='row' style={{ display: 'flex', alignItems: 'center' }}>
                    <div className='col-5 text-right'>
                        Поток
                    </div>
                    <div className='col-3 validated'>
                        <input
                            className={'form-control'}
                            value={name}
                            disabled={true}
                        />
                    </div>
                </div>
                <div className='row mt-3' style={{ display: 'flex', alignItems: 'center' }}>
                    <div className='col-5 text-right'>
                        {t('group.title')}
                    </div>
                    <div className='col-3 validated'>
                        <Select
                            className={'dropdown'}
                            searchable
                            options={potoks}
                            value={potokId}
                            disabled={true}
                            onChange={handlePotokChange}
                        />
                    </div>
                </div>
                <div className='row mt-3' style={{ display: 'flex', alignItems: 'center' }}>
                    <div className='col-5 text-right'>
                        Хамт сонгуулах эсэх
                    </div>
                    <div className='col-3 validated'>
                        <input
                            type='checkbox'
                            checked={isGroupCheckValue}
                            onChange={(e) => onCheckBoxChange(e)}
                        />
                    </div>
                </div>

                <table className='timetable-add-ptimes'>
                    <thead>
                        <tr>
                            <th>{t('subject.teacher')}</th>
                            <th>{t('subject.type')}</th>
                            <th>{t('common.weekday')}</th>
                            <th>{t('subject.time')}</th>
                            <th>{t('school.classroom.title')}</th>
                            <th>{t('subject.studyType')}</th>
                            <th>{t('timetable.seatCount')}</th>
                            <th>Дагуулах хичээл</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            rows && rows.length > 0 &&
                            rows.map((row, index) => {
                                return (
                                    <tr key={index}>
                                        <td className='pr-2'>
                                            <Select
                                                options={teachers}
                                                searchable
                                                onChange={val => handleSelectChange('teacher', val, index)}
                                                value={row.teacher}
                                            />
                                        </td>
                                        <td className='pr-2'>
                                            <Select
                                                options={pTimes}
                                                searchable
                                                onChange={val => handleSelectChange('pTime', val, index)}
                                                value={row.pTime}
                                            />
                                        </td>
                                        <td className='pr-2'>
                                            <Select
                                                options={days}
                                                searchable
                                                onChange={val => handleSelectChange('day', val, index)}
                                                value={row.day}
                                            />
                                        </td>
                                        <td className='pr-2'>
                                            <Select
                                                options={times}
                                                searchable
                                                onChange={val => handleSelectChange('time', val, index)}
                                                value={row.time}
                                            />
                                        </td>
                                        <td className='pr-2'>
                                            <Select
                                                searchable
                                                options={rooms}
                                                onChange={val => handleSelectChange('room', val, index)}
                                                value={row.room}
                                            />
                                        </td>
                                        <td className='pr-2'>
                                            <Select
                                                searchable
                                                options={timetableTypes}
                                                onChange={val => handleSelectChange('timetableType', val, index)}
                                                value={row.timetableType}
                                            />
                                        </td>
                                        <td className='pr-2' style={{width: 80}}>
                                            <input
                                                width={60}
                                                className={'form-control'}
                                                value={row.seatCount}
                                                onChange={(e) => handleSelectChange('seatCount', e.target.value, index)}
                                            />
                                        </td>
                                        <td className='pl-4' style={{width: 80}}>
                                            <input
                                                checked={row.isMain || false}
                                                type='radio'
                                                onChange={(e) => handleSelectChange('isMain', e.target.value, index)}
                                            />
                                        </td>
                                        <td style={{ width: 40 }}>
                                            {
                                                rows.length > 1 && (
                                                    <button className='btn btn-danger row-action-button ml-3' onClick={() => removeRow(index)}>
                                                        <i className='las la-times' style={{ fontSize: 20 }} />
                                                    </button>
                                                )
                                            }
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        <tr>
                            <td colSpan={8} className='text-right'>
                                <button className='btn btn-primary row-action-button ml-3' onClick={addRow}>
                                    <i className='las la-plus' style={{ fontSize: 20 }} />
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className='btn btn-link bolder'>{t('common.back')}</button>
                <Button variant='success btn-shadow' onClick={onSaveClick}>{t('common.save')}</Button>
            </Modal.Footer>
            {
                showDeleteModal && selectedTimetableId && (
                    <DeleteModal
                        onClose={onModalClose}
                        onDelete={onDelete}
                        title={t('warning.delete')}>
                        {t('warning.delete_confirmation')}
                        <br />
                        <br />
                        {t('warning.delete_confirmation_description')}
                    </DeleteModal>
                )
            }
        </Modal>
    );
};

export default TimetableEditPotokModal;