import React, { useEffect, useRef } from 'react';
import { Button, Modal } from "react-bootstrap";
import Forms from "../../../modules/Form/Forms";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { groupTypeEdit } from "../../../../utils/fetchRequest/Urls";
import { fetchRequest } from "../../../../utils/fetchRequest";
import { setLoading } from "../../../../redux/action";
import message from "../../../modules/message";

const GroupTypeAdd = ({
    codes,
    onClose,
    onSubmit,
    id
}) => {
    const { t } = useTranslation();
    const forms = useRef();
    const dispatch = useDispatch();
    const languages = useSelector(state => state?.languages?.languages || []).filter(lang => lang.code !== 'mn');
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const parentId = useSelector(state => state?.selectedSchool.parentId || null);

    useEffect(() => {
        if (id) {
            const params = {
                school: schoolId,
                groupType: id,
            };
            dispatch(setLoading(true));
            fetchRequest(groupTypeEdit, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res;
                        const translations = data?.groupTypeData?.translations;

                        if(translationFields && translationFields.length > 0 && translations && translations.length > 0){
                            for(let i = 0; i < translationFields.length; i++){
                                for(let t = 0; t < translations.length; t++)
                                    if(translationFields[i]['langCode'] == translations[t]['langCode']){
                                        translationFields[i]['value'] = translations[t]['name'];
                                    }
                            }
                        }

                        if (data?.groupTypeData) {
                            let selectedCode = codes.find(code => (code.text == data.groupTypeData.code));
                            forms?.current?.updateFields && forms.current.updateFields([
                                {
                                    key: 'code',
                                    label: t('common.code'),
                                    value: selectedCode.value || '',
                                    type: 'dropdown',
                                    options: codes,
                                    upperCase: true,
                                    inputWidth: 200,
                                    labelWidth: 300,
                                    required: true,
                                },
                                {
                                    label: t('setting.group.type'),
                                    value: data?.groupTypeData?.name || '',
                                    type: 'text',
                                    inputWidth: 200,
                                    labelWidth: 300,
                                    required: true,
                                    key: 'name',
                                },
                                {
                                    type: 'jsx',
                                    value: <div key={'separator'} className="separator separator-dashed my-7"/>,
                                },
                                ...translationFields
                            ]);
                        }

                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        }
    }, []);

    const translationFields = languages.map(lang => {
        return {
            key: 'language',
            label: `${t('setting.group.type')} - ${lang.name} (${lang.code})`,
            value: '',
            langCode: lang.code,
            type: 'text',
            labelWidth: 300,
            inputWidth: 200,
        }
    });

    const fields = [
        {
            key: 'code',
            label: t('common.code'),
            value: '',
            type: 'dropdown',
            options: codes,
            inputWidth: 200,
            labelWidth: 300,
            required: true,
        },
        {
            key: 'name',
            label: t('setting.group.type'),
            value: '',
            type: 'text',
            inputWidth: 200,
            labelWidth: 300,
            required: true,
        },
        // {
        //     label: t('school.isOtherSchool'),
        //     type: 'checkbox',
        //     value: true,
        //     inputWidth: 200,
        //     labelWidth: 300,
        //     hidden: parentId == null ? false : true,
        //     key: 'isSchool',
        // },
        {
            type: 'jsx',
            value: <div key={'separator'} className="separator separator-dashed my-7"/>,
        },
        ...translationFields
    ];

    const onSaveClick = () => {
        const [ isValid, states, values ] = forms.current.validate();
        if (isValid) {
            const translations = [];    
            for (let i = 0; i < states.length; i++) {
                const val = states[i];
                if (states[i].key && states[i].key == 'language') {
                    translations.push({
                        langCode: states[i].langCode,
                        name: states[i].value,
                    });
                }
            }

            if(values.isSchool){
                values.isSchool = 1;
            } else {
                values.isSchool = 0;
            }

            if(values.code){
                for(let i = 0; i < codes.length; i++){
                    if(values.code == codes[i].value){
                        values.code = codes[i].text
                    }
                }
            }

            const params = {
                ...values,
                translations: JSON.stringify(translations)
            };
            onSubmit(params);
        }
    };

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {id ? t('common.edit').toUpperCase() : t('setting.group.type').toUpperCase()}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Forms
                    ref={forms}
                    fields={fields}
                />
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className='btn btn-link bolder'>{t('common.back')}</button>
                <Button variant='success btn-shadow' onClick={onSaveClick}>{t('common.save')}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default GroupTypeAdd;