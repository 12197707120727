import { useTranslation } from "react-i18next";

export const CandidateProcessTitle = ({
  title,
  count,
  countDefault,
  status,
  givenColorCode,
  givenStatusName,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className="mt15 row"
      style={{
        width: '100%',
        marginLeft: "10px",
        marginRight: "10px",
        paddingTop: "15px",
        paddingBottom: "15px",
        fontSize: "15px",
        borderRadius: "8px",
        alignItems: "center",
        justifyContent: "space-evenly",
        backgroundColor:
          givenColorCode != null
            ? givenColorCode
            : parseInt(status) === 1
            ? "rgba(62, 191, 163, 0.5)"
            : "rgba(244, 81, 107, 0.5)",
      }}
    >
      <p style={{ textAlign: "end", color: "#fff", flex: 1, margin: "0px", fontWeight: 'bold' }}>
        {title}
      </p>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <p style={{ fontWeight: "bold", paddingLeft: "16px", margin: "0px" }}>
          {count}
        </p>
        <p style={{ fontWeight: "bold", paddingRight: "40px", margin: "0px" }}>
          {countDefault}
        </p>
      </div>
      <p
        style={{
          fontWeight: "bold",
          flex: 1,
          textTransform: "uppercase",
          margin: "0px",
          color: '#fff'
        }}
      >
        {givenStatusName != null
          ? givenStatusName
          : status === null
          ? t("enroll.failed")
          : parseInt(status) === 0
          ? "тэнцээгүй"
          : "тэнцсэн"}
      </p>
    </div>
  );
};
