import React, {useEffect, useRef, useState} from 'react'
import {Card, Button} from 'react-bootstrap'
import {Link, useHistory} from "react-router-dom";
import {setLoading} from "../../../redux/action";
import {fetchRequest} from "../../../utils/fetchRequest";
import {eventEdit} from "../../../utils/fetchRequest/Urls";
import message from "../../modules/message";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import Forms from "../../modules/Form/Forms";

export const EventEdit = ({
    location: {state: {eventId = null} = {}} = {},
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const history = useHistory();
    const [languages, setLanguages] = useState(useSelector(state => state?.languages?.languages || []).filter(lang => lang.code !== 'mn'));

    const [schools, setSchools] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [isTime, setIsTime] = useState(true);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [color, setColor] = useState(null);
    const [eventData, setEventData] = useState(null);

    const forms = useRef();

    useEffect(() => {


        let params = {
            school: schoolId,
            event: eventId
        };

        dispatch(setLoading(true));
        fetchRequest(eventEdit, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    let translationObj = data?.eventData?.translations;
                    setEventData(data?.eventData || []);
                    if(data?.schools && data?.schools.length > 0){
                        setSchools(data?.schools.map((school) => ({value: school.id, text: school.shortName})) || [])
                    }
                    setIsTime(data?.eventData && data?.eventData.startTime && data?.eventData.endTime ? true : false)

                    if(languages && languages.length > 0){
                        for(let i = 0; i < languages.length; i++){
                            if(translationObj && translationObj.length > 0){
                                for(let t = 0; t < translationObj.length; t++){
                                    if(languages[i].code == translationObj[t].langCode){
                                        languages[i].title = translationObj[t].name;
                                        languages[i].description = translationObj[t].description;
                                    }
                                }
                            } else {
                                languages[i].title = '';
                                languages[i].description = '';
                            }
                        }
                    }
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }, []);

    useEffect(() => {
        forms?.current?.updateFields && forms.current?.updateFields(fields);
    }, [schools, isTime, eventData, startDate, endDate, startTime, endTime]);

    const onDateRangeChange = (dates) => {
        if(dates && dates.length > 0){
            if(dates[0].startDate){
                setStartDate(dates[0].startDate)
            }
            if(dates[0].endDate){
                setEndDate(dates[0].endDate)
            }
        }
    };

    const onTimeRangeChange = (times) => {
        if(times && times.length > 0){
            if(times[0].startTime){
                setStartTime(times[0].startTime)
            }
            if(times[0].endTime){
                setEndTime(times[0].endTime)
            }
        }
    };

    const onNameChange = (evt) => {
        setName(evt.target.value)
    };

    const onDescriptionChange = (evt) => {
        setDescription(evt.target.value)
    };

    const onColorChange = (evt) => {
        setColor(evt.target.value)
    };

    const onCheckboxChange = (val) => {
        if(val === false){
            setStartTime(null);
            setEndTime(null);
        }
        setIsTime(val)
    };

    const fields = [
        {
            key: 'name',
            label: t('common.title'),
            name: 'title',
            value: name ? name : eventData ? eventData.name : '',
            errorMessage: '',
            required: true,
            type: 'text',
            searchable: true,
            clearable: true,
            align: 'left',
            labelWidth: 150,
            inputStyle: {maxWidth: 350},
            onChange: onNameChange,
        },
        {
            key: 'description',
            label: t('common.description'),
            name: 'description',
            value: description ? description : eventData ? eventData.description : '',
            errorMessage: '',
            required: true,
            type: 'textArea',
            searchable: true,
            clearable: true,
            align: 'left',
            labelWidth: 150,
            inputStyle: {maxWidth: 350},
            rows: 5,
            onChange: onDescriptionChange,
        },
        {
            key: 'color',
            label: t('common.color'),
            name: 'color',
            value: color ? color : eventData ? eventData.color : '',
            errorMessage: '',
            type: 'color',
            labelWidth: 150,
            inputStyle: {maxWidth: 250},
            onChange: onColorChange,
        },
        {
            key: 'dateRange',
            label: t('newsfeed.eventDates'),
            value: '',
            type: 'daterange',
            selectedStartDate: startDate ? startDate : eventData ? eventData.startDate : undefined,
            selectedEndDate: endDate ? endDate : eventData ? eventData.endDate : undefined,
            firstPlaceHolder: t('common.startDate'),
            lastPlaceHolder: t('common.endDate'),
            inputWidth: 300,
            labelWidth: 150,
            onChange: onDateRangeChange,
        },
        {
            key: 'isTime',
            label: t('newsfeed.isTime'),
            value: isTime,
            type: 'checkbox',
            inputWidth: 300,
            labelWidth: 150,
            onChange: onCheckboxChange,
        },
        {
            key: 'timeRange',
            label: t('newsfeed.eventTimes'),
            value: '',
            type: 'timerange',
            selectedStartTime: startTime ? startTime : eventData && eventData.startTime ? eventData.startTime : null,
            selectedEndTime: endTime ? endTime : eventData && eventData.endTime ? eventData.endTime : null,
            inputWidth: 300,
            labelWidth: 150,
            hidden: !isTime,
            onChange: onTimeRangeChange,
        },
    ];

    const onInputChange = (e, index, type) => {
        let cloneLanguage = [...languages];
        if(cloneLanguage && cloneLanguage.length > 0){
            if(type == 'title'){
                cloneLanguage[index].title = e.target.value;
            } else if(type == 'description') {
                cloneLanguage[index].description = e.target.value;
            }
        }
        setLanguages(cloneLanguage)
    };

    const renderTranslation = () => {
        let translationHtml = [];
        for(let i = 0; i < languages.length; i++){
            translationHtml.push(
                <div key={'language_' + i}>
                    <div style={{ display: 'flex', marginTop: '0.8rem' }}>
                        <label
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginRight : 10,
                                marginBottom: 0,
                                width: 140,
                                justifyContent: 'flex-end',
                            }}
                        >
                            {t('common.title') + ` - ${languages[i].name} (${languages[i].code})`}
                        </label>
                        <div
                            style={{
                                display: 'flex',
                                flex: 1,
                                flexDirection: 'column',
                                marginLeft: 10,
                                maxWidth: 350,
                            }}
                        >
                            <input
                                type='text'
                                className='form-control'
                                value={languages[i].title}
                                onChange={(e) => {onInputChange(e, i, 'title')}}
                                style={{border: '1px solid #919bc0'}}
                            />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flex: 0.8,
                                flexDirection: 'column',
                                marginLeft: 10,
                                width:'auto',
                            }}
                        />
                    </div>
                    <div key={'translation_' + i} style={{ display: 'flex', marginTop: '0.8rem' }}>
                        <label
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginRight : 10,
                                marginBottom: 0,
                                width: 140,
                                justifyContent: 'flex-end',
                            }}
                        >
                            {t('common.title') + ` - ${languages[i].name} (${languages[i].code})`}
                        </label>
                        <div
                            style={{
                                display: 'flex',
                                flex: 1,
                                flexDirection: 'column',
                                marginLeft: 10,
                                maxWidth: 350,
                            }}
                        >
                            <textarea
                                rows={3}
                                className='form-control'
                                value={languages[i].description}
                                onChange={(e) => {onInputChange(e, i, 'description')}}
                                style={{border: '1px solid #919bc0'}}
                            />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flex: 0.8,
                                flexDirection: 'column',
                                marginLeft: 10,
                                width:'auto',
                            }}
                        />
                    </div>
                    <div className="separator separator-dashed my-7" />
                </div>
            )
        }
        return translationHtml;
    };

    const handleSaveClick = () => {
        const [formsValid, formValues, values] = forms.current.validate();
        if(formsValid){
            let translations = [];
            if(languages && languages.length > 0){
                for(let i = 0; i < languages.length; i++){
                    translations.push({
                        id: languages[i].id,
                        langCode: languages[i].code,
                        name: languages[i].title,
                        description: languages[i].description,
                    })
                }
            }

            let selectedName = null;
            let selectedDescription = null;
            let selectedColor = null;
            let selectedStartDate = null;
            let selectedEndDate = null;
            let selectedStartTime = null;
            let selectedEndTime = null;
            if(formValues && formValues.length > 0){
                for(let i = 0; i < formValues.length; i++){
                    if(formValues[i].key == 'name'){
                        selectedName = formValues[i].value;
                    }
                    if(formValues[i].key == 'description'){
                        selectedDescription = formValues[i].value;
                    }
                    if(formValues[i].key == 'color'){
                        selectedColor = formValues[i].value;
                    }
                    if(formValues[i].key == 'dateRange'){
                        selectedStartDate = formValues[i].selectedStartDate;
                        selectedEndDate = formValues[i].selectedEndDate;
                    }
                    if(formValues[i].key == 'timeRange'){
                        selectedStartTime = formValues[i].selectedStartTime;
                        selectedEndTime = formValues[i].selectedEndTime;
                    }
                }
            }

            const params = {
                event: eventId,
                name: selectedName,
                description: selectedDescription,
                color: selectedColor,
                startDate: selectedStartDate,
                endDate: selectedEndDate,
                startTime: startTime,
                endTime: endTime,
                translations: JSON.stringify(translations),
            };

            dispatch(setLoading(true));
            fetchRequest(eventEdit, 'POST', params)
                .then(res => {
                    if (res.success) {
                        history.push('/calendar');
                    } else {
                        message(res?.data?.message);
                        dispatch(setLoading(false));
                    }
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        }
    };

    return (
        <div>
            <Card>
                <Card.Header>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <span className='french-blue fs-10'>
                            <b>{t('newsfeed.title').toUpperCase()} {t('common.add').toUpperCase()}</b>
                        </span>
                    </div>
                </Card.Header>
                <Card.Body>
                    <div className='row newfeed-style'>
                        <div className='col-12'>
                            <div className='col-12'>
                                <Forms
                                    ref={forms}
                                    fields={fields}
                                />
                            </div>
                            <p></p>
                            <div className="separator separator-dashed my-7" />
                            <div className='col-12'>
                                {
                                    languages && languages.length > 0
                                        ?
                                        renderTranslation()
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </Card.Body>
                <Card.Footer>
                    <div className='col-12 text-center'>
                        <Link
                            to='/calendar'
                            className="btn btn-link bolder"
                        >
                            {t('common.back')}
                        </Link>
                        <Button variant="success btn-shadow newsfeed-btn mr-2" onClick={() => handleSaveClick(0)}>{t('common.save')}</Button>
                    </div>
                </Card.Footer>
            </Card>
        </div>
    )
};
