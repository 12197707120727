import { useTranslation } from "react-i18next";

export const CandidateProcessInfo = ({
  data,
  titleName,
  scoreName,
  statusName,
  isAthlete = false,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className="mt15 column"
      style={{
        width: '100%',
        marginLeft: "10px",
        marginRight: "10px",
        fontSize: "15px",
        borderRadius: "8px",
        alignItems: "center",
        justifyContent: "space-evenly",
        backgroundColor: "#ffffff",
      }}
    >
      {data.map((item, i) => {
        if (
          (isAthlete && parseInt(item.isPublish) == false) ||
          (isAthlete && item.isPublish == null)
        )
          return <div key={i}></div>;
        return (
          <div
            key={i}
            style={{ display: "flex", flex: "row", marginBottom: "5px" }}
          >
            <p
              style={{
                textAlign: "end",
                color: "#185BC5",
                flex: 1,
                margin: "0px",
              }}
            >
              {item[titleName]}
            </p>
            <div
              style={{
                justifyContent: "space-between",
                display: "flex",
                marginRight: "15px",
                paddingLeft: 10,
                flex: 1,
              }}
            >
              {item[scoreName] !== null && (
                <p
                  style={{
                    fontWeight: "bold",
                    margin: "0px",
                    paddingLeft: "16px",
                    marginLeft: "15px",
                  }}
                >
                  {
                    isAthlete && <>Босго оноо: {item['genderMinScore'] !== null && parseInt(item['genderMinScore'])} <br /></>
                  }
                  Авсан оноо: {item[scoreName] !== null && parseInt(item[scoreName])}
                </p>
              )}
            </div>
            <p
              style={{
                fontWeight: "bold",
                flex: 1,
                margin: "0px",
                color: parseInt(item[statusName]) === 1 ? "#3EBFA3" : "#F4516B",
              }}
            >
              {parseInt(item[statusName]) === 1 ? "Тэнцсэн" : "Тэнцээгүй"}
            </p>
          </div>
        );
      })}
    </div>
  );
};
