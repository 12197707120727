import React, { useState, useEffect, useRef } from 'react';
import { Card, Button, ButtonToolbar, Modal, Toast } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation } from "react-router-dom";
import Forms from "../../../modules/Form/Forms";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import { subjectPriceAdd } from "../../../../utils/fetchRequest/Urls";
import { useDispatch, useSelector } from "react-redux";
import message from "../../../modules/message";

const SchoolSubjectPrice = ({ }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const selectedSchool = useSelector(state => state?.selectedSchool || []);

    const form = useRef();

    const [helperModal, setHelperModal] = useState(false);
    const [prices, setPrices] = useState([]);
    const [isHeadOfficer, setIsHeadOfficer] = useState(false);

    useEffect(() => {
        dispatch(setLoading(true));
        let params = {
            school: selectedSchool.id
        };

        fetchRequest(subjectPriceAdd, 'GET', params)
            .then(response => {
                if (response.success) {
                    const { data } = response;

                    setIsHeadOfficer(data.isHeadOfficer)
                    setPrices(data.prices || [])
                } else {
                    message(response.data.message, false)
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
            });
    }, []);

    useEffect(() => {
        form?.current?.updateFields && form.current.updateFields(forms);
    }, [isHeadOfficer, prices]);

    const forms = [
        {
            key: 'city',
            label: 'Бүрэлдэхүүн сургууль (хот)',
            value:  prices.priceCity || '',
            type: 'number',
            disabled: !isHeadOfficer
        },
        {
            key: 'city_foreign',
            label: 'Гадаад оюутан',
            value: prices.priceForeignCity || '',
            type: 'number',
            disabled: !isHeadOfficer
        },
        {
            key: 'country',
            label: 'Орон нутаг',
            value: prices.priceCountry || '',
            type: 'number',
            disabled: !isHeadOfficer
        },
        {
            key: 'country_foreign',
            label: 'Орон нутаг (Гадаад оюутан)',
            value: prices.priceForeignCountry || '',
            type: 'number',
            disabled: !isHeadOfficer
        },
        {
            key: 'magister',
            label: 'Магистр',
            value: prices.priceMaster || '',
            type: 'number',
            disabled: !isHeadOfficer
        },
        {
            key: 'doctor',
            label: 'Доктор',
            value: prices.priceDoctor || '',
            type: 'number',
            disabled: !isHeadOfficer
        },
        {
            key: 'magister_foreign',
            label: 'Магистр (Гадаад оюутан)',
            value: prices.priceForeignMaster || '',
            type: 'number',
            disabled: !isHeadOfficer
        },
        {
            key: 'doctor_foreign',
            label: 'Доктор (Гадаад оюутан)',
            value: prices.priceForeignDoctor || '',
            type: 'number',
            disabled: !isHeadOfficer
        },
        {
            key: 'description',
            label: 'Тайлбар',
            value: prices.description || '',
            type: 'textArea',
            disabled: !isHeadOfficer
        },
    ];

    const handleHelperModal = () => {
        setHelperModal(true)
    };

    const handleHelperModalClose = () => {
        setHelperModal(false)
    };

    const handlerOnSaveClick = () => {
        const [formValid, formValue] = form.current.validate();

        if (formValid) {
            const params = {
                school: selectedSchool.id,
                cityPrice: formValue?.[0]?.value,
                cityForeignPrice: formValue?.[1]?.value,
                countryPrice: formValue?.[2]?.value,
                countryForeignPrice: formValue?.[3]?.value,
                magisterPrice: formValue?.[4]?.value,
                doctorPrice: formValue?.[5]?.value,
                magisterForeignPrice: formValue?.[6]?.value,
                doctorForeignPrice: formValue?.[7]?.value,
                description: formValue?.[8]?.value,
            };

            dispatch(setLoading(true));
            fetchRequest(subjectPriceAdd, 'POST', params)
                .then(res => {
                    if (res.success) {

                    } else {
                        message(res.data.message, false)
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                })
        }
    };

    return (
        <div className='row'>
            <div className='col-12'>
                <Card>
                    <Card.Body>
                        <div className='col-12 text-right'>
                            <Button className='bg-white border-white d-inline btn-hover-less' onClick={handleHelperModal}>
                                <i className="icon-2x text-danger flaticon-questions-circular-button d-inline" />
                                <p className='text-danger d-inline'><b>{t('common.helper')}</b></p>
                            </Button>
                        </div>
                        <div className='col-12'>
                            <Forms
                                ref={form}
                                fields={forms}
                            />
                        </div>
                    </Card.Body>
                    <Card.Footer>
                        {
                            isHeadOfficer &&
                            <div className='col-12 text-center'>
                                <Button variant="success btn-shadow" onClick={handlerOnSaveClick}>{t('common.save')}</Button>
                            </div>
                        }
                    </Card.Footer>
                </Card>
            </div>
            <Modal
                show={helperModal}
                onHide={handleHelperModalClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Modal heading
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Centered Modal</h4>
                    <p>
                        Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
                        dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta
                        ac consectetur ac, vestibulum at eros.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleHelperModalClose}>{t('common.close')}</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
};

export default SchoolSubjectPrice;