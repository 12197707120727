import React, { useEffect, useState } from 'react';
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { setLoading } from "../../../redux/action";
import { fetchRequest } from "../../../utils/fetchRequest";
import message from "../../modules/message";
import Select from "../../modules/Form/Select";
import { roomSearch, timetableAddDatas } from "../../../utils/fetchRequest/Urls";
import { clone } from 'lodash';

const TimetableAddPotokModal = ({
    onClose,
    subject,
    shift,
    seasonId,
    isSelective = false,
    onSave,
    selectedPotokIds,
    isEdit,
    groupData
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [ rows, setRows ] = useState([{
        teacher: null,
        pTime: null,
        day: null,
        time: null,
        room: null,
        timetableType: null,
        seatCount: '',
        isMain: true,
        isGroup: false,
    }]);

    const [ days, setDays ] = useState([]);
    const [ teachers, setTeachers ] = useState([]);
    const [ pTimes, setPTimes ] = useState([]);
    const [ times, setTimes ] = useState([]);
    const [ rooms, setRooms ] = useState([]);
    const [ timetableTypes, setTimetableTypes ] = useState([]);
    const [ name, setName ] = useState(isEdit ? groupData.name : '');
    const [ errorPotokGroup, setErrorPotokGroup ] = useState(false);
    const [ potokId, setPotokId ] = useState(null);
    const [ potoks, setPotoks ] = useState([]);
    const [ isGroupCheckValue, setIsGroupCheckValue ] = useState(false);
    const [ roomSearchValue, setRoomSearchValue] = useState(null);

    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    useEffect(() => {
        const params = {
            school: schoolId,
            schoolShift: shift,
            season: seasonId,
            subject,
        };
        dispatch(setLoading(true));
        fetchRequest(timetableAddDatas, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;

                    if (data?.teachers?.length) {
                        setTeachers(data.teachers);
                    }

                    if (data?.lmPTimes?.length) {
                        setPTimes(data.lmPTimes.map(pTime => {
                            return {
                                ...pTime,
                                value: pTime.id,
                                text: pTime.name,
                            }
                        }))
                    }

                    if (data?.times?.length) {
                        setTimes(data.times.map(time => {
                            let stringTime = '';
                            if (time.startTime?.date) {
                                stringTime = `${time.startTime.date.substring(11, 16)}-`;
                            }
                            if (time.endTime?.date) {
                                stringTime = `${stringTime}${time.endTime.date.substring(11, 16)}`;
                            }
                            return {
                                ...time,
                                value: time.id,
                                text: stringTime + ' (' + time.name + ')',
                            }
                        }));
                    }

                    if (data?.days?.length) {
                        setDays(data.days.map(day => ({
                            ...day,
                            text: day.day,
                            value: day.id,
                        })));
                    }

                    if (data?.rooms?.length) {
                        setRooms(data.rooms.map(room => {
                            return {
                                ...room,
                                value: room.id,
                                text: room.roomNumber,
                            }
                        }));
                    }

                    if (data?.timetableTypes?.length) {
                        setTimetableTypes(data.timetableTypes.map(type => {
                            return {
                                ...type,
                                value: type.id,
                                text: type.name
                            }
                        }))
                    }

                    if(selectedPotokIds && selectedPotokIds.length > 0){
                        let potokArray = [];

                        for(let p = 0; p < data.potoks.length; p++){
                            if(!selectedPotokIds.includes(data.potoks[p].value)){
                                potokArray.push(data.potoks[p]);
                            }
                        }

                        setPotoks(potokArray);
                    } else {
                        setPotoks(data?.potoks || []);
                    }
                    
                    if(!isEdit){
                        setName(data?.name || null);
                    }
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            })
    }, []);

    const onSaveClick = () => {
        let error = false;
        let errorIsMain = true;
        
        const data = rows.map((row, index) => {
            const teacher = teachers.find(t => t.value === row.teacher);
            const potok = potoks.find(t => t.value === potokId);
            const day = days.find(d => d.value === row.day);
            const pTime = pTimes.find(pt => pt.value === row.pTime);
            const room = rooms.find(r => r.value === row.room);
            const time = times.find(t => t.value === row.time);
            const timetableType = timetableTypes.find(type => type.value === row.timetableType);

            if(!potok){
                error = true;
                return message('Бүлэг сонгоно уу');
            }

            if(row.isMain){
                errorIsMain = false;
            }

            return {
                rowId: index + 1,
                ...row,
                dayName: day?.text || '-',
                pTimeName: pTime?.text || '-',
                roomName: room?.text || '-',
                timeName: time?.text || '-',
                timetableTypeName: timetableType?.text || '-',
                timetableTypeCode: timetableType?.code || '-',
                teacher: teacher?.value || '-',
                teacherName: teacher?.text || '-',
                capacity: room?.capacity || 0,
                potokId: potok.value,
                potokName: potok.text,
                name,
            }
        });

        if(data && data.length > 0){
            if(error){

            } else {
                for(let i = 0; i < data.length; i++){
                    let existingData = data.find(dt => data[i].rowId != dt.rowId && data[i].day == dt.day && data[i].time == dt.time && data[i].room == dt.room && data[i].timetableType == dt.timetableType)
        
                    if(existingData){
                        return message('Мэдээлэл давхардаж байна, Давхардаж байгаа мэдээлэл (' + existingData.dayName + ' ' + existingData.timeName + ' ' + existingData.roomName + ' ' + existingData.timetableTypeName);
                    }
                }

                if(errorIsMain){
                    return message('Дагуулах хичээл сонгоно уу');
                } else {
                    onSave(data);
                }
            }
        }
    };

    const removeRow = index => {
        const clone = [...rows];
        clone.splice(index, 1);
        setRows(clone);
    };

    const addRow = () => {
        setRows([...rows, {
            teacher: rows && rows.length > 0 ? rows[rows.length - 1].teacher : null,
            pTime: rows && rows.length > 0 ? rows[rows.length - 1].pTime : null,
            day: rows && rows.length > 0 ? rows[rows.length - 1].day : null,
            time: rows && rows.length > 0 ? rows[rows.length - 1].time : null,
            room: rows && rows.length > 0 ? rows[rows.length - 1].room : null,
            timetableType: rows && rows.length > 0 ? rows[rows.length - 1].timetableType : null,
            seatCount: rows && rows.length > 0 ? rows[rows.length - 1].seatCount : null,
            isMain: false,
            isGroup: isGroupCheckValue ? 1 : 0
        }]);
    };

    const handleSelectChange = (key, value, index) => {
        const clone = [...rows];
        const row = { ...clone[ index ] };

        if(key == 'isMain'){
            if(clone && clone.length > 0){
                for(let i = 0; i < clone.length; i++){
                    clone[i].isMain = false;
                }
            }

            row[key] = true;
        } else {
            row[key] = value;
        }
        
        clone[index] = row;
        setRows(clone);
    };

    const handlePotokChange = val => {
        setPotokId(val);
    };

    useEffect(() => {
        let timeout;
        if (roomSearchValue) {
            timeout = setTimeout(() => {
                const params = {
                    value: roomSearchValue,
                };
                dispatch(setLoading(true));
                fetchRequest(roomSearch, 'GET', params)
                    .then(res => {
                        if (res.success) {
                            let roomList = res.data.rooms;
                            let cloneRooms = [...rooms];

                            for(let i = 0; i < roomList.length; i++){
                                if(!cloneRooms.find(room => room.id == roomList[i].id)){
                                    cloneRooms.push({
                                        value: roomList[i].id,
                                        text: roomList[i].roomNumber + ' (' + roomList[i].shortName + ')',
                                    })
                                }
                            }
                            setRooms(cloneRooms);
                        } else {
                            message(res?.data?.message || t('errorMessage.title'))
                        }
                        dispatch(setLoading(false));
                    })
                    .catch(() => {
                        dispatch(setLoading(false));
                        message(t('errorMessage.title'))
                    })
            }, 1000);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [roomSearchValue]);

    const onRoomInputChange = (inputValue, option) => {
        if(inputValue){
            setRoomSearchValue(inputValue);
        }
    };

    const onCheckBoxChange = (e) => {
        const clone = [...rows];
        if(clone && clone.length > 0){
            for(let i = 0; i < clone.length; i++){
                clone[i].isGroup = e.target.checked ? 1 : 0;
            }
        }

        setRows(clone);

        setIsGroupCheckValue(e.target.checked);
    };

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName='modal-90w'
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('timetable.add').toUpperCase()}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    <div className='row' style={{ display: 'flex', alignItems: 'center' }}>
                        <div className='col-5 text-right'>
                            Поток
                        </div>
                        <div className='col-3 validated'>
                            <input
                                className={'form-control'}
                                value={name}
                                disabled={true}
                            />
                        </div>
                    </div>
                }
                <div className='row mt-3' style={{ display: 'flex', alignItems: 'center' }}>
                    <div className='col-5 text-right'>
                        {t('group.title')}
                    </div>
                    <div className='col-3 validated'>
                        <Select
                            className={errorPotokGroup ? 'dropdown is-invalid' : 'dropdown'}
                            searchable
                            options={potoks}
                            value={potokId}
                            onChange={handlePotokChange}
                        />
                        {
                            errorPotokGroup
                            ?
                                <div className={'invalid-feedback'}>
                                    {t('timetable.selectPotok')}
                                </div>
                            : null
                        }
                    </div>
                </div>
                <div className='row mt-3' style={{ display: 'flex', alignItems: 'center' }}>
                    <div className='col-5 text-right'>
                        Хамт сонгуулах эсэх
                    </div>
                    <div className='col-3 validated'>
                        <input
                            type='checkbox'
                            checked={isGroupCheckValue}
                            onChange={(e) => onCheckBoxChange(e)}
                        />
                    </div>
                </div>
                <table className='timetable-add-ptimes'>
                    <thead>
                        <tr>
                            <th>{t('subject.teacher')}</th>
                            <th>{t('subject.type')}</th>
                            <th>{t('common.weekday')}</th>
                            <th>{t('subject.time')}</th>
                            <th>{t('school.classroom.title')}</th>
                            {
                                isSelective && (
                                    <th style={{width: '100px'}}>{t('timetable.seatCount')}</th>
                                )
                            }
                            <th>{t('subject.studyType')}</th>
                            <th>{t('timetable.seatCount')}</th>
                            <th>Дагуулах хичээл</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        rows.map((row, index) => {
                            return (
                                <tr key={index}>
                                    <td className='pr-2'>
                                        <Select
                                            options={teachers}
                                            searchable
                                            onChange={val => handleSelectChange('teacher', val, index)}
                                            value={row.teacher}
                                        />
                                    </td>
                                    <td className='pr-2'>
                                        <Select
                                            options={pTimes}
                                            searchable
                                            onChange={val => handleSelectChange('pTime', val, index)}
                                            value={row.pTime}
                                        />
                                    </td>
                                    <td className='pr-2'>
                                        <Select
                                            options={days}
                                            searchable
                                            onChange={val => handleSelectChange('day', val, index)}
                                            value={row.day}
                                        />
                                    </td>
                                    <td className='pr-2'>
                                        <Select
                                            options={times}
                                            searchable
                                            onChange={val => handleSelectChange('time', val, index)}
                                            value={row.time}
                                        />
                                    </td>
                                    <td className='pr-2'>
                                        <Select
                                            searchable
                                            value={row.room}
                                            options={rooms}
                                            onChange={val => handleSelectChange('room', val, index)}
                                            onInputChange={onRoomInputChange}
                                        />
                                    </td>
                                    <td className='pr-2'>
                                        <Select
                                            searchable
                                            options={timetableTypes}
                                            onChange={val => handleSelectChange('timetableType', val, index)}
                                            value={row.timetableType}
                                        />
                                    </td>
                                    <td className='pr-2' style={{width: 80}}>
                                        <input
                                            width={60}
                                            className={'form-control'}
                                            value={row.seatCount}
                                            onChange={(e) => handleSelectChange('seatCount', e.target.value, index)}
                                        />
                                    </td>
                                    <td className='pl-4' style={{width: 80}}>
                                        <input
                                            checked={row.isMain}
                                            type='radio'
                                            onChange={(e) => handleSelectChange('isMain', e.target.value, index)}
                                        />
                                    </td>
                                    <td style={{ width: 40 }}>
                                        {
                                            rows.length > 1 && (
                                                <button className='btn btn-danger row-action-button ml-3' onClick={() => removeRow(index)}>
                                                    <i className='las la-times' style={{ fontSize: 20 }} />
                                                </button>
                                            )
                                        }
                                    </td>
                                </tr>
                            )
                        })
                    }
                    <tr>
                        <td colSpan={8} className='text-right'>
                            <button className='btn btn-primary row-action-button ml-3' onClick={addRow}>
                                <i className='las la-plus' style={{ fontSize: 20 }} />
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className='btn btn-link bolder'>{t('common.back')}</button>
                <Button variant='success btn-shadow' onClick={onSaveClick}>{t('common.save')}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default TimetableAddPotokModal;