import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const ViewModal = ({ onClose, onEdit, onDelete, event }) => {
    const { t } = useTranslation();

    let endDate = new Date(event?.end)
    endDate.setDate(endDate.getDate() - 1);
    
    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {t('calendar.activity')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='row'>
                    <div className='col-12 d-flex justify-content-center'> 
                        <table>
                            <tbody>
                                <tr>
                                    <td className='text-right pr-3' style={{ color: '#868aa8' }}>{t('calendar.activity_name')}:</td>
                                    <td style={{ color: '#575962' }}>{event?.title || '-'}</td>
                                </tr>
                                <tr>
                                    <td className='text-right pr-3' style={{ color: '#868aa8' }}>{t('calendar.start_date')}:</td>
                                    <td style={{ color: '#575962' }}>{new Date(event?.start)?.toLocaleDateString('en-CA') || '-'}</td>
                                </tr>
                                <tr>
                                    <td className='text-right pr-3' style={{ color: '#868aa8' }}>{t('calendar.end_date')}:</td>
                                    <td style={{ color: '#575962' }}>
                                        {
                                            event?.allDay ? endDate?.toLocaleDateString('en-CA') : new Date(event?.end)?.toLocaleDateString('en-CA')
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td className='text-right pr-3' style={{ color: '#868aa8' }}>{t('calendar.length')}:</td>
                                    <td style={{ color: '#575962' }}>
                                        {
                                            event?.allDay ?
                                                t('calendar.all_day')
                                                :
                                                `${new Date(event?.start)?.toLocaleTimeString('it-IT')?.slice(0, 5)} - ${new Date(event?.end)?.toLocaleTimeString('it-IT')?.slice(0, 5)}`
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td className='text-right pr-3' style={{ color: '#868aa8' }}>{t('common.description')}:</td>
                                    <td style={{ color: '#575962' }}>{event?.description || '-'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="col-12 text-center">
                    <button onClick={onClose} className='btn btn-link bolder'>{t('common.back')}</button>
                    {/* <button
                        className="btn btn-danger mr-3"
                        onClick={onDelete}
                    >
                        {t('common.delete')}
                    </button>
                    <button
                        className="btn btn-outline-primary"
                        onClick={onEdit}
                    >
                        {t('common.edit')}
                    </button> */}
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default ViewModal