import React, {useRef, useEffect, useState} from 'react';
import {Button, Card, Modal} from "react-bootstrap";
import Forms from "../../../modules/Form/Forms";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {studentSearch, financeScholarshipInit} from "../../../../utils/fetchRequest/Urls";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import message from "../../../modules/message";
import Select from "../../../modules/Form/Select";
import RenderTableList from "../../../modules/RenderTableList";
import {LinearProgress} from "@material-ui/core";
import DTable from "../../../modules/DataTable/DTable";

const AddScholarship = ({
    onClose,
    onSubmit,
}) => {
    const { t } = useTranslation();
    const formRef = useRef();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    const [types, setTypes] = useState([]);
    const [studentId, setStudentId] = useState(null);
    const [value, setValue] = useState(null);
    const [labels, setLabels] = useState([]);
    const [students, setStudents] = useState([]);
    const [studentInfo, setStudentInfo] = useState(null);
    const [selectedTypeCodeId, setSelectedTypeCodeId] = useState(null);
    const [option, setOption] = useState([]);
    const [date, setDate] = useState(null);
    const [number, setNumber] = useState(null);
    const [seasons, setSeasons] = useState([]);
    const [selectedSeasonId, setSelectedSeasonId] = useState(null);

    const parentSeason = useSelector(state => {
        let schoolList = state?.school?.schoolList;
        if(schoolList && schoolList.length > 0){
            for (let i = 0; i < schoolList.length; i++){
                if(schoolId == schoolList[i]['id']){
                    return schoolList[i]['parentSeason'];
                }
            }
        }
    });

    useEffect(() => {
        const params = {
            school: schoolId,
            season: parentSeason,
        };
        init(params)
    }, []);

    const init = (params) => {
        dispatch(setLoading(true));
        fetchRequest(financeScholarshipInit, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    let types = data.types;
                    if(types && types.length > 0){
                        setTypes(types.map(type => ({value: type.id, text: type.name, typeCode: type.typeCode, amount: type.amount})));
                    }
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const onSeasonChange = (id, option) => {
        if(id){
            setSelectedSeasonId(id);
        }
    };

    const onTypeChange = (id, option) => {
        if(id){
            setOption(option);
            setSelectedTypeCodeId(id);
        }
    };

    const onDateChange = (date) => {
        setDate(date)
    };

    const onInputNumberChange = (evt) => {
        setNumber(evt.target.value)
    };

    useEffect(() => {
        formRef?.current?.updateFields && formRef.current?.updateFields(fields);
    }, [seasons, types, option]);

    const fields = [
        {
            label: t('subject.season'),
            value: selectedSeasonId || null,
            type: 'dropdown',
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            options: seasons,
            key: 'season',
            clearable: true,
            onChange: onSeasonChange
        },
        {
            label: t('scholarship.type'),
            value: selectedTypeCodeId || null,
            type: 'dropdown',
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            options: types,
            key: 'type',
            clearable: true,
            onChange: onTypeChange
        },
        {
            label: t('scholarship.amount'),
            type: 'inputGroup',
            groupText: option.typeCode == 'AMOUNT' || option.typeCode === 'INCONSTANT' ? '₮' : '%',
            disabled: option.typeCode == 'AMOUNT' || option.typeCode === 'PERCENT' ? true : false,
            value: option.typeCode == 'AMOUNT' || option.typeCode === 'PERCENT' ? option.amount : 0,
            inputWidth: 200,
            labelWidth: 300,
            key: 'amount',
        },
        {
            label: t('scholarship.date'),
            value: date || null,
            type: 'date',
            dateCustomButton: false,
            inputWidth: 200,
            labelWidth: 300,
            key: 'orderDate',
            clearable: true,
            required: true,
            onChange: onDateChange
        },
        {
            label: t('scholarship.number'),
            value: number || '',
            type: 'text',
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            key: 'orderNo',
            onChange: onInputNumberChange
        },
    ];

    useEffect(() => {
        let timeout;
        if (value) {
            timeout = setTimeout(() => {
                const params = {
                    school: schoolId,
                    season: parentSeason,
                    code: value,
                    scholarship: 1
                };
                dispatch(setLoading(true));
                fetchRequest(studentSearch, 'GET', params)
                    .then(res => {
                        if (res.success) {
                            const { data } = res;
                            let students = data.students;
                            if(students && students.length > 0){
                                for(let i = 0; i < students.length; i++){
                                    if(students[i].startDate){
                                        students[i].startDate = students[i].startDate.date.substring(0, 10)
                                    }
                                }
                            }
                            setStudents(students.map(student => ({value: student.id, text: student.firstName, student: student})));
                        } else {
                            message(res?.data?.message || t('errorMessage.title'))
                        }
                        dispatch(setLoading(false));
                    })
                    .catch(() => {
                        dispatch(setLoading(false));
                        message(t('errorMessage.title'))
                    })
            }, 1000);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [value]);

    const onStudentChange = (value) => {
        let studentInfo = [];
        if(students && students.length > 0){
            for(let i = 0; i < students.length; i++){
                if(value == students[i].value){
                    studentInfo = students[i].student;
                    let seasons = students[i]?.student?.seasons;
                    setSeasons(seasons.map(season => ({value: season.id, text: season.name})));
                }
            }
        }

        setStudentId(value);
        let labelArray = [];
        labelArray.push(
            {
                code: 'studentCode',
                name: t('student.code'),
                value: studentInfo?.code || ''
            },
            {
                code: 'lastName',
                name: t('student.lastName'),
                value: studentInfo?.lastName || ''
            },
            {
                code: 'firstName',
                name: t('student.name'),
                value: studentInfo?.firstName || ''
            },
            {
                code: 'createdDate',
                name: t('student.enrollmentDate'),
                value: studentInfo?.startDate || ''
            },
            {
                code: 'programName',
                name: t('curriculum.profession'),
                value: studentInfo?.programName || ''
            }
        );
        setLabels(labelArray);
    };

    const onStudentInputChange = (inputValue, option) => {
        if(inputValue){
            setValue(inputValue);
        }
    };

    const onSaveClick = () => {
        const [ isValid, states, values ] = formRef.current.validate();
        if (isValid) {
            const params = {
                ...values,
            };
            params.student = studentId;
            onSubmit(params);
        }
    };

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('scholarship.title').toUpperCase()}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='row'>
                    <div className='col-12' style={{display: 'flex', marginTop: 0.8}}>
                        <span style={{ flex: 4, textAlign: 'right', paddingRight: '1.5rem' }}>
                            <div style={{ width: 300, position: "relative", top: 8}}>
                                {t('student.title')}
                            </div>
                        </span>

                        <div style={{ flex: 8 }}>
                            <div style={{ width: 200 }}>
                                <Select
                                    className={'default-input-border'}
                                    value={studentId}
                                    searchable
                                    onChange={onStudentChange}
                                    onInputChange={onStudentInputChange}
                                    options={students}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12 ds-flex mt-6 mb-3'>
                        <div style={{ flex: 12 }}>
                            <RenderTableList
                                stringArray={labels}
                            />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <Forms
                            ref={formRef}
                            fields={fields}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className='btn btn-link bolder'>{t('common.back')}</button>
                <Button variant='success btn-shadow' onClick={onSaveClick}>{t('common.save')}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddScholarship;