import React,{ useState,useRef,useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import Forms from '../../../modules/Form/Forms'
import {useTranslation} from "react-i18next";
import {setLoading} from "../../../../redux/action";
import {fetchRequest} from "../../../../utils/fetchRequest";
import {studentRequestEdit} from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import {useDispatch, useSelector} from "react-redux";
export const EditModal = ({
    requestId,
    close,
    onSubmit
}) => {
    const formRef = useRef();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [requestTypes, setRequestTypes] = useState([]);
    const [request, setRequest] = useState(null);

    useEffect(() => {
        let params = {
            school: schoolId,
            id: requestId
        };

        dispatch(setLoading(true));
        fetchRequest(studentRequestEdit, 'GET', params)
            .then(res => {
                dispatch(setLoading(false));
                if (res.success) {
                    const data = res.data;
                    if(data.requestTypes && data.requestTypes.length > 0){
                        setRequestTypes(data.requestTypes.map((type) => ({value: type.id, text: type.name})))
                    }
                    setRequest(data?.request || []);
                } else {
                    message(res?.data?.message || t('errorMessage.title'));
                }
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            })
    }, []);

    useEffect(() => {
        formRef?.current?.updateFields && formRef.current?.updateFields(fields);
    }, [requestTypes, request]);

    const fields = [
        {
            key: 'requestType',
            label: t('common.requestType'),
            value: request?.requestType || null,
            type: 'dropdown',
            options: requestTypes,
            inputWidth: '300px',
            labelWidth: '30%',
            required: true,
            clearable: true
        },
        {
            key: 'content',
            label: t('common.cause'),
            value: request?.content || '',
            type: 'textArea',
            inputWidth: '300px',
            labelWidth: '30%',
            rows: 5,
            required: true,
        },
        {
            key: 'contact',
            label: t('common.phoneNumber'),
            value: request?.contact || '',
            type: 'numberOnly',
            inputWidth: '300px',
            labelWidth: '30%',
            inputClassName: 'gray-background-phone-number-input',
            required: true,
            disabled: false
        },
    ];

    const onSave = () =>{
        const [isValid, states, values] = formRef.current.validate();
        if(isValid){
            const params = {
                id: requestId,
                ...values
            };

            onSubmit(params);
        }
    };

    return (
        <Modal
            show={true}
            onHide={close}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('common.edit').toUpperCase()}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Forms
                    ref={formRef}
                    fields={fields}
                />
            </Modal.Body>
            <Modal.Footer>
                <button className='btn btn-link' onClick={close}>{t('common.back')}</button>
                <button className='btn btn-success' onClick={onSave}>{t('common.send')}</button>
            </Modal.Footer>
        </Modal>
    )
};
