import React, {useEffect, useState} from 'react'
import {Button, Card, Modal} from 'react-bootstrap'
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import DTable from "../../../../modules/DataTable/DTable";
import {setLoading} from "../../../../../redux/action";
import {fetchRequest} from "../../../../../utils/fetchRequest";
import {bankInfoCreate, bankInfoDelete, bankInfoInit, bankInfoEdit} from "../../../../../utils/fetchRequest/Urls";
import message from "../../../../modules/message";
import AddAccount from "./add";
import EditAccount from "./edit";

export const Account = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [banks, setBanks] = useState([]);
    const [bankId, setBankId] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false);

    useEffect(() => {
        const params = {
            school: schoolId,
        };
        init(params)
    }, []);

    const init = (params) => {
        dispatch(setLoading(true));
        fetchRequest(bankInfoInit, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setBanks(data.banks)
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const handleAddClick = () => {
        setShowAddModal(true);
    };

    const config = {
        showPagination: true,
        showFilter: true,
    };

    const columns = [
        {
            dataField: "isCgwEnabled",
            text: '',
            headerStyle: () => ({
                width: 80,
            }),
            style: {textAlign: 'center'},
            formatter: (cell, row) => {
                if(cell === true){
                    return (
                        <div><span><i className="icon-1_5x main-green fa fa-circle"/></span></div>
                    )
                } else {
                    return (
                        <div><span><i className="icon-1_5x text-dark-50 fa fa-circle"/></span></div>
                    )
                }
            }
        },
        {
            dataField: "bankCode",
            text: t('finance.bankCode'),
            sort: true
        },
        {
            dataField: "bankName",
            text: t('finance.bank'),
            sort: true
        },
        {
            dataField: "accountNumber",
            text: t('finance.account'),
            style: {textAlign: 'right'},
            sort: true
        },
        {
            dataField: "accountName",
            text: t('finance.accountName'),
            sort: true
        },
        // {
        //     dataField: "currency",
        //     text: t('finance.currency'),
        //     sort: true
        // },
        {
            dataField: "schoolName",
            text: t('school.title'),
            sort: true
        },
        {
            dataField: "qpayInvoiceCode",
            text: t('finance.qPayCode'),
            sort: true
        },
        {
            dataField: "merchantUsername",
            text: t('finance.merchantName'),
            sort: true
        },
        {
            dataField: "merchantPassword",
            text: t('finance.merchantCode'),
            sort: true
        },
    ];

    const contextMenus = [
        {
            key: 'edit',
            title: t('common.edit'),
            icon: <i className='las la-edit dt-cm-item-icon'/>
        },
        {
            key: 'delete',
            title: t('common.delete'),
            icon: <i className='las la-trash-alt dt-cm-item-icon' />,
        },
    ];

    const handleContextMenuClick = (id, key) => {
        if (id && key) {
            if (key === 'edit') {
                setShowEditModal(true);
            } else if (key === 'delete') {
                setDeleteModal(true);
            }
            setBankId(id);
        }
    };

    const onModalClose = () => {
        setShowAddModal(false);
        setShowEditModal(false);
    };

    const onSubmit = params => {
        const bodyParams = {
            ...params,
        };

        dispatch(setLoading(true));
        fetchRequest(bankInfoCreate, 'POST', bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    message(data?.message || t('common.success'), true);
                    onModalClose();
                    setBanks(data.banks)
                } else {
                    message(res?.data?.message || t('errorMessage.title'));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const onSubmitEdit = params => {
        const bodyParams = {
            id: bankId,
            ...params,
        };

        dispatch(setLoading(true));
        fetchRequest(bankInfoEdit, 'POST', bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    message(data?.message || t('common.success'), true);
                    onModalClose();
                    setBanks(data.banks)
                } else {
                    message(res?.data?.message || t('errorMessage.title'));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const handleDeleteModalClose = () => {
        setDeleteModal(false);
    };

    const handlerOnDeleteClick = () => {
        const params = {
            school: schoolId,
            id: bankId,
        };

        dispatch(setLoading(true));
        fetchRequest(bankInfoDelete, 'POST', params, false)
            .then(response => {
                if (response.success) {
                    setDeleteModal(false);
                    setBanks(response.data.banks);
                    message(response.data.message, true);
                } else {
                    message(response.data.message, false);
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    return (
        <div className='sm-container'>
            <Card>
                <Card.Body>
                    <div className='row'>
                        <div className='col-12'>
                            <button
                                className='btn btn-primary'
                                onClick={handleAddClick}
                            >
                                {t('common.add')}
                            </button>
                        </div>
                        <div className='col-12'>
                            <DTable
                                columns={columns}
                                config={config}
                                data={banks}
                                contextMenus={contextMenus}
                                onContextMenuItemClick={handleContextMenuClick}
                                selectMode={'radio'}
                            />
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <Modal
                show={deleteModal}
                onHide={handleDeleteModalClose}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t('warning.delete')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {t('warning.delete_confirmation')}
                        <br/>
                        <br/>
                        {t('warning.delete_confirmation_description')}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="btn btn-link bolder" onClick={handleDeleteModalClose}>{t('common.cancel')}</Button>
                    <Button variant="success btn-shadow" onClick={handlerOnDeleteClick}>{t('warning.delete')}</Button>
                </Modal.Footer>
            </Modal>
            {
                showAddModal && (
                    <AddAccount
                        onClose={onModalClose}
                        onSubmit={onSubmit}
                    />
                )
            }
            {
                showEditModal && (
                    <EditAccount
                        bankId={bankId}
                        onClose={onModalClose}
                        onSubmit={onSubmitEdit}
                    />
                )
            }
        </div>
    )
};
