import React, {useRef, useEffect, useState} from 'react';
import { Button, Modal } from "react-bootstrap";
import Forms from "../../../../modules/Form/Forms";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {bankInfoEdit} from "../../../../../utils/fetchRequest/Urls";
import { setLoading } from "../../../../../redux/action";
import { fetchRequest } from "../../../../../utils/fetchRequest";
import message from "../../../../modules/message";

const EditAccount = ({
    bankId,
    onClose,
    onSubmit,
}) => {
    const { t } = useTranslation();
    const formRef = useRef();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [schools, setSchools] = useState([]);
    const [bankData, setBankData] = useState([]);

    useEffect(() => {
        let params = {
            id: bankId
        };

        dispatch(setLoading(true));
        fetchRequest(bankInfoEdit, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    if(data.schools && data.schools.length > 0){
                        setSchools(data.schools.map(school => ({value: school.id, text: school.code + ' - ' + school.shortName})));
                    }
                    setBankData(data.bankData);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })

    }, []);

    useEffect(() => {
        formRef?.current?.updateFields && formRef.current?.updateFields(fields);
    }, [schools, bankData]);

    const fields = [
        {
            label: t('school.title'),
            value: bankData.schoolId || null,
            type: 'dropdown',
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            options: schools,
            key: 'school',
            clearable: true
        },
        // {
        //     label: t('finance.bankCode'),
        //     value: '',
        //     type: 'text',
        //     inputWidth: 200,
        //     labelWidth: 300,
        //     required: true,
        //     key: 'bankCode',
        // },
        {
            label: t('finance.bankName'),
            value: bankData.bankName || '',
            type: 'text',
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            key: 'bankName',
        },
        {
            label: t('finance.accountNumber'),
            value: bankData.accountNumber || '',
            type: 'text',
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            key: 'accountNumber',
        },
        {
            label: t('finance.accountName'),
            value: bankData.accountName || '',
            type: 'text',
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            key: 'accountName',
        },
        {
            label: t('finance.qPayCode'),
            value: bankData.qpayInvoiceCode || '',
            type: 'text',
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            key: 'qPayCode',
        },
        {
            label: t('finance.isCorporateText'),
            value: bankData.isCgwEnabled || '',
            type: 'checkbox',
            inputWidth: 200,
            labelWidth: 300,
            key: 'isCgwEnabled',
        },
        {
            label: t('finance.isQpayText'),
            value: bankData.qpayEnabled || '',
            type: 'checkbox',
            inputWidth: 200,
            labelWidth: 300,
            key: 'isQpayEnabled',
        },
        {
            label: t('finance.merchantName'),
            value: bankData.merchantName || '',
            type: 'text',
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            key: 'merchantName',
        },
        {
            label: t('finance.merchantCode'),
            value: bankData.merchantCode || '',
            type: 'text',
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            key: 'merchantCode',
        },
    ];

    const onSaveClick = () => {
        const [ isValid, states, values ] = formRef.current.validate();
        if (isValid) {
            const params = {
                ...values,
            };
            onSubmit(params);
        }
    };

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('finance.addAccount').toUpperCase()}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Forms
                    ref={formRef}
                    fields={fields}
                />
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className='btn btn-link bolder'>{t('common.back')}</button>
                <Button variant='success btn-shadow' onClick={onSaveClick}>{t('common.create')}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditAccount;