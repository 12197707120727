import React, { useEffect, useState } from 'react';
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { setLoading } from "../../../redux/action";
import { fetchRequest } from "../../../utils/fetchRequest";
import message from "../../modules/message";
import Select from "../../modules/Form/Select";
import { timetableAddDatas } from "../../../utils/fetchRequest/Urls";

const TimetableEditModal = ({
    onClose,
    subject,
    shift,
    isSelective = false,
    onSave,
    timetables,
    index,
    nameIndex,
    groupId,
    groupData
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    const [ days, setDays ] = useState([]);
    const [ dayId, setDayId ] =  useState([]);
    const [ teachers, setTeachers ] = useState([]);
    const [ teacherId, setTeacherId ] = useState(null);
    const [ pTimes, setPTimes ] = useState([]);
    const [ pTimeId, setPTimeId ] = useState(null);
    const [ times, setTimes ] = useState([]);
    const [ timeId, setTimeId ] = useState(null);
    const [ rooms, setRooms ] = useState([]);
    const [ roomId, setRoomId ] = useState(null);
    const [ timetableTypes, setTimetableTypes ] = useState([]);
    const [ timetableTypeId, setTimetableTypeId ] = useState(null);
    const [ name, setName ] = useState('');
    const [ seatCount, setSeatCount ] = useState(groupData ? groupData?.seatCount : '');
    const [ errorName, setErrorName ] = useState(false);
    const [ errorTeacher, setErrorTeacher ] = useState(false);

    const [ rows, setRows ] = useState([{
        pTime: null,
        day: null,
        time: null,
        room: null,
        timetableType: null,
    }]);

    useEffect(() => {
        let object = null;
        if(isSelective){
            object = timetables[index];
        } else {
            object = timetables.find(tt => tt.index == index);
        }

        if (object) {
            if(isSelective){
                setDayId(object.day || null);
                setPTimeId(object.pTime || null);
                setTimeId(object.time || null);
                setRoomId(object.room || null);
                setSeatCount(object.seatCount || 0);
                setTimetableTypeId(object.timetableType || null);
                setTeacherId(object.teacher);
            } else {
                // let filterObjects = timetables.filter(tt => tt.name === object.name && tt.groupId === groupId);
                let rowObject = [];
                if(timetables && timetables.length > 0) {
                    timetables.map((obj) => {
                        if(obj.name == object.name){
                            rowObject.push({
                                pTime: obj.pTime,
                                day: obj.day,
                                time: obj.time,
                                room: obj.room,
                                timetableType: obj.timetableType,
                                groupId: obj.groupId,
                                shiftId: obj.shiftId
                            })
                        }
                    });
                }
                setName(object.name);
                //setGroupId(object.groupId);
                setSeatCount(object.seatCount);
                setTeacherId(object.teacher);
                setRows(rowObject);
            }
        }

        const params = {
            school: schoolId,
            schoolShift: shift,
            subject
        };
        dispatch(setLoading(true));
        fetchRequest(timetableAddDatas, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;

                    if (data?.teachers?.length) {
                        setTeachers(data.teachers);
                    }

                    if (data?.lmPTimes?.length) {
                        setPTimes(data.lmPTimes.map(pTime => {
                            return {
                                ...pTime,
                                value: pTime.id,
                                text: pTime.name,
                            }
                        }))
                    }

                    if (data?.times?.length) {
                        setTimes(data.times.map(time => {
                            let stringTime = '';
                            if (time.startTime?.date) {
                                stringTime = `${time.startTime.date.substring(11, 16)} - `;
                            }
                            if (time.endTime?.date) {
                                stringTime = `${stringTime}${time.endTime.date.substring(11, 16)}`;
                            }

                            if(time.schoolName){
                                return {
                                    ...time,
                                    value: time.id,
                                    text: stringTime + ' - ' + time.schoolName,
                                }
                            } else {
                                return {
                                    ...time,
                                    value: time.id,
                                    text: stringTime + ' (' + time.name + ')',
                                }
                            }
                        }));
                    }

                    if (data?.days?.length) {
                        setDays(data.days.map(day => ({
                            ...day,
                            text: day.day,
                            value: day.id,
                        })));
                    }

                    if (data?.rooms?.length) {
                        setRooms(data.rooms.map(room => {
                            return {
                                ...room,
                                value: room.id,
                                text: room.roomNumber,
                            }
                        }));
                    }

                    if (data?.timetableTypes?.length) {
                        setTimetableTypes(data.timetableTypes.map(type => {
                            return {
                                ...type,
                                value: type.id,
                                text: type.name
                            }
                        }))
                    }
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }, []);

    const handleTeacherChange = val => {
        setTeacherId(val);
    };

    const removeRow = index => {
        const clone = [...rows];
        clone.splice(index, 1);
        setRows(clone);
    };

    const addRow = () => {
        setRows([...rows, {
            pTime: rows && rows.length > 0 ? rows[rows.length - 1].pTime : null,
            day: rows && rows.length > 0 ? rows[rows.length - 1].day : null,
            time: rows && rows.length > 0 ? rows[rows.length - 1].time : null,
            room: rows && rows.length > 0 ? rows[rows.length - 1].room : null,
            timetableType: rows && rows.length > 0 ? rows[rows.length - 1].timetableType : null,
        }]);
    };

    const handleSelectChange = (key, value, index) => {
        const clone = [...rows];
        const row = { ...clone[ index ] };
        row[key] = value;
        clone[index] = row;
        setRows(clone);
    };

    const onSaveClick = () => {
        const teacher = teachers.find(teacher => teacher.value == teacherId);

        if(isSelective){
            const day = days.find(d => d.value === dayId);
            const pTime = pTimes.find(pt => pt.value === pTimeId);
            const room = rooms.find(r => r.value === roomId);
            const time = times.find(t => t.value === timeId);
            const timetableType = timetableTypes.find(type => type.value === timetableTypeId);
            const clone = [...timetables];
            
            clone[index] = {
                ...clone[index],
                day: day?.id || null,
                dayName: day?.text || '-',
                pTime: pTime?.id || null,
                pTimeName: pTime?.text || '-',
                room: room?.id || null,
                roomName: room?.text || '-',
                teacher: teacher?.value || '-',
                teacherName: teacher?.text || '-',
                time: time?.id || null,
                timeName: time?.text || '-',
                timetableType: timetableType?.id || null,
                timetableTypeName: timetableType?.text || '-',
                capacity: room?.capacity || 0,
                seatCount: seatCount
            };

            onSave(clone);
        } else {
            //setName(timetables.name);
            if(!name){
                setErrorName(true);
                message(t('errorMessage.enterGroup'));
                return;
            } else {
                setErrorName(false);
            }
            setErrorName(false);
            if (!teacher){
                setErrorTeacher(true);
                message(t('timetable.selectTeacher'));
                return;
            } else {
                setErrorTeacher(false);
            }

            if(!seatCount){
                setErrorName(true);
                message(t('errorMessage.enterSeat'));
                return;
            } else {
                setErrorName(false);
            }

            const datas = rows.map((row, index) => {
                const day = days.find(d => d.value == row.day);
                const pTime = pTimes.find(pt => pt.value == row.pTime);
                const room = rooms.find(r => r.value == row.room);
                const time = times.find(t => t.value == row.time);
                const timetableType = timetableTypes.find(type => type.value == row.timetableType);
                
                var gid = timetables[index] ? timetables[index].groupId : null;
                var ttid = timetables[index] ? timetables[index].timetableId : null;
                
                if(gid == null && index>0 && timetables[index-1]){
                    gid = timetables[index-1].groupId;
                }
                //setName(timetables.name);
                return {
                    ...row,
                    //timetables: timetables,
                    index: index,
                    rowId: index + 1,
                    timetableId: ttid,
                    //index: timetables.length + index,
                    dayName: day?.text || '-',
                    pTimeName: pTime?.text || '-',
                    roomName: room?.text || '-',
                    timeName: time?.text || '-',
                    timetableTypeName: timetableType?.text || '-',
                    teacher: teacher?.value || '-',
                    teacherName: teacher?.text || '-',
                    capacity: room?.capacity || 0,
                    name,
                    groupId: gid,
                    seatCount,
                    // teacher,
                }
            });

            for(let i = 0; i < datas.length; i++){
                let existingData = datas.find(dt => datas[i].rowId != dt.rowId && datas[i].day == dt.day && datas[i].time == dt.time && datas[i].room == dt.room && datas[i].timetableType == dt.timetableType)
    
                if(existingData){
                    return message('Мэдээлэл давхардаж байна, Давхардаж байгаа мэдээлэл (' + existingData.dayName + ' ' + existingData.timeName + ' ' + existingData.roomName + ' ' + existingData.timetableTypeName);
                }
            }
            
            onSave(datas);
        }
    };

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName='modal-90w'
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('timetable.edit').toUpperCase()}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    isSelective
                    ?
                    <>
                        <div className='row mt-3' style={{ display: 'flex', alignItems: 'center' }}>
                            <div className='col-5 text-right'>
                                {t('teacher.title')}
                            </div>
                            <div className='col-3 validated'>
                                <Select
                                    className={errorTeacher ? 'dropdown is-invalid' : 'dropdown'}
                                    searchable
                                    options={teachers}
                                    value={teacherId}
                                    onChange={handleTeacherChange}
                                />
                                {
                                    errorTeacher
                                    ?
                                        <div className={'invalid-feedback'}>
                                            {t('timetable.selectTeacher')}
                                        </div>
                                    : null
                                }
                            </div>
                        </div>
                        <table className='timetable-add-ptimes'>
                            <thead>
                            <tr>
                                <th>{t('subject.type')}</th>
                                <th>{t('common.weekday')}</th>
                                <th>{t('subject.time')}</th>
                                <th>{t('school.classroom.title')}</th>
                                <th style={{width: 100}}>{t('timetable.seatCount')}</th>
                                <th>{t('subject.studyType')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td className='pr-2'>
                                    <Select
                                        options={pTimes}
                                        searchable
                                        onChange={val => setPTimeId(val)}
                                        value={pTimeId}
                                    />
                                </td>
                                <td className='pr-2'>
                                    <Select
                                        options={days}
                                        searchable
                                        onChange={val => setDayId(val)}
                                        value={dayId}
                                    />
                                </td>
                                <td className='pr-2'>
                                    <Select
                                        options={times}
                                        searchable
                                        onChange={val => setTimeId(val)}
                                        value={timeId}
                                    />
                                </td>
                                <td className='pr-2'>
                                    <Select
                                        searchable
                                        options={rooms}
                                        onChange={val => setRoomId(val)}
                                        value={roomId}
                                    />
                                </td>
                                <td className='pr-2'>
                                    <input
                                        style={{border: '1px solid hsl(0deg 0% 80%)'}}
                                        className='form-control'
                                        value={seatCount}
                                        onChange={(e) => setSeatCount(e.target.value)}
                                    />
                                </td>
                                <td className='pr-2'>
                                    <Select
                                        searchable
                                        options={timetableTypes}
                                        onChange={val => setTimetableTypeId(val)}
                                        value={timetableTypeId}
                                    />
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </>
                    :
                        <>
                            {/*<div className='row' style={{ display: 'flex', alignItems: 'center' }}>*/}
                            {/*    <div className='col-5 text-right'>*/}
                            {/*        {t('group.title')}*/}
                            {/*    </div>*/}
                            {/*    <div className='col-3 validated'>*/}
                            {/*        <input*/}
                            {/*            className={errorName ? 'form-control is-invalid' : 'form-control'}*/}
                            {/*            value={name}*/}
                            {/*            onChange={e => {*/}
                            {/*                setName(e.target.value)*/}
                            {/*            }}*/}
                            {/*        />*/}
                            {/*        {*/}
                            {/*            errorName*/}
                            {/*                ?*/}
                            {/*                <div className={'invalid-feedback'}>*/}
                            {/*                    {t('errorMessage.enterGroup')}*/}
                            {/*                </div>*/}
                            {/*                : null*/}
                            {/*        }*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className='row mt-3' style={{ display: 'flex', alignItems: 'center' }}>
                                <div className='col-5 text-right'>
                                    {t('teacher.title')}
                                </div>
                                <div className='col-3 validated'>
                                    <Select
                                        className={errorTeacher ? 'dropdown is-invalid' : 'dropdown'}
                                        searchable
                                        options={teachers}
                                        value={teacherId}
                                        onChange={handleTeacherChange}
                                    />
                                    {
                                        errorTeacher
                                            ?
                                            <div className={'invalid-feedback'}>
                                                {t('timetable.selectTeacher')}
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                            <div className='row mt-3' style={{ display: 'flex', alignItems: 'center' }}>
                                <div className='col-5 text-right'>
                                    {t('timetable.seatCount')}
                                </div>
                                <div className='col-3 validated'>
                                    <input
                                        className={errorName ? 'form-control is-invalid' : 'form-control'}
                                        value={seatCount}
                                        onChange={e => {
                                            setSeatCount(e.target.value)
                                        }}
                                    />
                                    {
                                        errorName
                                            ?
                                            <div className={'invalid-feedback'}>
                                                {t('errorMessage.enterSeat')}
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                            <table className='timetable-add-ptimes'>
                                <thead>
                                <tr>
                                    <th>{t('subject.type')}</th>
                                    <th>{t('common.weekday')}</th>
                                    <th>{t('subject.time')}</th>
                                    <th>{t('school.classroom.title')}</th>
                                    <th>{t('subject.studyType')}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    rows && rows.length > 0 &&
                                    rows.map((row, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className='pr-2'>
                                                    <Select
                                                        options={pTimes}
                                                        searchable
                                                        onChange={val => handleSelectChange('pTime', val, index)}
                                                        value={row.pTime}
                                                    />
                                                </td>
                                                <td className='pr-2'>
                                                    <Select
                                                        options={days}
                                                        searchable
                                                        onChange={val => handleSelectChange('day', val, index)}
                                                        value={row.day}
                                                    />
                                                </td>
                                                <td className='pr-2'>
                                                    <Select
                                                        options={times}
                                                        searchable
                                                        onChange={val => handleSelectChange('time', val, index)}
                                                        value={row.time}
                                                    />
                                                </td>
                                                <td className='pr-2'>
                                                    <Select
                                                        searchable
                                                        options={rooms}
                                                        onChange={val => handleSelectChange('room', val, index)}
                                                        value={row.room}
                                                    />
                                                </td>
                                                <td className='pr-2'>
                                                    <Select
                                                        searchable
                                                        options={timetableTypes}
                                                        onChange={val => handleSelectChange('timetableType', val, index)}
                                                        value={row.timetableType}
                                                    />
                                                </td>
                                                <td style={{ width: 40 }}>
                                                    {
                                                        rows.length > 1 && (
                                                            <button className='btn btn-danger row-action-button ml-3' onClick={() => removeRow(index)}>
                                                                <i className='las la-times' style={{ fontSize: 20 }} />
                                                            </button>
                                                        )
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                <tr>
                                    <td colSpan={6} className='text-right'>
                                        <button className='btn btn-primary row-action-button ml-3' onClick={addRow}>
                                            <i className='las la-plus' style={{ fontSize: 20 }} />
                                        </button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </>
                }
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className='btn btn-link bolder'>{t('common.back')}</button>
                <Button variant='success btn-shadow' onClick={onSaveClick}>{t('common.save')}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default TimetableEditModal;