import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Button, Card, ButtonToolbar, Modal } from "react-bootstrap";
import DTable from "../../../modules/DataTable/DTable";
import { fetchRequest } from "../../../../utils/fetchRequest";
import { setLoading } from "../../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { studentSelectionInit, AddSubjectStudentSelection } from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import { cloneDeep } from "lodash";

const SelectionOne = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [ helperModal, setHelperModal ] = useState(false);
    const [ addModal, setAddModal ] = useState(false);
    const [ deleteModal, setDeleteModal ] = useState(false);
    const [ selectionData, setSelectionData ] = useState({});
    const [ programDetails, setProgramDetails ] = useState([]);
    const [ selectedIds, setSelectedIds ] = useState([]);
    const [ selectedDetails, setSelectedDetails ] =  useState([]);
    const [ hasPenalty, setHasPenalty ] = useState(false);
    const [ hasConfig, setHasConfig ] = useState(false);
    const [ approved, setApproved ] = useState(false);
    const [ canSelect, setCanSelect ] = useState(false);
    const [ totalCredit, setTotalCredit ] = useState(null);
    const [ totalCreditPrice, setTotalCreditPrice ] = useState(null);

    const [updateView, setUpdateView] = useState(false);

    useEffect(() => {
        const params = {
            school: schoolId,
            selection: 1
        };
        dispatch(setLoading(true));
        fetchRequest(studentSelectionInit, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;

                    setHasPenalty(!!data.hasPenalty);
                    setApproved(!!data.approved);
                    setCanSelect(!!data.canSelect);
                    setHasConfig(!!data.hasConfig);

                    if (data.selectionData) {
                        setSelectionData(data.selectionData);

                        if (data.selectionData.subjects?.length) {
                            setSelectedDetails(data.selectionData.subjects);

                            let credits = 0;
                            let price = 0;

                            for(let i = 0; i < data.selectionData.subjects.length; i++)
                            {
                                credits += parseInt(data.selectionData.subjects[i].credit);
                                price += parseInt(data.selectionData.subjects[i].price);
                            }

                            setTotalCredit(credits);
                            setTotalCreditPrice(price);
                        }
                    }

                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            })
    }, []);

    const handlerIcon = (action, content, row, rowIndex) => {
        let updatedList = selectedDetails;
        updatedList.splice(rowIndex, 1);
        let credits = 0;
        let price = 0;
        if(updatedList && updatedList.length)
        {
            for(let i = 0; i < updatedList.length; i++)
            {
                credits += parseInt(updatedList[i].credit);
                price += parseInt(updatedList[i].price);
            }
        }

        setTotalCredit(credits);
        setTotalCreditPrice(price);
        setSelectedDetails(updatedList);
        let isUpdateView = updateView;
        setUpdateView(!isUpdateView);
    };

    const iconFormatter = (cell, row, rowIndex, extraData) => {
        return (
            <Button onClick={() => handlerIcon('delete', cell, row, rowIndex, extraData)} className="btn btn-danger m-btn m-btn--icon m-btn--icon-only m-btn--circle-28">
                <i className="mini flaticon2-cross"> </i>
            </Button>
        );
    };

    const config = {
        showPagination: false,
        showFilter: false
    };

    const getMainColumns = (approved) => {
        let colums = [];
        if(approved){
            colums = [
                {
                    dataField: "status",
                    text: t('common.status'),
                    sort: true,
                },
                {
                    dataField: "code",
                    text: t('subject.index'),
                    sort: false,
                },
                {
                    dataField: "name",
                    text: t('subject.name'),
                    sort: false,
                },
                {
                    dataField: "credit",
                    text: t('subject.credit'),
                    sort: false,
                },
                {
                    dataField: "before",
                    text: t('subject.previous'),
                    sort: false,
                },
                {
                    dataField: "qwe",
                    text: t('subject.time'),
                    sort: false,
                },
                {
                    dataField: "price",
                    text: t('assessment.tuition'),
                    sort: false,
                    align: 'right',
                }
            ]
        } else {
            colums = [
                {
                    dataField: "status",
                    text: t('common.status'),
                    sort: true,
                },
                {
                    dataField: "code",
                    text: t('subject.index'),
                    sort: false,
                },
                {
                    dataField: "name",
                    text: t('subject.name'),
                    sort: false,
                },
                {
                    dataField: "credit",
                    text: t('subject.credit'),
                    sort: false,
                },
                {
                    dataField: "before",
                    text: t('subject.previous'),
                    sort: false,
                },
                {
                    dataField: "qwe",
                    text: t('subject.time'),
                    sort: false,
                },
                {
                    dataField: "price",
                    text: t('assessment.tuition'),
                    sort: false,
                    align: 'right',
                },
                {
                    isDummyField: true,
                    dataField: "action",
                    text: "",
                    sort: false,
                    attrs: Attribute, //formatter ашиглаж байгаа бол attrs заавал дуудах ёстой.
                    formatter: !approved && canSelect ? iconFormatter : null,
                }
            ]
        }
        return colums;
    };

    const Attribute = (cell, row, rowIndex, colIndex) => {
    };

    const columns = [
        // {
        //     dataField: "status",
        //     text: t('common.status'),
        //     sort: true,
        // },
        {
            dataField: "subjectCode",
            text: t('subject.index'),
            sort: false,
        },
        {
            dataField: "subjectName",
            text: t('subject.name'),
            sort: false,
        },
        {
            dataField: "credit",
            text: t('subject.credit'),
            sort: false,
        },
        {
            dataField: "beforeSubjectName",
            text: t('subject.previous'),
            sort: false,
        },
        {
            dataField: "registrationNumber",
            text: t('subject.time'),
            sort: false,
        },
        {
            dataField: "totalPrice",
            text: t('assessment.tuition'),
            sort: false,
        },
    ];

    const handleHelperModal = () => {
        setHelperModal(true);
    };

    const handleHelperModalClose = () => {
        setHelperModal(false);
    };

    const handleAddModalClose = () => {
        setAddModal(false);
        setSelectedIds([]);
    };

    const handleAddModalSubmit = () => {
        const updatedDetails = cloneDeep(selectedDetails);
        const programDtl = cloneDeep(programDetails);
        const allDetails = [];

        for (const detail of programDtl)
        {
            for (const dtl of detail.details)
            {
                for (const listElement of dtl.list)
                {
                    allDetails.push(listElement);
                }
            }
        }

        for (const id of selectedIds)
        {
            if (!selectedDetails.some(detail => detail.id === id))
            {
                const detail = allDetails.find(dtl => dtl.id === id);
                if (detail)
                {
                    updatedDetails.push({
                        before: detail.beforeSubjectCode || '-',
                        credit: detail.credit || '-',
                        id: detail.id,
                        name: detail.subjectName || '-',
                        code: detail.subjectCode || '-',
                        price: detail.totalPrice || '-',
                    });
                }
            }
        }

        let credits = 0;
        let price = 0;
        if(updatedDetails && updatedDetails.length)
        {
            for(let i = 0; i < updatedDetails.length; i++)
            {
                credits += parseInt(updatedDetails[i].credit);
                price += parseInt(updatedDetails[i].price);
            }
        }

        setTotalCredit(credits);
        setTotalCreditPrice(price);
        setSelectedDetails(updatedDetails);
        handleAddModalClose();
    };

    const handleDeleteModalClose = () => {
        setDeleteModal(false);
    };

    const onSelect = (row, isSelect, rowIndex) => {
        let ids = [ ...selectedIds ];
        if (row.id)
        {
            if (isSelect)
            {
                ids.push(row.id)
            } else {
                for (let i = 0; i < ids.length; i++)
                {
                    if (parseInt(ids[i]) === parseInt(row.id))
                    {
                        ids.splice(i, 1);
                    }
                }
            }
        }

        setSelectedIds(ids);
    };

    const onSelectAll = (isSelect, rows) => {
        let ids = [ ...selectedIds ];
        if (isSelect) {
            for (let i = 0; i < rows.length; i++) {
                ids.push(rows[i].id);
            }
        } else {
            for (let i = 0; i < rows.length; i++) {
                for (let s = 0; s < ids.length; s++) {
                    if (parseInt(ids[s]) === parseInt(rows[i].id)) {
                        ids.splice(s, 1);
                    }
                }
            }
        }

        setSelectedIds(ids);
    };

    const handlerOnDeleteClick = () => {
        // const params = {
        //     school: selectedSchool.id,
        //     teacher: selectedRecord ? selectedRecord.id : null,
        // }

        // fetchRequest(teacherDelete, 'POST', params, false)
        //     .then(response => {
        //         if (response.success) {
        //             setDeleteModal(false);
        //             setTeachers(response.data.teachers);
        //             message(response.data.message, true);
        //         } else {
        //             message(response.data.message, false);
        //         }
        //     })
        //     .catch(err => {
        //     })
    };

    const handlerSubjectAddClick = () => {
        let dtls = [];

        if(selectedDetails && selectedDetails.length > 0){
            for (let i = 0; i < selectedDetails.length; i++){
                dtls.push(selectedDetails[i].id)
            }
        }

        const params = {
            school: schoolId,
            selection: 1,
            dtls: JSON.stringify(dtls)
        };

        dispatch(setLoading(true));
        fetchRequest(AddSubjectStudentSelection, 'GET', params)
            .then(response => {
                if (response.success) {
                    setProgramDetails(response.data.programDetails);
                    setAddModal(true);
                } else {
                    message(response.data.message, false);
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
            })
    };

    const handlerOnPublishClick = () => {
        onSaveClick(1);
    };

    const renderDetails = (details, index) => {
        let row = [];
        if (details && details.length > 0) {
            details.map(detail => {
                row.push(
                    <div key={'detail_' + index + '_' + detail['typeId']}>
                        <div className='col-12 p-0 font-w-bold mt-1'>
                            {detail.typeName + ' ' + (t('subject.subjects')).toLowerCase()}
                        </div>
                        <DTable
                            data={detail['list']}
                            columns={columns}
                            config={config}
                            showOrdering={true}
                            isSelect={true}
                            onSelect={onSelect}
                            onSelectAll={onSelectAll}
                        />
                    </div>
                );
            })
        }

        return row
    };

    const onSaveClick = (publish = 0) => {
        const ids = selectedDetails.map(detail => detail.id);
        const params = {
            school: schoolId,
            selection: 1,
            ids: JSON.stringify(ids),
            approved: publish
        };

        dispatch(setLoading(true));
        fetchRequest(AddSubjectStudentSelection, 'POST', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setCanSelect(!!data.canSelect);
                    setSelectionData(data.selectionData);
                    setApproved(data?.selectionData?.isApproved);
                    getMainColumns(data?.selectionData?.isApproved);
                    message(res?.data?.message, true)
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            })
    };

    if (hasPenalty) {
        if(hasConfig){
            return(
                <Card>
                    <Card.Body>
                        <Button
                            variant='danger'
                            size='lg'
                        >
                            {t('selection.payFine')}
                        </Button>
                        <div className='mt-3'>
                            {t('selection.payFineDescription')}
                        </div>
                    </Card.Body>
                </Card>
            )
        } else {
            return(
                <Card>
                    <Card.Body>
                        <div className='mt-3'>
                            {t('selection.notCreated')}
                        </div>
                    </Card.Body>
                </Card>
            )
        }
    }

    return (
        <div className='row'>
            {
                hasConfig
                ?
                    <div className='col-12'>
                        <Card>
                            <Card.Body>
                                <div className='row mb-2'>
                                    {
                                        selectionData && selectionData.isApproved === true
                                        ?
                                            <div className='col-12 pink-red'><b>{
                                                selectionData.parentSeasonName + ' ' + t('common.year') + ', ' + selectionData.seasonName + ' ' + t('selection.selectionOneApprovedText')
                                            }</b></div>
                                        :
                                            !approved && canSelect && (
                                                <div className='col-8'>
                                                    <ButtonToolbar className='d-inline'>
                                                        <Button
                                                            className="btn btn-primary btn-shadow"
                                                            onClick={handlerSubjectAddClick}
                                                        >
                                                            {t('subject.selectSubject')}
                                                        </Button>
                                                        <Button
                                                            className='ml-3'
                                                            variant='success'
                                                            onClick={() => onSaveClick(0)}
                                                        >
                                                            {t('common.reminder')}
                                                        </Button>
                                                        <Button
                                                            className='ml-3'
                                                            variant='publish'
                                                            onClick={() => handlerOnPublishClick()}
                                                        >
                                                            {t('action.publish')}
                                                        </Button>
                                                    </ButtonToolbar>
                                                </div>
                                            )
                                    }
                                    <div className='col-12 text-right'>
                                        <Button className='bg-white border-white d-inline btn-hover-less'
                                                onClick={handleHelperModal}>
                                            <i className="icon-2x text-danger flaticon-questions-circular-button d-inline"/>
                                            <p className='text-danger d-inline'><b>{t('common.helper')}</b></p>
                                        </Button>
                                    </div>
                                </div>
                                <Card>
                                    <Card.Body>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <h6 className='dark-blue text-uppercase'>{t('subject.selected')}</h6>
                                            </div>
                                            <div className='col-12 mb-4'>
                                                <table className='ml-12'>
                                                    <tbody>
                                                    <tr>
                                                        <td className='text-right'>{t('total.subject')}:</td>
                                                        <td className='pl-4 strong-blue font-w-bold'>{selectedDetails.length}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='text-right'>{t('total.credit')}:</td>
                                                        <td className='pl-4 strong-blue font-w-bold'>{totalCredit || 0}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='text-right'>{t('total.price')}:</td>
                                                        <td className='pl-4 strong-blue font-w-bold'>{totalCreditPrice || 0}₮</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div className='col-12 font-w-bold dark-blue'>
                                                {t('subject.generalAcademicSubject')}
                                            </div>
                                        </div>
                                        <DTable
                                            data={selectedDetails}
                                            columns={getMainColumns(approved)}
                                            config={config}
                                            totalDataSize={1500}
                                            updateTable={updateView}
                                            selectMode={'radio'}
                                        />
                                    </Card.Body>
                                </Card>
                            </Card.Body>
                        </Card>
                    </div>
                : null
            }
            <Modal
                show={helperModal}
                onHide={handleHelperModalClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Modal heading
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Centered Modal</h4>
                    <p>
                        Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
                        dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta
                        ac consectetur ac, vestibulum at eros.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleHelperModalClose}>{t('common.close')}</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={addModal}
                onHide={handleAddModalClose}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t('subject.selectSubject')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        programDetails && programDetails.length > 0
                            ?
                            programDetails.map((detail, index) => {
                                return (
                                    <Card key={'detail_' + index} className='mb-3'>
                                        <Card.Body>
                                            <div className='col-12 p-0 font-w-bold dark-blue'>
                                                {detail['year'] + ' ' + (detail && detail['seasonType'] ? (detail['seasonType']).toUpperCase() : '')}
                                            </div>
                                            <div className='col-12 p-0 font-w-bold dark-blue mt-1'>
                                                {t('subject.generalAcademicSubject')}
                                            </div>
                                            {renderDetails(detail['details'], index)}
                                            {/*<div '>*/}
                                            {/*    {detail['details']['']}*/}
                                            {/*</div>*/}
                                        </Card.Body>
                                    </Card>
                                )
                            })
                            : null
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='link' onClick={handleAddModalClose}>{t('common.close')}</Button>
                    <Button variant='next' onClick={handleAddModalSubmit}>{t('common.select')}</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={deleteModal}
                onHide={handleDeleteModalClose}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t('warning.delete')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {t('warning.delete_confirmation')}
                        <br/>
                        <br/>
                        {t('warning.delete_confirmation_description')}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="btn btn-link bolder" onClick={handleDeleteModalClose}>{t('common.cancel')}</Button>
                    <Button variant="success btn-shadow" onClick={handlerOnDeleteClick}>{t('warning.delete')}</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
};

export default SelectionOne;