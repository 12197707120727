import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DTable from "../../../modules/DataTable/DTable";
import ScholarshipSearch from "../components/scholarshipSearch";
import AddCertificate from "./addModal";
import DeleteModal from "../../../modules/DeleteModal";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import message from "../../../modules/message";
import { certificateNumberList, certificateNumberDelete } from "../../../../utils/fetchRequest/Urls";
import EditModal from "./editModal";

const EnrollCertificateNumber = () => {
    const [showModal, setShowModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [data, setData] = useState([]);
    const [certificateId, setCertificateId] = useState(null);
    const [searchParams, setSearchParams] = useState(null);
    const [searchValues, setSearchValues] = useState(null);
    const [totalCount, setTotalCount] = useState(0);

    const schoolId = useSelector((state) => state?.selectedSchool?.id || null);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        const params = {
            school: schoolId,
        };
        getData(params);
    }, []);

    const getData = (params) => {
        dispatch(setLoading(true));
        fetchRequest(certificateNumberList, "GET", params)
            .then((res) => {
                if (res.success) {
                    const { data } = res;
                    if (data?.list) {
                        setData(data.list);
                        setTotalCount(data.totalCount);
                    }
                } else {
                    message(res?.data?.message || t("errorMessage.title"));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t("errorMessage.title"));
            });
    };

    const columns = [
        {
            dataField: "longName",
            text: t("enroll.subSchool"),
            sort: false,
        },
        {
            dataField: "templateName",
            text:'Мэргэжил',
            sort: true
        },
        {
            dataField: "start",
            text: t("enroll.startNumber"),
            sort: true,
        },
        {
            dataField: "issueNumber",
            text: t("enroll.issueNumber"),
            sort: true,
        },
    ];

    const handleClick = () => {
        setShowModal(true);
    };

    const onModalClose = () => {
        setShowModal(false);
        setEditModal(false);
        setDeleteModal(false);
    };

    const contextMenus = [
        {
            key: "edit",
            title: t("common.edit"),
            icon: <i className="las la-edit dt-cm-item-icon" />,
        },
        {
            key: "delete",
            title: t("common.delete"),
            icon: <i className="las la-trash-alt dt-cm-item-icon" />,
        },
    ];

    const handleContextMenuClick = (id, key) => {
        if (id && key) {
            if (key === "edit") {
                setCertificateId(id);
                setEditModal(true);
            } else if (key == "delete") {
                setCertificateId(id);
                setDeleteModal(true);
            }
        }
    };

    const onDelete = () => {
        const params = {
            school: schoolId,
            certificateConfig: certificateId,
        };

        dispatch(setLoading(true));
        fetchRequest(certificateNumberDelete, "POST", params, false)
            .then((response) => {
                if (response.success) {
                    const { data } = response;
                    setShowModal(false);
                    message(data.message, true);
                    onModalClose();
                    handleOnFinish();
                } else {
                    message(response.data.message, false);
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t("errorMessage.title"));
            });
    };

    const onUserInteraction = (object) => {
        let params = {
            ...searchParams,
            ...object,
        };
        params.school = schoolId;
        setSearchValues(object);
        getData(params);
    };

    const search = (searchParam) => {
        const params = {
            ...searchParam,
            ...searchValues,
        };
        params.school = schoolId;
        setSearchParams(searchParam);
        getData(params);
    };

    const handleOnFinish = () => {
        const params = {
            school: schoolId,
        };
        getData(params);
    };

    return (
        <>
            <div className="mx-auto w-100">
                <ScholarshipSearch onSearch={search} />
            </div>
            <div className="mx-auto mt-3">
                <Card>
                    <Card.Body>
                        <div>
                            <button
                                className="btn btn-primary btn-shadow"
                                onClick={handleClick}
                            >
                                {t("common.add")}
                            </button>
                            {showModal && (
                                <AddCertificate
                                    onClose={onModalClose}
                                    schoolId={schoolId}
                                    onFinish={handleOnFinish}
                                />
                            )}
                            {editModal && (
                                <EditModal
                                    onClose={onModalClose}
                                    certificateId={certificateId}
                                    schoolId={schoolId}
                                    onFinish={handleOnFinish}
                                />
                            )}
                            <div>
                                <>
                                    {data ? (
                                        <DTable
                                            columns={columns}
                                            data={data}
                                            totalDataSize={totalCount}
                                            config={{
                                                showPagination: true,
                                                excelExport: true,
                                                excelFileRemote: true,
                                                excelFileName: t("enroll.numberOfCertificate"),
                                                excelFileRemoteUrl: `certificate-number/settings/excel`,
                                                params: {
                                                    school: schoolId,
                                                    ...searchParams,
                                                    ...searchValues,
                                                },
                                            }}
                                            remote
                                            onInteraction={onUserInteraction}
                                            showOrdering={true}
                                            contextMenus={contextMenus}
                                            onContextMenuItemClick={handleContextMenuClick}
                                            selectMode={"radio"}
                                        />
                                    ) : null}
                                </>
                                {!editModal && certificateId && !showModal && deleteModal ? (
                                    <DeleteModal
                                        onClose={onModalClose}
                                        // title={t("setting.teacherStatus")}
                                        title={t("enroll.deleteCertificateNumber")}
                                        onDelete={onDelete}
                                    >
                                        {t("warning.delete_confirmation")}
                                        <br />
                                        <br />
                                        {t("warning.delete_confirmation_description")}
                                    </DeleteModal>
                                ) : null}
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </>
    );
};

export default EnrollCertificateNumber;
