import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import {Link, useHistory} from "react-router-dom";
import Select from "../../modules/Form/Select";
import DTable from "../../modules/DataTable/DTable";
import { useSelector, useDispatch } from "react-redux";
import {setLoading} from "../../../redux/action";
import {fetchRequest} from "../../../utils/fetchRequest";
import {timetableIdsDelete, timetableInit, timetableGroups} from "../../../utils/fetchRequest/Urls";
import message from "../../modules/message";
import {Button, Form, Modal} from "react-bootstrap";

const All = ({
    seasonId,
    setSeasonId,
    setSeasons,
    seasons
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [ days, setDays ] = useState([]);
    const [ dayId, setDayId ] = useState(null);
    const [ shifts, setShifts ] = useState([]);
    const [ shiftId, setShiftId ] = useState(null);
    const [ times, setTimes ] = useState([]);
    const [ timetableId, setTimetableId] = useState(null);
    const [ subjects, setSubjects] = useState([]);
    const [ subjectTeachers, setSubjectTeachers ] = useState([]);
    const [ deleteModal, setDeleteModal ] = useState(false);
    const [ groupModal, setGroupModal ] = useState(false);

    const [ totalCount, setTotalCount ] = useState(0);
    const [ selectedTimetables, setSelectedTimetables ] = useState([]);
    const [ selectedGroupId, setSelectedGroupId ] = useState(null);
    const [initLoader, setInitLoader] = useState(true)

    const [ treeIndex ] = useState('timetable_tree_index');
    const [ dayIndex ] = useState('timetable_day_index');
    const [ shiftIndex ] = useState('timetable_shift_index');
    const [ tabIndex ] = useState('timetable_tab_index');

    useEffect(() => {
        const selectedTreeId = localStorage.getItem(treeIndex);
        const selectedDayId = localStorage.getItem(dayIndex);
        const selectedShiftId = localStorage.getItem(shiftIndex);

        if(selectedTreeId){
            setSeasonId(selectedTreeId);
        }

        if(selectedShiftId){
            setShiftId(parseInt(selectedShiftId));
        }

        if(selectedDayId){
            setDayId(parseInt(selectedDayId));
        }

        if(initLoader){
            init(selectedTreeId, selectedShiftId, selectedDayId)
        }
    }, []);

    useEffect(() => {
        if(seasonId && !initLoader){
            const selectedTreeId = localStorage.getItem(treeIndex);
            const selectedDayId = localStorage.getItem(dayIndex);
            const selectedShiftId = localStorage.getItem(shiftIndex);
            const selectedTab = localStorage.getItem(tabIndex);
    
            if(selectedTreeId){
                setSeasonId(selectedTreeId);
            }
    
            if(selectedShiftId){
                setShiftId(parseInt(selectedShiftId));
            }
    
            if(selectedDayId){
                setDayId(parseInt(selectedDayId));
            }
    
            if(selectedTab === 'all' && initLoader){
                init(selectedTreeId, selectedShiftId, selectedDayId)
            } else {
                init(seasonId, shiftId, dayId)
            }
        }
    }, [seasonId]);

    const init = (season, shift, day) => {
        const params = {
            school: schoolId,
            type: 'all',
            season: season,
            shift: shift,
            day: day
        };

        dispatch(setLoading(true));
        fetchRequest(timetableInit, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setSeasons(data.seasons || []);
                    setDays(data.days || []);
                    const schoolShifts = [];
                    if (data?.schoolShifts?.length) {
                        for (const schoolShift of data.schoolShifts) {
                            schoolShifts.push({
                                ...schoolShift,
                            })
                        }
                    }

                    if(!shiftId){
                        let selectedMainShiftId = null;
                        if(schoolShifts && schoolShifts.length > 0){
                            for(let i = 0; i < schoolShifts.length; i++){
                                if(schoolShifts[i].code.toLowerCase() == 'main'){
                                    selectedMainShiftId = schoolShifts[i].value;
                                }
                            }
                        }
                        
                        localStorage.setItem(shiftIndex, selectedMainShiftId);
                        setShiftId(selectedMainShiftId);
                    }

                    if(data.selectedShiftId){
                        setShiftId(data.selectedShiftId)
                    }
                    setShifts(schoolShifts);
                    setSubjectTeachers(data?.subjectTeachers || []);
                    setSubjects(data?.subjects || []);
                    setTimes(data?.times || []);
                    setTotalCount(data?.subjectTotalCount || 0);
                    setInitLoader(false)
                    dispatch(setLoading(false));
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                    dispatch(setLoading(false));
                }
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const columns = [
        {
            dataField: "subjectCode",
            text: t('subject.code'),
        },
        {
            dataField: "teacherCode",
            text: t('teacher.code'),
        },
        {
            dataField: "firstName",
            text: t('teacher.name'),
        },
    ];

    const config = {
        showPagination: false,
        showFilter: true,
        showAllData: true
    };

    const contextMenus = [
        {
            key: 'edit',
            title: t('common.edit'),
            icon: <i className='las la-edit dt-cm-item-icon'/>
        },
        // {
        //     key: 'delete',
        //     title: t('common.delete'),
        //     icon: <i className='las la-trash-alt dt-cm-item-icon' />,
        // },
    ];

    const onDayClick = id => {
        setDayId(id);
        init(seasonId, shiftId, id);
        localStorage.setItem(dayIndex, id);
    };

    const onSelect = id => {
        setShiftId(parseInt(id));
        init(seasonId, id, dayId);
        localStorage.setItem(shiftIndex, id);
    };

    const getColumns = () => {
        const array = [...columns];
        if(array && array.length > 0 && times && times.length > 0){
            for (const time of times) {
                array.push({
                    dataField: String(time.id),
                    text: time.startTime + '-' + time.endTime + ' (' + time.name + ')',
                    formatter: (cell, row) => {
                        if(row.times && row.times.length > 0){
                            let rowTimes = row.times;

                            for(let i = 0; i < rowTimes.length; i++){
                                if(rowTimes[i]['timeId'] === time.id){
                                    if(rowTimes[i]['timetables'] && rowTimes[i]['timetables'].length > 0){
                                        let timetables = rowTimes[i]['timetables'];
                                        let timetableObj = [];

                                        for (let t = 0; t < timetables.length; t++){
                                            if((timetables[t]['pTimeCode']).toLowerCase() === 'laboratory'){
                                                timetableObj.push(
                                                    <div
                                                        key={ 'time_' + i + '_timetable_' + t }
                                                        className='timetable-laboratory'
                                                    >
                                                        <div>
                                                            <span>{timetables[t]['pTimeName']}</span>
                                                            <span>{timetables[t]['room']}</span>
                                                            {
                                                                timetables[t]['isSelective']
                                                                ?
                                                                null
                                                                : 
                                                                <span>{timetables[t]['groupName']}</span>
                                                            }
                                                            <span>{timetables[t]['typeName']}</span>
                                                        </div>
                                                    </div>
                                                )
                                            } else if ((timetables[t]['pTimeCode']).toLowerCase() === 'seminar') {
                                                timetableObj.push(
                                                    <div
                                                        key={ 'time_' + i + '_timetable_' + t }
                                                        className='timetable-seminar'
                                                    >
                                                        <div>
                                                            <span>{timetables[t]['pTimeName']}</span>
                                                            <span>{timetables[t]['room']}</span>
                                                            {
                                                                timetables[t]['isSelective']
                                                                ?
                                                                 null
                                                                : 
                                                                <span>{timetables[t]['groupName']}</span>
                                                            }
                                                            <span>{timetables[t]['typeName']}</span>
                                                        </div>
                                                    </div>
                                                )
                                            } else {
                                                timetableObj.push(
                                                    <div
                                                        key={ 'time_' + i + '_timetable_' + t }
                                                        className='timetable-lecture'
                                                    >
                                                        <div>
                                                            <span>{timetables[t]['pTimeName']}</span>
                                                            <span>{timetables[t]['room']}</span>
                                                            {
                                                                timetables[t]['isSelective']
                                                                ?
                                                                 null
                                                                : 
                                                                <span>{timetables[t]['groupName']}</span>
                                                            }
                                                            <span>{timetables[t]['typeName']}</span>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        }

                                        return (
                                            <div>
                                                {timetableObj}
                                            </div>
                                        )
                                    }
                                }
                            }
                        }
                    }
                })
            }
        }

        return array;
    };

    const renderHeader = () => {
        const dayArray = [];
        const timeArray = [];

        if(days && days.length > 0 && times && times.length){
            for(let i = 0; i < days.length; i++){
                dayArray.push(
                    <th key={'th_day_' + i} colSpan={times.length} style={{textAlign: 'center'}}>{days[i].day}</th>
                );

                for(let t = 0; t < times.length; t++){
                    timeArray.push(
                        <th key={'th_' + i + '_' + t} style={{whiteSpace: 'nowrap'}}>{times[t].name}</th>
                    );
                }
            }
        }

        const tHead = [
            <thead key={'tHead_' + 1}>
                <tr>
                    <th rowSpan={3}>{t('subject.code')}</th>
                    <th rowSpan={3}>{t('teacher.code')}</th>
                    <th rowSpan={3}>{t('teacher.name')}</th>
                </tr>
                <tr>
                    {dayArray}
                </tr>
                <tr>
                    {timeArray}
                </tr>
            </thead>
        ];

        return tHead;
    };

    const renderBody = (data) => {
        const rowData = [];
        
        if(data && data.length > 0){
            if(days && days.length > 0 && times && times.length){
                for(let i = 0; i < data.length; i++){
                    if(data[i].dayTimes && data[i].dayTimes.length > 0){
                        let timetableObj = [];
                        let dayTimes = data[i].dayTimes;

                        for(let d = 0; d < dayTimes.length; d++){
                            if(dayTimes[d].times && dayTimes[d].times.length > 0){
                                let rowTimes = dayTimes[d].times;
                                for(let rt = 0; rt < rowTimes.length; rt++){
                                    let tdRow = [];
                                    if(rowTimes[rt]['timetables'] && rowTimes[rt]['timetables'].length > 0){
                                        let timetables = rowTimes[rt]['timetables'];

                                        for (let t = 0; t < timetables.length; t++){
                                            if((timetables[t]['pTimeCode']).toLowerCase() === 'laboratory' && rowTimes[rt]['timeId'] == timetables[t]['timeId']){
                                                tdRow.push(
                                                    <div
                                                        key={'data_' + i + '_day_' + d + '_' + rt + '_row_' + t}
                                                        className='timetable-laboratory'
                                                    >
                                                        <div>
                                                            <span>{timetables[t]['pTimeName']}</span>
                                                            <span>{timetables[t]['room']}</span>
                                                            {
                                                                timetables[t]['isSelective']
                                                                ?
                                                                    null
                                                                : 
                                                                <span>{timetables[t]['groupName']}</span>
                                                            }
                                                            <span>{timetables[t]['typeName']}</span>
                                                        </div>
                                                    </div>
                                                )
                                            } else if ((timetables[t]['pTimeCode']).toLowerCase() === 'seminar' && rowTimes[rt]['timeId'] == timetables[t]['timeId']) {
                                                tdRow.push(
                                                    <div
                                                        key={'data_' + i + '_day_' + d + '_' + rt + '_' + t}
                                                        className='timetable-seminar'
                                                    >
                                                        <div>
                                                            <span>{timetables[t]['pTimeName']}</span>
                                                            <span>{timetables[t]['room']}</span>
                                                            {
                                                                timetables[t]['isSelective']
                                                                ?
                                                                    null
                                                                :
                                                                <span>{timetables[t]['groupName']}</span>
                                                            }
                                                            <span>{timetables[t]['typeName']}</span>
                                                        </div>
                                                    </div>
                                                )
                                            } else {
                                                if(rowTimes[rt]['timeId'] == timetables[t]['timeId']){
                                                    tdRow.push(
                                                        <div
                                                            key={'data_' + i + '_day_' + d + '_' + rt + '_row_1_' + t}
                                                            className='timetable-lecture'
                                                        >
                                                            <div>
                                                                <span>{timetables[t]['pTimeName']}</span>
                                                                <span>{timetables[t]['room']}</span>
                                                                {
                                                                    timetables[t]['isSelective']
                                                                    ?
                                                                    null
                                                                    : 
                                                                    <span>{timetables[t]['groupName']}</span>
                                                                }
                                                                <span>{timetables[t]['typeName']}</span>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            }
                                        }
                                    }
                                    
                                    timetableObj.push(
                                        <td key={'data_' + i + '_day_' + d + '_' + rt + '_row_' + t}>{tdRow}</td>
                                    )
                                }
                            }
                        }

                        rowData.push(
                            <tr key={'time_' + t + '_td_' + i}>
                                <td>{data[i].subjectCode}</td>
                                <td>{data[i].teacherCode}</td>
                                <td>{data[i].firstName}</td>
                                {timetableObj}
                            </tr>
                        )
                    }
                }
            }
        }

        return rowData;
    };

    const handleContextMenuClick = (id, key) => {
        if (id && key) {
            if(key === 'delete'){
                setDeleteModal(true);
                setTimetableId(id);
            } else if(key === 'edit'){
                getTeacherTimetableGroup(id)
                // let timetableIds = [];
                // if(subjectTeachers && subjectTeachers.length > 0){
                //     for(let i = 0; i < subjectTeachers.length; i++){
                //         if(id == subjectTeachers[i].id){
                //             let times = subjectTeachers[i].times;
                //             if(times && times.length > 0){
                //                 for (let t = 0; t < times.length; t++){
                //                     let timetables = times[t]['timetables'];
                //                     if(timetables && timetables.length > 0){
                //                         for (let s = 0; s < timetables.length; s++){
                //                             timetableIds.push(timetables[s]['id']);
                //                         }
                //                     }
                //                 }
                //             }
                //         }
                //     }
                // }
            }
        }
    };

    const handlerOnEditClick = () => {
        if(selectedGroupId){
            history.push({
                pathname: '/timetable/edit',
                state: {
                    seasonId,
                    selectedGroupId: selectedGroupId,
                    dayId : dayId
                }
            });
        } else {
            message('Группийн мэдээлэл сонгоно уу')
        }
    }

    const getTeacherTimetableGroup = (data) => {
        let dataArray = data.split('_');

        if(dataArray && dataArray.length == 2){
            const params = {
                school: schoolId,
                day: dayId,
                teacher: dataArray[0],
                subject: dataArray[1],
                season: seasonId,
            };
    
            dispatch(setLoading(true));
            fetchRequest(timetableGroups, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res;

                        if(data.timetables && data.timetables.length > 0 && data.timetables.length == 1){
                            history.push({
                                pathname: '/timetable/edit',
                                state: {
                                    seasonId,
                                    selectedGroupId: data.timetables[0].groupId,
                                    dayId : dayId
                                }
                            });
                        } else {
                            setSelectedTimetables(data?.timetables || []);
                            setGroupModal(true);
                        }
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        } else {
            message(t('errorMessage.title'))
        }
    }

    const handleDeleteModalClose = () => {
        setDeleteModal(false);
    };

    const handleGroupModalClose = () => {
        setGroupModal(false);
    };

    const handlerOnDeleteClick = () => {
        if(timetableId){
            let timetableIds = [];
            if(subjectTeachers && subjectTeachers.length > 0){
                for(let i = 0; i < subjectTeachers.length; i++){
                    if(timetableId == subjectTeachers[i].id){
                        let times = subjectTeachers[i].times;
                        if(times && times.length > 0){
                            for (let t = 0; t < times.length; t++){
                                let timetables = times[t]['timetables'];
                                if(timetables && timetables.length > 0){
                                    for (let s = 0; s < timetables.length; s++){
                                        timetableIds.push(timetables[s]['id']);
                                    }
                                }
                            }
                        }
                    }
                }
            }

            let params = {
                school: schoolId,
                timetables: JSON.stringify(timetableIds),
            };

            dispatch(setLoading(true));
            fetchRequest(timetableIdsDelete, 'POST', params)
                .then(response => {
                    if (response.success) {
                        handleDeleteModalClose();
                        init(seasonId, shiftId, dayId)
                    } else {
                        message(response?.data?.message, false)
                    }
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                });
        } else {
            message(t('warning.invalid_parameter'), false)
        }
    };

    const onHandlerRadioChange = (e, timetable) => {
        setSelectedGroupId(timetable.groupId);
    };

    return (
        <div>
            <div style={{ display: 'flex' }}>
                <button
                    key={0}
                    onClick={() => onDayClick(0)}
                    className={`btn btn-warning mr-3  ${dayId ? 'disabled' : ''}`}
                    style={{ color: '#000', fontWeight: 600 }}
                > 
                    {t('common.all')}
                </button>
                {
                    days.map(day => {
                        const isActive = day.id === dayId;
                        return (
                            <button
                                key={day.id}
                                onClick={() => onDayClick(day.id)}
                                className={`btn btn-warning mr-3 ${isActive ? '' : 'disabled'}`}
                                style={{ color: '#000', fontWeight: 600 }}
                            >
                                {day.day?.toUpperCase?.()}
                            </button>
                        )
                    })
                }
            </div>
            {
                seasonId && seasons && seasons.length > 0 && (
                    <Link
                        to={{
                            pathname: '/timetable/add',
                            state: {
                                seasonId,
                            }
                        }}
                        className='btn btn-primary mt-5'
                    >
                        {t('common.add')}
                    </Link>
                )
            }
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '1rem' }}>
                <span className='mr-5 ml-5'>
                    {t('school.shift.title')}
                </span>
                <div className='timetable-shift-select' style={{ width: 200 }}>
                    <Select
                        options={shifts}
                        value={shiftId ? parseInt(shiftId) : null}
                        onChange={onSelect}
                        searchable
                    />
                </div>
            </div>
            {
                dayId ?
                    <DTable
                        data={subjectTeachers}
                        columns={getColumns()}
                        config={config}
                        remote={false}
                        totalDataSize={totalCount}
                        showOrdering={false}
                        contextMenus={contextMenus}
                        onContextMenuItemClick={handleContextMenuClick}
                        selectMode={'radio'}
                    />
                :
                    <div className='tableFixHead react-bootstrap-table table-responsive mt-4'>
                        <table className='table table-bordered table custom-dt'>
                            {renderHeader()}
                            <tbody>
                                {renderBody(subjectTeachers)}    
                            </tbody>
                        </table>
                    </div>
            }
            
            <Modal
                show={deleteModal}
                onHide={handleDeleteModalClose}
                size="sm"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t('warning.delete')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {t('warning.delete_confirmation')}
                        <br/>
                        <br/>
                        {t('warning.delete_confirmation_description')}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="btn btn-link bolder" onClick={handleDeleteModalClose}>{t('common.cancel')}</Button>
                    <Button variant={"success btn-shadow"} onClick={handlerOnDeleteClick}>{t('warning.delete')}</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={groupModal}
                onHide={handleGroupModalClose}
                size="md"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t('common.edit')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        selectedTimetables &&
                        selectedTimetables.map((timetable, index) =>
                            <Form.Check
                                key={'radio_' + index}
                                className='mr-2'
                                name="timetableGroup"
                                inline
                                label={timetable.groupName + ' - ' + timetable.roomName + ' (' + timetable.teacherName + ')' + ' - ' + timetable.timetableTypeName}
                                type='radio'
                                onChange={(e) => onHandlerRadioChange(e, timetable)}
                            />
                        )
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="btn btn-link bolder" onClick={handleGroupModalClose}>{t('common.cancel')}</Button>
                    <Button variant="success btn-shadow" onClick={handlerOnEditClick}>{t('common.edit')}</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default All;