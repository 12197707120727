import React, {useEffect, useState} from 'react'
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import { dateFormatMn } from 'utils/utils';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';

export const TimeSheet = React.forwardRef((props, ref) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { teacherData, mainSchoolData, schoolData, subjectData, sheetList, sheetPTimeData} = props;

    let totalCredit = 0;
    let avgGpa = 0;
    let totalListCount = 0;

    const mainSchoolName =  mainSchoolData && mainSchoolData.longName ? mainSchoolData.longName.substring(0,1).toUpperCase() + mainSchoolData.longName.substring(1) : '';
    const schoolName = schoolData && schoolData.longName ? schoolData.longName.substring(0,1).toUpperCase() + schoolData.longName.substring(1) : '';

    const shiftRow = totalListCount > 75 ? totalListCount/2 : 38;
    let totalRow = 0;
    let stopArrRow = null;
    let stopSubRow = null;

    if (stopArrRow == null && stopSubRow == null) {
        stopArrRow = shiftRow;
        stopSubRow = shiftRow;
    }

    let date = new Date();
    let totalTimeCount = 0;
    let totalEducationCount = 0;

    return (
        <div 
            ref={ref} 
            style={{marginTop: 20, color: '#000', fontFamily: 'Times New Roman'}}
        >
            {
                teacherData && mainSchoolData && subjectData &&
                <div>
                    <div className='col-12' style={{textAlign: 'center', border: '1px solid #ECF0F3', padding: 20}}>
                        <div style={{fontFamily: 'Times New Roman', fontSize: 14, textTransform: 'uppercase'}}>
                            {mainSchoolName}
                        </div>
                        <div style={{fontFamily: 'Times New Roman', fontSize: 14, lineHeight: 2, textTransform: 'uppercase'}}>
                            {schoolName}
                        </div>

                        <div style={{textAlign: 'center', fontFamily: 'Times New Roman', fontSize: 14, fontWeight: 'bold', textTransform: 'uppercase'}}>
                            БАГШИЙН ЦАГ ТООЦОХ ХУУДАС
                        </div>
                    </div>

                    <div style={{fontFamily: 'Times New Roman', fontSize: 14, border: '1px solid #ECF0F3', padding: 20}}>
                        <div className='col-12' style={{display: 'flex'}}>
                            <div className='col'>
                                <table style={{lineHeight: 1.3}}>
                                    <tbody>
                                        <tr>
                                            <td><b>Овог нэр:</b></td>
                                        </tr>
                                        <tr>
                                            <td><b>Албан тушаал:</b></td>
                                        </tr>
                                        {/* <tr>
                                            <td><b>Эрдмийн зэрэг зол:</b></td>
                                        </tr> */}
                                        <tr>
                                            <td><b>Багшийн код:</b></td>
                                        </tr>
                                        <tr>
                                            <td><b>Сургууль тэнхим:</b></td>
                                        </tr>
                                        <tr>
                                            <td><b>Хичээлийн нэр/ кредит:</b></td>
                                        </tr>
                                        <tr>
                                            <td><b>Хичээлийн код:</b></td>
                                        </tr>
                                        <tr>
                                            <td><b>Огноо:</b></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className='col'> 
                                <table style={{lineHeight: 1.3}}>
                                    <tbody>
                                        <tr>
                                            <td style={{paddingLeft: 30}}>{teacherData.firstName}</td>
                                        </tr>
                                        <tr>
                                            <td style={{paddingLeft: 30}}>{teacherData.position}</td>
                                        </tr>
                                        <tr>
                                            <td style={{paddingLeft: 30}}>{teacherData.code}</td>
                                        </tr>
                                        <tr>
                                            <td style={{paddingLeft: 30}}>{teacherData.department}</td>
                                        </tr>
                                        <tr>
                                            <td style={{paddingLeft: 30}}>{subjectData?.name + ' - ' + subjectData?.credit + ' кр'}</td>
                                        </tr>
                                        <tr>
                                            <td style={{paddingLeft: 30}}>{subjectData?.code}</td>
                                        </tr>
                                        <tr>
                                            <td style={{paddingLeft: 30}}>{dateFormatMn(date)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {
                            sheetList && sheetList.length > 0 &&
                            <div className='col-12' style={{fontFamily: 'Times New Roman'}}>
                                {
                                    sheetList.map((sheet, index) => {
                                        let tableData = [];
                                        let tableBody = [];
                                        let totalTime = 0;
                                        if(sheet && sheet.topics.length > 0){
                                            sheet.topics.map((topic, tIndex) => {
                                                totalTime = totalTime + parseInt(topic.time)
                                                tableBody.push(
                                                    <tr key={'body_' + index + '_' + tIndex}>
                                                        <td>{tIndex + 1}</td>
                                                        <td>{topic.date}</td> 
                                                        <td className='text-right'>{sheet.totalStudentNumber}</td>
                                                        <td>{sheet.name}</td>
                                                        <td>{topic.topicName}</td>
                                                        <td className='text-right'>{topic.time}</td>
                                                    </tr>
                                                )
                                            })

                                            tableData.push(
                                                <table className='table table-bordered teacher-time-sheet-main-table' key={'table_' + index}>
                                                    <thead>
                                                        <tr>
                                                            <th style={{width: 30}}>№</th>
                                                            <th style={{width: 80}}>Он,сар,өдөр</th>
                                                            <th style={{width: 80}}>Оюутны тоо</th>
                                                            <th style={{width: 120}}>Хичээлийн хэлбэр</th>
                                                            <th>Заасан сэдэв</th>
                                                            <th style={{width: 40}}>Цаг</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {tableBody}
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td className='text-center' colSpan={5}><b>Дүн</b></td>
                                                            <td className='text-right'>{totalTime}</td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            )
                                        }
                                        return (
                                            <>
                                                <div className='col-12 text-center mt-3' style={{fontSize: 14}}>
                                                    <b>{sheet.name}</b>
                                                </div>
                                                <div className='col-12 text-center mt-1 mb-3' style={{fontSize: 13}}>
                                                    <b>{sheet.seasonName + ' (улирал)'}</b>
                                                </div>
                                                <div>{tableData}</div>
                                            </>
                                        )
                                    })
                                }
                            </div>
                        }
                        <div className='col-12'>
                            <table className='table table-bordered teacher-time-sheet-main-table'>
                                <thead>
                                    <tr>
                                        <th rowSpan={2} style={{width: 30}}>№</th>
                                        <th rowSpan={2} className='text-center'>Хичээлийн төрөл</th>
                                        <th colSpan={2} className='text-center' >Бүгд</th>
                                    </tr>
                                    <tr>
                                        <th className='text-center' style={{width: 60}}>Цаг</th>
                                        <th className='text-center' style={{width: 60}}>Кредит</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {   
                                        sheetPTimeData && sheetPTimeData.length > 0 &&
                                        sheetPTimeData.map((pTimeData, index) => {
                                            let totalCount = 0
                                            let educationData = 0
                                            sheetList && sheetList.length > 0 &&
                                            sheetList.map((sheet) => {
                                                if(pTimeData.code == sheet.code){
                                                    if(sheet.topics.length > 0){
                                                        sheet.topics.map((topic) => {
                                                            totalCount = totalCount + parseInt(topic.time)
                                                        })

                                                        totalTimeCount = totalTimeCount + totalCount
                                                    }

                                                    educationData = sheet.creditByLearningNumber
                                                    totalEducationCount = parseFloat(totalEducationCount) + parseFloat(educationData)
                                                }
                                            })
                                            return(
                                                <tr key={'additional_' + index}>
                                                    <td>{index + 1}</td>
                                                    <td>{pTimeData.name}</td>
                                                    <td className='text-right'>{totalCount}</td>
                                                    <td className='text-right'>{educationData}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                    <tr>
                                        <td colSpan={3}><b>Үндсэн кредитийн дүн</b></td>
                                        <td className='text-right'>{totalEducationCount}</td>
                                    </tr>
                                    <tr>
                                        <td>{sheetPTimeData.length + 1}</td>
                                        <td colSpan={2}>Бие даалт</td>
                                        <td className='text-right'>{0.06}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={3}><b>Нэмэлт кредитийн дүн</b></td>
                                        <td className='text-right'>{0.06}</td>
                                    </tr>
                                    <tr>
                                        <td>{sheetPTimeData.length + 2}</td>
                                        <td>Нийт</td>
                                        <td className='text-right'>{totalTimeCount}</td>
                                        <td className='text-right'>{parseFloat(totalEducationCount) + 0.06}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        
                        <div className='row mt-3'>
                            <div className='col-12 text-center'>
                                Хичээл заасан багш: _______________________________/{teacherData.firstName}/
                            </div>
                        </div>
                        <div className='row mt-2'>
                            <div className='col-12 text-center'>
                                <b>Гүйцэтгэсэн хянасан</b>
                            </div>
                        </div>
                        <div className='row mt-2'>
                            <div className='col-12 text-center'>
                                Тэнхимийн эрхлэгч / профессорын багийн ахлагч: _______________________________
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
});
