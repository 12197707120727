import React from "react";
import { Card } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useRef, useState, useEffect } from "react";
import message from "../../../modules/message";
import { fetchRequest } from "../../../../utils/fetchRequest";
import DTable from "../../../modules/DataTable/DTable";
import { setLoading } from "../../../../redux/action";
import { useTranslation } from "react-i18next";
import RegSearch from "./search";
import ProfileEdit from "./profileEdit";
import ProgramEdit from "./programEdit";
import "./reg.scss";
import { useDispatch, useSelector } from "react-redux";
import { candidateProfileEdit, editCandidateProgram, registeredCandidatesList, registeredCandidatesDownloadEyesh } from "../../../../utils/fetchRequest/Urls";
import ChangeStatus from "./modals/changeStatus";

const Registrants = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [seasonIndex] = useState('enroll_register_season_index');
    const [tableStoreData] = useState('enroll_register_table_index');
    
    const [seasonId, setSeasonId] = useState(localStorage.getItem(seasonIndex) || null)
    const [totalCount, setTotalCount] = useState(0);
    const [list, setList] = useState([]);
    const [seasons, setSeasons] = useState([]);
    const [enrolls, setEnrolls] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [candidateId, setCandidateId] = useState(null);
    const [programId, setProgramId] = useState(null);
    const [candidateProgramId, setCandidateProgramId] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [eyeshInfo, setEyeshInfo] = useState(null);
    const [showProfileEditModal, setShowProfileEditModal] = useState(false);
    const [showProgramEditModal, setShowProgramEditModal] = useState(false);
    const [showStatusModal, setShowStatusModal] = useState(false);
    const selectedSchool = useSelector((state) => state?.selectedSchool || null);

    const [tableState, setTableState] = useState(JSON.parse(localStorage.getItem(tableStoreData)) ||
        {
            page: 1,
            pageSize: 10,
            search: '',
            sort: 'firstName',
            order: 'asc'
        }
    )

    useEffect(() => {
        let params = {
            school: selectedSchool.id,
            season: seasonId || '',
            page: tableState.page,
            pageSize: tableState.pageSize,
            search: tableState.search,
            sort: tableState.sort,
            order: tableState.order
        };
        init(params);
    }, [seasonId]);

    async function init(params) {
        dispatch(setLoading(true));
        fetchRequest(registeredCandidatesList, "GET", params)
            .then((res) => {
                if (res.success) {
                    const { data } = res;

                    if (data?.list) {
                        setList(data?.list || []);
                    }

                    setEyeshInfo(data.eyeshInfo || null)
                    setSeasons(data?.seasons || [])
                    setEnrolls(data?.enrolls || [])
                    setPrograms(data?.programs || [])
                    setTotalCount(data?.totalCount || 0)
                } else {
                    message(res?.data?.message || t("errorMessage.title"));
                }
            })
            .catch(e => {
                console.log(e)
                message(t("errorMessage.title"));
            })
            .finally(()=>{
                dispatch(setLoading(false));
            })
    }

    const config = {
        excelExport: true,
        printButton: true,
        columnButton: true,
        defaultSort: [{
            dataField: tableState?.sort || 'takenDate',
            order: tableState?.order || 'desc'
        }],
        defaultPageOptions: {
            page: tableState?.page || 1,
            sizePerPage: tableState?.pageSize || 10,
            search: tableState?.search || '',
        },
        excelFileRemote: true,
        excelFileRemoteUrl: `candidate-admin/excel/registered?school=` + selectedSchool.id + '&season=' + 
            seasonId + '&search=' + (tableState.search ? tableState.search : '') + 
            '&sort=' + tableState.sort + 
            '&order=' + tableState.order + 
            '&t=/'
    }

    const columns = [
        {
            dataField: "programName",
            text: "Бүртгүүлсэн мэргэжил",
            sort: true,
        },
        {
            dataField: "schoolShortName",
            text: "Бүрэлдэхүүн сургууль",
            hideCol: true
        },
        {
            dataField: "createdDate",
            text: "Бүртгүүлсэн огноо",
            sort: true,
        },
        {
            dataField: "candidateId",
            text: "ID",
            sort: true,
            formatter(cell, row, id) {
                if (cell) {
                    return (
                        <a
                            className="underline" 
                            onClick={() => {
                                viewClick(cell, row, id);
                            }}
                        >
                            {row.candidateId}
                        </a>
                    );
                }
            },
        },
        {
            dataField: "registrationNumber",
            text: "Регистрийн дугаар ",
            sort: true,
        },
        {
            dataField: "lastName",
            text: "Овог",
            sort: true,
        },
        {
            dataField: "firstName",
            text: "Нэр",
            sort: true,
            formatter(cell, row, id) {
                if (cell) {
                    return (
                        <a
                            className="underline"
                            onClick={() => {
                                viewClick(cell, row, id);
                            }}
                        >
                            {row.firstName}
                        </a>
                    );
                }
            },
        },
        {
            dataField: "birthDate",
            text: "Төрсөн огноо",
            sort: true,
        },
        {
            dataField: "gender",
            text: "Хүйс",
            sort: true,
        },
        {
            dataField: "gMobileNumber",
            text: "Холбоо барих",
            sort: true,
        },
        {
            dataField: "userEmail",
            text: "И-мэйл",
            sort: true
        },
        {
            dataField: "eyesh",
            text: "ЭЕШ мэдээлэл",
            sort: true
        },
    ];

    const contextMenus = [
        {
            key: 'edit',
            title: t('enroll.profileEdit'),
            icon: <i className='las la-edit dt-cm-item-icon'/>
        },
        {
            key: 'programEdit',
            title: t('enroll.programEdit'),
            icon: <i className='las la-edit dt-cm-item-icon' />,
        },
        {
            key: "status",
            title: "Төлөв солих",
            icon: <i className="las la-edit dt-cm-item-icon" />,
        },
    ];

    const viewClick = (cell, row) => {
        const url = `/enroll/candidate/profile`;
        history.push({
            pathname: url,
            state: {
                id: row.candidateId,
                program: row.programId,
            },
        });
    };

    const search = (params = {}) => {
        params.school = selectedSchool.id;
        params.season = seasonId;
        init(params);
    };

    const handleContextMenuClick = (id, key, row) => {
        if (id && key) {
            if (key == 'edit') {
                setCandidateId(row.userId)
                setCandidateProgramId(row.programId)
                setShowProfileEditModal(true)
            } else if (key == 'programEdit'){
                setCandidateId(row.candidateId)
                setShowProgramEditModal(true)
            } else if (key === "status") {
                console.log(row)
                setCandidateId(row.candidateId)
                setSelectedRow(row)
                setShowStatusModal(true);
            }
        }
    };

    const onModalClose = () => {
        setShowProfileEditModal(false);
        setShowProgramEditModal(false);
        setCandidateId(null)
    };

    const onModalStatusClose = () => {
        setShowStatusModal(false);
        setCandidateId(null)
        setSelectedRow(null)
    };

    const onSubmitProfileEdit = (params) => {
        dispatch(setLoading(true));
        fetchRequest(candidateProfileEdit, "POST", params)
            .then((res) => {
                if (res.success) {
                    let cloneData = {
                        page: tableState.page,
                        pageSize: tableState.pageSize,
                        search: tableState.search,
                        sort: tableState.sort,
                        order: tableState.order,
                        school: selectedSchool.id,
                        program: programId,
                        season: seasonId,
                    };
                    init(cloneData);

                    message(res?.data?.message, true);
                    setShowProfileEditModal(false);
                    dispatch(setLoading(false));
                } else {
                    message(res.data.message, false);
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t("errorMessage.title"));
            });
    }

    const onSubmitProgramEdit = (params) => {
        dispatch(setLoading(true));
        fetchRequest(editCandidateProgram, "POST", params)
            .then((res) => {
                if (res.success) {
                    let cloneData = {
                        page: tableState.page,
                        pageSize: tableState.pageSize,
                        search: tableState.search,
                        sort: tableState.sort,
                        order: tableState.order,
                        school: selectedSchool.id,
                        program: programId,
                        season: seasonId,
                    };
                    init(cloneData);

                    message(res?.data?.message, true);
                    setShowProgramEditModal(false);
                    dispatch(setLoading(false));
                } else {
                    message(res.data.message, false);
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t("errorMessage.title"));
            });
    }

    const handleInteraction = tableObject => {
        if(tableObject.search){
            let cloneData = {
                page: tableState.search == tableObject.search ? tableObject.page : 1,
                pageSize: tableObject.pageSize,
                search: tableObject.search,
                sort: tableObject.sort,
                order: tableObject.order,
            };

            setTableState(cloneData)
            localStorage.setItem(tableStoreData, JSON.stringify(cloneData));

            cloneData.school = selectedSchool.id;
            cloneData.season = seasonId;
            cloneData.program = programId;
            init(cloneData)
        } else {
            if(tableObject.page){
                setTableState(tableObject)
                let cloneData = {
                    page: tableObject.page,
                    pageSize: tableObject.pageSize,
                    search: tableObject.search,
                    sort: tableObject.sort,
                    order: tableObject.order
                };

                localStorage.setItem(tableStoreData, JSON.stringify(cloneData));

                cloneData.school = selectedSchool.id;
                cloneData.season = seasonId;
                cloneData.program = programId;
                init(cloneData)
            }
        }
    }

    const downloadCandidateData = (params) => {
        dispatch(setLoading(true));
        fetchRequest(registeredCandidatesDownloadEyesh, "POST", params)
            .then((res) => {
                console.log('res', res)
                if (res.success) {
                    if(res?.data?.totalCount == 0){
                        let cloneData = {
                            page: tableState.page,
                            pageSize: tableState.pageSize,
                            search: tableState.search,
                            sort: tableState.sort,
                            order: tableState.order,
                            school: selectedSchool.id,
                            program: programId,
                            season: seasonId,
                        };
                        init(cloneData);
    
                        message(res?.data?.message, true);
                        dispatch(setLoading(false));
                    } else {
                        let params = {
                            page: res?.data?.page
                        }

                        downloadCandidateData(params)
                    }
                } else {
                    message(res.data.message, false);
                }
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t("errorMessage.title"));
            });
    }

    const handleClick = (isNotFound = 0) => {
        let params = {}

        if(isNotFound){
            params = {
                page: 1,
                notFound: 1
            }
        } else{
            params = {
                page: 1
            }
        }

        downloadCandidateData(params)
    }

    const handleOnFinish = () => {
        onModalStatusClose()
        let params = {
            school: selectedSchool.id,
            season: seasonId || '',
            page: tableState.page,
            pageSize: tableState.pageSize,
            search: tableState.search,
            sort: tableState.sort,
            order: tableState.order
        };
        init(params);
    }

    return (
        <>
            <div className="search-component">
                <RegSearch 
                    onSearch={search} 
                    seasons={seasons}
                    enrolls={enrolls}
                    programs={programs}
                    setSelectedSeasonId={setSeasonId}
                    setSelectedProgramId={setProgramId}
                />
            </div>
            <div className="table-component">
                <Card>
                    <Card.Body>
                        <div className="col-12 pl-0">
                            <div>
                                <button
                                    className="btn btn-primary btn-shadow"
                                    onClick={() => handleClick(0)}
                                >
                                    ЭЕШ мэдээлэл татах
                                </button>
                                <button
                                    className="btn btn-primary btn-shadow ml-3"
                                    onClick={() => handleClick(1)}
                                >
                                    Мэдээлэл олдоогүй сурагчдыг дахин татах
                                </button>
                                <table className='mt-4'>
                                    <thead>
                                        <tr>
                                            <th colSpan={2}>ЭЕШ мэдээлэл таталтын мэдээлэл</th>
                                        </tr>
                                    </thead>
                                    <tbody >
                                        <tr>
                                            <td className='text-right'>
                                                Мэдээлэл татсан тоо:
                                            </td>
                                            <td className="pl-2">
                                                <b>{eyeshInfo?.downloaded || 0}</b>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-right'>
                                                Мэдээлэл татагдаагүй тоо:
                                            </td>
                                            <td className="pl-2">
                                                <b>{eyeshInfo?.leftCount || 0}</b>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-right'>
                                                Мэдээлэл олдоогүй тоо:
                                            </td>
                                            <td className="pl-2">
                                                <b>{eyeshInfo?.notFound || 0}</b>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <DTable
                            remote
                            columns={columns}
                            config={config}
                            data={list}
                            selectMode={'radio'}
                            contextMenus={contextMenus}
                            onContextMenuItemClick={handleContextMenuClick}
                            onInteraction={handleInteraction}
                            totalDataSize={totalCount}
                        />
                    </Card.Body>
                </Card>
            </div>
            {
                showProfileEditModal && 
                <ProfileEdit
                    id={candidateId} 
                    programId={candidateProgramId}
                    onClose={onModalClose} 
                    onSubmit={onSubmitProfileEdit}
                />
            }
            {
                showProgramEditModal && 
                <ProgramEdit
                    id={candidateId} 
                    onClose={onModalClose} 
                    onSubmit={onSubmitProgramEdit}
                />
            }
            {showStatusModal && (
                <ChangeStatus
                    onClose={onModalStatusClose}
                    id={candidateId}
                    onFinish={handleOnFinish}
                    // phase={searchState.phase}
                    // enroll={searchState.type}
                    program={selectedRow.programId}
                    // toStatus={toStatusName}
                />
            )}
        </>
    );
};

export default Registrants;
