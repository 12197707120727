import React, {useEffect, useRef, useState} from 'react';
import Forms from "../../../modules/Form/Forms";
import { Button, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import '../movement.scss';
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import { getSchoolClassAddStudent, addSchoolClassAddStudent, studentApiSearch, studentSearchByRegister } from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import DiscountAdd from "./DiscountAdd";

const nationality = [
    {
        value: 'Халх',
        text: 'Халх'
    },
    {
        value: 'Казак',
        text: 'Казак'
    },
    {
        value: 'Дөрвөд',
        text: 'Дөрвөд'
    },
    {
        value: 'Баяд',
        text: 'Баяд'
    },
    {
        value: 'Буриад',
        text: 'Буриад'
    },
    {
        value: 'Захчин',
        text: 'Захчин'
    },
    {
        value: 'Дарьганга',
        text: 'Дарьганга'
    },
    {
        value: 'Урианхай',
        text: 'Урианхай'
    },
    {
        value: 'Дархад',
        text: 'Дархад'
    },
    {
        value: 'Өөлд',
        text: 'Өөлд'
    },
    {
        value: 'Хотогойд',
        text: 'Хотогойд'
    },
    {
        value: 'Торгууд',
        text: 'Торгууд'
    },
    {
        value: 'Хотон',
        text: 'Хотон'
    },
    {
        value: 'Мянгад',
        text: 'Мянгад'
    },
    {
        value: 'Тува',
        text: 'Тува'
    },
    {
        value: 'Барга',
        text: 'Барга'
    },
    {
        value: 'Үзэмчин',
        text: 'Үзэмчин'
    },
    {
        value: 'Элжигэн',
        text: 'Элжигэн'
    },
    {
        value: 'Сартуул',
        text: 'Сартуул'
    },
    {
        value: 'Хамниган',
        text: 'Хамниган'
    },
    {
        value: 'Цаатан',
        text: 'Цаатан'
    },
    {
        value: 'Узбек',
        text: 'Узбек'
    },
    {
        value: 'Харчин',
        text: 'Харчин'
    },
    {
        value: 'Цахар',
        text: 'Цахар'
    },
    {
        value: 'Харчин',
        text: 'Харчин'
    },
    {
        value: 'Цахар',
        text: 'Цахар'
    },
    {
        value: 'Орос  /монголын харъ/',
        text: 'Орос  /монголын харъ/'
    },
    {
        value: 'Хятад /харъяат/',
        text: 'Хятад /харъяат/'
    },
    {
        value: 'Бусад /харъяат/',
        text: 'Бусад /харъяат/'
    }
]

const AddStudent = ({
    location: { state: {
        classId = null,
        path = '',
    } = {} } = {}, }) =>
{
    const dispatch = useDispatch();
    const history = useHistory();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const { t } = useTranslation();
    const languages = useSelector(state => state?.languages?.languages || []).filter(lang => lang.code !== 'mn');
    const [educationLevel, setEducationLevel] = useState(null);
    const [approach, setApproach] = useState(null);
    const [academicProgram, setAcademicProgram] = useState(null);
    const [academicProgramId, setAcademicProgramId] = useState(null);
    const [program, setProgram] = useState(null);
    const [programId, setProgramId] = useState(null);
    const [type, setType] = useState(null);
    const [seasonName, setSeasonName] = useState(null);
    const [countries, setCountries] = useState([]);
    const [isApi, setIsApi] = useState([]);
    const [value, setValue] = useState(null);
    const [student, setStudent] = useState(null);
    const [country, setCountry] = useState(null);
    const [studentCode, setStudentCode] = useState(null);
    const [person, setPerson] = useState(false);
    const [address, setAddress] = useState('');
    const [birthPlace, setBirthPlace] = useState(null);
    const [nationalityValue, setNationalityValue] = useState(null);
    const [addressSegments, setAddressSegments] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [beforeEducations, setBeforeEducations] = useState([]);
    const [beforeEducation, setBeforeEducation] = useState(null);
    const [segment, setSegment] = useState(null);
    const [district, setDistrict] = useState(null);
    const [showDiscountModal, setShowDiscountModal] = useState(false);

    const gender = [
        {text: 'Эрэгтэй', value: 'm'},
        {text: 'Эмэгтэй', value: 'f'},
    ];

    const formRef = useRef();
    const translationFormRef = useRef();

    useEffect(() => {
        const params = {
            school: schoolId,
            class: classId
        };

        dispatch(setLoading(true));
        fetchRequest(getSchoolClassAddStudent, 'GET', params)
            .then(response => {
                if (response.success)
                {
                    setApproach(response.data.approach);
                    setAcademicProgramId(response.data.academicProgramId);
                    setAcademicProgram(response.data.academicProgram);
                    setEducationLevel(response.data.educationLevel);
                    setProgram(response.data.program);
                    setProgramId(response.data.program?.id);
                    setType(response.data.type);
                    setSeasonName(response.data.seasonName);
                    setIsApi(response.data.isApi);
                    setCountries(response.data.countries.map(country => ({value: country.id, text: country.name})));
                    setAddressSegments(response.data.addressSegments);
                    setBeforeEducations(response.data.beforeEducations);
                } else
                {
                    message(response?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
            })
    }, []);

    useEffect(() => {
        if(isApi){
            let timeout;
            if (value) {
                timeout = setTimeout(() => {
                    const params = {
                        value,
                        academic: academicProgramId
                    };
                    dispatch(setLoading(true));
                    fetchRequest(studentApiSearch, 'GET', params)
                        .then(res => {
                            if (res.success) {
                                const { data } = res;
                                setStudent(data.students[0]);
                            } else {
                                message(res?.data?.message || t('errorMessage.title'))
                            }
                            dispatch(setLoading(false));
                        })
                        .catch(() => {
                            dispatch(setLoading(false));
                            message(t('errorMessage.title'))
                        })
                }, 1500);
            }
            return () => {
                clearTimeout(timeout);
            };
        }
    }, [value]);

    useEffect(() => {
        formRef?.current?.updateFields && formRef.current.updateFields(fields);
    }, [countries, student, person, addressSegments, districts, beforeEducations]);

    useEffect(() => {
        formRef?.current?.updateFields && formRef.current.updateFields(fields);
    }, [isApi]);

    const onRegisterChange = (value, evt, action) => {
        setValue(value.target.value);
    };

    const registerSearch = (action, value) => {
        if(action == 'click'){
            let params = {
                value
            };

            dispatch(setLoading(true));
            fetchRequest(studentSearchByRegister, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res;
                        setPerson(data.data);
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        }
    };

    const onCountryChange = (value) => {
        setCountry(value);
    };

    const onBirthPlaceChange = (value) => {
        setBirthPlace(value);
    };

    const onNationalityChange = (value) => {
        setNationalityValue(value);
    };

    const onAddressChange = (value) => {
        setAddress(value.target.value);
    };

    const onAddressSegmentChange = (value, evt, action) => {
        setSegment(value);
        setDistricts(evt?.children || [])
    };

    const onDistrictChange = (value) => {
        setDistrict(value);
    };

    const onBeforeEducationChange = (value) => {
        setBeforeEducation(value);
    };

    const fields = [
        {
            label: `${t('common.country')}*`,
            type: 'dropdown',
            required: true,
            value: country || null,
            multiple: false,
            options: countries,
            key: 'country',
            inputWidth: 300,
            labelWidth: '32%',
            clearable: true,
            searchable: true,
            onChange: onCountryChange,
            errorMessage: t('errorMessage.enterValue'),
        },
        {
            label: `${t('questionnaire.nationality')}*`,
            type: 'dropdown',
            required: true,
            value: nationalityValue || null,
            multiple: false,
            options: nationality,
            key: 'country',
            inputWidth: 300,
            labelWidth: '32%',
            clearable: true,
            searchable: true,
            onChange: onNationalityChange,
            errorMessage: t('errorMessage.enterValue'),
        },
        {
            label: `${t('questionnaire.birthPlace')}*`,
            type: 'dropdown',
            required: true,
            value: birthPlace || null,
            multiple: false,
            options: countries,
            key: 'country',
            inputWidth: 300,
            labelWidth: '32%',
            clearable: true,
            searchable: true,
            onChange: onBirthPlaceChange,
            errorMessage: t('errorMessage.enterValue'),
        },
        {
            label: `${t('setting.addressSegment.province')}*`,
            type: 'dropdown',
            required: true,
            value: segment || null,
            multiple: false,
            options: addressSegments,
            key: 'country',
            inputWidth: 300,
            labelWidth: '32%',
            clearable: true,
            searchable: true,
            onChange: onAddressSegmentChange,
            errorMessage: t('errorMessage.enterValue'),
        },
        {
            label: `${t('setting.addressSegment.district')}*`,
            type: 'dropdown',
            required: true,
            value: district || null,
            multiple: false,
            options: districts,
            key: 'country',
            inputWidth: 300,
            labelWidth: '32%',
            clearable: true,
            searchable: true,
            onChange: onDistrictChange,
            errorMessage: t('errorMessage.enterValue'),
        },
        {
            label: `${t('questionnaire.address')}*`,
            type: 'text',
            required: true,
            value: address || '',
            multiple: false,
            options: countries,
            key: 'country',
            inputWidth: 300,
            labelWidth: '32%',
            clearable: true,
            onChange: onAddressChange,
            errorMessage: t('errorMessage.enterValue'),
        },
        {
            label: `${t('person.registrationNumber')}`,
            type: 'text',
            required: true,
            value: value || '',
            key: 'registrationNumber',
            inputWidth: 300,
            labelWidth: '32%',
            onChange: onRegisterChange,
            isExtendedButton: true,
            isExtendedButtonText: t('common.search'),
            isExtendedButtonClass: 'btn btn-outline-success ml-2',
            onExtendedButtonChange: registerSearch,
            errorMessage: t('errorMessage.enterValue'),
        },
        {
            label: `${t('common.familyName')}`,
            type: 'text',
            required: false,
            value: '',
            key: 'familyName',
            inputWidth: 300,
            labelWidth: '32%',
        },
        {
            label: `${t('common.lastName')}`,
            type: 'text',
            required: true,
            value: person?.lastName || '',
            key: 'lastName',
            inputWidth: 300,
            labelWidth: '32%',
            disabled: isApi || false,
            errorMessage: t('errorMessage.enterValue'),
        },
        {
            label: `${t('common.firstName')}`,
            type: 'text',
            required: true,
            value: person?.firstName || '',
            key: 'firstName',
            inputWidth: 300,
            labelWidth: '32%',
            disabled: isApi || false,
            errorMessage: t('errorMessage.enterValue'),
        },
        {
            label: `${t('common.gender')}`,
            type: 'dropdown',
            required: true,
            value: person?.gender && person?.gender == 1 ? 'm' : 'f' || null,
            options: gender,
            key: 'gender',
            inputWidth: 300,
            labelWidth: '32%',
            clearable: true,
            disabled: isApi || false,
            errorMessage: t('errorMessage.enterValue'),
        },
        {
            label: `${t('person.birthdate')}`,
            type: 'date',
            dateCustomButton: false,
            required: true,
            value: '',
            key: 'birthdate',
            inputWidth: 300,
            labelWidth: '32%',
            errorMessage: t('errorMessage.selectDate'),
        },
        {
            label: `${t('questionnaire.preview')}`,
            type: 'dropdown',
            required: false,
            value: beforeEducation || '',
            key: 'beforeEducation',
            options: beforeEducations,
            onChange: onBeforeEducationChange,
            inputWidth: 300,
            labelWidth: '32%',
        },
        {
            label: `${t('questionnaire.passportNumber')}`,
            type: 'text',
            required: false,
            value: '',
            key: 'passportNumber',
            inputWidth: 300,
            labelWidth: '32%',
        },
        {
            label: `${'Иргэн эсэх'}`,
            type: 'checkbox',
            required: false,
            value: '',
            key: 'isCitizen',
            inputWidth: 300,
            labelWidth: '32%',
        },
    ];

    const getFormTranslations = () => {
        const array = [];
        for (const language of languages) {
            array.push({
                type: 'jsx',
                value: <div key={`${language.code}-separator`} className="separator separator-dashed my-7"/>
            });
            array.push({
                label: `${t('common.familyName')} - ${language.name} (${language.code})`,
                type: 'text',
                required: false,
                value: '',
                key: 'familyName',
                inputWidth: 300,
                labelWidth: '32%',
                langCode: language.code
            });
            array.push({
                label: `${t('common.lastName')} - ${language.name} (${language.code})`,
                type: 'text',
                required: false,
                value: '',
                key: 'lastName',
                inputWidth: 300,
                labelWidth: '32%',
                langCode: language.code
            });
            array.push({
                label: `${t('common.firstName')} - ${language.name} (${language.code})`,
                type: 'text',
                required: false,
                value: '',
                key: 'firstName',
                inputWidth: 300,
                labelWidth: '32%',
                langCode: language.code
            });
        }
        return array;
    };

    const handleSaveClick = () => {
        const [ formValid, formValue ] = formRef.current.validate();
        const [ translateValid, translateValues ] = translationFormRef.current.validate();

        if(!formValue[6].value){
            message('Төрсөн огноо оруулна уу', false);
        }

        if (formValid && translateValid) {
            let params = {
                school: schoolId,
                class: classId,
                country: formValue[0].value,
                nationality: formValue[1].value,
                birthPlace: formValue[2].value,
                province: formValue[3].value,
                district: formValue[4].value,
                address: formValue[5].value,
                register: formValue[6].value,
                familyName: formValue[7].value,
                lastName: formValue[8].value,
                firstName: formValue[9].value,
                gender: formValue[10].value,
                birthDay: formValue[11].value,
                before: formValue[12].value,
                passport: formValue[13].value,
                isCitizen: formValue[14].value ? 1 : 0,
            };

            let translations = [];
            if(languages && languages.length > 0){
                languages.map(language => {
                    if(language.code !== 'mn'){
                        const familyNameObject = translateValues.find(val => val.key === 'familyName' && val.langCode === language.code && val.value);
                        const lastNameObject = translateValues.find(val => val.key === 'lastName' && val.langCode === language.code && val.value);
                        const firstNameObject = translateValues.find(val => val.key === 'firstName' && val.langCode === language.code && val.value);
                        const registerNumberObject = translateValues.find(val => val.key === 'registrationNumber' && val.langCode === language.code && val.value);

                        translations.push({
                            langCode: language.code,
                            familyName: familyNameObject ? familyNameObject.value : '',
                            lastName: lastNameObject ? lastNameObject.value : '',
                            firstName: firstNameObject ? firstNameObject.value : '',
                            registerNumber: registerNumberObject ? registerNumberObject.value : ''
                        })
                    }
                });

                params.translations = JSON.stringify(translations);
            }

            dispatch(setLoading(true));
            fetchRequest(addSchoolClassAddStudent, 'POST', params)
                .then(response => {
                    if (response.success) {
                        message(response.data.message, true);
                        setStudentCode(response?.data?.studentCode)
                        if(student && student.Dun1 > 0){
                            const percent = (student?.Dun1 * 100)/800;
                            if( percent >= 60){
                                setShowDiscountModal(true)
                            } else {
                                history.push({
                                    pathname: path ? path : '/movement/class',
                                    state: {
                                        classId
                                    }
                                });
                            }
                        } else {
                            history.push({
                                pathname: path ? path : '/movement/class',
                                state: {
                                    classId
                                }
                            });
                        }
                        dispatch(setLoading(false));
                    } else {
                        dispatch(setLoading(false));
                        message(response?.data?.message, false);
                    }
                })
                .catch(() => {
                    dispatch(setLoading(false));
                })
        }
    };

    const onModalClose = () => {
        setShowDiscountModal(false);
        history.push({
            pathname: path ? path : '/movement/class',
            state: {
                classId
            }
        });
    };

    return (
        <div>
            <Card>
                <Card.Body>
                    <table className='class-info-table'>
                        <tbody>
                        <tr>
                            <td>{t('curriculum.educationLevel')}</td>
                            <th>{educationLevel}</th>
                        </tr>
                        <tr>
                            <td>{t('curriculum.approach')}</td>
                            <th>{approach}</th>
                        </tr>
                        <tr>
                            <td>{t('curriculum.type')}</td>
                            <th>{type}</th>
                        </tr>
                        <tr>
                            <td>{t('curriculum.title')}</td>
                            <th>{academicProgram}</th>
                        </tr>
                        <tr>
                            <td>{t('curriculum.profession')}</td>
                            <th>{program?.name}</th>
                        </tr>
                        <tr>
                            <td>{t('movement.class.admissionSeason')}</td>
                            <th>{seasonName}</th>
                        </tr>
                        <tr>
                            <td>{t('curriculum.totalCredits')}</td>
                            <th>{program?.totalCredit}</th>
                        </tr>
                        {
                            student && 
                            <>
                                <tr>
                                    <td>{student?.Hicheel1}</td>
                                    <th>{student?.Dun1}</th>
                                </tr>
                                <tr>
                                    <td>{student?.Hicheel2}</td>
                                    <th>{student?.Dun2}</th>
                                </tr>
                                <tr>
                                    <td>Хувь</td>
                                    <th>{student?.Dun1 > 0 ? ((student?.Dun1 * 100)/800).toFixed(0, 2) + '%' : 0}</th>
                                </tr>
                            </>
                        }
                        </tbody>
                    </table>
                    <Forms
                        ref={formRef}
                        fields={fields}
                    />
                    <Forms
                        ref={translationFormRef}
                        fields={getFormTranslations()}
                    />
                </Card.Body>
                <Card.Footer>
                    <div className='text-center'>
                        <Link
                            to={{
                                pathname: path ? path : '/movement/class',
                                state: {
                                    classId
                                }
                            }}
                            className="btn btn-link bolder"
                        >
                            {t('common.back')}
                        </Link>
                        <Button
                            variant="success btn-shadow"
                            onClick={handleSaveClick}
                        >
                            {t('common.save')}
                        </Button>
                    </div>
                </Card.Footer>
            </Card>
            {
                showDiscountModal && (
                    <DiscountAdd
                        onClose={onModalClose}
                        academicProgramId={academicProgramId}
                        programId={programId}
                        student={student}
                        studentCode={studentCode}
                    />
                )
            }
        </div>
    )
};

export default AddStudent;