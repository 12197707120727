import React, {useEffect, useRef, useState} from 'react';
import { Button, ButtonToolbar, Card, Modal, Tab, Tabs } from 'react-bootstrap';
import TreeView from "../../../modules/TreeView";
import { useTranslation } from "react-i18next";
import DTable from "../../../modules/DataTable/DTable";
import { LinearProgress } from "@material-ui/core";
import Select from "../../../modules/Form/Select";
import './selection.scss';
import { useHistory } from "react-router-dom";
import {setLoading} from "../../../../redux/action";
import {fetchRequest} from "../../../../utils/fetchRequest";
import {
    selectionStudentAdd,
    selectionGetStudentData,
    selectionGetSubjectData,
    selectionStudentIndex,
    selectionViewOne
} from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import {useDispatch, useSelector} from "react-redux";
import Forms from "../../../modules/Form/Forms";

const OneView = ({
    location: { state: {
        classId = null,
        season = null
    } = {} } = {},
}) => {
    const { t } = useTranslation();
    const formRef = useRef();
    const history = useHistory();
    const dispatch = useDispatch();

    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [ students, setStudents ] = useState([]);
    const [ totalStudentCount, setTotalStudentCount ] = useState(0);
    const [ selectedStudentCount, setSelectedStudentCount ] = useState(0);
    const [ totalCount, setTotalCount ] = useState(0);
    const [ classInfo, setClassInfo ] = useState(null);
    const [ showEditModal, setShowEditModal ] = useState(false);
    const [ selection1s, setSelection1s ] = useState([]);
    const [updateView, setUpdateView] = useState(false);
    const [ canSelect, setCanSelect ] = useState(false);

    const [ selectionData, setSelectionData ] = useState([]);
    const [ selectedDetails, setSelectedDetails ] =  useState([]);
    const [ totalCredit, setTotalCredit ] = useState(null);
    const [ totalCreditPrice, setTotalCreditPrice ] = useState(null);

    const [ programDetails, setProgramDetails ] = useState([]);
    const [ addModal, setAddModal ] = useState(false);
    const [ deleteModal, setDeleteModal ] = useState(false);
    const [ configId, setSelectedConfigId ] = useState(null);
    const [ selectedIds, setSelectedIds ] = useState([]);
    const [ studentId, setStudentId ] = useState(null)

    const config = {
        showAllData: true,
        showPagination: false,
        showFilter: true,
    };

    useEffect(() => {
        let params = {
            school: schoolId,
            class: classId,
            season: season
        }

        fetchData(params);
    }, []);

    const fetchData = params => {
        dispatch(setLoading(true));
        fetchRequest(selectionViewOne, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setStudents(data?.students || []);
                    setClassInfo(data?.classInfo || null);

                    let selectedStudentCount = 0;
                    
                    if(data.students && data.students.length > 0){
                        for(let i = 0; i < data.students.length; i++){
                            if(data.students[i].isSelection){
                                selectedStudentCount += data.students[i].isSelection;    
                            }
                        }
                    }

                    setTotalStudentCount(data.students.length);
                    setSelectedStudentCount(selectedStudentCount);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const byClassColumns = [
        {
            dataField: "studentCode",
            text: t('student.code'),
            sort: true,
        },
        {
            dataField: "lastName",
            text: t('common.lastName'),
            sort: true,
        },
        {
            dataField: "firstName",
            text: t('common.firstName'),
            sort: true,
        },
        {
            dataField: "registrationNumber",
            text: t('common.registerNumber'),
            sort: true,
        },
        {
            dataField: "gender",
            text: t('common.gender'),
            sort: true,
        },
        {
            dataField: "countryName",
            text: t('common.country'),
            sort: true,
        },
        {
            dataField: "isSelection",
            text: t('selection.selected'),
            sort: true,
            align: 'right',
            formatter: (cell) => {
                if(cell){
                    return 'Тийм'
                }
                return 'Үгүй'
            },
        },
    ];

    const handlerIcon = (action, content, row, rowIndex) => {
        let cloneSelectionData = [...selection1s];
        let credits = 0;
        let price = 0;
        let count = 0;

        if(cloneSelectionData && cloneSelectionData.length > 0){
            for(let i = 0; i < cloneSelectionData.length; i++){
                Object.keys(cloneSelectionData[i].dtls).map((category, ci) => { 
                    Object.keys(cloneSelectionData[i].dtls[category]).map((type, ti) => {
                        for(let typeList of cloneSelectionData[i].dtls[category][type]){
                            if(typeList.id == row.id){
                                cloneSelectionData[i].dtls[category][type].splice(rowIndex, 1);
                            }
                        }
                    })
                })
            }
        }

        if(cloneSelectionData && cloneSelectionData.length > 0){
            for(let i = 0; i < cloneSelectionData.length; i++){
                Object.keys(cloneSelectionData[i].dtls).map((category, ci) => { 
                    Object.keys(cloneSelectionData[i].dtls[category]).map((type, ti) => {
                        for(let typeList of cloneSelectionData[i].dtls[category][type]){
                            credits = credits + parseInt(typeList.credit);
                            price = price + parseInt(typeList.totalPrice);
                            count += 1;
                        }
                    })
                })

                cloneSelectionData[i]['totalCredit'] = credits;
                cloneSelectionData[i]['totalCreditPrice'] = price;
                cloneSelectionData[i]['totalCount'] = count;
            }
        }

        setSelection1s(cloneSelectionData);
        let isUpdateView = updateView;
        setUpdateView(!isUpdateView);
    };

    const iconFormatter = (cell, row, rowIndex, extraData) => {
        return (
            <Button onClick={() => handlerIcon('delete', cell, row, rowIndex, extraData)} className="btn btn-danger m-btn m-btn--icon m-btn--icon-only m-btn--circle-28">
                <i className="mini flaticon2-cross"> </i>
            </Button>
        );
    };

    const studentIndexColumns = [
        // {
        //     dataField: "status",
        //     text: t('common.status'),
        //     sort: true,
        // },
        {
            dataField: "code",
            text: t('subject.index'),
            sort: false,
        },
        {
            dataField: "name",
            text: t('subject.name'),
            sort: false,
        },
        {
            dataField: "credit",
            text: t('subject.credit'),
            sort: false,
        },
        {
            dataField: "before",
            text: t('subject.previous'),
            sort: false,
        },
        {
            dataField: "qwe",
            text: t('subject.time'),
            sort: false,
        },
        {
            dataField: "price",
            text: t('assessment.tuition'),
            sort: false,
            align: 'right',
        },
        {
            isDummyField: true,
            dataField: "action",
            text: "",
            sort: false,
            attrs: Attribute, //formatter ашиглаж байгаа бол attrs заавал дуудах ёстой.
            formatter: iconFormatter,
        }
    ]

    const columns = [
        // {
        //     dataField: "status",
        //     text: t('common.status'),
        //     sort: true,
        // },
        {
            dataField: "code",
            text: t('subject.index'),
            sort: false,
        },
        {
            dataField: "name",
            text: t('subject.name'),
            sort: false,
        },
        {
            dataField: "credit",
            text: t('subject.credit'),
            sort: false,
        },
        {
            dataField: "before",
            text: t('subject.previous'),
            sort: false,
        },
        {
            dataField: "qwe",
            text: t('subject.time'),
            sort: false,
        },
        {
            dataField: "price",
            text: t('assessment.tuition'),
            sort: false,
        },
        {
            dataField: "status",
            text: t('common.status'),
            sort: false,
            style: {padding: 3},
            headerStyle: () => {
                return {
                    width: 80
                }
            },
            formatter(cell, row) {
                if(row.status && row.success){
                    return <div style={{padding: 7}}>{row.status}</div>
                }

                return ''
            },
        },
        {
            dataField: "scoreType",
            text: t('assessment.title'),
            sort: false,
            style: {padding: 3},
            headerStyle: () => {
                return {
                    width: 80
                }
            },
            formatter(cell, row) {
                if(row.scoreType && row.isShow){
                    return <div style={{display: 'flex'}}>
                        <div style={{padding: 7}}><b>{row.scoreType}</b></div>
                        <button style={{width: '100%'}} className='btn btn-success' onClick={() => handlerHideButton(row)}>
                            {t('action.back')}
                        </button>
                    </div>
                } else if(row.scoreType){
                    return <button style={{width: '100%'}} className='btn btn-success' onClick={() => handlerViewButton(row)}>
                        {t('action.view')}
                    </button>;
                }

                return ''
            },
        },
    ];

    const handlerViewButton = (row) => {
        let cloneProgramDtl = [...programDetails];

        for(let i = 0; i < cloneProgramDtl.length; i++){
            for(let d = 0; d < cloneProgramDtl[i].details.length; d++){
                for(let l = 0; l < cloneProgramDtl[i].details[d].list.length; l++){
                    for(let t = 0; t < cloneProgramDtl[i].details[d].list[l].typeList.length; t++){
                        for(let s = 0; s < cloneProgramDtl[i].details[d].list[l].typeList[t].subjectList.length; s++){
                            if(row.id == cloneProgramDtl[i].details[d].list[l].typeList[t].subjectList[s].id){
                                cloneProgramDtl[i].details[d].list[l].typeList[t].subjectList[s].isShow = true;
                            }
                        }
                    }
                }
            }
        }

        setProgramDetails(cloneProgramDtl)
    }

    const handlerHideButton = (row) => {
        let cloneProgramDtl = [...programDetails];

        for(let i = 0; i < cloneProgramDtl.length; i++){
            for(let d = 0; d < cloneProgramDtl[i].details.length; d++){
                for(let l = 0; l < cloneProgramDtl[i].details[d].list.length; l++){
                    for(let t = 0; t < cloneProgramDtl[i].details[d].list[l].typeList.length; t++){
                        for(let s = 0; s < cloneProgramDtl[i].details[d].list[l].typeList[t].subjectList.length; s++){
                            if(row.id == cloneProgramDtl[i].details[d].list[l].typeList[t].subjectList[s].id){
                                cloneProgramDtl[i].details[d].list[l].typeList[t].subjectList[s].isShow = false;
                            }
                        }
                    }
                }
            }
        }

        setProgramDetails(cloneProgramDtl)
    }

    const Attribute = (cell, row, rowIndex, colIndex) => {
    };

    const contextMenus = [
        {
            key: 'edit',
            title: t('common.edit'),
            icon: <i className='las la-edit dt-cm-item-icon'/>
        }
    ];

    const handleContextMenuClick = (id, key) => {
        if (id && key) {
            if(key == 'edit'){
                let params = {
                    selection: 1,
                    school: schoolId,
                    student: id
                }

                dispatch(setLoading(true));
                fetchRequest(selectionStudentIndex, 'GET', params)
                    .then(res => {
                        if (res.success) {
                            const { data } = res;
                            let selection1Data = data.selection1s;
                            setSelectionData(data.selectionData);
                            setStudentId(id);

                            if(selection1Data && selection1Data.length > 0){
                                if(data.selectionData && data.selectionData.length > 0){
                                    let details = data.selectionData;
                                    for (let i = 0; i < selection1Data.length; i++){
                                        for(let d = 0; d < details.length; d++){
                                            if(selection1Data[i].seasonId == details[d].seasonId){
                                                selection1Data[i].selectionData = details[d];

                                                const groupByCategory = details[d].subjects.reduce((group, product) => {
                                                    const { categoryName } = product;
                                        
                                                    group[categoryName] = group[categoryName] ?? [];
                                                    group[categoryName].push(product);
                                        
                                                    return group;
                                                }, {});
                                        
                                                for (var categories in groupByCategory){
                                                    const typeNameList = groupByCategory[categories].reduce((group, product) => {
                                                        const { typeName } = product;
                                            
                                                        group[typeName] = group[typeName] ?? [];
                                                        group[typeName].push(product);
                                            
                                                        return group;
                                                    }, {});
                                        
                                                    groupByCategory[categories] = typeNameList;
                                                }

                                                selection1Data[i].dtls = groupByCategory;
                                                selection1Data[i].totalCount = details[d].totalSubjectCount;
                                                selection1Data[i].totalCredit = details[d].totalCredit;
                                                selection1Data[i].totalCreditPrice = details[d].totalCreditPrice;
                                            }
                                        }
                                    }
                                }

                                setSelection1s(selection1Data)
                            }
                        } else {
                            message(res?.data?.message || t('errorMessage.title'))
                        }
                        dispatch(setLoading(false));
                    })
                    .catch(() => {
                        dispatch(setLoading(false));
                        message(t('errorMessage.title'))
                    })
                setShowEditModal(true)
            }
        }   
    };

    const onUserInteraction = (object) => {
        let params = {
            school: schoolId,
            selection: 1,
            ...object,
        };

        fetchData(params)
    };

    const handleClassBackClick = () => {
        history.push({
            pathname: '/selection/1'
        });
    };

    const onEditClose = () => {
        setShowEditModal(false)
    }

    const handlerSubjectAddClick = (configId) => {
        let dtls = [];
        setProgramDetails([]);

        if(selection1s && selection1s.length > 0){
            for (let i = 0; i < selection1s.length; i++){
                if(configId == selection1s[i].id){
                    if(selection1s[i].dtls){
                        Object.keys(selection1s[i].dtls).map((category, ci) => {
                            Object.keys(selection1s[i].dtls[category]).map((type, ti) => {
                                for(let typeList of selection1s[i].dtls[category][type]){
                                    dtls.push(typeList.id)
                                }
                            })
                        })
                    }
                }
            }
        }

        const params = {
            school: schoolId,
            config: configId,
            dtls: JSON.stringify(dtls),
            student: studentId
        };

        dispatch(setLoading(true));
        fetchRequest(selectionStudentAdd, 'GET', params)
            .then(response => {
                if (response.success) {
                    setProgramDetails(response.data.programDetails);
                    setAddModal(true);
                    setSelectedConfigId(configId)
                } else {
                    message(response.data.message, false);
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
            })
    };

    const onSaveClick = (publish = 0, configId) => {
        let selectedDtls = [];
        let studentTotalCredit = 0;
        if(configId){
            if(selection1s && selection1s.length > 0){
                for(let i = 0; i < selection1s.length; i++){
                    if(configId == selection1s[i].id){
                        if(selection1s[i].dtls){
                            Object.keys(selection1s[i].dtls).map((category, ci) => {
                                Object.keys(selection1s[i].dtls[category]).map((type, ti) => {
                                    for(let typeList of selection1s[i].dtls[category][type]){
                                        studentTotalCredit = studentTotalCredit + parseInt(typeList.credit);
                                        selectedDtls.push(typeList.id)
                                    }
                                })
                            })
                        }
                    }
                }
            }
        }

        const params = {
            school: schoolId,
            config: configId,
            ids: JSON.stringify(selectedDtls),
            approved: publish,
            student: studentId
        };

        dispatch(setLoading(true));
        fetchRequest(selectionStudentAdd, 'POST', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    let cloneSelection1s = [...selection1s];

                    if(cloneSelection1s && cloneSelection1s.length > 0) {
                        for (let i = 0; i < cloneSelection1s.length; i++) {
                            if (data?.selectionData?.seasonId == cloneSelection1s[i].seasonId) {
                                cloneSelection1s[i].selectionData = data.selectionData;
                            }
                        }
                    }
                    // setSelection1s(cloneSelection1s);
                    let params = {
                        school: schoolId,
                        class: classId,
                        season: season
                    }
            
                    fetchData(params);
                    setShowEditModal(false)
                    message(res?.data?.message, true)
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            })
    };

    const handleAddModalClose = () => {
        setAddModal(false);
        setSelectedIds([]);
    };

    const handleDeleteModalClose = () => {
        setDeleteModal(false);
    };

    const handlerOnDeleteClick = () => {
        // const params = {
        //     school: selectedSchool.id,
        //     teacher: selectedRecord ? selectedRecord.id : null,
        // }

        // fetchRequest(teacherDelete, 'POST', params, false)
        //     .then(response => {
        //         if (response.success) {
        //             setDeleteModal(false);
        //             setTeachers(response.data.teachers);
        //             message(response.data.message, true);
        //         } else {
        //             message(response.data.message, false);
        //         }
        //     })
        //     .catch(err => {
        //     })
    };

    const onSelect = (row, isSelect, rowIndex) => {
        let ids = [ ...selectedIds ];
        if (row.id)
        {
            if (isSelect)
            {
                ids.push(row.id)
            } else {
                for (let i = 0; i < ids.length; i++)
                {
                    if (parseInt(ids[i]) === parseInt(row.id))
                    {
                        ids.splice(i, 1);
                    }
                }
            }
        }

        setSelectedIds(ids);
    };

    const onSelectAll = (isSelect, rows) => {
        let ids = [ ...selectedIds ];
        if (isSelect) {
            for (let i = 0; i < rows.length; i++) {
                ids.push(rows[i].id);
            }
        } else {
            for (let i = 0; i < rows.length; i++) {
                for (let s = 0; s < ids.length; s++) {
                    if (parseInt(ids[s]) === parseInt(rows[i].id)) {
                        ids.splice(s, 1);
                    }
                }
            }
        }

        setSelectedIds(ids);
    };

    const handleAddModalSubmit = () => {
        const cloneSelection1s = [...selection1s];

        let dtlArray = [];
        if(cloneSelection1s && cloneSelection1s.length > 0){
            if(cloneSelection1s && cloneSelection1s.length > 0){
                for (let i = 0; i < cloneSelection1s.length; i++){
                    if(configId == cloneSelection1s[i].id){
                        if(cloneSelection1s[i].dtls){
                            Object.keys(cloneSelection1s[i].dtls).map((category, ci) => {
                                Object.keys(cloneSelection1s[i].dtls[category]).map((type, ti) => {
                                    for(let typeList of cloneSelection1s[i].dtls[category][type]){
                                        dtlArray.push(typeList)
                                    }
                                })
                            })
                        }
                    }
                }
            }
        }

        for(let i = 0; i < programDetails.length; i++){
            for(let d = 0; d < programDetails[i].details.length; d++){
                for(let l = 0; l < programDetails[i].details[d].list.length; l++){
                    for(let t = 0; t < programDetails[i].details[d].list[l].typeList.length; t++){
                        for(let s = 0; s < programDetails[i].details[d].list[l].typeList[t].subjectList.length; s++){
                            if(selectedIds.includes(programDetails[i].details[d].list[l].typeList[t].subjectList[s].id)){
                                dtlArray.push(programDetails[i].details[d].list[l].typeList[t].subjectList[s]);
                            }
                        }
                    }
                }
            }
        }

        let credits = 0;
        let price = 0;
        if(dtlArray && dtlArray.length)
        {
            for(let i = 0; i < dtlArray.length; i++)
            {
                credits += parseInt(dtlArray[i].credit);
                if(parseInt(dtlArray[i].totalPrice) > 0){
                    price += parseInt(dtlArray[i].totalPrice);
                }
            }
        }

        const groupByCategory = dtlArray.reduce((group, product) => {
            const { categoryName } = product;

            group[categoryName] = group[categoryName] ?? [];
            group[categoryName].push(product);

            return group;
        }, {});

        for (var categories in groupByCategory){
            const typeNameList = groupByCategory[categories].reduce((group, product) => {
                const { typeName } = product;
    
                group[typeName] = group[typeName] ?? [];
                group[typeName].push(product);
    
                return group;
            }, {});

            groupByCategory[categories] = typeNameList;
        }

        if(configId){
            for(let i = 0; i < selection1s.length; i++){
                if(configId == selection1s[i].id){
                    selection1s[i].dtls = groupByCategory;
                    selection1s[i].totalCount = dtlArray.length;
                    selection1s[i].totalCredit = credits;
                    selection1s[i].totalCreditPrice = price;
                }
            }
        }

        handleAddModalClose();
        setSelectedConfigId(null);
    };

    const renderDetails = (details, index) => {
        let row = [];
        if (details && details.length > 0) {
            details.map(detail => {
                let typeRow = [];
                if(detail['list'] && detail['list'].length > 0){
                    detail['list'].map(type => {
                        let subjectRow = [];
                        if(type['typeList'] && type['typeList'].length > 0){
                            type['typeList'].map(subject => {
                                let nonSelectableIds = [];
                                for(let s = 0; s < subject['subjectList'].length; s++){
                                    if(subject['subjectList'][s].disabled && !subject['subjectList'][s].status){
                                        nonSelectableIds.push(parseInt(subject['subjectList'][s].id))
                                        nonSelectableIds.push(String(subject['subjectList'][s].id))
                                    }
                                }

                                subjectRow.push(
                                    <div key={'subject_' + index + '_' + subject['subjectTypeId']} className='student-selection-area'>
                                        <div className='col-12 p-0 font-w-bold mt-2' style={{color: '#000'}}>
                                            {subject.subjectTypeName}
                                        </div>
                                        <DTable
                                            data={subject['subjectList']}
                                            columns={columns}
                                            config={config}
                                            showOrdering={true}
                                            isSelect={true}
                                            onSelect={onSelect}
                                            onSelectAll={onSelectAll}
                                            nonSelectableIds={nonSelectableIds}
                                        />
                                    </div>
                                )
                            })
                        }
                        typeRow.push(
                            <div key={'type_' + index + '_' + type['typeId']}>
                                <div className='col-12 p-0 font-w-bold dark-blue'>
                                    &emsp;&emsp;&emsp;&emsp; {' - ' + type.typeName + ' - '}
                                    <hr/>
                                </div>
                                <div className='mt-2'>{subjectRow}</div>
                            </div>
                        )
                    })
                }

                row.push(
                    <div key={'detail_' + index + '_' + detail['categoryId']}>
                        <div className='col-12 p-0 font-w-bold dark-blue mt-1'>
                            <ol type="I" className="mb-0">
                                <li>{detail.categoryName.toUpperCase() + ' ' + (t('subject.subjects')).toUpperCase()}</li>
                            </ol>
                            <div>{typeRow}</div>
                        </div>
                    </div>
                );
            })
        }

        return row
    };

    return (
        <div className='row'>
            <div className='col-12 class-view-style'>
                <div className='card mb-4'>
                    <div className='card-body'>
                        <div className="col-12" align={"right"}>
                            <button
                                className='btn btn-link'
                                onClick={handleClassBackClick}
                            >
                                {t('common.backTo_list')}
                            </button>
                        </div>
                        <div className="col-12" align={"center"}>
                            {
                                classInfo
                                ?
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td className='text-right pr-3'>{t('school.title')}</td>
                                            <th>{classInfo?.schoolName}</th>
                                        </tr>
                                        <tr>
                                            <td className='text-right pr-3'>{t('movement.class.type')}</td>
                                            <th>{classInfo?.departmentName}</th>
                                        </tr>
                                        <tr>
                                            <td className='text-right pr-3'>{t('curriculum.title')}</td>
                                            <th>{classInfo?.academicProgramName}</th>
                                        </tr>
                                        <tr>
                                            <td className='text-right pr-3'>{t('curriculum.profession')}</td>
                                            <th>{classInfo?.programName}</th>
                                        </tr>
                                        <tr>
                                            <td className='text-right pr-3'>{t('movement.class.consultantTeacher')}</td>
                                            <th>{classInfo?.teacherCode + ' - ' + classInfo?.teacherName}</th>
                                        </tr>
                                        <tr>
                                            <td className='text-right pr-3'>{t('curriculum.type')}</td>
                                            <th>{classInfo?.typeName}</th>
                                        </tr>
                                        <tr>
                                            <td className='text-right pr-3'>{t('curriculum.approach')}</td>
                                            <th>{classInfo?.approachName}</th>
                                        </tr>
                                        <tr>
                                            <td className='text-right pr-3'>{t('school.season.title')}</td>
                                            <th>{classInfo?.seasonName}</th>
                                        </tr>
                                        </tbody>
                                    </table>
                                : null
                            }
                        </div>
                    </div>
                </div>
                <Card>
                    <Card.Body>
                        <div>
                            <div className='row mr-1'>
                                <div className='col-6 mb-3'>
                                    <Card>
                                        <Card.Body>
                                            <div style={{ fontWeight: 700, marginBottom: 6 }}>{`${t('common.total')}: ${totalStudentCount}`}</div>
                                            <LinearProgress variant='determinate' value={selectedStudentCount > 0 ? ((100 * selectedStudentCount)/totalStudentCount) : 0} className='msue-selection-progress' />
                                            <div style={{ display: 'flex', justifyContent: 'space-between', color: '#299f72', fontWeight: 700 }}>
                                                <span>{selectedStudentCount > 0 ? ((100 * selectedStudentCount)/totalStudentCount).toFixed(2) + '%' : 0}</span>
                                                <span>{selectedStudentCount}</span>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                            <DTable
                                remote={false}
                                config={config}
                                data={students}
                                columns={byClassColumns}
                                showOrdering={true}
                                totalDataSize={totalCount}
                                onInteraction={onUserInteraction}
                                selectMode={'radio'}
                                contextMenus={contextMenus}
                                onContextMenuItemClick={handleContextMenuClick}
                            />
                        </div>
                    </Card.Body>
                </Card>
            </div>
            {
                showEditModal &&
                <Modal
                    show={showEditModal}
                    onHide={onEditClose}
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                                {t('common.edit').toUpperCase()}
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='col-12 p-0'>
                            {
                                selection1s && selection1s.length > 0
                                ?
                                    selection1s.map((selection, index) => (
                                        <Card
                                            key={'main_' + index}
                                            className='mb-7'
                                        >
                                            <ButtonToolbar className='d-inline mt-7 ml-5'>
                                                <Button
                                                    key={'subject_index_' + index}
                                                    className="btn btn-primary btn-shadow ml-3"
                                                    onClick={() => handlerSubjectAddClick(selection.id)}
                                                >
                                                    {selection.seasonName + ' ' + t('subject.selectSubject').toLowerCase()}
                                                </Button>
                                                <Button
                                                    className='ml-3'
                                                    variant='success'
                                                    onClick={() => onSaveClick(selection.selectionData && selection.selectionData.approved1 === true ? 1 : 0, selection.id)}
                                                >
                                                    {t('common.save')}
                                                </Button>
                                                {
                                                    selection.selectionData && (!selection.selectionData.approved1 || selection.selectionData.approved1 == undefined || selection.selectionData.approved1 == false) &&
                                                    <Button
                                                        key={'publish_index_' + index}
                                                        className='ml-3'
                                                        variant='publish'
                                                        onClick={() => onSaveClick(1, selection.id)}
                                                    >
                                                        {selection.seasonName + ' ' + t('action.publish').toLowerCase()}
                                                    </Button>
                                                }
                                                {
                                                    selection.selectionData == undefined &&
                                                    <Button
                                                        key={'publish_index_' + index}
                                                        className='ml-3'
                                                        variant='publish'
                                                        onClick={() => onSaveClick(1, selection.id)}
                                                    >
                                                        {selection.seasonName + ' ' + t('action.publish').toLowerCase()}
                                                    </Button>
                                                }
                                                {
                                                    selection.selectionData && selection.selectionData.approved1 &&
                                                    <Button
                                                        key={'publish_index_' + index}
                                                        className='ml-3'
                                                        variant='publish'
                                                        onClick={() => onSaveClick(0, selection.id)}
                                                    >
                                                        {selection.seasonName + ' ' + t('action.unPublish').toLowerCase()}
                                                    </Button>
                                                }
                                            </ButtonToolbar>
                                            <Card.Body>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <h6 className='dark-blue text-uppercase'>{t('subject.selected')}</h6>
                                                    </div>
                                                    <div className='col-12 mb-4'>
                                                        <table className='ml-12'>
                                                            <tbody>
                                                            <tr>
                                                                <td className='text-right'>{t('total.subject')}:</td>
                                                                <td className='pl-4 strong-blue font-w-bold'>{selection?.totalCount || 0}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='text-right'>{t('total.credit')}:</td>
                                                                <td className='pl-4 strong-blue font-w-bold'>{selection?.totalCredit || 0}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='text-right'>{t('total.price')}:</td>
                                                                <td className='pl-4 strong-blue font-w-bold'>{selection?.totalCreditPrice || 0}₮</td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                {
                                                    selection.dtls && Object.keys(selection.dtls).map((categoryKey, ci) => { 
                                                        let renderArea = [];

                                                        renderArea.push(
                                                            <div key={'category_' + ci}>
                                                                <b>{categoryKey}</b>
                                                            </div>
                                                        )

                                                        Object.keys(selection.dtls[categoryKey]).map((typeKey, ti) => { 
                                                            renderArea.push(
                                                                <div key={'category_' + ci + '_type_' + ti}>
                                                                    <b>{' - ' + typeKey + ' - '}</b>
                                                                    <div>
                                                                        <DTable
                                                                            data={selection.dtls[categoryKey][typeKey]}
                                                                            columns={studentIndexColumns}
                                                                            config={config}
                                                                            updateTable={updateView}
                                                                            selectMode={'radio'}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )
                                                        })

                                                        return renderArea
                                                    })
                                                }
                                            </Card.Body>
                                        </Card>
                                    ))
                                : null
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button onClick={onEditClose} className='btn btn-link bolder'>{t('common.back')}</button>
                    </Modal.Footer>
                </Modal>
            }
            <Modal
                show={addModal}
                onHide={handleAddModalClose}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t('subject.selectSubject')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        programDetails && programDetails.length > 0
                            ?
                            programDetails.map((detail, index) => {
                                return (
                                    <Card key={'detail_' + index} className='mb-3'>
                                        <Card.Body>
                                            <div className='col-12 p-0 font-w-bold dark-blue'>
                                                {detail['year'] + ' ' + (detail && detail['seasonType'] ? (detail['seasonType']).toUpperCase() : '')}
                                            </div>
                                            {/* <div className='col-12 p-0 font-w-bold dark-blue mt-1'>
                                                {t('subject.generalAcademicSubject')}
                                            </div> */}
                                            {renderDetails(detail['details'], index)}
                                        </Card.Body>
                                    </Card>
                                )
                            })
                            : null
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='link' onClick={handleAddModalClose}>{t('common.close')}</Button>
                    <Button variant='next' onClick={handleAddModalSubmit}>{t('common.select')}</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={deleteModal}
                onHide={handleDeleteModalClose}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t('warning.delete')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {t('warning.delete_confirmation')}
                        <br/>
                        <br/>
                        {t('warning.delete_confirmation_description')}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="btn btn-link bolder" onClick={handleDeleteModalClose}>{t('common.cancel')}</Button>
                    <Button variant="success btn-shadow" onClick={handlerOnDeleteClick}>{t('warning.delete')}</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default OneView;