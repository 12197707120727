/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from "react";
import { useLocation } from "react-router";
import { NavLink, Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { useTranslation } from "react-i18next";
import {useSelector} from "react-redux";

export function AsideMenuList({ layoutProps }) {
    const location = useLocation();
    const { t } = useTranslation();
    const [menus, setMenus] = useState([]);
    const userMenus = useSelector(state => state?.userMenus?.userMenus || []);
    const menuItemClass = document.getElementsByClassName('menu-item');
    const [ menuIndex ] = useState('menu_list_index');
    const ulRef = useRef();

    const getMenuItemActive = (url) => {
        return checkIsActive(location, url)
            ? " menu-item-active menu-item-open "
            : "";
    };

    const getToggleMenuActive = (url, childMenus) => {
        if(childMenus && childMenus.length > 0){
            let active = false;
            for(let i = 0; i < childMenus.length; i++){
                if(url === childMenus[i].path){
                    active = true;
                }
            }

            if(active){
                return " menu-item-open "
            } else {
                return ""
            }
        }
    };

    const ulItemClick = (url, ref) => {
        let current = ref.current;
        if(current){
            let childrens = current.children;
            if(childrens && childrens.length > 0){
                for(let i = 0; i < childrens.length; i++){
                    if(childrens[i].classList.contains('menu-item')){
                        let child = childrens[i].children;
                        if(child && child.length > 0){
                            for(let s = 0; s < child.length; s++){
                                if(url === child[s].getAttribute('href')){
                                    childrens[i].classList.add("menu-item-active");
                                    childrens[i].classList.add("menu-item-open");
                                    localStorage.setItem(menuIndex, url);
                                } else if(child[s].classList.contains('menu-submenu')){
                                    if(url === child[s].querySelector('.menu-link').getAttribute('href')){
                                        child[s].querySelector('.menu-item').classList.add("menu-item-active");
                                        child[s].querySelector('.menu-item').classList.add("menu-item-open");
                                        localStorage.setItem(menuIndex, url);
                                    } else {
                                        let activeChildClass = child[s].querySelector('.menu-item').classList.contains("menu-item-active");
                                        if(activeChildClass){
                                            child[s].querySelector('.menu-item').classList.remove("menu-item-active");
                                            child[s].querySelector('.menu-item').classList.remove("menu-item-open");
                                        }
                                    }
                                } else {
                                    let activeClass = childrens[i].classList.contains('menu-item-active');
                                    if(activeClass){
                                        childrens[i].classList.remove("menu-item-active");
                                        childrens[i].classList.remove("menu-item-open");
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    };

    const toggleMenuClick = (menuId, ref) => {
        let current = ref.current;
        if(current){
            let childrens = current.children;
            if(childrens && childrens.length > 0){
                for(let i = 0; i < childrens.length; i++){
                    if(childrens[i].getAttribute('data-menu-toggle') === 'hover'){
                        let activeMenu = childrens[i].classList.contains('toggle-menu-id-' + menuId);
                        if(!activeMenu){
                            let activeClass = childrens[i].classList.contains('menu-item-open');
                            if(activeClass){
                                childrens[i].classList.remove("menu-item-open");
                                // childrens[i].style.display = 'none';
                                // childrens[i].style.overflow = 'hidden';
                            }
                        }
                    }
                }
            }
        }
    };

    const checkUrl = (url) => {
        if (url.indexOf(window.location.origin) > -1) {
            url = url.substring(window.location.origin.length, url.length);
        }

        return url;
    };

    useEffect(() => {
        const menuUrl = localStorage.getItem(menuIndex);
        for (let i = 0; i < menuItemClass.length; i++)
        {
            let menuItemOpen = menuItemClass[i].getElementsByClassName('menu-item-open');
            if(menuItemOpen && menuItemOpen.length > 0)
            {
                menuItemClass[i].className += " menu-item-open"
            }
        }

        let menuList = [];
        if(userMenus && userMenus.length > 0){
            for(let i = 0; i < userMenus.length; i++){
                if(userMenus[i]['children'] && userMenus[i]['children'].length > 0){
                    let menu = userMenus[i]['children'];
                    menuList.push(
                        <li className="menu-section " key={'section_' + userMenus[i]['id']}>
                            <h4 className="menu-text">{userMenus[i]['name']}</h4>
                            <i className="menu-icon flaticon-more-v2"/>
                        </li>
                    );

                    for(let c = 0; c < menu.length; c++)
                    {
                        if(menu[c].isMain){
                            menuList.push(
                                <li
                                    key={'menu_' + i + '_item_' + c}
                                    className={`menu-item ${menuUrl === menu[c].path ? 'menu-item-active menu-item-open' : ''}` }
                                    aria-haspopup="true"
                                    onClick={() => ulItemClick(menu[c].path, ulRef)}
                                >
                                    <Link className="menu-link" to={{
                                            pathname: menu[c].path,
                                        }}
                                    >
                                        <span className="svg-icon menu-icon">
                                          <i className={"icon-1_8x text-dark-50 " + menu[c].iconName}/>
                                        </span>
                                        <span className="menu-text">{menu[c].name}</span>
                                    </Link>
                                </li>
                            );
                        } else {
                            menuList.push(
                                <li
                                    key={'key_' + menu[c].id}
                                    className={`menu-item toggle-menu-id-` + menu[c].id + ` ${getToggleMenuActive(menuUrl, menu[c].children)}`}
                                    aria-haspopup="true"
                                    data-menu-toggle="hover"
                                    onClick={() => toggleMenuClick(menu[c].id, ulRef)}
                                >
                                    <a className="menu-link menu-toggle" href="#">
                                        <span className="svg-icon menu-icon">
                                          <i className={"icon-1_8x text-dark-50 " + menu[c].iconName}/>
                                        </span>
                                        <span className="menu-text"><b>{menu[c].name}</b></span><i className="menu-arrow"/>
                                    </a>
                                    {
                                        menu[c] && menu[c].children && menu[c].children.length > 0
                                            ?
                                            menu[c].children.map(children => {
                                                // if(children.isMain){
                                                //     return (
                                                //         <li
                                                //             key={'key_' + userMenus[i].id + '_menu_' + menu[c] + '_child_' + children.id}
                                                //             className={`menu-item ${() => getMenuItemActive(children.path)}`}
                                                //             aria-haspopup="true"
                                                //         >
                                                //             <Link className="menu-link menu-toggle" to={{
                                                //                 pathname: children.path,
                                                //             }}
                                                //             >
                                                //                 <span className="svg-icon menu-icon">
                                                //                   {/*<i className={"icon-1_8x text-dark-50 " + children.iconName}/>*/}
                                                //                 </span>
                                                //                 <span className="menu-text">{children.name}</span>
                                                //             </Link>
                                                //         </li>
                                                //     )
                                                // } else {
                                                    return (
                                                        <div
                                                            key={'key_' + userMenus[i].id + '_child_' + children.id}
                                                            className="menu-submenu"
                                                        >
                                                            <ul className="menu-subnav">
                                                                <li
                                                                    className={`menu-item ${menuUrl === children.path ? 'menu-item-active menu-item-open' : ''}`}
                                                                    aria-haspopup="true"
                                                                    onClick={() => ulItemClick(children.path, ulRef)}
                                                                >
                                                                    <Link className="menu-link" to={{
                                                                        pathname: children.path,
                                                                    }}
                                                                    >
                                                                        <i className="menu-bullet menu-bullet-dot"><span/></i>
                                                                        <span className="menu-text">{children.name}</span>
                                                                    </Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    )
                                                // }
                                            })
                                            : null
                                    }
                                </li>
                            );
                        }
                    }
                }
            }
        }

        return setMenus(menuList);
    }, [userMenus]);

    return (
        <>
            {/* begin::Menu Nav */}
            <ul
                ref={ulRef}
                className={`menu-nav ${layoutProps.ulClasses}`}
            >
                {menus}
                {/*<li*/}
                {/*    className={`menu-item ${getMenuItemActive("/home")}`}*/}
                {/*    aria-haspopup="true"*/}
                {/*>*/}
                {/*    <NavLink className="menu-link" to="/home">*/}
                {/*        <span className="svg-icon menu-icon">*/}
                {/*          <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />*/}
                {/*        </span>*/}
                {/*        <span className="menu-text">{t('menu.home')}</span>*/}
                {/*    </NavLink>*/}
                {/*</li>*/}
                {/*<li*/}
                {/*    className={`menu-item ${getMenuItemActive("/calendar")}`}*/}
                {/*    aria-haspopup="true"*/}
                {/*>*/}
                {/*    <Link className="menu-link" to={{*/}
                {/*            pathname: "/calendar",*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        <span className="svg-icon menu-icon">*/}
                {/*          <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />*/}
                {/*        </span>*/}
                {/*        <span className="menu-text">{t('menu.calendar')}</span>*/}
                {/*    </Link>*/}
                {/*</li>*/}
                {/* begin::Study section */}
                {/*<li className="menu-section ">*/}
                {/*    <h4 className="menu-text">{t('menu.study')}</h4>*/}
                {/*    <i className="menu-icon flaticon-more-v2"/>*/}
                {/*</li>*/}
                {/*<li*/}
                {/*    className={`menu-item ${getMenuItemActive("/student_grade")}`}*/}
                {/*    aria-haspopup="true"*/}
                {/*>*/}
                {/*    <NavLink className="menu-link" to="/student_grade">*/}
                {/*        <span className="svg-icon menu-icon">*/}
                {/*          <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />*/}
                {/*        </span>*/}
                {/*        <span className="menu-text">{t('menu.grade')}</span>*/}
                {/*    </NavLink>*/}
                {/*</li>*/}
                {/*<li*/}
                {/*    className={`menu-item ${getMenuItemActive("/student_payment")}`}*/}
                {/*    aria-haspopup="true"*/}
                {/*>*/}
                {/*    <NavLink className="menu-link" to="/student_payment">*/}
                {/*        <span className="svg-icon menu-icon">*/}
                {/*          <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />*/}
                {/*        </span>*/}
                {/*        <span className="menu-text">{t('menu.payment')}</span>*/}
                {/*    </NavLink>*/}
                {/*</li>*/}
                {/* end::Study section */}
                {/* begin::Request section */}
                {/*<li className="menu-section ">*/}
                {/*    <h4 className="menu-text">{t('menu.request')}</h4>*/}
                {/*    <i className="menu-icon flaticon-more-v2"/>*/}
                {/*</li>*/}
                {/*<li*/}
                {/*    className={`menu-item ${getMenuItemActive("/request_student")}`}*/}
                {/*    aria-haspopup="true"*/}
                {/*>*/}
                {/*    <NavLink className="menu-link" to="/request_student">*/}
                {/*        <span className="svg-icon menu-icon">*/}
                {/*          <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />*/}
                {/*        </span>*/}
                {/*        <span className="menu-text">{t('menu.student_request')}</span>*/}
                {/*    </NavLink>*/}
                {/*</li>*/}
                {/* end::Request section */}
                {/* start::Form section */}
                {/*<li className="menu-section ">*/}
                {/*    <h4 className="menu-text">{t('menu.form')}</h4>*/}
                {/*    <i className="menu-icon flaticon-more-v2"/>*/}
                {/*</li>*/}
                {/*<li*/}
                {/*    className={`menu-item ${getMenuItemActive("/anket_student")}`}*/}
                {/*    aria-haspopup="true"*/}
                {/*>*/}
                {/*    <NavLink className="menu-link" to="/anket_student">*/}
                {/*        <span className="svg-icon menu-icon">*/}
                {/*          <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />*/}
                {/*        </span>*/}
                {/*        <span className="menu-text">{t('menu.anket')}</span>*/}
                {/*    </NavLink>*/}
                {/*</li>*/}
                {/* end::Form section */}
                {/* start::Survey section */}
                {/*<li className="menu-section ">*/}
                {/*    <h4 className="menu-text">{t('menu.survey')}</h4>*/}
                {/*    <i className="menu-icon flaticon-more-v2"/>*/}
                {/*</li>*/}
                {/*<li*/}
                {/*    className={`menu-item ${getMenuItemActive("/survey_student")}`}*/}
                {/*    aria-haspopup="true"*/}
                {/*>*/}
                {/*    <NavLink className="menu-link" to="/survey_student">*/}
                {/*        <span className="svg-icon menu-icon">*/}
                {/*          <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />*/}
                {/*        </span>*/}
                {/*        <span className="menu-text">{t('menu.survey')}</span>*/}
                {/*    </NavLink>*/}
                {/*</li>*/}
                 {/*end::Survey section*/}
                {/* start::Additional section */}
                {/*<li className="menu-section ">*/}
                {/*    <h4 className="menu-text">{t('menu.additional')}</h4>*/}
                {/*    <i className="menu-icon flaticon-more-v2"/>*/}
                {/*</li>*/}
                {/*<li*/}
                {/*    className={`menu-item ${getMenuItemActive("/regulation")}`}*/}
                {/*    aria-haspopup="true"*/}
                {/*>*/}
                {/*    <NavLink className="menu-link" to="/regulation">*/}
                {/*        <span className="svg-icon menu-icon">*/}
                {/*          <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />*/}
                {/*        </span>*/}
                {/*        <span className="menu-text">{t('menu.regulation')}</span>*/}
                {/*    </NavLink>*/}
                {/*</li>*/}

                {/*Оюутан -> Хичээл сонголт*/}
                {/*<li className="menu-section ">*/}
                {/*    <h4 className="menu-text">{t('timetable.selection')}</h4>*/}
                {/*    <i className="menu-icon flaticon-more-v2"/>*/}
                {/*</li>*/}

                {/*Оюутан -> Хичээл сонголт 1*/}
                {/*<li*/}
                {/*    className={`menu-item`}*/}
                {/*    aria-haspopup="true"*/}
                {/*    data-menu-toggle="hover"*/}
                {/*>*/}
                {/*    <NavLink*/}
                {/*        to="/student/selection/one"*/}
                {/*        className={`menu-link ${getMenuItemActive("/student/selection/one")}`}*/}
                {/*        aria-haspopup="true"*/}
                {/*    >*/}
                {/*        <span className="svg-icon menu-icon">*/}
                {/*          <i className="icon-1_8x text-dark-50 flaticon-layer"/>*/}
                {/*        </span>*/}
                {/*        <span className="menu-text font-w-bold">{t('timetable.selectionOne')}</span>*/}
                {/*    </NavLink>*/}
                {/*</li>*/}
                {/*<li*/}
                {/*    className={`menu-item`}*/}
                {/*    aria-haspopup="true"*/}
                {/*    data-menu-toggle="hover"*/}
                {/*>*/}
                {/*    <NavLink*/}
                {/*        to="/student/selection/two"*/}
                {/*        className={`menu-link ${getMenuItemActive("/student/selection/two")}`}*/}
                {/*        aria-haspopup="true"*/}
                {/*    >*/}
                {/*        <span className="svg-icon menu-icon">*/}
                {/*          <i className="icon-1_8x text-dark-50 flaticon-layer"/>*/}
                {/*        </span>*/}
                {/*        <span className="menu-text font-w-bold">{t('timetable.selectionTwo')}</span>*/}
                {/*    </NavLink>*/}
                {/*</li>*/}

                {/*Сургууль -> Хичээл сонголт*/}
                {/*<li className="menu-section ">*/}
                {/*    <h4 className="menu-text">{t('timetable.title')}</h4>*/}
                {/*    <i className="menu-icon flaticon-more-v2"/>*/}
                {/*</li>*/}

                {/*Хичээлийн хуваарь -> Хичээл сонголт*/}
                {/*<li*/}
                {/*    className={`menu-item`}*/}
                {/*    aria-haspopup="true"*/}
                {/*    data-menu-toggle="hover"*/}
                {/*>*/}
                {/*    <a className="menu-link menu-toggle" href="#">*/}
                {/*        <span className="svg-icon menu-icon">*/}
                {/*          <i className="icon-1_8x text-dark-50 flaticon-layer"/>*/}
                {/*        </span>*/}
                {/*        <span className="menu-text"><b>{t('timetable.selection')}</b></span><i className="menu-arrow"/>*/}
                {/*    </a>*/}
                {/*    <div className="menu-submenu">*/}
                {/*        <ul className="menu-subnav">*/}
                {/*            <ul className="menu-subnav">*/}
                {/*                <li*/}
                {/*                    className={`menu-item ${getMenuItemActive("/selection/1")}`}*/}
                {/*                    aria-haspopup="true"*/}
                {/*                >*/}
                {/*                    <NavLink className="menu-link" to="/selection/1">*/}
                {/*                        <i className="menu-bullet menu-bullet-dot"><span/></i>*/}
                {/*                        <span className="menu-text">{t('timetable.selectionOne')}</span>*/}
                {/*                    </NavLink>*/}
                {/*                </li>*/}
                {/*                <li*/}
                {/*                    className={`menu-item ${getMenuItemActive("/selection/2")}`}*/}
                {/*                    aria-haspopup="true"*/}
                {/*                >*/}
                {/*                    <NavLink className="menu-link" to="/selection/2">*/}
                {/*                        <i className="menu-bullet menu-bullet-dot"><span/></i>*/}
                {/*                        <span className="menu-text">{t('timetable.selectionTwo')}</span>*/}
                {/*                    </NavLink>*/}
                {/*                </li>*/}
                {/*                <li*/}
                {/*                    className={`menu-item ${getMenuItemActive("/selection/fine")}`}*/}
                {/*                    aria-haspopup="true"*/}
                {/*                >*/}
                {/*                    <NavLink className="menu-link" to="/selection/fine">*/}
                {/*                        <i className="menu-bullet menu-bullet-dot"><span/></i>*/}
                {/*                        <span className="menu-text">{t('timetable.penalty')}</span>*/}
                {/*                    </NavLink>*/}
                {/*                </li>*/}
                {/*            </ul>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*</li>*/}

                {/*<li className={`menu-item ${getMenuItemActive("/timetable")}`} aria-haspopup="true" data-menu-toggle="hover">*/}
                {/*    <NavLink className="menu-link menu-toggle" to="/timetable">*/}
                {/*        <span className="svg-icon menu-icon">*/}

                {/*        </span>*/}
                {/*        <span className="menu-text">{t('timetable.title')}</span>*/}
                {/*    </NavLink>*/}
                {/*</li>*/}

                {/* begin::master data section */}
                {/*<li className="menu-section ">*/}
                {/*    <h4 className="menu-text">{t('menu.masterData')}</h4>*/}
                {/*    <i className="menu-icon flaticon-more-v2"/>*/}
                {/*</li>*/}
                {/* end::master data section */}
                {/*Мастер дата -> Бүтэц, зохион байгуулалт*/}
                {/*<li*/}
                {/*    className={`menu-item`}*/}
                {/*    aria-haspopup="true"*/}
                {/*    data-menu-toggle="hover"*/}
                {/*>*/}
                {/*    <a className="menu-link menu-toggle" href="#">*/}
                {/*        <span className="svg-icon menu-icon">*/}
                {/*          <i className="icon-1_8x text-dark-50 flaticon-layer"/>*/}
                {/*        </span>*/}
                {/*        <span className="menu-text"><b>{t('menu.structure')}</b></span><i className="menu-arrow"/>*/}
                {/*    </a>*/}
                {/*    <div className="menu-submenu">*/}
                {/*        <ul className="menu-subnav">*/}
                {/*            <ul className="menu-subnav">*/}
                {/*                <li*/}
                {/*                    className={`menu-item ${getMenuItemActive("/structure/component")}`}*/}
                {/*                    aria-haspopup="true"*/}
                {/*                >*/}
                {/*                    <Link replace className="menu-link" to={"/structure/component"}>*/}
                {/*                        <i className="menu-bullet menu-bullet-dot"><span/></i>*/}
                {/*                        <span className="menu-text">{t('menu.schoolComponent')}</span>*/}
                {/*                    </Link>*/}
                {/*                </li>*/}
                {/*                <li*/}
                {/*                    className={`menu-item ${getMenuItemActive("/structure/department")}`}*/}
                {/*                    aria-haspopup="true"*/}
                {/*                >*/}
                {/*                    <NavLink exact className="menu-link" to="/structure/department">*/}
                {/*                        <i className="menu-bullet menu-bullet-dot"><span/></i>*/}
                {/*                        <span className="menu-text">{t('menu.department')}</span>*/}
                {/*                    </NavLink>*/}
                {/*                </li>*/}
                {/*                <li*/}
                {/*                    className={`menu-item ${getMenuItemActive("/structure/roles")}`}*/}
                {/*                    aria-haspopup="true"*/}
                {/*                >*/}
                {/*                    <NavLink strict className="menu-link" to="/structure/roles">*/}
                {/*                        <i className="menu-bullet menu-bullet-dot"><span/></i>*/}
                {/*                        <span className="menu-text">{t('menu.roles')}</span>*/}
                {/*                    </NavLink>*/}
                {/*                </li>*/}
                {/*                <li*/}
                {/*                    className={`menu-item ${getMenuItemActive("/structure/classroom")}`}*/}
                {/*                    aria-haspopup="true"*/}
                {/*                >*/}
                {/*                    <NavLink className="menu-link" to="/structure/classroom">*/}
                {/*                        <i className="menu-bullet menu-bullet-dot"><span/></i>*/}
                {/*                        <span className="menu-text">{t('menu.classroom')}</span>*/}
                {/*                    </NavLink>*/}
                {/*                </li>*/}
                {/*                /!*<li*!/*/}
                {/*                /!*    className={`menu-item ${getMenuItemActive("/structure/about")}`}*!/*/}
                {/*                /!*    aria-haspopup="true"*!/*/}
                {/*                /!*>*!/*/}
                {/*                /!*    <NavLink className="menu-link" to="/structure/about">*!/*/}
                {/*                /!*        <i className="menu-bullet menu-bullet-dot"><span/></i>*!/*/}
                {/*                /!*        <span className="menu-text">{t('menu.aboutSchool')}</span>*!/*/}
                {/*                /!*    </NavLink>*!/*/}
                {/*                /!*</li>*!/*/}
                {/*            </ul>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*</li>*/}
                {/*Мастер дата -> Сургууль*/}
                {/*<li*/}
                {/*    className={`menu-item`}*/}
                {/*    aria-haspopup="true"*/}
                {/*    data-menu-toggle="hover"*/}
                {/*>*/}
                {/*    <a className="menu-link menu-toggle" href="#">*/}
                {/*        <span className="svg-icon menu-icon">*/}
                {/*          <i className="icon-1_8x text-dark-50 flaticon-layer"/>*/}
                {/*        </span>*/}
                {/*        <span className="menu-text"><b>{t('menu.school')}</b></span><i className="menu-arrow"/>*/}
                {/*    </a>*/}
                {/*    <div className="menu-submenu">*/}
                {/*        <ul className="menu-subnav">*/}
                {/*            <ul className="menu-subnav">*/}
                {/*                <li*/}
                {/*                    className={`menu-item ${getMenuItemActive("/school/subject")}`}*/}
                {/*                    aria-haspopup="true"*/}
                {/*                >*/}
                {/*                    <NavLink className="menu-link" to="/school/subject">*/}
                {/*                        <i className="menu-bullet menu-bullet-dot"><span/></i>*/}
                {/*                        <span className="menu-text">{t('menu.subject')}</span>*/}
                {/*                    </NavLink>*/}
                {/*                </li>*/}
                {/*                <li*/}
                {/*                    className={`menu-item ${getMenuItemActive("/school/academic")}`}*/}
                {/*                    aria-haspopup="true"*/}
                {/*                >*/}
                {/*                    <NavLink className="menu-link" to="/school/academic">*/}
                {/*                        <i className="menu-bullet menu-bullet-dot"><span/></i>*/}
                {/*                        <span className="menu-text">{t('curriculum.title')}</span>*/}
                {/*                    </NavLink>*/}
                {/*                </li>*/}
                {/*                <li*/}
                {/*                    className={`menu-item ${getMenuItemActive("/school/program")}`}*/}
                {/*                    aria-haspopup="true"*/}
                {/*                >*/}
                {/*                    <NavLink className="menu-link" to="/school/program">*/}
                {/*                        <i className="menu-bullet menu-bullet-dot"><span/></i>*/}
                {/*                        <span className="menu-text">{t('curriculum.profession')}</span>*/}
                {/*                    </NavLink>*/}
                {/*                </li>*/}
                {/*                <li*/}
                {/*                    className={`menu-item ${getMenuItemActive("/school/teacher")}`}*/}
                {/*                    aria-haspopup="true"*/}
                {/*                >*/}
                {/*                    <NavLink className="menu-link" to="/school/teacher">*/}
                {/*                        <i className="menu-bullet menu-bullet-dot"><span/></i>*/}
                {/*                        <span className="menu-text">{t('menu.teacherStaff')}</span>*/}
                {/*                    </NavLink>*/}
                {/*                </li>*/}
                {/*            </ul>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*</li>*/}
                {/*Мастер дата -> Шилжилт хөдөлгөөн*/}
                {/*<li*/}
                {/*    className={`menu-item`}*/}
                {/*    aria-haspopup="true"*/}
                {/*    data-menu-toggle="hover"*/}
                {/*>*/}
                {/*    <a className="menu-link menu-toggle" href="#">*/}
                {/*        <span className="svg-icon menu-icon">*/}
                {/*          <i className="icon-1_8x text-dark-50 flaticon-layer"/>*/}
                {/*        </span>*/}
                {/*        <span className="menu-text"><b>{t('movement.title')}</b></span><i className="menu-arrow"/>*/}
                {/*    </a>*/}
                {/*    <div className="menu-submenu">*/}
                {/*        <ul className="menu-subnav">*/}
                {/*            <ul className="menu-subnav">*/}
                {/*                <li*/}
                {/*                    className={`menu-item ${getMenuItemActive("/movement/class")}`}*/}
                {/*                    aria-haspopup="true"*/}
                {/*                >*/}
                {/*                    <NavLink className="menu-link" to="/movement/class">*/}
                {/*                        <i className="menu-bullet menu-bullet-dot"><span/></i>*/}
                {/*                        <span className="menu-text">{t('movement.admission')}</span>*/}
                {/*                    </NavLink>*/}
                {/*                </li>*/}
                {/*            </ul>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*    <div className="menu-submenu">*/}
                {/*        <ul className="menu-subnav">*/}
                {/*            <ul className="menu-subnav">*/}
                {/*                <li*/}
                {/*                    className={`menu-item ${getMenuItemActive("/movement/int")}`}*/}
                {/*                    aria-haspopup="true"*/}
                {/*                >*/}
                {/*                    <NavLink className="menu-link" to="/movement/int">*/}
                {/*                        <i className="menu-bullet menu-bullet-dot"><span/></i>*/}
                {/*                        <span className="menu-text">{t('movement.internal.title')}</span>*/}
                {/*                    </NavLink>*/}
                {/*                </li>*/}
                {/*            </ul>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*</li>*/}

                {/*<li className="menu-section ">*/}
                {/*    <h4 className="menu-text">{t('setting.title')}</h4>*/}
                {/*    <i className="menu-icon flaticon-more-v2"/>*/}
                {/*</li>*/}

                {/*<li*/}
                {/*    className={`menu-item`}*/}
                {/*    aria-haspopup="true"*/}
                {/*    data-menu-toggle="hover"*/}
                {/*>*/}
                {/*    <a className="menu-link menu-toggle" href="#">*/}
                {/*        <span className="svg-icon menu-icon">*/}
                {/*          <i className="icon-1_8x text-dark-50 flaticon-layer"/>*/}
                {/*        </span>*/}
                {/*        <span className="menu-text"><b>{t('setting.schoolSetting')}</b></span><i className="menu-arrow"/>*/}
                {/*    </a>*/}
                {/*    <div className="menu-submenu">*/}
                {/*        <ul className="menu-subnav">*/}
                {/*            <ul className="menu-subnav">*/}
                {/*                <li*/}
                {/*                    className={`menu-item ${getMenuItemActive("/setting/sc/shifts")}`}*/}
                {/*                    aria-haspopup="true"*/}
                {/*                >*/}
                {/*                    <NavLink className="menu-link" to="/setting/sc/shifts">*/}
                {/*                        <i className="menu-bullet menu-bullet-dot"><span/></i>*/}
                {/*                        <span className="menu-text">{t('school.schoolShift')}</span>*/}
                {/*                    </NavLink>*/}
                {/*                </li>*/}
                {/*            </ul>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*    <div className="menu-submenu">*/}
                {/*        <ul className="menu-subnav">*/}
                {/*            <ul className="menu-subnav">*/}
                {/*                <li*/}
                {/*                    className={`menu-item ${getMenuItemActive("/setting/sc/timetemplate")}`}*/}
                {/*                    aria-haspopup="true"*/}
                {/*                >*/}
                {/*                    <NavLink className="menu-link" to="/setting/sc/timetemplate">*/}
                {/*                        <i className="menu-bullet menu-bullet-dot"><span/></i>*/}
                {/*                        <span className="menu-text">{t('school.timeTemplate.title')}</span>*/}
                {/*                    </NavLink>*/}
                {/*                </li>*/}
                {/*            </ul>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*    <div className="menu-submenu">*/}
                {/*        <ul className="menu-subnav">*/}
                {/*            <ul className="menu-subnav">*/}
                {/*                <li*/}
                {/*                    className={`menu-item ${getMenuItemActive("/setting/sc/edu")}`}*/}
                {/*                    aria-haspopup="true"*/}
                {/*                >*/}
                {/*                    <NavLink className="menu-link" to="/setting/sc/edu">*/}
                {/*                        <i className="menu-bullet menu-bullet-dot"><span/></i>*/}
                {/*                        <span className="menu-text">{t('curriculum.educationLevel')}</span>*/}
                {/*                    </NavLink>*/}
                {/*                </li>*/}
                {/*            </ul>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*    <div className="menu-submenu">*/}
                {/*        <ul className="menu-subnav">*/}
                {/*            <ul className="menu-subnav">*/}
                {/*                <li*/}
                {/*                    className={`menu-item ${getMenuItemActive("/setting/sc/season-type")}`}*/}
                {/*                    aria-haspopup="true"*/}
                {/*                >*/}
                {/*                    <NavLink className="menu-link" to="/setting/sc/season-type">*/}
                {/*                        <i className="menu-bullet menu-bullet-dot"><span/></i>*/}
                {/*                        <span className="menu-text">{t('school.season.seasonType')}</span>*/}
                {/*                    </NavLink>*/}
                {/*                </li>*/}
                {/*            </ul>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*    <div className="menu-submenu">*/}
                {/*        <ul className="menu-subnav">*/}
                {/*            <ul className="menu-subnav">*/}
                {/*                <li*/}
                {/*                    className={`menu-item ${getMenuItemActive("/setting/sc/seasons")}`}*/}
                {/*                    aria-haspopup="true"*/}
                {/*                >*/}
                {/*                    <NavLink className="menu-link" to="/setting/sc/seasons">*/}
                {/*                        <i className="menu-bullet menu-bullet-dot"><span/></i>*/}
                {/*                        <span className="menu-text">{t('school.season.title')}</span>*/}
                {/*                    </NavLink>*/}
                {/*                </li>*/}
                {/*            </ul>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*</li>*/}

                {/*<li*/}
                {/*    className={`menu-item`}*/}
                {/*    aria-haspopup="true"*/}
                {/*    data-menu-toggle="hover"*/}
                {/*>*/}
                {/*    <a className="menu-link menu-toggle" href="#">*/}
                {/*        <span className="svg-icon menu-icon">*/}
                {/*          <i className="icon-1_8x text-dark-50 flaticon-layer"/>*/}
                {/*        </span>*/}
                {/*        <span className="menu-text"><b>{t('system.systemRole')}</b></span><i className="menu-arrow"/>*/}
                {/*    </a>*/}
                {/*    <div className="menu-submenu">*/}
                {/*        <ul className="menu-subnav">*/}
                {/*            <ul className="menu-subnav">*/}
                {/*                <li*/}
                {/*                    className={`menu-item ${getMenuItemActive("/setting/role")}`}*/}
                {/*                    aria-haspopup="true"*/}
                {/*                >*/}
                {/*                    <NavLink className="menu-link" to="/setting/role">*/}
                {/*                        <i className="menu-bullet menu-bullet-dot"><span/></i>*/}
                {/*                        <span className="menu-text">{t('system.systemRole')}</span>*/}
                {/*                    </NavLink>*/}
                {/*                </li>*/}
                {/*            </ul>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*</li>*/}

                {/*<li*/}
                {/*    className={`menu-item`}*/}
                {/*    aria-haspopup="true"*/}
                {/*    data-menu-toggle="hover"*/}
                {/*>*/}
                {/*    <a className="menu-link menu-toggle" href="#">*/}
                {/*        <span className="svg-icon menu-icon">*/}
                {/*          <i className="icon-1_8x text-dark-50 flaticon-layer"/>*/}
                {/*        </span>*/}
                {/*        <span className="menu-text"><b>{t('setting.teacherSetting')}</b></span><i className="menu-arrow"/>*/}
                {/*    </a>*/}
                {/*    <div className="menu-submenu">*/}
                {/*        <ul className="menu-subnav">*/}
                {/*            <ul className="menu-subnav">*/}
                {/*                <li*/}
                {/*                    className={`menu-item ${getMenuItemActive("/setting/teacher/status")}`}*/}
                {/*                    aria-haspopup="true"*/}
                {/*                >*/}
                {/*                    <NavLink className="menu-link" to="/setting/teacher/status">*/}
                {/*                        <i className="menu-bullet menu-bullet-dot"><span/></i>*/}
                {/*                        <span className="menu-text">{t('setting.teacherStatus')}</span>*/}
                {/*                    </NavLink>*/}
                {/*                </li>*/}
                {/*            </ul>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*    <div className="menu-submenu">*/}
                {/*        <ul className="menu-subnav">*/}
                {/*            <ul className="menu-subnav">*/}
                {/*                <li*/}
                {/*                    className={`menu-item ${getMenuItemActive("/setting/hr/status")}`}*/}
                {/*                    aria-haspopup="true"*/}
                {/*                >*/}
                {/*                    <NavLink className="menu-link" to="/setting/hr/status">*/}
                {/*                        <i className="menu-bullet menu-bullet-dot"><span/></i>*/}
                {/*                        <span className="menu-text">{t('setting.employeeStatus')}</span>*/}
                {/*                    </NavLink>*/}
                {/*                </li>*/}
                {/*            </ul>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*</li>*/}

                {/*<li*/}
                {/*    className={`menu-item`}*/}
                {/*    aria-haspopup="true"*/}
                {/*    data-menu-toggle="hover"*/}
                {/*>*/}
                {/*    <a className="menu-link menu-toggle" href="#">*/}
                {/*        <span className="svg-icon menu-icon">*/}
                {/*          <i className="icon-1_8x text-dark-50 flaticon-layer"/>*/}
                {/*        </span>*/}
                {/*        <span className="menu-text"><b>{t('setting.reference')}</b></span><i className="menu-arrow"/>*/}
                {/*    </a>*/}
                {/*    <div className="menu-submenu">*/}
                {/*        <ul className="menu-subnav">*/}
                {/*            <ul className="menu-subnav">*/}
                {/*                <li*/}
                {/*                    className={`menu-item ${getMenuItemActive("/setting/r/country")}`}*/}
                {/*                    aria-haspopup="true"*/}
                {/*                >*/}
                {/*                    <NavLink className="menu-link" to="/setting/r/country">*/}
                {/*                        <i className="menu-bullet menu-bullet-dot"><span/></i>*/}
                {/*                        <span className="menu-text">{t('common.country')}</span>*/}
                {/*                    </NavLink>*/}
                {/*                </li>*/}
                {/*            </ul>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*    <div className="menu-submenu">*/}
                {/*        <ul className="menu-subnav">*/}
                {/*            <ul className="menu-subnav">*/}
                {/*                <li*/}
                {/*                    className={`menu-item ${getMenuItemActive("/setting/r/address-segment")}`}*/}
                {/*                    aria-haspopup="true"*/}
                {/*                >*/}
                {/*                    <NavLink className="menu-link" to="/setting/r/address-segment">*/}
                {/*                        <i className="menu-bullet menu-bullet-dot"><span/></i>*/}
                {/*                        <span className="menu-text">{t('setting.addressSegment.title')}</span>*/}
                {/*                    </NavLink>*/}
                {/*                </li>*/}
                {/*            </ul>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*    <div className="menu-submenu">*/}
                {/*        <ul className="menu-subnav">*/}
                {/*            <ul className="menu-subnav">*/}
                {/*                <li*/}
                {/*                    className={`menu-item ${getMenuItemActive("/setting/r/contact-type")}`}*/}
                {/*                    aria-haspopup="true"*/}
                {/*                >*/}
                {/*                    <NavLink className="menu-link" to="/setting/r/contact-type">*/}
                {/*                        <i className="menu-bullet menu-bullet-dot"><span/></i>*/}
                {/*                        <span className="menu-text">{t('setting.contactType.title')}</span>*/}
                {/*                    </NavLink>*/}
                {/*                </li>*/}
                {/*            </ul>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*    <div className="menu-submenu">*/}
                {/*        <ul className="menu-subnav">*/}
                {/*            <ul className="menu-subnav">*/}
                {/*                <li*/}
                {/*                    className={`menu-item ${getMenuItemActive("/setting/r/education")}`}*/}
                {/*                    aria-haspopup="true"*/}
                {/*                >*/}
                {/*                    <NavLink className="menu-link" to="/setting/r/education">*/}
                {/*                        <i className="menu-bullet menu-bullet-dot"><span/></i>*/}
                {/*                        <span className="menu-text">{t('curriculum.education')}</span>*/}
                {/*                    </NavLink>*/}
                {/*                </li>*/}
                {/*            </ul>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*</li>*/}
                {/*Хөтөлбөрийн тохиргоо*/}
                {/*<li*/}
                {/*    className={`menu-item`}*/}
                {/*    aria-haspopup="true"*/}
                {/*    data-menu-toggle="hover"*/}
                {/*>*/}
                {/*    <a className="menu-link menu-toggle" href="#">*/}
                {/*        <span className="svg-icon menu-icon">*/}
                {/*          <i className="icon-1_8x text-dark-50 flaticon-layer"/>*/}
                {/*        </span>*/}
                {/*        <span className="menu-text"><b>{t('menu.curriculumConfig')}</b></span><i className="menu-arrow"/>*/}
                {/*    </a>*/}
                {/*    <div className="menu-submenu">*/}
                {/*        <ul className="menu-subnav">*/}
                {/*            <ul className="menu-subnav">*/}
                {/*                <li*/}
                {/*                    className={`menu-item ${getMenuItemActive("/setting/curriculum/subject-type")}`}*/}
                {/*                    aria-haspopup="true"*/}
                {/*                >*/}
                {/*                    <NavLink className="menu-link" to="/setting/curriculum/subject-type">*/}
                {/*                        <i className="menu-bullet menu-bullet-dot"><span/></i>*/}
                {/*                        <span className="menu-text">{t('menu.curriculumSubjectType')}</span>*/}
                {/*                    </NavLink>*/}
                {/*                </li>*/}
                {/*            </ul>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*    <div className="menu-submenu">*/}
                {/*        <ul className="menu-subnav">*/}
                {/*            <ul className="menu-subnav">*/}
                {/*                <li*/}
                {/*                    className={`menu-item ${getMenuItemActive("/setting/curriculum/status")}`}*/}
                {/*                    aria-haspopup="true"*/}
                {/*                >*/}
                {/*                    <NavLink className="menu-link" to="/setting/curriculum/status">*/}
                {/*                        <i className="menu-bullet menu-bullet-dot"><span/></i>*/}
                {/*                        <span className="menu-text">{t('menu.curriculumStatus')}</span>*/}
                {/*                    </NavLink>*/}
                {/*                </li>*/}
                {/*            </ul>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*    <div className="menu-submenu">*/}
                {/*        <ul className="menu-subnav">*/}
                {/*            <ul className="menu-subnav">*/}
                {/*                <li*/}
                {/*                    className={`menu-item ${getMenuItemActive("/setting/curriculum/type")}`}*/}
                {/*                    aria-haspopup="true"*/}
                {/*                >*/}
                {/*                    <NavLink className="menu-link" to="/setting/curriculum/type">*/}
                {/*                        <i className="menu-bullet menu-bullet-dot"><span/></i>*/}
                {/*                        <span className="menu-text">{t('menu.curriculumType')}</span>*/}
                {/*                    </NavLink>*/}
                {/*                </li>*/}
                {/*            </ul>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*    <div className="menu-submenu">*/}
                {/*        <ul className="menu-subnav">*/}
                {/*            <ul className="menu-subnav">*/}
                {/*                <li*/}
                {/*                    className={`menu-item ${getMenuItemActive("/setting/curriculum/approach")}`}*/}
                {/*                    aria-haspopup="true"*/}
                {/*                >*/}
                {/*                    <NavLink className="menu-link" to="/setting/curriculum/approach">*/}
                {/*                        <i className="menu-bullet menu-bullet-dot"><span/></i>*/}
                {/*                        <span className="menu-text">{t('menu.approach')}</span>*/}
                {/*                    </NavLink>*/}
                {/*                </li>*/}
                {/*            </ul>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*    <div className="menu-submenu">*/}
                {/*        <ul className="menu-subnav">*/}
                {/*            <ul className="menu-subnav">*/}
                {/*                <li*/}
                {/*                    className={`menu-item ${getMenuItemActive("/setting/curriculum/category")}`}*/}
                {/*                    aria-haspopup="true"*/}
                {/*                >*/}
                {/*                    <NavLink className="menu-link" to="/setting/curriculum/category">*/}
                {/*                        <i className="menu-bullet menu-bullet-dot"><span/></i>*/}
                {/*                        <span className="menu-text">{t('menu.subjectCategory')}</span>*/}
                {/*                    </NavLink>*/}
                {/*                </li>*/}
                {/*            </ul>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*    <div className="menu-submenu">*/}
                {/*        <ul className="menu-subnav">*/}
                {/*            <ul className="menu-subnav">*/}
                {/*                <li*/}
                {/*                    className={`menu-item ${getMenuItemActive("/setting/curriculum/ptype")}`}*/}
                {/*                    aria-haspopup="true"*/}
                {/*                >*/}
                {/*                    <NavLink className="menu-link" to="/setting/curriculum/ptype">*/}
                {/*                        <i className="menu-bullet menu-bullet-dot"><span/></i>*/}
                {/*                        <span className="menu-text">{t('menu.learningType')}</span>*/}
                {/*                    </NavLink>*/}
                {/*                </li>*/}
                {/*            </ul>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*    <div className="menu-submenu">*/}
                {/*        <ul className="menu-subnav">*/}
                {/*            <ul className="menu-subnav">*/}
                {/*                <li*/}
                {/*                    className={`menu-item ${getMenuItemActive("/setting/curriculum/time")}`}*/}
                {/*                    aria-haspopup="true"*/}
                {/*                >*/}
                {/*                    <NavLink className="menu-link" to="/setting/curriculum/time">*/}
                {/*                        <i className="menu-bullet menu-bullet-dot"><span/></i>*/}
                {/*                        <span className="menu-text">{t('menu.subjectType')}</span>*/}
                {/*                    </NavLink>*/}
                {/*                </li>*/}
                {/*            </ul>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*</li>*/}

                {/*<li*/}
                {/*    className={`menu-item`}*/}
                {/*    aria-haspopup="true"*/}
                {/*    data-menu-toggle="hover"*/}
                {/*>*/}
                {/*    <a className="menu-link menu-toggle" href="#">*/}
                {/*        <span className="svg-icon menu-icon">*/}
                {/*          <i className="icon-1_8x text-dark-50 flaticon-layer"/>*/}
                {/*        </span>*/}
                {/*        <span className="menu-text"><b>{t('setting.class.config')}</b></span><i className="menu-arrow"/>*/}
                {/*    </a>*/}
                {/*    <div className="menu-submenu">*/}
                {/*        <ul className="menu-subnav">*/}
                {/*            <ul className="menu-subnav">*/}
                {/*                <li*/}
                {/*                    className={`menu-item ${getMenuItemActive("/setting/c/class-type")}`}*/}
                {/*                    aria-haspopup="true"*/}
                {/*                >*/}
                {/*                    <NavLink className="menu-link" to="/setting/c/class-type">*/}
                {/*                        <i className="menu-bullet menu-bullet-dot"><span/></i>*/}
                {/*                        <span className="menu-text">{t('setting.class.classType')}</span>*/}
                {/*                    </NavLink>*/}
                {/*                </li>*/}
                {/*            </ul>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*    <div className="menu-submenu">*/}
                {/*        <ul className="menu-subnav">*/}
                {/*            <ul className="menu-subnav">*/}
                {/*                <li*/}
                {/*                    className={`menu-item ${getMenuItemActive("/setting/c/class-status")}`}*/}
                {/*                    aria-haspopup="true"*/}
                {/*                >*/}
                {/*                    <NavLink className="menu-link" to="/setting/c/class-status">*/}
                {/*                        <i className="menu-bullet menu-bullet-dot"><span/></i>*/}
                {/*                        <span className="menu-text">{t('setting.class.classStatus')}</span>*/}
                {/*                    </NavLink>*/}
                {/*                </li>*/}
                {/*            </ul>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*</li>*/}

                {/*<li*/}
                {/*    className={`menu-item`}*/}
                {/*    aria-haspopup="true"*/}
                {/*    data-menu-toggle="hover"*/}
                {/*>*/}
                {/*    <a className="menu-link menu-toggle" href="#">*/}
                {/*        <span className="svg-icon menu-icon">*/}
                {/*          <i className="icon-1_8x text-dark-50 flaticon-layer"/>*/}
                {/*        </span>*/}
                {/*        <span className="menu-text"><b>{t('setting.student.title')}</b></span><i className="menu-arrow"/>*/}
                {/*    </a>*/}
                {/*    <div className="menu-submenu">*/}
                {/*        <ul className="menu-subnav">*/}
                {/*            <ul className="menu-subnav">*/}
                {/*                <li*/}
                {/*                    className={`menu-item ${getMenuItemActive("/setting/s/status")}`}*/}
                {/*                    aria-haspopup="true"*/}
                {/*                >*/}
                {/*                    <NavLink className="menu-link" to="/setting/s/status">*/}
                {/*                        <i className="menu-bullet menu-bullet-dot"><span/></i>*/}
                {/*                        <span className="menu-text">{t('student.status')}</span>*/}
                {/*                    </NavLink>*/}
                {/*                </li>*/}
                {/*            </ul>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*    <div className="menu-submenu">*/}
                {/*        <ul className="menu-subnav">*/}
                {/*            <ul className="menu-subnav">*/}
                {/*                <li*/}
                {/*                    className={`menu-item ${getMenuItemActive("/setting/s/config-status")}`}*/}
                {/*                    aria-haspopup="true"*/}
                {/*                >*/}
                {/*                    <NavLink className="menu-link" to="/setting/s/config-status">*/}
                {/*                        <i className="menu-bullet menu-bullet-dot"><span/></i>*/}
                {/*                        <span className="menu-text">{t('setting.student.status')}</span>*/}
                {/*                    </NavLink>*/}
                {/*                </li>*/}
                {/*            </ul>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*</li>*/}

                {/*<li*/}
                {/*    className={`menu-item`}*/}
                {/*    aria-haspopup="true"*/}
                {/*    data-menu-toggle="hover"*/}
                {/*>*/}
                {/*    <a className="menu-link menu-toggle" href="#">*/}
                {/*        <span className="svg-icon menu-icon">*/}
                {/*          <i className="icon-1_8x text-dark-50 flaticon-layer"/>*/}
                {/*        </span>*/}
                {/*        <span className="menu-text"><b>{t('setting.movement.title')}</b></span><i className="menu-arrow"/>*/}
                {/*    </a>*/}
                {/*    <div className="menu-submenu">*/}
                {/*        <ul className="menu-subnav">*/}
                {/*            <ul className="menu-subnav">*/}
                {/*                <li*/}
                {/*                    className={`menu-item ${getMenuItemActive("/setting/mv/type")}`}*/}
                {/*                    aria-haspopup="true"*/}
                {/*                >*/}
                {/*                    <NavLink className="menu-link" to="/setting/mv/type">*/}
                {/*                        <i className="menu-bullet menu-bullet-dot"><span/></i>*/}
                {/*                        <span className="menu-text">{t('movement.movementType')}</span>*/}
                {/*                    </NavLink>*/}
                {/*                </li>*/}
                {/*            </ul>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*    <div className="menu-submenu">*/}
                {/*        <ul className="menu-subnav">*/}
                {/*            <ul className="menu-subnav">*/}
                {/*                <li*/}
                {/*                    className={`menu-item ${getMenuItemActive("/setting/mv/cause")}`}*/}
                {/*                    aria-haspopup="true"*/}
                {/*                >*/}
                {/*                    <NavLink className="menu-link" to="/setting/mv/cause">*/}
                {/*                        <i className="menu-bullet menu-bullet-dot"><span/></i>*/}
                {/*                        <span className="menu-text">{t('movement.movementCause')}</span>*/}
                {/*                    </NavLink>*/}
                {/*                </li>*/}
                {/*            </ul>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*</li>*/}

                {/*<li*/}
                {/*    className={`menu-item`}*/}
                {/*    aria-haspopup="true"*/}
                {/*    data-menu-toggle="hover"*/}
                {/*>*/}
                {/*    <a className="menu-link menu-toggle" href="#">*/}
                {/*        <span className="svg-icon menu-icon">*/}
                {/*          <i className="icon-1_8x text-dark-50 flaticon-layer"/>*/}
                {/*        </span>*/}
                {/*        <span className="menu-text"><b>{t('setting.timetable.title')}</b></span><i className="menu-arrow"/>*/}
                {/*    </a>*/}
                {/*    <div className="menu-submenu">*/}
                {/*        <ul className="menu-subnav">*/}
                {/*            <ul className="menu-subnav">*/}
                {/*                <li*/}
                {/*                    className={`menu-item ${getMenuItemActive("/setting/tt/weekdays")}`}*/}
                {/*                    aria-haspopup="true"*/}
                {/*                >*/}
                {/*                    <NavLink className="menu-link" to="/setting/tt/weekdays">*/}
                {/*                        <i className="menu-bullet menu-bullet-dot"><span/></i>*/}
                {/*                        <span className="menu-text">{t('common.weekday')}</span>*/}
                {/*                    </NavLink>*/}
                {/*                </li>*/}
                {/*            </ul>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*    <div className="menu-submenu">*/}
                {/*        <ul className="menu-subnav">*/}
                {/*            <ul className="menu-subnav">*/}
                {/*                <li*/}
                {/*                    className={`menu-item ${getMenuItemActive("/setting/tt/type")}`}*/}
                {/*                    aria-haspopup="true"*/}
                {/*                >*/}
                {/*                    <NavLink className="menu-link" to="/setting/tt/type">*/}
                {/*                        <i className="menu-bullet menu-bullet-dot"><span/></i>*/}
                {/*                        <span className="menu-text">{t('timetable.type')}</span>*/}
                {/*                    </NavLink>*/}
                {/*                </li>*/}
                {/*            </ul>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*    <div className="menu-submenu">*/}
                {/*        <ul className="menu-subnav">*/}
                {/*            <ul className="menu-subnav">*/}
                {/*                <li*/}
                {/*                    className={`menu-item ${getMenuItemActive("/setting/tt/selection")}`}*/}
                {/*                    aria-haspopup="true"*/}
                {/*                >*/}
                {/*                    <NavLink className="menu-link" to="/setting/tt/selection">*/}
                {/*                        <i className="menu-bullet menu-bullet-dot"><span/></i>*/}
                {/*                        <span className="menu-text">{t('setting.selection.title')}</span>*/}
                {/*                    </NavLink>*/}
                {/*                </li>*/}
                {/*            </ul>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*</li>*/}

                {/*<li*/}
                {/*    className={`menu-item`}*/}
                {/*    aria-haspopup="true"*/}
                {/*    data-menu-toggle="hover"*/}
                {/*>*/}
                {/*    <a className="menu-link menu-toggle" href="#">*/}
                {/*        <span className="svg-icon menu-icon">*/}
                {/*          <i className="icon-1_8x text-dark-50 flaticon-layer"/>*/}
                {/*        </span>*/}
                {/*        <span className="menu-text"><b>{t('setting.assessment.title')}</b></span><i className="menu-arrow"/>*/}
                {/*    </a>*/}
                {/*    <div className="menu-submenu">*/}
                {/*        <ul className="menu-subnav">*/}
                {/*            <ul className="menu-subnav">*/}
                {/*                <li*/}
                {/*                    className={`menu-item ${getMenuItemActive("/setting/as/assessment")}`}*/}
                {/*                    aria-haspopup="true"*/}
                {/*                >*/}
                {/*                    <NavLink className="menu-link" to="/setting/as/assessment">*/}
                {/*                        <i className="menu-bullet menu-bullet-dot"><span/></i>*/}
                {/*                        <span className="menu-text">{t('assessment.title')}</span>*/}
                {/*                    </NavLink>*/}
                {/*                </li>*/}
                {/*            </ul>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*    <div className="menu-submenu">*/}
                {/*        <ul className="menu-subnav">*/}
                {/*            <ul className="menu-subnav">*/}
                {/*                <li*/}
                {/*                    className={`menu-item ${getMenuItemActive("/setting/as/exam-type")}`}*/}
                {/*                    aria-haspopup="true"*/}
                {/*                >*/}
                {/*                    <NavLink className="menu-link" to="/setting/as/exam-type">*/}
                {/*                        <i className="menu-bullet menu-bullet-dot"><span/></i>*/}
                {/*                        <span className="menu-text">{t('examType.title')}</span>*/}
                {/*                    </NavLink>*/}
                {/*                </li>*/}
                {/*            </ul>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*    <div className="menu-submenu">*/}
                {/*        <ul className="menu-subnav">*/}
                {/*            <ul className="menu-subnav">*/}
                {/*                <li*/}
                {/*                    className={`menu-item ${getMenuItemActive("/setting/as/st-item")}`}*/}
                {/*                    aria-haspopup="true"*/}
                {/*                >*/}
                {/*                    <NavLink className="menu-link" to="/setting/as/st-item">*/}
                {/*                        <i className="menu-bullet menu-bullet-dot"><span/></i>*/}
                {/*                        <span className="menu-text">{t('assessment.specification')}</span>*/}
                {/*                    </NavLink>*/}
                {/*                </li>*/}
                {/*            </ul>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*    <div className="menu-submenu">*/}
                {/*        <ul className="menu-subnav">*/}
                {/*            <ul className="menu-subnav">*/}
                {/*                <li*/}
                {/*                    className={`menu-item ${getMenuItemActive("/setting/as/st-template")}`}*/}
                {/*                    aria-haspopup="true"*/}
                {/*                >*/}
                {/*                    <NavLink className="menu-link" to="/setting/as/st-template">*/}
                {/*                        <i className="menu-bullet menu-bullet-dot"><span/></i>*/}
                {/*                        <span className="menu-text">{t('assessment.stTemplate')}</span>*/}
                {/*                    </NavLink>*/}
                {/*                </li>*/}
                {/*            </ul>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*</li>*/}

                {/*<li*/}
                {/*    className={`menu-item`}*/}
                {/*    aria-haspopup="true"*/}
                {/*    data-menu-toggle="hover"*/}
                {/*>*/}
                {/*    <a className="menu-link menu-toggle" href="#">*/}
                {/*        <span className="svg-icon menu-icon">*/}
                {/*          <i className="icon-1_8x text-dark-50 flaticon-layer"/>*/}
                {/*        </span>*/}
                {/*        <span className="menu-text"><b>{t('setting.language.title')}</b></span><i className="menu-arrow"/>*/}
                {/*    </a>*/}
                {/*    <div className="menu-submenu">*/}
                {/*        <ul className="menu-subnav">*/}
                {/*            <ul className="menu-subnav">*/}
                {/*                <li*/}
                {/*                    className={`menu-item ${getMenuItemActive("/setting/lang/sys")}`}*/}
                {/*                    aria-haspopup="true"*/}
                {/*                >*/}
                {/*                    <NavLink className="menu-link" to="/setting/lang/sys">*/}
                {/*                        <i className="menu-bullet menu-bullet-dot"><span/></i>*/}
                {/*                        <span className="menu-text">{t('setting.language.system')}</span>*/}
                {/*                    </NavLink>*/}
                {/*                </li>*/}
                {/*            </ul>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*</li>*/}
            </ul>
        </>
    );
}
