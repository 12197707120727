import React, { useState, useEffect } from 'react';
import { Button, Card, Form } from "react-bootstrap";
// import FormCheck from 'react-bootstrap/FormCheck';
import Instruction from "../../modules/instruction";
import Select from "../../modules/Form/Select";
import { useTranslation } from "react-i18next";
import Checkbox from "../../modules/Form/Checkbox";
import DTable from "../../modules/DataTable/DTable";
import { useDispatch, useSelector } from "react-redux";
import { timetableAdd, timetableAddPotok, timetableInit, timetableSubjectInfo } from "../../../utils/fetchRequest/Urls";
import { setLoading } from "../../../redux/action";
import message from "../../modules/message";
import { fetchRequest } from "../../../utils/fetchRequest";
import TimetableAddModal from "./AddModal";
import { cloneDeep, set } from "lodash";
import {Link, useHistory} from "react-router-dom";
import EditModal from "./EditModal";
import AddPotokModal from './AddPotokModal';
import EditPotokModal from './EditPotokModal';

const TimetableAdd = ({
    location: { state :{ seasonId = null } = {} } = {},
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    const [ schoolShifts, setSchoolShifts ] = useState([]);
    const [ shiftId, setShiftId ] = useState(null);
    const [ schoolIds, setSchoolIds ] = useState([]);
    const [ schools, setSchools ] = useState([]);
    const [ subjects, setSubjects ] = useState([]);
    const [ subjectId, setSubjectId ] = useState(null);
    const [ pTimes, setPTimes ] = useState([]);
    const [ showModal, setShowModal ] = useState(false);
    const [ showPotokModal, setShowPotokModal ] = useState(false);
    const [ programs, setPrograms ] = useState([]);
    const [ selectionCount, setSelectionCount ] = useState(0);
    const [ isOnline, setIsOnline ] = useState(false);
    const [ isSelective, setIsSelective ] = useState(false);
    const [ isGroup, setIsGroup ] = useState(false);
    const [ timetables, setTimetables ] = useState([]);
    const [ editIndex, setEditIndex ] = useState(null);
    const [ editName, setEditName ] = useState('');
    const [ editPotokId, setEditPotokId ] = useState(null);
    const [ configs, setConfigs ] = useState(true);
    const [ selectedConfigId, setSelectedConfigId ] = useState(null);
    const [ isPotok, setIsPotok ] = useState(null);
    const [ selectedPotokIds, setSelectedPotokIds] = useState([]);
    const [ name, setName] = useState(null);
    const [ description, setDescription] = useState('');

    useEffect(() => {
        const params = {
            school: schoolId
        };
        dispatch(setLoading(true));
        fetchRequest(timetableAdd, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;

                    if (data?.pTimes?.length) {
                        setPTimes(data.pTimes);
                    }

                    if(data?.configs){
                        if(!selectedConfigId){
                            for(let i = 0; i < data.configs.length; i++) {
                                if(data.configs[i].code.toLowerCase() == 'primary'){
                                    setSelectedConfigId(data.configs[i].id);
                                }
                            }
                        }
                        setConfigs(data.configs);
                    }
                    setSubjects(data.subjects);

                    if (data?.schoolShifts?.length) {
                        setSchoolShifts(data.schoolShifts.map(shift => {
                            return {
                                ...shift,
                            }
                        }))
                    }

                    if(!shiftId){
                        let selectedMainShiftId = null;
                        if(data.schoolShifts && data.schoolShifts.length > 0){
                            let schoolShiftList = data.schoolShifts;
                            for(let i = 0; i < schoolShiftList.length; i++){
                                if(schoolShiftList[i].code.toLowerCase() == 'main'){
                                    selectedMainShiftId = schoolShiftList[i].value;
                                }
                            }
                        }

                        setShiftId(selectedMainShiftId);
                    }

                    setSchools(data?.schools || []);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            });
    }, []);

    const handleAddClick = () => {
        setShowModal(true);
    };

    const handlePotokClick = () => {
        if(timetables && timetables.length > 0){
            let cloneSelectedPotokIds = [...selectedPotokIds];

            for(let i = 0; i < timetables.length; i++){
                if(cloneSelectedPotokIds && cloneSelectedPotokIds.length > 0){
                    if(!cloneSelectedPotokIds.includes(timetables[i].potokId)){
                        cloneSelectedPotokIds.push(timetables[i].potokId)
                    }
                } else {
                    cloneSelectedPotokIds.push(timetables[i].potokId)
                }
            }

            setSelectedPotokIds(cloneSelectedPotokIds)
        }
        setShowPotokModal(true);
    };

    const onModalClose = () => {
        setShowModal(false);
        setShowPotokModal(false);
        setEditName('');
        setEditIndex(null);
        setEditPotokId(null);
    };

    const getSubjects = () => {
        return cloneDeep(subjects).map(subject => {
            return {
                ...subject,
                value: subject.id,
                text: subject.code + ' - ' + subject.name
            }
        })
    };

    const handleSubjectChange = value => {
        setTimetables([]);
        setEditIndex(null);
        setSubjectId(value);
        const params = {
            season: seasonId,
            school: schoolId,
            subject: value
        };
        dispatch(setLoading(true));
        fetchRequest(timetableSubjectInfo, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    // setSelectionCount(data.selectionCount || 0);
                    setPrograms(data.programs || []);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            })
    };

    const onIsOnlineChange = () => {
        setIsOnline(!isOnline);
    };

    const onIsSelectiveChange = () => {
        setIsSelective(!isSelective);
    };

    const onTimetableSave = timetableData => {
        const newTimetables = [...timetables, ...timetableData];
        setTimetables(newTimetables.map((tt, index) => ({...tt, id: index, index})));
        setIsGroup(true);
        onModalClose();
    };

    const onPotokTimetableSave = timetableData => {
        if(timetables && timetables.length > 0){
            let error = false;

            for(let i = 0; i < timetables.length; i++){
                if(parseInt(timetables[i].potokId) == parseInt(timetableData[0].potokId)){
                    error = true;
                }
            }

            if(error){
                message('Тухайн бүлэг бүртгэгдсэн байна, та засах дарж орж засна уу')
            } else {
                const newTimetables = [...timetables, ...timetableData];
                setTimetables(newTimetables.map((tt, index) => ({...tt, id: index, index})));
                setName(timetableData[0].name);
                setIsPotok(true);
                onModalClose();
            }
        } else {
            const newTimetables = [...timetables, ...timetableData];
            setTimetables(newTimetables.map((tt, index) => ({...tt, id: index, index})));
            setName(timetableData[0].name);
            setIsPotok(true);
            onModalClose();
        }
    };

    const onPotokEditTimetableSave = updated => {
        const newTimetables = [...updated];
        if(timetables && timetables.length > 0){
            let leftTimetable = timetables.filter((timetable) => (timetable.potokId !== newTimetables[0].potokId))

            const mergedArray = [...leftTimetable, ...newTimetables];
            const sortedArray = mergedArray.sort(function(a, b){return a.name-b.name});
            setTimetables(sortedArray);
        }
        onModalClose();
    };

    const onEditRowClick = ({ index }) => {
        setEditIndex(index);
    };

    const onRemoveRowClick = ({ index }, array) => {
        const clone = [...array];
        clone.splice(index, 1);
        setTimetables(clone.map((tt, index) => ({...tt, id: index, index})));
    };

    const onEditPermanentRowClick = (index, name) => {
        setEditIndex(index);
        setEditName(name);
    };

    const onRemovePermanentRowClick = (index , array) => {
        const object = timetables.find(tt => tt.index === index);
        if(object){
            let filterObjects = timetables.filter(tt => tt.name !== object.name);
            if(filterObjects.length == 0){
                setIsGroup(false);
            }

            setTimetables(filterObjects.map((tt, index) => ({...tt, id: index, index})));
        }
    };

    const onEditPotokRowClick = (potokId) => {
        setEditPotokId(potokId);
    };

    const onRemovePotokRowClick = (potokId) => {
        if(timetables && timetables.length > 0){
            const object = timetables.find(tt => tt.potokId == potokId);

            if(object){
                let filterObjects = timetables.filter(tt => tt.potokId !== object.potokId);

                if(filterObjects && filterObjects.length > 0){
                    setTimetables(filterObjects.map((tt, index) => ({...tt, id: index, index})));
                } else {
                    setIsPotok(false);
                    setTimetables(filterObjects.map((tt, index) => ({...tt, id: index, index})));
                }
            }
        } else {
            setIsPotok(false);
        }
    };

    const getColumns = () => {
        if (isSelective) {
            return [
                {
                    dataField: "dayName",
                    text: t('common.weekday'),
                },
                {
                    dataField: "timeName",
                    text: t('subject.time'),
                },
                {
                    dataField: "teacherName",
                    text: t('subject.teacher'),
                },
                {
                    dataField: "roomName",
                    text: t('school.classroom.title'),
                },
                {
                    dataField: "max",
                    text: t('school.classroom.seatCount'),
                    align: 'right',
                },
                {
                    dataField: "timetableTypeName",
                    text: t('subject.studyType'),
                },
                {
                    isDummyField: true,
                    dataField: "actions",
                    text: '',
                    formatExtraData: timetables,
                    formatter: (cell, row, rowIndex, extraData) => {
                        return (
                            <div>
                                <button className='btn btn-primary row-action-button ml-3' style={{ backgroundColor: '#716aca', borderColor: 'transparent' }} onClick={() => onEditRowClick(row)}>
                                    <i className='las la-pen' style={{ fontSize: 20 }} />
                                </button>
                                <button className='btn btn-danger row-action-button ml-3' onClick={() => onRemoveRowClick(row, extraData)}>
                                    <i className='las la-times' style={{ fontSize: 20 }} />
                                </button>
                            </div>
                        )
                    }
                },
            ];
        }
        return [
            {
                dataField: "dayName",
                text: t('common.weekday'),
            },
            {
                dataField: "timeName",
                text: t('subject.time'),
            },
            {
                dataField: "pTimeName",
                text: t('subject.type'),
            },
            {
                dataField: "teacherName",
                text: t('subject.teacher'),
            },
            {
                dataField: "roomName",
                text: t('school.classroom.title'),
            },
            {
                dataField: "seatCount",
                text: t('school.classroom.seatCount'),
                align: 'right',
            },
            {
                dataField: "timetableTypeName",
                text: t('subject.studyType'),
            },
            // {
            //     isDummyField: true,
            //     dataField: "actions",
            //     text: '',
            //     formatExtraData: timetables,
            //     formatter: (cell, row, rowIndex, extraData) => {
            //         return (
            //             <div>
            //                 <button className='btn btn-primary row-action-button ml-3' style={{ backgroundColor: '#716aca', borderColor: 'transparent' }} onClick={() => onEditRowClick(row)}>
            //                     <i className='las la-pen' style={{ fontSize: 20 }} />
            //                 </button>
            //                 <button className='btn btn-danger row-action-button ml-3' onClick={() => onRemoveRowClick(row, extraData)}>
            //                     <i className='las la-times' style={{ fontSize: 20 }} />
            //                 </button>
            //             </div>
            //         )
            //     }
            // },
        ];
    };

    const potokColumns = [
        {
            dataField: "dayName",
            text: t('common.weekday'),
        },
        {
            dataField: "timeName",
            text: t('subject.time'),
        },
        {
            dataField: "pTimeName",
            text: t('subject.type'),
        },
        {
            dataField: "teacherName",
            text: t('subject.teacher'),
        },
        {
            dataField: "roomName",
            text: t('school.classroom.title'),
        },
        {
            dataField: "seatCount",
            text: t('school.classroom.seatCount'),
            align: 'right',
        },
        {
            dataField: "timetableTypeName",
            text: t('subject.studyType'),
        },
    ];

    const renderTables = () => {
        if(isPotok){
            const groups = [];
            for (const timetable of timetables) {
                if (!groups.includes(timetable.name)) {
                    groups.push(timetable.name)
                }
            }

            return groups.map((group, index) => {
                const data = timetables.filter(tt => tt.name === group);

                const groupByCategory = data.reduce((group, product) => {
                    const { potokId } = product;
                    group[potokId] = group[potokId] ?? [];
                    group[potokId].push(product);
                    return group;
                }, {});

                let renderData = [
                    <div key={'group_potok_' + index}>
                        <b>{group}</b>
                    </div>
                ];

                for( let key in groupByCategory ){
                    renderData.push(
                        <Card
                            key={'potok_' + index + '_' + key}
                            className='mt-3'
                        >
                            <Card.Body>
                                <div className='col-12'>
                                    <div style={{display: 'inline-block'}}>
                                        {groupByCategory[key][0]?.potokName || ''}
                                    </div>
                                    <div style={{display: 'inline-block'}}>
                                        <button className='btn btn-primary row-action-button ml-2' style={{ backgroundColor: '#716aca', borderColor: 'transparent' }} onClick={() => onEditPotokRowClick(key)}>
                                            <i className='las la-pen' style={{ fontSize: 20 }} />
                                        </button>
                                        <button className='btn btn-danger row-action-button ml-2' onClick={() => onRemovePotokRowClick(key)}>
                                            <i className='las la-times' style={{ fontSize: 20 }} />
                                        </button>
                                    </div>
                                </div>
                                <DTable
                                    key={'table_' + index + '_' + key}
                                    data={groupByCategory[key]}
                                    config={{
                                        showPagination: false,
                                        showFilter: false,
                                    }}
                                    columns={potokColumns}
                                    selectMode={'radio'}
                                />
                            </Card.Body>
                        </Card>
                    )
                }

                return renderData;
            })
        } else {
            if (isSelective) {
                return pTimes.map(pTime => {
    
                    const data = timetables.filter(ttable => {
                        return ttable.pTime === pTime.id
                    });
    
                    return (
                        <div key={pTime.id}>
                            <div>{pTime.name}</div>
                            <DTable
                                data={data}
                                config={{
                                    showPagination: false,
                                    showFilter: false,
                                }}
                                columns={getColumns()}
                                selectMode={'radio'}
                            />
                        </div>
                    )
                })
            }
    
            const groups = [];
            for (const timetable of timetables) {
                if (!groups.includes(timetable.name)) {
                    groups.push(timetable.name)
                }
            }
    
            return groups.map((group, index) => {
                const data = timetables.filter(tt => tt.name === group);
                return (
                    <Card
                        className='mt-3'
                        key={index}
                    >
                        <Card.Body>
                            <div>
                                <b>{group}</b>
                                 <div>
                                     <button className='btn btn-primary row-action-button ml-3' style={{ backgroundColor: '#716aca', borderColor: 'transparent' }} onClick={() => onEditPermanentRowClick(data[0].index, data[0].name)}>
                                         <i className='las la-pen' style={{ fontSize: 20 }} />
                                     </button>
                                     <button className='btn btn-danger row-action-button ml-3' onClick={() => onRemovePermanentRowClick(data[0].index, timetables)}>
                                         <i className='las la-times' style={{ fontSize: 20 }} />
                                     </button>
                                 </div>
                            </div>
                            <DTable
                                data={data}
                                config={{
                                    showPagination: false,
                                    showFilter: false,
                                }}
                                columns={getColumns()}
                                selectMode={'radio'}
                            />
                        </Card.Body>
                    </Card>
                )
            })
        }
    };

    const onSaveClick = (isReCreate) => {
        let selectedSeasonId = '';
        if(seasonId){
            let seasonArray = seasonId.split('_');

            if(seasonArray && seasonArray.length > 0){
                for(let i = 0; i < seasonArray.length; i++){
                    if(seasonArray[i] == 's'){
                        selectedSeasonId = seasonArray[i + 1];
                    }
                }
            }
        }

        const params = {
            school: schoolId,
            isOnline: isOnline ? 1 : 0,
            isSelective: isSelective ? 1: 0,
            subject: subjectId,
            schoolShift: shiftId,
            season: selectedSeasonId,
            config: selectedConfigId,
            description: description,
            schools: JSON.stringify(schoolIds)
        };

        if(isPotok){
            const groups = [];

            for (const timetable of timetables) {
                if (!groups.includes(timetable.name)) {
                    groups.push(timetable.name)
                }
            }

            const timetableData = groups.map(group => {
                const data = timetables.filter(tt => tt.name === group);

                return {
                    name: group,
                    datas: data.map(tt => {
                        return {
                            teacher: tt.teacher,
                            day: tt.day,
                            time: tt.time,
                            pTime: tt.pTime,
                            room: tt.room,
                            potokId: tt.potokId,
                            timetableType: tt.timetableType,
                            seatCount: tt.seatCount,
                            isMain: tt.isMain,
                            isGroup: tt.isGroup,
                        }
                    })
                }
            });

            for(let i = 0; i < timetables.length; i++){
                let existingData = timetables.find(dt => timetables[i].potokId != dt.potokId && timetables[i].day == dt.day && timetables[i].time == dt.time && timetables[i].room == dt.room && timetables[i].timetableType == dt.timetableType)

                if(existingData && ((existingData.timetableTypeCode == 'EVEN' && timetables[i].timetableTypeCode == 'ODD') || (existingData.timetableTypeCode == 'ODD' && timetables[i].timetableTypeCode == 'EVEN'))){
                    
                } else if (existingData){
                    return message('Мэдээлэл давхардаж байна, Давхардаж байгаа мэдээлэл (' + existingData.dayName + ' ' + existingData.timeName + ' ' + existingData.roomName + ' ' + existingData.timetableTypeName);
                }
            }

            params.timetables = JSON.stringify(timetableData);
            if(timetables && timetables.length > 0){
                params.max = timetables[0]?.seatCount;
            }

            dispatch(setLoading(true));
            fetchRequest(timetableAddPotok, 'POST', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res;
                        if(isReCreate){
                            setTimetables([]);
                            setSelectedPotokIds([]);
                        } else {
                            history.push({
                                pathname: '/timetable',
                            });
                        }
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'));
                })
        } else {
            if (isSelective) {
                const timetableData = [];
                for (const pTime of pTimes) {
                    const data = timetables.filter(tt => tt.pTime === pTime.id);
                    if (data?.length) {
                        timetableData.push({
                            pTimeId: pTime.id,
                            datas: data.map(tt => {
                                return {
                                    day: tt.day,
                                    time: tt.time,
                                    teacher: tt.teacher,
                                    room: tt.room,
                                    max: tt.max,
                                    timetableType: tt.timetableType,
                                }
                            })
                        })
                    }
                }

                for(let i = 0; i < timetables.length; i++){
                    let existingData = timetables.find(dt => timetables[i].id != dt.id && timetables[i].day == dt.day && timetables[i].time == dt.time && timetables[i].room == dt.room && timetables[i].timetableType == dt.timetableType)

                    if(existingData && ((existingData.timetableTypeCode == 'EVEN' && timetables[i].timetableTypeCode == 'ODD') || (existingData.timetableTypeCode == 'ODD' && timetables[i].timetableTypeCode == 'EVEN'))){
                        
                    } else if (existingData){
                        return message('Мэдээлэл давхардаж байна, Давхардаж байгаа мэдээлэл (' + existingData.dayName + ' ' + existingData.timeName + ' ' + existingData.roomName + ' ' + existingData.timetableTypeName);
                    }
                }

                params.timetables = JSON.stringify(timetableData);
            } else {
                const groups = [];
                for (const timetable of timetables) {
                    if (!groups.includes(timetable.name)) {
                        groups.push(timetable.name)
                    }
                }
                const timetableData = groups.map(group => {
                    const data = timetables.filter(tt => tt.name === group);
                    return {
                        name: group,
                        teacher: data?.[0]?.teacher,
                        datas: data.map(tt => {
                            return {
                                day: tt.day,
                                time: tt.time,
                                pTime: tt.pTime,
                                room: tt.room,
                                timetableType: tt.timetableType,
                            }
                        })
                    }
                });

                for(let i = 0; i < timetables.length; i++){
                    let existingData = timetables.find(dt => timetables[i].name != dt.name && timetables[i].day == dt.day && timetables[i].time == dt.time && timetables[i].room == dt.room && timetables[i].timetableType == dt.timetableType)

                    if(existingData && ((existingData.timetableTypeCode == 'EVEN' && timetables[i].timetableTypeCode == 'ODD') || (existingData.timetableTypeCode == 'ODD' && timetables[i].timetableTypeCode == 'EVEN'))){
                        
                    } else if (existingData){
                        return message('Мэдээлэл давхардаж байна, Давхардаж байгаа мэдээлэл (' + existingData.dayName + ' ' + existingData.timeName + ' ' + existingData.roomName + ' ' + existingData.timetableTypeName);
                    }
                }
    
                params.timetables = JSON.stringify(timetableData);
                if(timetables && timetables.length > 0){
                    params.max = timetables[0]?.seatCount;
                }
            }
    
            dispatch(setLoading(true));
            fetchRequest(timetableAdd, 'POST', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res;
                        if(isReCreate){
                            setTimetables([]);
                        } else {
                            history.push({
                                pathname: '/timetable',
                            });
                        }
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'));
                })
        }
    };

    const onEditSave = (updated) => {
        if(isSelective){
            setTimetables(updated);
        } else {
            const newTimetables = [...updated];
            if(timetables && timetables.length > 0){
                let leftTimetable = timetables.filter((timetable) => (timetable.name !== newTimetables[0].name))

                const mergedArray = [...leftTimetable, ...newTimetables];
                const sortedArray = mergedArray.sort(function(a, b){return a.name-b.name});
                setTimetables(sortedArray);
            }
        }
        onModalClose()
    };

    const onSchoolShiftChange = value => {
        setShiftId(value)
    };

    const onSchoolsChange = value => {
        setSchoolIds(value)
    };

    const onDescriptionChange = (e) => {
        setDescription(e.target.value)
    };

    const onHandlerRadioChange = (e, config) => {
        setSelectedConfigId(config.id)
    };

    return (
        <Card>
            <Card.Body className={'timetable-add'}>
                <div style={{ textAlign: 'right' }}>
                    <Instruction/>
                </div>
                <div className='row' style={{ display: 'flex', alignItems: 'center' }}>
                    <div className='col-4 text-right'>
                        {t('subject.title') + '*'}
                    </div>
                    <div className='col-8'>
                        <Select
                            className='w-250'
                            options={getSubjects()}
                            onChange={handleSubjectChange}
                            value={subjectId}
                            searchable={true}
                        />
                    </div>
                </div>
                <div className='mt-3 row' style={{ display: 'flex', alignItems: 'center' }}>
                    <div className='col-4 text-right'>
                        {t('school.schoolShift') + '*'}
                    </div>
                    <div className='col-8'>
                        <Select
                            className='w-250'
                            options={schoolShifts}
                            onChange={onSchoolShiftChange}
                            value={shiftId}
                            searchable={true}
                        />
                    </div>
                </div>
                <div className='mt-3 row' style={{ display: 'flex', alignItems: 'center' }}>
                    <div className='col-4 text-right'>
                        Харагдах сургууль
                    </div>
                    <div className='col-8'>
                        <Select
                            multiple={true}
                            className='w-250'
                            options={schools}
                            onChange={onSchoolsChange}
                            value={schoolIds}
                            searchable={true}
                        />
                    </div>
                </div>
                <div className='mt-3 row' style={{ display: 'flex', alignItems: 'center' }}>
                    <div className='col-4 text-right'>
                        Тайлбар
                    </div>
                    <div className='col-8'>
                        <textarea
                            style={{border: '1px solid #CCCCCC', borderRadius: 4}}
                            className={'w-250'}
                            type='text'
                            onChange={(e) => onDescriptionChange(e)}
                            value={description}
                        />
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-4'/>
                    <div className='col-8'>
                        Сонголт №1 <span style={{color: '#026bca', fontWeight: 900}}>{selectionCount}</span>
                    </div>
                </div>
                <div className='row' style={{display: 'contents', }}>
                    <div className='col-12 mt-4' style={{display: 'flex', overflowX: 'auto'}}>
                        {
                            programs.map((program, index) => {
                                const className = 'timetable-add-subject-programs ml-2';
                                return (
                                    <table key={index} className={className} style={{display: "inline-flex"}}>
                                        <tbody>
                                            <tr>
                                                <td>{t('curriculum.code')}</td>
                                                <th>{program.code || '-'}</th>
                                            </tr>
                                            <tr>
                                                <td>{t('curriculum.name')}</td>
                                                <th>{program.name || '-'}</th>
                                            </tr>
                                            <tr>
                                                <td>{t('curriculum.profession')}</td>
                                                <th>{program.academicProgramName || '-'}</th>
                                            </tr>
                                        </tbody>
                                    </table>
                                )
                            })
                        }
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-4'/>
                    <div className='col-8'>
                        <Checkbox
                            checked={isOnline}
                            label={t('timetable.isOnline')}
                            onChange={onIsOnlineChange}
                        />
                    </div>
                </div>
                {
                    !isPotok &&
                    <div className='row'>
                        <div className='col-4'/>
                        <div className='col-8'>
                            <Checkbox
                                checked={isSelective}
                                label={t('timetable.isSelective')}
                                onChange={onIsSelectiveChange}
                            />
                        </div>
                    </div>
                }
                <div className='row'>
                    <div className='col-4 text-right'>
                        {t('timetable.type')}
                    </div>
                    <div className='col-8'>
                        <div key={`inline-2`} className="mb-3">
                            {
                                configs && configs.length > 0 &&
                                configs.map((config, index) => (
                                    <Form.Check
                                        defaultChecked={config.code.toLowerCase() == 'primary' ? true : false}
                                        key={'radio_' + index}
                                        className='mr-2'
                                        name="timetableType"
                                        inline
                                        label={config.name}
                                        type='radio'
                                        onChange={(e) => onHandlerRadioChange(e, config)}
                                    />
                                ))
                            }
                        </div>
                    </div>
                </div>
                {
                    subjectId
                    ?
                        <>
                            {
                                !isPotok &&
                                <button
                                    className='btn btn-primary mb-3'
                                    onClick={handleAddClick}
                                >
                                    {t('common.add')}
                                </button>
                            }
                            {
                                !isSelective && !isGroup &&
                                <button
                                    className='btn btn-primary mb-3 ml-2'
                                    onClick={handlePotokClick}
                                >
                                    Поток оруулах
                                </button>
                            }
                        </>
                        
                    : null
                }
                {
                    renderTables()
                }
            </Card.Body>
            <Card.Footer>
                <div className='text-center'>
                    <Link
                        className='btn btn-link bolder'
                        to='/timetable'
                    >
                        {t('common.back')}
                    </Link>
                    <Button variant='success btn-shadow' onClick={() => onSaveClick(false)}>
                        {t('common.save')}
                    </Button>
                    <Button variant='success btn-shadow ml-2' onClick={() => onSaveClick(true)}>
                        {t('common.recreate')}
                    </Button>
                </div>
            </Card.Footer>
            {
                showPotokModal && <AddPotokModal
                    onClose={onModalClose}
                    isSelective={isSelective}
                    subject={subjectId}
                    shift={shiftId}
                    seasonId={seasonId}
                    onSave={onPotokTimetableSave}
                    selectedPotokIds={selectedPotokIds}
                />
            }
            {
                showModal && <TimetableAddModal
                    season={seasonId}
                    onClose={onModalClose}
                    isSelective={isSelective}
                    subject={subjectId}
                    shift={shiftId}
                    onSave={onTimetableSave}
                />
            }
            {
                editIndex !== null && (
                    <EditModal
                        onClose={onModalClose}
                        isSelective={isSelective}
                        subject={subjectId}
                        shift={shiftId}
                        onSave={onEditSave}
                        timetables={timetables}
                        index={editIndex}
                        nameIndex={editName}
                    />
                )
            }
            {
                editPotokId !== null && (
                    <EditPotokModal
                        onClose={onModalClose}
                        isSelective={isSelective}
                        subject={subjectId}
                        shift={shiftId}
                        onSave={onPotokEditTimetableSave}
                        timetables={timetables}
                        editPotokId={editPotokId}
                        name={name}
                    />
                )
            }
        </Card>
    );
};

export default TimetableAdd;