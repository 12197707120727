import React, {useRef, useEffect, useState} from 'react';
import { Button, Modal } from "react-bootstrap";
import Forms from "../../../../modules/Form/Forms";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { financeDiscountEdit } from "../../../../../utils/fetchRequest/Urls";
import { setLoading } from "../../../../../redux/action";
import { fetchRequest } from "../../../../../utils/fetchRequest";
import message from "../../../../modules/message";

const EditDiscount = ({
    discountId,
    onClose,
    onSubmit,
}) => {
    const { t } = useTranslation();
    const formRef = useRef();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [codes, setCodes] = useState([]);
    const [code, setCode] = useState('');
    const [name, setName] = useState('');
    const [amount, setAmount] = useState('0');
    const [isAmount, setIsAmount] = useState(false);
    const [isActive, setIsActive] = useState(true);
    const [selectedCode, setSelectedCode] = useState(null);
    const [discountData, setDiscountData] = useState(null);

    useEffect(() => {
        let params = {
            id: discountId,
            school: schoolId
        };

        dispatch(setLoading(true));
        fetchRequest(financeDiscountEdit, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    let codes = data.discountTypes;
                    let discountData = data?.discountData || [];
                    if(codes && codes.length > 0){
                        let codesArray = [];
                        for(let i = 0; i < codes.length; i++)
                        {
                            codesArray.push({
                                value: codes[i].CODE,
                                text: codes[i].NAME
                            });
                        }
                        setCodes(codesArray)
                    }
                    if(discountData){
                        setCode(discountData?.code || '');
                        setName(discountData?.name || '');

                        if(discountData.typeCode == 'INCONSTANT'){
                            setIsAmount(false);
                        } else if (discountData.typeCode == 'PERCENT'){
                            setAmount(discountData?.percentage || '');
                            setIsAmount(true);
                        } else if (discountData.typeCode == 'AMOUNT'){
                            setAmount(discountData?.amount || '');
                            setIsAmount(true);
                        } else {
                            setIsAmount(true);
                        }

                        setIsActive(discountData.isActive);
                        setSelectedCode(discountData?.typeCode || '');
                    }
                    setDiscountData(discountData);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })

    }, []);

    const onInputCodeChange = (event) => {
        if(event){
            setCode((event.target.value).toUpperCase())
        }
    };

    const onInputNameChange = (event) => {
        if(event){
            setName(event.target.value)
        }
    };

    const onCodeChange = (code) => {
        if(code === 'PERCENT' || code === 'AMOUNT'){
            setIsAmount(true);
        } else if(code === 'INCONSTANT') {
            setIsAmount(false);
        }
        setSelectedCode(code)
    };

    const onCheckboxChange = (val) => {
        if(val === false){
            setAmount(0);
        }
        setIsActive(val)
    };

    useEffect(() => {
        formRef?.current?.updateFields && formRef.current?.updateFields(fields);
    }, [codes, isAmount, selectedCode, discountData]);

    const fields = [
        {
            label: t('common.code'),
            value: code || '',
            type: 'nonCryllic',
            upperCase: true,
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            key: 'code',
            onChange: onInputCodeChange,
        },
        {
            label: t('common.name'),
            value: name || '',
            type: 'text',
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            key: 'name',
            onChange: onInputNameChange,
        },
        {
            label: t('common.type'),
            value: selectedCode || null,
            type: 'dropdown',
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            key: 'discountType',
            options: codes,
            onChange: onCodeChange,
        },
        {
            label: t('common.amount'),
            value: amount || '',
            type: 'numberOnly',
            inputWidth: 200,
            labelWidth: 300,
            required: isAmount,
            key: 'amount',
            validValue: selectedCode == 'PERCENT' ?
                [{
                    'min': 0,
                    'max': 100
                }]
                : 0,
            errorMessage: selectedCode == 'PERCENT' ? t('errorMessage.enterMinAndMax') : t('errorMessage.enterMinAmount'),
            hidden: !isAmount
        },
        {
            label: t('common.isPublish'),
            value: isActive,
            type: 'checkbox',
            inputWidth: 200,
            labelWidth: 300,
            key: 'isActive',
            onChange: onCheckboxChange,
        },
    ];

    const onSaveClick = () => {
        const [ isValid, states, values ] = formRef.current.validate();
        if (isValid) {
            if(values.isActive){
                values.isActive = 1
            } else {
                values.isActive = 0
            }
            const params = {
                ...values,
            };
            onSubmit(params);
        }
    };

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('discount.type').toUpperCase()} {t('common.edit').toUpperCase()}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Forms
                    ref={formRef}
                    fields={fields}
                />
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className='btn btn-link bolder'>{t('common.back')}</button>
                <Button variant='success btn-shadow' onClick={onSaveClick}>{t('common.save')}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditDiscount;