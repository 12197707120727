import React,{useState,useEffect} from 'react'
import { Row,Col,Card,Tab,Tabs } from 'react-bootstrap'
import TreeView from '../../../modules/TreeView'
import { useTranslation } from 'react-i18next'
import { useDispatch,useSelector } from 'react-redux'
import { fetchRequest } from '../../../../utils/fetchRequest'
import { movementExcuse } from '../../../../utils/fetchRequest/Urls'
import message from '../../../modules/message'
import StudentTable from './StudentTable'
import { Link } from 'react-router-dom'
import Instruction from '../../../modules/instruction'
import { setLoading } from '../../../../redux/action'

const ExcusedMovement = () => {

    const { t } = useTranslation()
    const dispatch = useDispatch()

    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    const [nodeId,setNodeId] = useState()
    const [treeData,setTreeData] = useState([])
    const [tabKey,setTabKey] = useState()
    const [causes,setCauses] = useState([])
    const [treeIndex] = useState('movement_excused_tree_index');

    const renderTabs = () =>{
        return causes.map(s=>{
            return <Tab key={s.id} eventKey={s.code} title={s.name}/>
        })
    }

    const handleTreeClick = array => {
        if (array?.length) {
            const [ id ] = array;
            setNodeId(id);
            localStorage.setItem(treeIndex, id);
        }
    };

    useEffect(() => {
        const params = {
            school: schoolId
        }
        const treeId = localStorage.getItem(treeIndex);
        dispatch(setLoading(true));
        fetchRequest(movementExcuse, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    const tempEl = []
                    if (data?.educationLevels?.length) {
                        data?.educationLevels.map(el=>{
                            tempEl.push({
                                title: el.name,
                                key: el.id
                            })
                        })
                    }
                    setTreeData(tempEl)
                    if(tempEl.length){
                        if(treeId && treeId != null){
                            setNodeId(parseInt(treeId));
                            }else{
                                setNodeId(tempEl[0].key)
                            }
                    }
                    setCauses(data?.movementCauses || [])
                    if(data?.movementCauses?.length){
                        setTabKey(data?.movementCauses[0].code)
                    }
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            })
    }, []);

    return (
        <Row>
            <Col md={3}>
                <Card>
                    <Card.Body>
                        <TreeView
                            treeData={treeData}
                            selectedNodes={[nodeId]}
                            onSelect={handleTreeClick}
                        />
                    </Card.Body>
                </Card>
            </Col>
            <Col md={9}>
                <Card>
                    <Card.Header>
                        <Tabs
                            id={'msue-mv-excused'}
                            activeKey={tabKey}
                            className='msue-tab'
                            onSelect={key=>setTabKey(key)}
                        >
                            {renderTabs()}
                        </Tabs>
                    </Card.Header>
                    <Card.Body>
                        <div style={{ display: 'flex' }}>
                            <Link
                                to={{
                                    pathname: "/movement/excused/add",
                                    state: { 
                                        tab: tabKey 
                                    }
                                }}
                                className='btn btn-primary mr-3'
                            >
                                {t('movement.excused.add')}
                            </Link>
                            <Instruction/>
                        </div>
                        <StudentTable nodeId={nodeId} schoolId={schoolId} cause={tabKey}/>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}

export default ExcusedMovement