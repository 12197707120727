import React, { useEffect, useReducer } from "react";
import { Card } from "react-bootstrap";
import { Editor } from "react-draft-wysiwyg";
import { useTranslation } from "react-i18next";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import { questionBankEdit } from "../../../../utils/fetchRequest/Urls";
import { formatSelectData } from "../../../../utils/helpers/select";
import message from "../../../modules/message";
import { useDispatch, useSelector } from "react-redux";
import Select from "../../../modules/Form/Select";
import { TrueOrFalse, Radio, MultipleChoice, Connect, Order, TextInput, NumberInput } from './types'
import styles from './AddQuestion.module.scss';
import { TextareaAutosize } from "@material-ui/core";
import clsx from "clsx";
import { Link, useHistory, useLocation } from 'react-router-dom';
import draftToHtml from "draftjs-to-html";
import { EditorState, convertToRaw, convertFromHTML, ContentState } from "draft-js";
import cloneDeep from "lodash/cloneDeep";
import { validateQuestionForm } from "./helper";

const actions = {
    setState: 'SET_STATE',
}

const initialState = {
    editorState: EditorState.createEmpty(),
    subjects: [],
    difficulties: [],
    topics: [],
    questionTypes: [],

    description: '',
    subjectId: null,
    difficultyId: null,
    topicId: null,
    questionTypeCode: 'TRUEFALSE',
    fileType: null,
    file: null,
    questionData: null,

    score: '',
    errorMessages: [],
}

const reducer = (state, action) => {
    switch(action.type) {
        case actions.setState: {
            return {
                ...state,
                ...action.payload
            }
        }
        default:
            return state;
    }
}

const QuestionEdit = () => {

    const { t } = useTranslation();
    const dispatchGlobally = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const questionId = location?.state?.questionId ?? null;

    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [ state, dispatch ] = useReducer(reducer, initialState, undefined);

    useEffect(() => {
        const params = {
            school: schoolId,
            questionId
        }
        dispatchGlobally(setLoading(true));
        fetchRequest(questionBankEdit, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data = {} } = res;
                    const {
                        difficulties = [],
                        questionTypes = [],
                        topics = [],
                        subjects = [],
                        question = {}
                    } = data;

                    const questionHTML = question?.question ?? null;
                    const description = question?.description ?? null;
                    const subjectId = question?.subjectId ?? null;
                    const difficultyId = question?.difficultyId ? question.difficultyId.toString() : null;
                    const topicId = question?.topicId ?? null;
                    const questionTypeCode = question?.questionTypeCode ?? null;
                    const score = question?.score ?? '';
                    const questionData = question?.questionData || null;

                    const blocksFromHTML = convertFromHTML(questionHTML ?? '');

                    const contentState = ContentState.createFromBlockArray(
                        blocksFromHTML.contentBlocks,
                        blocksFromHTML.entityMap
                    );

                    const editorState = EditorState.createWithContent(contentState);

                    dispatch({
                        type: actions.setState,
                        payload: {
                            difficulties: formatSelectData(difficulties),
                            subjects: formatSelectData(subjects),
                            questionTypes: formatSelectData(questionTypes, 'code'),
                            topics: formatSelectData(topics),
                            description,
                            subjectId,
                            difficultyId,
                            topicId,
                            questionTypeCode,
                            score,
                            questionData,
                            editorState
                        }
                    })
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatchGlobally(setLoading(false));
            })
            .catch((e) => {
                dispatchGlobally(setLoading(false));
                message(t('errorMessage.title'))
            });
    }, []);

    const handleEditorChange = editorState => {
        dispatch({
            type: actions.setState,
            payload: {
                editorState
            }
        })
    }

    const handleSelectChange = (key, value) => {
        if (key === 'questionTypeCode') {
            let questionData = null;
            switch(value) {
                case 'TRUEFALSE' :{
                    questionData = 'true';
                    dispatch({
                        type: actions.setState,
                        payload: {
                            [key]: value,
                            questionData
                        }
                    })
                    break;
                }
                case 'RADIO' :{
                    questionData = [
                        {
                            isCorrect: true,
                            answer: '',
                            file: null,
                        }
                    ]
                    dispatch({
                        type: actions.setState,
                        payload: {
                            [key]: value,
                            questionData
                        }
                    })
                    break;
                }
                case 'MULTICHOICE' :{
                    questionData = [
                        {
                            isCorrect: true,
                            answer: '',
                            score: '',
                            file: null,
                        }
                    ]
                    dispatch({
                        type: actions.setState,
                        payload: {
                            [key]: value,
                            questionData
                        }
                    })
                    break;
                }
                case 'CONNECT' :{
                    questionData = [
                        {
                            premise: '',
                            response: '',
                            score: ''
                        }
                    ]
                    dispatch({
                        type: actions.setState,
                        payload: {
                            [key]: value,
                            questionData
                        }
                    })
                    break;
                }
                case 'ORDER' :{
                    questionData = [
                        {
                            answer: '',
                            score: ''
                        }
                    ]
                    dispatch({
                        type: actions.setState,
                        payload: {
                            [key]: value,
                            questionData
                        }
                    })
                    break;
                }
                case 'TEXTINPUT' :{
                    questionData = '';
                    dispatch({
                        type: actions.setState,
                        payload: {
                            [key]: value,
                            questionData
                        }
                    })
                    break;
                }
                case 'NUMBERINPUT' :{
                    questionData = {
                        answer: '',
                        answer2: ''
                    };
                    dispatch({
                        type: actions.setState,
                        payload: {
                            [key]: value,
                            questionData
                        }
                    })
                    break;
                }
                default: {
                    dispatch({
                        type: actions.setState,
                        payload: {
                            [key]: value,
                        }
                    })
                }
            }
        } else {
            dispatch({
                type: actions.setState,
                payload: {
                    [key]: value,
                }
            })
        }
    }

    const handleFileUpload = event => {
        if (event.target.files.length) {
            dispatch({
                type: actions.setState,
                payload: {
                    file: event.target.files[0],
                }
            })
        }

        event.target.value = null;
    }

    const fileUploadLabelClick = fileType => {
        dispatch({
            type: actions.setState,
            payload: {
                fileType
            }
        })
    }

    const handleQuestionDataChange = questionData => {
        dispatch({
            type: actions.setState,
            payload: {
                questionData
            }
        })
    }

    const getQuestionTypeInput = () => {
        switch (state.questionTypeCode) {
            case 'TRUEFALSE': {
                return <TrueOrFalse onChange={handleQuestionDataChange} value={state?.questionData}/>
            }
            case 'RADIO': {
                return <Radio onChange={handleQuestionDataChange} value={state.questionData}/>
            }
            case 'MULTICHOICE': {
                return <MultipleChoice onChange={handleQuestionDataChange} value={state?.questionData}/>
            }
            case 'CONNECT': {
                return <Connect onChange={handleQuestionDataChange} value={state?.questionData}/>
            }
            case 'ORDER': {
                return <Order onChange={handleQuestionDataChange} value={state?.questionData}/>
            }
            case 'TEXTINPUT': {
                return <TextInput onChange={handleQuestionDataChange} value={state?.questionData}/>
            }
            case 'NUMBERINPUT': {
                return <NumberInput onChange={handleQuestionDataChange} value={state?.questionData}/>
            }
            default: {
                return null;
            }
        }
    }

    const handleSubmit = async () => {

        const errorMessages = validateQuestionForm(state, t);

        if (errorMessages.length) {
            dispatch({
                type: actions.setState,
                payload: {
                    errorMessages
                }
            })
        } else {
            if (['CONNECT', 'MULTICHOICE', 'ORDER'].includes(state.questionTypeCode)) {
                let qScore = 0;
                for (const data of state.questionData) {
                    if(data.score && data.score != ''){
                        qScore += parseFloat(data.score);
                    }
                }
                if(qScore != state.score){
                    message('Даалгаварын оноо тохирохгүй байна');
                    return;
                }
            }
            dispatchGlobally(setLoading(true));
            const formData = new FormData();
            const rawEditorState = convertToRaw(state.editorState.getCurrentContent())
            const html = draftToHtml(rawEditorState);
            const questionData = cloneDeep(state.questionData);
            formData.append('school', schoolId);
            formData.append('questionId', questionId);
            formData.append('question', html);
            formData.append('description', state.description);
            formData.append('file', state.file);
            formData.append('subject', state.subjectId);
            formData.append('difficulty', state.difficultyId);
            formData.append('topic', state.topicId);
            formData.append('questionType', state.questionTypeCode);
            formData.append('score', state.score);
            let fileIndex = 0;
            if (['RADIO', 'MULTICHOICE'].includes(state.questionTypeCode)) {
                for (const data of questionData) {
                    if (data.file) {
                        formData.append('questionDataImages[]', data.file);
                        data.file = null;
                        data.fileIndex = fileIndex;
                        fileIndex++;
                    } else {
                        data.fileIndex = null;
                    }
                }
            }
            formData.append('questionData', JSON.stringify(questionData))
            try {
                const { data, success = false } = await fetchRequest(questionBankEdit, 'POST', formData, true, true);
                if (success) {
                    history.push('/exam/q/list');
                    message(data?.message ?? t('errorMessage.title'), true)
                } else {
                    message(data?.message ?? t('errorMessage.title'));
                }
                dispatchGlobally(setLoading(false));
            } catch (e) {
                dispatchGlobally(setLoading(false));
            }
        }
    }

    const getErrorMessagesByKey = key => {
        return state.errorMessages.filter(message => message.key === key);
    }

    return (
        <div>
            <Card>
                <Card.Header className={styles.header}>ДААЛГАВАР БҮРТГЭХ</Card.Header>
                <Card.Body>
                    <div>
                        <h3 className={styles.h3}>{t('exam.assignment')}</h3>
                        <Editor
                            editorState={state.editorState}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName={clsx('editorClassName', styles.editor, getErrorMessagesByKey('question').length ? styles.formEditorError : '')}
                            onEditorStateChange={handleEditorChange}
                            editorStyle={{ border: "1px solid rgba(0, 0, 0, .2)"}}
                        />
                        {
                            getErrorMessagesByKey('question').map((errorMessage, index) => {
                                return (
                                    <div key={`err-message-${errorMessage.key}-${index}`} className={styles.errorMessage}>
                                        {errorMessage.message}
                                    </div>
                                )
                            })
                        }
                        <table className={styles.table}>
                            <tbody>
                            <tr>
                                <th className={styles.hint}>{t('exam.hint')}</th>
                                <td>
                                    <TextareaAutosize
                                        className={clsx('form-control', styles.textArea)}
                                        rowsMin={4}
                                        value={state.description}
                                        onChange={e => {
                                            dispatch({
                                                type: actions.setState,
                                                payload: {
                                                    description: e.currentTarget.value,
                                                }
                                            })
                                        }}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>{t('exam.uploadFile')}</th>
                                <td>
                                    <div className={styles.fileContainer}>
                                        <div className={clsx('form-control', styles.fileName)}>
                                            {state?.file?.name ?? ''}
                                        </div>
                                        <input
                                            id='fileUpload'
                                            type={'file'}
                                            style={{ display: 'none' }}
                                            onChange={handleFileUpload}
                                        />
                                        <label
                                            className={clsx('btn btn-success mr-2', styles.fileButton)}
                                            htmlFor='fileUpload'
                                            onClick={() => fileUploadLabelClick('image')}
                                        >
                                            <i className='las la-image p-0' style={{ fontSize: 22 }} />
                                        </label>
                                        <label
                                            className={clsx('btn btn-primary mr-2', styles.fileButton)}
                                            htmlFor='fileUpload'
                                            onClick={() => fileUploadLabelClick('video')}
                                        >
                                            <i className='las la-video p-0' style={{ fontSize: 22 }} />
                                        </label>
                                        <label
                                            className={clsx('btn btn-info', styles.fileButton)}
                                            htmlFor='fileUpload'
                                            onClick={() => fileUploadLabelClick('audio')}
                                        >
                                            <i className='las la-microphone p-0' style={{ fontSize: 22 }} />
                                        </label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>{t('subject.title')}*</th>
                                <td>
                                    <Select
                                        className={clsx(styles.formInput, getErrorMessagesByKey('subjectId').length ? styles.formSelectError : '')}
                                        options={state.subjects}
                                        value={state.subjectId}
                                        onChange={value => handleSelectChange('subjectId', value)}
                                        searchable
                                        clearable={false}
                                    />
                                    {
                                        getErrorMessagesByKey('subjectId').map((errorMessage, index) => {
                                            return (
                                                <div key={`err-message-${errorMessage.key}-${index}`} className={styles.errorMessage}>
                                                    {errorMessage.message}
                                                </div>
                                            )
                                        })
                                    }
                                </td>
                            </tr>
                            <tr>
                                <th>{t('exam.difficulty')}*</th>
                                <td>
                                    <Select
                                        className={clsx(styles.formInput, getErrorMessagesByKey('difficultyId').length ? styles.formSelectError : '')}
                                        options={state.difficulties}
                                        value={state.difficultyId}
                                        onChange={value => handleSelectChange('difficultyId', value)}
                                        searchable
                                        clearable={false}
                                    />
                                    {
                                        getErrorMessagesByKey('difficultyId').map((errorMessage, index) => {
                                            return (
                                                <div key={`err-message-${errorMessage.key}-${index}`} className={styles.errorMessage}>
                                                    {errorMessage.message}
                                                </div>
                                            )
                                        })
                                    }
                                </td>
                            </tr>
                            <tr>
                                <th>{t('teacher.topic')}*</th>
                                <td>
                                    <Select
                                        className={clsx(styles.formInput, getErrorMessagesByKey('topicId').length ? styles.formSelectError : '')}
                                        options={state.topics}
                                        value={state.topicId}
                                        onChange={value => handleSelectChange('topicId', value)}
                                        searchable
                                        clearable={false}
                                    />
                                    {
                                        getErrorMessagesByKey('topicId').map((errorMessage, index) => {
                                            return (
                                                <div key={`err-message-${errorMessage.key}-${index}`} className={styles.errorMessage}>
                                                    {errorMessage.message}
                                                </div>
                                            )
                                        })
                                    }
                                </td>
                            </tr>
                            <tr>
                                <th>{t('exam.questionType')}*</th>
                                <td>
                                    <Select
                                        className={clsx(styles.formInput, getErrorMessagesByKey('questionTypeCode').length ? styles.formSelectError : '')}
                                        options={state.questionTypes}
                                        value={state.questionTypeCode}
                                        onChange={value => handleSelectChange('questionTypeCode', value)}
                                        searchable
                                        clearable={false}
                                    />
                                    {
                                        getErrorMessagesByKey('questionTypeCode').map((errorMessage, index) => {
                                            return (
                                                <div key={`err-message-${errorMessage.key}-${index}`} className={styles.errorMessage}>
                                                    {errorMessage.message}
                                                </div>
                                            )
                                        })
                                    }
                                </td>
                            </tr>
                            <tr>
                                <th>{t('exam.questionScore')}</th>
                                <td>
                                    <input
                                        className={clsx('form-control', styles.formInput, getErrorMessagesByKey('score').length ? styles.formError : '')}
                                        value={state.score}
                                        onChange={e => {
                                            dispatch({
                                                type: actions.setState,
                                                payload: {
                                                    score: e.currentTarget.value,
                                                }
                                            })
                                        }}
                                    />
                                    {
                                        getErrorMessagesByKey('score').map((errorMessage, index) => {
                                            return (
                                                <div key={`err-message-${errorMessage.key}-${index}`} className={styles.errorMessage}>
                                                    {errorMessage.message}
                                                </div>
                                            )
                                        })
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td/>
                                <td>
                                    {getQuestionTypeInput()}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </Card.Body>
                <Card.Footer className="text-center">
                    <Link
                        to='/exam/q/list'
                        className='btn btn-link'
                    >
                        {t('common.back')}
                    </Link>
                    <button
                        className='btn btn-success btn-lg'
                        onClick={handleSubmit}
                    >
                        {t('common.save')}
                    </button>
                </Card.Footer>
            </Card>
        </div>
    );
};

export default QuestionEdit;