import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import EnrollSearchComponent from "../components/enrollSearchComponent";
import DTable from "../../../modules/DataTable/DTable";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import EditTable from "./addModal";
import DeleteModal from "../../../modules/DeleteModal";
import CertificateView from "./certificateView";
import { certificateCandidate, certificateCandidateSubmitAll } from "utils/fetchRequest/Urls";
import { fetchRequest } from "utils/fetchRequest";
import { setLoading } from "redux/action";
import message from "app/modules/message";

const EnrollCertificate = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const schoolId = useSelector((state) => state?.selectedSchool?.id || null);

    const [deleteModal, setDeleteModal] = useState(false);
    const [list, setList] = useState([]);
    const [schools, setSchools] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [certificateId, setCertificateId] = useState(1);
    const [candidateId, setCandidateId] = useState(1);

    const [searchParams, setSearchParams] = useState(null);
    const [searchValues, setSearchValues] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);

    const [initLoader, setInitLoader] = useState(true);
    const [updateView, setUpdateView] = useState(false)

    useEffect(() => {
        let params = {
            school: schoolId,
        }

        init(params)
    }, []);

    const init = (params = {}) => {
        try {
            dispatch(setLoading(true));
            fetchRequest(certificateCandidate, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const { data: {
                            candidates = [],
                            totalCount = 0,
                            subSchool = []
                        } = {} } = res || {}

                        const candidateList = candidates || [];
                        for (let c = 0; c < candidateList.length; c++) {
                            candidateList[c].contextMenuKeys = candidateList[c]?.certificateNumber ? ['download'] : ['create']
                        }

                        setList(candidateList)
                        setSchools(subSchool)
                        setTotalCount(totalCount)
                        setInitLoader(false);

                        setUpdateView(!updateView)
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                })
                .catch(() => {
                    message(t('errorMessage.title'))
                })
                .finally(() => dispatch(setLoading(false)))
        } catch (e) {
            message(t('errorMessage.title'))
        }
    }

    const handleAddClick = () => {
        console.log("handleAddClick clicked");
    };

    const handleContextMenuClick = (id, key, row) => {
        if (key === "create") {
            setShowModal(true);
            setCandidateId(row.candidateId);
            setSelectedRow(row);
        } else if (key === 'download') {
            setShowModal(true);
            setCandidateId(row.candidateId);
            setSelectedRow(row);
        }
    };

    const onModalClose = () => {
        setShowModal(false);
        setDeleteModal(false);

        const params = {
            ...searchParams,
            school: schoolId
        };

        init(params);
    };

    const onSubmit = (params) => {

    };

    const onUserInteraction = (object) => {
        if (!initLoader) {
            const params = {
                ...searchParams,
                ...object,
                school: schoolId
            };
            setSearchValues(object);
            init(params);
        }
    };

    const search = (searchParam) => {
        const params = {
            ...searchParam,
            ...searchValues,
            school: schoolId
        };

        setSearchParams(searchParam);
        init(params);
    };

    const columns = [
        {
            dataField: "certificateNumber",
            text: t("enroll.certificateNumber"),
            sort: true,
        },
        {
            dataField: "candidateNo",
            text: t("enroll.id"),
            sort: true,
        },
        {
            dataField: "registrationNumber",
            text: t("enroll.registerNumber"),
            sort: true,
        },
        {
            dataField: "candidateLastName",
            text: t("person.lastName"),
            sort: true,
        },
        {
            dataField: "candidateFirstName",
            text: t("person.firstName"),
            sort: true,
        },
        {
            dataField: "email",
            text: t("enroll.email"),
            sort: true,
        }
    ];

    const contextMenus = [
        {
            key: "create",
            title: 'Эрхийн бичиг үүсгэх',
            icon: <i className="las la-edit dt-cm-item-icon" />,
        },
        {
            key: "download",
            title: 'Эрхийн бичиг татах',
            icon: <i className="las la-download dt-cm-item-icon" />,
        }
    ];

    const handleClick = (page = 1) => {
        let params = {
            ...searchParams,
            school: schoolId,
            page
        };

        dispatch(setLoading(true));
        fetchRequest(certificateCandidateSubmitAll, "POST", params)
            .then((res) => {
                if (res.success) {
                    const { data } = res;

                    if (data?.totalCount > (data?.page * data?.pageSize)) {
                        handleClick(data?.page + 1)
                    } else {
                        const params = {
                            ...searchParams,
                            school: schoolId
                        };
                        dispatch(setLoading(false));
                        init(params);                        
                    }
                } else {
                    message(res?.data?.message || t("errorMessage.title"));
                    dispatch(setLoading(false));
                }                
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t("errorMessage.title"));
            });
    }

    return (
        <>
            <div className="mx-auto w-100">
                <EnrollSearchComponent onSearch={search} schools={schools} />
            </div>

            <div className="mx-auto mt-3">
                <Card>
                    <Card.Body>
                        <button
                            className="btn btn-primary btn-shadow"
                            onClick={() => handleClick(1)}
                        >
                            Бүх тэнцсэн элсэгчид сертификат үүсгэх
                        </button>
                        <DTable
                            columns={columns}
                            data={list}
                            totalDataSize={totalCount}
                            config={{
                                showPagination: true,
                                excelExport: true,
                                excelFileRemote: true,
                                excelFileName: t("enroll.certificate"),
                                excelFileRemoteUrl: `candidate-sms/excel`,
                                params: {
                                    school: schoolId,
                                    ...searchParams,
                                    ...searchValues,
                                },
                            }}
                            remote
                            onInteraction={onUserInteraction}
                            individualContextMenus
                            contextMenus={contextMenus}
                            onContextMenuItemClick={handleContextMenuClick}
                        />
                        {showModal && (
                            <CertificateView
                                onClose={onModalClose}
                                id={candidateId}
                                row={selectedRow}
                                searchParams={searchParams}
                            />
                        )}
                    </Card.Body>
                </Card>
            </div>
        </>
    );
};

export default EnrollCertificate;
