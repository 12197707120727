import store from "../../redux/store";
import qs from 'qs';

let RequestHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
    'charset': 'UTF-8',
};

let RequestHeadersFileD = {
    Accept: 'application/json',
    'Content-Type': 'blob'
};

let RequestHeadersFile = {
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data; ',
    'charset': 'UTF-8',
};

export const ROOT_URL = 'https://darkhanapi.nomch.mn';
// export const ROOT_URL = 'http://localhost:9001';   

export const MAIN_URL = `${ROOT_URL}/api/`;
export const FILE_URL = `${ROOT_URL}/web/uploads/`;
export const ADMIN_URL = 'admin/';

export function fetchRequestFile(url, method, bodyParams) {
    let headerObject = RequestHeadersFileD;

    let requestUrl = MAIN_URL + url;

    const {
        auth: { authToken }
    } = store.getState();

    if (authToken) {
        headerObject['Authorization'] = `Bearer ${authToken}`;
    }
    requestUrl = `${requestUrl}?${qs.stringify(bodyParams)}`;
    let methodObj = method;
    let requestParameters = null;
    requestParameters = {
        headers: headerObject,
        responseType: 'arraybuffer',
        method: methodObj
    }

    return new Promise((resolve, reject) => {
        fetch(requestUrl, requestParameters)
            .then((responseData) => {
                resolve(responseData);
            }).catch((error) => {
            reject(error);
        });
    });
}

export function fetchRequest(url, method, bodyParams, fileUpload = false, formData = false, teams = false) {
    let headerObject = fileUpload ? RequestHeadersFile : RequestHeaders;

    let requestUrl = MAIN_URL + url;

    const {
        auth: { authToken }
    } = store.getState();

    if (authToken) {
        headerObject['Authorization'] = `Bearer ${authToken}`;
    }

    let methodObj = 'GET';
    let isPostRequest = false;

    if (method && method.toUpperCase() === 'POST') {
        methodObj = 'POST';
        isPostRequest = true;
    }

    let bodyObj = '{}';
    
    if (method && method.toUpperCase() === 'GET') {
        if(bodyParams && bodyParams.toString().includes('msToken')){
            requestUrl = requestUrl+"?"+bodyParams;
        }
        else{
            requestUrl = `${requestUrl}?${qs.stringify(bodyParams)}`;
        }
    } else {
        if (bodyParams) {
            if (formData) {
                headerObject = {
                    Authorization: `Bearer ${authToken}`,
                };
                bodyObj = bodyParams;
            } else if(teams){
                bodyObj = bodyParams;
            }
            else{
                bodyObj = qs.stringify(bodyParams);
            }
        }
    }

    let requestParameters = null;
    if (isPostRequest) {
        requestParameters = {
            headers: headerObject,
            method: methodObj,
            body: bodyObj
        }
    } else {
        requestParameters = {
            headers: headerObject,
            method: methodObj
        }
    }

    return new Promise((resolve, reject) => {
        fetch(requestUrl, requestParameters)
            .then((response) => response.json())
            .then((responseData) => {
                resolve(responseData);
            }).catch((error) => {
            reject(error);
        });
    });
}

export async function fetchRequestAdmin(url, method, bodyParams, fileUpload = false) {
    let headerObject = fileUpload ? RequestHeadersFile : RequestHeaders;

    const {
        auth: { authToken }
    } = store.getState();

    if (authToken) {
        headerObject['Authorization'] = `Bearer ${authToken}`;
    }

    let methodObj = 'GET';
    let isPostRequest = false;

    if (method && method.toUpperCase() === 'POST') {
        methodObj = 'POST';
        isPostRequest = true;
    }

    let bodyObj = JSON.stringify({});
    if (bodyParams) {
        bodyObj = bodyParams
    }

    let requestParameters = null;
    if (isPostRequest) {
        requestParameters = {
            headers: headerObject,
            method: methodObj,
            body: bodyObj
        }
    } else {
        requestParameters = {
            headers: headerObject,
            method: methodObj
        }
    }

    return new Promise((resolve, reject) => {
        fetch(MAIN_URL + ADMIN_URL + url, requestParameters)
            .then((response) => response.json())
            .then((responseData) => {
                resolve(responseData);
            }).catch((error) => {
            reject(error);
        });
    });
}

export async function fetchRequestLogin(url, method, bodyParams, fileUpload = false) {
    let headerObject = fileUpload ? RequestHeadersFile : RequestHeaders;
    let methodObj = 'GET';
    let isPostRequest = false;

    if (method && method.toUpperCase() === 'POST') {
        methodObj = 'POST';
        isPostRequest = true;
    }

    let bodyObj = JSON.stringify({});
    if (bodyParams) {
        bodyObj = bodyParams
    }

    //let bodyObj = objToQueryString(bodyParams);
    let requestParameters = null;
    if (isPostRequest) {
        requestParameters = {
            headers: headerObject,
            method: methodObj,
            body: bodyObj            
        }
    } else {
        requestParameters = {
            headers: headerObject,
            method: methodObj,
            body: bodyObj
        }
    }
    return new Promise((resolve, reject) => {
    fetch(MAIN_URL + url, requestParameters)
        .then((response) => response.json())
        .then((responseData) => {
            resolve(responseData);
        }).catch((error) => {
        reject(error);
    });
}); 
}

export async function fetchRequestNoToken(url, method, bodyParams, fileUpload = false) {
    let headerObject = fileUpload ? RequestHeadersFile : RequestHeaders;
    let methodObj = 'GET';
    let isPostRequest = false;

    if (method && method.toUpperCase() === 'POST') {
        methodObj = 'POST';
        isPostRequest = true;
    }

    let bodyObj = JSON.stringify({});
    if (bodyParams) {
        bodyObj = qs.stringify(bodyParams)
    }

    let requestParameters = null;
    if (isPostRequest) {
        requestParameters = {
            headers: headerObject,
            method: methodObj,
            body: bodyObj
        }
    } else {
        requestParameters = {
            headers: headerObject,
            method: methodObj
        }
    }

    return new Promise((resolve, reject) => {
        fetch(MAIN_URL + url, requestParameters)
            .then((response) => response.json())
            .then((responseData) => {
                resolve(responseData);
            }).catch((error) => {
            reject(error);
        });
    });
}

