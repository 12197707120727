import React,{useState,useEffect} from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { Card,Row,Col, Button } from 'react-bootstrap'
import Instruction from '../../../modules/instruction'
import { useTranslation } from 'react-i18next'
import DTable from '../../../modules/DataTable/DTable'
import { studentOnlineExamListInit } from '../../../../utils/fetchRequest/Urls'
import { fetchRequest } from '../../../../utils/fetchRequest'
import message from '../../../modules/message'
import { setLoading } from '../../../../redux/action'
import { Link } from 'react-router-dom'

const StudentOnlineExam = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const school = useSelector(state => state?.selectedSchool?.id || null);

    const [title,setTitle] = useState('')
    const [examList,setExamList] = useState([])
    const [totalCount,setTotalCount] = useState(1)

    const columns = [
        {
            dataField: 'subject',
            text: t('common.subject'),
            sort: true
        },
        {
            dataField: "teacherFirstName",
            text: t('teacher.title'),
            sort: true
        },
        {
            dataField: "examName",
            text: t('onlineExam.testName'),
            sort: true
        },
        {
            dataField: "activeDuration",
            text: t('onlineExam.activeDuration'),
            sort: true
        },
        {
            dataField: "testDuration",
            text: t('onlineExam.testDuration'),
            sort: true
        },
        {
            dataField: "isActive",
            text: '',
            sort: true,
            formatter(cell,row) {
                if(row.code == 'SENT'){
                    return (
                            <Link
                                to={{
                                    pathname: '/student/online-exam/result',
                                    state: { 
                                        examDtl: row.detailId,
                                        exam: row.id
                                    }
                                }}
                                className="btn btn-primary" 
                            >
                                {t('onlineExam.showResult')}
                            </Link>
                        )
                }else{
                    if(new Date(row.endDate.date) > new Date()){
                        return (
                            <Link
                                to={{
                                    pathname: '/student/online-exam/start',
                                    state: {
                                        exam: row.id
                                    }
                                }}
                                className="btn btn-outline-success"
                            >
                                {t('onlineExam.enterTest')}
                            </Link>
                        )
                    }else{
                        return <Button className="btn btn-secondary">{t('onlineExam.inactive')}</Button>
                    }
                }
            },
        },
    ]

    const fetchData = params => {
        const bodyParams = {
            ...params,
            school,
        }
        dispatch(setLoading(true));
        fetchRequest(studentOnlineExamListInit, 'GET', bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res

                    if(data?.title){
                        setTitle(data?.title)
                    }
                    if(data?.examList){
                        setExamList(data?.examList)
                    }
                    if(data?.total){
                        setTotalCount(data.total)
                    }
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const handleTableChange = params =>{
        fetchData(params)
    }

    useEffect(()=>{
        fetchData()
    },[])

    return (
        <Card>
            <Card.Header>
                <Row className='align-items-center'>
                    <Col>
                        <span style={{ color: '#29569f',fontSize:'14px'}}>
                            <b>{title}</b>
                        </span>
                    </Col>
                    <Col className='text-right'>
                        <Instruction/>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <DTable
                    remote
                    config={{}}
                    columns={columns}
                    data={examList}
                    onInteraction={handleTableChange}
                    totalDataSize={totalCount}
                />
            </Card.Body>
        </Card>
    )
}

export default StudentOnlineExam