import React from 'react';
import ReactSelect, { components } from "react-select";

const seasons = [
    {
        value: 1,
        parent: null,
        text: '2020-2021',
    },
    {
        value: 4,
        parent: null,
        text: '2021-2022',
    },
    {
        value: 5,
        parent: 4,
        text: 'Намар',
    },
    {
        value: 6,
        text: 'Өвөл',
        parent: 4,
    },
    {
        value: 7,
        parent: 4,
        text: 'Хавар',
    },
    {
        value: 2,
        parent: 1,
        text: 'Намар',
    },
    {
        value: 3,
        parent: 1,
        text: 'Хавар',
    },
];

// const MenuList = (props) => {
//     return (
//         <components.MenuList {...props}>
//             <div>
//                 {props.children}
//             </div>
//         </components.MenuList>
//     )
// }

const CustomOption = (props) => {
    return (
        <components.Option {...props}>
            {/*<div style={{ borderWidth: 1, borderColor: 'red', borderStyle: 'solid' }}>*/}
            {/*    hehehe*/}
            {/*</div>*/}
        </components.Option>
    )
}

const Group = (props) => {
    return (
        <div style={{ border: '2px solid violet' }}>
            <components.Group {...props}/>
        </div>
    )
}


const SeasonDropdown = () => {
    const getOptionLabel = (option, q, w) => {
        if (!option.parent) {
            return (
                // <div style={{ color: '#000', textAlign: 'center', fontSize: 20 }}>
                    option.text
                // </div>
            )
        }
        return ( option.text
            // <div style={{ display: 'flex' }}>
            //     {/*<div>|</div>*/}
            //     {option.text}
            //     {/*<div style={{ flex: 1, borderLeftStyle: 'solid', borderLeftWidth: 3, borderLeftColor: 'green' }}>{option.text}</div>*/}
            // </div>
        )
    }

    const getOptions = () => {
        const array = [];
        for (const season of seasons) {
            if (!season.parent) {
                const children = seasons.filter(ssn => ssn.parent === season.value);
                array.push({
                    options: [
                        {
                            ...season,
                        },
                        ...children
                    ]
                });
            }
        }
        return array;
    }

    return (
        <div style={{ width: 300 }}>
            SEASON DROPDOWN
            <ReactSelect
                hideSelectedOptions={false}
                isMulti
                styles={{
                    option: (provided, state) => {
                        // return provided
                        if (state.data.parent) {
                            return {
                                ...provided,
                                padding: '4px 6px 4px 25px',
                                textAlign: 'right',
                                borderLeft: '5px solid green',
                                // marginLeft: 15
                                // width: 'min-content',
                            }
                        }
                        return {
                            ...provided,
                            fontSize: 18,
                            textAlign: 'center',
                            color: '#000',
                            borderBottom: '1px solid blue'
                        }
                    }
                }}
                options={getOptions()}
                // options={[
                //     {
                //         label: "Group 1",
                //         options: [
                //             { label: "Group 1, option 1", value: "value_1" },
                //             { label: "Group 1, option 2", value: "value_2" }
                //         ]
                //     },
                //     { label: "A root option", value: "value_3" },
                //     { label: "Another root option", value: "value_4" }
                // ]}
                getOptionLabel={getOptionLabel}
                components={{ Option: CustomOption, Group }}
            />
        </div>
    )
}

export default SeasonDropdown;