import React from "react";
import { Newsfeed } from "./Newsfeed";

export const TeacherHome = () => {
    return (
        <div className='sm-container'>
            <Newsfeed/>
        </div>
    )
};


