import React, {useEffect, useState, useRef} from 'react'
import DTable from '../../../../modules/DataTable/DTable'
import {Card, Modal, Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {setLoading} from "../../../../../redux/action";
import {fetchRequest} from "../../../../../utils/fetchRequest";
import {lmsStudentChangeName, lmsStudentList} from "../../../../../utils/fetchRequest/Urls";
import message from "../../../../modules/message";
import {useDispatch, useSelector} from "react-redux";
import Select from "../../../../modules/Form/Select";
import DatePicker from "../../../../modules/Form/DatePicker";
import Forms from "app/modules/Form/Forms";
import { cyrillicToLatin } from 'utils/utils';

export const OfficerStudentList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    const formRef = useRef();
    const translateFormRef = useRef();

    const [educationId, setEducationId] = useState(null);
    const [educations, setEducations] = useState([]);
    const [curriculumId, setCurriculumId] = useState(null);
    const [curriculums, setCurriculums] = useState([]);
    const [programId, setProgramId] = useState(null);
    const [programs, setPrograms] = useState([]);
    const [typeId, setTypeId] = useState(null);
    const [types, setTypes] = useState([]);
    const [year, setYear] = useState(null);
    const [student, setStudent] = useState(null);
    const [studentId, setStudentId] = useState(null);
    const [students, setStudents] = useState([]);

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [search, setSearch] = useState('');
    const [tableTotalCount, setTableTotalCount] = useState([]);

    const languages = useSelector(state => state?.languages?.languages || []);

    const [changeNameModal, setChangeNameModal] = useState(false);

    useEffect(() => {
        const params = {
            school: schoolId,
            page,
            pageSize,
            search,
            educationLevel: educationId,
            academicProgram: curriculumId,
            program: programId,
            status: typeId,
            startYear: year,
        };

        init(params)
    }, [page, pageSize, search, educationId, curriculumId, programId, typeId, year]);

    const init = (params) => {
        dispatch(setLoading(true));
        fetchRequest(lmsStudentList, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    if(data.educationLevels && data.educationLevels.length > 0){
                        setEducations(data.educationLevels.map(level => ({ value: level.key, text: level.title })));
                    }
                    if(data.academicPrograms && data.academicPrograms.length > 0){
                        setCurriculums(data.academicPrograms.map(academic => ({ value: academic.id, text: academic.name })));
                    }
                    if(data.statuses && data.statuses.length > 0){
                        setTypes(data.statuses.map(status => ({ value: status.id, text: status.name })));
                    }
                    if(data.programs && data.programs.length > 0){
                        setPrograms(data.programs);
                    }

                    let students = data?.students || [];
                    if(students && students.length > 0){
                        for(let i = 0; i < students.length; i++){
                            if(students[i].startDate && students[i].startDate.date){
                                students[i].startDate = students[i].startDate.date.substring(0, 10)
                            }
                        }
                    }
                    setStudents(students);
                    setTableTotalCount(data.totalPage)
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const forms = [
        {
            label: t('common.lastName') + '*',
            value: student ? student['lastName'] : '',
            errorMessage: t('errorMessage.enterProfession'),
            required: true,
            type: 'text',
        },
        {
            label: t('common.name') + '*',
            value: student ? student['firstName'] : '',
            errorMessage: t('errorMessage.enterProfession'),
            required: true,
            type: 'text',
        },
        {
            label: t('common.registerNumber') + '*',
            value: student ? student['registerNumber'] : '',
            errorMessage: t('errorMessage.enterProfession'),
            required: true,
            type: 'text',
        }
    ];

    const getFormTranslations = () => {
        const array = [];
        for (const language of languages) {
            if(language.code != 'mn'){
                array.push({
                    type: 'jsx',
                    value: <div key={`${language.code}-separator`} className="separator separator-dashed my-7"/>
                });
            }
            array.push({
                label: `${t('common.lastName')} - ${language.name} (${language.code})`,
                type: 'text',
                value: '',
                key: 'lastName',
                langCode: language.code,
                hidden: language.code == 'mn' ? true : false,
                inputStyle: language.code == 'tr' ? {fontFamily: 'Urga'} : {}
            });
            array.push({
                label: `${t('common.name')} - ${language.name} (${language.code})`,
                type: 'text',
                value: '',
                key: 'firstName',
                langCode: language.code,
                hidden: language.code == 'mn' ? true : false,
                inputStyle: language.code == 'tr' ? {fontFamily: 'Urga'} : {}
            });
            array.push({
                label: `${t('common.registerNumber')} - ${language.name} (${language.code})`,
                type: 'text',
                value: '',
                key: 'registerNumber',
                langCode: language.code,
                hidden: language.code == 'mn' ? true : false,
                inputStyle: language.code == 'tr' ? {fontFamily: 'Urga'} : {}
            });
        }
        return array;
    };

    const [translationFields, setTranslationFields] = useState(getFormTranslations);
    

    useEffect(() => {
        formRef?.current?.updateFields && formRef.current.updateFields(forms);
    }, [student]);

    useEffect(() => {
        translateFormRef?.current?.updateFields && translateFormRef.current.updateFields(translationFields);
    }, [translationFields]);

    const config = {
        showPagination: true,
        showFilter: true,
        tableMarginLess: true
    };

    const columns = [
        {
            dataField: 'schoolName',
            text: t('school.title'),
            sort: true,
        },
        {
            dataField: 'academicProgramName',
            text: t('curriculum.codeAndName'),
            sort: true,
        },
        {
            dataField: 'programName',
            text: t('curriculum.programCodeAndName'),
            sort: true,
        },
        {
            dataField: 'startDate',
            text: t('student.enrollmentSeason'),
            sort: true,
        },
        {
            dataField: 'code',
            text: t('student.code'),
            sort: true,
        },
        {
            dataField: 'lastName',
            text: t('person.lastName'),
            sort: true,
        },
        {
            dataField: 'firstName',
            text: t('person.firstName'),
            sort: true,
        },
    ];

    const contextMenus = [
        {
            key: 'anket',
            title: t('questionnaire.view'),
            icon: <i className='flaticon-user dt-cm-item-icon'/>
        },
        {
            key: 'editName',
            title: t('common.edit'),
            icon: <i className='las la-edit dt-cm-item-icon'/>
        },
    ];

    const handleContextMenuClick = (id, key) => {
        if (id && key) {
            if (key === 'anket') {
                const url = `/officer/student/anket?student=` + id  + '&url=/officer/student/list';
                window.open(url);
            } else if(key === 'editName'){

                let params = {
                    school: schoolId,
                    student: id,
                };
    
                dispatch(setLoading(true));
                fetchRequest(lmsStudentChangeName, 'GET', params, false)
                    .then(response => {
                        if (response.success) {
                            let studentData = response.data?.studentData || null;
    
                            let translationFieldList = getFormTranslations();
                            if(studentData){
                                let translations = studentData.translations;

                                if (translationFieldList && translationFieldList.length > 0 && translations && translations.length > 0) {
                                    for (let i = 0; i < translationFieldList.length; i++)
                                    {
                                        for (let t = 0; t < translations.length; t++)
                                        {
                                            if (translationFieldList[i]['key'] == 'lastName' && translationFieldList[i]['langCode'] === translations[t]['langCode'])
                                            {
                                                if(translationFieldList[i]['langCode'] == 'en'){
                                                    if(translations[t]['lastName']){
                                                        translationFieldList[i]['value'] = translations[t]['lastName'];
                                                    } else {
                                                        if(translationFieldList[i]['key'] == 'lastName'){
                                                            translationFieldList[i]['value'] = cyrillicToLatin(studentData.lastName); 
                                                        }
                                                    }
                                                } else {
                                                    translationFieldList[i]['value'] = translations[t]['lastName'];
                                                }
                                            }
                                            if (translationFieldList[i]['key'] == 'firstName' && translationFieldList[i]['langCode'] === translations[t]['langCode'])
                                            {
                                                if(translationFieldList[i]['langCode'] == 'en'){
                                                    if(translations[t]['firstName']){
                                                        translationFieldList[i]['value'] = translations[t]['firstName'];
                                                    } else {
                                                        if(translationFieldList[i]['key'] == 'firstName'){
                                                            translationFieldList[i]['value'] = cyrillicToLatin(studentData.firstName); 
                                                        }
                                                    }
                                                } else {
                                                    translationFieldList[i]['value'] = translations[t]['firstName'];
                                                }
                                            }
                                            if (translationFieldList[i]['key'] == 'registerNumber' && translationFieldList[i]['langCode'] === translations[t]['langCode'])
                                            {
                                                if(translationFieldList[i]['langCode'] == 'en'){
                                                    if(translations[t]['firstName']){
                                                        translationFieldList[i]['value'] = translations[t]['registerNumber'];
                                                    } else {
                                                        if(translationFieldList[i]['key'] == 'registerNumber'){
                                                            translationFieldList[i]['value'] = cyrillicToLatin(studentData.registerNumber); 
                                                        }
                                                    }
                                                } else {
                                                    translationFieldList[i]['value'] = translations[t]['registerNumber'];
                                                }
                                            }
                                        }
                                    }
                                } else {
                                    for(let i = 0; i < translationFieldList.length; i++){
                                        if(translationFieldList[i].langCode == 'en'){
                                            if(studentData.lastName){
                                                if(translationFieldList[i]['key'] == 'lastName'){
                                                    translationFieldList[i]['value'] = cyrillicToLatin(studentData.lastName);
                                                }
                                                if(translationFieldList[i]['key'] == 'firstName'){
                                                    translationFieldList[i]['value'] = cyrillicToLatin(studentData.firstName);
                                                }
                                                if(translationFieldList[i]['key'] == 'registerNumber'){
                                                    translationFieldList[i]['value'] = cyrillicToLatin(studentData.registerNumber);
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            setTranslationFields(translationFieldList)
                            setChangeNameModal(true)
                            setStudentId(id)
                            setStudent(studentData);
                        } else {
                            message(response.data.message, false);
                        }
                        dispatch(setLoading(false));
                    })
                    .catch((e) => {
                        console.log('e', e)
                        dispatch(setLoading(false));
                    })
            }   
        }
    };

    const onEducationSelect = (id) => {
        setEducationId(id)
    };

    const onCurriculumSelect = (id) => {
        setCurriculumId(id);

        const params = {
            school: schoolId,
        };

        init(params)
    };

    const onProgramSelect = (id) => {
        setProgramId(id)
    };

    const onTypeSelect = (id) => {
        setTypeId(id)
    };

    const handleDateChange = (id) => {
        setYear(id)
    };

    const handlerOnClearClick = () => {
        setEducationId(null);
        setCurriculumId(null);
        setProgramId(null);
        setTypeId(null);
        setYear(null);
    };

    const handlerOnSearchClick = () => {
        dispatch(setLoading(true));

        const params = {
            school: schoolId,
        };

        if(educationId){
            params.educationLevel = educationId
        }
        if(curriculumId){
            params.academicProgram = curriculumId
        }
        if(programId){
            params.program = programId
        }
        if(typeId){
            params.status = typeId
        }
        if(year){
            params.startYear = year
        }

        init(params)
    };

    const handleInteraction = (params) => {
        setPageSize(params.pageSize)
        setPage(params.page)
        setSearch(params.search)
    }

    const handlerOnSaveClick = () => {
        const [formValid, formValue] = formRef.current.validate();
        const [translateValid, translateValues] = translateFormRef.current.validate();

        let params = {
            school: schoolId,
            student: studentId,
            lastName: formValue[0].value,
            firstName: formValue[1].value,
            registerNumber: formValue[2].value
        };

        let translations = [];
        if(languages && languages.length > 0){
            languages.map(language => {
                if(language.code !== 'mn'){
                    const lastNameObject = translateValues.find(val => val.key === 'lastName' && val.langCode === language.code && val.value);
                    const firstNameObject = translateValues.find(val => val.key === 'firstName' && val.langCode === language.code && val.value);
                    const registerObject = translateValues.find(val => val.key === 'registerNumber' && val.langCode === language.code && val.value);

                    translations.push({
                        langCode: language.code,
                        lastName: lastNameObject ? lastNameObject.value : null,
                        firstName: firstNameObject ? firstNameObject.value : null,
                        registerNumber: registerObject ? registerObject.value : null
                    })
                }
            });

            params.translations = JSON.stringify(translations);
        }

        dispatch(setLoading(true));
        fetchRequest(lmsStudentChangeName, 'POST', params, false)
            .then(response => {
                if (response.success) {
                    setChangeNameModal(false)
                    message(response.data.message, true);
                } else {
                    message(response.data.message, false);
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
            })
    }

    const handleModalClose = () => {
        setChangeNameModal(false)
    }

    return (
        <>
            <Card>
                <Card.Body>
                    <div className='row'>
                        <div className='col pr-0'>
                            <label>{t('curriculum.educationLevel')}</label>
                            <Select
                                value={educationId}
                                onChange={onEducationSelect}
                                options={educations}
                                searchable
                            />
                        </div>
                        <div className='col pr-0'>
                            <label>{t('curriculum.codeAndName')}</label>
                            <Select
                                value={curriculumId}
                                onChange={onCurriculumSelect}
                                options={curriculums}
                                searchable
                            />
                        </div>
                        <div className='col pr-0'>
                            <label>{t('curriculum.programCodeAndName')}</label>
                            <Select
                                value={programId}
                                onChange={onProgramSelect}
                                options={programs}
                                searchable
                            />
                        </div>
                        <div className='col pr-0'>
                            <label>{t('common.status')}</label>
                            <Select
                                value={typeId}
                                onChange={onTypeSelect}
                                options={types}
                                searchable
                            />
                        </div>
                        <div className='col pr-0' style={{display: 'grid'}}>
                            <label>{t('student.enrollmentSeason')}</label>
                            <DatePicker
                                value={year}
                                onChange={handleDateChange}
                                showYearPicker
                                isCustomButton={false}
                                dateFormat="yyyy"
                            />
                        </div>
                    </div>
                </Card.Body>
                <Card.Footer style={{padding: '1rem 2rem'}}>
                    <div className='col-12 text-center'>
                        <button className="btn btn-link bolder" onClick={handlerOnClearClick}>{t('common.clear').toUpperCase()}</button>
                        <button className="btn btn-warning m-btn--icon m-btn--icon-only btn-shadow" onClick={handlerOnSearchClick}>
                            <i className="flaticon-search-1" style={{color: '#000'}}></i>
                            <span style={{color: '#000'}}>
                                {t('common.search').toUpperCase()}
                            </span>
                        </button>
                    </div>
                </Card.Footer>
            </Card>
            <Card className='mt-4'>
                <Card.Body>
                    <DTable
                        remote  
                        config={config}
                        columns={columns}
                        data={students}
                        contextMenus={contextMenus}
                        onContextMenuItemClick={handleContextMenuClick}
                        selectMode={'radio'}
                        totalDataSize={tableTotalCount}
                        onInteraction={handleInteraction}
                    />
                </Card.Body>
            </Card>
            <Modal
                show={changeNameModal}
                onHide={handleModalClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t('common.view')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='col-12'>
                        <Forms
                            ref={formRef}
                            fields={forms}
                        />
                    </div>
                    <div className='col-12'>
                        <Forms
                            ref={translateFormRef}
                            fields={translationFields}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='link' onClick={handleModalClose}>{t('common.close')}</Button>
                    <Button variant="success btn-shadow" onClick={() => handlerOnSaveClick('save')}>{t('common.save')}</Button>
                </Modal.Footer>
            </Modal>
        </>

    )
};
