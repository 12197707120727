import React, { useState, useEffect, useRef } from 'react';
import { Card, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import Forms from "../../../modules/Form/Forms";
import Instruction from "../../../modules/instruction";
import { fetchRequest } from "../../../../utils/fetchRequest";
import { hrEdit } from "../../../../utils/fetchRequest/Urls";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../../redux/action";
import message from "../../../modules/message";

const RoleEdit = ({}) => {
    const { t } = useTranslation();
    const forms = useRef();
    const translations = useRef();
    const dispatch = useDispatch();
    const history = useHistory();
    const [ hrId, setHrId ] = useState(history?.location?.state?.id || null);
    const [ roles, setRoles ] = useState([]);

    const languages = useSelector(state => state?.languages?.languages || []).filter(lang => lang.code !== 'mn');
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    const translationFields = languages.map(lang => {
        return {
            label: `${t('common.name')} - ${lang.name} (${lang.code})`,
            value: '',
            langCode: lang.code,
            type: 'text',
        }
    });

    useEffect(() => {
        dispatch(setLoading(true));
        const params = {
            school: schoolId,
            id: hrId,
        };
        fetchRequest(hrEdit, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    const translations = data.position.translations;
                    let sysRoles = [];

                    if (data?.roles?.length) {
                        sysRoles = data.roles.map(role => {
                            return {
                                ...role,
                                value: role.id,
                                text: role.name
                            }
                        });
                        setRoles(sysRoles);
                    }
                    forms?.current?.updateFields && forms.current.updateFields([
                        // {
                        //     label: t('common.code') + '*',
                        //     value: data.position.code || '',
                        //     errorMessage: t('errorMessage.enterCode'),
                        //     required: true,
                        //     type: 'text',
                        // },
                        {
                            label: t('common.name') + '*',
                            value: data.position.name || '',
                            errorMessage: t('errorMessage.enterName'),
                            required: true,
                            type: 'text',
                        },
                        {
                            label: t('system.systemRole') + '*',
                            value: data.position.roleId || null,
                            required: true,
                            type: 'dropdown',
                            options: sysRoles,
                            multiple: false,
                            clearable: true
                        },
                    ]);

                    if (translationFields && translationFields.length > 0 && translations && translations.length > 0) {
                        for (let i = 0; i < translationFields.length; i++) {
                            for (let t = 0; t < translations.length; t++)
                                if (translationFields[i]['langCode'] == translations[t]['langCode']) {
                                    translationFields[i]['value'] = translations[t]['name'];
                                }
                        }
                    }
                } else {
                    message(res?.data?.message);
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }, []);

    // useEffect(() => {
    //     forms?.current?.updateFields && forms.current.updateFields(formFields);
    // }, [roles])

    const formFields = [
        // {
        //     label: t('common.code') + '*',
        //     value: '',
        //     errorMessage: t('errorMessage.enterCode'),
        //     required: true,
        //     type: 'text',
        // },
        {
            label: t('common.name') + '*',
            value: '',
            errorMessage: t('errorMessage.enterName'),
            required: true,
            type: 'text',
        },
        {
            label: t('system.systemRole') + '*',
            value: '',
            required: true,
            type: 'dropdown',
            options: roles,
            multiple: false,
            clearable: true
        },
    ];

    const handleSaveClick = () => {
        const [ formsValid, formValues ] = forms.current.validate();
        const [ translationsValid, translationValues ] = translations.current.validate();
        if (formsValid && translationsValid) {
            const params = {
                school: schoolId,
                id: hrId,
                // code: formValues?.[0]?.value,
                name: formValues?.[0]?.value
            };

            if (formValues?.[1]?.value) {
                params.role = formValues[1].value;
            }

            const translations = translationValues.map(lang => {
                return {
                    langCode: lang.langCode,
                    name: lang.value,
                }
            });
            params.translations = JSON.stringify(translations);

            dispatch(setLoading(true));
            fetchRequest(hrEdit, 'POST', params)
                .then(res => {
                    if (res.success) {
                        history.push('/structure/roles');
                    } else {
                        dispatch(setLoading(false));
                        message(res?.data?.message);
                    }
                })
                .catch(() => {
                    dispatch(setLoading(false));
                })
        }
    };

    return (
        <div className='row'>
            <div className='col-12'>
                <Card>
                    <Card.Body>
                        <div className='col-12 text-right'>
                            <Instruction
                            />
                        </div>
                        <div className='col-12'>
                            <Forms
                                ref={forms}
                                fields={formFields}
                            />
                        </div>
                        <div className="separator separator-dashed my-7"/>
                        <div className='col-12'>
                            <Forms
                                ref={translations}
                                fields={translationFields}
                            />
                        </div>
                    </Card.Body>
                    <Card.Footer>
                        <div className='col-12 text-center'>
                            <Link
                                to='/structure/roles'
                                className="btn btn-link bolder"
                            >
                                {t('common.back')}
                            </Link>
                            <Button
                                variant="success btn-shadow"
                                onClick={handleSaveClick}
                            >
                                {t('common.save')}
                            </Button>
                        </div>
                    </Card.Footer>
                </Card>
            </div>
        </div>
    )
};

export default RoleEdit;