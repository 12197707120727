import { TextareaAutosize } from "@material-ui/core";
import React, { useRef, useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import { sendEmail } from "../../../../utils/fetchRequest/Urls";
import Forms from "../../../modules/Form/Forms";
import message from "../../../modules/message";

const SendEmail = ({ onClose, candidateList = [], onFinish, searchState }) => {
    const { t } = useTranslation();
    const form = useRef();
    const [enrollCount, setEnrollCount] = useState(0);
    const languages = useSelector((state) => state?.languages?.languages || []).filter((lang) => lang.code !== "mn");

    const [inputText, setInputText] = useState("");

    useEffect(() => {
        console.log('candidateList', candidateList)
        if(candidateList && candidateList.length > 0){
            let counter = 0;
            for(let i = 0; i < candidateList.length; i++){
                if(candidateList[i].checkable){
                    counter = counter + 1;
                }
            }

            setEnrollCount(counter)
        }
    }, [candidateList]);

    const inputHandler = (e) => {
        setInputText(e.target.value);
    };

    const fields = [
        {
            label: "Гарчиг",
            type: "text",
            upperCase: true,
            value: "",
            required: true,
            labelWidth: 255,
            inputWidth: 180,
            key: "title",
        },
    ];

    const onSaveClick = () => {
        const [formValid, formValue] = form.current.validate();
        if (formValid) {
            let params = {
                title: formValue?.[0].value,
                content: inputText,
                candidates: JSON.stringify(candidateList),
                ...searchState,
                school: searchState.selectedSchool,
            };

            onFinish(params)
        }
    };

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: "#4a70ae", fontSize: "1.1rem" }}>
                        И-МЭЙЛ ИЛГЭЭХ
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    enrollCount 
                    ? 
                        <div className="d-flex flex-column justify-content-center">
                            <div style={{ marginLeft: "274px" }}>
                                {enrollCount} бүртгүүлэгчид
                            </div>
                            <Forms ref={form} fields={fields} />

                            <div className="mt-4 d-flex justify-content-center ">
                                <label className="ml-20">Агуулга</label>

                                <TextareaAutosize
                                    className="msue-textarea-description ml-5"
                                    label="Агуулга"
                                    style={{ height: "119px", width: "333px" }}
                                    onChange={inputHandler}
                                />
                            </div>
                        </div>
                    : 
                        <div className="text-center">Бүртгүүлэгч сонгоно уу</div>
                }
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className="btn btn-link bolder">
                    {t("common.back")}
                </button>
                {
                    enrollCount 
                    ? 
                        <Button variant="success btn-shadow" onClick={onSaveClick}>
                            {t("common.send")}
                        </Button>
                    : 
                        <></>
                }
            </Modal.Footer>
        </Modal>
    );
};

export default SendEmail;
