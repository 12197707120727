import React, {useRef, useEffect, useState} from 'react';
import {Button, Card} from "react-bootstrap";
import Forms from "../../../../modules/Form/Forms";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {teacherFileInit, teacherFileAdd} from "../../../../../utils/fetchRequest/Urls";
import { setLoading } from "../../../../../redux/action";
import { fetchRequest } from "../../../../../utils/fetchRequest";
import message from "../../../../modules/message";
import {Link, useHistory} from "react-router-dom";


export const TeacherOnlineFileAdd = ({}) => {
    

    const { t } = useTranslation();
    const formRef = useRef();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [subjects, setSubjects] = useState([]);
    const [types, setTypes] = useState([]);
    const [file, setFile] = useState([]);
    const params = new URLSearchParams(window.location.pathname);
    const history = useHistory();

    useEffect(() => {

        const params = {
            school: schoolId
        };
        dispatch(setLoading(true));
        fetchRequest(teacherFileInit, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;

                    if (data?.subjects?.length) {
                        setSubjects(data.subjects.map((subject) => ({value: subject.id, text: subject.name + '- (' + subject.code})));
                    }
                    if(data?.types?.length){
                        setTypes(data.types.map((type) => ({value: type.id, text: type.name})));
                    }
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            });
    }, []);

    useEffect(() => {
        formRef?.current?.updateFields && formRef.current?.updateFields(fields);
    }, [subjects, types]);

    const onSubmit = (params) => {
    };

    const onFileChange = (file, evt, action) => {
        if(action == 'clear'){
            setFile([])
        } else {
            setFile(file[0])
        }
    };

    const fields = [
        {
            key: 'file',
            label: t('file.title'),
            value: undefined,
            type: 'fileUpload',
            clearable: true,
            searchable: true,
            isExtendedButton: true,
            isExtendedButtonText: t('newsfeed.chooseFile'),
            isExtendedButtonClass: 'btn btn-outline-success',
            labelWidth: 250,
            onChange: onFileChange
        },
        {
            key: 'type',
            label: t('file.type'),
            value: null,
            type: 'dropdown',
            options: types,
            required: true,
            clearable: true,
            searchable: true,
            labelWidth: 250,
        },
        {
            key: 'subject',
            label: t('subject.code'),
            value: null,
            type: 'dropdown',
            options: subjects,
            required: true,
            clearable: true,
            searchable: true,
            labelWidth: 250,
        }
        
    ];

    const handlerOnSaveClick = () => {
        const [ isValid, states, values ] = formRef.current.validate();
        onSubmit(params);
        if (isValid) {
            if(file.length == 0){
                message('Файл оруулна уу', false);
                return;
            }
            const formData = new FormData();
            formData.append('type', states?.[1]?.value);
            formData.append('subject', states?.[2]?.value);
            formData.append('school', schoolId);
            formData.append('file', file);
            
            dispatch(setLoading(true));
            fetchRequest(teacherFileAdd, 'POST', formData, false, true)
                .then(res => {
                    if (res.success) {
                        history.push('/teacher/file');
                    } else {
                        message(res?.data?.message);
                        dispatch(setLoading(false));
                    }
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        }
        
    };

    return (
        <div className='sm-container'>
            <Card>
                <Card.Header>
                    <span className='french-blue fs-10'>
                        <b>{t('file.add').toUpperCase()}</b>
                    </span>
                </Card.Header>
                <Card.Body>
                    <div className='col-12'>
                        <Forms
                            ref={formRef}
                            fields={fields}
                        />
                    </div>
                </Card.Body>
                <Card.Footer>
                    <div className='col-12 text-center'>
                        <Link
                            to='/teacher/file'
                            className="btn btn-link bolder"
                        >
                            {t('common.back')}
                        </Link>
                        <Button variant="success btn-shadow" onClick={handlerOnSaveClick}>{t('common.save')}</Button>
                    </div>
                </Card.Footer>
            </Card>
        </div>
    );
};