import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from "react-i18next";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { fetchRequest } from "../../../utils/fetchRequest";
import { setLoading } from "../../../redux/action";
import message from "../../modules/message";
import { useDispatch, useSelector } from "react-redux";
import { adminNewsfeedInit, adminNewsfeedDelete } from "../../../utils/fetchRequest/Urls";
import DeleteModal from "../../modules/DeleteModal";
import {cloneDeep} from "lodash";
import {linkify} from "../../../utils/utils";
import { ChangePassword } from "../../../_metronic/layout/components/extras/offcanvas/ChangePassword";
import ImageGallery from 'react-image-gallery';

export const Newsfeed = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [newsfeeds, setNewsfeeds] = useState([]);
    const [newsfeedTypes, setNewsfeedTypes] = useState([]);
    const [newsId, setNewsId] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [initLoader, setInitLoader] = useState(true);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [showPasswordModal, setShowPasswordModal] = useState(false);

    const person = useSelector(state => state?.person || []);

    useEffect(() => {
        if(person.firstName && person.lastName && !person.isActive){
            setShowPasswordModal(true);
        }
    }, [person]);

    useEffect(() => {
        if(initLoader && schoolId){
            let params = {
                page: page + 1,
                pageSize: pageSize,
                school: schoolId
            }

            init(params);
        }
    }, [initLoader, schoolId]);

    const init = (params) => {
        dispatch(setLoading(true));
        fetchRequest(adminNewsfeedInit, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    let newsfeedObj = data?.newsfeeds;
                    if (newsfeedObj && newsfeedObj.length > 0) {
                        let array = [];
                        if(newsfeeds && newsfeeds.length > 0){
                            array = cloneDeep(newsfeeds);
                        }

                        for (let i = 0; i < newsfeedObj.length; i++) {
                            if(newsfeedObj[i].body && newsfeedObj[i].body.length > 500){
                                newsfeedObj[i].readMore = true;
                            }
                            array.push(newsfeedObj[i])
                            setInitLoader(false);
                            setPage(data?.page);
                        }
                        setNewsfeeds(array);
                    }
                    
                    setNewsfeedTypes(data?.types.map(type => ({ value: type.id, text: type.name })) || []);
                    setIsAdmin(data.isAdmin);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }

    window.onscroll = () => {
        if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight - 200 && !initLoader) {
            setInitLoader(true);
        }
    }

    const toggleNewsContent = index => {
        const cloneNewsfeed = [...newsfeeds];
        if(cloneNewsfeed && cloneNewsfeed.length > 0){
            cloneNewsfeed[index].readMore = false
            setNewsfeeds(cloneNewsfeed);
        }
    };

    const onModalClose = () => {
        setShowModal(false);
        setNewsId(null);
    };

    const onDelete = () => {
        const bodyParams = {
            newsfeed: newsId
        };
        dispatch(setLoading(true));
        fetchRequest(adminNewsfeedDelete, 'POST', bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    message(res?.data?.message || t('common.success'), true);
                    setNewsfeeds(data?.newsfeeds || []);
                    onModalClose();
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            })
    };

    const handlerDeleteClick = (id) => {
        setShowModal(true);
        setNewsId(id);
    };

    const renderBody = (body, readMore, index) => {
        if(body && body.length > 500){
            if(readMore){
                return (
                    <>
                        <div style={{fontSize: 13}} dangerouslySetInnerHTML={ {__html: linkify(body.substring(0, 500), '#5867dd')} }/>
                        <span className='underline-button read-more-position' onClick={() => toggleNewsContent(index)}>
                            {t('common.readMore')}
                        </span>
                    </>
                )
            } else {
                return <div style={{fontSize: 13}} dangerouslySetInnerHTML={ {__html: linkify(body, '#5867dd')} }/>
            }
        } else {
            return <div style={{fontSize: 13}} dangerouslySetInnerHTML={ {__html: linkify(body, '#5867dd')} }/>
        }
    }

    const renderNews = (news) => {
        return news.map((data, index) =>
            <div className='sm-home-news' key={data.id}>
                <p className='deep-bue fs-11' style={index < 1 ? {marginBottom: '0.3rem'} : {marginBottom: '0.3rem', marginTop: '1rem'}}><b>{data.title}</b></p>
                {
                    data.isPublish
                    ?
                        <div style={{
                            display: 'inline-block',
                            color: 'fff',
                            padding: 5,
                            background: '#299f72',
                            borderRadius: 5,
                            marginBottom: '0.5rem'
                        }}>
                            {t('newsfeed.published')}
                        </div>
                    :
                        <div style={{
                            display: 'inline-block',
                            color: 'fff',
                            padding: 5,
                            background: '#919bc0',
                            borderRadius: 5,
                            marginBottom: '0.5rem'
                        }}>
                            {t('newsfeed.notPublished')}
                        </div>
                }
                {
                    isAdmin &&
                    <>
                        {
                            !data.isPublish &&
                            <Link
                                to={{
                                    pathname: '/newsfeed/edit',
                                    state: {
                                        newsfeedId: data.id
                                    }
                                }}
                            >
                                <button className='btn btn-purple m-btn--icon m-btn--icon-only ml-2'>
                                    <i className='flaticon-edit fs-10'/>
                                </button>
                            </Link>
                        }
                        <button
                            className='btn btn-danger m-btn--icon m-btn--icon-only ml-2'
                            onClick={() => {handlerDeleteClick(data.id)}}
                        >
                            <i className='flaticon2-cross fs-06'/>
                        </button>
                    </>
                }
                <p style={{marginBottom: 0}}>
                    <b>{t('newsfeed.published')}: {data.createdDate && data.createdDate.date && data.createdDate.date.substring(0, 19)}, {data?.firstName}{data.schoolId ? ', ' + data.schoolName : ''}</b>
                </p>
                <div className='content'>
                    {
                        data.isCoverPhoto && 
                        <div className='mb-5'>
                            <img src={data.isCoverPhoto} style={{cursor: 'pointer', display: 'flex', justifyContent: 'center', maxWidth: '100%', height: 'auto'}}/>
                        </div>
                    }
                    {renderBody(data?.body, data?.readMore, index)}
                    {
                        data.files && data.files.length > 0 &&
                        <ImageGallery
                            // ref={i => this._imageGallery = i}
                            items={data.files} 
                            showThumbnails={true}
                            thumbnailPosition={'bottom'}
                        />
                    }
                </div>
            </div>
        )
    };

    const onModalPassClose=()=>{
        setShowPasswordModal(false);
    };

    return (
        <>
            <div className='mb-4'>
                {
                    isAdmin &&
                    <Link
                        to={{
                            pathname: '/newsfeed/add',
                            state: {
                                types: newsfeedTypes
                            }
                        }}
                        className="btn btn-primary btn-shadow ml-1"
                    >
                        {t('newsfeed.add')}
                    </Link>
                }
            </div>
            <Card className='mb-4'>
                <Card.Header>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <span className='french-blue fs-10'>
                            <b>{t('newsfeed.title').toUpperCase()}</b>
                        </span>
                    </div>
                </Card.Header>
                <Card.Body>
                    <div>
                        {
                            newsfeeds && newsfeeds.length > 0
                            ?
                                renderNews(newsfeeds)
                            :
                                null
                        }
                    </div>
                </Card.Body>
            </Card>
            {
                showModal && newsId && (
                    <DeleteModal
                        onClose={onModalClose}
                        onDelete={onDelete}
                        title={t('warning.delete')}>
                        {t('warning.delete_confirmation')}
                        <br />
                        <br />
                        {t('warning.delete_confirmation_description')}
                    </DeleteModal>
                )
            }
            {
                showPasswordModal &&
                (
                    <ChangePassword
                        forcePasswordChange={true}
                        onClose={onModalPassClose}
                    />
                )
            }
        </>
    )
};

