import React, {useEffect, useState, useRef} from 'react'
import {Button, Card, Form, Modal} from 'react-bootstrap'
import Instruction from "../../../modules/instruction";
import DTable from "../../../modules/DataTable/DTable";
import {AddDescription} from "./add";
import {useTranslation} from "react-i18next";
import {setLoading} from "../../../../redux/action";
import {fetchRequest} from "../../../../utils/fetchRequest";
import {studentTranscriptCreate, studentTranscriptInit, studentTranscriptView} from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import {useDispatch, useSelector} from "react-redux";
import QRCode from 'qrcode';
import { useReactToPrint } from 'react-to-print'
import { TranscriptSelf } from '../transcript/transcriptSelf';
import { TranscriptEnSelf } from '../transcript/transcriptEnSelf';
import { TranscriptScore } from '../transcript/transcriptScore';
import { TranscriptEnScore } from '../transcript/transcriptEnScore';

export const StudentDescription = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const printMnRefSelf = useRef();
    const printEnRefSelf = useRef();
    const printMnRefScore = useRef();
    const printEnRefScore = useRef();
    const [addModal, setAddModal] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [types, setTypes] = useState([]);
    const [studentData, setStudentData] = useState([]);
    const [schoolData, setSchoolData] = useState([]);
    const [mainSchoolData, setMainSchoolData] = useState([]);
    const [totalCount, setTotalCount] = useState([]);
    const [selectedType, setSelectedType] = useState(null);
    const [studentInfo, setStudentInfo] = useState(null);
    const [studentScores, setStudentScores] = useState(null);
    const [qrCode, setQrCode] = useState(null);

    const [languageCode, setLanguageCode] = useState('mn');
    const [languageModal, setLanguageModal] = useState(false);
    const languages = [
        {
            id: 1,
            name: 'Монгол',
            code: 'mn'
        },
        {
            id: 2,
            name: 'Англи',
            code: 'en'
        }
    ]

    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    useEffect(() => {
        let params = {
            school: schoolId
        };

        dispatch(setLoading(true));
        fetchRequest(studentTranscriptInit, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    let transcripts = data?.transcripts || [];
                    if(transcripts && transcripts.length > 0){
                        for(let i = 0; i < transcripts.length; i++){
                            if(transcripts[i].createdDate && transcripts[i].createdDate.date){
                                transcripts[i].createdDate = transcripts[i].createdDate.date.substring(0, 19)
                            }
                        }
                    }
                    setTableData(transcripts || []);
                    setTypes(data?.types);
                    setStudentData(data?.studentData);
                    setSchoolData(data?.schoolData);
                    setMainSchoolData(data?.mainSchoolData);
                    setTotalCount(data.totalCount);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            });
    }, []);

    const config = {
        showPagination: false,
        showFilter: false,
    };

    const columns = [
        {
            dataField: 'name',
            text: t('description.type'),
            sort: true,
        },
        {
            dataField: 'createdDate',
            text: t('description.date'),
            sort: true,
        },
        {
            dataField: 'validDate',
            text: t('description.expiredDate'),
            sort: true,
        },
        {
            dataField: 'toWhom',
            text: t('description.to'),
            sort: true,
        },
        {
            dataField: 'sent',
            text: '',
            headerStyle: () => {
                return {
                    width: 180
                }
            },
            style: {padding: 3, verticalAlign: 'middle'},
            formatter(cell, row, index) {
                return (
                    <Button onClick={() => {viewClick(cell, row, index)}} className="btn btn-outline-success">
                        {t('description.view')}
                    </Button>
                )
            },
        },
    ];

    const viewClick = (cell, row, index) => {
        let params = {
            school: schoolId,
            id: row.id
        };

        dispatch(setLoading(true));
        fetchRequest(studentTranscriptView, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;

                    setStudentInfo(data?.studentData || null);
                    setMainSchoolData(data?.mainSchoolData || null);
                    setSchoolData(data?.schoolData || null);
                    setStudentScores(data?.studentScores || null);

                    if (data?.studentData) {
                        QRCode.toDataURL('https://ulms.msue.edu.mn/student/qrcode/check/' + data?.studentData.studentCode, { version: 5 }, function (err, url) {
                            setQrCode(url);
                        })
                    }
                    setSelectedType(data?.type);
                    setLanguageModal(true);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch((e) => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            });
    };

    const onClose = () =>{
        setAddModal(false)
    };

    const onSubmit = (values) => {
        let params = {
            school: schoolId,
            ...values
        };

        dispatch(setLoading(true));
        fetchRequest(studentTranscriptCreate, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    let transcripts = data?.transcripts || [];
                    if(transcripts && transcripts.length > 0){
                        for(let i = 0; i < transcripts.length; i++){
                            if(transcripts[i].createdDate && transcripts[i].createdDate.date){
                                transcripts[i].createdDate = transcripts[i].createdDate.date.substring(0, 19)
                            }
                        }
                    }

                    setAddModal(false);
                    setTableData(transcripts || []);
                    setTotalCount(data.totalCount);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            });
    };

    const languageModalClose = () => {
        setLanguageModal(false);
    }

    const onHandlerRadioChange = (code) => {
        setLanguageCode(code);
    };

    const onModalViewClick = () => {
        setLanguageModal(false);
        if(languageCode == 'mn'){
            if(selectedType == 'SCORE'){
                handleMnPrintScore();
            } else if(selectedType == 'SELF'){
                handleMnPrintSelf();
            }
        } else if(languageCode == 'en') {
            if(selectedType == 'SCORE'){
                handleEnPrintScore();
            } else if(selectedType == 'SELF'){
                handleEnPrintSelf();
            }
        }
        setLanguageCode('mn');
    }

    const handleMnPrintSelf = useReactToPrint({
        suppressErrors: true,
        content: () => printMnRefSelf.current,
        onPrintError: () => { message(t('common.error')) },
        pageStyle: '@page{size: auto!important; margin: 0.5cm 2cm!important}',
        documentTitle: `${t('transcript.studentDescription')}`
    })

    const handleEnPrintSelf = useReactToPrint({
        suppressErrors: true,
        content: () => printEnRefSelf.current,
        onPrintError: () => { message(t('common.error')) },
        pageStyle: '@page{size: auto!important; margin: 0.5cm 2cm!important}',
        documentTitle: `${t('transcript.studentDescription')}`
    })

    const handleMnPrintScore = useReactToPrint({
        suppressErrors: true,
        content: () => printMnRefScore.current,
        onPrintError: () => { message(t('common.error')) },
        pageStyle: '@page{size: auto!important; margin: 0.5cm 1cm!important}',
        documentTitle: `${t('transcript.studentScoreDescription')}`
    })

    const handleEnPrintScore = useReactToPrint({
        suppressErrors: true,
        content: () => printEnRefScore.current,
        onPrintError: () => { message(t('common.error')) },
        pageStyle: '@page{size: auto!important; margin: 0.5cm 1cm!important}',
        documentTitle: `${t('transcript.studentScoreDescription')}`
    })

    return (
        <div className='sm-container'>
            <div className='d-none'><TranscriptSelf ref={printMnRefSelf} studentInfo={studentInfo} mainSchoolData={mainSchoolData} schoolData={schoolData} qrCode={qrCode}/></div>
            <div className='d-none'><TranscriptEnSelf ref={printEnRefSelf} studentInfo={studentInfo} mainSchoolData={mainSchoolData} schoolData={schoolData} qrCode={qrCode}/></div>
            <div className='d-none'><TranscriptScore ref={printMnRefScore} studentInfo={studentInfo} mainSchoolData={mainSchoolData} schoolData={schoolData} studentScores={studentScores} qrCode={qrCode}/></div>
            <div className='d-none'><TranscriptEnScore ref={printEnRefScore} studentInfo={studentInfo} mainSchoolData={mainSchoolData} schoolData={schoolData} studentScores={studentScores} qrCode={qrCode}/></div>
            <Card>
                <Card.Body>
                    <div className='ds-flex justify-content-between'>
                        <button className='btn btn-primary' onClick={() => setAddModal(true)}>{t('student.getDescription')}</button>
                        <Instruction/>
                    </div>
                    <DTable
                        columns={columns}
                        config={config}
                        data={tableData}
                        selectMode={'radio'}
                    />
                    {
                        addModal &&
                        <AddDescription
                            close={onClose}
                            onSubmit={onSubmit}
                            types={types}
                            studentData={studentData}
                            schoolData={schoolData}
                            mainSchoolData={mainSchoolData}
                        />
                    }
                </Card.Body>
            </Card>
            <Modal
                show={languageModal}
                onHide={languageModalClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                            {t('description.title').toUpperCase()}
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{textAlign: 'left'}}>
                    <div className='row'>
                        <div className='col-5'/>
                        <div className='col-7'>
                            {
                                languages &&
                                languages.map((language, index) =>
                                    <Form.Check
                                        defaultChecked={language.code.toLowerCase() == 'mn' ? true : false}
                                        key={'radio_' + index}
                                        className='mr-2'
                                        name="timetableGroup"
                                        label={language.name}
                                        type='radio'
                                        onChange={() => onHandlerRadioChange(language.code)}
                                    />
                                )
                            }    
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn btn-link' onClick={languageModalClose}>{t('common.back')}</button>
                    <button className='btn btn-success' onClick={onModalViewClick}>{t('description.view')}</button>
                </Modal.Footer>
            </Modal>
        </div>
    )
};
