import React, { useRef, useEffect, useState } from "react";
import { Button, Modal, View, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
    candidateMakeStudent
} from "../../../../utils/fetchRequest/Urls";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import message from "../../../modules/message";
import Checkbox from "../../../modules/Form/Checkbox";
import Select from "app/modules/Form/Select";

const MakeStudent = ({ onClose, onSaveClick, data }) => {
    const { t } = useTranslation();
    const [candidateDetail, setCandidateDetail] = useState([]);
    const dispatch = useDispatch();

    const [avatarUrl, setAvatarUrl] = useState(null)
    const [eyeshCode, setEyeshCode] = useState(null)
    const [gender, setGender] = useState(null)

    const [loaded, setLoaded] = useState(false)

    const [school, setSchool] = useState('')
    const [academicProgram, setAcademicProgram] = useState('')
    const [selectedClass, setSelectedClass] = useState('')

    const [checkScholarShip, setCheckScholarShip] = useState(true)
    const [scholarship, setScholarShip] = useState(false)
    const [eyeshValues, setEyeshValues] = useState([])
    const [classId, setClassId] = useState(null)
    const [classList, setClassList] = useState([])
    const [avgScore, setAvgScore] = useState(null)

    const [updateView, setUpdateView] = useState(false)

    useEffect(() => {
        setLoaded(false)
        classData();
    }, [data]);

    const classData = () => {
        let params = {
            candidate: data?.candidateNo,
            program: data?.programId
        }

        dispatch(setLoading(true));
        fetchRequest(candidateMakeStudent, "GET", params)
            .then((res) => {
                if (res.success) {
                    const { data } = res;

                    setLoaded(true)

                    setAvatarUrl(data?.avatar)
                    setEyeshCode(data?.eyeshNumber)
                    setGender(data?.gender)

                    setSchool(data?.school || '')
                    setAcademicProgram(data?.academicProgram || '')
                    setSelectedClass(data?.class)

                    setScholarShip(data?.scholarship)
                    setEyeshValues(data?.eyeshValueList)
                    setAvgScore(data?.avgScore)
                    setClassList(data?.classList || [])

                } else {
                    message(res?.data?.message || t("errorMessage.title"));
                }
            })
            .catch(() => {
                message(t("errorMessage.title"));
            })
            .finally(() => {
                dispatch(setLoading(false));
            })
    }

    const onClassChange = (value) => {
        setClassId(value)
    }

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: "#4a70ae", fontSize: "1.1rem" }}>Оюутан болгох</div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-4 text-right">
                        {
                            avatarUrl && <img src={'https://darkhanapi.nomch.mn/' + avatarUrl} style={{ width: 100, height: 100 }} />
                        }

                    </div>
                    <div className="col-md-8">
                        <div className="row" style={{ marginBottom: 0 }}>
                            <label className="col-lg-3 col-form-label text-right" style={{ color: '#185BC5', padding: 0 }}>
                                Бүртгэлийн дугаар
                            </label>
                            <div className="col-lg-9 col-xl-4">
                                <b>{data?.id}</b>
                            </div>
                        </div>
                        <div className="row">
                            <label className="col-lg-3 col-form-label text-right" style={{ color: '#185BC5', padding: 0 }}>
                                ЭЕШ бүртгэлийн дугаар
                            </label>
                            <div className="col-lg-9 col-xl-4">
                                <b>{eyeshCode}</b>
                            </div>
                        </div>
                        <div className="row" style={{ marginBottom: 0, marginTop: 20 }}>
                            <label className="col-lg-3 col-form-label text-right" style={{ color: '#185BC5', padding: 0 }}>
                                Овог
                            </label>
                            <div className="col-lg-9 col-xl-4">
                                <b>{data?.lastName}</b>
                            </div>
                        </div>
                        <div className="row" style={{ marginBottom: 0 }}>
                            <label className="col-lg-3 col-form-label text-right" style={{ color: '#185BC5', padding: 0 }}>
                                Нэр
                            </label>
                            <div className="col-lg-9 col-xl-4">
                                <b>{data?.firstName}</b>
                            </div>
                        </div>
                        <div className="row" style={{ marginBottom: 0 }}>
                            <label className="col-lg-3 col-form-label text-right" style={{ color: '#185BC5', padding: 0 }}>
                                Регистрийн дугаар
                            </label>
                            <div className="col-lg-9 col-xl-4">
                                <b>{data?.registrationNumber}</b>
                            </div>
                        </div>
                        <div className="row" style={{ marginBottom: 0 }}>
                            <label className="col-lg-3 col-form-label text-right" style={{ color: '#185BC5', padding: 0 }}>
                                Хүйс
                            </label>
                            <div className="col-lg-9 col-xl-4">
                                <b>{data?.gender === 'F' ? 'Эмэгтэй' : (data?.gender === 'M' ? 'Эрэгтэй' : '')}</b>
                            </div>
                        </div>
                        <div className="row" style={{ marginBottom: 0 }}>
                            <label className="col-lg-3 col-form-label text-right" style={{ color: '#185BC5', padding: 0 }}>
                                И-мэйл
                            </label>
                            <div className="col-lg-9 col-xl-4">
                                <b>{data?.userEmail}</b>
                            </div>
                        </div>
                        <div className="row" style={{ marginBottom: 0 }}>
                            <label className="col-lg-3 col-form-label text-right" style={{ color: '#185BC5', padding: 0 }}>
                                Утасны дугаар
                            </label>
                            <div className="col-lg-9 col-xl-4">
                                <b>{data?.phoneNumber}</b>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="row" style={{ marginTop: 10 }}>
                    <label className="col-md-5 col-form-label text-right">
                        Салбар сургууль
                    </label>
                    <div className="col-md-4">
                        <input
                            className="form-control"
                            disabled
                            value={school}
                            onChange={setSchool}
                        />
                    </div>
                </div>
                <div className="row" style={{ marginTop: 10 }}>
                    <label className="col-md-5 col-form-label text-right">
                        Мэргэжил
                    </label>
                    <div className="col-md-4">
                        <input
                            className="form-control"
                            disabled
                            value={academicProgram}
                            onChange={setAcademicProgram}
                        />
                    </div>
                </div>

                {
                    loaded
                    &&
                    <>
                        {
                            selectedClass
                                ? <>
                                    <div className="row" style={{ marginTop: 10 }}>
                                        <label className="col-md-5 col-form-label text-right">
                                            Анги
                                        </label>
                                        <div className="col-md-4">
                                            {/* <input
                                                className="form-control"
                                                disabled
                                                value={selectedClass}
                                                onChange={setSelectedClass}
                                            /> */}
                                            <Select
                                                className={'default-input-border'}
                                                value={classId}
                                                searchable
                                                options={classList}
                                                onChange={(value) => onClassChange(value)}
                                            />
                                        </div>
                                    </div>
                                    {/* {
                                        scholarship && <>
                                            <div className="row" style={{ marginTop: 10, marginBottom: 10 }}>
                                                <label className="col-md-5 col-form-label text-right">
                                                </label>
                                                <div className="col-md-4">
                                                    <Checkbox
                                                        className='custom-cbox'
                                                        checked={checkScholarShip}
                                                        onChange={(e) => {
                                                            setCheckScholarShip(!e)
                                                            setUpdateView(!updateView)
                                                        }}
                                                        label={`${'Элсэгчийн ЭЕШ-н оноо 60% хүрч байна. Та тэтгэлэг бүртгэх үү?'}`}
                                                    />
                                                </div>
                                            </div>
                                            {
                                                eyeshValues.map((eyeshObj, e) => {
                                                    return <div className="row" key={'e_' + e}>
                                                        <label className="col-md-5 col-form-label text-right">
                                                        </label>
                                                        <div className="col-md-4">
                                                            <p>{`${eyeshObj?.isPrimary ? `Үндсэн` : 'Хавсарга'}: ${eyeshObj?.subject} - ${eyeshObj?.score}`}</p>
                                                        </div>
                                                    </div>
                                                })
                                            }
                                            {
                                                avgScore && <div className="row" style={{ marginTop: 10 }}>
                                                    <label className="col-md-5 col-form-label text-right">
                                                    </label>
                                                    <div className="col-md-4">
                                                        <p>{`Дундаж: ${avgScore}`}</p>
                                                    </div>
                                                </div>
                                            }
                                        </>
                                    } */}
                                </>
                                :
                                <div className="row" style={{ marginTop: 10 }}>
                                    <label className="col-md-5 col-form-label text-right">

                                    </label>
                                    <div className="col-md-4">
                                        <span style={{ color: '#F64E60' }}>Сонгосон хөтөлбөрт анги үүсээгүй байна</span>
                                    </div>
                                </div>
                        }
                    </>
                }

            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className="btn btn-link bolder">
                    {t("common.back")}
                </button>
                {
                    selectedClass && <Button variant="success btn-shadow" onClick={() => onSaveClick({ ...data, ...{ scholarship: 0 }, ...{class: classId} })}>
                        {t("common.save")}
                    </Button>
                }
                {/* {
                    selectedClass && <Button variant="success btn-shadow" onClick={() => onSaveClick({ ...data, ...{ scholarship: checkScholarShip ? 1 : 0 } })}>
                        {t("common.save")}
                    </Button>
                } */}
            </Modal.Footer>
        </Modal>
    );
};

export default MakeStudent;
