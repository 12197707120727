import React, { useState, useEffect, useRef } from 'react';
import { Card, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import Forms from "../../../modules/Form/Forms";
import Instruction from "../../../modules/instruction";
import { fetchRequest } from "../../../../utils/fetchRequest";
import { hrAdd, hrInit } from "../../../../utils/fetchRequest/Urls";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../../redux/action";
import message from "../../../modules/message";

const RoleNew = ({}) => {
    const { t } = useTranslation();
    const forms = useRef();
    const translations = useRef();
    const dispatch = useDispatch();
    const history = useHistory();
    const [ roles, setRoles ] = useState([]);

    const languages = useSelector(state => state?.languages?.languages || []).filter(lang => lang.code !== 'mn');
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    useEffect(() => {
        dispatch(setLoading(true));
        const params = {
            school: schoolId,
        };
        fetchRequest(hrInit, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    if (data?.roles?.length) {
                        const sysRoles = data.roles.map(role => {
                            return {
                                ...role,
                                value: role.id,
                                text: role.name
                            }
                        });
                        setRoles(sysRoles);
                    }
                } else {
                    message(res?.data?.message);
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            })
    }, []);

    useEffect(() => {
        forms?.current?.updateFields && forms.current.updateFields(formFields);
    }, [roles]);

    const formFields = [
        // {
        //     label: t('common.code') + '*',
        //     value: '',
        //     errorMessage: t('errorMessage.enterCode'),
        //     required: true,
        //     type: 'text',
        // },
        {
            label: t('common.name') + '*',
            value: '',
            errorMessage: t('errorMessage.enterName'),
            required: true,
            type: 'text',
        },
        {
            label: t('system.systemRole') + '*',
            value: '',
            required: true,
            type: 'dropdown',
            options: roles,
            multiple: false,
            clearable: true
        },
    ];

    const getFormTranslations = () => {
        return languages.map(lang => {
            return {
                label: `${t('common.name')} - ${lang.name} (${lang.code})`,
                value: '',
                required: false,
                type: 'text',
                langCode: lang.code,
            }
        })
    };

    const handleSaveClick = () => {
        const [ formsValid, formValues ] = forms.current.validate();
        const [ translationsValid, translationValues ] = translations.current.validate();
        if (formsValid && translationsValid) {
            const params = {
                school: schoolId,
                // code: formValues?.[0]?.value,
                name: formValues?.[0]?.value
            };

            if (formValues?.[1]?.value) {
                params.role = formValues[1].value;
            }

            const translations = translationValues.map(lang => {
                return {
                    langCode: lang.langCode,
                    name: lang.value,
                }
            });
            params.translations = JSON.stringify(translations);

            dispatch(setLoading(true));
            fetchRequest(hrAdd, 'POST', params)
                .then(res => {
                    if (res.success) {
                        history.push('/structure/roles');
                    } else {
                        message(res?.data?.message);
                        dispatch(setLoading(false));
                    }
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'));
                })
        }
    };

    return (
        <div className='row'>
            <div className='col-12'>
                <Card>
                    <Card.Body>
                        <div className='col-12 text-right'>
                            <Instruction
                            />
                        </div>
                        <div className='col-12'>
                            <Forms
                                ref={forms}
                                fields={formFields}
                            />
                        </div>
                        <div className="separator separator-dashed my-7"/>
                        <div className='col-12'>
                            <Forms
                                ref={translations}
                                fields={getFormTranslations()}
                            />
                        </div>
                    </Card.Body>
                    <Card.Footer>
                        <div className='col-12 text-center'>
                            <Link
                                to='/structure/roles'
                                className="btn btn-link bolder"
                            >
                                {t('common.back')}
                            </Link>
                            <Button
                                variant="success btn-shadow"
                                onClick={handleSaveClick}
                            >
                                {t('common.save')}
                            </Button>
                        </div>
                    </Card.Footer>
                </Card>
            </div>
        </div>
    )
};

export default RoleNew;