import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import DTable from "../../../modules/DataTable/DTable";
import EnrollSearchComponent from "../components/enrollSearchComponent";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import { emailList } from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";

const EnrollEmailReport = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [data, setData] = useState([]);
    const [searchParams, setSearchParams] = useState(null);
    const [searchValues, setSearchValues] = useState(null);
    const [schools, setSchools] = useState([]);

    useEffect(() => {
        getData({
            school: schoolId
        });
    }, []);

    const getData = (params) => {
        dispatch(setLoading(true));
        fetchRequest(emailList, "GET", params)
            .then((res) => {
                if (res.success) {
                    const { data } = res;
                    setData(data?.email || []);
                    setSchools(data?.schools || []);
                } else {
                    message(res?.data?.message || t("errorMessage.title"));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t("errorMessage.title"));
            });
    };

    const columns = [
        {
            dataField: "createdDate",
            text: t("common.date"),
            sort: true,
        },
        {
            dataField: "phaseName",
            text: t("enroll.phase"),
            sort: true,
        },
        {
            dataField: "typeName",
            text: t("common.type"),
            sort: true,
        },
        {
            dataField: "candidateNo",
            text: t("enroll.id"),
            sort: true,
        },
        {
            dataField: "sender",
            text: t("enroll.sender"),
            sort: true,
        },
        {
            dataField: "email",
            text: t("common.email"),
            sort: true,
        },
        {
            dataField: "body",
            text: t("teacher.content"),
            sort: true,
        },
    ];

    const search = (searchParam) => {
        const params = {
            ...searchParam,
            ...searchValues,
        };

        setSearchParams(searchParam);
        getData(params);
    };

    return (
        <>
            <div className="mx-auto w-100">
                <EnrollSearchComponent
                    onSearch={search}
                    schools={schools}
                />
            </div>
            <div className="mx-auto mt-3">
                <Card>
                    <Card.Body>
                        <DTable
                            columns={columns}
                            data={data}
                            config={{
                                showPagination: true,
                                showFilter: true,
                                excelExport: true,
                                excelFileName: t("enroll.messageReport"),
                            }}
                            showOrdering={true}
                            selectMode={"radio"}
                        />
                    </Card.Body>
                </Card>
            </div>
        </>
    );

};

export default EnrollEmailReport;
