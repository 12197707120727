import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import * as _redux from "./redux";
import store, { persistor } from "./redux/store";
import { BrowserRouter } from "react-router-dom"
import App from "./app/App";
import "./index.scss"; // Standard version
// import "./sass/style.react.rtl.css"; // RTL version
import "./_metronic/_assets/plugins/keenthemes-icons/font/ki.css";
import "./_metronic/_assets/plugins/urga/urga.css";
import "socicon/css/socicon.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./_metronic/_assets/plugins/flaticon/flaticon.css";
import "./_metronic/_assets/plugins/flaticon2/flaticon.css";
// Datepicker
import "react-datepicker/dist/react-datepicker.css";
import {
  MetronicLayoutProvider,
  MetronicSplashScreenProvider,
  MetronicSubheaderProvider
} from "./_metronic/layout";
import { MetronicI18nProvider } from "./_metronic/i18n";

import { MsalProvider } from "@azure/msal-react";
import { Configuration, PublicClientApplication } from "@azure/msal-browser";

import './translations';
// rc-tree css
import "./_metronic/_assets/sass/components/_rc-tree.css";
// toastify css
import "./_metronic/_assets/sass/components/toastify.css";
// custom css
import "./index.css";

import 'line-awesome/dist/line-awesome/css/line-awesome.min.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import 'react-image-gallery/styles/scss/image-gallery.scss';

const { PUBLIC_URL } = process.env;

_redux.setupAxios(axios, store);

const configuration = {
  auth: {
    clientId: "d9d94ca9-68e1-484d-95a8-0ee4a297320f"
  }
};

const pca = new PublicClientApplication(configuration);

ReactDOM.render(
  <MsalProvider instance={pca}>
    <MetronicI18nProvider>
      <MetronicLayoutProvider>
        <MetronicSubheaderProvider>
          <MetronicSplashScreenProvider>
            <BrowserRouter>
              <App store={store} persistor={persistor} basename={PUBLIC_URL} />
            </BrowserRouter>
          </MetronicSplashScreenProvider>
        </MetronicSubheaderProvider>
      </MetronicLayoutProvider>
    </MetronicI18nProvider>
  </MsalProvider>,
  document.getElementById("root")
);
