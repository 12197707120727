import { Card, ProgressBar } from "react-bootstrap";
import Forms from "../../../modules/Form/Forms";
import { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { setLoading } from "../../../../redux/action";
import { useHistory } from "react-router-dom";
import message from "../../../modules/message";
import { fetchRequest } from "../../../../utils/fetchRequest";
import { useDispatch, useSelector } from "react-redux";
import { dashboardList } from "../../../../utils/fetchRequest/Urls";

const EnrollDashboard = () => {
    const { t } = useTranslation();
    const form = useRef();
    const history = useHistory();
    const dispatch = useDispatch();
    const [seasonIndex] = useState('enroll_dashboard_season_index');

    const [list, setList] = useState([]);
    const [seasons, setSeasons] = useState([]);
    const [totalCandidates, setTotalCandidates] = useState(0);
    const [seasonId, setSeasonId] = useState(localStorage.getItem(seasonIndex) || null);
    const schoolId = useSelector((state) => state?.selectedSchool?.id || null);

    const handleClick = (id) => {
        history.push({
            pathname: "/enroll/dashboard/details",
            state: {
                school: schoolId,
                id: id,
            },
        });
    };

    useEffect(() => {
        const params = {
            school: schoolId,
            season: seasonId,
        };

        dispatch(setLoading(true));
        fetchRequest(dashboardList, "GET", params)
            .then((res) => {
                if (res.success) {
                    const { data } = res;

                    setSeasons(data?.seasons || []);
                    setList(data?.list || []);
                    setTotalCandidates(data?.totalCandidate || []);
                } else {
                    message(res?.data?.message || t("errorMessage.title"));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t("errorMessage.title"));
            });
    }, [seasonId]);

    const onSeasonSelect = (id) => {
        setSeasonId(id);

        localStorage.setItem(seasonIndex, id);
    };

    const fields = [
        {
            key: "id",
            value: seasonId ? parseInt(seasonId) : null,
            label: "Хичээлийн жил",
            required: true,
            type: "dropdown",
            multiple: false,
            options: seasons,
            searchable: false,
            clearable: true,
            inputWidth: 200,
            onChange: onSeasonSelect,
        },
    ];

    useEffect(() => {
        form?.current?.updateFields && form.current?.updateFields(fields);
    }, [seasons, seasonId]);
    
    return (
        <div className="p-4">
            <div className="row mb-4">
                <div className="col-12 d-flex">
                    <Forms ref={form} fields={fields} className="bg-transparent"/>
                </div>
            </div>
            <div className="row">
                {list.map((item) => {
                    const isOpen = item.isOpen == 1;
                    const openText = isOpen ? "Нээгдсэн" : "Хаагдсан";
                    const openVariant = isOpen ? "success" : "secondary";
                    
                    let candidateCount = 0;
                    if(totalCandidates > 0){
                        candidateCount = (item.candidateCnt / totalCandidates) * 100;
                    }

                    return (
                        <div key={item.id} className="col-md-4 mb-4">
                            <Card style={{height: '100%'}}>
                                <div className="py-5 px-5">
                                    <button
                                        type="button"
                                        onClick={() => handleClick(item.id)}
                                        className={`btn btn-${openVariant}`}
                                    >
                                        {openText}
                                    </button>
                                    <div className="mt-4">
                                        <p className="text-lg">Үндсэн {item.name}-н элсэлт {'(' + item.educationLevelName + ')'}</p>
                                        <p>
                                            {item.startDate} - {item.endDate}
                                        </p>
                                        <p>
                                            {item.totalProgramNumber ? item.totalProgramNumber : "0"}{" "}
                                            хөтөлбөр | {item.candidateCnt ? item.candidateCnt : "0"}{" "}
                                            элсэгч 
                                        </p>
                                        <ProgressBar now={candidateCount} variant="success" />
                                        <p>
                                            {totalCandidates} Бүртгүүлэгч
                                        </p>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default EnrollDashboard;
