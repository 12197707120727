import "./certificateView.scss";
import ReactToPdf from "react-to-pdf";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import message from "../../../modules/message";
import ReactHtmlParser from "react-html-parser";
import { Button, Modal } from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import { setLoading } from "../../../../redux/action";
import { MAIN_URL, fetchRequest } from "utils/fetchRequest";
import { certificateCandidateSubmit } from "utils/fetchRequest/Urls";
import Select from "app/modules/Form/Select";
import { size } from "lodash";
import Checkbox from "../../../modules/Form/Checkbox";

const CertificateView = ({ onClose, onSubmit, id, searchParams, row }) => {
    const [bgImage, setBgImage] = useState();
    const [editorHeight, setEditorHeight] = useState("300");
    const [phases, setPhases] = useState([]);
    const [phaseId, setPhaseId] = useState(null);
    const [textData, setTextData] = useState(null);
    const [configId, setConfigId] = useState(null);
    const [isCreated, setIsCreated] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [candidateData, setCandidateData] = useState([]);

    const { t } = useTranslation();
    const forms = useRef();
    const dispatch = useDispatch();
    const ref = useRef();
    const options = {
        orientation: "landscape",
        unit: "mm",
        format: [210, 297],
    };

    useEffect(() => {
        if (id) {
            let params = {
                ...searchParams,
                school: searchParams.subSchool,
                candidate: id,
            };

            init(params)
        }
    }, [id]);

    const init = (params) => {
        dispatch(setLoading(true));
        fetchRequest(certificateCandidateSubmit, "GET", params)
            .then((res) => {
                if (res.success) {
                    const { data } = res;
                    setCandidateData(data?.candidateData || [])
                    setPhases(data?.phases || [])
                    setConfigId(data?.config || [])
                } else {
                    message(res?.data?.message || t("errorMessage.title"));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t("errorMessage.title"));
            });
    }

    const onSaveClick = () => {
        if (id) {
            let params = {
                ...searchParams,
                school: searchParams.subSchool,
                candidate: id,
                config: configId,
            };

            dispatch(setLoading(true));
            fetchRequest(certificateCandidateSubmit, "POST", params)
                .then((res) => {
                    if (res.success) {
                        const { data } = res;
                        init(params)
                        onClose()

                        window.open(MAIN_URL + 'pdf/certificate/print?id=' + row?.candidateId + '&program=' + searchParams?.program + '&phase=' + phaseId + '&checked=' + (isChecked ? 1 : 0) , "_blank", "noreferrer");
                    } else {
                        message(res?.data?.message || t("errorMessage.title"));
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t("errorMessage.title"));
                });
        }
    };

    const editorStyle = {
        height: `${editorHeight}px`,
        width: `auto`,
        backgroundImage: `url(${bgImage})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "100%",
    };

    const handleImageLoad = (event) => {
        const imageHeight = event.target.height;
        setEditorHeight(imageHeight / 1.1);
    };

    const onPhaseChange = (value) => {
        setPhaseId(value)

        let phaseData = phases.find(phase => phase.value == value)
        if (phaseData) {
            setTextData(phaseData.textData)
        }

        let params = {
            ...searchParams,
            school: searchParams.subSchool,
            candidate: id,
            config: configId,
            phase: value,
        };

        dispatch(setLoading(true));
        fetchRequest(certificateCandidateSubmit, "GET", params)
            .then((res) => {
                if (res.success) {
                    const { data } = res;
                    setIsCreated(data.isCreated)
                } else {
                    message(res?.data?.message || t("errorMessage.title"));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t("errorMessage.title"));
            });
    }

    const handleCheckboxClick = (value) => {
        setIsChecked(!value)
    }

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: "#4a70ae", fontSize: "1.1rem" }}>
                        ID: {candidateData?.candidateNo || ''}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="d-flex justify-content-end col-3 ml-10">
                        {
                            candidateData &&
                            <img
                                className="d-flex justify-content-end"
                                style={{
                                    objectFit: "contain",
                                    height: "105px",
                                    width: "93px",
                                    margin: "auto",
                                    marginTop: "0.5px",
                                    marginLeft: "10rem",
                                }}
                                src={
                                    candidateData.avatar === null ||
                                        candidateData.avatar === "" ||
                                        candidateData.avatar === undefined ||
                                        candidateData.avatar === "NULL"
                                        ? "/media/users/avatar_male.png"
                                        : "https://darkhanapi.nomch.mn/" + candidateData.avatar
                                }
                            />
                        }
                    </div>
                    <ul className="col-7" style={{ lineHeight: 0.5 }}>
                        <li className="d-flex justify-content-center row">
                            <div className="col">
                                <p
                                    style={{
                                        color: "#185BC5",
                                        fontWeight: "bold",
                                        fontSize: "normal",
                                    }}
                                    className=" d-flex justify-content-end"
                                >
                                    Бүртгэлийн дугаар
                                </p>
                            </div>
                            <div className="col justify-content-end">
                                <span style={{ fontWeight: "bold" }} className="text-dark">
                                    {candidateData?.candidateNo || ''}
                                </span>
                            </div>
                        </li>
                        <li className="d-flex justify-content-center row pb-3">
                            <div className="col">
                                <p
                                    style={{
                                        color: "#185BC5",
                                        fontWeight: "bold",
                                        fontSize: "normal",
                                    }}
                                    className=" d-flex justify-content-end"
                                >
                                    ЭЕШ бүртгэлийн дугаар
                                </p>
                            </div>
                            <div className="col justify-content-end">
                                <span style={{ fontWeight: "bold" }} className="text-dark">
                                    {candidateData?.butName || ''}
                                </span>
                            </div>
                        </li>
                        <li className="d-flex justify-content-center row">
                            <div className="col">
                                <p
                                    style={{
                                        color: "#185BC5",
                                        fontWeight: "bold",
                                    }}
                                    className=" d-flex justify-content-end"
                                >
                                    Овог
                                </p>
                            </div>
                            <div className="col justify-content-end">
                                <span style={{ fontWeight: "bold" }} className="text-dark">
                                    {candidateData?.lastName || ''}
                                </span>
                            </div>
                        </li>
                        <li className="d-flex justify-content-center row">
                            <div className="col">
                                <p
                                    style={{
                                        color: "#185BC5",
                                        fontWeight: "bold",
                                        fontSize: "normal",
                                    }}
                                    className=" d-flex justify-content-end"
                                >
                                    Нэр
                                </p>
                            </div>
                            <div className="col justify-content-end">
                                <span style={{ fontWeight: "bold" }} className="text-dark">
                                    {candidateData?.firstName || ''}
                                </span>
                            </div>
                        </li>
                        <li className="d-flex justify-content-center row">
                            <div className="col">
                                <p
                                    style={{
                                        color: "#185BC5",
                                        fontWeight: "bold",
                                        fontSize: "normal",
                                    }}
                                    className=" d-flex justify-content-end"
                                >
                                    Регистрийн дугаар
                                </p>
                            </div>
                            <div className="col justify-content-end">
                                <span style={{ fontWeight: "bold" }} className="text-dark">
                                    {candidateData?.registerNumber || ''}
                                </span>
                            </div>
                        </li>
                        {
                            candidateData && candidateData.gender &&
                            <li className="d-flex justify-content-center row">
                                <div className="col">
                                    <p
                                        style={{
                                            color: "#185BC5",
                                            fontWeight: "bold",
                                            fontSize: "normal",
                                        }}
                                        className=" d-flex justify-content-end"
                                    >
                                        Хүйс
                                    </p>
                                </div>
                                <div className="col justify-content-end">
                                    <span style={{ fontWeight: "bold" }} className="text-dark">
                                        {candidateData.gender === "M" ? "Эрэгтэй" : "Эмэгтэй"}
                                    </span>
                                </div>
                            </li>
                        }
                        <li className="d-flex justify-content-center row">
                            <div className="col">
                                <p
                                    style={{
                                        color: "#185BC5",
                                        fontWeight: "bold",
                                        fontSize: "normal",
                                    }}
                                    className=" d-flex justify-content-end"
                                >
                                    И-мэйл
                                </p>
                            </div>
                            <div className="col justify-content-end">
                                <span style={{ fontWeight: "bold" }} className="text-dark">
                                    {candidateData?.email || ''}
                                </span>
                            </div>
                        </li>
                        <li className="d-flex justify-content-center row">
                            <div className="col">
                                <p
                                    style={{
                                        color: "#185BC5",
                                        fontWeight: "bold",
                                        fontSize: "normal",
                                    }}
                                    className=" d-flex justify-content-end"
                                >
                                    Утасны дугаар
                                </p>
                            </div>
                            <div className="col justify-content-end">
                                <span style={{ fontWeight: "bold" }} className="text-dark">
                                    {candidateData?.phoneNumber || ''}
                                </span>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className='row mb-4'>
                    <div className='col-12' style={{ display: 'flex', marginTop: 0.8 }}>
                        <span style={{ flex: 4, textAlign: 'right', paddingRight: '1.5rem' }}>
                            <div style={{ width: 300, position: "relative", top: 8 }}>
                                Эрхийн бичгийн загвар
                            </div>
                        </span>

                        <div style={{ flex: 8 }}>
                            <div style={{ width: 350 }}>
                                <Select
                                    className={'default-input-border'}
                                    value={phaseId}
                                    searchable
                                    options={phases}
                                    onChange={(value) => onPhaseChange(value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-12' style={{display: 'flex', marginTop: 0.8}}>
                        <span style={{ flex: 4, textAlign: 'right', paddingRight: '1.5rem' }}>
                            <div style={{ width: 300, position: "relative", top: 8}}>
                                
                            </div>
                        </span>

                        <div style={{ flex: 8 }}>
                            <div style={{ width: 350 }}>
                                <Checkbox
                                    key={'check_'}
                                    className='custom-cbox mt-2'
                                    checked={isChecked}
                                    onChange={(e) => handleCheckboxClick(e)}
                                    label={'Арын фон арилгах'}
                                />
                            </div>
                        </div>
                    </div>
            </Modal.Body>
            <Modal.Footer>
                {
                    row.certId
                        ?
                        <Button onClick={() => {
                            window.open(MAIN_URL + 'pdf/certificate/print?id=' + row?.candidateId + '&program=' + searchParams?.program + '&phase=' + phaseId + '&checked=' + (isChecked ? 1 : 0) , "_blank", "noreferrer");
                        }} variant="success btn-shadow">
                            {t("enroll.downloadPdf")}
                        </Button>
                        // <ReactToPdf
                        //     targetRef={ref}
                        //     filename="certificate.pdf"
                        //     options={options}
                        //     scale={1}
                        // >
                        //     {({ toPdf }) => (
                        //         <Button onClick={toPdf} variant="success btn-shadow">
                        //             {t("enroll.downloadPdf")}
                        //         </Button>
                        //     )}
                        // </ReactToPdf>
                        :
                        <>
                            <button onClick={onClose} className="btn btn-link bolder">
                                {t("common.back")}
                            </button>
                            <button onClick={onSaveClick} className="btn btn-success">
                                {t("common.create")}
                            </button>
                        </>
                }
            </Modal.Footer>
        </Modal >
    );
};

export default CertificateView;

