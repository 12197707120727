import React,{useState,useEffect} from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { setLoading } from '../../../../redux/action';
import { fetchRequest } from '../../../../utils/fetchRequest';
import { useDispatch,useSelector } from 'react-redux';
import Instruction from '../../../modules/instruction';
import { Link,useHistory } from "react-router-dom";
import Select from "../../../modules/Form/Select";
import { TextareaAutosize } from "@material-ui/core";
import { getStudentInfo,movementMissingAdd } from '../../../../utils/fetchRequest/Urls';
import message from '../../../modules/message';
import StudentInfoTable from '../StudentInfoTable';

const AddMissingStudent = ({
    location: { state: {
        movement = null,
        studentCode = null,
        level='',
        nodeId=null
    } = {} } = {}, }) => {
        
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const history = useHistory()
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [ code, setCode ] = useState('');
    const [ student, setStudent ] = useState({});
    const [ types, setTypes ] = useState([]);
    const [ type, setType ] = useState(null);
    const [ description, setDescription ] = useState('');

    const fetchData = params =>{
        const bodyParams={
            ...params,
            nodeId
        }
        dispatch(setLoading(true));
        fetchRequest(getStudentInfo, 'GET', bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res;

                    if (data?.student) {
                        setStudent(data.student);

                        dispatch(setLoading(true));
                        fetchRequest(movementMissingAdd, 'GET', {
                            school: schoolId,
                            student: data.student?.id,
                            movement,
                        })
                            .then(res => {
                                if (res.success) {
                                    const { data } = res;
                                    const tempData = []
                                    if(data?.movementCauses?.length){
                                        data?.movementCauses.map(mc=>{
                                            tempData.push({
                                                value: mc.id,
                                                text: mc.name
                                            })
                                        })
                                    }
                                    setTypes(tempData);
                                    if(data?.movement){
                                        setDescription(data.movement.description)
                                        setType(data.movement.movementCause)
                                    }
                                    dispatch(setLoading(false));
                                } else {
                                    message(res?.data?.message || t('errorMessage.title'))
                                }
                                dispatch(setLoading(false));
                            })
                            .catch(err => {
                                dispatch(setLoading(false));
                                message(t('errorMessage.title'))
                            })
                    }

                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                    dispatch(setLoading(false));
                }
            })
            .catch(err => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }
    
    const handleBlur = () => {
        const params = {
            school: schoolId,
            studentCode: code,
            educationLevel: level
        }
        fetchData(params)
    }

    const onSave = () =>{
        let error = false;
        if(!description){
            error = true;
            message('Тайлбар оруулна уу', false);
        }
        if(!type){
            error = true;
            message('Төрөл сонгож оруулна уу', false);
        }
        if(!error){
            const params = {
                school: schoolId,
                description,
                movementCause: type,
                student: student?.id,
                movement,
                nodeId: level
            }
            dispatch(setLoading(true));
            fetchRequest(movementMissingAdd, 'POST', params)
                .then(res => {
                    if (res.success) {
                        history.push({
                            pathname: '/movement/missing'
                        })
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        }
    }
   
    useEffect(()=>{
        if(movement){
            fetchData({
                school:schoolId,
                studentCode,
                movement,
            })
        }
    },[movement,])

    return (
        <Card>
            <Card.Body>
                <div className='text-right'>
                    <Instruction
                    
                    />
                </div>
                {
                    studentCode
                    ? null
                    :
                    <Row>
                        <div className='col-3' style={{ display: 'flex', alignSelf: 'center', justifyContent: 'flex-end' }}>
                            {t('student.code') + '*'}
                        </div>
                        <div className='col-3'>
                            <input
                                className='form-control'
                                onChange={e=>{
                                    setCode(e.target.value)
                                    setStudent({});
                                    setType(null);
                                }}
                                value={code}
                                onBlur={handleBlur}
                            />
                        </div>
                    </Row>
                }
                <Row className='mt-3'>
                    <Col md={2}>

                    </Col>
                    <Col>
                        <StudentInfoTable student={student}/>
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col md={3} style={{ display: 'flex', alignSelf: 'center', justifyContent: 'flex-end' }}>
                        {t('common.type') + '*'}
                    </Col>
                    <Col md={3}>
                        <Select
                            searchable
                            value={type}
                            onChange={value=>setType(value)}
                            options={types}
                        />
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col md={3} style={{ display: 'flex', alignSelf: 'center', justifyContent: 'flex-end' }}>
                        {t('common.description') + '*'}
                    </Col>
                    <Col md={3}>
                        <TextareaAutosize
                            className='msue-textarea-description'
                            value={description}
                            onChange={e => {
                                setDescription(e.target.value);
                            }}
                            rows={5}
                        />

                    </Col>
                </Row>
            </Card.Body>
            <Card.Footer>
                <div className='text-center'>
                    <Link
                        to={'/movement/missing'}
                        className='btn btn-link bolder'
                    >
                        {t('common.back')}
                    </Link>
                    <button
                        className='btn btn-success'
                        onClick={onSave}
                    >
                        {t('common.save').toLocaleUpperCase()}
                    </button>
                </div>
            </Card.Footer>
        </Card>
    )
}

export default AddMissingStudent