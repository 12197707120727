import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal } from "react-bootstrap";
import Forms from "../../../modules/Form/Forms";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { compareAsc } from "date-fns";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import { seasonEdit } from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";

const SeasonAdd = ({onClose, onSubmit, seasons, types, id, educationLevel}) => {
    const { t } = useTranslation();
    const forms = useRef();
    const dispatch = useDispatch();
    const languages = useSelector(state => state?.languages?.languages || []).filter(lang => lang.code !== 'mn');
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const parentId = useSelector(state => state?.selectedSchool?.parentId || null);
    const [ seasonType, setSeasonType ] = useState(types);
    const [ parentSeasons, setParentSeasons ] = useState(seasons);

    useEffect(() => {
        forms?.current?.updateFields && forms.current.updateFields(fields);
    }, [ types, seasons ]);

    useEffect(() => {
        if (id) {
            const params = {
                school: schoolId,
                educationLevel,
                season: id,
            };
            dispatch(setLoading(true));
            fetchRequest(seasonEdit, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res;
                        if (data?.seasonData) {
                            const seasonData = data?.seasonData;
                            const translations = seasonData.translations;
                            if (seasonData) {
                                if(translationFields && translationFields.length > 0 && translations && translations.length > 0){
                                    for(let i = 0; i < translationFields.length; i++){
                                        for(let t = 0; t < translations.length; t++)
                                            if(translationFields[i]['langCode'] == translations[t]['langCode']){
                                                translationFields[i]['value'] = translations[t]['name'];
                                            }
                                    }
                                }
                            }
                            setSeasonType(data?.seasonTypes);
                            setParentSeasons(data?.parentSeasons);

                            seasonOptions.unshift(
                                {
                                    value: null,
                                    text: '- ' + t('common.select') + ' -'
                                }
                            );

                            forms?.current?.updateFields && forms.current.updateFields([
                                {
                                    label: t('common.code'),
                                    value: seasonData.code,
                                    type: 'nonCryllic',
                                    upperCase: true,
                                    inputWidth: 200,
                                    labelWidth: 300,
                                    required: true,
                                    textLength: 4,
                                    key: 'code',
                                },
                                {
                                    label: t('common.name'),
                                    value: seasonData.name,
                                    type: 'text',
                                    inputWidth: 200,
                                    labelWidth: 300,
                                    required: true,
                                    key: 'name',
                                },
                                {
                                    label: t('common.type'),
                                    value: seasonData.type,
                                    type: 'dropdown',
                                    inputWidth: 200,
                                    labelWidth: 300,
                                    required: true,
                                    options: seasonTypeOptions,
                                    key: 'type',
                                    clearable: true
                                },
                                {
                                    label: t('school.season.parent'),
                                    type: 'dropdown',
                                    value: seasonData.parent,
                                    inputWidth: 200,
                                    labelWidth: 300,
                                    options: seasonOptions,
                                    key: 'parent',
                                    required: false,
                                    clearable: true
                                },
                                {
                                    label: t('school.season.weekStart'),
                                    type: 'date',
                                    value: seasonData.weekStart,
                                    inputWidth: 200,
                                    labelWidth: 300,
                                    key: 'weekStart',
                                    required: true,
                                },
                                {
                                    label: t('common.startDate'),
                                    type: 'date',
                                    value: seasonData.startDate,
                                    inputWidth: 200,
                                    labelWidth: 300,
                                    key: 'start',
                                    required: true,
                                },
                                {
                                    label: t('common.endDate'),
                                    type: 'date',
                                    value: seasonData.endDate,
                                    inputWidth: 200,
                                    labelWidth: 300,
                                    key: 'end',
                                    required: true,
                                    validation: validateEndDate,
                                },
                                {
                                    label: t('school.season.isCurrent'),
                                    type: 'checkbox',
                                    value: seasonData.current,
                                    inputWidth: 200,
                                    labelWidth: 300,
                                    key: 'current',
                                },
                                {
                                    label: t('school.season.previousYear'),
                                    type: 'dropdown',
                                    value: seasonData.prevSeasonId,
                                    inputWidth: 200,
                                    labelWidth: 300,
                                    options: seasonOptions,
                                    key: 'prev',
                                    required: false,
                                    clearable: true
                                },
                                {
                                    type: 'jsx',
                                    value: <div key={'separator'} className="separator separator-dashed my-7"/>,
                                },
                                ...translationFields
                            ]);
                        }
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        }
    }, []);


    const translationFields = languages.map(lang => {
        return {
            label: `${t('common.name')} - ${lang.name} (${lang.code})`,
            value: '',
            langCode: lang.code,
            type: 'text',
            labelWidth: 300,
            inputWidth: 200,
        }
    });

    const seasonTypeOptions = seasonType?.map(type => {
        return {
            text: type.name,
            value: type.id,
        }
    });

    const seasonOptions = parentSeasons?.map(season => {
        return {
            text: season.name,
            value: season.id,
        }
    });

    function validateEndDate(endDate, state, values) {
        const { start } = values;
        if (!endDate || !start) {
            return {
                valid: false
            }
        }
        const greater = compareAsc(new Date(endDate), new Date(start));
        if (greater === 1 || greater === 0) {
            return {
                valid: true,
            }
        }
        return {
            valid: false,
            message: t('school.season.validEndDate')
        }
    }

    const fields = [
        {
            label: t('common.code'),
            value: '',
            type: 'nonCryllic',
            upperCase: true,
            inputWidth: 200,
            labelWidth: 300,
            textLength: 4,
            required: true,
            key: 'code',
        },
        {
            label: t('common.name'),
            value: '',
            type: 'text',
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            key: 'name',
        },
        {
            label: t('common.type'),
            value: null,
            type: 'dropdown',
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            options: seasonTypeOptions,
            key: 'type',
            clearable: true
        },
        {
            label: t('school.season.parent'),
            type: 'dropdown',
            value: null,
            inputWidth: 200,
            labelWidth: 300,
            options: seasonOptions,
            key: 'parent',
            required: false,
            clearable: true
        },
        {
            label: t('school.season.weekStart'),
            type: 'date',
            value: '',
            inputWidth: 200,
            labelWidth: 300,
            key: 'weekStart',
            required: true,
        },
        {
            label: t('common.startDate'),
            type: 'date',
            value: '',
            inputWidth: 200,
            labelWidth: 300,
            key: 'start',
            required: true,
        },
        {
            label: t('common.endDate'),
            type: 'date',
            value: '',
            inputWidth: 200,
            labelWidth: 300,
            key: 'end',
            required: true,
            validation: validateEndDate,
        },
        {
            label: t('school.season.isCurrent'),
            type: 'checkbox',
            value: false,
            inputWidth: 200,
            labelWidth: 300,
            key: 'current',
        },
        {
            label: t('school.isOtherSchool'),
            type: 'checkbox',
            value: !parentId ? true : false,
            inputWidth: 300,
            labelWidth: 300,
            hidden: parentId == null ? false : true,
            key: 'isSchool',
        },
        {
            label: t('school.season.previousYear'),
            type: 'dropdown',
            value: null,
            inputWidth: 200,
            labelWidth: 300,
            options: seasonOptions,
            key: 'prev',
            required: false,
            clearable: true
        },
        {
            type: 'jsx',
            value: <div key={'separator'} className="separator separator-dashed my-7"/>,
        },
        ...translationFields
    ];

    const onSaveClick = () => {
        const [ isValid, states, values ] = forms.current.validate();
        if (isValid) {
            const translations = [];
            for (let i = 0; i < states.length; i++) {
                const val = states[i];
                if (i >= 11) {
                    translations.push({
                        langCode: val.langCode,
                        name: val.value,
                    });
                }
            }
            if(values.current){
                values.current = 1
            } else {
                values.current = 0
            }
            if(values.isSchool){
                values.isSchool = 1
            } else {
                values.isSchool = 0
            }
            const params = {
                ...values,
                translations: JSON.stringify(translations)
            };
            onSubmit(params);
        }
    };

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('school.season.title').toUpperCase()}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Forms
                    ref={forms}
                    fields={fields}
                />
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className='btn btn-link bolder'>{t('common.back')}</button>
                <Button variant='success btn-shadow' onClick={onSaveClick}>{t('common.save')}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SeasonAdd;