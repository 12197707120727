import React from "react";
import styles from './questionTypes.module.scss';
import cloneDeep from 'lodash/cloneDeep'
import clsx from "clsx";
import { useTranslation } from "react-i18next";

const Connect = ({
    onChange,
    value
}) => {

    const { t } = useTranslation();

    const handlePremiseChange = (index, premise) => {
        const clone = cloneDeep(value);
        clone[index].premise = premise;
        onChange(clone);
    }

    const handleResponseChange = (index, response) => {
        const clone = cloneDeep(value);
        clone[index].response = response;
        onChange(clone);
    }

    const handleScoreChange = (index, score) => {
        // const re = /[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)/;
        // const re = /^[+-]?\d+(\.\d+)?$/

        // if (score === '' || re.test(score)) {
        const clone = cloneDeep(value);
        clone[index].score = score;
        onChange(clone);
        // }
    }

    const addRow = () => {
        const clone = cloneDeep(value);
        clone.push({
            premise: '',
            response: '',
            score: '',
        })
        onChange(clone);
    }

    const removeRow = index => {
        const clone = cloneDeep(value);
        clone.splice(index, 1);
        onChange(clone);
    }

    return (
        <div style={{ width: 'min-content' }}>
            {
                value.map((row, index) => {
                    return (
                        <div
                            key={index}
                            className={styles.rowContainer}
                        >
                            <input
                                className={clsx('form-control mr-5', styles.premiseInput)}
                                onChange={(e) => handlePremiseChange(index, e.target.value)}
                                value={row.premise}
                                placeholder={t('exam.premise')}
                            />
                            <input
                                className={clsx('form-control', styles.premiseInput)}
                                onChange={(e) => handleResponseChange(index, e.target.value)}
                                value={row.response}
                                placeholder={t('exam.premiseResponse')}
                            />
                            <input
                                className={clsx('form-control mr-5', styles.scoreInput)}
                                onChange={(e) => handleScoreChange(index, e.target.value)}
                                value={row.score}
                                placeholder={t('exam.score')}
                            />
                            {
                                index > 0
                                    ?   (
                                        <button className='btn btn-danger row-action-button' onClick={() => removeRow(index)}>
                                            <i className='las la-times' style={{ fontSize: 20 }} />
                                        </button>
                                    )
                                    :   <div style={{ width: 36 }}/>
                            }
                        </div>
                    )
                })
            }
            <div className='text-right pr-1'>
                <button className='btn btn-primary row-action-button mr-1' onClick={addRow}>
                    <i className='las la-plus' style={{ fontSize: 20 }} />
                </button>
            </div>
        </div>
    );
};

export default Connect;