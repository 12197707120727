import React, {useEffect, useState} from 'react'
import {Button, Card} from 'react-bootstrap'
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import DTable from "../../../modules/DataTable/DTable";
import {setLoading} from "../../../../redux/action";
import {fetchRequest} from "../../../../utils/fetchRequest";
import {financeDiscountAdd, financeDiscountInit} from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import RegisterGoverment from "./add";
import EditGovernment from "./edit";
import {priceFormat} from "../../../../utils/utils";

export const GovernmentLoan = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    const [showAddModal, setShowAddModal] = useState(false);
    const [isEditModal, setIsEditModal] = useState(false);
    const [students, setStudents] = useState([]);
    const [types, setTypes] = useState([]);

    useEffect(() => {
        const params = {
            school: schoolId,
        };
        init(params)
    }, []);

    const init = (params) => {
        dispatch(setLoading(true));
        fetchRequest(financeDiscountInit, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    let studentsObj = data.students;
                    if(studentsObj && studentsObj.length > 0){
                        for(let i = 0; i < studentsObj.length; i++){
                            if(studentsObj[i].transactionDate && studentsObj[i].transactionDate.date){
                                studentsObj[i].transactionDate = studentsObj[i].transactionDate.date.substring(0, 19)
                                studentsObj[i]['amount'] = priceFormat(studentsObj[i]['amount']);
                            }
                        }
                    }
                    setStudents(studentsObj);
                    setTypes(data?.discountTypes);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const handleAddClick = () => {
        setShowAddModal(true);
    };

    const config = {
        showPagination: true,
        showFilter: true,
        footer: true,
        footerStyle: {backgroundColor: '#ebecf5'}
    };

    const columns = [
        {
            dataField: "transactionDate",
            text: t('finance.transactionDate'),
            footer: '',
        },
        {
            dataField: "studentCode",
            text: t('student.code'),
            sort: true,
            footer: ''
        },
        {
            dataField: "lastName",
            text: t('student.lastName'),
            sort: true,
            footer: '',
        },
        {
            dataField: "firstName",
            text: t('student.name'),
            sort: true,
            footer: '',
        },
        {
            dataField: "type",
            text: t('common.type'),
            sort: true,
            footer: '',
        },
        {
            dataField: "amount",
            text: t('common.amount'),
            style: {textAlign: 'right'},
            sort: true,
            footerAlign: 'right',
            footerType: 'sum',
            footer: '0',
        },
        {
            dataField: "seasonName",
            text: t('school.season.title'),
            sort: true,
            footer: '',
        },
        {
            dataField: "createdDate",
            text: t('common.createdDate'),
            sort: true,
            footer: '',
        },
        {
            dataField: "createdUser",
            text: t('common.createdUser'),
            sort: true,
            footer: '',
        },
    ];

    const contextMenus = [
        {
            key: 'edit',
            title: t('common.edit'),
            icon: <i className='las la-edit dt-cm-item-icon'/>
        },
        {
            key: 'delete',
            title: t('common.delete'),
            icon: <i className='las la-trash-alt dt-cm-item-icon' />,
        },
    ];

    const handleContextMenuClick = (id, key) => {
        if (id && key) {
            if(key === 'edit'){
                setIsEditModal(true)
            } else if (key === 'delete') {
                
            }
        }
    };

    const onModalClose = () => {
        setShowAddModal(false);
    };

    const onEditModalClose = () => {
        setIsEditModal(false);
    };

    const onEditSubmit = params => {
        const bodyParams = {
            school: schoolId,
            ...params,
        };

        dispatch(setLoading(true));
        fetchRequest(financeDiscountAdd, 'POST', bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    message(data?.message || t('common.success'), true);
                    const params = {
                        school: schoolId,
                    };
                    init(params);
                    onModalClose();
                } else {
                    message(res?.data?.message || t('errorMessage.title'));
                    dispatch(setLoading(false));
                }
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const onSubmit = params => {
        const bodyParams = {
            school: schoolId,
            date: params.date,
            students: params.students,
            discount: params.discount
        };

        dispatch(setLoading(true));
        fetchRequest(financeDiscountAdd, 'POST', bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    message(data?.message || t('common.success'), true);
                    const params = {
                        school: schoolId,
                    };
                    init(params);
                    onModalClose();
                } else {
                    message(res?.data?.message || t('errorMessage.title'));
                    dispatch(setLoading(false));
                }
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    return (
        <div className='sm-container'>
            <Card>
                <Card.Body>
                    <div className='row'>
                        <div className='col-12'>
                            <button
                                className='btn btn-primary'
                                onClick={handleAddClick}
                            >
                                {t('common.register')}
                            </button>
                        </div>
                        <div className='col-12'>
                            <DTable
                                columns={columns}
                                config={config}
                                data={students}
                                contextMenus={contextMenus}
                                onContextMenuItemClick={handleContextMenuClick}
                                selectMode={'radio'}
                            />
                        </div>
                    </div>
                </Card.Body>
            </Card>
            {
                showAddModal && (
                    <RegisterGoverment
                        onClose={onModalClose}
                        onSubmit={onSubmit}
                        types={types}
                    />
                )
            }
            {
                isEditModal && (
                    <EditGovernment
                        onClose={onEditModalClose}
                        onSubmit={onEditSubmit}
                    />
                )
            }
        </div>
    )
};
