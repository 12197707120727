import React, {useEffect, useState} from 'react';
import { Card } from "react-bootstrap";
import TreeView from "../../../modules/TreeView";
import DTable from "../../../modules/DataTable/DTable";
import { useTranslation } from "react-i18next";
import AddPenalty from "./AddPenalty";
import {setLoading} from "../../../../redux/action";
import {fetchRequest} from "../../../../utils/fetchRequest";
import {
    selectionPenaltyIndex,
    selectionPenaltyCreate,
} from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import {useDispatch, useSelector} from "react-redux";

const SchoolPenalty = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [ seasons, setSeasons ] = useState([]);
    const [ selectedSeasonId, setSelectedSeasonId ] = useState(null);
    const [ seasonTitle, setSeasonTitle ] = useState(null);
    const [ showModal, setShowModal ] = useState(false);
    const [ students, setStudents ] = useState([]);
    const [ treeIndex ] = useState('selection_penalty_tree_index');

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [search, setSearch] = useState('');
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        let treeKey = localStorage.getItem(treeIndex);
        let params = {
            school: schoolId,
            pageSize,
            page,
            search
        };

        if(treeKey){
            let splitArray = treeKey.split('&');
            if(splitArray && splitArray.length > 0){
                setSelectedSeasonId(splitArray[0]);
                params.season = splitArray[0];
                if(splitArray.length == 2){
                    setSeasonTitle(splitArray[1])
                }
            }
        }

        init(params);
    }, [page, pageSize, search]);

    const init = (params) => {
        dispatch(setLoading(true));
        fetchRequest(selectionPenaltyIndex, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setStudents(data?.penalties || []);
                    setSeasons(data?.seasons || []);
                    setTotalCount(data?.totalPage || 0);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const handleNodeClick = (array, evt) => {
        if (array?.length > 0) {
            const [id] = array;
            if(id.toString().split('_').length === 4){
                setSelectedSeasonId(id);
                setSeasonTitle(evt.node.title);

                let params = {
                    school: schoolId,
                    season: id,
                    pageSize: 10,
                    page: 1,
                };

                init(params);
                localStorage.setItem(treeIndex, id + '&' + evt.node.title);
            }
        }
    };

    const config = {

    };

    const columns = [
        {
            dataField: "createdDate",
            text: t('common.paidDate'),
            sort: true
        },
        {
            dataField: "code",
            text: t('student.code'),
            sort: true
        },
        {
            dataField: "firstName",
            text: t('student.name'),
            sort: true
        },
        {
            dataField: "selectionType",
            text: t('common.paidAmount'),
            sort: true
        },
        {
            dataField: "createdUser",
            text: t('common.registered'),
            sort: true
        },
    ];

    const onModalClose = () => {
        setShowModal(false);
    };

    const onSubmit = params => {
        const bodyParams = {
            ...params,
        };

        dispatch(setLoading(true));
        fetchRequest(selectionPenaltyCreate, 'POST', bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    message(data?.message || t('common.success'), true);
                    onModalClose();
                    let configErrors = data?.errorConfigs;
                    if(configErrors && configErrors.length > 0){
                        for(let i = 0; i < configErrors.length; i++){
                            if(configErrors[i].code == 'EXTEND_ERROR'){
                                message(t('selection.penaltyMessage') + ' ' + configErrors[i].selectionType.toLowerCase() + ' ' + t('selection.penaltyMessageFour'), false);
                            } else if (configErrors[i].code == 'PENALTY_NOTFOUND_OR_EXPIRED_ERROR'){
                                message(t('selection.penaltyMessage') + ' ' + configErrors[i].selectionType.toLowerCase() + ' ' + t('selection.penaltyMessageTwo'), false);
                            } else if (configErrors[i].code == 'CREATE_ERROR'){
                                message(t('selection.penaltyMessage') + ' ' + configErrors[i].selectionType.toLowerCase() + ' ' + t('selection.penaltyMessageOne'), false);
                            } else if (configErrors[i].code == 'STUDENT_ALREADY_IN_ERROR'){
                                message(t('selection.penaltyMessage') + ' ' + configErrors[i].selectionType.toLowerCase() + ' ' + t('selection.penaltyMessageThree'), false);
                            }
                        }
                    }
                } else {
                    message(res?.data?.message || t('errorMessage.title'));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const onUserInteraction = (object) => {
        setPage(object.page);
        setPageSize(object.pageSize);
        setSearch(object.search);
    };

    return (
        <div className='row'>
            <div className='col-4'>
                <Card>
                    <Card.Body>
                        <TreeView
                            treeData={seasons}
                            selectedNodes={[selectedSeasonId]}
                            onSelect={handleNodeClick}
                            defaultExpandAll={true}
                        />
                    </Card.Body>
                </Card>
            </div>
            <div className='col-8 pl-0'>
                <Card>
                    <Card.Body>
                        {
                            selectedSeasonId &&
                            <button
                                className='btn btn-primary'
                                onClick={() => {
                                    setShowModal(true);
                                }}
                            >
                                {t('selection.enterFine')}
                            </button>
                        }
                        <DTable
                            remote
                            config={config}
                            columns={columns}
                            data={students}
                            onInteraction={onUserInteraction}
                            totalDataSize={totalCount}
                            selectMode={'radio'}
                        />
                    </Card.Body>
                </Card>
            </div>
            {
                showModal && (
                    <AddPenalty
                        seasonTitle={seasonTitle}
                        seasonId={selectedSeasonId}
                        onClose={onModalClose}
                        onSubmit={onSubmit}
                    />
                )
            }
        </div>
    );
};

export default SchoolPenalty;