import React,{useState,useEffect} from 'react';
import { Card,Row,Col,Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { studentOnlineLessonHomeworkView, studentOnlineLessonGetFile } from '../../../../utils/fetchRequest/Urls';
import { fetchRequest, fetchRequestFile } from '../../../../utils/fetchRequest';
import message from '../../../modules/message';
import { setLoading } from '../../../../redux/action';
import { useDispatch,useSelector } from 'react-redux';
import ReactPlayer from 'react-player/lazy';
import { saveAs } from 'file-saver';

const OnlineLessonViewHomework = ({
location: { state: {
    lesson = {},
    homeworkId = null
} = {} } = {}, }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();

    const school = useSelector(state => state?.selectedSchool?.id || null);

    const [moduleObj, setModuleObj] = useState({});

    const onBack = () =>{
        history.push({
            pathname: '/student/online-lesson/detail',
            state: {
                lesson,
                tab: 'homework'
            }
        })
    }

    const fetchData = id =>{
        const bodyParams = {
            school,
            homework: id,
            course: lesson.id,
        }

        dispatch(setLoading(true));
        fetchRequest(studentOnlineLessonHomeworkView, 'GET', bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    
                    setModuleObj(data?.homeworkData || []);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }

    useEffect(()=>{
        fetchData(homeworkId);
    },[])

    const onDownload = (id) => {
        const bodyParams = {
            file: id
        };

        dispatch(setLoading(true));
        fetchRequest(studentOnlineLessonGetFile, 'POST', bodyParams)
            .then(res => {
                if(res.success){
                    fetchRequestFile(studentOnlineLessonGetFile, 'GET', bodyParams)
                        .then(res => {
                            if(res.status != 200){
                                dispatch(setLoading(false));
                                message(res?.data?.message || t('errorMessage.title'));
                            }
                            else{
                                return res.blob();
                            }
                        })
                        .then(blob => {
                            dispatch(setLoading(false));
                            if(blob){
                                saveAs(blob, res.data?.name);
                            }
                        })
                        .catch((e) => {
                            dispatch(setLoading(false));
                            message(t('errorMessage.title'));
                        });
                }
                dispatch(setLoading(false));
            })
            .catch((e) => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            });        
    };

    return (
        <Card>
            <Card.Header>
                <Row className='align-items-center'>
                    <Col md={6}>
                        <span style={{ color: '#29569f',fontSize:'14px'}}>
                            <b>{moduleObj.name + ', ' + moduleObj.duration || '-'}</b>
                        </span>
                    </Col>
                    <Col md={6} className='text-right'>
                        <button onClick={onBack} className='btn btn-link bolder'>{t('common.back')}</button>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <div className='w-100 p-4' style={{textIndent: '25px',...border}}>
                    <p>
                        <b>{t('homework.question')}</b>
                    </p>
                    <p>
                        {moduleObj?.description || ''}
                    </p>
                    <p>
                        <b>Даалгавар авах төрөл</b>
                    </p>
                    <p>
                        {moduleObj?.typeNames || ''}
                    </p>
                    {
                        moduleObj.fileList && moduleObj.fileList.length > 0 &&
                        <>
                            <p>
                                <b>Даалгаврын файл</b>
                            </p>
                            <p>
                                {
                                    moduleObj.fileList.map((file, index) => {
                                        return <button style={{display: 'inline-flex', paddingLeft: 0, border: 'none', background: 'white'}} className='btn-link' key={'file' + index} onClick={() => onDownload(file.id)}>{file.name}</button>
                                    })
                                }
                            </p>
                        </>
                    }
                </div>
                {
                    moduleObj.studentFileList && moduleObj.studentFileList.length > 0
                    ?
                        <div className='w-100 p-4 my-4' style={{textIndent: '25px',...border}}>
                            <p>
                                <b>Оюутны илгээсэн файл</b>
                            </p>
                            <div>
                                {
                                    moduleObj.studentFileList.map((file, index) => {
                                        return <button style={{display: 'inline-flex', paddingLeft: 0, border: 'none', background: 'white'}} className='btn-link' key={'file' + index} onClick={() => onDownload(file.id)}>{file.name}</button>
                                    })
                                }
                            </div>
                        </div>    
                    : null
                }
            </Card.Body>
        </Card>
    )
}

const border = {
    border: 'none',
    boxShadow: '0 2px 10px 0 #919bc033',
    borderRadius: '8px',
    padding: '1.5rem'
}

export default OnlineLessonViewHomework