import React, {useEffect, useRef, useState} from 'react';
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {setLoading} from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import message from "../../../modules/message";
import Select from "../../../modules/Form/Select";
import {findTeachers, teacherInvite} from "../../../../utils/fetchRequest/Urls";
import Forms from "../../../modules/Form/Forms";

const InviteModal = ({
    onClose,
    onSave,
    selectedDepartmentId
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    const [value, setValue] = useState(null);
    const [teachers, setTeachers] = useState([]);
    const [teacherId, setTeacherId] = useState(null);
    const [departments, setDepartments] = useState([]);

    const inviteFormRef = useRef();

    useEffect(() => {
        const params = {
            school: schoolId,
        };
        dispatch(setLoading(true));
        fetchRequest(teacherInvite, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setDepartments(data.departments || []);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            })
    }, []);

    useEffect(() => {
        let timeout;
        if (value) {
            timeout = setTimeout(() => {
                const params = {
                    school: schoolId,
                    text: value
                };
                dispatch(setLoading(true));
                fetchRequest(findTeachers, 'GET', params)
                    .then(res => {
                        if (res.success) {
                            const { data } = res;
                            setTeachers(data.teachers);
                        } else {
                            message(res?.data?.message || t('errorMessage.title'))
                        }
                        dispatch(setLoading(false));
                    })
                    .catch(() => {
                        dispatch(setLoading(false));
                        message(t('errorMessage.title'))
                    })
            }, 1000);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [value]);

    useEffect(() => {
        inviteFormRef?.current?.updateFields && inviteFormRef.current?.updateFields(inviteForms);
    }, [departments]);

    const inviteForms = [
        {
            label: t('school.parentDepartment') + '*',
            value: selectedDepartmentId ? selectedDepartmentId !== '#' ? selectedDepartmentId : null : null,
            errorMessage: t('errorMessage.selectRelatedDepartment'),
            required: true,
            type: 'dropdown',
            options: departments,
            multiple: false,
            clearable: true,
        }
    ];

    const onStudentChange = (value) => {
        setTeacherId(value);
    };

    const onStudentInputChange = (inputValue) => {
        if(inputValue){
            setValue(inputValue);
        }
    };

    const onSaveClick = () => {
        const [ formValid, formValue ] = inviteFormRef.current.validate();

        if (formValid) {
            let data = teacherId + '_' + formValue[0].value;
            onSave(data)
        }
    };

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName='modal-90w'
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('teacher.invite')}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='col-12'>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <label
                            style={{
                                display: 'flex',
                                flex: 1,
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                marginRight : 10,
                                marginBottom: 0,
                                width: 'auto',
                            }}
                        >
                            {t('teacher.select')}
                        </label>
                        <div
                            style={{
                                display: 'flex',
                                flex: 1,
                                flexDirection: 'column',
                                marginLeft: 5,
                                width: 'auto',
                            }}
                        >
                            <Select
                                value={teacherId}
                                searchable
                                onChange={onStudentChange}
                                onInputChange={onStudentInputChange}
                                options={teachers}
                            />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flex: 0.8,
                                flexDirection: 'column',
                                width: 'auto',
                            }}
                        />
                    </div>
                </div>
                <div className='col-12'>
                    <Forms
                        ref={inviteFormRef}
                        fields={inviteForms}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className='btn btn-link bolder'>{t('common.back')}</button>
                <Button variant='success btn-shadow' onClick={onSaveClick}>{t('common.save')}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default InviteModal;