import React, { useEffect, useState } from 'react';
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import { examTypeConfigAdd, examTypeConfigEdit } from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import { useDispatch, useSelector } from "react-redux";
import TreeView from "../../../modules/TreeView";
import ExamTypeConfigAdd from "./ExamTypeConfigAdd";
import { getFlatTreeData } from "../../../../utils/utils";
import { useTranslation } from "react-i18next";

const ExamTypeConfig = () => {
    const { t } = useTranslation();
    const [ editId, setEditId ] = useState(null);
    const [ seasons, setSeasons ] = useState([]);
    const [ seasonId, setSeasonId ] = useState(null);
    const [ examTypes, setExamTypes ] = useState([]);
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const dispatch = useDispatch();

    useEffect(() => {
        const params = {
            school: schoolId
        }
        dispatch(setLoading(true));
        fetchRequest(examTypeConfigAdd, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    const seasonArray = data?.seasons;

                    if(seasonArray && seasonArray.length > 0){
                        for(let i = 0; i < seasonArray.length; i++)
                        {
                            const split = seasonArray[i]['key'].toString().split('_');
                            if (split.length !== 4)
                            {
                                seasonArray[i]['disableContextMenu'] = true;
                            }
                            if(seasonArray[i]['children'] && seasonArray[i]['children'].length > 0)
                            {
                                checkChildArray(seasonArray[i]['children']);
                            }
                        }
                    }

                    setSeasons(data?.seasons || []);
                    setExamTypes(data?.examTypes || []);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }, [])

    const checkChildArray = (array) => {
        if(array && array.length > 0){
            for(let c = 0; c < array.length; c++) {
                const split = array[c]['key'].toString().split('_');
                if (split.length !== 4) {
                    array[c]['disableContextMenu'] = true;
                } else {
                    array[c]['disableContextMenu'] = false;
                }

                if (array[c]['children'] && array[c]['children'].length > 0) {
                    checkChildArray(array[c]['children']);
                }
            }
        }
    }

    const handleNodeClick = array => {
        if (array?.length) {
            const [ id ] = array;
            const split = id.toString().split('_');
            if (split.length === 4) {
                setSeasonId(id);
            }
        }
    }

    const handleContextMenuClick = (id, key) => {
        if (id && key) {
            if (key === 'EDIT') {
                const split = id.toString().split('_');
                if (split.length === 4) {
                    setEditId(id);
                }
            }
        }
    };

    const onModalClose = () => {
        setEditId(null);
    };

    const addPrefixParent = (list, id = null, string = '') => {
        if (!id) {
            return string;
        }
        const parent = list.find(item => item.key === id);
        if (parent) {
            return addPrefixParent(list, parent.parent, `${parent.title || '*'} - ${string}`);
        }
        return string;
    }

    let selectedSeasonName = '-';

    if (editId) {
        const flatTreeData = getFlatTreeData(seasons);
        const selectedSeason = flatTreeData.find(object => object.key === editId);
        if (selectedSeason) {
            selectedSeasonName = addPrefixParent(flatTreeData, selectedSeason.parent, selectedSeason.title);
        }
    }

    const onSubmit = params => {
        const url = editId ? examTypeConfigEdit : examTypeConfigAdd;

        const { examTypes } = params;
        const formData = new FormData();
        formData.append('school', schoolId);
        formData.append('season', editId);

        for (const examType of examTypes) {
            formData.append('examTypes[]', examType);
        }
        dispatch(setLoading(true));
        fetchRequest(url, 'POST', formData, false, true)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    const seasonArray = data?.seasons;

                    if(seasonArray && seasonArray.length > 0){
                        for(let i = 0; i < seasonArray.length; i++)
                        {
                            const split = seasonArray[i]['key'].toString().split('_');
                            if (split.length !== 4)
                            {
                                seasonArray[i]['disableContextMenu'] = true;
                            }
                            if(seasonArray[i]['children'] && seasonArray[i]['children'].length > 0)
                            {
                                checkChildArray(seasonArray[i]['children']);
                            }
                        }
                    }

                    setExamTypes(data?.examTypes || []);
                    setSeasons(data?.seasons);

                    message(data?.message || t('common.success'), true);

                    onModalClose();
                } else {
                    message(res?.data?.message || t('errorMessage.title'));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }

    return (
        <div>
            <TreeView
                treeData={seasons}
                selectedNodes={[seasonId]}
                onSelect={handleNodeClick}
                defaultExpandAll={true}
                contextMenus={
                    {
                        examTypeConfig: [
                            {
                                key: 'EDIT',
                                iconClassName: 'las la-edit rc-tree-la-edit',
                                text: t('common.edit'),
                            }
                        ]
                    }
                }
                contextMenuKey={'examTypeConfig'}
                onContextMenuClick={handleContextMenuClick}
            />
            {
                editId && (
                    <ExamTypeConfigAdd
                        onClose={onModalClose}
                        onSubmit={onSubmit}
                        seasonName={selectedSeasonName}
                        options={examTypes}
                        id={editId}
                    />
                )
            }
        </div>
    );
};

export default ExamTypeConfig;