import React,{ useRef } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Forms from '../../../modules/Form/Forms'
export const AddModal = ({
    open = false,
    close = ()=>{},
    title = '',
    fields = [],
    onSubmit = () =>{},
}) => {
    const formRef = useRef();
    const {t} = useTranslation();

    const onSaveClick = () => {
        const [ isValid, states, values ] = formRef.current.validate();
        if (isValid) {
            const params = {
                ...values,
            };
            onSubmit(params);
        }
    };



    return (
        <Modal
            show={open}
            onHide={close}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {title.toUpperCase()}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Forms
                    ref={formRef}
                    fields={fields}
                />
            </Modal.Body>
            <Modal.Footer>
                <button className='btn btn-link btn-shadow' onClick={close}>{t('common.back')}</button>
                <button className='btn btn-success btn-shadow' onClick={onSaveClick}>{t('common.save')}</button>
            </Modal.Footer>
        </Modal>
    )
};
