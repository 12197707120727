import React,{useEffect,useState} from 'react'
import DTable from '../../../modules/DataTable/DTable';
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import { setLoading } from '../../../../redux/action';
import message from '../../../modules/message';
import { fetchRequest } from '../../../../utils/fetchRequest'
import { movementExcuse,movementExcuseUndo } from '../../../../utils/fetchRequest/Urls'
import ViewModal from './modals/ViewModal';
import UndoModal from './modals/UndoModal';
import ChangeTypeModal from './modals/ChangeTypeModal';

const StudentTable = ({
    nodeId,
    schoolId,
    cause
}) => {

    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();

    const [movements,setMovements] = useState([]);
    const [totalCount,setTotalCount] = useState(1);
    const [tempId,setTempId] = useState();
    const [viewModal,setViewModal] = useState(false);
    const [viewUndoModal,setViewUndoModal] = useState(false);
    const [viewTypeModal,setViewTypeModal] = useState(false);
    const [tableChangeParams,setTableChangeParams] = useState(null);

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [search, setSearch] = useState('');

    const contextMenus = [
        {
            key: 'changeType',
            title: t('common.changeType'),
            icon: <i className="fas fa-exchange-alt dt-cm-item-icon-18"/>
        },
        {
            key: 'view',
            title: t('action.view'),
            icon: <i className='las la-eye dt-cm-item-icon' />,
        },
        {
            key: 'edit',
            title: t('common.edit'),
            icon: <i className='las la-edit dt-cm-item-icon'/>
        },
        {
            key: 'undo',
            title: t('action.undo'),
            icon: <i className='las la-backspace dt-cm-item-icon' />,
        },
    ];

    const columns = [
        {
            dataField: 'createdDate',
            text: t('common.date'),
            sort: true,
            formatter(cell,row) {
                return (
                    <div className='msue-dt-clickable-cell' onClick={()=>onDateClick(cell,row)}>
                        {cell.date?.substring(0,19)}
                    </div>
                )
            },
        },
        {
            dataField: "studentCode",
            text: t('student.code'),
            sort: true
        },
        {
            dataField: "firstName",
            text: t('student.name'),
            sort: true
        },
        {
            dataField: "seasons",
            text: t('movement.excused.seasons'),
            sort: true,
            formatter(cell) {
                if(cell.length){
                    return cell.map((s,index)=>{
                        return (
                            <span key={index}>
                                {s.seasonName}
                                {index + 1 < cell.length ? ', ' : ''}
                            </span>
                        )
                    })
                    
                }else{
                    return null
                }
            },
        },
        {
            dataField: "fromClass",
            text: t('movement.out.prevClass'),
            sort: true
        },
        {
            dataField: "createdFirstName",
            text: t('common.createdUser'),
            sort: true
        },
    ];

    const contextMenuClick = (id,key) =>{
        if(id && key){
            if(key === 'edit'){
                history.push({
                    pathname: '/movement/excused/add',
                    state: {
                        movement: id,
                    }
                })
            }else if(key === 'undo'){
                setViewUndoModal(true)
                setTempId(id)
            }else if(key === 'view'){
                setViewModal(true)
                setTempId(id)
            }else if(key === 'changeType'){
                setViewTypeModal(true)
                setTempId(id)
            }
        }
    }

    const fetchData = params => {
        const bodyParams = {
            ...params,
            school: schoolId,
            level: nodeId,
            movementCause: cause,
            page,
            pageSize,
            search
        }
        dispatch(setLoading(true));
        fetchRequest(movementExcuse, 'GET', bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res;

                    setMovements(data?.movements)
                    setTotalCount(data?.totalCount)
                    dispatch(setLoading(false));
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                    dispatch(setLoading(false));
                }
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const handleTableChange = params =>{
        setPage(params.page);
        setPageSize(params.pageSize);
        setSearch(params.search);
        setTableChangeParams(params);
    }

    const onDateClick = (cell,row) =>{
        if(row.id){
            setViewModal(true)
            setTempId(row.id)
        }
    }

    const onDelete = () =>{
        if(tempId){
            const params = {
                movement: tempId,
                school: schoolId
            }
            dispatch(setLoading(true));
            fetchRequest(movementExcuseUndo,'POST',params)
                .then(res => {
                    if (res.success) {
                        setViewUndoModal(false)
                        setTempId(null)
                        dispatch(setLoading(false))
                        fetchData(tableChangeParams)
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                        dispatch(setLoading(false))
                    }
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        }
    }

    useEffect(()=>{
        if(nodeId){
            fetchData()
        }
    },[nodeId,cause, page, pageSize, search])

    return (
        <>
            <DTable
                remote
                config={{}}
                columns={columns}
                data={movements}
                contextMenus={contextMenus}
                onContextMenuItemClick={contextMenuClick}
                onInteraction={handleTableChange}
                totalDataSize={totalCount}
            />
            {
                viewModal
                ?
                    <ViewModal
                        movementId={tempId}
                        onClose={()=>{
                            setViewModal(false)
                            setTempId(null)
                        }}
                    />
                : null
            }
            {
                viewUndoModal
                ?
                    <UndoModal
                        onDelete={onDelete}
                        onClose={()=>{
                            setViewUndoModal(false)
                            setTempId(null)
                        }}
                    />
                : null
            }
            {
                viewTypeModal
                ?
                    <ChangeTypeModal
                        movementId={tempId}
                        onClose={()=>{
                            setViewTypeModal(false)
                            setTempId(null)
                        }}
                        fetchList={()=>fetchData(tableChangeParams)}
                    />
                : null
            }
        </>
    )
}

export default StudentTable