import React from 'react';
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Remove = ({
    onClose = () => {},
    children,
    onRemove = () => {},
    title = '',
}) => {
    const { t } = useTranslation();

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem', textTransform: 'upperCase' }}>
                        {title}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className='btn btn-link bolder'>{t('common.back')}</button>
                <Button variant='success btn-danger' onClick={onRemove}>{t('common.remove')}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default Remove;