import React, { useRef, useState } from 'react';
import Forms from "../../modules/Form/Forms";
import DropdownTest from "../../modules/Form/DropdownTest";
import Dropdown from "../../modules/Form/Dropdown";
import Checkbox from "../../modules/Form/Checkbox";
import Select from "../../modules/Form/Select";
import SeasonDropdown from "./SeasonDropdown";
import DatePicker from "../../modules/Form/DatePicker";
import TimePicker from "../../modules/Form/TimePicker";

const FormTest = () => {

    const [ checkForm, setCheckForm ] = useState(false);
    const [ gender , setGender ] = useState(null);
    const [ time, setTime ] = useState(null);

    const validateFirstName = () => {
        return {
            valid: false,
            message: 'Taalagadku bna'
        }
    }

    const validateLastName = () => {
        return {
            valid: true,
            message: 'Yanziin yanziin'
        }
    }

    const forms = [
        {
            label: 'First Name',
            value: '',
            errorMessage: 'Enter valid first name please',
            required: true,
            validation: validateFirstName,
            type: 'text',
        },
        {
            label: 'Last Name',
            value: '',
            errorMessage: 'Enter valid last name please',
            required: true,
            validation: validateLastName,
            type: 'text',
        },
        {
            label: 'Age',
            value: '',
            errorMessage: 'Zovhon too oruulna uu',
            required: true,
            // validation: validateEhniih,
            type: 'number',
        },
        {
            label: 'Gender',
            value: '',
            errorMessage: 'Toogoo songoochee',
            required: true,
            // validation: validateEhniih,
            type: 'dropdown',
            placeholder: 'Neg too songochih',
            options: [
                {
                    value: 1,
                    text: '1',
                },
                {
                    value: 2,
                    text: '2',
                },
                {
                    value: 3,
                    text: '3',
                },
                {
                    value: 4,
                    text: '4',
                },
                {
                    value: 5,
                    text: '5',
                },
            ],
            multiple: false,
            clearable: true
        },
    ];

    const onSubmit = () => {
        // setCheckForm(true);
    }

    const onSuccess = formValues => {
    }

    const onDropdownChange = (q, w, e, r) => {
        setGender(q);
    }

    const onAutocompleteChange = (q, w, e, r) => {
        setGender(q);
    }

    const qRef = useRef();

    return (
        <div>
            <h1 style={{ textAlign: 'center' }}>Test form page</h1>
            <Forms
                ref={qRef}
                fields={forms}
            />
            <button
                onClick={onSubmit}
            >
                Submit
            </button>
            {/*<DropdownTest
                // multiple
                value={gender}
                onChange={onDropdownChange}
                label={'xdxd'}
                options={[
                    {
                        value:  'M',
                        text: 'Eregtei'
                    },
                    {
                        value:  'F',
                        text: 'Emegtei'
                    },
                    {
                        value:  'U',
                        text: 'Helkue'
                    },
                ]}
            />*/}
            AUTOCOMPLETE
            <Dropdown
                // multiple
                value={gender}
                onChange={onAutocompleteChange}
                label={'xdxd'}
                options={[
                    {
                        value:  'M',
                        text: 'Eregtei'
                    },
                    {
                        value:  'F',
                        text: 'Emegtei'
                    },
                    {
                        value:  'U',
                        text: 'Helkue'
                    },
                ]}
            />
            <Checkbox
                label={'checkleed uz'}
                onChange={(checked, e) => {
                }}
                value={true}
            />
            <SeasonDropdown

            />
            <hr/>
            <DatePicker
                buttonClassName={''}
            />
            <hr/>
            <TimePicker
                value={time}
                onChange={(value) => {
                    setTime(value)
                }}
                allowInput
            />
            CONTENT BELOW
        </div>
    )
}

export default FormTest;