import React , {useRef} from "react";
import { Button, Modal } from "react-bootstrap";
import Forms from "../../../../../app/modules/Form/Forms";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { setLoading, setPersonInfo } from "../../../../../redux/action";
import { fetchRequest } from "../../../../../utils/fetchRequest";
import { passwordSave} from "../../../../../utils/fetchRequest/Urls";
import message from "../../../../../app/modules/message";

export const ChangePassword=({
    onClose, 
    forcePasswordChange, 
})=>{
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const forms = useRef();
    const fields = [
        {   
            label: t('auth.oldPassword'),
            value:'',
            type: 'password',
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            key: 'password',
        },
        {   
            label: t('common.newPassword'),
            value: '',
            type: 'password',
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            key: 'newpassword'
        },
        {
            label: t('common.newRePassword'),
            value: '',
            type: 'password',
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            key: 'newPasswordRepeat'
        }
    ];
    
    const newPassSave = () => { 
        const [statesForm, valueForm] = forms.current.validate();
            if(statesForm){
                let params = {
                        old: valueForm[0].value,
                        new: valueForm[1].value,
                        force: forcePasswordChange ? 1 : 0
                };
                if(valueForm[1].value === valueForm[2].value){
                    dispatch(setLoading(true));
                    fetchRequest(passwordSave, 'GET', params)
                        .then(res => {
                            if (res.success) {
                                message(res.data?.message, true);
                                onClose();
                            } else {
                                message(res.data?.message, false);
                            }
                            if(forcePasswordChange){
                                dispatch(setPersonInfo(res.data.user))
                            }
                            dispatch(setLoading(false));
                        })
                        .catch(() => {
                            dispatch(setLoading(false));
                            message(t('errorMessage.title'));
                        });
                }else{
                    message(t('errorMessage.repeatPassword'));
                }
            }
    };

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop={forcePasswordChange ? 'static' : true} 
        >
            <Modal.Header closeButton>
                <Modal.Title >
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('common.changePassword').toUpperCase()}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Forms
                    ref = {forms}
                    fields={fields}
                />
            </Modal.Body>
            <Modal.Footer>
                {
                    forcePasswordChange
                    ?
                        <></>
                    :
                        <button onClick={onClose} className='btn btn-link bolder'>{t('common.back')}</button>
                }
                <Button variant='success btn-shadow' onClick={newPassSave}>{t('common.save')}</Button>
            </Modal.Footer>
        </Modal>
    )
};