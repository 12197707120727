import React, {useEffect, useRef, useState} from 'react';
import {Button, Card, Modal} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import Select from "../../../modules/Form/Select";
import {setLoading} from "../../../../redux/action";
import {fetchRequest} from "../../../../utils/fetchRequest";
import {AddSubjectStudentSelection} from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";

const Penalty = ({
    selectionData
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const languages = useSelector(state => state?.languages?.languages || []).filter(lang => lang.code !== 'mn');
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    useEffect(() => {
    }, []);

    // const renderSubject = (subjects) => {
    //     let renderData = [];
    //
    //     if(subjects && subjects.length > 0){
    //         for(let i = 0; i < subjects.length; i++){
    //             let pTime = subjects[i].pTimes;
    //             let tdData = [];
    //
    //             if(pTime && pTime.length > 0){
    //                 for(let p = 0; p < pTime.length; p++){
    //                     tdData.push(
    //                         <tr key={'subject_' + i + '_ptime_' + p}>
    //                             <td>{p + 1}</td>
    //                             <td>{pTime[p].timeName}</td>
    //                             <td>
    //                                 <Select
    //                                     options={pTime[p].timetables}
    //                                     value={pTime[p].timetable}
    //                                     // onChange={(id) => onSelect(subjects[i].subjectId, pTime[p].id, id)}
    //                                     searchable
    //                                 />
    //                             </td>
    //                         </tr>
    //                     )
    //                 }
    //             }
    //
    //             renderData.push(
    //                 <div
    //                     key={'subject_' + i}
    //                 >
    //                     <p className='strong-blue'><b>{subjects[i].code + ' ' + subjects[i].name + ' - ' + subjects[i].credit + ' ' + (t('subject.credit')).toLowerCase()}</b></p>
    //                     <table className='table table-bordered'>
    //                         <thead>
    //                         <tr>
    //                             <th style={{width: 40}}>№</th>
    //                             <th style={{width: 180}}>{t('subject.title')}</th>
    //                             <th>{t('subject.teacher') + ' - ' + t('common.weekdayWithtime')}</th>
    //                         </tr>
    //                         </thead>
    //                         <tbody>
    //                         {tdData}
    //                         </tbody>
    //                     </table>
    //                 </div>
    //             );
    //         }
    //     }
    //
    //     return renderData;
    // };

    return (
        <Card.Body>
            <div className="col-12 pl-0">
                <Button
                    variant='danger'
                    size='lg'
                >
                    {t('selection.payFine')}
                </Button>
                <div className='col-12 mt-3 pink-red'>
                    <b>
                        {
                            t('selection.payFineTwoDescription')
                        }
                    </b>
                </div>
            </div>
        </Card.Body>
    );
};

export default Penalty;