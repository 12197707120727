import React, {useState, useEffect, useRef} from 'react';
import {Card, Button, ButtonToolbar, Modal, Nav, Form, Row, Col} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {useHistory, useLocation} from "react-router-dom";
import Forms from "../../../modules/Form/Forms";
import {setLoading} from "../../../../redux/action";
import {fetchRequest} from "../../../../utils/fetchRequest";
import { programEdit, getCurriculum, curriculumAdd, curriculumEdit, getTeachersBySubject, academicProgramList, curriculumReorder, curriculumDeLete, curriculumPublish, curriculumDownloadProgramDtl, subjectList } from "../../../../utils/fetchRequest/Urls";
import qs from "qs";
import {useDispatch, useSelector} from "react-redux";
import DTable from "../../../modules/DataTable/DTable";
import i18n from "i18next";
import message from "../../../modules/message";
import Select from "../../../modules/Form/Select";
import { cloneDeep } from 'lodash';

const ProgramEdit = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const selectedSchool = useSelector(state => state?.selectedSchool || []);
    const languages = useSelector(state => state?.languages?.languages || []).filter(lang => lang.code !== 'mn');
    const [key, setKey] = useState("#plan");
    const [selectedProgramId, setSelectedProgramId] = useState(location.state && location.state.id ? location.state.id : null);
    const [dtlList, setDtlList] = useState([]);
    const [addModal, setAddModal] = useState(false);
    const [selectedSubject, setSelectedSubject] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [subject, setSubject] = useState(null);
    const [subjectOption, setSubjectOption] = useState([]);
    const [categories, setCategories] = useState([]);
    const [subjectTypes, setSubjectTypes] = useState([]);
    const [pType, setPType] = useState([]);
    const [addSubjectData, setAddSubjectData] = useState([]);
    const [editId, setEditId] = useState(null);
    const [seasonTypes, setSeasonTypes] = useState([]);
    const [teacherList, setTeachers] = useState([]);
    const [pTimes, setPTimes] = useState([]);
    const [isSaved, setIsSaved] = useState(true);
    const [programs, setPrograms] = useState([]);
    const [selectedProgram, setSelectedProgram] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [types, setType] = useState([]);
    const [approaches, setApproaches] = useState([]);
    const [scoreTypes, setScoreTypes] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [educationLevels, setEducationLevels] = useState([]);
    const [approveModal, setApproveModal] = useState(false);
    const [downloadModal, setDownloadModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [record, setRecord] = useState(null);
    const [editAction, setEditAction] = useState(false);
    const [lastAddedSubjectId, setLastAddedSubjectId] = useState(null);
    const [selectedDtlId, setSelectedDtlId] = useState(null);
    const [programData, setProgramData] = useState(null);
    const [typeData, setTypeData] = useState(null);
    const [academicPrograms, setAcademicPrograms] = useState([]);
    const [programList, setProgramList] = useState([]);
    const [academicProgramId, setAcademicProgramId] = useState(null);
    const [programId, setProgramId] = useState(null);
    const [downloadSave, setDownloadSave] = useState(false);

    const statuses = [
        {id: 1, code: 'info', name: t('menu.generalInformation')},
        {id: 2, code: 'plan', name: t('menu.learningPlan')}
    ];

    const infoFormRef = useRef();
    const translateFormRef = useRef();

    const firstFormRef = useRef();
    const secondFormRef = useRef();
    const downloadFormRef = useRef();

    const translationFields = languages.map(lang => {
        return {
            label: `${t('common.name')} - ${lang.name} (${lang.code})`,
            value: '',
            langCode: lang.code,
            type: 'text',
            key: 'name',
            inputStyle: lang.code == 'tr' ? {fontFamily: 'Urga'} : {}
        }
    });

    useEffect(() => {
        dispatch(setLoading(true));
        let params = {
            school: selectedSchool.id,
            program: selectedProgramId
        };

        fetchRequest(getCurriculum, 'GET',params)
            .then(response => {
                if (response.success) {
                    setPType(response.data.pTypes.map(type => ({value: type.id, text: type.name})));
                    setProgramData(response.data.programData);

                    let dtlArray = [];
                    let typeList = response.data.types;
                    response.data.pTypes.map(function (type, i) {
                        if(typeList && typeList.length > 0){
                            for (let i = 0; i < typeList.length; i++)
                            {
                                if(type.id === typeList[i].typeId){
                                    let details = typeList[i]['details'];
                                    if(details && details.length > 0){
                                        for(let n = 0; n < details.length; n++)
                                        {
                                            let teacherNames = '';
                                            let timeString = '';
                                            let teachers = details[n]['teachers'];
                                            let times = details[n]['times'];
                                            if(teachers && teachers.length > 0){
                                                for(let t = 0; t < teachers.length; t++)
                                                {
                                                    if(teachers.length >= t && t === 0) {
                                                        teacherNames = teachers[t]['teacherCode'] + '-' + teachers[t]['firstName'];
                                                    } else {
                                                        teacherNames = teacherNames + '; ' + teachers[t]['teacherCode'] + '-' + teachers[t]['firstName'];
                                                    }
                                                }
                                            }
                                            if(times && times.length > 0){
                                                for(let s = 0; s < times.length; s++)
                                                {
                                                    timeString = timeString + times[s]['timeName'] + '(' + times[s]['hour'] + '); ';
                                                }
                                            }
                                            details[n]['teacherNames'] = teacherNames;
                                            details[n]['timeString'] = timeString;
                                        }
                                    }

                                    dtlArray['dtl_' + type.id] = typeList[i]['details'];
                                }
                            }
                        } else {
                            dtlArray['dtl_' + type.id] = []
                        }
                    });

                    setTypeData(response?.data?.types || []);
                    setDtlList(dtlArray);
                } else {
                    message(response?.data?.message, false);
                }
                setIsSaved(false);
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
            });
    }, [lastAddedSubjectId]);

    useEffect(() => {
        infoFormRef?.current?.updateFields && infoFormRef.current.updateFields(infoForms);
    }, [departments, types, educationLevels, templates, scoreTypes, approaches, selectedProgram]);

    useEffect(() => {
        infoFormRef?.current?.updateChildField && infoFormRef.current.updateChildField(infoForms, 'academicProgram', 3);
    }, [programs]);

    useEffect(() => {
        downloadFormRef?.current?.updateFields && downloadFormRef.current.updateFields(downloadForms);
    }, [academicPrograms, programList]);

    useEffect(() => {
        secondFormRef?.current?.updateFields && secondFormRef.current.updateFields(secondForms);
    }, [subject]);

    const tableData = addSubjectData.map(object => {
        const isEditing = object.id === editId;
        let types;
        let hours;
        let maximum;
        let minimum;
        let teachers;
        let typeNames;
        let teacherNames;
        let isFinish;
        if (isEditing) {
            types = <Select
                value={object.types}
                options={pTimes}
                multiple
                onChange={(val) => {

                    setValues(object.id, val, 'types')
                }}
            />;
            hours = <input
                    style={{width: '-webkit-fill-available'}}
                    type='number'
                    onChange={(e) => {
                        // const re = /^[0-9\b]+$/;
                        // if (e.target.value === '' || re.test(e.target.value)) {
                            setValues(object.id, e.target.value, 'hours')
                        // }
                    }}
                    value={object.hours}
                    inputMode='numeric'
                />;
            maximum = <input
                    style={{width: '-webkit-fill-available'}}
                    type='number'
                    onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === '' || re.test(e.target.value)) {
                            setValues(object.id, e.target.value, 'maximum')
                        }
                    }}
                    value={object.maximum}
                    inputMode='numeric'
                />;
            minimum = <input
                    style={{width: '-webkit-fill-available'}}
                    type='number'
                    onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === '' || re.test(e.target.value)) {
                            setValues(object.id, e.target.value, 'minimum')
                        }
                    }}
                    value={object.minimum}
                    inputMode='numeric'
                />;
            teachers = <Select
                value={object.teachers}
                options={teacherList}
                multiple
                isSearchable
                onChange={(val) => {
                    setValues(object.id, val, 'teachers')
                }}
            />
        } else {
            types = object ? object.types : '';
            hours = object ? object.hours : '';
            maximum = object ? object.maximum : '';
            minimum = object ? object.minimum : '';
            teachers = object ? object.teachers : '';
            typeNames = getNames(object.types, pTimes);
            teacherNames = getNames(object.teachers, teacherList);

            // dummy isFinished gj avav, isFinish bhgvi bol iconHandler uruu data set hiij chadahgvi tul, (zasah)
            isFinish = <input
                style={{width: '-webkit-fill-available'}}
                type='number'
                onChange={(e) => {
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === '' || re.test(e.target.value)) {
                        setValues(object.id, e.target.value, 'isFinish')
                    }
                }}
                value={object.minimum}
                inputMode='numeric'
            />;
        }

        return {
            ...object,
            types,
            hours,
            maximum,
            minimum,
            teachers,
            isFinish,
            typeNames,
            teacherNames
        }
    });

    function getNames (mainArray, searchArray) {
        let names = '';
        if(mainArray && mainArray.length > 0){
            for(let i = 0; i < mainArray.length; i++){
                for(let n = 0; n < searchArray.length; n++){
                    if(mainArray[i] == searchArray[n].value){
                        if(mainArray.length >= i && i === 0) {
                            names = searchArray[n].text;
                        } else {
                            names = names + ', ' + searchArray[n].text;
                        }
                    }
                }
            }
        }
        return names;
    }

    const setValues = (id, values, name) => {
        const clone = [...addSubjectData];
        const index = clone.findIndex(obj => obj.id === id);
        if (index >= 0) {
            const object = {...clone[index]};
            object[name] = values;
            clone[index] = object;
            setAddSubjectData(clone);
        }
    };

    const handleTab = (key, e) => {
        if(key === '#info'){
            handlerInfo();
        } else {

        }
        setKey(key);
    };

    const config = {
        showPagination: false,
        showFilter: false,
        showAllData: true
    };

    const columns = [
        {
            dataField: "subjectCode",
            text: t('subject.index'),
            sort: true,
        },
        {
            dataField: "subjectName",
            text: t('subject.name'),
            sort: true,
        },
        {
            dataField: "credit",
            text: t('subject.credit'),
            sort: true,
        },
        {
            dataField: "beforeSubjectName",
            text: t('subject.preSubject'),
            sort: true,
        },
        {
            dataField: "seasonTypeName",
            text: t('subject.season'),
            sort: true,
        },
        {
            dataField: "totalPrice",
            text: t('subject.price'),
            sort: true,
        },
        {
            dataField: "categoryName",
            text: t('subject.grade'),
            sort: true,
        },
        {
            dataField: "timeString",
            text: t('subject.time'),
            sort: true,
        },
        {
            dataField: "teacherNames",
            text: t('teacher.title'),
            sort: true,
        }
    ];

    const handlerIcon = (action, content, row, rowIndex, data) => {
        if (action === 'save') {
            for (let i = 0; i < data.length; i++)
            {
                if(i === rowIndex)
                {
                    data[i].types = data[i].types.props.value;
                    data[i].hours = data[i].hours.props.value;
                    data[i].maximum = data[i].maximum.props.value;
                    data[i].minimum = data[i].minimum.props.value;
                    data[i].teachers = data[i]['teachers'].props.value;
                } else {
                    data[i]['types'] = data[i]['cloneTypes'] ? data[i]['cloneTypes'] : data[i]['typeNames'];
                    data[i]['teachers'] = data[i]['cloneTeachers'] ? data[i]['cloneTeachers'] : data[i]['teacherNames'];
                }
                data[i]['isEdit'] = false;
                data[i]['isSave'] = true;
            }

            setAddSubjectData(data);
            setEditId(null);
        } else if (action === 'edit')
        {
            let teacherIds = [];
            let teacherNames = [];
            if(teacherList && teacherList.length > 0){
                for(let t = 0; t < teacherList.length; t++){
                    teacherIds.push(teacherList[t]['value'])
                    teacherNames.push(teacherList[t]['text'])
                }
            }
            for (let i = 0; i < data.length; i++)
            {
                if(i === rowIndex)
                {
                    data[i]['isSave'] = false;
                    data[i]['isEdit'] = true;
                    data[i]['teachers'] = data[i]['teachers'] && data[i]['teachers'].length > 0 ? data[i]['teachers'] : teacherIds;
                } else {
                    data[i]['isEdit'] = false;
                    data[i]['isSave'] = false;
                    data[i]['cloneTypes'] = data[i]['types'];
                    data[i]['types'] = data[i]['typeNames'];
                    data[i]['cloneTeachers'] = data[i]['teachers'];
                    data[i]['teachers'] = data[i]['teacherNames'];
                }
            }

            setAddSubjectData(data);
            setEditId(row.id);
        } else if (action === 'delete'){
            handlerAddSubjectOnAddRemoveRowButton(rowIndex, data)
        }
    };

    const iconFormatter = (cell, row, rowIndex, extraData) => {
        if (row && row.isSave === true) {
            return (
                <>
                    <Button onClick={() => {handlerIcon('edit', cell, row, rowIndex, extraData)}} className="btn btn-info m-btn m-btn--icon m-btn--icon-only m-btn--circle-30 mr-1">
                        <i className="flaticon-edit"> </i>
                    </Button>
                    <Button onClick={() => {handlerIcon('delete', cell, row, rowIndex, extraData)}} className="btn btn-danger m-btn m-btn--icon m-btn--icon-only m-btn--circle-30">
                        <i className="flaticon2-cross"> </i>
                    </Button>
                </>
            );
        } else if(row && row.isEdit === true) {
            return (
                <>
                    <Button onClick={() => {handlerIcon('save', cell, row, rowIndex, extraData)}} className="btn btn-success m-btn m-btn--icon m-btn--icon-only m-btn--circle-30 mr-1">
                        <i className="flaticon2-check-mark"> </i>
                    </Button>
                </>
            );
        }
    };

    const addSubjectColumn = [
        {
            dataField: "types",
            text: t('subject.type'),
            sort: true,
            editable: true,
            hidden: !editId
        },
        {
            dataField: "typeNames",
            text: t('subject.type'),
            sort: true,
            editable: true,
            hidden: !!editId
        },
        {
            dataField: "hours",
            text: t('subject.hoursPerWeek'),
            sort: true,
        },
        {
            dataField: "maximum",
            text: t('subject.maximum'),
            sort: true,
        },
        {
            dataField: "minimum",
            text: t('subject.minimum'),
            sort: true,
        },
        {
            dataField: "teachers",
            text: t('subject.teacher'),
            sort: true,
            hidden: !editId
        },
        {
            dataField: "teacherNames",
            text: t('subject.teacher'),
            sort: true,
            editable: true,
            hidden: !!editId
        },
        {
            isDummyField: true,
            dataField: "action",
            text: "",
            sort: false,
            formatExtraData: tableData,
            formatter: iconFormatter,
        }
    ];

    const contextMenus = [
        {
            key: 'edit',
            title: t('common.edit'),
            icon: <i className='las la-edit dt-cm-item-icon'/>
        },
        {
            key: 'delete',
            title: t('common.delete'),
            icon: <i className='las la-trash-alt dt-cm-item-icon'/>,
        },
    ];

    const handleContextMenuClick = (id, key, record) => {
        if(downloadSave){
            message('Та хадгалах үйлдэл хийж засах устгах үйлдэл хийх эрх нээгдэнэ.')
        } else {
            if(key === 'edit')
            {
                handleEditSubject(id);
                setRecord(record);
                setEditAction(true);
                setSelectedSubject({
                    code: record['subjectCode'],
                    name: record['subjectName'],
                    typeName: record['subjectType']
                });
                handlerSubject(record['subjectId']);
    
                let timesArray = [];
                if(record['times'] && record['times'].length > 0){
                    let recordTimes = record['times'];
                    let recordTeachers = record['teachers'];
    
                    for(let i = 0; i < recordTimes.length; i++){
                        let teacherIds = [];
                        let names = '';
    
                        if(recordTeachers && recordTeachers.length > 0)
                        {
                            for(let t = 0; t < recordTeachers.length; t++)
                            {
                                if(recordTimes[i]['timeId'] === recordTeachers[t]['pTimeId']){
                                    if(recordTeachers.length >= t && t === 0)
                                    {
                                        names = recordTeachers[t]['firstName'];
                                    } else {
                                        names = names + ', ' + recordTeachers[t]['firstName'];
                                    }
                                    teacherIds.push(parseInt(recordTeachers[t]['teacherId']));
                                }
                            }
                        }
    
                        timesArray.push({
                            id: i + 1,
                            types: [parseInt(recordTimes[i]['timeId'])],
                            typeNames: recordTimes[i]['timeName'],
                            hours: recordTimes[i]['hour'],
                            maximum: recordTimes[i]['maxLimit'],
                            minimum: recordTimes[i]['minLimit'],
                            teachers: teacherIds,
                            teacherNames: names,
                            isSave: true,
                            isEdit: false,
                            isFinish: ''
                        })
                    }
                }
    
                setAddSubjectData(timesArray);
                setSelectedDtlId(id);
            } else if (key === 'delete'){
                setDeleteModal(true);
                setSelectedDtlId(id);
            }
        }
    };

    const handlerSubject = (id, evt) => {
        if(id){
            if(subjects && subjects.length > 0){
                for (let i = 0; i < subjects.length; i++){
                    if(id === subjects[i].id){
                        setSelectedSubject(subjects[i]);
                    }
                }
            }

            let params = {
                school: selectedSchool.id,
                subject: id,
            };

            dispatch(setLoading(true));
            fetchRequest(getTeachersBySubject, 'GET', params)
                .then(response => {
                    if (response.success) {
                        setTeachers(response.data.teachers);
                    } else {
                        message(response?.data?.message, false);
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                });
        }
    };

    const handlerAcademicProgram = (id, evt) => {
        if(id){
            setAcademicProgramId(id);

            let params = {
                school: selectedSchool.id,
                selectedProgram: selectedProgramId,
                academicProgram: id,
            };

            dispatch(setLoading(true));
            fetchRequest(curriculumDownloadProgramDtl, 'GET', params)
                .then(response => {
                    if (response.success) {
                        setProgramList(response?.data?.programs)
                        
                    } else {
                        message(response?.data?.message, false);
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                });
        }
    };

    const handlerProgram = (id, evt) => {
        setProgramId(id);
    };

    const subjectChange = (id, evt) => {
        let selectedSubject = subjects.filter(subject => subject.id == id);
        if(selectedSubject){
            setSubject(selectedSubject)
            handlerSubject(selectedSubject)
        }
    };

    const firstForms = [
        {
            label: t('subject.title') + '*',
            value: record ? record['subjectId'] : null,
            errorMessage: t('errorMessage.selectSubject'),
            required: true,
            type: 'dropdown',
            searchable: true,
            options: subjectOption,
            onChange: subjectChange,
            multiple: false,
            clearable: true
        }
    ];

    const downloadForms = [
        {
            label: t('curriculum.title') + '*',
            value: academicProgramId || null,
            errorMessage: t('errorMessage.selectSubject'),
            required: true,
            type: 'dropdown',
            searchable: true,
            multiple: false,
            options: academicPrograms,
            onChange: handlerAcademicProgram,
            clearable: true
        },
        {
            label: t('curriculum.profession') + '*',
            value: programId || null,
            errorMessage: t('errorMessage.selectSubject'),
            required: true,
            type: 'dropdown',
            searchable: true,
            multiple: false,
            options: programList,
            onChange: handlerProgram,
            clearable: true
        }
    ];
    
    const secondForms = [
        {
            label: t('subject.credit') + '*',
            value: record ? record['credit'] : '',
            errorMessage: t('errorMessage.enterCredit'),
            required: true,
            type: 'text',
        },
        {
            label: t('subject.price') + '*',
            value: record ? record['totalPrice'] : '',
            errorMessage: t('errorMessage.enterAmount'),
            required: true,
            type: 'numberOnly',
        },
        {
            label: t('subject.preSubject'),
            value: record ? record['beforeSubjectId'] : null,
            required: false,
            type: 'dropdown',
            searchable: true,
            options: subjectOption,
            multiple: false,
            clearable: true
        },
        {
            label: t('menu.subjectCategory') + '*',
            value: record ? record['categoryId'] : null,
            errorMessage: t('errorMessage.selectSubjectCategory'),
            required: true,
            type: 'dropdown',
            searchable: true,
            options: categories,
            multiple: false,
            clearable: true
        },
        {
            label: t('subject.type') + '*',
            value: record ? record['subjectTypeId'] : subject ? subject.typeId : null,
            errorMessage: t('errorMessage.selectSubjectType'),
            required: true,
            type: 'dropdown',
            options: subjectTypes,
            multiple: false,
            clearable: true
        },
        {
            label: t('menu.learningType') + '*',
            value: record ? record['typeId'] : null,
            errorMessage: t('errorMessage.selectCurriculumLearningType'),
            required: true,
            type: 'dropdown',
            searchable: true,
            options: pType,
            multiple: false,
            clearable: true
        },
        {
            label: t('subject.ratingAboveC'),
            value: record ? record['isStar'] : false,
            errorMessage: t('errorMessage.selectRelatedDepartment'),
            required: false,
            type: 'checkbox',
        },
        {
            label: t('subject.isPrint'),
            value: record ? record['isPrint'] : false,
            errorMessage: t('errorMessage.selectRelatedDepartment'),
            required: false,
            type: 'checkbox',
        },
        {
            label: t('subject.maximum'),
            value: record ? record['maxLimit'] : '',
            errorMessage: t('errorMessage.enterCode'),
            required: false,
            type: 'text',
        },
        {
            label: t('subject.minimum'),
            value: record ? record['minLimit'] :'',
            errorMessage: t('errorMessage.enterCode'),
            required: false,
            type: 'text',
        },
        {
            label: t('subject.yearOfStudy') + '*',
            value: record ? record['year'] : '',
            errorMessage: t('errorMessage.enterSeasonOfStudy'),
            required: true,
            type: 'numberOnly',
        },
        {
            label: t('subject.seasonOfStudy') + '*',
            value: record ? record['seasonTypeId'] : null,
            errorMessage: t('errorMessage.selectYearOfStudy'),
            required: true,
            type: 'dropdown',
            searchable: true,
            options: seasonTypes,
            multiple: false,
            clearable: true
        },
    ];

    const handleAddSubject = () => {
        dispatch(setLoading(true));
        setRecord(null);
        setSelectedSubject([]);
        setEditAction(false);

        let params = {
            school: selectedSchool.id,
            program: selectedProgramId
        };

        fetchRequest(curriculumAdd, 'GET', params)
            .then(response => {
                if (response.success) {
                    const {data} = response;
                    let subjectList = data.subjects;

                    if(subjectList && subjectList.length > 0)
                    {
                        for(let i = 0; i < subjectList.length; i++){
                            subjectList[i]['name'] = subjectList[i]['code'] + ' - ' + subjectList[i]['name'];
                        }
                    }

                    setSubjects(subjectList);
                    setSubjectOption(response.data.subjects.map(subject => ({value: subject.id, text: subject.name})));
                    setCategories(response.data.categories.map(category => ({value: category.id, text: category.name})));
                    setPType(response.data.pTypes.map(type => ({value: type.id, text: type.name})));
                    setSeasonTypes(response.data.seasonTypes.map(season => ({value: season.id, text: season.name})));
                    setPTimes(response.data.pTimes.map(time => ({value: time.id, text: time.name})));
                    setSubjectTypes(response.data.subjectTypes.map(type => ({value: type.id, text: type.name})));
                    setAddModal(true);
                } else {
                    message(response?.data?.message, false);
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
            });
    };

    const handleDownloadProgramDtl = () => {
        dispatch(setLoading(true));

        let params = {
            school: selectedSchool.id,
            selectedProgram: selectedProgramId,
        };

        fetchRequest(curriculumDownloadProgramDtl, 'GET', params)
            .then(response => {
                if (response.success) {
                    const {data} = response;              
                    setAcademicPrograms(data?.academicPrograms || []);      
                    setDownloadModal(true);
                } else {
                    message(response?.data?.message, false);
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
            });
    };

    const handleEditSubject = (id) => {
        dispatch(setLoading(true));
        setRecord(null);
        setSelectedSubject([]);
        setEditAction(false);

        let params = {
            school: selectedSchool.id,
            program: selectedProgramId,
            id: id
        };

        fetchRequest(curriculumEdit, 'GET', params)
            .then(response => {
                if (response.success) {
                    const {data} = response;
                    let subjectList = data.subjects;

                    if(subjectList && subjectList.length > 0)
                    {
                        for(let i = 0; i < subjectList.length; i++){
                            subjectList[i]['name'] = subjectList[i]['code'] + ' - ' + subjectList[i]['name'];
                        }
                    }

                    setSubjects(subjectList);
                    setSubjectOption(response.data.subjects.map(subject => ({value: subject.id, text: subject.name})));
                    setCategories(response.data.categories.map(category => ({value: category.id, text: category.name})));
                    setPType(response.data.pTypes.map(type => ({value: type.id, text: type.name})));
                    setSeasonTypes(response.data.seasonTypes.map(season => ({value: season.id, text: season.name})));
                    setPTimes(response.data.pTimes.map(time => ({value: time.id, text: time.name})));
                    setSubjectTypes(response.data.subjectTypes.map(type => ({value: type.id, text: type.name})))
                    setAddModal(true);
                } else {
                    message(response?.data?.message, false);
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
            });
    };

    const exitProgramDtl = () => {
        history.push({
            pathname: '/school/program'
        })
    };

    const handleAddModalClose = () => {
        setAddModal(false);
        setAddSubjectData([]);
        setEditId(null);
    };

    const handlerAddSubjectOnAddNewRowButton = () => {
        const cloneAdd = [...addSubjectData];
        const newRow = [
            {
                id: cloneAdd.length > 0 ? cloneAdd[cloneAdd.length - 1].id + 1 : 1,
                types: [],
                typeNames: '',
                hours: '',
                maximum: '',
                minimum: '',
                teachers: [],
                teacherNames: '',
                isSave: true,
                isEdit: false,
                isFinish: ''
            }
        ];
        cloneAdd.push(newRow[0]);

        setAddSubjectData(cloneAdd);
    };

    const handlerAddSubjectOnAddRemoveRowButton = (rowIndex, extraData) => {
        extraData.splice(rowIndex, 1);
        setAddSubjectData(extraData);
    };

    const handlerAddSubjectOnSaveClick = () => {
        const [ firstFormValid, firstFormValue ] = firstFormRef.current.validate();
        const [ secondFormValid, secondFormValues ] = secondFormRef.current.validate();
        let hasErrorTable = true;

        if(tableData && tableData.length > 0){
            for(let i = 0; i < tableData.length; i++){

            }
            hasErrorTable = false;
        } else {
            hasErrorTable = true;
        }

        if(hasErrorTable){
            message(t('errorMessage.selectTeacher'), false);
        }

        if (firstFormValid && secondFormValid && !hasErrorTable) {
            let params = {
                school: selectedSchool.id,
                program: selectedProgramId,
                subject: firstFormValue[0].value,
                credit: secondFormValues[0].value,
                price: secondFormValues[1].value,
                preSubject: secondFormValues[2].value,
                pCategory: secondFormValues[3].value,
                subjectType: secondFormValues[4].value,
                pType: secondFormValues[5].value,
                isStar: secondFormValues[6].value == true ? 1 : 0,
                print: secondFormValues[7].value == true ? 1 : 0,
                max: secondFormValues[8].value,
                min: secondFormValues[9].value,
                year: secondFormValues[10].value,
                seasonType: secondFormValues[11].value,
            };

            let timeRows = [];
            if(tableData && tableData.length > 0){
                for(let i = 0; i < tableData.length; i++){
                    timeRows.push({
                        pTime: tableData[i].types.toString(),
                        hour: tableData[i].hours,
                        max: tableData[i].maximum,
                        min: tableData[i].minimum,
                        teachers: tableData[i].teachers.toString()
                    });

                    params.timeRows = JSON.stringify(timeRows);
                }
            }

            dispatch(setLoading(true));
            fetchRequest(curriculumAdd, 'POST', params)
                .then(response => {
                    if (response.success) {
                        message(response.data.message, true);
                        setAddModal(false);
                        setAddSubjectData([]);
                        setIsSaved(true);
                        setLastAddedSubjectId(response.data.id);
                    } else {
                        message(response.data.message, false)
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                });
        }
    };

    const handlerEditSubjectOnSaveClick = () => {
        const [ firstFormValid, firstFormValue ] = firstFormRef.current.validate();
        const [ secondFormValid, secondFormValues ] = secondFormRef.current.validate();

        if (firstFormValid && secondFormValid) {
            let params = {
                id: selectedDtlId,
                school: selectedSchool.id,
                program: selectedProgramId,
                subject: firstFormValue[0].value,
                credit: secondFormValues[0].value,
                price: secondFormValues[1].value,
                preSubject: secondFormValues[2].value,
                pCategory: secondFormValues[3].value,
                subjectType: secondFormValues[4].value,
                pType: secondFormValues[5].value,
                isStar: secondFormValues[6].value == true ? 1 : 0,
                print: secondFormValues[7].value == true ? 1 : 0,
                max: secondFormValues[8].value,
                min: secondFormValues[9].value,
                year: secondFormValues[10].value,
                seasonType: secondFormValues[11].value,
            };

            let timeRows = [];
            if(tableData && tableData.length > 0){
                for(let i = 0; i < tableData.length; i++){
                    timeRows.push({
                        pTime: tableData[i].types.toString(),
                        hour: tableData[i].hours,
                        max: tableData[i].maximum,
                        min: tableData[i].minimum,
                        teachers: tableData[i].teachers.toString()
                    });

                    params.timeRows = JSON.stringify(timeRows);
                }
            }

            dispatch(setLoading(true));
            fetchRequest(curriculumEdit, 'POST', params)
                .then(response => {
                    if (response.success) {
                        message(response.data.message, true);
                        setAddModal(false);
                        setAddSubjectData([]);
                        setIsSaved(true);
                        setSelectedDtlId(null);

                        let dtlArray = [];
                        let typeList = response.data.types;
                        response.data.pTypes.map(function (type, i) {
                            if(typeList && typeList.length > 0)
                            {
                                for (let i = 0; i < typeList.length; i++)
                                {
                                    if(type.id === typeList[i].typeId){
                                        let details = typeList[i]['details'];
                                        if(details && details.length > 0){
                                            for(let n = 0; n < details.length; n++)
                                            {
                                                let teacherNames = '';
                                                let timeString = '';
                                                let teachers = details[n]['teachers'];
                                                let times = details[n]['times'];
                                                if(teachers && teachers.length > 0){
                                                    for(let t = 0; t < teachers.length; t++)
                                                    {
                                                        if(teachers.length >= t && t === 0) {
                                                            teacherNames = teachers[t]['teacherCode'] + '-' + teachers[t]['firstName'];
                                                        } else {
                                                            teacherNames = teacherNames + '; ' + teachers[t]['teacherCode'] + '-' + teachers[t]['firstName'];
                                                        }
                                                    }
                                                }
                                                if(times && times.length > 0){
                                                    for(let s = 0; s < times.length; s++)
                                                    {
                                                        timeString = timeString + times[s]['timeName'] + '(' + times[s]['hour'] + '); ';
                                                    }
                                                }
                                                details[n]['teacherNames'] = teacherNames;
                                                details[n]['timeString'] = timeString;
                                            }
                                        }

                                        dtlArray['dtl_' + type.id] = typeList[i]['details'];
                                    }
                                }
                            } else {
                                dtlArray['dtl_' + type.id] = []
                            }

                            setDtlList(dtlArray);
                        });
                    } else {
                        message(response.data.message, false)
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                });
        }
    };

    const handlerOnSaveClick = (isPublish) => {
        if(isPublish){
            let dtlKeys = Object.keys(dtlList);
            let hasSubject = false;
            if(dtlKeys && dtlKeys.length > 0){
                for(let i = 0; i < dtlKeys.length; i++){
                    if(dtlList && dtlList[dtlKeys[i]] && (dtlList[dtlKeys[i]]).length > 0){
                        hasSubject = true
                    }
                }
            }

            if(hasSubject){
                setApproveModal(true);
            } else {
                message(t('errorMessage.chooseSubject'), false)
            }
        } else {
            const [ formValid, formValue ] = infoFormRef.current.validate();
            const [ translateValid, translateValues ] = translateFormRef.current.validate();

            if (formValid) {
                const params = {
                    school: selectedSchool.id,
                    id: selectedProgramId,
                    department: formValue?.[0]?.value,
                    type: formValue?.[1]?.value,
                    educationLevel: formValue?.[2]?.value,
                    academicProgram: formValue?.[3]?.value,
                    code: formValue?.[4]?.value,
                    studentCodePrefix: formValue?.[5]?.value,
                    profession: formValue?.[6]?.value,
                    preEducationLevel: formValue?.[7]?.value,
                    // evTemplate: formValue?.[6]?.value,
                    scoreType: formValue?.[8]?.value,
                    approach: formValue?.[9]?.value,
                };

                let translations = [];
                if(languages && languages.length > 0){
                    languages.map(language => {
                        if(language.code !== 'mn'){
                            const nameObject = translateValues.find(val => val.key === 'name' && val.langCode === language.code && val.value);

                            if (nameObject) {
                                translations.push({
                                    langCode: language.code,
                                    name: nameObject.value
                                })
                            }
                        }
                    });

                    params.translations = JSON.stringify(translations);
                }

                dispatch(setLoading(true));
                fetchRequest(programEdit, 'POST', params, false)
                    .then(response => {
                        if (response.success) {
                            message(response.data.message, true)
                        } else {
                            message(response.data.message, false)
                        }
                        dispatch(setLoading(false));
                    })
                    .catch(() => {
                        dispatch(setLoading(false));
                    })
            }
        }
    };

    const handlerOnSubmitDownloadDtl = () => {
        if(typeData && typeData.length > 0){
            for(let t = 0; t < typeData.length; t++){
                if(typeData[t].details && typeData[t].details.length > 0){
                    let typeDetails = typeData[t].details;
                    for(let d = 0; d < typeDetails.length; d++){
                        let params = {
                            school: selectedSchool.id,
                            program: selectedProgramId,
                            subject: typeDetails[d].subjectId,
                            credit: typeDetails[d].credit,
                            price: typeDetails[d].creditPrice && typeDetails[d].credit ? typeDetails[d].creditPrice * typeDetails[d].credit : 0,
                            preSubject: typeDetails[d].beforeSubjectId,
                            pCategory: typeDetails[d].categoryId,
                            subjectType: typeDetails[d].subjectTypeId,
                            pType: typeDetails[d].typeId,
                            isStar: typeDetails[d].isStar == true ? 1 : 0,
                            print: typeDetails[d].isPrint == true ? 1 : 0,
                            max: typeDetails[d].maxLimit,
                            min: typeDetails[d].minLimit,
                            year: typeDetails[d].year,
                            seasonType: typeDetails[d].seasonTypeId,
                        };
                
                        let timeRows = [];
                        let tableData = typeDetails[d].times;
                        if(tableData && tableData.length > 0){
                            for(let i = 0; i < tableData.length; i++){
                                let teacherIds = [];
                                if(typeDetails[d].teachers && typeDetails[d].teachers.length > 0){
                                    let teachers = typeDetails[d].teachers.filter(teacher => teacher.pTimeId == tableData[i].timeId);
                                    if(teachers && teachers.length > 0){
                                        for(let s = 0; s < teachers.length; s++){
                                            teacherIds.push(teachers[s].teacherId)
                                        }
                                    }
                                }

                                timeRows.push({
                                    pTime: [tableData[i].timeId].toString(),
                                    hour: tableData[i].hour,
                                    max: tableData[i].maxLimit,
                                    min: tableData[i].minLimit,
                                    teachers: teacherIds.toString()
                                });

                
                                params.timeRows = JSON.stringify(timeRows);
                            }
                        }

                        dispatch(setLoading(true));
                        fetchRequest(curriculumAdd, 'POST', params)
                            .then(response => {
                                if (response.success) {
                                    const { data } = response;

                                    let cloneDtl = {...dtlList};
                                    let list = cloneDtl['dtl_' + typeData[t].typeId]
                                    if(list && list.length > 0){
                                        for(let j = 0; j < list.length; j++){
                                            if(list[j].id == typeDetails[d].id){
                                                list[j].id = data.id;
                                            }
                                        }
                                    }

                                    setDtlList(cloneDtl)

                                    // message(response.data.message, true);
                                } else {
                                    message(response.data.message, false)
                                }
                                dispatch(setLoading(false));
                            })
                            .catch(() => {
                                dispatch(setLoading(false));
                            });
                    }
                }

                setDownloadSave(false);
            }
        }
    };

    // info -> tab change
    const handlerInfo = () => {
        dispatch(setLoading(true));
        let params = {
            school: selectedSchool.id,
            id: selectedProgramId
        };

        dispatch(setLoading(true));
        fetchRequest(programEdit, 'GET', params)
            .then(response => {
                if (response.success) {
                    const {data} = response;
                    const translations = data.selectedProgram.translations;

                    if(data.departments && data.departments.length){
                        setDepartments(data.departments);
                    }
                    if(data.types && data.types.length){
                        setType(data.types.map(type => ({ value: type.id, text: type.name })));
                    }
                    if(data.educationLevels && data.educationLevels.length){
                        setEducationLevels(data.educationLevels.map(level => ({ value: level.id, text: level.name })));
                    }
                    if(data.approaches && data.approaches.length){
                        setApproaches(data.approaches.map(approach => ({ value: approach.id, text: approach.name })));
                    }
                    if(data.scoreTypes && data.scoreTypes.length){
                        setScoreTypes(data.scoreTypes.map(scoreType => ({value: scoreType.id, text: scoreType.name})));
                    }
                    if(data.templates && data.templates.length){
                        setTemplates(data.templates.map(template => ({value: template.id, text: template.name})));
                    }
                    dispatch(setLoading(false));
                    if(data.selectedProgram){
                        setSelectedProgram(data.selectedProgram);
                        onDepartmentChange(data?.selectedProgram['departmentId'], false);
                    }

                    if (translationFields && translationFields.length > 0 && translations && translations.length > 0) {
                        for (let i = 0; i < translationFields.length; i++)
                        {
                            for (let t = 0; t < translations.length; t++)
                            {
                                if (translationFields[i]['langCode'] === translations[t]['langCode'])
                                {
                                    translationFields[i]['value'] = translations[t]['name'];
                                }
                            }
                        }
                    }

                    translateFormRef?.current?.updateFields && translateFormRef.current.updateFields(translationFields);
                } else {
                    message(response.data.message, false);
                    dispatch(setLoading(false));
                }

            })
            .catch(() => {
                dispatch(setLoading(false));
            });
    };

    const onDepartmentChange = (id, evt) => {
        dispatch(setLoading(true));
        let params = {
            school: selectedSchool.id,
            department: id,
            status: 'publish'
        };

        fetchRequest(academicProgramList, 'GET', params)
            .then(response => {
                if (response.success) {
                    if(response.data.programs){
                        setPrograms(response.data.programs.map(program => ({value: parseInt(program.id), text: program.name })));
                    }
                } else {
                    message(response.data.message, false);
                }
                dispatch(setLoading(false));
            });
    };

    const infoForms = [
        {
            label: t('school.parentDepartment') + '*',
            value: selectedProgram ? selectedProgram['departmentId'] : null,
            errorMessage: t('errorMessage.selectRelatedDepartment'),
            required: true,
            type: 'dropdown',
            options: departments,
            multiple: false,
            name: 'department',
            onChange: onDepartmentChange,
            clearable: true
        },
        {
            label: t('curriculum.curriculumType') + '*',
            value: selectedProgram ? selectedProgram['typeId'] : null,
            errorMessage: t('errorMessage.selectCurriculumType'),
            required: true,
            type: 'dropdown',
            options: types,
            multiple: false,
            clearable: true
        },
        {
            label: t('curriculum.educationLevel') + '*',
            value: selectedProgram ? selectedProgram['educationLevelId'] : null,
            errorMessage: t('errorMessage.selectEducationLevel'),
            required: true,
            type: 'dropdown',
            options: educationLevels,
            multiple: false,
            clearable: true
        },
        {
            label: t('curriculum.name') + '*',
            value: selectedProgram ? selectedProgram['academicId'] : null,
            errorMessage: t('errorMessage.selectCurriculumName'),
            required: true,
            type: 'dropdown',
            options: programs,
            multiple: false,
            name: 'academicProgram'
        },
        {
            key: 'code',
            label: t('common.code') + '*',
            value: selectedProgram ? selectedProgram['code'] : '',
            errorMessage: t('errorMessage.enterCode'),
            required: true,
            type: 'nonCryllic',
            upperCase: true,
        },
        {
            key: 'studentCodePrefix',
            label: t('student.code') + '*',
            value: selectedProgram ? selectedProgram['studentCodePrefix'] : '',
            errorMessage: t('errorMessage.enterStudentCode'),
            required: true,
            type: 'nonCryllic',
            upperCase: true,
        },
        {
            label: t('curriculum.profession') + '*',
            value: selectedProgram ? selectedProgram['name'] : '',
            errorMessage: t('errorMessage.enterProfession'),
            required: true,
            type: 'text',
        },
        {
            label: t('curriculum.previousEducationLevel') + '*',
            value: selectedProgram ? selectedProgram['previousEduLevelId'] : null,
            errorMessage: t('errorMessage.selectPreEducationLevel'),
            required: true,
            type: 'dropdown',
            options: educationLevels,
            multiple: false,
            clearable: true
        },
        // {
        //     label: t('curriculum.scoreTemplate') + '*',
        //     value: selectedProgram && selectedProgram.length > 0 ? selectedProgram[0]['templateId'] : null,
        //     errorMessage: t('errorMessage.selectScoreTemplate'),
        //     required: true,
        //     type: 'dropdown',
        //     options: templates,
        //     multiple: false,
        // },
        {
            label: t('curriculum.scoreType') + '*',
            value: selectedProgram ? selectedProgram['scoreTypeId'] : null,
            errorMessage: t('errorMessage.selectScoreType'),
            required: true,
            type: 'dropdown',
            options: scoreTypes,
            multiple: false,
            clearable: true
        },
        {
            label: t('curriculum.approach') + '*',
            value: selectedProgram ? selectedProgram['approachId'] : null,
            errorMessage: t('errorMessage.selectApproach'),
            required: true,
            type: 'dropdown',
            options: approaches,
            multiple: false,
            clearable: true
        },
    ];

    const handleApproveModalClose = () => {
        setApproveModal(false);
    };

    const handleDownloadModalClose = () => {
        setDownloadModal(false);
    };

    const handlerOnPublishClick = () => {
        const formData = new FormData();

        formData.append('school', selectedSchool.id);
        formData.append('program', selectedProgramId);

        dispatch(setLoading(true));
        fetchRequest(curriculumPublish, 'POST', formData, false, true)
            .then(res => {
                if (res.success) {
                    setApproveModal(false);
                    message(res.data?.message || t('common.success'), true);
                    history.push({
                        pathname: '/school/program',
                    });
                } else {
                    message(res.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            });

    };

    const handlerDownloadSubmit = () => {
        dispatch(setLoading(true));
        let params = {
            school: selectedSchool.id,
            program: programId
        };

        fetchRequest(getCurriculum, 'GET',params)
            .then(response => {
                if (response.success) {
                    setDownloadModal(false);
                    setDownloadSave(true);
                    setPType(response.data.pTypes.map(type => ({value: type.id, text: type.name})));
                    setProgramData(response.data.programData);

                    let dtlArray = [];
                    let typeList = response.data.types;
                    response.data.pTypes.map(function (type, i) {
                        if(typeList && typeList.length > 0){
                            for (let i = 0; i < typeList.length; i++)
                            {
                                if(type.id === typeList[i].typeId){
                                    let details = typeList[i]['details'];
                                    if(details && details.length > 0){
                                        for(let n = 0; n < details.length; n++)
                                        {
                                            let teacherNames = '';
                                            let timeString = '';
                                            let teachers = details[n]['teachers'];
                                            let times = details[n]['times'];
                                            if(teachers && teachers.length > 0){
                                                for(let t = 0; t < teachers.length; t++)
                                                {
                                                    if(teachers.length >= t && t === 0) {
                                                        teacherNames = teachers[t]['teacherCode'] + '-' + teachers[t]['firstName'];
                                                    } else {
                                                        teacherNames = teacherNames + '; ' + teachers[t]['teacherCode'] + '-' + teachers[t]['firstName'];
                                                    }
                                                }
                                            }
                                            if(times && times.length > 0){
                                                for(let s = 0; s < times.length; s++)
                                                {
                                                    timeString = timeString + times[s]['timeName'] + '(' + times[s]['hour'] + '); ';
                                                }
                                            }
                                            details[n]['teacherNames'] = teacherNames;
                                            details[n]['timeString'] = timeString;
                                        }
                                    }

                                    dtlArray['dtl_' + type.id] = typeList[i]['details'];
                                }
                            }
                        } else {
                            dtlArray['dtl_' + type.id] = []
                        }
                    });

                    setTypeData(response?.data?.types || []);
                    setDtlList(dtlArray);
                } else {
                    message(response?.data?.message, false);
                }
                setIsSaved(false);
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
            });
    };

    const handleDeleteModalClose = () => {
        setDeleteModal(false)
    };

    const handlerOnDeleteClick = () => {
        const formData = new FormData();

        formData.append('school', selectedSchool.id);
        formData.append('program', selectedProgramId);
        formData.append('dtl', selectedDtlId);

        dispatch(setLoading(true));
        fetchRequest(curriculumDeLete, 'POST', formData, false, true)
            .then(res => {
                if (res.success) {
                    let dtlArray = [];
                    let typeList = res.data.types;
                    res.data.pTypes.map(function (type, i) {
                        if(typeList && typeList.length > 0){
                            for (let i = 0; i < typeList.length; i++)
                            {
                                if(type.id === typeList[i].typeId){
                                    let details = typeList[i]['details'];
                                    if(details && details.length > 0){
                                        for(let n = 0; n < details.length; n++)
                                        {
                                            let teacherNames = '';
                                            let timeString = '';
                                            let teachers = details[n]['teachers'];
                                            let times = details[n]['times'];
                                            if(teachers && teachers.length > 0){
                                                for(let t = 0; t < teachers.length; t++)
                                                {
                                                    if(teachers.length >= t && t === 0) {
                                                        teacherNames = teachers[t]['teacherCode'] + '-' + teachers[t]['firstName'];
                                                    } else {
                                                        teacherNames = teacherNames + '; ' + teachers[t]['teacherCode'] + '-' + teachers[t]['firstName'];
                                                    }
                                                }
                                            }
                                            if(times && times.length > 0){
                                                for(let s = 0; s < times.length; s++)
                                                {
                                                    timeString = timeString + times[s]['timeName'] + '(' + times[s]['hour'] + '); ';
                                                }
                                            }
                                            details[n]['teacherNames'] = teacherNames;
                                            details[n]['timeString'] = timeString;
                                        }
                                    }

                                    dtlArray['dtl_' + type.id] = typeList[i]['details'];
                                }
                            }
                        } else {
                            dtlArray['dtl_' + type.id] = []
                        }
                    });

                    setDtlList(dtlArray);
                    setDeleteModal(false);
                    message(res.data?.message || t('common.success'), true);
                } else {
                    message(res.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            });
    };

    const onDrop = drop => {
        if(downloadSave){
            message('Та хадгалах үйлдэл хийж дараалал өөрчлөх үйлдэл хийх эрх нээгдэнэ.')
        } else {
            const formData = new FormData();

            formData.append('school', selectedSchool.id);
            formData.append('program', selectedProgramId);
            formData.append('item', drop.itemId);
            formData.append('target', drop.targetId);
    
            dispatch(setLoading(true));
            fetchRequest(curriculumReorder, 'POST', formData, false, true)
                .then(res => {
                    if (res.success) {
                        let dtlArray = [];
                        let typeList = res.data.types;
                        res.data.pTypes.map(function (type, i) {
                            if(typeList && typeList.length > 0){
                                for (let i = 0; i < typeList.length; i++)
                                {
                                    if(type.id === typeList[i].typeId){
                                        let details = typeList[i]['details'];
                                        if(details && details.length > 0){
                                            for(let n = 0; n < details.length; n++)
                                            {
                                                let teacherNames = '';
                                                let timeString = '';
                                                let teachers = details[n]['teachers'];
                                                let times = details[n]['times'];
                                                if(teachers && teachers.length > 0){
                                                    for(let t = 0; t < teachers.length; t++)
                                                    {
                                                        if(teachers.length >= t && t === 0) {
                                                            teacherNames = teachers[t]['teacherCode'] + '-' + teachers[t]['firstName'];
                                                        } else {
                                                            teacherNames = teacherNames + '; ' + teachers[t]['teacherCode'] + '-' + teachers[t]['firstName'];
                                                        }
                                                    }
                                                }
                                                if(times && times.length > 0){
                                                    for(let s = 0; s < times.length; s++)
                                                    {
                                                        timeString = timeString + times[s]['timeName'] + '(' + times[s]['hour'] + '); ';
                                                    }
                                                }
                                                details[n]['teacherNames'] = teacherNames;
                                                details[n]['timeString'] = timeString;
                                            }
                                        }
    
                                        dtlArray['dtl_' + type.id] = typeList[i]['details'];
                                    }
                                }
                            } else {
                                dtlArray['dtl_' + type.id] = []
                            }
                        });
    
                        setDtlList(dtlArray);
                        message(res.data?.message || t('common.success'), true);
                    } else {
                        message(res.data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        }
    };

    return (
        <div className='row'>
            <div className='col-12'>
                <Card className='card-header-with-tab'>
                    <Card.Header>
                        {
                            statuses && statuses.length > 0
                                ?
                                <Nav
                                    variant="tabs"
                                    defaultActiveKey={`#${statuses[1].code}`}
                                    onSelect={(key, e) => handleTab(key, e)}
                                >
                                    {
                                        statuses.map(status => {
                                            return (
                                                <Nav.Item key={'type_' + status.id}>
                                                    <Nav.Link eventKey={`#${status.code}`}>{status.name}</Nav.Link>
                                                </Nav.Item>
                                            )
                                        })
                                    }
                                </Nav>
                                : null
                        }
                    </Card.Header>
                    <Card.Body>
                        {
                            key.toLowerCase() === '#info'
                                ?
                                <>
                                    <div className='col-12'>
                                        <Forms
                                            ref={infoFormRef}
                                            fields={infoForms}
                                        />
                                    </div>
                                    <div className="separator separator-dashed my-7"/>
                                    <div className='col-12'>
                                        <Forms
                                            ref={translateFormRef}
                                            fields={translationFields}
                                        />
                                    </div>
                                </>
                                : null
                        }
                        {
                            key.toLowerCase() === '#plan'
                                ?
                                <>
                                    <ButtonToolbar className='d-inline'>
                                        <Button
                                            onClick={handleAddSubject}
                                            className="btn btn-primary btn-shadow"
                                        >
                                            {t('subject.add')}
                                        </Button>
                                        {
                                            typeData && typeData.length == 0 &&
                                            <Button
                                                onClick={handleDownloadProgramDtl}
                                                className="btn btn-primary btn-shadow ml-3"
                                            >
                                                {t('common.downloadProgramDtl')}
                                            </Button>
                                        }
                                        {
                                            downloadSave &&
                                            <Button
                                                onClick={() => handlerOnSubmitDownloadDtl()}
                                                className="btn btn-success btn-shadow ml-3"
                                            >
                                                {t('common.save')}
                                            </Button> 
                                        }
                                        <Button
                                            className="btn btn-warning btn-shadow ml-3"
                                        >
                                            {t('common.view')}
                                        </Button>
                                    </ButtonToolbar>
                                    <Card className='mt-5'>
                                        <Card.Body>
                                            <div className='col-12 p-0 font-w-bold dark-blue'>
                                                {t('subject.generalAcademicSubject')}
                                            </div>
                                            {
                                                pType && pType.length > 0
                                                ?
                                                    pType.map(type => {
                                                        return(
                                                            <div key={'type_' + type.value}>
                                                                <div className='col-12 p-0 font-w-bold'>
                                                                    {type.text  + ' ' + (t('subject.subjects')).toLowerCase()}
                                                                </div>
                                                                <DTable
                                                                    draggable
                                                                    data={dtlList['dtl_' + type.value]}
                                                                    columns={columns}
                                                                    config={config}
                                                                    remote
                                                                    showOrdering={true}
                                                                    contextMenus={contextMenus}
                                                                    onContextMenuItemClick={handleContextMenuClick}
                                                                    selectMode={'radio'}
                                                                    onDrop={onDrop}
                                                                />
                                                            </div>
                                                        )
                                                    })
                                                : null
                                            }
                                        </Card.Body>
                                    </Card>
                                </>
                                : null
                        }
                    </Card.Body>
                    <Card.Footer className='text-center'>
                        <Button variant="link" onClick={exitProgramDtl}><b>{t('common.exit')}</b></Button>
                        {
                            key === '#info'
                                ?
                                    <Button variant="success btn-shadow ml-3" onClick={() => {handlerOnSaveClick(0)}}>{t('common.save')}</Button>
                                : null
                        }
                        {
                            key === '#plan'
                            ?
                                programData && programData.isPublish
                                ?
                                    null
                                :
                                    <Button variant="publish btn-shadow ml-3" onClick={() => {handlerOnSaveClick(1)}}>{t('action.publish')}</Button>
                            : null
                        }
                    </Card.Footer>
                </Card>
            </div>
            <Modal
                show={addModal}
                onHide={handleAddModalClose}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t('subject.selectSubject')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='col-12 mb-4'>
                        <Forms
                            ref={firstFormRef}
                            fields={firstForms}
                        />
                    </div>
                    <div className='ml-4 mb-4'>
                        {
                            selectedSubject
                                ?
                                    <Form>
                                        <Form.Group className='form-padding-2' as={Row}>
                                            <Form.Label column sm="4" className='text-right'>
                                                {t('subject.code')}
                                            </Form.Label>
                                            <Col sm="8">
                                                <div className='form-label col-form-label font-w-bold'>{selectedSubject.code}</div>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group className='form-padding-2' as={Row}>
                                            <Form.Label column sm="4" className='text-right'>
                                                {t('subject.name')}
                                            </Form.Label>
                                            <Col sm="8">
                                                <div className='form-label col-form-label font-w-bold'>{selectedSubject.name}</div>
                                            </Col>
                                        </Form.Group>
                                        {/* <Form.Group className='form-padding-2' as={Row}>
                                            <Form.Label column sm="4" className='text-right'>
                                                {t('subject.grade')}
                                            </Form.Label>
                                            <Col sm="8">
                                                <div className='form-label col-form-label font-w-bold'>{selectedSubject.typeName}</div>
                                            </Col>
                                        </Form.Group> */}
                                    </Form>
                                : null
                        }
                    </div>
                    <div className='col-12 mb-4'>
                        <Forms
                            ref={secondFormRef}
                            fields={secondForms}
                        />
                    </div>
                    <div className='col-12'>
                        {
                            !editId
                            ?
                                <Button onClick={() => {handlerAddSubjectOnAddNewRowButton()}} variant='btn btn-primary btn-shadow'>{t('common.add')}</Button>
                            : null
                        }
                    </div>
                    <div className='col-12'>
                        <DTable
                            wrapperClassName={'overFlowInherit'}
                            data={tableData}
                            columns={addSubjectColumn}
                            config={config}
                            remote
                            selectMode={'radio'}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {
                        !editAction
                        ?
                            <>
                                <Button variant="link" onClick={handleAddModalClose}><b>{t('common.back')}</b></Button>
                                {
                                    !editId
                                        ?
                                        <Button variant="success btn-shadow ml-3"
                                            onClick={() => {
                                                handlerAddSubjectOnSaveClick()
                                            }}>
                                            {t('common.save')}
                                        </Button>
                                        : null
                                }
                            </>
                        :
                            <>
                                <Button variant="link" onClick={handleAddModalClose}><b>{t('common.back')}</b></Button>
                                {
                                    !editId
                                        ?
                                        <Button variant="success btn-shadow ml-3"
                                                onClick={() => {
                                                    handlerEditSubjectOnSaveClick()
                                                }}>
                                            {t('common.save')}
                                        </Button>
                                        : null
                                }
                            </>
                    }
                </Modal.Footer>
            </Modal>
            <Modal
                show={downloadModal}
                onHide={handleDownloadModalClose}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t('common.downloadProgramDtl')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='col-12 mb-4'>
                        <Forms
                            ref={downloadFormRef}
                            fields={downloadForms}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button 
                        variant="link" 
                        onClick={handleDownloadModalClose}
                    >
                        <b>{t('common.back')}</b>
                    </Button>
                    <Button variant="success btn-shadow ml-3"
                        onClick={() => {
                            handlerDownloadSubmit()
                        }}>
                        {t('common.save')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={approveModal}
                onHide={handleApproveModalClose}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t('action.publish')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {t('warning.approve_confirmation')}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="btn btn-link bolder" onClick={handleApproveModalClose}>{t('common.cancel')}</Button>
                    <Button variant="success btn-shadow" onClick={handlerOnPublishClick}>{t('common.yes')}</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={deleteModal}
                onHide={handleDeleteModalClose}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t('warning.delete')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {t('warning.delete_confirmation')}
                        <br/>
                        <br/>
                        {t('warning.delete_confirmation_description')}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="btn btn-link bolder" onClick={handleDeleteModalClose}>{t('common.cancel')}</Button>
                    <Button variant="success btn-shadow" onClick={handlerOnDeleteClick}>{t('warning.delete')}</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
};

export default ProgramEdit;