import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Forms from "app/modules/Form/Forms";
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import { useDispatch } from 'react-redux';
import { setLoading } from 'redux/action';
import { fetchRequest } from 'utils/fetchRequest';
import message from 'app/modules/message';
import { graduationTemplateCreate } from 'utils/fetchRequest/Urls';

const AddModal = ({
    open = false,
    onClose = () => { },
    setDatas = () => { }
}) => {
    const { t } = useTranslation()

    const ref = useRef();
    const dispatch = useDispatch()
    const [level, setlevel] = useState([])
    const [textValue, setTextValue] = useState(() => EditorState.createEmpty());
    const [textValueEn, setTextValueEn] = useState(() => EditorState.createEmpty());
    const [textValueTr, setTextValueTr] = useState(() => EditorState.createEmpty());

    const fields = useMemo(() => [
        {
            label: t('graduation.templateName'),
            value: '',
            type: 'text',
            required: true,
            key: 'name',
        },
        {
            label: t('curriculum.educationLevel'),
            value: '',
            type: 'dropdown',
            required: true,
            key: 'level',
            options: level
        }
    ], [level])

    const init = () => {
        try {
            dispatch(setLoading(true));
            fetchRequest(graduationTemplateCreate, 'GET')
                .then(res => {
                    if (res.success) {
                        const { data: {
                            educationLevels = []
                        } = {} } = res
                        setlevel(() => {
                            const tempData = []
                            educationLevels.map(obj => {
                                tempData.push({
                                    value: obj.code,
                                    text: obj.name
                                })
                            })
                            return tempData
                        })
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                })
                .catch(() => {
                    message(t('errorMessage.title'))
                })
                .finally(() => dispatch(setLoading(false)))
        } catch (e) {
            message(t('errorMessage.title'))
        }
    }

    const onPublish = () => {
        const [formValid, formValue] = ref.current.validate();

        if (formValid && formValue && formValue.length) {
            dispatch(setLoading(true));

            const mnRaw = convertToRaw(textValue.getCurrentContent());
            const mn = draftToHtml(mnRaw);
            const enRaw = convertToRaw(textValueEn.getCurrentContent());
            const en = draftToHtml(enRaw);
            const trRaw = convertToRaw(textValueTr.getCurrentContent());
            const tr = draftToHtml(trRaw);
            const levelObj = formValue[1]?.options.find(obj => obj.value == formValue[1]?.value)

            const params = {
                name: formValue[0]?.value,
                educationLevelCode: formValue[1]?.value,
                educationLevelName: levelObj.text,
                mn,
                en,
                mb: tr
            }
            fetchRequest(graduationTemplateCreate, 'POST', params)
                .then(res => {
                    if (res.success) {
                        const { data: {
                            templates = [],
                            totalCount = 0
                        } = {} } = res || {}
                        setDatas(templates, totalCount)
                        onClose()
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                })
                .catch(() => {
                    message(t('errorMessage.title'))
                })
                .finally(() => dispatch(setLoading(false)))
        }
    }

    const onDownload = () => {
        const [formValid, formValue] = ref.current.validate();

        if (formValid && formValue && formValue.length) {
            const bodyRaw = convertToRaw(textValue.getCurrentContent());
            const bodyHtml = draftToHtml(bodyRaw);
        }
    }

    useEffect(() => {
        if (open) {
            init()
        }
    }, [open])

    return (
        <Modal
            show={open}
            onHide={onClose}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {t('graduation.template')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Forms
                    ref={ref}
                    fields={fields}
                    fieldChanged={fields}
                />
                <div className='mb-5' />
                <p>Санамж:</p>
                <div className='table-responsive'>
                    <table className='table table-striped table-bordered'>
                        <tbody>
                            <tr>
                                <td>%lastName%</td>
                                <td>Овог</td>
                                <td>%year%</td>
                                <td>Төгсөж буй огноо</td>
                                <td>%decisionMonth%</td>
                                <td>Шийдвэрийн сар</td>
                            </tr>
                            <tr>
                                <td>%firstName%</td>
                                <td>Нэр</td>
                                <td>%orderYear%</td>
                                <td>Тушаалын он</td>
                                <td>%decisionDate%</td>
                                <td>Шийдвэрийн өдөр</td>
                            </tr>
                            <tr>
                                <td>%program%</td>
                                <td>Мэргэжил</td>
                                <td>%orderMonth%</td>
                                <td>Тушаалын сар</td>
                                <td>%orderNumber%</td>
                                <td>Тушаалын дугаар</td>
                            </tr>
                            <tr>
                                <td>%programIndex%</td>
                                <td>Мэргэжлийн индекс</td>
                                <td>%orderDate%</td>
                                <td>Тушаалын өдөр</td>
                                <td>%educationLevel%</td>
                                <td>Боловсролын зэрэг</td>
                            </tr>
                            <tr>
                                <td>%academicProgram%</td>
                                <td>Хөтөлбөр</td>
                                <td>%decisionYear%</td>
                                <td>Шийдвэрийн он</td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <h5 className="font-weight-bold">Монгол</h5>
                <Editor
                    editorState={textValue}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={setTextValue}
                    editorStyle={{ border: "1px solid rgba(0, 0, 0, .2)", fontFamily: 'Arial' }}
                    stripPastedStyles={true}
                    toolbar={{
                        options: ['inline', 'textAlign', 'history'],
                        inline: {
                            options: ['bold', 'italic'],
                        }
                    }}
                />
                <div style={dottedLine} />
                <h5 className="font-weight-bold">English</h5>
                <Editor
                    editorState={textValueEn}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={setTextValueEn}
                    editorStyle={{ border: "1px solid rgba(0, 0, 0, .2)", fontFamily: 'Arial' }}
                    stripPastedStyles={true}
                    toolbar={{
                        options: ['inline', 'textAlign', 'history'],
                        inline: {
                            options: ['bold', 'italic'],
                        }
                    }}
                />
                <div style={dottedLine} />
                <h5 className="font-weight-bold">Монгол бичиг</h5>
                <Editor
                    editorState={textValueTr}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={setTextValueTr}
                    editorStyle={{ border: "1px solid rgba(0, 0, 0, .2)", fontFamily: 'Urga' }}
                    stripPastedStyles={true}
                    toolbar={{
                        options: ['inline', 'textAlign', 'history'],
                        inline: {
                            options: ['bold', 'italic'],
                        }
                    }}
                />
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className="btn btn-link bolder mr-2">
                    {t("common.close").toLocaleUpperCase()}
                </button>
                <Button variant="success btn-shadow mr-2" onClick={onPublish}>
                    {t('action.publish')}
                </Button>
                <Button variant="success btn-shadow" onClick={onDownload}>
                    {t('action.download')}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const dottedLine = {
    borderTop: '1px dashed #000000',
    height: '1px',
    margin: '1rem'
}

export default AddModal
