// import React, { Component } from "react";
// import { DragSource } from "react-dnd";
//
// class Item extends Component {
//     render() {
//         const { shape, connectDragSource} = this.props;
//         return connectDragSource(
//             <div>
//                 please work
//             </div>
//         )
//     }
// }
//
// const spec = {
//     beginDrag(props) {
//         return {
//             shapeId: props.shapes.id
//         };
//     },
//     canDrag(props, monitor) {
//         return true;
//     },
//     endDrag(props, monitor) {
//     }
// };
//
// const collect = (connect, monitor) => ({
//     connectDragSource: connect.dragSource(spec),
//     isDragging: monitor.isDragging()
// });
//
// export default DragSource("shape", spec, collect)(Item);





import React, { useRef, useState } from 'react';
// import Draggable from 'react-draggable';
import { useDrag, useDrop, DndProvider, DragSource, DragSourceConnector, ConnectDragSource, DragSourceMonitor } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { findDOMNode } from "react-dom";

const ItemRaw = ({
    children,
    text,
              }) => {

    const ref = useRef(null);

    const [ count, setCount ] = useState(0);

    const item = document.getElementById('test-id');

    const dom = findDOMNode(item);

    const [ collected, drag ] = useDrag(() => ({
        // type,
        type: 'box',
        item: {

        },
        end: (item, monitor) => {
        },
        collect: (monitor, props) => {
            return {
                isDragging: monitor.isDragging(),
                handlerId: monitor.getHandlerId,
                // connectDragSource: item,

                // connectDragSource: monitor.dragSource(),
            }
        },
    }))

    // if (dom) {
    //     return (<div>
    //         {drag(dom)}
    //     </div>)
    // }


    const func = (el) => {
        if (dom) {
            const node = findDOMNode(dom);
            drag(node)
        }
        // drag(el)
    }

    return (
        <div
            // ref={instance => {
            //     const node = findDOMNode(instance);
            // }}
            ref={func}
            style={{
                borderWidth: 1,
                borderColor: 'blue',
                borderStyle: 'solid'
            }}
        >
            {text}
        </div>
    )
}

// const Item = () => DragSource(
//     'item',
//     {
//         canDrag() {
//             return true;
//         },
//         beginDrag() {
//         }
//     },
//     (connect, monitor) => {
//         return {
//             connectDragSource: connect.dragSource(),
//             isDragging: monitor.isDragging(),
//         }
//     }
// )(ItemRaw);

const collector = (connect, monitor) => {
}

// const Q = DragSource('testType', () => ({
//     beginDrag: () => {
//     }}), collector)(Item);


const DragTest =() => {

    const [ count, setCount ] = useState(0);
    const eventLogger = (e, data) => {
    };

    const handleStart = (q, w, e) => {
    }

    const handleDrag = (q, w, e) => {
    }

    const handleStop = (q, w, e) => {
    }

    const testData = [
        {
            text: 'QQQq',
            id: 1,
        },
        {
            text: 'WWWWWW',
            id: 2,
        },
        {
            text: 'EEEEEEEEE',
            id: 3,
        },
        {
            text: 'RRRRR',
            id: 4,
        },
    ];

    return (
        <div>
            <button onClick={() => setCount(count + 1)}>
                +++
            </button>
            <div id={'test-id'}>
                EXISTING ELEMENT
                <DndProvider backend={HTML5Backend}>
                    <ItemRaw
                        // key={index}
                        text={'qwe'}
                    >
                        {/*{item.text}*/}
                    </ItemRaw>
                    {
                        // testData.map((item, index) => {
                        //     return (
                        //
                        //     )
                        // })
                    }
                </DndProvider>
            </div>

        </div>
        // <Draggable
        //     axis="y"
        //     handle=".handle"
        //     defaultPosition={{x: 0, y: 0}}
        //     position={null}
        //     grid={[25, 25]}
        //     scale={1}
        //     onStart={handleStart}
        //     onDrag={handleDrag}
        //     onStop={handleStop}>
        //     <div>
        //         <div className="handle">Drag from here</div>
        //         <div>This readme is really dragging on...</div>
        //     </div>
        // </Draggable>

    );
}

export default DragTest;



