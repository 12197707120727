import React,{useEffect,useState} from 'react'
import DTable from '../../../modules/DataTable/DTable';
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import { setLoading } from '../../../../redux/action';
import message from '../../../modules/message';
import { fetchRequest } from '../../../../utils/fetchRequest';
import { movementMissing,movementMissingUndo } from '../../../../utils/fetchRequest/Urls';
import ViewModal from './modals/ViewModal';
import UndoModal from './modals/UndoModal';
import ChangeTypeModal from './modals/ChangeTypeModal';

const StudentTable = ({
    nodeId = null,
    schoolId = null,
    tabKey = null
}) => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch()

    const [movements,setMovements] = useState([])
    const [totalCount,setTotalCount] = useState(1)
    const [tempId,setTempId] = useState(null)
    const [viewModal,setViewModal] = useState(false)
    const [viewUndoModal,setViewUndoModal] = useState(false)
    const [viewTypeModal,setViewTypeModal] = useState(false)
    const [tableChangeParams,setTableChangeParams] = useState(null);

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [search, setSearch] = useState('');    

    const contextMenus = [
        {
            key: 'changeType',
            title: t('common.changeType'),
            icon: <i className="fas fa-exchange-alt dt-cm-item-icon-18"/>
        },
        {
            key: 'view',
            title: t('action.view'),
            icon: <i className='las la-eye dt-cm-item-icon' />,
        },
        {
            key: 'edit',
            title: t('common.edit'),
            icon: <i className='las la-edit dt-cm-item-icon'/>
        },
        {
            key: 'undo',
            title: t('action.undo'),
            icon: <i className='las la-backspace dt-cm-item-icon' />,
        },
    ];

    const columns = [
        {
            dataField: 'createdDate',
            text: t('common.date'),
            sort: true,
            formatter(cell,row) {
                return (
                    <div className='msue-dt-clickable-cell' onClick={()=>onDateClick(cell,row)}>
                        {cell.date?.substring(0,19)}
                    </div>
                )
            },
        },
        {
            dataField: "studentCode",
            text: t('student.code'),
            sort: true
        },
        {
            dataField: "firstName",
            text: t('student.name'),
            sort: true
        },
        {
            dataField: "fromClass",
            text: t('movement.out.prevClass'),
            sort: true
        },
        {
            dataField: "createdFirstName",
            text: t('common.createdUser'),
            sort: true
        },
    ];

    const contextMenuClick = (id,key,row) =>{
        if(id && key){
            if(key === 'edit'){
                history.push({
                    pathname: '/movement/missing/add',
                    state: {
                        movement: id,
                        studentCode: row.studentCode
                    }
                })
            }else if(key === 'undo'){
                setViewUndoModal(true)
                setTempId(id)
            }else if(key === 'view'){
                setViewModal(true)
                setTempId(id)
            }else if(key === 'changeType'){
                setViewTypeModal(true)
                setTempId(id)
            }
        }
    }

    const fetchData = params => {
        const bodyParams = {
            ...params,
            school: schoolId,
            level: nodeId,
            movementCause: tabKey,
            page,
            pageSize,
            search
        }
        dispatch(setLoading(true));
        fetchRequest(movementMissing, 'GET', bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setMovements(data?.movements)
                    setTotalCount(data?.totalCount || 1)
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const handleTableChange = params =>{
        setPage(params.page);
        setPageSize(params.pageSize);
        setSearch(params.search);
        setTableChangeParams(params)
    }

    const onDateClick = (cell,row) =>{
        if(row?.id){
            setViewModal(true)
            setTempId(row.id)
        }
    }

    const onDelete = () =>{
        if(tempId){
            const params = {
                school: schoolId,
                movement: tempId
            }
            dispatch(setLoading(true));
            fetchRequest(movementMissingUndo,'POST',params)
                .then(res => {
                    if (res.success) {
                        setViewUndoModal(false)
                        setTempId(null)
                        dispatch(setLoading(false))
                        fetchData(tableChangeParams)
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                        dispatch(setLoading(false))
                    }
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        }
    }

    useEffect(()=>{
        if(tabKey && nodeId){
            fetchData()
        }
    },[tabKey, nodeId, page, pageSize, search])

    return (
        <>
            <DTable
                remote
                config={{}}
                columns={columns}
                data={movements}
                contextMenus={contextMenus}
                onContextMenuItemClick={contextMenuClick}
                onInteraction={handleTableChange}
                totalDataSize={totalCount}
            />
            {
                viewModal
                ? 
                    <ViewModal
                        onClose={()=>{
                            setViewModal(false)
                            setTempId(null)
                        }}
                        movementId={tempId}
                    />
                : null
            }
            {
                viewUndoModal
                ?
                    <UndoModal
                        onClose={()=>{
                            setViewUndoModal(false)
                            setTempId(null)
                        }}
                        onDelete={onDelete}
                    />
                : null
            }
            {
                viewTypeModal
                ?
                    <ChangeTypeModal 
                        onClose={()=>{
                            setViewTypeModal(false)
                            setTempId(null)
                        }}
                        movementId={tempId}
                        fetchList={()=>fetchData(tableChangeParams)}
                    />
                : null
            }
        </>
    )
}

export default StudentTable