import React, { useEffect, useRef, useState} from "react";
import {useTranslation} from 'react-i18next';
import {Link, useHistory} from "react-router-dom";
import {Button, Card, Nav, ButtonToolbar, Modal, Form, Row, Col} from "react-bootstrap";
import TreeView from '../../../../app/modules/TreeView/index';
import DTable from "../../../modules/DataTable/DTable";
import {fetchRequest} from "../../../../utils/fetchRequest";
import {setLoading} from "../../../../redux/action";
import {useDispatch, useSelector} from "react-redux";
import {schoolScholarshipDelete, schoolScholarshipInit} from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import Instruction from "../../../modules/instruction";
import DeleteModal from 'app/modules/DeleteModal';
import AddScholarShip from "./add";

export function SchoolScholarship({
    location: { state: {
        academicProgramId,
        programId,
        student,
        studentCode,
    } = {} } = {},
}) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const [types, setTypes] = useState([]);
    const [selectedTreeId, setSelectedTreeId] = useState(null);
    const [key, setKey] = useState('');
    const [seasons, setSeasons] = useState([]);
    const [selectedType, setSelectedType] = useState(null);
    const [totalCount, setTotalCount] = useState(0);
    const [students, setStudents] = useState([]);
    const [addModal, setAddModal] = useState(false);
    const [autoModal, setAutoModal] = useState(false);
    const [deleteModal,setDeleteModal] = useState(false);
    const [initLoader, setInitLoader] = useState(true);
    const [tempId, setTempId] = useState(null);

    const [selectedAcademicId, setSelectedAcademicId] = useState(academicProgramId || null);
    const [selectedProgramId, setSelectedProgramId] = useState(programId || null);
    const [selectedStudent, setSelectedStudent] = useState(student || []);
    const [selectedStudentCode, setSelectedStudentCode] = useState(studentCode || null);

    const [treeIndex] = useState('school_scholarship_tree_index');
    const [tabIndex] = useState('school_scholarship_tab_index');
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [tableStoreData] = useState('lms_scholarship_table_index');
    const [tableState, setTableState] = useState(JSON.parse(localStorage.getItem(tableStoreData)) ||
        {
            page: 1,
            pageSize: 10,
            search: '',
            sort: 'firstName',
            order: 'asc'
        }
    )

    useEffect(() => {
        if(academicProgramId){
            setAutoModal(true);
        }

        let params = {
            school: schoolId,
            pageSize: 10,
            page: 1,
            search: '',
            sort: [],
            order: 'asc'
        };

        setInitLoader(false);
        init(params)
    }, []);

    const init = (params) => {
        let tabKey = localStorage.getItem(tabIndex);
        let treeId = localStorage.getItem(treeIndex);

        if(tabKey){
            setKey(tabKey);
            params.scholarship = tabKey;
        }
        if(treeId){
            setSelectedTreeId([treeId]);
            params.season =  treeId;
        }

        dispatch(setLoading(true));
        fetchRequest(schoolScholarshipInit, 'GET', params)
            .then(response => {
                dispatch(setLoading(false));
                if (response.success) {
                    const data = response.data;
                    setStudents(data?.configs || []);
                    setTotalCount(data?.totalCount || 0);
                    let discountTypes = data.discounts;
                    if(discountTypes && discountTypes.length > 0){
                        if(tabKey){
                            for(let i = 0; i < discountTypes.length; i++){
                                if(discountTypes[i].id == tabKey){
                                    setKey('#' + discountTypes[i].code);
                                    setSelectedType(discountTypes[i]);
                                }
                            }
                        }
                        setTypes(response.data.discounts);
                    }
                    if(response.data.seasons && response.data.seasons.length){
                        setSeasons(response.data.seasons);
                    }
                } else {
                    message(response.data.message, false);
                }
            })
            .catch(() => {
                dispatch(setLoading(false));
            })
    }

    const handleChange = (idArray) => {
        if(idArray && idArray.length > 0){
            setSelectedTreeId(idArray);
            let params = {
                school: schoolId,
                scholarship: selectedType ? selectedType.id : '',
                season:  idArray[0],
                pageSize: 10,
                page: 1,
                search: '',
                sort: [],
                order: 'asc'
            };

            dispatch(setLoading(true));
            fetchRequest(schoolScholarshipInit, 'GET', params)
                .then(response => {
                    if (response.success) {
                        setStudents(response.data.configs);
                    } else {
                        message(response.data.message, false);
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                });

            localStorage.setItem(treeIndex, idArray[0]);
        }
    };

    const handleTab = (key, e) => {
        let selectedType = [];

        if(types && types.length > 0){
            for(let i = 0; i < types.length; i++){
                if(key === ('#' + types[i]['code'])){
                    selectedType = types[i];
                }
            }
        }

        if(selectedType){
            setKey(selectedType.id);
            let params = {
                school: schoolId,
                season: selectedTreeId && selectedTreeId.length > 0 ? selectedTreeId[0] : null,
                scholarship: selectedType.id,
                pageSize: 10,
                page: 1,
                search: '',
                sort: [],
                order: 'asc'
            };
            dispatch(setLoading(true));
            fetchRequest(schoolScholarshipInit, 'GET', params)
                .then(response => {
                    if (response.success) {
                        const data = response.data;
                        setStudents(data.configs);
                        setTotalCount(data?.totalCount || 0);

                        let cloneData = {
                            page: 1,
                            pageSize: tableState.pageSize,
                            search: tableState.search,
                            sort: tableState.sort,
                            order: tableState.order,
                        };
        
                        setTableState(cloneData)
                    } else {
                        message(response.data.message, false);
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                });

            localStorage.setItem(tabIndex, selectedType.id);
        }
        setSelectedType(selectedType)
    };

    const config = {
        showPagination: true,
        defaultPageOptions: {
            page: tableState?.page || 1,
            sizePerPage: tableState?.pageSize || 10,
            search: tableState?.search || '',
        },
        defaultSort: [{
            dataField: tableState?.sort || 'score',
            order: tableState?.order || 'desc'
        }],
    };

    const columns = [
        {
            dataField: "studentCode",
            text: t('student.code'),
            sort: true,
        },
        {
            dataField: "lastName",
            text: t('person.lastName'),
            sort: true,
        },
        {
            dataField: "firstName",
            text: t('person.firstName'),
            sort: true,
        },
        {
            dataField: "class",
            text: t('curriculum.profession'),
            sort: true,
        },
        {
            dataField: "studentStartYear",
            text: t('student.enrollmentSeason'),
            sort: true,
            headerStyle: () => {
                return {
                    width: 120,
                }
            },
            style: { width: 120 },
        },
        {
            dataField: "startDate",
            text: t('common.startDate'),
            sort: true,
            headerStyle: () => ({
                width: 120,
            }),
            style: { width: 120 },
        },
        {
            dataField: "endDate",
            text: t('common.closeDate'),
            sort: true,
            headerStyle: () => {
                return {
                    width: 120,
                }
            },
            style: { width: 120 },
        },
        {
            dataField: "createdDate",
            text: t('common.createdDate'),
            sort: true,
            headerStyle: () => {
                return {
                    width: 150,
                }
            },
            style: { width: 150 },
        },
        {
            dataField: "createdUser",
            text: t('common.createdUser'),
            sort: true,
        },
    ];

    const contextMenus = [
        {
            key: 'delete',
            title: t('common.delete'),
            icon: <i className='las la-trash-alt dt-cm-item-icon' />,
        }
    ];

    const handleContextMenuClick = (id, key, record) => {
        if(key === 'delete')
        {
            setDeleteModal(true);
            setTempId(id)
        }
    };

    const handleTableInteraction = (object) => {
        if (!initLoader) {
            if (object.search) {
                let cloneData = {
                    page: tableState.search == object.search ? object.page : 1,
                    pageSize: object.pageSize,
                    search: object.search,
                    sort: object.sort,
                    order: object.order,
                    school: schoolId
                };

                setTableState(cloneData)
                localStorage.setItem(tableStoreData, JSON.stringify(cloneData));

                init(cloneData)
            } else {
                if (object.page) {
                    setTableState(object)
                    let cloneData = {
                        page: object.page,
                        pageSize: object.pageSize,
                        search: object.search,
                        sort: object.sort,
                        order: object.order,
                        school: schoolId
                    };

                    localStorage.setItem(tableStoreData, JSON.stringify(cloneData));
                    init(cloneData)
                }
            }
        }
    };

    const handleAddButton = () => {
        setAddModal(true);
    };

    const onCloseAddModal = () => {
        setAddModal(false);
        setAutoModal(false);
        setSelectedAcademicId(null);
        setSelectedProgramId(null);
        setSelectedStudentCode(null);
    };

    const onSubmit = bodyParams => {
        dispatch(setLoading(true));
        fetchRequest(schoolScholarshipInit, 'POST', bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setStudents(data?.configs || []);
                    setTotalCount(data?.totalCount || 0);
                    message(data?.message || t('common.success'), true);
                    history.push({
                        pathname: '/scholarship',
                    });
                    onCloseAddModal();
                } else {
                    message(res?.data?.message || t('errorMessage.title'));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const onModalClose = () => {
        setDeleteModal(false);
        setTempId(null);
    };

    const onDelete = () => {
        const bodyParams = {
            ...tableState,
            season: selectedTreeId && selectedTreeId.length > 0 ? selectedTreeId[0] : null,
            school: schoolId,
            scholarship: selectedType,
            id: tempId
        };

        dispatch(setLoading(true));
        fetchRequest(schoolScholarshipDelete, 'POST', bodyParams)
            .then(res => {
                dispatch(setLoading(false));
                if (res.success) {
                    const { data } = res;
                    setStudents(data?.configs || []);
                    setTotalCount(data?.totalCount || 0);
                    message(res?.data?.message || t('common.success'), true);
                    onModalClose();
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            })
    };

    return (
        <div className='row'>
            <div className='col-4'>
                <Card>
                    <Card.Body>
                        <TreeView
                            treeData={seasons}
                            selectedNodes={selectedTreeId}
                            onSelect={(e, data) => handleChange(e, data)}
                            defaultExpandAll={true}
                        />
                    </Card.Body>
                </Card>
            </div>
            <div className='col-8 pl-0'>
                <Card className='card-header-with-tab'>
                    <Card.Header>
                        {
                            types && types.length > 0
                                ?
                                    <Nav
                                        variant="tabs"
                                        defaultActiveKey={key}
                                        onSelect={(key, e) => handleTab(key, e)}
                                    >
                                        {
                                            types.map(type => {
                                                return (
                                                    <Nav.Item key={'type_' + type.id}>
                                                        <Nav.Link href={`#${type.code}`}>{type.name}</Nav.Link>
                                                    </Nav.Item>
                                                )
                                            })
                                        }
                                    </Nav>
                                : null
                        }
                    </Card.Header>
                    <Card.Body>
                        <div className='ds-flex justify-content-between'>
                            <div>
                                {
                                    selectedTreeId &&
                                    <button className='btn btn-primary' onClick={() => handleAddButton()}>{t('common.add')}</button>
                                }
                                {/*<button className='btn btn-outline-success ml-3' onClick={() => setViewModal(true)}>{t('student.downloadPrevSeason')}</button>*/}
                            </div>
                            <Instruction/>
                        </div>
                        <DTable
                            data={students}
                            columns={columns}
                            config={config}
                            onInteraction={handleTableInteraction}
                            totalDataSize={totalCount}
                            remote
                            showOrdering={true}
                            contextMenus={contextMenus}
                            onContextMenuItemClick={handleContextMenuClick}
                            selectMode={'radio'}
                        />
                    </Card.Body>
                </Card>
            </div>
            {
                (addModal || autoModal) &&
                <AddScholarShip
                    selectedTreeId={selectedTreeId}
                    types={types}
                    onClose={onCloseAddModal}
                    onSubmit={onSubmit}
                    selectedAcademicId={selectedAcademicId}
                    selectedProgramId={selectedProgramId}
                    studentCode={selectedStudentCode}
                    student={selectedStudent}
                />
            }
            {
                deleteModal &&
                <DeleteModal 
                    onClose={onModalClose} 
                    onDelete={onDelete}
                    title={t('common.delete')}
                >
                    {t('warning.delete_confirmation')}
                    <br/>
                    <br/>
                    {t('warning.delete_confirmation_description')}
                </DeleteModal>
            }
        </div>
    )
}

export default SchoolScholarship;