import React, { useEffect, useMemo, useState } from 'react'
import { Button, Card } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import DTable from 'app/modules/DataTable/DTable'
import AddModal from './modals/AddModal'
import EditModal from './modals/EditModal'
import ViewModal from './modals/ViewModal'
import { useDispatch } from 'react-redux'
import { setLoading } from 'redux/action'
import { fetchRequest } from 'utils/fetchRequest'
import { graduationTemplate, graduationTemplateInActive } from 'utils/fetchRequest/Urls'
import message from 'app/modules/message'

const GraduationTemplate = () => {
    const {t} = useTranslation()

    const dispatch = useDispatch()
    
    const [list,setList] = useState([])
    const [totalCount,setTotalCount] = useState(0)

    const [viewModal,setViewModal] = useState(false)
    const [addModal,setAddModal] = useState(false)
    const [tempId,setTempId] = useState(null)
    const [action,setAction] = useState(null)

    const setDatas = (list = [], count = 0) => {
        setList(()=>{
            const tempData = []
            list.map(obj=>{
                let contexKeys = ['view', 'edit', 'delete']
                if(obj.isActive) {
                    contexKeys = ['view', 'inactive']
                }
                tempData.push({
                    ...obj,
                    contextMenuKeys: contexKeys
                })
            })
            return tempData
        })
        setTotalCount(count)
    }

    const init = (tableFilter = {}) => {
        try {
            dispatch(setLoading(true));
            const params = {
                ...tableFilter
            }
            fetchRequest(graduationTemplate, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const { data: {
                            templates = [],
                            totalCount = 0
                        } = {}} = res || {}
                        setDatas(templates,totalCount)
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                })
                .catch(() => {
                    message(t('errorMessage.title'))
                })
                .finally(()=>dispatch(setLoading(false)))
        } catch (e) {
            message(t('errorMessage.title'))
        }
    }

    const setInactive = (id) => {
        try {
            dispatch(setLoading(true));
            const params = {
                id
            }
            fetchRequest(graduationTemplateInActive, 'POST', params)
                .then(res => {
                    if (res.success) {
                        const { data: {
                            templates = [],
                            totalCount = 0
                        } = {}} = res || {}
                        setDatas(templates,totalCount)
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                })
                .catch(() => {
                    message(t('errorMessage.title'))
                })
                .finally(()=>dispatch(setLoading(false)))
        } catch (e) {
            message(t('errorMessage.title'))
        }
    }

    const onContextMenuItemClick = (id,key) => {
        if (key === "delete") {
            setTempId(id)
            setAction('delete')
        } else if(key === 'edit') {
            setTempId(id)
            setAction('edit')
        } else if(key === 'inactive') {
            setInactive(id)
        } else if(key === 'view') {
            setTempId(id)
            setViewModal(true)
        }
    }

    const onInteraction = (params) => {
        init(params)
    }

    useEffect(()=>{
        init()
    },[])

    const tableConfig = useMemo(()=>{
        return { 
            showPagination: true, 
            excelExport: false,
        }
    },[])

    const contextMenus = useMemo(()=>[
        {
            key: 'view',
            title: t('common.view'),
            icon: <i className='las la-search dt-cm-item-icon'/>
        },
        {
          key: "edit",
          title: t("common.edit"),
          icon: <i className="las la-edit dt-cm-item-icon" />,
        },
        {
            key: "inactive",
            title: t("action.setInactive"),
            icon: <i className="las la-eye-slash dt-cm-item-icon" />,
        },
        {
            key: "delete",
            title: t("common.delete"),
            icon: <i className="las la-trash-alt dt-cm-item-icon" />,
        },
    ],[])

    const columns = useMemo(()=>[
        {
            dataField: "isActive",
            text: '',
            headerStyle: () => ({
                width: 50,
            }),
            formatter: (cell) => {
                if(cell === true){
                    return (
                        <div><span><i className="icon-1_5x main-green fa fa-circle"/></span></div>
                    )
                } else {
                    return (
                        <div><span><i className="icon-1_5x text-dark-50 fa fa-circle"/></span></div>
                    )
                }
            }
        },
        {
            dataField: "name",
            text: t('graduation.templateName'),
            sort: true,
        },
        {
            dataField: "createdUser",
            text: t("common.registered"),
            sort: true,
        },
        {
            dataField: "createdDate",
            text: t("common.confirmDate"),
            sort: false,
            formatter: (cell) => {
                if(cell && cell.date) {
                    return cell.date.substring(0,19)
                }
                return null
            }
        }
    ],[])

    return (
        <Card>
            <Card.Body>
                <Button variant="primary btn-shadow align-self-end" onClick={()=>setAddModal(true)}>
                    {t('graduation.templateAdd')}
                </Button>
                <DTable
                    data={list}
                    config={tableConfig}
                    contextMenus={contextMenus}
                    onContextMenuItemClick={onContextMenuItemClick}
                    onInteraction={onInteraction}
                    columns={columns}
                    totalDataSize={totalCount}
                    individualContextMenus={true}
                />
            </Card.Body>

            <AddModal onClose={()=>{
                init()
                setAddModal(false)
            }} open={addModal} setDatas={setDatas}/>
            <EditModal 
                onClose={()=>{
                    init()
                    setAction(null)
                    setTempId(null)
                }} 
                id={tempId} 
                open={!!action} 
                setDatas={setDatas} 
                action={action} 
            />
            <ViewModal onClose={()=>{
                setTempId(null)
                setViewModal(false)
            }} open={viewModal} id={tempId}/>
        </Card>
    )
}

export default GraduationTemplate
