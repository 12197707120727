import React, { useState, useEffect } from 'react';
import TreeView from '../../../../app/modules/TreeView/index';
import { Card, Button, ButtonToolbar, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { fetchRequestAdmin, fetchRequest } from "../../../../utils/fetchRequest";
import { setLoading } from "../../../../redux/action";
import { useDispatch } from "react-redux";
import { getFlatTreeData } from "../../../../utils/utils";
import DeleteModal from "../../../modules/DeleteModal";
import { schoolDelete } from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";

const SchoolComponents = ({}) => {
    const [ selectedTreeId, setSelectedTreeId ] = useState();
    const [ schoolList, setSchoolList ] = useState([]);
    const [ helperModal, setHelperModal ] = useState(false);
    const [ deleteId, setDeleteId ] = useState(null);
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(setLoading(true));

        fetchRequestAdmin('school/init', 'GET', [])
            .then(response => {
                dispatch(setLoading(false));
                if (response.success) {
                    if (response.data.schoolList.length) {
                        setSchoolList(response.data.schoolList);
                    }
                } else {
                    dispatch(setLoading(false));
                }
            });
    }, []);

    const totalSchoolCount = getFlatTreeData(schoolList).length;

    const handleChange = (e, data) => {
        setSelectedTreeId(e)
    };

    const _treeContextMenuClick = (id, key) => {
        if (id && key) {
            if (key === 'EDIT') {
                history.push({
                    pathname: '/structure/component_new',
                    state: {
                        school: id
                    }
                });
            }
            if (key === 'DELETE') {
                setDeleteId(id);
            }
        }
    }

    const handleHelperModal = () => {
        setHelperModal(true)
    }

    const handleHelperModalClose = () => {
        setHelperModal(false)
    }

    const onDelete = () => {
        const params = {
            school: deleteId,
        }
        dispatch(setLoading(true));
        fetchRequest(schoolDelete, 'POST', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setSchoolList(data?.schools || []);
                    setDeleteId(null);
                    message(data?.message || t('common.success'), true);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(err => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }

    return (
        <div className='row'>
            <div className='col-12'>
                <Card>
                    <Card.Body>
                        <div className='col-12 p-0'>
                            <ButtonToolbar className='d-inline'>
                                <Link
                                    to='/structure/component_new'
                                    className="btn btn-primary btn-shadow"
                                >
                                    {t('common.add')}
                                </Link>
                                {/*<Button variant="primary" onClick={addHandler}>{t('add')}</Button>*/}
                            </ButtonToolbar>
                            <Button className='bg-white border-white d-inline btn-hover-less'
                                    onClick={handleHelperModal}>
                                <i className="icon-2x text-danger flaticon-questions-circular-button d-inline"/>
                                <p className='text-danger d-inline'><b>{t('common.helper')}</b></p>
                            </Button>
                        </div>
                        <div className='col-12 p-0'>
                            <p className='mb-0'><b>{t('common.total')}: {totalSchoolCount}</b></p>
                        </div>
                        <TreeView
                            treeData={schoolList}
                            selectedNodes={selectedTreeId}
                            onSelect={(e, data) => handleChange(e, data)}
                            defaultExpandAll={true}
                            contextMenus={{
                                school: [
                                    {
                                        key: 'EDIT',
                                        // iconClassName: 'flaticon-delete-1',
                                        iconClassName: 'las la-edit rc-tree-la-edit',
                                        text: t('common.edit'),
                                    },
                                    {
                                        key: 'DELETE',
                                        iconClassName: 'flaticon2-trash ml3px',
                                        text: t('common.delete'),
                                    }
                                ]
                            }}
                            contextMenuKey={'school'}
                            onContextMenuClick={_treeContextMenuClick}
                        />

                    </Card.Body>
                </Card>
            </div>
            <Modal
                show={helperModal}
                onHide={handleHelperModalClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Modal heading
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Centered Modal</h4>
                    <p>
                        Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
                        dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta
                        ac consectetur ac, vestibulum at eros.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleHelperModalClose}>{t('common.close')}</Button>
                </Modal.Footer>
            </Modal>
            {
                deleteId && (
                    <DeleteModal
                        onClose={() => {
                            setDeleteId(null);
                        }}
                        onDelete={onDelete}
                        title={t('menu.schoolComponent')}
                    >
                        {t('warning.delete_confirmation')}
                        <br/>
                        <br/>
                        {t('warning.delete_confirmation_description')}
                    </DeleteModal>
                )
            }
        </div>
    )
};

export default SchoolComponents;