import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Button, Card, Col, Modal, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Forms from 'app/modules/Form/Forms';
import { useDispatch, useSelector } from 'react-redux';
import { graduationAdditionalCreate } from 'utils/fetchRequest/Urls';
import { setLoading } from 'redux/action';
import { fetchRequest } from 'utils/fetchRequest';
import message from 'app/modules/message';

const AddModal = ({
    open = false,
    onClose = () => {},
    setData = () => {},
    code = null
}) => {
    
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const dispatch = useDispatch()
    const {t} = useTranslation()

    const ref = useRef()
    const refEn = useRef()
    const refTr = useRef()
    const searchRef = useRef()

    const [info,setInfo] = useState({})
    const [fieldChanged,setFieldChanged] = useState(false)

    const init = (code) => {
        try {
            dispatch(setLoading(true));
            const params = {
                code,
                school: schoolId
            }
            fetchRequest(graduationAdditionalCreate, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const { data = {}} = res || {}
                        setInfo({...data})
                        setFieldChanged(prev=>!prev)
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                })
                .catch(() => {
                    message(t('errorMessage.title'))
                })
                .finally(()=>dispatch(setLoading(false)))
        } catch (e) {
            message(t('errorMessage.title'))
        }
    }

    const onSearch = () => {
        if(searchRef?.current && searchRef.current.value) {
            init(searchRef.current.value)
        }
    }

    const onSave = () => {
        const [ formValid, formValue ] = ref.current.validate();
        const [ formValidEn, formValueEn ] = refEn.current.validate();
        const [ formValidTr, formValueTr ] = refTr.current.validate();

        if(formValid && formValidEn && formValidTr && formValue && formValue.length) {
            try {
                dispatch(setLoading(true));
                const params = {
                    id: info.id, 
                    regNo: formValue[0].value, 
                    diplomaNumber: formValue[1].value,
                    school: schoolId,
                    eyesh: formValue[3].value,
                    examSubject: formValue[4].value,
                    previousLevel: formValue[5].value, 
                    topicMn: formValue[2].value,
                    topicEn: formValueEn[0].value,
                    examSubjectEn: formValueEn[1].value,
                    topicMb: formValueTr[0].value,
                    examSubjectMb: formValueTr[1].value
                }
                fetchRequest(graduationAdditionalCreate, 'POST', params)
                    .then(res => {
                        if (res.success) {
                            const { data: {
                                students = [],
                                totalCount = 0
                            } = {}} = res || {}
                            setData(students,totalCount)
                            clearClose()
                        } else {
                            message(res?.data?.message || t('errorMessage.title'))
                        }
                    })
                    .catch(() => {
                        message(t('errorMessage.title'))
                    })
                    .finally(()=>dispatch(setLoading(false)))
            } catch (e) {
                message(t('errorMessage.title'))
            }
        }
    }
    
    const clearClose = () => {
        setInfo({})
        onClose()
    }

    useEffect(()=>{
        if(code) {
            init(code)
        }
    },[code])

    const fields = useMemo(()=>[
        {   
            label: t('graduation.registerNumber'),
            value: info.diplomaRegistrationNo || '',
            type: 'text',
            required: true,
            key: 'registerNumber',
        },
        {   
            label: t('graduation.diplomNumber'),
            value: info.diplomaNumber || '',
            type: 'text',
            required: true,
            key: 'diplomaNumber',
        },
        {   
            label: t('graduation.diplomaTopic'),
            value: info.topicMn || '',
            type: 'textArea',
            required: true,
            key: 'diplomaTopic'
        },
        {   
            label: t('graduation.eyeshScore'),
            value: info.eyeshScore || '',
            type: 'numberOnly',
            required: true,
            key: 'eyeshScore'
        },
        {   
            label: t('graduation.diplomaSubject'),
            value: info.graduationExamSubject || '',
            type: 'text',
            required: true,
            key: 'graduationExamSubject'
        },
        {   
            label: t('graduation.prevLevelScore'),
            value: info.previousLevelScore || '',
            type: 'numberOnly',
            required: true,
            key: 'previousLevelScore'
        }
    ],[info])

    const fieldsEn = useMemo(()=>[
        {   
            label: t('graduation.diplomaTopic') + ' - English (en)',
            value: info.topicEn || '',
            type: 'textArea',
            required: true,
            key: 'diplomaTopic',
        },
        {   
            label: t('graduation.diplomaSubject') + ' - English (en)',
            value: info.graduationExamSubjectEn || '',
            type: 'text',
            required: true,
            key: 'graduationExamSubject',
        }
    ],[info])

    const fieldsTr = useMemo(()=>[
        {   
            label: t('graduation.diplomNumber') + ' - Монгол бичиг (tr)',
            value: info.diplomaNumber || '',
            type: 'text',
            required: true,
            key: 'diplomaNumber',
        },
        {   
            label: t('graduation.diplomaTopic') + ' - Монгол бичиг (tr)',
            value: info.topicMb || '',
            type: 'textArea',
            required: true,
            key: 'diplomaTopic',
            style: {
                fontFamily: 'Urga',
                fontSize: '1.2rem'
            }
        },
        {   
            label: t('graduation.diplomaSubject') + ' - Монгол бичиг (tr)',
            value: info.graduationExamSubjectMb || '',
            type: 'text',
            required: true,
            key: 'graduationExamSubject',
            style: {
                fontFamily: 'Urga',
                fontSize: '1.2rem'
            }
        }
    ],[info])

    const studentInfoRow = useMemo(()=>[
        {
            key: "code",
            text: t("student.code")
        },
        {
            key: "lastName",
            text: t("student.lastName")
        },
        {
            key: "firstName",
            text: t("student.name")
        },
        {
            key: "start",
            text: t("student.enrollmentDate")
        },
        {
            key: "academicProgram",
            text: t("curriculum.title")
        },
        {
            key: "lmProgram",
            text: t("curriculum.profession")
        },
    ],[])

    const renderStudentInfo = () => {
        return studentInfoRow.map(obj=>{
            return <Row key={obj.key} className='d-flex align-items-center'>
                <Col md={6} className='d-flex justify-content-end'>
                    <label>{obj.text}</label>
                </Col>
                <Col md={6}>
                    <span className="font-size-h5 font-weight-bold">{info[obj.key]}</span>
                </Col>
            </Row>
        })
    }

    return (
        <Modal
            show={open}
            onHide={clearClose}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {t('graduation.template')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Card>
                    <Card.Body>
                        <Row>
                            <Col md={6} className='d-flex justify-content-end'>
                                <label>{t('student.code')}</label>
                            </Col>
                            <Col md={6}></Col>
                        </Row>
                        <Row>
                            <Col md={6} className='d-flex justify-content-end'>
                                <input
                                    ref={searchRef}
                                    style={{width: 'auto'}}
                                    type="text"
                                    className='form-control'
                                />
                            </Col>
                            <Col md={6}>
                                <Button variant="warning btn-shadow mr-2" onClick={onSearch}>
                                    {t('action.search')}
                                </Button>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                {
                    info && info.id
                    ?
                        <Card className='mt-4'>
                            <Card.Body>
                                <div className='m-2'>
                                    {renderStudentInfo()}
                                </div>
                                <Forms
                                    ref={ref}
                                    fields={fields}
                                    fieldChanged={fieldChanged}
                                />
                                <div style={dottedLine}/>
                                <Forms
                                    ref={refEn}
                                    fields={fieldsEn}
                                    fieldChanged={fieldChanged}
                                />
                                <div style={dottedLine}/>
                                <Forms
                                    ref={refTr}
                                    fields={fieldsTr}
                                    fieldChanged={fieldChanged}
                                />
                            </Card.Body>
                        </Card>
                    : null
                }
            </Modal.Body>
            <Modal.Footer>
                <button onClick={clearClose} className="btn btn-link bolder mr-2">
                    {t("common.back").toLocaleUpperCase()}
                </button>
                <Button variant="success btn-shadow mr-2" onClick={onSave}>
                    {t('common.save')}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const dottedLine = {
    borderTop: '1px dashed #000000',
    height: '1px',
    margin: '1rem'
}

export default AddModal
