import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Forms from 'app/modules/Form/Forms';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRequest } from 'utils/fetchRequest';
import { setLoading } from 'redux/action';
import { graduationConfig, graduationConfigCreate } from 'utils/fetchRequest/Urls';
import message from 'app/modules/message';

const AddModal = ({
    open = false,
    onClose = () => { }
}) => {

    const { t } = useTranslation()

    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const dispatch = useDispatch();

    const ref = useRef()

    const [seasons, setSeasons] = useState([])
    const [educationLevels, setEducationLevels] = useState([])

    const [homeSignatures, setHomeSignatures] = useState([
        {
            mn: EditorState.createEmpty(),
            en: EditorState.createEmpty(),
            mb: EditorState.createEmpty(),
        }
    ])
    const [additionalSignatures, setAdditionalSignatures] = useState([
        {
            mn: EditorState.createEmpty(),
            en: EditorState.createEmpty(),
            mb: EditorState.createEmpty(),
        }
    ])

    const [textValue, setTextValue] = useState(() => EditorState.createEmpty());
    const [textValueEn, setTextValueEn] = useState(() => EditorState.createEmpty());
    const [textValueTr, setTextValueTr] = useState(() => EditorState.createEmpty());

    const [updateView, setUpdateView] = useState(false)

    let fields = [
        {
            label: t('subject.season'),
            value: '',
            type: 'dropdown',
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            key: 'season',
            options: seasons
        },
        {
            label: t('curriculum.educationLevel'),
            value: '',
            type: 'dropdown',
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            key: 'educationLevel',
            options: educationLevels
        },
        {
            label: t('graduation.config.orderDate'),
            value: '',
            type: 'date',
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            key: 'orderDate'
        },
        {
            label: t('graduation.config.decisionDate'),
            value: '',
            type: 'date',
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            key: 'decisionDate'
        }
    ];
    // const fields = useMemo(() => [

    // ], [seasons, educationLevels])

    const init = () => {
        dispatch(setLoading(true));
        const params = {
            school: schoolId
        }
        fetchRequest(graduationConfigCreate, 'GET', params)
            .then(res => {
                if (res?.success) {
                    setSeasons(res?.data?.seasons)
                    setEducationLevels(res?.data?.educationLevels.map(el => {
                        return {
                            value: el?.key,
                            text: el?.title
                        }
                    }))
                    const updateFields = [
                        {
                            label: t('subject.season'),
                            value: '',
                            type: 'dropdown',
                            inputWidth: 200,
                            labelWidth: 300,
                            required: true,
                            key: 'season',
                            options: res?.data?.seasons
                        },
                        {
                            label: t('curriculum.educationLevel'),
                            value: '',
                            type: 'dropdown',
                            inputWidth: 200,
                            labelWidth: 300,
                            required: true,
                            key: 'educationLevel',
                            options: res?.data?.educationLevels.map(el => {
                                return {
                                    value: el?.key,
                                    text: el?.title
                                }
                            })
                        },
                        {
                            label: t('graduation.config.orderDate'),
                            value: '',
                            type: 'date',
                            inputWidth: 200,
                            labelWidth: 300,
                            required: true,
                            key: 'orderDate'
                        },
                        {
                            label: t('graduation.config.decisionDate'),
                            value: '',
                            type: 'date',
                            inputWidth: 200,
                            labelWidth: 300,
                            required: true,
                            key: 'decisionDate'
                        }
                    ]

                    ref?.current?.updateFields && ref?.current.updateFields(updateFields);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(e => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            });
    }

    useEffect(() => {
        init()
    }, [])

    const onSave = () => {
        const [formValid, formValue, values] = ref.current.validate();

        if (formValid && formValue && formValue.length) {
            const mnOrderNumberRaw = convertToRaw(textValue.getCurrentContent());
            const orderNumberMn = draftToHtml(mnOrderNumberRaw);

            const enOrderNumberRaw = convertToRaw(textValueEn.getCurrentContent());
            const orderNumberEn = draftToHtml(enOrderNumberRaw);

            const mbOrderNumberRaw = convertToRaw(textValueTr.getCurrentContent());
            const orderNumberMb = draftToHtml(mbOrderNumberRaw);

            const homeSignatureList = []
            const additionalSignatureList = []
            for (let hs = 0; hs < homeSignatures.length; hs++) {
                const hsObj = homeSignatures[hs];

                const mnHomeSignatureRaw = convertToRaw(hsObj.mn.getCurrentContent());
                const homeSignatureMn = draftToHtml(mnHomeSignatureRaw);
                const enHomeSignatureRaw = convertToRaw(hsObj.en.getCurrentContent());
                const homeSignatureEn = draftToHtml(enHomeSignatureRaw);
                const mbHomeSignatureRaw = convertToRaw(hsObj.mb.getCurrentContent());
                const homeSignatureMb = draftToHtml(mbHomeSignatureRaw);

                homeSignatureList.push({
                    mn: homeSignatureMn,
                    en: homeSignatureEn,
                    mb: homeSignatureMb
                })
            }
            for (let hs = 0; hs < additionalSignatures.length; hs++) {
                const hsObj = additionalSignatures[hs];

                const mnAddSignatureRaw = convertToRaw(hsObj.mn.getCurrentContent());
                const enAddSignatureRaw = convertToRaw(hsObj.en.getCurrentContent());
                const mbAddSignatureRaw = convertToRaw(hsObj.mb.getCurrentContent());

                additionalSignatureList.push({
                    mn: mnAddSignatureRaw,
                    en: enAddSignatureRaw,
                    mb: mbAddSignatureRaw
                })
            }

            dispatch(setLoading(true));
            const params = {
                school: schoolId,
                ...values,
                orderNumberMn: orderNumberMn,
                orderNumberEn: orderNumberEn,
                orderNumberMb: orderNumberMb,
                homeSignatures: homeSignatureList,
                additionalSignatures: additionalSignatureList
            }
            fetchRequest(graduationConfigCreate, 'POST', params)
                .then(res => {
                    if (res?.success) {
                        onClose()
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(e => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                });
        }
    }

    const onHomeEditorChange = (index, langKey, data) => {
        const clone = [...homeSignatures]
        for (let i = 0; i < clone.length; i++) {
            if (i === index) {
                clone[i][langKey] = data
            }
        }
        setHomeSignatures(clone)
        setUpdateView(!updateView)
    }

    const onAdditionalEditorChange = (index, langKey, data) => {
        const clone = [...additionalSignatures]
        for (let i = 0; i < clone.length; i++) {
            if (i === index) {
                clone[i][langKey] = data
            }
        }
        setAdditionalSignatures(clone)
        setUpdateView(!updateView)
    }

    const addHomeSignature = () => {
        const clone = [...homeSignatures]
        clone.push({
            mn: EditorState.createEmpty(),
            en: EditorState.createEmpty(),
            mb: EditorState.createEmpty(),
        })

        setHomeSignatures(clone)
        setUpdateView(!updateView)
    }

    const addAdditionalSignature = () => {
        const clone = [...additionalSignatures]
        clone.push({
            mn: EditorState.createEmpty(),
            en: EditorState.createEmpty(),
            mb: EditorState.createEmpty(),
        })

        setAdditionalSignatures(clone)
        setUpdateView(!updateView)
    }

    const removeHomeSignature = (index) => {
        const clone = [...homeSignatures]
        clone.splice(index, 1);
        setHomeSignatures(clone)
        setUpdateView(!updateView)
    }

    const removeAdditionalSignature = (index) => {
        const clone = [...additionalSignatures]
        clone.splice(index, 1);

        setAdditionalSignatures(clone)
        setUpdateView(!updateView)
    }

    return (
        <Modal
            show={open}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {t('graduation.config.title')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Forms
                    ref={ref}
                    fields={fields}
                />
                <label className="font-weight-bold">Тушаалын дугаар</label>
                <div style={dottedLine} />
                <div style={{ display: 'inline-flex', width: '100%' }}>
                    <label style={{ width: 160 }}>Монгол:</label>
                    <div style={{ display: 'inline-block', width: '100%', marginLeft: 20 }}>
                        <Editor
                            editorState={textValue}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={setTextValue}
                            editorStyle={{ border: "1px solid rgba(0, 0, 0, .2)", fontFamily: 'Arial' }}
                            toolbar={{
                                options: []
                            }}
                            toolbarStyle={{
                                display: 'none'
                            }}
                        />
                    </div>
                </div>
                <div style={{ display: 'inline-flex', width: '100%', marginTop: 5 }}>
                    <label style={{ width: 160 }}>Англи:</label>
                    <div style={{ display: 'inline-block', width: '100%', marginLeft: 20 }}>
                        <Editor
                            editorState={textValueEn}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={setTextValueEn}
                            editorStyle={{ border: "1px solid rgba(0, 0, 0, .2)", fontFamily: 'Arial' }}
                            toolbar={{
                                options: []
                            }}
                            toolbarStyle={{
                                display: 'none'
                            }}
                        />
                    </div>
                </div>
                <div style={{ display: 'inline-flex', width: '100%', marginTop: 5 }}>
                    <label style={{ width: 160 }}>Монгол бичиг:</label>
                    <div style={{ display: 'inline-block', width: '100%', marginLeft: 20 }}>
                        <Editor
                            editorState={textValueTr}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={setTextValueTr}
                            editorStyle={{ border: "1px solid rgba(0, 0, 0, .2)", fontFamily: 'Urga' }}
                            toolbar={{
                                options: []
                            }}
                            toolbarStyle={{
                                display: 'none'
                            }}
                        />
                    </div>
                </div>
                <div style={dottedLine} />
                <label className="font-weight-bold">Гарын үсэг (Нүүр хуудас)</label>
                <div style={dottedLine} />
                {
                    homeSignatures && homeSignatures.map((homeSignatureObj, hs) => {
                        return <div key={'home_sign_' + hs} style={{ display: 'inline-flex', width: '100%', marginBottom: 10 }}>
                            <div style={{ width: '100%' }}>
                                <div style={{ display: 'inline-flex', width: '100%', marginTop: 5 }}>
                                    <label style={{ width: 160 }}>Монгол:</label>
                                    <div style={{ display: 'inline-block', width: '100%', marginLeft: 20 }}>
                                        <Editor
                                            editorState={homeSignatureObj?.mn}
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            onEditorStateChange={(data) => onHomeEditorChange(hs, 'mn', data)}
                                            editorStyle={{ border: "1px solid rgba(0, 0, 0, .2)", fontFamily: 'Arial' }}
                                            toolbar={{
                                                options: []
                                            }}
                                            toolbarStyle={{
                                                display: 'none'
                                            }}
                                        />
                                    </div>
                                </div>
                                <div style={{ display: 'inline-flex', width: '100%', marginTop: 5 }}>
                                    <label style={{ width: 160 }}>Англи:</label>
                                    <div style={{ display: 'inline-block', width: '100%', marginLeft: 20 }}>
                                        <Editor
                                            editorState={homeSignatureObj?.en}
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            onEditorStateChange={(data) => onHomeEditorChange(hs, 'en', data)}
                                            editorStyle={{ border: "1px solid rgba(0, 0, 0, .2)", fontFamily: 'Arial' }}
                                            toolbar={{
                                                options: []
                                            }}
                                            toolbarStyle={{
                                                display: 'none'
                                            }}
                                        />
                                    </div>
                                </div>
                                <div style={{ display: 'inline-flex', width: '100%', marginTop: 5 }}>
                                    <label style={{ width: 160 }}>Монгол бичиг:</label>
                                    <div style={{ display: 'inline-block', width: '100%', marginLeft: 20 }}>
                                        <Editor
                                            editorState={homeSignatureObj?.mb}
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            onEditorStateChange={(data) => onHomeEditorChange(hs, 'mb', data)}
                                            editorStyle={{ border: "1px solid rgba(0, 0, 0, .2)", fontFamily: 'Urga' }}
                                            toolbar={{
                                                options: []
                                            }}
                                            toolbarStyle={{
                                                display: 'none'
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            {
                                hs > 0 && <Button variant="danger btn-shadow ml-2" onClick={() => removeHomeSignature(hs)}>
                                    {t('common.remove')}
                                </Button>
                            }
                        </div>
                    })
                }
                <div className='text-center' style={{ marginTop: 10 }}>
                    <Button variant="primary btn-shadow" onClick={addHomeSignature}>
                        {t('graduation.signatureAdd')}
                    </Button>
                </div>
                <div style={dottedLine} />
                <label className="font-weight-bold">Гарын үсэг (Хавсралт)</label>
                <div style={dottedLine} />

                {
                    additionalSignatures && additionalSignatures.map((additionalSignatureObj, hs) => {
                        return <div key={'home_sign_' + hs} style={{ display: 'inline-flex', width: '100%', marginBottom: 10 }}>
                            <div style={{ width: '100%' }}>
                                <div style={{ display: 'inline-flex', width: '100%', marginTop: 5 }}>
                                    <label style={{ width: 160 }}>Монгол:</label>
                                    <div style={{ display: 'inline-block', width: '100%', marginLeft: 20 }}>
                                        <Editor
                                            editorState={additionalSignatureObj?.mn}
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            onEditorStateChange={(data) => onAdditionalEditorChange(hs, 'mn', data)}
                                            editorStyle={{ border: "1px solid rgba(0, 0, 0, .2)", fontFamily: 'Arial' }}
                                            toolbar={{
                                                options: []
                                            }}
                                            toolbarStyle={{
                                                display: 'none'
                                            }}
                                        />
                                    </div>
                                </div>
                                <div style={{ display: 'inline-flex', width: '100%', marginTop: 5 }}>
                                    <label style={{ width: 160 }}>Англи:</label>
                                    <div style={{ display: 'inline-block', width: '100%', marginLeft: 20 }}>
                                        <Editor
                                            editorState={additionalSignatureObj?.en}
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            onEditorStateChange={(data) => onAdditionalEditorChange(hs, 'en', data)}
                                            editorStyle={{ border: "1px solid rgba(0, 0, 0, .2)", fontFamily: 'Arial' }}
                                            toolbar={{
                                                options: []
                                            }}
                                            toolbarStyle={{
                                                display: 'none'
                                            }}
                                        />
                                    </div>
                                </div>
                                <div style={{ display: 'inline-flex', width: '100%', marginTop: 5 }}>
                                    <label style={{ width: 160 }}>Монгол бичиг:</label>
                                    <div style={{ display: 'inline-block', width: '100%', marginLeft: 20 }}>
                                        <Editor
                                            editorState={additionalSignatureObj?.mb}
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            onEditorStateChange={(data) => onAdditionalEditorChange(hs, 'mb', data)}
                                            editorStyle={{ border: "1px solid rgba(0, 0, 0, .2)", fontFamily: 'Urga' }}
                                            toolbar={{
                                                options: []
                                            }}
                                            toolbarStyle={{
                                                display: 'none'
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            {
                                hs > 0 && <Button variant="danger btn-shadow ml-2" onClick={() => removeAdditionalSignature(hs)}>
                                    {t('common.remove')}
                                </Button>
                            }
                        </div>
                    })
                }
                <div className='text-center' style={{ marginTop: 10 }}>
                    <Button variant="primary btn-shadow mr-2" onClick={addAdditionalSignature}>
                        {t('graduation.signatureAdd')}
                    </Button>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className="btn btn-link bolder mr-2">
                    {t("common.back").toLocaleUpperCase()}
                </button>
                <Button variant="success btn-shadow mr-2" onClick={onSave}>
                    {t('common.save')}
                </Button>
            </Modal.Footer>
        </Modal >
    )
}

const dottedLine = {
    borderTop: '1px dashed #000000',
    height: '1px',
    margin: '1rem'
}

export default AddModal
