import React, { forwardRef, useState } from "react";
import Picker from "react-datepicker";
import mn from "../DatePicker/mn";
import format from "date-fns/format";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";

const CustomIcon = forwardRef(({ onClick, className = "" }, ref) => {
  return (
    <button
      ref={ref}
      className={className}
      onClick={onClick}
      style={{ border: "1px solid #919bc0" }}
    >
      <i className="text-dark-50 flaticon2-calendar-9" />
    </button>
  );
});

const DateTimePickerRange = ({
  onChange,
  firstPlaceHolder,
  lastPlaceHolder,
  selectedStartDate,
  selectedEndDate,
  ...rest
}) => {
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);
  const [mainStartDate, setMainStartDate] = useState(undefined);
  const [mainEndDate, setMainEndDate] = useState(undefined);
  const [selectionComplete, toggleSelectionComplete] = useState(false);
  const [isMain, setIsMain] = useState(false);
  const [isStart, setIsStart] = useState(false);
  const [isEnd, setIsEnd] = useState(false);
  const [array] = useState([
    {
      startDate: null,
      endDate: null,
    },
  ]);

  const handleDateChange = (date) => {
    if (!selectionComplete && !mainStartDate) {
      setMainStartDate(date);
      toggleSelectionComplete(false);
      return;
    }

    if (!selectionComplete && mainStartDate && !mainEndDate) {
      setMainEndDate(date);
      toggleSelectionComplete(true);
      return;
    }

    if (selectionComplete && mainStartDate && mainEndDate) {
      setMainStartDate(date);
      setMainEndDate(undefined);
      toggleSelectionComplete(false);
      return;
    }
  };

  const handleSelect = (date) => {
    handleDateChange(date);
    setIsMain(true);
  };

  const handleStartDateChange = (date, e) => {
    if (date) {
      setIsStart(true);
      setStartDate(date);
      setMainStartDate(date);
      array[0].startDate = format(date, "yyyy-MM-dd HH:mm");
      onChange(array);
    }
  };

  const handleEndDateChange = (date, e) => {
    if (date) {
      setIsEnd(true);
      setEndDate(date);
      setMainEndDate(date);
      array[0].endDate = format(date, "yyyy-MM-dd HH:mm");
      onChange(array);
    }
  };

  const handleMainCalendarClose = () => {
    setIsMain(false);
    if (mainStartDate) {
      setStartDate(mainStartDate);
      array[0].startDate = format(mainStartDate, "yyyy-MM-dd HH:mm");
    }

    if (mainEndDate) {
      setEndDate(mainEndDate);
      array[0].endDate = format(mainEndDate, "yyyy-MM-dd HH:mm");
    }

    onChange(array);
  };

  const handleFirstCalendarClose = () => {
    setIsStart(false);
  };

  const handleLastCalendarClose = () => {
    setIsEnd(false);
  };

  const handleCalendarOpen = () => {
    setStartDate(undefined);
    setEndDate(undefined);
    setMainStartDate(undefined);
    setMainEndDate(undefined);
    toggleSelectionComplete(false);
    array[0].startDate = null;
    array[0].endDate = null;
    onChange(array);
  };

  return (
    <div className="date-picker-range-container">
      <DatePicker
        locale={mn}
        selected={
          startDate
            ? new Date(startDate)
            : selectedStartDate
            ? new Date(selectedStartDate)
            : null
        }
        onChange={handleStartDateChange}
        startDate={startDate}
        showTimeSelect
        timeFormat="HH:mm"
        timeIntervals={15}
        timeCaption="Цаг"
        dateFormat={"yyyy-MM-dd HH:mm"}
        disabled={isMain ? isMain : isEnd ? isEnd : false}
        className={"first-datepicker"}
        onCalendarClose={handleFirstCalendarClose}
        placeholderText={firstPlaceHolder ? firstPlaceHolder : ""}
        {...rest}
      />
      <DatePicker
        locale={mn}
        onChange={handleDateChange}
        onSelect={handleSelect}
        startDate={mainStartDate}
        endDate={mainEndDate}
        selectsRange={true}
        selectsStart={true}
        selectsEnd={true}
        customInput={<CustomIcon className={""} />}
        shouldCloseOnSelect={false}
        onCalendarOpen={handleCalendarOpen}
        onCalendarClose={handleMainCalendarClose}
        {...rest}
      />
      <DatePicker
        locale={mn}
        selected={
          endDate
            ? new Date(endDate)
            : selectedEndDate
            ? new Date(selectedEndDate)
            : null
        }
        onChange={handleEndDateChange}
        endDate={endDate}
        showTimeSelect
        timeFormat="HH:mm"
        timeIntervals={15}
        timeCaption="Цаг"
        dateFormat={"yyyy-MM-dd HH:mm"}
        disabled={isMain ? isMain : isStart ? isStart : false}
        className={"last-datepicker"}
        onCalendarClose={handleLastCalendarClose}
        shouldCloseOnSelect={false}
        placeholderText={lastPlaceHolder ? lastPlaceHolder : ""}
        {...rest}
      />
    </div>
  );
};

export default DateTimePickerRange;
