import React, { useRef, useEffect } from 'react';
import { Button, Modal } from "react-bootstrap";
import Forms from "../../../modules/Form/Forms";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import message from "../../../modules/message";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import { editRole } from "../../../../utils/fetchRequest/Urls";

const RoleAdd = ({
    onClose,
    onSubmit,
    roleId,
    systemRoles = [],
}) => {
    const { t } = useTranslation();
    const forms = useRef();
    const dispatch = useDispatch();
    const translationForms = useRef();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const parentId = useSelector(state => state?.selectedSchool?.parentId || null);

    useEffect(() => {
        forms?.current?.updateFields && forms.current.updateFields(fields);
    }, [ systemRoles ]);

    useEffect(() => {
        if (roleId) {
            const params = {
                role: roleId,
                school: schoolId,
            };
            dispatch(setLoading(true));
            fetchRequest(editRole, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res;
                        if (data?.role) {
                            const role = data.role;
                            const translations = role.translations;
                            if (role) {
                                forms?.current?.updateFields && forms.current.updateFields([
                                    // {
                                    //     label: t('common.code'),
                                    //     value: role.code,
                                    //     type: 'text',
                                    //     inputWidth: 200,
                                    //     labelWidth: 300,
                                    //     required: true,
                                    //     key: 'code',
                                    // },
                                    {
                                        label: t('system.systemRole'),
                                        value: role.name,
                                        type: 'text',
                                        inputWidth: 200,
                                        labelWidth: 300,
                                        required: true,
                                        key: 'name',
                                    },
                                    {
                                        label: 'Sys role',
                                        value: role.sysRoleId,
                                        type: 'dropdown',
                                        options: systemRoles,
                                        inputWidth: 200,
                                        labelWidth: 300,
                                        required: true,
                                        key: 'sysRole',
                                        clearable: true
                                    },
                                ]);
                            }
                        }
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'));
                })
        }
    }, [ roleId ]);

    const fields = [
        // {
        //     label: t('common.code'),
        //     value: '',
        //     type: 'text',
        //     inputWidth: 200,
        //     labelWidth: 300,
        //     required: true,
        //     key: 'code',
        // },
        {
            label: t('system.systemRole'),
            value: '',
            type: 'text',
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            key: 'name',
        },
        {
            label: t('system.menuRole'),
            value: '',
            type: 'dropdown',
            options: systemRoles,
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            key: 'sysRole',
            clearable: true
        },
        {
            label: t('school.isOtherSchool'),
            type: 'checkbox',
            value: true,
            inputWidth: 300,
            labelWidth: 300,
            hidden: parentId == null ? false : true,
            key: 'isSchool',
        },
    ];

    const onSaveClick = () => {
        const [ isValid, states, values ] = forms.current.validate();
        if (isValid) {
            if(values.isSchool){
                values.isSchool = 1
            } else {
                values.isSchool = 0
            }
            const params = {
                ...values,
            };
            onSubmit(params);
        }
    };

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('system.systemRole').toUpperCase()}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Forms
                    ref={forms}
                    fields={fields}
                />
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className='btn btn-link bolder'>{t('common.back')}</button>
                <Button variant='success btn-shadow' onClick={onSaveClick}>{t('common.save')}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default RoleAdd;