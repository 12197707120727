import React, { useState } from "react";
import styles from './questionTypes.module.scss';
import cloneDeep from 'lodash/cloneDeep'
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { TextareaAutosize } from "@material-ui/core";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";

const Wrapper = (props) => {
    return (
        <DndProvider backend={HTML5Backend}>
            <TextInput {...props}/>
        </DndProvider>
    )
}

const TextInput = ({
    onChange,
    value
    
}) => {

    const { t } = useTranslation();
    const handleAnswerChange = (answer) => {
        let clone = cloneDeep(value);
        clone = answer;
        onChange(clone);
    }

    return (
        <div style={{ width: 'auto', display: 'none' }}>
            {
                
                <div
                    id={0}
                    key={0}
                    className={clsx(`order-row-${0}`, styles.rowContainer)}
                >
                    <TextareaAutosize
                        className={clsx('form-control', styles.textArea)}
                        placeholder={t('exam.premiseResponse')}
                        rowsMin={4}
                        cols={4}
                        value={value}
                        onChange={(e) => handleAnswerChange(e.target.value)}
                    />
                </div>
            }
        </div>
    );
};

export default Wrapper;