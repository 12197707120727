import React, {useState, useEffect, useRef} from 'react';
import {Card, Button, ButtonToolbar, Modal} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {Link, useHistory} from "react-router-dom";
import Forms from "../../../modules/Form/Forms";
import {setLoading} from "../../../../redux/action";
import {fetchRequest} from "../../../../utils/fetchRequest";
import {teacherAdd} from "../../../../utils/fetchRequest/Urls";
import {useDispatch, useSelector} from "react-redux";
import i18n from "i18next";
import message from "../../../modules/message";
import {getFlatTreeData} from "../../../../utils/utils";

const TeacherNew = ({
    location: { state: { departmentId = null } = {} } = {},
}) => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const selectedSchool = useSelector(state => state?.selectedSchool || []);
    const languages = useSelector(state => state?.languages?.languages || []);
    const [helperModal, setHelperModal] = useState(false);
    const [departments, setDepartments] = useState([]);
    const [selectedDepartmentId, setSelectedDepartmentId] = useState(departmentId);
    const [positions, setPositions] = useState([]);
    const [countries, setCountries] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [statusList, setStatusList] = useState([]);
    const [selectedPositionId, setSelectedPositionId] = useState(null);
    const gender = [
        {text: 'Эрэгтэй', value: 'm'},
        {text: 'Эмэгтэй', value: 'f'},
    ];

    const form = useRef();
    const translateForm = useRef();

    useEffect(() => {
        dispatch(setLoading(true));
        let params = {
            school: selectedSchool.id
        };

        fetchRequest(teacherAdd, 'GET', params)
            .then(response => {
                if (response.success) {
                    let departmentData = response.data.departments;

                    if(departmentData && departmentData.length){
                        setDepartments(departmentData.map(depart => ({ value: depart.id, text: depart.title, positions: depart.positions })));

                        if(departmentId)
                        {
                            for(let i = 0; i < departmentData.length; i++)
                            {
                                if(departmentId === departmentData[i]['id'])
                                {
                                    setPositions(departmentData[i].positions.map(position => ({ value: position.id, text: position.name, code: position.code })));
                                }
                            }
                        }
                    }
                    if(response.data.countries && response.data.countries.length){
                        setCountries(response.data.countries.map(country => ({ value: country.id, text: country.name })));
                    }
                    if(response.data.subjects && response.data.subjects.length){
                        setSubjects(response.data.subjects.map(subject => ({ value: subject.id, text: subject.name })));
                    }
                    if(response.data.statusList && response.data.statusList.length){
                        setStatusList(response.data.statusList.map(status => ({value: status.id, text: status.name})));
                    }
                } else {
                    message(response.data.message, false);
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
            })
    }, []);

    useEffect(() => {
        form?.current?.updateFields && form.current.updateFields(forms);
    }, [departments, positions, countries, subjects, statusList]);

    const onDepartmentChange = (id) => {
        if(id)
        {
            for(let i = 0; i < departments.length; i++)
            {
                if(id === departments[i]['value'])
                {
                    setPositions(departments[i].positions.map(position => ({ value: position.id, text: position.name, code: position.code })));
                    break;
                } else {
                    setPositions([]);
                }
            }

            setSelectedDepartmentId(id)
        }
    };

    const onPositionChange = (id) => {
        if(id)
        {
            for(let i = 0; i < positions.length; i++)
            {
                if(id == positions[i]['value'])
                {
                    if((positions[i]['code']).toLowerCase() === 'teacher'){
                        let isHereSubject = false;
                        if(forms && forms.length > 0){
                            for(let i = 0; i < forms.length; i++){
                                if(forms[i]['name'] === 'subject'){
                                    isHereSubject = true;
                                }
                                if(forms[i]['name'] === 'position'){
                                    forms[i]['value'] = id;
                                }
                            }
                        }

                        if(!isHereSubject){
                            forms.push({
                                name: 'subject',
                                label: t('common.subject') + '*',
                                value: null,
                                errorMessage: t('errorMessage.selectSubject'),
                                required: true,
                                type: 'dropdown',
                                options: subjects,
                                multiple: true,
                                searchable: true
                            });
                        }
                    } else {
                        if(forms && forms.length > 0){
                            for(let i = 0; i < forms.length; i++){
                                if(forms[i]['name'] === 'position'){
                                    forms[i]['value'] = id;
                                }
                                if(forms[i]['name'] === 'subject'){
                                    forms.splice(i, 1);
                                }
                            }
                        }
                    }
                    form?.current?.updateFields && form.current.updateFields(forms);
                }
            }
            setSelectedPositionId(id);
        }
        // check if ids
        // if(ids && ids.length > 0)
        // {
        //     for(let i = 0; i < positions.length; i++)
        //     {
        //         for(let d = 0; d < ids.length; d++){
        //             if(ids[d] === positions[i]['value'])
        //             {
        //                 if((positions[i]['code']).toLowerCase() === 'teacher'){
        //                     let isHereSubject = false;
        //                     if(forms && forms.length > 0){
        //                         for(let i = 0; i < forms.length; i++){
        //                             if(forms[i]['name'] === 'subject'){
        //                                 isHereSubject = true;
        //                             }
        //                             if(forms[i]['name'] === 'position'){
        //                                 forms[i]['value'] = ids;
        //                             }
        //                         }
        //                     }

        //                     if(!isHereSubject){
        //                         forms.push({
        //                             name: 'subject',
        //                             label: t('common.subject') + '*',
        //                             value: null,
        //                             errorMessage: t('errorMessage.selectSubject'),
        //                             required: true,
        //                             type: 'dropdown',
        //                             options: subjects,
        //                             multiple: true,
        //                             searchable: true
        //                         });
        //                     }
        //                 } else {
        //                     if(forms && forms.length > 0){
        //                         for(let i = 0; i < forms.length; i++){
        //                             if(forms[i]['name'] === 'position'){
        //                                 forms[i]['value'] = ids;
        //                             }
        //                             if(forms[i]['name'] === 'subject'){
        //                                 forms.splice(i, 1);
        //                             }
        //                         }
        //                     }
        //                 }
        //                 form?.current?.updateFields && form.current.updateFields(forms);
        //             }
        //         }
        //     }
        //     setSelectedPositionId(ids);
        // }
    };

    const forms = [
        {
            name: 'department',
            label: t('school.parentDepartment') + '*',
            value: selectedDepartmentId,
            errorMessage: t('errorMessage.selectRelatedDepartment'),
            required: true,
            type: 'dropdown',
            options: departments,
            multiple: false,
            searchable: true,
            onChange: onDepartmentChange,
            clearable: true
        },
        {
            name: 'position',
            label: t('menu.roles') + '*',
            value: selectedPositionId,
            errorMessage: t('errorMessage.selectRoles'),
            required: true,
            type: 'dropdown',
            options: positions,
            multiple: false,
            searchable: true,
            onChange: onPositionChange,
            clearable: true
        },
        {
            name: 'status',
            label: t('common.status') + '*',
            value: null,
            errorMessage: t('errorMessage.selectStatus'),
            required: true,
            type: 'dropdown',
            options: statusList,
            multiple: false,
            searchable: true,
            clearable: true
        },
        {
            name: 'country',
            label: t('common.country') + '*',
            value: null,
            errorMessage: t('errorMessage.selectCountry'),
            required: true,
            type: 'dropdown',
            options: countries,
            multiple: false,
            searchable: true,
            clearable: true
        },
        {
            key: 'code',
            name: 'code',
            label: t('common.code') + '*',
            value: '',
            errorMessage: t('errorMessage.enterCode'),
            required: true,
            type: 'nonCryllic',
            upperCase: true,
        },
        {
            name: 'familyName',
            label: t('common.familyName') + '*',
            value: '',
            errorMessage: t('errorMessage.enterFamilyName'),
            required: true,
            type: 'text',
        },
        {
            name: 'lastName',
            label: t('common.lastName') + '*',
            value: '',
            errorMessage: t('errorMessage.enterLastName'),
            required: true,
            type: 'text',
        },
        {
            name: 'firstName',
            label: t('common.firstName') + '*',
            value: '',
            errorMessage: t('errorMessage.enterFirstName'),
            required: true,
            type: 'text',
        },
        {
            name: 'registerNumber',
            label: t('common.registerNumber') + '*',
            value: '',
            errorMessage: t('errorMessage.enterRegisterNumber'),
            required: true,
            type: 'text',
        },
        {
            name: 'gender',
            label: t('common.gender') + '*',
            value: null,
            errorMessage: t('errorMessage.selectGender'),
            required: true,
            type: 'dropdown',
            options: gender,
            multiple: false,
            clearable: true
        },
        {
            name: 'phoneNumber',
            label: t('common.phoneNumber'),
            value: '',
            errorMessage: t('errorMessage.enterPhoneNumber'),
            required: true,
            type: 'phone',
        },
        {
            name: 'email',
            label: t('common.email') + '*',
            value: '',
            errorMessage: t('errorMessage.enterEmail'),
            required: true,
            type: 'email',
        },
        {
            name: 'employmentDate',
            label: t('common.employmentDate') + '*',
            value: '',
            errorMessage: t('errorMessage.selectEmploymentDate'),
            required: true,
            type: 'date',
        },
        {
            name: 'password',
            label: t('common.password') + '*',
            value: '',
            errorMessage: t('errorMessage.enterPassword'),
            required: true,
            type: 'text',
        },
    ];

    const handleHelperModal = () => {
        setHelperModal(true)
    };

    const handleHelperModalClose = () => {
        setHelperModal(false)
    };

    const getFormTranslations = () => {
        const array = [];
        for (const language of languages) {
            if(language.code !== 'mn'){
                array.push(
                    {
                        label: `${t('common.familyName')} - ${language.name} (${language.code})`,
                        value: '',
                        required: false,
                        type: 'text',
                        langCode: language.code,
                        key: 'familyName',
                    },
                    {
                        label: `${t('common.lastName')} - ${language.name} (${language.code})`,
                        value: '',
                        required: false,
                        type: 'text',
                        langCode: language.code,
                        key: 'lastName',
                    },
                    {
                        label: `${t('common.firstName')} - ${language.name} (${language.code})`,
                        value: '',
                        required: false,
                        type: 'text',
                        langCode: language.code,
                        key: 'firstName',
                    },
                    // {
                    //     label: `${i18n.store.data[language.code].translation.common.registerNumber} - ${language.name}(${language.code})`,
                    //     value: '',
                    //     required: false,
                    //     type: 'text',
                    //     langCode: language.code,
                    //     key: 'registerNumber',
                    // }
                );
            }
        }
        return array;
    };

    const handlerOnSaveClick = () => {
        const [ formValid, formValue ] = form.current.validate();
        const [ translateValid, translateValues ] = translateForm.current.validate();

        if (formValid) {
            const params = {
                school: selectedSchool.id,
                code: formValue?.[4]?.value,
                familyName: formValue?.[5]?.value,
                lastName: formValue?.[6]?.value,
                firstName: formValue?.[7]?.value,
                registerNumber: formValue?.[8]?.value,
                gender: formValue?.[9]?.value,
                phone: formValue?.[10]?.value,
                email: formValue?.[11]?.value,
                startDate: formValue?.[12]?.value,
                password: formValue?.[13]?.value,
                subjects: formValue[14]?.value
            };

            if (formValue?.[0]?.value) {
                params.department = formValue[0].value;
            }
            if(formValue?.[1]?.value){
                params.depPosition = formValue[1].value;
            }
            if(formValue?.[2]?.value){
                params.status = formValue[2].value;
            }
            if(formValue?.[3]?.value){
                params.country = formValue[3].value;
            }

            let translations = [];
            if(languages && languages.length > 0){
                languages.map(language => {
                    if(language.code !== 'mn'){
                        const familyNameObject = translateValues.find(val => val.key === 'familyName' && val.langCode === language.code && val.value);
                        const lastNameObject = translateValues.find(val => val.key === 'lastName' && val.langCode === language.code && val.value);
                        const firstNameObject = translateValues.find(val => val.key === 'firstName' && val.langCode === language.code && val.value);
                        // const registerNumberObject = translateValues.find(val => val.key === 'registerNumber' && val.langCode === language.code && val.value);

                        translations.push({
                            langCode: language.code,
                            familyName: familyNameObject?.value,
                            lastName: lastNameObject?.value,
                            firstName: firstNameObject?.value,
                            // registerNumber: registerNumberObject.value
                        })
                    }
                });

                params.translations = JSON.stringify(translations);
            }

            dispatch(setLoading(true));
            fetchRequest(teacherAdd, 'POST', params, false)
                .then(response => {
                    if (response.success) {
                        history.push('/school/teacher')
                    } else {
                        message(response.data.message, false)
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                })
        }
    };

    const getChildrenRecursively = (array, id) => {
        const children = [];

        for (const item of array)
        {
            if(item.children && item.children.length > 0){
                let test = getChildrenRecursively(item.children, item.key);

                children.push({
                    ...item,
                });
            } else {
                children.push({
                    ...item,
                });
            }
        }

        return children;
    };

    return (
        <div className='row'>
            <div className='col-12'>
                <Card>
                    <Card.Body>
                        <div className='col-12 text-right'>
                            <Button className='bg-white border-white d-inline btn-hover-less' onClick={handleHelperModal}>
                                <i className="icon-2x text-danger flaticon-questions-circular-button d-inline"/>
                                <p className='text-danger d-inline'><b>{t('common.helper')}</b></p>
                            </Button>
                        </div>
                        <div className='col-12'>
                            <Forms
                                ref={form}
                                fields={forms}
                            />
                        </div>
                        <div className="separator separator-dashed my-7"/>
                        <div className='col-12'>
                            <Forms
                                ref={translateForm}
                                fields={getFormTranslations()}
                            />
                        </div>
                    </Card.Body>
                    <Card.Footer>
                        <div className='col-12 text-center'>
                            <Link
                                to='/school/teacher'
                                className="btn btn-link bolder"
                            >
                                {t('common.back')}
                            </Link>
                            <Button variant="success btn-shadow" onClick={handlerOnSaveClick}>{t('common.save')}</Button>
                        </div>
                    </Card.Footer>
                </Card>
            </div>
            <Modal
                show={helperModal}
                onHide={handleHelperModalClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Modal heading
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Centered Modal</h4>
                    <p>
                        Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
                        dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta
                        ac consectetur ac, vestibulum at eros.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleHelperModalClose}>{t('common.close')}</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
};

export default TeacherNew;