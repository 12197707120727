/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import {ContentRoute, Layout} from "../_metronic/layout";
import BasePage from "./BasePage";
import {AuthPage, ForgotPassword, LogoutPage} from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import Loader from "./modules/loader";
import {ToastContainer} from "react-toastify";
import {StudentQrCode} from "./pages/officer/description/check";

export function Routes() {
    // const {isAuthorized} = useSelector(
    //     ({auth}) => ({
    //         isAuthorized: auth.user != null,
    //     }),
    //     shallowEqual
    // );

    let isAuthorized = false;
    const auth = useSelector(({ auth }) => auth);
    if (
        auth.authToken
    ) {
        isAuthorized = true;
    }

    const loading = useSelector(state => {
        return state.loading
    });

    return (
        <>
            {
                loading && (<Loader/>)
            }
            <ToastContainer
                position="top-right"
                autoClose={4000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Switch>
                <ContentRoute path="/recover" component={ForgotPassword} />
                <ContentRoute path="/student/qrcode/check/" component={StudentQrCode} />

                {!isAuthorized ? (
                    /*Render auth page when user at `/auth` and not authorized.*/
                    <Route>
                        <AuthPage />
                    </Route>
                ) : (
                    /*Otherwise redirect to root page (`/`)*/
                    <Redirect from="/auth" to="/"/>
                )}

                <Route path="/logout" component={LogoutPage}/>
                <Route path="/error" component={ErrorsPage}/>

                {!isAuthorized ? (
                    /*Redirect to `/auth` when user is not authorized*/
                    <Redirect to="/auth/login"/>
                ) : (
                    <Layout>
                        <BasePage/>
                    </Layout>
                )}
            </Switch>
        </>
    );
}
