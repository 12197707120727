import React, { useState } from "react";
import styles from './questionTypes.module.scss';
import cloneDeep from 'lodash/cloneDeep'
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import DragHandle from "../../../../modules/DataTable/DragHandle";

const Wrapper = (props) => {
    return (
        <DndProvider backend={HTML5Backend}>
            <Order {...props}/>
        </DndProvider>
    )
}

const Order = ({
    onChange,
    value
}) => {

    const { t } = useTranslation();

    const [ isDragging, setIsDragging ] = useState(false);

    const handleAnswerChange = (index, answer) => {
        const clone = cloneDeep(value);
        clone[index].answer = answer;
        onChange(clone);
    }

    const handleScoreChange = (index, score) => {
        // const re = /[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)/;
        // const re = /^[+-]?\d+(\.\d+)?$/

        // if (score === '' || re.test(score)) {
        const clone = cloneDeep(value);
        clone[index].score = score;
        onChange(clone);
        // }
    }

    const addRow = () => {
        const clone = cloneDeep(value);
        clone.push({
            answer: '',
            score: '',
        })
        onChange(clone);
    }

    const removeRow = index => {
        const clone = cloneDeep(value);
        clone.splice(index, 1);
        onChange(clone);
    }

    const onRowDragStart = (q) => {
        setIsDragging(true);
    }

    const onDrop = ({ itemId: index, position, targetId: targetIndex }) => {
        setIsDragging(false);
        if (index !== targetIndex) {
            const clone = cloneDeep(value);
            const temp = clone[index];
            const target = position === 'top' ? targetIndex : targetIndex + 1;
            clone.splice(target, 0, temp);
            if (index > target) {
                clone.splice(index + 1, 1);
            } else {
                clone.splice(index, 1);
            }
            onChange(clone);
        }
    }

    return (
        <div style={{ width: 'min-content', border: isDragging ? '1px solid #c9c9c9' : 'none' }}>
            {
                value.map((row, index) => {
                    return (
                        <div
                            id={`${index}-dragrow`}
                            key={index}
                            className={clsx(`order-row-${index}`, styles.rowContainer)}
                        >
                            <DragHandle
                                rowClass={`order-row-${index}`}
                                data={{ ...row, id: index }}
                                onStart={onRowDragStart}
                                onDrop={onDrop}
                            />
                            <input
                                className={clsx('form-control', styles.premiseInput)}
                                onChange={(e) => handleAnswerChange(index, e.target.value)}
                                value={row.answer}
                                placeholder={t('exam.premiseResponse')}
                            />
                            <input
                                className={clsx('form-control mr-5', styles.scoreInput)}
                                onChange={(e) => handleScoreChange(index, e.target.value)}
                                value={row.score}
                                placeholder={t('exam.score')}
                            />
                            {
                                index > 0
                                    ?   (
                                        <button className='btn btn-danger row-action-button' onClick={() => removeRow(index)}>
                                            <i className='las la-times' style={{ fontSize: 20 }} />
                                        </button>
                                    )
                                    :   <div style={{ width: 36 }}/>
                            }
                        </div>
                    )
                })
            }
            <div className='text-right pr-1'>
                <button className='btn btn-primary row-action-button mr-1' onClick={addRow}>
                    <i className='las la-plus' style={{ fontSize: 20 }} />
                </button>
            </div>
        </div>
    );
};

export default Wrapper;