import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import Forms from "../../../modules/Form/Forms";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import message from "../../../modules/message";
import {
  candidateCommon,
  psychologyAdd,
  psychologyDesc,
  psychologyEdit,
} from "../../../../utils/fetchRequest/Urls";
const PsychologyEdit = ({ onClose, canId, id, onFinish }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [listt, setListt] = useState([]);
  const [statusId, setStatusId] = useState("");
  const [candidateProgramId, setCandidateProgramId] = useState(null);
  const [candidateId, setCandidateId] = useState(null);
  const [statuses, setStatuses] = useState(null);
  const [candidatePsychology, setCandidatePsychology] = useState([]);
  const [candidateDetail, setCandidateDetail] = useState([]);
  const [selectedEnrollId, setSelectedEnrollId] = useState(null);
  const [selectedDescription, setSelectedDescription] = useState("");
  const schoolId = useSelector((state) => state?.selectedSchool?.id || null);
  const form = useRef();
  const forms1 = useRef();

  const languages = useSelector(
    (state) => state?.languages?.languages || []
  ).filter((lang) => lang.code !== "mn");

  useEffect(() => {
    getData();
    candidateData();
    getDescription();
  }, []);

  useEffect(() => {
    form?.current?.updateFields && form.current.updateFields(fields);
  }, [candidateId, canId, selectedEnrollId, listt]);

  useEffect(() => {
    forms1?.current?.updateFields && forms1.current.updateFields(fields1);
  }, [selectedDescription, statusId, statuses, candidatePsychology]);

  const getDescription = () => {
    const params = {
      candidate: canId,
      school: schoolId,
    };
    dispatch(setLoading(true));
    fetchRequest(psychologyDesc, "GET", params)
      .then((res) => {
        if (res.success) {
          const { data } = res;
          setSelectedDescription(data.candidatePsychology.description);
          setStatusId(Number(data.candidatePsychology.status_id));
          if (data?.candidatePsychology) {
            setCandidatePsychology(candidatePsychology);
          }
        } else {
          message(res?.data?.message || t("errorMessage.title"));
        }
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        message(t("errorMessage.title"));
      });
  };
  const getData = () => {
    const params = {
      school: schoolId,
    };
    dispatch(setLoading(true));
    fetchRequest(psychologyAdd, "GET", params)
      .then((res) => {
        if (res.success) {
          const { data } = res;
          const candidateOptions = [];
          data?.list.forEach((candidate) => {
            candidateOptions.push({
              value: Number(candidate.id),
              text: candidate.id,
            });
          });
          setListt(candidateOptions);
          if (data?.statuses) {
            const statusOptions = [];
            const statuses = data.statuses;
            statuses.forEach((element) => {
              statusOptions.push({ value: element.id, text: element.name });
            });
            setStatuses(statusOptions);
          }
        } else {
          message(res?.data?.message || t("errorMessage.title"));
        }
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        message(t("errorMessage.title"));
      });
  };

  const onCandidateChange = (id) => {
    if (id) {
      setCandidateId(id);
    }
    setLoading(true);
  };

  const onStatusChange = (id) => {
    if (id) {
      setStatusId(id);
    }
  };
  const fields = [
    {
      label: "Элсэгч",
      type: "dropdown",
      value: selectedEnrollId ?? null,
      required: false,
      labelWidth: 200,
      inputWidth: 200,
      disabled: true,
      key: "id",
      options: listt,
      disabled: true,
    },
  ];
  const fields1 = [
    {
      label: "Төлөв",
      type: "dropdown",
      upperCase: true,
      value: statusId ?? null,
      key: "name",
      required: false,
      options: statuses,
      labelWidth: 200,
      inputWidth: 200,
      onChange: onStatusChange,
    },
    {
      label: "Тайлбар",
      type: "textArea",
      upperCase: true,
      value: selectedDescription ?? null,
      required: false,
      labelWidth: 200,
      inputWidth: 200,
      key: "description",
    },
  ];
  const candidateData = () => {
    setLoading(true);

    const params = {
      candidate: canId,
      school: schoolId,
    };
    dispatch(setLoading(true));
    fetchRequest(candidateCommon, "GET", params)
      .then((res) => {
        if (res.success) {
          const { data } = res;
          setSelectedEnrollId(Number(data.candidateDetail.id));
          if (data?.candidateDetail) {
            setCandidateDetail(data.candidateDetail);
            setCandidateProgramId(data.candidateDetail.candidateProgramId);
          }
        } else {
          message(res?.data?.message || t("errorMessage.title"));
        }
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        message(t("errorMessage.title"));
      });
  };
  const onSaveClick = () => {
    const [formValid, formValues] = forms1.current.validate();
    if (formValid) {
      const params = {
        candidatePsychology: id,
        school: schoolId,
        candidateProgramId: candidateProgramId,
        status: formValues?.[0]?.value,
        description: formValues?.[1]?.value,
      };
      dispatch(setLoading(true));
      fetchRequest(psychologyEdit, "POST", params)
        .then((res) => {
          if (res.success) {
            message(res?.data?.message, true);

            onClose();
            onFinish();
          } else {
            message(res.data.message, false);
          }
          dispatch(setLoading(false));
        })
        .catch(() => {
          dispatch(setLoading(false));
          message(t("errorMessage.title"));
        });
    }
  };
  return (
    <Modal
      show={true}
      onHide={onClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div style={{ color: "#4a70ae", fontSize: "1.1rem" }}>
            {/* {t("setting.teacherStatus").toUpperCase()} */}
            Ярилцлага Засах
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div>
            <Forms ref={form} fields={fields} />
          </div>
          <div>
            <div className="row mt-4">
              <div className="d-flex justify-content-end col-3 ml-10">
                <img
                  src={
                    candidateDetail.avatar === null
                      ? "/media/users/avatar_male.png"
                      : toAbsoluteUrl("/" + candidateDetail.avatar)
                  }
                  alt={toAbsoluteUrl("/" + candidateDetail.avatar)}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = "/media/users/avatar_male.png";
                  }}
                  width={80}
                  height={100}
                />
              </div>
              <ul className="col-7">
                <li className="d-flex justify-content-center row">
                  <div className="col">
                    <p className="text-primary d-flex justify-content-end">
                      Бүртгэлийн дугаар
                    </p>
                  </div>
                  <div className="col justify-content-end">
                    <span className="text-dark">
                      {candidateDetail.requestNo}
                    </span>
                  </div>
                </li>
                <li className="d-flex justify-content-center row">
                  <div className="col">
                    <p className="text-primary d-flex justify-content-end">
                      ЭЕШ бүртгэлийн дугаар
                    </p>
                  </div>
                  <div className="col justify-content-end">
                    <span className="text-dark">{candidateDetail.butName}</span>
                  </div>
                </li>
                <li className="d-flex justify-content-center row">
                  <div className="col">
                    <p className="text-primary d-flex justify-content-end">
                      Овог
                    </p>
                  </div>
                  <div className="col justify-content-end">
                    <span className="text-dark">
                      {candidateDetail.lastName}
                    </span>
                  </div>
                </li>
                <li className="d-flex justify-content-center row">
                  <div className="col">
                    <p className="text-primary d-flex justify-content-end">
                      Нэр
                    </p>
                  </div>
                  <div className="col justify-content-end">
                    <span className="text-dark">
                      {candidateDetail.firstName}
                    </span>
                  </div>
                </li>
                <li className="d-flex justify-content-center row">
                  <div className="col">
                    <p className="text-primary d-flex justify-content-end">
                      Регистрийн дугаар
                    </p>
                  </div>
                  <div className="col justify-content-end">
                    <span className="text-dark">
                      {candidateDetail.registrationNumber}
                    </span>
                  </div>
                </li>
                <li className="d-flex justify-content-center row">
                  <div className="col">
                    <p className="text-primary d-flex justify-content-end">
                      Хүйс
                    </p>
                  </div>
                  <div className="col justify-content-end">
                    <span className="text-dark">
                      {" "}
                      {candidateDetail.gender === String("M")
                        ? "Эрэгтэй"
                        : "Эмэгтэй"}
                    </span>
                  </div>
                </li>
                <li className="d-flex justify-content-center row">
                  <div className="col">
                    <p className="text-primary d-flex justify-content-end">
                      И-мэйл
                    </p>
                  </div>
                  <div className="col justify-content-end">
                    <span className="text-dark">{candidateDetail.email}</span>
                  </div>
                </li>
                <li className="d-flex justify-content-center row">
                  <div className="col">
                    <p className="text-primary d-flex justify-content-end">
                      Утасны дугаар
                    </p>
                  </div>
                  <div className="col justify-content-end">
                    <span className="text-dark">
                      {candidateDetail.gMobileNumber}
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <Forms ref={forms1} fields={fields1} />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={onClose} className="btn btn-link bolder">
          {t("common.back")}
        </button>
        <Button variant="success btn-shadow" onClick={onSaveClick}>
          {t("common.save")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PsychologyEdit;
