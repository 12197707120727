import React,{useState,useEffect} from 'react';
import { Card,Row,Col,Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { studentOnlineLessonTopicView, studentOnlineLessonGetFile } from '../../../../utils/fetchRequest/Urls';
import { fetchRequest, fetchRequestFile } from '../../../../utils/fetchRequest';
import message from '../../../modules/message';
import { setLoading } from '../../../../redux/action';
import { useDispatch,useSelector } from 'react-redux';
import ReactPlayer from 'react-player/lazy';
import { saveAs } from 'file-saver';

const OnlineLessonViewModule = ({
location: { state: {
    lesson = {},
    syllabusId = null
} = {} } = {}, }) => {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();

    const school = useSelector(state => state?.selectedSchool?.id || null);

    const [moduleObj, setModuleObj] = useState({});
    const [starRate, setStarRate] = useState(0);

    const onBack = () =>{
        history.push({
            pathname: '/student/online-lesson/detail',
            state: {
                lesson,
                tab: 'subjects'
            }
        })
    }

    const onRate = () =>{
        const bodyParams = {
            school,
            syllabus: moduleObj.id,
            course: lesson.id,
            rate: starRate
        }

        dispatch(setLoading(true));
        fetchRequest(studentOnlineLessonTopicView, 'POST', bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res;

                    message(res?.data?.message || t('common.success'),true);

                    if(data?.module){
                        setModuleObj(data.module)
                        setStarRate(data.module?.rating)
                    }
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }

    const onNext = () =>{
        if(moduleObj.hasNext){
            fetchData(moduleObj.hasNext)
        }
    }

    const onPrev = () =>{
        if(moduleObj.hasPrev){
            fetchData(moduleObj.hasPrev)
        }
    }

    const renderStars = () =>{
        const tempStarData = []
        let counter = starRate
        let i = 0
        while(i < 5){
            let color = '#919bc0'
            if(counter > 0){
                color = '#f5af04'
            }

            tempStarData.push({
                color: color,
                opacity: (counter < 1 && counter > 0) ? counter : null,
                index: i + 1
            })
            counter--
            i++
        }
        return tempStarData.map((star,index)=>{
            return <i 
                key={index} 
                className='flaticon-star mx-1' 
                style={{
                    color: star.color,
                    fontSize: '30px',
                    opacity: star.opacity ? star.opacity : 1
                }}
                onClick={()=>setStarRate(star.index)}
            />
        })
    }

    const fetchData = id =>{
        const bodyParams = {
            school,
            syllabus: id,
            course: lesson.id,
        }
        dispatch(setLoading(true));
        fetchRequest(studentOnlineLessonTopicView, 'GET', bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    
                    if(data?.module){
                        setModuleObj(data.module)
                        setStarRate(data.module?.rating)
                    }

                    dispatch(setLoading(false));
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                    dispatch(setLoading(false));
                }
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }

    useEffect(()=>{
        fetchData(syllabusId)
    },[])

    const onDownload = (id) => {
        const bodyParams = {
            file: id
        };

        dispatch(setLoading(true));
        fetchRequest(studentOnlineLessonGetFile, 'POST', bodyParams)
            .then(res => {
                if(res.success){
                    fetchRequestFile(studentOnlineLessonGetFile, 'GET', bodyParams)
                        .then(res => {
                            if(res.status != 200){
                                dispatch(setLoading(false));
                                message(res?.data?.message || t('errorMessage.title'));
                            }
                            else{
                                return res.blob();
                            }
                        })
                        .then(blob => {
                            dispatch(setLoading(false));
                            if(blob){
                                saveAs(blob, res.data?.name);
                            }
                        })
                        .catch((e) => {
                            dispatch(setLoading(false));
                            message(t('errorMessage.title'));
                        });
                }
                dispatch(setLoading(false));
            })
            .catch((e) => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            });        
    };

    return (
        <Card>
            <Card.Header>
                <Row className='align-items-center'>
                    <Col md={6}>
                        <span style={{ color: '#29569f',fontSize:'14px'}}>
                            <b>{moduleObj.name || '-'}</b>
                        </span>
                    </Col>
                    <Col md={6} className='text-right'>
                        <button onClick={onBack} className='btn btn-link bolder'>{t('common.back')}</button>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                {
                    moduleObj.video && moduleObj.isVideo
                    ?
                        <div className='w-100 d-flex justify-content-center mb-6'>
                            <ReactPlayer
                                width={'400px'}
                                height={'260px'}
                                url={moduleObj.video}
                            />
                        </div>
                    : 
                    moduleObj.video
                    ?
                        <div className='w-100 d-flex justify-content-center mb-6'>
                            <a href={moduleObj.video} target="_blank">{moduleObj.video}</a>
                        </div>
                    : null
                }
                <div className='w-100 p-4' style={{textIndent: '25px',...border}}>
                    <p>
                        <b>{moduleObj.shortDescription}</b>
                    </p>
                    <p>
                        {moduleObj.description}
                    </p>
                </div>
                {
                    moduleObj.fileList && moduleObj.fileList.length > 0
                    ?
                        <div className='w-100 my-4' style={{textIndent: '25px',...border}}>
                            <p>
                                <b>{t('file.title')}</b>
                            </p>
                            <div style={{display: 'grid'}}>
                                {
                                    moduleObj.fileList.map((file, index) => {
                                        return <button style={{display: 'inline-flex'}} className='btn btn-link' key={'file' + index} onClick={() => onDownload(file.id)}>{file.name}</button>
                                    })
                                }
                            </div>
                        </div>    
                    : null
                }
                <Row className='align-items-center my-4 mx-0' style={border}>
                    <Col md={2}>
                        <span style={{color:'#29569f'}}>
                            <b>{t('onlineLesson.rate')}</b>
                        </span>
                    </Col>
                    <Col md={4}>
                        {renderStars()}
                    </Col>
                    <Col md={2}>
                        <Button className='btn btn-success' onClick={onRate}>
                            {t('common.send')}
                        </Button>
                    </Col>
                </Row>
                <Row className='my-3'>
                    <Col md={6}>
                        {
                            moduleObj.hasPrev
                            ?
                                <Button className="btn btn-outline-success" onClick={onPrev}>{'<< ' + t('onlineLesson.prev')}</Button>
                            : null
                        }
                    </Col>
                    <Col md={6} className='text-right'>
                        {
                            moduleObj.hasNext
                            ?
                                <Button className="btn btn-outline-success" onClick={onNext}>{t('onlineLesson.next') + ' >>'}</Button>
                            : null
                        }
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

const border = {
    border: 'none',
    boxShadow: '0 2px 10px 0 #919bc033',
    borderRadius: '8px',
    padding: '1.5rem'
}

export default OnlineLessonViewModule