import React, {useRef, useEffect, useState} from 'react';
import { Button, Modal } from "react-bootstrap";
import Forms from "../../../modules/Form/Forms";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {getPrograms, invoiceCreate, movementCauseEdit} from "../../../../utils/fetchRequest/Urls";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import message from "../../../modules/message";

const AddInvoice = ({
    onClose,
    onSubmit,
}) => {
    const { t } = useTranslation();
    const formRef = useRef();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    const [invoiceTypes, setInvoiceTypes] = useState([]);
    const [academicPrograms, setAcademicPrograms] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [classes, setClasses] = useState([]);
    const [students, setStudents] = useState([]);
    const [invoiceTypeId, setInvoiceTypeId] = useState(null);
    const [academicProgramId, setAcademicProgramId] = useState(null);
    const [programId, setProgramId] = useState(null);
    const [classId, setClassId] = useState(null);

    const parentSeason = useSelector(state => {
            let schoolList = state?.school?.schoolList;
            if(schoolList && schoolList.length > 0){
                for (let i = 0; i < schoolList.length; i++){
                    if(schoolId == schoolList[i]['id']){
                        return schoolList[i]['parentSeason'];
                    }
                }
            }
        }
    );

    useEffect(() => {
        const params = {
            school: schoolId,
            season: parentSeason,
        };
        init(params)
    }, []);

    const init = (params) => {
        dispatch(setLoading(true));
        fetchRequest(invoiceCreate, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    if(data.invoiceTypes && data.invoiceTypes.length > 0){
                        setInvoiceTypes(data.invoiceTypes.map(type => ({value: type.id, text: type.name})));
                    }
                    if(data.academicPrograms && data.academicPrograms.length > 0){
                        setAcademicPrograms(data.academicPrograms.map(program => ({value: program.id, text: program.name})));
                    }
                    if(data.programs && data.programs.length > 0){
                        setPrograms(data.programs);
                    }
                    if(data.classes && data.classes.length > 0){
                        setClasses(data.classes.map(classObj => ({value: classObj.id, text: classObj.educationLevelName + ' - ' + classObj.programName})));
                    }
                    if(data.students && data.students.length > 0){
                        setStudents(data.students.map(student => ({value: student.id, text: student.lastName + ' - ' + student.firstName})));
                    }
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(err => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    useEffect(() => {
        formRef?.current?.updateFields && formRef.current?.updateFields(fields);
    }, [invoiceTypes, academicPrograms, programs, classes]);

    const onInvoiceTypeChange = (id) => {
        setInvoiceTypeId(id)
    };

    const onAcademicProgramChange = (id, evt) => {
        if (id) {
            setPrograms([]);
            setAcademicProgramId(id);
            const params = {
                school: schoolId,
                season: parentSeason,
                academicProgram: id,
            };
            init(params)
        }
    };

    const onProgramChange = (id, evt) => {
        if (id && id.length > 0) {
            setPrograms([]);
            let programArray = [];
            for(let i = 0; i < id.length; i++){
                programArray.push({
                    value: id[i]
                });
            }
            setProgramId(id);
            const params = {
                school: schoolId,
                season: parentSeason,
                academicProgram: academicProgramId,
                program: programArray
            };
            init(params)
        }
    };

    const onClassChange = (id, evt) => {
        if (id && id.length > 0) {
            let programArray = [];
            for(let i = 0; i < programId.length; i++){
                programArray.push({
                    value: programId[i]
                });
            }
            let classArray = [];
            for(let i = 0; i < id.length; i++){
                classArray.push({
                    id: id[i]
                });
            }
            setClassId(id);
            const params = {
                school: schoolId,
                season: parentSeason,
                academicProgram: academicProgramId,
                program: programArray,
                class: classArray
            };
            init(params)
        }
    };

    const fields = [
        {
            label: t('finance.invoiceType'),
            value: invoiceTypeId || null,
            type: 'dropdown',
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            options: invoiceTypes,
            onChange: onInvoiceTypeChange,
            key: 'invoiceType',
            clearable: true
        },
        {
            label: t('curriculum.title'),
            value: academicProgramId || null,
            type: 'dropdown',
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            options: academicPrograms,
            onChange: onAcademicProgramChange,
            key: 'academicProgram',
            clearable: true
        },
        {
            label: t('curriculum.profession'),
            value: programId || null,
            type: 'dropdown',
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            options: programs,
            onChange: onProgramChange,
            key: 'program',
            clearable: true,
            multiple: true,
            searchable: true
        },
        {
            label: t('common.className'),
            value: classId || null,
            type: 'dropdown',
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            options: classes,
            onChange: onClassChange,
            key: 'class',
            clearable: true,
            multiple: true,
            searchable: true
        },
        {
            label: t('student.title'),
            value: null,
            type: 'dropdown',
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            options: students,
            key: 'students',
            clearable: true,
            multiple: true,
            searchable: true
        },
        {
            label: t('common.amount'),
            value: '',
            type: 'text',
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            key: 'amount',
        },
    ];

    const onSaveClick = () => {
        const [ isValid, states, values ] = formRef.current.validate();
        if (isValid) {
            const params = {
                ...values,
            };
            onSubmit(params);
        }
    };

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('finance.add').toUpperCase()}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Forms
                    ref={formRef}
                    fields={fields}
                />
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className='btn btn-link bolder'>{t('common.back')}</button>
                <Button variant='success btn-shadow' onClick={onSaveClick}>{t('common.create')}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddInvoice;