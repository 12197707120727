import React, { useEffect, useRef, useState} from "react";
import {useTranslation} from 'react-i18next';
import {Link, useHistory} from "react-router-dom";
import {Button, Card, Nav, ButtonToolbar, Modal, Form, Row, Col} from "react-bootstrap";
import TreeView from '../../../../app/modules/TreeView/index';
import DTable from "../../../modules/DataTable/DTable";
import {fetchRequest} from "../../../../utils/fetchRequest";
import {setLoading} from "../../../../redux/action";
import {useDispatch, useSelector} from "react-redux";
import {programInit, programChangeStatus, programChangeName} from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import Forms from "app/modules/Form/Forms";

export function Academic() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    const formRef = useRef();
    const translateFormRef = useRef();
    
    const [statuses, setStatuses] = useState([]);
    const [selectedTreeId, setSelectedTreeId] = useState(null);
    const [key, setKey] = useState('');
    const [departments, setDepartments] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [helperModal, setHelperModal] = useState(false);
    const [program, setProgram] = useState(null);
    const [programs, setPrograms] = useState([]);
    const [viewModal, setViewModal] = useState(false);
    const [changeNameModal, setChangeNameModal] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState([]);
    const [treeIndex] = useState('school_program_tree_index');
    const [tabIndex] = useState('school_program_tab_index');
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [search, setSearch] = useState('');
    const [tableTotalSize, setTableTotalSize] = useState(0);
    const languages = useSelector(state => state?.languages?.languages || []);

    useEffect(() => {
        let params = {
            school: schoolId,
            page,
            pageSize,
            search
        };

        let tabKey = localStorage.getItem(tabIndex);
        let treeId = localStorage.getItem(treeIndex);

        if(tabKey){
            setKey(tabKey);
            params.code = tabKey ? tabKey.substring(1, tabKey.length) : '';
        }
        if(treeId){
            setSelectedTreeId([parseInt(treeId)]);
            params.department =  treeId;
        }

        dispatch(setLoading(true));
        fetchRequest(programInit, 'GET', params)
            .then(response => {
                if (response.success) {
                    if (response.data.statuses && response.data.statuses.length) {
                        setStatuses(response.data.statuses);
                        const status = response.data.statuses.find(status => status.id === response.data.selectedStatusId);
                        setSelectedStatus(status);
                    }
                    if(response.data.departments && response.data.departments.length){
                        setDepartments(response.data.departments);
                    }
                    setTableTotalSize(response.data.totalCount);
                    setPrograms(response.data.programs);
                } else {
                    message(response.data.message, false);
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
            })
    }, [page, pageSize, search]);

    const handleChange = (idArray) => {
        if(idArray && idArray.length > 0){
            let params = {
                school: schoolId,
                department: idArray[0],
                status: selectedStatus ? selectedStatus.id : '',
                pageSize: pageSize,
                page: page,
                search: search,
                sort: [],
                order: 'asc'
            };

            dispatch(setLoading(true));
            fetchRequest(programInit, 'GET', params)
                .then(response => {
                    if (response.success) {
                        setTableTotalSize(response.data.totalCount);
                        setPrograms(response.data.programs);
                        setSelectedTreeId(idArray)
                    } else {
                        message(response.data.message, false);
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                });

            localStorage.setItem(treeIndex, idArray[0]);
        }
    };

    const handleTab = (key, e) => {
        let selectedType = [];
        setKey(key);
        localStorage.setItem(tabIndex, key);

        if(statuses && statuses.length > 0){
            for(let i = 0; i < statuses.length; i++){
                if(key === ('#' + statuses[i]['code'])){
                    selectedType = statuses[i];
                }
            }
        }

        if(selectedType){
            let params = {
                school: schoolId,
                department: selectedTreeId && selectedTreeId.length > 0 ? selectedTreeId[0] : null,
                status: selectedType.id,
                pageSize,
                page,
                search,
                sort: [],
                order: 'asc'
            };
            dispatch(setLoading(true));
            fetchRequest(programInit, 'GET', params)
                .then(response => {
                    if (response.success) {
                        setTableTotalSize(response.data.totalCount);
                        setPrograms(response.data.programs && response.data.programs.length > 0 ? response.data.programs : []);
                    } else {
                        message(response.data.message, false);
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                });
        }

        setSelectedStatus(selectedType)
    };

    const forms = [
        {
            label: t('curriculum.profession') + '*',
            value: program ? program['name'] : '',
            errorMessage: t('errorMessage.enterProfession'),
            required: true,
            type: 'text',
        }
    ];

    const [translationFields, setTranslationFields] = useState(languages.map(lang => {
        return {
            label: `${t('common.name')} - ${lang.name} (${lang.code})`,
            value: '',
            langCode: lang.code,
            type: 'text',
            key: 'name',
            hidden: lang.code == 'mn' ? true : false,
            inputStyle: lang.code == 'tr' ? {fontFamily: 'Urga'} : {}
        }
    }));

    useEffect(() => {
        formRef?.current?.updateFields && formRef.current.updateFields(forms);
    }, [program]);

    useEffect(() => {
        translateFormRef?.current?.updateFields && translateFormRef.current.updateFields(translationFields);
    }, [translationFields]);

    const config = {
        showPagination: true,
    };

    const columns = [
        {
            dataField: "studentCodePrefix",
            text: t('student.code'),
            sort: true,
        },
        {
            dataField: "academicCode",
            text: t('curriculum.code'),
            sort: true,
        },
        {
            dataField: "academicName",
            text: t('curriculum.name'),
            sort: true,
        },
        {
            dataField: "name",
            text: t('curriculum.profession'),
            sort: true,
        },
        {
            dataField: "educationLevelName",
            text: t('curriculum.difficulty'),
            sort: true,
        },
        {
            dataField: "typeName",
            text: t('curriculum.scheduleType'),
            sort: true,
        },
        {
            dataField: "approachName",
            text: t('curriculum.learningType'),
            sort: true,
        },
        {
            dataField: "totalCredit",
            text: t('curriculum.totalCredits'),
            sort: true,
        }
    ];

    const contextMenusActive = [
        {
            key: 'view',
            title: t('common.view'),
            icon: <i className='las la-search dt-cm-item-icon'/>
        },
        {
            key: 'editName',
            title: t('common.edit'),
            icon: <i className='las la-edit dt-cm-item-icon'/>
        },
        {
            key: 'unpublish',
            title: t('action.setInactive'),
            icon: <i className='las la-eye-slash dt-cm-item-icon'/>
        },
    ];

    const contextMenusInActive = [
        {
            key: 'view',
            title: t('common.view'),
            icon: <i className='las la-search dt-cm-item-icon'/>
        },
        {
            key: 'edit',
            title: t('common.edit'),
            icon: <i className='las la-edit dt-cm-item-icon'/>
        },
        // {
        //     key: 'publish',
        //     title: t('action.setActive'),
        //     icon: <i className='las la-eye dt-cm-item-icon'/>
        // },
    ];

    const handleContextMenuClick = (id, key, record) => {
        if(key === 'view')
        {
            setSelectedRecord(record);
            setViewModal(true);
        } else if (key === 'publsih' || key === 'unpublish')
        {
            let toStatusId = null;
            if(statuses && statuses.length > 0){
                for (let i = 0; i < statuses.length; i++){
                    if(key === (statuses[i].code).toLowerCase()){
                        toStatusId = statuses[i].id;
                    }
                }
            }

            let params = {
                school: schoolId,
                department: selectedTreeId && selectedTreeId.length > 0 ? selectedTreeId[0] : null,
                status: selectedStatus.id,
                to_status: toStatusId,
                program: record.id,
            };

            dispatch(setLoading(true));
            fetchRequest(programChangeStatus, 'POST', params, false)
                .then(response => {
                    if (response.success) {
                        setPrograms(response.data.programs);
                        message(response.data.message, true);
                    } else {
                        message(response.data.message, false);
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                })
        } else if (key === 'edit'){
            history.push({
                pathname: '/school/edit/id=' + record.id,
                state: {
                    id: record.id,
                }
            });
        } else if (key === 'editName'){
            setSelectedRecord(record);
            let params = {
                school: schoolId,
                department: selectedTreeId && selectedTreeId.length > 0 ? selectedTreeId[0] : null,
                program: record.id,
            };

            dispatch(setLoading(true));
            fetchRequest(programChangeName, 'GET', params, false)
                .then(response => {
                    if (response.success) {
                        let programData = response.data.programData;

                        if(programData){
                            let translations = programData.translations;

                            if (translationFields && translationFields.length > 0 && translations && translations.length > 0) {
                                for (let i = 0; i < translationFields.length; i++)
                                {
                                    for (let t = 0; t < translations.length; t++)
                                    {
                                        if (translationFields[i]['langCode'] === translations[t]['langCode'])
                                        {
                                            translationFields[i]['value'] = translations[t]['name'];
                                        }
                                    }
                                }

                                translateFormRef?.current?.updateFields && translateFormRef.current.updateFields(translationFields);
                                setTranslationFields(translationFields)
                            }
                        }

                        setChangeNameModal(true)
                        setProgram(response.data?.programData || []);
                    } else {
                        message(response.data.message, false);
                    }
                    dispatch(setLoading(false));
                })
                .catch((e) => {
                    dispatch(setLoading(false));
                })
        }
    };

    const handleTableInteraction = (object) => {
        setPage(object.page);
        setPageSize(object.pageSize);
        setSearch(object.search);
    };

    const handleHelperModal = () => {
        setHelperModal(true);
    };

    const handleHelperModalClose = () => {
        setHelperModal(false);
    };

    const handleViewModalClose = () => {
        setViewModal(false);
        setChangeNameModal(false)
    };

    const handlerOnSaveClick = () => {
        const [formValid, formValue] = formRef.current.validate();
        const [translateValid, translateValues] = translateFormRef.current.validate();

        let params = {
            school: schoolId,
            department: selectedTreeId && selectedTreeId.length > 0 ? selectedTreeId[0] : null,
            program: selectedRecord.id,
            name: formValue[0].value
        };

        let translations = [];
        if(languages && languages.length > 0){
            languages.map(language => {
                if(language.code !== 'mn'){
                    const nameObject = translateValues.find(val => val.key === 'name' && val.langCode === language.code && val.value);

                    if (nameObject) {
                        translations.push({
                            langCode: language.code,
                            name: nameObject.value
                        })
                    }
                }
            });

            params.translations = JSON.stringify(translations);
        }

        dispatch(setLoading(true));
        fetchRequest(programChangeName, 'POST', params, false)
            .then(response => {
                if (response.success) {
                    setChangeNameModal(false)
                    message(response.data.message, true);
                } else {
                    message(response.data.message, false);
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
            })
    }

    return (
        <div className='row'>
            <div className='col-4'>
                <Card>
                    <Card.Body>
                        <TreeView
                            treeData={departments}
                            selectedNodes={selectedTreeId}
                            onSelect={(e, data) => handleChange(e, data)}
                            defaultExpandAll={true}
                        />
                    </Card.Body>
                </Card>
            </div>
            <div className='col-8 pl-0'>
                <Card className='card-header-with-tab'>
                    <Card.Header>
                        {
                            statuses && statuses.length > 0
                                ?
                                    <Nav
                                        variant="tabs"
                                        defaultActiveKey={key}
                                        onSelect={(key, e) => handleTab(key, e)}
                                    >
                                        {
                                            statuses.map(status => {
                                                return (
                                                    <Nav.Item key={'type_' + status.id}>
                                                        <Nav.Link href={`#${status.code}`}>{status.name}</Nav.Link>
                                                    </Nav.Item>
                                                )
                                            })
                                        }
                                    </Nav>
                                : null
                        }
                    </Card.Header>
                    <Card.Body>
                        {
                            key.toLowerCase() === '#publish'
                            ?
                                <>
                                    {
                                        selectedTreeId && selectedTreeId.length > 0
                                        ?
                                            <ButtonToolbar className='d-inline'>
                                                <Link
                                                    to={{
                                                        pathname: '/school/add',
                                                        state: {
                                                            departmentId: selectedTreeId[0]
                                                        }
                                                    }}
                                                    className="btn btn-primary btn-shadow"
                                                >
                                                    {t('common.add')}
                                                </Link>
                                            </ButtonToolbar>
                                        : null
                                    }
                                    <Button className='bg-white border-white d-inline btn-hover-less' onClick={handleHelperModal}>
                                        <i className="icon-2x text-danger flaticon-questions-circular-button d-inline"/>
                                        <p className='text-danger d-inline'><b>{t('common.helper')}</b></p>
                                    </Button>
                                    <DTable
                                        remote
                                        data={programs}
                                        columns={columns}
                                        config={config}
                                        onInteraction={handleTableInteraction}
                                        totalDataSize={tableTotalSize}
                                        showOrdering={true}
                                        contextMenus={contextMenusActive}
                                        onContextMenuItemClick={handleContextMenuClick}
                                        selectMode={'radio'}
                                    />
                                </>
                            : null
                        }
                        {
                            key.toLowerCase() === '#unpublish'
                            ?
                                <>
                                    {
                                        selectedTreeId && selectedTreeId.length > 0
                                            ?
                                                <ButtonToolbar className='d-inline'>
                                                    <Link
                                                        to={{
                                                            pathname: '/school/add',
                                                            state: {
                                                                departmentId: selectedTreeId[0]
                                                            }
                                                        }}
                                                        className="btn btn-primary btn-shadow"
                                                    >
                                                        {t('common.add')}
                                                    </Link>
                                                </ButtonToolbar>
                                            : null
                                    }
                                    <Button className='bg-white border-white d-inline btn-hover-less' onClick={handleHelperModal}>
                                        <i className="icon-2x text-danger flaticon-questions-circular-button d-inline"/>
                                        <p className='text-danger d-inline'><b>{t('common.helper')}</b></p>
                                    </Button>
                                    <DTable
                                        remote
                                        data={programs}
                                        columns={columns}
                                        config={config}
                                        onInteraction={handleTableInteraction}
                                        totalDataSize={tableTotalSize}
                                        showOrdering={true}
                                        contextMenus={contextMenusInActive}
                                        onContextMenuItemClick={handleContextMenuClick}
                                        selectMode={'radio'}
                                    />
                                </>
                            : null
                        }
                    </Card.Body>
                </Card>
            </div>
            <Modal
                show={helperModal}
                onHide={handleHelperModalClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Modal heading
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Centered Modal</h4>
                    <p>
                        Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
                        dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta
                        ac consectetur ac, vestibulum at eros.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleHelperModalClose}>{t('common.close')}</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={viewModal}
                onHide={handleViewModalClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t('common.view')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        selectedRecord
                            ?
                            <Form>
                                <Form.Group as={Row}>
                                    <Form.Label column sm="4" className='text-right'>
                                        {t('common.code')}
                                    </Form.Label>
                                    <Col sm="8">
                                        <div className='form-label col-form-label'>{selectedRecord.code}</div>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label column sm="4" className='text-right'>
                                        {t('subject.name')}
                                    </Form.Label>
                                    <Col sm="8">
                                        <div className='form-label col-form-label'>{selectedRecord.name}</div>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label column sm="4" className='text-right'>
                                        {t('subject.type')}
                                    </Form.Label>
                                    <Col sm="8">
                                        <div className='form-label col-form-label'>{selectedRecord.typeName}</div>
                                    </Col>
                                </Form.Group>
                            </Form>
                            : null
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleViewModalClose}>{t('common.close')}</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={changeNameModal}
                onHide={handleViewModalClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t('common.view')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='col-12'>
                        <Forms
                            ref={formRef}
                            fields={forms}
                        />
                    </div>
                    <div className="separator separator-dashed my-7"/>
                    <div className='col-12'>
                        <Forms
                            ref={translateFormRef}
                            fields={translationFields}
                        />
                        </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='link' onClick={handleViewModalClose}>{t('common.close')}</Button>
                    <Button variant="success btn-shadow" onClick={() => handlerOnSaveClick('save')}>{t('common.save')}</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Academic;