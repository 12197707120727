import React, { useState, useEffect } from 'react';
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import {
    menuPositionInit,
    menuPositionAdd, menuPositionEdit,
    menuPositionDelete
} from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import DeleteModal from "../../../modules/DeleteModal";
import DTable from "../../../modules/DataTable/DTable";
import MenuPositionAdd from "./MenuPositionAdd";

const MenuPositions = () => {
    const [ positions, setPositions ] = useState([]);
    const [ showModal, setShowModal ] = useState(false);
    const [ positionId, setPositionId ] = useState(null);
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const contextMenus = [
        {
            key: 'edit',
            title: t('common.edit'),
            icon: <i className='las la-edit dt-cm-item-icon'/>
        },
    ];

    useEffect(() => {
        dispatch(setLoading(true));
        fetchRequest(menuPositionInit, 'GET', [])
            .then(res => {
                if (res.success) {
                    let positions = res.data.positions;
                    if(positions.length > 0){
                        for(let i = 0; i < positions.length; i++){
                            if(positions[i].isActive){
                                contextMenus.push({
                                    key: 'publish',
                                    title: t('action.setActive'),
                                    icon: <i className='las la-eye dt-cm-item-icon'/>
                                })
                            }
                        }
                    }
                    setPositions(positions);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }, []);

    const handleAddClick = () => {
        setShowModal(true);
    };

    const onModalClose = () => {
        setShowModal(false);
        setPositionId(null);
    };

    const config = {
        showPagination: false,
        showFilter: false,
        showAllData: true,
    };

    const columns = [
        {
            dataField: "isActive",
            text: t('common.status'),
            headerStyle: () => ({
                width: 80,
            }),
            style: {textAlign: 'center'},
            formatter: (cell, row) => {
                if(cell === true){
                    return (
                        <div><span><i className="icon-1_5x main-green fa fa-circle"/></span></div>
                    )
                } else {

                    return (
                        <div><span><i className="icon-1_5x text-dark-50 fa fa-circle"/></span></div>
                    )
                }
            }
        },
        {
            dataField: "code",
            text: t('common.code'),
        },
        {
            dataField: "name",
            text: t('common.name'),
        },
        {
            dataField: "ordering",
            text: t('common.ordering'),
            style: {textAlign: 'right'},
            sort: true,
            headerStyle: () => ({
                width: 120,
            }),
        },
    ];

    const handleContextMenuClick = (id, key) => {
        if (id && key) {
            setPositionId(id);
            if (key === 'edit') {
                setShowModal(true);
            }
        }
    };

    const onSubmit = params => {
        const url = positionId ? menuPositionEdit : menuPositionAdd;
        const bodyParams = {
            ...params,
        };

        if (positionId) {
            bodyParams.position = positionId;
        }

        dispatch(setLoading(true));
        fetchRequest(url, 'POST', bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setPositions(data?.positions);

                    message(data?.message || t('common.success'), true);
                    onModalClose();
                } else {
                    message(res?.data?.message || t('errorMessage.title'));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            })
    };

    const onDelete = () => {
        const params = {
            position: positionId
        };
        dispatch(setLoading(true));
        fetchRequest(menuPositionDelete, 'POST', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setPositions(data?.positions ||[]);
                    message(data?.message || t('common.success'), true);
                    onModalClose();
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    return (
        <Card>
            <Card.Body>
                <button
                    className='btn btn-primary'
                    onClick={handleAddClick}
                >
                    {t('common.add')}
                </button>
                <DTable
                    columns={columns}
                    config={config}
                    data={positions}
                    contextMenus={contextMenus}
                    onContextMenuItemClick={handleContextMenuClick}
                    selectMode={'radio'}
                />
                {
                    showModal && (
                        <MenuPositionAdd
                            onClose={onModalClose}
                            onSubmit={onSubmit}
                            id={positionId}
                        />
                    )
                }
                {
                    !showModal && positionId && (
                        <DeleteModal
                            onClose={onModalClose}
                            onDelete={onDelete}
                            title={t('assessment.specification')}
                        >
                            {t('warning.delete_confirmation')}
                            <br/>
                            <br/>
                            {t('warning.delete_confirmation_description')}
                        </DeleteModal>
                    )
                }
            </Card.Body>
        </Card>
    );
};

export default MenuPositions;