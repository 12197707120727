import React from "react";
import { Radio as MUIRadio } from "@material-ui/core";
import styles from './questionTypes.module.scss';
import clsx from "clsx";

const types = [
    {
        value: 'true',
        label: 'Үнэн',
    },
    {
        value: 'false',
        label: 'Худал',
    },
]

const TrueOrFalse = ({
    onChange,
    value = 'true'
}) => {

    const handleChange = newValue => {
        onChange(newValue)
    }

    return (
        <div>
            {
                types.map((type, i) => {
                    return (
                        <div
                            key={type.value}
                            className={styles.rowContainer}
                        >
                            <MUIRadio
                                checked={value === type.value}
                                onChange={() => handleChange(type.value)}
                            />
                            <div
                                className={clsx('form-control', styles.readOnly)}
                            >
                                {type.label}
                            </div>
                        </div>
                    )
                })
            }
        </div>
    );
};

export default TrueOrFalse;