import React, { useEffect, useReducer, useState } from 'react';
import { Card, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DTable from "../../../modules/DataTable/DTable";
import { fetchRequest } from "../../../../utils/fetchRequest";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../../redux/action";
import {
    questionBankDeactivate, questionBankDelete,
    questionBankInit,
    questionBankList
} from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import { formatSelectData } from '../../../../utils/helpers/select';
import Select from '../../../modules/Form/Select';
import styles from './question.module.scss';
import clsx from "clsx";
import { Link, useHistory } from 'react-router-dom';
import cloneDeep from "lodash/cloneDeep";
import DeleteModal from "../../../modules/DeleteModal";
import QuestionPreviewModal from "./QuestionPreviewModal";
import { sortCaret } from '../../../../_metronic/_helpers/TableSortingHelpers';

const fakeApi = (params = {}) => {
    const { pageSize = 10, page = 1 } = params;
    const data = [];
    for (let i = 0; i < 35; i++) {
        data.push({
            id: i + 1,
            code: `CODE-${i + 1}`,
        })
    }
    return data.slice(pageSize * (page - 1), pageSize * page);
}

const initialState = {
    subjects: [],
    difficulties: [],
    topics: [],
    subjectId: null,
    difficultyId: null,
    topicId: null,
    status: null,
    questions: [],    
    totalCount: 0,
    deleteId: null,
}

const actions = {
    setState: 'SET_STATE',
}

const reducer = (state, action) => {
    switch(action.type) {
        case actions.setState: {
            return {
                ...state,
                ...action.payload
            }
        }
        default:
            return state;
    }
}

const STATUSES = [
    {
        value: '1',
        text: 'Идэвхтэй',
    },
    {
        value: '0',
        text: 'Идэвхгүй',
    },
];


const tableConfig = {
    showPagination: true,
    showFilter: true,
    showAllData: false
};

const ExamList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [ state, dispatchState ] = useReducer(reducer, initialState, undefined);

    useEffect(() => {
        fetchList();
        const params = {
            school: schoolId
        }
        dispatch(setLoading(true));
        fetchRequest(questionBankInit, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data = {} } = res;
                    const {
                        difficulties = [],
                        questionTypes = [],
                        subjects = [],
                        topics = []
                    } = data;

                    dispatchState({
                        type: actions.setState,
                        payload: {
                            difficulties: formatSelectData(difficulties),
                            questionTypes: formatSelectData(questionTypes),
                            subjects: formatSelectData(subjects),
                            topics: formatSelectData(topics)
                        }
                    })

                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            });
    }, []);

    const htmlFormatter = cell => {
        var div = document.createElement("div");
        div.innerHTML = cell;
        var text = div.textContent || div.innerText || "";
        div.remove();
        return shorten(text);
    };

    const shorten = (value) =>{
        if(value && value.length > 30){
            var trimmedString = value.substr(0, 30);
            trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")));
            return trimmedString;
        }
        return value;
    }

    const fetchList = (params) => {
        dispatchState({
            type: actions.setState,
            payload: {
                questions: [],
            }
        })
        const bodyParams = {
            school: schoolId,
            ...params
        }
        dispatch(setLoading(true));
        fetchRequest(questionBankList, 'GET', bodyParams)
            .then(res => {
                if (res.success) {
                    const { data = {} } = res;

                    const questions = (data?.questions ?? []).map(question => {

                        const isActive = question.isActive;

                        const contextMenuKeys = isActive ? ['view', 'deactivate'] : ['edit', 'delete'];

                        const status = (
                            <div className={styles.statusContainer}>
                                <div className={isActive ? styles.tableStatusActive : styles.tableStatusInactive}/>
                            </div>
                        )

                        const questionButton = (
                            <button
                                className='btn btn-link'
                                disabled={question.isActive ? false : true}
                                style={question.isActive ? {} : {color:'black'}}
                                onClick={() => {
                                    dispatchState({
                                        type: actions.setState,
                                        payload: {
                                            previewContent: question.question,
                                            description: question.description
                                        }
                                    })
                                }}
                            >
                                {htmlFormatter(question.question)}
                            </button>
                        )

                        return {
                            ...question,
                            contextMenuKeys,
                            status,
                            questionButton
                        }
                    })

                    dispatchState({
                        type: actions.setState,
                        payload: {
                            questions,
                            totalCount: data.totalCount ?? 0,
                        }
                    })
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch((e) => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            });
    }

    const columns = [
        {
            dataField: "status",
            text: '',
            headerStyle: () => ({
                width: 50,
            }),
        },
        {
            dataField: "questionButton",
            text: t('exam.assignment'),
            sort: true,
            sortCaret: sortCaret
        },
        {
            dataField: "questionTypeName",
            text: t('common.type'),
            sort: true,
            sortCaret: sortCaret
        },
        {
            dataField: "difficultyName",
            text: t('exam.difficulty'),
            sort: true,
            sortCaret: sortCaret
        },
        {
            dataField: "score",
            text: t('exam.score'),
            sort: true,
            sortCaret: sortCaret
        },
        {
            dataField: "topicName",
            text: t('teacher.topic'),
            sort: true,
            sortCaret: sortCaret
        },
    ];

    const contextMenus = [
        {
            key: 'edit',
            title: t('common.edit'),
            icon: <i className='las la-edit dt-cm-item-icon'/>
        },
        {
            key: 'delete',
            title: t('common.delete'),
            icon: <i className='las la-trash-alt dt-cm-item-icon' />,
        },
        {
            key: 'view',
            title: t('common.view'),
            icon: <i className='las la-search dt-cm-item-icon'/>
        },
        {
            key: 'deactivate',
            title: t('action.setInactive'),
            icon: <i className='las la-eye-slash dt-cm-item-icon'/>
        },
    ];

    const handleContextMenuClick = (id, key) => {
        if (id && key) {
            switch (key) {
                case 'view': {
                    const question = state.questions.find(question => question.id === id);
                    if (question) {
                        dispatchState({
                            type: actions.setState,
                            payload: {
                                previewContent: question.question,
                                description: question.description
                            }
                        })
                    }
                    break;
                }
                case 'deactivate': {
                    const params = {
                        id,
                        school: schoolId
                    }
                    void deactivate(params);
                    break;
                }
                case 'delete': {
                    dispatchState({
                        type: actions.setState,
                        payload: {
                            deleteId: id,
                        }
                    })
                    break;
                }
                case 'edit': {
                    history.push('/exam/q/edit', {
                        questionId: id
                    })
                    break;
                }
                default:
                    return;
            }
        }
    };

    const deactivate = async params => {
        dispatch(setLoading(true));
        try {
            const response = await fetchRequest(questionBankDeactivate, 'POST', params);
            if (response.success) {
                const clone = cloneDeep(state.questions);
                const index = clone.findIndex(record => record.id === params.id);
                if (index > -1) {
                    //clone.splice(index, 1);
                    clone[index].isActive = false;
                    clone[index].contextMenuKeys = ['edit', 'delete'];
                    clone[index].status = (
                            <div className={styles.statusContainer}>
                                <div className={styles.tableStatusInactive}/>
                            </div>
                        )
                    dispatchState({
                        type: actions.setState,
                        payload: {
                            questions: clone,
                        }
                    })
                }
                message(response?.data?.message ?? t('common.success'), true)
            }
            else{
                message(response?.data?.message);
            }
            dispatch(setLoading(false));
        } catch (e) {
            dispatch(setLoading(false));
        }
    }

    const handleSelectChange = (key, value) => {
        dispatchState({
            type: actions.setState,
            payload: {
                [key]: value,
            }
        })
    }

    const handleSearch = () => {
        const params = {};

        if (state.subjectId) params.subject = state.subjectId;
        if (state.difficultyId) params.difficulty = state.difficultyId;
        if (state.topicId) params.topic = state.topicId;
        if (state.status !== null) params.isActive = state.status;
        fetchList(params);
    }

    const clearFilter = () => {
        dispatchState({
            type: actions.setState,
            payload: {
                subjectId: null,
                difficultyId: null,
                topicId: null,
                status: null,
            }
        });
        fetchList();
    };

    const handleModalClose = () => {
        dispatchState({
            type: actions.setState,
            payload: {
                deleteId: null,
            }
        })
    };

    const handleDelete = async () => {
        const params = {
            questionId: state.deleteId,
        }
        dispatch(setLoading(true));
        try {
            const response = await fetchRequest(questionBankDelete, 'POST', params);
            if (response.success) {
                const clone = cloneDeep(state.questions);
                const index = clone.findIndex(record => record.id === state.deleteId);
                if (index > -1) {
                    clone.splice(index, 1);
                    dispatchState({
                        type: actions.setState,
                        payload: {
                            questions: clone,
                            deleteId: null,
                        }
                    })
                }
                message(response?.data?.message ?? t('common.success'), true)
            }
            dispatch(setLoading(false));
        } catch (e) {
            dispatch(setLoading(false));
        }
    };

    const closePreview = () => {
        dispatchState({
            type: actions.setState,
            payload: {
                previewContent: null,
                description: null
            }
        })
    }

    return (
        <div>
            <Card className='mb-5'>
                <Card.Body>
                    <div className={styles['filter-wrapper']}>
                        <div>
                            <div>
                                {t('subject.title')}
                            </div>
                            <Select
                                options={state.subjects}
                                value={state.subjectId}
                                onChange={value => handleSelectChange('subjectId', value)}
                                searchable
                            />
                        </div>
                        <div>
                            <div>
                                {t('exam.difficulty')}
                            </div>
                            <Select
                                options={state.difficulties}
                                value={state.difficultyId}
                                onChange={value => handleSelectChange('difficultyId', value)}
                                searchable
                            />
                        </div>
                        <div>
                            <div>
                                {t('teacher.topic')}
                            </div>
                            <Select
                                options={state.topics}
                                value={state.topicId}
                                onChange={value => handleSelectChange('topicId', value)}
                                searchable
                            />
                        </div>
                        <div>
                            <div>
                                {t('common.status')}
                            </div>
                            <Select
                                options={STATUSES}
                                value={state.status}
                                onChange={value => handleSelectChange('status', value)}
                                searchable
                            />
                        </div>
                    </div>
                </Card.Body>
                <Card.Footer>
                    <div className='text-center'>
                        <Button
                            onClick={clearFilter}
                            variant='link'
                        >
                            {t('common.clear')}
                        </Button>
                        <Button
                            size='lg'
                            variant='warning'
                            onClick={handleSearch}
                        >
                            <i className='las la-search'/>
                            {t('common.search')}
                        </Button>
                    </div>
                </Card.Footer>
            </Card>
            <Card>
                <Card.Body>
                    <Link
                        className='btn btn-primary'
                        onClick={() => {}}
                        to='/exam/q/add'
                    >
                        {t('common.add')}
                    </Link>
                    <DTable
                        remote
                        data={state.questions}
                        config={tableConfig}
                        columns={columns}
                        totalDataSize={state.totalCount}
                        contextMenus={contextMenus}
                        onContextMenuItemClick={handleContextMenuClick}
                        onInteraction={fetchList}
                        individualContextMenus
                        selectMode={'radio'}
                    />
                </Card.Body>
            </Card>
            {
                state.deleteId && (
                    <DeleteModal
                        onClose={handleModalClose}
                        title={t('exam.assignment')}
                        onDelete={handleDelete}
                    >
                        {t('warning.delete_confirmation')}
                        <br/>
                        <br/>
                        {t('warning.delete_confirmation_description')}
                    </DeleteModal>
                )
            }
            {
                state.previewContent && (
                    <QuestionPreviewModal
                        onClose={closePreview}
                        question={state.previewContent}
                        description={state.description}
                    />
                )
            }
        </div>
    );
};

export default ExamList;