import React,{useEffect,useState} from 'react'
import { Modal,CloseButton,Button } from 'react-bootstrap'
import DTable from '../../../../../modules/DataTable/DTable'
import { useTranslation } from 'react-i18next'
import { htmlDecode } from '../../../../../../utils/utils'
import { isNull } from 'lodash'

const OrderQuestion = ({
    onClose = () =>{},
    questions = [],
    fetchData = () =>{}
}) => {
    const {t} = useTranslation()

    const [tableData,setTableData] = useState([])

    const columns = [
        {
            dataField: 'text',
            text: t('teacher.topic'),
            sort: false,
            formatter(cell,row){
                return <div className='w-100'>
                    <div dangerouslySetInnerHTML={{ __html: cell}}></div>
                </div>
            }
        }
    ]

    const onDrop = (params) =>{
        const {itemId,targetId} = params
        const target = tableData.find(data=>data.id === targetId)
        const targetIndex = tableData.findIndex(data=>data.id === targetId)
        const itemIndex = tableData.findIndex(data=>data.id === itemId)
        const tempClone = [...tableData]

        if(tempClone.length && !isNull(itemIndex) && !isNull(targetIndex) && target){
            tempClone[targetIndex] = tempClone[itemIndex]
            tempClone[itemIndex] = target
        }

        setTableData(tempClone)
    }

    const onSave = () =>{
        const params = {
            questions: JSON.stringify(tableData),
            action: 'order'
        }
        fetchData(params,'POST')
    }

    useEffect(()=>{
        if(questions.length){
            const tempData = []
            questions.map(q=>{
                tempData.push({
                    id: q.examQuestionId,
                    text: q.description
                })
            })
            setTableData(tempData)
        }
    },[])

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('onlineExam.orderChange')}
                    </div>
                </Modal.Title>
                <CloseButton onClick={onClose}/>
            </Modal.Header>
            <Modal.Body>
                <DTable
                    data={tableData}
                    columns={columns}
                    config={{
                        showPagination: false,
                        showFilter: false,
                        showAllData: true
                    }}
                    remote
                    draggable
                    onDrop={onDrop}
                />
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className='btn btn-link bolder'>{t('common.cancel')}</button>
                <Button style={btnStyle} onClick={onSave}>{t('common.save')}</Button>
            </Modal.Footer>
        </Modal>
    )
}

const btnStyle = {
    backgroundColor: '#299f72',
    border: 'none',
    borderRadius: '6px',
    color: '#ffffff',
    boxShadow: '0 2px 10px 0 #299f7233'
}

export default OrderQuestion