import React, { useEffect, useRef, useState} from "react";
import {useTranslation} from 'react-i18next';
import {Link, useHistory} from "react-router-dom";
import {Button, Card, Nav, ButtonToolbar, Modal, Form, Row, Col} from "react-bootstrap";
import TreeView from '../../../../app/modules/TreeView/index';
import DTable from "../../../modules/DataTable/DTable";
import {fetchRequest} from "../../../../utils/fetchRequest";
import {setLoading} from "../../../../redux/action";
import {useDispatch} from "react-redux";
import {
    teacherChangePassword,
    teacherChangeType,
    teacherInit,
    teacherList,
    teacherMovement,
    teacherDelete, teacherInvite,
} from "../../../../utils/fetchRequest/Urls";
import store from "../../../../redux/store";
import Forms from "../../../modules/Form/Forms";
import message from "../../../modules/message";
import Select from "../../../modules/Form/Select";
import InviteModal from "./inviteModal";

export function Teacher() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const {selectedSchool} = store.getState();
    const [types, setTypes] = useState([]);
    const [selectedTreeId, setSelectedTreeId] = useState(null);
    const [departments, setDepartments] = useState([]);
    const [selectedType, setSelectedType] = useState([]);
    const [helperModal, setHelperModal] = useState(false);
    const [teachers, setTeachers] = useState([]);
    const [viewModal, setViewModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState([]);
    const [changeTypeModal, setChangeTypeModal] = useState(false);
    const [changeTypeFormOptions, setChangeTypeFormOptions] = useState([]);
    const [movementModal, setMovementModal] = useState(false);
    const [changePasswordModal, setChangePasswordModal] = useState(false);
    const [inviteModal, setInviteModal] = useState(false);

    const [treeIndex] = useState('school_teacher_tree_index');
    const [tabIndex] = useState('school_teacher_tab_index');

    const [mvSchools, setMvSchools] = useState([]);
    const [mvDepartments, setMvDepartments] = useState([]);
    const [mvPositions, setMvPositions] = useState([]);
    const [mvStatuses, setMvStatuses] = useState([]);
    const [mvSchoolId, setMvSchoolId] = useState(null);
    const [mvDepartmentId, setMvDepartmentId] = useState(null);
    const [mvPositionId, setMvPositionId] = useState(null);
    const [totalCount, setTotalCount] = useState(0);

    const changeTypeFormRef = useRef();
    const movementFormRef = useRef();
    const changePasswordFormRef = useRef();

    useEffect(() => {
        let params = {
            school: selectedSchool.id
        };

        let tabKey = localStorage.getItem(tabIndex);
        let treeId = localStorage.getItem(treeIndex);

        if(treeId){
            if(treeId === '#'){
                setSelectedTreeId([treeId]);
            } else {
                setSelectedTreeId([parseInt(treeId)]);
            }

            params.department =  treeId;
        }

        dispatch(setLoading(true));
        fetchRequest(teacherInit, 'GET', params)
            .then(response => {
                if (response.success) {
                    let types = response.data.types;
                    if (types && types.length > 0)
                    {
                        setTypes(types);
                        let isActiveCode = false;
                        for(let i = 0; i < types.length; i++)
                        {
                            if(tabKey){
                                if(tabKey == types[i]['id'])
                                {
                                    isActiveCode = true;
                                    setSelectedType(types[i]);
                                }
                            } else {
                                if('active' == types[i]['code'].toLowerCase())
                                {
                                    isActiveCode = true;
                                    setSelectedType(types[i]);
                                }
                            }
                        }
                        if(!isActiveCode){
                            setSelectedType(types[0]);
                        }
                    }
                    setDepartments(response.data.departments);
                    setTotalCount(response.data.totalCount);
                } else {
                    message(response.data.message, false);
                    dispatch(setLoading(false));
                }
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            });
    }, []);

    useEffect(() => {
        let params = {
            school: selectedSchool.id,
            type: selectedType ? selectedType.id : null,
            department: selectedTreeId && selectedTreeId.length > 0 ? selectedTreeId[0] : null,
            pageSize: 10,
            page: 1,
            search: '',
            sort: [],
            order: 'asc'
        };

        dispatch(setLoading(true));
        fetchRequest(teacherList, 'GET', params)
            .then(response => {
                if (response.success) {
                    setTeachers(response.data.teachers);
                    setTotalCount(response.data.totalCount)
                } else {
                    message(response.data.message, false);
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            });
    }, [departments]);

    const getTeacherList = () => {
        let params = {
            school: selectedSchool.id,
            type:  selectedType ? selectedType.id : '',
            department: selectedTreeId && selectedTreeId.length > 0 ? selectedTreeId[0] : null,
            pageSize: 10,
            page: 1,
            search: '',
            sort: [],
            order: 'asc'
        };

        dispatch(setLoading(true));
        fetchRequest(teacherList, 'GET', params)
            .then(response => {
                if (response.success) {
                    setTeachers(response.data.teachers);
                    setTotalCount(response.data.totalCount)
                } else {
                    message(response.data.message, false)
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            });
    };

    useEffect(() => {
        movementFormRef?.current?.updateFields && movementFormRef.current.updateFields(movementForms);
    }, [mvDepartments, mvStatuses, mvSchools, mvPositions]);

    const handleChange = (idArray) => {
        if(idArray && idArray.length > 0)
        {
            let params = {
                school: selectedSchool.id,
                type:  selectedType ? selectedType.id : '',
                department: idArray[0],
                pageSize: 10,
                page: 1,
                search: '',
                sort: [],
                order: 'asc'
            };

            dispatch(setLoading(true));
            fetchRequest(teacherList, 'GET', params)
                .then(response => {
                    if (response.success) {
                        setTeachers(response.data.teachers);
                        setSelectedTreeId(idArray);
                        setTotalCount(response.data.totalCount)
                    } else {
                        message(response.data.message, false);
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                });
            localStorage.setItem(treeIndex, idArray[0]);
        }
    };

    const config = {
        showPagination: true,
    };

    const columns = [
        {
            dataField: "code",
            text: t('common.code'),
            sort: true,
        },
        {
            dataField: "lastName",
            text: t('common.lastName'),
            sort: true,
        },
        {
            dataField: "firstName",
            text: t('common.name'),
            sort: true,
        },
        {
            dataField: "positionName",
            text: t('menu.roles'),
            sort: true,
        },
        {
            dataField: "status",
            text: t('common.status'),
            sort: true,
        },
        {
            dataField: "subjectName",
            text: t('common.subject'),
            sort: true,
        },
        {
            dataField: "registrationNumber",
            text: t('common.registerNumber'),
            sort: true,
        },
        {
            dataField: "contact",
            text: t('common.phoneNumber'),
            sort: true,
        },
    ];

    const contextMenus = [
        {
            key: 'view',
            title: t('common.view'),
            icon: <i className='las la-search dt-cm-item-icon'/>
        },
        {
            key: 'edit',
            title: t('common.edit'),
            icon: <i className='las la-edit dt-cm-item-icon'/>
        },
        {
            key: 'delete',
            title: t('common.delete'),
            icon: <i className='las la-trash-alt dt-cm-item-icon'/>,
        },
        {
            key: 'change_type',
            title: t('common.changeType'),
            icon: <i className='fas fa-exchange-alt dt-cm-item-icon'/>,
        },
        {
            key: 'movement',
            title: t('common.movement'),
            icon: <i className='las la-boxes dt-cm-item-icon'/>,
        },
        {
            key: 'change_password',
            title: t('common.changePassword'),
            icon: <i className='las la-key dt-cm-item-icon'/>,
        },
    ];

    const handleContextMenuClick = (id, key, record) => {
        if(key === 'view')
        {
            setSelectedRecord(record);
            setViewModal(true);
        } else if (key === 'edit')
        {
            history.push({
                pathname: '/school/teacher_edit',
                state: {
                    teacherId: record.id,
                    departmentId: selectedTreeId[0],
                    depPositionId: record.depPositionId
                }
            });
        } else if (key === 'delete')
        {
            setDeleteModal(true);
            setSelectedRecord(record)
        } else if (key === 'change_type')
        {
            setSelectedRecord(record);
            setChangeTypeModal(true);
            let typesArray = [];
            if(types && types.length > 0 && selectedType){                
                for(let i = 0; i < types.length; i++){
                    if(types[i]['id'] !== selectedType['id']){
                        typesArray.push({
                            'value': types[i]['id'],
                            'text': types[i]['text']
                        })
                    }
                }
            }            
            setChangeTypeFormOptions(typesArray);
        } else if (key === 'movement')
        {
            handleMovementAction(record);
        } else if (key === 'change_password')
        {
            setSelectedRecord(record);
            setChangePasswordModal(true);
        }
    };

    const handleTableInteraction = (object) => {
        let params = {
            school: selectedSchool.id,
            type:  selectedType ? selectedType.id : '',
            department: selectedTreeId && selectedTreeId.length > 0 ? selectedTreeId[0] : null,
            ...object
        };

        dispatch(setLoading(true));
        fetchRequest(teacherList, 'GET', params)
            .then(response => {
                if (response.success) {
                    setTeachers(response.data.teachers);
                    setTotalCount(response.data.totalCount)
                } else {
                    message(response.data.message, false)
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            });
    };

    const onSelect = (id) => {
        if(id){
            let params = {
                school: selectedSchool.id,
                type: id,
                department: selectedTreeId && selectedTreeId.length > 0 ? selectedTreeId[0] : '',
                pageSize: 10,
                page: 1,
                search: '',
                sort: [],
                order: 'asc'
            };

            dispatch(setLoading(true));
            fetchRequest(teacherList, 'GET', params)
                .then(response => {
                    if (response.success) {
                        setTeachers(response.data.teachers);
                        setTotalCount(response.data.totalCount)
                    } else {
                        message(response.data.message, false);
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                });
            localStorage.setItem(tabIndex, id);

            if(types && types.length > 0)
            {
                for(let i = 0; i < types.length; i++)
                {
                    if(id === (types[i]['id']))
                    {
                        setSelectedType(types[i]);
                    }
                }
            }
        }
    };

    const handleHelperModal = () => {
        setHelperModal(true);
    };

    const handleHelperModalClose = () => {
        setHelperModal(false);
    };

    const handleViewModalClose = () => {
        setViewModal(false);
    };

    const handleDeleteModalClose = () => {
        setDeleteModal(false);
    };

    const handlerOnDeleteClick = () => {
        const params = {
            school: selectedSchool.id,
            teacher: selectedRecord ? selectedRecord.id : null,
            type: selectedType.id,
            department: selectedTreeId && selectedTreeId.length > 0 ? selectedTreeId[0] : ''
        };

        dispatch(setLoading(true));
        fetchRequest(teacherDelete, 'POST', params, false)
            .then(response => {
                if (response.success) {
                    setDeleteModal(false);
                    setTeachers(response.data.teachers);
                    message(response.data.message, true);
                } else {
                    message(response.data.message, false);
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
            });
    };

    const handleChangeTypeModalClose = () => {
        setChangeTypeModal(false);
    };

    const handleMovementModalClose = () => {
        setMovementModal(false);
    };

    const handleChangePasswordModalClose = () => {
        setChangePasswordModal(false);
    };

    const handleInviteModalClose = () => {
        setInviteModal(false);
    };

    const handleChangeTypeOnSaveClick = () => {
        const [ formValid, formValue ] = changeTypeFormRef.current.validate();

        if (formValid) {
            const params = {
                school: selectedSchool.id,
                teacher: selectedRecord ? selectedRecord.id : null,
                description: formValue?.[1]?.value,
                type: formValue?.[0]?.value,
                department: selectedTreeId && selectedTreeId.length > 0 ? selectedTreeId[0] : '',
                current_type: selectedType.id
            };

            dispatch(setLoading(true));
            fetchRequest(teacherChangeType, 'POST', params, false)
                .then(response => {
                    if (response.success) {
                        setChangeTypeModal(false);
                        setTeachers(response.data.teachers);
                        message(response.data.message, true);
                    } else {
                        message(response.data.message, false);
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                });
        }
    };

    const handleMovementAction = (record) => {
        if(record){
            let params = {
                school: selectedSchool.id,
                teacher: record.id
            };

            dispatch(setLoading(true));
            fetchRequest(teacherMovement, 'GET', params)
                .then(response => {
                    if (response.success) {
                        setSelectedRecord(record);
                        setMovementModal(true);
                        setMvSchools(response.data.schools.map(school => ({value: school.id, text: school.shortName, departments: school.departments})));
                        setMvStatuses(response.data.statuses.map(status => ({value: status.id, text: status.name })));
                    } else {
                        message(response.data.message, false);
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                });
        }
    };

    const handleMovementOnSaveClick = () => {
        const [ formValid, formValue ] = movementFormRef.current.validate();

        if (formValid) {
            const params = {
                school: selectedSchool.id,
                teacher: selectedRecord ? selectedRecord.id : null,
                movementSchool: formValue?.[0]?.value,
                department: formValue?.[1]?.value,
                depPosition: formValue?.[2]?.value,
                status: formValue?.[3]?.value,
                description: formValue?.[4]?.value,
            };

            dispatch(setLoading(true));
            fetchRequest(teacherMovement, 'POST', params, false)
                .then(response => {
                    if (response.success) {
                        setMovementModal(false);
                        setTeachers(response.data.teachers);
                        message(response.data.message, true);
                    } else {
                        message(response.data.message, false);
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                })
        }
    };

    const handleChangePasswordOnSaveClick = () => {
        const [ formValid, formValue ] = changePasswordFormRef.current.validate();

        if (formValid) {
            const params = {
                school: selectedSchool.id,
                teacher: selectedRecord ? selectedRecord.id : null,
                password: formValue?.[0]?.value,
                passwordRepeat: formValue?.[1]?.value,
            };

            dispatch(setLoading(true));
            fetchRequest(teacherChangePassword, 'POST', params, false)
                .then(response => {
                    if (response.success) {
                        setChangePasswordModal(false);
                        message(response.data.message, true);
                    } else {
                        message(response.data.message, false);
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        }
    };

    const handleInviteOnSaveClick = (data) => {
        if(data){
            let array = data.split("_");
            if(array && array.length > 0){
                const params = {
                    school: selectedSchool.id,
                    teacher: array[0],
                    department: array[1]
                };

                dispatch(setLoading(true));
                fetchRequest(teacherInvite, 'POST', params, false)
                    .then(response => {
                        if (response.success) {
                            setInviteModal(false);
                            message(response.data.message, true);
                            getTeacherList();
                        } else {
                            message(response.data.message, false);
                            dispatch(setLoading(false));
                        }
                    })
                    .catch(() => {
                        dispatch(setLoading(false));
                        message(t('errorMessage.title'))
                    })
            }
        }
    };

    const onSchoolChange = (id) => {
        if(id)
        {
            for(let i = 0; i < mvSchools.length; i++)
            {
                if(id === mvSchools[i]['value'])
                {
                    setMvDepartments(mvSchools[i].departments);
                }
            }

            setMvSchoolId(id);
            setMvDepartmentId(null);
            setMvPositionId(null);
            setMvPositions([]);
        }
    };

    const onDepartmentChange = (id) => {
        if(id)
        {
            for(let i = 0; i < mvDepartments.length; i++)
            {
                if(id === mvDepartments[i]['id'])
                {
                    setMvPositions(mvDepartments[i].positions.map(position => ({ value: position.id, text: position.name })));
                }
            }

            setMvDepartmentId(id)
        }
    };

    const onPositionChange = (id) => {
        if(id)
        {
            setMvPositionId(id)
        }
    };

    const inviteTeacher = () => {
        setInviteModal(true);
    };

    const changeTypeForms = [
        {
            label: t('common.status'),
            value: null,
            errorMessage: t('errorMessage.selectStatus'),
            required: true,
            type: 'dropdown',
            options: changeTypeFormOptions,
            multiple: false,
            clearable: true
        },
        {
            label: t('common.description'),
            value: '',
            required: false,
            type: 'text',
        },
    ];

    const movementForms = [
        {
            label: t('school.title') + '*',
            value: mvSchoolId,
            errorMessage: t('errorMessage.selectStatus'),
            required: true,
            type: 'dropdown',
            options: mvSchools,
            multiple: false,
            name: 'school',
            onChange: onSchoolChange,
            clearable: true
        },
        {
            label: t('movement.class.type') + '*',
            value: mvDepartmentId,
            errorMessage: t('errorMessage.selectStatus'),
            required: true,
            type: 'dropdown',
            options: mvDepartments,
            multiple: false,
            name: 'department',
            onChange: onDepartmentChange,
            clearable: true
        },
        {
            label: t('department.position') + '*',
            value: mvPositionId,
            errorMessage: t('errorMessage.selectStatus'),
            required: true,
            type: 'dropdown',
            options: mvPositions,
            multiple: false,
            name: 'position',
            onChange: onPositionChange,
            clearable: true
        },
        {
            label: t('common.type') + '*',
            value: null,
            errorMessage: t('errorMessage.selectStatus'),
            required: true,
            type: 'dropdown',
            options: mvStatuses,
            multiple: false,
            clearable: true
        },
        {
            label: t('common.description'),
            value: '',
            required: false,
            type: 'text',
        },
    ];

    const changePasswordForms = [
        {
            label: t('common.newPassword'),
            value: '',
            required: true,
            errorMessage: t('errorMessage.enterNewPassword'),
            type: 'text',
        },
        {
            label: t('common.newRePassword'),
            value: '',
            required: true,
            errorMessage: t('errorMessage.enterNewRePassword'),
            type: 'text',
        },
    ];

    return (
        <div className='row'>
            <div className='col-4'>
                <Card>
                    <Card.Body>
                        <TreeView
                            treeData={departments}
                            selectedNodes={selectedTreeId}
                            onSelect={(e, data) => handleChange(e, data)}
                            defaultExpandAll={true}
                        />
                    </Card.Body>
                </Card>
            </div>
            <div className='col-8 pl-0'>
                <Card>
                    <Card.Body>
                        <div className='row mb-2'>
                            <div className='col-5'>
                                <Select
                                    options={types}
                                    value={selectedType ? selectedType.id : null}
                                    onChange={onSelect}
                                    searchable
                                />
                            </div>
                        </div>
                        {
                            departments && departments.length > 0
                            ?
                                <ButtonToolbar className='d-inline'>
                                    <Link
                                        to={{
                                            pathname: '/school/teacher_new',
                                            state: {
                                                departmentId: selectedTreeId && selectedTreeId.length > 0 ? selectedTreeId[0] : null
                                            }
                                        }}
                                        className="btn btn-primary btn-shadow mr-2"
                                    >
                                        {t('common.add')}
                                    </Link>
                                    <Button
                                        className="btn btn-primary btn-shadow"
                                        onClick={inviteTeacher}
                                    >
                                        {t('teacher.invite')}
                                    </Button>
                                </ButtonToolbar>
                            : null
                        }
                        <Button className='bg-white border-white d-inline btn-hover-less' onClick={handleHelperModal}>
                            <i className="icon-2x text-danger flaticon-questions-circular-button d-inline"/>
                            <p className='text-danger d-inline'><b>{t('common.helper')}</b></p>
                        </Button>
                        <DTable
                            data={teachers}
                            columns={columns}
                            config={config}
                            onInteraction={handleTableInteraction}
                            totalDataSize={totalCount}
                            remote
                            showOrdering={true}
                            contextMenus={contextMenus}
                            onContextMenuItemClick={handleContextMenuClick}
                            selectMode={'radio'}
                        />
                    </Card.Body>
                </Card>
            </div>
            <Modal
                show={helperModal}
                onHide={handleHelperModalClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Modal heading
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Centered Modal</h4>
                    <p>
                        Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
                        dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta
                        ac consectetur ac, vestibulum at eros.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleHelperModalClose}>{t('common.close')}</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={viewModal}
                onHide={handleViewModalClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t('common.view')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        selectedRecord
                            ?
                            <Form>
                                <Form.Group as={Row} className='form-padding-2'>
                                    <Form.Label column sm="4" className='text-right'>
                                        {t('common.code')}
                                    </Form.Label>
                                    <Col sm="8">
                                        <div className='form-label col-form-label font-w-bold'>{selectedRecord.code}</div>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className='form-padding-2'>
                                    <Form.Label column sm="4" className='text-right'>
                                        {t('common.lastName')}
                                    </Form.Label>
                                    <Col sm="8">
                                        <div className='form-label col-form-label font-w-bold'>{selectedRecord.lastName}</div>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className='form-padding-2'>
                                    <Form.Label column sm="4" className='text-right'>
                                        {t('common.firstName')}
                                    </Form.Label>
                                    <Col sm="8">
                                        <div className='form-label col-form-label font-w-bold'>{selectedRecord.firstName}</div>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className='form-padding-2'>
                                    <Form.Label column sm="4" className='text-right'>
                                        {t('common.currentType')}
                                    </Form.Label>
                                    <Col sm="8">
                                        <div className='form-label col-form-label font-w-bold'>{selectedType ? selectedType.name : ''}</div>
                                    </Col>
                                </Form.Group>
                            </Form>
                            : null
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleViewModalClose}>{t('common.close')}</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={deleteModal}
                onHide={handleDeleteModalClose}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t('warning.delete')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {t('warning.delete_confirmation')}
                        <br/>
                        <br/>
                        {t('warning.delete_confirmation_description')}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="btn btn-link bolder" onClick={handleDeleteModalClose}>{t('common.cancel')}</Button>
                    <Button variant="success btn-shadow" onClick={handlerOnDeleteClick}>{t('warning.delete')}</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={changeTypeModal}
                onHide={handleChangeTypeModalClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t('common.changeType')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        selectedRecord
                            ?
                            <Form>
                                <Form.Group as={Row} className='form-padding-2'>
                                    <Form.Label column sm="4" className='text-right'>
                                        {t('common.code')}
                                    </Form.Label>
                                    <Col sm="8">
                                        <div className='form-label col-form-label font-w-bold'>{selectedRecord.code}</div>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className='form-padding-2'>
                                    <Form.Label column sm="4" className='text-right'>
                                        {t('common.lastName')}
                                    </Form.Label>
                                    <Col sm="8">
                                        <div className='form-label col-form-label font-w-bold'>{selectedRecord.lastName}</div>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className='form-padding-2'>
                                    <Form.Label column sm="4" className='text-right'>
                                        {t('common.firstName')}
                                    </Form.Label>
                                    <Col sm="8">
                                        <div className='form-label col-form-label font-w-bold'>{selectedRecord.firstName}</div>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className='form-padding-2'>
                                    <Form.Label column sm="4" className='text-right'>
                                        {t('common.currentType')}
                                    </Form.Label>
                                    <Col sm="8">
                                        <div className='form-label col-form-label font-w-bold'>{selectedType ? selectedType.name : ''}</div>
                                    </Col>
                                </Form.Group>
                            </Form>
                            : null
                    }
                    <div className='col-12'>
                        <div className='col-8'>
                            <Forms
                                ref={changeTypeFormRef}
                                fields={changeTypeForms}
                            />
                        </div>
                        <div className='col-4'>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleChangeTypeModalClose} variant='link'><b>{t('common.back')}</b></Button>
                    <Button onClick={handleChangeTypeOnSaveClick} variant='success'>{t('common.save')}</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={movementModal}
                onHide={handleMovementModalClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t('common.changeType')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        selectedRecord
                            ?
                            <Form>
                                <Form.Group as={Row} className='form-padding-2'>
                                    <Form.Label column sm="4" className='text-right'>
                                        {t('common.code')}
                                    </Form.Label>
                                    <Col sm="8">
                                        <div className='form-label col-form-label font-w-bold'>{selectedRecord.code}</div>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className='form-padding-2'>
                                    <Form.Label column sm="4" className='text-right'>
                                        {t('common.lastName')}
                                    </Form.Label>
                                    <Col sm="8">
                                        <div className='form-label col-form-label font-w-bold'>{selectedRecord.lastName}</div>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className='form-padding-2'>
                                    <Form.Label column sm="4" className='text-right'>
                                        {t('common.firstName')}
                                    </Form.Label>
                                    <Col sm="8">
                                        <div className='form-label col-form-label font-w-bold'>{selectedRecord.firstName}</div>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className='form-padding-2'>
                                    <Form.Label column sm="4" className='text-right'>
                                        {t('common.currentType')}
                                    </Form.Label>
                                    <Col sm="8">
                                        <div className='form-label col-form-label font-w-bold'>{selectedType ? selectedType.name : ''}</div>
                                    </Col>
                                </Form.Group>
                            </Form>
                            : null
                    }
                    <div className='col-12'>
                        <div className='col-8'>
                            <Forms
                                ref={movementFormRef}
                                fields={movementForms}
                                // onDropdownChange={onDropdownChange}
                            />
                        </div>
                        <div className='col-4'>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleMovementModalClose} variant='link'><b>{t('common.back')}</b></Button>
                    <Button onClick={handleMovementOnSaveClick} variant='success'>{t('common.save')}</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={changePasswordModal}
                onHide={handleChangePasswordModalClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t('common.changePassword')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='col-12 mb15'>
                        <span className='warning-red'>{t('warning.changePasswordDescription')}</span>
                    </div>
                    <div className='col-12'>
                        <div className='col-8'>
                            <Forms
                                ref={changePasswordFormRef}
                                fields={changePasswordForms}
                            />
                        </div>
                        <div className='col-4'>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleChangePasswordModalClose} variant='link'><b>{t('common.back')}</b></Button>
                    <Button onClick={handleChangePasswordOnSaveClick} variant='success'>{t('common.save')}</Button>
                </Modal.Footer>
            </Modal>
            {
                inviteModal
                ?
                    <InviteModal
                        onClose={handleInviteModalClose}
                        onSave={handleInviteOnSaveClick}
                        selectedDepartmentId={selectedTreeId && selectedTreeId.length > 0 ? selectedTreeId[0] : null}
                    />
                : null
            }
        </div>
    )
}

export default Teacher;