import React, { useState, useEffect } from 'react';
import { Card, ButtonToolbar, Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import DTable from "../../../modules/DataTable/DTable";
import Instruction from "../../../modules/instruction";
import { fetchRequest } from "../../../../utils/fetchRequest";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../../redux/action";
import { hrInit, hrDelete } from "../../../../utils/fetchRequest/Urls";
import PositionSchool from "./PositionSchool";
import { useHistory } from "react-router-dom";
import message from "../../../modules/message";
import DeleteModal from "../../../modules/DeleteModal";

const Roles = (props) => {
    const history = useHistory();
    const [ positions, setPositions ] = useState([]);
    const [ hrId, setHrId ] = useState(null);
    const [ showModal, setShowModal ] = useState(false);
    const [ key, setKey ] = useState('role');
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    useEffect(() => {
        dispatch(setLoading(true));
        const params = {
            school: schoolId,
        };
        fetchRequest(hrInit, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    if (data?.positions?.length) {
                        setPositions(data.positions);
                    }
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
            })
    }, []);

    const config = {
        showPagination: false,
        showAllData: true,
    };

    const columns = [
        {
            dataField: "code",
            text: t('common.code'),
            sort: true,
            classes: '',
            headerStyle() {
                return {
                    // width: 30,
                }
            },
        },
        {
            dataField: "name",
            text: t('common.name'),
            sort: true,
        },
    ];

    const contextMenus = [
        {
            key: 'edit',
            title: t('common.edit'),
            icon: <i className='las la-edit dt-cm-item-icon'/>
        },
        {
            key: 'delete',
            title: t('common.delete'),
            icon: <i className='las la-trash-alt dt-cm-item-icon' />,
        },
    ];

    const handleContextMenuClick = (id, key) => {
        if(id && key)
        {
            if(key === 'edit')
            {
                history.push({
                    pathname: '/structure/role_edit',
                    state: {
                        id: id,
                    }
                });
            } else if (key === 'delete'){
                setHrId(id);
                setShowModal(true);
            }
        }
    };

    const onDelete = () => {
        const params = {
            id: hrId,
            school: schoolId
        };
        dispatch(setLoading(true));
        fetchRequest(hrDelete, 'POST', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setPositions(data.positions || []);
                    onModalClose();
                    message(res?.data?.message || t('common.success'), true);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const onModalClose = () => {
        setShowModal(false);
        setHrId(null);
    };

    const handleTabChange = key => {
        setKey(key)
    };

    return (
        <div className='row'>
            <div className='col-12'>
                <Card className='card-header-with-tab'>
                    <Card.Header>
                        <Tabs
                            id={'msue-roles'}
                            activeKey={key}
                            className='msue-tab'
                            onSelect={handleTabChange}
                        >
                            <Tab eventKey={'role'} title={t('menu.roles')}/>
                            <Tab eventKey={'schoolRole'} title={t('menu.schoolRoles')}/>
                        </Tabs>
                    </Card.Header>
                    <Card.Body>
                        <div className='col-12 p-0'>
                            {
                                key === 'role'
                                    ?
                                    <>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <ButtonToolbar className='d-inline mr-3'>
                                                <Link
                                                    to={{
                                                        pathname: '/structure/role_new',
                                                        state: {
                                                            action: 'role',
                                                        }
                                                    }}
                                                    className="btn btn-primary btn-shadow"
                                                >
                                                    {t('common.add')}
                                                </Link>
                                            </ButtonToolbar>
                                            <Instruction/>
                                        </div>

                                        <DTable
                                            className='tl-auto'
                                            data={positions}
                                            columns={columns}
                                            // onInteraction={handleTableInteraction}
                                            config={config}
                                            contextMenus={contextMenus}
                                            onContextMenuItemClick={handleContextMenuClick}
                                            selectMode={'radio'}
                                        />
                                    </>
                                    : null
                            }
                            {
                                key === 'schoolRole'
                                    ?   <PositionSchool/>
                                    :
                                    null
                            }
                        </div>
                    </Card.Body>
                </Card>
            </div>
            {
                showModal && hrId && (
                    <DeleteModal
                        onClose={onModalClose}
                        onDelete={onDelete}
                        title={t('department.position')}
                    >
                        {t('warning.delete_confirmation')}
                        <br/>
                        <br/>
                        {t('warning.delete_confirmation_description')}
                    </DeleteModal>
                )
            }
        </div>
    )
};

export default Roles;