import React from "react";
import { Card } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useRef, useState, useEffect } from "react";
import message from "../../../modules/message";
import { fetchRequest } from "../../../../utils/fetchRequest";
import DTable from "../../../modules/DataTable/DTable";
import { setLoading } from "../../../../redux/action";
import { useTranslation } from "react-i18next";
import RegSearch from "./search";
import ProfileEdit from "./profileEdit";
import "./reg.scss";
import { useDispatch, useSelector } from "react-redux";
import { candidateProfileEdit, nonRegisteredCandidatesList, sendEmail } from "../../../../utils/fetchRequest/Urls";
import SendEmail from "./emailModal";
import { cloneDeep } from "lodash";

const NonRegistrationUser = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [tableStoreData] = useState('enroll_non_register_table_index');
    
    const [totalCount, setTotalCount] = useState(0);
    const [list, setList] = useState([]);
    const [notRegisteredUserIds, setNotRegisteredUserIds] = useState([]);
    const [candidateId, setCandidateId] = useState(null);
    const [showProfileEditModal, setShowProfileEditModal] = useState(false);
    const [showEmailModal, setShowEmailModal] = useState(false);
    const selectedSchool = useSelector((state) => state?.selectedSchool || null);

    const [tableState, setTableState] = useState(JSON.parse(localStorage.getItem(tableStoreData)) ||
        {
            page: 1,
            pageSize: 10,
            search: '',
            sort: 'firstName',
            order: 'asc'
        }
    )

    useEffect(() => {
        let params = {
            school: selectedSchool.id,
            page: tableState.page,
            pageSize: tableState.pageSize,
            search: tableState.search,
            sort: tableState.sort,
            order: tableState.order
        };
        init(params);
    }, []);

    async function init(params) {
        dispatch(setLoading(true));
        fetchRequest(nonRegisteredCandidatesList, "GET", params)
            .then((res) => {
                if (res.success) {
                    const { data } = res;

                    let listData = data?.list || []

                    if(notRegisteredUserIds && notRegisteredUserIds.length > 0){
                        if(listData && listData.length > 0){
                            for(let i = 0; i < listData.length; i++){
                                let existingCandidate = notRegisteredUserIds.find(user => user.id == listData[i].id)

                                if(existingCandidate){
                                    listData[i].checkable = existingCandidate.checkable;
                                }
                                console.log(existingCandidate)
                            }
                        }
                    } else {
                        setNotRegisteredUserIds(data?.notRegisteredUserIds || [])
                    }

                    setList(listData);
                    setTotalCount(data?.totalCount || 0)
                } else {
                    message(res?.data?.message || t("errorMessage.title"));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t("errorMessage.title"));
            });
    }

    const config = {
        excelExport: true,
        printButton: true,
        columnButton: true,
        defaultSort: [{
            dataField: tableState?.sort || 'takenDate',
            order: tableState?.order || 'desc'
        }],
        defaultPageOptions: {
            page: tableState?.page || 1,
            sizePerPage: tableState?.pageSize || 10,
            search: tableState?.search || '',
        },
        excelFileRemote: true,
        excelFileRemoteUrl: `candidate-admin/excel/non-registered?school=` + selectedSchool.id + 
            '&sort=' + tableState.sort + 
            '&order=' + tableState.order + 
            '&t=/'
    }

    const columns = [
        {
            dataField: "createdDate",
            text: "Бүртгүүлсэн огноо",
            sort: true,
        },
        {
            dataField: "email",
            text: t("common.email"),
            sort: true,
        },
        {
            dataField: "registrationNumber",
            text: "Регистрийн дугаар ",
            sort: true,
        },
        {
            dataField: "lastName",
            text: "Овог",
            sort: true,
        },
        {
            dataField: "firstName",
            text: "Нэр",
            sort: true,
        },
        {
            dataField: "birthDate",
            text: "Төрсөн огноо",
            sort: true,
        },
        {
            dataField: "gender",
            text: "Хүйс",
            sort: true,
        },
        {
            dataField: "gMobileNumber",
            text: "Холбоо барих",
            sort: true,
        },
    ];

    const contextMenus = [
        {
            key: 'edit',
            title: t('enroll.profileEdit'),
            icon: <i className='las la-edit dt-cm-item-icon'/>
        }
    ];

    const handleContextMenuClick = (id, key) => {
        if (id && key) {
            if (key == 'edit') {
                setCandidateId(id)
                setShowProfileEditModal(true)
            }
        }
    };

    const onModalClose = () => {
        setShowProfileEditModal(false);
    };

    const onModalEmailClose = () => {
        setShowEmailModal(false);
    };

    const onSubmitProfileEdit = (params) => {
        dispatch(setLoading(true));
        fetchRequest(candidateProfileEdit, "POST", params)
            .then((res) => {
                if (res.success) {
                    let cloneData = {
                        page: tableState.page,
                        pageSize: tableState.pageSize,
                        search: tableState.search,
                        sort: tableState.sort,
                        order: tableState.order,
                        school: selectedSchool.id,
                    };
                    init(cloneData);

                    message(res?.data?.message, true);
                    setShowProfileEditModal(false);
                    dispatch(setLoading(false));
                } else {
                    message(res.data.message, false);
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t("errorMessage.title"));
            });
    }

    const onEmailSubmit = (params) => {
        dispatch(setLoading(true));
        fetchRequest(sendEmail, "POST", params)
            .then((res) => {
                if (res.success) {
                    message(res.data.message, true);
                    setShowEmailModal(false);
                } else {
                    message(res.data.message, false);
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
            });
    }

    const handleInteraction = tableObject => {
        if(tableObject.search){
            let cloneData = {
                page: tableState.search == tableObject.search ? tableObject.page : 1,
                pageSize: tableObject.pageSize,
                search: tableObject.search,
                sort: tableObject.sort,
                order: tableObject.order,
            };

            setTableState(cloneData)
            localStorage.setItem(tableStoreData, JSON.stringify(cloneData));

            cloneData.school = selectedSchool.id;
            init(cloneData)
        } else {
            if(tableObject.page){
                setTableState(tableObject)
                let cloneData = {
                    page: tableObject.page,
                    pageSize: tableObject.pageSize,
                    search: tableObject.search,
                    sort: tableObject.sort,
                    order: tableObject.order
                };

                localStorage.setItem(tableStoreData, JSON.stringify(cloneData));

                cloneData.school = selectedSchool.id;
                init(cloneData)
            }
        }
    }

    const checkboxChange = (row, index, isChecked, id) => {
        console.log(row, index, isChecked, id)
        let cloneList = [...list];
        let cloneNotRegisteredUserIds = [...notRegisteredUserIds]; 

        if (row == 'row') {
            if(cloneList && cloneList.length > 0){
                for(let i = 0; i < cloneList.length; i++){
                    if(cloneList[i].id == id){
                        cloneList[i].checkable = isChecked;
                    }
                }
            }

            if(cloneNotRegisteredUserIds && cloneNotRegisteredUserIds.length > 0){
                for(let i = 0; i < cloneNotRegisteredUserIds.length; i++){
                    if(cloneNotRegisteredUserIds[i].id == id){
                        cloneNotRegisteredUserIds[i].checkable = isChecked;
                        console.log(cloneNotRegisteredUserIds[i])
                    }
                }
            }
        } else if (row == 'allCheck') {
            if(cloneList && cloneList.length > 0){
                for(let i = 0; i < cloneList.length; i++){
                    cloneList[i].checkable = isChecked;
                }
            }

            if(cloneNotRegisteredUserIds && cloneNotRegisteredUserIds.length > 0){
                for(let i = 0; i < cloneNotRegisteredUserIds.length; i++){
                    cloneNotRegisteredUserIds[i].checkable = isChecked;
                }
            }
        }

        setNotRegisteredUserIds(cloneNotRegisteredUserIds)
        setList(cloneList)
    }

    const handleAddEmailClick = () => {
        setShowEmailModal(true);
    };

    return (
        <>
            <div className="table-component">
                <Card>
                    <Card.Body>
                        <div>
                            <button
                                className="btn btn-primary shadow"
                                onClick={handleAddEmailClick}
                            >
                                И-мэйл явуулах
                            </button>
                        </div>
                        <DTable
                            remote
                            columns={columns}
                            config={config}
                            data={list}
                            selectMode={'radio'}
                            contextMenus={contextMenus}
                            onContextMenuItemClick={handleContextMenuClick}
                            onInteraction={handleInteraction}
                            totalDataSize={totalCount}
                            checkable
                            onCheckable={checkboxChange}
                        />
                    </Card.Body>
                </Card>
            </div>
            {
                showProfileEditModal && 
                <ProfileEdit
                    id={candidateId} 
                    onClose={onModalClose} 
                    onSubmit={onSubmitProfileEdit}
                />
            }
            {
                showEmailModal && 
                <SendEmail
                    candidateList={notRegisteredUserIds}
                    onClose={onModalEmailClose}
                    onSubmit={onEmailSubmit}
                />
            }
        </>
    );
};

export default NonRegistrationUser;
