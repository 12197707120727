import { Card } from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import Forms from "../../../modules/Form/Forms";
import { useTranslation } from "react-i18next";
import "./enrollSearchComponent.scss";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import { getProgramsBySchool } from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import { useDispatch } from "react-redux";

const EnrollSearchComponent = ({ onSearch, schools }) => {
    const form = useRef();
    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [schoolId, setSchoolId] = useState(null);
    const [programId, setProgramId] = useState(null);
    const [programs, setPrograms] = useState(null);
    const { t } = useTranslation();

    const onDateRangeChange = (dates) => {
        if (dates && dates.length > 0) {
            if (dates[0].startDate) {
                setStartDate(dates[0].startDate);
            }
            if (dates[0].endDate) {
                setEndDate(dates[0].endDate);
            }
        }
    };

    useEffect(() => {
        form?.current?.updateFields && form.current.updateFields(fields);
    }, [schools, programs]);

    const onSchoolChange = (id) => {
        setSchoolId(id);

        let params = {
            school: id
        }

        dispatch(setLoading(true));
        fetchRequest(getProgramsBySchool, "GET", params)
            .then((res) => {
                if (res.success) {
                    const { data } = res;
                    setPrograms(data?.list || []);
                } else {
                    message(res?.data?.message || t("errorMessage.title"));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t("errorMessage.title"));
            });
    };

    const onProgramChange = (id) => {
        setProgramId(id)
    };

    const fields = [
        {
            label: "Сургууль",
            value: schoolId || null,
            type: "dropdown",
            clearable: true,
            inputWidth: 180,
            required: false,
            key: "school",
            options: schools,
            onChange: onSchoolChange,
        },
        {
            label: "Мэргэжил",
            value: programId || null,
            required: false,
            type: "dropdown",
            multiple: false,
            clearable: true,
            inputWidth: 180,
            key: "program",
            options: programs || [],
            onChange: onProgramChange,
        },
        // {
        //     key: "daterange",
        //     label: t("common.date"),
        //     value: "",
        //     type: "daterange",
        //     clearable: true,
        //     inputWidth: 300,
        //     firstPlaceHolder: t("common.startDate"),
        //     lastPlaceHolder: t("common.endDate"),
        //     onChange: onDateRangeChange,
        // },
    ];

    const handleSearch = () => {
        const [formValid, formValue] = form.current.validate();
        if (formValid) {
            const params = {
                subSchool: formValue?.[0].value,
                program: formValue?.[1].value,
                startDate: startDate,
                endDate: endDate,
            };
            Object.keys(params).forEach((key) => {
                if (
                    params[key] === undefined ||
                    params[key] === null ||
                    params[key] === ""
                ) {
                    delete params[key];
                }
            });
            onSearch(params);
        }
    };

    return (
        <Card>
            <div className="my-form d-flex pt-6 pb-8 justify-content-center align-items-center flex-wrap">
                <div>
                    <Forms ref={form} fields={fields} />
                </div>
                <div className="ml-2 pt-2">
                    <button
                        variant="text"
                        className="btn bg-warning"
                        onClick={handleSearch}
                    >
                        <i className="flaticon-search-1" style={{ color: "#000" }}></i>

                        <span style={{ color: "#000" }}>
                            {t("common.search").toUpperCase()}
                        </span>
                    </button>
                </div>
            </div>
        </Card>
    );
};

export default EnrollSearchComponent;
