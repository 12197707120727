import React, { useState, useRef, useEffect } from 'react';
import Forms from "../../../modules/Form/Forms";
import { fetchRequest } from "../../../../utils/fetchRequest";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../../redux/action";
import { useTranslation } from "react-i18next";
import message from "../../../modules/message";
import { getSchoolClassEdit, getAcademicPrograms, getPrograms } from "../../../../utils/fetchRequest/Urls";
import { Button, Card } from "react-bootstrap";
import {Link, useHistory, useLocation} from "react-router-dom";

const ClassEdit = ({
    location: { state: {
        classId = null,
        selectedSeason = null
    } = {} } = {},
}) => {
    const forms = useRef(null);
    const forms2 = useRef(null);
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const { t } = useTranslation();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [ departments, setDepartments ] = useState([]);
    const [ departmentId, setDepartmentId ] = useState(null);
    const [ academicPrograms, setAcademicPrograms ] = useState([]);
    const [ academicProgramId, setAcademicProgramId ] = useState(null);
    const [ programs, setPrograms ] = useState([]);
    const [ teachers, setTeachers ] = useState([]);
    const [ approaches, setApproaches ] = useState([]);
    const [ selectedProgram, setSelectedProgram ] = useState([]);
    const [ classData, setClassData ] = useState([]);
    const [ schoolShifts, setSchoolShifts ] = useState([]);

    useEffect(() => {
        const params = {
            school: schoolId,
            class: classId
        };
        dispatch(setLoading(true));
        fetchRequest(getSchoolClassEdit, 'GET', params)
            .then(resInit => {
                if (resInit.success) {
                    if (resInit.data?.departments?.length)
                    {
                        setDepartments(resInit.data.departments);
                    }
                    if (resInit.data?.teachers?.length)
                    {
                        setTeachers(resInit.data.teachers);
                    }
                    if (resInit.data?.approaches?.length)
                    {
                        setApproaches(resInit.data.approaches.map(approach => ({ value: approach.id, text: approach.name })))
                    }
                    if (resInit.data?.schoolShifts?.length)
                    {
                        setSchoolShifts(resInit.data.schoolShifts);
                    }
                    setClassData(resInit.data.classData);
                    setAcademicProgramId(resInit.data.classData.academicProgramId)
                    setDepartmentId(resInit.data.classData.departmentId)

                    let academicParams = {
                        school: schoolId,
                        department: resInit.data.classData.departmentId
                    };

                    dispatch(setLoading(true));
                    fetchRequest(getAcademicPrograms, 'GET', academicParams)
                        .then(resAcademic => {
                            if (resAcademic.success) {
                                if (resAcademic.data?.academicPrograms?.length) {
                                    setAcademicPrograms(resAcademic.data.academicPrograms.map(academicProgram => ({ value: parseInt(academicProgram.id), text: academicProgram.name })));
                                }
                            } else {
                                message(resAcademic?.data?.message || t('errorMessage.title'))
                            }
                            dispatch(setLoading(false));
                        })
                        .catch(() => {
                            dispatch(setLoading(false));
                        })

                    let programParams = {
                        school: schoolId,
                        department: resInit.data.classData.departmentId,
                        academicProgram: resInit.data.classData.academicProgramId,
                    };

                    dispatch(setLoading(true));
                    fetchRequest(getPrograms, 'GET', programParams)
                        .then(res => {
                            if (res.success) {
                                const { data } = res;
                                setPrograms(data.programs)
                                if(data.programs && data.programs.length > 0){
                                    let selectedProgram = data.programs.find(program => (program.value === parseInt(resInit.data.classData.programId)));
                                    setSelectedProgram(selectedProgram);
                                }
                            } else {
                                message(res?.data?.message || t('errorMessage.title'))
                            }
                            dispatch(setLoading(false));
                        })
                        .catch(() => {
                            dispatch(setLoading(false));
                        })
                } else {
                    message(resInit?.data?.message || t('errorMessage.title'))
                }
            })
            .catch(() => {
                dispatch(setLoading(false));
            })
    }, []);

    useEffect(() => {
        forms?.current?.updateFields && forms.current.updateFields(fields);
    }, [departments, programs, academicPrograms, departmentId, academicProgramId, classData]);

    useEffect(() => {
        forms2?.current?.updateFields && forms2.current.updateFields(fields2);
    }, [approaches, teachers, classData,schoolShifts]);

    const onDepartmentChange = (id, evt) => {
        if (id) {
            setDepartmentId(id);
            setAcademicProgramId(null);
            setPrograms([]);
            setAcademicPrograms([]);
            const params = {
                school: schoolId,
                department: id
            };

            dispatch(setLoading(true));
            fetchRequest(getAcademicPrograms, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res;
                        if (data?.academicPrograms?.length) {
                            setAcademicPrograms(data.academicPrograms.map(academicProgram => ({ value: academicProgram.id, text: academicProgram.name })));
                        }
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                })
        }
    };

    const onAcademicProgramChange = (id, evt) => {
        if (id) {
            setPrograms([]);
            setAcademicProgramId(id);
            const params = {
                school: schoolId,
                department: departmentId,
                academicProgram: id,
            };
            dispatch(setLoading(true));
            fetchRequest(getPrograms, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res;
                        setPrograms(data.programs)
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                })
        }
    };

    const onProgramChange = (id, evt) => {
        if (id) {
            let selectedProgram = programs.find(program => (program.value === id));
            setSelectedProgram(selectedProgram);
        }
    };

    const fields = [
        {
            label: `${t('school.parentDepartment')}*`,
            type: 'dropdown',
            required: true,
            value: departmentId,
            multiple: false,
            options: departments,
            onChange: onDepartmentChange,
            clearable: true
        },
        {
            label: `${t('curriculum.title')}*`,
            type: 'dropdown',
            required: true,
            value: academicProgramId,
            multiple: false,
            options: academicPrograms,
            onChange: onAcademicProgramChange,
            clearable: true
        },
        {
            label: `${t('curriculum.profession')}*`,
            type: 'dropdown',
            required: true,
            value: classData ? classData.programId : null,
            multiple: false,
            options: programs,
            onChange: onProgramChange,
            clearable: true
        },
    ];

    const fields2 = [
        {
            label: `${t('movement.class.consultantTeacher')}*`,
            type: 'dropdown',
            required: true,
            value: classData ? classData.teacherId : null,
            multiple: false,
            options: teachers,
            clearable: true
        },
        {
            label: `${t('movement.class.admissionCount')}`,
            type: 'numberOnly',
            required: false,
            value: classData ? classData.classLimit : '',
        },
        {
            label: `${t('movement.class.approach')}*`,
            type: 'dropdown',
            required: true,
            value: classData ? classData.approachId : null,
            multiple: false,
            options: approaches,
            clearable: true
        },
        {
            label: `${t('movement.class.shift')}*`,
            type: 'dropdown',
            required: true,
            value: classData ? classData.shiftId : null,
            multiple: false,
            options: schoolShifts,
            clearable: true
        },
    ];

    const handleSaveClick = () => {
        const [ formValid, formValues ] = forms.current.validate();
        const [ form2Valid, form2Values ] = forms2.current.validate();
        if (formValid && form2Valid && selectedSeason && selectedSeason.toString().split('_').length === 4) {
            let selectedSeasonArray = selectedSeason.toString().split('_');
            let params = {
                school: schoolId,
                class: classId,
                department: formValues?.[0]?.value,
                academicProgram: formValues?.[1]?.value,
                program: formValues?.[2]?.value,
                teacher: form2Values?.[0]?.value,
                limit: form2Values?.[1]?.value,
                approach: form2Values?.[2]?.value,
                schoolShift: form2Values?.[3]?.value,
                level: selectedSeasonArray[1],
                season: selectedSeasonArray[3],
            };

            dispatch(setLoading(true));
            fetchRequest(getSchoolClassEdit, 'POST', params, false)
                .then(response => {
                    if (response.success) {
                        message(response.data.message, true);
                        history.push({
                            pathname: '/movement/class',
                            state: {
                                id: selectedSeason,
                            }
                        });
                    } else {
                        message(response?.data?.message, false);
                    }
                })
                .catch(() => {
                    dispatch(setLoading(false));
                })
        }
    };

    return (
        <Card>
            <Card.Body>
                <div className='mb-20'>
                    <div className='row'>
                        <div className='col-12'>
                            <Forms
                                ref={forms}
                                fields={fields}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-3'/>
                        <div className='col-9'>
                            {
                                selectedProgram
                                ?
                                    <table className='mt-10 mb-5'>
                                        <tbody>
                                            <tr>
                                                <td className='text-right pr-10'>{t('curriculum.educationLevel')}</td>
                                                <th>{selectedProgram.educationLevelName}</th>
                                            </tr>
                                            <tr>
                                                <td className='text-right pr-10'>{t('curriculum.approach')}</td>
                                                <th>{selectedProgram.approachName}</th>
                                            </tr>
                                            <tr>
                                                <td className='text-right pr-10'>{t('curriculum.type')}</td>
                                                <th>{selectedProgram.typeName}</th>
                                            </tr>
                                            <tr>
                                                <td className='text-right pr-10'>{t('curriculum.totalCredits')}</td>
                                                <th>{selectedProgram.totalCredit}</th>
                                            </tr>
                                        </tbody>
                                    </table>
                                : null
                            }
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12'>
                            <Forms
                                ref={forms2}
                                fields={fields2}
                            />
                        </div>
                    </div>
                </div>
            </Card.Body>
            <Card.Footer>
                <div className='text-center'>
                    <Link
                        to='/movement/class'
                        className="btn btn-link bolder"
                    >
                        {t('common.back')}
                    </Link>
                    <Button
                        variant="success btn-shadow"
                        onClick={handleSaveClick}
                    >
                        {t('common.save')}
                    </Button>
                </div>
            </Card.Footer>
        </Card>
    )
};

export default ClassEdit;