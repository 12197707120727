import React, {useEffect, useState} from 'react';
import { useTranslation } from "react-i18next";
import DTable from "../../modules/DataTable/DTable";
import { Link } from "react-router-dom";
import {Card, Nav} from "react-bootstrap";
import {setLoading} from "../../../redux/action";
import {fetchRequest} from "../../../utils/fetchRequest";
import {studentFinanceProfile} from "../../../utils/fetchRequest/Urls";
import message from "../../modules/message";
import {useDispatch, useSelector} from "react-redux";
import RenderUserInfo from "../../modules/RenderUserInfo";
import {priceFormat} from "../../../utils/utils";

const StudentView = ({}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    const [tableData, setTableData] = useState([]);
    const [key, setKey] = useState('#TUITION');
    const [labels, setLabels] = useState([]);
    const [balance, setBalance] = useState(0);
    const [backUrl, setBackUrl] = useState('');
    const [studentCode, setStudentCode] = useState(null);
    const [hasOther, setHasOther] = useState([]);
    const [image, setImage] = useState([]);
    const [types, setTypes] = useState([
        {
            id: 'TUITION',
            name: 'Сургалтын төлбөр',
        },
        {
            id: 'DORMITORY',
            name: 'Дотуур байр',
        },
        {
            id: 'PENALTY',
            name: 'Торгууль',
        },
        {
            id: 'DIPLOMA',
            name: 'Ромбо диплом',
        },
        {
            id: 'OTHER',
            name: 'Бусад',
        }
    ]);

    useEffect(() => {
        let studentCode = null;
        let backUrlString = '';
        let searchUrl = window.location.search;
        let paramString = searchUrl.split('?')[1];
        let paramsArr = paramString.split('&');
        for(let i = 0; i < paramsArr.length; i++) {
            let item = paramsArr[i].split('=');
            if(item[0] == 'student'){
                studentCode = item[1];
            } else if (item[0] == 'url'){
                backUrlString = item[1];
            }
        }
        setBackUrl(backUrlString);
        if(studentCode){
            setStudentCode(studentCode)
        }

        const params = {
            school: schoolId,
            code: studentCode,
            invoiceType: key.substring(1)
        };

        init(params)
    }, []);

    const init = (params) => {
        dispatch(setLoading(true));
        fetchRequest(studentFinanceProfile, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    let studentInfo = data?.student || [];

                    let labelArray = [];
                    labelArray.push(
                        {
                            code: 'school',
                            name: t('school.title'),
                            value: studentInfo.schoolName || ''
                        },
                        {
                            code: 'studentCode',
                            name: t('student.code'),
                            value: studentInfo.studentCode || ''
                        },
                        {
                            code: 'lastName',
                            name: t('person.lastName'),
                            value: studentInfo.lastName || ''
                        },
                        {
                            code: 'firstName',
                            name: t('person.firstName'),
                            value: studentInfo.firstName || ''
                        },
                        {
                            code: 'microsoftAccount',
                            name: 'Microsoft account',
                            value: studentInfo.microsoft || ''
                        },
                        {
                            code: 'phoneNumber',
                            name: t('common.phoneNumber'),
                            value: studentInfo.gMobileNumber || ''
                        },
                        {
                            code: 'createdDate',
                            name: t('student.enrollmentDate'),
                            value: studentInfo.start || ''
                        },
                        {
                            code: 'programName',
                            name: t('student.currentProgram'),
                            value: studentInfo.program || ''
                        },
                        {
                            code: 'registerNumber',
                            name: t('person.registrationNumber'),
                            value: studentInfo.registerNumber || ''
                        },
                    );
                    setImage(studentInfo?.avatar || null);
                    setHasOther(data.hasOther);
                    if(!key){
                        if(data.invoiceTypes && data.invoiceTypes.length > 0){
                           setKey('#' + data.invoiceTypes[0].code);
                        }
                    }
                    setListArray(data?.list || []);
                    setLabels(labelArray);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const setListArray = (listArray) => {
        if(listArray && listArray.length > 0){
            for(let i = 0; i < listArray.length; i++){
                if(listArray[i].transactionDate && listArray[i].transactionDate.date){
                    listArray[i].transactionDate = listArray[i].transactionDate.date.substring(0, 16);
                }
                if(listArray[i].createdDate && listArray[i].createdDate.date){
                    listArray[i].createdDate = listArray[i].createdDate.date.substring(0, 16);
                }
            }
        }
        setTableData(listArray)
    };

    const config = {
        showPagination: false,
        showFilter: false,
        footer: true,
        footerStyle: {backgroundColor: '#ebecf5'}
    };

    const column = (key) => {
        if(key == '#TUITION'){
            return ([
                {
                    dataField: 'seasonName',
                    text: t('subject.season'),
                    sort: true,
                    footer: '',
                },
                {
                    dataField: 'createdDate',
                    text: t('common.date'),
                    sort: true,
                    footer: '',
                },
                {
                    dataField: 'type',
                    text: t('common.type'),
                    sort: true,
                    footer: '',
                },
                {
                    dataField: 'description',
                    text: t('common.description'),
                    sort: true,
                    footer: '',
                },
                {
                    dataField: 'income',
                    text: t('finance.income'),
                    sort: true,
                    footerAlign: 'right',
                    footer: '',
                    footerType: 'sum'
                },
                {
                    dataField: 'amount',
                    text: t('finance.deposit'),
                    sort: true,
                    footerAlign: 'right',
                    footer: '',
                    footerType: 'sum'
                },
                {
                    dataField: 'statusCode',
                    text: '',
                    headerStyle: () => {
                        return {
                            width: 150
                        }
                    },
                    style: {padding: 3},
                    formatter(cell) {
                        if (cell && cell.toLowerCase() == 'complete') {
                            return (
                                <div className='flex-center'>
                                    <button className='btn btn-outline-success btn-shadow'>{t('invoiceType.ebarimt')}</button>
                                </div>
                            )
                        }
                        return (
                            <div className='flex-center'>
                                <button className='btn btn-danger btn-shadow'>{t('invoiceType.pay')}</button>
                            </div>
                        )
                    },
                    footer: '',
                }
            ])
        } else if (key == '#DORMITORY'){
            return([
                {
                    dataField: 'date',
                    text: t('common.date'),
                    sort: true,
                    footer: '',
                },
                {
                    dataField: 'type',
                    text: t('common.type'),
                    sort: true,
                    footer: '',
                },
                {
                    dataField: 'description',
                    text: t('common.description'),
                    sort: true,
                    footer: '',
                },
                {
                    dataField: 'income',
                    text: t('common.amount'),
                    sort: true,
                    footerAlign: 'right',
                    footer: '',
                    footerType: 'sum'
                },
                {
                    dataField: 'paid',
                    text: '',
                    headerStyle: () => {
                        return {
                            width: 150
                        }
                    },
                    formatter(cell) {
                        return (
                            <div className='flex-center'>
                                <button className='btn btn-danger btn-shadow button-in-table-cell'>Төлбөр төлөх</button>
                            </div>
                        )
                    },
                    footer: '',
                },
            ]);
        } else if (key == '#PENALTY'){
            return([
                {
                    dataField: 'paidType',
                    text: t('finance.paidType'),
                    sort: true,
                    footer: '',
                },
                {
                    dataField: 'parentName',
                    text: t('school.season.title'),
                    sort: true,
                    footer: '',
                },
                {
                    dataField: 'seasonName',
                    text: t('subject.season'),
                    sort: true,
                    footer: '',
                },
                {
                    dataField: 'paidAmount',
                    text: t('common.amount'),
                    sort: true,
                    footerAlign: 'right',
                    footer: '',
                    footerType: 'sum'
                },
                {
                    dataField: 'createdDate',
                    text: t('common.paidDate'),
                    sort: true,
                    footer: '',
                },
            ]);
        } else if (key == '#DIPLOMA'){
            return([
                {
                    dataField: 'date',
                    text: t('common.date'),
                    sort: true,
                    footer: '',
                },
                {
                    dataField: 'type',
                    text: t('common.type'),
                    sort: true,
                    footer: '',
                },
                {
                    dataField: 'description',
                    text: t('common.description'),
                    sort: true,
                    footer: '',
                },
                {
                    dataField: 'amount',
                    text: t('common.amount'),
                    sort: true,
                    footerAlign: 'right',
                    footer: '',
                    footerType: 'sum'
                },
                {
                    dataField: 'paid',
                    text: '',
                    headerStyle: () => {
                        return {
                            width: 150
                        }
                    },
                    formatter(cell) {
                        return (
                            <div className='flex-center'>
                                <button className='button-in-table-cell'>Төлбөр төлөх</button>
                            </div>
                        )
                    },
                    footer: '',
                },
            ]);
        } else if (key == '#OTHER'){
            return([
                {
                    dataField: 'date',
                    text: t('common.date'),
                    sort: true,
                    footer: '',
                },
                {
                    dataField: 'type',
                    text: t('common.type'),
                    sort: true,
                    footer: '',
                },
                {
                    dataField: 'description',
                    text: t('common.description'),
                    sort: true,
                    footer: '',
                },
                {
                    dataField: 'income',
                    text: t('common.amount'),
                    sort: true,
                    footerAlign: 'right',
                    footer: '',
                    footerType: 'sum'
                },
                {
                    dataField: 'paid',
                    text: '',
                    headerStyle: () => {
                        return {
                            width: 150
                        }
                    },
                    formatter(cell) {
                        return (
                            <div className='flex-center'>
                                <button className='button-in-table-cell'>Төлбөр төлөх</button>
                            </div>
                        )
                    },
                    footer: '',
                },
            ]);
        }
        else {
            return([]);
        }
    };

    const handleTab = (key, e) => {
        if(key){
            setKey(key);
            setTableData([]);

            const params = {
                school: schoolId,
                code: studentCode,
                invoiceType: key.substring(1)
            };

            init(params)
        }
    };

    return (
        <div className='p-5'>
            <Card className='mb-4'>
                <Card.Header>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <span style={{ color: '#4a70ae'}}>
                            <b>{t('student.financeInfo').toUpperCase()}</b>
                        </span>
                        <Link to={backUrl ? backUrl : '/finance/income/bank'}>
                            {t('common.backTo_list')}
                        </Link>
                    </div>
                </Card.Header>
                <Card.Body>
                    <RenderUserInfo
                        labels={labels}
                        image={image}
                        isImage={true}
                    />
                </Card.Body>
            </Card>
            <Card className='card-header-with-tab'>
                <Card.Header>
                    {
                        types && types.length > 0
                            ?
                            <Nav
                                variant="tabs"
                                activeKey={key}
                                onSelect={(key, e) => handleTab(key, e)}
                            >
                                {
                                    types.map(type => {
                                        if(type.id == 'OTHER'){
                                            if(hasOther){
                                                return (
                                                    <Nav.Item key={'type_' + type.id}>
                                                        <Nav.Link href={`#${type.id}`}>{type.name}</Nav.Link>
                                                    </Nav.Item>
                                                )
                                            }
                                        } else {
                                            return (
                                                <Nav.Item key={'type_' + type.id}>
                                                    <Nav.Link href={`#${type.id}`}>{type.name}</Nav.Link>
                                                </Nav.Item>
                                            )
                                        }
                                    })
                                }
                            </Nav>
                            : null
                    }
                </Card.Header>
                <Card.Body>
                    <div><b>{t('common.balance') + ': ' + priceFormat(balance)}</b></div>
                    <DTable
                        data={tableData}
                        columns={column(key)}
                        config={config}
                        selectMode={'radio'}
                    />
                    <div style={{textAlign: "right"}}><b>{t('common.balance') + ': ' + priceFormat(balance)}</b></div>
                </Card.Body>
            </Card>
        </div>
    );
};

export default StudentView;