import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import {
  certificateAdd,
  certificateEdit,
} from "../../../../utils/fetchRequest/Urls";
import Forms from "../../../modules/Form/Forms";
import message from "../../../modules/message";
import Editor from "../../../modules/textEditor";

const EditModal = ({ onClose, certificateId, schoolId, onFinish, filter = {} }) => {
  const [bgImage, setBgImage] = useState(
    "http://192.168.0.132:8000/image/certificate/background_file.jpg"
  );
  const { t } = useTranslation();
  const form = useRef();
  const dispatch = useDispatch();
  
  const [richTextValue, setrichTextValue] = useState("");
  const [data, setData] = useState(null);
  const [fieldChanged,setFieldChanged] = useState(false)

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    const [formValid, formValue] = form.current.validate();
    if (formValid) {
      const params = {
        certificateTemplate: certificateId,
      };

      dispatch(setLoading(true));
      fetchRequest(certificateEdit, "GET", params)
        .then((res) => {
          if (res.success) {
            const { data: {
              certificateData = {}
            }} = res;
            setrichTextValue(certificateData.textData);
            setData(certificateData);
            setFieldChanged(prev=>!prev)
          } else {
            message(res?.data?.message || t("errorMessage.title"));
          }
          dispatch(setLoading(false));
        })
        .catch(() => {
          dispatch(setLoading(false));
          message(t("errorMessage.title"));
        });
    }
  };

  const fields = [
    {
      label: t("enroll.certificateType"),
      value: data != null ? data.templateName : "",
      type: "text",
      upperCase: true,
      required: false,
      labelWidth: 250,
      inputWidth: 300,
      key: "templateName",
    },
    {
      type: "jsx",
      value: (
        <div key={"separator"} className="separator separator-dashed my-7" />
      ),
    },
  ];

  const onSaveClick = () => {
    const [formValid, formValue] = form.current.validate();
    if (formValid) {
      const params = {
        school: schoolId,
        certificateTemplate: certificateId,
        templateName: formValue?.[0].value,
        textData: richTextValue,
        ...filter
      };

      dispatch(setLoading(true));
      fetchRequest(certificateEdit, "POST", params, false).then((res) => {
        if (res.success) {
          message(res.data.message, true);

          onClose();
          onFinish();
        } else {
          message(res.data.message, false);
        }
        dispatch(setLoading(false));
      });
    } else {
      console.log("form invalid");
    }
  };

  return (
    <Modal
      show={true}
      onHide={onClose}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div style={{ color: "#4a70ae", fontSize: "1.1rem" }}>
            {t("enroll.editCeritificateModel").toUpperCase()}
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Forms ref={form} fields={fields} fieldChanged={fieldChanged} />
        </div>
        <div className="mx-auto">
          <p><b>Дугаар: %certificationNumber%</b></p>
          <p><b>Элсэгчийн нэр: %firstName%</b></p>
          <p><b>Эцэг эхийн нэр: %lastName%</b></p>
          <p><b>Мэргэжил нэр: %programName%</b></p>
          <p><b>Сургуулийн нэр: %schoolName%</b></p>
          <p><b>Хөтөлбөрийн нэр: %academicProgramName%</b></p>
          <Editor
            bgImage={bgImage}
            textValue={richTextValue}
            onTextChange={setrichTextValue}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={onClose} className="btn btn-link bolder">
          {t("common.back")}
        </button>
        <Button variant="success btn-shadow" onClick={onSaveClick}>
          {t("common.save")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditModal;
