import React, {useEffect, useRef, useState} from 'react';
import {Button, Card, Modal} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {setLoading} from "../../../../redux/action";
import {fetchRequest} from "../../../../utils/fetchRequest";
import {studentPaymentSubmit, studentSelectionPayment} from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import PaymentModal from './PaymentModal';

const Payment = ({
    payment,
    paymentData,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const languages = useSelector(state => state?.languages?.languages || []).filter(lang => lang.code !== 'mn');
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [showPaymentModal, setShowPaymentModal] = useState(false)
    const [configId, setConfigId] = useState(null);

    useEffect(() => {

    }, []);

    // const renderSubject = (subjects) => {
    //     let renderData = [];
    //
    //     if(subjects && subjects.length > 0){
    //         for(let i = 0; i < subjects.length; i++){
    //             let pTime = subjects[i].pTimes;
    //             let tdData = [];
    //
    //             if(pTime && pTime.length > 0){
    //                 for(let p = 0; p < pTime.length; p++){
    //                     tdData.push(
    //                         <tr key={'subject_' + i + '_ptime_' + p}>
    //                             <td>{p + 1}</td>
    //                             <td>{pTime[p].timeName}</td>
    //                             <td>
    //                                 <Select
    //                                     options={pTime[p].timetables}
    //                                     value={pTime[p].timetable}
    //                                     // onChange={(id) => onSelect(subjects[i].subjectId, pTime[p].id, id)}
    //                                     searchable
    //                                 />
    //                             </td>
    //                         </tr>
    //                     )
    //                 }
    //             }
    //
    //             renderData.push(
    //                 <div
    //                     key={'subject_' + i}
    //                 >
    //                     <p className='strong-blue'><b>{subjects[i].code + ' ' + subjects[i].name + ' - ' + subjects[i].credit + ' ' + (t('subject.credit')).toLowerCase()}</b></p>
    //                     <table className='table table-bordered'>
    //                         <thead>
    //                         <tr>
    //                             <th style={{width: 40}}>№</th>
    //                             <th style={{width: 180}}>{t('subject.title')}</th>
    //                             <th>{t('subject.teacher') + ' - ' + t('common.weekdayWithtime')}</th>
    //                         </tr>
    //                         </thead>
    //                         <tbody>
    //                         {tdData}
    //                         </tbody>
    //                     </table>
    //                 </div>
    //             );
    //         }
    //     }
    //
    //     return renderData;
    // };

    const onModalClose = () => {
        setShowPaymentModal(false);
    };

    const onPaymentClick = (configId) => {
        setShowPaymentModal(true);
        setConfigId(configId);
    };

    const onCheckPayment = () => {
        window.location.reload();
    }

    return (
        <div className="col-12 mb-5">
            <Card>
                <Card.Body>
                    <Button
                        className='mr-2'
                        variant='danger'
                        size='lg'
                        onClick={() => onPaymentClick(paymentData.id)}
                    >
                        {t('Төлбөр төлөх')}
                    </Button>
                    <Button
                        variant='warning'
                        size='lg'
                        onClick={() => onCheckPayment(paymentData.id)}
                    >
                        {t('Төлбөр шалгах')}
                    </Button>
                    <div className='col-12 mt-3 pink-red'>
                        <b>
                            {
                                payment && paymentData
                                ?
                                    'Та төлбөрөө төлж ' + paymentData.seasonName + ' хичээл сонголт 2 хийх боломжтой. ' +
                                    /*' + payment.bankName + ' - ' + payment.bankAccountNumber + ' (' +
                                        payment.bankAccountName + ') дансанд төлбөрөө төлөх ба*/
                                         'Төлбөр төлөх дүн ' + paymentData.amount + '₮.'
                                : null
    }
                        </b>
                    </div>
                </Card.Body>
            </Card>
            {
                showPaymentModal &&
                <PaymentModal 
                    onClose={onModalClose}
                    config={configId}
                    school={schoolId}
                />
            }
        </div>
    );
};

export default Payment;