import React, {useEffect, useState} from "react";
import {useTranslation} from 'react-i18next';
import {Link, useHistory} from "react-router-dom";
import {Button, Card, ButtonToolbar, Modal, Form, Col, Row} from "react-bootstrap";
import TreeView from '../../../../app/modules/TreeView/index';
import DTable from "../../../modules/DataTable/DTable";
import {fetchRequest} from "../../../../utils/fetchRequest";
import {setLoading} from "../../../../redux/action";
import {useDispatch} from "react-redux";
import qs from 'qs';
import {subjectDelete, subjectEdit, subjectInit, subjectList, timetableInit} from "../../../../utils/fetchRequest/Urls";
import store from "../../../../redux/store";
import message from "../../../modules/message";

export function SubjectConfig() { 
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const {selectedSchool} = store.getState();
    const [selectedTreeId, setSelectedTreeId] = useState([]);
    const [department, setDepartment] = useState([]);
    const [helperModal, setHelperModal] = useState(false);
    const [viewModal, setViewModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [subjects, setSubjects] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [ treeIndex ] = useState('school_subject_index');

    useEffect(() => {
        dispatch(setLoading(true));
        const selectedTreeId = localStorage.getItem(treeIndex);
        let params = {
            school: selectedSchool.id
        };

        fetchRequest(subjectInit + '?' + qs.stringify(params), 'GET')
            .then(response => {
                if (response.success) {
                    if(response.data.departments && response.data.departments.length){
                        setDepartment(response.data.departments);
                        setSelectedTreeId(selectedTreeId ? [parseInt(selectedTreeId)] : [response.data.departments[0].key]);
                        handleSubjectList(selectedTreeId ? [selectedTreeId] : response.data.departments[0].key)
                    } else {
                        dispatch(setLoading(false));    
                    }
                } else {
                    message(response.data.message, false);
                    dispatch(setLoading(false));
                }
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            });
    }, []);

    const handleTreeChange = (treeArray, data) => {
        if(treeArray?.length){
            setSelectedTreeId(treeArray);
            handleSubjectList(treeArray[0]);
            localStorage.setItem(treeIndex, treeArray[0]);
        }
    };

    const handleSubjectList = (value) => {
        if(value){
            let params = {
                school: selectedSchool.id,
                department: value,
                pageSize: 10,
                page: 1
            };

            dispatch(setLoading(true));
            fetchRequest(subjectList + '?' + qs.stringify(params), 'GET')
                .then(response => {
                    if (response.success) {
                        setSubjects(response.data.subjects);
                        setTotalCount(response.data.totalCount)
                    } else {
                        message(response.data.message, false)
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                });
        }
    };

    const config = {
        showPagination: true,
        showFilter: true,
    };

    const columns = [
        {
            dataField: "code",
            text: t('common.code'),
            sort: true,
        },
        {
            dataField: "name",
            text: t('subject.name'),
            sort: true,
        },
        {
            dataField: "typeName",
            text: t('subject.type'),
            sort: true,
        },
    ];

    const contextMenus = [
        {
            key: 'view',
            title: t('common.view'),
            icon: <i className='las la-search dt-cm-item-icon'/>
        },
        {
            key: 'edit',
            title: t('common.edit'),
            icon: <i className='las la-edit dt-cm-item-icon'/>
        },
        {
            key: 'delete',
            title: t('common.delete'),
            icon: <i className='las la-trash-alt dt-cm-item-icon'/>,
        },
    ];

    const handleContextMenuClick = (id, key, record) => {
        if(key === 'view'){
            setSelectedRecord(record);
            handleViewModal();
        } else if (key === 'edit'){
            history.push({
                pathname: '/school/subject_edit',
                state: {
                    record,
                    selectedTreeId
                }
            });
        } else if (key === 'delete'){
            setSelectedRecord(record);
            setDeleteModal(true)
        }
    };

    const handleTableInteraction = (object) => {
        let params = {
            school: selectedSchool.id,
            department: selectedTreeId && selectedTreeId.length > 0 ? selectedTreeId[0] : null,
            ...object
        };

        dispatch(setLoading(true));
        fetchRequest(subjectList, 'GET', params)
            .then(response => {
                if (response.success) {
                    setSubjects(response.data.subjects);
                    setTotalCount(response.data.totalCount)
                } else {
                    message(response.data.message, false)
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            });
    };

    const handleHelperModal = () => {
        setHelperModal(true);
    };

    const handleHelperModalClose = () => {
        setHelperModal(false)
    };

    const handleViewModal = () => {
        setViewModal(true);
    };

    const handleViewModalClose = () => {
        setViewModal(false)
    };

    const handleDeleteModalClose = () => {
        setDeleteModal(false)
    };

    const handlerOnDeleteClick = () => {
        if(selectedTreeId && selectedTreeId.length > 0 && selectedRecord){
            let params = {
                school: selectedSchool.id,
                subject: selectedRecord.id,
                department: selectedTreeId && selectedTreeId.length > 0 ? selectedTreeId[0] : null,
                pageSize: 10,
                page: 1
            };

            dispatch(setLoading(true));
            fetchRequest(subjectDelete, 'POST', params)
                .then(response => {
                    if (response.success) {
                        handleDeleteModalClose();
                        setSubjects(response.data.subjects)
                        setTotalCount(response.data.totalCount)
                    } else {
                        message(response.data.message, false)
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                });
        } else {
            message(t('warning.invalid_parameter'), false)
        }
    };

    return (
        <div className='row'>
            <div className='col-4'>
                <Card className='card card-custom gutter-b'>
                    <Card.Body>
                        <TreeView
                            treeData={department}
                            selectedNodes={selectedTreeId}
                            onSelect={(e, data) => handleTreeChange(e, data)}
                            defaultExpandAll={true}
                        />
                    </Card.Body>
                </Card>
            </div>
            <div className='col-8 pl-0'>
                <Card className='card card-custom gutter-b card-header-with-tab'>
                    <Card.Body>
                        <ButtonToolbar className='d-inline'>
                            <Link
                                to={{
                                    pathname: '/school/subject_new',
                                    state: {
                                        selectedTreeId: selectedTreeId
                                    }
                                }}
                                className="btn btn-primary btn-shadow"
                            >
                                {t('common.add')}
                            </Link>
                        </ButtonToolbar>
                        <Button className='bg-white border-white d-inline btn-hover-less' onClick={handleHelperModal}>
                            <i className="icon-2x text-danger flaticon-questions-circular-button d-inline"> </i>
                            <p className='text-danger d-inline'><b>{t('common.helper')}</b></p>
                        </Button>
                        <DTable
                            data={subjects}
                            columns={columns}
                            config={config}
                            onInteraction={handleTableInteraction}
                            totalDataSize={totalCount}
                            remote
                            showOrdering={true}
                            contextMenus={contextMenus}
                            onContextMenuItemClick={handleContextMenuClick}
                            selectMode={'radio'}
                        />
                    </Card.Body>
                </Card>
            </div>
            <Modal
                show={helperModal}
                onHide={handleHelperModalClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Modal heading
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Centered Modal</h4>
                    <p>
                        Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
                        dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta
                        ac consectetur ac, vestibulum at eros.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleHelperModalClose}>{t('common.close')}</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={viewModal}
                onHide={handleViewModalClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t('common.view')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        selectedRecord
                        ?
                            <Form>
                                <Form.Group as={Row}>
                                    <Form.Label column sm="4" className='text-right'>
                                        {t('common.code')}
                                    </Form.Label>
                                    <Col sm="8">
                                        <div className='form-label col-form-label'>{selectedRecord.code}</div>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label column sm="4" className='text-right'>
                                        {t('subject.name')}
                                    </Form.Label>
                                    <Col sm="8">
                                        <div className='form-label col-form-label'>{selectedRecord.name}</div>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label column sm="4" className='text-right'>
                                        {t('subject.type')}
                                    </Form.Label>
                                    <Col sm="8">
                                        <div className='form-label col-form-label'>{selectedRecord.typeName}</div>
                                    </Col>
                                </Form.Group>
                            </Form>
                        : null
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleViewModalClose}>{t('common.close')}</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={deleteModal}
                onHide={handleDeleteModalClose}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t('warning.delete')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {t('warning.delete_confirmation')}
                        <br/>
                        <br/>
                        {t('warning.delete_confirmation_description')}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="btn btn-link bolder" onClick={handleDeleteModalClose}>{t('common.cancel')}</Button>
                    <Button variant="success btn-shadow" onClick={handlerOnDeleteClick}>{t('warning.delete')}</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default SubjectConfig;