import React, { useState, useEffect, useRef } from "react";
import Checkbox from "../../../../../modules/Form/Checkbox";
import Forms from "../../../../../modules/Form/Forms";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../../../../redux/action";
import { fetchRequest } from "../../../../../../utils/fetchRequest";
import { getCVConfig } from "../../../../../../utils/fetchRequest/Urls";

const CriteriaAge = ({enrollId, onChange, mYear, fYear }) => {
    const forms = useRef();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [years, setYears] = useState([]);
    const [selectedMYear, setSelectedMYear] = useState(mYear)
    const [selectedFYear, setSelectedFYear] = useState(fYear)

    useEffect(() => {
        getConfig();
    }, []);

    const getConfig = () => {
        dispatch(setLoading(true));
        const params = {
            enroll: enrollId,
        };

        fetchRequest(getCVConfig, "GET", params)
            .then((res) => {
                if (res.success) {
                    const { data } = res;
                    if(data.config && data.config.length > 0){
                        setSelectedMYear(data.config[0].mYear || null)    
                        setSelectedFYear(data.config[0].fYear || null)    
                    }
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
            });
    };

    const onSelectMYear = (id) => {
        onChange({ column: "mYear", value: id });
    };

    const onSelectFYear = (id) => {
        onChange({ column: "fYear", value: id });
    };

    const fields = [
        {
            label: t("enroll.bornSinceMale"),
            type: "dropdown",
            upperCase: true,
            value: selectedMYear || null,
            required: false,
            labelWidth: 200,
            inputWidth: 200,
            options: years,
            onChange: onSelectMYear,
        },
        {
            label: t("enroll.bornSinceFemale"),
            type: "dropdown",
            upperCase: true,
            value: selectedFYear || null,
            required: false,
            labelWidth: 200,
            inputWidth: 200,
            options: years,
            onChange: onSelectFYear,
        },
    ];

    useEffect(() => {
        initializeYear();
    }, []);

    useEffect(() => {
        forms?.current?.updateFields && forms.current.updateFields(fields);
    }, [years, selectedMYear, selectedFYear]);

    const initializeYear = () => {
        let minOffset = 0,
            maxOffset = 50;
        let thisYear = new Date().getFullYear();
        let allYears = [];
        for (let x = 0; x <= maxOffset; x++) {
            allYears.push({ value: thisYear - x, text: thisYear - x });
        }
        setYears(allYears);
    };

    return (
        <div className="row p-6 ">
            <div className="">
                <div className="m-4">
                    <Forms ref={forms} fields={fields} vertical={true} />
                </div>
            </div>
        </div>
    );
};

export default CriteriaAge;
