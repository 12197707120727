import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal } from "react-bootstrap";
import Forms from "../../../modules/Form/Forms";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import './assessment.scss';
import Checkbox from "../../../modules/Form/Checkbox";
import { scoreTypeEdit } from "../../../../utils/fetchRequest/Urls";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import message from "../../../modules/message";

const defaultRow = {
    code: '',
    max: '',
    min: '',
    gpa: '',
    ordering: '',
    isQuality: false,
    isSuccess: false,
    isGPA: false,
    isCredit: false,
    isSelection: false,
    isPayment: false,
    errorCode: false,
    errorMax: false,
    errorMin: false,
    errorGpa: false,
    errorOrdering: false,
};

const AssessmentAdd = ({
    onClose,
    onSubmit,
    id
}) => {
    const { t } = useTranslation();
    const [ rows, setRows ] = useState([{ ...defaultRow }]);
    const [ langValues, setLangValues ] = useState([]);
    const forms = useRef();
    const dispatch = useDispatch();
    const languages = useSelector(state => state?.languages?.languages || []).filter(lang => lang.code !== 'mn');
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const parentId = useSelector(state => state?.selectedSchool?.parentId || null);

    useEffect(() => {
        const array = [];
        for (const lang of languages) {
            array.push({
                langCode: lang.code,
                value: '',
                name: lang.name,
            })
        }

        if (id) {
            const params = {
                scoreType: id,
                school: schoolId
            };
            dispatch(setLoading(true));
            fetchRequest(scoreTypeEdit, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res;
                        const scoreType = data.scoreType;
                        const translations = data?.scoreType?.translations;

                        if(array && array.length > 0 && translations && translations.length > 0){
                            for(let i = 0; i < array.length; i++){
                                for(let t = 0; t < translations.length; t++)
                                    if(array[i]['langCode'] == translations[t]['langCode']){
                                        array[i]['value'] = translations[t]['name'];
                                    }
                            }
                        }

                        if (data?.scoreType) {
                            forms?.current?.updateFields && forms.current.updateFields([
                                {
                                    label: t('common.code'),
                                    value: data.scoreType.code || '',
                                    type: 'nonCryllic',
                                    upperCase: true,
                                    required: true,
                                    key: 'code',
                                },
                                {
                                    label: t('common.name'),
                                    value: data.scoreType.name || '',
                                    type: 'text',
                                    required: true,
                                    key: 'name',
                                },
                            ]);

                            const children = data.scoreType.children || [];

                            const newRows = children.map(child => {
                                return {
                                    code: child.code || '',
                                    max: child.max ? child.max.toString() : '',
                                    min: child.min ? child.min.toString() : '',
                                    gpa: child.gpa || '',
                                    ordering: child.ordering ? child.ordering : '',
                                    isQuality: !!child.isQuality,
                                    isSuccess: !!child.isSuccess,
                                    isGPA: !!child.isGpa,
                                    isCredit: !!child.isCredit,
                                    isSelection: !!child.isSelection,
                                    isPayment: !!child.isPayment,
                                }
                            });
                            setRows(newRows);
                        }
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        }

        setLangValues(array);
    }, []);

    const fields = [
        {
            label: t('common.code'),
            value: '',
            type: 'nonCryllic',
            upperCase: true,
            required: true,
            errorMessage: t('errorMessage.enterCode'),
            key: 'code',
        },
        {
            label: t('common.name'),
            value: '',
            type: 'text',
            required: true,
            errorMessage: t('errorMessage.enterName'),
            key: 'name',
        },
        {
            label: t('school.isOtherSchool'),
            type: 'checkbox',
            value: true,
            hidden: parentId == null ? false : true,
            key: 'isSchool',
        },
    ];

    const onSaveClick = () => {
        const [ isValid, , values ] = forms.current.validate();
        if (isValid) {
            const cloneRows = [...rows];
            let hasErrorCode = false;
            let hasErrorGpa = false;
            let hasErrorMin = false;
            let hasErrorMax = false;
            let hasErrorOrdering = false;
            if(cloneRows && cloneRows.length > 0){
                for(let i = 0; i < cloneRows.length; i++){
                    // if(cloneRows[i].code){
                    //     cloneRows[i].errorCode = false;
                    //     hasErrorCode = false;
                    // } else {
                    //     cloneRows[i].errorCode = true;
                    //     hasErrorCode = true;
                    // }
                    // if (cloneRows[i].gpa){
                    //     cloneRows[i].errorGpa = false;
                    //     hasErrorGpa = false;
                    // } else {
                    //     cloneRows[i].errorGpa = true;
                    //     hasErrorGpa = true;
                    // }
                    // if (cloneRows[i].ordering){
                    //     cloneRows[i].errorOrdering = false;
                    //     hasErrorOrdering = false;
                    // } else {
                    //     cloneRows[i].errorOrdering = true;
                    //     hasErrorOrdering = true;
                    // }
                    // if (cloneRows[i].min){
                    //     cloneRows[i].errorMin = false;
                    //     hasErrorMin = false;
                    // } else {
                    //     cloneRows[i].errorMin = true;
                    //     hasErrorMin = true;
                    // }
                    // if (cloneRows[i].max){
                    //     cloneRows[i].errorMax = false;
                    //     hasErrorMax = false;
                    // } else {
                    //     cloneRows[i].errorMax = true;
                    //     hasErrorMax = true;
                    // }
                }
            }

            if(!hasErrorCode && !hasErrorGpa && !hasErrorMax && !hasErrorMin && !hasErrorOrdering){
                const translations = langValues.map(lang => {
                    return {
                        langCode: lang.langCode,
                        name: lang.value,
                    }
                });
                if(values.isSchool){
                    values.isSchool = 1;
                } else {
                    values.isSchool = 0;
                }
                const params = {
                    ...values,
                    datas: JSON.stringify(rows),
                    translations: JSON.stringify(translations)
                };
                onSubmit(params);
            } else {
                setRows(cloneRows);
            }
        }
    };

    const addRow = () => {
        setRows([...rows, { ...defaultRow }]);
    };

    const removeRow = index => {
        if (rows.length > 1) {
            const clone = [...rows];
            clone.splice(index, 1);
            setRows(clone);
        }
    };

    const handleRowValueChange = (value, key, index) => {
        const clone = [...rows];
        const row = { ...clone[index] };
        row[key] = value;
        clone[index] = row;
        setRows(clone);
    };

    const handleLanguageValueChange = (val, code) => {
        const clone = [...langValues];
        const index = clone.findIndex(lang => lang.langCode === code);
        if (index >= 0) {
            const language = { ...clone[index] };
            language.value = val;
            clone[index] = language;
            setLangValues(clone);
        }
    };

    // const onMinChange = (e, index) => {
    //
    // }
    //
    // const onMaxChange = (e, index) => {
    //
    // }
    //
    // const onGPAChange = (e, index) => {
    //
    // }
    //
    // const onOrderingChange = (e, index) => {
    //
    // }
    //
    // const onQualityChange = (e, index) => {
    //
    // }
    //
    // const onSuccessChange = (e, index) => {
    //
    // }
    //
    // const onGPACheckboxChange = (e, index) => {
    //
    // }
    //
    // const onCreditCBClick = (e, index) => {
    //
    // }
    //
    // const onIsSelectionChange = (e, index) => {
    //
    // }
    //
    // const onIsPaymentChange = (e, index) => {
    //
    // }

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName='modal-90w'
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('assessment.title').toUpperCase()} {id ? t('common.edit').toUpperCase() : ''}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='row'>
                    <div className='col-12'>
                        <Forms
                            ref={forms}
                            fields={fields}
                        />
                    </div>
                </div>
                <table className='scoretype-add-table'>
                    <thead>
                        <tr>
                            <td>{t('common.code')}</td>
                            <td>{t('assessment.maxPercent') + ` ">"`}</td>
                            <td>{t('assessment.minPercent')}</td>
                            <td>{t('assessment.gpa')}</td>
                            <td>{t('common.ordering')}</td>
                            <td>{t('assessment.quality')}</td>
                            <td>{t('assessment.success')}</td>
                            <td>{t('assessment.totalGPA')}</td>
                            <td>{t('assessment.totalCredit')}</td>
                            <td>{t('assessment.selection')}</td>
                            <td>{t('assessment.tuition')}</td>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        rows.map((row, index) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        <input
                                            type= {'nonCryllic'}
                                            className={row.errorCode ? 'form-control is-invalid' : 'form-control'}
                                            value={row.code}
                                            onChange={e => handleRowValueChange(e.target.value, 'code', index)}
                                        />
                                        {
                                            row.errorCode
                                            ?
                                                <div className='invalid-feedback'>
                                                    {t('errorMessage.enterCode')}
                                                </div>
                                            : null
                                        }
                                    </td>
                                    <td>
                                        <input
                                            
                                            className={row.errorMax ? 'form-control is-invalid' : 'form-control'}
                                            value={row.max ? Number(row.max) : 0}
                                            onChange={e => handleRowValueChange(parseInt(e.target.value), 'max', index)}
                                        />
                                        {
                                            row.errorMax
                                                ?
                                                <div className='invalid-feedback'>
                                                    {t('errorMessage.enterValue')}
                                                </div>
                                                : null
                                        }
                                    </td>
                                    <td>
                                        <input
                                            className={row.errorMin ? 'form-control is-invalid' : 'form-control'}
                                            value={row.min ? Number(row.min) : 0}
                                            onChange={e => handleRowValueChange(parseInt(e.target.value), 'min', index)}
                                        />
                                        {
                                            row.errorMin
                                                ?
                                                <div className='invalid-feedback'>
                                                    {t('errorMessage.enterValue')}
                                                </div>
                                                : null
                                        }
                                    </td>
                                    <td>
                                        <input
                                            className={row.errorGpa ? 'form-control is-invalid' : 'form-control'}
                                            value={row.gpa ? row.gpa : ''}
                                            onChange={e => handleRowValueChange((e.target.value), 'gpa', index)}  
                                        />
                                        {
                                            row.errorGpa
                                                ?
                                                <div className='invalid-feedback'>
                                                    {t('errorMessage.enterGpa')}
                                                </div>
                                                : null
                                        }
                                    </td>
                                    <td>
                                        <input
                                            className={row.errorOrdering ? 'form-control is-invalid' : 'form-control'}
                                            value={row.ordering ? Number(row.ordering) : 0}
                                            onChange={e => handleRowValueChange(parseInt(e.target.value), 'ordering', index)}
                                        />
                                        {
                                            row.errorOrdering
                                                ?
                                                <div className='invalid-feedback'>
                                                    {t('errorMessage.enterOrdering')}
                                                </div>
                                                : null
                                        }
                                    </td>
                                    <td>
                                        <Checkbox
                                            className='flex-center'
                                            checked={row.isQuality}
                                            onChange={() => handleRowValueChange(!row.isQuality, 'isQuality', index)}
                                        />
                                    </td>
                                    <td>
                                        <Checkbox
                                            className='flex-center'
                                            checked={row.isSuccess}
                                            onChange={() => handleRowValueChange(!row.isSuccess, 'isSuccess', index)}
                                        />
                                    </td>
                                    <td>
                                        <Checkbox
                                            className='flex-center'
                                            checked={row.isGPA}
                                            onChange={() => handleRowValueChange(!row.isGPA, 'isGPA', index)}
                                        />
                                    </td>
                                    <td>
                                        <Checkbox
                                            className='flex-center'
                                            checked={row.isCredit}
                                            onChange={() => handleRowValueChange(!row.isCredit, 'isCredit', index)}
                                        />
                                    </td>
                                    <td>
                                        <Checkbox
                                            className='flex-center'
                                            checked={row.isSelection}
                                            onChange={() => handleRowValueChange(!row.isSelection, 'isSelection', index)}
                                        />
                                    </td>
                                    <td>
                                        <Checkbox
                                            className='flex-center'
                                            checked={row.isPayment}
                                            onChange={() => handleRowValueChange(!row.isPayment, 'isPayment', index)}
                                        />
                                    </td>
                                    <td>
                                        <button className='btn btn-danger row-action-button' onClick={() => removeRow(index)}>
                                            <i className='las la-times' style={{ fontSize: 20 }} />
                                        </button>
                                    </td>
                                </tr>
                            )
                        })
                    }
                    <tr>
                       <td colSpan={12}>
                           <button className='btn btn-primary row-action-button' onClick={addRow}>
                               <i className='las la-plus' style={{ fontSize: 20 }} />
                           </button>
                       </td>
                    </tr>
                    </tbody>
                </table>
                <div key={'separator'} className="separator separator-dashed my-7"/>
                {
                    langValues.map(lang => {
                        return (
                            <div key={lang.langCode} className='row'>
                                <div className='col-8'>
                                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '0.8rem' }}>
                                        <div style={{ flex: 2, textAlign: 'right', marginRight: 10 }}>{`${t('common.name')} - ${lang.name} (${lang.langCode})`}</div>
                                        <div style={{ flex: 1, marginLeft: 10 }}>
                                            <input
                                                value={lang.value}
                                                onChange={e => handleLanguageValueChange(e.target.value, lang.langCode)}
                                                className='form-control'
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className='btn btn-link bolder'>{t('common.back')}</button>
                <Button variant='success btn-shadow' onClick={onSaveClick}>{t('common.save')}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AssessmentAdd;