import React, { useEffect, useRef } from 'react';
import { Button, Modal } from "react-bootstrap";
import Forms from "../../../modules/Form/Forms";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { strTimeToMinutes } from "../../../../utils/utils";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import message from "../../../modules/message";
import { schoolShiftEdit } from "../../../../utils/fetchRequest/Urls";

const ShiftAdd = ({
    onClose,
    onSubmit,
    id
}) => {
    const { t } = useTranslation();
    const forms = useRef();
    const dispatch = useDispatch();
    const languages = useSelector(state => state?.languages?.languages || []).filter(lang => lang.code !== 'mn');
    const schoolId = useSelector(state => state?.selectedSchool.id || null);
    const parentId = useSelector(state => state?.selectedSchool.parentId || null);

    const translationFields = languages.map(lang => {
        return {
            label: `${t('common.name')} - ${lang.name} (${lang.code})`,
            value: '',
            langCode: lang.code,
            type: 'text',
            labelWidth: 300,
            inputWidth: 200,
        }
    });

    useEffect(() => {
        if (id) {
            const params = {
                schoolShift: id,
                school: schoolId,
            };
            dispatch(setLoading(true));
            fetchRequest(schoolShiftEdit, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res;
                        if (data?.schoolShift) {
                            const shift = data.schoolShift;
                            const translations = shift.translations;
                            if (shift) {
                                if(translationFields && translationFields.length > 0 && translations && translations.length > 0){
                                    for(let i = 0; i < translationFields.length; i++){
                                        for(let t = 0; t < translations.length; t++)
                                        if(translationFields[i]['langCode'] == translations[t]['langCode']){
                                            translationFields[i]['value'] = translations[t]['name'];
                                        }
                                    }
                                }
                                
                                forms?.current?.updateFields && forms.current.updateFields([
                                    {
                                        label: t('common.code'),
                                        value: shift.code || '',
                                        type: 'nonCryllic',
                                        upperCase: true,
                                        inputWidth: 200,
                                        labelWidth: 300,
                                        required: true,
                                        key: 'code',
                                    },
                                    {
                                        label: t('school.shift.name'),
                                        value: shift.name || '',
                                        type: 'text',
                                        inputWidth: 200,
                                        labelWidth: 300,
                                        required: true,
                                        key: 'name',
                                    },
                                    {
                                        label: t('common.startTime'),
                                        value: shift.startTime || '',
                                        type: 'time',
                                        inputWidth: 200,
                                        labelWidth: 300,
                                        required: true,
                                        key: 'start',
                                        allowTimeInput: true,
                                    },
                                    {
                                        label: t('common.endTime'),
                                        value: shift.endTime || '',
                                        type: 'time',
                                        inputWidth: 200,
                                        labelWidth: 300,
                                        required: true,
                                        key: 'end',
                                        allowTimeInput: true,
                                        validation: validateEndTime,
                                    },
                                    {
                                        type: 'jsx',
                                        value: <div key={'separator'} className="separator separator-dashed my-7"/>,
                                    },

                                    ...translationFields
                                ]);
                            }
                        }
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'));
                })
        }
    }, [ id ]);

    const validateEndTime = (value, states, values) => {
        if (!value) {
            return { valid: false };
        }
        if (values && values.start) {
            const startTimeMinutes = strTimeToMinutes(values.start);
            const endTimeMinutes = strTimeToMinutes(value);
            if (endTimeMinutes < startTimeMinutes) {
                return {
                    valid: false,
                    message: 'Дуусах цаг эхлэх цагийн өмнө байж болохгүй'
                }
            }
            return {
                valid: true,
            }
        }
        return {
            valid: false,
        }
    };

    const fields = [
        {
            label: t('common.code'),
            value: '',
            type: 'nonCryllic',
            upperCase: true,
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            key: 'code',
        },
        {
            label: t('school.shift.name'),
            value: '',
            type: 'text',
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            key: 'name',
        },
        {
            label: t('common.startTime'),
            value: '',
            type: 'time',
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            key: 'start',
            allowTimeInput: true,
        },
        {
            label: t('common.endTime'),
            value: '',
            type: 'time',
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            key: 'end',
            allowTimeInput: true,
            validation: validateEndTime,
        },
        {
            label: t('school.isOtherSchool'),
            type: 'checkbox',
            value: true,
            inputWidth: 300,
            labelWidth: 300,
            hidden: parentId == null ? false : true,
            key: 'isSchool',
        },
        {
            type: 'jsx',
            value: <div key={'separator'} className="separator separator-dashed my-7"/>,
        },
        ...translationFields
    ];

    const onSaveClick = () => {
        const [ isValid, states, values ] = forms.current.validate();
        if (isValid) {
            const translations = [];
            for (let i = 0; i < states.length; i++) {
                const val = states[i];
                if (i >= 6) {
                    translations.push({
                        langCode: val.langCode,
                        name: val.value,
                    });
                }
            }
            if(values.isSchool){
                values.isSchool = 1
            } else {
                values.isSchool = 0
            }
            const params = {
                ...values,
                translations: JSON.stringify(translations)
            };
            onSubmit(params);
        }
    };

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: '#29569f', fontSize: '1.1rem' }}>
                        {t('school.shift.title').toUpperCase()}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Forms
                    ref={forms}
                    fields={fields}
                />
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className='btn btn-link bolder'>{t('common.back')}</button>
                <Button variant='success btn-shadow' onClick={onSaveClick}>{t('common.save')}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ShiftAdd;