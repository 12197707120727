import React,{useEffect,useState} from 'react'
import {Button, Col, Modal, Row} from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import {studentOnlineLessonHw} from '../../../../../utils/fetchRequest/Urls'
import { fetchRequest } from '../../../../../utils/fetchRequest'
import message from '../../../../modules/message'
import { setLoading } from '../../../../../redux/action'
import { TextareaAutosize } from '@material-ui/core';
import { useDispatch } from 'react-redux';

const SendHomework = ({
    onClose = () =>{},
    homework = null,
    school = null,
    lesson = null,
    selectedHomework = null,
    onSubmitData = () => {}
}) => {

    const {t} = useTranslation()
    const dispatch = useDispatch()

    const [files,setFiles] = useState([])
    const [description,setDescription] = useState('')

    const changeHandler = (event) => {
		setFiles(event.target.files);
	};
    
    const fileNames = () =>{
        if(files.length){
            let filenames;
            for(let i = 0; i < files.length; i++){
                filenames += files[i].name;
                if(i + 1 < files.length){
                    filenames += ', ';
                }
            }
            return filenames;
        }else{
            return '';
        }
    }

    const onSend = () =>{
        if(homework && lesson && school){
            const formData = new FormData();

            formData.append('school', school);
            formData.append('homework', homework);
            formData.append('course', lesson);
            formData.append('description', description);
            formData.append('type', 'ADD');

            for(let i = 0; i < files.length; i++){
                formData.append('files[]', files[i]);
            }

            dispatch(setLoading(true));
            fetchRequest(studentOnlineLessonHw, 'POST', formData, true, true)
                .then(res => {
                    if (res.success) {
                        const { data } = res;
                        onClose();
                        onSubmitData(data.homeworks);
                        message(res?.data?.message || t('common.success'),true);
                    } else {
                        message(res?.data?.message || t('errorMessage.title'));
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'));
                })
        }
    }

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('homework.send')}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    selectedHomework && selectedHomework.isFile && 
                    <Row className='align-items-center mb-4'>
                        <Col md={3} className='text-right' style={{position: 'relative', bottom: 6}}>
                            {t('file.add')}
                        </Col>
                        <Col md={6}>
                            <input 
                                type="file" 
                                id="file_upload" 
                                onChange={changeHandler} 
                                multiple={true}
                                className='file_upload d-none'
                            />
                            <label
                                type='text'
                                htmlFor='file_upload'
                                className='msue-textarea-description p-3'
                            >
                                <span style={{color: '#919bc0'}}>{fileNames() || t('common.clickHere')}</span>
                            </label>
                        </Col>
                    </Row>
                }
                {
                    selectedHomework && selectedHomework.isText && 
                    <Row className='align-items-start my-4'>
                        <Col md={3} className='text-right'>
                            {t('common.description')}
                        </Col>
                        <Col md={6}>
                            <TextareaAutosize
                                className='msue-textarea-description'
                                value={description}
                                onChange={e => {
                                    setDescription(e.target.value);
                                }}
                                rows={5}
                            />
                        </Col>
                    </Row>
                }
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className='btn btn-link bolder'>{t('common.back')}</button>
                <Button style={btnStyle} onClick={onSend}>{t('common.send')}</Button>
            </Modal.Footer>
        </Modal>
    )
}

const btnStyle = {
    backgroundColor: '#299f72',
    border: 'none',
    borderRadius: '6px',
    color: '#ffffff',
    boxShadow: '0 2px 10px 0 #299f7233'
}

export default SendHomework