import cloneDeep from 'lodash/cloneDeep';

export const formatSelectData = (array, identifier = 'id', textKey = 'name') => {
    const clone = cloneDeep(array);
    return clone.map(item => {
        return {
            value: item[identifier],
            text: item[textKey],
            ...item
        }
    })
}