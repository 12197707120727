import React from 'react';
import { Card } from "react-bootstrap";
import DTable from "../../../modules/DataTable/DTable";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Class = () => {

    const { t } = useTranslation();

    const columns = [
        {
            dataField: "studentCode",
            text: t('student.code'),
            sort: true
        },
        {
            dataField: "lastName",
            text: t('person.lastName'),
            sort: true
        },
        {
            dataField: "firstName",
            text: t('person.firstName'),
            sort: true
        },
        {
            dataField: "credit",
            text: t('selection.credit'),
            sort: true
        },
        {
            dataField: "date",
            text: t('selection.date'),
            sort: true
        },
        {
            dataField: "paidDate",
            text: t('selection.paidDate'),
            sort: true
        },
    ];

    return (
        <div style={{ height: '100vh', padding: 10 }}>
            <Card style={{ height: '100%' }}>
                <Card.Header>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <span style={{ color: '#4a70ae', fontSize: 18 }}>
                            2020-2021, E32749 Багш, Бага ангийн багш
                        </span>
                        <Link to={'/selection/1'}>
                            {t('common.back')}
                        </Link>
                    </div>
                </Card.Header>
                <Card.Body>
                    <DTable
                        data={[]}
                        columns={columns}
                    />
                </Card.Body>
            </Card>
        </div>
    );
};

export default Class;