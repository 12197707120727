import React,{useState,useEffect} from 'react'
import { Modal,Button, Row, Col, CloseButton } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Select from '../../../../../modules/Form/Select'

const AddTopic = ({
    onClose = () =>{},
    fetchData = () =>{},
    random
}) => {

    const {t} = useTranslation()

    const [topic,setTopic] = useState()
    const [level,setLevel] = useState()
    const [topicOption,setTopicOption] = useState([])
    const [levelOption,setLevelOption] = useState([])
    const [questionNumber,setQuestionNumber] = useState('')

    const onSave = () =>{
        if(topic && level && questionNumber){
            const params = {
                topic,
                level,
                action: 'add',
                questionNumber
            }
            fetchData(params,'POST')
        }
    }

    useEffect(()=>{
        fetchData(null,'GET',setTopicOption,setLevelOption)
    },[])

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('onlineExam.questionFromBlueprint')}
                    </div>
                </Modal.Title>
                <CloseButton onClick={onClose}/>
            </Modal.Header>
            <Modal.Body>
                <Row className='align-items-center mb-4'>
                    <Col className='text-right' md={4}>
                        <span>
                            <b>{t('common.level')}</b>
                        </span>
                    </Col>
                    <Col md={6}>
                        <Select
                            value={level}
                            onChange={value=>setLevel(value)}
                            options={levelOption}
                            searchable
                        />
                    </Col>
                </Row>
                <Row className='align-items-center mb-4'>
                    <Col className='text-right' md={4}>
                        <span>
                            <b>{t('teacher.topic')}</b>
                        </span>
                    </Col>
                    <Col md={6}>
                        <Select
                            value={topic}
                            onChange={value=>setTopic(value)}
                            options={topicOption}
                            searchable
                        />
                    </Col>
                </Row>
                <Row className='align-items-center mb-4'>
                    <Col className='text-right' md={4}>
                        <span>
                            <b>{t('onlineExam.questionNumber')}</b>
                        </span>
                    </Col>
                    <Col md={6}>
                        <input
                            type={'number'}
                            value={questionNumber}
                            onChange={e=>setQuestionNumber(e.target?.value)}
                            style={inputStyle}
                        />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className='btn btn-link bolder'>{t('common.cancel')}</button>
                <Button style={btnStyle} onClick={onSave}>{t('common.save')}</Button>
            </Modal.Footer>
        </Modal>
    )
}

const btnStyle = {
    backgroundColor: '#299f72',
    border: 'none',
    borderRadius: '6px',
    color: '#ffffff',
    boxShadow: '0 2px 10px 0 #299f7233'
}

const inputStyle = {
    border: 'solid 1px #919bc0',
    borderRadius: '4px',
    alignItems: 'center',
    minHeight: '38px',
    width: '100%',
    outline: '0',
    padding: '2px 8px'
}

export default AddTopic