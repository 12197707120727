import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import message from "../../../modules/message";
import React, { useState, useEffect } from "react";
import { ButtonToolbar, Card } from "react-bootstrap";
import { setLoading } from "../../../../redux/action";
import DTable from "../../../modules/DataTable/DTable";
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "../../../modules/DeleteModal";
import { fetchRequest } from "../../../../utils/fetchRequest";
import {
    enrollSettingList,
    getDeleteSetting,
    getInActiveSetting,
} from "../../../../utils/fetchRequest/Urls";
const List = () => {
    const [enrolls, setEnrolls] = useState([]);
    const [enrollId, setEnrollId] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [isParent, setIsParent] = useState(true);
    const history = useHistory();
    const schoolId = useSelector((state) => state?.selectedSchool?.id || null);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        const params = {
            school: schoolId,
        };
        dispatch(setLoading(true));
        fetchRequest(enrollSettingList, "GET", params)
            .then((res) => {
                if (res.success) {
                    const { data } = res;
                    setEnrolls(data?.enrolls || []);
                    setIsParent(data?.isParent)
                } else {
                    message(res?.data?.message || t("errorMessage.title"));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t("errorMessage.title"));
            });
    }, []);

    const config = {
        showPagination: true,
        showFilter: true,
    };

    const columns = [
        {
            dataField: "isApproved",
            text: t("common.status"),
            type: "checkbox",
            sort: true,
            headerStyle: () => ({
                width: 80,
            }),
            style: { textAlign: "center" },
            formatter: (cell) => {
                if (cell === true) {
                    return (
                        <div>
                            <span>
                                <i className="icon-1_5x main-green fa fa-circle" />
                            </span>
                        </div>
                    );
                } else {
                    return (
                        <div>
                            <span>
                                <i className="icon-1_5x text-dark-50 fa fa-circle" />
                            </span>
                        </div>
                    );
                }
            },
        },
        {
            dataField: "schoolName",
            text: "Салбар сургууль",
            hidden: isParent,
            sort: true,
        },
        {
            dataField: "seasonName",
            text: "Хичээлийн жил",
            sort: true,
        },
        {
            dataField: "educationLevel",
            text: 'Түвшин',
            sort: true,
        },
        {
            dataField: "name",
            text: "Элсэлтийн төрөл",
            sort: true,
        },
        {
            dataField: "startDate",
            text: t("common.startDate"),
            sort: true,
            formatter(cell) {
                return (
                    <div className="msue-dt-cell">{cell.date?.substring(0, 19)}</div>
                );
            },
        },
        {
            dataField: "endDate",
            text: t("common.closeDate"),
            sort: false,
            formatter(cell) {
                return (
                    <div className="msue-dt-cell">{cell.date?.substring(0, 19)}</div>
                );
            },
        },
        {
            dataField: "totalProgramNumber",
            text: "Элсэлт авах мэргэжил",
            sort: false,
        },
        {
            dataField: "totalNumber",
            text: "Нийт элсэгчийн тоо",
            sort: false,
        },
    ];

    const contextMenus = [
        {
            key: "view",
            title: t("common.view"),
            icon: <i className="las la-search dt-cm-item-icon" />,
        },
        {
            key: "edit",
            title: t("common.edit"),
            icon: <i className="las la-edit dt-cm-item-icon" />,
        },
        {
            key: "delete",
            title: t("common.delete"),
            icon: <i className="las la-trash-alt dt-cm-item-icon" />,
        },
        {
            key: "inactive",
            title: "Идэвхтэй болгох/ идэвхгүй болгох",
            icon: <i className="las la-eye-slash dt-cm-item-icon" />,
        },
    ];

    const handleContextMenuClick = (id, key) => {
        if (id && key) {
            if (key === "edit") {
                history.push({
                    pathname: "/enroll/setting/editEntry",
                    state: {
                        id: id,
                        isEdit: true,
                    },
                });
            } else if (key === "delete") {
                setEnrollId(id);
                setShowModal(true);
            } else if (key == "view") {
            } else if (key == "inactive") {
                handleInActive(id);
            }
        }
    };

    const handleInActive = (id) => {
        const params = {
            enroll: id,
            school: schoolId,
        };

        dispatch(setLoading(true));
        fetchRequest(getInActiveSetting, "POST", params, false)
            .then((response) => {
                if (response.success) {
                    const { data } = response;
                    setShowModal(false);
                    message(data.message, true);
                    setTimeout(() => {
                        window.location.reload();
                    }, 100);
                } else {
                    message(response.data.message, false);
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t("errorMessage.title"));
            });
    };

    const onModalClose = () => {
        setShowModal(false);
    };

    const onDelete = () => {
        const params = {
            school: schoolId,
            enroll: enrollId,
        };

        dispatch(setLoading(true));
        fetchRequest(getDeleteSetting, "POST", params, false)
            .then((response) => {
                if (response.success) {
                    const { data } = response;
                    setEnrolls(data.enrolls)
                    setShowModal(false);
                    message(data.message, true);
                } else {
                    message(response.data.message, false);
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t("errorMessage.title"));
            });
    };

    return (
        <Card>
            <Card.Body>
                <ButtonToolbar className="d-inssline">
                    <Link
                        to={{
                            pathname: "/enroll/setting/addEntry",
                        }}
                        className="btn btn-primary btn-shadow"
                    >
                        {t("common.addEntry")}
                    </Link>
                </ButtonToolbar>
                <DTable
                    columns={columns}
                    config={config}
                    data={enrolls}
                    contextMenus={contextMenus}
                    selectMode={"radio"}
                    onContextMenuItemClick={handleContextMenuClick}
                />
            </Card.Body>

            {showModal && enrollId ? (
                <DeleteModal
                    onClose={onModalClose}
                    onDelete={onDelete}
                    title={"Элсэлт"}
                >
                    {t("warning.delete_confirmation")}
                    <br />
                    <br />
                    {t("warning.delete_confirmation_description")}
                </DeleteModal>
            ) : null}
        </Card>
    );
};

export default List;
