import React, { useEffect, useRef, useState} from "react";
import {useTranslation} from 'react-i18next';
import {Link, useHistory} from "react-router-dom";
import {Button, Card, Nav, ButtonToolbar, Modal, Form, Row, Col} from "react-bootstrap";
import TreeView from '../../../../app/modules/TreeView/index';
import DTable from "../../../modules/DataTable/DTable";
import {fetchRequest} from "../../../../utils/fetchRequest";
import {setLoading} from "../../../../redux/action";
import {useDispatch, useSelector} from "react-redux";
import {schoolDecisionInit} from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import Instruction from "../../../modules/instruction";
import AddSigning from "./add";

export function SchoolSigning() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [selectedTreeId, setSelectedTreeId] = useState(null);
    const [seasons, setSeasons] = useState([]);
    const [students, setStudents] = useState([]);
    const [addModal, setAddModal] = useState(false);
    const [treeIndex] = useState('school_signing_tree_index');
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    useEffect(() => {
        let params = {
            school: schoolId
        };

        let treeId = localStorage.getItem(treeIndex);
        if(treeId){
            setSelectedTreeId([treeId]);
            params.season =  treeId;
        }

        dispatch(setLoading(true));
        fetchRequest(schoolDecisionInit, 'GET', params)
            .then(response => {
                dispatch(setLoading(false));
                if (response.success) {
                    const data = response.data;
                    setStudents(data?.configs || []);
                    if(response.data.seasons && response.data.seasons.length){
                        setSeasons(response.data.seasons);
                    }
                } else {
                    message(response.data.message, false);
                }
            })
            .catch(() => {
                dispatch(setLoading(false));
            })
    }, []);

    const handleChange = (idArray) => {
        if(idArray && idArray.length > 0){
            let params = {
                school: schoolId,
                season: idArray[0],
                pageSize: 10,
                page: 1,
                search: '',
                sort: [],
                order: 'asc'
            };

            dispatch(setLoading(true));
            fetchRequest(schoolDecisionInit, 'GET', params)
                .then(response => {
                    if (response.success) {
                        setStudents(response?.data.configs);
                        setSelectedTreeId(idArray)
                    } else {
                        message(response.data.message, false);
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                });

            localStorage.setItem(treeIndex, idArray[0]);
        }
    };

    const config = {
        showPagination: true,
    };

    const columns = [
        {
            dataField: "studentCode",
            text: t('student.code'),
            sort: true,
        },
        {
            dataField: "lastName",
            text: t('person.lastName'),
            sort: true,
        },
        {
            dataField: "firstName",
            text: t('person.firstName'),
            sort: true,
        },
        {
            dataField: "class",
            text: t('curriculum.profession'),
            sort: true,
        },
        {
            dataField: "studentStartYear",
            text: t('student.enrollmentSeason'),
            sort: true,
        },
        {
            dataField: "signingDate",
            text: t('common.signingDate'),
            sort: true,
        },
        {
            dataField: "startDate",
            text: t('common.startDate'),
            sort: true,
        },
        {
            dataField: "endDate",
            text: t('common.closeDate'),
            sort: true,
        },
        {
            dataField: "createdDate",
            text: t('common.createdDate'),
            sort: true,
        },
        {
            dataField: "createdUser",
            text: t('common.createdUser'),
            sort: true,
        },
    ];

    const contextMenus = [
        // {
        //     key: 'delete',
        //     title: t('common.delete'),
        //     icon: <i className='las la-trash-alt dt-cm-item-icon' />,
        // },
    ];

    const handleContextMenuClick = (id, key, record) => {
        if(key === 'delete')
        {

        }
    };

    const handleTableInteraction = (object) => {

    };

    const onCloseAddModal = () => {
        setAddModal(false);
    };

    const onSubmit = bodyParams => {
        dispatch(setLoading(true));
        fetchRequest(schoolDecisionInit, 'POST', bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setStudents(data.configs);
                    message(data?.message || t('common.success'), true);
                    onCloseAddModal();
                } else {
                    message(res?.data?.message || t('errorMessage.title'));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    return (
        <div className='row'>
            <div className='col-4'>
                <Card>
                    <Card.Body>
                        <TreeView
                            treeData={seasons}
                            selectedNodes={selectedTreeId}
                            onSelect={(e, data) => handleChange(e, data)}
                            defaultExpandAll={true}
                        />
                    </Card.Body>
                </Card>
            </div>
            <div className='col-8 pl-0'>
                <Card className=''>
                    <Card.Body>
                        <div className='ds-flex justify-content-between'>
                            <div>
                                {
                                    selectedTreeId &&
                                    <button className='btn btn-primary' onClick={() => setAddModal(true)}>{t('common.add')}</button>
                                }
                            </div>
                            <Instruction/>
                        </div>
                        <DTable
                            data={students}
                            columns={columns}
                            config={config}
                            onInteraction={handleTableInteraction}
                            totalDataSize={150}
                            remote
                            showOrdering={true}
                            contextMenus={contextMenus}
                            onContextMenuItemClick={handleContextMenuClick}
                            selectMode={'radio'}
                        />
                    </Card.Body>
                </Card>
            </div>
            {
                addModal &&
                <AddSigning
                    selectedTreeId={selectedTreeId}
                    onClose={onCloseAddModal}
                    onSubmit={onSubmit}
                />
            }
        </div>
    )
}

export default SchoolSigning;