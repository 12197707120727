import SendEmail from "./sendEmailModal";
import SendMessage from "./sendSmsModal";
import ViewHealth from "./viewHealth";
import ChangeStatus from "./changeStatus";
import ChangeAthlete from "./changeAthlete";
import ChangeFinalStatus from './changeFinalStatus'
import ChangeAthleteScore from './changeAthleteScore'
import MakeStudent from './makeStudent'
import ProgramEdit from "./changeProgram";
import message from "../../../modules/message";
import { useTranslation } from "react-i18next";
import ChangePhychology from "./changePhychology";
import { setLoading } from "../../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import DTable from "../../../modules/DataTable/DTable";
import { Card, Tabs, Tab, Form } from "react-bootstrap";
import React, { useState, useEffect, useRef } from "react";
import { fetchRequest } from "../../../../utils/fetchRequest";
import {
    candidateAdminDownloadPassedCandidate, candidateAdminEnrollPhaseIsShow, getCandidateListByPhase, sendEmail,
    certificateCandidateEmailSend, candidateFinalStatus, candidateChangeAthleteScore, candidateMakeStudent
} from "../../../../utils/fetchRequest/Urls";
import Forms from "app/modules/Form/Forms";
import Checkbox from "app/modules/Form/Checkbox";
import { useHistory } from "react-router-dom";

const AddEntry = () => {
    const [tableStoreData] = useState('enroll_process_table_index');
    const [searchStoreData] = useState('enroll_process_search_index');

    const form = useRef();
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const [list, setList] = useState([]);
    const [schoolList, setSchoolList] = useState([]);
    const [downloadPercent, setDownloadPercent] = useState(0)

    const [initLoader, setInitLoader] = useState(true);
    const [showHealth, setShowHealth] = useState(false);
    const [phaseData, setPhaseData] = useState({});
    const [tabKey, setTabKey] = useState("passed");
    const [isPassed, setIsPassed] = useState(1);
    const [enrollId, setEnrollId] = useState(null);
    const [athEnrollId, setAthEnrollId] = useState(null);
    const [selectedId, setSelectedId] = useState(null);
    const [showAthlete, setShowAthlete] = useState(false);
    const [statusEnrollId, setStatusEnrollId] = useState(null);
    const [totalCount, setTotalCount] = useState(0);
    const [toStatusName, setToStatusName] = useState(null);
    const [psychologId, setPsychologyId] = useState(null);
    const [healthId, setHealthId] = useState(null);
    const [showMessageModal, setShowMessageModal] = useState(false);
    const [candidateId, setCandidateId] = useState(null);
    const [showViewModal, setShowViewModal] = useState(false);
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [showStatusModal, setShowStatusModal] = useState(false);
    const [showProgramModal, setShowProgramModal] = useState(false);
    const [phaseIsShow, setPhaseIsShow] = useState(false);

    const [changeFinalStatusData, setChangeFinalStatusData] = useState(null)
    const [showChangeFinalStatusModal, setShowChangeFinalStatusModal] = useState(false)

    const [changeAthleteScoreData, setChangeAthleteScoreData] = useState(null)
    const [showChangeAthleteScoreModal, setShowChangeAthleteScoreModal] = useState(false)

    const [makeStudentData, setMakeStudentData] = useState(null)
    const [showMakeStudentModal, setShowMakeStudentModal] = useState(false)

    const [seasons, setSeasons] = useState([]);
    const [types, setTypes] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [phases, setPhases] = useState([]);
    const [candidateIds, setCandidateIds] = useState([]);

    const [fieldChanged, setFieldChanged] = useState(false)

    const schoolId = useSelector((state) => state?.selectedSchool?.id || null);
    const [checkedRows, setCheckedRows] = useState([]);
    const [candidateScoreList, setCandidateScoreList] = useState([]);

    const [tableState, setTableState] = useState(JSON.parse(localStorage.getItem(tableStoreData)) ||
    {
        page: 1,
        pageSize: 10,
        search: '',
        sort: 'firstName',
        order: 'asc'
    }
    )

    const [searchState, setSearchState] = useState(JSON.parse(localStorage.getItem(searchStoreData)) ||
    {
        selectedSchool: null,
        season: null,
        type: null,
        program: null,
        phase: null
    }
    )

    useEffect(() => {
        let params = {
            ...tableState,
            ...searchState,
            school: schoolId,
            status: tabKey == 'passed' ? 1 : 0
        }

        dispatch(setLoading(true));
        fetchRequest(getCandidateListByPhase, "GET", params)
            .then((res) => {
                if (res.success) {
                    const { data } = res;

                    const listData = data?.list || [];
                    for (let i = 0; i < listData.length; i++) {
                        const obj = listData[i];

                        if (obj?.athleteStatusId) {
                            if (phaseData?.code === "EXAM") {
                                if (obj?.finalStatusCode === 'APPROVED') {
                                    if (obj?.studentId) {
                                        obj.contextMenuKeys = ['status', 'program', 'changeFinalStatus', 'changeAthleteScore'];
                                    } else {
                                        obj.contextMenuKeys = ['status', 'program', 'changeFinalStatus', 'changeAthleteScore', 'makeStudent'];
                                    }
                                } else {
                                    obj.contextMenuKeys = ['status', 'program', 'changeAthleteScore'];
                                }
                            } else {
                                if (obj?.finalStatusCode === 'APPROVED') {
                                    if (obj?.studentId) {
                                        obj.contextMenuKeys = ['status', 'program', 'changeFinalStatus'];
                                    } else {
                                        obj.contextMenuKeys = ['status', 'program', 'changeFinalStatus', 'makeStudent'];
                                    }
                                } else {
                                    obj.contextMenuKeys = ['status', 'program'];
                                }
                            }
                        } else {
                            if (obj?.finalStatusCode === 'APPROVED') {
                                if (obj?.studentId) {
                                    obj.contextMenuKeys = ['status', 'program', 'changeFinalStatus'];
                                } else {
                                    obj.contextMenuKeys = ['status', 'program', 'changeFinalStatus', 'makeStudent'];
                                }
                            } else {
                                obj.contextMenuKeys = ['status', 'program'];
                            }
                        }
                    }

                    setCandidateIds(data?.candidateIds || [])
                    setTotalCount(data?.totalCount || 0);
                    setSchoolList(data?.schoolList || []);
                    setSeasons(data?.seasons || []);
                    setTypes(data?.types || []);
                    setPrograms(data?.programs || []);
                    setPhases(data?.phases || []);
                    setPhaseData(data?.phaseData || null)
                    setPhaseIsShow(data?.isShow || false)
                    setFieldChanged(prev => !prev)
                    setInitLoader(false);
                    setList(listData);
                } else {
                    message(res?.data?.message || t("errorMessage.title"));
                }
            })
            .catch(() => {
                message(t("errorMessage.title"));
            })
            .finally(() => {
                dispatch(setLoading(false));
            })
    }, [searchState]);

    const onSchoolChange = (id) => {
        let cloneData = {
            selectedSchool: id,
            season: null,
            type: null,
            program: null,
            phase: null
        };

        setSearchState(cloneData)
        localStorage.setItem(searchStoreData, JSON.stringify(cloneData));
    };

    const onSeasonSelect = (id) => {
        let cloneData = {
            selectedSchool: searchState.selectedSchool,
            season: id,
            type: null,
            program: null,
            phase: null
        };

        setSearchState(cloneData)
        localStorage.setItem(searchStoreData, JSON.stringify(cloneData));
    };

    const onTypeChange = (id) => {
        let cloneData = {
            selectedSchool: searchState.selectedSchool,
            season: searchState.season,
            type: id,
            program: null,
            phase: null
        };

        setSearchState(cloneData)
        localStorage.setItem(searchStoreData, JSON.stringify(cloneData));
    };

    const onProgramChange = (id) => {
        let cloneData = {
            selectedSchool: searchState.selectedSchool,
            season: searchState.season,
            type: searchState.type,
            program: id,
            phase: null
        };

        setSearchState(cloneData)
        localStorage.setItem(searchStoreData, JSON.stringify(cloneData));
    };

    const onPhaseChange = (id) => {
        let cloneData = {
            selectedSchool: searchState.selectedSchool,
            season: searchState.season,
            type: searchState.type,
            program: searchState.program,
            phase: id
        };

        setSearchState(cloneData)
        localStorage.setItem(searchStoreData, JSON.stringify(cloneData));
    };

    const forms = [
        {
            label: "Сургууль",
            required: true,
            value: searchState?.selectedSchool || null,
            type: "dropdown",
            multiple: false,
            key: "school",
            clearable: true,
            inputWidth: 180,
            options: schoolList,
            onChange: onSchoolChange,
        },
        {
            label: "Хичээлийн жил",
            required: true,
            value: searchState?.season || null,
            type: "dropdown",
            multiple: false,
            clearable: true,
            inputWidth: 180,
            key: "season",
            options: seasons,
            onChange: onSeasonSelect,
        },
        {
            label: "Элсэлтийн төрөл",
            required: true,
            value: searchState?.type || null,
            type: "dropdown",
            multiple: false,
            clearable: true,
            inputWidth: 180,
            key: "enroll",
            options: types,
            onChange: onTypeChange,
        },
        {
            label: "Мэргэжил",
            required: true,
            value: searchState?.program || null,
            type: "dropdown",
            multiple: false,
            key: "program",
            clearable: true,
            inputWidth: 180,
            options: programs,
            onChange: onProgramChange,
        },
        {
            label: "Үе шат",
            value: searchState?.phase || null,
            required: false,
            type: "dropdown",
            multiple: false,
            clearable: true,
            inputWidth: 180,
            key: "stage",
            options: phases,
            onChange: onPhaseChange,
        },
    ];

    async function getList(params, isTabChange) {
        dispatch(setLoading(true));

        fetchRequest(getCandidateListByPhase, "GET", params)
            .then((res) => {
                if (res.success) {
                    const { data } = res;

                    let listData = data?.list || []

                    if (isTabChange) {
                        setCandidateIds(data?.candidateIds || [])
                    } else {
                        if (candidateIds && candidateIds.length > 0) {
                            if (listData && listData.length > 0) {
                                for (let i = 0; i < listData.length; i++) {
                                    let existingCandidate = candidateIds.find(user => user.id == listData[i].userId)
                                    if (existingCandidate) {
                                        listData[i].checkable = existingCandidate.checkable;
                                    }
                                }
                            }
                        } else {
                            setCandidateIds(data?.candidateIds || [])
                        }
                    }

                    for (let i = 0; i < listData.length; i++) {
                        const obj = listData[i];

                        if (obj?.athleteStatusId) {
                            if (phaseData?.code === "EXAM") {
                                if (obj?.finalStatusCode === 'APPROVED') {
                                    if (obj.studentId) {
                                        obj.contextMenuKeys = ['status', 'program', 'changeFinalStatus', 'changeAthleteScore'];
                                    } else {
                                        obj.contextMenuKeys = ['status', 'program', 'changeFinalStatus', 'changeAthleteScore', 'makeStudent'];
                                    }
                                } else {
                                    obj.contextMenuKeys = ['status', 'program', 'changeAthleteScore'];
                                }
                            } else {
                                if (obj?.finalStatusCode === 'APPROVED') {
                                    if (obj?.studentId) {
                                        obj.contextMenuKeys = ['status', 'program', 'changeFinalStatus'];
                                    } else {
                                        obj.contextMenuKeys = ['status', 'program', 'changeFinalStatus', 'makeStudent'];
                                    }
                                } else {
                                    obj.contextMenuKeys = ['status', 'program'];
                                }
                            }

                        } else {
                            if (obj?.finalStatusCode === 'APPROVED') {
                                if (obj?.studentId) {
                                    obj.contextMenuKeys = ['status', 'program', 'changeFinalStatus'];
                                } else {
                                    obj.contextMenuKeys = ['status', 'program', 'changeFinalStatus', 'makeStudent'];
                                }
                            } else {
                                obj.contextMenuKeys = ['status', 'program'];
                            }
                        }
                    }

                    setList(listData);
                    setTotalCount(data?.totalCount || 0);
                    setPhaseData(data?.phaseData || null)
                    setPhaseIsShow(data?.isShow || false)
                } else {
                    message(res?.data?.message || t("errorMessage.title"));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t("errorMessage.title"));
            });
    }

    const handleMessageSendClick = () => {
        setShowMessageModal(true);
    };

    const handleAddEmailClick = () => {
        setShowEmailModal(true);
    };

    const handleAddViewClick = (cell, row) => {
        setSelectedId(cell);
        setEnrollId(row.enrollId);
        setPsychologyId(row.candidatePsychologyId);

        setShowViewModal(true);
    };
    const onModalClose = () => {
        setShowViewModal(false);
    };
    const onShowAthleteClose = () => {
        setShowAthlete(false);
    };
    const onShowAthlete = (cell, row) => {
        setShowAthlete(true);
        setSelectedId(cell);
        setAthEnrollId(row.enrollId);
    };

    const onMessageClose = () => {
        setShowMessageModal(false);
    };
    const onViewHealth = (row, cell) => {
        setShowHealth(true);
        setEnrollId(cell.enrollId);
        setSelectedId(cell.id);
        setHealthId(cell.healthCandidateId);
    };
    const onViewHealthClose = () => {
        setShowHealth(false);
    };
    const onModalStatusClose = () => {
        setShowStatusModal(false);
    };
    const onModalProgramClose = () => {
        setShowProgramModal(false);
    };
    const onModalEmailClose = () => {
        setShowEmailModal(false);
    };

    const onModalFinalStatusClose = () => {
        setChangeFinalStatusData(null)
        setShowChangeFinalStatusModal(false)
    }

    const onModalChangeAthleteClose = () => {
        setChangeAthleteScoreData(null)
        setShowChangeAthleteScoreModal(false)
    }

    const onModalMakeStudentClose = () => {
        setShowMakeStudentModal(false)
    }

    const handleOnFinish = () => {
        onModalEmailClose();
        onModalProgramClose();
        onModalStatusClose();
        onMessageClose();
        onModalClose();
        onShowAthleteClose();

        let params = {
            ...searchState,
            ...tableState,
            school: schoolId,
            status: tabKey == 'passed' ? 1 : 0
        }

        dispatch(setLoading(true));
        fetchRequest(getCandidateListByPhase, "GET", params)
            .then((res) => {
                if (res.success) {
                    const { data } = res;
                    if (data?.list) {

                        const listData = data?.list
                        for (let i = 0; i < listData.length; i++) {
                            const obj = listData[i];

                            if (obj?.athleteStatusId) {
                                if (phaseData?.code === "EXAM") {
                                    if (obj?.finalStatusCode === 'APPROVED') {
                                        if (obj?.studentId) {
                                            obj.contextMenuKeys = ['status', 'program', 'changeFinalStatus', 'changeAthleteScore'];
                                        } else {
                                            obj.contextMenuKeys = ['status', 'program', 'changeFinalStatus', 'changeAthleteScore', 'makeStudent'];
                                        }
                                    } else {
                                        obj.contextMenuKeys = ['status', 'program', 'changeAthleteScore'];
                                    }
                                } else {
                                    if (obj?.finalStatusCode === 'APPROVED') {
                                        if (obj?.studentId) {
                                            obj.contextMenuKeys = ['status', 'program', 'changeFinalStatus'];
                                        } else {
                                            obj.contextMenuKeys = ['status', 'program', 'changeFinalStatus', 'makeStudent'];
                                        }
                                    } else {
                                        obj.contextMenuKeys = ['status', 'program'];
                                    }
                                }

                            } else {
                                if (obj?.finalStatusCode === 'APPROVED') {
                                    if (obj?.studentId) {
                                        obj.contextMenuKeys = ['status', 'program', 'changeFinalStatus'];
                                    } else {
                                        obj.contextMenuKeys = ['status', 'program', 'changeFinalStatus', 'makeStudent'];
                                    }

                                } else {
                                    obj.contextMenuKeys = ['status', 'program'];
                                }
                            }
                        }

                        setList(listData);
                        setCheckedRows([]);
                        setTotalCount(data.totalCount);
                    }
                } else {
                    message(res?.data?.message || t("errorMessage.title"));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t("errorMessage.title"));
            });
    };

    const checkboxChange = (row, index, isChecked, id) => {
        let cloneList = [...list];
        let cloneCandidateIds = [...candidateIds];
        let rowData = list.find(data => data.id == id)

        const checkedRows = []
        if (row == 'row') {
            if (cloneList && cloneList.length > 0) {
                for (let i = 0; i < cloneList.length; i++) {
                    if (cloneList[i].id == id) {
                        cloneList[i].checkable = isChecked;
                    }
                }
            }

            if (cloneCandidateIds && cloneCandidateIds.length > 0) {
                for (let i = 0; i < cloneCandidateIds.length; i++) {
                    if (parseInt(cloneCandidateIds[i].id) == parseInt(rowData.userId)) {
                        cloneCandidateIds[i].checkable = isChecked;
                    }
                }
            }
        } else if (row == 'allCheck') {
            if (cloneList && cloneList.length > 0) {
                for (let i = 0; i < cloneList.length; i++) {
                    cloneList[i].checkable = isChecked;
                }
            }

            if (cloneCandidateIds && cloneCandidateIds.length > 0) {
                for (let i = 0; i < cloneCandidateIds.length; i++) {
                    cloneCandidateIds[i].checkable = isChecked;
                }
            }
        }
        setCandidateIds(cloneCandidateIds)
        setList(cloneList)  
    }

    const onUserInteraction = (object) => {
        if (!initLoader) {
            if (object.search) {
                let cloneData = {
                    ...searchState,
                    page: tableState.search == object.search ? object.page : 1,
                    pageSize: object.pageSize,
                    search: object.search,
                    sort: object.sort,
                    order: object.order,
                    status: tabKey == 'passed' ? 1 : 0
                };

                setTableState(cloneData)
                localStorage.setItem(tableStoreData, JSON.stringify(cloneData));

                cloneData.school = schoolId;
                getList(cloneData)
            } else {
                if (object.page) {
                    setTableState(object)
                    let cloneData = {
                        ...searchState,
                        page: object.page,
                        pageSize: object.pageSize,
                        search: object.search,
                        sort: object.sort,
                        order: object.order,
                        status: tabKey == 'passed' ? 1 : 0
                    };

                    localStorage.setItem(tableStoreData, JSON.stringify(cloneData));

                    cloneData.school = schoolId;
                    getList(cloneData)
                }
            }
        }
    };

    const onTabChange = (key) => {
        setTabKey(key);
        if (key == 'passed') {
            setIsPassed(null);
            setCheckedRows([]);
            setIsPassed(1);
        } else if (key == 'failed') {
            setIsPassed(null);
            setCheckedRows([]);
            setIsPassed(0);
        }

        let cloneData = {
            page: 1,
            pageSize: tableState.pageSize,
            search: tableState.search,
            sort: tableState.sort,
            order: tableState.order,
        };

        localStorage.setItem(tableStoreData, JSON.stringify(cloneData));
        setTableState(cloneData)

        let params = {
            ...searchState,
            ...tableState,
            page: 1,
            school: schoolId,
            status: key == 'passed' ? 1 : 0
        };

        getList(params, true)
    };

    const viewClick = (cell, row) => {
        const url = `/enroll/candidate/profile`;
        history.push({
            pathname: url,
            state: {
                id: row.id,
                program: searchState.program,
                backUrl: '/enroll/process'
            },
        });
    };

    const columns = [
        {
            dataField: "studentId",
            text: "",
            sort: true,
            formatter(cell, row) {
                if (cell) {
                    return <span className="circle-sm-gray"
                        title={row?.studentCode}
                        style={{ backgroundColor: '#F5AF04', display: 'inline-block' }}></span>
                } else {
                    return null
                }
            },
        },
        {
            dataField: "finalStatusCode",
            text: "",
            formatter(cell, row) {
                if (cell === 'APPROVED') {
                    return <span className="circle-sm-gray"
                        title={row?.finalStatusName}
                        style={{ backgroundColor: '#299F72', display: 'inline-block' }}></span>
                } else if (cell === 'DENIED') {
                    return <span className="circle-sm-gray"
                        title={row?.finalStatusName}
                        style={{ backgroundColor: '#F64E60', display: 'inline-block' }}></span>
                } else {
                    return null
                }
            },
        },
        {
            dataField: "schoolShortName",
            text: "Бүрэлдэхүүн сургууль",
            hideCol: true
        },
        {
            dataField: "programName",
            text: "Мэргэжил",
            hideCol: true
        },
        {
            dataField: "statusName",
            text: "Төлөв",
        },
        {
            dataField: "approveLogCreatedDate",
            text: "Баталгаажуулсан огноо",
            sort: true,
            headerStyle: () => {
                return {
                    width: 180
                }
            },
        },
        {
            dataField: "message",
            text: "Мессеж",
            formatter(cell, row) {
                if (Number(row.isSendMessage === "1")) {
                    return (
                        <div
                            style={{
                                backgroundColor: "#299F72",
                                color: "#1E1E2D",
                                borderRadius: "4px",
                                fontSize: "15px",
                            }}
                            className="text-center"
                        >
                            <p className="">Илгээсэн</p>
                        </div>
                    );
                } else {
                    return <p>-</p>;
                }
            },
        },
        {
            dataField: "email",
            text: "И-мэйл",
            formatter(cell, row) {
                if (Number(row.email == 1)) {
                    return (
                        <div
                            style={{
                                backgroundColor: "#299F72",
                                color: "#1E1E2D",
                                borderRadius: "4px",
                                fontSize: "15px",
                            }}
                            className="text-center"
                        >
                            <p className="">Илгээсэн</p>
                        </div>
                    );
                } else {
                    return <p>-</p>;
                }
            },
        },
        {
            dataField: "candidateNo",
            text: "ID",
            sort: true,
            formatter(cell, row, id) {
                return (
                    <a
                        className="underline"
                        onClick={() => {
                            viewClick(cell, row, id);
                        }}
                    >
                        {row.candidateNo}
                    </a>
                );
            },
        },
        // {
        //     dataField: "studentCode",
        //     text: "Оюутны код",
        //     sort: true,
        // },
        {
            dataField: "registrationNumber",
            text: "Регистрийн дугаар",
            sort: true,
        },
        {
            dataField: "lastName",
            text: "Овог",
            sort: true,
        },
        {
            dataField: "firstName",
            text: "Нэр",
            sort: true,
        },
        {
            dataField: "studentScore",
            text: "Үр дүн",
            sort: true,
            formatter(cell, row, id) {
                return (
                    row.score
                );
            },
        },
        {
            dataField: "userEmail",
            text: t("common.email"),
            hideCol: true
        },
        {
            dataField: "phoneNumber",
            text: "Утасны дугаар",
            hideCol: true
        },
        {
            dataField: "examLoc",
            text: "Шалгалт өгсөн газар",
            sort: true,
        },
        {
            dataField: "score",
            text: "Үр дүн",
            hideCol: true
        },
    ];

    const columns1 = [
        {
            dataField: "statusName",
            text: "Төлөв",
        },
        {
            dataField: "message",
            text: "Мессеж",
            formatter(cell, row) {
                if (Number(row.message === "1")) {
                    return (
                        <div
                            style={{
                                backgroundColor: "#299F72",
                                color: "#1E1E2D",
                                borderRadius: "4px",
                                fontSize: "15px",
                            }}
                            className="text-center"
                        >
                            <p className="">Илгээсэн</p>
                        </div>
                    );
                } else {
                    return <p>-</p>;
                }
            },
        },

        {
            dataField: "email",
            text: "И-мэйл",
            formatter(cell, row) {
                if (Number(row.email == 1)) {
                    return (
                        <div
                            style={{
                                backgroundColor: "#299F72",
                                color: "#1E1E2D",
                                borderRadius: "4px",
                                fontSize: "15px",
                            }}
                            className="text-center"
                        >
                            <p className="">Илгээсэн</p>
                        </div>
                    );
                } else {
                    return <p>-</p>;
                }
            },
        },
        {
            dataField: "candidateNo",
            text: "ID",
            sort: true,
            formatter(cell, row) {
                if (cell) {
                    return (
                        <a
                            style={{ color: "#5C3CC7", textDecoration: "underline" }}
                            onClick={() => onViewHealth(cell, row)}
                        >
                            {row.id}
                        </a>
                    );
                }
            },
        },
        // {
        //     dataField: "studentCode",
        //     text: "Оюутны код",
        //     sort: true,
        // },
        {
            dataField: "registrationNumber",
            text: "Регистрийн дугаар",
            sort: true,
        },
        {
            dataField: "lastName",
            text: "Овог",
            sort: true,
        },
        {
            dataField: "firstName",
            text: "Нэр",
            sort: true,
        },
        {
            dataField: "studentScore",
            text: "Үр дүн",
            sort: true,
            formatter(cell, row, id) {
                return (
                    row.score
                );
            },
        },
        {
            dataField: "examLoc",
            text: "Шалгалт өгсөн газар",
            sort: true,
        },
        {
            dataField: "score",
            text: "Үр дүн",
            hideCol: true
        },
    ];

    const columns2 = [
        {
            dataField: "studentId",
            text: "",
            sort: true,
            formatter(cell, row) {
                if (cell) {
                    return <span className="circle-sm-gray"
                        title={row?.studentCode}
                        style={{ backgroundColor: '#F5AF04', display: 'inline-block' }}></span>
                } else {
                    return null
                }
            },
        },
        {
            dataField: "finalStatusCode",
            text: "",
            formatter(cell, row) {
                if (cell === 'APPROVED') {
                    return <span className="circle-sm-gray"
                        title={row?.finalStatusName}
                        style={{ backgroundColor: '#299F72', display: 'inline-block' }}></span>
                } else if (cell === 'DENIED') {
                    return <span className="circle-sm-gray"
                        title={row?.finalStatusName}
                        style={{ backgroundColor: '#F64E60', display: 'inline-block' }}></span>
                } else {
                    return null
                }
            },
        },
        {
            dataField: "schoolShortName",
            text: "Бүрэлдэхүүн сургууль",
            hideCol: true
        },
        {
            dataField: "programName",
            text: "Мэргэжил",
            hideCol: true
        },
        {
            dataField: "statusName",
            text: "Төлөв",
        },
        {
            dataField: "approveLogCreatedDate",
            text: "Баталгаажуулсан огноо",
            sort: true,
            headerStyle: () => {
                return {
                    width: 180
                }
            },
        },
        {
            dataField: "message",
            text: "Мессеж",
            formatter(cell, row) {
                if (Number(row.message === "1")) {
                    return (
                        <div
                            style={{
                                backgroundColor: "#299F72",
                                color: "#1E1E2D",
                                borderRadius: "4px",
                                fontSize: "15px",
                            }}
                            className="text-center"
                        >
                            <p className="">Илгээсэн</p>
                        </div>
                    );
                } else {
                    return <p>-</p>;
                }
            },
        },
        {
            dataField: "email",
            text: "И-мэйл",
            formatter(cell, row) {
                if (Number(row.email == 1)) {
                    return (
                        <div
                            style={{
                                backgroundColor: "#299F72",
                                color: "#1E1E2D",
                                borderRadius: "4px",
                                fontSize: "15px",
                            }}
                            className="text-center"
                        >
                            <p className="">Илгээсэн</p>
                        </div>
                    );
                } else {
                    return <p>-</p>;
                }
            },
        },
        {
            dataField: "candidateNo",
            text: "ID",
            sort: true,
            formatter(cell, row) {
                if (cell) {
                    return (
                        <a
                            style={{ color: "#5C3CC7", textDecoration: "underline" }}
                            onClick={() => viewClick(cell, row)}
                        >
                            {row.id}
                        </a>
                    );
                }
            },
        },
        // {
        //     dataField: "studentCode",
        //     text: "Оюутны код",
        //     sort: true,
        // },
        {
            dataField: "registrationNumber",
            text: "Регистрийн дугаар",
            sort: true,
        },
        {
            dataField: "lastName",
            text: "Овог",
            sort: true,
        },
        {
            dataField: "firstName",
            text: "Нэр",
            sort: true,
        },
        {
            dataField: "userEmail",
            text: t("common.email"),
            hideCol: true
        },
        {
            dataField: "phoneNumber",
            text: "Утасны дугаар",
            hideCol: true
        },
        {
            dataField: "examLoc",
            text: "Шалгалт өгсөн газар",
            sort: true,
        }
    ];

    const columns3 = [
        {
            dataField: "statusName",
            text: "Төлөв",
        },
        {
            dataField: "message",
            text: "Мессеж",
            formatter(cell, row) {
                if (Number(row.message === "1")) {
                    return (
                        <div
                            style={{
                                backgroundColor: "#299F72",
                                color: "#1E1E2D",
                                borderRadius: "4px",
                                fontSize: "15px",
                            }}
                            className="text-center"
                        >
                            <p className="">Илгээсэн</p>
                        </div>
                    );
                } else {
                    return <p>-</p>;
                }
            },
        },
        {
            dataField: "email",
            text: "И-мэйл",
            formatter(cell, row) {
                if (Number(row.email == 1)) {
                    return (
                        <div
                            style={{
                                backgroundColor: "#299F72",
                                color: "#1E1E2D",
                                borderRadius: "4px",
                                fontSize: "15px",
                            }}
                            className="text-center"
                        >
                            <p className="">Илгээсэн</p>
                        </div>
                    );
                } else {
                    return <p>-</p>;
                }
            },
        },
        {
            dataField: "candidateNo",
            text: "ID",
            sort: true,
            formatter(cell, row) {
                if (cell) {
                    return (
                        <a
                            style={{ color: "#5C3CC7", textDecoration: "underline" }}
                            onClick={() => handleAddViewClick(cell, row)}
                        >
                            {row.id}
                        </a>
                    );
                }
            },
        },
        // {
        //     dataField: "studentCode",
        //     text: "Оюутны код",
        //     sort: true,
        // },
        {
            dataField: "registrationNumber",
            text: "Регистрийн дугаар",
            sort: true,
        },
        {
            dataField: "lastName",
            text: "Овог",
            sort: true,
        },
        {
            dataField: "firstName",
            text: "Нэр",
            sort: true,
        },
        {
            dataField: "studentScore",
            text: "Үр дүн",
            sort: true,
            formatter(cell, row, id) {
                return (
                    row.score
                );
            },
        },
        {
            dataField: "examLoc",
            text: "Шалгалт өгсөн газар",
            sort: true,
        },
        {
            dataField: "score",
            text: "Үр дүн",
            hideCol: true
        },
    ];

    const columns4 = [
        {
            dataField: "studentId",
            text: "",
            sort: true,
            formatter(cell, row) {
                if (cell) {
                    return <span className="circle-sm-gray"
                        title={row?.studentCode}
                        style={{ backgroundColor: '#F5AF04', display: 'inline-block' }}></span>
                } else {
                    return null
                }
            },
        },
        {
            dataField: "finalStatusCode",
            text: "",
            formatter(cell, row) {
                if (cell === 'APPROVED') {
                    return <span className="circle-sm-gray"
                        title={row?.finalStatusName}
                        style={{ backgroundColor: '#299F72', display: 'inline-block' }}></span>
                } else if (cell === 'DENIED') {
                    return <span className="circle-sm-gray"
                        title={row?.finalStatusName}
                        style={{ backgroundColor: '#F64E60', display: 'inline-block' }}></span>
                } else {
                    return null
                }
            },
        },
        {
            dataField: "schoolShortName",
            text: "Бүрэлдэхүүн сургууль",
            hideCol: true
        },
        {
            dataField: "programName",
            text: "Мэргэжил",
            hideCol: true
        },
        {
            dataField: "statusName",
            text: "Төлөв",
        },
        {
            dataField: "approveLogCreatedDate",
            text: "Баталгаажуулсан огноо",
            sort: true,
            headerStyle: () => {
                return {
                    width: 180
                }
            },
        },
        {
            dataField: "message",
            text: "Мессеж",
            formatter(cell, row) {
                if (Number(row.isSendMessage === "1")) {
                    return (
                        <div
                            style={{
                                backgroundColor: "#299F72",
                                color: "#1E1E2D",
                                borderRadius: "4px",
                                fontSize: "15px",
                            }}
                            className="text-center"
                        >
                            <p className="">Илгээсэн</p>
                        </div>
                    );
                } else {
                    return <p>-</p>;
                }
            },
        },
        {
            dataField: "email",
            text: "И-мэйл",
            formatter(cell, row) {
                if (Number(row.email == 1)) {
                    return (
                        <div
                            style={{
                                backgroundColor: "#299F72",
                                color: "#1E1E2D",
                                borderRadius: "4px",
                                fontSize: "15px",
                            }}
                            className="text-center"
                        >
                            <p className="">Илгээсэн</p>
                        </div>
                    );
                } else {
                    return <p>-</p>;
                }
            },
        },
        {
            dataField: "candidateNo",
            text: "ID",
            sort: true,
            formatter(cell, row, id) {
                return (
                    <a
                        className="underline"
                        onClick={() => {
                            viewClick(cell, row, id);
                        }}
                    >
                        {row.candidateNo}
                    </a>
                );
            },
        },
        // {
        //     dataField: "studentCode",
        //     text: "Оюутны код",
        //     sort: true,
        // },
        {
            dataField: "registrationNumber",
            text: "Регистрийн дугаар",
            sort: true,
        },
        {
            dataField: "lastName",
            text: "Овог",
            sort: true,
        },
        {
            dataField: "firstName",
            text: "Нэр",
            sort: true,
        },
        {
            dataField: "studentScore",
            text: "Үр дүн",
            sort: true,
            formatter(cell, row, id) {
                return (
                    row.score
                );
            },
        },
        {
            dataField: "userEmail",
            text: t("common.email"),
            hideCol: true
        },
        {
            dataField: "phoneNumber",
            text: "Утасны дугаар",
            hideCol: true
        },
        {
            dataField: "examLoc",
            text: "Шалгалт өгсөн газар",
            sort: true,
        },
        {
            dataField: "score",
            text: "Үр дүн",
            hideCol: true
        },
    ];
    
    const contextMenus = [
        {
            key: "status",
            title: "Төлөв солих",
            icon: <i className="las la-edit dt-cm-item-icon" />,
        },
        {
            key: "program",
            title: "Хөтөлбөр солих",
            icon: <i className="las la-edit dt-cm-item-icon" />,
        },
        {
            key: "changeFinalStatus",
            title: "Баталгаажуулалт солих",
            icon: <i className="las la-edit dt-cm-item-icon" />,
        },
        {
            key: "changeAthleteScore",
            title: "Оноо солих",
            icon: <i className="las la-edit dt-cm-item-icon" />,
        },
        // {
        //     key: "makeStudent",
        //     title: "Оюутан болгох",
        //     icon: <i className="las la-edit dt-cm-item-icon" />,
        // }
    ];

    const handleContextMenuClick = (id, key, row) => {
        if (id && key) {
            if (key === 'changeFinalStatus') {
                setChangeFinalStatusData(row)
                setShowChangeFinalStatusModal(true)
            } else if (key === 'changeAthleteScore') {
                setChangeAthleteScoreData(row)
                setShowChangeAthleteScoreModal(true)
            } else if (key === 'makeStudent') {
                // setMakeStudentData(row)
                // setShowMakeStudentModal(true)
            } else {
                if (key === "status") {
                    setShowStatusModal(true);
                } else if (key === "program") {
                    setShowProgramModal(true);
                }
                var enrollId = "";
                var statusName = "";
                list.forEach((item) => {
                    if (item.id === id) {
                        enrollId = item.enrollId;
                        statusName = item.statusName;
                    }
                });

                setStatusEnrollId(enrollId);
                setCandidateId(id);
                setToStatusName(statusName);
            }
        }
    };

    const downloadApprovedExcel = () => {
        if (searchState?.selectedSchool) {
            if (searchState?.type) {
                if (searchState?.program) {
                    window.open(`https://darkhanapi.nomch.mn/api/enroll-dashboard/download-excel?school=${searchState?.selectedSchool}&program=${searchState?.program}&enroll=${searchState?.type}`)
                } else {
                    message('Мэргэжил сонгоно уу');
                }
            } else {
                message('Элсэлтийн төрөл сонгоно уу');
            }
        } else {
            message('Сургууль сонгоно уу');
        }
    }

    const buildExcelFileName = () => {
        if (searchState) {
            let fileName = '';
            if (searchState?.selectedSchool) {
                for (let s = 0; s < schoolList.length; s++) {
                    if (schoolList[s].value === searchState?.selectedSchool) {
                        fileName += schoolList[s].text;
                        break;
                    }
                }
            }
            if (searchState?.program) {
                for (let p = 0; p < programs.length; p++) {
                    if (programs[p].value === searchState?.program) {
                        fileName += '-' + programs[p].text;
                        break;
                    }
                }
            }
            return fileName + ' элсэгчид';
        } else {
            return 'file';
        }
    }

    const getCheckedList = () => {
        const candidates = []
        for (let i = 0; i < list.length; i++) {
            if (list[i].checkable) {
                candidates.push(list[i])
            }
        }
        return candidates
    }

    const renderTab = () => {
        const tabs = {
            passed: (
                <>
                    <div className="mt-1">
                        <Card.Body>
                            <div className="flex space-x-2 ">
                                <button
                                    className="btn btn-primary shadow"
                                    onClick={handleAddEmailClick}
                                >
                                    И-мэйл явуулах
                                </button>
                                <button
                                    className="btn btn-primary shadow"
                                    onClick={handleMessageSendClick}
                                    style={{ marginLeft: "1.1rem" }}
                                >
                                    Мессеж явуулах
                                </button>

                                <button
                                    className="btn btn-primary shadow"
                                    onClick={downloadApprovedExcel}
                                    style={{ marginLeft: "1.1rem" }}
                                >
                                    Баталгаажуулсан элсэгчдийг татах
                                </button>
                            </div>
                            {showMessageModal && (
                                <SendMessage
                                    params={{
                                        stage: phaseData?.code
                                    }}
                                    onClose={onMessageClose}
                                    candidateList={getCheckedList()}
                                    onFinish={handleOnFinish}
                                />
                            )}
                            <DTable
                                columns={
                                    phaseData?.code === "EYESH"
                                        ? columns
                                        : phaseData?.code === "HEALTH"
                                            ? columns1
                                            : phaseData?.code === "EXAM"
                                                ? columns2
                                                : phaseData?.code === "PSYCHOLOGY"
                                                    ? columns3
                                                    : columns4
                                }
                                config={{
                                    showPagination: true,
                                    showFilter: true,
                                    excelFileRemote: true,
                                    excelFileName: buildExcelFileName(),
                                    excelFileRemoteUrl: 'candidate-admin/excel/stage?school=' + schoolId +
                                        '&selectedSchool=' + searchState.selectedSchool +
                                        '&season=' + searchState.season +
                                        '&type=' + searchState.type +
                                        '&program=' + searchState.program +
                                        '&phase=' + searchState.phase +
                                        '&search=' + tableState.search +
                                        '&sort=' + tableState.sort +
                                        '&order=' + tableState.order +
                                        '&status=' + 1 +
                                        '&t=/',
                                    excelExport: true,
                                    defaultPageOptions: {
                                        page: tableState?.page || 1,
                                        sizePerPage: tableState?.pageSize || 10,
                                        search: tableState?.search || '',
                                    },
                                    defaultSort: [{
                                        dataField: tableState?.sort || 'score',
                                        order: tableState?.order || 'desc'
                                    }],
                                }}
                                individualContextMenus={true}
                                data={list}
                                onInteraction={onUserInteraction}
                                showOrdering={true}
                                remote
                                selectMode={"radio"}
                                totalDataSize={totalCount}
                                contextMenus={contextMenus}
                                onContextMenuItemClick={handleContextMenuClick}
                                checkable
                                onCheckable={checkboxChange}
                            />
                        </Card.Body>
                    </div>
                </>
            ),
            failed: (
                <div className="table-component">
                    <Card.Body>
                        <div className="flex space-x-2 ">
                            <button
                                className="btn btn-primary shadow"
                                onClick={handleAddEmailClick}
                            >
                                И-мэйл явуулах
                            </button>
                            <button
                                className="btn btn-primary shadow"
                                onClick={handleMessageSendClick}
                                style={{ marginLeft: "1.1rem" }}
                            >
                                Мессеж явуулах
                            </button>
                        </div>
                        {showMessageModal && (
                            <SendMessage
                                params={{
                                    stage: phaseData?.code
                                }}
                                onClose={onMessageClose}
                                candidateList={getCheckedList()}
                                onFinish={handleOnFinish}
                            />
                        )}
                        <DTable
                            columns={
                                phaseData?.code === "EYESH"
                                    ? columns
                                    : phaseData?.code === "HEALTH"
                                        ? columns1
                                        : phaseData?.code === "EXAM"
                                            ? columns2
                                            : phaseData?.code === "PSYCHOLOGY"
                                                ? columns3
                                                : columns4
                            }
                            config={{
                                showPagination: true,
                                showFilter: true,
                                showAllData: false,
                                excelFileRemote: true,
                                excelFileName: buildExcelFileName(),
                                excelFileRemoteUrl: 'candidate-admin/excel/stage?school=' + schoolId +
                                    '&selectedSchool=' + searchState.selectedSchool +
                                    '&season=' + searchState.season +
                                    '&type=' + searchState.type +
                                    '&program=' + searchState.program +
                                    '&phase=' + searchState.phase +
                                    '&search=' + tableState.search +
                                    '&sort=' + tableState.sort +
                                    '&order=' + tableState.order +
                                    '&status=' + 1 +
                                    '&t=/',
                                excelExport: true,
                                defaultPageOptions: {
                                    page: tableState?.page || 1,
                                    sizePerPage: tableState?.pageSize || 10,
                                    search: tableState?.search || '',
                                },
                                defaultSort: [{
                                    dataField: tableState?.sort || 'score',
                                    order: tableState?.order || 'desc'
                                }],
                            }}
                            data={list}
                            remote
                            setLoading={true}
                            onInteraction={onUserInteraction}
                            selectMode={"radio"}
                            totalDataSize={totalCount}
                            contextMenus={contextMenus}
                            onContextMenuItemClick={handleContextMenuClick}
                            individualContextMenus={true}
                            checkable
                            onCheckable={checkboxChange}
                        />
                    </Card.Body>
                </div>
            ),
        };
        return tabs[tabKey];
    };

    const handleSearch = () => {
        const [formValid, formValue] = form.current.validate();
        if (formValid) {
            let params = {
                ...searchState,
                school: schoolId,
                status: tabKey == 'passed' ? 1 : 0
            }

            getList(params);
        }
    };

    const onHandlerRadioChange = (checked) => {
        setPhaseIsShow(!checked)

        let params = {
            ...searchState,
            value: !checked == true ? 1 : 0
        }

        dispatch(setLoading(true));
        fetchRequest(candidateAdminEnrollPhaseIsShow, "POST", params)
            .then((res) => {
                if (res.success) {
                    message(res?.data?.message, true);
                } else {
                    message(res?.data?.message || t("errorMessage.title"));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t("errorMessage.title"));
            });
    };

    const downloadPassedStudents = (pageNum = 1, list = []) => {
        let params = (searchState || {});
        params.page = pageNum;

        console.log('params', params)

        dispatch(setLoading(true));
        fetchRequest(candidateAdminDownloadPassedCandidate, "POST", params)
            .then((res) => {
                if (res.success) {
                    if(res?.data?.phaseCode == 'ENROLL_NO'){
                        let params = {
                            ...searchState,
                            ...tableState,
                            school: schoolId,
                            status: tabKey == 'passed' ? 1 : 0,
                            checkRank: 1
                        };

                        getList(params)
                        message(res?.data?.message, true);
                        dispatch(setLoading(false));
                    } else {
                        if ((res?.data?.page * res?.data?.pageSize) > res?.data?.totalCount) {
                            let params = {
                                ...searchState,
                                ...tableState,
                                school: schoolId,
                                status: tabKey == 'passed' ? 1 : 0,
                                checkRank: 1
                            };
    
                            getList(params)
                            message(res?.data?.message, true);
                            dispatch(setLoading(false));
                        } else {
                            setDownloadPercent(res?.data?.totalCount > 0 ? parseInt(((res?.data?.page * res?.data?.pageSize) / res?.data?.totalCount) * 100) : 0)
                            downloadPassedStudents(res?.data?.page + 1)
                        }    
                    }
                } else {
                    message(res?.data?.message || t("errorMessage.title"));
                    dispatch(setLoading(false));
                }
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t("errorMessage.title"));
            })
    }


    const onEmailSubmit = (params) => {
        dispatch(setLoading(true));
        fetchRequest(certificateCandidateEmailSend, "POST", params)
            .then((res) => {
                if (res.success) {
                    message(res.data.message, true);
                    setShowEmailModal(false);
                } else {
                    message(res.data.message, false);
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
            });
    }

    const submitFinalStatusChange = (programId = null, description = null) => {
        if (programId) {
            dispatch(setLoading(true));
            const params = {
                program: changeFinalStatusData?.programId,
                candidate: changeFinalStatusData?.candidateNo,
                toProgram: programId,
                description
            }
            fetchRequest(candidateFinalStatus, "POST", params)
                .then((res) => {
                    if (res.success) {
                        onModalFinalStatusClose()

                        let params = {
                            ...tableState,
                            ...searchState,
                            school: schoolId,
                            status: tabKey == 'passed' ? 1 : 0
                        }

                        dispatch(setLoading(true));
                        fetchRequest(getCandidateListByPhase, "GET", params)
                            .then((res) => {
                                if (res.success) {
                                    const { data } = res;

                                    const listData = data?.list || [];

                                    for (let i = 0; i < listData.length; i++) {
                                        const obj = listData[i];

                                        if (obj?.athleteStatusId) {
                                            if (phaseData?.code === "EXAM") {
                                                if (obj?.finalStatusCode === 'APPROVED') {
                                                    obj.contextMenuKeys = ['status', 'program', 'changeFinalStatus', 'changeAthleteScore', 'makeStudent'];
                                                } else {
                                                    obj.contextMenuKeys = ['status', 'program', 'changeAthleteScore'];
                                                }
                                            } else {
                                                if (obj?.finalStatusCode === 'APPROVED') {
                                                    obj.contextMenuKeys = ['status', 'program', 'changeFinalStatus', 'makeStudent'];
                                                } else {
                                                    obj.contextMenuKeys = ['status', 'program'];
                                                }
                                            }

                                        } else {
                                            if (obj?.finalStatusCode === 'APPROVED') {
                                                obj.contextMenuKeys = ['status', 'program', 'changeFinalStatus', 'makeStudent'];
                                            } else {
                                                obj.contextMenuKeys = ['status', 'program'];
                                            }
                                        }
                                    }

                                    setList(listData);

                                    setCandidateIds(data?.candidateIds || [])
                                    setTotalCount(data?.totalCount || 0);
                                    setSchoolList(data?.schoolList || []);
                                    setSeasons(data?.seasons || []);
                                    setTypes(data?.types || []);
                                    setPrograms(data?.programs || []);
                                    setPhases(data?.phases || []);
                                    setPhaseData(data?.phaseData || null)
                                    setPhaseIsShow(data?.isShow || false)
                                    setFieldChanged(prev => !prev)
                                    setInitLoader(false);
                                } else {
                                    message(res?.data?.message || t("errorMessage.title"));
                                }
                            })
                            .catch(() => {
                                message(t("errorMessage.title"));
                            })
                            .finally(() => {
                                dispatch(setLoading(false));
                            })

                    } else {
                        message(res.data.message, false);
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                });
        } else {
            message('Солих хөтөлбөр сонгоно уу');
        }
    }

    const submitChangeAthleteScoreChange = (scores = [], description = null) => {
        if (scores && scores.length > 0) {
            let changeCriterias = []
            for (let s = 0; s < scores.length; s++) {
                const scoreObj = scores[s]
                if ('tempScore' in scoreObj) {
                    changeCriterias.push({
                        id: scoreObj?.id,
                        score: scoreObj?.tempScore
                    })
                }
            }

            dispatch(setLoading(true));
            const params = {
                program: changeAthleteScoreData?.programId,
                candidate: changeAthleteScoreData?.candidateNo,
                scores: JSON.stringify(changeCriterias),
                description
            }
            fetchRequest(candidateChangeAthleteScore, "POST", params)
                .then((res) => {
                    if (res.success) {
                        onModalChangeAthleteClose()

                        let params = {
                            ...tableState,
                            ...searchState,
                            school: schoolId,
                            status: tabKey == 'passed' ? 1 : 0
                        }

                        dispatch(setLoading(true));
                        fetchRequest(getCandidateListByPhase, "GET", params)
                            .then((res) => {
                                if (res.success) {
                                    const { data } = res;

                                    const listData = data?.list || [];
                                    for (let i = 0; i < listData.length; i++) {
                                        const obj = listData[i];

                                        if (obj?.athleteStatusId) {
                                            if (phaseData?.code === "EXAM") {
                                                if (obj?.finalStatusCode === 'APPROVED') {
                                                    obj.contextMenuKeys = ['status', 'program', 'changeFinalStatus', 'changeAthleteScore', 'makeStudent'];
                                                } else {
                                                    obj.contextMenuKeys = ['status', 'program', 'changeAthleteScore'];
                                                }
                                            } else {
                                                if (obj?.finalStatusCode === 'APPROVED') {
                                                    obj.contextMenuKeys = ['status', 'program', 'changeFinalStatus', 'makeStudent'];
                                                } else {
                                                    obj.contextMenuKeys = ['status', 'program'];
                                                }
                                            }

                                        } else {
                                            if (obj?.finalStatusCode === 'APPROVED') {
                                                obj.contextMenuKeys = ['status', 'program', 'changeFinalStatus', 'makeStudent'];
                                            } else {
                                                obj.contextMenuKeys = ['status', 'program'];
                                            }
                                        }
                                    }

                                    setList(listData);

                                    setCandidateIds(data?.candidateIds || [])
                                    setTotalCount(data?.totalCount || 0);
                                    setSchoolList(data?.schoolList || []);
                                    setSeasons(data?.seasons || []);
                                    setTypes(data?.types || []);
                                    setPrograms(data?.programs || []);
                                    setPhases(data?.phases || []);
                                    setPhaseData(data?.phaseData || null)
                                    setPhaseIsShow(data?.isShow || false)
                                    setFieldChanged(prev => !prev)
                                    setInitLoader(false);
                                } else {
                                    message(res?.data?.message || t("errorMessage.title"));
                                }
                            })
                            .catch(() => {
                                message(t("errorMessage.title"));
                            })
                            .finally(() => {
                                dispatch(setLoading(false));
                            })

                    } else {
                        message(res.data.message, false);
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                });
        } else {
            message('Онооны мэдээлэл олдсонгүй');
        }
    }

    const submitMakeStudent = (data) => {
        let params = {
            candidate: data?.candidateNo,
            program: data?.programId,
            scholarship: data?.scholarship,
            class: data?.class
        }

        dispatch(setLoading(true));
        fetchRequest(candidateMakeStudent, "POST", params)
            .then((res) => {
                if (res.success) {
                    const { data } = res;

                    onModalMakeStudentClose()

                    if (data?.redirectScholarship) {
                        history.push({
                            pathname: '/scholarship',
                            state: {
                                academicProgramId: data?.academicProgramId,
                                programId: data?.programId,
                                student: data?.studentId,
                                studentCode: data?.studentCode,
                            }
                        });
                    }

                    let params = {
                        ...tableState,
                        ...searchState,
                        school: schoolId,
                        status: tabKey == 'passed' ? 1 : 0
                    }

                    dispatch(setLoading(true));
                    fetchRequest(getCandidateListByPhase, "GET", params)
                        .then((res) => {
                            if (res.success) {
                                const { data } = res;

                                const listData = data?.list || [];
                                for (let i = 0; i < listData.length; i++) {
                                    const obj = listData[i];

                                    if (obj?.athleteStatusId) {
                                        if (phaseData?.code === "EXAM") {
                                            if (obj?.finalStatusCode === 'APPROVED') {
                                                obj.contextMenuKeys = ['status', 'program', 'changeFinalStatus', 'changeAthleteScore', 'makeStudent'];
                                            } else {
                                                obj.contextMenuKeys = ['status', 'program', 'changeAthleteScore'];
                                            }
                                        } else {
                                            if (obj?.finalStatusCode === 'APPROVED') {
                                                obj.contextMenuKeys = ['status', 'program', 'changeFinalStatus', 'makeStudent'];
                                            } else {
                                                obj.contextMenuKeys = ['status', 'program'];
                                            }
                                        }

                                    } else {
                                        if (obj?.finalStatusCode === 'APPROVED') {
                                            obj.contextMenuKeys = ['status', 'program', 'changeFinalStatus', 'makeStudent'];
                                        } else {
                                            obj.contextMenuKeys = ['status', 'program'];
                                        }
                                    }
                                }

                                setList(listData);

                                setCandidateIds(data?.candidateIds || [])
                                setTotalCount(data?.totalCount || 0);
                                setSchoolList(data?.schoolList || []);
                                setSeasons(data?.seasons || []);
                                setTypes(data?.types || []);
                                setPrograms(data?.programs || []);
                                setPhases(data?.phases || []);
                                setPhaseData(data?.phaseData || null)
                                setPhaseIsShow(data?.isShow || false)
                                setFieldChanged(prev => !prev)
                                setInitLoader(false);
                            } else {
                                message(res?.data?.message || t("errorMessage.title"));
                            }
                        })
                        .catch(() => {
                            message(t("errorMessage.title"));
                        })
                        .finally(() => {
                            dispatch(setLoading(false));
                        })

                } else {
                    message(res?.data?.message || t("errorMessage.title"));
                }
            })
            .catch(() => {
                message(t("errorMessage.title"));
            })
            .finally(() => {
                dispatch(setLoading(false));
            })
    }

    return (
        <>
            <div className="search-component mb-4">
                <Card>
                    <Card.Body>
                        <div className="row justify-content-start">
                            <div className="col-10 d-flex justify-content start align-items-start">
                                <div className="form-component">
                                    <Forms ref={form} fieldChanged={fieldChanged} fields={forms} />
                                </div>
                            </div>

                            <div className=" pt-2 col-2 d-flex align-items-end justify-content-start">
                                <button
                                    style={{ marginTop: "0.8rem" }}
                                    className="btn btn-warning m-btn--icon m-btn--icon-only btn-shadow ml-8"
                                    onClick={handleSearch}
                                >
                                    <i className="flaticon-search-1" style={{ color: "#000" }}></i>
                                    <span style={{ color: "#000" }}>
                                        {t("common.search").toUpperCase()}
                                    </span>
                                </button>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </div>
            <Card>
                <div className="flex p-4 ">
                    <button className="btn btn-purple m-btn--icon m-btn--icon-only ml-2" onClick={() => downloadPassedStudents(1)}>
                        Тэнцсэн сурагчдыг татах
                    </button>
                    {
                        !phaseData && searchState && searchState.program &&
                        <div className="checkbox">
                            <Checkbox
                                label={'Үр дүнг харуулах эсэх'}
                                className='flex-center'
                                onChange={(checked) => onHandlerRadioChange(checked, 'credit')}
                                checked={phaseIsShow}
                            />
                        </div>
                    }
                </div>

                <Card.Header>
                    <Tabs
                        id={"msue-mv-internal"}
                        defaultActiveKey={"passed"}
                        activeKey={tabKey}
                        onSelect={onTabChange}
                        className="msue-tab"
                    >
                        <Tab title="Тэнцсэн" eventKey={"passed"} />
                        <Tab title="Тэнцээгүй" eventKey={"failed"} />
                    </Tabs>
                </Card.Header>
                {renderTab()}
                {showViewModal && (
                    <ChangePhychology
                        phase={"PSYCHOLOGY"}
                        enroll={enrollId}
                        psychologyId={psychologId}
                        id={selectedId}
                        onFinish={handleOnFinish}
                        onClose={onModalClose}
                    />
                )}
                {showHealth && (
                    <ViewHealth
                        phase={"HEALTH"}
                        enroll={enrollId}
                        id={selectedId}
                        onFinish={handleOnFinish}
                        healthCandidate={healthId}
                        onClose={onViewHealthClose}
                    />
                )}
                {showAthlete && (
                    <ChangeAthlete
                        phase={"ATHLETE"}
                        status={list.isPassed}
                        id={selectedId}
                        onFinish={handleOnFinish}
                        enroll={athEnrollId}
                        onClose={onShowAthleteClose}
                    />
                )}

                {showStatusModal && (
                    <ChangeStatus
                        onClose={onModalStatusClose}
                        id={candidateId}
                        onFinish={handleOnFinish}
                        phase={searchState.phase}
                        enroll={searchState.type}
                        program={searchState.program}
                        toStatus={toStatusName}
                    />
                )}
                {showProgramModal && (
                    <ProgramEdit
                        onClose={onModalProgramClose}
                        id={candidateId}
                        onFinish={handleOnFinish}
                        phase={searchState.phase}
                        program={searchState.program}
                        enroll={statusEnrollId}
                    />
                )}
            </Card>
            {showEmailModal && (
                <SendEmail
                    onClose={onModalEmailClose}
                    candidateList={candidateIds}
                    searchState={searchState}
                    onFinish={onEmailSubmit}
                />
            )}
            {showChangeFinalStatusModal && (
                <ChangeFinalStatus
                    onClose={onModalFinalStatusClose}
                    data={changeFinalStatusData}
                    onSaveClick={submitFinalStatusChange}
                />
            )}

            {showChangeAthleteScoreModal && (
                <ChangeAthleteScore
                    onClose={onModalChangeAthleteClose}
                    data={changeAthleteScoreData}
                    onSaveClick={submitChangeAthleteScoreChange}
                />
            )}

            {showMakeStudentModal && (
                <MakeStudent
                    onClose={onModalMakeStudentClose}
                    data={makeStudentData}
                    onSaveClick={submitMakeStudent}
                />
            )}
        </>
    );
};

export default AddEntry;
