import React, { useEffect, useState } from 'react';
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { setLoading } from "../../../redux/action";
import { fetchRequest } from "../../../utils/fetchRequest";
import message from "../../modules/message";
import Select from "../../modules/Form/Select";
import { roomSearch, timetableAddDatas } from "../../../utils/fetchRequest/Urls";

const TimetableAddModal = ({
    onClose,
    subject,
    shift,
    isSelective = false,
    onSave,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [ rows, setRows ] = useState([{
        pTime: null,
        day: null,
        time: null,
        room: null,
        max: '',
        timetableType: null,
    }]);

    const [ days, setDays ] = useState([]);
    const [ teachers, setTeachers ] = useState([]);
    const [ teacherId, setTeacherId ] = useState(null);
    const [ pTimes, setPTimes ] = useState([]);
    const [ times, setTimes ] = useState([]);
    const [ rooms, setRooms ] = useState([]);
    const [ timetableTypes, setTimetableTypes ] = useState([]);
    const [ name, setName ] = useState('');
    const [ errorName, setErrorName ] = useState(false);
    const [ errorTeacher, setErrorTeacher ] = useState(false);
    const [ errorSeat, setErrorSeat ] = useState(false);
    const [ seatCount, setSeatCount ] = useState('');
    const [ roomSearchValue, setRoomSearchValue] = useState(null);

    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    useEffect(() => {
        const params = {
            school: schoolId,
            schoolShift: shift,
            subject,
        };
        dispatch(setLoading(true));
        fetchRequest(timetableAddDatas, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;

                    if (data?.teachers?.length) {
                        setTeachers(data.teachers);
                    }

                    if (data?.lmPTimes?.length) {
                        setPTimes(data.lmPTimes.map(pTime => {
                            return {
                                ...pTime,
                                value: pTime.id,
                                text: pTime.name,
                            }
                        }))
                    }

                    if (data?.times?.length) {
                        setTimes(data.times.map(time => {
                            let stringTime = '';
                            if (time.startTime?.date) {
                                stringTime = `${time.startTime.date.substring(11, 16)}-`;
                            }
                            if (time.endTime?.date) {
                                stringTime = `${stringTime}${time.endTime.date.substring(11, 16)}`;
                            }
                            return {
                                ...time,
                                value: time.id,
                                text: stringTime + ' (' + time.name + ')',
                            }
                        }));
                    }

                    if (data?.days?.length) {
                        setDays(data.days.map(day => ({
                            ...day,
                            text: day.day,
                            value: day.id,
                        })));
                    }

                    if (data?.rooms?.length) {
                        setRooms(data.rooms.map(room => {
                            return {
                                ...room,
                                value: room.id,
                                text: room.roomNumber,
                            }
                        }));
                    }

                    if (data?.timetableTypes?.length) {
                        setTimetableTypes(data.timetableTypes.map(type => {
                            return {
                                ...type,
                                value: type.id,
                                text: type.name
                            }
                        }))
                    }

                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            })
    }, []);

    const onSaveClick = () => {
        const teacher = teachers.find(teacher => teacher.value === teacherId);
        const datas = rows.map((row, index) => {
            const day = days.find(d => d.value === row.day);
            const pTime = pTimes.find(pt => pt.value === row.pTime);
            const room = rooms.find(r => r.value === row.room);
            const time = times.find(t => t.value === row.time);
            const timetableType = timetableTypes.find(type => type.value === row.timetableType);

            return {
                ...row,
                rowId: index + 1,
                dayName: day?.text || '-',
                pTimeName: pTime?.text || '-',
                roomName: room?.text || '-',
                timeName: time?.text || '-',
                timetableTypeName: timetableType?.text || '-',
                timetableTypeCode: timetableType?.code || '-',
                teacher: teacher?.value || '-',
                teacherName: teacher?.text || '-',
                capacity: room?.capacity || 0,
                name,
                seatCount: isSelective ? row.max : seatCount,
                // teacher,
            }
        });

        for(let i = 0; i < datas.length; i++){
            let existingData = datas.find(dt => datas[i].rowId != dt.rowId && datas[i].day == dt.day && datas[i].time == dt.time && datas[i].room == dt.room && datas[i].timetableType == dt.timetableType)

            if(existingData){
                return message('Мэдээлэл давхардаж байна, Давхардаж байгаа мэдээлэл (' + existingData.dayName + ' ' + existingData.timeName + ' ' + existingData.roomName + ' ' + existingData.timetableTypeName);
            }
        }

        if(isSelective){
            onSave(datas);
        } else {
            if(!name){
                setErrorName(true);
                message(t('errorMessage.enterGroup'));
                return;
            } else {
                setErrorName(false);
            }
            if (!teacher){
                setErrorTeacher(true);
                message(t('timetable.selectTeacher'));
            } else {
                setErrorTeacher(false);
            }
            if(!seatCount){
                setErrorSeat(true);
                message(t('errorMessage.enterSeat'));
                return;
            } else {
                setErrorSeat(false);
            }

            if(name && teacher && seatCount){
                onSave(datas);
            }
        }
    };

    const removeRow = index => {
        const clone = [...rows];
        clone.splice(index, 1);
        setRows(clone);
    };

    const addRow = () => {
        setRows([...rows, {
            pTime: rows && rows.length > 0 ? rows[rows.length - 1].pTime : null,
            day: rows && rows.length > 0 ? rows[rows.length - 1].day : null,
            time: rows && rows.length > 0 ? rows[rows.length - 1].time : null,
            room: rows && rows.length > 0 ? rows[rows.length - 1].room : null,
            max: '',
            timetableType: rows && rows.length > 0 ? rows[rows.length - 1].timetableType : null,
        }]);
    };

    const handleSelectChange = (key, value, index) => {
        const clone = [...rows];
        const row = { ...clone[ index ] };
        row[key] = value;
        clone[index] = row;
        setRows(clone);
    };

    const handleTeacherChange = val => {
        setTeacherId(val);
    };

    useEffect(() => {
        let timeout;
        if (roomSearchValue) {
            timeout = setTimeout(() => {
                const params = {
                    value: roomSearchValue,
                };
                dispatch(setLoading(true));
                fetchRequest(roomSearch, 'GET', params)
                    .then(res => {
                        if (res.success) {
                            let roomList = res.data.rooms;
                            let cloneRooms = [...rooms];

                            for(let i = 0; i < roomList.length; i++){
                                if(!cloneRooms.find(room => room.id == roomList[i].id)){
                                    cloneRooms.push({
                                        value: roomList[i].id,
                                        text: roomList[i].roomNumber + ' (' + roomList[i].shortName + ')',
                                    })
                                }
                            }
                            setRooms(cloneRooms);
                        } else {
                            message(res?.data?.message || t('errorMessage.title'))
                        }
                        dispatch(setLoading(false));
                    })
                    .catch(() => {
                        dispatch(setLoading(false));
                        message(t('errorMessage.title'))
                    })
            }, 1000);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [roomSearchValue]);

    const onRoomInputChange = (inputValue, option) => {
        if(inputValue){
            setRoomSearchValue(inputValue);
        }
    };

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName='modal-90w'
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('timetable.add').toUpperCase()}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    !isSelective && (
                        <div className='row' style={{ display: 'flex', alignItems: 'center' }}>
                            <div className='col-5 text-right'>
                                {t('group.title')}
                            </div>
                            <div className='col-3 validated'>
                                <input
                                    className={errorName ? 'form-control is-invalid' : 'form-control'}
                                    value={name}
                                    onChange={e => {
                                        setName(e.target.value)
                                    }}
                                />
                                {
                                    errorName
                                    ?
                                        <div className={'invalid-feedback'}>
                                            {t('errorMessage.enterGroup')}
                                        </div>
                                    : null
                                }
                            </div>
                        </div>
                    )
                }
                <div className='row mt-3' style={{ display: 'flex', alignItems: 'center' }}>
                    <div className='col-5 text-right'>
                        {t('teacher.title')}
                    </div>
                    <div className='col-3 validated'>
                        <Select
                            className={errorTeacher ? 'dropdown is-invalid' : 'dropdown'}
                            searchable
                            options={teachers}
                            value={teacherId}
                            onChange={handleTeacherChange}
                        />
                        {
                            errorTeacher
                            ?
                                <div className={'invalid-feedback'}>
                                    {t('timetable.selectTeacher')}
                                </div>
                            : null
                        }
                    </div>
                </div>
                {
                    !isSelective && (
                        <div className='row mt-3' style={{ display: 'flex', alignItems: 'center' }}>
                            <div className='col-5 text-right'>
                                {t('timetable.seatCount')}
                            </div>
                            <div className='col-3 validated'>
                                <input
                                    className={errorSeat ? 'form-control is-invalid' : 'form-control'}
                                    value={seatCount}
                                    onChange={e => {
                                        setSeatCount(e.target.value)
                                    }}
                                />
                                {
                                    errorSeat
                                        ?
                                        <div className={'invalid-feedback'}>
                                            {t('errorMessage.enterSeat')}
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                    )
                }
                <table className='timetable-add-ptimes'>
                    <thead>
                        <tr>
                            <th>{t('subject.type')}</th>
                            <th>{t('common.weekday')}</th>
                            <th style={{width: '220px'}}>{t('subject.time')}</th>
                            <th>{t('school.classroom.title')}</th>
                            {
                                isSelective && (
                                    <th style={{width: '100px'}}>{t('timetable.seatCount')}</th>
                                )
                            }
                            <th>{t('subject.studyType')}</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        rows.map((row, index) => {
                            return (
                                <tr key={index}>
                                    <td className='pr-2'>
                                        <Select
                                            options={pTimes}
                                            searchable
                                            onChange={val => handleSelectChange('pTime', val, index)}
                                            value={row.pTime}
                                        />
                                    </td>
                                    <td className='pr-2'>
                                        <Select
                                            options={days}
                                            searchable
                                            onChange={val => handleSelectChange('day', val, index)}
                                            value={row.day}
                                        />
                                    </td>
                                    <td className='pr-2'>
                                        <Select
                                            options={times}
                                            searchable
                                            onChange={val => handleSelectChange('time', val, index)}
                                            value={row.time}
                                        />
                                    </td>
                                    <td className='pr-2'>
                                        <Select
                                            searchable
                                            options={rooms}
                                            onChange={val => handleSelectChange('room', val, index)}
                                            onInputChange={onRoomInputChange}
                                            value={row.room}
                                        />
                                    </td>
                                    {
                                        isSelective && (
                                            <td className='pr-2'>
                                                <input
                                                    style={{border: '1px solid hsl(0deg 0% 80%)'}}
                                                    className='form-control'
                                                    value={row.max}
                                                    onChange={(e) => handleSelectChange('max', e.target.value, index)}
                                                />
                                            </td>
                                        )
                                    }
                                    <td className='pr-2'>
                                        <Select
                                            searchable
                                            options={timetableTypes}
                                            onChange={val => handleSelectChange('timetableType', val, index)}
                                            value={row.timetableType}
                                        />
                                    </td>
                                    <td style={{ width: 40 }}>
                                        {
                                            rows.length > 1 && (
                                                <button className='btn btn-danger row-action-button ml-3' onClick={() => removeRow(index)}>
                                                    <i className='las la-times' style={{ fontSize: 20 }} />
                                                </button>
                                            )
                                        }
                                    </td>
                                </tr>
                            )
                        })
                    }
                    <tr>
                        <td colSpan={6} className='text-right'>
                            <button className='btn btn-primary row-action-button ml-3' onClick={addRow}>
                                <i className='las la-plus' style={{ fontSize: 20 }} />
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className='btn btn-link bolder'>{t('common.back')}</button>
                <Button variant='success btn-shadow' onClick={onSaveClick}>{t('common.save')}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default TimetableAddModal;