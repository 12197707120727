/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import clsx from "clsx";
import { Dropdown } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import i18n from "i18next";

const getLanguage = () => i18n.language || window.localStorage.i18nextLng

const languages = [
    {
        lang: "mn",
        name: "Монгол",
        flag: toAbsoluteUrl("/media/svg/flags/258-mongolia.svg"),
    },
    {
        lang: "en",
        name: "English",
        flag: toAbsoluteUrl("/media/svg/flags/226-united-states.svg"),
    },
    {
        lang: "cn",
        name: "中文",
        flag: toAbsoluteUrl("/media/svg/flags/015-china.svg"),
    }
];

export function LanguageSelectorDropdown() {
    const current = i18n.language || 'mn';

    const currentLanguageObj = languages.find(lang => lang.lang === current);

    const handleLanguageChange = lang => {
        i18n.changeLanguage(lang);
    }

    return (
        <Dropdown drop="down" alignRight>
            <Dropdown.Toggle
                as={DropdownTopbarItemToggler}
                id="dropdown-toggle-my-cart"
            >
                <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id="language-panel-tooltip">Select Language</Tooltip>}
                >
                    <div className="btn btn-icon btn-clean btn-dropdown btn-lg mr-1">
                        <img
                            className="h-25px w-25px rounded"
                            src={currentLanguageObj?.flag}
                            alt={currentLanguageObj?.name}
                        />
                    </div>
                </OverlayTrigger>
            </Dropdown.Toggle>
            <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround">
                <ul className="navi navi-hover py-4">
                    {languages.map((language) => (
                        <li
                            key={language.lang}
                            className={clsx("navi-item", {
                                active: language.lang === current
                            })}
                        >
                            <a
                                href="#"
                                onClick={() => handleLanguageChange(language.lang)}
                                className="navi-link"
                            >
                                <span className="symbol symbol-20 mr-3">
                                  <img src={language.flag} alt={language.name} />
                                </span>
                                <span className="navi-text">{language.name}</span>
                            </a>
                        </li>
                    ))}
                </ul>
            </Dropdown.Menu>
        </Dropdown>
    );
}
