import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import message from "../../../modules/message";
import { sendMessage } from "../../../../utils/fetchRequest/Urls";
import { TextareaAutosize } from "@material-ui/core";

const SendMessage = ({ onClose, candidateList, params, onFinish }) => {
  console.log('>>>', candidateList)
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const defaultRemain = 160;
  const enrollCount = candidateList.length;
  const languages = useSelector(
    (state) => state?.languages?.languages || []
  ).filter((lang) => lang.code !== "mn");

  // const languages = useSelector((state) => state?.languages?.languages?.filter((lang) => lang.code !== "mn") || []);

  const [inputText, setInputText] = useState("");

  const inputHandler = (e) => {
    setInputText(e.target.value);
  };

  const translationFields = languages.map((lang) => {
    return {
      label: `${t("common.name")} - ${lang.name} (${lang.code})`,
      value: "",
      langCode: lang.code,
      type: "text",
      inputWidth: 300,
      labelWidth: 230,
    };
  });

  const onSaveClick = (currentPage = 1) => {
    const content = inputText;
    const phase = params?.stage;

    const messageParams = candidateList.map((candidate) => ({
      candidateProgramId: candidate?.candidateProgramId
    }));

    const paramsToSend = {
      candidateList: messageParams,
      content,
      phase,
      page: currentPage
    };

    dispatch(setLoading(true));
    fetchRequest(sendMessage, "POST", paramsToSend, false)
      .then((res) => {
        if (res.success) {
          const { totalPage = null, page = null } = res?.data;

          if (totalPage > page) {
            onSaveClick(page + 1)
          } else {
            message(res.data.message, true);

            onClose();
            onFinish();
          }
        } else {
          message(res.data.message, false);
        }
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  return (
    <Modal
      show={true}
      onHide={onClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div style={{ color: "#4a70ae", fontSize: "1.1rem" }}>
            МЕССЕЖ ИЛГЭЭХ
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {enrollCount ? (
          <div className="d-flex flex-column justify-content-center">
            <div style={{ marginLeft: "274px" }}>
              {enrollCount} бүртгүүлэгчид
            </div>

            <div className="mt-4 d-flex justify-content-center ">
              <label className="ml-20">Агуулга</label>
              <TextareaAutosize
                className="msue-textarea-description ml-5"
                maxLength={defaultRemain}
                label="Агуулга"
                style={{ height: "119px", width: "333px" }}
                onChange={inputHandler}
              />
            </div>

            <div style={{ marginLeft: "274px" }}>
              {defaultRemain - inputText.length} тэмдэгт үлдсэн
            </div>
          </div>
        ) : (
          <div className="text-center">Бүртгүүлэгч байхгүй байна</div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button onClick={onClose} className="btn btn-link bolder">
          {t("common.back")}
        </button>
        {enrollCount ? (
          <Button variant="success btn-shadow" onClick={() => onSaveClick(1)}>
            {t("common.send")}
          </Button>
        ) : (
          <></>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default SendMessage;
