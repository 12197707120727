import React, { useState, useEffect } from 'react';
import TreeView from '../../../../app/modules/TreeView/index';
import { Card, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import { departmentDelete, departmentInit } from "../../../../utils/fetchRequest/Urls";
import { useHistory } from 'react-router-dom';
import { getFlatTreeData } from "../../../../utils/utils";
import DeleteModal from "../../../modules/DeleteModal";
import message from "../../../modules/message";

const Department = ({}) => {
    const [ schools, setSchools ] = useState([]);
    const [ departments, setDepartments ] = useState([]);
    const [ schoolId, setSchoolId ] = useState(null);
    const [ departmentId, setDepartmentId ] = useState(null);
    const [ deleteId, setDeleteId ] = useState(null);
    const [ treeIndex ] = useState('structure_department_index');
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        const selectedTreeId = localStorage.getItem(treeIndex);

        const params = {};

        if(selectedTreeId){
            setSchoolId(parseInt(selectedTreeId));
            params.school = selectedTreeId;
        }

        dispatch(setLoading(true));
        fetchRequest(departmentInit, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    if (data?.schools?.length) {
                        setSchools(data.schools);
                    }
                    if (data?.departments?.length) {
                        setDepartments(data.departments);
                    }
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
            })
    }, []);

    const handleChange = (e, data) => {
        // setSelectedTreeId(e)
    };

    const _treeContextMenuClick = (id, key) => {
        if (id && key) {
            if (key === 'EDIT') {
                history.push({
                    pathname: '/structure/department_new',
                    state: {
                        departmentId: id,
                        schoolId,
                    }
                })
            }
            if (key === 'DELETE') {
                if(departments && departments.length > 0){
                    if(departments[0].key == id){
                        message(t('department.canNotDelete'));
                    } else {
                        setDeleteId(id);
                    }
                }
            }
        }
    };

    const handleSchoolClick = array => {
        if (array?.length) {
            setDepartmentId(null);
            const [ id ] = array;
            setSchoolId(id);
            const params = {
                school: id,
            };
            dispatch(setLoading(true));
            fetchRequest(departmentInit, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res;
                        setDepartments(data.departments)
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                });

            localStorage.setItem(treeIndex, array[0]);
        }
    };

    const handleDepartmentClick = array => {
        if (array?.length) {
            const [ id ] = array;
            setDepartmentId(id);
        }
    };

    const totalSchoolCount = getFlatTreeData(schools).length;

    const onDeleteModalClose = () => {
        setDeleteId(null);
    };

    const onDelete = () => {
        const params = {
            school: schoolId,
            department: deleteId
        };
        dispatch(setLoading(true));
        fetchRequest(departmentDelete, 'POST', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setDepartments(data.departments || []);
                    message(data?.message || t('common.success'), true);
                    onDeleteModalClose();
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            })
    };

    return (
        <div className='row'>
            <div className='col-4 pr-0'>
                <Card>
                    <Card.Body>
                        <div className='col-12 p-0'>
                            <p className='mb-0'><b>{t('common.total')}: {totalSchoolCount}</b></p>
                        </div>
                        <TreeView
                            className='mt-5'
                            treeData={schools}
                            selectedNodes={[schoolId]}
                            onSelect={handleSchoolClick}
                            defaultExpandAll={true}
                            // contextMenus={{
                            //     hasNoInvoice: [
                            //         {
                            //             key: 'EDIT',
                            //             iconClassName: 'flaticon-delete-1',
                            //             text: 'edit',
                            //         },
                            //         {
                            //             key: 'DELETE',
                            //             iconClassName: 'flaticon-delete-1',
                            //             text: 'delete',
                            //         }
                            //     ]
                            // }}
                            // contextMenuKey={'hasNoInvoice'}
                            // onContextMenuClick={_treeContextMenuClick}
                        />

                    </Card.Body>
                </Card>
            </div>
            <div className='col-8'>
                <Card>
                    <Card.Body>
                        <div className='col-12  p-0'>
                            {/*<ButtonToolbar className='d-inline'>*/}
                            <button
                                disabled={!schoolId}
                                className='btn btn-primary btn-shadow'
                                onClick={() => {
                                    history.push({
                                        pathname: '/structure/department_new',
                                        state: {
                                            schoolId,
                                        }
                                    });
                                }}
                            >
                                {t('common.add')}
                            </button>
                                {/*<Link*/}
                                {/*    to='/structure/department_new'*/}
                                {/*    className="btn btn-primary btn-shadow"*/}
                                {/*>*/}
                                {/*</Link>*/}
                            {/*</ButtonToolbar>*/}
                            <Button className='bg-white border-white d-inline btn-hover-less'
                                    /*onClick={handleHelperModal}*/>
                                <i className="icon-2x text-danger flaticon-questions-circular-button d-inline"/>
                                <p className='text-danger d-inline'><b>{t('common.helper')}</b></p>
                            </Button>
                        </div>
                        <TreeView
                            className='mt-5'
                            treeData={departments}
                            selectedNodes={[departmentId]}
                            onSelect={handleDepartmentClick}
                            defaultExpandAll={true}
                            contextMenus={{
                                department: [
                                    {
                                        key: 'EDIT',
                                        // iconClassName: 'flaticon-delete-1',
                                        iconClassName: 'las la-edit rc-tree-la-edit',
                                        text: t('common.edit'),
                                    },
                                    {
                                        key: 'DELETE',
                                        iconClassName: 'flaticon2-trash ml3px',
                                        text: t('common.delete'),
                                    }
                                ]
                            }}
                            contextMenuKey={'department'}
                            onContextMenuClick={_treeContextMenuClick}
                        />
                    </Card.Body>
                </Card>
            </div>
            {
                deleteId && (
                    <DeleteModal
                        onClose={onDeleteModalClose}
                        onDelete={onDelete}
                        title={t('menu.department')}
                    >
                        {t('warning.delete_confirmation')}
                        <br/>
                        <br/>
                        {t('warning.delete_confirmation_description')}
                    </DeleteModal>
                )
            }
        </div>
    )
};

export default Department;