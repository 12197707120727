import DTable from 'app/modules/DataTable/DTable';
import React, { useEffect, useMemo, useState } from 'react'
import { Button, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRequest } from 'utils/fetchRequest';
import { graduationConfig, graduationConfigDelete } from 'utils/fetchRequest/Urls';
import { setLoading } from 'redux/action';
import message from 'app/modules/message';
import AddModal from './modals/AddModal'
import ViewModal from './modals/ViewModal'
import 'app/css/calendarPopover.css';

const Config = () => {
    const { t } = useTranslation()
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const dispatch = useDispatch();

    const [totalCount, setTotalCount] = useState(0)
    const [list, setList] = useState([])
    const [addModal, setAddModal] = useState(false)
    const [viewModal, setViewModal] = useState(false)
    const [viewId, setViewId] = useState(null)

    const tableConfig = useMemo(() => {
        return {
            showPagination: true,
            excelExport: false,
        }
    }, [])

    const contextMenus = useMemo(() => [
        {
            key: "view",
            title: t("common.view"),
            icon: <i className="las la-search dt-cm-item-icon" />,
        },
        {
            key: "delete",
            title: t("common.delete"),
            icon: <i className="las la-trash-alt dt-cm-item-icon" />,
        },
    ], [])

    const columns = useMemo(() => [
        {
            dataField: "seasonName",
            text: t('subject.season'),
            sort: false,
        },
        {
            dataField: "educationLevelName",
            text: t('curriculum.educationLevel'),
            sort: false,
        },
        {
            dataField: "decisionDate",
            text: t('graduation.config.decisionDate'),
            sort: false,
        },
        {
            dataField: "orderDate",
            text: t('graduation.config.orderDate'),
            sort: false,
        }
    ], [])

    const deleteConfig = (id) => {
        dispatch(setLoading(true));
        const params = {
            school: schoolId,
            id
        }
        fetchRequest(graduationConfigDelete, 'POST', params)
            .then(res => {
                if (res?.success) {
                    setTotalCount(res?.data?.totalCount)
                    setList(res?.data?.configs)
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(e => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            });
    }

    const onContextMenuItemClick = (id, key) => {
        if (key === "delete") {
            deleteConfig(id)
        } else if (key === 'view') {
            setViewId(id)
            setViewModal(true)
        }
    }

    const init = (page = 1, pageSize = 10, search = null, sort = null, order = null) => {
        dispatch(setLoading(true));
        const params = {
            school: schoolId,
            page,
            pageSize,
            search,
            sort,
            order
        }
        fetchRequest(graduationConfig, 'GET', params)
            .then(res => {
                if (res?.success) {
                    setTotalCount(res?.data?.totalCount)
                    setList(res?.data?.configs)
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(e => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            });
    }

    useEffect(() => {
        init()
    }, [])

    const onInteraction = (params) => {
        init(params?.page, params?.pageSize, params?.search, params?.sort, params?.order)
    }

    return (
        <Card>
            <Card.Body>
                <Button variant="primary btn-shadow align-self-end" onClick={() => setAddModal(true)}>
                    {t('graduation.config.create')}
                </Button>
                <DTable
                    remote
                    totalDataSize={totalCount}
                    data={list}
                    config={tableConfig}
                    contextMenus={contextMenus}
                    onContextMenuItemClick={onContextMenuItemClick}
                    onInteraction={onInteraction}
                    columns={columns}
                />
            </Card.Body>
            {
                addModal && <AddModal open={addModal} onClose={() => setAddModal(false)} />
            }
            {
                viewModal && <ViewModal open={viewModal} onClose={() => {
                    setViewId(null)
                    setViewModal(false)
                }} id={viewId} />
            }
        </Card>
    )
}

export default Config
