import React, { useState, useEffect } from 'react';
import TreeView from '../../../../app/modules/TreeView/index';
import {Card, Button, ButtonToolbar, Modal} from "react-bootstrap";
import {generateData} from "../../school/teacher";
import { useTranslation } from "react-i18next";
import {Link} from "react-router-dom";
import {fetchRequestAdmin} from "../../../../utils/fetchRequest";

const SchoolAbout = ({

    }) => {
    const [ selectedTreeId, setSelectedTreeId ] = useState();
    const [ schools, setSchools ] = useState([]);
    const [ helperModal, setHelperModal ] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        // fetchRequestAdmin('school/init', 'GET', [])
        //     .then(async response => {
        //         if (response.success) {
        //             setSchools(response.data.schools)
        //         } else {
        //
        //         }
        //     });
    }, []);

    const handleChange = (e, data) => {
        setSelectedTreeId(e)
    };

    const _treeContextMenuClick = (id, key) => {
        if (id, key) {
            // if (key === 'EDIT') {
            //     const params = {
            //         id,
            //     }
            //
            //     this.setState({
            //         fetchInvoiceTypeEdit: true,
            //         tempId: id,
            //         showLoader: true,
            //     })
            // }
            // if (key === 'DELETE') {
            //     this.setState({
            //         tempId: id,
            //         showDeleteInvoiceTypeModal: true,
            //     })
            // }
        }
    };

    const addHandler = () => {
        // <Redirect from="/structure/component" to="/school/teacher"/>
    };

    const handleHelperModal = () => {
        setHelperModal(true)
    };

    const handleHelperModalClose = () => {
        setHelperModal(false)
    };

    return (
        <div className='row'>
            <p>ABOUT</p>
            {/*<div className='col-12'>*/}
            {/*    <Card>*/}
            {/*        <Card.Body>*/}
            {/*            <div className='col-12 p-0'>*/}
            {/*                <ButtonToolbar className='d-inline'>*/}
            {/*                    <Link*/}
            {/*                        to='/structure/component_new'*/}
            {/*                        className="btn btn-primary btn-shadow"*/}
            {/*                    >*/}
            {/*                        {t('add')}*/}
            {/*                    </Link>*/}
            {/*                    /!*<Button variant="primary" onClick={addHandler}>{t('add')}</Button>*!/*/}
            {/*                </ButtonToolbar>*/}
            {/*                <Button className='bg-white border-white d-inline btn-hover-less' onClick={handleHelperModal}>*/}
            {/*                    <i className="icon-2x text-danger flaticon-questions-circular-button d-inline"></i>*/}
            {/*                    <p className='text-danger d-inline'><b>{t('helper')}</b></p>*/}
            {/*                </Button>*/}
            {/*            </div>*/}
            {/*            <div className='col-12 p-0'>*/}
            {/*                <p className='mb-0 mt-3'><b>{t('total')}: {schools.length}</b></p>*/}
            {/*            </div>*/}
            {/*            <TreeView*/}
            {/*                treeData={schools}*/}
            {/*                selectedNodes={selectedTreeId}*/}
            {/*                onSelect={(e, data) => handleChange(e, data)}*/}
            {/*                defaultExpandAll={true}*/}
            {/*                contextMenus={{*/}
            {/*                    hasNoInvoice: [*/}
            {/*                        {*/}
            {/*                            key: 'EDIT',*/}
            {/*                            iconClassName: 'flaticon-delete-1',*/}
            {/*                            text: 'edit',*/}
            {/*                        },*/}
            {/*                        {*/}
            {/*                            key: 'DELETE',*/}
            {/*                            iconClassName: 'flaticon-delete-1',*/}
            {/*                            text: 'delete 123',*/}
            {/*                        }*/}
            {/*                    ]*/}
            {/*                }}*/}
            {/*                contextMenuKey={'hasNoInvoice'}*/}
            {/*                onContextMenuClick={_treeContextMenuClick}*/}
            {/*            />*/}

            {/*        </Card.Body>*/}
            {/*    </Card>*/}
            {/*</div>*/}
            <Modal
                show={helperModal}
                onHide={handleHelperModalClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Modal heading
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Centered Modal</h4>
                    <p>
                        Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
                        dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta
                        ac consectetur ac, vestibulum at eros.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleHelperModalClose}>{t('common.close')}</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
};

export default SchoolAbout;