import React, {useEffect, useState} from 'react';
import {Card} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import DatePicker from "../../modules/Form/DatePicker";
import {Link, useHistory} from "react-router-dom";
import DeleteModal from "../../modules/DeleteModal";
import {useDispatch, useSelector} from "react-redux";
import {setLoading} from "../../../redux/action";
import {fetchRequest} from "../../../utils/fetchRequest";
import {eventDelete, eventInit, eventDrag} from "../../../utils/fetchRequest/Urls";
import message from "../../modules/message";
import FullCalendar from '@fullcalendar/react';
import listPlugin from '@fullcalendar/list';
import momentPlugin from '@fullcalendar/moment';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import ViewModal from './modal/view';

export const MainCalendar = ({
    mainEvents,
    selectedMonth
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [month, setMonth] = useState(null);
    const [showModal, setShowModal] = useState();
    const [events, setEvents] = useState();
    const [eventId, setEventId] = useState();
    const [isMount, setIsMount] = useState(true);
    const [isEvent, setIsEvent] = useState(true);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [showViewModal, setShowViewModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [ mainMonthIndex ] = useState('main_month_index');

    useEffect(() => {
        fetchInit()
    }, []);

    const fetchInit = (date) => {
        localStorage.setItem(mainMonthIndex, date);
        let params = {
            school: schoolId,
        };

        dispatch(setLoading(true));
        fetchRequest(eventInit, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setEvents(data?.events || []);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const handlerDeleteClick = (id) => {
        setShowModal(true);
        setEventId(selectedEvent.id);
    };

    const onModalClose = () => {
        setShowModal(false);
        setEventId(null);
    };

    const onDelete = () => {
        const bodyParams = {
            school: schoolId,
            event: eventId
        };
        dispatch(setLoading(true));
        fetchRequest(eventDelete, 'POST', bodyParams)
            .then(res => {
                dispatch(setLoading(false));
                if (res.success) {
                    message(res?.data?.message || t('common.success'), true);
                    setEvents(res?.data?.events || []);
                    onModalClose();
                    setSelectedEvent(null);
                    setShowViewModal(false);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            })
    };

    // const renderEvents = () =>{
    //     return events.map((data, index) =>
    //         <div
    //             key={'other_event_' + index}
    //         >
    //             <div className='row event-style' key={data.id}>
    //                 <div className='col-3'>
    //                     <span className='date ml-3' style={{color: '#29569f'}}><b>{data.startDate + ' - ' + data.endDate}</b></span>
    //                 </div>
    //                 <div className='col-9 ds-flex justify-content-between'>
    //                     <div>
    //                         <span className='title'><b>{data.name}</b></span>
    //                     </div>
    //                     <div>
    //                         <Link
    //                             to={{
    //                                 pathname: '/event/edit',
    //                                 state: {
    //                                     eventId: data.id
    //                                 }
    //                             }}
    //                         >
    //                             <button className='btn btn-purple m-btn--icon m-btn--icon-only ml-2'>
    //                                 <i className='flaticon-edit fs-10'/>
    //                             </button>
    //                         </Link>
    //                         <button
    //                             className='btn btn-danger m-btn--icon m-btn--icon-only ml-2'
    //                             onClick={() => {handlerDeleteClick(data.id)}}
    //                         >
    //                             <i className='flaticon2-cross fs-06'/>
    //                         </button>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div className='row'>
    //                 <div className='col-3'/>
    //                 <div className='col-9'>
    //                     {data.description}
    //                 </div>
    //             </div>
    //             <div className="separator separator-dashed my-7" />
    //         </div>
    //     )
    // };

    const handleEventDrop = params => {
        setLoading(true)
        fetchRequest(eventDrag, 'POST', { school: schoolId, id: params?.event?.id, start: params?.event?.start?.toISOString(), end: params?.event?.end?.toISOString() })
            .then((res) => {
                if (res.success) {
                    const { events } = res.data
                    setEvents(events || [])
                    message(res.data.message, res.success)
                } else {
                    message(res.data.message)
                }
                setLoading(false)
            })
            .catch(() => {
                message(t('errorMessage.title'))
                setLoading(false)
            })
    }

    const handlerEventEdit = () => {
        history.push("/event/edit", {
            eventId: selectedEvent.id
        });
    }

    const handleEventClick = params => {
        setSelectedEvent(events?.find(el => el?.id == params?.event?.id));
        setShowViewModal(true);
    }

    const closeModal = () => {
        setSelectedEvent(null);
        setShowViewModal(false);
        setShowDeleteModal(false);
    }

    return (
        <Card>
            <Card.Header>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <span className='french-blue fs-10'>
                        <b>{t('newsfeed.schoolCalendar').toUpperCase()}</b>
                    </span>
                </div>
            </Card.Header>
            <Card.Body>
                <FullCalendar
                    firstDay={1}
                    events={events}
                    editable={true}
                    titleFormat='YYYY-MM'
                    eventDrop={handleEventDrop}
                    initialView='dayGridMonth'
                    eventClick={handleEventClick}
                    timeZone='Asia/Ulaanbaatar'
                    headerToolbar={{
                        start: 'prev,next today',
                        center: 'title',
                        end: 'dayGridMonth listMonth',
                    }}
                    buttonText={{
                        day: t('calendar.day'),
                        week: t('calendar.week'),
                        list: t('calendar.list'),
                        month: t('calendar.month'),
                        today: t('calendar.today'),
                    }}
                    allDayText={t('calendar.all_day')}
                    noEventsClassNames='text-center pt-5'
                    noEventsContent={t('calendar.no_event')}
                    plugins={[dayGridPlugin, listPlugin, interactionPlugin, momentPlugin]}
                    views={{
                        dayGridMonth: {
                            eventTimeFormat: { hour: '2-digit', minute: '2-digit', meridiem: false, hour12: false, },
                        },
                        listMonth: {
                            listDaySideFormat: true,
                            eventTimeFormat: { hour: '2-digit', minute: '2-digit', meridiem: false, hour12: false, },
                        }
                    }}
                />
                {/* <div style={{ display: 'flex'}}>
                    <label
                        style={{
                            display: 'flex',
                            flex: 0.7,
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            marginRight : 10,
                            marginBottom: 0,
                            width: 'auto',
                        }}
                    >
                        {t('common.month')}
                    </label>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginLeft: 10,
                            width: 180,
                        }}
                    >
                        <DatePicker
                            value={month}
                            onChange={handleDateChange}
                            showMonthYearPicker
                            showFullMonthYearPicker
                            isCustomButton={false}
                            dateFormat={'yyyy-MM'}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'column',
                            marginLeft: 10,
                            width: 'auto',
                        }}
                    />
                </div> */}
                {/* {
                    events && events.length > 0 &&
                    <>
                        <div className="separator separator-dashed my-7" />
                        {renderEvents()}
                    </>
                } */}
            </Card.Body>
            {
                showModal && eventId && (
                    <DeleteModal
                        onClose={onModalClose}
                        onDelete={onDelete}
                        title={t('warning.delete')}>
                        {t('warning.delete_confirmation')}
                        <br />
                        <br />
                        {t('warning.delete_confirmation_description')}
                    </DeleteModal>
                )
            }
            {
                showViewModal && selectedEvent &&
                <ViewModal
                    event={selectedEvent}
                    onClose={closeModal}
                    onDelete={handlerDeleteClick}
                    onEdit={handlerEventEdit}
                />
            }
        </Card>
    )
};
