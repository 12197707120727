import React, {useEffect, useRef, useState} from 'react'
import DTable from '../../../modules/DataTable/DTable'
import {Button, Card, Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import Select from "../../../modules/Form/Select";
import {setLoading} from "../../../../redux/action";
import {fetchRequest} from "../../../../utils/fetchRequest";
import {attendanceSave, getJournalDetail, journalResultSave, journalResultUnpublish, timetableInit} from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import {cloneDeep} from "lodash";
import Forms from "../../../modules/Form/Forms";
import {getDateByDateObject} from "../../../../utils/utils";

const Score = ({
    timetableId
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const formRef = useRef();
    const [addModal, setAddModal] = useState(false);
    const [publishModal, setPublishModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isExam, setIsExam] = useState(null);
    const [isPublish, setIsPublish] = useState(false);
    const [publishedDate, setPublishedDate] = useState('');
    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState([]);
    const [oldStudents, setOldStudents] = useState([]);
    const [students, setStudents] = useState([]);

    useEffect(() => {
        let params = {
            school: schoolId,
            timetable: timetableId,
            tab: 'RESULT'
        };
        init(params)
    }, []);

    const init = (params) => {
        dispatch(setLoading(true));
        fetchRequest(getJournalDetail, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    let templates = data.stTemplates;
                    setIsExam(data.exam);
                    if(templates && templates.length > 0){
                        setTemplates(templates.map((template) => ({
                            value: template.id,
                            text: template.name,
                            totalScore: template.totalScore,
                            details: template.details,
                            scoreTypes: template.scoreTypes
                        })));

                        if(data.examTemplate && data.exam){
                            for(let i = 0; i < templates.length; i++){
                                if(data.examTemplate == templates[i].id){
                                    setSelectedTemplate([{
                                        value: templates[i].id,
                                        text: templates[i].name,
                                        totalScore: templates[i].totalScore,
                                        details: templates[i].details,
                                    }]);
                                }
                            }
                            let studentsData = [];
                            if(data.students && data.students.length > 0){
                                for(let i = 0; i < data.students.length; i++){
                                    let detailsKey = data.students[i].details ? Object.keys(data.students[i].details) : [];
                                    const selectedDetails = cloneDeep(data.stTemplateDtls);
                                    const scoreTypes = cloneDeep(data.scoreTypes);

                                    if(detailsKey && detailsKey.length > 0){
                                        for(let k = 0; k < detailsKey.length; k++){
                                            for(let t = 0; t < selectedDetails.length; t++){
                                                if(detailsKey[k] == selectedDetails[t].id){
                                                    selectedDetails[t].value = data.students[i].details[detailsKey[k]];
                                                }
                                            }
                                        }
                                    }

                                    studentsData.push({
                                        ...data.students[i],
                                        dtls: selectedDetails,
                                        scoreTypes
                                    });
                                }
                            }

                            setIsPublish(data.publish);
                            setPublishedDate(data.publishDate);
                            setStudents(studentsData);
                            setOldStudents(cloneDeep(studentsData));
                        } else {
                            setStudents(data.students);
                            setOldStudents(cloneDeep(data.students));
                        }
                    } else {
                        setTemplates([]);
                    }
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch((e) => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    useEffect(() => {
        formRef?.current?.updateFields && formRef.current?.updateFields(fields);
    }, [templates]);

    const fields = [
        {
            key: 'template',
            label: t('assessment.stTemplate'),
            value: null,
            type: 'dropdown',
            options: templates,
            clearable: true,
            required: true,
            errorMessage: t('errorMessage.selectScoreTemplate'),
        },
    ];

    const handlerInputChange = (e, studentId, dtlId) => {
        let cloneStudents = [...students];
        if(cloneStudents && cloneStudents.length > 0){
            for(let i = 0; i < cloneStudents.length; i++){
                if(studentId == cloneStudents[i].id){
                    if(cloneStudents[i].dtls && cloneStudents[i].dtls.length > 0){
                        let details = cloneStudents[i].dtls;

                        for(let d = 0; d < details.length; d++){
                            if(dtlId == details[d].id){
                                if(!e.target.value){
                                    details[d].value = 0;
                                } else {
                                    if(parseInt(e.target.value) <= parseInt(details[d].maxScore)){
                                        details[d].value = e.target.value;
                                        details[d].error = false;
                                    } else {
                                        message('Үнэлгээний бүтцийн дээд авах оноо хэтэрч байна');
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        setStudents(cloneStudents);
    };

    const handlerScoreType = (scoreTypeId, studentId, scoreTypes) => {
        let cloneStudents = [...students];
        if(cloneStudents && cloneStudents.length > 0){
            let selectedScoreType = null;
            if(scoreTypes && scoreTypes.length > 0){
                for(let s = 0; s < scoreTypes.length; s++){
                    if(scoreTypeId == scoreTypes[s].id){
                        selectedScoreType = scoreTypes[s];
                    }
                }
            }

            for(let i = 0; i < cloneStudents.length; i++){
                if(studentId == cloneStudents[i].id){
                    cloneStudents[i].scoreTypeId = scoreTypeId;
                    if(selectedScoreType){
                        cloneStudents[i].scoreTypeName = selectedScoreType.name;
                        cloneStudents[i].isAuto = selectedScoreType.isAuto;
                        cloneStudents[i].isForce = true;
                        cloneStudents[i].errorScoreType = false;
                    }
                }
            }
        }
        setStudents(cloneStudents);
    };

    const handlerAddScore = () => {
        if(isExam){
            setIsEdit(true)
        } else {
            if(templates && templates.length == 1){
                setIsEdit(true);
                let cloneStudent = [...oldStudents];
                if(cloneStudent && cloneStudent.length > 0){
                    for(let i = 0; i < cloneStudent.length; i++){
                        let templateDetails = cloneDeep(templates[0].details);
                        let templateScoreTypes = cloneDeep(templates[0].scoreTypes);

                        for(let t = 0; t < templateDetails.length; t++){
                            templateDetails[t].value = null;
                        }
                        cloneStudent[i].dtls = templateDetails;
                        cloneStudent[i].scoreTypes = templateScoreTypes;
                    }
                }
                setStudents(cloneStudent);
                setSelectedTemplate(templates);
            } else if(templates.length > 1) {
                setAddModal(true);
            } else {
                message(t('assessment.notSelected'));
            }
        }
    };

    const closeModal = () => {
        setAddModal(false)
    };

    const closePublishModal = () => {
        setPublishModal(false)
    };

    const handleBackClick = () => {
        let cloneOldData = cloneDeep(oldStudents);
        setStudents(cloneOldData);
        setIsEdit(false);
        if(!isExam){
            setSelectedTemplate([]);
        }
    };

    const onPublishButton = () => {
        let hasError = false;
        let cloneStudents = [...students];
        if(cloneStudents && cloneStudents.length > 0){
            for(let i = 0; i < cloneStudents.length; i++){
                if(cloneStudents[i].dtls && cloneStudents[i].dtls.length > 0){
                    let details = cloneStudents[i].dtls;
                    for(let d = 0; d < details.length; d++){
                        if(details[d].isEdit){
                            if(details[d].value == null){
                                hasError = true;
                                details[d].error = true;
                            } else if(!details[d].value){
                                hasError = true;
                                details[d].error = true;
                            } else if (details[d].value == null){
                                hasError = true;
                                details[d].error = true;
                            }
                        }
                    }
                }

                // if(!cloneStudents[i].scoreTypeId){
                //     console.log('cloneStudents[i]', cloneStudents[i])
                //     cloneStudents[i].errorScoreType = true;
                //     hasError = true;
                // }
            }
        }

        if(!hasError){
            setPublishModal(true);
        } else {
            setStudents(cloneStudents);
            message(t('warning.error_publish'))
        }
    };

    const onTemplateSaveClick = () => {
        const [ isValid, states, values ] = formRef.current.validate();
        if (isValid) {
            if(templates && templates.length > 0){
                setIsEdit(true);
                for(let i = 0; i < templates.length; i++){
                    if(values.template == templates[i].value){
                        let cloneStudent = [...oldStudents];
                        if(cloneStudent && cloneStudent.length > 0){
                            for(let s = 0; s < cloneStudent.length; s++){
                                const templateDetails = cloneDeep(templates[i].details);
                                const templateScoreTypes = cloneDeep(templates[i].scoreTypes);

                                for(let t = 0; t < templateDetails.length; t++){
                                    templateDetails[t].value = null;
                                }
                                cloneStudent[s].dtls = templateDetails;
                                cloneStudent[s].scoreTypes = templateScoreTypes;
                            }
                        }
                        setStudents(cloneStudent);
                        setSelectedTemplate([templates[i]]);
                        setAddModal(false);
                    }
                }
            }
        }
    };

    const onSubmit = (publish) => {
        setIsEdit(false);

        let cloneStudents = [...students];
        let studentsArray = [];
        if(cloneStudents && cloneStudents.length > 0){
            for(let i = 0; i < cloneStudents.length; i++){
                cloneStudents[i].errorScoreType = false;
                let scoreTypeId = null;
                let isForce = false;
                let selectedScoreType = null;
                if(cloneStudents[i].scoreTypeId){
                    scoreTypeId = cloneStudents[i].scoreTypeId;
                    isForce = cloneStudents[i].isForce;
                } else {
                    if(cloneStudents[i].dtls && cloneStudents[i].dtls.length > 0){
                        let studentDetails = cloneStudents[i].dtls;
                        let studentTotalScore = 0;
                        for(let l = 0; l < studentDetails.length; l++){
                            if(studentDetails[l].value){
                                studentTotalScore = studentTotalScore + parseInt(studentDetails[l].value);
                            }
                        }
                        if(studentTotalScore > 0){
                            if(cloneStudents[i].scoreTypes && cloneStudents[i].scoreTypes.length > 0){
                                let scoreType = cloneStudents[i].scoreTypes;
                                for(let t = 0; t < scoreType.length; t++){
                                    if(scoreType[t].minScore <= studentTotalScore && studentTotalScore <= scoreType[t].maxScore){
                                        selectedScoreType = scoreType[t];
                                    }
                                }
                            }
                        }
                    }
                }
                if(cloneStudents[i].dtls && cloneStudents[i].dtls.length > 0){
                    let details = cloneStudents[i].dtls;
                    for(let d = 0; d < details.length; d++){
                        details[d].error = false;
                        studentsArray.push({
                            id: cloneStudents[i].id,
                            dtl: details[d].id,
                            score: details[d].value,
                            scoreType: scoreTypeId ? scoreTypeId : selectedScoreType ? selectedScoreType.value : null,
                            isForce: isForce
                        })
                    }
                }
            }
        }

        const bodyParams = {
            school: schoolId,
            timetable: timetableId,
            template: selectedTemplate[0].value,
            students: JSON.stringify(studentsArray),
            publish
        };

        dispatch(setLoading(true));
        fetchRequest(journalResultSave, 'POST', bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    message(data?.message || t('common.success'), true);
                    setIsExam(data.exam);
                    let studentData = data.students;
                    if(studentData && studentData.length > 0){
                        for(let i = 0; i < studentData.length; i++){
                            let cloneStudents = [...students];
                            for(let s = 0; s < cloneStudents.length; s++){
                                if(studentData[i].id == cloneStudents[s].id){
                                    cloneStudents[s].scoreTypeId = studentData[i].scoreTypeId;
                                    cloneStudents[s].scoreTypeName = studentData[i].scoreTypeName;
                                    cloneStudents[s].totalScore = studentData[i].totalScore;
                                    if(studentData[i].details && studentData[i].details.length > 0){
                                        cloneStudents[s].details = studentData[i].details;
                                    }
                                }
                            }
                        }
                        if(publish){
                            setPublishModal(false);
                        }
                        setIsPublish(publish);
                        setPublishedDate(data.publishDate);
                        setStudents(cloneStudents);
                    }
                    closeModal();
                } else {
                    message(res?.data?.message || t('errorMessage.title'));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const renderBody = () => {
        let tdObj = [];
        if(students && students.length > 0){
            for(let i = 0; i < students.length; i++){
                let studentTotalScore = 0;
                let scoreTypes = [];
                let selectedScoreType = [];
                let isAuto = false;

                if(students[i].dtls && students[i].dtls.length > 0){
                    let studentDetails = students[i].dtls;
                    for(let s = 0; s < studentDetails.length; s++){
                        if(studentDetails[s].value){
                            studentTotalScore = studentTotalScore + parseInt(studentDetails[s].value);
                        }
                    }
                }

                if(students[i].scoreTypes && students[i].scoreTypes.length > 0){
                    let studentScoreType = students[i].scoreTypes;
                    for(let t = 0; t < studentScoreType.length; t++){
                        if(studentScoreType[t].minScore <= studentTotalScore && studentTotalScore <= studentScoreType[t].maxScore){
                            selectedScoreType = studentScoreType[t];
                        }
                        if(studentScoreType[t].isAuto){
                            scoreTypes.push({
                                value: studentScoreType[t].id,
                                text: studentScoreType[t].name
                            })
                        }
                    }
                }

                if(selectedScoreType){
                    isAuto = selectedScoreType.isAuto;
                }

                tdObj.push(
                    <tr key={'score_' + i}>
                        <td>{i + 1}</td>
                        <td>{students[i].code}</td>
                        <td>{students[i].lastName}</td>
                        <td>{students[i].firstName}</td>
                        {
                            students[i].dtls && students[i].dtls.length > 0
                                ?
                                students[i].dtls.map((studentTemplate, index) => {
                                    return (
                                        <td key={'student_score_' + index} style={studentTemplate.isEdit && isEdit ? {padding: 3} : {}}>
                                            {
                                                studentTemplate.isEdit
                                                ?
                                                    isEdit
                                                    ?
                                                        <input
                                                            type={'number'}
                                                            className={studentTemplate.error ? 'is-invalid form-control' : 'form-control'}
                                                            value={studentTemplate.value ? studentTemplate.value : ''}
                                                            onChange={(e) => {
                                                                const re = /^[0-9\b]+$/;
                                                                if (e.target.value === '' || re.test(e.target.value)) {
                                                                    handlerInputChange(e, students[i].id, studentTemplate.id)
                                                                }
                                                            }}
                                                        />
                                                    :
                                                        studentTemplate.value ? studentTemplate.value : 0
                                                :
                                                    ''
                                            }
                                        </td>
                                    )
                                })
                                : null
                        }
                        <td>{studentTotalScore || 0}</td>
                        <td style={isEdit ? isAuto ? {padding: 3} : {} : {}}>
                            {
                                isEdit
                                ?
                                    isAuto
                                    ?
                                        <Select
                                            className={students[i].errorScoreType ? 'is-invalid' : ''}
                                            placeholder={t('common.select')}
                                            options={scoreTypes}
                                            value={students[i].scoreTypeId || null}
                                            onChange={(e) => handlerScoreType(e, students[i].id, students[i].scoreTypes && students[i].scoreTypes.length > 0 ? students[i].scoreTypes : [])}
                                            searchable
                                        />
                                    :
                                        selectedScoreType.name
                                : students[i].scoreTypeName || ''
                            }
                        </td>
                    </tr>
                );
            }
        }

        return tdObj;
    };

    const handlerUnPublish = () => {
        const bodyParams = {
            school: schoolId,
            timetable: timetableId,
            exam: isExam,
        };

        dispatch(setLoading(true));
        fetchRequest(journalResultUnpublish, 'POST', bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    message(data?.message || t('common.success'), true);

                    let params = {
                        school: schoolId,
                        timetable: timetableId,
                        tab: 'RESULT'
                    };
                    init(params)
                } else {
                    message(res?.data?.message || t('errorMessage.title'));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }

    let totalScore = 0;

    return (
        <div className='sm-container'>
            <div className='row'>
                <div className='col-12 mb-6'>
                    {
                        !isPublish
                        ?
                            isEdit
                            ?
                                <>
                                    <button
                                        className='btn btn-success'
                                        onClick={() => onSubmit(0)}
                                    >
                                        {t('common.save')}
                                    </button>
                                    <button
                                        className='btn btn-success ml-3'
                                        onClick={() => onPublishButton()}
                                    >
                                        {t('action.publish')}
                                    </button>
                                    <button
                                        className='btn btn-link bolder'
                                        onClick={() => handleBackClick()}
                                    >
                                        {t('common.back')}
                                    </button>
                                </>
                            :
                                <button className='btn btn-primary btn-shadow' onClick={handlerAddScore}>{t('teacher.addScore')}</button>
                        :
                            <div>{t('teacher.publish') + ': ' + publishedDate} <button onClick={handlerUnPublish} style={{position: 'relative', bottom: 2}} className='btn btn-warning ml-3'>{t('action.unPublish')}</button></div>
                    }
                </div>
                <div className='col-12'>
                    <table className='table table-bordered'>
                        <thead>
                            <tr>
                                <th rowSpan={2}>№</th>
                                <th rowSpan={2}>{t('student.code')}</th>
                                <th rowSpan={2}>{t('student.lastName')}</th>
                                <th rowSpan={2}>{t('student.name')}</th>
                                {
                                    selectedTemplate && selectedTemplate.length > 0 && selectedTemplate[0].details && selectedTemplate[0].details.length > 0
                                    ?
                                        selectedTemplate[0].details.map((template, index) => {
                                            return (
                                                <th key={'item_' + index}>{template.name}</th>
                                            )
                                        })
                                    : null
                                }
                                <th style={{width: 60}}>{t('total.title')}</th>
                                <th rowSpan={2} style={{width: 150}}>{t('assessment.title')}</th>
                            </tr>
                            <tr>
                                {
                                    selectedTemplate && selectedTemplate.length > 0 && selectedTemplate[0].details && selectedTemplate[0].details.length > 0
                                        ?
                                        selectedTemplate[0].details.map((template, index) => {
                                            totalScore = totalScore + parseInt(template.maxScore)
                                            return (
                                                <th key={'score_' + index}>{parseInt(template.maxScore)}</th>
                                            )
                                        })
                                        : null
                                }
                                {
                                    selectedTemplate && selectedTemplate.length > 0
                                    ?
                                        <th style={{width: 60}}>{totalScore}</th>
                                    : null
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {renderBody()}
                        </tbody>
                    </table>
                </div>
            </div>
            <Modal
                show={addModal}
                onHide={closeModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                            {t('assessment.selectStTemplate').toUpperCase()}
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Forms
                        ref={formRef}
                        fields={fields}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={closeModal} className='btn btn-link bolder'>{t('common.back')}</button>
                    <Button variant='success btn-shadow' onClick={onTemplateSaveClick}>{t('common.select')}</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                size={'lg'}
                show={publishModal}
                onHide={closePublishModal}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                            {t('action.publish').toUpperCase()}
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {t('warning.publish_confirmation_description')}
                    {t('warning.publish_confirmation')}<br/>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={closePublishModal} className='btn btn-link bolder'>{t('common.cancel')}</button>
                    <Button variant='success btn-shadow' onClick={() => onSubmit(1)}>{t('action.publish')}</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
};

export default Score;