import React, { useEffect, useState } from 'react';
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import Select from "../../../modules/Form/Select";
import { examTypeAdd, getSubjectListByType, scoreTypeAdd, scoreTypeEdit, stItemAdd, stTemplateEdit } from "../../../../utils/fetchRequest/Urls";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import message from "../../../modules/message";
import Checkbox from "../../../modules/Form/Checkbox";
import DTable from "../../../modules/DataTable/DTable";

const StTemplateAdd = ({
    onClose,
    onSubmit,
    subjectTypes,
    id
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [ code, setCode ] = useState('');
    const [ name, setName ] = useState('');
    const [ scoreTypes, setScoreTypes ] = useState([]);
    const [ examTypes, setExamTypes ] = useState([]);
    const [ scoreTypeId, setScoreTypeId ] = useState(null);
    const [ subjectRows, setSubjectRows ] = useState([{
        subjectTypeId: null,
        subjectIds: [],
        subjects: [],
    }]);
    const [ langValues, setLangValues ] = useState([]);
    const [ details, setDetails ] = useState([]);
    const [ editDetail, setEditDetail ] = useState(false);
    const [ stItems, setStItems ] = useState([]);
    const [ stItemId, setStItemId ] = useState(null);
    const [ detailId, setDetailId ] = useState(null);
    const [ isEditable, setIsEditable ] = useState(false);
    const [ min, setMin ] = useState('');
    const [ max, setMax ] = useState('');
    const [ ordering, setOrdering ] = useState('');
    const [ examTypeIds, setExamTypeIds ] = useState([]);
    const [ totalScore, setTotalScore ] = useState([]);
    const [ action, setAction ] = useState('');

    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    useEffect(() => {
        if(id){
            dispatch(setLoading(true));
            const params = {
                template: id,
                school: schoolId,
            };

            fetchRequest(stTemplateEdit, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res;
                        const translations = data?.template?.translations;
                        const translationArray = [];
                        for (const lang of languages) {
                            translationArray.push({
                                langCode: lang.code,
                                value: '',
                                name: lang.name,
                            })
                        }

                        if(translationArray && translationArray.length > 0 && translations && translations.length > 0){
                            for(let i = 0; i < translationArray.length; i++){
                                for(let t = 0; t < translations.length; t++)
                                    if(translationArray[i]['langCode'] == translations[t]['langCode']){
                                        translationArray[i]['value'] = translations[t]['name'];
                                    }
                            }
                        }

                        setCode(data.template?.code);
                        setName(data.template?.name);
                        setScoreTypeId(data.template?.scoreTypeId);
                        setDetails(data.template?.details);
                        setLangValues(translationArray);
                        if(data.template?.rowSubjects && data.template?.rowSubjects.length > 0){
                            setSubjectRows(data.template?.rowSubjects);
                        }
                    } else {
                        message(res?.data?.message || t('errorMessage.title'));
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'));
                });
        }
    }, [id]);

    useEffect(() => {
        if(details && details.length > 0){
            let totalScore = 0;
            for (let i = 0; i < details.length; i++){
                totalScore = totalScore + parseInt(details[i]['min']);
            }
            setTotalScore(totalScore);
        }
    }, [details]);

    useEffect(() => {
        const array = [];
        for (const lang of languages) {
            array.push({
                langCode: lang.code,
                value: '',
                name: lang.name,
            })
        }
        setLangValues(array);
        const params = {
            school: schoolId,
        };
        dispatch(setLoading(true));
        fetchRequest(scoreTypeAdd, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;

                    if (data?.scoreTypes?.length) {
                        const types = data.scoreTypes.map(type => ({
                            text: type.name,
                            value: type.id,
                        }));
                        setScoreTypes(types);
                    }
                } else {
                    message(res?.data?.message || t('errorMessage.title'));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            });
        dispatch(setLoading(true));
        fetchRequest(stItemAdd, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;

                    const items = [];
                    if (data?.stItems?.length) {
                        for (const stItem of data.stItems) {
                            items.push({
                                text: stItem.name,
                                value: stItem.id,
                                code: stItem.code,
                            })
                        }
                    }
                    setStItems(items);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            });
        dispatch(setLoading(true));
        fetchRequest(examTypeAdd, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;

                    if (data?.examTypes?.length) {
                        const types = data.examTypes.map(type => {
                            return {
                                value: type.id,
                                text: type.name,
                            }
                        });
                        setExamTypes(types);
                    }

                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }, []);

    const languages = useSelector(state => state?.languages?.languages || []).filter(lang => lang.code !== 'mn');

    const onScoreTypeChange = val => {
        setScoreTypeId(val);
    };

    const handleSubjectTypeChange = (value, index) => {
        const clone = [ ...subjectRows ];
        const row = { ...clone[index] };
        row.subjectTypeId = value;
        const params = {
            school: schoolId,
            type: value
        };
        dispatch(setLoading(true));
        fetchRequest(getSubjectListByType, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;

                    row.subjects = data?.subjects?.length ? data.subjects : [];
                    clone[index] = row;
                    setSubjectRows(clone);
                } else {
                    row.subjects = [];
                    clone[index] = row;
                    setSubjectRows(clone);
                    message(res?.data?.message || t('errorMessage.title'));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                row.subjects = [];
                clone[index] = row;
                setSubjectRows(clone);
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            });
    };

    const handleSubjectChange = (val, index) => {
        const clone = [ ...subjectRows ];
        const row = { ...clone[index] };
        row.subjectIds = val;
        clone[index] = row;
        setSubjectRows(clone);
    };

    const addRow = () => {
        setSubjectRows([...subjectRows, {
            subjectTypeId: null,
            subjectIds: [],
            subjects: [],
        }]);
    };

    const removeRow = index => {
        const clone = [...subjectRows];
        clone.splice(index, 1);
        setSubjectRows(clone);
    };

    const getIsAllSubjectsSelected = index => {
        if(subjectRows && subjectRows.length > 0 && subjectRows[index].subjectIds && subjectRows[index].subjectIds.length > 0 ){
            return subjectRows[index].subjects.length && subjectRows[index].subjects.length === subjectRows[index].subjectIds.length;
        }
    };

    const handleSelectAllClick = (checked, index) => {
        const clone = [ ...subjectRows ];
        const row = { ...clone[index] };

        if (checked) {
            row.subjectIds = [];
        } else {
            row.subjectIds = row.subjects.map(subject => subject.value);
        }
        clone[index] = row;
        setSubjectRows(clone);
    };

    const handleLanguageValueChange = (val, code) => {
        const clone = [...langValues];
        const index = clone.findIndex(lang => lang.langCode === code);
        if (index >= 0) {
            const language = { ...clone[index] };
            language.value = val;
            clone[index] = language;
            setLangValues(clone);
        }
    };

    const columns = [
        {
            dataField: "stItemName",
            text: 'Үзүүлэлт',
            formatExtraData: stItems,
            type: 'number',
            formatter: (cell, row, rowIndex, items) => {
                if(id){
                    return row.stItemName;
                } else {
                    let name = '-';
                    const item = items.find(item => item.value === row.stItemId);
                    if (item) {
                        name = item.text;
                    }
                    return name;
                }
            }
        },
        {
            dataField: "isEditable",
            text: t('common.isEdit'),
            formatter: cell => {
                if (cell) {
                    return t('common.yes');
                }
                return t('common.no');
            }
        },
        {
            dataField: "rate",
            text: t('assessment.rate'),
            formatter: (cell, row, index) => {
                return (
                    <div key={index} style={{ display: 'flex' }}>
                        <div style={{ flex: 1, textAlign: 'right' }}>{row.min}</div>
                        <div style={{ flex: 1, textAlign: 'right' }}>{row.max}</div>
                    </div>
                )
            }
        },
        {
            dataField: "ordering",
            text: t('common.ordering'),
        },
    ];

    const contextMenus = [
        {
            key: 'edit',
            title: t('common.edit'),
            icon: <i className='las la-edit dt-cm-item-icon'/>
        },
        {
            key: 'delete',
            title: t('common.delete'),
            icon: <i className='las la-trash-alt dt-cm-item-icon' />,
        },
    ];

    const handleContextMenuClick = (id, key) => {
        if(key){
            if(key == 'delete'){
                let leftArray = [];
                for (let i = 0; i < details.length; i++){
                    if(id !== details[i]['id']){
                        leftArray.push(details[i]);
                    }
                }

                setDetails(leftArray);
            } else if (key == 'edit'){
                let selectedItem = [];
                for (let i = 0; i < details.length; i++){
                    if(id == details[i]['id']){
                        selectedItem = details[i];
                    }
                }
                setAction(key);
                setDetailId(id);
                setEditDetail(true);

                setStItemId(selectedItem.stItemId);
                setIsEditable(selectedItem.isEditable);
                setMax(selectedItem.max);
                setMin(selectedItem.min);
                setOrdering(selectedItem.ordering);
            }
        }
    };

    const handleAddClick = () => {
        setEditDetail(true);
    };

    const cancelEditDetail = () => {
        setEditDetail(false);
        setStItemId(null);
        setIsEditable(false);
        setMax('');
        setMin('');
        setOrdering('');
        setExamTypeIds([]);
    };

    const onStItemChange = value => {
        setStItemId(value);
    };

    const onExamTypeChange = value => {
        setExamTypeIds(value);
    };

    const onSaveDetailClick = () => {
        if(action == 'edit'){
            const cloneDetail = [...details];
            const stItem = stItems.find(i => i.value === stItemId);

            for (let i = 0; i < cloneDetail.length; i++){
                if(detailId == cloneDetail[i]['id']){
                    cloneDetail[i].stItemId = stItem.value;
                    cloneDetail[i].stItemName = stItem.text;
                    cloneDetail[i].stItemCode = stItem.code;
                    cloneDetail[i].max = max;
                    cloneDetail[i].min = min;
                    cloneDetail[i].ordering = ordering;
                    cloneDetail[i].isEditable = isEditable;
                    cloneDetail[i].examTypeIds = [...examTypeIds];
                }
            }

            setDetails(cloneDetail);
            setEditDetail(false);
        } else {
            const stItem = stItems.find(i => i.value === stItemId);
            setDetails([...details, {
                id: details.length,
                stItemId: stItemId,
                stItemName: stItem.text,
                stItemCode: stItem.code,
                examTypeIds: [...examTypeIds],
                isEditable,
                max,
                min,
                ordering,
            }]);

            cancelEditDetail();
        }
    };

    const onSaveClick = publish => {
        const formData = new FormData();
        if(id){
            formData.append('template', id);    
        }
        
        formData.append('school', schoolId);
        formData.append('code', code);
        formData.append('name', name);
        formData.append('scoreType', scoreTypeId);
        formData.append('publish', publish);
        formData.append('total', totalScore > 0 ? totalScore : 0);
        formData.append('details', JSON.stringify(details));
        formData.append('subjects', JSON.stringify(subjectRows));

        const translations = langValues.map(lang => {
            return {
                langCode: lang.langCode,
                name: lang.value,
            }
        });

        formData.append('translations', JSON.stringify(translations));
        onSubmit(formData);
    };

    let stItemCode = '';
    if (stItemId) {
        const stItem = stItems.find(i => i.value === stItemId);
        if (stItem) {
            stItemCode = stItem.code;
        }
    }

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName='modal-90w'
        >
            {
                !editDetail
                    ?
                    <>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                                    {t('assessment.stTemplate').toUpperCase()} {id ? t('common.edit').toUpperCase() : ''}
                                </div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='row' style={{ alignItems: 'center' }}>
                                <div className='col-5 text-right'>
                                    {t('common.code')}
                                </div>
                                <div className='col-3'>
                                    <input
                                        className='form-control'
                                        value={code}
                                        onChange={e => {setCode(e.target.value)}}
                                    />
                                </div>
                            </div>
                            <div className='row mt-2' style={{ alignItems: 'center' }}>
                                <div className='col-5 text-right'>
                                    {t('common.name')}
                                </div>
                                <div className='col-3'>
                                    <input
                                        className='form-control'
                                        value={name}
                                        onChange={e => {setName(e.target.value)}}
                                    />
                                </div>
                            </div>
                            <div className='row mt-2' style={{ alignItems: 'center' }}>
                                <div className='col-5 text-right'>
                                    {t('curriculum.scoreType')}
                                </div>
                                <div className='col-3'>
                                    <Select
                                        searchable
                                        value={scoreTypeId}
                                        onChange={onScoreTypeChange}
                                        options={scoreTypes}
                                    />
                                </div>
                            </div>
                            <div className='row mt-8'>
                                <div className='col-2'/>
                                <div className='col'>
                                    <table>
                                        <thead>
                                            <tr>
                                                <td style={{ width: 250 }} className='pb-3'>{t('subject.subjectType')}</td>
                                                <td style={{ width: 250 }} className='pl-3 pb-3'>{t('subject.title')}</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            subjectRows.map((row, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td style={{ verticalAlign: 'baseline' }}>
                                                            <Select
                                                                value={row.subjectTypeId}
                                                                options={subjectTypes}
                                                                searchable
                                                                onChange={val => handleSubjectTypeChange(val, index)}
                                                            />
                                                        </td>
                                                        <td>
                                                            <div className='ml-3'>
                                                                <Select
                                                                    disabled={!row.subjectTypeId}
                                                                    value={row.subjectIds}
                                                                    options={row.subjects}
                                                                    searchable
                                                                    onChange={val => handleSubjectChange(val, index)}
                                                                    multiple
                                                                />
                                                                <Checkbox
                                                                    className='mt-2'
                                                                    disabled={!row.subjectTypeId || !row.subjects.length}
                                                                    label={t('common.selectAll')}
                                                                    checked={getIsAllSubjectsSelected(index)}
                                                                    onChange={(checked) => handleSelectAllClick(checked, index)}
                                                                />
                                                            </div>
                                                        </td>
                                                        <td style={{ verticalAlign: 'baseline' }}>
                                                            <button className='btn btn-primary row-action-button ml-3' onClick={addRow}>
                                                                <i className='las la-plus' style={{ fontSize: 20 }} />
                                                            </button>
                                                        </td>
                                                        <td style={{ verticalAlign: 'baseline' }}>
                                                            <button className='btn btn-danger row-action-button ml-3' onClick={() => removeRow(index)}>
                                                                <i className='las la-times' style={{ fontSize: 20 }} />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                                <div style={{ flex: 1 }}>
                                    <button
                                        className='btn btn-primary'
                                        onClick={handleAddClick}
                                    >
                                        {t('common.add')}
                                    </button>
                                </div>
                                <span>
                                    {`${t('assessment.totalScore')}: ` + totalScore }
                                </span>
                            </div>
                            <DTable
                                columns={columns}
                                data={details}
                                config={{
                                    showPagination: false,
                                    showFilter: false,
                                    showAllData: true
                                }}
                                contextMenus={contextMenus}
                                onContextMenuItemClick={handleContextMenuClick}
                                selectMode={'radio'}
                            />

                            <div key={'separator'} className="separator separator-dashed my-7"/>
                            {
                                langValues.map(lang => {
                                    return (
                                        <div key={lang.langCode} className='row'>
                                            <div className='col-8'>
                                                <div style={{ display: 'flex', alignItems: 'center', marginTop: '0.8rem' }}>
                                                    <div style={{ flex: 2, textAlign: 'right', marginRight: 10 }}>{`${t('common.name')} - ${lang.name} (${lang.langCode})`}</div>
                                                    <div style={{ flex: 1, marginLeft: 10 }}>
                                                        <input
                                                            value={lang.value}
                                                            onChange={e => handleLanguageValueChange(e.target.value, lang.langCode)}
                                                            className='form-control'
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <button onClick={onClose} className='btn btn-link bolder'>{t('common.back')}</button>
                            <Button variant='success btn-shadow' onClick={() => onSaveClick(0)}>{t('common.save')}</Button>
                            <Button variant='success btn-shadow' onClick={() => onSaveClick(1)}>{t('action.publish')}</Button>
                        </Modal.Footer>
                    </>
                    :
                    <>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                                    {t('assessment.specification').toUpperCase()}
                                </div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='row mt-2' style={{ alignItems: 'center' }}>
                                <div className='col-5 text-right'>
                                    Үзүүлэлт
                                </div>
                                <div className='col-3'>
                                    <Select
                                        searchable
                                        value={stItemId}
                                        onChange={onStItemChange}
                                        options={stItems}
                                    />
                                </div>
                            </div>
                            {
                                stItemCode.toLowerCase() === 'exam' && (
                                    <div className='row mt-2' style={{ alignItems: 'center' }}>
                                        <div className='col-5 text-right'>
                                            {t('examType.title')}
                                        </div>
                                        <div className='col-3'>
                                            <Select
                                                searchable
                                                multiple
                                                value={examTypeIds}
                                                onChange={onExamTypeChange}
                                                options={examTypes}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                            <div className='row mt-2' style={{ alignItems: 'center' }}>
                                <div className='col-5 text-right'/>
                                <div className='col-3'>
                                    <Checkbox
                                        label={'Гараас утга засах эсэх'}
                                        onChange={checked => {
                                            setIsEditable(!checked);
                                        }}
                                        checked={isEditable}
                                    />
                                </div>
                            </div>
                            <div className='row mt-2' style={{ alignItems: 'center' }}>
                                <div className='col-5 text-right'>
                                    {t('assessment.maxPercent')}
                                </div>
                                <div className='col-3'>
                                    <input
                                        className='form-control'
                                        value={max}
                                        onChange={e => {
                                            const re = /^[0-9\b]+$/;
                                            if (e.target.value === '' || re.test(e.target.value)) {
                                                setMax(e.target.value)
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='row mt-2' style={{ alignItems: 'center' }}>
                                <div className='col-5 text-right'>
                                    {t('assessment.minPercent')}
                                </div>
                                <div className='col-3'>
                                    <input
                                        className='form-control'
                                        value={min}
                                        onChange={e => {
                                            const re = /^[0-9\b]+$/;
                                            if (e.target.value === '' || re.test(e.target.value)) {
                                                setMin(e.target.value)
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='row mt-2' style={{ alignItems: 'center' }}>
                                <div className='col-5 text-right'>
                                    {t('common.ordering')}
                                </div>
                                <div className='col-3'>
                                    <input
                                        className='form-control'
                                        value={ordering}
                                        onChange={e => {
                                            const re = /^[0-9\b]+$/;
                                            if (e.target.value === '' || re.test(e.target.value)) {
                                                setOrdering(e.target.value)
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button onClick={cancelEditDetail} className='btn btn-link bolder'>{t('common.back')}</button>
                            <Button variant='success btn-shadow' onClick={onSaveDetailClick}>{t('common.save')}</Button>
                        </Modal.Footer>
                    </>
            }
        </Modal>
    );
};

export default StTemplateAdd;