import React, {useRef, useEffect, useState} from 'react';
import {Button, ButtonToolbar, Card} from "react-bootstrap";
import Forms from "../../../../modules/Form/Forms";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {teacherOnlineLessonHomeworkView, teacherFileGet} from "../../../../../utils/fetchRequest/Urls";
import { setLoading } from "../../../../../redux/action";
import {fetchRequest, fetchRequestFile} from "../../../../../utils/fetchRequest";
import message from "../../../../modules/message";
import {Link, useHistory} from "react-router-dom";
import DTable from "../../../../modules/DataTable/DTable";
import RenderTableList from "../../../../modules/RenderTableList";
import {cloneDeep, isNumber} from "lodash";
import { saveAs } from 'file-saver';

export const TeacherOnlineLessonEditHomeworkView = ({
    location: {state: {id = null, homeworkId = null} = {}} = {},
}) => {
    const { t } = useTranslation();
    const formRef = useRef();
    const history = useHistory();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [oldGroupsData, setOldGroupsData] = useState([]);
    const [groups, setGroups] = useState([]);
    const [homeworkData, setHomeworkData] = useState(null);
    const [students, setStudents] = useState([]);
    const [oldStudentData, setOldStudentData] = useState([]);

    const [labels, setLabels] = useState([]);
    const [editIndex, setEditIndex] = useState(null);

    useEffect(() => {
        const params = {
            school: schoolId,
            course: id,
            homework: homeworkId,
        };

        dispatch(setLoading(true));
        fetchRequest(teacherOnlineLessonHomeworkView, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setOldGroupsData(data?.groups || []);
                    setGroups(data?.groups || []);
                    setHomeworkData(data?.homeworkData || null);

                    let labelArray = [];

                    labelArray.push(
                        {
                            code: 'name',
                            name: t('homework.name'),
                            value: data?.homeworkData.name || ''
                        },
                        {
                            code: 'description',
                            name: t('homework.description'),
                            value: data?.homeworkData.description || ''
                        },
                        {
                            code: 'score',
                            name: t('homework.score'),
                            value: data?.homeworkData.totalScore || ''
                        },
                        {
                            code: 'answerType',
                            name: t('homework.answerType'),
                            value: data?.homeworkData.typeNames || ''
                        },
                        {
                            code: 'date',
                            name: t('common.date'),
                            value: data?.homeworkData.duration || ''
                        }
                    );
                    setLabels(labelArray);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }, []);

    const onSaveRowClick = (groupId, studentId) => {
        let score = null;
        let description = null;

        if(groups && groups.length > 0){
            for(let i = 0; i < groups.length; i++){
                if(groupId == groups[i].id){
                    let students = groups[i].studentList;
                    if(students && students.length > 0){
                        for(let s = 0; s < students.length; s++){
                            if(parseInt(studentId) == parseInt(students[s].id)){
                                score = students[s].score;
                                description = students[s].description;
                            }
                        }
                    }
                }
            }
        }

        if(!score){
            return message('Оноо оруулна уу')
        }

        if(parseInt(score) > parseInt(homeworkData.totalScore)){
            return message('Даалгаврын онооноос их оноо оруулсан байна');
        }

        const params = {
            school: schoolId,
            course: id,
            homework: homeworkId,
            student: studentId,
            score: score,
            description: description,
        };

        dispatch(setLoading(true));
        fetchRequest(teacherOnlineLessonHomeworkView, 'POST', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setOldGroupsData(data?.groups || []);
                    setGroups(data?.groups || []);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })

        setEditIndex(null);
    };

    const onCancelRowClick = () => {
        const cloneOldGroupsData = cloneDeep(oldGroupsData);
        setEditIndex(null);
        setGroups(cloneOldGroupsData);
    };

    const onEditRowClick = (index, extraData, groupId) => {
        let cloneGroups = cloneDeep(extraData);

        if(cloneGroups && cloneGroups.length > 0){
            for(let i = 0; i < cloneGroups.length; i++){
                if(parseInt(groupId) == parseInt(cloneGroups[i].id)){
                    let cloneStudent = cloneGroups[i].studentList;
                    if(cloneStudent && cloneStudent.length > 0){
                        for(let s = 0; s < cloneStudent.length; s++){
                            if(index == s){
                                cloneStudent[s].isEdit = true;
                            } else {
                                cloneStudent[s].isEdit = false;
                            }
                        }
                    }
                } else {
                    let cloneStudent = cloneGroups[i].studentList;
                    if(cloneStudent && cloneStudent.length > 0){
                        for(let s = 0; s < cloneStudent.length; s++){
                            cloneStudent[s].isEdit = false;
                        }
                    }
                }
            }

            setEditIndex(index);
            setGroups(cloneGroups);
        }
    };

    const onRemoveRowClick = (groupId, studentId) => {
        const params = {
            school: schoolId,
            course: id,
            homework: homeworkId,
            student: studentId,
            type: 'delete'
        };

        dispatch(setLoading(true));
        fetchRequest(teacherOnlineLessonHomeworkView, 'POST', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setOldGroupsData(data?.groups || []);
                    setGroups(data?.groups || []);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const config = {
        showAllData: true,
        showFilter: true,
        showPagination: false,
    };

    const onInputHandler = (e, index, groupId) => {
        let cloneGroups = [...groups];
        if(cloneGroups && cloneGroups.length > 0){
            for(let i = 0; i < cloneGroups.length; i++){
                if(groupId == cloneGroups[i].id){
                    let cloneStudent = cloneGroups[i].studentList;
                    if(cloneStudent && cloneStudent.length > 0){
                        for(let s = 0; s < cloneStudent.length; s++){
                            if(index == s){
                                cloneStudent[s].score = e.target.value;
                            }
                        }
                    }
                }
            }

            setGroups(cloneGroups);
        }
    };

    const onTextAreaHandler = (e, index, groupId) => {
        let cloneGroups = [...groups];
        if(cloneGroups && cloneGroups.length > 0){
            for(let i = 0; i < cloneGroups.length; i++){
                if(groupId == cloneGroups[i].id){
                    let cloneStudent = cloneGroups[i].studentList;
                    if(cloneStudent && cloneStudent.length > 0){
                        for(let s = 0; s < cloneStudent.length; s++){
                            if(index == s){
                                cloneStudent[s].description = e.target.value;
                            }
                        }
                    }
                }
            }

            setGroups(cloneGroups);
        }
    };

    const viewHandler = (fileId) => {
        const bodyParams = {
            file: fileId,
            type: 'fmFile'
        };

        dispatch(setLoading(true));
        fetchRequest(teacherFileGet, 'POST', bodyParams)
            .then(res => {
                if(res.success){
                    fetchRequestFile(teacherFileGet, 'GET', bodyParams)
                        .then(res => {
                            if(res.status != 200){
                                dispatch(setLoading(false));
                                message(res?.data?.message || t('errorMessage.title'));
                            }
                            else{
                                return res.blob();
                            }
                        })
                        .then(blob => {
                            dispatch(setLoading(false));
                            if(blob){
                                saveAs(blob, res.data?.name);
                            }
                        })
                        .catch((e) => {
                            dispatch(setLoading(false));
                            message(t('errorMessage.title'));
                        });
                }
            })
            .catch((e) => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            });
    };

    const columns = [
        {
            dataField: "code",
            text: t('student.code'),
            sort: true,
        },
        {
            dataField: "lastName",
            text: t('person.lastName'),
            sort: true,
        },
        {
            dataField: "firstName",
            text: t('person.firstName'),
            sort: true,
        },
        {
            dataField: "fileList",
            text: t('homework.sentFile'),
            formatter: (fileList) => {
                if(fileList && fileList.length > 0){
                    return (
                        <div className='msue-dt-clickable-cell'>
                            <span style={{color:'#5c3cc7',cursor:'pointer'}}>
                                {
                                    fileList.map((c, index)=>{
                                        let url = c.name;
                                        if(index + 1 < fileList.length){
                                            url += ', '
                                        }
                                        return <u onClick={()=>viewHandler(c.id)} key={index + url}>{url}</u>
                                    })
                                }
                            </span>
                        </div>
                    )
                } else {
                    return null;
                }
            }
        },
        {
            dataField: "studentAnswer",
            text: t('exam.premiseResponse'),
            sort: false,
        },
        {
            dataField: "score",
            text: t('teacher.score'),
            sort: false,
            style: {padding: 3, verticalAlign: 'middle', width: 90},
            formatter: (cell, row, rowIndex) => {
                if(row.isEdit){
                    return (
                        <div>
                            <input type='number' className='form-control' value={cell ? cell : ''} autoFocus={true} onChange={(e) => onInputHandler(e, rowIndex, row.groupId)}/>
                        </div>
                    )
                } else {
                    return cell
                }
            }
        },
        {
            dataField: "description",
            text: t('common.description'),
            sort: false,
            style: {padding: 3, verticalAlign: 'middle'},
            formatter: (cell, row, rowIndex) => {
                if(row.isEdit){
                    return (
                        <div>
                            <input type='textArea' className='form-control' value={cell ? cell : ''} autoFocus={true} onChange={(e) => onTextAreaHandler(e, rowIndex, row.groupId)}/>
                        </div>
                    )
                } else {
                    return cell
                }
            }
        },
        {
            isDummyField: true,
            dataField: "actions",
            text: '',
            style: {padding: 3, verticalAlign: 'middle', width: 90},
            formatExtraData: groups,
            formatter: (cell, row, rowIndex, extraData) => {
                if(row.isEdit == true){
                    return (
                        <div>
                            <button className='btn btn-success row-action-button ml-1' style={{borderColor: 'transparent' }} onClick={(e) => onSaveRowClick(row.groupId, row.id)}>
                                <i className='la la-check' style={{ fontSize: 20 }} />
                            </button>
                            <button className='btn btn-secondary row-action-button ml-2' onClick={() => onCancelRowClick()}>
                                <i className='las la-times' style={{ fontSize: 20 }} />
                            </button>
                        </div>
                    )
                } else {
                    if(isNumber(editIndex)){
                        return null;
                    } else {
                        return (
                            <div>
                                <button className='btn btn-primary row-action-button ml-1' style={{ backgroundColor: '#716aca', borderColor: 'transparent' }} onClick={() => onEditRowClick(rowIndex, extraData, row.groupId)}>
                                    <i className='las la-pen' style={{ fontSize: 20 }} />
                                </button>
                                <button className='btn btn-danger row-action-button ml-2' onClick={() => onRemoveRowClick(row.groupId, row.id)}>
                                    <i className='las la-times' style={{ fontSize: 20 }} />
                                </button>
                            </div>
                        )
                    }
                }
            }
        },
    ];

    return (
        <div className='sm-container'>
            <Card>
                <Card.Header>
                    <span className='french-blue fs-10'>
                        <b>{t('homework.title').toUpperCase()}</b>
                    </span>
                    <div style={{float: 'right'}}>
                        <Link
                            to={{
                                pathname: '/teacher/online/lesson-edit',
                                state: {
                                    courseId: id,
                                }
                            }}
                            className="btn btn-link bolder"
                        >
                            {t('common.back')}
                        </Link>
                    </div>
                </Card.Header>
                <Card.Body>
                    <Card>
                        <Card.Body>
                            <RenderTableList
                                stringArray={labels}
                            />
                        </Card.Body>
                    </Card>

                    <Card className='mt-4'>
                        <Card.Header>
                            <span className='french-blue fs-10'>
                                <b>{t('homework.assignmentStudents').toUpperCase()}</b>
                            </span>
                        </Card.Header>
                        <Card.Body>
                            {
                                groups && groups.length > 0 
                                ?
                                    groups.map((group, index) => {
                                        return (
                                            <div key={'group_' + index}>
                                                <p>
                                                    <b>{group['name'] + ', ' + group['typeName']}</b>
                                                </p>
                                                <DTable
                                                    data={group['studentList']}
                                                    columns={columns}
                                                    config={config}
                                                    selectMode={'radio'}
                                                />
                                            </div>
                                        )
                                    })
                                : null
                            }
                        </Card.Body>
                    </Card>
                </Card.Body>
                <Card.Footer>
                    <div className='col-11 text-center'>
                        <Link
                            to={{
                                pathname: '/teacher/online/lesson-edit',
                                state: {
                                    courseId: id,
                                }
                            }}
                            className="btn btn-link bolder"
                        >
                            {t('common.back')}
                        </Link>
                    </div>
                </Card.Footer>
            </Card>

        </div>
    );
};