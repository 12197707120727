import React, {useEffect, useState} from 'react'
import DTable from '../../../modules/DataTable/DTable'
import {Button, Card} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {selectionPenaltyIndex, teacherTimetableInit} from "../../../../utils/fetchRequest/Urls";
import {setLoading} from "../../../../redux/action";
import {fetchRequest} from "../../../../utils/fetchRequest";
import message from "../../../modules/message";

export const TeacherTimetable = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [ shifts, setShifts ] = useState([]);
    const [ days, setDays ] = useState([]);
    const [ season, setSeason ] = useState([]);

    useEffect(() => {
        let params = {
            school: schoolId,
            pageSize: 10,
            page: 1,
        };

        init(params);
    }, []);

    const init = (params) => {
        dispatch(setLoading(true));
        fetchRequest(teacherTimetableInit, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setSeason(data?.season || null);
                    setDays(data?.days || []);
                    setShifts(data?.shifts || [])
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const getCellTeachers = (timetables, day, period) => {
        return timetables.map(data => {
            if(data){
                if(data.timeId === period){
                    if(data.timetables && data.timetables.length > 0){
                        let timetableDatas = data.timetables;

                        for (let i = 0; i < timetableDatas.length; i++){
                            if(timetableDatas[i].dayId === day){
                                if(timetableDatas[i].dayTimetables && timetableDatas[i].dayTimetables.length > 0){
                                    let dayDatas = timetableDatas[i].dayTimetables;
                                    for (let t = 0; t < dayDatas.length; t++){
                                        if((dayDatas[t].timetables.pTimeCode && dayDatas[t].timetables.pTimeCode).toLowerCase() === 'laboratory'){
                                            return (
                                                <div
                                                    key={'timetable_' + t}
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        borderRadius: 6,
                                                        backgroundColor: 'rgba(245, 175, 4, 0.5)',
                                                    }}
                                                >
                                                    <span>{dayDatas[t].timetables.subjectCode}</span>
                                                    <span>{dayDatas[t].timetables.teacherFirstName}</span>
                                                    <span>{dayDatas[t].timetables.pTimeName}</span>
                                                    <span>{dayDatas[t].timetables.room}</span>
                                                    <span>{dayDatas[t].timetables.typeName}</span>
                                                </div>
                                            )
                                        } else if (dayDatas[t].timetables.pTimeCode && (dayDatas[t].timetables.pTimeCode).toLowerCase() === 'seminar') {
                                            return (
                                                <div
                                                    key={'timetable_' + t}
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        borderRadius: 6,
                                                        backgroundColor: 'rgba(54, 163, 247, 0.5)',
                                                    }}
                                                >
                                                    <span>{dayDatas[t].timetables.subjectCode}</span>
                                                    <span>{dayDatas[t].timetables.teacherFirstName}</span>
                                                    <span>{dayDatas[t].timetables.pTimeName}</span>
                                                    <span>{dayDatas[t].timetables.room}</span>
                                                    <span>{dayDatas[t].timetables.typeName}</span>
                                                </div>
                                            )
                                        } else {
                                            return (
                                                <div
                                                    key={'timetable_' + t}
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        borderRadius: 6,
                                                        backgroundColor: '#d8d8d8',
                                                    }}
                                                >
                                                    <span>{dayDatas[t].timetables.subjectCode}</span>
                                                    <span>{dayDatas[t].timetables.teacherFirstName}</span>
                                                    <span>{dayDatas[t].timetables.pTimeName}</span>
                                                    <span>{dayDatas[t].timetables.room}</span>
                                                    <span>{dayDatas[t].timetables.typeName}</span>
                                                </div>
                                            )
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        })
    };

    return (
        <div className='sm-container'>
            <Card>
                <Card.Body>
                    <div className='mb-4'>
                        <div className='col-12 justify-content-between ds-flex'>
                            {
                                season &&
                                <p className='french-blue'><b>{season.name}</b></p>
                            }
                            <button className='btn btn-purple' >{t('common.print')}</button>
                        </div>
                    </div>
                    {
                        shifts && shifts.length > 0 &&
                        shifts.map((shift, sIndex) => {
                            return (
                                <div className='col-12' key={'shift_' + sIndex}>
                                    <div className="mb-2" style={{fontWeight: 'bold', fontSize: 14}}>{shift.name}</div>
                                    <table className='table table-bordered'>
                                        <thead>
                                        <tr>
                                            <th>{t('subject.time')}</th>
                                            {
                                                days.map(day => {
                                                    return (
                                                        <th key={day.id}>
                                                            {day.day}
                                                        </th>
                                                    )
                                                })
                                            }
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            shift.timetables && shift.timetables.length > 0 && shift.timetables.map(period => {
                                                const time = `${period.startTime}-${period.endTime}`  + ' (' + period.timeName + ')';
                                                return (
                                                    <tr key={'teacher_tr_index_' + period.timeId}>
                                                        <td>{time}</td>
                                                        {
                                                            days.map(day => {
                                                                return (
                                                                    <td key={`${day.id}-${period.id}`}>
                                                                        {getCellTeachers(shift.timetables, day.id, period.timeId)}
                                                                    </td>
                                                                )
                                                            })
                                                        }
                                                    </tr>
                                                )
                                            })
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            )
                        })
                    }
                </Card.Body>
            </Card>
        </div>
    )
};
