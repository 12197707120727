import React, { useState, useEffect } from 'react';
import { Card, Tab, Tabs } from "react-bootstrap";
import TreeView from "../../modules/TreeView";
import { useTranslation } from "react-i18next";
import All from "./All";
import BySubject from "./BySubject";
import ByTeacher from "./ByTeacher";
import './timetable.scss';

const Timetable = () => {
    const { t } = useTranslation();

    const [ seasons, setSeasons ] = useState([]);
    const [ seasonId, setSeasonId ] = useState(null);
    const [ tabKey, setTabKey ] = useState('all');

    const [ treeIndex ] = useState('timetable_tree_index');
    const [ tabIndex ] = useState('timetable_tab_index');

    useEffect(() => {
        const selectedTab = localStorage.getItem(tabIndex);
        if(selectedTab){
            setTabKey(selectedTab);
        }
    }, []);

    const handleTreeClick = array => {
        if (array?.length) {
            const [ id ] = array;
            if(id.toString().split('_').length === 4){
                setSeasonId(id);
            }
            localStorage.setItem(treeIndex, id);
        }
    };

    const handleTabChange = key => {
        setTabKey(key);
        localStorage.setItem(tabIndex, key);
    };

    const renderTab = () => {
        const tabs = {
            all: <All
                seasonId={seasonId}
                seasons={seasons}
                setSeasonId={setSeasonId}
                setSeasons={setSeasons}
            />,
            subject: <BySubject
                seasonId={seasonId}
                setSeasonId={setSeasonId}
                setSeasons={setSeasons}
            />,
            teacher: <ByTeacher
                seasonId={seasonId}
                setSeasonId={setSeasonId}
                setSeasons={setSeasons}
            />
        };
        return tabs[tabKey];
    };

    return (
        <div className='row'>
            <div className='col-3 pr-0'>
                <Card>
                    <Card.Body>
                        <TreeView
                            defaultExpandAll={true}
                            treeData={seasons}
                            selectedNodes={[seasonId]}
                            onSelect={handleTreeClick}
                        />
                    </Card.Body>
                </Card>
            </div>
            <div className='col-9'>
                <Card>
                    <Card.Header>
                        <Tabs
                            id={'msue-timetable'}
                            activeKey={tabKey}
                            className='msue-tab'
                            onSelect={handleTabChange}
                        >
                            <Tab eventKey={'all'} title={t('common.all')}/>
                            <Tab eventKey={'subject'} title={t('timetable.bySubject')}/>
                            <Tab eventKey={'teacher'} title={t('timetable.byTeacher')}/>
                        </Tabs>
                    </Card.Header>
                    <Card.Body>
                        {renderTab()}
                    </Card.Body>
              </Card>
            </div>
        </div>
    );
};

export default Timetable;