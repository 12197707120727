import React,{useEffect,useState} from 'react'
import {Button, Modal} from "react-bootstrap";
import { fetchRequest } from '../../../../../utils/fetchRequest';
import { movementOutView } from '../../../../../utils/fetchRequest/Urls';
import { useDispatch,useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setLoading } from "../../../../../redux/action";
import message from '../../../../modules/message';

const ViewModal = ({
    onClose = () => {},
    movementId = null
}) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    const [movement,setMovement] = useState({})

    useEffect(()=>{
        if(movementId){
            const params = {
                schoolId,
                movementId
            }
            dispatch(setLoading(true));
            fetchRequest(movementOutView, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res;
                        if(data?.movement?.id){
                            setMovement(data?.movement)
                        }
                        dispatch(setLoading(false));
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                        dispatch(setLoading(false));
                    }
                })
                .catch(err => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        }
    },[movementId])

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('movement.out.view')}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <table className='info-table' style={{margin: 'auto'}}>
                    <tbody>
                        <tr>
                            <td>{t('student.code')}</td>
                            <th>{movement?.studentCode || '-'}</th>
                        </tr>
                        <tr>
                            <td>{t('common.lastName')}</td>
                            <th>{movement?.lastName || '-'}</th>
                        </tr>
                        <tr>
                            <td>{t('common.firstName')}</td>
                            <th>{movement?.firstName || '-'}</th>
                        </tr>
                        <tr>
                            <td>{t('movement.out.prevClass')}</td>
                            <th>{movement?.class || '-'}</th>
                        </tr>
                        <tr>
                            <td>{t('student.schoolEnrollmentDate')}</td>
                            <th>{movement?.startDate?.date ? movement.startDate.date.substring(0, 10) : '-'}</th>
                        </tr>
                        <tr>
                            <td>{t('movement.out.date')}</td>
                            <th>{movement?.outDate?.date ? movement.outDate.date.substring(0, 10) : '-'}</th>
                        </tr>
                        <tr>
                            <td>{t('movement.out.cause')}</td>
                            <th>{movement?.cause || '-'}</th>
                        </tr>
                        <tr>
                            <td>{t('common.description')}</td>
                            <th>{movement?.description || '-'}</th>
                        </tr>
                    </tbody>
                </table>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className='btn btn-link bolder'>{t('common.back')}</button>
            </Modal.Footer>
        </Modal>
    )
}

export default ViewModal