import React, {useEffect, useState} from 'react'
import {Row, Col, Card, Nav} from 'react-bootstrap'
import DTable from "../../../modules/DataTable/DTable";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {MainInformation} from "../anket/MainInformation";
import {FamilyInformation} from "../anket/FamilyInformation";
import {ExtendedFamilyInformation} from "../anket/ExtendedFamilyInformation";
import {EducationInformation} from "../anket/EducationInformation";
import {AwardInformation} from "../anket/AwardInformation";
import {HighSchoolInformation} from "../anket/HighSchoolInformation";
import {WorkExperienceInformation} from "../anket/WorkExperienceInformation";
import RenderTableList from "../../../modules/RenderTableList";
import {setLoading} from "../../../../redux/action";
import {fetchRequest} from "../../../../utils/fetchRequest";
import {studentTimetableIndex, studentTimetableProgress} from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
export const StudentCurrent = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [key, setKey] = useState('#timetable');
    const [types] = useState([
        {
            name: t('timetable.title'),
            code: 'timetable'
        },
        {
            name: t('timetable.current'),
            code: 'progress'
        }
    ]);
    const [navKey,setNavKey] = useState('main');
    const [days, setDays] = useState([]);
    const [timetables, setTimetables] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [subjectInfoArray, setSubjectInfoArray] = useState([]);
    const [tabIndex] = useState('student_timetable_tab_index');

    useEffect(() => {
        let params = {
            school: schoolId,
        };

        let url = '';
        let tabKey = localStorage.getItem(tabIndex);
        if(tabKey){
            if(tabKey == '#timetable'){
                url = studentTimetableIndex;
            } else if(tabKey == '#progress') {
                url = studentTimetableProgress;
            } else {
                url = studentTimetableIndex;
            }
            setKey(tabKey);
        } else {
            url = studentTimetableIndex;
        }

        init(params, url)
    }, []);

    const init = (params, url) => {
        dispatch(setLoading(true));
        fetchRequest(url, 'GET', params)
            .then(response => {
                dispatch(setLoading(false));
                if (response.success) {
                    const data = response.data;
                    if(url == 'student/timetable/index'){
                        setSubjects(data?.subjects);
                        setTimetables(data?.timetables);
                        setDays(data?.days);
                    } else if(url == 'student/timetable/progress') {
                        setSubjects(data?.subjects);
                    }
                } else {
                    message(response.data.message, false);
                }
            })
            .catch(() => {
                dispatch(setLoading(false));
            })
    };

    const config = {
        showPagination: false,
        showFilter: false,
        tableMarginLess: true
    };

    const column = [
        {
            dataField: 'name',
            text: t('subject.title'),
            sort: false,
        },
        {
            dataField: 'teacherName',
            text: t('subject.teacher'),
            sort: false,
        },
        {
            dataField: 'microsoft',
            text: t('subject.microsoft'),
            sort: false,
            headerStyle: () => {
                return {
                    width: 150
                }
            },
            formatter(cell) {
                if (cell) {
                    return (
                        <div className='text-center'>
                            <button className='btn btn-link btn-padding-less underline-button'>{t('common.clickHere')}</button>
                        </div>
                    )
                }
                return ('')
            },
        },
        {
            dataField: 'lesson',
            text: t('subject.onlineLesson'),
            sort: false,
            headerStyle: () => {
                return {
                    width: 150
                }
            },
            formatter(cell) {
                if (cell) {
                    return (
                        <div className='text-center'>
                            <button className='btn btn-link btn-padding-less underline-button'>{t('common.clickHere')}</button>
                        </div>
                    )
                }
                return ('')
            },
        }
    ];

    const handleTab = (key, e) => {
        if(key){
            let params = {
                school: schoolId,
            };
            if(key == '#progress'){
                init(params, studentTimetableProgress)
            } else if (key == '#timetable'){
                init(params, studentTimetableIndex)
            }
            setKey(key);
            localStorage.setItem(tabIndex, key);
        }
    };

    const onNavChange  = key =>{
        let subjectArray = [];
        let selectedSubject = null;
        if(key){
            if(subjects && subjects.length > 0){
                for(let i = 0; i < subjects.length; i++){
                    if(key == subjects[i].id){
                        selectedSubject = subjects[i];
                    }
                }
            }
            setNavKey(key);

            subjectArray.push(
                {
                    code: 'subjectCode',
                    name: t('subject.code'),
                    value: selectedSubject.subjectCode
                },
                {
                    code: 'subjectName',
                    name: t('subject.name'),
                    value: selectedSubject.subject
                },
                {
                    code: 'credit',
                    name: t('subject.credit'),
                    value: selectedSubject.credit
                },
                {
                    code: 'enrollmentDate',
                    name: t('school.season.studied'),
                    value: '-'
                },
                {
                    code: 'teacherCode',
                    name: t('teacher.code'),
                    value: selectedSubject.teacherCode
                },
                {
                    code: 'teacherName',
                    name: t('teacher.name'),
                    value: selectedSubject.teacher
                },
                {
                    code: 'profession',
                    name: t('assessment.gpa'),
                    value: '-'
                },
                {
                    code: 'profession',
                    name: t('assessment.title'),
                    value: '-'
                }
            );
        }

        setSubjectInfoArray(subjectArray);
    };

    const renderNavs = () =>{
        return (
            <>
                <div className='col-12' style={{paddingTop: '12.5px', paddingBottom: '12.5px'}}>
                    <Card>
                        <Card.Body>
                            <RenderTableList
                                stringArray={subjectInfoArray}
                            />
                        </Card.Body>
                    </Card>
                </div>
                <div className='col-12'>
                    <Card>
                        <Card.Body>
                            <table className='table table-bordered'>
                                <thead>
                                    <tr>
                                        <th>{t('common.specification')}</th>
                                        <th>{t('assessment.score')}</th>
                                        <th>{t('assessment.takenScore')}</th>
                                    </tr>
                                </thead>
                            </table>
                        </Card.Body>
                    </Card>
                </div>
            </>
        )
    };

    const getCellTeachers = (day, period) => {
        return timetables.map(data => {
            if(data){
                if(data.timeId === period){
                    if(data.timetables && data.timetables.length > 0){
                        let timetableDatas = data.timetables;

                        for (let i = 0; i < timetableDatas.length; i++){
                            if(timetableDatas[i].dayId === day){
                                if(timetableDatas[i].dayTimetables && timetableDatas[i].dayTimetables.length > 0){
                                    let dayDatas = timetableDatas[i].dayTimetables;
                                    for (let t = 0; t < dayDatas.length; t++){
                                        if((dayDatas[t].timetables.pTimeCode && dayDatas[t].timetables.pTimeCode).toLowerCase() === 'laboratory'){
                                            return (
                                                <div
                                                    key={'timetable_' + t}
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        borderRadius: 6,
                                                        backgroundColor: 'rgba(245, 175, 4, 0.5)',
                                                    }}
                                                >
                                                    <span>{dayDatas[t].timetables.subjectCode}</span>
                                                    <span>{dayDatas[t].timetables.teacherFirstName}</span>
                                                    <span>{dayDatas[t].timetables.pTimeName}</span>
                                                    <span>{dayDatas[t].timetables.room}</span>
                                                    <span>{dayDatas[t].timetables.typeName}</span>
                                                </div>
                                            )
                                        } else if (dayDatas[t].timetables.pTimeCode && (dayDatas[t].timetables.pTimeCode).toLowerCase() === 'seminar') {
                                            return (
                                                <div
                                                    key={'timetable_' + t}
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        borderRadius: 6,
                                                        backgroundColor: 'rgba(54, 163, 247, 0.5)',
                                                    }}
                                                >
                                                    <span>{dayDatas[t].timetables.subjectCode}</span>
                                                    <span>{dayDatas[t].timetables.teacherFirstName}</span>
                                                    <span>{dayDatas[t].timetables.pTimeName}</span>
                                                    <span>{dayDatas[t].timetables.room}</span>
                                                    <span>{dayDatas[t].timetables.typeName}</span>
                                                </div>
                                            )
                                        } else {
                                            return (
                                                <div
                                                    key={'timetable_' + t}
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        borderRadius: 6,
                                                        backgroundColor: '#d8d8d8',
                                                    }}
                                                >
                                                    <span>{dayDatas[t].timetables.subjectCode}</span>
                                                    <span>{dayDatas[t].timetables.teacherFirstName}</span>
                                                    <span>{dayDatas[t].timetables.pTimeName}</span>
                                                    <span>{dayDatas[t].timetables.room}</span>
                                                    <span>{dayDatas[t].timetables.typeName}</span>
                                                </div>
                                            )
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        })
    };

    const renderTab = () => {
        if(key == '#timetable'){
            return(
                <>
                    <Card>
                        <Card.Body>
                            <table className='table table-bordered'>
                                <thead>
                                <tr>
                                    <th>{t('subject.time')}</th>
                                    {
                                        days.map(day => {
                                            return (
                                                <th key={day.id}>
                                                    {day.day}
                                                </th>
                                            )
                                        })
                                    }
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    timetables.map(period => {
                                        const time = `${period.startTime}-${period.endTime}`  + ' (' + period.timeName + ')';
                                        return (
                                            <tr key={'teacher_tr_index_' + period.timeId}>
                                                <td>{time}</td>
                                                {
                                                    days.map(day => {
                                                        return (
                                                            <td key={`${day.id}-${period.timeId}`}>
                                                                {getCellTeachers(day.id, period.timeId)}
                                                            </td>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        </Card.Body>
                    </Card>
                    <Card className="mt-5">
                        <Card.Body>
                            {
                                subjects && subjects.length > 0 &&
                                subjects.map((subject, index) => (
                                    <div
                                        key={'subject_' + index}
                                    >
                                        <p className='french-blue' style={{marginBottom: '0.5rem'}}><b>{subject.subjectCode + ' ' + subject.subjectName}</b></p>
                                        <div className='mb-4'>
                                            <DTable
                                                columns={column}
                                                config={config}
                                                data={subject.pTimes}
                                            />
                                        </div>
                                    </div>
                                ))
                            }

                        </Card.Body>
                    </Card>
                </>
            )
        } else if(key == '#progress'){
            return(
                <div className='row'>
                    <div className='col-3 pr-0'>
                        {
                            subjects && subjects.map((subject, index) => (
                                <div
                                    key={'subject_index_' + index}
                                    className='student-anket-navigation'
                                >
                                    <Nav
                                        className="flex-column"
                                        navbar='m-menu__link-text'
                                        activeKey={navKey}
                                        onSelect={onNavChange}
                                    >
                                        <Nav.Link eventKey={subject.id}>{subject.subjectCode + ' ' + subject.subject}</Nav.Link>
                                    </Nav>
                                </div>
                            ))
                        }
                    </div>
                    <div className='col-9 pl-0' style={{padding:'0', border: '1px solid #4a70ae'}}>
                        {renderNavs()}
                    </div>
                </div>
            )
        }
    };

    return (
        <div className='sm-container'>
            <Card className='card-header-with-tab'>
                <Card.Header>
                    {
                        types && types.length > 0
                            ?
                            <Nav
                                variant="tabs"
                                activeKey={key}
                                onSelect={(key, e) => handleTab(key, e)}
                            >
                                {
                                    types.map(type => {
                                        return (
                                            <Nav.Item key={'type_' + type.code}>
                                                <Nav.Link href={`#${type.code}`}>{type.name}</Nav.Link>
                                            </Nav.Item>
                                        )
                                    })
                                }
                            </Nav>
                            : null
                    }
                </Card.Header>
                <Card.Body>
                    {renderTab()}
                </Card.Body>
            </Card>
        </div>
    )
};
