import React, {useEffect, useState} from 'react';
import Select from "../../modules/Form/Select";
import { useTranslation } from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {setLoading} from "../../../redux/action";
import {fetchRequest} from "../../../utils/fetchRequest";
import {timetableInit} from "../../../utils/fetchRequest/Urls";
import message from "../../modules/message";

const ByTeacher = ({
    seasonId,
    setSeasonId,
    setSeasons,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [ shiftId, setShiftId ] = useState([]);
    const [ teacherId, setTeacherId ] = useState([]);
    const [ timetables, setTimetables ] = useState([]);
    const [ teachers, setTeachers ] = useState([]);
    const [ shifts, setShifts ] = useState([]);
    const [ days, setDays ] = useState([]);
    const [ times, setTimes ] = useState([]);

    const [ treeIndex ] = useState('timetable_tree_index');
    const [ shiftIndex ] = useState('timetable_teacher_shift_index');
    const [ teacherIndex ] = useState('timetable_teacher_index');
    const [ tabIndex ] = useState('timetable_tab_index');
    const [ allShiftIndex ] = useState('timetable_shift_index');

    useEffect(() => {
        const selectedTreeId = localStorage.getItem(treeIndex);
        let selectedShiftId = localStorage.getItem(shiftIndex);
        const selectedTeacherId = localStorage.getItem(teacherIndex);
        const selectedTab = localStorage.getItem(tabIndex);

        if(selectedTreeId){
            setSeasonId(selectedTreeId);
        }

        if(selectedShiftId){
            setShiftId(parseInt(selectedShiftId));
        } else {
            let selectedAllShiftId = localStorage.getItem(allShiftIndex);

            if(selectedAllShiftId){
                selectedShiftId = selectedAllShiftId;
                localStorage.setItem(shiftIndex, selectedAllShiftId);
            }

            setShiftId(parseInt(selectedAllShiftId));
        }

        if(selectedTeacherId){
            setTeacherId(parseInt(selectedTeacherId));
        }

        if(selectedTab === 'teacher'){
            init(selectedTreeId, selectedShiftId, selectedTeacherId)
        }
    }, [seasonId]);

    const init = (season, shift, teacher) => {
        const params = {
            school: schoolId,
            type: 'teacher',
            season: season,
            shift: shift,
            teacher: teacher,
        };

        dispatch(setLoading(true));
        fetchRequest(timetableInit, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setSeasons(data.seasons);
                    setDays(data.days || []);
                    const schoolShifts = [];
                    if (data?.schoolShifts?.length) {
                        for (const schoolShift of data.schoolShifts) {
                            schoolShifts.push({
                                ...schoolShift,
                            })
                        }
                    }
                    if(data.selectedShiftId){
                        setShiftId(data.selectedShiftId)
                    }
                    setShifts(schoolShifts);
                    setTeachers(data?.teachers || []);
                    setTimes(data?.times || []);
                    setTimetables(data?.timetables || [])
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const handleTeacherChange = value => {
        setTeacherId(value);
        init(seasonId, shiftId, value);
        localStorage.setItem(teacherIndex, value);
    };

    const onSelect = id => {
        setShiftId(id);
        init(seasonId, id, teacherId);
        localStorage.setItem(shiftIndex, id);
    };

    const getCellTeachers = (day, period) => {
        return timetables.map(data => {
            if(data){
                if(data.timeId === period){
                    if(data.timetables && data.timetables.length > 0){
                        let timetableDatas = data.timetables;
                        for (let i = 0; i < timetableDatas.length; i++){
                            if(timetableDatas[i].dayId === day){
                                let timetableObj = [];
                                if(timetableDatas[i].dayTimetables && timetableDatas[i].dayTimetables.length > 0){
                                    let dayDatas = timetableDatas[i].dayTimetables;
                                    for (let t = 0; t < dayDatas.length; t++){
                                        let timetableList = dayDatas[t].timetables;

                                        for(let j = 0; j < timetableList.length; j++){
                                            if(timetableList[j].pTimeCode.toLowerCase() === 'laboratory'){
                                                timetableObj.push(
                                                    <div
                                                        key={'timetable_' + i + '_day_' + t + '_list_' + j}
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            borderRadius: 6,
                                                            backgroundColor: 'rgba(245, 175, 4, 0.5)',
                                                            marginBottom: 5
                                                        }}
                                                    >
                                                        <span>{timetableList[j].subjectCode}</span>
                                                        <span>{timetableList[j].teacherFirstName}</span>
                                                        <span>{timetableList[j].pTimeName}</span>
                                                        <span>{timetableList[j].room}</span>
                                                        {
                                                            timetableList[j].isSelective
                                                            ?
                                                            null
                                                            : 
                                                            <span>{timetableList[j].groupName}</span>
                                                        }
                                                        <span>{timetableList[j].typeName}</span>
                                                    </div>
                                                )
                                            } else if (timetableList[j].pTimeCode.toLowerCase() === 'seminar') {
                                                timetableObj.push(
                                                    <div
                                                        key={'timetable_' + i + '_day_' + t + '_list_' + j}
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            borderRadius: 6,
                                                            backgroundColor: 'rgba(54, 163, 247, 0.5)',
                                                            marginBottom: 5
                                                        }}
                                                    >
                                                        <span>{timetableList[j].subjectCode}</span>
                                                        <span>{timetableList[j].teacherFirstName}</span>
                                                        <span>{timetableList[j].pTimeName}</span>
                                                        <span>{timetableList[j].room}</span>
                                                        {
                                                            timetableList[j].isSelective
                                                            ?
                                                            null
                                                            : 
                                                            <span>{timetableList[j].groupName}</span>
                                                        }
                                                        <span>{timetableList[j].typeName}</span>
                                                    </div>
                                                )
                                            } else {
                                                timetableObj.push(
                                                    <div
                                                        key={'timetable_' + i + '_day_' + t + '_list_' + j}
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            borderRadius: 6,
                                                            backgroundColor: '#d8d8d8',
                                                            marginBottom: 5
                                                        }}
                                                    >
                                                        <span>{timetableList[j].subjectCode}</span>
                                                        <span>{timetableList[j].teacherFirstName}</span>
                                                        <span>{timetableList[j].pTimeName}</span>
                                                        <span>{timetableList[j].room}</span>
                                                        {
                                                            timetableList[j].isSelective
                                                            ?
                                                            null
                                                            : 
                                                            <span>{timetableList[j].groupName}</span>
                                                        }
                                                        <span>{timetableList[j].typeName}</span>
                                                    </div>
                                                )
                                            }
                                        }
                                    }
                                }
                                return timetableObj
                            }
                        }
                    }
                }
            }
        })
    };

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ flex: 4, textAlign: 'right', paddingRight: '1.5rem' }}>{t('school.shift.title')}</span>
                <div style={{ flex: 8 }}>
                    <div style={{ width: 200 }}>
                        <Select
                            options={shifts}
                            value={shiftId ? parseInt(shiftId) : null}
                            onChange={onSelect}
                            searchable
                        />
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '1rem', marginBottom: '1rem' }}>
                <span style={{ flex: 4, textAlign: 'right', paddingRight: '1.5rem' }}>{t('teacher.title')}</span>
                <div style={{ flex: 8}}>
                    <div style={{ width: 200 }}>
                        <Select
                            searchable
                            options={teachers}
                            value={teacherId}
                            onChange={handleTeacherChange}
                        />
                    </div>
                </div>
            </div>
            <table className='table table-bordered'>
                <thead>
                <tr>
                    <th>{t('subject.time')}</th>
                    {
                        days.map(day => {
                            return (
                                <th key={day.id}>
                                    {day.day}
                                </th>
                            )
                        })
                    }
                </tr>
                </thead>
                <tbody>
                {
                    times.map(period => {
                        const time = `${period.startTime}-${period.endTime}`  + ' (' + period.name + ')';
                        return (
                            <tr key={'teacher_tr_index_' + period.id}>
                                <td>{time}</td>
                                {
                                    days.map(day => {
                                        return (
                                            <td key={`${day.id}-${period.id}`}>
                                                {getCellTeachers(day.id, period.id)}
                                            </td>
                                        )
                                    })
                                }
                            </tr>
                        )
                    })
                }
                </tbody>
            </table>
        </div>
    );
};

export default ByTeacher;