import React,{ useState,useRef,useEffect } from 'react'
import { Modal, Card } from 'react-bootstrap'
import Forms from '../../../modules/Form/Forms'
import {useTranslation} from "react-i18next";
import Select from "../../../modules/Form/Select";
import {setLoading} from "../../../../redux/action";
import {fetchRequest} from "../../../../utils/fetchRequest";
import {lmsStudentSearch, lmsTranscriptCreate} from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import {useDispatch, useSelector} from "react-redux";
import RenderUserInfo from "../../../modules/RenderUserInfo";

export const CreateStudentDescription = ({
    close,
    onSubmit
}) => {
    const ref = useRef();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    const [amount, setAmount] = useState(null);
    const [days, setDays] = useState(null);
    const [value, setValue] = useState('');
    const [types, setTypes] = useState([]);
    const [typeId, setTypeId] = useState([]);
    const [studentId, setStudentId] = useState(null);
    const [students, setStudents] = useState([]);
    const [labels, setLabels] = useState([]);

    const init = (params) => {
        dispatch(setLoading(true));
        fetchRequest(lmsTranscriptCreate, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    let studentInfo = data?.student;
                    let labelArray = [];
                    labelArray.push(
                        {
                            code: 'studentCode',
                            name: t('student.code'),
                            value: studentInfo?.studentCode || ''
                        },
                        {
                            code: 'lastName',
                            name: t('student.lastName'),
                            value: studentInfo?.lastName || ''
                        },
                        {
                            code: 'firstName',
                            name: t('student.name'),
                            value: studentInfo?.firstName || ''
                        },
                        {
                            code: 'createdDate',
                            name: t('student.enrollmentDate'),
                            value: studentInfo?.startDate && studentInfo?.startDate.date && studentInfo?.startDate.date.substring(0, 10) || ''
                        },
                        {
                            code: 'programName',
                            name: t('curriculum.profession'),
                            value: studentInfo?.programName || ''
                        }
                    );
                    setLabels(labelArray);
                    setTypes(data?.types || []);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            });
    };

    useEffect(() => {
        ref?.current?.updateFields && ref.current?.updateFields(fields);
    }, [types]);

    const onTypeChange = (id, evt) => {
        if (id) {
            setTypeId(id);
            setAmount(evt.price);
            setDays(evt.validDay);
        }
    };

    const fields = [
        {
            key: 'type',
            label: t('description.type'),
            value: '',
            type: 'dropdown',
            options: types,
            onChange: onTypeChange,
            required: true,
            clearable: true
        },
        {
            key: 'to',
            label: t('description.whereFor'),
            value: '',
            type: 'textArea',
            rows: 3,
            required: true,
        },
    ];

    const onStudentInputChange = (inputValue, option) => {
        if(inputValue){
            setValue(inputValue);
        }
    };

    const onStudentChange = (value) => {
        if(value){
            let params = {
                school: schoolId,
                student: value,
            };

            init(params);
        }
        setStudentId(value);
    };

    useEffect(() => {
        let timeout;
        if (value) {
            timeout = setTimeout(() => {
                const params = {
                    school: schoolId,
                    code: value,
                    select: 1
                };
                dispatch(setLoading(true));
                fetchRequest(lmsStudentSearch, 'GET', params)
                    .then(res => {
                        if (res.success) {
                            const { data } = res;
                            setStudents(data?.students || []);
                        } else {
                            message(res?.data?.message || t('errorMessage.title'))
                        }
                        dispatch(setLoading(false));
                    })
                    .catch(() => {
                        dispatch(setLoading(false));
                        message(t('errorMessage.title'))
                    })
            }, 1000);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [value]);

    const onSave = () =>{
        const [isValid, states, values] = ref.current.validate();
        if(isValid){
            const params = {
                school: schoolId,
                student: studentId,
                ...values
            };

            onSubmit(params);
        }
    };

    return (
        <Modal
            show={true}
            onHide={close}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('description.title').toUpperCase()}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Card>
                    <Card.Body>
                        <div className='col-12' style={{display: 'flex', marginTop: 0.8}}>
                            <span className='col-4' style={{ textAlign: 'right'}}>
                                <div style={{ position: "relative", top: 8}}>
                                    {t('student.title')}
                                </div>
                            </span>
                            <div className='col-6'>
                                <Select
                                    className={'default-input-border'}
                                    value={studentId}
                                    searchable
                                    onChange={onStudentChange}
                                    onInputChange={onStudentInputChange}
                                    options={students}
                                />
                            </div>
                            <div className='col-2'/>
                        </div>
                    </Card.Body>
                </Card>
                {
                    labels && labels.length > 0 &&
                    <div className='row mt-4 mb-2'>
                        <div className='col-3'/>
                        <div className='col-9'>
                            <RenderUserInfo
                                labels={labels}
                            />
                        </div>
                    </div>
                }
                <Forms
                    fields={fields}
                    ref={ref}
                />
                {
                    amount && days &&
                    <div className='row mt-5'>
                        <div className='col-12 text-center'>
                            <b>{t('description.fee') + ': '}</b> {amount + '₮'}
                                <p><b>{t('description.validDays') + ': '}</b> {days}</p>
                        </div>
                    </div>
                }
            </Modal.Body>
            <Modal.Footer>
                <button className='btn btn-link' onClick={close}>{t('common.back')}</button>
                <button className='btn btn-success' onClick={onSave}>{t('description.create')}</button>
            </Modal.Footer>
        </Modal>
    )
};
