import React, {useEffect, useState} from 'react'
import {Button, Card} from 'react-bootstrap'
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import DTable from "../../../modules/DataTable/DTable";
import {setLoading} from "../../../../redux/action";
import {fetchRequest} from "../../../../utils/fetchRequest";
import {invoiceCreate, invoiceInit} from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";

export const Ebarimt = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    const [activeButton, setActiveButton] = useState('pay');
    const [invoices, setInvoice] = useState([]);

    const parentSeason = useSelector(state => {
            let schoolList = state?.school?.schoolList;
            if(schoolList && schoolList.length > 0){
                for (let i = 0; i < schoolList.length; i++){
                    if(schoolId == schoolList[i]['id']){
                        return schoolList[i]['parentSeason'];
                    }
                }
            }
        }
    );

    useEffect(() => {
        const params = {
            school: schoolId,
            season: parentSeason,
            paid: activeButton == 'pay' ? 0 : 1
        };
        init(params)
    }, []);

    const init = (params) => {
        dispatch(setLoading(true));
        fetchRequest(invoiceInit, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setInvoice(data.invoices)
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const config = {
        showPagination: true,
        showFilter: true,
        footer: true,
        footerStyle: {backgroundColor: '#ebecf5'}
    };

    const columns = [
        {
            dataField: "status",
            text: t('ebarimt.status'),
            footer: '',
        },
        {
            dataField: "paymentDate",
            text: t('ebarimt.paymentDate'),
            footer: '',
        },
        {
            dataField: "ebarimtDate",
            text: t('ebarimt.date'),
            footer: '',
        },
        {
            dataField: "studentCode",
            text: t('student.code'),
            sort: true,
            footer: ''
        },
        {
            dataField: "lastName",
            text: t('student.lastName'),
            sort: true,
            footer: '',
        },
        {
            dataField: "firstName",
            text: t('student.name'),
            sort: true,
            footer: '',
        },
        {
            dataField: "type",
            text: t('invoiceType.paymentType'),
            sort: true,
            footer: '',
        },
        {
            dataField: "amount",
            text: t('common.amount'),
            style: {textAlign: 'right'},
            sort: true,
            footerAlign: 'right',
            footer: '',
            footerType: 'sum'
        },
    ];

    const contextMenus = [
        {
            key: 'connect',
            title: t('common.sendAgian'),
            icon: <i className='fas fa-exchange-alt dt-cm-item-icon-20'/>,
        },
    ];

    const handleContextMenuClick = (id, key) => {
        if (id && key) {
            if(key === 'connect'){
                const bodyParams = {
                    school: schoolId,
                    season: parentSeason,
                };

                dispatch(setLoading(true));
                fetchRequest(invoiceCreate, 'POST', bodyParams)
                    .then(res => {
                        if (res.success) {
                            const { data } = res;
                            message(data?.message || t('common.success'), true);
                            const params = {
                                school: schoolId,
                                season: parentSeason,
                            };
                            init(params);
                        } else {
                            message(res?.data?.message || t('errorMessage.title'));
                            dispatch(setLoading(false));
                        }
                    })
                    .catch(() => {
                        dispatch(setLoading(false));
                        message(t('errorMessage.title'))
                    })
            }
        }
    };

    return (
        <div className='sm-container'>
            <Card>
                <Card.Body>
                    <div className='row'>
                        <div className='col-12'>
                            <DTable
                                columns={columns}
                                config={config}
                                data={invoices}
                                contextMenus={contextMenus}
                                onContextMenuItemClick={handleContextMenuClick}
                                selectMode={'radio'}
                            />
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </div>
    )
};
