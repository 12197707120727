import React,{useState,useEffect} from 'react'
import { useSelector,useDispatch } from 'react-redux'
import { Card,Row,Col,Button,Image } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link,useHistory } from 'react-router-dom'
import ReactPlayer from 'react-player/lazy'
import { studentOnlineExamAnswer, studentOnlineExamStart, studentOnlineExamFinish } from '../../../../utils/fetchRequest/Urls'
import { fetchRequest } from '../../../../utils/fetchRequest'
import message from '../../../modules/message'
import { setLoading } from '../../../../redux/action'
import Checkbox from '../../../modules/Form/Checkbox'
import { TextareaAutosize } from "@material-ui/core"
import FinishConfirm from './modals/FinishConfirm'
import { htmlDecode } from '../../../../utils/utils'
import { ROOT_URL } from '../../../../utils/fetchRequest'
import { Radio as MUIRadio } from "@material-ui/core";
import styles from './../../exam/question/types/questionTypes.module.scss';
import clsx from "clsx";

const OnlineExamStart = ({
    location: { state: {
        exam = 1
    } = {} } = {}, }) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { t } = useTranslation()

    const school = useSelector(state => state?.selectedSchool?.id || null);

    const [examObj,setExamObj] = useState({})
    const [tabKey,setTabKey] = useState('init')
    const [countdown,setCountdown] = useState(1)
    const [finishDate, setFinishDate] = useState(null)
    const [question,setQuestion] = useState({})
    const [questionList,setQuestionList] = useState([])
    const [finishModal,setFinishModal] = useState(false)
    const [unansweredQuestions,setUnansweredQuestions] = useState([])
    const [value, setValue] = useState('')
    const [examDtl, setExamDtl] = useState({})
    

    const types = [
        {
            value: 'true',
            label: 'Үнэн',
        },
        {
            value: 'false',
            label: 'Худал',
        },
    ]

    const infoTableRows = [
        {
            key: 'subject',
            text: t('common.subject')
        },
        {
            key: 'teacherFirstName',
            text: t('teacher.title')
        },
        {
            key: 'activeDuration',
            text: t('onlineExam.activeDuration')
        },
        {
            key: "testDuration",
            text: t('onlineExam.testDuration')
        },
        {
            key: "questionNumber",
            text: t('onlineExam.testQuestionNumber')
        },
        {
            key: "totalScore",
            text: t('assessment.totalScore')
        },
        {
            key: "description",
            text: t('common.description')
        },
    ]

    const getTimer = () =>{
        if(countdown < 0){
            return '00 : 00'
        }else{
            const minutes = Math.floor((countdown % (1000 * 60 * 60)) / (1000 * 60)) 
            const seconds = Math.floor((countdown % (1000 * 60)) / 1000)

            if(Math.floor(seconds / 10) === 0 && Math.floor(minutes / 10) === 0){
                return '0' + minutes + ' : ' + '0' + seconds
            }else if (Math.floor(seconds / 10) === 0){
                return minutes + ' : ' + '0' + seconds
            }else if (Math.floor(minutes / 10) === 0){
                return '0' + minutes + ' : ' +  seconds
            }else{
                return minutes + ' : ' +  seconds
            }
        }
    }

    const startExam = () =>{
        if(exam){
            const params = {
                exam,
                school,
                action: 'questions'
            }
            dispatch(setLoading(true));
            fetchRequest(studentOnlineExamStart, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res
    
                        if(data?.exam){
                            setExamObj(data?.exam)
                        }
                        if(data?.questions?.length){
                            setQuestionList(data?.questions || [])
                            setQuestion(data.questions[0])
                        }
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        }
    }
    const onAnswer = (answer, second = false) =>{
        let tempData = [...questionList]
        if(tempData?.length){
            tempData.map(td=>{
                if(td.id === question.id){
                    if(!answer){
                        td.isAnswered = false
                    }else if(typeof answer === 'object' && !answer.length){
                        td.isAnswered = false
                    }else{
                        td.isAnswered = true
                    }
                    if(second){
                        td.answer2 = answer
                    } else{
                        td.answer = answer
                    }
                }
            })
        }
        setQuestionList(tempData)
    }

    const onSelectOne = (id,checked) =>{
        let tempData = {...question}
        if(tempData.answers?.length && checked){
            tempData.answers.map(td=>{
                if(id === td.id){
                    td.isChecked = checked
                }else{
                    td.isChecked = false
                }
            })
        }
        if(tempData.type != 'TRUEFALSE'){
            tempData.answer = id
        }
        setQuestion(tempData)
        onAnswer(id)
    }

    const onMultiSelect = (id,checked) =>{
        let tempData = {...question}
        if(!tempData.answer?.length){
            tempData.answer = []
        }
        if(tempData.answers?.length){
            tempData.answers.map(td=>{
                if(id === td.id){
                    td.isChecked = checked
                }
            })
        }
        if(checked){
            tempData.answer.push(id)
            onAnswer(tempData.answer)
        }else{
            const answer = tempData.answer.filter(a=>a != id)
            tempData.answer = answer
            onAnswer(answer)
        }
        setQuestion(tempData)
    }

    const sendAnswer = (q) => {
        const params = {
            school,
            exam,
            question: q.id,
            isAnswered: q.isAnswered,
            type: q.type,
            answer: q.type == 'CONNECT' || q.type == 'ORDER' ? JSON.stringify(q.answers) : q.answer,
            answer2: q.answer2 ? q.answer2 : null,
            answerId: q.type == 'TRUEFALSE' || q.type == 'NUMBERINPUT' ? q.answers[0].id : 0
        }
        dispatch(setLoading(true));
        fetchRequest(studentOnlineExamAnswer, 'POST', params)
            .then(res => {
                if (res.success) {
                    
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }
    
    const onNextQuestion = () =>{ 
        sendAnswer(question);
        const tempData = questionList.find(q=>q.ordering === (question.ordering + 1))
        if(tempData){
            setQuestion(tempData)
        }        
    }

    const onPrevQuestion = () =>{
        const tempData = questionList.find(q=>q.ordering === (question.ordering - 1))
        if(tempData){
            setQuestion(tempData)
        }
    }

    const openConfirmation = () =>{
        const tempData = questionList.filter(q=>!q.isAnswered)
        setUnansweredQuestions(tempData)
        setFinishModal(true)
    }

    const onConnArrowClick = (id,direction) =>{
        const tempQuestion = {...question}
        if(tempQuestion.answers?.length){
            const idx = tempQuestion.answers.findIndex(a=>a.id === id)
            if(idx === 0 || idx){
                const tempValue = tempQuestion.answers[idx].response
                let movement = 1
                if(direction === 'UP'){
                    movement = -1
                }
                if(tempQuestion.answers[idx+movement]){
                    tempQuestion.answers[idx].response = tempQuestion.answers[idx+movement].response
                    tempQuestion.answers[idx+movement].response = tempValue
                }else{
                    if(direction === 'UP'){
                        tempQuestion.answers[idx].response = tempQuestion.answers.at(-1).response
                        tempQuestion.answers.at(-1).response = tempValue
                    }else{
                        tempQuestion.answers[idx].response = tempQuestion.answers[0].response
                        tempQuestion.answers[0].response = tempValue
                    }
                }
            }
        }
        onAnswer(tempQuestion.answers)
        setQuestion(tempQuestion)
    }

    const onOrderArrowClick = (id,direction) =>{
        let tempQuestion = {...question}
        if(tempQuestion.answers?.length){
            const idx = tempQuestion.answers.findIndex(a=>a.id === id)
            if(idx === 0 || idx){
                const tempAnswer = tempQuestion.answers[idx]
                let movement = 1
                if(direction === 'UP'){
                    movement = -1
                }
                if(tempQuestion.answers[idx+movement]){
                    tempQuestion.answers[idx] = tempQuestion.answers[idx+movement]
                    tempQuestion.answers[idx+movement] = tempAnswer
                }else{
                    if(direction === 'UP'){
                        tempQuestion.answers[idx] = tempQuestion.answers.at(-1)
                        tempQuestion.answers[tempQuestion.answers.length - 1] = tempAnswer
                    }else{
                        tempQuestion.answers[idx] = tempQuestion.answers[0]
                        tempQuestion.answers[0] = tempAnswer
                    }
                }
            }
        }
        onAnswer(tempQuestion.answers)
        setQuestion(tempQuestion)
    }

    

    const renderAnswers = answer =>{
        if(question.type === 'MULTICHOICE'){
            if(answer.filePath){
                return (
                <div className='p-4'>
                    <Image
                        src={ROOT_URL+ '/' + answer.filePath}
                        height={'160px'}
                        onClick={() => onMultiSelect(answer.id,!question.answer?.includes(answer.id))}
                        style={
                            question.answer?.length && question.answer.includes(answer.id) 
                            ? 
                                {opacity:'1',cursor:'pointer'}
                            : {opacity:'.5',cursor:'pointer'}
                        }
                    />
                    <Checkbox
                        label={answer.answer}
                        className='ml-10'
                        onChange={() => onMultiSelect(answer.id, !answer.isChecked)}
                        checked={!!answer.isChecked}
                    />                    
                </div>)
            }else{
                return (
                    <Checkbox
                        label={answer.answer}
                        className='ml-10'
                        onChange={() => onMultiSelect(answer.id, !answer.isChecked)}
                        checked={!!answer.isChecked}
                    />
                )
            }
        } else if(question.type === 'TRUEFALSE'){
            return (
                <div>
                    {
                        types.map(type => {
                            return (
                                <div
                                    key={type.value}
                                    className={styles.rowContainer}
                                >
                                    <MUIRadio
                                        checked={answer.answer === type.value}
                                        onChange={() => {
                                            setValue(type.value); 
                                            answer.answer = type.value; 
                                            question.answer = type.value;
                                            onSelectOne(answer.id,answer.id !== question.answer)
                                        }}
                                    />
                                    <div className={clsx('form-control', styles.readOnly)}>
                                        {type.label}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>                
            )
        }
        else if(question.type === 'RADIO'){
            if(answer.filePath){
                return (
                <div className='p-4'>
                    <Image
                        src={ROOT_URL +'/'+ answer.filePath}
                        height={'160px'}
                        onClick={() => onSelectOne(answer.id,answer.id !== question.answer)}
                        style={
                            answer.isChecked
                            ? 
                                {opacity:'1',cursor:'pointer'}
                            : {opacity:'.2',cursor:'pointer'}
                        }
                    />
                    <Checkbox
                        label={answer.answer}
                        className='ml-10'
                        onChange={() => onSelectOne(answer.id, !answer.isChecked)}
                        checked={!!answer.isChecked}
                    />                    
                </div>
                )
            }else{
                return (
                    <Checkbox
                        label={answer.answer}
                        className='ml-10'
                        onChange={() => onSelectOne(answer.id, !answer.isChecked)}
                        checked={!!answer.isChecked}
                    />
                )
            }
            
        }
        else if(question.type === 'TEXTINPUT'){
            return (
                <TextareaAutosize
                    className='msue-textarea-description'
                    value={question.answer}
                    onChange={e => {
                        onAnswer(e.target.value);
                    }}
                    rows={5}
                />
            )
        }else if(question.type === 'NUMBERINPUT'){
            return (
                <div className='align-items-center w-100 my-2'>
                    <input
                        className='msue-textarea-description'
                        value={question.answer || ''}
                        type='number'
                        onChange={e => {
                            onAnswer(e.target.value, false);
                        }}
                        style={{width: '160px'}}
                    />
                    {
                        question.answer1 ? 
                            <input
                                className='msue-textarea-description ml-2'
                                value={question.answer2 || ''}
                                type='number'
                                onChange={e => {
                                    onAnswer(e.target.value, true);
                                }}
                                style={{width: '160px'}}
                            />
                            : null
                    }
                </div>
            )
        }else if(question.type === 'CONNECT'){
            return (
                <Row className='align-items-center'>
                    <Col md={3} className='text-center'>
                        <span style={{fontSize: '16px'}}>
                            <b>{answer.premise}</b>
                        </span>
                    </Col>
                    <Col md={3} className='text-center'>
                        <i className='flaticon2-arrow-up' style={{color: '#299f7233'}} onClick={()=>onConnArrowClick(answer.id,'UP')}/>
                            <span className='mx-2' style={{fontSize: '16px'}}>
                                {answer.response[1] || '-'}
                            </span>
                        <i className='flaticon2-arrow-down' style={{color: '#f64e6033'}} onClick={()=>onConnArrowClick(answer.id,'DOWN')}/>
                    </Col>
                </Row>
            )
        }else if(question.type === 'ORDER'){
            return (
                <Row className='align-items-center'>
                    <Col md={3} className='text-center'>
                        <i className='flaticon2-arrow-up' style={{color: '#299f7233'}} onClick={()=>onOrderArrowClick(answer.id,'UP')}/>
                            <span className='mx-2' style={{fontSize: '16px'}}>
                                {answer.answer || '-'}
                            </span>
                        <i className='flaticon2-arrow-down' style={{color: '#f64e6033'}} onClick={()=>onOrderArrowClick(answer.id,'DOWN')}/>
                    </Col>
                </Row>
            )
        }else{
            return null
        }
    }

    const renderFile = (url) =>{
        if(typeof url == 'string'){
            if(/\.(jpg|jpeg|png|gif)$/.test(url)){
                return( 
                    <Image
                        src={ROOT_URL+ '/' +url}
                        height={'160px'}
                    />
                )
            }else{
                return (
                    <ReactPlayer
                        width={'320px'}
                        height={'160px'}
                        url={ROOT_URL+ '/' +url}
                    />
                )
            }
        }else{
            return null
        }
    }

    const renderExamInit = () =>{
        return (
            <Row>
                <Col md={4}></Col>
                <Col md={6}>
                    <table className='info-table'>
                        <tbody>
                            {
                                infoTableRows.map(itr=>{
                                    return (
                                        <tr key={itr.key}>
                                            <td>{itr.text}</td>
                                            <th>{examObj[itr.key] || ' - '}</th>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                    <div className='w-100'>
                        <span style={{fontSize:'14px'}}>
                            <b>{t('onlineExam.testInstruction') + ':'}</b>
                        </span>
                    </div>
                    {
                        examObj.instruction
                        ?
                            <div className='w-100'>
                                <ReactPlayer
                                    width={'400px'}
                                    height={'260px'}
                                    url={examObj.instruction}
                                />
                            </div>
                        : null
                    }
                </Col>
            </Row>
        )
    }

    const renderExamStart = () =>{
        return(
            <Row>
                <Col md={9}>
                    <Card>
                        <Card.Body>
                            <div className='w-100' style={{fontSize:'16px', color:'rgb(41, 86, 159)', fontWeight:'bold'}}>
                                <div dangerouslySetInnerHTML={{ __html: question.question }}></div>
                            </div>
                            <div className='w-100'>
                                <span style={{fontSize:'14px'}}>
                                    {t('onlineExam.questionScore') + ': '} <b>{question.score}</b>
                                </span>
                            </div>
                            <div className='w-100 mt-4'>
                                <span style={{fontSize:'14px'}}>
                                    {question.description}
                                </span>
                            </div>
                            <div className='w-100 mt-5' style={{maxHeight:'160px'}}>
                                {renderFile(question.path)}
                            </div>
                            {
                                question.type === 'TEXT' || question.type === 'NUMBER'
                                ?
                                    <div className='w-100 my-4'>
                                        {renderAnswers()}
                                    </div>
                                : 
                                    (question.answers?.length
                                    ?
                                        question.answers.map(a=>{
                                            return (
                                                <div 
                                                    key={a.id}
                                                    className='w-100 my-2'
                                                >
                                                    {renderAnswers(a)}
                                                </div>
                                            )
                                        })
                                    : null)
                            }
                        </Card.Body>
                    </Card>
                    <Row className='my-3'>
                        <Col md={6}>
                            <Button className="btn btn-outline-success" onClick={onPrevQuestion}>{'<< ' + t('onlineExam.before')}</Button>
                        </Col>
                        <Col md={6} className='text-right'>
                            <Button className="btn btn-outline-success" onClick={onNextQuestion}>{t('onlineExam.next') + ' >>'}</Button>
                        </Col>
                    </Row>
                </Col>
                <Col md={3}>
                    <Card>
                        <Card.Header>
                            <span style={{ color: '#4a70ae',fontSize:'14px'}}>
                                <b>{t('onlineExam.questions').toLocaleUpperCase()}</b>
                            </span>
                        </Card.Header>
                        <Card.Body style={{maxHeight: '450px',overflow:'auto'}}>
                            {
                                questionList.length
                                ?
                                    questionList.map((q,index)=>{
                                        const bgStyle = {
                                            boxShadow: '0 2px 10px 0 #014812',
                                            border: 'solid 1px #fff',
                                            borderRadius: '10px'
                                        }
                                        return (
                                            <div 
                                                key={q.id + ' - ' + index}
                                                style={q.id === question.id ? bgStyle : null}
                                                className='m-2 p-3 align-items-center'
                                            >
                                                <span style={{ color: '#4a70ae',fontSize:'14px'}} className='pr-2'>
                                                    <b>{q.order}</b>
                                                </span>
                                                <button 
                                                        style={q.isAnswered ? checkIsActive : checkInactive}
                                                        onClick={()=>{setQuestion(q); sendAnswer(question);}}
                                                        >
                                                    <i className='flaticon2-check-mark' style={{color:'#fff'}}/>
                                                </button>
                                            </div>
                                        )
                                    })
                                : null
                            }
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        )
    }

    const onFinish = () =>{
        const params = {
            school,
            exam,
            examDtl: examObj.detailId
        }
        sendAnswer(question);
        dispatch(setLoading(true));
        fetchRequest(studentOnlineExamFinish, 'POST', params)
            .then(res => {
                if (res.success) {
                    let dtlId = 0;
                    if(res?.data.examDtl){
                        dtlId = res.data.examDtl;
                    }
                    history.push({
                        pathname: '/student/online-exam/result',
                        state: {
                            school,
                            exam,
                            examDtl: dtlId
                        }
                    })
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }

    useEffect(()=>{
        if(exam){
            const params = {
                exam,
                school,
            }
            dispatch(setLoading(true));
            fetchRequest(studentOnlineExamStart, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res
    
                        if(data?.exam){
                            setExamObj(data?.exam)
                        }
                        
                        //Go to Questions
                        if(!data?.exam.isFirst){
                            startExam();
                            setTabKey('start');
                            //setFinishDate(new Date().getTime() + data.exam.testDuration*60000)
                            setFinishDate(new Date(data?.exam.endDate).getTime());                  
                        }

                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        }
    },[exam])

    useEffect(() => {
        if(tabKey === 'start'){
            if(countdown < 0){
                onFinish()
            }else{
            const interval = setInterval(() => {
                setCountdown(finishDate - new Date().getTime())
            }, 1000)

            return () => clearInterval(interval)
            }
        }
    }, [countdown,tabKey, finishDate])

    return (
        <Card>
            <Card.Header>
                <Row className='align-items-center'>
                    <Col>
                        <span style={{ color: '#29569f',fontSize:'14px'}}>
                            <b>{examObj.name}</b>
                        </span>
                    </Col>
                    <Col className='text-right'>
                        {
                            tabKey === 'init'
                            ?
                                <Link
                                    to='/student/online-exam'
                                    className='btn btn-link bolder'
                                >
                                    {t('common.back')}
                                </Link>
                            :
                                <span style={{color: '#d84434',fontSize: '16px'}}>
                                    {t('onlineExam.timeRemain') + ' ' + getTimer()}
                                </span>
                        }
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                    {
                        tabKey === 'init'
                        ?
                            renderExamInit()
                        : renderExamStart()
                    }
            </Card.Body>
            <Card.Footer>
                {
                    tabKey === 'init'
                    ?
                        <Row className='align-items-center'>
                            <Col className='text-right'>
                                <Link
                                    to='/student/online-exam'
                                    className='btn btn-link bolder'
                                >
                                    {t('common.back')}
                                </Link>
                            </Col>
                            <Col>
                                <Button style={btnStyle} disabled={!examObj?.name} onClick={()=>{
                                        startExam();
                                        setTabKey('start')
                                        setFinishDate(new Date().getTime() + examObj.testDuration*60000)
                                        
                                    }}>
                                    {t('onlineExam.startTest').toLocaleUpperCase()}
                                </Button>
                            </Col>
                        </Row>
                    :
                        <div className='text-center'>
                            <Button 
                            style={btnStyle} 
                            onClick={openConfirmation}>
                                {t('onlineExam.finishTest').toLocaleUpperCase()}
                            </Button>
                        </div>
                }
            </Card.Footer>
            {
                finishModal
                ?
                    <FinishConfirm
                        onClose={()=>{
                            setFinishModal(false)
                            setUnansweredQuestions([])
                        }}
                        questions={unansweredQuestions}
                        onConfirm={onFinish}
                    />
                : null
            }
        </Card>
    )
}

const btnStyle = {
    backgroundColor: '#014812',
    border: 'none',
    borderRadius: '6px',
    color: '#ffffff',
    boxShadow: '0 2px 10px 0 #014812'
}

const checkInactive = {
    width: '30px',
    height: '30px',
    borderRadius: '15px',
    opacity: '0.2',
    backgroundColor: '#299f72',
    color: '#ffffff',
    boxShadow: '0 5px 15px 0 #299f7233',
    border: 'none'
}

const checkIsActive = {
    width: '30px',
    height: '30px',
    borderRadius: '15px',
    backgroundColor: '#299f72',
    color: '#ffffff',
    boxShadow: '0 5px 15px 0 #299f7233',
    border: 'none'
}

export default OnlineExamStart