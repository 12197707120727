import React, {useEffect, useRef, useState} from 'react';
import { Button, ButtonToolbar, Card, Modal, Tab, Tabs } from 'react-bootstrap';
import TreeView from "../../../modules/TreeView";
import { useTranslation } from "react-i18next";
import DTable from "../../../modules/DataTable/DTable";
import { LinearProgress } from "@material-ui/core";
import Select from "../../../modules/Form/Select";
import './selection.scss';
import { useHistory } from "react-router-dom";
import {setLoading} from "../../../../redux/action";
import {fetchRequest} from "../../../../utils/fetchRequest";
import {
    selectionFindStudents,
    selectionGetStudentData,
    selectionGetSubjectData,
    selectionInit,
    selectionStudentAdd,
    selectionStudentIndex
} from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import {useDispatch, useSelector} from "react-redux";
import Forms from "../../../modules/Form/Forms";

const One = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const [ seasons, setSeasons ] = useState([]);
    const [ classes, setClasses ] = useState([]);
    const [ subjects, setSubjects ] = useState([]);
    const [ subjectId, setSubjectId ] = useState([]);
    const [ subjectData, setSubjectData ] = useState([]);
    const [ studentData, setStudentData ] = useState([]);
    const [ totalStudentCount, setTotalStudentCount ] = useState(0);
    const [ selectedStudentCount, setSelectedStudentCount ] = useState(0);
    const [ selectionData, setSelectionData ] = useState([]);
    const [ studentSelectionData, setStudentSelectionData ] = useState([]);
    const [ students, setStudents ] = useState([]);
    const [ studentId, setStudentId ] = useState(null);
    const [ selectedTreeId, setSelectedTreeId ] = useState(null);
    const [ tabKey, setTabKey ] = useState('class');
    const [ totalCount, setTotalCount ] = useState(0);
    const [ value, setValue ] = useState('');
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const dispatch = useDispatch();
    const formRef = useRef();

    const [ treeIndex ] = useState('selection_one_tree_index');
    const [ tabIndex ] = useState('selection_one_tab_index');

    const [ programDetails, setProgramDetails ] = useState([]);
    const [ addModal, setAddModal ] = useState(false);
    const [ deleteModal, setDeleteModal ] = useState(false);
    const [ configId, setSelectedConfigId ] = useState(null);
    const [ selectedIds, setSelectedIds ] = useState([]);
    const [ showEditModal, setShowEditModal ] = useState(false);
    const [ selection1s, setSelection1s ] = useState([]);
    const [updateView, setUpdateView] = useState(false);

    const classesConfig = {
        showPagination: true,
        showFilter: true,
        defaultPageOptions: {
            page: 1,
            sizePerPage: 10,
            search: '',
        },
    };

    const config = {
        showPagination: true,
        showFilter: true
    };

    const editConfig = {
        showAllData: true,
        showPagination: false,
        showFilter: true,
    };

    const studentConfig = {
        showPagination: false,
        showFilter: false,
        showAllData: true
    };

    useEffect(() => {
        const treeId = localStorage.getItem(treeIndex);
        const tab = localStorage.getItem(tabIndex);

        if(tab){
            setTabKey(tab)
        }

        if(treeId){
            setSelectedTreeId(treeId);

            const params = {
                school: schoolId,
                season: treeId,
                selection: 1,
                type: tab,
                pageSize: 10,
                page: 1
            };
            fetchData(params);
        } else {
            const params = {
                school: schoolId,
                selection: 1,
                type: tab,
                pageSize: 10,
                page: 1
            };
            fetchData(params);
        }
    }, []);

    useEffect(() => {
        formRef?.current?.updateFields && formRef.current.updateFields(fields);
    }, [ subjects ]);

    const fetchData = params => {
        dispatch(setLoading(true));
        fetchRequest(selectionInit, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    let classes = data.classes;
                    if(classes && classes.length > 0){
                        for (let i = 0; i < classes.length; i++){
                            classes[i]['teacherFirstName'] = classes[i]['teacherCode'] + ' ' + classes[i]['teacherFirstName']
                        }
                    }
                    setClasses(classes);
                    setSeasons(data.seasons);
                    setSubjects(data.subjects);
                    setTotalCount(data?.totalCount ? data.totalCount : 0);

                    let selectedStudentCount = 0;
                    
                    if(data.classes && data.classes.length > 0){
                        for(let i = 0; i < data.classes.length; i++){
                            selectedStudentCount += data.classes[i].totalSelectionCount;
                        }
                    }

                    setTotalStudentCount(data.totalStudentCount);
                    setSelectedStudentCount(selectedStudentCount);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const fetchSubjectData = params => {
        dispatch(setLoading(true));
        fetchRequest(selectionGetSubjectData, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setSubjectData(data.subjectData);
                    setTotalCount(data?.totalCount ? data.totalCount : 0);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const fetchStudentData = params => {
        dispatch(setLoading(true));
        fetchRequest(selectionGetStudentData, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setStudentData(data.studentData);
                    setStudentSelectionData(data.selectionData);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const byClassColumns = [
        {
            dataField: "parentSeasonName",
            text: t('Элссэн жил'),
            sort: true
        },
        {
            dataField: "academicProgramName",
            text: t('curriculum.title'),
            sort: true
        },
        {
            dataField: "programName",
            text: t('curriculum.profession'),
            sort: true
        },
        {
            dataField: "teacherFirstName",
            text: t('movement.class.consultantTeacher'),
            sort: true
        },
        {
            dataField: "studentsCount",
            text: t('movement.class.studentCount'),
            sort: true,
            align: 'right',
        },
        {
            dataField: "totalSelectionCount",
            text: t('selection.selected'),
            sort: true,
            align: 'right',
        },
        // {
        //     dataField: "totalPaidCount",
        //     text: t('selection.paid'),
        //     sort: true,
        //     align: 'right',
        // },
    ];

    const bySubjectColumns = [
        {
            dataField: "code",
            text: t('student.code'),
            sort: true
        },
        {
            dataField: "lastName",
            text: t('person.lastName'),
            sort: true
        },
        {
            dataField: "firstName",
            text: t('person.firstName'),
        },
        {
            dataField: "academicProgramName",
            text: t('curriculum.title'),
        },
        {
            dataField: "programName",
            text: t('curriculum.profession'),
        },
    ];

    const byStudentColumns = [
        {
            dataField: "programName",
            text: t('curriculum.profession'),
            sort: true
        },
        {
            dataField: "code",
            text: t('subject.code'),
            sort: true
        },
        {
            dataField: "name",
            text: t('subject.name'),
            sort: true
        },
        {
            dataField: "credit",
            text: t('subject.credit'),
        },
        {
            dataField: "price",
            text: t('assessment.tuition'),
        },
    ];

    const columns = [
        // {
        //     dataField: "status",
        //     text: t('common.status'),
        //     sort: true,
        // },
        {
            dataField: "code",
            text: t('subject.index'),
            sort: false,
        },
        {
            dataField: "name",
            text: t('subject.name'),
            sort: false,
        },
        {
            dataField: "credit",
            text: t('subject.credit'),
            sort: false,
        },
        {
            dataField: "before",
            text: t('subject.previous'),
            sort: false,
        },
        {
            dataField: "qwe",
            text: t('subject.time'),
            sort: false,
        },
        {
            dataField: "price",
            text: t('assessment.tuition'),
            sort: false,
        },
        {
            dataField: "status",
            text: t('common.status'),
            sort: false,
            style: {padding: 3},
            headerStyle: () => {
                return {
                    width: 80
                }
            },
            formatter(cell, row) {
                if(row.status && row.success){
                    return <div style={{padding: 7}}>{row.status}</div>
                }

                return ''
            },
        },
        {
            dataField: "scoreType",
            text: t('assessment.title'),
            sort: false,
            style: {padding: 3},
            headerStyle: () => {
                return {
                    width: 80
                }
            },
            formatter(cell, row) {
                if(row.scoreType && row.isShow){
                    return <div style={{display: 'flex'}}>
                        <div style={{padding: 7}}><b>{row.scoreType}</b></div>
                        <button style={{width: '100%'}} className='btn btn-success' onClick={() => handlerHideButton(row)}>
                            {t('action.back')}
                        </button>
                    </div>
                } else if(row.scoreType){
                    return <button style={{width: '100%'}} className='btn btn-success' onClick={() => handlerViewButton(row)}>
                        {t('action.view')}
                    </button>;
                }

                return ''
            },
        },
    ];

    const Attribute = (cell, row, rowIndex, colIndex) => {
    };

    const studentIndexColumnsTest = [
        // {
        //     dataField: "status",
        //     text: t('common.status'),
        //     sort: true,
        // },
        {
            dataField: "code",
            text: t('subject.index'),
            sort: false,
        },
        {
            dataField: "name",
            text: t('subject.name'),
            sort: false,
        },
        {
            dataField: "credit",
            text: t('subject.credit'),
            sort: false,
        },
        {
            dataField: "before",
            text: t('subject.previous'),
            sort: false,
        },
        {
            dataField: "qwe",
            text: t('subject.time'),
            sort: false,
        },
        {
            dataField: "price",
            text: t('assessment.tuition'),
            sort: false,
            align: 'right',
        },
        {
            isDummyField: true,
            dataField: "action_12",
            text: "",
            sort: false,
            attrs: Attribute, //formatter ашиглаж байгаа бол attrs заавал дуудах ёстой.
            // formatter: iconFormatter,
            formatter: (cell, row, rowIndex, extraData) => {
                return (
                    <Button onClick={() => handlerIcon('delete', cell, row, rowIndex, extraData)} className="btn btn-danger m-btn m-btn--icon m-btn--icon-only m-btn--circle-28">
                        <i className="mini flaticon2-cross"> </i>
                    </Button>
                )
            }
        }
    ]

    const handlerIcon = (action, content, row, rowIndex) => {
        let cloneSelectionData = [...selection1s];
        let credits = 0;
        let price = 0;
        let count = 0;

        if(cloneSelectionData && cloneSelectionData.length > 0){
            for(let i = 0; i < cloneSelectionData.length; i++){
                Object.keys(cloneSelectionData[i].dtls).map((category, ci) => { 
                    Object.keys(cloneSelectionData[i].dtls[category]).map((type, ti) => {
                        for(let typeList of cloneSelectionData[i].dtls[category][type]){
                            if(typeList.id == row.id){
                                cloneSelectionData[i].dtls[category][type].splice(rowIndex, 1);
                            }
                        }
                    })
                })
            }
        }

        if(cloneSelectionData && cloneSelectionData.length > 0){
            for(let i = 0; i < cloneSelectionData.length; i++){
                Object.keys(cloneSelectionData[i].dtls).map((category, ci) => { 
                    Object.keys(cloneSelectionData[i].dtls[category]).map((type, ti) => {
                        for(let typeList of cloneSelectionData[i].dtls[category][type]){
                            credits = credits + parseInt(typeList.credit);
                            price = price + parseInt(typeList.totalPrice);
                            count += 1;
                        }
                    })
                })

                cloneSelectionData[i]['totalCredit'] = credits;
                cloneSelectionData[i]['totalCreditPrice'] = price;
                cloneSelectionData[i]['totalCount'] = count;
            }
        }

        setSelection1s(cloneSelectionData);
        let isUpdateView = updateView;
        setUpdateView(!isUpdateView);
    };

    const iconFormatter = (cell, row, rowIndex, extraData) => {
        return <Button onClick={() => handlerIcon('delete', cell, row, rowIndex, extraData)} className="btn btn-danger m-btn m-btn--icon m-btn--icon-only m-btn--circle-28">
                <i className="mini flaticon2-cross"> </i>
            </Button>
    };

    const handlerViewButton = (row) => {
        let cloneProgramDtl = [...programDetails];

        for(let i = 0; i < cloneProgramDtl.length; i++){
            for(let d = 0; d < cloneProgramDtl[i].details.length; d++){
                for(let l = 0; l < cloneProgramDtl[i].details[d].list.length; l++){
                    for(let t = 0; t < cloneProgramDtl[i].details[d].list[l].typeList.length; t++){
                        for(let s = 0; s < cloneProgramDtl[i].details[d].list[l].typeList[t].subjectList.length; s++){
                            if(row.id == cloneProgramDtl[i].details[d].list[l].typeList[t].subjectList[s].id){
                                cloneProgramDtl[i].details[d].list[l].typeList[t].subjectList[s].isShow = true;
                            }
                        }
                    }
                }
            }
        }

        setProgramDetails(cloneProgramDtl)
    }

    const handlerHideButton = (row) => {
        let cloneProgramDtl = [...programDetails];

        for(let i = 0; i < cloneProgramDtl.length; i++){
            for(let d = 0; d < cloneProgramDtl[i].details.length; d++){
                for(let l = 0; l < cloneProgramDtl[i].details[d].list.length; l++){
                    for(let t = 0; t < cloneProgramDtl[i].details[d].list[l].typeList.length; t++){
                        for(let s = 0; s < cloneProgramDtl[i].details[d].list[l].typeList[t].subjectList.length; s++){
                            if(row.id == cloneProgramDtl[i].details[d].list[l].typeList[t].subjectList[s].id){
                                cloneProgramDtl[i].details[d].list[l].typeList[t].subjectList[s].isShow = false;
                            }
                        }
                    }
                }
            }
        }

        setProgramDetails(cloneProgramDtl)
    }

    const onSelect = (row, isSelect, rowIndex) => {
        let ids = [ ...selectedIds ];
        if (row.id)
        {
            if (isSelect)
            {
                ids.push(row.id)
            } else {
                for (let i = 0; i < ids.length; i++)
                {
                    if (parseInt(ids[i]) === parseInt(row.id))
                    {
                        ids.splice(i, 1);
                    }
                }
            }
        }

        setSelectedIds(ids);
    };

    const onSelectAll = (isSelect, rows) => {
        let ids = [ ...selectedIds ];
        if (isSelect) {
            for (let i = 0; i < rows.length; i++) {
                ids.push(rows[i].id);
            }
        } else {
            for (let i = 0; i < rows.length; i++) {
                for (let s = 0; s < ids.length; s++) {
                    if (parseInt(ids[s]) === parseInt(rows[i].id)) {
                        ids.splice(s, 1);
                    }
                }
            }
        }

        setSelectedIds(ids);
    };

    const contextMenus = [
        {
            key: 'view',
            title: t('common.view'),
            icon: <i className='las la-search dt-cm-item-icon'/>
        }
    ];

    const onSubjectChange = (id, evt) => {
        const params = {
            school: schoolId,
            season: selectedTreeId,
            selection: 1,
            subject: id
        };
        setSubjectId(id);
        fetchSubjectData(params);
    };

    const fields = [
        {
            label: t('subject.title') + '*',
            value: null,
            type: 'dropdown',
            searchable: true,
            inputWidth: 200,
            required: true,
            options: subjects,
            key: 'type',
            onChange: onSubjectChange,
        }
    ];

    const handleTreeClick = array => {
        if (array?.length) {
            const [ id ] = array;
            const split = id.toString().split('_');
            if (split.length === 4) {
                localStorage.setItem(treeIndex, id);
                setSelectedTreeId(id);

                const params = {
                    school: schoolId,
                    season: id,
                    selection: 1,
                    type: tabKey
                };
                fetchData(params);
            }
        }
    };

    const handleTabChange = key => {
        setTabKey(key);
        setSubjectData([]);
        setClasses([]);
        setStudentData([]);

        localStorage.setItem(tabIndex, key);

        const params = {
            school: schoolId,
            season: selectedTreeId,
            selection: 1,
            type: key
        };
        fetchData(params);
    };

    const handleContextMenuClick = (id, key) => {
        if (id && key) {
            if(key == 'view'){
                let classObj = classes.find(classList => classList.id == id)

                if(classObj){
                    history.push({
                        pathname: '/selection/1/view',
                        state: {
                            season: selectedTreeId,
                            classId: classObj.classId
                        }
                    })
                }
            }
        }   
    };

    const onUserInteraction = (object) => {
        if(tabKey === 'class'){
            if(selectedTreeId && tabKey){
                let params = {
                    school: schoolId,
                    season: selectedTreeId,
                    selection: 1,
                    type: tabKey,
                    ...object,
                };

                fetchData(params)
            }
        } else if (tabKey === 'subject'){
            if(selectedTreeId && subjectId){
                const params = {
                    school: schoolId,
                    season: selectedTreeId,
                    selection: 1,
                    subject: subjectId,
                    ...object,
                };

                fetchSubjectData(params);
            }
        } else if (tabKey === 'student'){
            const params = {
                school: schoolId,
                season: selectedTreeId,
                selection: 1,
                student: studentId,
                ...object,
            };
            fetchStudentData(params);
        }
    };

    const onStudentChange = (value) => {
        setStudentId(value);
        const params = {
            school: schoolId,
            season: selectedTreeId,
            selection: 1,
            student: value
        };
        fetchStudentData(params)
    };

    useEffect(() => {
        let timeout;
            if (value) {
                timeout = setTimeout(() => {
                    if(selectedTreeId){
                        const params = {
                            school: schoolId,
                            season: selectedTreeId,
                            selection: 1,
                            text: value
                        };
                        dispatch(setLoading(true));
                        fetchRequest(selectionFindStudents, 'GET', params)
                            .then(res => {
                                if (res.success) {
                                    const { data } = res;
                                    setStudents(data.students);
                                } else {
                                    message(res?.data?.message || t('errorMessage.title'))
                                }
                                dispatch(setLoading(false));
                            })
                            .catch(() => {
                                dispatch(setLoading(false));
                                message(t('errorMessage.title'))
                            })
                    } else {
                        message(t('errorMessage.selectSeason'))
                    }
                }, 1000);
            }
        return () => {
            clearTimeout(timeout);
        };
    }, [value]);

    const onStudentInputChange = (inputValue, actionMeta) => {
        if(inputValue){
            setValue(inputValue);
        }
    };

    const onEditStudentSelection = () => {
        let params = {
            selection: 1,
            school: schoolId,
            student: studentId
        }

        dispatch(setLoading(true));
        fetchRequest(selectionStudentIndex, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    let selection1Data = data.selection1s;
                    setSelectionData(data.selectionData);

                    if(selection1Data && selection1Data.length > 0){
                        if(data.selectionData && data.selectionData.length > 0){
                            let details = data.selectionData;
                            for (let i = 0; i < selection1Data.length; i++){
                                for(let d = 0; d < details.length; d++){
                                    if(selection1Data[i].seasonId == details[d].seasonId){
                                        selection1Data[i].selectionData = details[d];

                                        const groupByCategory = details[d].subjects.reduce((group, product) => {
                                            const { categoryName } = product;
                                
                                            group[categoryName] = group[categoryName] ?? [];
                                            group[categoryName].push(product);
                                
                                            return group;
                                        }, {});
                                
                                        for (var categories in groupByCategory){
                                            const typeNameList = groupByCategory[categories].reduce((group, product) => {
                                                const { typeName } = product;
                                    
                                                group[typeName] = group[typeName] ?? [];
                                                group[typeName].push(product);
                                    
                                                return group;
                                            }, {});
                                
                                            groupByCategory[categories] = typeNameList;
                                        }

                                        selection1Data[i].dtls = groupByCategory;
                                        selection1Data[i].totalCount = details[d].totalSubjectCount;
                                        selection1Data[i].totalCredit = details[d].totalCredit;
                                        selection1Data[i].totalCreditPrice = details[d].totalCreditPrice;
                                    }
                                }
                            }
                        }

                        setSelection1s(selection1Data)
                        setShowEditModal(true)
                    }
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }

    const onEditClose = () => {
        setShowEditModal(false)
    }

    const handlerSubjectAddClick = (configId) => {
        let dtls = [];
        setProgramDetails([]);

        if(selection1s && selection1s.length > 0){
            for (let i = 0; i < selection1s.length; i++){
                if(configId == selection1s[i].id){
                    if(selection1s[i].dtls){
                        Object.keys(selection1s[i].dtls).map((category, ci) => {
                            Object.keys(selection1s[i].dtls[category]).map((type, ti) => {
                                for(let typeList of selection1s[i].dtls[category][type]){
                                    dtls.push(typeList.id)
                                }
                            })
                        })
                    }
                }
            }
        }

        const params = {
            school: schoolId,
            config: configId,
            dtls: JSON.stringify(dtls),
            student: studentId
        };

        dispatch(setLoading(true));
        fetchRequest(selectionStudentAdd, 'GET', params)
            .then(response => {
                if (response.success) {
                    setProgramDetails(response.data.programDetails);
                    setAddModal(true);
                    setSelectedConfigId(configId)
                } else {
                    message(response.data.message, false);
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
            })
    };

    const onSaveClick = (publish = 0, configId) => {
        let selectedDtls = [];
        let studentTotalCredit = 0;
        if(configId){
            if(selection1s && selection1s.length > 0){
                for(let i = 0; i < selection1s.length; i++){
                    if(configId == selection1s[i].id){
                        if(selection1s[i].dtls){
                            Object.keys(selection1s[i].dtls).map((category, ci) => {
                                Object.keys(selection1s[i].dtls[category]).map((type, ti) => {
                                    for(let typeList of selection1s[i].dtls[category][type]){
                                        studentTotalCredit = studentTotalCredit + parseInt(typeList.credit);
                                        selectedDtls.push(typeList.id)
                                    }
                                })
                            })
                        }
                    }
                }
            }
        }

        const params = {
            school: schoolId,
            config: configId,
            ids: JSON.stringify(selectedDtls),
            approved: publish,
            student: studentId
        };

        dispatch(setLoading(true));
        fetchRequest(selectionStudentAdd, 'POST', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    let cloneSelection1s = [...selection1s];

                    if(cloneSelection1s && cloneSelection1s.length > 0) {
                        for (let i = 0; i < cloneSelection1s.length; i++) {
                            if (data?.selectionData?.seasonId == cloneSelection1s[i].seasonId) {
                                cloneSelection1s[i].selectionData = data.selectionData;
                            }
                        }
                    }
                    // setSelection1s(cloneSelection1s);
                    let params = {
                        school: schoolId,
                        // class: classId,
                        season: selectedTreeId
                    }
            
                    fetchData(params);
                    setShowEditModal(false)
                    fetchStudentData({
                        school: schoolId,
                        season: selectedTreeId,
                        selection: 1,
                        student: studentId
                    })
                    message(res?.data?.message, true)
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            })
    };

    const handleAddModalClose = () => {
        setAddModal(false);
        setSelectedIds([]);
    };

    const handleDeleteModalClose = () => {
        setDeleteModal(false);
    };

    const handlerOnDeleteClick = () => {
        // const params = {
        //     school: selectedSchool.id,
        //     teacher: selectedRecord ? selectedRecord.id : null,
        // }

        // fetchRequest(teacherDelete, 'POST', params, false)
        //     .then(response => {
        //         if (response.success) {
        //             setDeleteModal(false);
        //             setTeachers(response.data.teachers);
        //             message(response.data.message, true);
        //         } else {
        //             message(response.data.message, false);
        //         }
        //     })
        //     .catch(err => {
        //     })
    };

    const renderTab = () => {
        if (tabKey === 'class') {
            return (
                <div>
                    <div className='row mr-1'>
                        <div className='col-6 mb-3'>
                            <Card>
                                <Card.Body>
                                    <div style={{ fontWeight: 700, marginBottom: 6 }}>{`${t('common.total')}: ${totalStudentCount}`}</div>
                                    <LinearProgress variant='determinate' value={selectedStudentCount > 0 ? ((100 * selectedStudentCount)/totalStudentCount) : 0} className='msue-selection-progress' />
                                    <div style={{ display: 'flex', justifyContent: 'space-between', color: '#299f72', fontWeight: 700 }}>
                                        <span>{selectedStudentCount > 0 ? ((100 * selectedStudentCount)/totalStudentCount).toFixed(2) + '%' : 0}</span>
                                        <span>{selectedStudentCount}</span>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                    <DTable
                        config={classesConfig}
                        data={classes}
                        columns={byClassColumns}
                        selectMode={'radio'}
                        contextMenus={contextMenus}
                        onContextMenuItemClick={handleContextMenuClick}
                    />
                </div>
            )
        }
        else if (tabKey === 'subject') {
            return (
                <div>
                    <div className='row'>
                        <div className='col-7'>
                            <Forms
                                ref={formRef}
                                fields={fields}
                            />
                        </div>
                        <div className='col-5'/>
                    </div>
                    <DTable
                        config={config}
                        data={subjectData}
                        columns={bySubjectColumns}
                        showOrdering={true}
                        totalDataSize={totalCount}
                        remote
                        onInteraction={onUserInteraction}
                        selectMode={'radio'}
                    />
                </div>
            )
        } else if(tabKey === 'student') {
            return (
                <div>
                    <div className='row'>
                        <div className='col-4' style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            {t('student.title') + '*'}
                        </div>
                        <div className='col-6'>
                            <Select
                                value={studentId}
                                searchable
                                onChange={onStudentChange}
                                onInputChange={onStudentInputChange}
                                options={students}
                            />
                        </div>
                    </div>
                    <table className='student-selection-details'>
                        <tbody>
                        <tr>
                            <td>{t('selection.date')}</td>
                            <th>{studentSelectionData?.createdDate}</th>
                        </tr>
                        <tr>
                            <td>{t('total.credit')}</td>
                            <th>{studentSelectionData?.totalCredit}</th>
                        </tr>
                        <tr>
                            <td>{t('total.price')}</td>
                            <th>{studentSelectionData?.totalPrice}</th>
                        </tr>
                        {/*<tr>*/}
                        {/*    <td>{t('selection.paidDate')}</td>*/}
                        {/*    <th>{studentSelectionData?.paidDate}</th>*/}
                        {/*</tr>*/}
                        </tbody>
                    </table>
                    {
                        studentId &&
                        <button className='btn btn-primary' onClick={onEditStudentSelection}>
                            {t('common.edit')}
                        </button>
                    }
                    <DTable
                        config={studentConfig}
                        data={studentData}
                        columns={byStudentColumns}
                        // contextMenus={[
                        //     {
                        //         key: 'delete',
                        //         title: t('common.delete'),
                        //         icon: <i className='las la-trash-alt dt-cm-item-icon' />,
                        //     },
                        // ]}
                        // onContextMenuItemClick={handleContextMenuClick}
                        selectMode={'radio'}
                    />
                </div>
            );
        }
    };

    const handleAddModalSubmit = () => {
        const cloneSelection1s = [...selection1s];

        let dtlArray = [];
        if(cloneSelection1s && cloneSelection1s.length > 0){
            if(cloneSelection1s && cloneSelection1s.length > 0){
                for (let i = 0; i < cloneSelection1s.length; i++){
                    if(configId == cloneSelection1s[i].id){
                        if(cloneSelection1s[i].dtls){
                            Object.keys(cloneSelection1s[i].dtls).map((category, ci) => {
                                Object.keys(cloneSelection1s[i].dtls[category]).map((type, ti) => {
                                    for(let typeList of cloneSelection1s[i].dtls[category][type]){
                                        dtlArray.push(typeList)
                                    }
                                })
                            })
                        }
                    }
                }
            }
        }

        for(let i = 0; i < programDetails.length; i++){
            for(let d = 0; d < programDetails[i].details.length; d++){
                for(let l = 0; l < programDetails[i].details[d].list.length; l++){
                    for(let t = 0; t < programDetails[i].details[d].list[l].typeList.length; t++){
                        for(let s = 0; s < programDetails[i].details[d].list[l].typeList[t].subjectList.length; s++){
                            if(selectedIds.includes(programDetails[i].details[d].list[l].typeList[t].subjectList[s].id)){
                                dtlArray.push(programDetails[i].details[d].list[l].typeList[t].subjectList[s]);
                            }
                        }
                    }
                }
            }
        }

        let credits = 0;
        let price = 0;
        if(dtlArray && dtlArray.length)
        {
            for(let i = 0; i < dtlArray.length; i++)
            {
                credits += parseInt(dtlArray[i].credit);
                if(parseInt(dtlArray[i].totalPrice) > 0){
                    price += parseInt(dtlArray[i].totalPrice);
                }
            }
        }

        const groupByCategory = dtlArray.reduce((group, product) => {
            const { categoryName } = product;

            group[categoryName] = group[categoryName] ?? [];
            group[categoryName].push(product);

            return group;
        }, {});

        for (var categories in groupByCategory){
            const typeNameList = groupByCategory[categories].reduce((group, product) => {
                const { typeName } = product;
    
                group[typeName] = group[typeName] ?? [];
                group[typeName].push(product);
    
                return group;
            }, {});

            groupByCategory[categories] = typeNameList;
        }

        if(configId){
            for(let i = 0; i < selection1s.length; i++){
                if(configId == selection1s[i].id){
                    selection1s[i].dtls = groupByCategory;
                    selection1s[i].totalCount = dtlArray.length;
                    selection1s[i].totalCredit = credits;
                    selection1s[i].totalCreditPrice = price;
                }
            }
        }

        handleAddModalClose();
        setSelectedConfigId(null);
    };

    const renderDetails = (details, index) => {
        let row = [];
        if (details && details.length > 0) {
            details.map(detail => {
                let typeRow = [];
                if(detail['list'] && detail['list'].length > 0){
                    detail['list'].map(type => {
                        let subjectRow = [];
                        if(type['typeList'] && type['typeList'].length > 0){
                            type['typeList'].map(subject => {
                                let nonSelectableIds = [];
                                for(let s = 0; s < subject['subjectList'].length; s++){
                                    if(subject['subjectList'][s].disabled && !subject['subjectList'][s].status){
                                        nonSelectableIds.push(parseInt(subject['subjectList'][s].id))
                                        nonSelectableIds.push(String(subject['subjectList'][s].id))
                                    }
                                }

                                subjectRow.push(
                                    <div key={'subject_' + index + '_' + subject['subjectTypeId']} className='student-selection-area'>
                                        <div className='col-12 p-0 font-w-bold mt-2' style={{color: '#000'}}>
                                            {subject.subjectTypeName}
                                        </div>
                                        <DTable
                                            data={subject['subjectList']}
                                            columns={columns}
                                            config={editConfig}
                                            showOrdering={true}
                                            isSelect={true}
                                            onSelect={onSelect}
                                            onSelectAll={onSelectAll}
                                            nonSelectableIds={nonSelectableIds}
                                        />
                                    </div>
                                )
                            })
                        }
                        typeRow.push(
                            <div key={'type_' + index + '_' + type['typeId']}>
                                <div className='col-12 p-0 font-w-bold dark-blue'>
                                    &emsp;&emsp;&emsp;&emsp; {' - ' + type.typeName + ' - '}
                                    <hr/>
                                </div>
                                <div className='mt-2'>{subjectRow}</div>
                            </div>
                        )
                    })
                }

                row.push(
                    <div key={'detail_' + index + '_' + detail['categoryId']}>
                        <div className='col-12 p-0 font-w-bold dark-blue mt-1'>
                            <ol type="I" className="mb-0">
                                <li>{detail.categoryName.toUpperCase() + ' ' + (t('subject.subjects')).toUpperCase()}</li>
                            </ol>
                            <div>{typeRow}</div>
                        </div>
                    </div>
                );
            })
        }

        return row
    };

    return (
        <div className='row'>
            <div className='col-4 pr-0'>
                <Card>
                    <Card.Body>
                        <TreeView
                            treeData={seasons}
                            selectedNodes={[selectedTreeId]}
                            onSelect={handleTreeClick}
                            defaultExpandAll={true}
                        />
                    </Card.Body>
                </Card>
            </div>
            <div className='col-8'>
                <Card>
                    <Card.Header>
                        <Tabs
                            id={'msue-selection'}
                            activeKey={tabKey}
                            className='msue-tab'
                            onSelect={handleTabChange}
                        >
                            <Tab eventKey={'class'} title={t('selection.byClass')}/>
                            <Tab eventKey={'subject'} title={t('selection.bySubject')}/>
                            <Tab eventKey={'student'} title={t('selection.byStudent')}/>
                        </Tabs>
                    </Card.Header>
                    <Card.Body>
                        {renderTab()}
                    </Card.Body>
                </Card>
            </div>
            {
                showEditModal &&
                <Modal
                    show={showEditModal}
                    onHide={onEditClose}
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                                {t('common.edit').toUpperCase()}
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='col-12 p-0'>
                            {
                                selection1s && selection1s.length > 0
                                ?
                                    selection1s.map((selection, index) => (
                                        <Card
                                            key={'main_' + index}
                                            className='mb-7'
                                        >
                                            <ButtonToolbar className='d-inline mt-7 ml-5'>
                                                <Button
                                                    key={'subject_index_' + index}
                                                    className="btn btn-primary btn-shadow ml-3"
                                                    onClick={() => handlerSubjectAddClick(selection.id)}
                                                >
                                                    {selection.seasonName + ' ' + t('subject.selectSubject').toLowerCase()}
                                                </Button>
                                                <Button
                                                    className='ml-3'
                                                    variant='success'
                                                    onClick={() => onSaveClick(selection.selectionData && selection.selectionData.approved1 === true ? 1 : 0, selection.id)}
                                                >
                                                    {t('common.save')}
                                                </Button>
                                                {
                                                    selection.selectionData && (!selection.selectionData.approved1 || selection.selectionData.approved1 == undefined || selection.selectionData.approved1 == false) &&
                                                    <Button
                                                        key={'publish_index_' + index}
                                                        className='ml-3'
                                                        variant='publish'
                                                        onClick={() => onSaveClick(1, selection.id)}
                                                    >
                                                        {selection.seasonName + ' ' + t('action.publish').toLowerCase()}
                                                    </Button>
                                                }
                                                {
                                                    selection.selectionData == undefined &&
                                                    <Button
                                                        key={'publish_index_' + index}
                                                        className='ml-3'
                                                        variant='publish'
                                                        onClick={() => onSaveClick(1, selection.id)}
                                                    >
                                                        {selection.seasonName + ' ' + t('action.publish').toLowerCase()}
                                                    </Button>
                                                }
                                                {
                                                    selection.selectionData && selection.selectionData.approved1 &&
                                                    <Button
                                                        key={'publish_index_' + index}
                                                        className='ml-3'
                                                        variant='publish'
                                                        onClick={() => onSaveClick(0, selection.id)}
                                                    >
                                                        {selection.seasonName + ' ' + t('action.unPublish').toLowerCase()}
                                                    </Button>
                                                }
                                            </ButtonToolbar>
                                            <Card.Body>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <h6 className='dark-blue text-uppercase'>{t('subject.selected')}</h6>
                                                    </div>
                                                    <div className='col-12 mb-4'>
                                                        <table className='ml-12'>
                                                            <tbody>
                                                            <tr>
                                                                <td className='text-right'>{t('total.subject')}:</td>
                                                                <td className='pl-4 strong-blue font-w-bold'>{selection?.totalCount || 0}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='text-right'>{t('total.credit')}:</td>
                                                                <td className='pl-4 strong-blue font-w-bold'>{selection?.totalCredit || 0}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='text-right'>{t('total.price')}:</td>
                                                                <td className='pl-4 strong-blue font-w-bold'>{selection?.totalCreditPrice || 0}₮</td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                {
                                                    selection.dtls && Object.keys(selection.dtls).map((categoryKey, ci) => { 
                                                        let renderArea = [];

                                                        renderArea.push(
                                                            <div key={'category_' + ci}>
                                                                <b>{categoryKey}</b>
                                                            </div>
                                                        )

                                                        Object.keys(selection.dtls[categoryKey]).map((typeKey, ti) => { 
                                                            renderArea.push(
                                                                <div key={'category_' + ci + '_type_' + ti}>
                                                                    <b>{' - ' + typeKey + ' - '}</b>
                                                                    <div>
                                                                        <DTable
                                                                            data={selection.dtls[categoryKey][typeKey]}
                                                                            columns={studentIndexColumnsTest}
                                                                            config={editConfig}
                                                                            updateTable={updateView}
                                                                            selectMode={'radio'}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )
                                                        })

                                                        return renderArea
                                                    })
                                                }
                                            </Card.Body>
                                        </Card>
                                    ))
                                : null
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button onClick={onEditClose} className='btn btn-link bolder'>{t('common.back')}</button>
                    </Modal.Footer>
                </Modal>
            }
            <Modal
                show={addModal}
                onHide={handleAddModalClose}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t('subject.selectSubject')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        programDetails && programDetails.length > 0
                            ?
                            programDetails.map((detail, index) => {
                                return (
                                    <Card key={'detail_' + index} className='mb-3'>
                                        <Card.Body>
                                            <div className='col-12 p-0 font-w-bold dark-blue'>
                                                {detail['year'] + ' ' + (detail && detail['seasonType'] ? (detail['seasonType']).toUpperCase() : '')}
                                            </div>
                                            {/* <div className='col-12 p-0 font-w-bold dark-blue mt-1'>
                                                {t('subject.generalAcademicSubject')}
                                            </div> */}
                                            {renderDetails(detail['details'], index)}
                                        </Card.Body>
                                    </Card>
                                )
                            })
                            : null
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='link' onClick={handleAddModalClose}>{t('common.close')}</Button>
                    <Button variant='next' onClick={handleAddModalSubmit}>{t('common.select')}</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={deleteModal}
                onHide={handleDeleteModalClose}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t('warning.delete')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {t('warning.delete_confirmation')}
                        <br/>
                        <br/>
                        {t('warning.delete_confirmation_description')}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="btn btn-link bolder" onClick={handleDeleteModalClose}>{t('common.cancel')}</Button>
                    <Button variant="success btn-shadow" onClick={handlerOnDeleteClick}>{t('warning.delete')}</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default One;