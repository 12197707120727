import React from 'react'
import { useTranslation } from "react-i18next";

const StudentInfoTable = ({
    student = {}
}) => {
    const { t } = useTranslation();

    return (
        <table className='info-table'>
            <tbody>
                <tr>
                    <td>{t('common.country')}</td>
                    <th>{student?.nationality || '-'}</th>
                </tr>
                <tr>
                    <td>{t('common.familyName')}</td>
                    <th>{student?.familyName || '-'}</th>
                </tr>
                <tr>
                    <td>{t('common.lastName')}</td>
                    <th>{student?.lastName || '-'}</th>
                </tr>
                <tr>
                    <td>{t('common.firstName')}</td>
                    <th>{student?.firstName || '-'}</th>
                </tr>
                <tr>
                    <td>{t('person.registrationNumber')}</td>
                    <th>{student?.registerNumber || '-'}</th>
                </tr>
                <tr>
                    <td>{t('student.currentClass')}</td>
                    <th>{student?.class || '-'}</th>
                </tr>
                <tr>
                    <td>{t('student.schoolEnrollmentDate')}</td>
                    <th>{student?.startDate?.date ? student.startDate.date.substring(0, 10) : '-'}</th>
                </tr>
            </tbody>
        </table>
    )
}

export default StudentInfoTable