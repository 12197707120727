import React, { useRef, useEffect, useState } from "react";
import { Button, Modal, View, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  getSchools,
  getAddProgram,
  getEditProgram,
  getSchoolProgram,
  getAcademicProgram,
  getCandidateHealth,
} from "../../../../utils/fetchRequest/Urls";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import message from "../../../modules/message";
import { TextareaAutosize } from "@material-ui/core";
// import Forms from "../../../modules/Form/Forms";

const ViewHealth = ({ onClose, id, schoolId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const getData = () => {
    const params = {
      school: id,
      enrollDtlProgram: id,
    };
    dispatch(setLoading(true));
    fetchRequest(getCandidateHealth, "GET", params)
      .then((res) => {
        if (res.success) {
          const { data } = res;
          if (data?.enrollDtlProgramData) {
            // setProgram(data?.enrollDtlProgramData);
          }
        } else {
          message(res?.data?.message || t("errorMessage.title"));
        }
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        message(t("errorMessage.title"));
      });
  };
  return (
    <Modal
      show={true}
      onHide={onClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div style={{ color: "#4a70ae", fontSize: "1.1rem" }}>Харах</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <TextareaAutosize
          className="msue-textarea-description"
          style={{ height: "119px", width: "333px" }}
        /> */}

        {/* </div>
        </div> */}
        <div className="row">
          <div className="d-flex border border-1 col-2 ml-10">
            <img
              // alt="/"
              height={100}
              width={80}
              // src={mainSchoolData.logo}
            />
          </div>
          <ul className="col-8">
            <li className="d-flex justify-content-center row">
              <div className="col">
                <p className="text-primary d-flex justify-content-end">
                  Бүртгэлийн дугаар
                </p>
              </div>
              <div className="col justify-content-end">
                <span className="text-dark">26384</span>
              </div>
            </li>
            <li className="d-flex justify-content-center row">
              <div className="col">
                <p className="text-primary d-flex justify-content-end">
                  ЭЕШ бүртгэлийн дугаар
                </p>
              </div>
              <div className="col justify-content-end">
                <span className="text-dark">363485759</span>
              </div>
            </li>
            <li className="d-flex justify-content-center row">
              <div className="col">
                <p className="text-primary d-flex justify-content-end">Овог</p>
              </div>
              <div className="col justify-content-end">
                <span className="text-dark">Баатар</span>
              </div>
            </li>
            <li className="d-flex justify-content-center row">
              <div className="col">
                <p className="text-primary d-flex justify-content-end">Нэр</p>
              </div>
              <div className="col justify-content-end">
                <span className="text-dark">Баярсайхан</span>
              </div>
            </li>
            <li className="d-flex justify-content-center row">
              <div className="col">
                <p className="text-primary d-flex justify-content-end">
                  Регистрийн дугаар
                </p>
              </div>
              <div className="col justify-content-end">
                <span className="text-dark">ТЕ77657548</span>
              </div>
            </li>
            <li className="d-flex justify-content-center row">
              <div className="col">
                <p className="text-primary d-flex justify-content-end">Хүйс</p>
              </div>
              <div className="col justify-content-end">
                <span className="text-dark">Эрэгтэй</span>
              </div>
            </li>
            <li className="d-flex justify-content-center row">
              <div className="col">
                <p className="text-primary d-flex justify-content-end">И-мэйл</p>
              </div>
              <div className="col justify-content-end">
                <span className="text-dark">info@yahoo.com </span>
              </div>
            </li>
            <li className="d-flex justify-content-center row">
              <div className="col">
                <p className="text-primary d-flex justify-content-end">
                  Утасны дугаар
                </p>
              </div>
              <div className="col justify-content-end">
                <span className="text-dark">88776655</span>
              </div>
            </li>
          </ul>
        </div>

        <p className="d-flex justify-content-center">Төлөв : Тэнцсэн</p>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={onClose} className="btn btn-link bolder">
          {t("common.back")}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewHealth;
