import React, {useRef, useEffect, useState} from 'react';
import { Button, Modal } from "react-bootstrap";
import Forms from "../../../modules/Form/Forms";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {financeDiscountAdd, invoiceCreate, movementCauseEdit} from "../../../../utils/fetchRequest/Urls";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import message from "../../../modules/message";
import DTable from "../../../modules/DataTable/DTable";
import Checkbox from "../../../modules/Form/Checkbox";

const AddGovernment = ({
    onClose,
    onSubmit,
    types,
}) => {
    const { t } = useTranslation();
    const formRef = useRef();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    const [date, setDate] = useState(null);
    const [students, setStudents] = useState([]);
    const [typeId, setTypeId] = useState(null);
    const [index, setIndex] = useState(null);
    const [discountTypes, setDiscounTypes] = useState(types && types.length > 0 ? types.map(type => ({value: type.id, text: type.name})) : []);
    const [isSameAmount, setIsSameAmount] = useState(false);
    const [amount, setAmount] = useState('')

    const discountChange = (params) => {
        dispatch(setLoading(true));
        setStudents([])
        fetchRequest(financeDiscountAdd, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setStudents(data?.students || [])
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const config = {
        showPagination: false,
        showFilter: true,
        footer: true,
        footerStyle: {backgroundColor: '#ebecf5'}
    };

    const columns = [
        {
            dataField: "isCheck",
            text: '',
            sort: false,
            footer: '',
            headerStyle: () => ({
                width: 50,
            }),
            style: {textAlign: 'center'},
            formatter: (cell, row, rowIndex) => {
                return (
                    <Checkbox
                        className='flex-center'
                        onChange={() => handleCheckBoxChange(!row.isChecked, rowIndex)}
                        checked={row.isChecked}
                    />
                );
            },
        },
        {
            dataField: "code",
            text: t('student.code'),
            sort: false,
            footer: ''
        },
        {
            dataField: "lastName",
            text: t('student.lastName'),
            sort: false,
            footer: '',
        },
        {
            dataField: "firstName",
            text: t('student.name'),
            sort: false,
            footer: '',
        },
        {
            dataField: "amount",
            text: t('common.amount'),
            sort: false,
            footer: '',
            footerAlign: 'right',
            footerType: 'sum',
            style: {padding: 1, verticalAlign: 'middle'},
            formatter: (cell, row, rowIndex) => {
                return (
                    row.isChecked
                    ?
                        <input
                            autoFocus={index == rowIndex ? true : false}
                            value={row.amount || '0'}
                            type="text"
                            style={{width: '100%', borderRadius: 3, border: '1px solid #ebedf2', height: 'inherit', padding: 11}}
                            onChange={(e) => {handleInputChange(e, 'amount', row, rowIndex)}}
                        />
                    :
                        <input
                            autoFocus={index == rowIndex ? true : false}
                            value={''}
                            type="text"
                            style={{width: '100%', borderRadius: 3, border: '1px solid #ebedf2', height: 'inherit', padding: 11, background: '#eceef2'}}
                            onChange={(e) => {handleInputChange(e, 'amount', row, rowIndex)}}
                            disabled={true}
                        />
                );
            },
        },
    ];

    const onDateChange = (date) => {
        setDate(date)
    };

    const onDiscountChange = (id, evt) => {
        if (id) {
            setTypeId(id);
            const params = {
                school: schoolId,
                discount: id,
            };
            discountChange(params)
        }
    };

    const onIsSameChange = (val) => {
        setIsSameAmount(val)
    };

    const inputAmountChange = (evt) => {
        let cloneData = [...students];

        for (let i = 0; i < cloneData.length; i++){
            if(cloneData[i].isChecked){
                cloneData[i].amount = evt.target.value
            }
        }
        setAmount(evt.target.value)
        setStudents(cloneData)
    };

    useEffect(() => {
        formRef?.current?.updateFields && formRef.current?.updateFields(fields);
    }, [date, typeId, isSameAmount, students]);

    const fields = [
        {
            label: t('finance.transactionDate'),
            value: date || null,
            type: 'date',
            dateCustomButton: false,
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            onChange: onDateChange,
            key: 'date',
        },
        {
            label: t('common.type'),
            value: typeId || null,
            type: 'dropdown',
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            options: discountTypes,
            onChange: onDiscountChange,
            key: 'discount',
            clearable: true
        },
        {
            label: t('finance.isSameAmount'),
            type: 'checkbox',
            value: isSameAmount,
            inputWidth: 200,
            labelWidth: 300,
            key: 'isSameAmount',
            onChange: onIsSameChange
        },
        {
            label: t('common.amount'),
            value: amount,
            type: 'numberOnly',
            inputWidth: 200,
            labelWidth: 300,
            key: 'amount',
            hidden: !isSameAmount,
            onChange: inputAmountChange
        },
    ];

    const handleCheckBoxChange = (checked, index) => {
        let cloneData = [...students];
        for (let i = 0; i < cloneData.length; i++){
            if(i == index){
                cloneData[i].isChecked = checked;
            }
        }

        setStudents(cloneData)
    };

    const handleInputChange = (e, column, row, index) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            let cloneData = [...students];

            cloneData[index][column] = e.target.value;

            setStudents(cloneData);
            setIndex(index);
        }
    };

    const onSaveClick = () => {
        const [ isValid, states, values ] = formRef.current.validate();
        if (isValid) {
            let studentArray = [];
            if(students && students.length > 0){
                for(let i = 0; i < students.length; i ++) {
                    studentArray.push({
                        id: students[i].id,
                        amount: students[i].amount,
                    })
                }
            }
            const params = {
                ...values,
                students: JSON.stringify(studentArray)
            };
            onSubmit(params);
        }
    };

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('finance.governmentRegister').toUpperCase()}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='row'>
                    <div className='col-12'>
                        <Forms
                            ref={formRef}
                            fields={fields}
                        />
                    </div>
                    <div className='col-12'>
                        <DTable
                            columns={columns}
                            config={config}
                            data={students}
                            selectMode={'radio'}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className='btn btn-link bolder'>{t('common.back')}</button>
                <Button variant='success btn-shadow' onClick={onSaveClick}>{t('common.save')}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddGovernment;