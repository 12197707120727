import React, {useEffect, useState} from 'react';
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {lmsGradeIndex} from "../../../../../utils/fetchRequest/Urls";
import { setLoading } from "../../../../../redux/action";
import { fetchRequest } from "../../../../../utils/fetchRequest";
import message from "../../../../modules/message";
import {StudentInfo} from "../../../student/scoreTable/StudentInfo";
import {Ranking} from "./Ranking";
import {StudentGradeTable} from "./StudentGradeTable";

const StudentGradeView = ({
    studentId,
    onClose,
    onSubmit,
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const person = useSelector(state => state?.person || []);

    const [labels, setLabels] = useState([]);
    const [studentInfo, setStudentInfo] = useState([]);
    const [image, setImage] = useState([]);

    useEffect(() => {
        const params = {
            school: schoolId,
            id: studentId,
        };

        init(params)
    }, []);

    const init = (params) => {
        dispatch(setLoading(true));
        fetchRequest(lmsGradeIndex, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    let studentInfo = data || [];

                    let labelArray = [];
                    labelArray.push(
                        {
                            code: 'school',
                            name: t('school.title'),
                            value: studentInfo.schoolName || ''
                        },
                        {
                            code: 'studentCode',
                            name: t('student.code'),
                            value: studentInfo.studentCode || ''
                        },
                        {
                            code: 'lastName',
                            name: t('person.lastName'),
                            value: studentInfo.lastName || ''
                        },
                        {
                            code: 'firstName',
                            name: t('person.firstName'),
                            value: studentInfo.firstName || ''
                        },
                        {
                            code: 'microsoftAccount',
                            name: 'Microsoft account',
                            value: studentInfo.email || ''
                        },
                        {
                            code: 'phoneNumber',
                            name: t('common.phoneNumber'),
                            value: studentInfo.phone || ''
                        },
                        {
                            code: 'createdDate',
                            name: t('student.enrollmentDate'),
                            value: studentInfo.startDate && studentInfo.startDate.date ? studentInfo.startDate.date.substring(0, 10) : ''  || ''
                        },
                        {
                            code: 'programName',
                            name: t('student.currentProgram'),
                            value: studentInfo.programName || ''
                        },
                        {
                            code: 'registerNumber',
                            name: t('person.registrationNumber'),
                            value: studentInfo.registerNumber || ''
                        },
                    );

                    setLabels(labelArray);
                    setStudentInfo(studentInfo);
                    setImage(studentInfo.avatar);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('student.gradeInfo').toUpperCase()}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <StudentInfo
                    labels={labels}
                    image={image}
                />
                {/* <Ranking
                    ranking={studentInfo.ranking}
                /> */}
                <StudentGradeTable
                    studentId={studentId}
                />
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className='btn btn-link bolder'>{t('common.back')}</button>
            </Modal.Footer>
        </Modal>
    );
};

export default StudentGradeView;