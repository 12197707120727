import React,{useState,useEffect} from 'react';
import {Card,button,Row,Col,Tab,Tabs, Button, Image} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch,useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { 
    studentOnlineLessonNewsfeed,
    studentOnlineLessonTopic,
    studentOnlineLessonInit,
    studentOnlineLessonDiscussion,
    studentOnlineLessonHw,
    studentOnlineLessonCourseRate
} from '../../../../utils/fetchRequest/Urls'
import { fetchRequest } from '../../../../utils/fetchRequest'
import message from '../../../modules/message'
import { setLoading } from '../../../../redux/action'
import RenderNewsfeed from './RenderNewsfeed'
import DTable from '../../../modules/DataTable/DTable'
import { useHistory } from 'react-router-dom'
import SendHomework from './modals/SendHomework'
import { TextareaAutosize } from '@material-ui/core';
import UndoModal from './modals/UndoModal'
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';

const OnlineLessonDtl = ({
    location: { state: {
        lesson = {
            id: '',
            subjectCode: '',
            subjectName: '',
            subjectType: '',
            teacherCode: '',
            teacherName: '',
            duration: '',
            rating: 0
        },
        tab = 'newsfeed'
    } = {} } = {}, }) => {

    const dispatch = useDispatch()
    const { t } = useTranslation()
    const history = useHistory()

    const school = useSelector(state => state?.selectedSchool?.id || null)

    const [lessonNews, setLessonNews] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalSize, setTotalSize] = useState(1);
    const [tabKey, setTabKey] = useState(tab);
    const [starRate, setStarRate] = useState(lesson.rating || 0);
    const [number, setNumber] = useState(3);
    const [subjects, setSubjects] = useState([]);
    const [totalSubjects, setTotalSubjects] = useState(1);
    const [subjectTableChangeParams, setSubjectTableChangeParams] = useState({});
    const [homework, setHomework] = useState(null);
    const [homeworks, setHomeworks] = useState([]);
    const [totalHomework, setTotalHomework] = useState(1);
    const [hwTableChangeParams, setHwTableChangeParams] = useState({});
    const [discussions, setDiscussions] = useState([]);
    const [discussion, setDiscussion] = useState({});
    const [description, setDescription] = useState('');
    const [sendHwModal, setSendHwModal] = useState(false);
    const [viewUndoModal, setViewUndoModal] = useState(false);
    const [newComment, setNewComment] = useState('');
    const [tempId, setTempId] = useState(null);

    const columns = [
        {
            dataField: 'status',
            text: t('common.status'),
            sort: true,
            headerStyle: () => ({
                width: 80,
            }),
            style: {textAlign: 'center', padding: 3},
            formatter(cell, row) {
                if(cell === true){
                    return <Button className='btn btn-success'>
                            {t('discussion.open')}
                        </Button>
                }else if(cell === false){
                    return <Button className='btn btn-secondary'>
                            {t('discussion.close')}
                        </Button>
                }else{
                    return null
                }
            },
        },
        {
            dataField: "type",
            text: t('common.type'),
            sort: true
        },
        {
            dataField: "name",
            text: t('subject.title'),
            sort: true,
            formatter(cell, row) {
                if(row.status){
                    return (
                        <div className='msue-dt-clickable-cell' onClick={() => viewSubjectClick(row.id)}>
                            <span style={{color:'#5c3cc7',cursor:'pointer'}}>
                                <u>{cell}</u>
                            </span>
                        </div>
                    )
                }

                return (
                    <div>
                        <span style={{cursor:'pointer'}}>
                            <u>{cell}</u>
                        </span>
                    </div>
                )
            },
        },
        {
            dataField: "rating",
            text: t('onlineLesson.rate'),
            sort: true,
            headerStyle: () => ({
                width: 100,
            }),
            style: {textAlign: 'center', padding: 0},
            formatter(cell,row) {
                const tempStarData = []
                let counter = cell
                let i = 0
                while(i < 5){
                    let color = '#919bc0'
                    if(counter > 0){
                        color = '#f5af04'
                    }

                    tempStarData.push({
                        color: color,
                        opacity: (counter < 1 && counter > 0) ? counter : null,
                        index: i + 1
                    })
                    counter--
                    i++
                }

                return tempStarData.map((star, index)=>{
                    return <i 
                        key={index} 
                        className='flaticon-star mx-1' 
                        style={{
                            color: star.color,
                            fontSize: '30px',
                            opacity: star.opacity || 1
                        }}
                        onClick={()=>onTableStarClick(row.id, index + 1)}
                    />
                })
            },
        }
    ]

    const hwColumns = [
        {
            dataField: 'status',
            text: t('common.status'),
            sort: true,
            headerStyle: () => ({
                width: 80,
            }),
            style: {textAlign: 'center', padding: 3},
            formatter(cell,row) {
                if(row.isChecked){
                    return <span className='text-center'>
                        <Button className='btn btn-success' style={{backgroundColor: '#014812',border: 'none'}}>
                            {t('onlineLesson.checked')}
                        </Button>
                    </span>
                }

                if(cell === 'OPEN'){
                    return <span className='text-center'>
                        <Button className='btn btn-warning'>
                            {t('discussion.open')}
                        </Button>
                    </span>
                }else if(cell === 'CLOSED'){
                    return <div className='text-center'>
                        <Button className='btn btn-secondary'>
                            {t('discussion.close')}
                        </Button>
                    </div>
                }else if(cell === 'SENT'){
                    return <div className='text-center'>
                        <Button className='btn btn-success'>
                            {t('attendance.sent')}
                        </Button>
                    </div>
                }if(cell === 'CHECKED'){
                    return <div className='text-center'>
                        <Button className='btn btn-success' style={{backgroundColor: '#014812',border: 'none'}}>
                            {t('onlineLesson.checked')}
                        </Button>
                    </div>
                }else{
                    return null
                }
            },
        },
        {
            dataField: "name",
            text: t('homework.name'),
            sort: true,
            formatter(cell,row){
                return (
                    <div className='msue-dt-clickable-cell' onClick={()=>viewHomeworkClick(row.id)}>
                        <span style={{color:'#5c3cc7',cursor:'pointer'}}>
                            <u>{cell}</u>
                        </span>
                    </div>
                )
            }
        },
        {
            dataField: "file",
            text: t('homework.sentFile'),
            sort: true,
            style: {textAlign: 'center', padding: 3},
            formatter(cell, row) {
                if(row.sentDate){
                    return (
                        <div className='msue-dt-clickable-cell' onClick={()=>viewHomeworkClick(row.id)}>
                            <span style={{color:'#5c3cc7',cursor:'pointer'}}>
                                {
                                    cell.map((c,index)=>{
                                        let url = c
                                        if(index + 1 < cell.length){
                                            url += ', '
                                        }
                                        return <u key={index + url}>{url}</u>
                                    })
                                }
                            </span>
                        </div>
                    )
                }else{
                    if(row.status == 'CLOSED'){
                        return null;
                    } else {
                        return (
                            <div style={{width: '150px'}}>
                                <Button 
                                    className='btn btn-outline-success'
                                    onClick={()=>{
                                        setSendHwModal(true)
                                        setTempId(row.id)
                                        setHomework(row)
                                    }}
                                >
                                    {t('homework.send')}
                                </Button>
                            </div>
                        )
                    }
                }
            },
            headerStyle: () => {
                return {
                    width: '80px'
                }
            }
        },
        {
            dataField: "totalScore",
            text: t('assessment.score'),
            sort: true,
            formatter(cell){
                return (
                    <div className='w-100 text-right'>
                        <span>{cell || '-'}</span>
                    </div>
                )
            }
        },
        {
            dataField: "takenScore",
            text: t('assessment.takenScore'),
            sort: true,
            formatter(cell){
                return (
                    <div className='w-100 text-right'>
                        <span>{cell || '-'}</span>
                    </div>
                )
            }
        },
        {
            dataField: "duration",
            text: t('common.duration'),
            sort: true,
            style: {textAlign: 'right'},
        },
        {
            dataField: "sentDate",
            text: t('homework.sentDate'),
            sort: true,
            style: {textAlign: 'right'},
        }
    ];

    const hwContextMenus = [
        {
            key: 'undo',
            title: t('action.undo'),
            icon: <i className='las la-backspace dt-cm-item-icon' />,
        },
    ];

    const contextMenuClick = (id, key, row) =>{
        if(id && key){
            if(key === 'undo'){
                setViewUndoModal(true)
                setTempId(id)
            }
        }
    };

    const onDetail = id =>{
        const tempData = [...lessonNews]
        if(tempData.length){
            tempData.map(data=>{
                if(data.id === id){
                    if(data.isViewAll){
                        data.isViewAll = false
                    }else{
                        data.isViewAll = true
                    }
                }
            })
        }
        setLessonNews(tempData)
    };

    const viewSubjectClick = id =>{
        history.push({
            pathname: '/student/online-lesson/view-module',
            state: {
                lesson,
                syllabusId: id
            }
        })
    };

    const viewHomeworkClick = id =>{
        history.push({
            pathname: '/student/online-lesson/view-homework',
            state: {
                lesson,
                homeworkId: id
            }
        })
    };

    const onPageChange = page =>{
        setPageNumber(page)
        fetchNewsfeed(page)
    };

    const onSubjectTableChange = params =>{
        const bodyParams = {
            school,
            lesson: lesson.id,
            ...params
        }
        fetchSubjects(bodyParams)
        setSubjectTableChangeParams(params)
    };

    const onHwTableChange = params =>{
        const bodyParams = {
            school,
            lesson: lesson.id,
            ...params
        }
        fetchHomework(bodyParams)
        setHwTableChangeParams(params)
    };

    const onStarClick = index =>{
        if(number > 0){
            setStarRate(index)
        }
    };

    const onLessonRateSend = () =>{
        const bodyParams = {
            school,
            course: lesson.id,
            rate: starRate
        }

        dispatch(setLoading(true));
        fetchRequest(studentOnlineLessonCourseRate, 'POST', bodyParams)
            .then(res => {
                if (res.success) {
                    const {data} = res;
                    setNumber(data?.number);
                    message(res?.data?.message || t('common.success'),true)
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const onTableStarClick = (id, rating) => {
        const bodyParams = {
            school,
            course: lesson.id,
            syllabus: id,
            rate: rating
        }
        dispatch(setLoading(true));
        fetchRequest(studentOnlineLessonTopic, 'POST', bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res;

                    if(data.modules?.length){
                        setSubjects(data.modules);
                    }

                    setTotalSubjects(data.totalSize || 1);
                    // onSubjectTableChange(subjectTableChangeParams)
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const onHwDelete = () =>{
        if(tempId){
            const formData = new FormData();
            formData.append('school', school);
            formData.append('homework', tempId);
            formData.append('course', lesson.id);
            formData.append('type', 'REMOVE');

            dispatch(setLoading(true));
            fetchRequest(studentOnlineLessonHw, 'POST', formData, false, true)
                .then(res => {
                    if (res.success) {
                        const { data } = res;

                        setViewUndoModal(false);
                        setTempId(null);
                        setHomeworks(data?.homeworks || []);
                        setTotalHomework(data.totalSize || 1);

                        message(res?.data?.message || t('common.success'),true);
                    } else {
                        message(res?.data?.message || t('errorMessage.title'));
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'));
                })
        }
    };

    const fetchHomework = params => {
        dispatch(setLoading(true));
        fetchRequest(studentOnlineLessonHw, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    
                    setHomeworks(data?.homeworks || []);
                    setTotalHomework(data.totalSize || 1);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const fetchSubjects = params => {
        dispatch(setLoading(true));
        fetchRequest(studentOnlineLessonTopic, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    
                    if(data.modules?.length){
                        setSubjects(data.modules)
                    }

                    setTotalSubjects(data.totalSize || 1)
                    dispatch(setLoading(false));
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                    dispatch(setLoading(false));
                }
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const fetchNewsfeed = page => {
        const bodyParams = {
            page,
            school,
            pageSize: 2,
            course: lesson.id
        }
        dispatch(setLoading(true));
        fetchRequest(studentOnlineLessonNewsfeed, 'GET', bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res;

                    let newsfeedObj = data?.lessonNews;

                    for (let i = 0; i < newsfeedObj.length; i++) {
                        if(newsfeedObj[i].body && newsfeedObj[i].body.length > 500){
                            newsfeedObj[i].readMore = true;
                        }
                    }

                    setTotalSize(data?.totalCount || 0);
                    setStarRate(data?.rate || 0);
                    if(data?.number == 0){
                        setNumber(data?.number);
                    } else {
                        setNumber(data?.number || 3);
                    }
                    setLessonNews(newsfeedObj);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const fetchDiscussion = (method = 'GET') =>{
        const params = {
            school,
            course: lesson.id,
            news: discussion.id,
            description,
        }

        dispatch(setLoading(true));
        fetchRequest(studentOnlineLessonDiscussion, method.toLocaleUpperCase(), params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    
                    setDiscussions(data?.discussions || []);

                    if(data?.newsId){
                        let discussionsList = data?.discussions || [];

                        if(discussionsList && discussionsList.length > 0){
                            for(let i = 0; i < discussionsList.length; i++){
                                if(discussionsList[i].id == data.newsId){
                                    setDiscussion(discussionsList[i]);
                                }
                            }
                        }
                    }

                    setDescription('');
                    dispatch(setLoading(false));
                } else {
                    message(res?.data?.message || t('errorMessage.title'));
                    dispatch(setLoading(false));
                }
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            })
    };

    const renderStars = () =>{
        const tempStarData = []
        let counter = starRate
        let i = 0
        while(i < 5){
            let color = '#919bc0'
            if(counter > 0){
                color = '#f5af04'
            }

            tempStarData.push({
                color: color,
                opacity: (counter < 1 && counter > 0) ? counter : null,
                index: i + 1
            })
            counter--
            i++
        }
        return tempStarData.map((star,index)=>{
            return <i 
                key={index} 
                className='flaticon-star mx-1' 
                style={{
                    color: star.color,
                    fontSize: '30px',
                    opacity: star.opacity ? star.opacity : 1
                }}
                onClick={()=>onStarClick(star.index)}
            />
        })
    }

    const onSaveReply = (comment, parentNewId, method) => {
        const params = {
            school,
            course: lesson.id,
            news: discussion.id,
            description,
            comment: comment,
            parent: parentNewId
        }

        dispatch(setLoading(true));
        fetchRequest(studentOnlineLessonDiscussion, method.toLocaleUpperCase(), params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    
                    setDiscussions(data?.discussions || []);

                    if(data?.newsId){
                        let discussionsList = data?.discussions || [];

                        if(discussionsList && discussionsList.length > 0){
                            for(let i = 0; i < discussionsList.length; i++){
                                if(discussionsList[i].id == data.newsId){
                                    setDiscussion(discussionsList[i]);
                                }
                            }
                        }
                    }

                    setDescription('');
                    dispatch(setLoading(false));
                } else {
                    message(res?.data?.message || t('errorMessage.title'));
                    dispatch(setLoading(false));
                }
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            })
    }

    const newCommentChange = (e, newsId, commentId) => {
        if(newsId && commentId){
            const cloneDiscussions = [...discussions];

            if(cloneDiscussions && cloneDiscussions.length > 0){
                for(let i = 0; i < cloneDiscussions.length; i++){
                    if(newsId == cloneDiscussions[i].id){
                        if(cloneDiscussions[i].children && cloneDiscussions[i].children.length > 0){
                            let cloneChildren = cloneDiscussions[i].children;

                            for(let c = 0; c < cloneChildren.length; c++){
                                if(commentId == cloneChildren[c].id){
                                    cloneChildren[c].comment = e.target.value;
                                }
                            }
                        }
                    }
                }
            }

            setDiscussions(cloneDiscussions);
        }
    }

    const onSubmit = (newsId, commentId) => {
        if(newsId && commentId){
            let selectedComment = '';
            if(discussions && discussions.length > 0){
                for(let i = 0; i < discussions.length; i++){
                    if(newsId == discussions[i].id){
                        if(discussions[i].children && discussions[i].children.length > 0){
                            let cloneChildren = discussions[i].children;

                            for(let c = 0; c < cloneChildren.length; c++){
                                if(commentId == cloneChildren[c].id){
                                    selectedComment = cloneChildren[c].comment;
                                }
                            }
                        }
                    }
                }
            }

            onSaveReply(selectedComment, commentId, 'POST');
        }
    }

    const newCommentOnKeyPress = (e, newsId, commentId) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();

            if(newsId && commentId){
                let selectedComment = '';
                if(discussions && discussions.length > 0){
                    for(let i = 0; i < discussions.length; i++){
                        if(newsId == discussions[i].id){
                            if(discussions[i].children && discussions[i].children.length > 0){
                                let cloneChildren = discussions[i].children;
    
                                for(let c = 0; c < cloneChildren.length; c++){
                                    if(commentId == cloneChildren[c].id){
                                        selectedComment = cloneChildren[c].comment;
                                    }
                                }
                            }
                        }
                    }
                }
    
                onSaveReply(selectedComment, commentId, 'POST');
            }
        }
    }

    const renderChildren = () => {
        const children = discussion.children
        if(children?.length){
            return children.map((c,index)=>{
                return (
                    <div style={border} key={index + ' - ' + c.id} className='my-4'>
                        <Row className='align-items-center'>
                            <Col md={1} className="w-100">
                                {
                                    c && c.studentPhoto 
                                    ?
                                        <Image
                                            src={c.studentPhoto}
                                            width={50}
                                            height={55}
                                            style={{borderRadius: 8, objectFit: 'cover'}}
                                        />
                                    : 
                                        <Image
                                            src={toAbsoluteUrl("/media/users/avatar_male.png")}
                                            width={50}
                                            height={55}
                                            style={{borderRadius: 8, objectFit: 'cover'}}
                                        />
                                }
                            </Col>
                            <Col md={10} className="w-100 ml-3">
                                {
                                    discussion.discussionType == 'only_teacher' &&
                                    <div className='w-100'>
                                        <span>
                                            {c.studentCode}
                                        </span>
                                    </div>
                                }
                                <div className='w-100'>
                                    <span>
                                        <b>{c.lastName + ', ' + c.firstName}</b>
                                    </span>
                                </div>
                                <div className='w-100'>
                                    <span>{c?.publishedDate || ''}</span>
                                </div>
                            </Col>
                        </Row>
                        <div className='w-100 my-3'>
                            <p>{c.description}</p>
                        </div>
                        {
                            c.comments && c.comments.length > 0 && 
                            <div className='w-100 my-3'>
                                {
                                    c.comments.map((comment, cIndex) => {
                                        return (
                                            <Row key={'c_' + index + '_comment_' + cIndex} className='align-items-center'>
                                                {
                                                    cIndex == 0
                                                    ? 
                                                        <Col md={12} className="w-100">
                                                            <div className="separator separator-dashed my-2"></div>
                                                        </Col>
                                                    : null
                                                }
                                                <Col md={1} className="w-100">
                                                    {
                                                        comment && comment.studentPhoto 
                                                        ?
                                                            <Image
                                                                src={comment.studentPhoto}
                                                                width={30}
                                                                height={35}
                                                                style={{borderRadius: '50%', objectFit: 'fill'}}
                                                            />
                                                        : 
                                                            <Image
                                                                src={toAbsoluteUrl("/media/users/avatar_male.png")}
                                                                width={30}
                                                                height={35}
                                                                style={{borderRadius: '50%', objectFit: 'fill'}}
                                                            />
                                                    }
                                                </Col>
                                                <Col md={10} className="w-100">
                                                    <div className='w-100'>
                                                        <span>
                                                            <b>{comment.lastName + ', ' + comment.firstName}</b>
                                                        </span>
                                                    </div>
                                                    <div className='w-100'>
                                                        <span>{comment?.publishedDate || ''}</span>
                                                    </div>
                                                    
                                                </Col>
                                                <Col md={12} className="w-100 mt-2">
                                                    <span>{comment.description}</span>
                                                </Col>
                                                <Col md={12} className="w-100">
                                                    <div className="separator separator-dashed my-2"></div>
                                                </Col>
                                            </Row>
                                        )
                                    })
                                }
                            </div>
                        }
                        {
                            discussion.discussionType == 'only_student' &&
                            <div className='w-100 my-3'>
                                <div className='nf-item-new-comment'>
                                    <div className='nf-item-new-comment-content'>
                                        <div className='nf-item-new-comment-textarea-container'>
                                            <TextareaAutosize
                                                placeholder={''}
                                                className='nf-item-new-comment-textarea'
                                                rowsMin={1}
                                                onChange={(e) => newCommentChange(e, c.parentId, c.id)}
                                                value={c.comment}
                                                spellCheck={false}
                                                onKeyPress={(e) => newCommentOnKeyPress(e, c.parentId, c.id)}
                                            />
                                        </div>
                                        <button
                                            className='nf-item-new-comment-send-btn'
                                            onClick={() => onSubmit(c.parentId, c.id)}
                                        >
                                            {t('common.send')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                )
            })
        }else{
            return null
        }
    }

    const renderDiscussion = () =>{
        if(discussions.length){
            return discussions.map((d, index)=>{
                return (
                    <div 
                        className={index == 0 ? 'mb-3' : 'my-3'}
                        style={{
                            ...border,
                            backgroundColor: discussion.id === d.id ? 'rgba(54, 163, 247, 0.5)' : 'transparent',
                        }} 
                        key={d.id + '-' + index} 
                        onClick={()=>setDiscussion(d)}
                    >
                        <div className='w-100'>
                            <span>
                                <b>{d.name}</b>
                            </span>
                        </div>
                        {/* <div className='w-100'>
                            <span>
                                <b>{d.description}</b>
                            </span>
                        </div> */}
                        <div className='w-100'>
                            <span>
                                <b>{d.duration}</b>
                            </span>
                        </div>
                    </div>
                )
            })
        }else{
            return null
        }
    }

    const renderTabs = () =>{
        const tabs = {
            newsfeed: (
                <RenderNewsfeed 
                    lessonNews={lessonNews} 
                    onDetail={onDetail}
                    onPageChange={onPageChange}
                    totalSize={totalSize}
                    page={pageNumber}
                />
            ),
            intro: (
                <div className='w-100 p-4' style={{textIndent: '25px'}}>
                    <p>
                        {lesson.shortDescription}
                    </p>
                    <p>
                        {lesson.description}
                    </p>
                </div>
            ),
            subjects: (
                <DTable
                    // remote
                    config={{}}
                    columns={columns}
                    data={subjects}
                    // onInteraction={onSubjectTableChange}
                    totalDataSize={totalSubjects}
                    selectMode={'radio'}
                />
            ),
            homework: (
                <DTable
                    // remote
                    config={{}}
                    columns={hwColumns}
                    data={homeworks}
                    // onInteraction={onHwTableChange}
                    totalDataSize={totalHomework}
                    contextMenus={hwContextMenus}
                    onContextMenuItemClick={contextMenuClick}
                    individualContextMenus
                />
            ),
            discussion: (
                <Row>
                    <Col md={4}>
                        {renderDiscussion()}
                    </Col>
                    <Col md={8}>
                        <div style={border}>
                            {
                                discussion && discussion.description &&
                                <>
                                    <div className='w-100 mb-2'>
                                        <b>{t('discussion.title')}</b>
                                    </div>
                                    <div className='w-100 mb-6'>
                                        <div style={{fontSize: 13}} dangerouslySetInnerHTML={ {__html: discussion.description} }/>
                                    </div>
                                </>
                            }
                            {
                                discussion && discussion.isPublish == false
                                ?
                                    <Row className='align-items-start'>
                                        <Col md={12} className='text-center'>
                                            <Button className='btn btn-secondary btn btn-primary'>
                                                {t('discussion.close')}
                                            </Button>
                                        </Col>
                                    </Row>
                                :
                                discussion.hasOwnProperty('id') == true
                                ?
                                    <Row className='align-items-start'>
                                        <Col md={3} className='text-right'>
                                            <span>
                                                {t('common.description')}
                                            </span>
                                        </Col>
                                        <Col md={8}>
                                            <div className='w-100'>
                                                <TextareaAutosize
                                                    className='msue-textarea-description'
                                                    value={description}
                                                    onChange={e => {
                                                        setDescription(e.target.value);
                                                    }}
                                                    rows={5}
                                                />
                                            </div>
                                            <Button className='btn btn-success' onClick={()=>fetchDiscussion('POST')}>
                                                {t('common.send')}
                                            </Button>
                                        </Col>
                                    </Row>
                                : 'Хэлэлцүүлэг сонгоно уу'
                            }
                        </div>
                        {renderChildren()}
                    </Col>
                </Row>
            )
        }
        return tabs[tabKey]
    }

    useEffect(()=>{
        const initParams = {
            school,
            course: lesson.id
        }

        fetchNewsfeed(pageNumber);
        fetchSubjects(initParams);
        fetchHomework(initParams);
        fetchDiscussion();
    },[])

    const sendHomeworkHandler = (data) => {
        setHomeworks(data);
    }

    return (
        <Card>
            <Card.Header>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <span style={{ color: '#29569f',fontSize:'14px'}}>
                        <b>{t('onlineLesson.detail').toLocaleUpperCase()}</b>
                    </span>
                    <Link to={'/student/online-lesson'}>
                        {t('common.backTo_list')}
                    </Link>
                </div>
            </Card.Header>
            <Card.Body>
                <Row className='align-items-center mb-4'>
                    <Col md={6}>
                        <div style={{...border,height:'150px'}}>
                            <div className='w-100 mb-2'>
                                <span>
                                    <b>
                                        {lesson.subjectCode + ' ' + lesson.subjectName + ', ' + lesson.subjectType}
                                    </b>
                                </span>
                            </div>
                            <div className='w-100 my-2'>
                                <span>
                                    <b>{lesson.teacherCode + ' ' + lesson.teacherName}</b>
                                </span>
                            </div>
                            <div className='w-100 my-2'>
                                <span>
                                    {lesson.duration}
                                </span>
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div style={{...border, height:'150px'}}>
                            <div className='w-100'>
                                <span style={{color:'#29569f'}}>
                                    <b>{t('onlineLesson.rate')}</b> 
                                </span>
                                <span style={{float: 'right'}}>
                                    Танд үнэлгээ өгөх {number} хүсэлт байна
                                </span>
                            </div>
                            <div className='w-100 px-6 my-2'>
                                {renderStars()}
                            </div>
                            {
                                number > 0 && 
                                <div className='text-center'>
                                    <Button className='btn btn-success' onClick={onLessonRateSend}>
                                        {t('common.send')}
                                    </Button>
                                </div>
                            }
                        </div>
                    </Col>
                </Row>
                <Card.Header className='mb-6'>
                    <Tabs
                        id={'msue-sm-online-lesson'}
                        activeKey={tabKey}
                        className='msue-tab'
                        onSelect={key=>setTabKey(key)}
                    >
                        <Tab eventKey={'newsfeed'} title={t('onlineLesson.news')}/>
                        <Tab eventKey={'intro'} title={t('onlineLesson.intro')}/>
                        <Tab eventKey={'subjects'} title={t('subject.subjects')}/>
                        <Tab eventKey={'homework'} title={t('teacher.homework')}/>
                        <Tab eventKey={'discussion'} title={t('discussion.title')}/>
                    </Tabs>
                </Card.Header>
                {renderTabs()}
            </Card.Body>
            {
                sendHwModal
                ?
                    <SendHomework
                        onClose={()=>{
                            setSendHwModal(false)
                            setTempId(null)
                        }}
                        homework={tempId}
                        selectedHomework={homework}
                        school={school}
                        lesson={lesson.id}
                        onSubmitData={(data) => sendHomeworkHandler(data)}
                    />
                : null
            }
            {
                viewUndoModal
                ?
                <UndoModal
                    onClose={()=>{
                        setViewUndoModal(false)
                        setTempId(null)
                    }}
                    onDelete={onHwDelete}
                />
                : null
            }
        </Card>
    )
}

const border = {
    border: 'none',
    boxShadow: '1px 4px 12px 5px #919bc033',
    borderRadius: '8px',
    padding: '1.5rem'
}

export default OnlineLessonDtl