import React, { useState, useEffect, useRef } from 'react'
import DTable from 'app/modules/DataTable/DTable'
import {Button, Card} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import Select from "app/modules/Form/Select";
import {setLoading} from "redux/action";
import {fetchRequest} from "utils/fetchRequest";
import message from "app/modules/message";
import {teacherGradeInit, teacherGradeLog, teacherGradeLogView} from "utils/fetchRequest/Urls";
import { useReactToPrint } from 'react-to-print'
import {SingleStudentPrint} from './singleStudent'
import {AllStudentsPrint} from './allStudents'
import ViewModal from './viewModal';

export const TeacherPrintGrade = () => {
    const teacherSingleStudentRef = useRef();
    const teacherAllStudentRef = useRef();

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    const [ teacherParentSeasonIndex ] = useState('teacher_print_grade_parent_season_index');
    const [ teacherSeasonIndex ] = useState('teacher_print_grade_season_index');

    const [list, setList] = useState([]);
    const [years, setYears] = useState([]);
    const [seasons, setSeasons] = useState([]);
    const [mainSchoolData, setMainSchoolData] = useState([]);
    const [logs, setLogs] = useState([]);
    const [scoreTypes, setScoreTypes] = useState([]);
    const [stDetails, setStDetails] = useState(null)
    const [teacherData, setTeacherData] = useState(null)
    const [subjectData, setSubjectData] = useState(null)
    const [seasonData, setSeasonData] = useState(null)
    const [schoolData, setSchoolData] = useState(null)
    const [templates, setTemplates] = useState(null)
    const [row, setRow] = useState(null)
    const [yearId, setYearId] = useState(localStorage.getItem(teacherParentSeasonIndex) || null);
    const [seasonId, setSeasonId] = useState(localStorage.getItem(teacherSeasonIndex) || null);
    const [viewModal, setViewModal] = useState(false);

    useEffect(() => {
        const params = {
            school: schoolId,
            parent: yearId,
            season: seasonId,
        };
        init(params)
    }, []);

    const init = (params) => {
        dispatch(setLoading(true));
        fetchRequest(teacherGradeInit, 'GET', params)
            .then(res => {
                dispatch(setLoading(false));
                if (res.success) {
                    const { data } = res;

                    if(!yearId){
                        setYearId(data?.parentSeasonId || null);
                    }

                    if(!seasonId){
                        setSeasonId(data?.seasonId || null);
                    }
                    
                    setYears(data?.parentSeasons || [])
                    setSeasons(data?.seasons || [])
                    setList(data?.list || [])
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const onYearChange = (value) => {
        setYearId(value)

        localStorage.setItem(teacherParentSeasonIndex, value);

        const params = {
            school: schoolId,
            parent: value,
            season: seasonId,
        };
        init(params)
    }

    const onSeasonChange = (value) => {
        setSeasonId(value)

        localStorage.setItem(teacherSeasonIndex, value);

        const params = {
            school: schoolId,
            parent: yearId,
            season: value
        };
        init(params)
    }

    const renderBody = (students, stTemplateDetails, subjectId) => {
        let tdObj = [];
        if(students && students.length > 0){
            for(let i = 0; i < students.length; i++){
                let studentTotalScore = 0;

                tdObj.push(
                    <tr key={'score_' + i}>
                        <td>{i + 1}</td>
                        <td>{students[i].code}</td>
                        <td>{students[i].lastName}</td>
                        <td>{students[i].firstName}</td>
                        {
                            students[i].details && stTemplateDetails && stTemplateDetails.length > 0
                                ?
                                    stTemplateDetails.map((details, dIndex) => {
                                        studentTotalScore = studentTotalScore + students[i].details[details.id]
                                        return (
                                            <td key={'student_score_' + dIndex} style={{textAlign: 'right'}}>
                                                {students[i].details[details.id]}
                                            </td>
                                        )
                                    })
                                : null
                        }
                        <td className='text-right'>{studentTotalScore || 0}</td>
                        <td>{students[i].scoreTypeName || ''}</td>
                        <td style={{padding: 3}}><button onClick={() => printSingleStudent(students[i], subjectId)} className='btn btn-success btn-shadow'>{t('common.print')}</button></td>
                        <td style={{padding: 3}}><button onClick={() => viewStudentLog(students[i], subjectId)} className='btn btn-warning btn-shadow'>{t('common.view')}</button></td>
                    </tr>
                );
            }
        }

        return tdObj;
    };

    const handleTeacherSingleStudent = useReactToPrint({
        suppressErrors: true,
        content: () => teacherSingleStudentRef.current,
        onPrintError: () => { message(t('common.error')) },
        pageStyle: '@page{size: auto!important; margin: 0.5cm 1cm!important}',
        documentTitle: `${t('transcript.studentScoreDescription')}`
    })

    const handleTeacherAllStudent = useReactToPrint({
        suppressErrors: true,
        content: () => teacherAllStudentRef.current,
        onPrintError: () => { message(t('common.error')) },
        pageStyle: '@page{size: auto!important; margin: 0.5cm 1cm!important}',
        documentTitle: `${t('transcript.studentScoreDescription')}`
    })

    const printSingleStudent = (row, subjectId) => {
        const params = {
            school: schoolId,
            season: seasonId,
            id: row.id,
            subject: subjectId,
            exam: row.examId,
            details: JSON.stringify(row.details),
            type: 'single'
        }

        dispatch(setLoading(true));
        fetchRequest(teacherGradeLog, 'POST', params)
            .then(res => {
                if (res.success) {
                    const {data} = res;
                    setMainSchoolData(data?.mainSchoolData || null)
                    setTeacherData(data?.teacherData || null)
                    setSubjectData(data?.subjectData || null)
                    setSeasonData(data?.seasonData || null)
                    setSchoolData(data?.schoolData || null)
                    setTemplates(data?.templateDetailList || [])
                    setScoreTypes(data?.scoreTypes || [])
                    setRow([row])
                    dispatch(setLoading(false));
                    handleTeacherSingleStudent();
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                    dispatch(setLoading(false));
                }
            })
            .catch(err => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }

    const printAllStudent = (rows, subjectId) => {
        const params = {
            school: schoolId,
            season: seasonId,
            subject: subjectId,
            students: JSON.stringify(rows),
            type: 'all'
        }

        dispatch(setLoading(true));
        fetchRequest(teacherGradeLog, 'POST', params)
            .then(res => {
                if (res.success) {
                    const {data} = res;
                    setMainSchoolData(data?.mainSchoolData || null)
                    setTeacherData(data?.teacherData || null)
                    setSubjectData(data?.subjectData || null)
                    setSeasonData(data?.seasonData || null)
                    setSchoolData(data?.schoolData || null)
                    setTemplates(data?.templateDetailList || [])
                    setScoreTypes(data?.scoreTypes || [])
                    setRow(rows)
                    dispatch(setLoading(false));
                    handleTeacherAllStudent();
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                    dispatch(setLoading(false));
                }
            })
            .catch(err => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }

    const viewStudentLog = (row, subjectId) => {
        const params = {
            school: schoolId,
            season: seasonId,
            id: row.id,
            subject: subjectId,
            exam: row.examId,
            details: JSON.stringify(row.details),
            type: 'single' 
        }

        dispatch(setLoading(true));
        fetchRequest(teacherGradeLogView, 'POST', params)
            .then(res => {
                if (res.success) {
                    const {data} = res;
                    setStDetails(data.detailList)
                    setLogs(data.logs)
                    setViewModal(true)
                    dispatch(setLoading(false));
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                    dispatch(setLoading(false));
                }
            })
            .catch(err => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }

    return (
        <div className='sm-container'>
            <Card>
                <Card.Body>
                    <div className='row'>
                        <div className='col-12 mb-2'>
                            <label className="mr-2">{t('school.season.title')}</label>
                            <div style={{ width: 200, display: 'inline-flex' }}>
                                <Select
                                    className={'default-input-border w-100'}
                                    value={parseInt(yearId)}
                                    searchable
                                    onChange={value => onYearChange(value)}
                                    options={years}
                                />
                            </div>
                            <label className="ml-4 mr-2">{t('school.season.title')}</label>
                            <div style={{ width: 200, display: 'inline-flex' }}>
                                <Select
                                    className={'default-input-border w-100'}
                                    value={parseInt(seasonId)}
                                    searchable
                                    onChange={value => onSeasonChange(value)}
                                    options={seasons}
                                />
                            </div>
                        </div>
                        <div className='col-12'>
                            {
                                list && list.length > 0 &&
                                list.map((subject, i) => {
                                    return (
                                        <div className='mb-4' key={'subject_' + i}>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <div className='french-blue fs-11 mt-3 mb-3'>
                                                    <b>{subject?.subjectCode + ' ' + subject?.subjectName + ' (' + subject.pTimeName + ')'}</b>
                                                </div>
                                                <div style={{position: 'relative', bottom: 3}}>
                                                    <button onClick={() => printAllStudent(subject.students, subject.subjectId)} className='btn btn-purple btn-shadow'>{t('common.print')}</button>
                                                </div>
                                            </div>
                                            
                
                                            <table className='table table-bordered'>
                                                <thead>
                                                    <tr>
                                                        <th rowSpan={2}>№</th>
                                                        <th rowSpan={2}>{t('student.code')}</th>
                                                        <th rowSpan={2}>{t('student.lastName')}</th>
                                                        <th rowSpan={2}>{t('student.name')}</th>
                                                        {
                                                            subject?.stTemplates && subject?.stTemplates.length > 0 && subject?.stTemplates[0].details && subject?.stTemplates[0].details.length > 0
                                                            ?
                                                                subject.stTemplates[0].details.map((template, index) => {
                                                                    return (
                                                                        <th key={'item_' + index}>{template.name}</th>
                                                                    )
                                                                })
                                                            : null
                                                        }
                                                        <th style={{width: 60}}>{t('total.title')}</th>
                                                        <th rowSpan={2} style={{width: 80}}>{t('assessment.title')}</th>
                                                        <th rowSpan={2} style={{width: 70}}></th>
                                                        <th rowSpan={2} style={{width: 40}}></th>
                                                    </tr>
                                                    <tr>
                                                        {
                                                            subject.stTemplates && subject.stTemplates.length > 0 && subject.stTemplates[0].details && subject.stTemplates[0].details.length > 0
                                                                ?
                                                                subject.stTemplates[0].details.map((template, index) => {
                                                                    return (
                                                                        <th className='text-right' key={'score_' + index}>{parseInt(template.maxScore)}</th>
                                                                    )
                                                                })
                                                                : null
                                                        }
                                                        {
                                                            subject.stTemplates && subject.stTemplates.length > 0
                                                            ?
                                                                <th className='text-right' style={{width: 60}}>{subject.stTemplates[0].totalScore}</th>
                                                            : null
                                                        }
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {renderBody(subject.students, subject.stTemplates[0].details, subject.subjectId)}
                                                </tbody>
                                            </table>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </Card.Body>
            </Card>
            {
                viewModal &&
                <ViewModal
                    seasonId={seasonId}
                    logs={logs}
                    details={stDetails}
                    onClose={()=>{
                        setViewModal(false)
                    }}
                />
            }
            <div className='d-none'>
                <SingleStudentPrint 
                    ref={teacherSingleStudentRef} 
                    mainSchoolData={mainSchoolData}
                    teacherData={teacherData}
                    subjectData={subjectData}
                    schoolData={schoolData}
                    seasonData={seasonData}
                    list={row}
                    templates={templates}
                    scoreTypes={scoreTypes}
                />
            </div>
            <div className='d-none'>
                <AllStudentsPrint 
                    ref={teacherAllStudentRef}
                    mainSchoolData={mainSchoolData}
                    teacherData={teacherData}
                    subjectData={subjectData}
                    schoolData={schoolData}
                    seasonData={seasonData}
                    list={row}
                    templates={templates}
                    scoreTypes={scoreTypes}
                />
            </div>
        </div>
    )
};
