import React, { useRef, useEffect } from 'react';
import { Button, Modal } from "react-bootstrap";
import Forms from "../../../modules/Form/Forms";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { editAttendanceTypes } from "../../../../utils/fetchRequest/Urls";
import { setLoading } from "../../../../redux/action";
import message from "../../../modules/message";
import { fetchRequest } from "../../../../utils/fetchRequest";

const AttendanceTypeAdd = ({
    onClose,
    onSubmit,
    id
}) => {
    const { t } = useTranslation();
    const forms = useRef();
    const dispatch = useDispatch();
    const languages = useSelector(state => state?.languages?.languages || []).filter(lang => lang.code !== 'mn');

    useEffect(() => {
        if (id) {
            const params = {
                attendance: id,
            };
            dispatch(setLoading(true));
            fetchRequest(editAttendanceTypes, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res;

                        if (data?.attendanceType) {
                            const attendanceType = data.attendanceType;
                            const translations = attendanceType.translations;
                            if (attendanceType) {
                                if(translationFields && translationFields.length > 0 && translations && translations.length > 0){
                                    for(let i = 0; i < translationFields.length; i++){
                                        for(let t = 0; t < translations.length; t++)
                                            if(translationFields[i]['langCode'] == translations[t]['langCode']){
                                                translationFields[i]['value'] = translations[t]['name'];
                                            }
                                    }
                                }

                                forms?.current?.updateFields && forms.current.updateFields([
                                    {
                                        label: t('common.code'),
                                        value: attendanceType.code || '',
                                        type: 'nonCryllic',
                                        upperCase: true,
                                        inputWidth: 200,
                                        labelWidth: 300,
                                        required: true,
                                        key: 'code',
                                    },
                                    {
                                        label: t('common.name'),
                                        value: attendanceType.name || '',
                                        type: 'text',
                                        inputWidth: 200,
                                        labelWidth: 300,
                                        required: true,
                                        key: 'name',
                                    },
                                    {
                                        label: t('common.color'),
                                        value: attendanceType.color || '',
                                        type: 'text',
                                        inputWidth: 200,
                                        labelWidth: 300,
                                        required: true,
                                        key: 'color',
                                    },
                                    {
                                        label: t('common.ordering'),
                                        value: attendanceType.ordering || '',
                                        type: 'numberOnly',
                                        inputWidth: 200,
                                        labelWidth: 300,
                                        required: true,
                                        key: 'ordering',
                                    },
                                    {
                                        label: t('attendance.isReasonable'),
                                        type: 'checkbox',
                                        value: !!attendanceType.isReasonable || false,
                                        labelWidth: 300,
                                        key: 'isReasonable',
                                    },
                                    {
                                        type: 'jsx',
                                        value: <div key={'separator'} className="separator separator-dashed my-7"/>,
                                    },
                                    ...translationFields
                                ]);
                            }
                        }

                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        }
    }, []);

    const translationFields = languages.map(lang => {
        return {
            label: `${t('common.name')} - ${lang.name} (${lang.code})`,
            value: '',
            langCode: lang.code,
            type: 'text',
            labelWidth: 300,
            inputWidth: 200,
        }
    });

    const fields = [
        {
            label: t('common.code'),
            value: '',
            type: 'nonCryllic',
            upperCase: true,
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            key: 'code',
        },
        {
            label: t('common.name'),
            value: '',
            type: 'text',
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            key: 'name',
        },
        {
            label: t('common.color'),
            value: '',
            type: 'text',
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            key: 'color',
        },
        {
            label: t('common.ordering'),
            value: '',
            type: 'numberOnly',
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            key: 'ordering',
        },
        {
            label: t('attendance.isReasonable'),
            type: 'checkbox',
            value: false,
            labelWidth: 300,
            key: 'isReasonable',
        },
        {
            type: 'jsx',
            value: <div key={'separator'} className="separator separator-dashed my-7"/>,
        },
        ...translationFields
    ];

    const onSaveClick = () => {
        const [ isValid, states, values ] = forms.current.validate();
        if (isValid) {
            const translations = [];
            for (let i = 0; i < states.length; i++) {
                const val = states[i];
                if (i >= 6) {
                    translations.push({
                        langCode: val.langCode,
                        name: val.value,
                    });
                }
            }
            const params = {
                ...values,
                translations: JSON.stringify(translations)
            };
            onSubmit(params);
        }
    };

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('attendance.type').toUpperCase()}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Forms
                    ref={forms}
                    fields={fields}
                />
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className='btn btn-link bolder'>{t('common.back')}</button>
                <Button variant='success btn-shadow' onClick={onSaveClick}>{t('common.save')}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AttendanceTypeAdd;