import React,{useEffect,useState} from 'react'
import DTable from '../../../modules/DataTable/DTable';
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import { setLoading } from '../../../../redux/action';
import { fetchRequest } from '../../../../utils/fetchRequest';
import { movementOut,movementOutUndo } from '../../../../utils/fetchRequest/Urls';
import message from '../../../modules/message';
import ViewModal from './modal/ViewModal';
import UndoModal from './modal/UndoModal';

const StudentTable = ({
    nodeId,
    schoolId,
    isClick,
    tree_Id
}) => {

    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch()

    const [movements,setMovements] = useState([])
    const [totalCount,setTotalCount] = useState(1)
    const [tempId,setTempId] = useState(null)
    const [viewModal,setViewModal] = useState(false)
    const [viewDeleteModal,setViewDeleteModal] = useState(false)
    const [tableChangeParams,setTableChangeParams] = useState(null)

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [search, setSearch] = useState('');

    const contextMenus = [
        {
            key: 'view',
            title: t('action.view'),
            icon: <i className='las la-eye dt-cm-item-icon' />,
        },
        {
            key: 'edit',
            title: t('common.edit'),
            icon: <i className='las la-edit dt-cm-item-icon'/>
        },
        {
            key: 'undo',
            title: t('action.undo'),
            icon: <i className='las la-backspace dt-cm-item-icon' />,
        },
    ];

    const columns = [
        {
            dataField: 'date',
            text: t('common.date'),
            sort: true,
            formatter(cell,row) {
                return (
                    <div className='msue-dt-clickable-cell' onClick={()=>onDateClick(row)}>
                        {cell}
                    </div>
                )
            },
        },
        {
            dataField: "studentCode",
            text: t('student.code'),
            sort: true
        },
        {
            dataField: "firstName",
            text: t('student.name'),
            sort: true
        },
        {
            dataField: "cause",
            text: t('common.cause'),
            sort: true
        },
        {
            dataField: "fromClass",
            text: t('movement.out.prevClass'),
            sort: true
        },
        {
            dataField: "createdFirstName",
            text: t('common.createdUser'),
            sort: true
        },
    ];

    const contextMenuClick = (id,key,row) =>{
        if(id && key){
            if(key === 'edit'){
                    if((!isClick && tree_Id.length === 2) || tree_Id.length === 2 ){
                        message(t('errorMessage.selectEducationLevel'))
                    }else{
                     history.push({
                        pathname: '/movement/out/add',
                        state: {
                            movement: id,
                            studentCode: row.studentCode,
                            level:nodeId
                            }
                        })}
                }else if(key === 'undo'){
                    setViewDeleteModal(true)
                    setTempId(id)
                }else if(key === 'view'){
                    setTempId(id)
                    setViewModal(true)
                }
            }
    }

    const fetchData = params => {
        const bodyParams = {
            ...params,
            school: schoolId,
            level: nodeId,
            page,
            pageSize,
            search
        }
        dispatch(setLoading(true));
        fetchRequest(movementOut, 'GET', bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res
                    const tempData = []
                    if(data?.movements?.length){
                        data?.movements.map(move=>{
                            tempData.push({
                                ...move,
                                date: move.createdDate?.date?.substring(0,19)
                            })
                        })
                    }
                    setMovements(tempData)
                    setTotalCount(data?.totalCount || 1)
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false))
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const handleTableChange = params =>{
        setTableChangeParams(params);
        setPage(params.page);
        setPageSize(params.pageSize);
        setSearch(params.search);
    }

    const onDateClick = row =>{
        if(row.id){
            setTempId(row.id)
            setViewModal(true)
        }
    }

    const onDelete = () =>{
        if(tempId){
            const params = {
                movement: tempId,
                school: schoolId
            }
            dispatch(setLoading(true));
            fetchRequest(movementOutUndo,'POST',params)
                .then(res => {
                    if (res.success) {
                        setViewDeleteModal(false)
                        setTempId(null)
                        dispatch(setLoading(false))
                        fetchData(tableChangeParams)
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                        dispatch(setLoading(false))
                    }
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        }
    }

    useEffect(()=>{
        if(nodeId){
            fetchData()
        }
    },[nodeId, page, pageSize, search])

    return (
        <>
            <DTable
                remote
                config={{}}
                columns={columns}
                data={movements}
                contextMenus={contextMenus}
                onContextMenuItemClick={contextMenuClick}
                onInteraction={handleTableChange}
                totalDataSize={totalCount}
            />
            {
                viewModal
                ?
                    <ViewModal 
                        onClose={()=>{
                            setViewModal(false)
                            setTempId(null)
                        }}
                        movementId={tempId}
                    />
                : null
            }
            {
                viewDeleteModal
                ?
                    <UndoModal
                        onClose={()=>{
                            setViewDeleteModal(false)
                            setTempId(null)
                        }}
                        onDelete={onDelete}
                    />
                : null
            }
        </>
    )
}

export default StudentTable