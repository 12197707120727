import React, { useState, useEffect } from "react";
import Checkbox from "../../../../../modules/Form/Checkbox";

const CriteriaCVConfig = ({ onChange, configs }) => {
    const [config, setConfig] = useState(configs);
    useEffect(() => { }, [config]);

    const handleCheckBoxChange = (checked, index, column) => {
        let cloneData = [...config];
        for (let i = 0; i < cloneData.length; i++) {
            if (i == index) {
                cloneData[i].value = checked;
            }
        }
        setConfig(cloneData);
        onChange({ column: column, value: checked });
    };
    
    return (
        <div className="row p-6 pl-40">
            <div className="">
                {config != null ? (
                    config.map((item, index) => (
                        <Checkbox
                            key={index}
                            label={item.name}
                            // className="flex-center"
                            onChange={() =>
                                handleCheckBoxChange(!item.value, index, item.column)
                            }
                            checked={item.value}
                        />
                    ))
                ) : (
                    <div>null af</div>
                )}
            </div>
        </div>
    );
};

export default CriteriaCVConfig;
