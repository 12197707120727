import React, {useEffect, useState} from 'react'
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';

export const TranscriptScore = React.forwardRef((props, ref) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { studentInfo, mainSchoolData, schoolData, qrCode, studentScores} = props;

    let totalCredit = 0;
    let avgGpa = 0;
    let totalListCount = 0;
    if(studentScores && studentScores.length > 0){
        for(let i = 0; i < studentScores.length; i++){
            if(studentScores[i] && studentScores[i].list && (studentScores[i].list).length > 0){
                let studentList = studentScores[i].list;
                let totalGpa = 0;
                let seasonTotalCredit = 0;
                let seasonGpa = 0;
                let subjectCount = 0;

                for(let l = 0; l < studentList.length; l++){
                    totalCredit += studentList[l].credit;
                    totalGpa += studentList[l].studentGpa;

                    seasonTotalCredit += studentList[l].credit;
                    seasonGpa += studentList[l].studentGpa;
                    
                    totalListCount += 1;
                    subjectCount += 1;
                }

                studentScores[i]['totalCredit'] = seasonTotalCredit;
                studentScores[i]['seasonAvgGpa'] = seasonGpa/subjectCount;
                avgGpa += (totalGpa / studentList.length)
            }
        }
        avgGpa = (avgGpa / studentScores.length)
    }

    const mainSchoolName =  mainSchoolData && mainSchoolData.longName ? mainSchoolData.longName.substring(0,1).toUpperCase() + mainSchoolData.longName.substring(1) : '';
    const schoolName = schoolData && schoolData.longName ? schoolData.longName.substring(0,1).toUpperCase() + schoolData.longName.substring(1) : '';

    const countCredit = (list) =>{
        let totalCredit = 0;
        if(list && list.length> 0){
            for(let i = 0; i < list.length; i++){
                totalCredit += list[i].credit;
            }
        }
        return totalCredit
    };

    const countGpa = (list) =>{
        let totalGpa = 0;
        if(list && list.length> 0){
            for(let i = 0; i < list.length; i++){
                totalGpa += list[i].studentGpa;
            }
        }
        return (totalGpa / list.length).toFixed(2);
    };

    const shiftRow = totalListCount > 75 ? totalListCount/2 : 38;
    let totalRow = 0;
    let stopArrRow = null;
    let stopSubRow = null;

    studentScores?.forEach((el, index) => {
        el?.list?.forEach((x, key) => {
            totalRow++
            if (totalRow == shiftRow) {
                stopArrRow = index;
                stopSubRow = key;
            }
        })
    })

    if (stopArrRow == null && stopSubRow == null) {
        stopArrRow = shiftRow;
        stopSubRow = shiftRow;
    }

    const capitalizeFirstLetter = (string) => {
        if(string){
            let sliceString = string.slice(1);
            return string.charAt(0).toUpperCase() + sliceString.toLowerCase();    
        }

        return null;
    }

    return (
        <div 
            ref={ref} 
            style={{marginTop: 20, color: '#000', fontFamily: 'Times New Roman'}}
        >
            {
                studentInfo && mainSchoolData &&
                <div>
                    <div style={{textAlign: 'center'}}>
                        <div style={{fontFamily: 'Times New Roman', fontSize: 24, fontWeight: 'bold', textTransform: 'uppercase'}}>
                            {mainSchoolName}
                        </div>
                        <div style={{fontFamily: 'Times New Roman', fontSize: 22, fontWeight: 'bold', lineHeight: 1, textTransform: 'uppercase'}}>
                            {schoolName}
                        </div>
                        <div style={{fontFamily: 'Times New Roman', fontSize: 16, marginTop: 8, lineHeight: 1}}>
                            {mainSchoolData.address}
                        </div>
                        <div style={{fontFamily: 'Times New Roman', fontSize: 16, lineHeight: 2}}>
                            {mainSchoolData.contact + ', (веб) ' + mainSchoolData.website}
                        </div>
                    </div>

                    <div style={{textAlign: 'center', fontFamily: 'Times New Roman', fontSize: 16, fontWeight: 'bold', margin: '15px 60px 26px 50px', textTransform: 'uppercase'}}>
                        {t('transcript.studentScoreDescription')}
                    </div>

                    <div style={{fontFamily: 'Times New Roman', fontSize: 14, marginTop: 20, textAlign: 'right', marginRight: 40}}>Хэвлэгдсэн онгоо: {studentInfo.createdDate}</div>

                    <hr style={{border: '0.5px solid #000', margin: '0px 10px 5px 5px'}}/>

                    <div className='row' style={{fontFamily: 'Times New Roman', fontSize: 14}}>
                        <div className='col-6'>
                            <table style={{marginLeft: '2.3rem', lineHeight: 1.3}}>
                                <tbody>
                                    <tr>
                                        <td>Овог нэр:</td>
                                        <td style={{paddingLeft: 30}}>{studentInfo.lastName.charAt(0) + '.' + studentInfo.firstName}</td>
                                    </tr>
                                    <tr>
                                        <td>Регистр:</td>
                                        <td style={{paddingLeft: 30}}>{studentInfo.registerNumber}</td>
                                    </tr>
                                    <tr>
                                        <td>Үндэс угсаа:</td>
                                        <td style={{paddingLeft: 30}}>{studentInfo.countryName}</td>
                                    </tr>
                                    <tr>
                                        <td>Хүйс:</td>
                                        <td style={{paddingLeft: 30}}>{studentInfo.gender == 'F' ? 'Эмэгтэй' : 'Эрэгтэй'}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className='col-6'> 
                            <table style={{marginLeft: '0.7rem', lineHeight: 1.3}}>
                                <tbody>
                                    <tr>
                                        <td>Оюутны код:</td>
                                        <td style={{paddingLeft: 30}}>{studentInfo.studentCode}</td>
                                    </tr>
                                    <tr>
                                        <td>Элссэн огноо:</td>
                                        <td style={{paddingLeft: 30}}>{avgGpa.toFixed(2)}</td>
                                    </tr>
                                    <tr>
                                        <td>Мэргэжил:</td>
                                        <td style={{paddingLeft: 30}}>{capitalizeFirstLetter(studentInfo.academicProgramName)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div> 
                    </div>

                    <hr style={{border: '0.5px solid #000', margin: '10px 10px 5px 10px'}}/>

                    <div className='m-portlet w-100' style={{minHeight: 1000}}>
                        <div className='m-portlet__body'>
                            <div className='row' style={{fontFamily: 'Times New Roman'}}>
                                <div className='col-6 pr-0' style={{paddingLeft: '1.65rem'}}>
                                    <table style={{width: '100%', paddingLeft: 10}}>
                                        <thead>
                                            <tr style={{borderBottom: '1px solid black'}}>
                                                <td style={{whiteSpace: 'nowrap', paddingLeft: 20, paddingBottom: 3}}>ХИЧЭЭЛИЙН КОД</td>
                                                <td style={{paddingLeft: 30}}>НЭР</td>
                                                <td>КРЕДИТ</td>
                                                <td style={{paddingLeft: 20}} colSpan={2}>ҮНЭЛГЭЭ</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                studentScores?.map((arr, arrIndex) => {
                                                    let counter = 0;
                                                    if (arrIndex <= stopArrRow)
                                                        return (
                                                            <>
                                                                <tr>
                                                                    <td style={{paddingLeft: '1.65rem'}} colSpan={4}><b>{arr?.seasonName + ', ' + arr?.parentSeasonName}</b></td>
                                                                </tr>
                                                                {
                                                                    arr?.list?.map((sub, subIndex) => {
                                                                        if (arrIndex == stopArrRow && subIndex > stopSubRow)
                                                                            return
                                                                        else
                                                                            counter += 1;
                                                                            return (
                                                                                <tr>
                                                                                    <td style={{paddingLeft: '1.65rem'}}>{sub?.subjectCode}</td>
                                                                                    <td style={{whiteSpace: 'nowrap'}}>{sub?.subjectName}</td>
                                                                                    <td style={{textAlign: 'right'}}>{sub?.credit}</td>
                                                                                    <td style={{textAlign: 'center',paddingLeft:20}}>{sub?.studentScore}</td>
                                                                                    <td style={{textAlign: 'center', whiteSpace: 'nowrap'}}>{sub?.scoreType}</td>
                                                                                </tr>
                                                                            )
                                                                    })
                                                                }
                                                                {
                                                                    arr?.list.length == counter && 
                                                                    <tr>
                                                                        <td style={{paddingLeft: '1.65rem'}}></td>
                                                                        <td style={{textAlign: 'right', whiteSpace: 'nowrap'}} colSpan={2}>Кредит: {arr.totalCredit}</td>
                                                                        <td style={{textAlign: 'right',paddingLeft:20, whiteSpace: 'nowrap'}} colSpan={2}>Голч оноо: {arr.seasonAvgGpa.toFixed(2)}</td>
                                                                    </tr>
                                                                }
                                                            </>
                                                        )
                                                })
                                            }
                                            {
                                                shiftRow > totalListCount &&
                                                <tr>
                                                    <td style={{paddingLeft: '1.65rem'}}></td>
                                                    <td style={{textAlign: 'right', whiteSpace: 'nowrap'}} colSpan={2}><b>Нийт кредит: {totalCredit}</b></td>
                                                    <td style={{textAlign: 'right',paddingLeft:20, whiteSpace: 'nowrap'}} colSpan={2}><b>Голч оноо: {avgGpa.toFixed(2)}</b></td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className='col-6 pl-0' style={{paddingRight: '1.65rem'}}>
                                    <table style={{height: 'max-content', width: '100%'}} height={'auto'}>
                                        <thead>
                                        <tr style={{borderBottom: '1px solid black'}}>
                                                <td style={{whiteSpace: 'nowrap', paddingLeft: 20, paddingBottom: 3}}>ХИЧЭЭЛИЙН КОД</td>
                                                <td style={{paddingLeft: 30}}>НЭР</td>
                                                <td >КРЕДИТ</td>
                                                <td style={{paddingLeft: 20}} colSpan={2}>ҮНЭЛГЭЭ</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                studentScores?.map((arr, arrIndex) => {
                                                    return (
                                                        <>
                                                            {
                                                                arrIndex > stopArrRow &&
                                                                <tr>
                                                                    <td style={{paddingLeft: '1.65rem'}} colSpan={4}><b>{arr?.seasonName + ', ' + arr?.parentSeasonName}</b></td>
                                                                </tr>
                                                            }
                                                            {
                                                                arr?.list?.map((sub, subIndex) => {
                                                                    if ((arrIndex == stopArrRow && subIndex > stopSubRow) || arrIndex > stopArrRow)
                                                                        return (
                                                                            <tr>
                                                                                <td style={{paddingLeft: '1.65rem'}}>{sub?.subjectCode}</td>
                                                                                <td style={{whiteSpace: 'nowrap'}}>{sub?.subjectName}</td>
                                                                                <td style={{textAlign: 'right'}}>{sub?.credit}</td>
                                                                                <td style={{textAlign: 'center',paddingLeft:20}}>{sub?.studentScore}</td>
                                                                                <td style={{textAlign: 'center', whiteSpace: 'nowrap'}}>{sub?.scoreType}</td>
                                                                            </tr>
                                                                        )
                                                                })
                                                            }
                                                            {
                                                                arrIndex >= stopArrRow &&
                                                                <tr>
                                                                    <td style={{paddingLeft: '1.65rem'}}></td>
                                                                    <td style={{textAlign: 'right', whiteSpace: 'nowrap'}} colSpan={2}>Кредит: {arr.totalCredit}</td>
                                                                    <td style={{textAlign: 'right',paddingLeft:20, whiteSpace: 'nowrap'}} colSpan={2}>Голч оноо: {arr.seasonAvgGpa.toFixed(2)}</td>
                                                                </tr>
                                                            }
                                                        </>
                                                    )
                                                })
                                            }
                                            {
                                                shiftRow < totalListCount &&
                                                <tr>
                                                    <td style={{paddingLeft: '1.65rem'}}></td>
                                                    <td style={{textAlign: 'right', whiteSpace: 'nowrap'}} colSpan={2}><b>Нийт кредит: {totalCredit}</b></td>
                                                    <td style={{textAlign: 'right',paddingLeft:20, whiteSpace: 'nowrap'}} colSpan={2}><b>Голч оноо: {avgGpa.toFixed(2)}</b></td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>  
                            </div>
                        </div>
                    </div>

                    <hr style={{border: '0.5px solid #000', margin: '0px 10px 5px 5px'}}/>

                    <div style={{display: 'flex', color: '#000', position: 'relative', bottom: 0}}>   
                        <div style={{display: 'inline', paddingLeft: 30, paddingRight: 30}}>
                            <p>Үнэлгээ:(2014-2015 болон түүнээс хойш) A=4.3(98-100),A=4.0(94-97),A-=3.7(91-93),B+=3.3(88-90),B=3.0(84-87),B-=2.7(81- 83),C+=2.3(78-80),\n C=2.0(74-77),C-=1.7(71-73),D+=1.3(68-70),D=1.0(64-67),D-=0.7(61-63), F=0.0(0-60)</p>
                            <p>Үнэлгээ:(2014-2015-c өмнөх) A=4.0(90-100),B=3.0(80-89),C=2.0(70-79),D=1.0(60-69)</p>
                            <p>I - Кредит шууд тооцох шалгалтад тэнцээгүй, R - Тухайн хичээлийг дахин судлах, W - Хичээлийн явцад суралцахаас татгалзсан</p>
                        </div>

                        <div style={{display: 'inline'}}>
                            <img style={{width: 120, height: 120, margin: '20px auto 19px auto'}} src={qrCode}/>
                        </div>
                    </div>
                    <div style={{width: '100%', position: 'absolute', top: '50%', left: '0%', fontSize: 40, zIndex: -1, color: '#989898', transform: 'rotate(-45deg)', opacity: 0.1}}>{mainSchoolName.toUpperCase()}</div>
                </div>
            }
        </div>
    )
});
