import { useTranslation } from "react-i18next";
import { Button, Modal } from "react-bootstrap";
import message from "../../../../../modules/message";
import Forms from "../../../../../modules/Form/Forms";
import { useSelector, useDispatch } from "react-redux";
import React, { useRef, useEffect, useState } from "react";
import { setLoading } from "../../../../../../redux/action";
import { fetchRequest } from "../../../../../../utils/fetchRequest";
import { getEditAthleteCriteria } from "../../../../../../utils/fetchRequest/Urls";

const EditBodyModal = ({ enrollId, id, onClose, onSubmit }) => {
  const [athleteData, setAthleteData] = useState(null);
  const form = useRef();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    getCriteriaAthlete();
  }, []);

  useEffect(() => {
    form?.current?.updateFields && form.current.updateFields(forms);
  }, [athleteData]);

  const getCriteriaAthlete = () => {
    const params = {
      enroll: enrollId,
      athleteCriteria: id,
    };
    dispatch(setLoading(true));
    fetchRequest(getEditAthleteCriteria, "GET", params)
      .then((res) => {
        if (res.success) {
          const { data } = res;
          if (data?.criteriaData) {
            setAthleteData(data.criteriaData);
          }
        } else {
          message(res?.data?.message || t("errorMessage.title"));
        }
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        message(t("errorMessage.title"));
      });
  };

  const forms = [
    {
      value: athleteData ? athleteData.name : "",
      label: "Нэр",
      key: "name",
      type: "text",
      inputWidth: 300,
      labelWidth: 230,
      required: true,
      clearable: true,
    },

    {
      value: athleteData ? athleteData.mMinScore : "",
      label: "Доод утга-эрэгтэй*",
      type: "number",
      inputWidth: 300,
      labelWidth: 230,
      required: true,
      multiple: true,
      searchable: true,
      key: "mMinScore",
    },
    {
      value: athleteData ? athleteData.fMinScore : "",
      label: "Доод утга-эмэгтэй*",
      type: "number",
      inputWidth: 300,
      labelWidth: 230,
      required: true,
      multiple: true,
      searchable: true,
      key: "fMinScore",
    },
  ];

  const onSaveClick = () => {
    const [formValid, formValue] = form.current.validate();
    if (formValid) {
      const params = {
        enroll: enrollId,
        athleteCriteria: id,
        name: formValue?.[0].value,
        mMinScore: formValue?.[1].value,
        fMinScore: formValue?.[2].value,
      };

      onSubmit(params);
    }
  };
  return (
    <Modal
      show={true}
      onHide={onClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div style={{ color: "#4a70ae", fontSize: "1.1rem" }}>
            Бие бялдарын үзүүлэлт
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Forms ref={form} fields={forms} />
      </Modal.Body>
      <Modal.Footer>
        <button onClick={onClose} className="btn btn-link bolder">
          {t("common.back")}
        </button>
        <Button variant="success btn-shadow" onClick={onSaveClick}>
          {t("common.save")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default EditBodyModal;
