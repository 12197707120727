import React, { useState, useEffect } from 'react';
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import { movementCauseAdd, movementCauseDelete, movementCauseEdit } from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import DTable from "../../../modules/DataTable/DTable";
import MovementCauseAdd from "./CauseAdd";
import { getLastOrderNumber } from "../../../../utils/utils";
import DeleteModal from "../../../modules/DeleteModal";

const MovementCause = () => {
    const [ types, setTypes ] = useState([]);
    const [ causes, setCauses ] = useState([]);
    const [ showModal, setShowModal ] = useState(false);
    const [ causeId, setCauseId ] = useState(null);
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [causeCodes, setCauseCodes]=useState([]);

    useEffect(() => {
        const params = {
            school: schoolId
        };
        dispatch(setLoading(true));
        fetchRequest(movementCauseAdd, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;

                    if (data?.types?.length) {
                        setTypes(formatTypes(data.types));
                    }

                    if (data?.causes?.length) {
                        setCauses(data.causes);
                    }
                    if (data?.causeCodes?.length) {
                        setCauseCodes(data.causeCodes.map((e)=> ({value:e, text:e})));
                    }

                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }, []);

    const handleAddClick = () => {
        setShowModal(true);
    };

    const onModalClose = () => {
        setShowModal(false);
        setCauseId(null);
    };

    const config = {
        showPagination: false,
        showFilter: false,
        showAllData: true
    };

    const columns = [
        {
            dataField: "code",
            text: t('common.code'),
        },
        {
            dataField: "name",
            text: t('movement.cause'),
            sort: true
        },
        {
            dataField: "typeName",
            text: t('movement.type'),
            sort: true
        },
        {
            dataField: "ordering",
            text: t('common.ordering'),
            style: {textAlign: 'right'},
            sort: true
        },
    ];

    const contextMenus = [
        {
            key: 'edit',
            title: t('common.edit'),
            icon: <i className='las la-edit dt-cm-item-icon'/>
        },
        {
            key: 'delete',
            title: t('common.delete'),
            icon: <i className='las la-trash-alt dt-cm-item-icon' />,
        },
    ];

    const handleContextMenuClick = (id, key) => {
        if (id && key) {
            setCauseId(id);
            if (key === 'edit') {
                setShowModal(true);
            }
        }
    };

    const formatTypes = array => {
        return array.map(item => {
            return {
                value: item.id,
                text: item.name
            }
        })
    };

    const onSubmit = params => {
        const url = causeId ? movementCauseEdit : movementCauseAdd;
        const bodyParams = {
            ...params,
            school: schoolId,
        };

        if (causeId) {
            bodyParams.movementCause = causeId;
            const cause = causes.find(c => c.id === causeId);
            bodyParams.ordering = cause?.ordering || getLastOrderNumber(causes) + 1;
        } else {
            bodyParams.ordering = getLastOrderNumber(causes) + 1;
        }

        dispatch(setLoading(true));
        fetchRequest(url, 'POST', bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res;

                    setTypes(data?.types ? formatTypes(data.types) : []);
                    setCauses(data?.causes || []);

                    message(data?.message || t('common.success'), true);

                    onModalClose();
                } else {
                    message(res?.data?.message || t('errorMessage.title'));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const onDelete = () => {
        const params = {
            school: schoolId,
            movementCause: causeId,
        };
        dispatch(setLoading(true));
        fetchRequest(movementCauseDelete, 'POST', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setTypes(data?.types ? formatTypes(data.types) : []);
                    setCauses(data?.causes || []);
                    message(data?.message || t('common.success'), true);
                    onModalClose();
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    return (
        <Card>
            <Card.Body>
                <button
                    className='btn btn-primary'
                    onClick={handleAddClick}
                >
                    {t('common.add')}
                </button>
                <DTable
                    columns={columns}
                    config={config}
                    data={causes}
                    contextMenus={contextMenus}
                    onContextMenuItemClick={handleContextMenuClick}
                    selectMode={'radio'}
                />
                {
                    showModal && (
                        <MovementCauseAdd
                        causeCodes={causeCodes}
                            onClose={onModalClose}
                            onSubmit={onSubmit}
                            types={types}
                            id={causeId}
                        />
                    )
                }
                {
                    !showModal && causeId && (
                        <DeleteModal
                            onClose={onModalClose}
                            onDelete={onDelete}
                            title={t('warning.delete').toUpperCase()}
                        >
                            {t('warning.delete_confirmation')}
                            <br/>
                            <br/>
                            {t('warning.delete_confirmation_description')}
                        </DeleteModal>
                    )
                }
            </Card.Body>
        </Card>
    );
};

export default MovementCause;