import React,{useEffect,useState} from 'react'
import {Button, Modal} from "react-bootstrap";
import { useTranslation } from 'react-i18next';


const FinishConfirm = ({
    onClose = () =>{},
    onConfirm = () =>{},
    questions = []
}) => {

    const {t} = useTranslation()

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('onlineExam.finishTest')}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='mb-4'>
                    <span>
                        {t('onlineExam.finishConfirmation')}
                    </span>
                </div>
                <div className='w-100'>
                    <span>
                        {t('onlineExam.unansweredQuestions') + ': '}
                    </span>
                </div>
                <div className='w-100'>
                    <span style={{color:'#f64e60',fontSize:'22px'}}>
                        {
                            questions.length
                            ?
                                questions.map((q,index)=>{
                                    if(index + 1 === questions.length){
                                        return q.ordering
                                    }else{
                                        return q.ordering + ', '
                                    }
                                })
                            : null
                        }
                    </span>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className='btn btn-link bolder'>{t('common.back')}</button>
                <Button style={btnStyle} onClick={onConfirm}>{t('common.send')}</Button>
            </Modal.Footer>
        </Modal>
    )
}

const btnStyle = {
    backgroundColor: '#299f72',
    border: 'none',
    borderRadius: '6px',
    color: '#ffffff',
    boxShadow: '0 2px 10px 0 #299f7233'
}

export default FinishConfirm