import React, { useState, useEffect } from "react";
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { surveyCategoryCreate, surveyCategoryDelete, surveyCategoryEdit, surveyCategoryIndex } from '../../../../../../utils/fetchRequest/Urls';
import { fetchRequest } from '../../../../../../utils/fetchRequest';
import { setLoading } from "../../../../../../redux/action";
import showMessage from "../../../../../modules/message";
import TreeView from "../../../../../modules/TreeView";
import useLocalStorage from '../../hooks/useLocalStorage';
import AddModal from './AddModal';
import EditModal from './EditModal';
import DeleteModal from "app/modules/DeleteModal";

export function convertDataToTree(data) {
    return data.map((item) => {
        const { name, id } = item;
        const children = item.sub_categories && item.sub_categories?.length > 0 ? convertDataToTree(item.sub_categories) : [];
        return { key: id, title: name, children };
    });
}

export function convertDataToOptions(data) {
    return data.reduce((acc, item) => {
        const { key, title, children } = item;
        acc.push({ value: key, text: title });
        if (children) {
            acc.push(...convertDataToOptions(children));
        }
        return acc;
    }, []);
}

const CategoryContainer = (props) => {
    const { setCategory } = props;
    const dispatch = useDispatch();
    const { selectedSchool } = useSelector(state => state || null);

    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const [selectedTreeId, setSelectedTreeId] = useLocalStorage('category', '');
    const [categories, setCategories] = useState([]);
    const [codes, setCodes] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [selectedData, setSelectedData] = useState(null);

    const handleTreeClick = (array) => {
        const [id] = array;
        setSelectedTreeId(id);
    };

    useEffect(() => {
        setCategory(selectedTreeId);
    }, [selectedTreeId]);

    const fetch = () => {
        dispatch(setLoading(true));
        const postData = {
            school: selectedSchool?.id,
        };

        fetchRequest(surveyCategoryIndex, 'POST', postData)
            .then((res) => {
                const { success = false, data, categoryCodes } = res;

                if (success) {
                    setCategories(data?.categories || []);
                    setCodes(data?.categoryCodes || [])
                } else {
                    showMessage(data.message || t('errorMessage.title'));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                showMessage(t('errorMessage.title'));
            });
    };

    const handleSubmit = async (values) => {
        dispatch(setLoading(true));
        const postData = {
            school: selectedSchool?.id,
            ...values
        };

        fetchRequest(surveyCategoryCreate, 'POST', postData)
            .then((res) => {
                const { success = false, message = null, data } = res;
                if (success) {
                    setShow(false);
                    setCategories(data?.categories || [])
                } else {
                    showMessage(message || t('errorMessage.title'));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                showMessage(t('errorMessage.title'));
            });
    };

    const handleEditSubmit = async (values) => {
        dispatch(setLoading(true));
        const postData = {
            school: selectedSchool?.id,
            id: selectedId,
            ...values
        };

        fetchRequest(surveyCategoryEdit, 'POST', postData)
            .then((res) => {
                if (res.success) {
                    if (res.success) {
                        const {data} = res;
                        showMessage(res?.data?.message || t('common.success'), true);
                        setCategories(data.categories);
                        onModalClose();
                    } else {
                        showMessage(res?.data?.message || t('errorMessage.title'))
                    }
                } else {
                    showMessage(res.message || t('errorMessage.title'));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                showMessage(t('errorMessage.title'));
            });
    };

    const onDelete = () => {
        const bodyParams = {
            school: selectedSchool.id,
            id: selectedId
        };

        dispatch(setLoading(true));
        fetchRequest(surveyCategoryDelete, 'POST', bodyParams)
            .then(res => {
                dispatch(setLoading(false));
                if (res.success) {
                    const {data} = res;
                    showMessage(res?.data?.message || t('common.success'), true);
                    setCategories(data.categories);
                    onModalClose();
                } else {
                    showMessage(res?.data?.message || t('errorMessage.title'))
                }
            })
            .catch(() => {
                dispatch(setLoading(false));
                showMessage(t('errorMessage.title'));
            })
    };

    useEffect(() => {
        fetch();
    }, []);

    const _treeContextMenuClick = (id, key, node) => {
        if (id && key) {
            if (key === 'EDIT') {
                setSelectedId(id);
                setSelectedData({
                    title: node.title,
                    code: node.code,
                    parent: node.parent
                })
                setShowEditModal(true);
            }
            if (key === 'DELETE') {
                setSelectedId(id);
                setShowDeleteModal(true);
            }
        }
    }

    const onModalClose = () => {
        setShow(false)
        setShowDeleteModal(false)
        setShowEditModal(false)
    }

    return (
        <>
            <section>
                <div className="bg-white p-5">
                    <div className="">
                        <Button
                            type="button"
                            variant="primary"
                            size="sm"
                            className="br-8 mb-3"
                            onClick={() => {
                                setShow(true);
                            }}
                        >
                            <span className="m-0 font-weight-bold d-flex align-items-center">
                                {/* <AddCircleOutline className="w-19" /> */}
                                &nbsp;{t('survey.createCategory')}
                            </span>
                        </Button>
                    </div>
                    <TreeView
                        defaultExpandAll={true}
                        selectedNodes={[selectedTreeId]}
                        onSelect={(e) => handleTreeClick(e)}
                        treeData={[{ key: '', title: t('common.all'), children: categories }]}
                        contextMenus={{
                            school: [
                                {
                                    key: 'EDIT',
                                    iconClassName: 'las la-edit rc-tree-la-edit',
                                    text: t('common.edit'),
                                },
                                {
                                    key: 'DELETE',
                                    iconClassName: 'flaticon2-trash ml3px',
                                    text: t('common.delete'),
                                }
                            ]
                        }}
                        contextMenuKey={'school'}
                        onContextMenuClick={_treeContextMenuClick}
                    />
                </div>
            </section>
            {
                showDeleteModal && selectedId && (
                    <DeleteModal
                        onClose={onModalClose}
                        onDelete={onDelete}
                        title={t('warning.delete')}>
                        {t('warning.delete_confirmation')}
                        <br />
                        <br />
                        {t('warning.delete_confirmation_description')}
                    </DeleteModal>
                )
            }
            <AddModal
                show={show}
                setShow={setShow}
                onSubmit={(values) => {
                    handleSubmit(values);
                }}
                categories={convertDataToOptions(categories)}
                codes={codes}
            />
            {
                showEditModal && selectedId && (
                    <EditModal
                        show={showEditModal}
                        setShow={onModalClose}
                        onSubmit={(values) => {
                            handleEditSubmit(values);
                        }}
                        data={selectedData}
                        categories={convertDataToOptions(categories)}
                        codes={codes}
                    />
                )
            }
        </>
    );
};

export default CategoryContainer;
