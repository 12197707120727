import React, {useEffect, useState} from 'react'
import {Row, Col, Card} from 'react-bootstrap'
import { BranchSchoolCal } from './BranchSchoolCal'
import { MainCalendar } from './MainCalendar'
import {Link} from "react-router-dom";
import {setLoading} from "../../../../redux/action";
import {fetchRequest} from "../../../../utils/fetchRequest";
import {financeEventInit} from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

export const FinanceCalendar = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [mainEvents, setMainEvents] = useState([]);
    const [otherEvents, setOtherEvents] = useState([]);
    const [mainMonth, setMainMonth] = useState(null);
    const [otherMonth, setOtherMonth] = useState(null);
    const [mainMonthIndex] = useState('main_month_index');
    const [otherMonthIndex] = useState('other_month_index');
    const schoolParentId = useSelector(state => state?.selectedSchool?.parentId || null);

    useEffect(() => {
        const selectedMainMonth = localStorage.getItem(mainMonthIndex);
        const selectedOtherMonth = localStorage.getItem(otherMonthIndex);

        let params = {
            school: schoolId,
        };

        if(selectedMainMonth){
            params.main_month = selectedMainMonth
        }

        if(selectedOtherMonth){
            params.other_month = selectedOtherMonth
        }

        dispatch(setLoading(true));
        fetchRequest(financeEventInit, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setMainEvents(data?.mainSchoolEvents || []);
                    setOtherEvents(data?.otherSchoolEvents || []);
                    setMainMonth(data?.mainSelectedMonth || null);
                    setOtherMonth(data?.otherSelectedMonth || null);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }, []);

    return (
        <div>
            {/*<div className='mb-4'>*/}
            {/*    <Link*/}
            {/*        to={{*/}
            {/*            pathname: '/event/add',*/}
            {/*        }}*/}
            {/*        className="btn btn-primary btn-shadow ml-1"*/}
            {/*    >*/}
            {/*        {t('newsfeed.events') + ' ' + t('common.add').toLowerCase()}*/}
            {/*    </Link>*/}
            {/*</div>*/}
            {
                schoolParentId &&
                <BranchSchoolCal
                    otherEvents={otherEvents}
                    selectedMonth={otherMonth}
                />
            }
            {
                <MainCalendar
                    mainEvents={mainEvents}
                    selectedMonth={mainMonth}
                />
            }
        </div>
    )
};
