import { useTranslation } from "react-i18next";
import Forms from "../../../../../modules/Form/Forms";
import React, { useState, useEffect, useRef } from "react";
import Checkbox from "../../../../../modules/Form/Checkbox";
import {
    getCVConfig,
} from "../../../../../../utils/fetchRequest/Urls";
import { fetchRequest } from "../../../../../../utils/fetchRequest";
import { setLoading } from "../../../../../../redux/action";
import { useDispatch } from "react-redux";

const CriteriaGraduatedSchool = ({
    onChange,
    onChangeGPA,
    enrollId,
}) => {
    const forms = useRef();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [isChecked, setIsChecked] = useState(false);
    const [isChecked2, setIsChecked2] = useState(false);
    const [valueGpa, setValueGpa] = useState(null);
    const [valueGpa2, setValueGpa2] = useState(null);

    useEffect(() => { 
        dispatch(setLoading(true));
        const params = {
            enroll: enrollId,
        };

        fetchRequest(getCVConfig, "GET", params)
            .then((res) => {
                if (res.success) {
                    const { data } = res;
                    setIsChecked(data.config && data.config .length > 0 ? data.config[0].isGraduateschool || false : false)
                    setIsChecked2(data.config && data.config.length > 0 ? data.config[0].isGraduateschool2 || false : false)
                    setValueGpa(data.config && data.config.length > 0 ? data.config[0].beforeMinGpa : null)
                    setValueGpa2(data.config && data.config.length > 0 ? data.config[0].beforeMinGpa2 : null)
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
            });
    }, []);

    const handleChange = (value, name) => {
        setIsChecked(!value);
        onChange({ column: name, value: !value });
    };

    const handleChange2 = (value, name) => {
        setIsChecked2(!value);
        onChange({ column: name, value: !value });
    };

    const handleGPA = (e) => {
        setValueGpa(e.target.value)
    };

    const handleGPA2 = (e) => {
        setValueGpa2(e.target.value)
    };

    useEffect(() => {
        let timeout;
        if (valueGpa) {
            timeout = setTimeout(() => {
                onChangeGPA({
                    column: "beforeMinGpa",
                    value: valueGpa,
                });        
            }, 1000);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [valueGpa]);

    useEffect(() => {
        let timeout;
        if (valueGpa2) {
            timeout = setTimeout(() => {
                onChangeGPA({
                    column: "beforeMinGpa2",
                    value: valueGpa2,
                });        
            }, 1000);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [valueGpa2]);

    return (
        <div className="row p-6 ">
            <div className="col-1"></div>
            <div className="col-5">
                <div className="mb-5" style={{ width: "fit-content" }}>
                    <Checkbox
                        key={0}
                        label={t("enroll.requireGraduateSchool")}
                        onChange={(e) => handleChange(e, 'isGraduateSchool')}
                        checked={isChecked}
                    />
                </div>

                <div className="d-flex mb-5">
                    <label className="d-inline-flex mr-2" style={{whiteSpace: 'nowrap', position: 'relative', top: 7}}>
                        {t("enroll.lowestGPA") + ': '}
                    </label>
                    <input 
                        disabled={!isChecked}
                        type="number" 
                        className='form-control' 
                        value={valueGpa}
                        onChange={(e) => handleGPA(e)}
                    />
                </div>

                <div className="mb-5" style={{ width: "fit-content" }}>
                    <Checkbox
                        key={0}
                        label={t("enroll.requireGraduateSchool2")}
                        onChange={(e) => handleChange2(e, 'isGraduateSchool2')}
                        checked={isChecked2}
                    />
                </div>

                <div className="d-flex">
                    <label className="d-inline-flex mr-2" style={{whiteSpace: 'nowrap', position: 'relative', top: 7}}>
                        {t("enroll.lowestGPA") + ': '}
                    </label>
                    <input 
                        disabled={!isChecked2}
                        type="number" 
                        className='form-control' 
                        value={valueGpa2}
                        onChange={(e) => handleGPA2(e)}
                    />
                </div>
            </div>
        </div>
    );
};

export default CriteriaGraduatedSchool;
