import "./reg.scss";
import React, { Fragment } from "react";
import { useRef } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { hexToRgb } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import message from "../../../modules/message";
import { useTranslation } from "react-i18next";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import { CandidateProcessInfo } from "./components/candidateProcessInfo";
import { CandidateProfileInfo } from "./components/candidateProfileInfo";
import { CandidateProcessTitle } from "./components/candidateProcessCard";
import { CandidateProfileList } from "../../../../utils/fetchRequest/Urls";
import { CandidateProcessDetail } from "./components/candidateProcessDetail";

const CandidateProfile = (
    { location: { state: { classId = null, path = "", backUrl = '' } = {} } = {} }
) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [candidate, setCandidate] = useState(
        history?.location?.state?.id || null
    );

    const [details, setDetails] = useState([])
    const [eyeshValues, setEyeshValues] = useState([])
    const [contacts, setContacts] = useState([])
    const [works, setWorks] = useState([])
    const [files, setFiles] = useState([])

    const sms = useRef([]);
    const email = useRef([]);
    const athlete = useRef([]);
    const changeLogs = useRef([]);
    const programChange = useRef(null);
    const modifiedLogs = useRef([]);
    const [stateBackUrl] = useState(backUrl);

    useEffect(() => {
        const candidateId = candidate;
        const params = {
            candidate: candidateId,
            program: history?.location?.state?.program
        };
        setIsLoading(true);
        dispatch(setLoading(true));
        fetchRequest(CandidateProfileList, "GET", params)
            .then((res) => {
                if (res.success) {
                    const { data } = res;
                    setDetails(data?.detail || [])
                    setEyeshValues(data?.eyeshValue || [])
                    setContacts(data?.contacts || [])
                    setWorks(data?.works || [])
                    setFiles(data?.files || [])

                    if (data?.sms) sms.current = data.sms;
                    if (data?.email) email.current = data.email;
                    if (data?.athlete) athlete.current = data.athlete;
                    if (data?.programChange) programChange.current = data.programChange;
                    if (data?.changeLogs) {
                        changeLogs.current = data.changeLogs;
                        const logObject = {};
                        data.changeLogs.forEach((item) => {
                            if (!logObject[item.code]) {
                                logObject[item.code] = [
                                    { ...item, createdDate: item["changedDate"] },
                                ];
                            } else {
                                logObject[item.code] = [
                                    ...logObject[item.code],
                                    { ...item, createdDate: item["changedDate"] },
                                ];
                            }
                        });
                        modifiedLogs.current = logObject;
                        if (data.sms.length !== 0) {
                            data.sms.forEach((item) => {
                                if (!logObject[item.code]) {
                                    logObject[item.code] = [item];
                                } else {
                                    logObject[item.code] = [...logObject[item.code], item];
                                }
                            });
                        }
                        if (data.email.length !== 0) {
                            data.email.forEach((item) => {
                                if (!logObject[item.code]) {
                                    logObject[item.code] = [item];
                                } else {
                                    logObject[item.code] = [...logObject[item.code], item];
                                }
                            });
                        }
                        modifiedLogs.current = logObject;
                    }
                } else {
                    message(res?.data?.message || t("errorMessage.title"));
                }
                setIsLoading(false);
                dispatch(setLoading(false));
            })
            .catch(() => {
                setIsLoading(false);

                dispatch(setLoading(false));
                message(t("errorMessage.title"));
            })
            .finally(()=>{
                setIsLoading(false);
                dispatch(setLoading(false));
            })
    }, []);

    const handlePdf = (path) => {
        window.open("https://darkhanapi.nomch.mn/" + path);
    };
    return (
        <>
            <Card>
                <Card.Header>
                    <Link
                        to={{
                            pathname: stateBackUrl ? stateBackUrl : '/enroll/candidate',
                            state: {
                                classId,
                            },
                        }}
                        className="nav-link active text-uppercase d-flex flex-row-reverse"
                        style={{ color: "#5C3CC7" }}
                    >
                        Буцах
                    </Link>
                </Card.Header>
                <Card.Body>
                    <div className='row'>
                        <div className="col-4">
                            {/* Хувийн мэдээлэл */}
                            <div
                                className="border border-1 mb-4"
                                style={{
                                    backgroundColor: "#fff",
                                    borderColor: "#a94a32",
                                    borderRadius: "8px",
                                }}
                            >
                                <Card.Header style={{ backgroundColor: "#185BC5" }}>
                                    <span
                                        className="fs-1"
                                        style={{
                                            color: "#fff",
                                            width: "30px",
                                            textAlign: "center",
                                            fontSize: "18px",
                                        }}
                                    >
                                        {t("enroll.personalInformation")}
                                    </span>
                                </Card.Header>
                                {
                                    details && details.length > 0 &&
                                    <div>
                                        <img
                                            className="text-center"
                                            style={{
                                                objectFit: "contain",
                                                height: "105px",
                                                width: "93px",
                                                margin: "auto",
                                                display: "block",
                                                marginTop: "13px",
                                            }}
                                            src={
                                                details[0].avatar === "NULL" ||
                                                    details[0].avatar === "" ||
                                                    details[0].avatar == null
                                                    ? "/media/users/avatar_male.png"
                                                    : "https://darkhanapi.nomch.mn/candidate/" +
                                                    details[0].avatar
                                            }
                                        />
                                        <ul className="mt-3 mr-10">
                                            <CandidateProfileInfo
                                                title={t("enroll.registrationNumber")}
                                                text={details[0].candidateNo}
                                            />
                                            <CandidateProfileInfo
                                                title={t("enroll.eyeshRegNumber")}
                                                text={details[0].butNume || ''}
                                            />
                                            <CandidateProfileInfo
                                                title={t("enroll.lastName")}
                                                text={details[0].lastName}
                                            />
                                            <CandidateProfileInfo
                                                title={t("enroll.firstName")}
                                                text={details[0].firstName}
                                            />
                                            <CandidateProfileInfo
                                                title={t("enroll.registerNumber")}
                                                text={details[0].registrationNumber}
                                            />
                                            <CandidateProfileInfo
                                                title={t("enroll.gender")}
                                                text={
                                                    details[0].gender == null
                                                        ? ""
                                                        : details[0].gender.toLowerCase() === "m"
                                                            ? "Эрэгтэй"
                                                            : "Эмэгтэй"
                                                }
                                            />
                                            <CandidateProfileInfo
                                                title={t("enroll.email")}
                                                text={details[0].email}
                                            />
                                            <CandidateProfileInfo
                                                title={t("enroll.phoneNumber")}
                                                text={details[0].gMobileNumber}
                                            />
                                        </ul>
                                    </div>
                                }
                            </div>

                            {/* ЭЕШ оноо */}
                            <div
                                className="border border-1"
                                style={{
                                    backgroundColor: "#fff",
                                    borderColor: "#a94a32",
                                    borderRadius: "8px",
                                }}
                            >
                                <Card.Header style={{ backgroundColor: "#185BC5" }}>
                                    <span
                                        className="fs-1"
                                        style={{
                                            color: "#fff",
                                            width: "30px",
                                            textAlign: "center",
                                            fontSize: "18px",
                                        }}
                                    >
                                        {'ЭЕШ оноо'}
                                    </span>
                                </Card.Header>
                                {
                                    eyeshValues && eyeshValues.length > 0 
                                    ?
                                        eyeshValues.map((eyeshValue, i) => {
                                            return (
                                                <div key={'eyesh_' + i}>
                                                    <ul className="mt-3 mr-10">
                                                        <CandidateProfileInfo
                                                            title={eyeshValue.subjectName}
                                                            text={eyeshValue.score}
                                                        />
                                                    </ul>
                                                </div>
                                            )
                                        })
                                    : 
                                        <p className="text-center"><b>ЭЕШ оноо татагдаагүй байна</b></p>
                                }
                            </div>
                            {/* contact */}
                            {
                                contacts && contacts.length > 0 &&
                                <div
                                    className="border border-1 mt-3"
                                    style={{
                                        backgroundColor: "#fff",
                                        borderColor: "#a94a32",
                                        borderRadius: "8px",
                                    }}
                                >
                                    <Card.Header style={{ backgroundColor: "#185BC5" }}>
                                        <span
                                            className="fs-1"
                                            style={{
                                                color: "#fff",
                                                width: "30px",
                                                textAlign: "center",
                                                fontSize: "18px",
                                            }}
                                        >
                                            {'Холбоо барих'}
                                        </span>
                                    </Card.Header>
                                    {
                                        contacts.map((contact, i) => {
                                            return (
                                                <div key={'eyesh_' + i}>
                                                    <ul className="mt-3 mr-10">
                                                        <CandidateProfileInfo
                                                            title={'Хүний нэр'}
                                                            text={contact.contactName}
                                                        />
                                                        <CandidateProfileInfo
                                                            title={'Утасны дугаар'}
                                                            text={contact.contact}
                                                        />
                                                    </ul>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }
                            {
                                files && files.length > 0 &&
                                <div
                                    className="border border-1"
                                    style={{
                                        marginTop: "11px",
                                        borderRadius: "8px",
                                        backgroundColor: "#fff",
                                    }}
                                >
                                    <Card.Header style={{ backgroundColor: "#185BC5" }}>
                                        <span
                                            className="fs-1"
                                            style={{
                                                color: "#fff",
                                                width: "30px",
                                                fontSize: "18px",
                                                textAlign: "center",
                                            }}
                                        >
                                            {t("enroll.additionalFile")}
                                        </span>
                                    </Card.Header>
                                    <div>
                                        <ul className="mt-3 mr-10">
                                            {
                                                files.map((file, i) => {
                                                    if(file.typeCode == 'ESSAY'){
                                                        return (
                                                            <CandidateProfileInfo
                                                                key={i}
                                                                hasLink={true}
                                                                text={"Essay.pdf"}
                                                                title={t("enroll.essay")}
                                                                func={() => handlePdf(file.path)}
                                                            />
                                                        )
                                                    } else if (file.typeCode == 'APPLICATION') {
                                                        return (
                                                            <CandidateProfileInfo
                                                                key={i}
                                                                hasLink={true}
                                                                text={"Өргөдөл.pdf"}
                                                                title={t("enroll.application")}
                                                                func={() => handlePdf(file.path)}
                                                            />
                                                        )
                                                    }
                                                    
                                                    return null;
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            }
                        </div>

                        <div
                            style={{
                                borderRadius: "8px",
                                backgroundColor: "#fff",
                            }}
                            className="col border border-1 p-0"
                        >
                            <Card.Header
                                style={{
                                    backgroundColor: "#185BC5",
                                }}
                            >
                                <span
                                    className="fs-1"
                                    style={{
                                        color: "#fff",
                                        width: "30px",
                                        fontSize: "18px",
                                        textAlign: "center",
                                    }}
                                >
                                    {t("enroll.enrollmentProcess")}
                                </span>
                            </Card.Header>
                            {
                                details.map((detail, i) => {
                                    return (
                                        <Fragment key={i}>
                                            <div className="mt-8">
                                                <ul>
                                                    {/* vndsen status */}
                                                    <li className="d-flex row ">
                                                        <div className="col-4">
                                                            <p
                                                                className="d-flex justify-content-end mt-2"
                                                                style={{ color: "#185BC5", fontSize: "15px" }}
                                                            >
                                                                {t("enroll.status")}
                                                            </p>
                                                        </div>
                                                        <div className="col justify-content-start ">
                                                            <div
                                                                className="text-dark rounded p-2"
                                                                style={{
                                                                    width: "200px",
                                                                    height: "32px",
                                                                    fontSize: "15px",
                                                                    fontWeight: "bold",
                                                                    backgroundColor: detail.mainStatusColor,
                                                                }}
                                                            >
                                                                <p className="d-flex justify-content-center" style={{color: '#fff'}}>
                                                                    {detail.mainStatusName}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="d-flex row ">
                                                        <div className="col-4">
                                                            <p
                                                                style={{
                                                                    color: "#185BC5",
                                                                    fontSize: "15px",
                                                                    textAlign: "right",
                                                                }}
                                                                className="d-flex justify-content-end"
                                                            >
                                                                {t("enroll.school")}
                                                            </p>
                                                        </div>
                                                        <div
                                                            className="col justify-content-start"
                                                            style={{ fontWeight: "bold", fontSize: "15px" }}
                                                        >
                                                            <p className="text-dark">{detail.schoolLongName}</p>
                                                        </div>
                                                    </li>
                                                    <li className="d-flex row ">
                                                        <div className="col-4">
                                                            <p
                                                                style={{
                                                                    color: "#185BC5",
                                                                    fontSize: "15px",
                                                                    textAlign: "right",
                                                                }}
                                                                className="d-flex justify-content-end"
                                                            >
                                                                {t("enroll.profession")}
                                                            </p>
                                                        </div>
                                                        <div
                                                            className="col justify-content-start"
                                                            style={{ fontWeight: "bold", fontSize: "15px" }}
                                                        >
                                                            <p className="text-dark">{detail.programName}</p>
                                                        </div>
                                                    </li>
                                                    <li className="d-flex row ">
                                                        <div className="col-4">
                                                            <p
                                                                style={{
                                                                    color: "#185BC5",
                                                                    fontSize: "15px",
                                                                    textAlign: "right",
                                                                }}
                                                                className="d-flex justify-content-end"
                                                            >
                                                                {t("enroll.createdDate")}
                                                            </p>
                                                        </div>
                                                        <div
                                                            className="col justify-content-start"
                                                            style={{ fontWeight: "bold", fontSize: "15px" }}
                                                        >
                                                            <p className="text-dark">{detail.createdDate}</p>
                                                        </div>
                                                    </li>
                                                    <li className="d-flex row">
                                                        {detail.athleteStatusId != null && detail.athletes && detail.athletes.length > 0 && 
                                                            (
                                                                <>
                                                                    <CandidateProcessTitle
                                                                        title="Ур чадвар"
                                                                        givenColorCode={detail?.athleteStatusColor}
                                                                        givenStatusName={detail?.athleteStatusName}
                                                                    />
                                                                    <CandidateProcessInfo
                                                                        data={detail.athletes || []}
                                                                        titleName="name"
                                                                        scoreName="score"
                                                                        statusName="isPass"
                                                                        isAthlete={true}
                                                                    />
                                                                    {modifiedLogs.current.length !== 0 &&
                                                                        modifiedLogs.current["ATHLETE"] != null && (
                                                                            <CandidateProcessDetail
                                                                                data={modifiedLogs.current["ATHLETE"]}
                                                                                timestampName="createdDate"
                                                                                textName="name"
                                                                                descriptionName="description"
                                                                            />
                                                                        )}
                                                                </>
                                                            )
                                                        }
                                                    </li>
                                                </ul>
                                            </div>
                                            {!isLoading && (
                                                <div className="d-flex flex-column justify-content-center align-items-center">
                                                    <div style={{ width: "100%" }}>
                                                        {programChange.current != null &&
                                                            Object.keys(programChange.current).length !== 0 && (
                                                                <div
                                                                    className="mt15 column"
                                                                    style={{
                                                                        marginLeft: "30px",
                                                                        marginRight: "30px",
                                                                        paddingTop: "10px",
                                                                        fontSize: "12px",
                                                                        borderRadius: "8px",
                                                                        alignItems: "center",
                                                                        justifyContent: "space-evenly",
                                                                        backgroundColor: "#D9D9D9",
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            flex: "row",
                                                                            display: "flex",
                                                                            marginBottom: "5px",
                                                                        }}
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                flex: 5,
                                                                                display: "flex",
                                                                                flexDirection: "row",
                                                                                overflowWrap: "break-word",
                                                                            }}
                                                                        >
                                                                            <p
                                                                                style={{
                                                                                    flex: 1.35,
                                                                                    margin: "0px",
                                                                                    textAlign: "end",
                                                                                }}
                                                                            >
                                                                                {programChange.current["createdDate"]}
                                                                            </p>

                                                                            <p
                                                                                style={{
                                                                                    flex: 0.65,
                                                                                    margin: "0px",
                                                                                    paddingLeft: "10px",
                                                                                    paddingRight: "10px",
                                                                                }}
                                                                            >
                                                                                {programChange.current["userName"]}
                                                                            </p>
                                                                            <div
                                                                                style={{
                                                                                    flex: 3,
                                                                                    marginRight: "7px",
                                                                                }}
                                                                            >
                                                                                <p
                                                                                    style={{
                                                                                        flex: 3,
                                                                                        margin: "0px",
                                                                                        textAlign: "start",
                                                                                    }}
                                                                                >
                                                                                    {`Хөтөлбөр: ${programChange.current.programName != null
                                                                                        ? programChange.current.programName
                                                                                        : ""
                                                                                        } -> ${detail.programName != null
                                                                                            ? detail.programName
                                                                                            : ""
                                                                                        }`}
                                                                                </p>
                                                                                <p>
                                                                                    {programChange.current["description"] == null
                                                                                        ? ""
                                                                                        : programChange.current["description"]}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        {detail?.eyeshValues && detail?.eyeshValues.length > 0 && (
                                                            <>
                                                                {detail?.eyeshValues.map((eyesh) => (
                                                                    <CandidateProcessTitle
                                                                        key={eyesh.id}
                                                                        count={eyesh.score}
                                                                        status={eyesh.isPass}
                                                                        title={eyesh.subjectName}
                                                                    />
                                                                ))}
                                                                {modifiedLogs.current.length !== 0 &&
                                                                    modifiedLogs.current["EYESH"] != null && (
                                                                        <CandidateProcessDetail
                                                                            data={modifiedLogs.current["EYESH"]}
                                                                            timestampName="createdDate"
                                                                            textName="name"
                                                                            descriptionName="description"
                                                                        />
                                                                    )}
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </Fragment>
                                    )
                                })
                            }
                        </div>
                    </div>
                </Card.Body>    
            </Card>
        </>
    );
};

export default CandidateProfile;
