import React, {useEffect, useState} from 'react'
import DTable from '../../../modules/DataTable/DTable'
import {Card} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {setLoading} from "../../../../redux/action";
import {fetchRequest} from "../../../../utils/fetchRequest";
import {regulationIndex, studentRegulationInit} from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import {useDispatch, useSelector} from "react-redux";

export const OfficerScholarship = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        const params = {
            school: schoolId,
            type: 'SCHOLARSHIP'
        };

        init(params)
    }, []);

    const init = (params) => {
        dispatch(setLoading(true));
        fetchRequest(regulationIndex, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    let regulations = data?.regulations || [];
                    if(regulations && regulations.length > 0){
                        for(let i = 0; i < regulations.length; i++){
                            if(regulations[i].regulationDate && regulations[i].regulationDate.date){
                                regulations[i].regulationDate = regulations[i].regulationDate.date.substring(0, 10)
                            }
                        }
                    }
                    setTableData(regulations);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch((e) => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })



    };

    const config = {
        showPagination: false,
        showFilter: false,
        tableMarginLess: true
    };

    const columns = [
        {
            dataField: 'regulationDate',
            text: t('description.forceDate'),
            sort: true,
        },
        {
            dataField: 'name',
            text: t('description.ruleName'),
            sort: true,
        },
        {
            dataField: '',
            text: '',
            style: {padding: 3},
            headerStyle: () => {
                return {
                    width: 65
                }
            },
            formatter(cell) {
                return (
                    <button className='btn btn-outline-success btn-shadow'>{t('common.view')}</button>
                )
            },
        },
    ];

    return (
        <Card>
            <Card.Body>
                <DTable
                    config={config}
                    columns={columns}
                    data={tableData}
                    selectMode={'radio'}
                />
            </Card.Body>
        </Card>
    )
};
