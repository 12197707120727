import { Card } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import DTable from "../../../modules/DataTable/DTable";
import EnrollSearchComponent from "../components/enrollSearchComponent";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import { candidateRegistrationPayments } from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import Select from "app/modules/Form/Select";
import moment from "moment";

const RegistrationPayment = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector((state) => state?.selectedSchool?.id || null);

    const [schools, setSchools] = useState([]);
    const [seasons, setSeasons] = useState([]);

    const [data, setData] = useState([]);
    const [totalCount, setTotalCount] = useState(0)

    const [selectedSubSchool, setSelectedSubSchool] = useState(null)
    const [selectedSeason, setSelectedSeason] = useState(null)

    useEffect(() => {
        const params = {
            school: schoolId,
        };
        getData(params);
    }, []);

    const getData = (params) => {
        dispatch(setLoading(true));
        fetchRequest(candidateRegistrationPayments, "GET", params)
            .then((res) => {
                if (res.success) {
                    const { data } = res;
                    if (data?.list) {
                        setData(data.list);
                    }
                    setTotalCount(data?.totalCount)
                    setSchools(data?.subSchools)
                    if (data?.seasons && data?.seasons.length > 0) {
                        setSeasons(data?.seasons.map(seasonObj => {
                            return {
                                value: seasonObj?.id,
                                text: seasonObj?.name
                            }
                        }))
                    } else {
                        setSeasons([])
                    }
                    
                } else {
                    message(res?.data.message || t("errorMessage.title"));
                }
                dispatch(setLoading(false));
            })
            .catch((e) => {
                dispatch(setLoading(false));
                message(t("errorMessage.title"));
            });
    };

    const columns = [
        {
            dataField: "createdDate.date",
            text: t("common.date"),
            sort: true,
            formatter: (cellContent) => {
                return moment(cellContent).format("YYYY-MM-DD");
            },
        },
        {
            dataField: "candidateNo",
            text: "ID",
            sort: false,
        },
        {
            dataField: "firstName",
            text: "Нэр",
            sort: true,
        },
        {
            dataField: "qpayPaymentId",
            text: "qPay ID",
            sort: false,
        },
        {
            dataField: "gMobileNumber",
            text: "Утасны дугаар",
            sort: true,
        },
    ];

    const onUserInteraction = (searchParams) => {
        const params = {
            school: schoolId,
            page: searchParams?.page,
            pageSize: searchParams?.pageSize,
            search: searchParams?.search,
            sort: searchParams?.sort,
            orderBy: searchParams?.order
        }
        getData(params)
    };

    const search = (params) => {
        params.school = schoolId;
        getData(params);
    };

    return (
        <>
            <div className="mx-auto w-100">
                {/* <EnrollSearchComponent onSearch={search} schools={schools} /> */}
                <Card>
                    <div className="my-form d-flex pt-6 pb-8 justify-content-center align-items-center flex-wrap">
                        <div>
                            <div>
                                <form>
                                    <div style={{ display: 'flex', marginTop: '0.8rem' }}>
                                        <label
                                            style={{
                                                display: 'flex',
                                                flex: 1,
                                                justifyContent: 'flex-end',
                                                alignItems: 'center',
                                                marginRight: 10,
                                                marginBottom: 0,
                                                width: 'auto',
                                            }}
                                        >
                                            {'Сургууль'}
                                        </label>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flex: 1,
                                                flexDirection: 'column',
                                                marginLeft: 10,
                                                width: 200,
                                            }}
                                        >
                                            <Select
                                                className={'default-input-border'}
                                                value={selectedSubSchool}
                                                searchable
                                                options={schools}
                                                onChange={(e) => {
                                                    setSelectedSubSchool(e)
                                                    const params = {
                                                        school: schoolId,
                                                        subSchool: e
                                                    };
                                                    getData(params);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', marginTop: '0.8rem' }}>
                                        <label
                                            style={{
                                                display: 'flex',
                                                flex: 1,
                                                justifyContent: 'flex-end',
                                                alignItems: 'center',
                                                marginRight: 10,
                                                marginBottom: 0,
                                                width: 'auto',
                                            }}
                                        >
                                            {'Улирал'}
                                        </label>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flex: 1,
                                                flexDirection: 'column',
                                                marginLeft: 10,
                                                width: 200,
                                            }}
                                        >
                                            <Select
                                                className={'default-input-border'}
                                                value={selectedSeason}
                                                searchable
                                                options={seasons}
                                                onChange={(e) => {
                                                    setSelectedSeason(e)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', marginTop: '0.8rem', paddingLeft: 20 }}>
                                        <button
                                            type="button"
                                            variant="text"
                                            className="btn bg-warning"
                                            onClick={() => {
                                                const params = {
                                                    school: schoolId,
                                                    subSchool: selectedSubSchool,
                                                    season: selectedSeason
                                                };
                                                getData(params);
                                            }}
                                        >
                                            <i className="flaticon-search-1" style={{ color: "#000" }}></i>
                                            <span style={{ color: "#000" }}>
                                                {t("common.search").toUpperCase()}
                                            </span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
            <div className="mx-auto mt-3">
                <Card>
                    <Card.Body>
                        <DTable
                            remote
                            columns={columns}
                            data={data}
                            config={{
                                showPagination: true,
                                excelExport: true,
                                excelFileName: "Бүртгэлийн хураамж",
                            }}
                            totalDataSize={totalCount}
                            onInteraction={onUserInteraction}
                        />
                    </Card.Body>
                </Card>
            </div>
        </>
    );
};

export default RegistrationPayment;
