import React, { useState, useRef, useEffect } from 'react';
import Forms from "../../../modules/Form/Forms";
import { fetchRequest } from "../../../../utils/fetchRequest";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../../redux/action";
import { useTranslation } from "react-i18next";
import message from "../../../modules/message";
import { getSchoolClassAdd, getAcademicPrograms, getPrograms } from "../../../../utils/fetchRequest/Urls";
import { Button, Card } from "react-bootstrap";
import {Link, useHistory, useLocation} from "react-router-dom";

const ClassAdd = ({
    location: { state: {
        id = null,
    } = {} } = {}, }) =>
{
    const forms = useRef(null);
    const forms2 = useRef(null);
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const { t } = useTranslation();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [ departments, setDepartments ] = useState([]);
    const [ departmentId, setDepartmentId ] = useState(null);
    const [ academicPrograms, setAcademicPrograms ] = useState([]);
    const [ academicProgramId, setAcademicProgramId ] = useState(null);
    const [ programs, setPrograms ] = useState([]);
    const [ teachers, setTeachers ] = useState([]);
    const [ approaches, setApproaches ] = useState([]);
    const [ schoolShifts, setSchoolShifts ] = useState([]);
    const [ selectedProgram, setSelectedProgram ] = useState([]);
    const [ selectedSeason, setSelectedSeason] = useState(location.state && location.state.id ? location.state.id : null);

    useEffect(() => {
        const params = {
            school: schoolId,
            season: selectedSeason,
        };
        dispatch(setLoading(true));
        fetchRequest(getSchoolClassAdd, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;

                    if (data?.departments?.length)
                    {
                        setDepartments(data.departments);
                    }
                    if (data?.teachers?.length)
                    {
                        setTeachers(data.teachers);
                    }
                    if (data?.approaches?.length)
                    {
                        setApproaches(data.approaches.map(approach => ({ value: approach.id, text: approach.name })))
                    }
                    if (data?.schoolShifts?.length)
                    {
                        setSchoolShifts(data.schoolShifts || [])
                    }
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }, []);

    useEffect(() => {
        forms?.current?.updateFields && forms.current.updateFields(fields);
    }, [departments, programs, academicPrograms, departmentId, academicProgramId]);

    useEffect(() => {
        forms2?.current?.updateFields && forms2.current.updateFields(fields2);
    }, [approaches, teachers, schoolShifts]);

    const onDepartmentChange = (id, evt) => {
        if (id) {
            setDepartmentId(id);
            setAcademicProgramId(null);
            setPrograms([]);
            setAcademicPrograms([]);
            const params = {
                school: schoolId,
                department: id,
                season: selectedSeason,
            };
            dispatch(setLoading(true));
            fetchRequest(getAcademicPrograms, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res;
                        if (data?.academicPrograms?.length) {
                            setAcademicPrograms(data.academicPrograms.map(academicProgram => ({ value: academicProgram.id, text: academicProgram.name + ' (' + academicProgram.code + ')' })));
                        }
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        }
    };

    const onAcademicProgramChange = (id, evt) => {
        if (id) {
            setPrograms([]);
            setAcademicProgramId(id);
            const params = {
                school: schoolId,
                department: departmentId,
                academicProgram: id,
                season: selectedSeason,
            };
            dispatch(setLoading(true));
            fetchRequest(getPrograms, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res;
                        setPrograms(data.programs)
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                })
        }
    };

    const onProgramChange = (id, evt) => {
        if (id) {
            let selectedProgram = programs.find(program => (program.value === id));
            setSelectedProgram(selectedProgram);
        }
    };

    const fields = [
        {
            label: `${t('school.parentDepartment')}*`,
            type: 'dropdown',
            required: true,
            value: departmentId,
            multiple: false,
            options: departments,
            onChange: onDepartmentChange,
            clearable: true
        },
        {
            label: `${t('curriculum.title')}*`,
            type: 'dropdown',
            required: true,
            value: academicProgramId,
            multiple: false,
            options: academicPrograms,
            onChange: onAcademicProgramChange,
            clearable: true
        },
        {
            label: `${t('curriculum.profession')}*`,
            type: 'dropdown',
            required: true,
            value: null,
            multiple: false,
            options: programs,
            onChange: onProgramChange,
            clearable: true
        },
    ];

    const fields2 = [
        {
            label: `${t('movement.class.consultantTeacher')}*`,
            type: 'dropdown',
            required: true,
            value: null,
            multiple: false,
            options: teachers,
            clearable: true
        },
        {
            label: `${t('movement.class.admissionCount')}`,
            type: 'numberOnly',
            required: false,
            value: '',
        },
        {
            label: `${t('movement.class.approach')}*`,
            type: 'dropdown',
            required: true,
            value: null,
            multiple: false,
            options: approaches,
            clearable: true
        },
        {
            label: `${t('movement.class.shift')}*`,
            type: 'dropdown',
            required: true,
            value: null,
            multiple: false,
            options: schoolShifts,
            clearable: true
        },
    ];

    const handleSaveClick = () => {
        const [ formValid, formValues ] = forms.current.validate();
        const [ form2Valid, form2Values ] = forms2.current.validate();
        if (formValid && form2Valid && selectedSeason && selectedSeason.toString().split('_').length === 4) {
            let selectedSeasonArray = selectedSeason.toString().split('_');
            let params = {
                school: schoolId,
                department: formValues?.[0]?.value,
                academicProgram: formValues?.[1]?.value,
                program: formValues?.[2]?.value,
                teacher: form2Values?.[0]?.value,
                limit: form2Values?.[1]?.value,
                approach: form2Values?.[2]?.value,
                schoolShift: form2Values?.[3]?.value,
                level: selectedSeasonArray[1],
                season: selectedSeasonArray[3],
            };

            dispatch(setLoading(true));
            fetchRequest(getSchoolClassAdd, 'POST', params, false)
                .then(response => {
                    if (response.success) {
                        message(response.data.message, true);
                        history.push({
                            pathname: '/movement/class',
                            state: {
                                id: selectedSeason,
                            }
                        });
                    } else {
                        message(response?.data?.message, false)
                    }
                })
                .catch(() => {
                    dispatch(setLoading(false));
                })
        }
    };

    return (
        <Card>
            <Card.Body>
                <div className='mb-20'>
                    <div className='row'>
                        <div className='col-12'>
                            <Forms
                                ref={forms}
                                fields={fields}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-3'/>
                        <div className='col-9'>
                            {
                                selectedProgram
                                ?
                                    <table className='mt-10 mb-5'>
                                        <tbody>
                                            <tr>
                                                <td className='text-right pr-10'>{t('curriculum.educationLevel')}</td>
                                                <th>{selectedProgram.educationLevelName}</th>
                                            </tr>
                                            <tr>
                                                <td className='text-right pr-10'>{t('curriculum.approach')}</td>
                                                <th>{selectedProgram.approachName}</th>
                                            </tr>
                                            <tr>
                                                <td className='text-right pr-10'>{t('curriculum.type')}</td>
                                                <th>{selectedProgram.typeName}</th>
                                            </tr>
                                            <tr>
                                                <td className='text-right pr-10'>{t('curriculum.totalCredits')}</td>
                                                <th>{selectedProgram.totalCredit}</th>
                                            </tr>
                                        </tbody>
                                    </table>
                                : null
                            }
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12'>
                            <Forms
                                ref={forms2}
                                fields={fields2}
                            />
                        </div>
                    </div>
                </div>
            </Card.Body>
            <Card.Footer>
                <div className='text-center'>
                    <Link
                        to='/movement/class'
                        className="btn btn-link bolder"
                    >
                        {t('common.back')}
                    </Link>
                    <Button
                        variant="success btn-shadow"
                        onClick={handleSaveClick}
                    >
                        {t('common.save')}
                    </Button>
                </div>
            </Card.Footer>
        </Card>
    )
};

export default ClassAdd;