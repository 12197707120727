import React, { useEffect, useState } from 'react';
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import message from "../../../modules/message";
import { movementSchoolBetweenEdit, movementSchoolBetweenAdd } from "../../../../utils/fetchRequest/Urls";
import Select from "../../../modules/Form/Select";
import { TextareaAutosize } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import Instruction from "../../../modules/instruction";
import StudentInfoTable from '../StudentInfoTable';

const AddMovement = ({
    location: { state: {
        schools = [],
        level = '',
        movement = null
    } = {} } = {}, }) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    const [code, setCode] = useState('');
    const [student, setStudent] = useState({});
    const [classrooms, setClassrooms] = useState([]);
    const [classroom, setClassroom] = useState(null);
    const [description, setDescription] = useState('');
    const [school, setSchool] = useState()
    const [students, setStudents] = useState([])
    const [selectedStudent, setSelectedStudent] = useState()

    const handleCodeChange = e => {
        setCode(e.target.value);
        setStudent({});
        setClassroom(null);
    }

    const onStudentChange = (value) => {
        setSelectedStudent(value)
        if (value) {
            const tempData = students.find(s => s.value === value)
            setStudent(tempData)
        }
    }

    const handleBlur = () => {
        const params = {
            school: schoolId,
            studentCode: code,
            selectedSchool: school,
            educationLevel: level
        }
        dispatch(setLoading(true));
        fetchRequest(movementSchoolBetweenAdd, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    const tempData = []
                    if (data?.students?.length) {
                        data.students?.map(s => {
                            tempData.push({
                                ...s,
                                value: s.id,
                                // text: s.firstName + ' - / ' + s.lastName + ' /',
                                text: s.code,
                                class: s.programName,
                                registerNumber: s.registrationNumber
                            })
                        })
                    }
                    if (tempData.length === 1) {
                        setStudent(tempData[0])
                        setSelectedStudent(tempData[0].value)
                    }
                    setStudents(tempData);
                    if (data?.classes?.length) {
                        setClassrooms(data?.classes.map(classObj => ({ value: classObj.value, text: classObj.academicProgramName + ' - ' + classObj.programName })) || [])
                    }
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const handleClassroomChange = value => {
        setClassroom(value)
    };

    const handleDescriptionChange = e => {
        setDescription(e.target.value);
    };

    const onSave = () => {
        let error = false;
        if (!description) {
            error = true;
            message('Тайлбар оруулна уу', false);
        }
        if (!classroom) {
            error = true;
            message('Шилжих анги оруулна уу', false);
        }
        if (!error) {
            let url = movementSchoolBetweenAdd

            let params = {
                school: schoolId,
                description,
                class: classroom,
                student: student?.id,
                selectedSchool: school,
                educationLevel: level
            }

            if (movement) {
                url = movementSchoolBetweenEdit
                params = {
                    school: schoolId,
                    description,
                    class: classroom,
                    movement
                }
            }

            dispatch(setLoading(true));
            fetchRequest(url, 'POST', params)
                .then(res => {
                    if (res.success) {
                        history.push({
                            pathname: '/movement/int',
                            state: {
                                tab: 'school'
                            }
                        });
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        }
    };

    useEffect(() => {
        if (!movement && typeof level === 'string') {
            if (!level || level.substring(0, 6) == 'season') {
                history.push({
                    pathname: '/movement/int',
                    state: {
                        tab: 'school'
                    }
                });
                message(t('errorMessage.selectEducationLevel'))
            }
        }
    }, [level])

    useEffect(() => {
        if (movement) {
            const params = {
                school: schoolId,
                movement,
            }
            dispatch(setLoading(true));
            fetchRequest(movementSchoolBetweenEdit, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res;
                            setStudent(data?.student)
                            setClassrooms(data?.classes)
                        if (data?.movement) {
                            setSchool(data?.movement.school)
                            setDescription(data?.movement.description)
                            setClassroom(data?.movement.class)
                        }
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        }
    }, [movement])

    return (
        <Card>
            <Card.Body>
                <div className='text-right'>
                    <Instruction

                    />
                </div>
                <div className='row'>
                    <div className='col-3' style={{ display: 'flex', alignSelf: 'center', justifyContent: 'flex-end' }}>
                        {t('school.title') + '*'}
                    </div>
                    <div className='col-3'>
                        <Select
                            searchable
                            disabled={movement ? true : false}
                            value={school}
                            onChange={value => {
                                setSchool(value)
                                setStudents([])
                                setStudent(null)
                            }}
                            options={schools}
                        />
                    </div>
                </div>
                {
                    movement
                        ?
                        null
                        :
                        <div className='row mt-3'>
                            <div className='col-3' style={{ display: 'flex', alignSelf: 'center', justifyContent: 'flex-end' }}>
                                {
                                    students.length
                                        ?
                                        t('student.title') + '*'
                                        : t('student.code') + '*'
                                }
                            </div>
                            <div className='col-3'>
                                {
                                    students.length
                                        ?
                                        <Select
                                            searchable
                                            value={selectedStudent}
                                            onChange={onStudentChange}
                                            options={students}
                                        />
                                        : <input
                                            className='form-control'
                                            onChange={handleCodeChange}
                                            value={code}
                                            onBlur={handleBlur}
                                        />
                                }
                            </div>
                        </div>
                }
                <div className='row'>
                    <div className='col-2'>

                    </div>
                    <div className='col-10'>
                        <StudentInfoTable student={student} />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-3' style={{ display: 'flex', alignSelf: 'center', justifyContent: 'flex-end' }}>
                        {t('Хүлээн авах анги') + '*'}
                    </div>
                    <div className='col-3'>
                        <Select
                            searchable
                            value={classroom}
                            onChange={handleClassroomChange}
                            options={classrooms}
                        />
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-3' style={{ display: 'flex', alignSelf: 'center', justifyContent: 'flex-end' }}>
                        {t('common.description') + '*'}
                    </div>
                    <div className='col-6'>
                        <TextareaAutosize
                            className='msue-textarea-description'
                            value={description}
                            onChange={handleDescriptionChange}
                            rows={5}
                        />

                    </div>
                </div>
            </Card.Body>
            <Card.Footer>
                <div className='text-center'>
                    <Link
                        to={'/movement/int'}
                        className='btn btn-link bolder'
                    >
                        {t('common.back')}
                    </Link>
                    <button
                        className='btn btn-success'
                        onClick={onSave}
                    >
                        {t('common.save')}
                    </button>
                </div>
            </Card.Footer>
        </Card>
    );
};

export default AddMovement;