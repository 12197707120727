import React from 'react';
import { useTranslation } from "react-i18next";
import DTable from "../../../../modules/DataTable/DTable";
import { Link } from "react-router-dom";

const Connected = ({statements}) => {
    const { t } = useTranslation();

    const config = {
        showPagination: true,
        showFilter: true,
        footer: true,
        footerStyle: {backgroundColor: '#ebecf5'}
    };

    const columns = [
        {
            dataField: "transactionDate",
            text: t('finance.transactionDate'),
            sort: true,
            footer: ''
        },
        {
            dataField: "income",
            text: t('finance.incomeAmount'),
            style: {textAlign: 'right'},
            sort: true,
            footer: '',
            footerType: 'sum',
            footerAlign: 'right',
        },
        {
            dataField: "description",
            text: t('finance.description'),
            sort: true,
            footer: ''
        },
        {
            dataField: "typeName",
            text: t('common.type'),
            sort: true,
            footer: ''
        },
        {
            dataField: "studentCode",
            text: t('student.code'),
            sort: true,
            footer: '',
            formatter(cell, row, index) {
                return (
                    <div className='msue-dt-clickable-cell' onClick={() => studentCodeClick(cell, row, index)}>
                        {cell}
                    </div>
                )
            },
        },
        {
            dataField: "studentName",
            text: t('student.name'),
            sort: true,
            footer: ''
        },
        {
            dataField: "connectedUser",
            text: t('common.connectedUser'),
            sort: true,
            footer: ''
        },
        {
            dataField: "connectedDate",
            text: t('common.connectedDate'),
            sort: true,
            footer: ''
        }
    ];

    const studentCodeClick = (cell, row, index) => {

    };

    return (
        <div>
            <DTable
                data={statements}
                columns={columns}
                config={config}
                selectMode={'radio'}
            />
        </div>
    );
};

export default Connected;