import * as React from 'react';
import { useTranslation } from 'react-i18next';
// import '../styles/app.css';
import CreateSurveyContainer from '../containers/Survey/Create';

export const OfficerSurveyCreatePage = (props) => {
    const { t } = useTranslation();

    return (
        <div>
            <CreateSurveyContainer />
        </div>
    );
};

export default OfficerSurveyCreatePage;