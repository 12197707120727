export const TRANSLATIONS_MN = {
    action: {
        edit: 'Засах',
        datatable_info: 'Нийт _TOTAL_ илэрцийн _START_-с _END_-г харуулж байна',
        delete: 'Устгах',
        loading: 'Ачааллаж байна',
        next: 'Дараагийнх',
        post: 'Нийтлэх',
        publish: 'Баталгаажуулах',
        publishAll: 'Бүгдийг баталгаажуулах',
        download: 'Татаж авах',
        unPublish: 'Баталгаажуулах цуцлах',
        recalculate: 'Дахин бодох',
        search: 'Хайлт хийх...',
        setInactive: 'Хүчингүй болгох',
        setActive: 'Идэвхтэй болгох',
        typeHere: 'Энд бичнэ үү',
        undo: 'Буцаах',
        view: 'Үзэх',
        back: 'Буцах',
        selectionOnePublish: 'Сонголт 1 баталгаажуулах',
    },
    assessment: {
        eachCredit: 'Нэг кредитийн үнэлгээ',
        gpa: 'Голч',
        maxPercent: 'Дээд хувь',
        minPercent: 'Доод хувь',
        quality: 'Чанар',
        rate: 'Эзлэх хувь',
        success: 'Амжилт',
        title: 'Үнэлгээ',
        totalCredit: 'Нийт кредит',
        totalGPA: 'Нийт голч',
        selection: 'Сонголт',
        selectStTemplate: 'Үнэлгээний бүтэц сонгох',
        specification: 'Үнэлгээний үзүүлэлт',
        stTemplate: 'Үнэлгээний бүтэц',
        score: 'Авах оноо',
        totalScore: 'Нийт оноо',
        totalAssessment: 'Нийт үнэлгээ',
        tuition: 'Төлбөр',
        takenScore: 'Авсан оноо',
        notSelected: 'Тухайн хичээл дээр үнэлгээний бүтцийн тохиргоо хийгдээгүй байна.',
        view: 'Үнэлгээ үзэх',
    },
    auth: {
        title: 'Сургалтын удирдлага, мэдээллийн систем',
        forgotPassword: 'Нууц үг мартсан',
        login: 'Нэвтрэх',
        loginName: 'Нэвтрэх нэр',
        password: 'Нууц үг',
        recoverPassword: 'Нууц үг сэргээх',
        recoverByEmail: 'Өөрийн бүртгэлтэй и-мэйл хаяг руу авах',
        recoverByPhone: 'Оюутанд олгогдсон (97xxxxxx) дугаараар авах',
        loginMicrosoft: 'Microsoft хаягаар нэвтрэх',
        loginDanOne: 'Танилт нэвтрэлтийн',
        loginDanTwo: 'Нэгдсэн систем',
        getRecoverCode: 'Сэргээх код авах',
        verificationCode: 'Баталгаажуулах код',
        verificationCodeDescription: 'Өөрийн бүртгэлтэй и-мэйл хаяг болон оюутанд олгогдсон (97xxxxxx) илгээсэн баталгаажуулах код оруулна уу',
        newPassword: 'Шинэ нууц үг',
        oldPassword: 'Хуучин нууц үг',
        repeatNewPassword: 'Шинэ нууц үг давтах',
        loginError: 'Хэрэглэгчийн нэр эсвэл нууц үг буруу байна'
    },
    attendance: {
        attendanceDay: 'Ирц бүртгэх огноо',
        add: 'Ирц бүртгэх',
        absent: 'Чөлөөтэй',
        absentCode: 'Ч',
        absentHour: 'Чөлөөтэй цаг',
        absentRegistration: 'Чөлөө бүртгэл',
        came: 'Ирсэн',
        cameCode: 'И',
        cameHour: 'Ирсэн цаг',
        cameStudents: 'Ирсэн сурагчид',
        cameStudentHour: 'Ирсэн сурагч | цаг',
        getPreviousTimeReport: 'Өмнөх цагийн мэдээлэл татах',
        late: 'Хоцорсон',
        lateCode: 'Х',
        lateHour: 'Хоцорсон цаг',
        nonAttendance: 'Тасалсан',
        nonAttendanceCode: 'Т',
        nonAttendanceHour: 'Тасалсан цаг',
        notSent: 'Илгээгээгүй',
        registeredHour: 'Бүртгэсэн цаг',
        report: 'Ирцийн мэдээ',
        sendTimeReport: 'Цагийн мэдээ илгээх',
        sent: 'Илгээсэн',
        sentDay: 'Илгээсэн өдөр',
        sentHour: 'Илгээсэн цаг',
        sentHourShort: 'Илгэ.цаг',
        sentTimeReport: 'Ирцийн мэдээ илгээсэн',
        sick: 'Өвчтэй',
        sickCode: 'Ө',
        sickHour: 'Өвчтэй цаг',
        studentHour: 'сурагч / цаг',
        totalStudentHour: 'Нийт сурагч | цаг',
        title: 'Ирц',
        type: 'Ирцийн төрөл',
        hours: 'Цагууд',
        isReasonable: 'Үндэслэлтэй эсэх',
        no: '-р долоо хоног',
    },
    calendar: {
        title: 'Сургуулийн календарь',
        color: 'Өнгө',
        add_activity: 'Үйл ажиллагаа нэмэх',
        no_event: 'Үйл ажиллагаа олдсонгүй',
        activity: 'Үйл ажиллагаа',
        activity_name: 'Үйл ажиллагааны нэр',
        start_end_date: 'Эхлэх, дуусах огноо',
        start_end_time: 'Эхлэх, дуусах',
        is_full_day: 'Бүтэн өдөр үргэлжлэх эсэх',
        length: 'Үргэлжлэх цаг',
        description: 'Тайлбар',
        today: 'Өнөөдөр',
        view_event: 'Үйл ажиллагаа үзэх',
        start_date: 'Эхлэх огноо',
        end_date: 'Дуусах огноо',
        edit_event: 'Үйл ажиллагаа засах',
        month: 'Сар',
        week: '7 хоног',
        day: 'Өдөр',
        list: 'Жагсаалт',
        all_day: 'Бүтэн өдөр',
        enter_event_name: 'Үйл ажиллагааны нэр оруулна уу.',
        enter_start_date: 'Эхлэх огноо оруулна уу',
        enter_end_date: 'Дуусах огноо оруулна уу',
        enter_start_time: 'Эхлэх цаг оруулна уу',
        enter_end_time: 'Дуусах цаг оруулна уу',
        time_duplicate: 'Цаг давхцаж байна',
    },
    common: {
        all: 'Бүгд',
        addEntry: "Элсэлт нэмэх",
        add: 'Нэмэх',
        answer: 'Хариулт',
        active: 'Идэвхтэй',
        inactive: 'Идэвхгүй',
        amount: 'Дүн',
        back: 'Буцах',
        backTo_list: 'Жагсаалт руу буцах',
        balance: 'Үлдэгдэл',
        belongsTo: 'Харьяалах',
        cancel: 'Цуцлах',
        cause: 'Шалтгаан',
        change: 'Өөрчлөх',
        code: 'Код',
        color: 'Өнгө',
        copy: 'Хуулах',
        confirmDate: 'Баталгаажуулсан огноо',
        confirmUser: 'Баталгаажуулсан хэрэглэгч',
        connected: 'Холбосон',
        connectedUser: 'Холбосон хэрэглэгч',
        connectedDate: 'Холбосон огноо',
        create: 'Үүсгэх',
        createdDate: 'Бүртгэсэн огноо',
        createdUser: 'Бүртгэсэн хэрэглэгч',
        className: 'Анги',
        clear: 'Цэвэрлэх',
        country: 'Улс',
        close: 'Хаах',
        closeDate: 'Хаах огноо',
        changeType: 'Төлөв өөрчлөх',
        changePassword: 'Нууц үг солих',
        currentType: 'Одоогийн төлөв',
        clickHere: 'Энд дарах',
        coverPhoto: 'Cover зураг',
        day: 'Өдөр',
        date: 'Огноо',
        delete: 'Устгах',
        duration: 'Хугацаа',
        description: 'Тайлбар',
        edit: 'Засах',
        email: 'И-мэйл',
        employmentDate: 'Ажилд орсон огноо',
        endDate: 'Дуусах огноо',
        endTime: 'Дуусах цаг',
        exit: 'Гарах',
        familyName: 'Ургийн овог',
        firstName: 'Өөрийн нэр',
        gender: 'Хүйс',
        helper: 'Ашиглах зааварчилгааг энд дарж үзнэ үү',
        isEdit: 'Засах эсэх',
        isPublish: 'Хүчинтэй эсэх',
        language: 'Хэл',
        lastName: 'Эцэг/эхийн нэр',
        level: 'Түвшин',
        link: 'Холбоос',
        movement: 'Шилжилт хөдөлгөөн',
        month: 'Сар',
        name: 'Нэр',
        no: 'Үгүй',
        notFound: "Хуудас олдсонгүй",
        notification: 'Мэдэгдэл',
        notConnected: 'Холбоогүй',
        newPassword: 'Шинэ нууц үг',
        newRePassword: 'Шинэ нууц үг давтах',
        ordering: 'Дараалал',
        otherPhoto: 'Бусад зурагнууд',
        paidAmount: 'Төлсөн дүн',
        paidDate: 'Төлсөн огноо',
        password: 'Нууц үг',
        phoneNumber: 'Утасны дугаар',
        print: 'Хэвлэх',
        downloadProgramDtl: 'Сургалтын төлөвлөгөө татах',
        role: 'Дүр',
        readMore: 'Дэлгэрэнгүй унших',
        register: 'Бүртгэх',
        registered: 'Бүртгэсэн',
        registerNumber: 'Регистр',
        reminder: 'Түр сануулах',
        requestSend: 'Хүсэлт илгээх',
        requestType: 'Хүсэлтийн төрөл',
        remove: 'Хасах',
        recreate: 'Дахин үүсгэх',
        save: 'Хадгалах',
        see: 'Харах',
        seeAll: 'Бүгдийг үзэх',
        send: 'Илгээх',
        sendAgian: 'Дахин илгээх',
        search: 'Хайх',
        select: 'Сонгох',
        selectAll: 'Бүгдийг сонгох',
        selection: 'Сонголт',
        subject: 'Хичээл',
        success: 'Амжилттай',
        show_result: 'Үр дүнг үзэх',
        specification: 'Үзүүлэлт',
        startDate: 'Эхлэх огноо',
        startTime: 'Эхлэх цаг',
        status: 'Төлөв',
        signingDate: 'Цохолтын огноо',
        shortDescription: 'Товч тайлбар',
        time: 'Цаг',
        total: 'Нийт',
        type: 'Төрөл',
        title: 'Гарчиг',
        open: 'Нээх',
        view: 'Үзэх',
        weekday: 'Гараг',
        weekdayWithtime: 'Гараг (Цаг)',
        year: 'он',
        yes: 'Тийм',
        fullName: 'Овог нэр',
        male: 'Эрэгтэй',
        female: 'Эмэгтэй'
    },
    curriculum: {
        approach: 'Сургалтын хэлбэр',
        index: 'Индекс',
        code: 'Хөтөлбөрийн код',
        curriculumType: 'Хөтөлбөрийн төрөл',
        curriculumTypeEdit: 'Хөтөлбөрийн төрөл засах',
        codeAndName: 'Хөтөлбөр код, нэр',
        education: 'Боловсрол',
        educationLevel: 'Боловсролын зэрэг',
        educationLevelName: 'Боловсролын зэргийн нэр',
        isStudentApi: 'Элсэлтийн системээс оюутан татах эсэх',
        difficulty: 'Зэрэг',
        learningType: 'Хэлбэр',
        name: 'Хөтөлбөрийн нэр',
        profession: 'Мэргэжил',
        previousEducationLevel: 'Өмнөх боловсролын зэрэг',
        programCode: 'Мэргэжил код',
        programName: 'Мэргэжил нэр',
        programCodeAndName: 'Мэргэжил код, нэр',
        scoreTemplate: 'Үнэлгээний бүтэц',
        scoreType: 'Үнэлгээний төрөл',
        scheduleType: 'Төрөл',
        title: 'Хөтөлбөр',
        totalCredits: 'Нийт кредит',
        type: 'Сургалтын төрөл',
    },
    ebarimt: {
        status: 'И-баримт төлөв',
        date: 'И-баримт огноо',
        paymentDate: 'Төлбөрийн огноо',
    },
    datatable:{
        add: 'Нэмэх',
        edit: 'Засах',
        delete: 'Устгах',
    },
    department: {
        addPosition: 'Албан тушаал нэмэх',
        isUsedInCurriculum: 'Хөтөлбөрт ашиглагдах эсэх',
        position: 'Албан тушаал',
        title: 'Алба, нэгж',
        canNotDelete: 'Төв алба тул устгах боломжгүй',
    },
    description: {
        title: 'Тодорхойлолт',
        type: 'Тодорхойлолтын төрөл',
        date: 'Тодорхойлолт авсан огноо',
        expiredDate: 'Хүчинтэй огноо',
        to: 'Хаана',
        whereFor: 'Хаана зориулж',
        view: 'Тодорхойлолт үзэх',
        get: 'Тодорхойлолт авах',
        create: 'Тодорхойлолт үүсгэх',
        fee: 'Хураамж',
        validDays: 'Хүчинтэй хоног',
        forceDate: 'Мөрдөж эхэлсэн огноо',
        ruleName: 'Дүрэм журмын нэр',
    },
    discussion: {
        add: 'Хэлэлцүүлэг нэмэх',
        close: 'Хаалттай',
        create: 'Хэлэлцүүлэг үүсгэх',
        description: 'Хэлэлцүүлгийн агуулга',
        header: 'Хэлэлцүүлгийн гарчиг',
        open: 'Нээлттэй',
        students: 'Хэлэлцүүлэгт оролцсон оюутнууд',
        title: 'Хэлэлцүүлэг',
        type: 'Хэлэлцүүлэг төрөл',
        downloadStudents: 'Оюутан татах'
    },
    errorMessage: {
        difficulty: 'Даалгаварт орсон тул устгах боломжгүй',
        attendance: 'Тухайн өдөр хичээл заадаггүй тул ирц бүртгэх боломжгүй',
        enterFile: 'Файл оруулна уу',
        enterAmount: 'Үнэ оруулна уу',
        enterCode: 'Код оруулна уу',
        enterIndex: 'Индекс оруулна уу',
        enterCredit: 'Кредит оруулна уу',
        enterName: 'Нэр оруулна уу',
        enterSchoolName: 'Сургуулийн нэр оруулна уу',
        enterSchoolCode: 'Сургуулийн код оруулна уу',
        enterSchoolShortName: 'Сургуулийн товч нэр оруулна уу',
        enterStudentCode: 'Оюутны код оруулна уу',
        enterSubjectName: 'Хичээлийн нэр оруулна уу',
        enterSubjectCode: 'Хичээлийн код оруулна уу',
        enterScoreType: 'Үнэлгээ оруулна уу',
        enterSeasonOfStudy: 'Судлах улирал оруулна уу',
        enterFamilyName: 'Ургийн овог оруулна уу',
        enterLastName: 'Эцэг/эхийн нэр оруулна уу',
        enterFirstName: 'Өөрийн нэр оруулна уу',
        enterRegisterNumber: 'Регистр оруулна уу',
        enterPhoneNumber: 'Утасны дугаар оруулна уу',
        enterMinAndMax: '0-с 100-н хооронд утга оруулна уу',
        enterValue: 'Утга оруулна уу',
        enterMinAmount: 'Төлөх дүн 0-с их байх шаардлагатай',
        enterEmail: 'И-мэйл оруулна уу',
        enterPassword: 'Нууц үг оруулна уу',
        enterNewPassword: 'Шинэ нууц үг оруулна уу',
        enterNewRePassword: 'Шинэ нууц үг давтан оруулна уу',
        enterProfession: 'Мэргэжил оруулна уу',
        enterCurriculumSubjectType: 'Судлагдахууны төрөл оруулна уу',
        enterCurriculumCode: 'Хөтөлбөрийн код оруулна уу',
        enterCurriculumStatus: 'Хөтөлбөрийн статус оруулна уу',
        enterApproach: 'Сургалтын хэлбэр оруулна уу',
        enterSubjectCategory: 'Хичээлийн бүлэг оруулна уу',
        enterGroup: 'Бүлэг оруулна уу',
        enterSeat: 'Суудлын тоо оруулна уу',
        enterGpa: 'Голч оруулна уу',
        enterOrdering: 'Дараалал оруулна уу',
        enterCurriculumLearningType: 'Судлах төрөл оруулна уу',
        enterVerificationCode: 'Баталгаажуулах код оруулна уу',
        enterQuestionScore: 'Даалгаврын оноо оруулна уу',
        chooseSubject: 'Хичээл сонгож оруулна уу',
        selectCurriculumLearningType: 'Судлах төрөл сонгоно уу',
        selectDate: 'Огноо сонгоно уу',
        selectValue: 'Утга сонгоно уу',
        selectRelatedSchool: 'Харъяалах сургууль сонгоно уу',
        selectRelatedDepartment: 'Харъяалах тэнхим сонгоно уу',
        selectSubjectType: 'Хичээлийн төрөл сонгоно уу',
        selectSubjectCategory: 'Хичээлийн бүлэг сонгоно уу',
        selectYearOfStudy: 'Судлах жил сонгоно уу',
        selectRoles: 'Албан тушаал сонгоно уу',
        selectCountry: 'Улс сонгоно уу',
        selectGender: 'Хүйс сонгоно уу',
        selectEmploymentDate: 'Ажилд орсон огноо сонгоно уу',
        selectSubject: 'Хичээл сонгоно уу',
        selectStatus: 'Төлөв сонгоно уу',
        selectSeason: 'Улирал сонгоно уу',
        selectCurriculumType: 'Хөтөлбөрийн төрөл сонгоно уу',
        selectEducationLevel: 'Боловсролын зэрэг сонгоно уу',
        selectCurriculumName: 'Хөтөлбөрийн нэр сонгоно уу',
        selectPreEducationLevel: 'Өмнөх боловсролын зэрэг сонгоно уу',
        selectScoreTemplate: 'Үнэлгээний бүтэц сонгоно уу',
        selectScoreType: 'Үнэлгээний төрөл сонгоно уу',
        selectLevel: 'Түвшин сонгоно уу',
        selectTopic: 'Сэдэв сонгоно уу',
        selectQuestionType: 'Даалгаврын төрөл сонгоно уу',
        selectApproach: 'Сургалтын хэлбэр сонгоно уу',
        title: 'Алдаа гарлаа',
        selectTeacher: 'Хичээл заах багшийн мэдээлэл оруулна уу',
        repeatPassword: 'Тухайн оруулсан шинэ нууц үг давтан оруулсан нууц үгтэй тохирохгүй байна'
    },
    examType: {
        setting: 'Шалгалтын төрлийн тохиргоо',
        title: 'Шалгалтын төрөл',
    },
    finance: {
        account: 'Данс',
        accountName: 'Дансны нэр',
        accountNumber: 'Дансны дугаар',
        add: 'Нэхэмжлэх үүсгэх',
        addAccount: 'Данс бүртгэх',
        bank: 'Банк',
        bankCode: 'Банкны код',
        bankName: 'Банкны нэр',
        billedAmount: 'Нэхэмжилсэн дүн',
        currency: 'Валют',
        description: 'Гүйлгээний утга',
        details: 'Төлбөрийн дэлгэрэнгүй',
        pay: 'Төлөх',
        paid: 'Төлсөн',
        income: 'Орлого',
        index: 'Индекс',
        deposit: 'Зарлага',
        incomeAmount: 'Орлого дүн',
        invoiceType: 'Нэхэмжлэхийн төрөл',
        isCorporateText: 'Corporate gateway-д холбогдсон эсэх',
        isQpayText: 'qpay-д холбогдсон эсэх',
        transactionDate: 'Гүйлгээний огноо',
        transactionNumber: 'Гүйлгээний дугаар',
        qPayCode: 'qPay Invoice code',
        merchantName: 'Мерчант нэр',
        merchantCode: 'Мерчант код',
        notFound: 'Нэхэмжлэхийн мэдээлэл олдсонгүй',
        upperLimit: 'Дээд лимит',
        saleLoanConfig: 'Авлагын тохиргоо',
        governmentRegister: 'Төрийн сангийн орлого бүртгэх',
        governmentEdit: 'Бүртгэл засах',
        isSameAmount: 'Бүгд ижил дүнтэй эсэх',
        paidType: 'Төлсөн хэлбэр',
        transactionSuccess: 'Гүйлгээ амжилттай хийгдлээ.',
        transactionFailed: 'Гүйлгээ амжилтгүй.',
    },
    file: {
        add: 'Файл оруулах',
        title: 'Файл',
        type: 'Файлын төрөл'
    },
    discount: {
        amount: 'Үнийн дүн',
        title: 'Зээл, тусламж',
        type: 'Зээл, тусламжийн төрөл',
        loanType: 'Зээлийн төрөл',
    },
    group: {
        title: 'Бүлэг',
        groups: 'Бүлгүүд',
        grades: 'Хамрагдах бүлгүүд',
        selectGroup: 'Бүлэг сонгоно уу',
    },
    exam: {
        addMoreQuestion: 'Дахиж даалгавар үүсгэнэ',
        assignment: 'Даалгавар',
        difficulty: 'Түвшин',
        addDifficulty: 'Түвшин нэмэх',
        editDifficulty: 'Түвшин засах',
        hint: 'Туслах мэдээ',
        uploadFile: 'Файл оруулах',
        questionScore: 'Даалгаврын оноо',
        questionText: 'Асуултын текст',
        questionType: 'Даалгаврын төрөл',
        score: 'Оноо',
        premise: 'Тохируулах',
        premiseResponse: 'Хариулт',
        error: {
            enterQuestion: 'Асуулт оруулна уу',
            selectSubject: 'Хичээл сонгоно уу',
            selectDifficulty: 'Түвшин сонгоно уу',
            selectQuestionType: 'Асуултын төрөл сонгоно уу',
            selectTopic: 'Сэдэв сонгоно уу',
            enterScore: 'Оноо оруулна уу',
            scoreNotNumber: 'Оноо зөвхөн тооноос бүрдэх боломжтой',
        }
    },
    invoiceType: {
        ebarimt: 'И-баримт',
        ebarimtCode: 'И-баримт код',
        ebarimtServiceCode: 'И-баримтын үйлчилгээний код',
        isSaleType: 'Үйлчилгээний эрхийн төрөл үүсгэх эсэх',
        isEbarimt: 'И-баримт өгөх эсэх',
        isConstant: 'Дүн тогтмол эсэх',
        isStudentAccount: 'Оюутан дээр данс хөтлөх эсэх',
        pay: 'Төлбөр төлөх',
        paid: 'Төлбөр төлсөн',
        paymentType: 'Төлбөрийн төрөл',
        paymentCode: 'Төлбөрийн код',
        paymentName: 'Төлбөрийн нэр',
    },
    menu: {
        add: 'Меню нэмэх',
        approach: 'Сургалтын хэлбэр',
        additional: 'Нэмэлт',
        aboutSchool: 'Сургуулийн дэлгэрэнгүй',
        calendar: 'Сургалтын нэгдсэн хуанли',
        study: 'СУРГАЛТ',
        grade: 'Дүн',
        payment: 'Tөлбөр',
        request: 'Хүсэлт',
        student_request: 'Өргөдөл, хүсэлт',
        form: 'Тодорхойлолт',
        anket: 'Анкет',
        survey: 'Судалгаа',
        regulation: 'Дүрэм журам',
        department: 'Салбар, тэнхим',
        classroom: 'Анги, танхим',
        curriculum: 'Сургалтын хөтөлбөр',
        curriculumConfig: 'Хөтөлбөрийн тохиргоо',
        curriculumSubjectType: 'Судлагдахууны төрөл',
        curriculumStatus: 'Хөтөлбөрийн статус',
        curriculumType: 'Хөтөлбөрийн төрөл',
        evaluation: 'Үнэлгээ',
        exam: 'Шалгалт',
        edit: 'Меню засах',
        generalInformation: 'Ерөнхий мэдээлэл',
        home: 'Нүүр хуудас',
        homework: 'Гэрийн даалгавар',
        learningPlan: 'Сургалтын төлөвлөгөө',
        learningType: 'Судлах төрөл',
        isActive: 'Идэвхтэй эсэх',
        isMain: 'Үндсэн меню эсэх',
        masterData: 'Мастер дата',
        roles: 'Албан тушаал',
        report: 'Тайлан',
        school: 'Сургууль',
        schoolComponent: 'Бүрэлдэхүүн сургууль',
        subject: 'Хичээл',
        subjectCategory: 'Хичээлийн бүлэг',
        subjectType: 'Хичээлийн төрөл',
        structure: 'Бүтэц, зохион байгуулалт',
        schoolRoles: 'Сургуулийн албан тушаал',
        parent: 'Харьяалах меню',
        position: 'Меню байршил',
        psychologicalServices: 'Сэтгэл зүйн үйлчилгээ',
        timetable: 'Хичээлийн хуваарь',
        teacherStaff: 'Багш, ажилтан',
    },
    movement: {
        admission: 'Элсэлт',
        cause: 'Шилжилт хөдөлгөөний шалтгаан',
        class: {
            add: 'Анги нэмэх',
            addStudent: 'Оюутан нэмэх',
            admissionCount: 'Авах элсэгчийн тоо',
            admissionSeason: 'Элсэлтийн улирал',
            approach: 'Хичээллэх хэлбэр',
            consultantTeacher: 'Зөвлөх багш',
            studentCount: 'Оюутны тоо',
            teamMember: 'Оюутан холбох',
            type: 'Тэнхим',
            shift: 'Суралцах хэлбэр',
        },
        out: {
            remove: 'Оюутан хасах',
            prevClass: 'Сурч байсан анги',
            cause: 'Гарч байгаа шалтгаан',
            view: 'Сургуулиас гарсан оюутны мэдээлэл',
            date: 'Сургуулиас гарсан огноо'
        },
        missing: {
            view: 'Сураггүй оюутны мэдээлэл',
            date: 'Сураггүй болсон огноо',
            statusChange: 'Төлөв өөрчлөх'
        },
        excused: {
            year: 'Жилийн чөлөө',
            semester: 'Хагас жилийн чөлөө',
            add: 'Чөлөө бүртгэх',
            seasons: 'Чөлөөтэй улиралууд',
            excuseableSeasons: 'Чөлөөлөгдөх улиралууд',
            cause: 'Чөлөөний авсан шалтгаан',
            date: 'Чөлөө авсан огноо',
            info: 'Чөлөөний мэдээлэл',
        },
        enrolledClass: 'Орсон анги',
        enrollmentClass: 'Шилжих анги',
        enrolledSchool: 'Орсон сургууль',
        external: {
            title: '',
        },
        internal: {
            betweenSchool: 'Сургууль хоорондох шилжилт',
            betweenInfo: 'Сургууль хоорондох шилжилт мэдээлэл',
            inSchool: 'Сургууль доторх шилжилт',
            title: 'Дотоод шилжилт',
            info: 'Дотоод шилжилтийн мэдээлэл'
        },
        leftClass: 'Явсан анги',
        leftSchool: 'Явсан сургууль',
        move: 'Шилжүүлэх',
        movementCause: 'Хөдөлгөөний шалтгаан',
        movementType: 'Хөдөлгөөний төрөл',
        title: 'Шилжилт хөдөлгөөн',
        type: 'Шилжилт хөдөлгөөний төрөл',
        undo: 'Шилжилт хөдөлгөөний буцаалт',
        undoCheck: 'Та уг шилжилт хөдөлгөөнийг буцаахдаа итгэлтэй байна уу?',
        date: 'Шилжилт хөдөлгөөний огноо',
        toClass: 'Шилжин орсон анги'
    },
    newsfeed: {
        add: 'Зарлал нэмэх',
        addEvent: 'Үйл явдал нэмэх',
        title: 'Зарлал мэдээлэл',
        eventTitle: 'Удахгүй болох үйл явдал',
        published: 'Нийтэлсэн',
        notPublished: 'Нийтлээгүй',
        seeAll: 'Бүх мэдээг харах',
        otherLanguage: 'Бусад хэлээр мэдээ оруулах',
        isSchool: 'Зөвхөн тухайн сургууль дээр оруулах',
        branchCalendar: 'Салбар сургуулийн календарь',
        schoolCalendar: 'Нэгдсэн календарь',
        events: 'Үйл ажиллагаа',
        eventDates: 'Үйл ажиллагааны огноо',
        eventTimes: 'Үйл ажиллагааны цаг',
        isTime: 'Цаг оруулах эсэх',
        chooseImg: 'Зураг сонгох',
        chooseFile: 'Файл сонгох',
        fileSizeWarning: 'Файлын хэмжээ нь 50MB -аас ихгүй байхыг анхаарна уу.',
        imageTypeError: 'Файлын төрөл тохирохгүй байна. Файлын төрөл /png, x-png, jpg, jpeg, gif, docx, xlsx, pptx, wmv, mp4, mp3, mpeg / байхаар тохируулна уу.',
    },
    person: {
        birthdate: 'Төрсөн өдөр',
        firstName: 'Нэр',
        lastName: 'Овог',
        registrationNumber: 'Регистрийн дугаар',
    },
    questionnaire: {
        address: 'Оршин суугаа хаяг',
        age: 'Нас',
        awardName: 'Шагналын нэр',
        awardTitle: 'Шагнал урамшуулал',
        birthDate: 'Төрсөн огноо',
        birthPlace: 'Төрсөн газар',
        citizenship: 'Иргэний харъяалал',
        currentJob: 'Одоо эрхэлж буй ажил',
        completionDate: 'Төгссөн он, сар',
        diplom: 'Эзэмшсэн боловсрол, мэргэжил, гэрчилгээ, дипломын дугаар',
        deleteFamilyMember: 'Гэр бүлийн мэдээлэл устгах',
        deleteRelatives: 'Садан төрлийн мэдээлэл устгах',
        deleteEducation: 'Боловсролын мэдээлэл устгах',
        deleteAward: 'Шагнал урамшуулалын мэдээлэл устгах',
        deleteWork: 'Ажлын туршлагын мэдээлэл устгах',
        documentNumber: 'Баримт бичгийн дугаар',
        enrolledProgram: 'Элссэн мэргэжлийн хөтөлбөр',
        email: 'И-мэйл хаяг',
        educationInfo: 'Боловсролын ерөнхий, тусгай дунд, дээд боловсрол, бакалаврын болон магистрийн зэргийг оруулна.',
        educationTitle: 'Боловсролын мэдээлэл',
        entryDate: 'Орсон он, сар',
        employedOrganizations: 'Ажилласан байгууллага, хэлтэс',
        employmentEndDate: 'Ажлаас гарсан огноо',
        familyMember: 'Таны юу болох',
        familyTitle: 'Гэр бүлийн мэдээлэл',
        mainTitle: 'Үндсэн мэдээлэл',
        homeNumber: 'Гэрийн утас',
        highSchoolTitle: 'Бүрэн дунд боловсрол',
        mobile: 'Утас',
        nationality: 'Яс, үндэс',
        onlyFamilyMembers: 'Зөвхөн гэр бүлд байгаа хүмүүс',
        organization: 'Олгосон байгууллага',
        organizationDate: 'Олгосон он',
        parents: 'Эцэг, эхийн нэр',
        passportNumber: 'Гадаад паспортын дугаар',
        print: 'Анкет хэвлэх',
        programIndex: 'Мэргэжлийн индекс',
        programPlan: 'Сургалтын төлөвлөгөө',
        preview: 'Элсэхийн өмнө',
        phone: 'Гар утас',
        relatives: 'Садан төрлийн байдал /Таны эцэг, эх, төрсөн ах эгч, дүү/',
        role: 'Албан тушаал',
        reward: 'Шагнал',
        relativesTitle: 'Садан төрлийн мэдээлэл',
        title: 'Анкет',
        view: 'Анкет үзэх',
        workTitle: 'Ажлын туршлага',
    },
    ranking: {
        score: 'Дүнгийн чансаа',
        gpaScore: 'Чансаа оноо',
        academicProgram: 'Хөтөлбөрийн хэмжээнд',
        childSchool: 'Салбар сургуулийн хэмжээнд',
        parentSchool: 'Хэмжээнд'
    },
    report: {
        view: 'Тайлан үзэх',
        changeDuration: 'Хугацаа өөрчлөх'
    },
    selection: {
        amount: 'Төлвөл зохих',
        byClass: 'Ангиар',
        byStudent: 'Оюутнаар',
        bySubject: 'Хичээлээр',
        credit: 'Сонгосон кредит',
        date: 'Сонголт хийсэн огноо',
        enterFine: 'Торгууль бүртгэх',
        notCreated: 'Хичээл сонголт үүсээгүй байна',
        paid: 'Төлбөр төлсөн',
        paidDate: 'Төлбөр төлсөн огноо',
        payFine: 'Торгууль төлөх',
        payFine1Description: 'Хичээл сонголт №1 хийх хугацаа дууссан тул Торгууль төлж хичээл сонголтоо хийнэ үү.',
        payFine2Description: 'Хичээл сонголт №2 хийх хугацаа дууссан тул Торгууль төлж хичээл сонголтоо хийнэ үү.',
        payFineTwoDescription: 'Хичээл сонголт №2 хийх хугацаа дууссан тул Торгууль төлж хичээл сонголтоо хийнэ үү.',
        selected: 'Сонголт хийсэн',
        selectedDate: 'Сонголт нээгдсэн огноо',
        selectionOneApprovedText: 'улирлын Таны хичээл сонголт №1 баталгаажсан. Та засварлах боломжгүй.',
        selectionTwoApprovedText: 'улирлын Таны хичээл сонголт №2 баталгаажсан. Та завсарлах боломжгүй.',
        selectionTwoHasConfigText: 'Таны хичээл сонголт №2 үүсээгүй байна.',
        selectionTwoCanSelectText: 'Таны хичээл сонголт №2 хичээлийн хувиар үүсээгүй байна, та түр хүлээнэ үү.',
        student: 'Сонгосон оюутан',
        penaltyMessage: 'Тухайн оюутныг',
        penaltyMessageOne: 'сонголт дээр бүртгэх боломжгүй, Шалтгаан: Хичээл сонголтын тохиргоо үүсээгүй',
        penaltyMessageTwo: 'сонголт дээр бүртгэх боломжгүй, Шалтгаан: Хичээл сонголт дууссан эсвэл хичээл сонголтын торгуулийн хугацаа үүсээгүй',
        penaltyMessageThree: 'сонголт дээр бүртгэх боломжгүй, Шалтгаан: Бүртгэлтэй байна',
        penaltyMessageFour: 'сонголт дээр бүртгэх боломжгүй, Шалтгаан: Сонголтын хугацаа сунгах үед алдаа гарлаа',
        limit: 'Сонголт хийх дээд хязгаар'
    },
    setting: {
        addressSegment: {
            district: 'Сум дүүрэг',
            title: 'Аймаг, сум',
            province: 'Аймаг',
        },
        assessment: {
            title: 'Үнэлгээний тохиргоо',
        },
        class: {
            classType: 'Ангийн төрөл',
            classStatus: 'Ангийн төлөв',
            config: 'Ангийн тохиргоо',
        },
        contactType: {
            title: 'Холбоо барих төрөл',
        },
        employeeStatus: 'Ажилтны статус',
        group: {
            type: 'Группийн төрөл',
        },
        language: {
            title: 'Хэлний тохиргоо',
            system: 'Системийн хэл',
        },
        movement: {
            title: 'Шилжилт хөдөлгөөний тохиргоо',
        },
        reference: 'Лавлах',
        request: {
            type: 'Өргөдлийн төрөл',
            status: 'Өргөдлийн статус'
        },
        selection: {
            closeDate: 'Хаагдах огноо',
            openDate: 'Нээгдэх огноо',
            title: 'Сонголтын тохиргоо',
        },
        schoolSetting: 'Сургуулийн тохиргоо',
        student: {
            enteringStatus: 'Очих төлөв',
            status: 'Оюутны төлвийн тохиргоо',
            title: 'Оюутны тохиргоо',
        },
        teacherSetting: 'Багшийн тохиргоо',
        teacherStatus: 'Багшийн төлөв',
        timetable: {
            title: 'Хичээлийн хуваарийн тохиргоо',
        },
        title: 'Тохиргоо',
    },
    social: {
        facebookDescription: 'facebook хуудас',
        twitterDescription: 'twitter хуудас',
        youtubeDescription: 'youtube хуудас',
    },
    subject: {
        add: 'Хичээл нэмэх',
        code: 'Хичээлийн код',
        credit: 'Кредит',
        scoreType: 'Үнэлгээний төрөл',
        selected: 'Сонгосон хичээлүүд',
        generalAcademicSubject: 'Ерөнхий эрдмийн хичээл',
        grade: 'Хичээлийн бүлэг',
        gradeEdit: 'Хичээлийн бүлэг засах',
        hoursPerWeek: 'Долоо хоногт цаг',
        index: 'Хичээлийн индекс',
        isPrint: 'Хэвлэх эсэх',
        maximum: 'Дээд хязгаар',
        minimum: 'Доод хязгаар',
        microsoft: 'Microsoft Teams',
        name: 'Хичээлийн нэр',
        onlineLesson: 'Цахим хичээл',
        notFound: 'Хичээлийн мэдээлэл олдсонгүй',
        notCreated: 'Хичээлийн мэдээлэл үүсээгүй байна.',
        requiredSubjects: 'Заавал судлах хичээлүүд',
        ratingAboveC: 'С дээш үнэлгээ авах',
        season: 'Улирал',
        selectiveSubjects: 'Сонгон судлах хичээлүүд',
        selectSubject: 'Хичээл сонгох',
        selectionOneButton: 'Хичээл сонголт 1 хийх',
        seasonOfStudy: 'Судлах улирал',
        studyType: 'Хичээллэх төрөл',
        subjects: 'Хичээлүүд',
        subjectType: 'Судлагдахууны төрөл',
        score: 'Хичээлийн дүн',
        previous: 'Өмнөх холбоос',
        preSubject: 'Өмнөх холбоосын хичээл',
        price: 'Үнэ',
        teacher: 'Заах багш',
        title: 'Хичээл',
        time: 'Цаг',
        type: 'Хичээлийн төрөл',
        yearOfStudy: 'Судлах жил',
        isSameCredit: 'Ижил кредит',
        isSameAssessment: 'Ижил үнэлгээ',
    },
    system: {
        title: 'Сургалтын удирдлага, мэдээллийн систем',
        systemRole: 'Системийн дүр',
        menuRole: 'Меню дүр',
        quit: 'Системээс гарах',
        profile: 'Профайл зургаа солих',
    },
    school: {
        title: 'Сургууль',
        classroom: {
            title: 'Өрөө',
            number: 'Өрөөний дугаар',
            seatCount: 'Өрөөний суудлын тоо',
            name: 'Өрөөний нэр',
        },
        code: 'Сургуулийн код',
        isOtherSchool: 'Бусад сургууль дээр үүсгэх эсэх',
        shortName: 'Сургуулийн товч нэр',
        name: 'Сургуулийн нэр',
        parentSchool: 'Харъяалах сургууль',
        parentDepartment: 'Харъяалах тэнхим',
        schoolShift: 'Хичээлийн ээлж',
        shift: {
            name: 'Ээлжийн нэр',
            title: 'Ээлж',
        },
        timeTemplate: {
            title: 'Хонхны хуваарь',
        },
        season: {
            current: 'Энэ хичээлийн жил',
            isAllSeason: 'Бүх улирал эсэх',
            isCurrent: 'Одоогийн улирал эсэх',
            weekStart: 'Улирал эхлэх огноо',
            parent: 'Харъяалах жил',
            previous: 'Өмнөх хичээлийн жил',
            previousYear: 'Өмнөх хичээлийн жил',
            seasonType: 'Улирлын төрөл',
            title: 'Хичээлийн жил',
            studied: 'Судалсан улирал',
            validEndDate: 'Дуусах огноо эхлэх огнооноос өмнө байж болохгүй'
        },
    },
    student: {
        code: 'Оюутны код',
        currentClass: 'Одоо суралцаж байгаа анги',
        currentProgram: 'Суралцаж байгаа хөтөлбөр',
        lastName: 'Оюутны овог',
        name: 'Оюутны нэр',
        schoolEnrollmentDate: 'Сургууль элссэн огноо',
        enrollmentDate: 'Элссэн огноо',
        enrollmentSeason: 'Элссэн он',
        financeInfo: 'Оюутны төлбөрийн мэдээлэл',
        gradeInfo: 'Оюутны дүнгийн мэдээлэл',
        getDescription: 'Тодорхойлолт авах',
        getScoreDescription: 'Дүнгийн тодорхойлолт авах',
        status: 'Оюутны төлөв',
        number: 'Оюутны тоо',
        title: 'Оюутан',
        register: 'Оюутан бүртгэх',
        downloadPrevSeason: 'Өмнөх улирлын оюутнуудыг татах',
        request: 'Илгээсэн',
        requestApproved: 'Шийдвэрлэсэн',
    },
    scholarship: {
        amount: 'Тэтгэлэгийн дүн',
        title: 'Тэтгэлэг',
        type: 'Тэтгэлэгийн төрөл',
        date: 'Тушаалын огноо',
        number: 'Тушаалын дугаар',
        minScore: 'Элсэлтийн доод хувь'
    },
    teacher: {
        add: 'Багш нэмэх',
        addTopic: 'Сэдэв нэмэх',
        addScore: 'Дүн оруулах',
        attendanceRegistration: 'Ирц бүртгэл',
        code: 'Багшийн код',
        content: 'Агуулга',
        createOnlineLesson: 'Цахим хичээл үүсгэх',
        createTopic: 'Сэдэв үүсгэх',
        courseContent: 'Хичээлийн агуулга',
        createdDate: 'Үүсгэсэн огноо',
        discussion: 'Хэлэлцүүлэг',
        downloadPrevOnlineLesson: 'Өмнөх цахим хичээлийн агуулгы хуулах эсэх',
        exam: 'Шалгалт',
        flow: 'Явц',
        homework: 'Даалгавар',
        name: 'Багшийн нэр',
        norm: 'Норм',
        views: 'Үзсэн тоо',
        title: 'Багш',
        invite: 'Багш урих',
        select: 'Багш сонгох',
        students: 'Оюутнууд',
        shortDescription: 'Товч тайлбар',
        subjectFile: 'Хичээлийн файл',
        selectFileDirectory: 'Файлын сангаас сонгох',
        seenStudents: 'Үзсэн оюутнууд',
        score: 'Оноо',
        result: 'Дүн',
        journal: 'Журнал',
        teams: 'MS Teams',
        teamMember: 'Оюутан холбох',
        topic: 'Сэдэв',
        topicName: 'Сэдвийн нэр',
        topicRegistration: 'Сэдэв бүртгэл',
        topicStatic: 'Сэдвийн статистик',
        totalViews: 'Нийт үзсэн тоо',
        timeSheets: 'Багшийн цагийн тооцооны нэгтгэл',
        timeAdditional: 'Багшийн нэмэлт цаг',
        timeSheet: 'Багшийн цагийн тооцоо',
        onlineLesson: 'Цахим хичээл',
        publish: 'Дүн баталгаажсан',
        previousOnlineLesson: 'Өмнөх цахим хичээл',
        week: 'Долоо хоног',
        webLink: 'Вэб холбоос',
    },
    homework: {
        add: 'Даалгавар нэмэх',
        assignmentStudents: 'Даалгавар илгээсэн оюутнууд',
        answerType: 'Хариулт авах төрөл',
        create: 'Даалгавар үүсгэх',
        description: 'Даалгаврын тайлбар',
        endDateTime: 'Хаагдах огноо, цаг',
        file: 'Даалгаврын файл',
        name: 'Даалгаврын нэр',
        title: 'Гэрийн даалгавар',
        startDateTime: 'Харагдаж эхлэх огноо, цаг',
        score: 'Даалгаврын оноо',
        sentFile: 'Илгээсэн файл',
        question: 'Даалгавар',
        send: 'Даалгавар илгээх',
        sentDate: 'Илгээсэн огноо',
        undo: 'Илгээсэн даалгаварыг буцаах',
        undoCheck: 'Та илгээсэн даалгаварын файлыг устгах гэж байна. Үргэлжлүүлэхийг хүсэхгүй байвал БУЦАХ товчийг дарна уу'
    },
    timetable: {
        add: 'Хуваарь нэмэх',
        bySubject: 'Хичээлээр',
        byDay: 'Өдрөөр',
        byTeacher: 'Багшаар',
        edit: 'Хичээлийн хуваарь засах',
        isOnline: 'Цахим эсэх',
        isSelective: 'Чөлөөт сонголт эсэх',
        penalty: 'Торгууль',
        selection: 'Хичээл сонголт',
        selective: 'Чөлөөт',
        selectionOne: 'Хичээл сонголт 1',
        selectionTwo: 'Хичээл сонголт 2',
        selectTeacher: 'Багш сонгоно уу',
        selectPotok: 'Поток сонгоно уу',
        title: 'Хичээлийн хуваарь',
        type: 'Хичээлийн хуваарийн төрөл',
        current: 'Хичээлийн явц',
        main: 'Үндсэн',
        seatCount: 'Суудлын тоо'
    },
    transcript: {
        type: 'Тодорхойлолтын төрөл',
        template: 'Загвар',
        price: 'Төлбөр',
        valid: 'Хүчинтэй огноо',
        ulaanbaatar: 'Улаанбаатар хот',
        studentDescription: 'Суралцагчийн тодорхойлолт',
        studentScoreDescription: 'Дүнгийн хуудас',
    },
    total: {
        title: 'Нийт',
        subject: 'Нийт хичээлийн тоо',
        credit: 'Нийт кредит',
        price: 'Нийт төлбөр',
        pay: 'Нийт төлөх',
    },
    warning: {
        approve: 'Зөвшөөрөх',
        approve_confirmation: 'Та итгэлтэй байна уу?',
        changePasswordDescription: 'Нууц үг солиход хэрэглэгч хуучин нууц үгээрээ орох боломжгүй болохыг анхаарна уу.',
        delete: 'Устгах',
        delete_confirmation: 'Та устгахдаа итгэлтэй байна уу?',
        delete_confirmation_description: 'Нэг устгасан мэдээллийг дахин сэргээх боломжгүйг анхаарна уу!',
        error_publish: 'Та мэдээлэлийг бүрэн бөглөөгүй байна. Мэдээлэлийг бөглөлгүй үргэлжлүүлэх бол хадгалах товчийг дахин дарна уу',
        invalid_parameter: 'Мэдээлэл дамжуулах үед алдаа гарлаа.',
        publish_confirmation: 'Шалгалтын дүнг баталгаажуулснаар дахин засах, устгах боломжгүй болохыг анхаарна уу!',
        publish_confirmation_score: 'Дүнг баталгаажуулснаар дахин засах, устгах боломжгүй болохыг анхаарна уу!',
        publish_confirmation_description: 'Зөвхөн баталгаажуулсан дүнгүүд оюутанд харагдана.',
        connectOfficer: 'Сургалтын албаны ажилтантай холбогдоно уу.',
        cantEditPublish: 'Баталгаажуулсан тул засварлах боломжгүй',
        completeInformation: 'Мэдээллийг бүрэн бөглөж оруулна уу',
        selectBankType: 'Төлбөр төлөх хэлбэрийг сонгоно уу'
    },
    question: {
        title: 'Даалгавар',
        helpfulQuestion: 'Туслах мэдээ',
        type: 'Даалгаврын төрөл',
        score: 'Даалгаврын оноо',
        reCreate: 'Дахиж даалгавар үүсгэнэ',
        insert: 'Даалгавар оруулна уу',
        checkCorrectQuestion: 'Зөв асуултыг сонгоно уу'
    },
    onlineExam: {
        testName: 'Сорилын нэр',
        description: 'Дэд мэдээ',
        activeDuration: 'Идэвхтэй хугацаа',
        testDuration: 'Үргэлжлэх минут',
        enterTest: 'Сорил өгөх',
        inactive: 'Хаагдсан',
        showResult: 'Дүнгээ харах',
        startTest: 'Сорил эхлүүлэх',
        finishTest: 'Сорил дуусгах',
        timeRemain: 'Үлдсэн хугацаа',
        testQuestionNumber: 'Шалгалтын асуултын тоо',
        questionNumber: 'Асуултын тоо',
        testInstruction: 'Сорил өгөх зааварчилгаа',
        before: 'Өмнөх асуулт',
        check: 'Шалгах',
        next: 'Дараагийн асуулт',
        type: 'Сорилын асуултын төрөл',
        questions: 'Асуултууд',
        question: 'Асуулт',
        unansweredQuestions: 'Дараах асуултанд хариулаагүй байна',
        finishConfirmation: 'Та ИЛГЭЭХ товчийг дарснаар сорилоо дуусгана. Нэг илгээсэн сорилыг дахин өгөх боломжгүйг анхаарна уу. Сорил руу буцах бол БУЦАХ товчийг дарна уу.',
        averageScore: 'Гүйцэтгэлийн хувь',
        rightQuestions: 'Зөв хариулсан асуултын тоо',
        wrongQuestions: 'Буруу хариулсан асуултын тоо',
        noAnswerQuestions: 'Хариулаагүй асуултын тоо',
        testTotalScore: 'Сорилын нийт оноо',
        testTotalQuestions: 'Сорилын нийт асуулт',
        correct: 'Хариулт зөв',
        incorrect: 'Хариулт буруу. Зөв хариулт',
        questionScore: 'Асуултын оноо',
        takenScore: 'Авсан оноо',
        givenAnswer: 'Өгсөн хариулт',
        changeTime: 'Хугацаа өөрчлөх',
        delete: 'Сорил устах',
        removeTopic: 'Сорилын сэдэв хасах',
        add: 'Сорил үүсгэх',
        edit: 'Сорил засах',
        groups: 'Сорил өгөх бүлгүүд',
        date: 'Сорил өгөх огноо',
        examDate: 'Шалгалтын огноо',
        isRepeatAble: 'Шалгалтыг дахин өгч болох эсэх',
        showAnswer: 'Шалгалтын зөв хариултыг харуулах эсэх',
        addNewQuestion: 'Шинэ асуулт нэмэх',
        selectFromQuestionStore: 'Даалгаврын сангаас сонгох',
        addFromQuestionStore: 'Даалгаврын сангаас нэмэх',
        questionEdit: 'Даалгаврыг засах',
        removeFromTest: 'Сорилоос хасах',
        orderChange: 'Дараалал өөрчлөх',
        topicRemoveConfirmation: 'Сорилын сэдэв хасахдаа итгэлтэй байна уу?',
        questionRemoveConfirmation: 'Сорилоос даалгавар хасахдаа итгэлтэй байна уу?',
        topicRemoveWarning: 'Сорилын сэдэвтэй холбоотой асуултууд бүгд хасагдахыг анхаарна уу.',
        questionFromBlueprint: 'Даалгаврын сангаас асуултууд сонгох',
        testDescription: 'Сорилын тайлбар',
        testStudents: 'Хамрагдсан оюутнууд',
        totalStudents: 'Хамрагдах оюутнууд',
        notStarted: 'Эхлээгүй',
        inProgress: 'Хийж байгаа',
        progress: 'Гүйцэтгэл',
        activate: 'Дахин нээх',
        enterExam: 'Сорилын нэр оруулна уу',
        enterScore: 'Үнэлгээ оруулах',
        chooseQuestion: 'Асуултыг түүвэрлэн сонгох эсэх',
        studentQNumber: 'Оюутанд харуулах асуултын тоо'
    },
    onlineLesson: {
        watchedLesson: 'Судалж байгаа хичээлүүд',
        detail: 'Хичээлийн дэлгэрэнгүй',
        news: 'Зарлал',
        intro: 'Хичээлийн танилцуулга',
        rate: 'Үнэлгээ өгөх',
        next: 'Дараагийн хичээл',
        prev: 'Өмнөх хичээл',
        checked: 'Шалгасан'
    },
    enroll: {
        birthDate: "Төрсөн огноо",
        rank: "Цол",
        essay: "Эссэ",
        eduGpa: "Голч",
        gender: "Хүйс",
        email: "И-мэйл",
        status: "Төлөв",
        lastName: "Овог",
        firstName: "Нэр",
        school: "Сургууль",
        profession: "Мэргэжил",
        profileEdit: 'Мэдээлэл засах',
        programEdit: 'Хөтөлбөр солих',
        lowestGPA: "Доод голч",
        application: "Өргөдөл",
        contact: "Холбоо барих",
        comment: "Тайлбар текст",
        position: "Албан тушаал",
        downloadPdf: "PDF татах",
        graduateDate: "Төгссөн он",
        totalNumber: "Хяналтын тоо",
        schoolYear: "Хичээлийн жил",
        department: "Хэлтэс, газар",
        phoneNumber: "Утасны дугаар",
        enrollmentType: "Элсэлтийн төрөл",
        additionalFile: "Нэмэлт материал",
        diplomVersion: "Дипломын хувилбар",
        registerNumber: "Регистрийн дугаар",
        createdDate: "Хүсэлт гаргасан огноо",
        organizationName: "Байгууллагын нэр",
        personalInformation: "Хувийн мэдээлэл",
        enrollmentProcess: "Элсэлтийн процесс",
        registrationNumber: "Бүртгэлийн дугаар",
        eyeshRegNumber: "ЭЕШ бүртгэлийн дугаар",
        insuranceDefinitions: "НДШ тодорхойлолт",
        checkPunishment: "Ял шийтгэл шалгах эсэх",
        jobDefinitions: "Ажлын газрын тодорхойлолт",
        bornSinceMale: "Оноос хойш төрсөн (эрэгтэй)",
        bornSinceFemale: "Оноос хойш төрсөн (эмэгтэй)",
        requireGraduateSchool: "Төгссөн сургууль шаардах эсэх?",
        requireGraduateSchool2: "Магистр төгссөн сургууль шаардах эсэх?",
        phase: "Үе шат",
        id: "ID",
        sender: "Илгээгч",
        messageReport: "Мессеж тайлан",
        emailReport: "И-мэйл тайлан",
        certificateNumber: "Суралцах эрхийн бичиг дугаар",
        certificate: "Суралцах эрхийн бичиг",
        type: "Төрөл",
        content: "Агуулга",
        certificateType: "Суралцах эрхийн бичиг төрөл",
        certificateTemplate: 'Эрхийн бичгийн загвар',
        createCertificateModel: "Эрхийн бичиг үүсгэх",
        deleteCertificateModel: "Суралцах эрхийн бичиг загвар устгах",
        editCeritificateModel: "Суралцах эрхийн бичгийн загвар засах",
        viewCertificateModel: "Суралцах эрхийн бичгийн загвар үзэх",
        subSchool: "Бүрэлдэхүүн сургууль",
        startNumber: "Эхлэх дугаар",
        issueNumber: "Олгох тоо",
        numberOfCertificate: "Суралцах эрхийн бичгийн дугаар",
        deleteCertificateNumber: "Суралцах эрхийн бичиг дугаар устгах",
        certificateNo: "Сертификат дугаар",
        addCertificateNumber: "Суралцах эрхийн бичиг дугаар нэмэх",
        editCeritificateNumber: "Суралцах эрхийн бичгийн дугаар засах",
        basicCourse: "Үндсэн хичээл",
        basicScore: "Үндсэн хичээлийн оноо",
        appendix: "Хавсарга хичээл",
        appendixScore: "Хавсарга хичээлийн оноо",
        deleteScholarship: "Тэтгэлэг устгах",
        enrollmentPeriod: "Элсэлтийн хугацаа ",
        criteria: 'Шалгуур',
        criteriaIndicator: 'Шалгуурын үзүүлэлт',
        enrollment: "Элсэлт",
        totalProgramNumber: 'Элсэлт авах мэргэжил',
        totalEnrollmentNumber: 'Нийт элсэгчдийн тоо',
        activeOrDeactive: '"Идэвхтэй болгох/ идэвхгүй болгох'
    },
    survey: {
        status: "Статус",
        title: 'Судалгаа',
        name: 'Судалгааны нэр',
        category: 'Категори',
        createCategory: 'Категори үүсгэх',
        date: 'Судалгааны хугацаа',
        fillOut: 'Судалгаа бөглөх',
        participants: 'Оролцогчид',
        level: "Түвшин",
        group: "Бүлэг",
        goal: "Судалгааны зорилго",
        surveyCategory: 'Судалгааны категори',
        parentCategory: 'Харъяалах категори',
        parentCategoryPlaceholder: 'Харъяалах категори сонгоно уу',
        enterCategory: 'Категори оруулна уу',
        save: "Асуумж нэмэх",
        startDate: "Эхлэх",
        notFound: 'Судалгааны мэдээлэл олдсонгүй, хөтөлбөрийн албаны ажилтанд хандана уу',
        modalDescription: 'Танд бөглөх ёстой судалгаа байгаа тул судалгаагаа бөглөнө үү',
        endDate: "Дуусах",
        registered: "Бүртгэсэн",
        publishedDate: "Нийтэлсэн огноо",
        systemRole: "Систем дүр",
        workers: "Багш ажилтан",
        general: "Ерөнхий мэдээлэл",
        questionnaire: "Асуумж",
        questionnaireDesc: "Асуумжийн тайлбар",
        answerRequired: "Заавал хариулт сонгоно",
        questionnaireType: "Асуумжийн төрөл",
        typeText: "Текст бичих",
        typeSelect: "Сонголтоос сонгох",
        selectImage: "Зураг оруулах",
        sentDate: 'Илгээсэн огноо',
        sent: 'Илгээх',
        addAgain: "Дахин асуумж нэмэх",
        orderAction: 'Дараалал өөрчлөх',
        report: "Тайлан үзэх",
        changeDate: "Хугацаа өөрчлөх",
        copy: "Хуулах",
        changeQuestionsOrder: "Асуумжийн дараалал өөрчлөх"
    },
    graduation: {
        template: 'Загвар',
        templateName: 'Загварын нэр',
        pageNumber: 'Хуудасны тоо',
        templateAdd: 'Загвар нэмэх',
        subSchool: "Салбар сургууль",
        subject: "Мэргэжил",
        addStudent: "Төгсөгч нэмэх",
        gpaCalculate: 'Нэгдсэн голч бодох',
        students: 'Төгсөгчид',
        report: 'Тайлан',
        calculatedGpa: 'Нэгдсэн голч',
        config: {
            create: 'Тохиргоо нэмэх',
            decisionDate: 'Шийдвэрийн огноо',
            title: 'Тохиргоо',
            orderDate: 'Тушаалын огноо',
            orderNumber: 'Тушаалын дугаар',
        },
        signatureAdd: 'Гарын үсэг нэмэх',
        visibleTemplate: 'Харагдах загвар',
        diplomNumber: 'Дипломын дугаар',
        registerNumber: 'Бүртгэлийн дугаар',
        addInformation: 'Мэдээлэл бүртгэх',
        diplomaTopic: 'Дипломын сэдэв',
        templateDelete: 'Загварын устгах',
        approvePage: 'Баталгаажуулах хуудас',
        infoApprovePage: 'Мэдээлэл баталгаажуулах хуудас',
        subjectCount: 'Судалсан хичээлийн тоо',
        score: 'Дүн',
        eyeshScore: 'Элсэлтийн шалгалтын оноо',
        prevLevelScore: 'Өмнөх шатны боловсролын үнэлгээний дундаж оноо',
        diplomaSubject: 'Дипломийн хичээл'
    }
};