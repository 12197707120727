import React, { useState, useEffect } from 'react';
import { Card } from "react-bootstrap";
import DTable from "../../../modules/DataTable/DTable";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import message from "../../../modules/message";
import { roomAdd, roomDelete, roomEdit } from "../../../../utils/fetchRequest/Urls";
import AddRoom from "./Add";
import DeleteModal from "../../../modules/DeleteModal";

const Classroom = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [ rooms, setRooms ] = useState([]);
    const [ showModal, setShowModal ] = useState(false);
    const [ roomId, setRoomId ] = useState(null);

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [search, setSearch] = useState('');
    const [tableTotalSize, setTableTotalSize] = useState(0);

    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    useEffect(() => {
        const params = {
            school: schoolId,
            page,
            pageSize,
            search
        }
        dispatch(setLoading(true));
        fetchRequest(roomAdd, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    if (data?.rooms?.length) {
                        setRooms(data.rooms);
                        
                    }
                    setTableTotalSize(data?.totalCount || 0);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }, [page, pageSize, search]);

    const config = {
        showPagination: true,
        showFilter: true,
    };

    const columns = [
        {
            dataField: "roomNumber",
            text: t('school.classroom.number'),
        },
        {
            dataField: "capacity",
            text: t('school.classroom.seatCount'),
        },
    ];

    const contextMenus = [
        {
            key: 'edit',
            title: t('common.edit'),
            icon: <i className='las la-edit dt-cm-item-icon'/>
        },
        {
            key: 'delete',
            title: t('common.delete'),
            icon: <i className='las la-trash-alt dt-cm-item-icon' />,
        },
    ];

    const handleContextMenuClick = (id, key) => {
        if (id && key) {
            setRoomId(id);
            if (key === 'edit') {
                setShowModal(true);
            }
        }
    }

    const onModalClose = () => {
        setShowModal(false);
        setRoomId(null);
    }

    const onSubmit = params => {
        const url = roomId ? roomEdit : roomAdd
        const bodyParams = {
            ...params,
            school: schoolId
        }

        if (roomId) {
            bodyParams.room = roomId;
        }

        dispatch(setLoading(true));
        fetchRequest(url, 'POST', bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setRooms(data.rooms || []);
                    message(data?.message || t('errorMessage.title'), true);
                    setTableTotalSize(data?.totalCount || 0);
                    onModalClose();
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const onDelete = () => {
        const params = {
            school: schoolId,
            room: roomId
        };
        dispatch(setLoading(true));
        fetchRequest(roomDelete, 'POST', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setRooms(data.rooms || []);
                    message(data?.message || t('errorMessage.title'), true);
                    setTableTotalSize(data?.totalCount || 0);
                    onModalClose();
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const handleTableChange = data => {
        setPage(data.page);
        setPageSize(data.pageSize);
        setSearch(data.search);
    };

    return (
        <Card>
            <Card.Body>
                <button
                    className='btn btn-primary'
                    onClick={() => {
                        setShowModal(true);
                    }}
                >
                    {t('common.add')}
                </button>
                <DTable
                    remote
                    config={config}
                    columns={columns}
                    data={rooms}
                    contextMenus={contextMenus}
                    onContextMenuItemClick={handleContextMenuClick}
                    selectMode={'radio'}
                    totalDataSize={tableTotalSize}
                    onInteraction={handleTableChange}
                />
            </Card.Body>
            {
                showModal && (
                    <AddRoom
                        onClose={onModalClose}
                        onSubmit={onSubmit}
                        id={roomId}
                    />
                )
            }
            {
                !showModal && roomId && (
                    <DeleteModal
                        onClose={onModalClose}
                        onDelete={onDelete}
                        title={t('menu.classroom')}
                    >
                        {t('warning.delete_confirmation')}
                        <br/>
                        <br/>
                        {t('warning.delete_confirmation_description')}
                    </DeleteModal>
                )
            }
        </Card>
    );
};

export default Classroom;