import { Card, Button } from "react-bootstrap";
import AddCriteriaModal from "./addCriteriaModal";
import React, { useState, useEffect } from "react";
import DTable from "../../../modules/DataTable/DTable";
import { fetchRequest } from "../../../../utils/fetchRequest";
import message from "../../../modules/message";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../../redux/action";
import { getDeleteEnrollPhase, getEditEnrollPhase, getOrderingEnrollPhase } from "../../../../utils/fetchRequest/Urls";
import Dropdown from "../../../modules/Form/Dropdown";
// import Dropdown from "../Form/Dropdown";

const CriteriaEnroll = ({ enrollId, criteria, enrollPhases, setSelectedCriteria }) => {
    const dispatch = useDispatch();
    const [phases, setPhases] = useState([]);
    const [phaseTypes, setPhaseTypes] = useState([]);
    const [phaseId, setPhaseId] = useState([]);
    const [phaseTypeId, setPhaseTypeId] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [list, setList] = useState([])

    useEffect(() => {
        if (criteria && criteria.phases) {
            const phaseOptions = [];
            criteria.phases.forEach((phase) => {
                phaseOptions.push({ value: phase.id, text: phase.name });
            });
            setPhases(phaseOptions);
        }

        if (criteria && criteria.enrollPhaseTypes) {
            const typeOptions = [];
            criteria.enrollPhaseTypes.forEach((type) => {
                typeOptions.push({ value: type.id, text: type.name });
            });
            setPhaseTypes(typeOptions);
        }
    }, [criteria]);

    useEffect(() => { 
        setList(enrollPhases)
    }, [enrollPhases]);

    const config = {
        showFilter: false,
        showAllData: true,
        showPagination: false,
    };

    const Attribute = (cell, row, rowIndex, extraData) => {
    };

    const columns = [
        {
            dataField: "phaseTypeName",
            text: "Төрөл",
            sort: false,
            style: { textAlign: "center", padding: 3 },
            formatter: (cell, row, rowIndex) => {
                if(row.isEdit){
                    return (
                        <Dropdown
                            options={phaseTypes}
                            value={row.phaseTypeId}
                            onChange={(e) => handlerDropdown(e, 'type', rowIndex)}
                        />
                    )
                } else {
                    return cell
                }
            }
        },
        {
            dataField: "phaseName",
            text: "Шалгуур",
            sort: false,
            style: { textAlign: "center", padding: 3 },
            formatter: (cell, row, rowIndex) => {
                if(row.isEdit){
                    return (
                        <Dropdown
                            options={phases}
                            value={row.phaseId}
                            onChange={(e) => handlerDropdown(e, 'phase', rowIndex)}
                        />
                    )
                } else {
                    return cell
                }
            }
        },
        {
            dataField: "ordering",
            text: "Дараалал",
            sort: false,
        },
        {
            isDummyField: true,
            dataField: "icons",
            text: "",
            sort: false,
            headerStyle: () => {
                return {
                    width: 160
                }
            },
            style: { textAlign: "center", padding: 3 },
            attrs: Attribute,
            formatter: (cell, row, rowIndex, extraData) => {
                if(row.isEdit){
                    return(
                        <Button onClick={() => {handlerIcon('save', cell, row, rowIndex, extraData)}} className="btn btn-success m-btn m-btn--icon m-btn--icon-only m-btn--circle-30 mr-1">
                            <i className="flaticon2-check-mark"> </i>
                        </Button>
                    )
                } else if(row.hidden) {
                    return <></>
                } else if(!row.hidden){
                    return (
                        <div>
                            <table className="table table-borderless mb-0" style={{tableLayout: 'fixed'}}>
                                <tbody>
                                    <tr>
                                        <td className="p-0">
                                            {
                                                row.ordering > 1 &&
                                                <Button onClick={() => {handlerIcon('up', cell, row, rowIndex, extraData)}} className="btn btn-outline-purple m-btn m-btn--icon m-btn--icon-only m-btn--circle-30 mr-1">
                                                    <i className="fa fa-arrow-up"> </i>
                                                </Button>
                                            }
                                        </td>
                                        <td className="p-0">
                                            {
                                                list.length != row.ordering && 
                                                <Button onClick={() => {handlerIcon('down', cell, row, rowIndex, extraData)}} className="btn btn-outline-purple m-btn m-btn--icon m-btn--icon-only m-btn--circle-30 mr-1">
                                                    <i className="fa fa-arrow-down"> </i>
                                                </Button>
                                            }
                                        </td>
                                        <td className="p-0">
                                            <Button onClick={() => {handlerIcon('edit', cell, row, rowIndex, extraData)}} className="btn btn-purple m-btn m-btn--icon m-btn--icon-only m-btn--circle-30 mr-1">
                                                <i className="flaticon-edit"> </i>
                                            </Button>
                                        </td>
                                        <td className="p-0">
                                            <Button onClick={() => {handlerIcon('delete', cell, row, rowIndex, extraData)}} className="btn btn-danger m-btn m-btn--icon m-btn--icon-only m-btn--circle-30">
                                                <i className="flaticon2-cross"> </i>
                                            </Button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    );
                }
            },
        },
    ];

    const handlerDropdown = (e, action, rowIndex) => {
        if(e && e.value){
            let cloneData = [...list];

            if(cloneData && cloneData.length > 0){
                for(let i = 0; i < cloneData.length; i++){
                    if(i == rowIndex){
                        if(action == 'type'){
                            cloneData[i].phaseTypeId = e.value;
                        } else if (action == 'phase'){
                            cloneData[i].phaseId = e.value;
                        }
                    }
                }
            }

            setList(cloneData)
        }
    }

    const handlerIcon = (action, content, row, rowIndex) => {
        if(action == 'delete'){
            const params = {
                enroll: enrollId,
                enrollPhase: row.id,
            };

            dispatch(setLoading(true));
            fetchRequest(getDeleteEnrollPhase, "POST", params, false)
                .then((response) => {
                    if (response.success) {
                        message(response.data.message, true);
                        setSelectedCriteria(response.data?.list || [])
                    } else {
                        message(response.data.message, false);
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                });
        } else if (action == 'edit'){
            let cloneData = [...list];

            if(cloneData && cloneData.length > 0){
                for(let i = 0; i < cloneData.length; i++){
                    if(i == rowIndex){
                        cloneData[i].isEdit = true;
                    } else {
                        cloneData[i].hidden = true;
                    }
                }
            }
            setList(cloneData)
        } else if (action == 'up' || action == 'down'){
            const params = {
                enroll: enrollId,
                enrollPhase: row.id,
                type: action
            };

            dispatch(setLoading(true));
            fetchRequest(getOrderingEnrollPhase, "POST", params, false)
                .then((response) => {
                    if (response.success) {
                        message(response.data.message, true);
                        setSelectedCriteria(response.data?.list || [])
                    } else {
                        message(response.data.message, false);
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                });
        } else if (action == 'save'){
            const params = {
                enroll: enrollId,
                enrollPhase: row.id,
                phase: row.phaseId,
                enrollPhaseType: row.phaseTypeId
            };

            dispatch(setLoading(true));
            fetchRequest(getEditEnrollPhase, "POST", params, false)
                .then((response) => {
                    if (response.success) {
                        message(response.data.message, true);
                        setSelectedCriteria(response.data?.list || [])
                    } else {
                        message(response.data.message, false);
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                });
        }
    };

    const handleAddClick = () => {
        setShowModal(true);
    };

    const onModalClose = () => {
        setShowModal(false);
    };

    return (
        <>
            <Card.Body>
                <button className="btn btn-primary shadow" onClick={handleAddClick}>
                    Нэмэх
                </button>
                {showModal && (
                    <AddCriteriaModal
                        onClose={onModalClose}
                        enrollId={enrollId}
                        phases={phases}
                        phaseTypes={phaseTypes}
                        setSelectedCriteria={(data) => setSelectedCriteria(data)}
                    />
                )}
                <DTable
                    config={config}
                    columns={columns}
                    selectMode={"radio"}
                    data={list}
                    selectModeClass={'white'}
                />
            </Card.Body>
        </>
    );
};

export default CriteriaEnroll;
