import React, { useState, useEffect } from 'react';
import { Modal, Button, Card, Row, Col, CloseButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import DatePicker from "app/modules/Form/DatePicker";
import message from "app/modules/message";

const SurveyModal = ({ show, onClose, onStartSubmit, onSubmit, data, selectedRow }) => {
    const { t } = useTranslation();

    const [surveyData, setSurveyData] = useState({})

    useEffect(() => {
        setSurveyData(data)
    }, [data]);

    const onSaveClick = () => {
        if(surveyData){
            let error = false
            let cloneSurveyData = {...surveyData} 
            let questions = cloneSurveyData.questions;

            for(let i = 0; i < questions.length; i++){
                if(questions[i].isRequired){
                    if(questions[i].typeCode == 'TEXT' || questions[i].typeCode == 'NUMBER' || questions[i].typeCode == 'DATE'){
                        if(!questions[i].value){
                            questions[i].isError = true;
                            error = true;
                        } else {
                            error = false;
                            questions[i].isError = false;
                        }
                    }
                    
                    if(questions[i].answers && questions[i].answers.length > 0){
                        let answers = questions[i].answers;

                        let allFalse = false;
                        for(let a = 0; a < answers.length; a++){
                            if(answers[a]?.isChecked){
                                allFalse = answers[a]?.isChecked
                                break
                            }
                        }

                        if(!allFalse){
                            questions[i].isError = true;
                            error = true;
                        } else {
                            error = false;
                            questions[i].isError = false;
                        }
                    }
                }
            }
            
            if(!error){
                onSubmit(questions);    
            } else {
                setSurveyData(cloneSurveyData)
                message(t('warning.completeInformation'))
            }
        }
    };

    const onStartClick = () => {
        onStartSubmit(surveyData);    
    }

    const onCheckBoxHandler = (e, qIndex, aIndex) => {
        let cloneSurveyData = {...surveyData}
        cloneSurveyData.questions[qIndex].answers[aIndex]['isChecked'] = e.target.checked;
        setSurveyData(cloneSurveyData)
    }

    const onRadioHandler = (e, qIndex, aIndex) => {
        let cloneSurveyData = {...surveyData}
        if(cloneSurveyData.questions[qIndex].answers){
            let answers = cloneSurveyData.questions[qIndex].answers;
            for(let i = 0; i < answers.length; i++) {
                if(i == aIndex){
                    answers[i].isChecked = e.target.checked;
                } else {
                    answers[i].isChecked = false
                }
            }
        }
        setSurveyData(cloneSurveyData)
    }

    const onNumberHandler = (e, qIndex) => {
        let cloneSurveyData = {...surveyData}
        cloneSurveyData.questions[qIndex].value = e.target.value;
        setSurveyData(cloneSurveyData)
    }

    const onTextHandler = (e, qIndex) => {
        let cloneSurveyData = {...surveyData}
        cloneSurveyData.questions[qIndex].value = e.target.value;
        setSurveyData(cloneSurveyData)
    }

    const handleDateChange = (date, qIndex) => {
        let cloneSurveyData = {...surveyData}
        cloneSurveyData.questions[qIndex].value = date;
        setSurveyData(cloneSurveyData)
    }

    return (
        <Modal
            show={show}
            onClose={onClose}
            onHide={onClose}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop={'static'} 
        >
            <Modal.Header>
                <Modal.Title className="fs-14 d-flex justify-content-between w-100 align-items-center">
                    <span>{t('survey.title')}</span>
                </Modal.Title>
                <CloseButton onClick={onClose}/>
            </Modal.Header>
            <Modal.Body>
                {
                    surveyData && surveyData.statusCode == 'IN_PROGRESS'
                    ?
                    surveyData?.questions?.map((question, i) => {
                        return (
                            <div className={question.isError ? "custom-container-error mb-4" : "custom-container mb-4"} key={`questions-${i}`}>
                                <div className="custom-q">
                                    <div className="d-block">
                                        <span className="d-block">№{i + 1}.</span>
                                        {
                                            question && question.filePath &&
                                            <img className="img-responsive mt-3 mb-4" height='350px' src={question.filePath} alt="Image"></img>
                                        }
                                    </div>
                                    <h4>Асуумж: {question?.question}</h4>
                                    <p className="mb-3">Тайлбар: {question?.description}</p>
                                    <div className="text-red mb-2">{question.isRequired ? 'Заавал хариулт авна' : ''}</div>
                                    <div className="d-flex flex-column px-4">
                                        {question.answers?.map((answer, j) => (
                                            <label className="mb-2" key={`qa-${i}-${j}`}>
                                                {
                                                    question?.isMultiAnswer 
                                                    ? 
                                                        <input type="checkbox" value={answer.isChecked} onChange={(e) => onCheckBoxHandler(e, i, j)} className='mr-2' name={`q-${i}-${j}`} /> 
                                                    : 
                                                        <input type="radio" checked={answer.isChecked || false} onChange={(e) => onRadioHandler(e, i, j)} className='mr-2' name={`q-${i}-${j}`} />
                                                }
                                                {answer?.answer}
                                            </label>
                                        ))}
                                        {
                                            question.typeCode == "NUMBER"
                                            ?
                                                <input type="number" value={question.value || ''} onChange={(e) => onNumberHandler(e, i)} className='form-control' name={`q-number-${i}`} />
                                            :
                                            question.typeCode == "TEXT"
                                            ?
                                                <input type="text" value={question.value || ''} onChange={(e) => onTextHandler(e, i)} className='form-control' name={`q-text-${i}`} />
                                            :
                                            question.typeCode == "DATE"
                                            ?
                                                <DatePicker 
                                                    value={question.value}
                                                    onChange={(date) => handleDateChange(date, i)}
                                                    isCustomButton={false}
                                                    dateFormat={'yyyy-MM-dd'}
                                                />
                                            :
                                                null
                                        }
                                    </div>
                                </div>
                            </div>
                        );
                    })
                    :
                    <div className="custom-container">
                        <Row className="mb-1">
                            <Col md={5}>
                                <div className="text-right">{t('survey.status')}</div>
                            </Col>
                            <Col md={7}>
                                <strong className='custom-badge' style={{background: + ' ' + surveyData?.statusCode}}>{surveyData?.statusName}</strong> 
                            </Col>
                        </Row>
                        <Row className="mb-1">
                            <Col md={5}>
                                <div className="text-right">{t('survey.name')}</div>
                            </Col>
                            <Col md={7}>
                                <strong>{surveyData?.name}</strong>
                            </Col>
                        </Row>
                        <Row className="mb-1">
                            <Col md={5}>
                                <div className="text-right">{t('subject.title')}</div>
                            </Col>
                            <Col md={7}>
                                <strong>{selectedRow?.subjectCode + ' - ' + selectedRow?.subjectName}</strong>
                            </Col>
                        </Row>
                        <Row className="mb-1">
                            <Col md={5}>
                                <div className="text-right">{t('teacher.title')}</div>
                            </Col>
                            <Col md={7}>
                                <strong>{selectedRow?.teacherCode + ' - ' + selectedRow?.teacherName}</strong>
                            </Col>
                        </Row>
                        {
                            surveyData?.purpose &&
                            <Row className="mb-1">
                                <Col md={5}>
                                    <div className="text-right">{t('survey.goal')}</div>
                                </Col>
                                <Col md={7}>
                                    <strong>{surveyData?.purpose || '-'}</strong>
                                </Col>
                            </Row>
                        }
                    </div>
                }
            </Modal.Body>
            <Modal.Footer>
                <div className="text-center">
                    <Button size="sm" variant="link" onClick={onClose}>
                        {t('common.back')}
                    </Button>
                    {
                        surveyData.statusCode == 'IN_PROGRESS' &&
                        <Button variant="success" className="br-8 ps-4 pe-4" size="sm" onClick={onSaveClick}>
                            {t('survey.sent')}
                        </Button>
                    }
                    {
                        surveyData.statusCode == 'NEW' &&
                        <Button variant="success" className="br-8 ps-4 pe-4" size="sm" onClick={onStartClick}>
                            {t('survey.fillOut')}
                        </Button>
                    }
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default SurveyModal;
