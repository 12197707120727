import React, { useRef, useEffect, useState } from "react";
import { Button, Modal, View, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getPsychology } from "../../../../utils/fetchRequest/Urls";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import message from "../../../modules/message";
import Forms from "../../../modules/Form/Forms";

const ChangePhychology = ({
    onClose,
    onFinish,
    id,
    phase,
    enroll,
    psychologyId,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [candidateDetail, setCandidateDetail] = useState([]);
    const [candidateLogDesc, setCandidateLogDesc] = useState([]);
    const [statusId, setStatusId] = useState();
    const [psychologyData, setPsychologyData] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const form = useRef();
    const form1 = useRef();

    useEffect(() => {
        getData();
    }, []);
    useEffect(() => {
        form?.current?.updateFields && form.current.updateFields(forms);
        form1?.current?.updateFields && form1.current.updateFields(forms1);
    }, [statuses, candidateDetail, candidateLogDesc, psychologyData]);
    const getData = () => {
        const params = {
            enroll: enroll,
            candidatePsychology: psychologyId,
            candidate: id,
            phase: phase,
        };

        dispatch(setLoading(true));
        fetchRequest(getPsychology, "GET", params)
            .then((res) => {
                if (res.success) {
                    const { data } = res;
                    const optionsaa = [];
                    data?.statuses.forEach((status) => {
                        optionsaa.push({ value: status.id, text: status.name });
                    });
                    setStatuses(optionsaa);
                    if (data?.candidateDetail) {
                        setCandidateDetail(data.candidateDetail);
                    }

                    if (data?.candidateLogDesc != null) {
                        setCandidateLogDesc(data.candidateLogDesc);
                    }
                    if (data?.psychologyData != null) {
                        setStatusId(Number(data.psychologyData.statusId));
                        setPsychologyData(data.psychologyData);
                    }
                } else if (psychologyId === null) {
                } else {
                    message(res?.data?.message || t("errorMessage.title"));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t("errorMessage.title"));
            });
    };
    console.log("status", statuses);
    const onSaveClick = () => {
        const [formValid, formValues] = form.current.validate();
        if (formValid) {
            const params = {
                candidate: id,
                candidatePsychology: psychologyId,
                status: statusId,
                enroll: enroll,
                description: formValues?.[0]?.value,
                phase: phase,
            };
            dispatch(setLoading(true));
            fetchRequest(getPsychology, "POST", params)
                .then((res) => {
                    if (res.success) {
                        message(res?.data?.message, true);

                        dispatch(setLoading(false));
                        onFinish();
                        // onClose();
                    } else {
                        message(res.data.message, false);
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t("errorMessage.title"));
                });
        }
    };
    const onStatusChange = (id) => {
        if (id) {
            setStatusId(id);
        }
    };

    const forms1 = [
        {
            label: false,
            value: statusId ? statusId : null,
            type: "dropdown",
            inputWidth: 192,
            maxLength: 160,
            key: "name",
            options: statuses,
            onChange: onStatusChange,
        },
    ];
    const forms = [
        {
            label: "Тайлбар*",
            type: "textArea",
            inputWidth: 240,
            labelWidth: 200,
            required: true,
            maxLength: 160,
            key: "description",
            value: candidateLogDesc.description,
        },
    ];
    return (
        <Modal
            show={true}
            onHide={onClose}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: "#4a70ae", fontSize: "1.1rem" }}>Засах</div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {psychologyId ? (
                    <div>
                        {" "}
                        <div className="row">
                            <div className="d-flex justify-content-end col-3 ml-10">
                                <img
                                    class="d-flex justify-content-end"
                                    style={{
                                        objectFit: "contain",
                                        height: "105px",
                                        width: "93px",
                                        margin: "auto",
                                        marginTop: "0.5px",
                                        marginLeft: "10rem",
                                    }}
                                    src={
                                        candidateDetail.avatar === null ||
                                            candidateDetail.avatar === "" ||
                                            candidateDetail.avatar === undefined ||
                                            candidateDetail.avatar === "NULL"
                                            ? "/media/users/avatar_male.png"
                                            : "https://darkhanapi.nomch.mn/" +
                                            candidateDetail.avatar
                                    }
                                />
                            </div>
                            <ul className="col-7" style={{ lineHeight: 0.5 }}>
                                <li class="d-flex justify-content-center row">
                                    <div class="col">
                                        <p
                                            style={{
                                                color: "#185BC5",
                                                fontWeight: "bold",
                                                fontSize: "normal",
                                            }}
                                            class=" d-flex justify-content-end"
                                        >
                                            Бүртгэлийн дугаар
                                        </p>
                                    </div>
                                    <div class="col justify-content-end">
                                        <span style={{ fontWeight: "bold" }} class="text-dark">
                                            {candidateDetail.id}
                                        </span>
                                    </div>
                                </li>
                                <li class="d-flex justify-content-center row pb-3">
                                    <div class="col">
                                        <p
                                            style={{
                                                color: "#185BC5",
                                                fontWeight: "bold",
                                                fontSize: "normal",
                                            }}
                                            class=" d-flex justify-content-end"
                                        >
                                            ЭЕШ бүртгэлийн дугаар
                                        </p>
                                    </div>
                                    <div class="col justify-content-end">
                                        <span style={{ fontWeight: "bold" }} class="text-dark">
                                            {candidateDetail.butName}
                                        </span>
                                    </div>
                                </li>
                                <li class="d-flex justify-content-center row">
                                    <div class="col">
                                        <p
                                            style={{
                                                color: "#185BC5",
                                                fontWeight: "bold",
                                            }}
                                            class=" d-flex justify-content-end"
                                        >
                                            Овог
                                        </p>
                                    </div>
                                    <div class="col justify-content-end">
                                        <span style={{ fontWeight: "bold" }} class="text-dark">
                                            {candidateDetail.lastName}
                                        </span>
                                    </div>
                                </li>
                                <li class="d-flex justify-content-center row">
                                    <div class="col">
                                        <p
                                            style={{
                                                color: "#185BC5",
                                                fontWeight: "bold",
                                                fontSize: "normal",
                                            }}
                                            class=" d-flex justify-content-end"
                                        >
                                            Нэр
                                        </p>
                                    </div>
                                    <div class="col justify-content-end">
                                        <span style={{ fontWeight: "bold" }} class="text-dark">
                                            {candidateDetail.firstName}
                                        </span>
                                    </div>
                                </li>
                                <li class="d-flex justify-content-center row">
                                    <div class="col">
                                        <p
                                            style={{
                                                color: "#185BC5",
                                                fontWeight: "bold",
                                                fontSize: "normal",
                                            }}
                                            class=" d-flex justify-content-end"
                                        >
                                            Регистрийн дугаар
                                        </p>
                                    </div>
                                    <div class="col justify-content-end">
                                        <span style={{ fontWeight: "bold" }} class="text-dark">
                                            {candidateDetail.registrationNumber}
                                        </span>
                                    </div>
                                </li>
                                <li class="d-flex justify-content-center row">
                                    <div class="col">
                                        <p
                                            style={{
                                                color: "#185BC5",
                                                fontWeight: "bold",
                                                fontSize: "normal",
                                            }}
                                            class=" d-flex justify-content-end"
                                        >
                                            Хүйс
                                        </p>
                                    </div>
                                    <div class="col justify-content-end">
                                        <span style={{ fontWeight: "bold" }} class="text-dark">
                                            {candidateDetail.gender === "M" ? "Эрэгтэй" : "Эмэгтэй"}
                                        </span>
                                    </div>
                                </li>
                                <li class="d-flex justify-content-center row">
                                    <div class="col">
                                        <p
                                            style={{
                                                color: "#185BC5",
                                                fontWeight: "bold",
                                                fontSize: "normal",
                                            }}
                                            class=" d-flex justify-content-end"
                                        >
                                            И-мэйл
                                        </p>
                                    </div>
                                    <div class="col justify-content-end">
                                        <span style={{ fontWeight: "bold" }} class="text-dark">
                                            {candidateDetail.email}
                                        </span>
                                    </div>
                                </li>
                                <li class="d-flex justify-content-center row">
                                    <div class="col">
                                        <p
                                            style={{
                                                color: "#185BC5",
                                                fontWeight: "bold",
                                                fontSize: "normal",
                                            }}
                                            class=" d-flex justify-content-end"
                                        >
                                            Утасны дугаар
                                        </p>
                                    </div>
                                    <div class="col justify-content-end">
                                        <span style={{ fontWeight: "bold" }} class="text-dark">
                                            {candidateDetail.gMobileNumber}
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="d-flex mt-5 mb-5 items-center justify-content-center">
                            <div
                                style={{ width: 600 }}
                                className="row d-flex align-sitems-center"
                            >
                                <span
                                    className="border border-1 col-4 d-flex align-items-center"
                                    style={{ fontWeight: "bold" }}
                                >
                                    Төлөв
                                </span>
                                <span className="border border-1 col-4 d-flex align-items-center">
                                    {psychologyData.statusName}
                                </span>
                                <span className="border border-1 col-4 d-flex d-flex-col">
                                    <span
                                        style={{
                                            marginTop: "-9px",
                                            marginBottom: "1px",
                                            marginLeft: "-30px",
                                        }}
                                        className="d-flex"
                                    >
                                        <Forms
                                            style={{ marginTop: "-10px", paddingLeft: "-10px" }}
                                            ref={form1}
                                            fields={forms1}
                                        />
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div class="d-flex justify-content-center">
                            <Forms ref={form} fields={forms} />
                        </div>
                        <div style={{ height: "100px" }}></div>
                    </div>
                ) : (
                    <>
                        <div className="d-flex justify-content-center">
                            Мэдээлэл бүртгэгдээгүй
                        </div>
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className="btn btn-link bolder">
                    {t("common.back")}
                </button>
                <Button variant="success btn-shadow" onClick={onSaveClick}>
                    {t("common.save")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ChangePhychology;
