import React, {useState, useCallback, useRef} from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { userProfile } from "../../../../../utils/fetchRequest/Urls";
import message from "../../../../../app/modules/message";
import { setLoading, setPersonInfo } from "../../../../../redux/action";
import { fetchRequest } from "../../../../../utils/fetchRequest";
import { useDispatch } from "react-redux";
import {ImageCropper} from "./ImageCropper";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

export const ChangeProfile=({onClose})=>{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const fileUploadRef = useRef();
    const [file, setBlob] = useState(null);
    const [inputImg, setInputImg] = useState('');
    const [cropData, setCropData] = useState("#");
    const [cropper, setCropper] = useState();

    const getBlob = (file) => {
        setBlob(file);
    };

    const onInputChange = (e) => {
        if(e.target && e.target.files && e.target.files.length > 0){
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                setInputImg(reader.result);
            }, false);
            if (file) {
                reader.readAsDataURL(file);
            }
        }
    };

    const saveImage=()=>{
        let croppedImg = null;
        if (typeof cropper !== "undefined") {
            croppedImg = cropper.getCroppedCanvas().toDataURL();
        }

        let params = {
            blob: croppedImg
        };
        dispatch(setLoading(true));
            fetchRequest(userProfile, 'POST', params)
                .then(response => {
                    if (response.success) {
                        dispatch(setPersonInfo(response.data.userData));
                        onClose();
                    } else {
                        message(response.data?.message, false);
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'));
                });
    };

    const onChooseFileHandler = () => {
        if(fileUploadRef && fileUploadRef.current){
            fileUploadRef.current.click();
        }
    };

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title >
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('system.profile').toUpperCase()}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='row'>
                    <div className='col-3'/>
                    <div className='col-8 ds-flex'>
                        <label
                            style={{
                                display: 'inline-flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                width: 'auto',
                                marginRight: '1rem',
                                marginBottom: 0
                            }}
                        >
                            {t('newsfeed.chooseImg')}
                        </label>
                        <input
                            ref={fileUploadRef}
                            type='file'
                            accept='image/*'
                            onChange={onInputChange}
                            hidden={true}
                        />
                        <input
                            style={{width: 'auto'}}
                            type="text"
                            className='form-control'
                        />
                        <Button style={{color: '#000'}} variant="btn btn-warning btn-shadow ml-3" onClick={onChooseFileHandler}>
                            <b>{t("newsfeed.chooseImg")}</b>
                        </Button>
                    </div>
                </div>
                <br/>
                <div className='row'>
                    {
                        inputImg && (
                            <Cropper
                                // style={{ height: 300, width: "100%" }}
                                // zoomTo={0.5}
                                // aspectRatio ={3/4}
                                preview=".img-preview"
                                src={inputImg}
                                viewMode={1}
                                minCropBoxHeight={10}
                                minCropBoxWidth={10}
                                responsive={false}
                                autoCropArea={1}
                                checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                onInitialized={(instance) => {
                                    setCropper(instance);
                                }}
                                guides={true}
                            />
                        )
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className='btn btn-link bolder'>{t('common.back')}</button>
                <Button variant='success btn-shadow' onClick={saveImage}>{t('common.save')}</Button>
            </Modal.Footer>
        </Modal>
    )
};