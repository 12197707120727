import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux';
import { setLoading } from 'redux/action';
import { fetchRequest } from 'utils/fetchRequest';
import message from 'app/modules/message';

import { EditorState, ContentState, convertToRaw, convertFromHTML } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import { graduationTemplateEdit } from 'utils/fetchRequest/Urls';

import 'app/css/mbDiploma.css'

const ViewModal = ({
    open = false,
    onClose = () => { },
    id = null
}) => {
    const { t } = useTranslation()

    const dispatch = useDispatch()

    const [name, setName] = useState('')
    const [level, setLevel] = useState('')
    const [textValue, setTextValue] = useState(null);
    const [textValueEn, setTextValueEn] = useState(null);
    const [textValueTr, setTextValueTr] = useState(null);

    const init = () => {
        try {
            dispatch(setLoading(true));
            fetchRequest(graduationTemplateEdit, 'GET', { id })
                .then(res => {
                    if (res.success) {
                        const { data: {
                            name = '',
                            educationLevel = '',
                            mb = null,
                            mn = null,
                            en = null
                        } = {} } = res

                        setName(name)
                        setLevel(educationLevel)
                        setTextValue(mn)
                        
                        const trState = ContentState.createFromBlockArray(
                            convertFromHTML(mb).contentBlocks,
                            convertFromHTML(mb).entityMap
                        );
                        setTextValueTr(()=>EditorState.createWithContent(trState))
                        setTextValueEn(en)
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                })
                .catch(() => {
                    message(t('errorMessage.title'))
                })
                .finally(() => dispatch(setLoading(false)))
        } catch (e) {
            message(t('errorMessage.title'))
        }
    }

    useEffect(() => {
        if (id) {
            init()
        }
    }, [id])

    return (
        <Modal
            show={open}
            onHide={onClose}
            size={'xl'}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {t('graduation.template')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={6} className='d-flex justify-content-end'>
                        <label className='font-size-h5'>{t('graduation.templateName')}</label>
                    </Col>
                    <Col md={6}>
                        <label className='font-size-h5 font-weight-bold'>{name}</label>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} className='d-flex justify-content-end'>
                        <label className='font-size-h5'>{t('curriculum.educationLevel')}</label>
                    </Col>
                    <Col md={6}>
                        <label className='font-size-h5 font-weight-bold'>{level}</label>
                    </Col>
                </Row>
                <div className='mb-5' />
                <h5 className="font-weight-bold">MN</h5>
                <div dangerouslySetInnerHTML={{ __html: textValue }} />
                <div style={dottedLine} />
                <h5 className="font-weight-bold">EN</h5>
                <div dangerouslySetInnerHTML={{ __html: textValueEn }} />
                <div style={dottedLine} />
                <h5 className="font-weight-bold">TR</h5>
                <Editor
                    editorState={textValueTr}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="mb-renderer"
                    onEditorStateChange={null}
                    editorStyle={{ border: "1px solid rgba(0, 0, 0, .2)", fontFamily: 'Urga' }}
                    toolbar={{
                        options: []
                    }}
                    toolbarStyle={{
                        display: 'none'
                    }}
                />
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className="btn btn-link bolder mr-2">
                    {t("common.close").toLocaleUpperCase()}
                </button>
            </Modal.Footer>
        </Modal>
    )
}

const dottedLine = {
    borderTop: '1px dashed #000000',
    height: '1px',
    margin: '1rem'
}

export default ViewModal
