// https://react-bootstrap-table.github.io/react-bootstrap-table2/
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
// https://www.npmjs.com/package/react-bootstrap-table-next
import React, { useState, useRef, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider, SizePerPageDropdownStandalone } from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import cellEditFactory from 'react-bootstrap-table2-editor';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import PaginationLinks from "./PaginationLinks";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Search from "./Search";
import { cloneDeep } from 'lodash';
import ReactDOM from 'react-dom';
import { ClickAwayListener } from "@material-ui/core";
import './datatable.scss';
import DragHandle from "./DragHandle";
import { isEqual } from 'lodash';
import { Button } from "react-bootstrap";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { htmlDecode, numberFormat, numberReverseFormat, priceFormat } from "../../../utils/utils";
import * as XLSX from "xlsx";
import { fetchRequest } from "../../../utils/fetchRequest";
import Checkbox from '@mui/material/Checkbox';

const defaultConfig = {
    showPagination: true,
    showFilter: true,
    showLeftButton: false,
    showLeftButton2: false,
    leftButtonClassName: '',
    leftButtonClassName2: '',
    leftButtonStyle: {},
    leftButtonStyle2: {},
    leftButtonText: '',
    leftButtonText2: '',
    tableMarginLess: false,
    defaultSort: [
        {
            dataField: 'id',
            order: 'asc'
        },
    ],
    showAllData: false,
    footer: false,
    footerStyle: '',
    headerFilter: false,
    isTableEdit: false,
    blurToSave: false,
    excelExport: false,
    customExport: false,
    excelFileName: '',
    defaultPageOptions: {
        custom: true,
        paginationSize: 5,
        sizePerPageList: [10, 25, 50, 100],
        page: null,
        sizePerPage: null,
    }
};

const MyExportCSV = (props) => {
    const handleRemoteExcel = () => {
        fetchRequest(props.excelFileRemoteUrl, "GET", props.params)
            .then((res) => {
                if (res.success) {
                    const { data } = res;

                    if (data.list && data.list.length > 0) {
                        handleClick(data.list);
                    } else {
                        handleClick([]);
                    }
                }
            })
            .catch(() => { });
    };

    const handleClick = (data = []) => {
        const tableList = [];
        if (
            props.columns &&
            props.columns.length > 0 &&
            props.data &&
            props.data.length > 0
        ) {
            const header = props.columns;
            const filterRecords = data && data.length > 0 ? data : props.data;

            let rowIndex = 1;
            let records = [];
            for (let record of filterRecords) {
                let recordCol = {};
                let colIndex = 1;
                for (let column of header) {
                    if (
                        !column.hide &&
                        !column.hidden &&
                        column.text != "" &&
                        !column.isDummyField &&
                        column.dataField?.toLowerCase() != "avatar" &&
                        column.text != "image" &&
                        column.colType !== "image"
                    ) {
                        if (column.dataField in record) {
                            if (column.colType && column.colType === "number") {
                                if (
                                    typeof record[column.dataField] === "string" &&
                                    record[column.dataField].includes("'")
                                ) {
                                    recordCol["№"] = rowIndex;
                                    recordCol[column.text] = parseInt(
                                        numberReverseFormat(record[column.dataField], "'", "")
                                    );
                                    colIndex++;
                                } else if (
                                    typeof record[column.dataField] === "string" &&
                                    record[column.dataField].includes(",")
                                ) {
                                    recordCol[column.text] = parseInt(
                                        numberReverseFormat(record[column.dataField], ",", "")
                                    );
                                    colIndex++;
                                } else {
                                    recordCol[column.text] = record[column.dataField];
                                    colIndex++;
                                }
                            } else if (column.colType && column.colType === "html") {
                                if (column.textValueKey) {
                                    if (column.text !== undefined) {
                                        recordCol[column.text] = record[column.textValueKey] || "-";
                                    }
                                } else {
                                    if (
                                        record[column.dataField].props &&
                                        record[column.dataField].props.data
                                    ) {
                                        let cellText =
                                            (record[column.dataField].props &&
                                                record[column.dataField].props.data) ||
                                            "";
                                        recordCol[column.text] = cellText;
                                    } else {
                                        let cellText = htmlDecode(record[column.dataField]);
                                        recordCol[column.text] = cellText;
                                    }
                                }
                                colIndex++;
                            } else if (column.colType && column.colType === "qpay") {
                                recordCol[column.text] = record[column.dataField].toString();
                                colIndex++;
                            } else if (column.colType && column.colType === "date_label") {
                                recordCol[column.text] =
                                    record[column.dataField].props &&
                                        record[column.dataField].props.data
                                        ? record[column.dataField].props.data
                                        : "";
                            } else if (column.colType && column.colType === "gender") {
                                if (record[column.dataField] == "M") {
                                    recordCol[column.text] = "Эрэгтэй";
                                } else if (record[column.dataField] == "F") {
                                    recordCol[column.text] = "Эмэгтэй";
                                }
                            } else {
                                recordCol["№"] = rowIndex;
                                recordCol[column.text] = record[column.dataField];
                                colIndex++;
                            }
                        }
                    }
                }
                rowIndex++;
                records.push(recordCol);
            }

            if (rowIndex == filterRecords.length + 1) {
                let footerRecordCol = {};
                for (let i = 0; i < header.length; i++) {
                    let column = header[i];
                    footerRecordCol[column.text] = i;
                    if (column && column.footerAvg && column.footerAvg === true) {
                        let avg = 0;
                        let total = 0;
                        let isPercent = false;
                    }
                }
            }

            let ws = XLSX.utils.json_to_sheet(records);
            let wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "sheet");
            let buf = XLSX.write(wb, { bookType: "xlsx", type: "buffer" }); // generate a nodejs buffer
            let str = XLSX.write(wb, { bookType: "xlsx", type: "binary" }); // generate a binary string in web browser
            XLSX.writeFile(wb, `${props?.excelFileName || "file"}.xlsx`);
        }
    };

    return (
        <button
            style={{
                height: "33px",
                width: "33px",
            }}
            className="btn m-btn--icon m-btn--icon-only btn-info br-03 mx-1"
        >
            <i
                onClick={props.excelFileRemote ? handleRemoteExcel : handleClick}
                className="la la-file-excel-o"
                style={{
                    fontSize: "22px",
                    color: "#ffffff",
                    position: "relative",
                    right: 8,
                    bottom: 1,
                }}
            />
        </button>
    );
};

const DTable = ({
    data = [],
    config: propsConfig = {},
    columns = [],
    onInteraction,
    loading = false,
    remote = false,
    totalDataSize = 0,
    contextMenus = [],
    onContextMenuItemClick,
    onLeftButtonClick,
    onLeftButtonClick2,
    wrapperClassName = '',
    className = '',
    draggable = false,
    onDragStart,
    onDrop,
    showOrdering = true,
    individualContextMenus = false,
    isSelect = false,
    selectMode = 'checkbox',
    selectModeClass = '#fff',
    selectPosition = 'left',
    onSelect,
    onSelectAll,
    onExport,
    customSelectRow,
    expandRow,
    nonSelectableIds,
    checkable = false,
    hideAllCheck = false,
    onCheckable,
}) => {
    const [initialData, setInitialData] = useState([]);
    const [allCheckValue, setAllCheckValue] = useState(false);

    const config = { ...defaultConfig, ...propsConfig };
    const [searchValue, setSearchValue] = useState(config.defaultPageOptions.search || '');
    const [sizePerPage, setSizePerPage] = useState(config.defaultPageOptions.sizePerPage || 10);
    const [pageNumber, setPageNumber] = useState(1);

    const tableRef = useRef(null);

    useEffect(() => {
        if (!isEqual(data, initialData)) {
            setInitialData(data);
        }
        if (config.showAllData) {
            setSizePerPage(data.length + 10)
        }
        return () => {
            unMountContextMenus();
        }
    }, [data]);

    const pgOptions = {
        custom: true,
        paginationSize: 5,
        sizePerPageList: config.defaultPageOptions.sizePerPageList || [10, 25, 50, 100],
        totalSize: remote ? totalDataSize : data && data.length > 0 ? data.length : 0,
        page: config?.defaultPageOptions?.page ? config.defaultPageOptions.page : pageNumber,
        sizePerPage: config?.defaultPageOptions?.sizePerPage || sizePerPage,
        search: config?.defaultPageOptions?.search || '',
    };

    const handleTableChange = (type, newState) => {
        switch (type) {
            case 'filter': {
                break;
            }
            case 'pagination': {
                if (newState.page !== pageNumber) {
                    setPageNumber(newState.page);
                }
                if (newState.sizePerPage !== sizePerPage) {
                    setSizePerPage(newState.sizePerPage);
                    // getUserInteraction({ pageSize: newState.sizePerPage });
                }
                getUserInteraction({
                    page: newState.page ? newState.page : 1,
                    pageSize: newState.sizePerPage
                });
                break;
            }
            case 'sort': {
                getUserInteraction({
                    sort: newState.sortField,
                    order: newState.sortOrder
                });
                break;
            }
            case 'cellEdit': {
                break;
            }
            default: {
                return;
            }
        }
    };

    const getNoDataText = () => {
        if (loading) {
            return (
                <div className="spinner-border text-primary" />
            )
        }

        const text = 'Мэдээлэл олдсонгүй';

        return (
            // text
            <div style={{ textAlign: 'center' }}>
                {text}
            </div>
        );
    };

    const handleSearch = () => {
        if (remote) {
            getUserInteraction();
        }
    };

    const getTableData = () => {
        if (remote) {
            return data;
        }

        let clone = [];
        if (Array.isArray(initialData)) {
            clone = cloneDeep(initialData);
        } else {
            clone = cloneDeep(Object.values(initialData));
        }

        if (searchValue) {
            const val = searchValue.toLowerCase();
            return clone.filter(record => {
                for (const column of columns) {
                    if (record.hasOwnProperty(column.dataField)) {
                        /// ignoring number type for now!!!.
                        if (typeof record[column.dataField] === 'string' && record[column.dataField].toLowerCase().includes(val)) {
                            return true;
                        }
                    }
                }
                return false;
            })
        }
        return clone;
    };

    const getUserInteraction = (object) => {
        let tableState = {};
        if (tableRef.current) {
            const { page, sizePerPage: pageSize, sortField, sortOrder } = tableRef.current.getNewestState();
            tableState = {
                page: page ? page : 1,
                pageSize,
                sort: sortField,
                order: sortOrder,
                search: searchValue,
                filter: object
            };
        }

        onInteraction?.({
            ...tableState,
            ...object,
        });
    };

    const unMountContextMenus = () => {
        const wrapper = getWrapper();
        if (wrapper) {
            ReactDOM.unmountComponentAtNode(wrapper);
        }
    };

    const onContextMenu = (e, row) => {
        e.preventDefault();
        unMountContextMenus();

        let availableContextMenus = [];

        if (individualContextMenus) {
            if (contextMenus.length && row.contextMenuKeys?.length) {
                for (const menu of contextMenus) {
                    if (row.contextMenuKeys.includes(menu.key)) {
                        availableContextMenus.push(menu);
                    }
                }
            }
        } else {
            availableContextMenus = contextMenus;
        }

        if (availableContextMenus.length) {
            const wrapper = getWrapper();
            const menu = (
                <ClickAwayListener onClickAway={unMountContextMenus}>
                    <div
                        className='dt-cm-wrapper'
                        style={{ top: e.pageY, left: e.pageX }}
                    >
                        {
                            availableContextMenus.map(menu => {
                                return (
                                    <div
                                        className='dt-cm-item'
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            unMountContextMenus();
                                            onContextMenuItemClick?.(row.id, menu.key, row, event);
                                        }}
                                        key={menu.key}
                                    >
                                        <div>
                                            {menu.icon ? (menu.icon) : null}
                                        </div>
                                        <span>
                                            {menu.title}
                                        </span>
                                    </div>
                                )
                            })
                        }
                    </div>
                </ClickAwayListener>
            );
            ReactDOM.render(menu, wrapper);
        }
    };

    const getWrapper = () => {
        const id = 'datatable-contextmenu-wrapper';
        const cmWrapper = document.getElementById(id);
        if (cmWrapper) {
            return cmWrapper;
        } else {
            const cmWrapper = document.createElement('div');
            cmWrapper.id = id;
            document.body.appendChild(cmWrapper);
            return cmWrapper;
        }
    };

    const onRowDragStart = params => {
        onDragStart?.(params);
    };

    const onRowDrop = params => {
        onDrop?.(params);
    };

    const onCheckableHandler = (e, id, rowIndex, allCheck) => {
        let clone = [...initialData];

        if (clone && clone.length > 0 && id) {
            for (let i = 0; i < clone.length; i++) {
                if (id == clone[i].id) {
                    clone[i].checkable = e.target.checked;
                }
            }
        }

        setInitialData(clone);
        if (allCheck == 'allCheck') {
            setAllCheckValue(e.target.checked);
            onCheckable?.('allCheck', rowIndex, e.target.checked)
        } else {
            onCheckable?.('row', rowIndex, e.target.checked, id)
        }
    }

    const getColumns = (filterVisible = true) => {
        const cols = [];
        if (draggable) {
            if (config.footer) {
                cols.push({
                    isDummyField: true,
                    text: '',
                    dataField: 'dragHandle',
                    formatter: (cell, row) => {
                        return (
                            <DragHandle
                                rowClass={`dt-row-${row.id}`}
                                data={row}
                                onStart={onRowDragStart}
                                onDrop={onRowDrop}
                            />
                        )
                    },
                    headerStyle: () => {
                        return {
                            width: 30
                        }
                    },
                    style: {
                        verticalAlign: 'middle',
                    },
                    footer: '',
                    footerStyle: config.footerStyle
                });
            } else {
                cols.push({
                    isDummyField: true,
                    text: '',
                    dataField: 'dragHandle',
                    formatter: (cell, row) => {
                        return (
                            <DragHandle
                                rowClass={`dt-row-${row.id}`}
                                data={row}
                                onStart={onRowDragStart}
                                onDrop={onRowDrop}
                            />
                        )
                    },
                    headerStyle: () => {
                        return {
                            width: 30
                        }
                    },
                    style: {
                        verticalAlign: 'middle',
                    },
                });
            }
        }

        if (checkable) {
            cols.push({
                isDummyField: true,
                text: '',
                headerFormatter: (column, index, components) => {
                    return hideAllCheck
                        ? null
                        : (
                            <Checkbox
                                style={{padding: 3}}
                                className="checkbox-light"
                                checked={allCheckValue}
                                onChange={(e) => onCheckableHandler(e, null, index, 'allCheck')}
                            />
                        );
                },
                dataField: 'checkable',
                formatter: (cell, row, rowIndex) => {
                    return (
                        <Checkbox
                            style={{padding: 4}}
                            className="checkbox-light"
                            checked={row.checkable || false}
                            onChange={(e) => onCheckableHandler(e, row.id, rowIndex, '')}
                        />
                    );
                },
                headerStyle: () => {
                    return {
                        width: 50,
                    };
                },
                style: {
                    verticalAlign: 'middle',
                },
            });
        }

        if (showOrdering) {
            if (config.footer) {
                cols.push({
                    isDummyField: true,
                    text: '№',
                    dataField: 'dt-row-order-number',
                    formatter(cell, row, rowIndex) {
                        return rowIndex + 1;
                    },
                    headerStyle: () => {
                        return {
                            width: 50
                        }
                    },
                    headerAlign: 'center',
                    style: {
                        textAlign: 'right',
                    },
                    footer: '',
                    footerStyle: config.footerStyle
                });
            } else {
                cols.push({
                    isDummyField: true,
                    text: '№',
                    dataField: 'dt-row-order-number',
                    formatter(cell, row, rowIndex) {
                        return rowIndex + 1;
                    },
                    headerStyle: () => {
                        return {
                            width: 50
                        }
                    },
                    headerAlign: 'center',
                    style: {
                        textAlign: 'right',
                    },
                    csvFormatter(cell, row, rowIndex) {
                        return rowIndex + 1;
                    }
                });
            }
        }

        for (const col of columns) {
            if (filterVisible) {
                if (col.hideCol) {

                } else {
                    if (config.headerFilter) {
                        if (col.filterDisable) {
                            cols.push({
                                ...col,
                            });
                        } else {
                            cols.push({
                                ...col,
                                filter: textFilter({
                                    placeholder: ' ',
                                    onFilter: (filterValue) => {
                                        getUserInteraction({
                                            filter: filterValue,
                                            field: col.dataField
                                        });
                                    }
                                }),
                            });
                        }
                    } else {
                        cols.push({
                            ...col,
                        });
                    }
                }
            } else {
                if (config.headerFilter) {
                    if (col.filterDisable) {
                        cols.push({
                            ...col,
                        });
                    } else {
                        cols.push({
                            ...col,
                            filter: textFilter({
                                placeholder: ' ',
                                onFilter: (filterValue) => {
                                    getUserInteraction({
                                        filter: filterValue,
                                        field: col.dataField
                                    });
                                }
                            }),
                        });
                    }
                } else {
                    cols.push({
                        ...col,
                    });
                }
            }
        }

        if (!config.footer) {
            if (cols && cols.length > 0) {
                for (let i = 0; i < cols.length; i++) {
                    if (cols && cols[i].footer) {
                        cols[i].footer = false;
                    }
                }
            }
        } else {
            if (cols && cols.length > 0) {
                for (let i = 0; i < cols.length; i++) {
                    if (cols) {
                        cols[i].footerStyle = config.footerStyle;
                        if (cols[i].footerType == 'sum') {
                            let sum = 0;
                            if (data && data.length > 0) {
                                for (let c = 0; c < data.length; c++) {
                                    let amount = data[c][cols[i].dataField];
                                    if (amount) {
                                        const specialChars = '\'"\\,./`';
                                        const isSpecChar = checkSpecialChars(specialChars, amount);

                                        if (isSpecChar) {
                                            amount = amount.replaceAll("'", '');
                                        }
                                        sum += parseInt(amount);
                                    }
                                }
                            }

                            cols[i].footer = sum?.toFixed() || '0';
                        } else if (cols[i].footerType == 'avg') {
                            let avg = 0;
                            let totalCount = 0;
                            if (data && data.length > 0) {
                                for (let c = 0; c < data.length; c++) {
                                    let amount = data[c][cols[i].dataField];
                                    if (amount) {
                                        totalCount = totalCount + amount;
                                    }
                                }

                                avg = totalCount / data.length;
                            }

                            cols[i].footer = avg?.toFixed(2) || '0';
                        } else if (cols[i].footerType == 'avgGpa') {
                            let avgGpa = 0;
                            let totalCredit = 0;
                            let total = 0;
                            if (data && data.length > 0) {
                                for (let c = 0; c < data.length; c++) {
                                    let amount = data[c][cols[i].dataField];
                                    if (amount && data[c].credit > 0) {
                                        total = total + (amount * data[c].credit);
                                    }
                                    totalCredit = totalCredit + data[c].credit;
                                }

                                avgGpa = total / totalCredit;
                            }

                            cols[i].footer = avgGpa?.toFixed(2) || '0';
                        }
                    }
                }
            }
        }

        return cols;
    };

    const checkSpecialChars = (specialChars, string) => {
        if (typeof string === 'string') {
            for (let i = 0; i < specialChars.length; i++) {
                if (string?.toString().indexOf(specialChars[i]) > -1) {
                    return true;
                }
            }
        }
        return false;
    };

    const handlerOnSelect = (row, isSelect, rowIndex, e) => {
        onSelect?.(row, isSelect, rowIndex, e);
    };

    const handlerOnSelectAll = (isSelect, rows, e) => {
        onSelectAll?.(isSelect, rows, e);
    };

    const selectRow = {
        mode: selectMode,
        clickToSelect: true,
        nonSelectable: nonSelectableIds,
        nonSelectableClasses: 'row-disabled',
        hideSelectColumn: !isSelect,
        selectColumnPosition: selectPosition,
        classes: selectModeClass ? selectModeClass : 'dtable-selected',
        onSelect: (row, isSelect, rowIndex, e) => { handlerOnSelect(row, isSelect, rowIndex, e) },
        onSelectAll: (isSelect, rows, e) => { handlerOnSelectAll(isSelect, rows, e) },
        clickToEdit: config.isTableEdit,
    };

    const cellEdit = cellEditFactory({
        mode: 'click',
        blurToSave: config.blurToSave,
    });

    const { ExportCSVButton } = CSVExport;

    return (
        <PaginationProvider pagination={paginationFactory(pgOptions)}>
            {
                ({
                    paginationProps,
                    paginationTableProps
                }) => (
                    <div style={config.tableMarginLess ? {} : { marginBottom: 15, marginTop: 15 }}>
                        <DndProvider backend={HTML5Backend}>
                            <ToolkitProvider
                                keyField="id"
                                data={getTableData()}
                                columns={getColumns()}
                                // exportCSV={{ 
                                //     fileName: config.excelFileName?.replaceAll(/ /g, "_") + '.xlsx', 
                                //     exportAll: false,
                                //     blobType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'

                                // }}
                                exportCSV={{
                                    fileName: config.excelFileName?.replaceAll(/ /g, "_") + ".xlsx",
                                    exportAll: false,
                                    blobType:
                                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
                                }}
                            >
                                {
                                    props => (
                                        <div>
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                marginBottom: 10,
                                            }}>
                                                {
                                                    config.showLeftButton
                                                        ?
                                                        <button
                                                            className={config.leftButtonClassName}
                                                            style={config.leftButtonStyle}
                                                            onClick={onLeftButtonClick}
                                                        >
                                                            {config.leftButtonText}
                                                        </button>
                                                        : null
                                                }
                                                {
                                                    config.showLeftButton2
                                                        ?
                                                        <button
                                                            className={config.leftButtonClassName2}
                                                            style={config.leftButtonStyle2}
                                                            onClick={onLeftButtonClick2}
                                                        >
                                                            {config.leftButtonText2}
                                                        </button>
                                                        : null
                                                }
                                                <SizePerPageDropdownStandalone
                                                    className={`custom-size-per-page ${!config.showPagination ? 'height-0' : ''}`}
                                                    {...paginationProps}
                                                    //sizePerPage={sizePerPage}
                                                    hidden={!config.showPagination}
                                                />
                                                <div className="w-100 text-right">
                                                    {
                                                        config.excelExport
                                                            ?
                                                            <MyExportCSV
                                                                columns={getColumns(false)}
                                                                data={getTableData()}
                                                                {...config}
                                                            />
                                                            : null
                                                    }
                                                    {
                                                        config.customExport
                                                            ?
                                                            <Button
                                                                children={<i
                                                                    className="las la-file-excel m-0 p-0"
                                                                    style={{
                                                                        fontSize: '22px',
                                                                        color: '#ffffff',
                                                                    }}
                                                                />}
                                                                onClick={onExport}
                                                                style={{
                                                                    backgroundColor: '#5c3cc7',
                                                                    boxShadow: '0 2px 10px 0 #5c3cc776',
                                                                    border: 'none',
                                                                    height: '33px',
                                                                    alignItems: 'center'
                                                                }}
                                                                className='m-btn m-btn--icon m-btn--icon-only p-1 mx-2'
                                                            />
                                                            : null
                                                    }
                                                </div>
                                                {
                                                    config.showFilter && (
                                                        <Search
                                                            value={searchValue}
                                                            onSearch={handleSearch}
                                                            setter={setSearchValue}
                                                        />
                                                    )
                                                }
                                            </div>
                                            <BootstrapTable
                                                {...paginationTableProps}
                                                {...props.baseProps}
                                                ref={tableRef}
                                                keyField="id"
                                                wrapperClasses={`table-responsive ${wrapperClassName}`}
                                                classes={`table custom-dt ${className}`}
                                                remote={remote}
                                                data={getTableData()}
                                                columns={getColumns()}
                                                onTableChange={handleTableChange}
                                                noDataIndication={getNoDataText}
                                                defaultSorted={config.defaultSort}
                                                rowEvents={{ onContextMenu }}
                                                rowClasses={row => {
                                                    let cursor = 'cursor-default'
                                                    if (individualContextMenus) {
                                                        if (row?.contextMenuKeys) cursor = 'cursor-context-menu'
                                                    }
                                                    else if (contextMenus?.length) cursor = 'cursor-context-menu'
                                                    return `dt-row-${row.id} ${cursor}`;
                                                }}
                                                selectRow={selectRow}
                                                filter={filterFactory()}
                                                cellEdit={cellEdit}
                                                bootstrap4
                                            >
                                            </BootstrapTable>
                                        </div>
                                    )
                                }
                            </ToolkitProvider>
                        </DndProvider>
                        {
                            config.showPagination && (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ flex: 1 }}>
                                        <PaginationLinks paginationProps={paginationProps} />
                                    </div>
                                    <div>{`Нийт ${paginationProps.totalSize} утга`}</div>
                                </div>
                            )
                        }
                    </div>
                )
            }
        </PaginationProvider>
    );
};

export default DTable;
