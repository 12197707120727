import React from "react";
import {Newsfeed} from "./Newsfeed";

export const StudentHome = () => {
    return (
        <div className='sm-container'>
            <Newsfeed/>
        </div>
    )
};
export default StudentHome;

