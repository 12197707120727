import React, {useEffect, useState} from 'react'
import DTable from '../../../../modules/DataTable/DTable'
import {Button, ButtonToolbar, Card} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import DeleteModal from "../../../../modules/DeleteModal";
import Select from "../../../../modules/Form/Select";
import DatePicker from "../../../../modules/Form/DatePicker";
import {setLoading} from "../../../../../redux/action";
import {fetchRequest} from "../../../../../utils/fetchRequest";
import {adminNewsfeedDelete, teacherOnlineLessonInit} from "../../../../../utils/fetchRequest/Urls";
import message from "../../../../modules/message";
import season from "../../../setting/school/Season";
import {Link, useHistory} from "react-router-dom";

export const TeacherOnlineLesson = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [subjects, setSubjects] = useState([]);
    const [deleteModal, setDeleteModal] = useState(false);

    const [subjectId, setSubjectId] = useState(null);
    const [yearId, setYearId] = useState(null);
    const [seasonId, setSeasonId] = useState(null);
    const [codeId, setCodeId] = useState(null);
    const [typeId, setTypeId] = useState(null);
    const [years, setYears] = useState([]);
    const [seasons, setSeasons] = useState([]);
    const [codes, setCodes] = useState([]);
    const [types, setTypes] = useState([]);
    const [courses, setCourses] = useState([]);
    const [educationLevels, setEducationLevels] = useState([]);
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        let params = {
            school: schoolId
        }

        init(params)
    }, [])

    const init = (params) => {
        dispatch(setLoading(true));
        fetchRequest(teacherOnlineLessonInit, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setTableData(data?.courses || []);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }

    const config = {
        showPagination: true,
        showFilter: true,
    };

    const columns = [
        {
            dataField: 'status',
            text: t('common.status'),
            sort: true,
        },
        {
            dataField: 'code',
            text: t('subject.code'),
            sort: true,
        },
        {
            dataField: 'name',
            text: t('subject.name'),
            sort: true,
        },
        {
            dataField: 'type',
            text: t('subject.type'),
            sort: true,
        },
        {
            dataField: 'groupNames',
            text: t('group.grades'),
            sort: true,
        },
        {
            dataField: 'duration',
            text: t('common.duration'),
            sort: true,
        },
        {
            dataField: 'createdUserName',
            text: t('common.createdUser'),
            sort: true,
        },
        {
            dataField: 'createdDate',
            text: t('common.createdDate'),
            sort: true,
        },
    ];

    const contextMenus = [
        {
            key: 'edit',
            title: t('common.edit'),
            icon: <i className='las la-edit dt-cm-item-icon'/>
        },
        {
            key: 'delete',
            title: t('common.delete'),
            icon: <i className='las la-trash-alt dt-cm-item-icon' />,
        },
        {
            key: 'report',
            title: t('report.view'),
            icon: <i className='flaticon-graphic-1 dt-cm-item-icon' />,
        },
        {
            key: 'change',
            title: t('report.changeDuration'),
            icon: <i className='la la-refresh dt-cm-item-icon' />,
        },
        {
            key: 'close',
            title: t('common.close'),
            icon: <i className='la la-eye-slash dt-cm-item-icon' />,
        },
    ];

    const handleContextMenuClick = (id, key, record) => {
        if(key === 'view'){

        } else if (key === 'delete'){
            setDeleteModal(true);
        } else if (key === 'report'){

        } else if (key === 'change'){

        } else if (key === 'close'){

        } else if (key === 'edit'){
            history.push({
                pathname: '/teacher/online/lesson-edit',
                state: {
                    courseId: id,
                }
            });
        }
    };

    const onYearSelect = (id) => {
        setSeasonId(id)
    };

    const onSeasonSelect = (id) => {
        setSeasonId(id);

        const params = {
            school: schoolId,
        };

        if(seasonId){
            params.season = id;
        }
        if(codeId){
            params.code = codeId;
        }
        if(typeId){
            params.status = typeId;
        }
    };

    const onCodeSelect = (id) => {
        setCodeId(id)
    };

    const onTypeSelect = (id) => {
        setTypeId(id)
    };

    const handlerOnSearchClick = () => {
        dispatch(setLoading(true));

        const params = {
            school: schoolId,
        };

        if(yearId){
            params.year = yearId
        }
        if(seasonId){
            params.season = seasonId
        }
        if(codeId){
            params.code = codeId
        }
        if(typeId){
            params.status = typeId
        }

        init(params)
    };

    const handlerOnClearClick = () => {
        setYearId(null);
        setSeasonId(null);
        setCodeId(null);
        setTypeId(null);
    };

    const onModalClose = () => {
        setDeleteModal(false);
    };

    const onDelete = () => {
        const bodyParams = {
            subject: subjectId
        };

        dispatch(setLoading(true));
        fetchRequest(adminNewsfeedDelete, 'POST', bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    message(res?.data?.message || t('common.success'), true);
                    onModalClose();
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            })
    };

    return (
        <div className='sm-container'>
            <Card className='mb-4'>
                <Card.Body>
                    <div className='row'>
                        <div className='col pr-0'>
                            <label>{t('curriculum.educationLevel')}</label>
                            <Select
                                value={yearId}
                                onChange={onYearSelect}
                                options={years}
                                searchable
                            />
                        </div>
                        <div className='col pr-0'>
                            <label>{t('school.season.title')}</label>
                            <Select
                                value={yearId}
                                onChange={onYearSelect}
                                options={years}
                                searchable
                            />
                        </div>
                        <div className='col pr-0'>
                            <label>{t('subject.season')}</label>
                            <Select
                                value={seasonId}
                                onChange={onSeasonSelect}
                                options={seasons}
                                searchable
                            />
                        </div>
                        <div className='col pr-0'>
                            <label>{t('subject.code')}</label>
                            <Select
                                value={codeId}
                                onChange={onCodeSelect}
                                options={codes}
                                searchable
                            />
                        </div>
                        <div className='col pr-0'>
                            <label>{t('common.status')}</label>
                            <Select
                                value={typeId}
                                onChange={onTypeSelect}
                                options={types}
                                searchable
                            />
                        </div>
                    </div>
                </Card.Body>
                <Card.Footer>
                    <div className='col-12 text-center'>
                        <button className="btn btn-link bolder" onClick={handlerOnClearClick}>{t('common.clear').toUpperCase()}</button>
                        <button className="btn btn-warning m-btn--icon m-btn--icon-only btn-shadow" onClick={handlerOnSearchClick}>
                            <i className="flaticon-search-1" style={{color: '#000'}}></i>
                            <span style={{color: '#000'}}>
                                {t('common.search').toUpperCase()}
                            </span>
                        </button>
                    </div>
                </Card.Footer>
            </Card>
            <Card>
                <Card.Body>
                    <div className='row'>
                        <div className='col-12'>
                            <ButtonToolbar className='d-inline'>
                                <Link
                                    to={{
                                        pathname: '/teacher/online/lesson-add',
                                    }}
                                    className="btn btn-primary btn-shadow"
                                >
                                    {t('common.add')}
                                </Link>
                            </ButtonToolbar>
                        </div>
                        <div className='col-12'>
                            <DTable
                                data={tableData}
                                columns={columns}
                                config={config}
                                totalDataSize={150}
                                contextMenus={contextMenus}
                                onContextMenuItemClick={handleContextMenuClick}
                                selectMode={'radio'}
                            />
                        </div>
                    </div>
                </Card.Body>
            </Card>
            {
                deleteModal && (
                    <DeleteModal
                        onClose={onModalClose}
                        onDelete={onDelete}
                        title={t('warning.delete')}>
                        {t('warning.delete_confirmation')}
                        <br />
                        <br />
                        {t('warning.delete_confirmation_description')}
                    </DeleteModal>
                )
            }
        </div>
    )
};
