import AddModal from "./addModal";
import EditModal from "./editModal";
import message from "../../../modules/message";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Card, Modal, Button } from "react-bootstrap";
import { setLoading } from "../../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import DTable from "../../../modules/DataTable/DTable";
import React, { useState, useEffect, useRef } from "react";
import { fetchRequest } from "../../../../utils/fetchRequest";
import { getDeleteProgram } from "../../../../utils/fetchRequest/Urls";

const config = {
    showPagination: false,
    showFilter: false,
    showAllData: true,
};
const Profession = ({ enrollId, programs }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [deleteId, setDeleteId] = useState(null);
    const [selectedId, setSelectedId] = useState(-1);
    const [showModal, setShowModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [schoolPrograms, setSchoolPrograms] = useState([]);

    useEffect(() => {
        setSchoolPrograms(programs)
    }, [programs]);

    const handleEdit = (cell, row) => {
        setSelectedId(row.id);
        setShowEditModal(true);
    };

    const handleDelete = (cell, row) => {
        setDeleteId(row.id);
        setDeleteModal(true);
    };

    const columns = [
        {
            dataField: "longName",
            text: "Бүрэлдэхүүн сургууль",
            sort: true,
        },
        {
            dataField: "academicCode",
            text: "Хөтөлбөрийн индекс",
            sort: true,
        },
        {
            dataField: "academicName",
            text: "Хөтөлбөрийн нэр",
            sort: true,
        },
        {
            dataField: "programName",
            text: "Мэргэжил",
            sort: true,
        },
        {
            dataField: "isHide",
            text: "Хаагдсан эсэх",
            sort: false,
            formatter: (cell, row) => {
                if (cell === true) {
                    return t("common.yes")
                } else {
                    return t("common.no")
                }
                
            },
        },
        {
            dataField: "icons",
            text: "",
            sort: true,
            style: { textAlign: "center", padding: 3 },
            formatter: (cell, row) => {
                return (
                    <div>
                        <Button onClick={() => {handleEdit(cell, row)}} className="btn btn-purple m-btn m-btn--icon m-btn--icon-only m-btn--circle-30 mr-1">
                            <i className="flaticon-edit"> </i>
                        </Button>
                        <Button onClick={() => {handleDelete(cell, row)}} className="btn btn-danger m-btn m-btn--icon m-btn--icon-only m-btn--circle-30">
                            <i className="flaticon2-cross"> </i>
                        </Button>
                    </div>
                );
            },
        },
    ];

    const handleAddClick = () => {
        setShowModal(true);
    };

    const onModalClose = () => {
        setShowModal(false);
    };

    const onEditClose = () => {
        setShowEditModal(false);
    };

    const handleDeleteModalClose = () => {
        setDeleteModal(false);
    };

    const handlerOnDeleteClick = () => {
        const params = {
            enroll: enrollId,
            enrollDtlProgram: deleteId,
        };

        dispatch(setLoading(true));
        fetchRequest(getDeleteProgram, "POST", params, false)
            .then((response) => {
                if (response.success) {
                    const { data } = response;
                    setSchoolPrograms(data?.program || [])
                    setDeleteModal(false);
                    message(data.message, true);
                } else {
                    message(response.data.message, false);
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t("errorMessage.title"));
            });
    };

    return (
        <>
            <Card.Body>
                <button className="btn btn-primary shadow" onClick={handleAddClick}>
                    Мэргэжил нэмэх
                </button>
                {showModal && <AddModal onClose={onModalClose} enrollId={enrollId} setSelectedSchoolProgram={(program) => setSchoolPrograms(program)}/>}
                {showEditModal ? (
                    <EditModal
                        id={selectedId}
                        onClose={onEditClose}
                        enrollId={enrollId}
                        setSelectedSchoolPrograms={(program) => setSchoolPrograms(program)}
                    />
                ) : null}

                <Modal
                    show={deleteModal}
                    onHide={handleDeleteModalClose}
                    size="sm"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {t("warning.delete")}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            {t("warning.delete_confirmation")}
                            <br />
                            <br />
                            {t("warning.delete_confirmation_description")}
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="btn btn-link bolder"
                            onClick={handleDeleteModalClose}
                        >
                            {t("common.cancel")}
                        </Button>
                        <Button variant="success btn-shadow" onClick={handlerOnDeleteClick}>
                            {t("warning.delete")}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <DTable
                    config={config}
                    data={schoolPrograms}
                    columns={columns}
                    selectMode={"radio"}
                />
            </Card.Body>
        </>
    );
};

export default Profession;
