import "./enroll.scss";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import message from "../../../modules/message";
import Forms from "../../../modules/Form/Forms";
import { Card } from "react-bootstrap";
import { setLoading } from "../../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect, useRef } from "react";
import { fetchRequest } from "../../../../utils/fetchRequest";
import {
    getAddSetting,
    getEditSetting,
} from "../../../../utils/fetchRequest/Urls";

const AddEntry = () => {
    const form = useRef();
    const history = useHistory();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [seasons, setSeasons] = useState([]);
    const [endDate, setEndDate] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [schools, setSchools] = useState([])
    const [educationLevels, setEducationLevels] = useState([]);
    const [childSeasons, setChildSeasons] = useState([]);
    const schoolId = useSelector((state) => state?.selectedSchool?.id || null);
    const [selectedSchoolId, setSelectedSchoolId] = useState(null)
    const [educationLevelId, setEducationLevelId] = useState(null)
    const [seasonId, setSeasonId] = useState(null)
    const [childSeasonId, setChildSeasonId] = useState(null)
    const [enrollName, setEnrollName] = useState('')

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        dispatch(setLoading(true));
        const params = {
            school: schoolId,
        };

        fetchRequest(getAddSetting, "GET", params)
            .then((res) => {
                if (res.success) {
                    const { data } = res;
                    if (data?.educationLevels) {
                        const educationLevelOptions = [];
                        data?.educationLevels.forEach((level) => {
                            educationLevelOptions.push({ value: level.id, text: level.name });
                        });
                        setEducationLevels(educationLevelOptions);
                    }
                    if (data?.seasons) {
                        const seasonsOptions = [];
                        data?.seasons.forEach((season) => {
                            seasonsOptions.push({ value: season.id, text: season.name });
                        });
                        setSeasons(seasonsOptions);
                    }
                    setSchools(data?.schools || [])
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
            });
    };

    useEffect(() => {
        form?.current?.updateFields && form.current.updateFields(forms);
    }, [schools, educationLevels, seasons, childSeasons, selectedSchoolId, educationLevelId, seasonId, childSeasonId, enrollName]);

    const onDateRangeChange = (dates) => {
        if (dates && dates.length > 0) {
            if (dates[0].startDate) {
                setStartDate(dates[0].startDate);
            }
            if (dates[0].endDate) {
                setEndDate(dates[0].endDate);
            }
        }
    };

    const handleSave = () => {
        const [formValid, formValue] = form.current.validate();
        if (formValid) {
            const params = {
                school: selectedSchoolId,
                educationLevel: educationLevelId,
                season: seasonId,
                childSeason: childSeasonId,
                name: enrollName,
                startDate: startDate,
                endDate: endDate,
            };

            dispatch(setLoading(true));
            fetchRequest(getAddSetting, "POST", params)
                .then((response) => {
                    if (response.success) {
                        message(response.data.message, true);
                        history.push({
                            pathname: "/enroll/setting",
                        });
                    } else {
                        message(response.data.message, false);
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                });
        } else {
        }
    };

    const onSchoolChange = (id) => {
        if (id) {
            setSelectedSchoolId(id);
        }
    };

    const onEducationChange = (value) => {
        if(value){
            setEducationLevelId(value)
        }
    };

    const onSeasonChange = (value) => {
        if(value){
            setChildSeasonId(null)
            dispatch(setLoading(true));
            const params = {
                school: schoolId,
                season: value
            };

            fetchRequest(getAddSetting, "GET", params)
                .then((res) => {
                    if (res.success) {
                        const { data } = res;
                        setChildSeasons(data?.childSeasons || [])
                        setSeasonId(value)
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                });
        }
    };

    const onChildSeasonChange = (value) => {
        if(value){
            setChildSeasonId(value)
        }
    };

    const onNameChange = (e) => {
        if(e){
            setEnrollName(e.target.value)
        }
    };

    const forms = [
        {
            key: "school",
            required: true,
            label: "Бүрэлдэхүүн сургууль",
            value: selectedSchoolId || null,
            type: "dropdown",
            multiple: false,
            inputWidth: 300,
            clearable: true,
            options: schools,
            onChange: onSchoolChange,
        },
        {
            key: "educationLevel",
            required: true,
            type: "dropdown",
            multiple: false,
            clearable: true,
            inputWidth: 300,
            label: "Боловсролын түвшин",
            options: educationLevels,
            value: educationLevelId,
            onChange: onEducationChange,
        },
        {
            key: "season",
            required: true,
            type: "dropdown",
            multiple: false,
            clearable: true,
            inputWidth: 300,
            options: seasons,
            label: "Хичээлийн жил",
            value: seasonId,
            onChange: onSeasonChange,
        },
        {
            key: "childSeason",
            required: true,
            type: "dropdown",
            multiple: false,
            clearable: true,
            inputWidth: 300,
            options: childSeasons,
            label: "Улирал",
            value: childSeasonId,
            onChange: onChildSeasonChange,
        },
        {
            key: "name",
            required: true,
            type: "text",
            multiple: false,
            clearable: true,
            inputWidth: 300,
            label: "Элсэлтийн төрөл",
            value: enrollName,
            onChange: onNameChange,
        },
        {
            key: "dateRange",
            inputWidth: 300,
            value: "",
            type: "dateTimeRange",
            label: "Элсэлтийн хугацаа",
            lastPlaceHolder: t("common.endDate"),
            firstPlaceHolder: t("common.startDate"),
            selectedStartDate: startDate,
            selectedEndDate: endDate,
            onChange: onDateRangeChange,
        },
    ];

    const onClose = () => {
        history.push({
            pathname: '/enroll/setting',
        });
    };

    return (
        <Card>
            <Card.Body>
                <div className="row mb-4">
                    <div className='col-12 d-flex justify-content-center'>
                        <Forms ref={form} fields={forms} />
                    </div>
                </div>
                <div className="row">
                    <div className="mx-auto mt-4">
                    <button onClick={onClose} className="btn btn-link bolder">
                        {t("common.back")}
                    </button>
                        <button
                            type="button"
                            onClick={handleSave}
                            className={`btn btn-success`}
                        >
                            {t("common.save")}
                        </button>
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
};

export default AddEntry;
