import React, {useEffect, useRef, useState} from 'react';
import {Button, Card, Modal} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import Select from "../../../modules/Form/Select";
import {setLoading} from "../../../../redux/action";
import {fetchRequest} from "../../../../utils/fetchRequest";
import {AddSubjectStudentSelection} from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import {FieldFeedbackLabel} from "../../../../_metronic/_partials/controls";

const CanSelect = ({
    selectionData,
    configId,
    timetableData,
    days,
    times,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const languages = useSelector(state => state?.languages?.languages || []).filter(lang => lang.code !== 'mn');
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [subjectData, setSubjectData] = useState(subjects);
    const [totalCredit, setTotalCredit] = useState(null);
    const [totalSubjectCount, setTotalSubjectCount] = useState(null);
    const [subjects, setSubjects] = useState(null);
    const [timetables, setTimetables] = useState(timetableData);
    const [timetableList, setTimetableList] = useState();

    useEffect(() => {
        let subjects = selectionData?.subjects;
        let dataStructure = [];

        setTotalCredit(selectionData?.totalCredit);
        setTotalSubjectCount(selectionData?.totalSubjectCount);
        setSubjects(subjects);

        let timetableListData = [];
        if(subjects && subjects.length > 0){
            for(let i = 0; i < subjects.length; i++){
                dataStructure.push({
                    'dtlId': subjects[i].id,
                    'subjectId': subjects[i].subjectId,
                    'code': subjects[i].code,
                    'name': subjects[i].name,
                    'credit': subjects[i].credit,
                    'pTimes': []
                });

                let pTime = subjects[i].pTimes;
                if(pTime && pTime.length > 0){
                    for(let p = 0; p < pTime.length; p++){
                        let timetables = pTime[p].timetables;
                        let isPotok = false;
                        let isMain = false;
                        let isForce = false;

                        if(timetables && timetables.length > 0){
                            if(pTime.length == 1 && timetables.length == 1){
                                isMain = true;
                            }
                            for (let t = 0; t < timetables.length; t++){
                                timetableListData.push(timetables[t])
                                if(timetables[t].isPotok){
                                    isPotok = true;
                                }

                                isMain = timetables[t].isMain;

                                let relationNames = '';
                                if(timetables[t].relations && timetables[t].relations.length > 0){
                                    for(let r = 0; r < timetables[t].relations.length; r++){
                                        isMain = true;
                                        let relation = timetables[t].relations[r];
                                        relation['value'] = relation.id;
                                        relation['text'] = ((relation.lastName && relation.lastName).trim().charAt(0) + '.') + relation.firstName
                                            + ' - ' + relation.day + ' '+ relation.timeTemplateName + ' (' + relation.startTime + '-' + relation.endTime + ')';
                                        // relationNames = relationNames + ' - ' + ((relation.lastName && relation.lastName).trim().charAt(0) + '.') + relation.firstName
                                        // + ' - ' + relation.day + ' (' + relation.startTime + '-' + relation.endTime + ')';
                                    }
                                }

                                if(!timetables[t].isPotok){
                                    let existingTimetables = timetables.filter(timetableObj => timetableObj.groupId == timetables[t].groupId)
                                    if(existingTimetables && existingTimetables.length > 1){
                                        let existingTimetableIds = [];
                                        let existingTimetableNames = [];
                                        for(let y = 0; y < existingTimetables.length; y++){
                                            let existing = existingTimetableIds.find(existingTimetableId => (existingTimetableId == existingTimetables[y].id))

                                            if(!existing){
                                                existingTimetableIds.push(existingTimetables[y].id) 

                                                existingTimetableNames = existingTimetableNames + ' ' + ((existingTimetables[y].lastName && existingTimetables[y].lastName).trim().charAt(0) + '.') + existingTimetables[y].firstName
                                                    + ' - ' + existingTimetables[y].day +' '+ existingTimetables[y].timeTemplateName +' (' + existingTimetables[y].startTime + '-' + existingTimetables[y].endTime + ')';    
                                            }
                                        }

                                        timetables[t]['value'] = existingTimetableIds;
                                        timetables[t]['text'] = existingTimetableNames;
                                    } else {
                                        let existing = timetables.find(timetable => (timetable.value == timetables[t].id))

                                        if(!existing){
                                            timetables[t]['value'] = timetables[t].id;
                                            timetables[t]['text'] = ((timetables[t].lastName && timetables[t].lastName).trim().charAt(0) + '.') + timetables[t].firstName
                                                + ' - ' + timetables[t].day +' '+ timetables[t].timeTemplateName +' (' + timetables[t].startTime + '-' + timetables[t].endTime + ')' + ' ' + (relationNames ? relationNames : '');
                                        }    
                                    }
                                } else {
                                    let relationNames = '';
                                    if(timetables[t].isGroup){
                                        if(timetables[t].relations && timetables[t].relations.length > 0){
                                            for(let r = 0; r < timetables[t].relations.length; r++){
                                                let relation = timetables[t].relations[r];
                                                relationNames = relationNames + ' - ' + ((relation.lastName && relation.lastName).trim().charAt(0) + '.') + relation.firstName
                                                + ' - ' + relation.day + ' '+relation.timeTemplateName +' (' + relation.startTime + '-' + relation.endTime + ')';
                                            }
                                        }
                                        
                                        timetables[t]['value'] = timetables[t].id;
                                        timetables[t]['text'] = ((timetables[t].lastName && timetables[t].lastName).trim().charAt(0) + '.') + timetables[t].firstName
                                            + ' - ' + timetables[t].day +' '+ timetables[t].timeTemplateName +' (' + timetables[t].startTime + '-' + timetables[t].endTime + ')' + ' ' + (relationNames ? relationNames : '');
                                    } else {
                                        timetables[t]['value'] = timetables[t].id;
                                        timetables[t]['text'] = ((timetables[t].lastName && timetables[t].lastName).trim().charAt(0) + '.') + timetables[t].firstName
                                            + ' - ' + timetables[t].day + ' '+timetables[t].timeTemplateName +' (' + timetables[t].startTime + '-' + timetables[t].endTime + ')' + ' ' + (relationNames ? relationNames : '');
                                    }
                                }
                            }
                        }

                        // let relationDisable = false;
                        // if(!isForce){
                        //     if(isPotok){
                        //         if(!isMain){
                        //             relationDisable = true
                        //         }
                        //     }
                        // }

                        let mergedArray = []
                        if(timetables && timetables.length > 0){
                            for(let b = 0; b < timetables.length; b++){
                                let exitingArray = mergedArray.find(mergedA => (mergedA.text == timetables[b]['text']))

                                if(!exitingArray){
                                    mergedArray.push(timetables[b])
                                }
                            }
                        }

                        dataStructure[i]['pTimes'].push({
                            'id': pTime[p].timeId,
                            'timeName': pTime[p].timeName,
                            'timeCode': pTime[p].timeCode,
                            'timetables': mergedArray,
                            'timetable': null,
                            'isPotok': isPotok,
                            'isMain': isMain,
                            // 'isDisable': relationDisable ? true : false,
                            'error': false
                        });
                    }
                }
            }
        }

        if(selectionData.times && selectionData.times.length > 0){
            for(let i = 0; i < selectionData.times.length; i++){
                if(selectionData.times[i].startTime && selectionData.times[i].startTime.date){
                    selectionData.times[i].startTime = selectionData.times[i].startTime.date.substring(11, 16)
                }
                if(selectionData.times[i].endTime && selectionData.times[i].endTime.date){
                    selectionData.times[i].endTime = selectionData.times[i].endTime.date.substring(11, 16)
                }
            }
        }

        setSubjectData(dataStructure);
        setTimetableList(timetableListData);
    }, [subjects]);

    const renderSubject = (subjects, showTable = true) => {
        let renderData = [];

        if(subjects && subjects.length > 0){
            for(let i = 0; i < subjects.length; i++){
                let pTime = subjects[i].pTimes;
                let tdData = [];
                let relationCode = null;
                let relationData = [];

                if(pTime && pTime.length > 0){
                    for(let p = 0; p < pTime.length; p++){
                        if(pTime[p].isPotok && pTime[p].timetable){
                            for(let j = 0; j < pTime[p].timetables.length; j++){
                                if(pTime[p].timetable == pTime[p].timetables[j].id){
                                    if(pTime[p].timetables[j].isMain){
                                        if(pTime[p].timetables[j].relations && pTime[p].timetables[j].relations.length > 0){
                                            relationCode = pTime[p].timetables[j].relations[0].pTimeCode;
                                        }

                                        relationData = pTime[p].timetables[j].relations;
                                    }
                                }
                            }
                        }

                        tdData.push(
                            <tr key={'subject_' + i + '_ptime_' + p}>
                                <td>{p + 1}</td>
                                <td>{pTime[p].timeName}</td>
                                <td>
                                    {
                                        selectionData.approved2
                                        ?
                                            <div>{pTime[p]?.timetables[0]?.text || ''}</div>
                                        :
                                        <>
                                            {
                                                pTime[p].isPotok && pTime[p].timeCode == relationCode && relationData ?
                                                    <Select
                                                        className={pTime[p].error ? 'is-invalid' : ''}
                                                        options={pTime[p].isMain ? pTime[p].timetables : relationData.filter((data) => data.pTimeCode == pTime[p].timeCode)}
                                                        value={pTime[p].timetable}
                                                        onChange={(id, data) => onSelect(subjects[i].subjectId, pTime[p].id, id, pTime[p].timetables, pTime[p].isMain)}
                                                        searchable
                                                        disabled={pTime[p].isDisable}
                                                    />
                                                : 
                                                pTime[p].isPotok
                                                ?
                                                    <>  
                                                        <Select
                                                            className={pTime[p].error ? 'is-invalid' : ''}
                                                            options={pTime[p].isMain ? pTime[p].timetables : relationData.filter((data) => data.pTimeCode == pTime[p].timeCode)}
                                                            value={pTime[p].timetable}
                                                            onChange={(id, data) => onSelect(subjects[i].subjectId, pTime[p].id, id, pTime[p].timetables, pTime[p].isMain)}
                                                            searchable
                                                            disabled={pTime[p].isDisable}
                                                        />
                                                    </>
                                                : 
                                                    <Select
                                                        className={pTime[p].error ? 'is-invalid' : ''}
                                                        options={pTime[p].timetables}
                                                        value={pTime[p].timetable}
                                                        onChange={(id, data) => onSelect(subjects[i].subjectId, pTime[p].id, id, pTime[p].timetables, pTime[p].isMain)}
                                                        searchable
                                                        disabled={pTime[p].isDisable}
                                                    />
                                            }
                                            {
                                                pTime[p].description
                                                    ?
                                                    <div style={{ display: 'block' }} className={'invalid-feedback'}>
                                                        <b>{pTime[p].description}</b>
                                                    </div>
                                                    : null
                                            }
                                            {
                                                pTime[p].error
                                                    ?
                                                    <div style={{ display: 'block' }} className={'invalid-feedback'}>
                                                        {t('errorMessage.selectTeacher')}
                                                    </div>
                                                    : null
                                            }
                                        </>
                                    }
                                </td>
                            </tr>
                        )
                    }
                }

                let isPotok = false;
                if(
                    subjects[i] && 
                    subjects[i].pTimes && 
                    subjects[i].pTimes.length > 0 && 
                    subjects[i].pTimes[0].timetables &&
                    subjects[i].pTimes[0].timetables.length > 0 &&
                    subjects[i].pTimes[0].timetables[0].isPotok
                ){
                    isPotok = true;
                }

                renderData.push(
                    <div
                        key={'subject_' + i}
                    >
                        <p className='strong-blue'><b>{subjects[i].code + ' ' + subjects[i].name + ' - ' + subjects[i].credit + ' ' + (t('subject.credit')).toLowerCase() + ' ' + (isPotok ? '(Поток хичээл)' : '')} </b></p>
                        {
                            showTable &&
                            <table className='table table-bordered'>
                                <thead>
                                <tr>
                                    <th style={{width: 40}}>№</th>
                                    <th style={{width: 180}}>{t('subject.title')}</th>
                                    <th>{t('subject.teacher') + ' - ' + t('common.weekdayWithtime')}</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {tdData}
                                </tbody>
                            </table>
                        }
                    </div>
                );
            }
        }

        return renderData;
    };

    const getCellTeachers = (day, period) => {
        let timetableObj = [];
        timetables.map(data => {
            if(data){
                if(data.timeId === period){
                    if(data.timetables && data.timetables.length > 0){
                        let timetableDatas = data.timetables;

                        for (let i = 0; i < timetableDatas.length; i++){
                            if(timetableDatas[i].dayId === day){
                                if(timetableDatas[i].dayTimetables && timetableDatas[i].dayTimetables.length > 0){
                                    let dayDatas = timetableDatas[i].dayTimetables;
                                    for (let t = 0; t < dayDatas.length; t++){

                                        let timetableList = dayDatas[t].timetables;

                                        if(timetableList && timetableList.length > 0){
                                            for(let j = 0; j < timetableList.length; j++){
                                                if(timetableList[j].pTimeCode.toLowerCase() === 'laboratory'){
                                                    timetableObj.push(
                                                        <div
                                                            key={'timetable_' + i + '_day_' + t + '_list_' + j}
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                borderRadius: 6,
                                                                backgroundColor: 'rgba(245, 175, 4, 0.5)',
                                                                marginBottom: 5
                                                            }}
                                                        >
                                                            <span>{timetableList[j].subjectCode}</span>
                                                            <span>{timetableList[j].firstName}</span>
                                                            <span>{timetableList[j].pTimeName}</span>
                                                            <span>{timetableList[j].room}</span>
                                                            <span>{timetableList[j].typeName}</span>
                                                        </div>
                                                    )
                                                } else if (timetableList[j].pTimeCode.toLowerCase() === 'seminar') {
                                                    timetableObj.push(
                                                        <div
                                                            key={'timetable_' + i + '_day_' + t + '_list_' + j}
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                borderRadius: 6,
                                                                backgroundColor: 'rgba(54, 163, 247, 0.5)',
                                                                marginBottom: 5
                                                            }}
                                                        >
                                                            <span>{timetableList[j].subjectCode}</span>
                                                            <span>{timetableList[j].firstName}</span>
                                                            <span>{timetableList[j].pTimeName}</span>
                                                            <span>{timetableList[j].room}</span>
                                                            <span>{timetableList[j].typeName}</span>
                                                        </div>
                                                    )
                                                } else {
                                                    timetableObj.push(
                                                        <div
                                                            key={'timetable_' + i + '_day_' + t + '_list_' + j}
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                borderRadius: 6,
                                                                backgroundColor: '#d8d8d8',
                                                                marginBottom: 5
                                                            }}
                                                        >
                                                            <span>{timetableList[j].subjectCode}</span>
                                                            <span>{timetableList[j].firstName}</span>
                                                            <span>{timetableList[j].pTimeName}</span>
                                                            <span>{timetableList[j].room}</span>
                                                            <span>{timetableList[j].typeName}</span>
                                                        </div>
                                                    )
                                                }
                                            }
                                        }
                                        
                                    }
                                }

                                
                            }
                        }
                    }
                }
            }
        })
        return timetableObj
    };

    const onSelect = (subjectId, timeId, id, data, isMainChange = false) => {
        const cloneData = [...subjectData];
        const cloneTimetable = [...timetables];

        if(cloneData && cloneData.length > 0){
            for(let i = 0; i < cloneData.length; i++){
                if(subjectId == cloneData[i].subjectId){
                    let pTime = cloneData[i].pTimes;
                    if(pTime && pTime.length > 0){
                        for (let p = 0; p < pTime.length; p++){
                            if(isMainChange && !pTime[p].isMain){
                                pTime[p].timetable = null;
                            }
                            if(timeId == pTime[p].id){
                                let description = '';
                                if(pTime[p].timetables && pTime[p].timetables.length > 0){
                                    description = pTime[p].timetables[0].description
                                }
                                pTime[p].timetable = id;
                                pTime[p].description = description;
                                pTime[p].error = false;
                            }
                        }
                    }
                }
            }
        }

        let selectedIds = [];
        if(cloneData && cloneData.length > 0){
            for(let i = 0; i < cloneData.length; i++){
                let pTime = cloneData[i].pTimes;
                if(pTime && pTime.length > 0){
                    for (let p = 0; p < pTime.length; p++){
                        if(pTime[p].timetable){
                            if(pTime[p].timetable && pTime[p].timetable.length > 0){
                                for(let j = 0; j < pTime[p].timetable.length; j++){
                                    selectedIds.push(pTime[p].timetable[j])
                                }
                            } else {
                                selectedIds.push(pTime[p].timetable)
                            }
                        }
                    }
                }
            }
        }

        if(cloneTimetable && cloneTimetable.length > 0){
            for(let t = 0; t < cloneTimetable.length; t++){
                if(cloneTimetable[t].timetables && cloneTimetable[t].timetables.length > 0){
                    let timetableDays = cloneTimetable[t].timetables;
                    
                    for(let s = 0; s < timetableDays.length; s++){
                        timetableDays[s].dayTimetables = [];
                    }
                }
            }
        }

        if(timetableList && timetableList.length > 0){
            for(let i = 0; i < timetableList.length; i++){
                if(selectedIds && selectedIds.length > 0){
                    for(let t = 0; t < selectedIds.length; t++){
                        if(selectedIds[t] == timetableList[i].id){
                            if(cloneTimetable && cloneTimetable.length > 0){
                                for(let t = 0; t < cloneTimetable.length; t++){
                                    if(cloneTimetable[t].timetables && cloneTimetable[t].timetables.length > 0){
                                        let timetableDays = cloneTimetable[t].timetables;
                                        
                                        for(let s = 0; s < timetableDays.length; s++){
                                            if(timetableList[i].dayId == timetableDays[s].dayId && cloneTimetable[t].timeId == timetableList[i].timeTemplateId){
                                                let dayTimetableList = [];        
                                                let list = [];

                                                list.push({
                                                    ...timetableList[i]
                                                })

                                                dayTimetableList.push({
                                                    dayId: timetableList[i].dayId,
                                                    timetables: list
                                                });

                                                if(timetableDays[s].dayTimetables && timetableDays[s].dayTimetables.length > 0){
                                                    if(timetableDays[s].dayTimetables[0].timetables && timetableDays[s].dayTimetables[0].timetables.length > 0){ 
                                                        timetableDays[s].dayTimetables[0].timetables.push(timetableList[i])
                                                    }
                                                } else {
                                                    timetableDays[s].dayTimetables = dayTimetableList;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        setTimetables(cloneTimetable);
        setSubjectData(cloneData)
    };

    const onPublishClick = () => {
        const cloneData = [...subjectData];
        let dtls = [];
        let error = false;
        if(cloneData && cloneData.length > 0){
            for(let i = 0; i < cloneData.length; i++){
                dtls.push({
                    'subject': cloneData[i].subjectId,
                    'pTimes': []
                });

                let pTime = cloneData[i].pTimes;
                if(pTime && pTime.length > 0){
                    for(let p = 0; p < pTime.length; p++){
                        if(!pTime[p].timetable){
                            error = true;
                            pTime[p].error = true
                        } else {
                            dtls[i]['pTimes'].push({
                                'pTime': pTime[p].id,
                                'timetable': pTime[p].timetable,
                            });
                            pTime[p].error = false;
                        }
                    }
                }
            }
        }

        setSubjectData(cloneData);

        if(dtls && !error){
            const params = {
                school: schoolId,
                config: configId,
                selection: 2,
                dtls: JSON.stringify(dtls)
            };

            dispatch(setLoading(true));
            fetchRequest(AddSubjectStudentSelection, 'POST', params)
                .then(res => {
                    const { data } = res;
                    if (res.success) {
                        message(data?.message, true);
                        window.location.reload();
                    } else {
                        message(data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                });
        } else {
            message(t('errorMessage.selectTeacher'))
        }
    };

    return (
        <div className='col-12'>
            <Card>
                <Card.Body>
                    {
                        subjectData
                        ?
                            <>
                                {
                                    !selectionData.approved2 && selectionData.selection2Started &&
                                    <div className="col-12 pl-0 mb-5">
                                        <Button variant="publish btn-shadow fs-11" onClick={onPublishClick}>{selectionData.seasonName + ' ' + t('action.publish').toLowerCase()}</Button>
                                    </div>
                                }
                                <div>
                                    <Card>
                                        <Card.Body>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <h6 className='dark-blue text-uppercase'>{t('subject.selected')}</h6>
                                                </div>
                                                <div className='col-12 mb-4'>
                                                    <table className='ml-12'>
                                                        <tbody>
                                                        {
                                                            selectionData.approved2 &&
                                                            <tr>
                                                                <td className='text-right'>{t('subject.season')}:</td>
                                                                <td className='pl-4 strong-blue font-w-bold'>{selectionData.seasonName || ''}</td>
                                                            </tr>
                                                        }
                                                        <tr>
                                                            <td className='text-right'>{t('total.subject')}:</td>
                                                            <td className='pl-4 strong-blue font-w-bold'>{totalSubjectCount ? totalSubjectCount : 0}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='text-right'>{t('total.credit')}:</td>
                                                            <td className='pl-4 strong-blue font-w-bold'>{totalCredit ? totalCredit : 0}</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className='col-12'>
                                                    {
                                                        subjectData && subjectData.length > 0
                                                            ?
                                                            renderSubject(subjectData, selectionData.selection2Started)
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </>
                        :
                            <p>{t('selection.selectionTwoCanSelectText')}</p>
                    }
                    {
                        days && days.length > 0 && times && times.length > 0 && selectionData.selection2Started &&
                            <Card className='mt-5'>
                                <Card.Body>
                                    <table className='table table-bordered'>
                                        <thead>
                                        <tr>
                                            <th>{t('subject.time')}</th>
                                            {
                                                days.map(day => {
                                                    return (
                                                        <th key={day.id}>
                                                            {day.day}
                                                        </th>
                                                    )
                                                })
                                            }
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            times.map(period => {
                                                const time = `${period.startTime}-${period.endTime}` + ' (' + period.name + ')';
                                                return (
                                                    <tr key={'teacher_tr_index_' + period.id}>
                                                        <td>{time}</td>
                                                        {
                                                            days.map(day => {
                                                                return (
                                                                    <td key={`${day.id}-${period.id}`}>
                                                                        {getCellTeachers(day.id, period.id)}
                                                                    </td>
                                                                )
                                                            })
                                                        }
                                                    </tr>
                                                )
                                            })
                                        }
                                        </tbody>
                                    </table>
                                </Card.Body>
                            </Card>
                    }
                </Card.Body>
            </Card>
        </div>
    );
};

export default CanSelect;