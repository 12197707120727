import React, {useState, useEffect, useRef} from 'react';
import {Card, Button, ButtonToolbar, Modal, Toast} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {Link, useHistory, useLocation} from "react-router-dom";
import Forms from "../../../modules/Form/Forms";
import {setLoading} from "../../../../redux/action";
import {fetchRequest} from "../../../../utils/fetchRequest";
import {subjectAdd} from "../../../../utils/fetchRequest/Urls";
import qs from "qs";
import {useDispatch, useSelector} from "react-redux";
import message from "../../../modules/message";

const SubjectNew = ({}) => {
    const { t } = useTranslation();
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();
    const selectedSchool = useSelector(state => state?.selectedSchool || []);
    const languages = useSelector(state => state?.languages?.languages || []);

    const form = useRef();
    const translateForm = useRef();

    const [ helperModal, setHelperModal ] = useState(false);
    const [departments, setDepartments] = useState([]);
    const [subjectTypes, setSubjectTypes] = useState([]);
    const [selectedDepartmentId, setSelectedDepartmentId] = useState(location.state.selectedTreeId);
    const [selectedTypeId, setSelectedTypeId] = useState(null);
    const [code, setCode] = useState('');

    useEffect(() => {
        dispatch(setLoading(true));
        let params = {
            school: selectedSchool.id
        };

        fetchRequest(subjectAdd, 'GET', params)
            .then(response => {
                if (response.success) {
                    if(response.data['departments'] && response.data['departments'].length){
                        setDepartments(response.data['departments']/*.map(depart => ({ value: depart.id, text: depart.name }))*/)
                    }
                    if(response.data['subjectTypes'] && response.data['subjectTypes'].length){
                        setSubjectTypes(response.data['subjectTypes'].map(type => ({ value: type.id, text: type.name })))
                    }
                } else {
                    message(response.data.message, false)
                }
                dispatch(setLoading(false));
            });
    }, []);

    useEffect(() => {
        form?.current?.updateFields && form.current.updateFields(forms);
    }, [departments, subjectTypes, selectedDepartmentId, selectedTypeId]);

    const handlerCode = (e) => {
        let value = e.target.value;
        if(value){
            if(/[А-Яа-яЁё]/.test(value)){
                setCode(value)
            }
        }
    };

    const forms = [
        {
            label: t('school.parentDepartment') + '*',
            value: selectedDepartmentId && selectedDepartmentId.length ? selectedDepartmentId[0] : null,
            errorMessage: t('errorMessage.selectRelatedDepartment'),
            required: true,
            type: 'dropdown',
            options: departments,
            multiple: false,
            clearable: true
        },
        {
            label: t('subject.type') + '*',
            value: selectedTypeId,
            errorMessage: t('errorMessage.selectSubjectType'),
            required: true,
            type: 'dropdown',
            options: subjectTypes,
            multiple: false,
            clearable: true
        },
        {
            key: 'code',
            label: t('common.code') + '*',
            value: '',
            errorMessage: t('errorMessage.enterCode'),
            required: true,
            type: 'nonCryllic',
            upperCase: true,
        },
        {
            label: t('subject.name') + '*',
            value: '',
            errorMessage: t('errorMessage.enterSubjectName'),
            required: true,
            type: 'text',
        },
    ];

    const getFormTranslations = () => {
        const array = [];
        for (const language of languages) {
            if(language.code !== 'mn'){
                array.push({
                    label: `${t('common.name')} - ${language.name} (${language.code})`,
                    value: '',
                    required: false,
                    type: 'text',
                    langCode: language.code,
                    key: 'name',
                    inputStyle: language.code == 'tr' ? {fontFamily: 'Urga'} : {}
                });
            }
        }
        return array;
    };

    const handleHelperModal = () => {
        setHelperModal(true)
    };

    const handleHelperModalClose = () => {
        setHelperModal(false)
    };

    const handlerOnSaveClick = () => {
        const [ formValid, formValue ] = form.current.validate();
        const [ translateValid, translateValues ] = translateForm.current.validate();
        if (formValid) {
            const params = {
                school: selectedSchool.id,
                code: formValue?.[2]?.value,
                name: formValue?.[3]?.value,
            };
            if (formValue?.[0]?.value) {
                params.department = formValue[0].value;
            }
            if(formValue?.[1]?.value){
                params.subjectType = formValue[1].value
            }

            let translations = [];
            if(languages && languages.length > 0){
                languages.map(language => {
                    if(language.code !== 'mn'){
                        const shortNameObject = translateValues.find(val => val.key === 'name' && val.langCode === language.code && val.value);

                        if (shortNameObject) {
                            translations.push({
                                langCode: language.code,
                                name: shortNameObject.value
                            })
                        }
                    }
                });

                params.translations = JSON.stringify(translations);
            }
            dispatch(setLoading(true));
            fetchRequest(subjectAdd, 'POST', params)
                .then(res => {
                    if (res.success) {
                        history.push('/school/subject')
                    } else {
                        message(res.data.message, false)
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                })
        }
    };

    return (
        <div className='row'>
            <div className='col-12'>
                <Card>
                    <Card.Body>
                        <div className='col-12 text-right'>
                            <Button className='bg-white border-white d-inline btn-hover-less' onClick={handleHelperModal}>
                                <i className="icon-2x text-danger flaticon-questions-circular-button d-inline"/>
                                <p className='text-danger d-inline'><b>{t('common.helper')}</b></p>
                            </Button>
                        </div>
                        <div className='col-12'>
                            <Forms
                                ref={form}
                                fields={forms}
                            />
                        </div>
                        <div className="separator separator-dashed my-7"/>
                        <div className='col-12'>
                            <Forms
                                ref={translateForm}
                                fields={getFormTranslations()}
                            />
                        </div>
                    </Card.Body>
                    <Card.Footer>
                        <div className='col-12 text-center'>
                            <Link
                                to='/school/subject'
                                className="btn btn-link bolder"
                            >
                                {t('common.back')}
                            </Link>
                            <Button variant="success btn-shadow" onClick={handlerOnSaveClick}>{t('common.save')}</Button>
                        </div>
                    </Card.Footer>
                </Card>
            </div>
            <Modal
                show={helperModal}
                onHide={handleHelperModalClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Modal heading
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Centered Modal</h4>
                    <p>
                        Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
                        dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta
                        ac consectetur ac, vestibulum at eros.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleHelperModalClose}>{t('common.close')}</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
};

export default SubjectNew;