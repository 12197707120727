import { Card } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import DTable from "../../../modules/DataTable/DTable";
import EnrollSearchComponent from "../components/enrollSearchComponent";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import { messageList } from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import moment from "moment";

const EnrollMessageReport = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector((state) => state?.selectedSchool?.id || null);

    const [schools, setSchools] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [data, setData] = useState([]);
    const [totalCount, setTotalCount] = useState(0)

    useEffect(() => {
        const params = {
            school: schoolId,
        };
        getData(params);
    }, []);

    const getData = (params) => {
        dispatch(setLoading(true));
        fetchRequest(messageList, "GET", params)
            .then((res) => {
                if (res.success) {
                    const { data } = res;
                    if (data?.candidates) {
                        setData(data.candidates);
                    }
                    setTotalCount(data?.totalCount)
                    setSchools(data?.subSchools)
                } else {
                    message(res?.data.message || t("errorMessage.title"));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t("errorMessage.title"));
            });
    };

    const columns = [
        {
            dataField: "createdDate.date",
            text: t("common.date"),
            sort: true,
            formatter: (cellContent) => {
                return moment(cellContent).format("YYYY-MM-DD");
            },
        },
        {
            dataField: "phaseName",
            text: "Үе шат",
            sort: true,
        },
        {
            dataField: "candidateNo",
            text: "ID",
            sort: false,
        },
        {
            dataField: "createdUser",
            text: "Илгээгч",
            sort: false,
        },
        {
            dataField: "content",
            text: t("teacher.content"),
            sort: true,
        },
    ];

    // const contextMenus = [
    //     {
    //         key: "edit",
    //         title: t("common.edit"),
    //         icon: <i className="las la-edit dt-cm-item-icon" />,
    //     },
    //     {
    //         key: "delete",
    //         title: t("common.delete"),
    //         icon: <i className="las la-trash-alt dt-cm-item-icon" />,
    //     },
    // ];

    // const handleContextMenuClick = (id, key) => {
    //     if (key === "edit") {
    //         setShowModal(true);
    //     } else if (key === "delete") {
    //         setDeleteModal(true);
    //     }
    // };

    const onModalClose = () => {
        setShowModal(false);
        setDeleteModal(false);
    };

    const onSubmit = (params) => { };
    const onDelete = () => { };

    const search = (params) => {
        params.pageSize = 100;
        params.school = schoolId;
        getData(params);
    };

    const onUserInteraction = (searchParams) => {
        const params = {
            school: schoolId,
            page: searchParams?.page,
            pageSize: searchParams?.pageSize,
            search: searchParams?.search,
            sort: searchParams?.sort,
            orderBy: searchParams?.order
        }
        getData(params)
    };

    return (
        <>
            <div className="mx-auto w-100">
                <EnrollSearchComponent onSearch={search} schools={schools} />
            </div>
            <div className="mx-auto mt-3">
                <Card>
                    <Card.Body>
                        <DTable
                            remote
                            columns={columns}
                            data={data}
                            config={{
                                showPagination: true,
                                excelExport: true,
                                excelFileName: "Мессеж тайлан",
                            }}
                            totalDataSize={totalCount}
                            onInteraction={onUserInteraction}
                        />
                    </Card.Body>
                </Card>
            </div>
        </>
    );
};

export default EnrollMessageReport;
