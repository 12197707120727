import React,{useState,useEffect} from 'react'
import { Row,Col,Card,Tab,Tabs } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch,useSelector } from 'react-redux'
import TreeView from '../../../modules/TreeView'
import { setLoading } from '../../../../redux/action'
import { fetchRequest } from '../../../../utils/fetchRequest'
import { movementMissing } from '../../../../utils/fetchRequest/Urls'
import message from '../../../modules/message'
import { Link } from 'react-router-dom'
import Instruction from '../../../modules/instruction'
import StudentTable from './StudentTable'

const MissingMovement = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [nodeId,setNodeId] = useState(null);
    const [treeData,setTreeData] = useState([]);
    const [movementCauses,setMovementCauses] = useState([]);
    const [tabKey,setTabKey] = useState(0);
    const [treeIndex] = useState('movement_missing_tree_index');
    const handleTreeClick = array => {
        if (array?.length) {
            const [ id ] = array;
            setNodeId(id);
            localStorage.setItem(treeIndex, id);
        }
    };

    const renderTabs = () =>{
        return movementCauses.map(s=>{
            return <Tab key={s.id} eventKey={s.id} title={s.name}/>
        })
    }

    useEffect(() => {
        const params = {
            school: schoolId
        }
        const treeId = localStorage.getItem(treeIndex);
        dispatch(setLoading(true));
        fetchRequest(movementMissing, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    const tempEl = []
                    if (data?.educationLevels?.length) {
                        data?.educationLevels.map(el=>{
                            tempEl.push({
                                title: el.name,
                                key: el.id
                            })
                        })
                    }
                    setTreeData(tempEl);
                    if(tempEl.length){
                        if(treeId && treeId != null){
                            setNodeId(parseInt(treeId))
                        }else{
                            setNodeId(tempEl[0].key)
                        }
                    }
                    setMovementCauses(data?.movementCauses || [])
                    if(data?.movementCauses?.length){
                        setTabKey(data?.movementCauses[0].id)
                    }
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            })
    }, []);

    return (
        <Row>
            <Col md={3}>
                <Card>
                    <Card.Body>
                        <TreeView
                            treeData={treeData}
                            selectedNodes={[nodeId]}
                            onSelect={handleTreeClick}
                        />
                    </Card.Body>
                </Card>
            </Col>
            <Col md={9}>
                <Card>
                    <Card.Header>
                        <Tabs
                            id={'msue-mv-missing'}
                            activeKey={tabKey}
                            className='msue-tab'
                            onSelect={key=>setTabKey(key)}
                        >
                            {renderTabs()}
                        </Tabs>
                    </Card.Header>
                    <Card.Body>
                        <div style={{ display: 'flex' }}>
                            <Link
                                to='/movement/missing/add'
                                className='btn btn-primary mr-3'
                            >
                                {t('common.register')}
                            </Link>
                            <Instruction/>
                        </div>
                        <StudentTable nodeId={nodeId} schoolId={schoolId} tabKey={tabKey}/>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}

export default MissingMovement