import React, { useState, useEffect, useRef } from 'react';
import { Card, Row, Col, Button, Tabs, Tab } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BorderColorTwoTone, DeleteTwoTone, HighlightOff, PlaylistAddCheckCircleOutlined } from '@mui/icons-material';
import FileCopyTwoToneIcon from '@mui/icons-material/FileCopyTwoTone';
import PreviewTwoToneIcon from '@mui/icons-material/PreviewTwoTone';
import ManageHistoryTwoToneIcon from '@mui/icons-material/ManageHistoryTwoTone';
import { useSelector, useDispatch } from 'react-redux';
import ChangeDateModal from './ChangeDateModal';
import { surveyIndex, surveyDelete, surveyChangeDate, surveyUnpublish, surveyDuplicate } from '../../../../../../utils/fetchRequest/Urls';
import { fetchRequest } from '../../../../../../utils/fetchRequest';
import { setLoading } from "../../../../../../redux/action";
import message from "../../../../../modules/message";
import DTable from '../../../../../modules/DataTable/DTable';
import DeleteModal from '../../../../../modules/DeleteModal'

const SurveyListContainer = (props) => {
    const [counts, setCounts] = useState({ PUBLISH: 0, DRAFT: 0 });
    const history = useHistory();
    const { category } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const current = new Date();
    const dateTimeToday =
        current.getFullYear() +
        '-' +
        ('00' + (current.getMonth() + 1)).slice(-2) +
        '-' +
        ('00' + current.getDate()).slice(-2) +
        ' ' +
        current.getHours() +
        ':' +
        ('00' + current.getMinutes()).slice(-2) +
        ':' +
        ('00' + current.getSeconds()).slice(-2);

    const { selectedSchool } = useSelector(state => state || null);
    const [selectedData, setSelectedData] = useState(null);

    const [showDelete, setShowDelete] = useState(false);

    const [tableTotalCount, setTableTotalCount] = useState(0);
    const [sortKey, setSortKey] = useState('');
    const [sortOrder, setSortOrder] = useState('');
    const [tableData, setTableData] = useState([]);
    const [tabKey, setTabKey] = useState('PUBLISH');
    const [surveyStatuses, setSurveyStatuses] = useState([]);

    const [changeDate, setChangeDate] = useState(false);

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [search, setSearch] = useState('');

    const config = {
        excelExport: true,
        printButton: true,
        columnButton: false,
        showPagination: true,
        showFilter: true,
        footer: false,
        excelFileName: t('survey.title') + ' ' + dateTimeToday,
    };

    const contextMenuArray = [
        {
            key: 'report',
            title: t('survey.report'),
            icon: <PreviewTwoToneIcon className="color-info" />,
        },
        {
            key: 'date',
            title: t('survey.changeDate'),
            icon: <ManageHistoryTwoToneIcon className="color-info" />,
        },
        {
            key: 'edit',
            title: t('common.edit'),
            icon: <BorderColorTwoTone className="color-info" />,
        },
        {
            key: 'copy',
            title: t('survey.copy'),
            icon: <FileCopyTwoToneIcon className="color-info" />,
        },
        {
            key: 'delete',
            title: t('common.delete'),
            icon: <DeleteTwoTone className="color-info" />,
        },
        {
            key: 'inactive',
            title: t('action.unPublish'),
            icon: <HighlightOff className="color-info" />,
        },
        {
            key: 'active',
            title: t('action.setActive'),
            icon: <PlaylistAddCheckCircleOutlined className="color-info" />,
        },
    ];

    const column = [
        {
            dataField: 'code',
            text: t('common.code'),
            sort: true,
        },
        {
            dataField: 'name',
            text: t('survey.name'),
            sort: true,
            style: {padding: 3},
            formatter: (cell, row) => {
                return (
                    <button onClick={() => handlerSurveyViewClick(row)} className="btn btn-link underline" style={{ color: '#4037d7' }}>
                        {cell}
                    </button>
                );
            },
        },
        {
            dataField: 'categoryName',
            text: t('survey.category'),
            sort: true,
        },
        {
            dataField: 'startDate',
            text: t('survey.startDate'),
            sort: true
        },
        {
            dataField: 'endDate',
            text: t('survey.endDate'),
            sort: true
        },
        {
            dataField: 'firstName',
            text: t('survey.registered'),
            sort: true,
        },
        {
            dataField: 'createdDate',
            text: t('common.createdDate'),
            sort: true,
        },
        {
            dataField: 'publishDate',
            text: t('survey.publishedDate'),
            sort: true
        },
    ];

    useEffect(() => {
        let params = {
            school: selectedSchool?.id,
            category: category,
            status: tabKey,
            page,
            pageSize,
            search
        };

        fetchSurveyList(params);
    }, [category]);

    const fetchSurveyList = (params) => {
        dispatch(setLoading(true));
        fetchRequest(surveyIndex, 'POST', params)
            .then((res) => {
                
                if (res.success) {
                    const { data } = res;
                    setTableData(data?.list || []);
                    setSurveyStatuses(data?.statuses || [])
                    setTableTotalCount(data.totalCount || 0)
                } else {
                    message(res.data.message || t('errorMessage.title'));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            });
    };

    const onDelete = () => {
        const postData = {
            school: selectedSchool.id,
            survey: selectedData.id,
        };

        dispatch(setLoading(true));
        fetchRequest(surveyDelete, 'POST', postData)
            .then((res) => {
                setShowDelete(false);
                if (res.success) {
                    let params = {
                        school: selectedSchool?.id,
                        category: category,
                        status: tabKey,
                        page,
                        pageSize,
                        search
                    };

                    fetchSurveyList(params);

                    message(res.data.message, res.success);
                } else {
                    message(res.data.message || t('errorMessage.title'));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                setShowDelete(false);
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            })
            .finally(() => {
                setSelectedData(null);
            });
    };

    const actionToDraft = (id) => {
        dispatch(setLoading(true));
        const postData = {
            school: selectedSchool.id,
            survey: id,
        };

        fetchRequest(surveyUnpublish, 'POST', postData)
            .then((res) => {
                if (res.success) {
                    let params = {
                        school: selectedSchool?.id,
                        category: category,
                        status: tabKey,
                        page,
                        pageSize,
                        search
                    };

                    fetchSurveyList(params);
                    message(res.data.message, res.success);
                } else {
                    message(res.data.message || t('errorMessage.title'));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            });
    };

    const actionToChangeDate = (postData) => {
        postData.school = selectedSchool.id;

        dispatch(setLoading(true));
        fetchRequest(surveyChangeDate, 'POST', postData)
            .then((res) => {
                if (res.success) {
                    setChangeDate(false);
                    setSelectedData(null);
                    let params = {
                        school: selectedSchool?.id,
                        category: category,
                        status: tabKey,
                        page,
                        pageSize,
                        search
                    };

                    fetchSurveyList(params);
                    message(res.data.message, res.success);
                } else {
                    message(res.data.message || t('errorMessage.title'));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            });
    };

    const copySurvey = (row) => {
        dispatch(setLoading(true));
        const postData = {
            school: selectedSchool?.id,
            survey: row.id,
        };

        fetchRequest(surveyDuplicate, 'POST', postData)
            .then(async (res) => {
                if (res?.success) {
                    let params = {
                        school: selectedSchool?.id,
                        category: category,
                        status: tabKey,
                        page,
                        pageSize,
                        search
                    };

                    fetchSurveyList(params);
                    message(res?.message);
                } else {
                    message(res?.message || t('errorMessage.title'));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            })
}

    const handleContextMenuClick = (id, key, row) => {
        if (id && key) {
            if (key === 'edit') {
                history.push("/survey/edit", {
                    survey: id
                });
            } else if (key == 'delete') {
                setSelectedData(row);
                setShowDelete(true);
            } else if (key == 'inactive') {
                actionToDraft(id);
            } else if (key == 'active') {
                //
            } else if (key === 'report') {
                history.push("/survey/report", {
                    survey: id
                });
            } else if (key === 'copy') {
                copySurvey(row);
            } else if (key === 'date') {
                setSelectedData(row);
                setChangeDate(true);
            }
        }
    };

    const onInteraction = (obj) => {
        setPage(obj.page);
        setPageSize(obj.pageSize);
        setSearch(obj.search);
        setSortKey(obj.sort);
        setSortOrder(obj.order);

        let params = {
            school: selectedSchool?.id,
            category: category,
            status: tabKey,
            page: obj.page,
            pageSize: obj.pageSize,
            search: obj.search
        };

        fetchSurveyList(params);
    };

    const handlerTabChange = (key) => {
        setTabKey(key)
        let params = {
            school: selectedSchool?.id,
            category: category,
            status: key,
            page,
            pageSize,
            search
        };

        fetchSurveyList(params);
    }

    const handlerSurveyViewClick = (row) => {
        history.push("/survey/view", {
            survey: row.id
        });
    }

    const onModalClose = () => {
        setShowDelete(false);
        setSelectedData(null);
    }

    return (
        <>
            <Row>
                <Col className='pl-0'>
                    <Card className="mb-3 no-border-radius">
                        <Card.Header>
                            <Tabs
                                id={'msue-mv-excused'}
                                activeKey={tabKey}
                                className='msue-tab'
                                onSelect={(key) => handlerTabChange(key)}
                            >
                                {
                                    surveyStatuses.map(status => {
                                        return (
                                            <Tab key={status.id} eventKey={status.code} title={status.name} />
                                        )
                                    })
                                }
                            </Tabs>
                        </Card.Header>
                        <Card.Body className="">
                            <Link to="/survey/create">
                                <Button type="button" variant="primary" size="sm" className="br-8">
                                    <span className="m-0 font-weight-bold d-flex align-items-center">
                                        &nbsp;{t('common.create')}
                                    </span>
                                </Button>
                            </Link>
                            <DTable
                                remote
                                onInteraction={onInteraction}
                                selectMode="radio"
                                config={config}
                                columns={column}
                                data={tableData?.map((td) => ({
                                    ...td,
                                    contextMenuKeys: tabKey === 'DRAFT' ? ['edit', 'copy', 'delete'] : ['report', 'date', 'copy', 'inactive'],
                                }))}
                                individualContextMenus="true"
                                contextMenus={contextMenuArray}
                                totalDataSize={tableTotalCount}
                                onContextMenuItemClick={handleContextMenuClick}
                                excelExportUrl="url"
                                exportExportParams={{
                                    school: selectedSchool?.id,
                                    sortBy: sortKey,
                                    order: sortOrder,
                                }}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {
                changeDate && selectedData &&
                <ChangeDateModal
                    show={changeDate}
                    setShow={setChangeDate}
                    onSubmit={actionToChangeDate}
                    survey={selectedData}
                />
            }
            {
                showDelete && selectedData &&
                (
                    <DeleteModal
                        onClose={onModalClose}
                        onDelete={onDelete}
                        title={t('warning.delete')}>
                        {t('warning.delete_confirmation')}
                        <br />
                        <br />
                        {t('warning.delete_confirmation_description')}
                    </DeleteModal>
                )
            }
        </>
    );
};

export default SurveyListContainer;
