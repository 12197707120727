import React, {forwardRef, useEffect, useRef, useState} from 'react'
import {Button, Card} from 'react-bootstrap'
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import DTable from "../../../../modules/DataTable/DTable";
import {setLoading} from "../../../../../redux/action";
import {fetchRequest} from "../../../../../utils/fetchRequest";
import {schoolClassInit, statementInit} from "../../../../../utils/fetchRequest/Urls";
import message from "../../../../modules/message";
import DatePickerRange from "../../../../modules/Form/DatePickerRange";
import {priceFormat} from "../../../../../utils/utils";

export const IncomeBank = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    const [statements, setStatements] = useState([]);
    const [bankInfo, setBankInfo] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    useEffect(() => {
        const params = {
            school: schoolId
        };
        init(params)
    }, []);

    const init = (params) => {
        dispatch(setLoading(true));
        fetchRequest(statementInit, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    let statements = data.statements;

                    if(statements && statements.length){
                        for(let i = 0; i < statements.length; i++){
                            statements[i]['tranDate'] = statements[i]['tranDate'].date.substring(0, 10);
                            statements[i]['typeName'] = 'typeName';
                            statements[i]['studentCode'] = '_001';
                            statements[i]['studentName'] = 'studentName';
                            statements[i]['amount'] = priceFormat(statements[i]['amount']);
                        }
                    }

                    setBankInfo(data.banks);
                    setStatements(statements);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const handleButtonsClick = (value) => {
        const params = {
            school: schoolId,
            start: startDate,
            end: endDate,
            bank: bankInfo && bankInfo.length > 0 ? bankInfo[0].id : null
        };
        init(params);
    };

    const config = {
        showPagination: true,
        showFilter: true,
        footer: true,
        footerStyle: {backgroundColor: '#ebecf5'}
    };

    const columns = [
        {
            dataField: "tranDate",
            text: t('finance.transactionDate'),
            sort: true,
            footerAlign: 'center',
            footer: '',
            filterPlaceholder : 'test',
        },
        {
            dataField: "amount",
            text: t('finance.incomeAmount'),
            style: {textAlign: 'right'},
            sort: true,
            footerAlign: 'right',
            footer: '',
            footerType: 'sum'
        },
        {
            dataField: "description",
            text: t('finance.description'),
            sort: true,
            footer: ''
        },
        {
            dataField: "typeName",
            text: t('common.type'),
            sort: true,
            footer: ''
        },
        {
            dataField: "studentCode",
            text: t('student.code'),
            sort: true,
            formatter(cell, row, index) {
                return (
                    <div className='msue-dt-clickable-cell' onClick={() => studentCodeClick(cell, row, index)}>
                        {cell}
                    </div>
                )
            },
            footer: ''
        },
        {
            dataField: "studentName",
            text: t('student.name'),
            sort: true,
            footer: ''
        },
    ];

    const contextMenus = [
        {
            key: 'delete',
            title: t('common.delete'),
            icon: <i className='las la-trash-alt dt-cm-item-icon' />,
        },
    ];

    const handleContextMenuClick = (id, key) => {
        if (id && key) {
            if (key === 'delete') {

            }
        }
    };

    const handerRangePicker = (dates) => {
        if(dates && dates.length > 0){
            setStartDate(dates[0].startDate ? dates[0].startDate : null);
            setEndDate(dates[0].endDate ? dates[0].endDate : null);
        }
    };

    const studentCodeClick = (cell, row, index) => {
        const url = `student/view?student=` + row['studentCode']  + '&url=/finance/income/bank';
        window.open(url);
    };

    const onUserInteraction = (object) => {

    };

    return (
        <div className='sm-container'>
            <Card className='mb-4'>
                <Card.Body>
                    <label className="text-lg-right">
                        {t('finance.account') + ': '}
                        <input type="text" disabled className='account ml-2' value={bankInfo && bankInfo.length > 0 ? bankInfo[0].accountNumber : ''}/>
                        <span />
                    </label>
                    <label
                        style={{marginBottom: 0}}
                        className="text-lg-right ml-10">
                        <span className='mr-3'>{t('common.date')}</span>
                        <DatePickerRange
                            onChange={handerRangePicker}
                            firstPlaceHolder={t('common.startDate')}
                            lastPlaceHolder={t('common.endDate')}
                        />
                    </label>
                    <Button
                        className={"btn btn-warning-custom active btn-shadow ml-3"}
                        onClick={() => handleButtonsClick('paid')}
                    >
                        <b>{t('common.see').toUpperCase()}</b>
                    </Button>
                </Card.Body>
            </Card>
            <Card>
                <Card.Body>
                    <div className='row'>
                        <div className='col-12'>
                            <DTable
                                remote
                                columns={columns}
                                config={config}
                                data={statements}
                                contextMenus={contextMenus}
                                onContextMenuItemClick={handleContextMenuClick}
                                onInteraction={onUserInteraction}
                                selectMode={'radio'}
                                headerFilter
                            />
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </div>
    )
};
