import React, {useEffect, useMemo, useState} from "react";
import {Link} from "react-router-dom";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import {useHtmlClassService} from "../../_core/MetronicLayout";
import {toAbsoluteUrl} from "../../../_helpers";
import { useTranslation } from "react-i18next";
import {useSelector} from "react-redux";

export function Brand() {
  const uiService = useHtmlClassService();
  const { t } = useTranslation();
  const person = useSelector(state => state?.person || []);
  const [personData, setPersonData] = useState([]);

  const layoutProps = useMemo(() => {
    return {
      brandClasses: uiService.getClasses("brand", true),
      asideSelfMinimizeToggle: objectPath.get(
          uiService.config,
          "aside.self.minimize.toggle"
      ),
      headerLogo: uiService.getLogo(),
      headerStickyLogo: uiService.getStickyLogo()
    };
  }, [uiService]);

  useEffect(() => {
    setPersonData(person);
  }, [person]);

  return (
    <>
      {/* begin::Brand */}
      <div
          className={`brand flex-column-auto ${layoutProps.brandClasses}`}
          id="kt_brand"
      >
        {/* begin::Logo */}
        {
          personData && personData.isStudent
            ?
              <Link to="/student" className="brand-logo">
                <img alt="logo" src={layoutProps.headerLogo}/>
                <b className={"brand-name"}>{t('system.title')}</b>
              </Link>
              :
              <Link to="/" className="brand-logo">
                <img alt="logo" src={layoutProps.headerLogo}/>
                <b className={"brand-name"}>{t('system.title')}</b>
              </Link>
        }
        {/* end::Logo */}

        {layoutProps.asideSelfMinimizeToggle && (
          <>
            {/* begin::Toggle */}
            <button className="brand-toggle btn btn-sm px-0" id="kt_aside_toggle">
              <span className="svg-icon svg-icon-xl">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-double-left.svg")}/>
              </span>
            </button>
            {/* end::Toolbar */}
            </>
        )}
      </div>
      {/* end::Brand */}
      </>
  );
}
