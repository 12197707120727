import React,{useEffect,useState, useRef} from 'react'
import {Modal} from "react-bootstrap";
import { fetchRequest } from 'utils/fetchRequest';
import { teacherTimeSheetPrint, teacherTimeSheetView } from 'utils/fetchRequest/Urls';
import { useDispatch,useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setLoading } from "redux/action";
import message from 'app/modules/message';
import DTable from 'app/modules/DataTable/DTable'

const ViewModal = ({
    onClose = () => {},
    details = [],
    logs = []
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    const renderBody = (students, stTemplateDetails) => {
        let tdObj = [];
        if(students && students.length > 0){
            for(let i = 0; i < students.length; i++){
                let studentTotalScore = 0;

                tdObj.push(
                    <tr key={'score_' + i}>
                        <td>{i + 1}</td>
                        <td>{students[i].code}</td>
                        <td>{students[i].lastName}</td>
                        <td>{students[i].firstName}</td>
                        {
                            students[i].details && stTemplateDetails && stTemplateDetails.length > 0
                                ?
                                    stTemplateDetails.map((details, dIndex) => {
                                        studentTotalScore = studentTotalScore + students[i].details[details.id]
                                        return (
                                            <td key={'student_score_' + dIndex} style={{textAlign: 'right'}}>
                                                {students[i].details[details.id]}
                                            </td>
                                        )
                                    })
                                : null
                        }
                        <td className='text-right'>{studentTotalScore || 0}</td>
                        <td>{students[i].scoreTypeName || ''}</td>
                        <td>{students[i].createdUser || ''}</td>
                        <td>{students[i].createdDate || ''}</td>
                    </tr>
                );
            }
        }

        return tdObj;
    };

    let totalScore = 0;

    return (
        <>
            <Modal
                show={true}
                onHide={onClose}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                            Оюутны дүнгийн хуудас хэвлэсэн түүх
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table className='table table-bordered'>
                        <thead>
                            <tr>
                                <th rowSpan={2}>№</th>
                                <th rowSpan={2}>{t('student.code')}</th>
                                <th rowSpan={2}>{t('student.lastName')}</th>
                                <th rowSpan={2}>{t('student.name')}</th>
                                {
                                    details && details.length > 0
                                    ?
                                        details.map((detail, index) => {
                                            return (
                                                <th key={'item_' + index}>{detail.name}</th>
                                            )
                                        })
                                    : null
                                }
                                <th style={{width: 60}}>{t('total.title')}</th>
                                <th rowSpan={2} style={{width: 80}}>{t('assessment.title')}</th>
                                <th rowSpan={2} style={{width: 80}}>{t('common.createdUser')}</th>
                                <th rowSpan={2}>{t('common.date')}</th>
                            </tr>
                            <tr>
                                {
                                    details && details.length > 0
                                        ?
                                        details.map((detail, index) => {
                                            totalScore = totalScore + parseInt(detail.maxScore);
                                            return (
                                                <th className='text-right' key={'score_' + index}>{parseInt(detail.maxScore)}</th>
                                            )
                                        })
                                        : null
                                }
                                <th style={{width: 60}}>{totalScore}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderBody(logs, details)}
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={onClose} className='btn btn-link bolder'>{t('common.back')}</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ViewModal