import React,  { useState, useEffect } from 'react';
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DTable from "../../../modules/DataTable/DTable";
import { fetchRequest } from "../../../../utils/fetchRequest";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../../redux/action";
import {
    regulationIndex,
    regulationCreate,
    regulationEdit,
    regulationDelete,
} from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import RegulationAdd from "./RegulationAdd";
import DeleteModal from "../../../modules/DeleteModal";
import TreeView from "../../../modules/TreeView";

const Regulations = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [ showModal, setShowModal ] = useState(false);
    const [ deleteModal, setDeleteModal ] = useState(false);
    const [ regulationTypes, setRegulationTypes ] = useState([]);
    const [ regulationId, setRegulationId ] = useState([]);
    const [ regulations, setRegulations ] = useState([]);
    const [ typeId, setTypeId ] = useState(null);
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [treeIndex] = useState('manuals_tree_index');

    useEffect(() => {
        let params = {
            school: schoolId,
        };
        let treeKey = localStorage.getItem(treeIndex);

        if(treeKey){
            let splitArray = treeKey.toString().split('_');
            if(splitArray && splitArray.length > 0){
                setTypeId(parseInt(splitArray[0]));
                params.type = splitArray[1];
            }
        }

        init(params)
    }, []);

    const setRegulationList = (regulations) => {
        if(regulations && regulations.length > 0){
            for(let i = 0; i < regulations.length; i++){
                if(regulations[i].regulationDate && regulations[i].regulationDate.date){
                    regulations[i].regulationDate = regulations[i].regulationDate.date.substring(0, 16);
                }
            }
        }
        setRegulations(regulations)
    };

    const init = (params) => {
        dispatch(setLoading(true));
        fetchRequest(regulationIndex, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setRegulationList(data?.regulations || []);
                    setRegulationTypes(data?.regulationTypes || []);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            });
    };

    const config = {
        showPagination: false,
        showFilter: false,
        showAllData: true
    };

    const columns = [
        {
            dataField: "regulationDate",
            text: t('description.forceDate'),
        },
        {
            dataField: "name",
            text: t('description.ruleName'),
        },
        {
            dataField: '',
            text: '',
            style: {padding: 3},
            headerStyle: () => {
                return {
                    width: 65
                }
            },
            formatter(cell) {
                return (
                    <button className='btn btn-outline-success btn-shadow'>{t('common.view')}</button>
                )
            },
        },
    ];

    const contextMenus = [
        {
            key: 'edit',
            title: t('common.edit'),
            icon: <i className='las la-edit dt-cm-item-icon'/>
        },
        {
            key: 'delete',
            title: t('common.delete'),
            icon: <i className='las la-trash-alt dt-cm-item-icon' />,
        },
    ];

    const handleContextMenuClick = (id, key) => {
        if (id && key) {
            setRegulationId(id);
            if (key === 'edit') {
                setShowModal(true);
            } else if (key === 'delete'){
                setDeleteModal(true);
            }
        }
    };

    const onModalClose = () => {
        setShowModal(false);
        setDeleteModal(false);
        setRegulationId(null);
    };

    const onSubmit = params => {
        const url = regulationId ? regulationEdit : regulationCreate;

        const bodyParams = {
            ...params,
            school: schoolId,
        };

        if (regulationId) {
            bodyParams.id = regulationId;
        }

        dispatch(setLoading(true));
        fetchRequest(url, 'POST', bodyParams)
            .then(res => {
                dispatch(setLoading(false));
                if (res.success) {
                    let selectedType = regulationTypes.find(type => (type.key === typeId));
                    if(selectedType){
                        let params = {
                            school: schoolId,
                            type: selectedType.code
                        };

                        init(params)
                    }
                    onModalClose();
                    message(res?.data?.message || t('common.success'), true);
                } else {
                    message(res?.data?.message || t('errorMessage.title'));
                }
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            })
    };

    const onDelete = () => {
        const params = {
            school: schoolId,
            id: regulationId
        };
        dispatch(setLoading(true));
        fetchRequest(regulationDelete, 'POST', params)
            .then(res => {
                dispatch(setLoading(false));
                if (res.success) {
                    let selectedType = regulationTypes.find(type => (type.key === typeId));
                    if(selectedType){
                        let params = {
                            school: schoolId,
                            type: selectedType.code
                        };

                        init(params)
                    }
                    onModalClose();
                    message(res?.data?.message || t('common.success'), true);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const handleTreeNodeClick = array => {
        if (array?.length > 0) {
            const [id] = array;
            if(regulationTypes && regulationTypes.length > 0){
                let selectedType = regulationTypes.find(type => (type.key === id));

                if(selectedType){
                    let params = {
                        school: schoolId,
                        type: selectedType.code
                    };

                    init(params);
                    localStorage.setItem(treeIndex, id + '_' + selectedType.code);
                }
            }
            setTypeId(id);
        }
    };

    return (
        <div className='row'>
            <div className='col-3 pr-0'>
                <div className='card'>
                    <div className='card-body'>
                        <TreeView
                            treeData={regulationTypes}
                            selectedNodes={[typeId]}
                            onSelect={handleTreeNodeClick}
                            defaultExpandAll={true}
                            contextMenuKey='movement-class'
                        />
                    </div>
                </div>
            </div>
            <div className='col-9'>
                <Card>
                    <Card.Body>
                        <button
                            className='btn btn-primary'
                            onClick={() => {
                                setShowModal(true);
                            }}
                        >
                            {t('common.add')}
                        </button>
                        <DTable
                            data={regulations}
                            config={config}
                            columns={columns}
                            contextMenus={contextMenus}
                            onContextMenuItemClick={handleContextMenuClick}
                            selectMode={'radio'}
                        />
                        {
                            showModal && (
                                <RegulationAdd
                                    id={regulationId}
                                    typeId={typeId}
                                    regulationTypes={regulationTypes}
                                    onClose={onModalClose}
                                    onSubmit={onSubmit}
                                />
                            )
                        }
                        {
                            deleteModal && regulationId && (
                                <DeleteModal
                                    onClose={onModalClose}
                                    onDelete={onDelete}
                                    title={t('warning.delete')}
                                >
                                    {t('warning.delete_confirmation')}
                                    <br/>
                                    <br/>
                                    {t('warning.delete_confirmation_description')}
                                </DeleteModal>
                            )
                        }
                    </Card.Body>
                </Card>
            </div>
        </div>

    );
};

export default Regulations;