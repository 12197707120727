import React,{useState,useEffect} from 'react'
import { Modal,Button, Row, Col, CloseButton } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { teacherOnlineExamChangeTime } from '../../../../../../utils/fetchRequest/Urls'
import { fetchRequest } from '../../../../../../utils/fetchRequest'
import message from '../../../../../modules/message'
import { setLoading } from '../../../../../../redux/action'
import DateTimePickerComp from '../../../../../modules/Form/DateTimePicker'
import { useDispatch } from 'react-redux'
import format from 'date-fns/format'
import { isNull } from 'lodash'

const ChangeTime = ({
    onClose = () =>{},
    school,
    exam = {},
    fetchData = () =>{}
}) => {

    const {t} = useTranslation()
    const dispatch = useDispatch()

    const [startDateTime,setStartDateTime] = useState(exam.startDate?.date)
    const [endDateTime,setEndDateTime] = useState(exam.endDate?.date)
    const [duration,setDuration] =useState(exam.duration || 0)

    const onStartTimeChange = (value,date) =>{
        const startTime = date.getTime() / 60000
        const endTime = new Date(endDateTime).getTime() / 60000
        if((endTime - startTime) >= 0){
            setDuration(Math.floor(endTime - startTime))
            setStartDateTime(value)
        }
    }

    const onEndTimeChange = (value,date) =>{
        const startTime = new Date(startDateTime).getTime() / 60000
        const endTime = date.getTime() / 60000
        if((endTime - startTime) >= 0){
            setDuration(Math.ceil(endTime - startTime))
            setEndDateTime(value)
        }
    }

    const onDurationChange = (duration) =>{
        let value = duration
        if(isNull(value) || value < 0){
            value = 0
        }
        if(startDateTime){
            const endTime = new Date(new Date(startDateTime).getTime() + (value*60000))
            setEndDateTime(format(endTime,'y-MM-dd HH:mm'))
        }
        setDuration(value)
    }

    const onSave = () =>{
        const params = {
            school,
            exam: exam.id,
            startDateTime,
            endDateTime,
            duration
        }
        dispatch(setLoading(true));
        fetchRequest(teacherOnlineExamChangeTime, 'POST', params)
            .then(res => {
                if (res.success) {
                    onClose()
                    fetchData()
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                    dispatch(setLoading(false));
                }
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('onlineExam.changeTime')}
                    </div>
                </Modal.Title>
                <CloseButton onClick={onClose}/>
            </Modal.Header>
            <Modal.Body>
                <Row className='align-items-center mb-4'>
                    <Col className='text-right' md={4}>
                        <span>
                            <b>{t('common.startDate')}</b>
                        </span>
                    </Col>
                    <Col md={6}>
                        <DateTimePickerComp
                            value={startDateTime}
                            onChange={onStartTimeChange}
                            className='w-100'
                        />
                    </Col>
                </Row>
                <Row className='align-items-center mb-4'>
                    <Col className='text-right' md={4}>
                        <span>
                            <b>{t('common.endDate')}</b>
                        </span>
                    </Col>
                    <Col md={6}>
                        <DateTimePickerComp
                            value={endDateTime}
                            onChange={onEndTimeChange}
                            className='w-100'
                        />
                    </Col>
                </Row>
                <Row className='align-items-center mb-4'>
                    <Col className='text-right' md={4}>
                        <span>
                            <b>{t('common.duration')}</b>
                        </span>
                    </Col>
                    <Col md={6}>
                        <input
                            type={'number'}
                            value={duration}
                            onChange={e=>onDurationChange(e.target?.value)}
                            style={inputStyle}
                        />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className='btn btn-link bolder'>{t('common.cancel')}</button>
                <Button style={btnStyle} onClick={onSave}>{t('common.save')}</Button>
            </Modal.Footer>
        </Modal>
    )
}

const btnStyle = {
    backgroundColor: '#299f72',
    border: 'none',
    borderRadius: '6px',
    color: '#ffffff',
    boxShadow: '0 2px 10px 0 #299f7233'
}

const inputStyle = {
    border: 'solid 1px #919bc0',
    borderRadius: '4px',
    alignItems: 'center',
    minHeight: '38px',
    width: '100%',
    outline: '0',
    padding: '2px 8px'
}

export default ChangeTime