import React, {useRef, useEffect, useState} from 'react';
import { Button, Modal } from "react-bootstrap";
import Forms from "../../../../modules/Form/Forms";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
    bankInfoCreate,
    getPrograms,
    invoiceTypeCreate,
    movementCauseEdit
} from "../../../../../utils/fetchRequest/Urls";
import { setLoading } from "../../../../../redux/action";
import { fetchRequest } from "../../../../../utils/fetchRequest";
import message from "../../../../modules/message";

const AddType = ({
    onClose,
    onSubmit,
}) => {
    const { t } = useTranslation();
    const formRef = useRef();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [schools, setSchools] = useState([]);
    const [codes, setCodes] = useState([]);
    const [banks, setBanks] = useState([]);
    const [isCreatedSaleType, setIsCreatedSaleType] = useState(true);
    const [selectedSchoolId, setSchoolId] = useState(null);
    const [codeId, setCodeId] = useState(null);
    const [bankId, setBankId] = useState(null);
    const [paymentName, setPaymentName] = useState('');
    const [isConstant, setIsConstant] = useState(true);
    const [amount, setAmount] = useState(0);
    const [ebarimtCode, setEbarimtCode] = useState('');
    const [isEbarimt, setIsEbarimt] = useState(true);
    const [isStudent, setIsStudent] = useState(true);

    const parentSeason = useSelector(state => {
            let schoolList = state?.school?.schoolList;
            if(schoolList && schoolList.length > 0){
                for (let i = 0; i < schoolList.length; i++){
                    if(schoolId == schoolList[i]['id']){
                        return schoolList[i]['parentSeason'];
                    }
                }
            }
        }
    );

    useEffect(() => {
        const params = {
            school: schoolId,
            season: parentSeason,
        };
        dispatch(setLoading(true));
        fetchRequest(invoiceTypeCreate, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    let codes = data.codes;
                    let banks = data.banks;
                    if(data.schools && data.schools.length > 0){
                        setSchools(data.schools.map(school => ({value: school.id, text: school.code + ' - ' + school.shortName})))
                    }
                    if(codes && codes.length > 0){
                        let codesArray = [];
                        for(let i = 0; i < codes.length; i++)
                        {
                            codesArray.push({
                                value: i + 1,
                                text: codes[i].code,
                                saleCreated: codes[i].saleCreated,
                            });
                        }
                        setCodes(codesArray)
                    }
                    if(banks && banks.length > 0){
                        setBanks(banks.map(bank => ({value: bank.id, text: bank.bankName + ' - ' + bank.accountNumber})))
                    }
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }, []);

    const onSchoolChange = (id) => {
        if(id){
            setSchoolId(id)
        }
    };

    const onCodeChange = (id) => {
        if(id){
            if(codes && codes.length > 0){
                for (let i = 0; i < codes.length; i++){
                    if(id == codes[i].value){
                        setIsCreatedSaleType(codes[i].saleCreated)
                    }
                }
            }
            setCodeId(id)
        }
    };

    const onBankChange = (id) => {
        if(id){
            setBankId(id)
        }
    };

    const onInputPaymentNameChange = (event) => {
        if(event){
            setPaymentName(event.target.value)
        }
    };

    const onInputAmountChange = (event) => {
        if(event){
            setAmount(event.target.value)
        }
    };

    const onInputEbarimtCodeChange = (event) => {
        if(event){
            setEbarimtCode(event.target.value)
        }
    };

    const onIsConstantChange = (val) => {
        if(val === false){
            setAmount(0);
        }
        setIsConstant(val)
    };

    const onEbarimtChange = (val) => {
        if(val === false){
            setEbarimtCode('');
        }
        setIsEbarimt(val)
    };

    const onIsStudentChange = (val) => {
        setIsStudent(val)
    };

    useEffect(() => {
        formRef?.current?.updateFields && formRef.current?.updateFields(fields);
    }, [schools, codes, banks, isConstant, isEbarimt, isCreatedSaleType]);

    const fields = [
        {
            label: t('school.title'),
            value: selectedSchoolId || null,
            type: 'dropdown',
            required: true,
            options: schools,
            key: 'selectedSchool',
            clearable: true,
            onChange: onSchoolChange,
        },
        {
            label: t('invoiceType.paymentCode'),
            value: codeId || null,
            type: 'dropdown',
            required: true,
            key: 'code',
            options: codes,
            onChange: onCodeChange,
        },
        {
            label: t('invoiceType.paymentName'),
            value: paymentName || '',
            type: 'text',
            required: true,
            key: 'name',
            onChange: onInputPaymentNameChange,
        },
        {
            label: t('finance.bank'),
            value: bankId || null,
            type: 'dropdown',
            required: true,
            key: 'bank',
            options: banks,
            onChange: onBankChange,
        },
        {
            label: t('invoiceType.isSaleType'),
            value: true,
            type: 'checkbox',
            key: 'sale',
            hidden: isCreatedSaleType
        },
        {
            label: t('invoiceType.isConstant'),
            value: isConstant,
            type: 'checkbox',
            key: 'constantAmount',
            onChange: onIsConstantChange,
        },
        {
            label: t('common.amount'),
            value: amount || 0,
            type: 'numberOnly',
            required: false,
            key: 'amount',
            hidden: isConstant === false,
            onChange: onInputAmountChange,
        },
        {
            label: t('invoiceType.isEbarimt'),
            value: isEbarimt,
            type: 'checkbox',
            key: 'isEbarimt',
            onChange: onEbarimtChange,
        },
        {
            label: t('invoiceType.ebarimtServiceCode'),
            value: ebarimtCode || '',
            type: 'text',
            required: false,
            key: 'ebarimtCode',
            hidden: isEbarimt === false,
            onChange: onInputEbarimtCodeChange,
        },
        {
            label: t('invoiceType.isStudentAccount'),
            value: isStudent,
            type: 'checkbox',
            key: 'isStudent',
            onChange: onIsStudentChange,
        },
    ];

    const onSaveClick = () => {
        const [ isValid, states, values ] = formRef.current.validate();
        if (isValid) {
            if(values.sale) {
                if (isCreatedSaleType) {
                    values.sale = 0
                } else {
                    values.sale = 1
                }
            }
            else {
                values.sale = 0
            }
            if(values.constantAmount){
                values.constantAmount = 1
            } else {
                values.constantAmount = 0
            }
            if(values.isEbarimt){
                values.isEbarimt = 1
            } else {
                values.isEbarimt = 0
            }
            if(values.isStudent){
                values.isStudent = 1
            } else {
                values.isStudent = 0
            }
            if(values.code){
                for (let i = 0; i < codes.length; i++)
                {
                    if(values.code == codes[i]['value']){
                        values.code = codes[i]['text'];
                    }
                }
            }
            const params = {
                ...values,
            };
            onSubmit(params);
        }
    };

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('invoiceType.paymentType').toUpperCase()} {t('common.add').toUpperCase()}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Forms
                    ref={formRef}
                    fields={fields}
                />
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className='btn btn-link bolder'>{t('common.back')}</button>
                <Button variant='success btn-shadow' onClick={onSaveClick}>{t('common.save')}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddType;