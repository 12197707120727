import React,{useState, useEffect, useRef} from 'react'
import { GradeTable } from './GradeTable'
import {Button, Card, Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import Forms from "../../../modules/Form/Forms";
import StudentScoreViewModal from "./StudentScoreViewModal";
import SurveyModal from "./SurveyModal";
import {setLoading} from "../../../../redux/action";
import {fetchRequest} from "../../../../utils/fetchRequest";
import {getStudentSurvey, getStudentSurveyStart, getStudentSurveySubmit, studentGradeScore} from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import DTable from '../../../modules/DataTable/DTable';

export const StudentGradeTable = ({
    studentInfo
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [viewModal, setViewModal] = useState(false);
    const [tableData, setTableData]= useState([]);
    const [surveyData, setSurveyData]= useState();
    const [showSurveyModal, setShowSurveyModal]= useState(false);
    const [selectedRow, setSelectedRow]= useState(false);

    useEffect(() => {
        const params = {
            school: schoolId,
        };

        init(params)
    }, []);

    const init = (params) => {
        dispatch(setLoading(true));
        fetchRequest(studentGradeScore, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setTableData(data.details || []);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const column=[
        {
            dataField: 'subjectCode',
            text: t('subject.code'),
            sort: true,
            footer: '',
        },
        {
            dataField: 'subjectName',
            text: t('subject.name'),
            sort: true,
            footer: 'Нийт',
        },
        {
            dataField: 'credit',
            text: t('subject.credit'),
            sort: true,
            align: 'right',
            footerAlign: 'right',
            footer: columnData => columnData.reduce((acc, item) => acc + item, 0)
        },
        {
            dataField: 'studentGpa',
            text: t('ranking.gpaScore'),
            sort: true,
            align: 'right',
            footerAlign: 'right',
            footerType: 'avgGpa',
            footer: ''
        },
        {
            dataField: 'studentScore',
            text: t('assessment.title'),
            sort: true,
            align: 'right',
            headerAttrs: { 'colSpan': 2 },
            headerStyle: {width: 120},
            footerType: 'avg',
            footerAlign: 'right',
            footer: '',
            // style: {padding: 3},
            formatter: (cell, row, rowIndex) => {
                if(row.isSurvey){
                    return cell
                } else {
                    return <button onClick={() => handlerSurveyClick(row)} className={'btn'} style={{width: '100%', color: 'white', background: row.resultStatusColor ? row.resultStatusColor : '#299f72'}}>{row.resultStatusName ? row.resultStatusName : t('survey.fillOut')}</button>
                }
            }
        },
        {
            dataField: 'scoreType',
            text: '',
            sort: true,
            headerStyle: { display: 'none' },
            footer: '',
        },
        {
            dataField: 'teacherCode',
            text: t('teacher.code'),
            sort: true,
            footer: '',
            key:'',
        },
        {
            dataField: 'teacherName',
            text: t('teacher.name'),
            sort: true,
            footer: '',
            key:'',
        }
    ];

    const config = {
        showPagination: false,
        showFilter: false,
        footer: true,
        footerStyle: {backgroundColor: '#ebecf5'}
    };

    const contentRender = () =>{
        return tableData.map((data, index) =>
            <React.Fragment key={index}>
                <div className='table-title mb-4'>
                    <b className='ml-2'>{data.parentSeasonName + ', ' + data.seasonName}</b>
                </div>
                <div className='table-wrap'>
                    <DTable 
                        config={config}
                        data={data.list}
                        columns={column}
                    />
                </div>
            </React.Fragment>
        )
    };

    const onModalClose = () => {
        setViewModal(false);
    };

    const onSurveyModalClose = () => {
        setShowSurveyModal(false);
    };

    const getGradeScore = (row) => {
        let params = {
            school: schoolId,
            id: row.id
        };
    };

    const handlerSurveyClick = (row) => {
        if(row.surveyId){
            let params = {
                school: schoolId,
                survey: row.surveyId,
                exam: row.id
            }
    
            dispatch(setLoading(true));
            fetchRequest(getStudentSurvey, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res;
                        setSurveyData(data?.surveyData || {});
                        setSelectedRow(row);
                        setShowSurveyModal(true);
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        } else {
            message(t('survey.notFound'))
        }
    }

    const surveySubmit = (questions) => {
        let params = {
            school: schoolId,
            survey: selectedRow.surveyId,
            questions: JSON.stringify(questions),
            exam: selectedRow.id,
        }

        dispatch(setLoading(true));
        fetchRequest(getStudentSurveySubmit, 'POST', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    const params = {
                        school: schoolId,
                    };
            
                    init(params)
                    onSurveyModalClose()
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }

    const onStartSubmit = (data) => {
        let params = {
            school: schoolId,
            survey: selectedRow.surveyId,
            exam: selectedRow.id,
        }

        dispatch(setLoading(true));
        fetchRequest(getStudentSurveyStart, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setSurveyData(data?.surveyData || {});
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }

    return (
        <Card>
            <Card.Body>
                <button className='btn btn-warning-custom mb-4' onClick={getGradeScore}>
                    <b>{t('student.getScoreDescription')}</b>
                </button>
                {contentRender()}
            </Card.Body>
            {
                viewModal
                    ?
                    <StudentScoreViewModal
                        onClose={onModalClose}
                        subjectId={11}
                    />
                    : null
            }
            {
                showSurveyModal
                    ?
                    <SurveyModal
                        show={showSurveyModal}
                        onClose={onSurveyModalClose}
                        onSubmit={surveySubmit} 
                        onStartSubmit={onStartSubmit} 
                        data={surveyData}
                        selectedRow={selectedRow}
                    />
                    : null
            }
        </Card>
    )
};
