import React,{useState,useEffect} from 'react'
import { Card,Row,Col, Button } from 'react-bootstrap'
import Select from "../../../../modules/Form/Select"
import DTable from '../../../../modules/DataTable/DTable'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { teacherOnlineExam } from '../../../../../utils/fetchRequest/Urls'
import { fetchRequest } from '../../../../../utils/fetchRequest'
import message from '../../../../modules/message'
import { setLoading } from '../../../../../redux/action'
import { useSelector,useDispatch } from 'react-redux'
import ChangeTime from './modals/ChangeTime'
import DeleteModal from '../../../../modules/DeleteModal'
import { useHistory } from 'react-router-dom'

const TeacherOnlineExam = () => {

    const {t} = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()

    const school = useSelector(state => state?.selectedSchool?.id || null)

    const [subject,setSubject] = useState(0)
    const [subjectOption,setSubjectOption] = useState([])
    const [season,setSeason] = useState(0)
    const [seasonOption,setSeasonOption] = useState([])
    const [testName,setTestName] = useState('')
    const [status,setStatus] = useState(null)

    const [tableData,setTableData] = useState([])
    const [totalData,setTotalData] = useState(1)

    const [tempData,setTempData] = useState({})
    const [tempId,setTempId] = useState({})

    const [viewChangeTimeModal,setViewChangeTimeModal] = useState(false)
    const [viewDeleteModal,setViewDeleteModal] = useState(false)

    const activeCM = ['report','change','inactive','copy']
    const inactiveCM = ['edit','delete']

    const active = <div className='text-center w-100'><span><i className="icon-1_5x main-green fa fa-circle"/></span></div>
    const inactive = <div className='text-center w-100'><span><i className="icon-1_5x text-dark-50 fa fa-circle"/></span></div>

    const contextMenus = [
        {
            key: 'edit',
            title: t('common.edit'),
            icon: <i className='las la-edit dt-cm-item-icon'/>
        },
        {
            key: 'delete',
            title: t('common.delete'),
            icon: <i className='las la-trash-alt dt-cm-item-icon' />,
        },
        {
            key: 'report',
            title: t('report.view'),
            icon: <i className='flaticon-graphic-1 dt-cm-item-icon' />,
        },
        {
            key: 'change',
            title: t('report.changeDuration'),
            icon: <i className='la la-refresh dt-cm-item-icon' />,
        },
        {
            key: 'inactive',
            title: t('action.setInactive'),
            icon: <i className='la la-eye-slash dt-cm-item-icon' />,
        },
        {
            key: 'copy',
            title: t('common.copy'),
            icon: <i className='flaticon-file-2 dt-cm-item-icon' />,
        },
    ];

    const columns = [
        {
            dataField: 'status',
            text: t('common.status'),
            sort: true,
            formatter(cell,row){
                if(cell){
                    return active
                }else{
                    return inactive
                }
            },
            headerStyle: () => {
                return {
                    width: 70
                }
            }
        },
        {
            dataField: 'name',
            text: t('onlineExam.testName'),
            sort: true,
            formatter(cell,row){
                return <span style={{color:'#5c3cc7'}}>
                    <u>{cell}</u>
                </span>
            }
        },
        {
            dataField: 'season',
            text: t('school.season.title'),
            sort: true,
        },
        {
            dataField: 'startDate',
            text: t('common.startDate'),
            sort: true,
            formatter(cell,row){
                if(cell && typeof cell === 'object'){
                    return cell.date?.substring(0,19)
                }else{
                    return cell
                }
            }
        },
        {
            dataField: 'endDate',
            text: t('common.endDate'),
            sort: true,
            formatter(cell,row){
                if(cell && typeof cell === 'object'){
                    return cell.date?.substring(0,19)
                }else{
                    return cell
                }
            }
        },
        {
            dataField: 'duration',
            text: t('common.duration'),
            sort: true,
        },
        {
            dataField: 'questionNumber',
            text: t('onlineExam.testQuestionNumber'),
            sort: true,
        },
        {
            dataField: 'totalScore',
            text: t('assessment.totalScore'),
            sort: true,
        },
        {
            dataField: 'createdUserName',
            text: t('common.createdUser'),
            sort: true,
        },
        {
            dataField: 'createdDate',
            text: t('common.createdDate'),
            sort: true,
            formatter(cell,row){
                if(cell && typeof cell === 'object'){
                    return cell.date?.substring(0,19)
                }else{
                    return cell
                }
            }
        }
    ]

    const onClear = () =>{
        setSubject(0)
        setSeason(0)
        setStatus(null)
        setTestName('')
    }

    const onSearch = () =>{
        const params = {
            subject,season,status,testName
        }
        fetchData(params)
    }

    const handleContextMenuClick = (id, key, record) => {
        if(id){
            if(key === 'edit'){
                history.push({
                    pathname: '/teacher/online/exam-create',
                    state: {
                        examId: id,
                    }
                });
            } else if (key === 'delete'){
                setViewDeleteModal(true)
                setTempId(id)
            } else if (key === 'report'){
                history.push({
                    pathname: '/teacher/online/exam-view',
                    state: {
                        examId: id,
                    }
                });
            } else if (key === 'change'){
                setViewChangeTimeModal(true)
                setTempData(record)
            } else if (key === 'inactive'){
                const params = {
                    subject,
                    season,
                    status,
                    testName,
                    action: 'inactive',
                    exam: id
                }
                fetchData(params, 'POST')
            } else if (key === 'copy'){
                const params = {
                    subject,
                    season,
                    status,
                    testName,
                    action: 'copy',
                    exam: id
                }
                fetchData(params, 'POST')
            }
        }
    };

    const modalClose = () =>{
        if(viewChangeTimeModal) setViewChangeTimeModal(false)
        if(viewDeleteModal) setViewDeleteModal(false)
    }

    const onDelete = () =>{
        if(tempId){
            const params = {
                subject,
                season,
                status,
                testName,
                action: 'delete',
                exam: tempId
            }
            fetchData(params,'POST');
        }
    }

    const fetchData = (params,method = 'GET') => {
        const bodyParams = {
            ...params,
            school,
        }
        dispatch(setLoading(true));
        fetchRequest(teacherOnlineExam, method, bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res
                    if(method == 'POST'){
                        message(t('common.success'),true);
                    }
                    if(data?.examList?.length){
                        const tempExams = []
                        data.examList.map(exam=>{
                            tempExams.push({
                                ...exam,
                                contextMenuKeys: exam.status ? activeCM : inactiveCM
                            })
                        })
                        setTableData(tempExams)
                    }else{
                        setTableData([])
                    }
                    if(data?.subjects?.length){
                        const subjects = []
                        data.subjects.map(subj=>{
                            subjects.push({
                                'text': subj['name'],
                                'value': subj['id']
                            })
                        });
                        setSubjectOption(subjects);
                    }
                    if(data?.seasons?.length){
                        const seasons = []
                        data.seasons.map(s =>{
                            seasons.push({
                                'text': s['season'] + ' - ' + s['education'],
                                'value': s['id']
                            })
                        });
                        setSeasonOption(seasons)
                    }
                    if(data?.totalSize){
                        setTotalData(data.totalSize)
                    }
                    modalClose()
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    useEffect(()=>{
        fetchData()
    },[])

    return (
        <div className='w-100'>
            <Card className='mb-4'>
                <Card.Body>
                    <Row>
                        <Col md={3}>
                            <div className='w-100'>
                                <label>{t('subject.title')}</label>
                            </div>
                            <Select
                                value={subject}
                                onChange={value=>setSubject(value)}
                                options={subjectOption}
                                searchable
                            />
                        </Col>
                        <Col md={3}>
                            <div className='w-100'>
                                <label>{t('school.season.title')}</label>
                            </div>
                            <Select
                                value={season}
                                onChange={value=>setSeason(value)}
                                options={seasonOption}
                                searchable
                            />
                        </Col>
                        <Col md={3}>
                            <div className='w-100'>
                                <label>{t('onlineExam.testName')}</label>
                            </div>
                            <input
                                type='text'
                                value={testName}
                                onChange={e=>setTestName(e.target?.value || '')}
                                style={inputStyle}
                            />
                        </Col>
                        <Col md={3}>
                            <div className='w-100'>
                                <label>{t('common.status')}</label>
                            </div>
                            <Select
                                value={status}
                                onChange={value=>setStatus(value)}
                                options={[
                                    {
                                        value: '1',
                                        text: t('common.active')
                                    },
                                    {
                                        value: '0',
                                        text: t('common.inactive')
                                    }
                                ]}
                                searchable
                            />
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer>
                    <div className='text-center'>
                        <button className='btn btn-link bolder' onClick={onClear}>
                            {t('common.clear').toUpperCase()}
                        </button>
                        <button className='btn btn-warning m-btn--icon m-btn--icon-only btn-shadow' onClick={onSearch}>
                            <i className="flaticon-search-1" style={{color: '#000'}}></i>
                            <span style={{color: '#000'}}>
                                {t('common.search').toUpperCase()}
                            </span>
                        </button>
                    </div>
                </Card.Footer>
            </Card>
            <Card>
                <Card.Body>
                    <div className='w-100'>
                        <Link
                            to={{
                                pathname: '/teacher/online/exam-create'
                            }}
                            className="btn btn-primary btn-shadow"
                        >
                            {t('common.add').toLocaleUpperCase()}
                        </Link>
                    </div>
                    <DTable
                        data={tableData}
                        columns={columns}
                        config={{}}
                        totalDataSize={totalData}
                        contextMenus={contextMenus}
                        onContextMenuItemClick={handleContextMenuClick}
                        selectMode={'radio'}
                        individualContextMenus
                    />
                </Card.Body>
            </Card>
            {
                viewChangeTimeModal
                ?
                    <ChangeTime
                        onClose={()=>{
                            setViewChangeTimeModal(false)
                            setTempData({})
                        }}
                        exam={tempData}
                        school={school}
                        fetchData={onSearch}
                    />
                : null
            }
            {
                viewDeleteModal
                ?
                    <DeleteModal
                        onClose={()=>{
                            setViewDeleteModal(false)
                            setTempId(null)
                        }}
                        title={t('onlineExam.delete')}
                        children={(
                            <>
                                <div className='w-100'>
                                    <span className='w-100'>{t('warning.delete_confirmation')}</span>
                                </div>
                                <div>
                                    <span className='w-100'>{t('warning.delete_confirmation_description')}</span>
                                </div>
                            </>
                        )}
                        onDelete={onDelete}
                    />
                : null
            }
        </div>
    )
}

const inputStyle = {
    border: 'solid 1px #cccccc',
    borderRadius: '4px',
    alignItems: 'center',
    minHeight: '38px',
    width: '100%',
    outline: '0',
    padding: '2px 8px'
}

export default TeacherOnlineExam