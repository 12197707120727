import React, { useState, useEffect, useRef } from 'react';
import { Button, Card, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DTable from "../../../modules/DataTable/DTable";
import { fetchRequest } from "../../../../utils/fetchRequest";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../../redux/action";
import {
    LmPTimeAdd,
    lmPTimeDelete,
    lmPTimeEdit,
    lmPTimeReorder,
} from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import { getLastOrderNumber } from "../../../../utils/utils";
import Forms from "../../../modules/Form/Forms";
import qs from "qs";

const Time = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const languages = useSelector(state => state?.languages?.languages || []).filter(lang => lang.code !== 'mn');
    const selectedSchool = useSelector(state => state?.selectedSchool || []);
    const parentId = useSelector(state => state?.selectedSchool.parentId || null);
    const [ addModal, setAddModal ] = useState(false);
    const [ editModal, setEditModal ] = useState(false);
    const [ deleteModal, setDeleteModal ] = useState(false);
    const [ pTimes, setPTimes ] = useState([]);
    const [ timeId, setTimeId ] = useState(null);

    const formRef = useRef();
    const translateFormRef = useRef();
    const editForm = useRef();
    const editTranslateForm = useRef();

    useEffect(() => {
        dispatch(setLoading(true));
        let params = {
            school: selectedSchool.id
        };

        fetchRequest(LmPTimeAdd + '?' + qs.stringify(params), 'GET')
            .then(response => {
                if (response.success) {
                    setPTimes(response.data.pTimes);
                } else {
                    message(response?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }, []);

    const config = {
        showPagination: false,
        showFilter: false,
        showAllData: true
    };

    const columns = [
        {
            dataField: "code",
            text: t('common.code'),
        },
        {
            dataField: "name",
            text: t('subject.type'),
            sort: true
        },
        {
            dataField: "ordering",
            text: t('common.ordering'),
            style: {textAlign: 'right'},
            sort: true
        },
    ];

    const contextMenus = [
        {
            key: 'edit',
            title: t('common.edit'),
            icon: <i className='las la-edit dt-cm-item-icon'/>
        },
        {
            key: 'delete',
            title: t('common.delete'),
            icon: <i className='las la-trash-alt dt-cm-item-icon' />,
        },
    ];

    const formFields = [
        {
            label: t('common.code') + '*',
            value: '',
            type: 'nonCryllic',
            upperCase: true,
            required: true,
            key: 'code',
            errorMessage: t('errorMessage.enterCode'),
        },
        {
            label: t('subject.type') + '*',
            value: '',
            type: 'text',
            required: true,
            key: 'name',
            errorMessage: t('errorMessage.selectSubjectType'),
        },
        {
            label: t('school.isOtherSchool'),
            type: 'checkbox',
            value: true,
            hidden: parentId == null ? false : true,
            key: 'isSchool',
        },
    ];

    const getFormTranslations = () => {
        const array = [];
        for (const language of languages) {
            array.push(
                {
                    label: `${t('subject.type')} - ${language.name} (${language.code})`,
                    value: '',
                    required: false,
                    type: 'text',
                    langCode: language.code,
                    key: 'name',
                }
            );
        }
        return array;
    };

    const handleContextMenuClick = (id, key) => {
        setTimeId(id);
        if (key === 'edit') {
            setEditModal(true);
            const params = {
                school: selectedSchool.id,
                pTime: id,
            };
            dispatch(setLoading(true));
            fetchRequest(lmPTimeEdit, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res;
                        if (data?.pTime) {
                            editForm?.current?.updateFields && editForm.current.updateFields([
                                {
                                    label: t('common.code') + '*',
                                    value: data.pTime.code || '',
                                    type: 'nonCryllic',
                                    upperCase: true,
                                    required: true,
                                    key: 'code',
                                    errorMessage: t('errorMessage.enterCode'),
                                },
                                {
                                    label: t('subject.type') + '*',
                                    value: data.pTime.name || '',
                                    type: 'text',
                                    required: true,
                                    key: 'name',
                                    errorMessage: t('errorMessage.selectSubjectType'),
                                },
                            ]);
                            const translations = data.pTime.translations || [];

                            const trFields = languages.map(lang => {

                                const translation = translations.find(tr => tr.langCode === lang.code);
                                const value = translation?.name || '';

                                return {
                                    label: `${t('subject.type')} - ${lang.name}(${lang.code})`,
                                    value,
                                    required: false,
                                    type: 'text',
                                    langCode: lang.code,
                                    key: 'name',
                                }
                            });

                            editTranslateForm.current?.updateFields && editTranslateForm.current.updateFields(trFields);
                        }

                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        } else if (key === 'delete') {
            setDeleteModal(true);
        }
    };

    const handlerAddModal = () => {
        setAddModal(true);
    };

    const handlerAddModalClose = () => {
        setAddModal(false);
    };

    const handlerEditModalClose = () => {
        setEditModal(false);
        setTimeId(null);
    };

    const handlerDeleteModalClose = () => {
        setDeleteModal(false);
        setTimeId(null);
    };

    const onDrop = drop => {
        const formData = new FormData();

        formData.append('school', selectedSchool.id);
        formData.append('item', drop.itemId);
        formData.append('target', drop.targetId);

        dispatch(setLoading(true));
        fetchRequest(lmPTimeReorder, 'POST', formData, false, true)
            .then(res => {
                if (res.success) {
                    setPTimes(res.data?.pTimes || []);
                    message(res?.data?.message || t('common.success'), true);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const handlerOnSaveClick = () => {
        const [ formValid, formValue ] = formRef.current.validate();
        const [ translateValid, translateValues ] = translateFormRef.current.validate();

        if (formValid) {
            dispatch(setLoading(true));
            const ordering = getLastOrderNumber(pTimes) + 1;
            const params = {
                school: selectedSchool.id,
                code: formValue?.[0]?.value,
                name: formValue?.[1]?.value,
                isSchool: formValue?.[2]?.value == true ? 1 : 0,
                ordering: ordering
            };

            let translations = [];
            if(languages && languages.length > 0){
                languages.map(language => {
                    const name = translateValues.find(val => val.key === 'name' && val.langCode === language.code && val.value);

                    if (name) {
                        translations.push({
                            langCode: language.code,
                            name: name.value
                        })
                    }
                });

                params.translations = JSON.stringify(translations);
            }

            fetchRequest(LmPTimeAdd, 'POST', { ...params })
                .then(response => {
                    if (response.success) {
                        message(response?.data?.message || t('common.success'), true);
                        setPTimes(response.data?.pTimes || []);
                        handlerAddModalClose();
                    } else {
                        message(response?.data?.message || t('errorMessage.title'));
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'));
                })
        }
    };

    const handlerOnDeleteClick = () => {
        const params = {
            school: selectedSchool.id,
            pTime: timeId
        };
        dispatch(setLoading(true));
        fetchRequest(lmPTimeDelete, 'POST', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    message(data?.message || t('common.success'), true);
                    setPTimes(data?.pTimes || []);
                    handlerDeleteModalClose();
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const editSave = () => {
        const [ formValid, formValue ] = editForm.current.validate();
        const [ translateValid, translateValues ] = editTranslateForm.current.validate();

        if (formValid) {
            dispatch(setLoading(true));
            const time = pTimes.find(t => t.id === timeId);
            const ordering = time?.ordering || getLastOrderNumber(pTimes) + 1;
            const params = {
                school: selectedSchool.id,
                code: formValue?.[0]?.value,
                name: formValue?.[1]?.value,
                pTime: timeId,
                ordering
            };

            let translations = [];
            if(languages && languages.length > 0){
                languages.map(language => {
                    const name = translateValues.find(val => val.key === 'name' && val.langCode === language.code && val.value);

                    if (name) {
                        translations.push({
                            langCode: language.code,
                            name: name.value
                        })
                    }
                });

                params.translations = JSON.stringify(translations);
            }

            fetchRequest(lmPTimeEdit, 'POST', { ...params })
                .then(response => {
                    if (response.success) {
                        message(response?.data?.message || t('common.success'), true);
                        setPTimes(response.data?.pTimes || []);
                        handlerEditModalClose();
                    } else {
                        message(response?.data?.message || t('errorMessage.title'));
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'));
                })
        }
    };

    return (
        <Card>
            <Card.Body>
                <button
                    className='btn btn-primary'
                    onClick={handlerAddModal}
                >
                    {t('common.add')}
                </button>
                <DTable
                    draggable
                    data={pTimes}
                    config={config}
                    columns={columns}
                    contextMenus={contextMenus}
                    onContextMenuItemClick={handleContextMenuClick}
                    onDrop={onDrop}
                    selectMode={'radio'}
                />
                <Modal
                    show={addModal}
                    onHide={handlerAddModalClose}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    dialogClassName='modal-90w'
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                                {t('subject.type').toUpperCase()}
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='col-12'>
                            <Forms
                                ref={formRef}
                                fields={formFields}
                            />
                        </div>
                        <div className="separator separator-dashed my-7"/>
                        <div className='col-12'>
                            <Forms
                                ref={translateFormRef}
                                fields={getFormTranslations()}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="btn btn-link bolder" onClick={handlerAddModalClose}>{t('common.back')}</Button>
                        <Button variant="success btn-shadow" onClick={handlerOnSaveClick}>{t('common.save')}</Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={editModal}
                    onHide={handlerEditModalClose}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    dialogClassName='modal-90w'
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                                {t('common.edit').toUpperCase()}
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='col-12'>
                            <Forms
                                ref={editForm}
                                fields={formFields}
                            />
                        </div>
                        <div className="separator separator-dashed my-7"/>
                        <div className='col-12'>
                            <Forms
                                ref={editTranslateForm}
                                fields={getFormTranslations()}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="btn btn-link bolder" onClick={handlerEditModalClose}>{t('common.cancel')}</Button>
                        <Button variant="success btn-shadow" onClick={editSave}>{t('common.save')}</Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={deleteModal}
                    onHide={handlerDeleteModalClose}
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                                {t('warning.delete').toUpperCase()}
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            {t('warning.delete_confirmation')}
                            <br/>
                            <br/>
                            {t('warning.delete_confirmation_description')}
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="btn btn-link bolder" onClick={handlerDeleteModalClose}>{t('common.cancel')}</Button>
                        <Button variant="danger btn-shadow" onClick={handlerOnDeleteClick}>{t('warning.delete')}</Button>
                    </Modal.Footer>
                </Modal>
            </Card.Body>
        </Card>
    );
};

export default Time;