import React, {useEffect, useState} from 'react'
import DTable from '../../../modules/DataTable/DTable'
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {setLoading} from "../../../../redux/action";
import {fetchRequest} from "../../../../utils/fetchRequest";
import {attendanceSave, getJournalDetail} from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import AddAttendance from "./addAttendance";
import {Button} from "react-bootstrap";

const Attendance = ({
    timetableId
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    const [addModal, setAddModal] = useState(false);
    const [students, setStudents] = useState([]);

    useEffect(() => {
        let params = {
            school: schoolId,
            timetable: timetableId,
            tab: 'ATTENDANCE'
        };
        init(params);
    }, []);

    const init = (params) => {
        dispatch(setLoading(true));
        fetchRequest(getJournalDetail, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    let weeks = data.weeks;
                    setStudents(data.students);

                    let cloneStaticColums = [...staticColums];

                    if(weeks && weeks.length > 0){
                        for(let i = 0; i < weeks.length; i++){
                            if(weeks[i].dates && weeks[i].dates.length > 0){
                                let dates = weeks[i].dates;
                                for(let d = 0; d < dates.length; d++){
                                    cloneStaticColums.push({
                                        key: 'day_' + i + '_' + d,
                                        dataField: dates[d],
                                        text: <>
                                            <p>{weeks[i].week}{t('attendance.no')}</p>
                                            <div>{dates[d]}</div>
                                        </>,
                                        sort: false,
                                        headerStyle: () => {
                                            return {
                                                width: 120,
                                            }
                                        },
                                        formatter: (cell, row) => {
                                            let rowData = null;
                                            if(row.logs && row.logs.length > 0){
                                                for(let l = 0; l < row.logs.length; l++){
                                                    if(row.logs[l].date === dates[d] && row.logs[l].log){
                                                        let buttonName = '';
                                                        if(row.logs[l].log.code.toLowerCase() === 'came'){
                                                            buttonName = t('attendance.cameCode');
                                                        } else if(row.logs[l].log.code.toLowerCase() === 'nonatt'){
                                                            buttonName = t('attendance.nonAttendanceCode');
                                                        } else if(row.logs[l].log.code.toLowerCase() === 'late'){
                                                            buttonName = t('attendance.lateCode');
                                                        } else if(row.logs[l].log.code.toLowerCase() === 'sick'){
                                                            buttonName = t('attendance.sickCode');
                                                        } else if(row.logs[l].log.code.toLowerCase() === 'absent'){
                                                            buttonName = t('attendance.absentCode');
                                                        }

                                                        rowData = <Button
                                                            key={'button_' + i}
                                                            style={{
                                                                color: '#fff',
                                                                backgroundColor: '#' + row.logs[l].log.color,
                                                                borderColor: '#' + row.logs[l].log.color,
                                                                borderRadius: '50%',
                                                            }}
                                                            className={"btn btn-outline-success m-btn m-btn--icon m-btn--icon-only"}
                                                        >
                                                            {buttonName}
                                                        </Button>
                                                    }
                                                }
                                            }
                                            return(rowData)
                                        }
                                    });
                                }
                            }
                        }
                    }

                    setColumns(cloneStaticColums)
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const [staticColums] = useState([
        {
            dataField: 'code',
            text: t('student.code'),
            sort: true,
        },
        {
            dataField: 'firstName',
            text: t('student.name'),
            sort: true,
        },
        {
            dataField: 'total',
            text: t('total.title'),
            sort: true,
            formatter: (cell, row) => {
                let rowLogs = row.logs;
                let counter = 0;
                if(rowLogs && rowLogs.length > 0){
                    for(let i = 0; i < rowLogs.length; i++){
                        if(rowLogs && rowLogs[i].log){
                            counter = counter + 1;
                        }
                    }
                }
                return counter;
            }
        },
    ]);

    const [colums, setColumns] = useState([
        {
            dataField: 'code',
            text: t('student.code'),
            sort: true,
        },
        {
            dataField: 'firstName',
            text: t('student.name'),
            sort: true,
        },
        {
            dataField: 'total',
            text: t('total.title'),
            sort: true,
            formatter: (cell, row) => {
                let rowLogs = row.logs;
                let counter = 0;
                if(rowLogs && rowLogs.length > 0){
                    for(let i = 0; i < rowLogs.length; i++){
                        if(rowLogs && rowLogs[i].log){
                            counter = counter + 1;
                        }
                    }
                }
                return counter;
            }
        },
    ]);

    const config = {
        showPagination: false,
        showFilter: true,
        showAllData: true
    };

    const handlerAddModal = () => {
        setAddModal(true)
    };

    const closeAddModal = () => {
        setAddModal(false)
    };

    const onSubmit = params => {
        const bodyParams = {
            school: schoolId,
            ...params,
        };

        dispatch(setLoading(true));
        fetchRequest(attendanceSave, 'POST', bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    message(data?.message || t('common.success'), true);
                    closeAddModal();
                    let params = {
                        school: schoolId,
                        timetable: timetableId,
                        tab: 'ATTENDANCE'
                    };
                    init(params);
                } else {
                    message(res?.data?.message || t('errorMessage.title'));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    return (
        <div className='sm-container'>
            <div className='row'>
                <div className='col-12'>
                    <button className='btn btn-primary btn-shadow mr-2' onClick={handlerAddModal}>{t('attendance.add')}</button>
                    {/*<button className='btn btn-purple btn-shadow'>{t('common.print')}</button>*/}
                </div>
                <div className='col-12'>
                    <DTable
                        data={students}
                        columns={colums}
                        config={config}
                        remote
                        selectMode={'radio'}
                        className={'table-layout-none'}
                    />
                </div>
                {
                    addModal
                    ?
                        <AddAttendance
                            timetableId={timetableId}
                            onClose={closeAddModal}
                            onSubmit={onSubmit}
                        />
                    : null
                }
            </div>
        </div>
    )
};

export default Attendance;