import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import {
    schoolShiftAdd,
    timeTemplateAdd, timeTemplateDelete,
    timeTemplateEdit, timeTemplateReorder
} from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import DTable from "../../../modules/DataTable/DTable";
import TimeTemplateAdd from "./TimeTemplateAdd";
import { getLastOrderNumber } from "../../../../utils/utils";
import DeleteModal from "../../../modules/DeleteModal";

const TimeTemplate = () => {
    const [ shifts, setShifts ] = useState([]);
    const [ tabKey, setTabKey ] = useState();
    const [ timeTemplates, setTimeTemplates ] = useState([]);
    const [ showModal, setShowModal ] = useState(false);
    const [ templateId, setTemplateId ] = useState(null);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    useEffect(() => {
        const params = {
            school: schoolId
        };
        dispatch(setLoading(true));
        fetchRequest(schoolShiftAdd, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;

                    if (data?.schoolShifts?.length) {
                        setShifts(data.schoolShifts);
                        setTabKey(data.schoolShifts[0].key ? data.schoolShifts[0].key.toString() : null);
                    }
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            })
    }, []);

    useEffect(() => {
        if (tabKey) {
            setTimeTemplates([]);
            const params = {
                school: schoolId,
                schoolShift: tabKey
            };
            dispatch(setLoading(true));
            fetchRequest(timeTemplateAdd, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res;

                        if (data?.timeTemplates?.length) {
                            setTimeTemplates(formatTimes(data.timeTemplates));
                        }
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        }
    }, [tabKey]);

    const handleTabChange = key => {
        if (key !== tabKey) {
            setTabKey(key);
        }
    };

    const handleAddClick = () => {
        setShowModal(true);
    };

    const onModalClose = () => {
        setShowModal(false);
        setTemplateId(null);
    };

    const config = {
        showPagination: false,
        showFilter: false,
        showAllData: true
    };

    const columns = [
        {
            dataField: "startTime",
            text: t('common.startTime'),
            style: {textAlign: 'right'}
            // sort: true
        },
        {
            dataField: "endTime",
            text: t('common.endTime'),
            style: {textAlign: 'right'}
            // sort: true
        },
        {
            dataField: "name",
            text: t('common.name'),
            // sort: true
        },
        {
            dataField: "ordering",
            text: t('common.ordering'),
            style: {textAlign: 'right'}
            // sort: true
        },
    ];

    const contextMenus = [
        {
            key: 'edit',
            title: t('common.edit'),
            icon: <i className='las la-edit dt-cm-item-icon'/>
        },
        {
            key: 'delete',
            title: t('common.delete'),
            icon: <i className='las la-trash-alt dt-cm-item-icon' />,
        },
    ];

    const formatTimes = array => {
        return array.map(item => {
            return {
                ...item,
                startTime: item.startTime?.date ? item.startTime?.date.substring(11, 16) : '-',
                endTime: item.endTime?.date ? item.endTime?.date.substring(11, 16) : '-',
            }
        })
    };

    const handleContextMenuClick = (id, key) => {
        if (id && key) {
            if (key === 'edit') {
                setTemplateId(id);
                setShowModal(true);
            }
            if (key === 'delete') {
                setTemplateId(id);
            }
        }
    };

    const onSubmit = params => {
        const url = templateId ? timeTemplateEdit : timeTemplateAdd;
        const bodyParams = {
            ...params,
            school: schoolId,
            schoolShift: tabKey
        };

        if (!templateId) {
            bodyParams.ordering = getLastOrderNumber(timeTemplates) + 1;
        } else {
            bodyParams.timeTemplate = templateId;
            const template = timeTemplates.find(t => t.id === templateId);
            bodyParams.ordering = template?.ordering || getLastOrderNumber(timeTemplates) + 1;
        }

        dispatch(setLoading(true));
        fetchRequest(url, 'POST', bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setTimeTemplates(data.timeTemplates ? formatTimes(data.timeTemplates) : []);

                    message(data?.message || t('common.success'), true);
                    onModalClose();
                } else {
                    message(res?.data?.message || t('errorMessage.title'));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const onDelete = () => {
        dispatch(setLoading(true));
        const params =  {
            school: schoolId,
            schoolShift: tabKey,
            timeTemplate: templateId,
        };
        fetchRequest(timeTemplateDelete, 'POST', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setTimeTemplates(data.timeTemplates ? formatTimes(data.timeTemplates) : []);
                    message(data?.message || t('common.success'), true);
                    onModalClose();
                } else {
                    message(res?.data?.message || t('errorMessage.title'));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const onDrop = drop => {
        const formData = new FormData();

        formData.append('school', schoolId);
        formData.append('schoolShift', tabKey);
        formData.append('item', drop.itemId);
        formData.append('target', drop.targetId);

        dispatch(setLoading(true));
        fetchRequest(timeTemplateReorder, 'POST', formData, false, true)
            .then(res => {
                const { data } = res;
                if (res.success) {
                    setTimeTemplates(data.timeTemplates ? formatTimes(data.timeTemplates) : []);
                    message(res?.data?.message || t('common.success'), true);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    return (
        <Card>
            <Card.Header>
                <Tabs
                    id={'school-shfits'}
                    activeKey={tabKey}
                    onSelect={handleTabChange}
                    className='msue-tab'
                    transition={false}
                >
                    {
                        shifts.map(shift => {
                            return (
                                <Tab key={shift.key} eventKey={shift.key} title={shift.title}/>
                            )
                        })
                    }
                </Tabs>
            </Card.Header>
            <Card.Body>
                {
                    shifts && shifts.length > 0 &&
                    <button
                        className='btn btn-primary'
                        onClick={handleAddClick}
                    >
                        {t('common.add')}
                    </button>
                }
                <DTable
                    draggable
                    columns={columns}
                    config={config}
                    data={timeTemplates}
                    contextMenus={contextMenus}
                    onContextMenuItemClick={handleContextMenuClick}
                    selectMode={'radio'}
                    onDrop={onDrop}
                />
                {
                    showModal && (
                        <TimeTemplateAdd
                            onClose={onModalClose}
                            onSubmit={onSubmit}
                            id={templateId}
                            shift={tabKey}
                        />
                    )
                }
                {
                    !showModal && templateId && (
                        <DeleteModal
                            onClose={onModalClose}
                            title={t('school.timeTemplate.title')}
                            onDelete={onDelete}
                        >
                            {t('warning.delete_confirmation')}
                            <br/>
                            <br/>
                            {t('warning.delete_confirmation_description')}
                        </DeleteModal>
                    )
                }
            </Card.Body>
        </Card>
    );
};

export default TimeTemplate;