import React, {useEffect, useRef, useState} from 'react';
import { Button, Modal } from "react-bootstrap";
import Forms from "../../../modules/Form/Forms";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {lmsStudentSearch, selectionPenaltyCreate} from "../../../../utils/fetchRequest/Urls";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import message from "../../../modules/message";
import Select from "../../../modules/Form/Select";
import Checkbox from "../../../modules/Form/Checkbox";

const AddPenalty = ({
    seasonTitle,
    seasonId,
    onClose,
    onSubmit,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [students, setStudents] = useState([]);
    const [studentId, setStudentId] = useState([]);
    const [value, setValue] = useState('');
    const [configs, setConfigs] = useState([]);

    useEffect(() => {

    }, []);

    const onSaveClick = () => {
        let dtls = [];
        if(configs && configs.length > 0){
            for(let i = 0; i < configs.length; i++){
                if(configs[i].checked){
                    dtls.push(configs[i].id)
                }
            }
        }
        let params = {
            school: schoolId,
            season: seasonId,
            student: studentId,
            configs: dtls
        };
        onSubmit(params);
    };

    const handleCheckboxClick = (value, index) => {
        let cloneConfig = [...configs];
        cloneConfig[index].checked = !value;
        setConfigs(cloneConfig);
    };

    const onSeasonChange = () => {
    };

    const onStudentChange = (value) => {
        if(value){
            setConfigs([]);
            setStudentId(value);
            const params = {
                school: schoolId,
                season: seasonId,
                student: value
            };
            dispatch(setLoading(true));
            fetchRequest(selectionPenaltyCreate, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res;
                        let configObj = data?.configs || [];
                        if(configObj && configObj.length > 0){
                            for(let i = 0; i < configObj.length; i++){
                                configObj[i].checked = false;
                            }
                        }
                        setConfigs(configObj);
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        }
    };

    const onStudentInputChange = (inputValue, actionMeta) => {
        if(inputValue){
            setValue(inputValue);
        }
    };

    useEffect(() => {
        let timeout;
        if (value) {
            timeout = setTimeout(() => {
                const params = {
                    school: schoolId,
                    season: seasonId,
                    studentCode: value,
                    select: 1
                };
                dispatch(setLoading(true));
                fetchRequest(selectionPenaltyCreate, 'GET', params)
                    .then(res => {
                        if (res.success) {
                            const { data } = res;
                                setStudents(data.students);
                        } else {
                            message(res?.data?.message || t('errorMessage.title'))
                        }
                        dispatch(setLoading(false));
                    })
                    .catch(() => {
                        dispatch(setLoading(false));
                        message(t('errorMessage.title'))
                    })
            }, 1000);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [value]);

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('selection.enterFine').toUpperCase()}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='mt-3' style={{ display: 'flex', marginTop: '0.8rem' }}>
                    <label
                        style={{
                            display: 'flex',
                            flex: 1,
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            marginRight : 10,
                            marginBottom: 0,
                            width: 'auto',
                        }}
                    >
                        {t('subject.season') + '*'}
                    </label>
                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'column',
                            marginLeft: 10,
                            width: 'auto',
                        }}
                    >
                        <Select
                            value={null}
                            searchable
                            onChange={onSeasonChange}
                            placeholder={seasonTitle}
                            options={[]}
                            disabled={true}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flex: 0.8,
                            flexDirection: 'column',
                            marginLeft: 10,
                            width: 'auto',
                        }}
                    />
                </div>
                <div className='mt-3' style={{ display: 'flex', marginTop: '0.8rem' }}>
                    <label
                        style={{
                            display: 'flex',
                            flex: 1,
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            marginRight : 10,
                            marginBottom: 0,
                            width: 'auto',
                        }}
                    >
                        {t('student.title') + '*'}
                    </label>
                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'column',
                            marginLeft: 10,
                            width: 'auto',
                        }}
                    >
                        <Select
                            value={studentId}
                            searchable
                            onChange={onStudentChange}
                            onInputChange={onStudentInputChange}
                            options={students}
                        />

                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flex: 0.8,
                            flexDirection: 'column',
                            marginLeft: 10,
                            width: 'auto',
                        }}
                    />
                </div>
                {
                    configs && configs.length > 0 &&
                    <div className='mt-3' style={{ display: 'flex', marginTop: '0.8rem' }}>
                        <label
                            style={{
                                display: 'flex',
                                flex: 0.7,
                                justifyContent: 'flex-end',
                                alignItems: 'start',
                                marginRight : 10,
                                marginBottom: 0,
                                width: 'auto',
                                marginTop: '0.5rem'
                            }}
                        >
                            {t('timetable.selection') + '*'}
                        </label>
                        <div
                            style={{
                                display: 'flex',
                                flex: 1,
                                flexDirection: 'column',
                                marginLeft: 10,
                                width: 'auto',
                            }}
                        >
                            {
                                configs.map((config, index) => {
                                    let label =
                                    <div
                                        style={{
                                            padding: 5,
                                            borderRadius: 8,
                                            border: '1px solid #d8d8d8',
                                        }}
                                    >
                                        <b>{config.selection}</b><br/>
                                        {t('common.startDate').substring(0, 5) + ': ' + config.startDate}<br/>
                                        {t('common.endDate').substring(0, 6) + ': ' + config.endDate}<br/>
                                        {
                                            config.hasSelection &&
                                            t('selection.selectedDate').substring(0, 16) + ': ' + config.selectionDate
                                        }
                                    </div>;
                                    return (
                                        <Checkbox
                                            key={'check_' + index}
                                            className='custom-cbox mt-2'
                                            checked={config.checked}
                                            onChange={(e) => handleCheckboxClick(e, index)}
                                            label={label
                                            }
                                        />
                                    )
                                })
                            }
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flex: 0.3,
                                flexDirection: 'column',
                                marginLeft: 10,
                                width: 'auto',
                            }}
                        />
                    </div>
                }
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className='btn btn-link bolder'>{t('common.back')}</button>
                <Button variant='success btn-shadow' onClick={onSaveClick}>{t('common.save')}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddPenalty;