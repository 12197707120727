import React, {useRef, useEffect, useState} from 'react';
import {Button, ButtonToolbar, Card} from "react-bootstrap";
import Forms from "../../../../modules/Form/Forms";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {teacherOnlineLessonTopicList, teacherOnlineLessonTopicActive, teacherOnlineLessonTopicDelete} from "../../../../../utils/fetchRequest/Urls";
import { setLoading } from "../../../../../redux/action";
import { fetchRequest } from "../../../../../utils/fetchRequest";
import message from "../../../../modules/message";
import {Link, useHistory} from "react-router-dom";
import DTable from "../../../../modules/DataTable/DTable";
import {AddFileModal} from "./AddFileModal";
import DeleteModal from "../../../../modules/DeleteModal";

const style = {
    cardBody: {
        position: 'relative',
        bottom: '4px',
        zIndex: 10
    }
}

export const Topic = ({
    id,
}) => {
    const { t } = useTranslation();
    const formRef = useRef();
    const history = useHistory();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [topics, setTopics] = useState([]);
    const [deleteModal, setDeleteModal] = useState(false);
    const [syllabusId, setSyllabusId] = useState(null);

    const setTopicList = (topics) => {
        if(topics && topics.length > 0){
            for(let i = 0; i < topics.length; i++){
                if(topics[i].isActive){
                    topics[i].contextMenuKeys = ['report', 'close'];
                } else {
                    topics[i].contextMenuKeys = ['report', 'edit', 'delete', 'open'];
                }
            }
        }
        setTopics(topics)
    };

    useEffect(() => {
        const params = {
            school: schoolId,
            course: id
        };

        dispatch(setLoading(true));
        fetchRequest(teacherOnlineLessonTopicList, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setTopicList(data?.topics || []);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }, []);

    const changeActive = (contextId, active) => {
        const params = {
            school: schoolId,
            course: id,
            syllabus: contextId,
            active: active
        };

        dispatch(setLoading(true));
        fetchRequest(teacherOnlineLessonTopicActive, 'POST', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setTopicList(data?.topics || []);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }

    const config = {
        showFilter: true,
        showAllData: true
    }

    const columns = [
        {
            dataField: "isActive",
            text: t('common.status'),
            headerStyle: () => ({
                width: 80,
            }),
            style: {textAlign: 'center', padding: 3},
            formatter: (cell, row) => {
                if(cell === true){
                    return (
                        <Button className="btn btn-success">{t('discussion.open')}</Button>
                    )
                } else {
                    return (
                        <Button className="btn btn-secondary">{t('discussion.close')}</Button>
                    )
                }
            }
        },
        {
            dataField: "pTimeName",
            text: t('common.type'),
            sort: true
        },
        {
            dataField: "name",
            text: t('teacher.topicName'),
            sort: true
        },
        {
            dataField: "createdDate",
            text: t('common.createdDate'),
            sort: true
        },
    ];

    const contextMenus = [
        {
            key: 'edit',
            title: t('common.edit'),
            icon: <i className='las la-edit dt-cm-item-icon'/>
        },
        {
            key: 'delete',
            title: t('common.delete'),
            icon: <i className='las la-trash-alt dt-cm-item-icon' />,
        },
        {
            key: 'report',
            title: t('report.view'),
            icon: <i className='flaticon-graphic-1 dt-cm-item-icon' />,
        },
        {
            key: 'close',
            title: t('common.close'),
            icon: <i className='la la-eye-slash dt-cm-item-icon' />,
        },
        {
            key: 'open',
            title: t('common.open'),
            icon: <i className='la la-eye dt-cm-item-icon' />,
        },
    ];

    const handleContextMenuClick = (contextId, key, record) => {
        if(key === 'edit'){
            history.push({
                pathname: '/teacher/online/lesson-edit/topic-edit',
                state: {
                    id: id,
                    syllabusId: contextId
                }
            })
        } else if (key === 'delete'){
            setSyllabusId(contextId);
            setDeleteModal(true);
        } else if (key === 'report'){
            history.push({
                pathname: '/teacher/online/lesson-edit/topic-view',
                state: {
                    id: id,
                    syllabusId: contextId
                }
            })
        } else if (key === 'close'){
            changeActive(contextId , 0)
        } else if (key === 'open'){
            changeActive(contextId , 1)
        }
    };

    const onModalClose = () => {
        setDeleteModal(false);
        setSyllabusId(null);
    };

    const onDelete = () => {
        const bodyParams = {
            school: schoolId,
            course: id,
            syllabus: syllabusId
        };

        dispatch(setLoading(true));
        fetchRequest(teacherOnlineLessonTopicDelete, 'POST', bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setTopicList(data?.topics || []);
                    message(res?.data?.message || t('common.success'), true);
                    onModalClose();
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            })
    };

    return (
        <div className='sm-container'>
            <Card style={style.cardBody}>
                <Card.Body>
                    <ButtonToolbar className='d-inline'>
                        <Link
                            to={{
                                pathname: '/teacher/online/lesson-edit/topic-add',
                                state: {
                                    id: id,
                                }
                            }}
                            className="btn btn-primary btn-shadow"
                        >
                            {t('teacher.addTopic')}
                        </Link>
                    </ButtonToolbar>
                    <ButtonToolbar className='d-inline' style={{float: 'right'}}>
                        <Link
                            to='/teacher/online/lesson'
                            className="btn btn-link bolder"
                        >
                            {t('common.backTo_list')}
                        </Link>
                    </ButtonToolbar>

                    <DTable
                        columns={columns}
                        config={config}
                        data={topics}
                        contextMenus={contextMenus}
                        onContextMenuItemClick={handleContextMenuClick}
                        individualContextMenus={true}
                        selectMode={'radio'}
                    />
                </Card.Body>
            </Card>
            {
                deleteModal && syllabusId && (
                    <DeleteModal
                        onClose={onModalClose}
                        onDelete={onDelete}
                        title={t('warning.delete')}>
                        {t('warning.delete_confirmation')}
                        <br />
                        <br />
                        {t('warning.delete_confirmation_description')}
                    </DeleteModal>
                )
            }
        </div>
    );
};