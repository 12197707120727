import React, {useEffect, useState} from 'react'
import DTable from 'app/modules/DataTable/DTable'
import {Button, Card} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {setLoading} from "redux/action";
import {fetchRequest} from "utils/fetchRequest";
import {teacherTimeAdditionalAdd, teacherTimeAdditionalDelete, teacherTimeAdditionalEdit, teacherTimeAdditionalInit} from "utils/fetchRequest/Urls";
import message from "app/modules/message";
import Select from "app/modules/Form/Select";
import AddModal from "./addModal";
import EditModal from "./editModal";
import DeleteModal from 'app/modules/DeleteModal';

export const TeacherTimeAdditional = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [ teacherTimeSheetParentSeasonIndex ] = useState('teacher_time_additional_parent_season_index');
    const [ teacherTimeSheetSeasonIndex ] = useState('teacher_time_additional_season_index');

    const [list, setList] = useState([]);
    const [years, setYears] = useState([]);
    const [seasons, setSeasons] = useState([]);
    const [yearId, setYearId] = useState(localStorage.getItem(teacherTimeSheetParentSeasonIndex) || null);
    const [seasonId, setSeasonId] = useState(localStorage.getItem(teacherTimeSheetSeasonIndex) || null);
    const [selectedSeasonId, setSelectedSeasonId] = useState(null);
    const [tableId, setTableId] = useState(null);
    const [addModal, setAddModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);

    useEffect(() => {
        const params = {
            school: schoolId,
            parent: yearId,
            season: seasonId,
        };
        init(params)
    }, []);

    const init = (params) => {
        dispatch(setLoading(true));
        fetchRequest(teacherTimeAdditionalInit, 'GET', params)
            .then(res => {
                dispatch(setLoading(false));
                if (res.success) {
                    const { data } = res;

                    if(!yearId){
                        setYearId(data?.parentSeasonId || null);
                    }

                    if(!seasonId){
                        setSeasonId(data?.seasonId || null);
                    }
                    
                    setYears(data?.parentSeasons || [])
                    setSeasons(data?.seasons || [])
                    setList(data?.list || [])
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const config = {
        showPagination: false,
        showFilter: false,
        showAllData: true,
        tableMarginLess: true
    };

    const columns = [
        {
            dataField: 'typeName',
            text: t('common.type'),
            sort: false,
        },
        {
            dataField: 'subjectName',
            text: t('subject.title'),
            sort: false,
        },
        {
            dataField: 'credit',
            text: t('subject.credit'),
            sort: false,
        },
        {
            dataField: 'studentNumber',
            text: t('student.number'),
            sort: false,
        },
        {
            dataField: 'description',
            text: t('common.description'),
            sort: true,
        },
        {
            dataField: 'createdDate',
            text: t('common.createdDate'),
            sort: false,
        }
    ];

    const tableContextItem = [
        {
            key: 'edit',
            title: t('common.edit'),
            icon: <i className='las la-edit dt-cm-item-icon'/>
        },
        {
            key: 'delete',
            title: t('action.delete'),
            icon: <i className='las la-trash-alt dt-cm-item-icon'/>
        },
    ];

    const handleContextMenuClick = (id, key) => {
        if(id && key) {
            if(key === 'delete'){
                setDeleteModal(true);
                setTableId(id)
            }
            if(key === 'edit'){
                setTableId(id)
                setEditModal(true)
            }
        }
    };

    const onYearChange = (value) => {
        setYearId(value)

        localStorage.setItem(teacherTimeSheetParentSeasonIndex, value);

        const params = {
            school: schoolId,
            parent: value,
            season: seasonId,
        };
        init(params)
    }

    const onSeasonChange = (value) => {
        setSeasonId(value)

        localStorage.setItem(teacherTimeSheetSeasonIndex, value);

        const params = {
            school: schoolId,
            parent: yearId,
            season: value
        };
        init(params)
    }

    const handleAddClick = () => {
        setAddModal(true);
    };

    const onSubmit = (params) => {
        params.school = schoolId
        
        dispatch(setLoading(true));
        fetchRequest(teacherTimeAdditionalAdd, 'POST', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;

                    setList(data?.list || [])
                    setAddModal(false)
                    dispatch(setLoading(false));
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                    dispatch(setLoading(false));
                }
            })
            .catch(err => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }

    const onEditSubmit = (params) => {
        params.school = schoolId
        
        dispatch(setLoading(true));
        fetchRequest(teacherTimeAdditionalEdit, 'POST', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;

                    setList(data?.list || [])
                    setEditModal(false)
                    dispatch(setLoading(false));
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                    dispatch(setLoading(false));
                }
            })
            .catch(err => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }

    const onDelete = () => {
        const bodyParams = {
            school: schoolId,
            season: seasonId,
            id: tableId
        };

        dispatch(setLoading(true));
        fetchRequest(teacherTimeAdditionalDelete, 'POST', bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res;

                    setList(data?.list || []);
                    message(res?.data?.message || t('common.success'), true);
                    onClose();
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            })
    };

    const onClose = () =>{
        setDeleteModal(false);
        setTableId(null)
    };

    return (
        <div className='sm-container'>
            <Card>
                <Card.Body>
                    <div className='row'>
                        <div className='col-12'>
                            <label className="mr-2">{t('school.season.title')}</label>
                            <div style={{ width: 200, display: 'inline-flex' }}>
                                <Select
                                    className={'default-input-border w-100'}
                                    value={parseInt(yearId)}
                                    searchable
                                    onChange={value => onYearChange(value)}
                                    options={years}
                                />
                            </div>
                            <label className="ml-4 mr-2">{t('school.season.title')}</label>
                            <div style={{ width: 200, display: 'inline-flex' }}>
                                <Select
                                    className={'default-input-border w-100'}
                                    value={parseInt(seasonId)}
                                    searchable
                                    onChange={value => onSeasonChange(value)}
                                    options={seasons}
                                />
                            </div>
                        </div>
                        <div className='col-12'>
                            {
                                seasonId &&
                                <button
                                    className='btn btn-primary mt-2'
                                    onClick={handleAddClick}
                                >
                                    {t('common.add')}
                                </button>
                            }
                            <DTable
                                config={config}
                                data={list}
                                columns={columns}
                                selectMode={'radio'}
                                contextMenus={tableContextItem}
                                onContextMenuItemClick={handleContextMenuClick}
                            />
                        </div>
                    </div>
                </Card.Body>
            </Card>
            {
                addModal &&
                <AddModal
                    seasonId={seasonId}
                    onSubmit={onSubmit}
                    onClose={()=>{
                        setAddModal(false)
                        setSelectedSeasonId(null)
                    }}
                />
            }
            {
                editModal &&
                <EditModal
                    seasonId={seasonId}
                    tableId={tableId}
                    onSubmit={onEditSubmit}
                    onClose={()=>{
                        setEditModal(false)
                        setTableId(null)
                    }}
                />
            }
            {
                deleteModal &&
                <DeleteModal 
                    onClose={onClose} 
                    onDelete={onDelete}
                    title={t('common.delete')}
                >
                    {t('warning.delete_confirmation')}
                    <br/>
                    <br/>
                    {t('warning.delete_confirmation_description')}
                </DeleteModal>
            }
        </div>
    )
};
