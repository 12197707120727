import React, { useRef, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Forms from "../../../modules/Form/Forms";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
    getSchools,
    getAddProgram,
    getEditProgram,
    getSchoolProgram,
    getAcademicProgram,
} from "../../../../utils/fetchRequest/Urls";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import message from "../../../modules/message";

const EditModal = ({ onClose, enrollId, id, setSelectedSchoolPrograms }) => {
    const form = useRef();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const languages = useSelector(
        (state) => state?.languages?.languages || []
    ).filter((lang) => lang.code !== "mn");

    const [program, setProgram] = useState(null);
    const [schools, setSchools] = useState([]);
    const [schoolPrograms, setSchoolPrograms] = useState([]);
    const [academicPrograms, setAcademicPrograms] = useState([]);

    const [selectedSchoolId, setSelectedSchoolId] = useState(null);
    const [selectedAcademicId, setSelectedAcademicId] = useState(null);
    const [selectedProgramId, setSelectedProgramId] = useState(null);
    const [isGenderRequired, setIsGenderRequired] = useState(false);
    const [isHide, setIsHide] = useState(false);

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        form?.current?.updateFields && form.current.updateFields(forms);
    }, [schools, academicPrograms, schoolPrograms, isGenderRequired, program, isHide]);

    useEffect(() => {
        getSchool();
        form?.current?.updateFields && form.current.updateFields(forms);
    }, [program]);

    const onSchoolChange = (id) => {
        if (id) {
            setSelectedSchoolId(id);
            getAcademicPrograms(id);
        }
    };

    const onAcademicChange = (id) => {
        if (id) {
            setSelectedAcademicId(id);
            getSchoolPrograms({ id: id });
        }
    };

    const onProgramChange = (id) => {
        if (id) {
            setSelectedProgramId(id);
        }
    };

    const onGenderChange = (value) => {
        setIsGenderRequired(value);
    };

    const onHideChange = (value) => {
        setIsHide(value);
    };

    const forms = [
        {
            label: "Бүрэлдэхүүн сургууль",
            value: selectedSchoolId
                ? selectedSchoolId
                : program
                    ? program.schoolId
                    : null,
            type: "dropdown",
            inputWidth: 300,
            labelWidth: 230,
            required: true,
            key: "school",
            options: schools,
            onChange: onSchoolChange,
        },
        {
            label: "Хөтөлбөр",
            value: selectedAcademicId
                ? selectedAcademicId
                : program
                    ? program.academicProgramId
                    : null,
            type: "dropdown",
            inputWidth: 300,
            labelWidth: 230,
            required: true,
            key: "academicProgram",
            options: academicPrograms,
            onChange: onAcademicChange,
        },
        {
            label: "Мэргэжил",
            value: selectedProgramId
                ? selectedProgramId
                : program
                    ? program.programId
                    : null,
            type: "dropdown",
            inputWidth: 300,
            labelWidth: 230,
            required: true,
            key: "program",
            clearable: true,
            searchable: true,
            options: schoolPrograms,
            onChange: onProgramChange,
        },
        {
            label: "Нийт",
            value: program ? program.totalNumber : "",
            type: "text",
            inputWidth: 300,
            labelWidth: 230,
            required: true,
            // options: positionArray,
            key: "totalNumber",
            clearable: true,
        },
        {
            label: "Хүйсээр ялгах эсэх",
            type: "checkbox",
            value: isGenderRequired,
            inputWidth: 300,
            labelWidth: 230,
            key: "gender",
            onChange: onGenderChange,
        },
        {
            label: "Эрэгтэй*",
            value: program ? program.mNumber : "",
            type: "text",
            inputWidth: 300,
            labelWidth: 230,
            required: false,
            // options: roleArray,
            key: "mNumber",
            multiple: true,
            searchable: true,
            disabled: !isGenderRequired,
        },
        {
            label: "Эмэгтэй*",
            value: program ? program.fNumber : "",
            type: "text",
            inputWidth: 300,
            labelWidth: 230,
            required: false,
            // options: roleArray,
            key: "fNumber",
            multiple: true,
            searchable: true,
            disabled: !isGenderRequired,
        },
        {
            label: "Элсэлт хаагдсан эсэх",
            type: "checkbox",
            value: isHide,
            inputWidth: 300,
            labelWidth: 230,
            key: "isHide",
            onChange: onHideChange,
        },
    ];

    const getData = () => {
        const params = {
            school: id,
            enrollDtlProgram: id,
        };
        dispatch(setLoading(true));
        fetchRequest(getEditProgram, "GET", params)
            .then((res) => {
                if (res.success) {
                    const { data } = res;
                    if (data?.enrollDtlProgramData) {
                        setProgram(data?.enrollDtlProgramData);
                        setIsHide(data?.enrollDtlProgramData?.isHide)
                        if(data.enrollDtlProgramData && (data.enrollDtlProgramData.mNumber > 0 || data.enrollDtlProgramData.fNumber > 0)){
                            setIsGenderRequired(true)
                        }
                    }
                } else {
                    message(res?.data?.message || t("errorMessage.title"));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t("errorMessage.title"));
            });
    };

    const getSchool = () => {
        dispatch(setLoading(true));
        fetchRequest(getSchools, "GET", [])
            .then((res) => {
                if (res.success) {
                    const { data } = res;
                    const schoolOptions = [];
                    data?.list.forEach((school) => {
                        schoolOptions.push({ value: school.id, text: school.longName });
                    });
                    setSchools(schoolOptions);
                    if (program && program.schoolId) {
                        setSelectedSchoolId(program.schoolId);
                        getAcademicPrograms(program.schoolId);
                    }
                } else {
                    message(res?.data?.message || t("errorMessage.title"));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t("errorMessage.title"));
            });
    };

    const getAcademicPrograms = (id) => {
        const params = {
            school: id,
        };
        dispatch(setLoading(true));
        fetchRequest(getAcademicProgram, "GET", params)
            .then((res) => {
                if (res.success) {
                    const { data } = res;
                    const programOptions = [];
                    data?.list.forEach((program) => {
                        programOptions.push({
                            value: Number(program.id),
                            text: program.name,
                        });
                    });
                    setAcademicPrograms(programOptions);
                    if (program && program.academicProgramId) {
                        setSelectedAcademicId(program.academicProgramId);
                        getSchoolPrograms({ id: program.academicProgramId, schoolId: id });
                    }
                } else {
                    message(res?.data?.message || t("errorMessage.title"));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t("errorMessage.title"));
            });
    };

    const getSchoolPrograms = ({ id, schoolId }) => {
        const params = {
            school: selectedSchoolId ?? schoolId,
            academicProgram: id,
        };
        dispatch(setLoading(true));
        fetchRequest(getSchoolProgram, "GET", params)
            .then((res) => {
                if (res.success) {
                    const { data } = res;
                    const programOptions = [];
                    data?.list.forEach((program) => {
                        programOptions.push({ value: program.id, text: program.name });
                    });
                    setSchoolPrograms(programOptions);
                } else {
                    message(res?.data?.message || t("errorMessage.title"));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t("errorMessage.title"));
            });
    };

    const onSaveClick = () => {
        const [formValid, formValue] = form.current.validate();
        if (formValid) {
            const params = {
                enrollDtlProgram: id,
                enroll: enrollId,
                school: formValue?.[0].value,
                academicProgram: formValue?.[1].value,
                program: formValue?.[2].value,
                totalNumber: formValue?.[3].value,
                mNumber: formValue?.[5].value,
                fNumber: formValue?.[6].value,
                isHide: formValue?.[7].value === true ? 1 : 0,
            };

            dispatch(setLoading(true));
            fetchRequest(getEditProgram, "POST", params, false)
                .then((response) => {
                    if (response.success) {
                        setSelectedSchoolPrograms(response.data?.program || [])
                        message(response.data.message, true);
                        onClose();
                    } else {
                        message(response.data.message, false);
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                });
        } else {
        }
    };

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: "#4a70ae", fontSize: "1.1rem" }}>
                        Мэргэжил засах
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Forms ref={form} fields={forms} />
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className="btn btn-link bolder">
                    {t("common.back")}
                </button>
                <Button variant="success btn-shadow" onClick={onSaveClick}>
                    {t("common.save")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditModal;
