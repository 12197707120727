import React, {useEffect, useState} from 'react';
import DTable from '../../../modules/DataTable/DTable';
import {Button, Card, Nav} from 'react-bootstrap';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {setLoading} from "../../../../redux/action";
import {fetchRequest} from "../../../../utils/fetchRequest";
import {studentPaymentInit} from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import ViewPayment from "./view";

export const PaymentTable = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [key, setKey] = useState('#TUITION');
    const [tableDatas, setTableDatas] = useState([]);
    const [studentId, setStudentId] = useState([]);
    const [hasOther, setHasOther] = useState([]);
    const [types, setTypes] = useState([
        {
            id: 'TUITION',
            name: 'Сургалтын төлбөр',
        },
        {
            id: 'DORMITORY',
            name: 'Дотуур байр',
        },
        {
            id: 'PENALTY',
            name: 'Торгууль',
        },
        {
            id: 'DIPLOMA',
            name: 'Ромбо диплом',
        },
        {
            id: 'OTHER',
            name: 'Бусад',
        }
    ]);

    const parentSeason = useSelector(state => {
            let schoolList = state?.school?.schoolList;
            if(schoolList && schoolList.length > 0){
                for (let i = 0; i < schoolList.length; i++){
                    if(schoolId == schoolList[i]['id']){
                        return schoolList[i]['parentSeason'];
                    }
                }
            }
        }
    );

    useEffect(() => {
        const params = {
            school: schoolId,
            season: parentSeason
        };

        init(params)
    }, []);

    useEffect(() => {
        if(key){
            dispatch(setLoading(true));
            const params = {
                school: schoolId,
                season: parentSeason,
                invoiceType: key.substring(1)
            };

            init(params)
        }
    }, [key]);

    const init = (params) => {
        dispatch(setLoading(true));
        fetchRequest(studentPaymentInit, 'GET', params)
            .then(res => {
                dispatch(setLoading(false));
                if (res.success) {
                    const { data } = res;
                    if(!key){
                        if(data.invoiceTypes && data.invoiceTypes.length > 0){
                            setKey('#' + data.invoiceTypes[0].id);
                        }
                    }
                    setListArray(data?.list || []);
                    setHasOther(data.hasOther);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }

            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const setListArray = (listArray) => {
        if(listArray && listArray.length > 0){
            for(let i = 0; i < listArray.length; i++){
                if(listArray[i].transactionDate && listArray[i].transactionDate.date){
                    listArray[i].transactionDate = listArray[i].transactionDate.date.substring(0, 16);
                }
                if(listArray[i].createdDate && listArray[i].createdDate.date){
                    listArray[i].createdDate = listArray[i].createdDate.date.substring(0, 16);
                }
            }
        }
        setTableDatas(listArray)
    };

    const config = {
        showPagination: false,
        showFilter: false,
        tableMarginLess: true,
        footer: true,
        footerStyle: {backgroundColor: '#ebecf5'}
    };

    const column = (key) => {
        if(key == '#TUITION'){
            return ([
                {
                    dataField: 'seasonName',
                    text: t('subject.season'),
                    sort: false,
                    footer: '',
                },
                {
                    dataField: 'totalCredit',
                    text: t('selection.credit'),
                    sort: false,
                    footer: '',
                },
                {
                    dataField: 'totalPrice',
                    text: t('selection.amount'),
                    sort: false,
                    footer: '',
                },
                {
                    dataField: 'discount',
                    text: t('discount.title'),
                    sort: false,
                    footer: '',
                },
                {
                    dataField: 'scholarshipAmount',
                    text: t('scholarship.title'),
                    sort: false,
                    footer: '',
                },
                {
                    dataField: 'toPayAmount',
                    text: t('total.pay'),
                    sort: false,
                    footer: '',
                },
                {
                    dataField: 'paidAmount',
                    text: t('common.paidAmount'),
                    sort: false,
                    footer: '',
                }
            ])
        } else if (key == '#DORMITORY'){
            return([
                {
                    dataField: 'date',
                    text: t('common.date'),
                    sort: true,
                    footer: '',
                },
                {
                    dataField: 'type',
                    text: t('common.type'),
                    sort: true,
                    footer: '',
                },
                {
                    dataField: 'description',
                    text: t('common.description'),
                    sort: true,
                    footer: '',
                },
                {
                    dataField: 'income',
                    text: t('common.amount'),
                    sort: true,
                    footerAlign: 'right',
                    footer: '',
                    footerType: 'sum'
                },
                {
                    dataField: 'paid',
                    text: '',
                    headerStyle: () => {
                        return {
                            width: 150
                        }
                    },
                    formatter(cell) {
                        return (
                            <div className='flex-center'>
                                <button className='btn btn-danger btn-shadow button-in-table-cell'>Төлбөр төлөх</button>
                            </div>
                        )
                    },
                    footer: '',
                },
            ]);
        } else if (key == '#PENALTY'){
            return([
                {
                    dataField: 'paidType',
                    text: t('finance.paidType'),
                    sort: true,
                    footer: '',
                },
                {
                    dataField: 'parentName',
                    text: t('school.season.title'),
                    sort: true,
                    footer: '',
                },
                {
                    dataField: 'seasonName',
                    text: t('subject.season'),
                    sort: true,
                    footer: '',
                },
                {
                    dataField: 'paidAmount',
                    text: t('common.amount'),
                    sort: true,
                    footerAlign: 'right',
                    footer: '',
                    footerType: 'sum'
                },
                {
                    dataField: 'createdDate',
                    text: t('common.paidDate'),
                    sort: true,
                    footer: '',
                },
            ]);
        } else if (key == '#DIPLOMA'){
            return([
                {
                    dataField: 'date',
                    text: t('common.date'),
                    sort: true,
                    footer: '',
                },
                {
                    dataField: 'type',
                    text: t('common.type'),
                    sort: true,
                    footer: '',
                },
                {
                    dataField: 'description',
                    text: t('common.description'),
                    sort: true,
                    footer: '',
                },
                {
                    dataField: 'amount',
                    text: t('common.amount'),
                    sort: true,
                    footerAlign: 'right',
                    footer: '',
                    footerType: 'sum'
                },
                {
                    dataField: 'paid',
                    text: '',
                    headerStyle: () => {
                        return {
                            width: 150
                        }
                    },
                    formatter(cell) {
                        return (
                            <div className='flex-center'>
                                <button className='button-in-table-cell'>Төлбөр төлөх</button>
                            </div>
                        )
                    },
                    footer: '',
                },
            ]);
        } else if (key == '#OTHER'){
            return([
                {
                    dataField: 'date',
                    text: t('common.date'),
                    sort: true,
                    footer: '',
                },
                {
                    dataField: 'type',
                    text: t('common.type'),
                    sort: true,
                    footer: '',
                },
                {
                    dataField: 'description',
                    text: t('common.description'),
                    sort: true,
                    footer: '',
                },
                {
                    dataField: 'income',
                    text: t('common.amount'),
                    sort: true,
                    footerAlign: 'right',
                    footer: '',
                    footerType: 'sum'
                },
                {
                    dataField: 'paid',
                    text: '',
                    headerStyle: () => {
                        return {
                            width: 150
                        }
                    },
                    formatter(cell) {
                        return (
                            <div className='flex-center'>
                                <button className='button-in-table-cell'>Төлбөр төлөх</button>
                            </div>
                        )
                    },
                    footer: '',
                },
            ]);
        }
        else {
            return([]);
        }
    };

    const handleTab = (key, e) => {
        if(key){
            setKey(key);
        }
    };

    return (
        <div className='sm-container'>
            <Card className='card-header-with-tab'>
                <Card.Header>
                    {
                        types && types.length > 0
                            ?
                            <Nav
                                variant="tabs"
                                activeKey={key}
                                onSelect={(key, e) => handleTab(key, e)}
                            >
                                {
                                    types.map(type => {
                                        if(type.id == 'OTHER'){
                                            if(hasOther){
                                                return (
                                                    <Nav.Item key={'type_' + type.id}>
                                                        <Nav.Link href={`#${type.id}`}>{type.name}</Nav.Link>
                                                    </Nav.Item>
                                                )
                                            }
                                        } else {
                                            return (
                                                <Nav.Item key={'type_' + type.id}>
                                                    <Nav.Link href={`#${type.id}`}>{type.name}</Nav.Link>
                                                </Nav.Item>
                                            )
                                        }
                                    })
                                }
                            </Nav>
                            : null
                    }
                </Card.Header>
                <Card.Body>
                    {
                        key &&
                        <DTable
                            columns={column(key)}
                            config={config}
                            data={tableDatas}
                            selectMode={'radio'}
                        />
                    }
                </Card.Body>
            </Card>
            {
                studentId &&
                <ViewPayment onOpen={false} />
            }
        </div>
    )
};
