import React, { useState, useEffect } from 'react';
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import {
    educationLevelAdd,
    educationLevelDelete,
    educationLevelEdit,
    educationLevelReorder,
} from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import DTable from "../../../modules/DataTable/DTable";
import EducationLevelAdd from "./EducationLevelAdd";
import { getLastOrderNumber } from "../../../../utils/utils";
import DeleteModal from "../../../modules/DeleteModal";

const EducationLevel = () => {
    const [ levels, setLevels ] = useState([]);
    const [ showModal, setShowModal ] = useState(false);
    const [ levelId, setLevelId ] = useState(null);
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        const params = {
            school: schoolId
        };
        dispatch(setLoading(true));
        fetchRequest(educationLevelAdd, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;

                    if (data?.levels?.length) {
                        setLevels(data.levels);
                    }
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            })
    }, []);

    const handleAddClick = () => {
        setShowModal(true);
    };

    const onModalClose = () => {
        setShowModal(false);
        setLevelId(null);
    };

    const config = {
        showPagination: false,
        showFilter: false,
        showAllData: true
    };

    const columns = [
        {
            dataField: "code",
            text: t('common.code'),
            sort: true,
        },
        {
            dataField: "name",
            text: t('curriculum.educationLevel'),
            sort: true
        },
        {
            dataField: "ordering",
            text: t('common.ordering'),
            style: {textAlign: 'right'},
            sort: true
        },
    ];

    const contextMenus = [
        {
            key: 'edit',
            title: t('common.edit'),
            icon: <i className='las la-edit dt-cm-item-icon'/>
        },
        {
            key: 'delete',
            title: t('common.delete'),
            icon: <i className='las la-trash-alt dt-cm-item-icon' />,
        },
    ];

    const handleContextMenuClick = (id, key) => {
        if (id && key) {
            if (key === 'edit') {
                setLevelId(id);
                setShowModal(true);
            }
            if (key === 'delete') {
                setLevelId(id);
            }
        }
    };

    const onSubmit = params => {
        const url = levelId ? educationLevelEdit : educationLevelAdd;

        const bodyParams = {
            ...params,
            school: schoolId
        };

        if (!levelId) {
            bodyParams.ordering = getLastOrderNumber(levels) + 1;
        } else {
            bodyParams.educationLevel = levelId;
            const level = levels.find(lvl => lvl.id === levelId);
            bodyParams.ordering = level?.ordering || getLastOrderNumber(levels) + 1;
        }
        dispatch(setLoading(true));
        fetchRequest(url, 'POST', bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res;

                    setLevels(data?.levels ||[]);

                    message(data?.message || t('common.success'), true);

                    onModalClose();
                } else {
                    message(res?.data?.message || t('errorMessage.title'));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const onDelete = () => {
        const params = {
            school: schoolId,
            educationLevel: levelId
        };
        dispatch(setLoading(true));
        fetchRequest(educationLevelDelete, 'POST', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setLevels(data?.levels ||[]);
                    message(data?.message || t('common.success'), true);
                    onModalClose();
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const onDrop = drop => {
        const formData = new FormData();

        formData.append('school', schoolId);
        formData.append('item', drop.itemId);
        formData.append('target', drop.targetId);

        dispatch(setLoading(true));
        fetchRequest(educationLevelReorder, 'POST', formData, false, true)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setLevels(data.levels ? data.levels : []);
                    message(res?.data?.message || t('common.success'), true);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    return (
        <Card>
            <Card.Body>
                <button
                    className='btn btn-primary'
                    onClick={handleAddClick}
                >
                    {t('common.add')}
                </button>
                <DTable
                    draggable
                    columns={columns}
                    config={config}
                    data={levels}
                    contextMenus={contextMenus}
                    onContextMenuItemClick={handleContextMenuClick}
                    onDrop={onDrop}
                    selectMode={'radio'}
                />
                {
                    showModal && (
                        <EducationLevelAdd
                            onClose={onModalClose}
                            onSubmit={onSubmit}
                            id={levelId}
                        />
                    )
                }
                {
                    !showModal && levelId && (
                        <DeleteModal
                            title={t('curriculum.educationLevel')}
                            onDelete={onDelete}
                            onClose={onModalClose}
                        >
                            {t('warning.delete_confirmation')}
                            <br/>
                            <br/>
                            {t('warning.delete_confirmation_description')}
                        </DeleteModal>
                    )
                }
            </Card.Body>
        </Card>
    );
};

export default EducationLevel;