import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import { Card } from "react-bootstrap";
import { fetchRequest } from "../../../../utils/fetchRequest";
import { setLoading } from "../../../../redux/action";
import message from "../../../modules/message";
import {useDispatch, useSelector} from "react-redux";
import { financeNewsfeedInit } from "../../../../utils/fetchRequest/Urls";
import {cloneDeep} from "lodash";
import {linkify} from "../../../../utils/utils";
import ImageGallery from 'react-image-gallery';

export const Newsfeed = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [newsfeeds, setNewsfeeds] = useState([]);
    const [initLoader, setInitLoader] = useState(true);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);

    useEffect(() => {
        if(initLoader && schoolId){
            let params = {
                school: schoolId,
                page: page + 1,
                pageSize: pageSize
            }

            init(params);
        }
    }, [initLoader, schoolId]);

    const init = (params) => {
        dispatch(setLoading(true));
        fetchRequest(financeNewsfeedInit, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    let newsfeedObj = data?.newsfeeds;
                    if (newsfeedObj && newsfeedObj.length > 0) {
                        let array = [];
                        if(newsfeeds && newsfeeds.length > 0){
                            array = cloneDeep(newsfeeds);
                        }

                        for (let i = 0; i < newsfeedObj.length; i++) {
                            if(newsfeedObj[i].body && newsfeedObj[i].body.length > 500){
                                newsfeedObj[i].readMore = true;
                            }
                            array.push(newsfeedObj[i])
                            setInitLoader(false);
                            setPage(data?.page);
                        }
                        setNewsfeeds(array)
                    }
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }

    window.onscroll = () => {
        if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight && !initLoader) {
            setInitLoader(true);
        }
    }

    const toggleNewsContent = index => {
        const cloneNewsfeed = [...newsfeeds];
        if(cloneNewsfeed && cloneNewsfeed.length > 0){
            cloneNewsfeed[index].readMore = false
            setNewsfeeds(cloneNewsfeed);
        }
    };

    const renderBody = (body, readMore, index) => {
        if(body && body.length > 500){
            if(readMore){
                return (
                    <>
                        <div style={{fontSize: 13}} dangerouslySetInnerHTML={ {__html: linkify(body.substring(0, 500), '#5867dd')} }/>
                        <span className='underline-button read-more-position' onClick={() => toggleNewsContent(index)}>
                            {t('common.readMore')}
                        </span>
                    </>
                )
            } else {
                return <div style={{fontSize: 13}} dangerouslySetInnerHTML={ {__html: linkify(body, '#5867dd')} }/>
            }
        } else {
            return <div style={{fontSize: 13}} dangerouslySetInnerHTML={ {__html: linkify(body, '#5867dd')} }/>
        }
    }

    const renderNews = (news) => {
        return news.map((data, index) =>
            <div className='sm-home-news' key={data.id}>
                <p className='deep-bue fs-11' style={index < 1 ? {marginBottom: '0.3rem'} : {marginBottom: '0.3rem', marginTop: '1rem'}}><b>{data.title}</b></p>
                {
                    data.isPublish
                    ?
                        <div style={{
                            display: 'inline-block',
                            color: 'fff',
                            padding: 5,
                            background: '#299f72',
                            borderRadius: 5,
                            marginBottom: '0.5rem'
                        }}>
                            {t('newsfeed.published')}
                        </div>
                    :
                        <div style={{
                            display: 'inline-block',
                            color: 'fff',
                            padding: 5,
                            background: '#919bc0',
                            borderRadius: 5,
                            marginBottom: '0.5rem'
                        }}>
                            {t('newsfeed.notPublished')}
                        </div>
                }
                <p style={{marginBottom: 0}}>
                    <b>{t('newsfeed.published')}: {data.createdDate && data.createdDate.date && data.createdDate.date.substring(0, 19)}, {data?.firstName}{data.schoolId ? ', ' + data.schoolName : ''}</b>
                </p>
                <div className='content'>
                    <div>
                        {
                            data.isCoverPhoto && 
                            <div className='mb-5'>
                                <img src={data.isCoverPhoto} style={{cursor: 'pointer', display: 'flex', justifyContent: 'center', maxWidth: '100%', height: 'auto'}}/>
                            </div>
                        }
                    </div>
                    {renderBody(data?.body, data?.readMore, index)}
                    {
                        data.files && data.files.length > 0 &&
                        <ImageGallery
                            // ref={i => this._imageGallery = i}
                            items={data.files} 
                            showThumbnails={true}
                            thumbnailPosition={'bottom'}
                        />
                    }
                </div>
            </div>
        )
    };

    return (
        <>
            <Card className='mb-4'>
                <Card.Header>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <span className='french-blue fs-10'>
                            <b>{t('newsfeed.title').toUpperCase()}</b>
                        </span>
                    </div>
                </Card.Header>
                <Card.Body>
                    <div>
                        {
                            newsfeeds && newsfeeds.length > 0
                            ?
                                renderNews(newsfeeds)
                            :
                                null
                        }
                    </div>
                </Card.Body>
            </Card>
        </>
    )
};

