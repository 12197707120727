import React, {useEffect, useState} from 'react'
import DTable from '../../../../modules/DataTable/DTable'
import {Button, Card} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {setLoading} from "../../../../../redux/action";
import {fetchRequest} from "../../../../../utils/fetchRequest";
import {teacherOnlineLessonAnnouncementList} from "../../../../../utils/fetchRequest/Urls";
import message from "../../../../modules/message";
import {Link} from "react-router-dom";
import DeleteModal from "../../../../modules/DeleteModal";
import {cloneDeep} from "lodash";
import {linkify} from "../../../../../utils/utils";
import ImageGallery from 'react-image-gallery';

export const TeacherAnnouncement = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [newsfeed, setNewsfeed] = useState([]);
    const [initLoader, setInitLoader] = useState(true);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);

    const [newsId, setNewsId] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        if(initLoader){
            let params = {
                school: schoolId,
                page: page + 1,
                pageSize: pageSize
            }

            init(params);
        }
    }, [initLoader]);

    const init = (params) => {
        dispatch(setLoading(true));
        fetchRequest(teacherOnlineLessonAnnouncementList, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    let newsfeedObj = data?.newsfeed;
                    if (newsfeedObj && newsfeedObj.length > 0) {
                        let array = [];
                        if(newsfeed && newsfeed.length > 0){
                            array = cloneDeep(newsfeed);
                        }

                        for (let i = 0; i < newsfeedObj.length; i++) {
                            if(newsfeedObj[i].body && newsfeedObj[i].body.length > 500){
                                newsfeedObj[i].readMore = true;
                            }

                            array.push(newsfeedObj[i])
                            setInitLoader(false);
                            setPage(data?.page);
                        }
                        setNewsfeed(array)
                    }
                    setIsAdmin(true);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }

    window.onscroll = () => {
        if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight && !initLoader) {
            setInitLoader(true);
        }
    }

    const toggleNewsContent = index => {
        const cloneNewsfeed = [...newsfeed];
        if(cloneNewsfeed && cloneNewsfeed.length > 0){
            cloneNewsfeed[index].readMore = false
            setNewsfeed(cloneNewsfeed);
        }
    };

    const onModalClose = () => {
        setShowModal(false);
        setNewsId(null);
    };

    const onDelete = () => {
        // const bodyParams = {
        //     newsfeed: newsId
        // };
        // dispatch(setLoading(true));
        // fetchRequest(teacherOnlineLessonAnnouncementList, 'POST', bodyParams)
        //     .then(res => {
        //         if (res.success) {
        //             const { data } = res;
        //             message(res?.data?.message || t('common.success'), true);
        //             setNewsfeeds(data?.newsfeeds || []);
        //             onModalClose();
        //         } else {
        //             message(res?.data?.message || t('errorMessage.title'))
        //         }
        //         dispatch(setLoading(false));
        //     })
        //     .catch(() => {
        //         dispatch(setLoading(false));
        //         message(t('errorMessage.title'));
        //     })
    };

    const handlerDeleteClick = (id) => {
        setShowModal(true);
        setNewsId(id);
    };

    const renderBody = (body, readMore, index) => {
        if(body && body.length > 500){
            if(readMore){
                return (
                    <>
                        <div style={{fontSize: 13}} dangerouslySetInnerHTML={ {__html: linkify(body.substring(0, 500), '#5867dd')} }/>
                        <span className='underline-button read-more-position' onClick={() => toggleNewsContent(index)}>
                            {t('common.readMore')}
                        </span>
                    </>
                )
            } else {
                return <div style={{fontSize: 13}} dangerouslySetInnerHTML={ {__html: linkify(body, '#5867dd')} }/>
            }
        } else {
            return <div style={{fontSize: 13}} dangerouslySetInnerHTML={ {__html: linkify(body, '#5867dd')} }/>
        }
    }

    const renderNews = (news) => {
        return news.map((data, index) =>
            <div className='sm-home-news' key={data.id}>
                <p className='fs-10' style={index < 1 ? {marginBottom: '0.3rem'} : {marginBottom: '0.3rem', marginTop: '1rem'}}><b>{data.subjectCode + ' - ' + data.subjectName}</b></p>
                <p className='deep-bue fs-11' style={index < 1 ? {marginBottom: '0.3rem'} : {marginBottom: '0.3rem', marginTop: '1rem'}}><b>{data.title}</b></p>
                {
                    data.isPublish
                    ?
                        <div style={{
                            display: 'inline-block',
                            color: 'fff',
                            padding: 5,
                            background: '#299f72',
                            borderRadius: 5,
                            marginBottom: '0.5rem'
                        }}>
                            {t('newsfeed.published')}
                        </div>
                    :
                        <div style={{
                            display: 'inline-block',
                            color: 'fff',
                            padding: 5,
                            background: '#919bc0',
                            borderRadius: 5,
                            marginBottom: '0.5rem'
                        }}>
                            {t('newsfeed.notPublished')}
                        </div>
                }
                {
                    isAdmin &&
                    <>
                        {
                            !data.isPublish &&
                            <Link
                                to={{
                                    pathname: '/teacher/announcement-edit',
                                    state: {
                                        newsId: data.id
                                    }
                                }}
                            >
                                <button className='btn btn-purple m-btn--icon m-btn--icon-only ml-2'>
                                    <i className='flaticon-edit fs-10'/>
                                </button>
                            </Link>
                        }
                        <button
                            className='btn btn-danger m-btn--icon m-btn--icon-only ml-2'
                            onClick={() => {handlerDeleteClick(data.id)}}
                        >
                            <i className='flaticon2-cross fs-06'/>
                        </button>
                    </>
                }
                <p style={{marginBottom: 0}}>
                    {
                        data.isPublish && 
                        <b>{t('newsfeed.published')}: {data.publishedDate && data.publishedDate.date && data.publishedDate.date.substring(0, 19)}, {data?.firstName}</b>
                    }
                </p>
                <div className='content'>
                    <div>
                        {
                            data.isCoverPhoto && 
                            <div className='mb-5 text-center'>
                                <img src={data.isCoverPhoto} style={{cursor: 'pointer', maxWidth: 600, maxHeight: 600, height: 'auto', width: 'auto'}}/>
                            </div>
                        }
                    </div>
                    {renderBody(data?.body, data?.readMore, index)}
                    {
                        data.files && data.files.length > 0 &&
                        <ImageGallery
                            // ref={i => this._imageGallery = i}
                            items={data.files} 
                            showThumbnails={true}
                            thumbnailPosition={'bottom'}
                        />
                    }
                </div>
            </div>
        )
    };

    return (
        <>
            <div className='mb-4'>
                <Link
                    to={{
                        pathname: '/teacher/announcement-add',
                    }}
                    className="btn btn-primary btn-shadow ml-1"
                >
                    {t('newsfeed.add')}
                </Link>
            </div>
            <Card className='mb-4'>
                <Card.Header>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <span className='french-blue fs-10'>
                            <b>{t('newsfeed.title').toUpperCase()}</b>
                        </span>
                    </div>
                </Card.Header>
                <Card.Body>
                    <div>
                        {
                            newsfeed && newsfeed.length > 0
                            ?
                                renderNews(newsfeed)
                            :
                                null
                        }
                    </div>
                </Card.Body>
            </Card>
            {
                showModal && newsId && (
                    <DeleteModal
                        onClose={onModalClose}
                        onDelete={onDelete}
                        title={t('warning.delete')}>
                        {t('warning.delete_confirmation')}
                        <br />
                        <br />
                        {t('warning.delete_confirmation_description')}
                    </DeleteModal>
                )
            }
        </>
    )
};
