import React,{useEffect,useState} from 'react';
import { useTranslation } from "react-i18next";
import Instruction from "../../../modules/instruction";
import DTable from "../../../modules/DataTable/DTable";
import { Link } from "react-router-dom";
import { fetchRequest } from '../../../../utils/fetchRequest';
import { useHistory } from 'react-router-dom';
import { movementSchoolBetweenAdd,movementSchoolBetweenUndo } from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import { setLoading } from "../../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import BetweenViewModal from './modals/BetweenViewModal';
import UndoModal from './modals/UndoModal';

const Between = ({
    educationLevel = null
}) => {

    const { t } = useTranslation();
    const dispatch = useDispatch()
    const history = useHistory();

    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    const [movements,setMovements] = useState()
    const [totalCount,setTotalCount] = useState(1)
    const [schools,setSchools] = useState([])
    const [viewModal,setViewModal] = useState(false)
    const [viewUndoModal,setViewUndoModal] = useState(false)
    const [tempId,setTempId] = useState()
    const [tableChangeParams,setTableChangeParams] = useState(null)
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [search, setSearch] = useState('');

    const config = {

    }

    const columns = [
        {
            dataField: "type",
            text: t('common.type'),
            formatter(cell) {
                if(typeof cell == 'boolean'){
                    if (cell) {
                        return (
                            <div style={{ display: 'grid', placeItems: 'center' }}>
                                <i className='las la-arrow-alt-circle-right mv-table-icon' style={{ color: '#299f72' }}/>
                            </div>
                        )
                    } else {
                        return (
                            <div style={{ display: 'grid', placeItems: 'center' }}>
                                <i className='las la-arrow-alt-circle-left mv-table-icon' style={{ color: '#36a3f7' }}/>
                            </div>
                        )
                    }
                }else{
                    return null
                }
            }
        },
        {
            dataField: 'date',
            text: t('common.date'),
            sort: true,
            formatter(cell,row) {
                return (
                    <div className='msue-dt-clickable-cell' onClick={()=>onDateClick(row)}>
                        {cell}
                    </div>
                )
            },
        },
        {
            dataField: "studentCode",
            text: t('student.code'),
            sort: true
        },
        {
            dataField: "firstName",
            text: t('student.name'),
            sort: true
        },
        {
            dataField: "toSchool",
            text: t('movement.enrolledSchool'),
            sort: true
        },
        {
            dataField: "toClass",
            text: t('movement.enrolledClass'),
            sort: true
        },
        {
            dataField: "fromSchool",
            text: t('movement.leftSchool'),
            sort: true
        },
        {
            dataField: "fromClass",
            text: t('movement.leftClass'),
            sort: true
        },
        {
            dataField: "createdFirstName",
            text: t('common.createdUser'),
            sort: true
        },
    ];

    const contextMenus = [
        {
            key: 'view',
            title: t('action.view'),
            icon: <i className='las la-eye dt-cm-item-icon' />,
        },
        {
            key: 'edit',
            title: t('common.edit'),
            icon: <i className='las la-edit dt-cm-item-icon'/>
        },
        {
            key: 'undo',
            title: t('action.undo'),
            icon: <i className='las la-backspace dt-cm-item-icon' />,
        },
    ];

    const contextMenuClick = (id,key) =>{
        if(id && key){
            if(key === 'edit'){
                history.push({
                    pathname: '/movement/add-ext',
                    state: {
                        movement: id,
                        schools: schools
                    }
                })
            }else if(key === 'undo'){
                setViewUndoModal(true)
                setTempId(id)
            }else if(key === 'view'){
                setViewModal(true)
                setTempId(id)
            }
        }
    }

    const handleTableChange = data => {
        setPage(data.page);
        setPageSize(data.pageSize);
        setSearch(data.search);
        fetchData(data)
        setTableChangeParams(data)
    };

    const onDateClick = row => {
        setViewModal(true)
        setTempId(row.id)
    }

    const onUndo = () =>{
        if(tempId){
            const params = {
                movement: tempId,
                school: schoolId
            }
            dispatch(setLoading(true));
            fetchRequest(movementSchoolBetweenUndo,'POST',params)
                .then(res => {
                    if (res.success) {
                        setViewUndoModal(false)
                        setTempId(null)
                        fetchData(tableChangeParams)
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                        dispatch(setLoading(false))
                    }
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        }
    }

    const fetchData = params => {
        const bodyParams = {
            ...params,            
            school: schoolId,
            educationLevel,
            movementType: 'OUTSIDE',
            page,
            pageSize,
            search
        }
        dispatch(setLoading(true));
        fetchRequest(movementSchoolBetweenAdd, 'GET', bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    const tempData = []
                    if(data?.movements?.length){
                        data?.movements.map(move=>{
                            tempData.push({
                                ...move,
                                date: move.createdDate?.date?.substring(0,19),
                                type: move.fromSchoolId === schoolId ? false : (move.toSchoolId === schoolId ? true : null),
                                contextMenuKeys: move.fromSchoolId === schoolId 
                                    ?  ['view']
                                    : 
                                    (
                                        move.toSchoolId === schoolId 
                                        ? ['view','edit','undo']
                                        : null
                                    )
                            })
                        })
                    }
                    setMovements(tempData)
                    const tempSchools = []                    
                    if(data?.schools?.length){
                        data?.schools.map(sc=>{
                            tempSchools.push({
                                value: sc.id,
                                text: sc.shortName
                            })
                        })
                    }
                    setSchools(tempSchools)
                    setTotalCount(data?.totalCount || 1)
                    dispatch(setLoading(false));
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                    dispatch(setLoading(false));
                }
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    useEffect(() => {
        if(educationLevel){
            fetchData(null)
        }
    }, [ educationLevel,page, pageSize, search])

    return (
        <div>
            <div style={{ display: 'flex',  }}>
                <Link
                    to={{
                        pathname: '/movement/add-ext',
                        state: { 
                            schools: schools,
                            level: educationLevel
                        }
                    }}
                    className='btn btn-primary mr-5'
                >
                    {t('Хүлээн авах')}
                </Link>
                <Instruction

                />
            </div>
            <DTable
                remote
                data={movements}
                config={config}
                columns={columns}
                contextMenus={contextMenus}
                individualContextMenus
                onContextMenuItemClick={contextMenuClick}
                onInteraction={handleTableChange}
                totalDataSize={totalCount}
            />
            {
                viewModal
                ?
                    <BetweenViewModal
                        onClose={()=>{
                            setViewModal(false)
                            setTempId(null)
                        }}
                        movementId={tempId}
                    />
                : null
            }
            {
                viewUndoModal
                ?
                    <UndoModal
                        onClose={()=>{
                            setViewUndoModal(false)
                            setTempId(null)
                        }}
                        onDelete={onUndo}
                    />
                : null
            }
        </div>
    );
};

export default Between;