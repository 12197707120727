import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";

export const validateQuestionForm = (values, t) => {
    const errors = [];
    const rawEditorState = convertToRaw(values.editorState.getCurrentContent())
    const html = draftToHtml(rawEditorState);
    if (!html) {
        errors.push({
            key: 'question',
            message: t('exam.error.enterQuestion')
        })
    }
    if (!values.subjectId) {
        errors.push({
            key: 'subjectId',
            message: t('exam.error.selectSubject')
        })
    }
    if (!values.difficultyId) {
        errors.push({
            key: 'difficultyId',
            message: t('exam.error.selectDifficulty')
        })
    }
    if (!values.questionTypeCode) {
        errors.push({
            key: 'questionTypeCode',
            message: t('exam.error.selectQuestionType')
        })
    }
    if (!values.topicId) {
        errors.push({
            key: 'topicId',
            message: t('exam.error.selectTopic')
        })
    }
    if (!values.score) {
        errors.push({
            key: 'score',
            message: t('exam.error.enterScore')
        })
    }
    if (!/^[0-9]*(\.[0-9]{0,2})?$/.test(values.score)) {
        errors.push({
            key: 'score',
            message: t('exam.error.scoreNotNumber')
        })
    }
    return errors;
}