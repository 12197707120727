import React, {useRef, useEffect, useState} from 'react';
import { Button, Modal } from "react-bootstrap";
import Forms from "../../../modules/Form/Forms";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {getJournalDetail, journalTopicEdit} from "../../../../utils/fetchRequest/Urls";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import message from "../../../modules/message";

const AddTopic = ({
    timetableId,
    topicId,
    onClose,
    onSubmit,
}) => {
    const { t } = useTranslation();
    const formRef = useRef();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [weeks, setWeeks] = useState([]);
    const [topicData, setTopicData] = useState([]);

    useEffect(() => {
        const params = {
            school: schoolId,
            timetable: timetableId,
        };
        init(params)
    }, []);

    const init = (params) => {
        dispatch(setLoading(true));
        if(topicId){
            params.topic = topicId
        }
        fetchRequest(topicId ? journalTopicEdit : getJournalDetail, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setWeeks(data.weeks);
                    let topicData = data.topicData;
                    if(topicData){
                        setTopicData(topicData);
                    }
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    useEffect(() => {
        formRef?.current?.updateFields && formRef.current?.updateFields(fields);
    }, [weeks, topicData]);

    const fields = [
        {
            key: 'week',
            label: t('teacher.week'),
            value: topicData.weekId || null,
            type: 'dropdown',
            required: true,
            options: weeks,
            clearable: true
        },
        {
            key: 'date',
            label: t('common.date'),
            value: topicData?.date || '',
            type: 'date',
            required: true,
            clearable: true,
            dateCustomButton: false,
        },
        {
            key: 'time',
            label: t('common.time'),
            value: topicData?.time || '',
            type: 'number',
            required: true,
            clearable: true,
            dateCustomButton: false,
        },
        {
            key: 'topic',
            label: t('teacher.topic'),
            value: topicData.topicName || '',
            type: 'textArea',
            rows: 5,
            required: true,
            typeStyle: {width: 200},
            style: {verticalAlign: 'text-top'},
            editable: true
        },
    ];

    const onSaveClick = () => {
        const [ isValid, states, values ] = formRef.current.validate();
        if (isValid) {
            if(values.topic){
                if(topicId){
                    values.name = values.topic;
                    values.topic = topicId;
                }
            }
            const params = {
                ...values,
            };
            onSubmit(params);
        }
    };

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('teacher.topicRegistration').toUpperCase()}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Forms
                    ref={formRef}
                    fields={fields}
                />
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className='btn btn-link bolder'>{t('common.back')}</button>
                <Button variant='success btn-shadow' onClick={onSaveClick}>{t('common.save')}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddTopic;