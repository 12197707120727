import React, {useEffect, useState} from 'react'
import { useTranslation } from "react-i18next";
import RenderTableList from "../../../../modules/RenderTableList";
import {useDispatch, useSelector} from "react-redux";
import {setLoading} from "../../../../../redux/action";
import {fetchRequest} from "../../../../../utils/fetchRequest";
import {lmsAnketInit} from "../../../../../utils/fetchRequest/Urls";
import message from "../../../../modules/message";
import {cloneDeep} from "lodash";

export const MainInformation = ({
    studentId
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    const [studentInfoArray, setStudentInfoArray] = useState([]);
    const [closeStudentArray, setCloneStudentArray] = useState([]);
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        let studentArray = [];

        if (schoolId) {
            let params = {
                school: schoolId,
                id: studentId
            };

            dispatch(setLoading(true));
            fetchRequest(lmsAnketInit, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res;
                        let student = data.student;
                        studentArray.push(
                            {
                                code: 'studentCode',
                                name: t('student.code'),
                                value: student?.studentCode,
                                style: {height: 30, verticalAlign: 'text-top'},
                            },
                            {
                                code: 'citizenship',
                                name: t('questionnaire.citizenship'),
                                value: student?.nationality,
                            },
                            {
                                code: 'registrationNumber',
                                name: t('person.registrationNumber'),
                                value: student?.registrationNumber
                            },
                            {
                                code: 'parents',
                                name: t('questionnaire.parents'),
                                value: student?.lastName,
                            },
                            {
                                code: 'firstName',
                                name: t('common.firstName'),
                                value: student?.firstName,
                            },
                            {
                                code: 'age',
                                name: t('questionnaire.age'),
                                value: student?.age,
                            },
                            {
                                code: 'gender',
                                name: t('common.gender'),
                                value: student?.gender,
                            },
                            {
                                code: 'birthDate',
                                name: t('questionnaire.birthDate'),
                                value: student?.birthDate,
                            },
                            {
                                code: 'nationality',
                                name: t('questionnaire.nationality'),
                                value: '-',
                            },
                            {
                                code: 'birthPlace',
                                name: t('questionnaire.birthPlace'),
                                value: '-',
                                style: {height: 30, verticalAlign: 'text-top'},
                            },
                            {
                                code: 'schoolTitle',
                                name: t('school.title'),
                                value: student?.schoolName
                            },
                            {
                                code: 'enrolledProgram',
                                name: t('questionnaire.enrolledProgram'),
                                value: student?.academicProgram
                            },
                            {
                                code: 'programIndex',
                                name: t('questionnaire.programIndex'),
                                value: student?.programIndex
                            },
                            {
                                code: 'programPlan',
                                name: t('questionnaire.programPlan'),
                                value: student?.program
                            },
                            {
                                code: 'preview',
                                name: t('questionnaire.preview'),
                                value: student?.academicProgram,
                                style: {height: 30, verticalAlign: 'text-top'}
                            },
                            {
                                code: 'homeNumber',
                                name: t('questionnaire.homeNumber'),
                                value: student?.homePhoneNumber
                            },
                            {
                                code: 'phone',
                                name: t('questionnaire.phone'),
                                value: student?.phone
                            },
                            {
                                code: 'email',
                                name: t('questionnaire.email'),
                                value: student?.email
                            },
                            {
                                code: 'address',
                                name: t('questionnaire.address'),
                                value: student?.address,
                                type: 'textArea',
                                typeStyle: {width: 200},
                                style: {verticalAlign: 'text-top'},
                                editable: true
                            },
                        );

                        setStudentInfoArray(studentArray);
                        setCloneStudentArray(cloneDeep(studentArray));
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'));
                })
        }
    }, []);

    const onClickEdit = () => {
        setIsEdit(true);
    };

    const onClickSave = () => {
        let address = null;
        if(studentInfoArray && studentInfoArray.length > 0){
            for(let i = 0; i < studentInfoArray.length; i++){
                if(studentInfoArray[i].code == 'address'){
                    address = studentInfoArray[i].value;
                }
            }
        }
        const params = {
            address: address,
        };
        dispatch(setLoading(true));
        fetchRequest(lmsAnketInit, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    let studentArray = [];
                    let student = data.student;

                    studentArray.push(
                        {
                            code: 'studentCode',
                            name: t('student.code'),
                            value: student?.studentCode,
                            style: {height: 30, verticalAlign: 'text-top'},
                        },
                        {
                            code: 'citizenship',
                            name: t('questionnaire.citizenship'),
                            value: student?.nationality,
                        },
                        {
                            code: 'registrationNumber',
                            name: t('person.registrationNumber'),
                            value: student?.registrationNumber
                        },
                        {
                            code: 'parents',
                            name: t('questionnaire.parents'),
                            value: student?.lastName,
                        },
                        {
                            code: 'firstName',
                            name: t('common.firstName'),
                            value: student?.firstName,
                        },
                        {
                            code: 'age',
                            name: t('questionnaire.age'),
                            value: student?.age,
                        },
                        {
                            code: 'gender',
                            name: t('common.gender'),
                            value: student?.gender,
                        },
                        {
                            code: 'birthDate',
                            name: t('questionnaire.birthDate'),
                            value: student?.birthDate,
                        },
                        {
                            code: 'nationality',
                            name: t('questionnaire.nationality'),
                            value: '-',
                        },
                        {
                            code: 'birthPlace',
                            name: t('questionnaire.birthPlace'),
                            value: '-',
                            style: {height: 30, verticalAlign: 'text-top'},
                        },
                        {
                            code: 'schoolTitle',
                            name: t('school.title'),
                            value: student?.schoolName
                        },
                        {
                            code: 'enrolledProgram',
                            name: t('questionnaire.enrolledProgram'),
                            value: student?.academicProgram
                        },
                        {
                            code: 'programIndex',
                            name: t('questionnaire.programIndex'),
                            value: student?.programIndex
                        },
                        {
                            code: 'programPlan',
                            name: t('questionnaire.programPlan'),
                            value: student?.program
                        },
                        {
                            code: 'preview',
                            name: t('questionnaire.preview'),
                            value: student?.academicProgram,
                            style: {height: 30, verticalAlign: 'text-top'}
                        },
                        {
                            code: 'homeNumber',
                            name: t('questionnaire.homeNumber'),
                            value: student?.homePhoneNumber
                        },
                        {
                            code: 'phone',
                            name: t('questionnaire.phone'),
                            value: student?.phone
                        },
                        {
                            code: 'email',
                            name: t('questionnaire.email'),
                            value: student?.email
                        },
                        {
                            code: 'address',
                            name: t('questionnaire.address'),
                            value: student?.address,
                            type: 'textArea',
                            typeStyle: {width: 200},
                            style: {verticalAlign: 'text-top'},
                            editable: true
                        },
                    );

                    setStudentInfoArray(studentArray);
                    setCloneStudentArray(cloneDeep(studentArray));

                    setIsEdit(false);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            });
    };

    const onClickBack = () => {
        setIsEdit(false);
        let cloneOldData = cloneDeep(closeStudentArray);
        setStudentInfoArray(cloneOldData);
    };

    const onInputChange = (val, code, index) => {
        let cloneData = [...studentInfoArray];
        cloneData[index].value = val;
        setStudentInfoArray(cloneData);
    };

    return (
        <div style={{border: '1px solid #4a70ae'}}>
            <div className='text-right'>
                {/*{*/}
                {/*    isEdit*/}
                {/*    ?*/}
                {/*        <>*/}
                {/*            <button className='btn btn-success btn-shadow mt-4 mr-2 mb-4' onClick={onClickSave}>{t('common.save')}</button>*/}
                {/*            <button className='btn btn-link mt-4 mr-5 mb-4' onClick={onClickBack}>{t('common.back')}</button>*/}
                {/*        </>*/}
                {/*    :*/}
                {/*        <button className='btn btn-outline-success mt-4 mr-5 mb-4' onClick={onClickEdit}>{t('common.edit')}</button>*/}
                {/*}*/}
            </div>
            <div className='mb-10 mt-5'>
                <RenderTableList
                    stringArray={studentInfoArray}
                    reverse={'left'}
                    isEdit={isEdit}
                    onInputChange={onInputChange}
                />
            </div>
        </div>
    )
};


