import React, {useEffect, useState} from 'react';
import { useTranslation } from "react-i18next";
import DTable from "../../../../modules/DataTable/DTable";
import { Link } from "react-router-dom";
import {Button, Modal, Row, Col} from "react-bootstrap";
import Forms from "../../../../modules/Form/Forms";
import {setLoading} from "../../../../../redux/action";
import {fetchRequest} from "../../../../../utils/fetchRequest";
import {statementInit, studentSearch, statementConnect} from "../../../../../utils/fetchRequest/Urls";
import message from "../../../../modules/message";
import {useDispatch, useSelector} from "react-redux";
import Select from "../../../../modules/Form/Select";
import RenderTableList from "../../../../modules/RenderTableList";

const NotConnected = ({statements}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    const [modalShow, setModalShow] = useState(false);
    const [modalStatement, setModalStatement] = useState(null);
    const [studentCode, setStudentCode] = useState('');
    const [modalStudent, setModalStudent] = useState(null);
    const [invoices, setInvoices] = useState([]);
    const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);
    const [stringArray, setStringArray] = useState([]);

    useEffect(() => {

    }, []);

    const config = {
        showPagination: true,
        showFilter: true,
        footer: true,
        footerStyle: {backgroundColor: '#ebecf5'}
    };

    const columns = [
        {
            dataField: "tranDate",
            text: t('finance.transactionDate'),
            sort: true,
            footer: ''
        },
        {
            dataField: "amount",
            text: t('finance.incomeAmount'),
            style: {textAlign: 'right'},
            sort: true,
            footer: '',
            footerType: 'sum',
            footerAlign: 'right',
        },
        {
            dataField: "description",
            text: t('finance.description'),
            sort: true,
            footer: ''
        },
        {
            dataField: "type",
            text: '',
            footer: '',
            headerStyle: () => ({
                width: 50,
            }),
            style: {textAlign: 'center'},
            formatter(cell, row, index) {
                return (
                    <Button onClick={() => {onEditClick(cell, row, index)}} className="btn btn-info m-btn m-btn--icon m-btn--icon-only m-btn--circle-28">
                        <i className="flaticon-edit"> </i>
                    </Button>
                )
            }
        },
    ];

    const onEditClick = (e, data) => {
        if (data) {
            let stringArray = [];
            stringArray.push(
                {
                    code: 'transDate',
                    name: t('finance.transactionDate'),
                    value: data?.tranDate
                },
                {
                    code: 'incomeAmount',
                    name: t('finance.incomeAmount'),
                    value: data?.amount
                },
                {
                    code: 'description',
                    name: t('finance.description'),
                    value: data?.description
                },
                {
                    type: 'input',
                    isButton: true,
                    placeholder: t('student.code'),
                    code: 'input',
                    name: t('student.code'),
                    value: null,
                }
            );

            setStringArray(stringArray);
            setModalStatement(data);
            setModalShow(true)
        }
    };

    const onInput = (value, code) => {
        if(code == 'input'){
            setStudentCode(value)
        }
    };

    const onModalClose = () => {
        setModalStatement(null);
        setModalStudent(null);
        setInvoices([]);
        setSelectedInvoiceId(null);
        setModalShow(false);
    };

    const searchStudent = (student) => {
        if (student) {
            let params = {
                school: schoolId,
                code: student,
                invoice: 1
            };
            dispatch(setLoading(true));
            fetchRequest(studentSearch, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res;
                        let allInvoices = [];
                        if (data?.invoices) {
                            for (let i = 0; i < data?.invoices.length; i++) {
                                allInvoices.push({
                                    text: data?.invoices[i]?.name,
                                    value: data?.invoices[i]?.id
                                })
                            }
                        }
                        setInvoices(allInvoices);
                        setModalStudent(data);
                        let stringArray = [];
                        stringArray.push(
                            {
                                code: 'transDate',
                                name: t('finance.transactionDate'),
                                value: modalStatement?.tranDate
                            },
                            {
                                code: 'incomeAmount',
                                name: t('finance.incomeAmount'),
                                value: modalStatement?.amount
                            },
                            {
                                code: 'description',
                                name: t('finance.description'),
                                value: modalStatement?.description
                            },
                            {
                                type: 'input',
                                isButton: true,
                                placeholder: t('student.code'),
                                code: 'input',
                                name: t('student.code'),
                                value: student,
                            },
                            {
                                code: 'lastName',
                                name: t('person.lastName'),
                                value: data?.lastName
                            },
                            {
                                code: 'firstName',
                                name: t('person.firstName'),
                                value: data?.firstName
                            },
                            {
                                code: 'className',
                                name: t('common.className'),
                                value: data?.programName
                            },
                            {
                                code: 'invoice',
                                type: 'select',
                                options: allInvoices,
                                name: '',
                                value: null
                            }
                        );
                        setStringArray(stringArray);
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        }
    };

    const onInvoiceChange = (e, code) => {
        setSelectedInvoiceId(e);
    };

    const connectStatement = () => {
        if (modalStatement && modalStudent && selectedInvoiceId) {
            let bodyParams = {
                school: schoolId,
                statement: modalStatement?.id,
                student: modalStudent?.id,
                invoices: JSON.stringify([{
                    id: selectedInvoiceId,
                    amount: modalStatement?.amount
                }])
            };
            dispatch(setLoading(true));
            fetchRequest(statementConnect, 'POST', bodyParams)
                .then(res => {
                    if (res.success) {
                        const { data } = res;
                        window.location.reload();
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        }
    };

    return (
        <div>
            <DTable
                data={statements}
                columns={columns}
                config={config}
                selectMode={'radio'}
            />

            <Modal
                show={modalShow}
                onHide={onModalClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                            {'Гүйлгээ холбох'}
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        <RenderTableList
                            stringArray={stringArray}
                            onInputChange={onInput}
                            onInputButtonClick={searchStudent}
                            onSelectChange={onInvoiceChange}
                        />
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={onModalClose} className='btn btn-link bolder'>{t('common.back')}</button>
                    <Button variant='success btn-shadow' onClick={connectStatement}>{'Холбох'}</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default NotConnected;