import React, { useState, useEffect } from 'react';
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import { getSeasonTypes, seasonTypeAdd, seasonTypeDelete, seasonTypeEdit } from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import DTable from "../../../modules/DataTable/DTable";
import SeasonTypeAdd from "./SeasonTypeAdd";
import DeleteModal from "../../../modules/DeleteModal";

const SeasonType = () => {

    const [ seasonTypes, setSeasonTypes ] = useState([]);
    const [ showModal, setShowModal ] = useState(false);
    const [ seasonTypeId, setSeasonTypeId ] = useState(null);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setLoading(true));
        fetchRequest(getSeasonTypes, 'GET')
            .then(res => {
                if (res.success) {
                    const { data } = res;

                    if (data?.seasonTypes?.length) {
                        setSeasonTypes(data.seasonTypes);
                    }

                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }, []);

    const handleAddClick = () => {
        setShowModal(true);
    };

    const onModalClose = () => {
        setShowModal(false);
        setSeasonTypeId(null);
    };

    const config = {
        showPagination: false,
        showFilter: false,
    };

    const columns = [
        {
            dataField: "code",
            text: t('common.code'),
            sort: true
        },
        {
            dataField: "name",
            text: t('common.name'),
            sort: true
        },
        {
            dataField: "isAllSeason",
            text: t('school.season.isAllSeason'),
            sort: true
        },
    ];

    const contextMenus = [
        {
            key: 'edit',
            title: t('common.edit'),
            icon: <i className='las la-edit dt-cm-item-icon'/>
        },
        {
            key: 'delete',
            title: t('common.delete'),
            icon: <i className='las la-trash-alt dt-cm-item-icon' />,
        },
    ];

    const handleContextMenuClick = (id, key) => {
        if (id && key) {
            if (key === 'edit') {
                setShowModal(true);
                setSeasonTypeId(id);
            }
            if (key === 'delete') {
                setSeasonTypeId(id);
            }
        }
    };

    const onSubmit = params => {
        const url = seasonTypeId ? seasonTypeEdit : seasonTypeAdd;
        const bodyParams = {
            ...params,
            isAll: params.isAll ? 1 : 0,
        };

        if (seasonTypeId) {
            bodyParams.seasonType = seasonTypeId;
        }

        dispatch(setLoading(true));
        fetchRequest(url, 'POST', bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res;

                    setSeasonTypes(data?.seasonTypes || []);

                    message(data?.message || t('common.success'), true);

                    onModalClose();
                } else {
                    message(res?.data?.message || t('errorMessage.title'));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const tableData = seasonTypes.map(seasonType => {
        return {
            ...seasonType,
            isAllSeason: seasonType.isAll ? t('common.yes') : t('common.no'),
        }
    });

    const onDelete = () => {
        const params = {
            seasonType: seasonTypeId
        };
        dispatch(setLoading(true));
        fetchRequest(seasonTypeDelete, 'POST', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setSeasonTypes(data?.seasonTypes || []);
                    message(data?.message || t('common.success'), true);
                    onModalClose();
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    return (
        <Card>
            <Card.Body>
                <button
                    className='btn btn-primary'
                    onClick={handleAddClick}
                >
                    {t('common.add')}
                </button>
                <DTable
                    columns={columns}
                    config={config}
                    data={tableData}
                    contextMenus={contextMenus}
                    selectMode={'radio'}
                    onContextMenuItemClick={handleContextMenuClick}
                />
                {
                    showModal && (
                        <SeasonTypeAdd
                            onClose={onModalClose}
                            onSubmit={onSubmit}
                            id={seasonTypeId}
                        />
                    )
                }
                {
                    !showModal && seasonTypeId && (
                        <DeleteModal
                            onClose={onModalClose}
                            title={t('school.season.seasonType')}
                            onDelete={onDelete}
                        >
                            {t('warning.delete_confirmation')}
                            <br/>
                            <br/>
                            {t('warning.delete_confirmation_description')}
                        </DeleteModal>
                    )
                }
            </Card.Body>
        </Card>
    );
};

export default SeasonType;