import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import * as person from './reducers/personInfo';
import * as lang from './reducers/locale';
import schoolList from "./reducers/schoolList";
import * as selectedSchool from './reducers/selectedSchool';
import loader from "./reducers/loader";
import languages from "./reducers/languages";
import userMenus from "./reducers/userMenus";
import menuPositions from "./reducers/menuPositions";

export const rootReducer = combineReducers({
    auth: auth.reducer,
    person: person.personInfo,
    locale: lang.locale,
    school: schoolList,
    selectedSchool: selectedSchool.selectedSchool,
    loading: loader,
    languages,
    userMenus,
    menuPositions
});

export function* rootSaga() {
    yield all([auth.saga()]);
}
