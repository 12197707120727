import React, {useEffect, useState} from 'react'
import DTable from '../../../modules/DataTable/DTable'
import {Button, Card} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {setLoading, setAuth} from "../../../../redux/action";
import {fetchRequest, fetchRequestLogin} from "../../../../utils/fetchRequest";
import {getJournalInit} from "../../../../utils/fetchRequest/Urls";
import {msToken} from "../../../../utils/utils";
import message from "../../../modules/message";
import qs from 'qs';
import { useMsal } from "@azure/msal-react";


export const TeacherJournal = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const isLoader = useSelector(state => state?.loading || null);
    const [activeButton, setActiveButton] = useState('subject');
    const [subjects, setSubjets] = useState([]);
    const { instance, accounts, inProgress } = useMsal();
    
    const [days, setDays] = useState([]);
    var timeTable;

    useEffect(() => {
        const params = {
            school: schoolId,
            viewType: activeButton.toUpperCase(),
        };
        init(params)
    }, []);

    const init = (params) => {
        dispatch(setLoading(true));
        fetchRequest(getJournalInit, 'GET', params)
            .then(res => {
                dispatch(setLoading(false));
                if (res.success) {
                    const { data } = res;
                    let subjectList = data.subjects;
                    let dayList = data.days;
                    if(subjectList && subjectList.length > 0){
                        for (let i = 0; i < subjectList.length; i++){
                            let timetables = subjectList[i].timetables;
                            if(timetables && timetables.length > 0) {
                                for (let t = 0; t < timetables.length; t++) {
                                    timetables[t].time = timetables[t].time + ' ' + '(' + timetables[t].startTime + '-' + timetables[t].endTime + ')';
                                }
                            }
                        }
                    }
                    if(dayList && dayList.length > 0){
                        for (let i = 0; i < dayList.length; i++){
                            let timetables = dayList[i].timetables;
                            if(timetables && timetables.length > 0) {
                                for (let t = 0; t < timetables.length; t++) {
                                    timetables[t].time = timetables[t].time + ' ' + '(' + timetables[t].startTime + '-' + timetables[t].endTime + ')';
                                }
                            }
                        }
                    }
                    setDays(dayList);
                    setSubjets(subjectList)
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const config = {
        showPagination: false,
        showFilter: false,
        tableMarginLess: true
    };

    const columnSubject = [
        {
            dataField: 'pTime',
            text: t('subject.title'),
            sort: true,
            headerStyle: () => {
                return {
                    width: 200
                }
            },
        },
        {
            dataField: 'day',
            text: t('common.day'),
            sort: true,
            headerStyle: () => {
                return {
                    width: 130
                }
            },
        },
        {
            dataField: 'time',
            text: t('common.time'),
            sort: true,
            headerStyle: () => {
                return {
                    width: 200
                }
            },
        },
        {
            dataField: 'studentCount',
            text: t('movement.class.studentCount'),
            sort: true,
        },
        {
            dataField: 'journal',
            text: t('teacher.journal'),
            style: {padding: 3, textAlign: 'center', verticalAlign: 'middle'},
            headerStyle: () => {
                return {
                    width: 90
                }
            },
            formatter(cell, row, index) {
                if (cell) {
                    return (
                        <Button onClick={() => {viewClick(cell, row, index)}} className="btn-primary m-btn m-btn--icon m-btn--icon-only btn-custom-cicrle">
                            <i className='far fa-eye'/>
                        </Button>
                    )
                }
            },
        },
        {
            dataField: 'teams',
            text: t('teacher.teams'),
            style: {padding: 3, textAlign: 'center'},
            headerStyle: () => {
                return {
                    width: 100
                }
            },
            formatter(cell, row, index) {
                if (cell) {
                    return (
                        <Button onClick={() => {teamsClick(cell, row, index)}} className="btn-success m-btn btn-teams ">
                            Үүсгэх
                        </Button>
                    )
                }
                else{
                    return (
                        <Button onClick={() => {teamsClick(cell, row, index)}} className="btn-primary m-btn btn-teams">
                            Нэвтрэх
                        </Button>
                    )
                }
            },
        },
        {
            dataField: 'teamMember',
            text: t('teacher.teamMember'),
            style: {padding: 3, textAlign: 'center'},
            headerStyle: () => {
                return {
                    width: 120
                }
            },
            formatter(cell, row, index) {
                if (cell == 1) {
                    return (
                        <Button onClick={() => {teamMemberClick(cell, row, index)}} className="btn-success m-btn btn-teams">
                            Холбох
                        </Button>
                    )
                }
                else if(cell == 2){
                    return (
                        <Button onClick={() => {teamMemberClick(cell, row, index)}} className="btn-primary m-btn btn-teams">
                            Нэвтрэх
                        </Button>
                    )
                }
            },
        },
    ];

    const columnDay = [
        {
            dataField: 'subjectCode',
            text: t('subject.code'),
            sort: true,
        },
        {
            dataField: 'pTime',
            text: t('subject.title'),
            sort: true,
        },
        {
            dataField: 'time',
            text: t('common.time'),
            sort: true,
            headerStyle: () => {
                return {
                    width: 170
                }
            },
        },
        {
            dataField: 'studentCount',
            text: t('movement.class.studentCount'),
            sort: true,
        },
        {
            dataField: 'journal',
            text: t('teacher.journal'),
            style: {padding: 3, textAlign: 'center', verticalAlign: 'middle'},
            headerStyle: () => {
                return {
                    width: 90
                }
            },
            formatter(cell, row, index) {
                if (cell) {
                    return (
                        <Button onClick={() => {viewClick(cell, row, index)}} className="btn-primary m-btn m-btn--icon m-btn--icon-only btn-custom-cicrle">
                            <i className='far fa-eye'/>
                        </Button>
                    )
                }
            },
        },
        {
            dataField: 'teams',
            text: t('teacher.teams'),
            style: {padding: 3, textAlign: 'center', verticalAlign: 'middle'},
            headerStyle: () => {
                return {
                    width: 100
                }
            },
            formatter(cell, row, index) {
                if (cell) {
                    return (
                        <Button onClick={() => {teamsClick(cell, row, index)}} className="btn-primary m-btn m-btn--icon m-btn--icon-only btn-custom-cicrle">
                            <i className='far fa-eye'/>
                        </Button>
                    )
                }
            },
        },
        {
            dataField: 'elearning',
            text: t('teacher.teamMember'),
            style: {padding: 3, textAlign: 'center', verticalAlign: 'middle'},
            headerStyle: () => {
                return {
                    width: 120
                }
            },
            formatter(cell, row, index) {
                if (cell) {
                    return (
                        <Button onClick={() => {teamMemberClick(cell, row, index)}} className="btn-primary m-btn m-btn--icon m-btn--icon-only btn-custom-cicrle">
                            <i className='far fa-eye'/>
                        </Button>
                    )
                }
            },
        },
    ];

    const viewClick = (cell, row) => {
        const url = `/teacher/journal/view?timetable=` + row['id'];
        window.open(url);
    };

    const login = async e => {
        try {
            var loginRequest = {
                scopes: ["user.read", "Team.Readbasic.All","Team.Create", "TeamMember.Read.All", "TeamSettings.Read.All",//]//"mail.send", "calendars.readwrite", "Directory.ReadWrite.All",
                     "GroupMember.Read.All", "Group.Read.All", "Group.ReadWrite.All", "User.Read.All", "Directory.ReadWrite.All", 
                      "TeamMember.ReadWrite.All", "ChannelMember.ReadWrite.All", "EduAssignments.ReadBasic", "EduAssignments.ReadWriteBasic"]
            };
            const loginResponse = await instance.loginPopup(loginRequest);
            var expireDate = loginResponse['expiresOn'];//sessionStorage.getItem('expireDate');
            if(loginResponse !== null){
                let params = {
                    token: loginResponse['accessToken'],
                    email: loginResponse['account']['username'],
                    expireDate: expireDate.toLocaleString()
                };
                sessionStorage.setItem('expireDate', expireDate.toLocaleString());
                dispatch(setLoading(true));
                fetchRequestLogin('auth/msLogin', 'POST', qs.stringify(params))
                    .then(response => {
                        if (response.success) {
                            dispatch(setAuth(response.data.user.token));
                            teamsClick(null,null,null,true);
                        } else {
                            message(t('auth.loginError'), false)
                            dispatch(setLoading(false));
                        }  
                    });
            }
        } catch (err) {
            // handle error
        }
    };

    const loginMember = async e => {
        try {
            var loginRequest = {
                scopes: ["user.read", "Team.Readbasic.All","Team.Create", "TeamMember.Read.All", "TeamSettings.Read.All",//]//"mail.send", "calendars.readwrite", "Directory.ReadWrite.All",
                     "GroupMember.Read.All", "Group.Read.All", "Group.ReadWrite.All", "User.Read.All", "Directory.ReadWrite.All", 
                      "TeamMember.ReadWrite.All", "ChannelMember.ReadWrite.All", "EduAssignments.ReadBasic", "EduAssignments.ReadWriteBasic"]
            };
            const loginResponse = await instance.loginPopup(loginRequest);
            var expireDate = loginResponse['expiresOn'];//sessionStorage.getItem('expireDate');
            if(loginResponse !== null){
                let params = {
                    token: loginResponse['accessToken'],
                    email: loginResponse['account']['username'],
                    expireDate: expireDate.toLocaleString()
                };
                //sessionStorage.setItem('expireDate', expireDate.toLocaleString());
                dispatch(setLoading(true));
                fetchRequestLogin('auth/msLogin', 'POST', qs.stringify(params))
                    .then(response => {
                        if (response.success) {
                            dispatch(setAuth(response.data.user.token));
                            teamMemberClick(null,null,null,true);
                        } else {
                            message(t('auth.loginError'), false)
                            dispatch(setLoading(false));  
                            // sessionStorage.setItem('loginMessage', data.data.message);
                        }
                    });
            }
        } catch (err) {
        }
    };

    const teamsClick = (cell, row, index, fromTable = false) => {
        const url = `teacher/journal/teams`;
        const expDate = sessionStorage.getItem("expireDate");
        if(fromTable == false){
            timeTable = row['id'] ;
        } 
        
        let param = {
            school: schoolId,
            timetable: timeTable
        };  
        dispatch(setLoading(true));
        fetchRequest(url, 'POST', qs.stringify(param), false, false, true).then(res => {
                if (res.success) {
                    const { data } = res;
                    window.location.reload();
                    window.open(data.url);
                    dispatch(setLoading(false));    
                }
                else if(res.data.msg == "expired"){
                    login();
                }
                else if(res.data.msg == "error"){
                    message(res.data.description, false);
                    dispatch(setLoading(false));
                } 
                
        });
        // const url = `/teams`;
        // window.open(url);              
        // let param = {
        //     msToken: sessionStorage.getItem("msToken"),
        // };                   
        // let param1 = {
        //     msToken: sessionStorage.getItem("msToken"),
        //     name: 'Test Team edited',
        //     description: 'Test Description edited',
        // };         
        // let param2 = {
        //     msToken: sessionStorage.getItem("msToken"),
        //     team: '774df530-f6ae-4583-8840-6ec365957c99',
        //     name: 'Test Team edited 3',
        //     description: 'Test Description edited 3',
        // };    
        // let paramGroupCreate = {
        //     msToken: sessionStorage.getItem("msToken"),
        //     name: 'Test Group 3',
        //     description: 'Test Group 3 Description',
        // };      
        // let paramChannelCreate = {
        //     msToken: sessionStorage.getItem("msToken"),
        //     teamId: '774df530-f6ae-4583-8840-6ec365957c99',
        //     name: 'Test Channel 1',
        //     description: 'Test Channel 1 Description',
        // };        
        // //Team get test
        // fetchRequest('ms/user/teams', 'GET', qs.stringify(param)).then(res => {
        // });

        // //Team Create test
        // // fetchRequest('ms/user/teams-create', 'POST', qs.stringify(param1)).then(res => {
        // // });

        // //Team detail test
        // fetchRequest('ms/user/teams/774df530-f6ae-4583-8840-6ec365957c99', 'POST', qs.stringify(param)).then(res => {
        // });

        // //Team Edit test
        // // fetchRequest('ms/user/teams-edit', 'POST', qs.stringify(param2)).then(res => {
        // // });

        // //Team delete test
        // // fetchRequest('ms/user/teams-delete', 'POST', qs.stringify(param2)).then(res => {
        // // });

        // //Group Create test
        // // fetchRequest('ms/user/group-create', 'POST', qs.stringify(paramGroupCreate)).then(res => {
        // // });

        // //group-id:e26021a7-8c88-4a07-b1c3-3ffcaedfd185

        // //Channel Create test
        // fetchRequest('ms/user/channel-create', 'POST', qs.stringify(paramChannelCreate)).then(res => {
        // });

    };

    const teamMemberClick = (cell, row, index, fromTable = false) => {
        const url = `teacher/journal/teams/member`;
        const expDate = sessionStorage.getItem("expireDate");
        
        if(fromTable == false){
            timeTable = row['id'] ;
        } 
        
        let param = {
            //msToken: sessionStorage.getItem("msToken"),
            school: schoolId,
            timetable: timeTable,
            expireDate: expDate
        };  
        dispatch(setLoading(true));
        fetchRequest(url, 'POST', qs.stringify(param), false, false, true).then(res => {
                
                if (res.success) {
                    const { data } = res;
                    window.open(data.url);
                    dispatch(setLoading(false));
                }
                else if(res.data.msg == "expired"){
                    loginMember();
                }
                else if(res.data.msg == "error"){
                    message(res.data.description, false)
                    dispatch(setLoading(false));
                }
                else if(res.data.msg == "new.team" || res.data.msg == "no.channel"){
                    message(res.data.description, false)
                    setTimeout(() => {
                        window.location.reload();
                    }, 800);
                    dispatch(setLoading(false));
                }
                
        });
    };

    const handleButtonsClick = (value) => {
        setActiveButton(value);
        const params = {
            school: schoolId,
            viewType: value.toUpperCase()
        };
        init(params)
    };

    const renderDatatables = (buttonType) => {
        let subjectData = [];
        let dayData = [];
        if(buttonType == 'subject'){
            if(subjects && subjects.length > 0){
                for(let i = 0; i < subjects.length; i++){
                    subjectData.push(
                        <div className='mb-4' key={'subject_' + i}>
                            <div className='french-blue fs-11'>
                                <b>{subjects[i].code + ' ' + subjects[i].name}</b>
                            </div>

                            <DTable
                                config={config}
                                data={subjects[i].timetables}
                                columns={columnSubject}
                                selectMode={'radio'}
                            />
                        </div>
                    );
                }

                return(
                    subjectData
                )
            } else {
                if(!isLoader){
                    return(
                        <div>{t('subject.notCreated')}</div>
                    )
                }
            }
        } else if (buttonType == 'day'){
            if(days && days.length > 0){
                for(let i = 0; i < days.length; i++){
                    if(days[i].timetables && days[i].timetables.length > 0){
                        dayData.push(
                            <div className='mb-4' key={'subject_' + i}>
                                <div className='french-blue fs-11'>
                                    <b>{days[i].day}</b>
                                </div>

                                <DTable
                                    config={config}
                                    data={days[i].timetables}
                                    columns={columnDay}
                                    selectMode={'radio'}
                                />
                            </div>
                        );
                    }
                }

                return(
                    dayData
                )
            } else {
                if(!isLoader){
                    return(
                        <div>{t('subject.notCreated')}</div>
                    )
                }
            }
        }
    };

    return (
        <div className='sm-container'>
            <Card>
                <Card.Body>
                    <div className='row'>
                        <div className='col-12 mb-8'>
                            <Button
                                className={activeButton === 'subject' ? "btn btn-warning-custom active btn-shadow mr-3" : "btn btn-warning-custom btn-shadow mr-3"}
                                onClick={() => handleButtonsClick('subject')}
                            >
                                <b>{t('timetable.bySubject').toUpperCase()}</b>
                            </Button>
                            <Button
                                className={activeButton === 'day' ? "btn btn-warning-custom active btn-shadow" : "btn btn-warning-custom btn-shadow"}
                                onClick={() => handleButtonsClick('day')}
                            >
                                <b>{t('timetable.byDay').toUpperCase()}</b>
                            </Button>
                        </div>
                        <div className='col-12'>
                            {renderDatatables(activeButton)}
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </div>
    )
};
