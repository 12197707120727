import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { LinearProgress } from "@material-ui/core";
import { useSelector } from 'react-redux';

const ReportList = ({
    list = []
}) => {
    const school = useSelector(state => state?.selectedSchool || null);
    const [total,setTotal] = useState(0)

    useEffect(()=>{
        if(list.length) {
            setTotal(()=>{
                let total = 0
                list.map(obj=>{
                    total = total + obj.counted
                })
                return total
            })
        } else {
            setTotal(0)
        }
    },[list])

    const renderList = () => {
        return list.map((obj,index)=>{
            return <Card key={index} className='mt-2'>
                <Card.Body>
                    <Row className='d-flex justify-content-between'>
                        <Col md={3}>
                        <span className="symbol-label font-size-h5">{obj.name || ''}</span>
                        </Col>
                        <Col md={6}>
                            <LinearProgress variant='determinate' value={obj.counted > 0 ? ((100 * obj.counted)/total) : 0} className='msue-selection-progress' />
                            <span className="symbol-label mt-1">{obj.counted > 0 ? ((100 * obj.counted)/total).toFixed(2) : 0}%</span>
                        </Col>
                        <span className="symbol-label french-blue font-size-h4 font-weight-bold">{obj.counted}</span>
                    </Row>
                </Card.Body>
            </Card>
        })
    }

    return (
        <>
            <Card className='mt-2'>
                <Card.Body>
                    <Row className='d-flex justify-content-between'>
                        <Col md={3}>
                            <span className="symbol-label font-size-h5">{school.longName || ''}</span>
                        </Col>
                        <span className="symbol-label french-blue font-size-h4 font-weight-bold">{total}</span>
                    </Row>
                </Card.Body>
            </Card>
            {renderList()}
        </>
    )
}

export default ReportList
