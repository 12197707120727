import React,{useEffect,useState, useRef} from 'react'
import {Button, Modal} from "react-bootstrap";
import { fetchRequest } from 'utils/fetchRequest';
import { teacherTimeAdditionalAdd, teacherTimeAdditionalSubjectSearch } from 'utils/fetchRequest/Urls';
import { useDispatch,useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setLoading } from "redux/action";
import message from 'app/modules/message';
import Forms from 'app/modules/Form/Forms';

const AddModal = ({
    onClose = () => {},
    onSubmit = () => {},
    seasonId = null
}) => {
    const { t } = useTranslation();
    const formRef = useRef();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    const [types, setTypes] = useState([])
    const [subjects, setSubjects] = useState([])

    const [typeId, setTypeId] = useState(null)
    const [subjectId, setSubjectId] = useState(null)
    const [studentNumber, setStudentNumber] = useState(null)
    const [credit, setCredit] = useState(null)
    const [description, setDescription] = useState(null)
    const [subjectValue, setSubjectValue] = useState(null)

    useEffect(()=>{
        if(seasonId){
            const params = {
                school: schoolId,
                season: seasonId
            }
            dispatch(setLoading(true));
            fetchRequest(teacherTimeAdditionalAdd, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res;

                        setTypes(data?.types || []);

                        dispatch(setLoading(false));
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                        dispatch(setLoading(false));
                    }
                })
                .catch(err => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        }
    },[seasonId])

    const onTypeChange = (value) => {
        setTypeId(value)
    }

    const onSubjectChange = (id, evt) => {
        setSubjectId(id)
    };

    const handlerInputSubject = (value) => {
        setSubjectValue(value)
    }

    useEffect(() => {
        let timeout;
        if (subjectValue) {
            timeout = setTimeout(() => {
                let params = {
                    text: subjectValue
                };

                dispatch(setLoading(true));
                fetchRequest(teacherTimeAdditionalSubjectSearch, 'GET', params)
                    .then(res => {
                        if (res.success) {
                            const { data } = res;
                            setSubjects(data.subjects);
                        } else {
                            message(res?.data?.message || t('errorMessage.title'))
                        }
                        dispatch(setLoading(false));
                    })
                    .catch(() => {
                        dispatch(setLoading(false));
                        message(t('errorMessage.title'))
                    })
            }, 1000);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [subjectValue]);

    const onNumberChange = (evt) => {
        setStudentNumber(evt.target.value)
    }

    const onCreditChange = (evt) => {
        setCredit(evt.target.value)
    }

    const onDescriptionChange = (evt) => {
        setDescription(evt.target.value)
    }

    const fields = [
        {
            key: 'type',
            label: t('common.type'),
            value: typeId || null,
            type: 'dropdown',
            options: types,
            clearable: true,
            searchable: true,
            required: true,
            errorMessage: t('errorMessage.selectScoreTemplate'),
            onChange: onTypeChange,
        },
        {
            key: 'subject',
            label: t('subject.title'),
            value: subjectId,
            type: 'dropdown',
            options: subjects,
            clearable: true,
            searchable: true,
            required: true,
            errorMessage: t('errorMessage.selectScoreTemplate'),
            onChange: onSubjectChange,
            onInputChange: handlerInputSubject,
        },
        {
            key: 'studentNumber',
            label: t('student.number'),
            value: studentNumber || '',
            type: 'number',
            required: true,
            errorMessage: t('errorMessage.selectScoreTemplate'),
            onChange: onNumberChange,
        },
        {
            key: 'credit',
            label: t('subject.credit'),
            value: credit || '',
            type: 'number',
            required: true,
            errorMessage: t('errorMessage.selectScoreTemplate'),
            onChange: onCreditChange,
        },
        {
            key: 'description',
            label: t('common.description'),
            value: description || '',
            type: 'textArea',
            errorMessage: t('errorMessage.selectScoreTemplate'),
            onChange: onDescriptionChange,
        },
    ];

    useEffect(() => {
        formRef?.current?.updateFields && formRef.current?.updateFields(fields);
    }, [types, subjects]);

    const handlerSubmit = () => {
        let params = {
            season: seasonId,
            type: typeId,
            subject: subjectId,
            number: studentNumber,
            credit: credit,
            description: description,
        }

        onSubmit(params)
    }

    return (
        <>
            <Modal
                show={true}
                onHide={onClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                            {t('teacher.timeAdditional')}
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row mt-4'>
                        <div className='col-12'>
                            <Forms
                                ref={formRef}
                                fields={fields}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={onClose} className='btn btn-link bolder'>{t('common.back')}</button>
                    <button
                        onClick={() => handlerSubmit()}
                        className='btn btn-success'
                    >
                        {t('common.save')}
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AddModal