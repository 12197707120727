/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState, useRef} from "react";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { makeStyles } from '@material-ui/core';
import { loadCSS } from 'fg-loadcss';
import { Button, Hidden, List, ListItem, ListItemText, TextField, Tooltip, Typography } from '@material-ui/core';
import { Modal } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import {fetchRequest, fetchRequestLogin, fetchRequestNoToken} from '../../../../utils/fetchRequest';
import qs from 'qs';
import {
    setAuth,
    setInitData,
    setLanguages, setMenuPositions,
    setPersonInfo,
    setSelectedSchool,
    setUserMenus
} from "../../../../redux/action";
import {Redirect, Route, useHistory} from "react-router-dom";
import message from "../../../modules/message";
import { useMsal } from "@azure/msal-react";
import {} from '@azure/msal-browser';
import clsx from 'clsx';
import {setLoading} from "../../../../redux/action";
import ReactPlayer from 'react-player/youtube';
import { FacebookProvider, Page } from 'react-facebook';
import Forms from "../../Form/Forms";
import {mainInit} from "../../../../utils/fetchRequest/Urls";
import {useLang} from "../../../../_metronic/i18n";
import SchoolComponents from "../../../pages/structure/schoolComponent";

const useStyles = makeStyles(theme => ({
    input: {
        backgroundColor: theme.palette.common.white,
    },
    textField: {
        backgroundColor: 'transparent'
    },
    button: {
        padding: '8px 60px',
        width: '70%',
        height: '45px'
    },
    buttonMicrosoft: {
        padding: '8px 20px',
        width: '70%',
        height: '45px'
    },
    icon: {
        margin: theme.spacing(2),
    },
    iconHover: {
        margin: theme.spacing(2),
        '&:hover': {
            color: '#f00',
        },
    },
    textRed: {
        color: '#d84434',
        fontWeight: 'bold'
    },
    success: {
        color: '#fff',
        boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        backgroundColor: '#299f72',
        '&:hover': {
            backgroundColor: '#207f5b',
        },
    },
    marginRight: {
        marginRight: '5px'
    },
    shadow: {
        boxShadow: '0px 3px 2px 1px rgb(28 68 111 / 5%), 0px 2px 2px 0px rgb(28 68 111 / 5%), 0px 1px 5px 0px rgb(28 68 111 / 30%)'
    }
}));

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || "i18nConfig";

export function AuthPage() {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const locale = useLang();
    const [loginName, setLoginName] = useState('');
    const [loginNameError, setLoginNameError] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [registerNumber, setRegisterNumber] = useState('');
    const [registerError, setRegisterError] = useState(false);
    const [showForgotModal, setShowForgotModal] = useState(false);
    const { instance, accounts, inProgress } = useMsal();
    const [ height, setHeight ] = useState(0);
    const [ width, setWidth ] = useState(0);
    const [emailValue, setEmailValue] = useState(null);
    const [phoneValue, setPhoneValue] = useState(null);
    const ref = useRef(null);
    const formRef = useRef();

    useEffect(() => {
        loadCSS(
            'https://use.fontawesome.com/releases/v5.1.0/css/all.css',
            document.querySelector('#font-awesome-css'),
        );

        if(window && window.innerHeight){
            setHeight(ref?.current?.offsetHeight);
            setWidth(parseInt(window.innerWidth))
        }
    }, []);

    useEffect(() => {
        if(ref?.current){
            setHeight(ref?.current?.offsetHeight);
        }
    }, [ref?.current?.offsetHeight]);

    useEffect(() => {
        if(emailValue && emailValue !== ''){
            if(fields && fields.length > 0){
                for(let i = 0; i < fields.length; i++){
                    if(fields[i]['key'] === 'phone'){
                        fields[i]['disabled'] = true;
                        fields[i]['required'] = false;
                        fields[i]['value'] = null;
                    } else {
                        fields[i]['required'] = true;
                    }
                }
            }
        }

        formRef?.current?.updateFields && formRef.current.updateFields(fields);
    }, [emailValue]);

    useEffect(() => {
        if(phoneValue && phoneValue !== ''){
            if(fields && fields.length > 0){
                for(let i = 0; i < fields.length; i++){
                    if(fields[i]['key'] === 'email'){
                        fields[i]['disabled'] = true;
                        fields[i]['required'] = false;
                        fields[i]['value'] = null;
                    } else {
                        fields[i]['required'] = true;
                    }
                }
            }
        }

        formRef?.current?.updateFields && formRef.current.updateFields(fields);
    }, [phoneValue]);

    const login = async e => {
        try {
            var loginRequest = {
                scopes: ["user.read", "Team.Readbasic.All","Team.Create", "TeamMember.Read.All", "TeamSettings.Read.All",//]//"mail.send", "calendars.readwrite", "Directory.ReadWrite.All",
                     "GroupMember.Read.All", "Group.Read.All", "Group.ReadWrite.All", "User.Read.All", "Directory.ReadWrite.All", 
                      "TeamMember.ReadWrite.All", "ChannelMember.ReadWrite.All", "EduAssignments.ReadBasic", "EduAssignments.ReadWriteBasic"]
            };
            const loginResponse = await instance.loginPopup(loginRequest);
            var expDate = loginResponse['expiresOn'].toLocaleString()
            if(loginResponse !== null){
                let params = {
                    token: loginResponse['accessToken'],
                    email: loginResponse['account']['username'],
                    expireDate: expDate
                };
                //sessionStorage.setItem('expireDate',loginResponse['expiresOn'].toLocaleString());
                sessionStorage.setItem('expireDate',expDate);

                fetchRequestLogin('auth/msLogin', 'POST', qs.stringify(params))
                    .then(response => {
                        if (response.success) {
                            dispatch(setAuth(response.data.user.token)
                            );
                            let userRoles = response.data.user.userRoles;
                            fetchRequest(mainInit, 'GET', [])
                                .then(response => {
                                    if (response.success) {
                                        if (response.data && response.data.user) {
                                            dispatch(setPersonInfo(response.data.user))
                                        }
                                        if(response?.data?.userRoles?.length > 0){
    
                                        }
                                        if (response.data && response.data.user && response.data.user.locale !== locale) {
                                            localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({ selectedLang: response.data.user.locale }));
                                        }
                                        if (response.data && response.data.schools && response.data.schools.length > 0) {
                                            dispatch(setInitData(response.data));
                                            dispatch(setSelectedSchool(response.data.schools[0]))
                                        }
                                        if (response?.data?.languages?.length) {
                                            dispatch(setLanguages(response.data.languages));
                                        }
                                         if(response?.data?.userMenus?.length > 0){
                                            dispatch(setUserMenus(response.data.userMenus));
                                        }
                                        if(response?.data?.menuPositions?.length > 0){
                                            dispatch(setMenuPositions(response.data.menuPositions));
                                        }
                                    } else {
    
                                    }
                                });
    
                            let hasStudentRole = false;
                            let hasTeacherRole = false;
                            let hasFinanceRole = false;
                            if(userRoles && userRoles.length > 0){
                                for (let i = 0; i < userRoles.length; i++){
                                    if(userRoles[i].code === 'ROLE_STUDENT'){
                                        hasStudentRole = true;
                                    } else if (userRoles[i].code === 'ROLE_TEACHER'){
                                        hasTeacherRole = true;
                                    } else if (userRoles[i].code === 'ROLE_FINANCE'){
                                        hasFinanceRole = true;
                                    } else if (userRoles[i].code === 'ROLE_OFFICER_CANDIDATE'){
                                        hasFinanceRole = true;
                                    }
                                }
                            }
                            if(hasStudentRole){
                                history.push('/student/home')
                            } else if (hasTeacherRole){
                                history.push('/teacher/home')
                            } else if (hasFinanceRole){
                                history.push('/finance/home')
                            } else {
                                history.push('/home')
                            }
                        } else {
                            message(t('auth.loginError'), false)
                            // sessionStorage.setItem('loginMessage', data.data.message);
                        }
                        dispatch(setLoading(false));  
                    });
            }
        } catch (err) {
            // handle error
        }
    };

    const submitLogin = () => {
        setLoginNameError(false);
        setPasswordError(false);
        if (!loginName || loginName.length === 0) {
            setLoginNameError(true)
        }
        if (!password || password.length === 0) {
            setPasswordError(true)
        }
        if(!loginNameError && !passwordError){
            let params = {
                username: loginName,
                password: password,
            };

            dispatch(setLoading(true));
            fetchRequestLogin('auth/login', 'POST', qs.stringify(params))
                .then(response => {
                    if (response.success) {
                        dispatch(setAuth(response.data.user.token));
                        let userRoles = response.data.user.userRoles;
                        fetchRequest(mainInit, 'GET', [])
                            .then(response => {
                                if (response.success) {
                                    if (response.data && response.data.user) {
                                        dispatch(setPersonInfo(response.data.user))
                                    }
                                    if(response?.data?.userRoles?.length > 0){

                                    }
                                    if (response.data && response.data.user && response.data.user.locale !== locale) {
                                        localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({ selectedLang: response.data.user.locale }));
                                    }
                                    if (response.data && response.data.schools && response.data.schools.length > 0) {
                                        dispatch(setInitData(response.data));
                                        dispatch(setSelectedSchool(response.data.schools[0]))
                                    }
                                    if (response?.data?.languages?.length) {
                                        dispatch(setLanguages(response.data.languages));
                                    }
                                    if(response?.data?.userMenus?.length > 0){
                                        dispatch(setUserMenus(response.data.userMenus));
                                    }
                                    if(response?.data?.menuPositions?.length > 0){
                                        dispatch(setMenuPositions(response.data.menuPositions));
                                    }
                                } else {

                                }
                            });

                        let hasStudentRole = false;
                        let hasTeacherRole = false;
                        let hasFinanceRole = false;
                        if(userRoles && userRoles.length > 0){
                            for (let i = 0; i < userRoles.length; i++){
                                if(userRoles[i].code === 'ROLE_STUDENT'){
                                    hasStudentRole = true;
                                } else if (userRoles[i].code === 'ROLE_TEACHER'){
                                    hasTeacherRole = true;
                                } else if (userRoles[i].code === 'ROLE_FINANCE'){
                                    hasFinanceRole = true;
                                }else if (userRoles[i].code === 'ROLE_OFFICER_CANDIDATE'){
                                    hasFinanceRole = true;
                                }
                            }
                        }
                        if(hasStudentRole){
                            history.push('/student/home')
                        } else if (hasTeacherRole){
                            history.push('/teacher/home')
                        } else if (hasFinanceRole){
                            history.push('/finance/home')
                        } else {
                            history.push('/home')
                        }
                    } else {
                        message(t('auth.loginError'), false)
                        // sessionStorage.setItem('loginMessage', data.data.message);
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                });
        }
    };

    const handleSubmit = e => {
        e.preventDefault();
        submitLogin();
    };

    const renderLoginForm = () => {
        return (
            <div className="login-content login-content-form padding-top">
                <div className="row auth-login-area">
                    <div className="col-12">
                        <div>
                            <div className="card card-custom" style={{height: '100%'}} ref={ref}>
                                <div className="card-body">
                                    <div className="text-center">
                                        <h4 className="branch-title" style={{ paddingLeft: 20, paddingRight: 20 }}>{(t('auth.title')).toUpperCase()}</h4>
                                    </div>

                                    <form onSubmit={handleSubmit}>
                                        <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                                            <div className="row">
                                                <div className="col-md-1"/>
                                                <div className="col-md-10">
                                                    <TextField
                                                        label={t('auth.loginName')}
                                                        className={classes.textField + ' col-sm-12'}
                                                        value={loginName}
                                                        onChange={(e) => setLoginName(e.target.value)}
                                                        margin="normal"
                                                        required
                                                        error={loginNameError}
                                                        size="medium"
                                                    />
                                                </div>
                                                <div className="col-md-1"/>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-1"/>
                                                <div className="col-md-10">
                                                    <TextField
                                                        label={t('auth.password')}
                                                        className={classes.textField + ' mt0 col-sm-12'}
                                                        value={password}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                        margin="normal"
                                                        required
                                                        type={'password'}
                                                        error={passwordError}
                                                        size="medium"
                                                    />
                                                </div>
                                                <div className="col-md-1"/>
                                            </div>

                                            <div className="text-center mt10">
                                                <Button variant="contained" color="primary" size={'large'} className={classes.button} onClick={submitLogin}>{t('auth.login')}</Button>
                                            </div>

                                            {/* <div className="text-center mt20"> */}
                                                {/* <Button className={classes.textRed} onClick={() => setShowForgotModal(true)}>{t('auth.forgotPassword')}</Button> */}
                                            {/* </div> */}
                                        </div>
                                        <input type='submit' style={{ display: 'none' }}/>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const _closeForgotModal = () => {
        setShowForgotModal(false);
        setEmailValue(null);
        setPhoneValue(null);
    };

    const handlerEmail = (event) => {
        setEmailValue(event.target.value);
    };

    const handlerPhone = (event) => {
        setPhoneValue(event.target.value);
    };

    let fields = [
        {
            label: t('auth.recoverByEmail'),
            value: emailValue || '',
            type: 'text',
            inputWidth: 250,
            labelWidth: 300,
            required: true,
            errorMessage: t('errorMessage.enterEmail'),
            key: 'email',
            onChange: handlerEmail,
        },
        {
            label: t('auth.recoverByPhone'),
            value: phoneValue || '',
            type: 'numberOnly',
            inputWidth: 250,
            labelWidth: 300,
            required: true,
            errorMessage: t('errorMessage.enterPhoneNumber'),
            key: 'phone',
            onChange: handlerPhone,
        },
    ];

    const onForgotSubmit = () => {
        const [ formValid, formValue ] = formRef.current.validate();

        if (formValid) {
            let formKey = '';
            for(let i = 0; i < formValue.length; i++){
                if(formValue[i]['value']){
                    formKey = formValue[i]['key']
                }
            }

            if(formKey === 'email' || formKey === 'phone'){
                const params = {
                    type: formKey,
                    email: formValue?.[0]?.value,
                    phone: formValue?.[1]?.value,
                };

                dispatch(setLoading(true));
                fetchRequestNoToken('auth/forgot', 'POST', params, false)
                    .then(response => {
                        if (response.success) {
                            if(formKey === 'phone'){
                                history.push('/recover');
                            }
                        }

                        history.push('/recover');
                        _closeForgotModal();
                        message(response.data.message, response.success);
                        dispatch(setLoading(false));
                    })
                    .catch(() => {
                        dispatch(setLoading(false));
                    })
            }
        }
    };

    return (
        <React.Fragment>
            <section id="authWrapper">
                <div className="login-content-absolute row login-content-bg">
                    <Hidden smDown>
                        <div className="col-12" style={{display: 'flex'}}>
                            <div className="col-2"/>
                            <div className="col-8">
                                {
                                    renderLoginForm()
                                }
                            </div>
                            <div className="col-2"/>
                        </div>
                    </Hidden>
                    <Hidden mdUp>
                        <div className="col-12 mm" style={{display: 'flex'}}>
                            <div className="col-2"/>
                            <div className="col-8">
                                {
                                    renderLoginForm()
                                }
                            </div>
                            <div className="col-2"/>
                        </div>
                    </Hidden>
                    {/* <div className="col-12" style={{display: 'table'}}>
                        <div className={'text-center'} style={{display: 'table-cell', verticalAlign: 'bottom'}}>
                            <Typography variant="body2" display="block" gutterBottom className={'mb-8'} style={{color: '#000'}}>
                                {'© 2014 - ' + (new Date().getFullYear()) + ' Зохиогчийн эрхийг Монгол Улсын Боловсролын их сургууль эзэмшинэ.'}
                            </Typography>
                        </div>
                    </div> */}
                </div>
            </section>

            <Modal
                size="lg"
                show={showForgotModal}
                onHide={_closeForgotModal}
                centered
            >
                <Modal.Header closeButton>
                    <div style={{ color: '#4a70ae', fontSize: '1rem' }}>
                        {t('auth.recoverPassword').toUpperCase()}
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Forms
                            ref={formRef}
                            fields={fields}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="text" className={classes.marginRight} onClick={_closeForgotModal}>{t('common.close')}</Button>
                    <Button variant="contained" className={classes.success} onClick={onForgotSubmit}>{t('auth.getRecoverCode')}</Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
}
