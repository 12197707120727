import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { Button, Col, Modal, Row} from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import DTable from 'app/modules/DataTable/DTable'
import { useDispatch } from 'react-redux';
import { setLoading } from 'redux/action'
import { MAIN_URL, fetchRequest } from 'utils/fetchRequest'
import { graduationConfirm, graduationCreate, graduationStudents } from 'utils/fetchRequest/Urls'
import message from 'app/modules/message'
import { isArray } from 'lodash';

const ApproveModal = ({
    open = false,
    onClose = () => {},
    school = null,
    id
}) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const [student,setStudent] = useState({})
    const [list,setList] = useState([])

    const submit = () => {
        try {
            dispatch(setLoading(true));
            
            const params = {
                school,
                id
            }
            fetchRequest(graduationConfirm, 'POST', params)
                .then(res => {
                    if (res.success) {
                        onClose()
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                })
                .catch(() => {
                    message(t('errorMessage.title'))
                })
                .finally(()=>dispatch(setLoading(false)))
        } catch (e) {
           message(t('errorMessage.title'))
        }
    }

    const onDownload = () => {
        window.open(MAIN_URL + 'pdf/exam/result?school=' + school + '&id=' + id, "_blank", "noreferrer");
    }

    const init = () => {
        try {
            dispatch(setLoading(true));
            const params = {
                school,
                id
            }
            fetchRequest(graduationStudents, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const {data = {}} = res
                        setStudent(data)
                        setList(data.seasons)
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                })
                .catch(() => {
                    message(t('errorMessage.title'))
                })
                .finally(()=>dispatch(setLoading(false)))
        } catch (e) {
            message(t('errorMessage.title'))
        }
    }

    useEffect(()=>{
        if(open) {
            init()
        }
    },[open])

    const tableConfig = useMemo(()=>{
        return { 
            showPagination: false,
            showFilter: false,
            showAllData: true
        }
    },[])

    const columns = useMemo(()=>[
        {
            dataField: "subjectCode",
            text: t("subject.code"),
            sort: false,
        },
        {
            dataField: "subjectName",
            text: t("subject.name"),
            sort: false,
        },
        {
            dataField: "credit",
            text: t("subject.credit"),
            sort: false,
        },
        {
            dataField: "studentScore",
            text: t("subject.score"),
            sort: false,
        },
        {
            dataField: "scoreType",
            text: t("subject.scoreType"),
            sort: false,
        },
        {
            dataField: "teacherCode",
            text: t("teacher.code"),
            sort: false,
        },
        {
            dataField: "teacherName",
            text: t("teacher.name"),
            sort: false,
        }
    ],[])

    const studentInfoRow = useMemo(()=>{
        return [
            {
                key: 'schoolName',
                keyEn: 'schoolNameEn',
                keyTr: 'schoolNameTr',
                text: t('school.title')
            },
            {
                key: 'studentCode',
                keyEn: 'codeEn',
                keyTr: 'codeTr',
                text: t('student.code')
            },
            {
                key: 'firstName',
                keyEn: 'firstNameEn',
                keyTr: 'firstNameTr',
                text: t('student.name')
            },
            {
                key: 'lastName',
                keyEn: 'lastNameEn',
                keyTr: 'lastNameTr',
                text: t('student.lastName')
            },
            {
                key: 'academicProgram',
                keyEn: 'curriculumEn',
                keyTr: 'curriculumTr',
                text: t('curriculum.title')
            },
            {
                key: 'lmProgram',
                keyEn: 'professionEn',
                keyTr: 'professionTr',
                text: t('enroll.profession'),
                margin: true
            },
            {
                key: 'totalCredit',
                keyEn: 'creditEn',
                keyTr: 'creditTr',
                text: t('total.credit'),
            },
            {
                key: 'subjects',
                keyEn: 'subjectCountEn',
                keyTr: 'subjectCountTr',
                text: t('graduation.subjectCount'),
            },
            {
                key: 'score',
                keyEn: 'scoreEn',
                keyTr: 'scoreTr',
                text: t('graduation.score'),
            },
            {
                key: 'overallGpa',
                keyEn: 'gpaEn',
                keyTr: 'gpaTr',
                text: t('assessment.gpa'),
            },
        ]
    },[])

    const returnStudentInfo = () => {
        return studentInfoRow.map((obj,index)=>{
            let value = student[obj.key]
            if(obj.key == 'subjects') {
                value = isArray(value) ? value.length : 0
            }
            return <Row key={index} className={obj.margin ? 'mb-3' : ''}>
                <Col md={4} className={'d-flex justify-content-end'}>
                    <label>{obj.text}</label>
                </Col>
                <Col>
                    <Row className='d-flex justify-content-space'>
                        <Col md={4} className='d-flex justify-content-start'>
                            <label className="font-weight-bold">{value}</label>
                        </Col>
                        <Col md={4} className='d-flex justify-content-center'>
                            <label className="font-weight-bold">{student[obj.keyEn]}</label>
                        </Col>
                        <Col md={4} className='d-flex justify-content-end'>
                            <label className="font-weight-bold">{student[obj.keyTr]}</label>
                        </Col>
                    </Row>
                </Col>
            </Row>
        })
    }

    const renderSeasons = () => {
        return list.map(obj=>{
            return <Fragment key={obj.id}>
                <h5 className='font-weight-bold mt-2 ml-3'>{obj.seasonName}</h5>
                <DTable
                    data={obj.exams}
                    config={tableConfig}
                    columns={columns}
                />
            </Fragment>
        })
    }

    return (
        <Modal
            show={open}
            onHide={onClose}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {t('graduation.infoApprovePage')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {returnStudentInfo()}
                {renderSeasons()}
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className="btn btn-link bolder">
                    {t("common.back").toLocaleUpperCase()}
                </button>
                <Button variant="success btn-shadow" onClick={submit}>
                    {t('action.publish')}
                </Button>
                <Button variant="success btn-shadow" onClick={onDownload}>
                    {t('action.download')}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ApproveModal
