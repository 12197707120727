import React, {useRef, useEffect, useState} from 'react';
import {Button, ButtonToolbar, Card} from "react-bootstrap";
import Forms from "../../../../modules/Form/Forms";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {teacherOnlineLessonTopicView} from "../../../../../utils/fetchRequest/Urls";
import { setLoading } from "../../../../../redux/action";
import { fetchRequest } from "../../../../../utils/fetchRequest";
import message from "../../../../modules/message";
import {Link, useHistory} from "react-router-dom";
import DTable from "../../../../modules/DataTable/DTable";
import {AddFileModal} from "./AddFileModal";
import RenderTableList from "../../../../modules/RenderTableList";
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import StarHalfRoundedIcon from '@material-ui/icons/StarHalfRounded';
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';

export const TeacherOnlineLessonEditTopicView = ({
    location: {state: {id = null, syllabusId = null} = {}} = {},
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [groups, setGroups] = useState([]);
    const [labels, setLabels] = useState([]);

    useEffect(() => {
        const params = {
            school: schoolId,
            course: id, 
            syllabus: syllabusId
        };

        dispatch(setLoading(true));
        fetchRequest(teacherOnlineLessonTopicView, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setGroups(data?.groups || []);

                    let labelArray = [];

                    let rank = 0;

                    if(data.topicData && data.topicData.rate > 0){
                        rank = data.topicData.rate;
                    }

                    let starsArray = [];

                    if(rank == 0 || rank < 0.5){
                        starsArray = [
                            <StarBorderRoundedIcon className='bluey-grey' viewBox="3 3 18 18" preserveAspectRatio="none"/>,
                            <StarBorderRoundedIcon className='bluey-grey' viewBox="3 3 18 18" preserveAspectRatio="none"/>,
                            <StarBorderRoundedIcon className='bluey-grey' viewBox="3 3 18 18" preserveAspectRatio="none"/>,
                            <StarBorderRoundedIcon className='bluey-grey' viewBox="3 3 18 18" preserveAspectRatio="none"/>,
                            <StarBorderRoundedIcon className='bluey-grey' viewBox="3 3 18 18" preserveAspectRatio="none"/>
                        ];
                    } else if(rank >= 0.5 && rank < 1){
                        starsArray = [
                            <StarHalfRoundedIcon className='yellow-orange' viewBox="3 3 18 18" preserveAspectRatio="none"/>,
                            <StarBorderRoundedIcon className='bluey-grey' viewBox="3 3 18 18" preserveAspectRatio="none"/>,
                            <StarBorderRoundedIcon className='bluey-grey' viewBox="3 3 18 18" preserveAspectRatio="none"/>,
                            <StarBorderRoundedIcon className='bluey-grey' viewBox="3 3 18 18" preserveAspectRatio="none"/>,
                            <StarBorderRoundedIcon className='bluey-grey' viewBox="3 3 18 18" preserveAspectRatio="none"/>
                        ];
                    } else if(rank >= 1 && rank < 1.5){
                        starsArray = [
                            <StarRoundedIcon className='yellow-orange' viewBox="3 3 18 18" preserveAspectRatio="none"/>,
                            <StarBorderRoundedIcon className='bluey-grey' viewBox="3 3 18 18" preserveAspectRatio="none"/>,
                            <StarBorderRoundedIcon className='bluey-grey' viewBox="3 3 18 18" preserveAspectRatio="none"/>,
                            <StarBorderRoundedIcon className='bluey-grey' viewBox="3 3 18 18" preserveAspectRatio="none"/>,
                            <StarBorderRoundedIcon className='bluey-grey' viewBox="3 3 18 18" preserveAspectRatio="none"/>
                        ];
                    } else if (rank >= 1.5 && rank < 2){
                        starsArray = [
                            <StarRoundedIcon className='yellow-orange' viewBox="3 3 18 18" preserveAspectRatio="none"/>,
                            <StarHalfRoundedIcon className='yellow-orange' viewBox="3 3 18 18" preserveAspectRatio="none"/>,
                            <StarBorderRoundedIcon className='bluey-grey' viewBox="3 3 18 18" preserveAspectRatio="none"/>,
                            <StarBorderRoundedIcon className='bluey-grey' viewBox="3 3 18 18" preserveAspectRatio="none"/>,
                            <StarBorderRoundedIcon className='bluey-grey' viewBox="3 3 18 18" preserveAspectRatio="none"/>
                        ];
                    } else if (rank >= 2 && rank < 2.5){
                        starsArray = [
                            <StarRoundedIcon className='yellow-orange' viewBox="3 3 18 18" preserveAspectRatio="none"/>,
                            <StarRoundedIcon className='yellow-orange' viewBox="3 3 18 18" preserveAspectRatio="none"/>,
                            <StarBorderRoundedIcon className='bluey-grey' viewBox="3 3 18 18" preserveAspectRatio="none"/>,
                            <StarBorderRoundedIcon className='bluey-grey' viewBox="3 3 18 18" preserveAspectRatio="none"/>,
                            <StarBorderRoundedIcon className='bluey-grey' viewBox="3 3 18 18" preserveAspectRatio="none"/>
                        ];
                    } else if (rank >= 2.5 && rank < 3){
                        starsArray = [
                            <StarRoundedIcon className='yellow-orange' viewBox="3 3 18 18" preserveAspectRatio="none"/>,
                            <StarRoundedIcon className='yellow-orange' viewBox="3 3 18 18" preserveAspectRatio="none"/>,
                            <StarHalfRoundedIcon className='yellow-orange' viewBox="3 3 18 18" preserveAspectRatio="none"/>,
                            <StarBorderRoundedIcon className='bluey-grey' viewBox="3 3 18 18" preserveAspectRatio="none"/>,
                            <StarBorderRoundedIcon className='bluey-grey' viewBox="3 3 18 18" preserveAspectRatio="none"/>
                        ];
                    } else if (rank >= 3 && rank < 3.5){
                        starsArray = [
                            <StarRoundedIcon className='yellow-orange' viewBox="3 3 18 18" preserveAspectRatio="none"/>,
                            <StarRoundedIcon className='yellow-orange' viewBox="3 3 18 18" preserveAspectRatio="none"/>,
                            <StarRoundedIcon className='yellow-orange' viewBox="3 3 18 18" preserveAspectRatio="none"/>,
                            <StarBorderRoundedIcon className='bluey-grey' viewBox="3 3 18 18" preserveAspectRatio="none"/>,
                            <StarBorderRoundedIcon className='bluey-grey' viewBox="3 3 18 18" preserveAspectRatio="none"/>
                        ];
                    } else if (rank >= 3.5 && rank < 4){
                        starsArray = [
                            <StarRoundedIcon className='yellow-orange' viewBox="3 3 18 18" preserveAspectRatio="none"/>,
                            <StarRoundedIcon className='yellow-orange' viewBox="3 3 18 18" preserveAspectRatio="none"/>,
                            <StarRoundedIcon className='yellow-orange' viewBox="3 3 18 18" preserveAspectRatio="none"/>,
                            <StarHalfRoundedIcon className='yellow-orange' viewBox="3 3 18 18" preserveAspectRatio="none"/>,
                            <StarBorderRoundedIcon className='bluey-grey' viewBox="3 3 18 18" preserveAspectRatio="none"/>
                        ];
                    } else if (rank >= 4 && rank < 4.5){
                        starsArray = [
                            <StarRoundedIcon className='yellow-orange' viewBox="3 3 18 18" preserveAspectRatio="none"/>,
                            <StarRoundedIcon className='yellow-orange' viewBox="3 3 18 18" preserveAspectRatio="none"/>,
                            <StarRoundedIcon className='yellow-orange' viewBox="3 3 18 18" preserveAspectRatio="none"/>,
                            <StarRoundedIcon className='yellow-orange' viewBox="3 3 18 18" preserveAspectRatio="none"/>,
                            <StarBorderRoundedIcon className='bluey-grey' viewBox="3 3 18 18" preserveAspectRatio="none"/>
                        ];
                    } else if (rank >= 4.5 && rank < 5){
                        starsArray = [
                            <StarRoundedIcon className='yellow-orange' viewBox="3 3 18 18" preserveAspectRatio="none"/>,
                            <StarRoundedIcon className='yellow-orange' viewBox="3 3 18 18" preserveAspectRatio="none"/>,
                            <StarRoundedIcon className='yellow-orange' viewBox="3 3 18 18" preserveAspectRatio="none"/>,
                            <StarRoundedIcon className='yellow-orange' viewBox="3 3 18 18" preserveAspectRatio="none"/>,
                            <StarHalfRoundedIcon className='yellow-orange' viewBox="3 3 18 18" preserveAspectRatio="none"/>,
                        ];
                    } else if (rank >= 5){
                        starsArray = [
                            <StarRoundedIcon className='yellow-orange' viewBox="3 3 18 18" preserveAspectRatio="none"/>,
                            <StarRoundedIcon className='yellow-orange' viewBox="3 3 18 18" preserveAspectRatio="none"/>,
                            <StarRoundedIcon className='yellow-orange' viewBox="3 3 18 18" preserveAspectRatio="none"/>,
                            <StarRoundedIcon className='yellow-orange' viewBox="3 3 18 18" preserveAspectRatio="none"/>,
                            <StarRoundedIcon className='yellow-orange' viewBox="3 3 18 18" preserveAspectRatio="none"/>,
                        ];
                    }

                    labelArray.push(
                        {
                            code: 'topicName',
                            name: t('teacher.topicName'),
                            value: data?.topicData?.topicName || ''
                        },
                        {
                            code: 'status',
                            name: t('common.status'),
                            value: data?.topicData && data?.topicData.isActive ? t('discussion.open') : t('discussion.close')
                        },
                        {
                            code: 'createdDate',
                            name: t('teacher.createdDate'),
                            value: data?.topicData && data?.topicData.createdDate ? data?.topicData.createdDate : ''
                        },
                        {
                            code: 'totalViews',
                            name: t('teacher.totalViews'),
                            value: data?.topicData.totalView || 0
                        },
                        {
                            code: 'assessment',
                            name: t('assessment.title'),
                            type: 'icon',
                            iconType: 'svg',
                            multiple: 5,
                            iconName: starsArray,
                        }
                    );

                    setLabels(labelArray);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }, []);

    const config = {
        showAllData: true,
        showFilter: true,
        showPagination: false,
    }

    const columns = [
        {
            dataField: "code",
            text: t('student.code'),
            sort: true,
        },
        {
            dataField: "lastName",
            text: t('person.lastName'),
            sort: true,
        },
        {
            dataField: "firstName",
            text: t('person.firstName'),
            sort: true,
        },
        {
            dataField: "viewCount",
            text: t('teacher.views'),
            sort: true,
        },
    ];

    return (
        <div className='sm-container'>
            <Card>
                <Card.Header>
                    <span className='french-blue fs-10'>
                        <b>{t('teacher.topicStatic').toUpperCase()}</b>
                    </span>
                    <div style={{float: 'right'}}>
                        <Link
                            to={{
                                pathname: '/teacher/online/lesson-edit',
                                state: {
                                    courseId: id,
                                }
                            }}
                            className="btn btn-link bolder"
                        >
                            {t('common.back')}
                        </Link>
                    </div>
                </Card.Header>
                <Card.Body>
                    <Card>
                        <Card.Body>
                            <RenderTableList
                                stringArray={labels}
                            />
                        </Card.Body>
                    </Card>

                    <Card className='mt-4'>
                        <Card.Header>
                            <span className='french-blue fs-10'>
                                <b>{t('teacher.seenStudents').toUpperCase()}</b>
                            </span>
                        </Card.Header>
                        <Card.Body>
                            {
                                groups && groups.length > 0
                                ?
                                    groups.map((group, index) => {
                                        return (
                                            <div key={'group_' + index}>
                                                <p>
                                                    <b>{group['name'] + ', ' + group['typeName']}</b>
                                                </p>
                                                <DTable
                                                    data={group['studentList']}
                                                    columns={columns}
                                                    config={config}
                                                    selectMode={'radio'}
                                                />
                                            </div>
                                        )
                                    })
                                : null 
                            }
                        </Card.Body>
                    </Card>
                </Card.Body>
                <Card.Footer>
                    <div className='col-11 text-center'>
                        <Link
                            to={{
                                pathname: '/teacher/online/lesson-edit',
                                state: {
                                    courseId: id,
                                }
                            }}
                            className="btn btn-link bolder"
                        >
                            {t('common.back')}
                        </Link>
                    </div>
                </Card.Footer>
            </Card>

        </div>
    );
};