import React, { useState, useEffect } from 'react';
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import {
    studentStatusConfigAdd,
    studentStatusConfigDelete,
    studentStatusConfigEdit
} from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import DTable from "../../../modules/DataTable/DTable";
import StudentStatusConfigAdd from "./StatusConfigAdd";
import DeleteModal from "../../../modules/DeleteModal";

const StudentStatusConfig = () => {
    const [ statuses, setStatuses ] = useState([]);
    const [ statusConfigs, setStatusConfigs ] = useState([])
    const [ showModal, setShowModal ] = useState(false);
    const [ configId, setConfigId ] = useState(null);
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        const params = {
            school: schoolId
        };
        dispatch(setLoading(true));
        fetchRequest(studentStatusConfigAdd, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;

                    if (data?.statuses?.length) {
                        setStatuses(formatStatuses(data.statuses));
                    }

                    if (data?.statusConfigs?.length) {
                        setStatusConfigs(data.statusConfigs);
                    }

                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }, []);

    const handleAddClick = () => {
        setShowModal(true);
    };

    const onModalClose = () => {
        setShowModal(false);
        setConfigId(null);
    };

    const config = {
        showPagination: false,
        showFilter: false,
        showAllData: true
    };

    const columns = [
        {
            dataField: "fromName",
            text: t('student.status'),
            sort: true
        },
        {
            dataField: "toName",
            text: t('setting.student.enteringStatus'),
            sort: true
        },
    ];

    const contextMenus = [
        {
            key: 'edit',
            title: t('common.edit'),
            icon: <i className='las la-edit dt-cm-item-icon'/>
        },
        {
            key: 'delete',
            title: t('common.delete'),
            icon: <i className='las la-trash-alt dt-cm-item-icon' />,
        },
    ];

    const handleContextMenuClick = (id, key) => {
        if (id && key) {
            setConfigId(id);
            if (key === 'edit') {
                setShowModal(true);
            }
        }
    };

    const formatStatuses = array => {
        return array.map(item => {
            return {
                value: item.id,
                text: item.name
            }
        })
    };

    const onSubmit = params => {
        const url = configId ? studentStatusConfigEdit : studentStatusConfigAdd;
        const formData = new FormData();

        formData.append('school', schoolId);
        formData.append('from', params.from);
        for (const id of params.to) {
            formData.append('to[]', id);
        }

        if (configId) {
            formData.append('config', configId);
        }

        dispatch(setLoading(true));
        fetchRequest(url, 'POST', formData, false, true)
            .then(res => {
                if (res.success) {
                    const { data } = res;

                    message(data?.message || t('common.success'), true);

                    setStatuses(data?.statuses ? formatStatuses(data.statuses) : []);
                    setStatusConfigs(data?.statusConfigs || []);

                    onModalClose();
                } else {
                    message(res?.data?.message || t('errorMessage.title'));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const tableData = statusConfigs.map(config => {
        let toName = '-';

        if (config?.to?.length) {
            toName = '';
            for (const status of config.to) {
                toName = `${toName}${status.name}, `;
            }
            toName = toName.substring(0, toName.length - 2);
        }

        return {
            ...config,
            fromName: config.from?.name || '-',
            toName,
        }
    });

    const onDelete = () => {
        let configFromId = null
        if(tableData && tableData.length > 0){
            for (let i = 0; i < tableData.length; i++){
                if(tableData[i]['id'] == configId){
                    configFromId = tableData[i]['from']['id'];
                }
            }
        }

        if(configFromId){
            const params = {
                school: schoolId,
                config: configFromId,
            };
            dispatch(setLoading(true));
            fetchRequest(studentStatusConfigDelete, 'POST', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res;
                        message(data?.message || t('common.success'), true);
                        setStatuses(data?.statuses ? formatStatuses(data.statuses) : []);
                        setStatusConfigs(data?.statusConfigs || []);
                        onModalClose();
                    } else {
                        message(res?.data?.message || t('errorMessage.title'));
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'));
                })
        } else {
            message(t('errorMessage.title'));
        }
    };

    return (
        <Card>
            <Card.Body>
                <button
                    className='btn btn-primary'
                    onClick={handleAddClick}
                >
                    {t('common.add')}
                </button>
                <DTable
                    columns={columns}
                    config={config}
                    data={tableData}
                    contextMenus={contextMenus}
                    onContextMenuItemClick={handleContextMenuClick}
                    selectMode={'radio'}
                />
                {
                    showModal && (
                        <StudentStatusConfigAdd
                            onClose={onModalClose}
                            onSubmit={onSubmit}
                            statuses={statuses}
                            id={configId}
                        />
                    )
                }
                {
                    !showModal && configId && (
                        <DeleteModal
                            onClose={onModalClose}
                            onDelete={onDelete}
                            title={t('setting.student.status')}
                        >
                            {t('warning.delete_confirmation')}
                            <br/>
                            <br/>
                            {t('warning.delete_confirmation_description')}
                        </DeleteModal>
                    )
                }
            </Card.Body>
        </Card>
    );
};

export default StudentStatusConfig;