import React, { useState, useEffect } from 'react';
import { Card, Tab, Tabs } from "react-bootstrap";
import TreeView from "../../../modules/TreeView";
import { useTranslation } from "react-i18next";
import Internal from "./Internal";
import Between from "./Between";
import { fetchRequest } from "../../../../utils/fetchRequest";
import { setLoading } from "../../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import message from "../../../modules/message";
import { movementInit } from "../../../../utils/fetchRequest/Urls";

const InternalMovement = ({
    location: { state: {
        tab = 'internal'
    } = {} } = {}, }) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    const [educationLevels, setEducationLevels] = useState([])
    const [educationLevelId, setEducationLevelId] = useState(null);
    const [tabKey, setTabKey] = useState('internal');
    const [isClick, setIsClick] = useState(false);
    const [treeIndex] = useState('movement_internal_tree_index');
    const treeId = localStorage.getItem(treeIndex);

    const init = (params) => {
        dispatch(setLoading(true));
        fetchRequest(movementInit, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setEducationLevels(data?.treeData || []);
                    dispatch(setLoading(false));
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                    dispatch(setLoading(false));
                }
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            })
    }

    useEffect(() => {
        const params = {
            school: schoolId
        }

        if (treeId) {
            setEducationLevelId(parseInt(treeId))
        }

        init(params)
    }, []);

    const handleTreeClick = array => {
        if (array?.length) {
            setEducationLevelId(array[0]);
            setIsClick(true);
            localStorage.setItem(treeIndex, parseInt(array[0]));
        }
    };

    const handleTabChange = key => {
        setTabKey(key);
    };

    const renderTabs = () => {
        if (tabKey === 'internal') {
            return (
                <Internal
                    educationLevel={educationLevelId}
                    isClick={isClick}
                />
            )
        }
        return (
            <Between
                educationLevel={educationLevelId}
            />
        )
    }

    return (
        <div className='row'>
            <div className='col-3 p-0'>
                <Card>
                    <Card.Body>
                        <TreeView
                            treeData={educationLevels}
                            selectedNodes={[educationLevelId]}
                            onSelect={handleTreeClick}
                            defaultExpandAll={true}
                        />
                    </Card.Body>
                </Card>
            </div>
            <div className='col-9 pr-0'>
                <Card>
                    <Card.Header>
                        <Tabs
                            id={'msue-mv-internal'}
                            activeKey={tabKey}
                            className='msue-tab'
                            onSelect={handleTabChange}
                        >
                            <Tab eventKey={'internal'} title={t('movement.internal.inSchool')} />
                            <Tab eventKey={'school'} title={t('movement.internal.betweenSchool')} />
                        </Tabs>
                    </Card.Header>
                    <Card.Body>
                        {renderTabs()}
                    </Card.Body>
                </Card>
            </div>
        </div>
    );
};

export default InternalMovement;