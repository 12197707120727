import React, {forwardRef, useEffect, useRef, useState} from 'react'
import {Button, Card, Tab, Tabs} from 'react-bootstrap'
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {setLoading} from "../../../../../redux/action";
import {fetchRequest} from "../../../../../utils/fetchRequest";
import {invoiceInit, statementInit} from "../../../../../utils/fetchRequest/Urls";
import message from "../../../../modules/message";
import DatePickerRange from "../../../../modules/Form/DatePickerRange";
import NotConnected from "./notConnected";
import Connected from "./connected";
import {priceFormat} from "../../../../../utils/utils";

export const IncomeUndefined = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    const [activeButton, setActiveButton] = useState(null);
    const [seasonId, setSeasonId] = useState(null);
    const [tabKey, setTabKey] = useState('notConnected');

    const [statements, setStatements] = useState([]);
    const [bankInfo, setBankInfo] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    useEffect(() => {
        const params = {
            school: schoolId,
            season: seasonId,
            paid: activeButton,
            connectedType: 0
        };
        init(params)
    }, []);

    const init = (params) => {
        dispatch(setLoading(true));
        fetchRequest(statementInit, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    let statements = data.statements;

                    if(statements && statements.length){
                        for(let i = 0; i < statements.length; i++){
                            statements[i]['tranDate'] = statements[i]['tranDate'].date.substring(0, 10);
                            statements[i]['amount'] = priceFormat(statements[i]['amount']);
                        }
                    }

                    setBankInfo(data.banks);
                    setStatements(statements);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    // const init = (params) => {
    //     // dispatch(setLoading(true));
    //     // fetchRequest(invoiceInit, 'GET', params)
    //     //     .then(res => {
    //     //         if (res.success) {
    //     //             const { data } = res;
    //     //         } else {
    //     //             message(res?.data?.message || t('errorMessage.title'))
    //     //         }
    //     //         dispatch(setLoading(false));
    //     //     })
    //     //     .catch(() => {
    //     //         dispatch(setLoading(false));
    //     //         message(t('errorMessage.title'))
    //     //     })
    // };

    const handleButtonsClick = (value) => {
        setActiveButton(value);

        const params = {
            school: schoolId,
            start: startDate,
            end: endDate,
            bank: bankInfo && bankInfo.length > 0 ? bankInfo[0].id : null,
            connectedType: tabKey === 'notConnected' ? 0 : 1
        };
        init(params);
    };

    const handleTabChange = key => {
        setTabKey(key);
        setStatements([]);

        const params = {
            school: schoolId,
            bank: bankInfo && bankInfo.length > 0 ? bankInfo[0].id : null,
            paid: activeButton,
            start: startDate,
            end: endDate,
            connectedType: key === 'notConnected' ? 0 : 1
        };
        init(params)
    };

    const handlerRangePicker = (dates) => {
        if(dates && dates.length > 0){
            setStartDate(dates[0].startDate ? dates[0].startDate : null);
            setEndDate(dates[0].endDate ? dates[0].endDate : null);
        }
    };

    const renderTabs = () => {
        if (tabKey === 'notConnected') {
            return (
                <NotConnected
                    statements={statements}
                />
            )
        } else if (tabKey === 'connected'){
            return (
                <Connected
                    statements={statements}
                />
            )
        }
    };

    return (
        <div className='sm-container'>
            <Card className='mb-4'>
                <Card.Body>
                    <label className="text-lg-right">
                        {t('finance.account') + ': '}
                        <input type="text" disabled className='account ml-2' value={bankInfo && bankInfo.length > 0 ? bankInfo[0].accountNumber : ''}/>
                        <span />
                    </label>
                    <label
                        style={{marginBottom: 0}}
                        className="text-lg-right ml-10">
                        <span className='mr-3'>{t('common.date')}</span>
                        <DatePickerRange
                            onChange={handlerRangePicker}
                            firstPlaceHolder={t('common.startDate')}
                            lastPlaceHolder={t('common.endDate')}
                        />
                    </label>
                    <Button
                        className={"btn btn-warning-custom active btn-shadow ml-3"}
                        onClick={() => handleButtonsClick()}
                    >
                        <b>{t('common.see').toUpperCase()}</b>
                    </Button>
                </Card.Body>
            </Card>
            <Card>
                <Card.Header>
                    <Tabs
                        id={'msue-mv-internal'}
                        activeKey={tabKey}
                        className='msue-tab'
                        onSelect={handleTabChange}
                    >
                        <Tab eventKey={'notConnected'} title={t('common.notConnected')}/>
                        <Tab eventKey={'connected'} title={t('common.connected')}/>
                    </Tabs>
                </Card.Header>
                <Card.Body>
                    {renderTabs()}
                </Card.Body>
            </Card>
        </div>
    )
};
