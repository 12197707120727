import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from "react-i18next";
import { Card, Modal } from "react-bootstrap";
import { fetchRequest } from "../../../../utils/fetchRequest";
import { setLoading } from "../../../../redux/action";
import message from "../../../modules/message";
import { useDispatch, useSelector } from "react-redux";
import { studentNewsfeedInit, newsfeedStudentTranscriptView } from "../../../../utils/fetchRequest/Urls";
import { cloneDeep } from "lodash";
import { linkify } from "../../../../utils/utils";
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import ImageGallery from 'react-image-gallery';
import QRCode from 'qrcode';
import { useReactToPrint } from 'react-to-print'
import { TranscriptSelf } from '../transcript/transcriptSelf';
import { TranscriptScore } from '../transcript/transcriptScore';
import { TranscriptEnScore } from '../transcript/transcriptEnScore';
import { TranscriptEnSelf } from '../transcript/transcriptEnSelf';
import { useHistory } from 'react-router-dom';

export const Newsfeed = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    const printMnRefSelf = useRef();
    const printEnRefSelf = useRef();
    const printMnRefScore = useRef();
    const printEnRefScore = useRef();

    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [newsfeeds, setNewsfeeds] = useState([]);
    const [types, setTypes] = useState([]);
    const [initLoader, setInitLoader] = useState(true);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [key, setKey] = useState(1);
    const person = useSelector(state => state?.person || []);
    const school = useSelector(state => state?.selectedSchool || null);
    const [typeCode, setTypeCode] = useState(null);

    const [studentInfo, setStudentInfo] = useState(null);
    const [mainSchoolData, setMainSchoolData] = useState([null]);
    const [schoolData, setSchoolData] = useState(null);
    const [studentScores, setStudentScores] = useState(null);
    const [qrCode, setQrCode] = useState(null);
    const [totalCredit, setTotalCredit] = useState(0);
    const [gpa, setGpa] = useState(0);

    const [showSurveyModal, setShowSurveyModal] = useState(false);

    useEffect(() => {
        if (initLoader && schoolId) {
            let params = {
                school: schoolId,
                page: page + 1,
                pageSize: pageSize,
                type: typeCode
            }

            init(params);

            if(person.isSurvey){
                setShowSurveyModal(true);
            }
        }
    }, [initLoader, schoolId]);

    const init = (params) => {
        dispatch(setLoading(true));
        fetchRequest(studentNewsfeedInit, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    let newsfeedObj = data?.newsfeeds;
                    if (newsfeedObj && newsfeedObj.length > 0) {
                        let array = [];
                        if (newsfeeds && newsfeeds.length > 0) {
                            array = cloneDeep(newsfeeds);
                        }

                        for (let i = 0; i < newsfeedObj.length; i++) {
                            if (newsfeedObj[i].body && newsfeedObj[i].body.length > 500) {
                                newsfeedObj[i].readMore = true;
                            }
                            array.push(newsfeedObj[i]);
                            setInitLoader(false);
                            setPage(data?.page);
                        }
                        setNewsfeeds(array)
                    }

                    setTotalCredit(data?.totalCredit || 0)
                    setGpa(data?.gpa || 0)
                    setTypes(data?.types || []);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }

    window.onscroll = () => {
        if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight - 200 && !initLoader) {
            setInitLoader(true);
        }
    }

    const toggleNewsContent = index => {
        const cloneNewsfeed = [...newsfeeds];
        if (cloneNewsfeed && cloneNewsfeed.length > 0) {
            cloneNewsfeed[index].readMore = false
            setNewsfeeds(cloneNewsfeed);
        }
    };

    const renderBody = (body, readMore, index) => {
        if (body && body.length > 500) {
            if (readMore) {
                return (
                    <>
                        <div style={{ fontSize: 13 }} dangerouslySetInnerHTML={{ __html: linkify(body.substring(0, 500), '#5867dd') }} />
                        <span className='underline-button read-more-position' onClick={() => toggleNewsContent(index)}>
                            {t('common.readMore')}
                        </span>
                    </>
                )
            } else {
                return <div style={{ fontSize: 13 }} dangerouslySetInnerHTML={{ __html: linkify(body, '#5867dd') }} />
            }
        } else {
            return <div style={{ fontSize: 13 }} dangerouslySetInnerHTML={{ __html: linkify(body, '#5867dd') }} />
        }
    }

    const renderNews = (news) => {
        return news.map((data, index) =>
            <div className='sm-home-news' key={data.id}>
                <p className='deep-bue fs-11' style={index < 1 ? { marginBottom: '0.3rem' } : { marginBottom: '0.3rem', marginTop: '1rem' }}><b>{data.title}</b></p>
                {
                    data.isPublish
                        ?
                        <div style={{
                            display: 'inline-block',
                            color: 'fff',
                            padding: 5,
                            background: '#299f72',
                            borderRadius: 5,
                            marginBottom: '0.5rem'
                        }}>
                            {t('newsfeed.published')}
                        </div>
                        :
                        <div style={{
                            display: 'inline-block',
                            color: 'fff',
                            padding: 5,
                            background: '#919bc0',
                            borderRadius: 5,
                            marginBottom: '0.5rem'
                        }}>
                            {t('newsfeed.notPublished')}
                        </div>
                }
                <p style={{ marginBottom: 0 }}>
                    <b>{t('newsfeed.published')}: {data.createdDate && data.createdDate.date && data.createdDate.date.substring(0, 19)}, {data?.firstName}{data.schoolId ? ', ' + data.schoolName : ''}</b>
                </p>
                <div className='content'>
                    <div>
                        {
                            data.isCoverPhoto &&
                            <div className='mb-5'>
                                <img src={data.isCoverPhoto} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', maxWidth: '100%', height: 'auto' }} />
                            </div>
                        }
                    </div>
                    {renderBody(data?.body, data?.readMore, index)}
                    {
                        data.files && data.files.length > 0 &&
                        <ImageGallery
                            // ref={i => this._imageGallery = i}
                            items={data.files}
                            showThumbnails={true}
                            thumbnailPosition={'bottom'}
                            originalHeight={100}
                            thumbnailWidth={100}
                            thumbnailHeight={100}
                            originalWidth={100}
                        />
                    }
                </div>
            </div>
        )
    };

    const capitalizeFirstLetter = (string) => {
        if (string) {
            return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
        }
    }

    const onHandlerTypeClick = (code) => {
        let params = {
            school: schoolId,
            page: 1,
            pageSize: 10,
            type: code
        }

        dispatch(setLoading(true));
        fetchRequest(studentNewsfeedInit, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    let newsfeedObj = data?.newsfeeds;
                    setNewsfeeds(newsfeedObj);
                    setTypeCode(code);
                    setInitLoader(false);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }

    const handleMnPrintSelf = useReactToPrint({
        suppressErrors: true,
        content: () => printMnRefSelf.current,
        onPrintError: () => { message(t('common.error')) },
        pageStyle: '@page{size: auto!important; margin: 0.5cm 2cm!important}',
        documentTitle: `${t('transcript.studentDescription')}`
    })

    const handleEnPrintSelf = useReactToPrint({
        suppressErrors: true,
        content: () => printEnRefSelf.current,
        onPrintError: () => { message(t('common.error')) },
        pageStyle: '@page{size: auto!important; margin: 0.5cm 2cm!important}',
        documentTitle: `${t('transcript.studentDescription')}`
    })

    const handleMnPrintScore = useReactToPrint({
        suppressErrors: true,
        content: () => printMnRefScore.current,
        onPrintError: () => { message(t('common.error')) },
        pageStyle: '@page{size: auto!important; margin: 0.5cm 1cm!important}',
        documentTitle: `${t('transcript.studentScoreDescription')}`
    })

    const handleEnPrintScore = useReactToPrint({
        suppressErrors: true,
        content: () => printEnRefScore.current,
        onPrintError: () => { message(t('common.error')) },
        pageStyle: '@page{size: auto!important; margin: 0.5cm 1cm!important}',
        documentTitle: `${t('transcript.studentScoreDescription')}`
    })

    const onHandlerTranscript = (type, langCode) => {
        let params = {
            school: schoolId,
            type
        };

        dispatch(setLoading(true));
        fetchRequest(newsfeedStudentTranscriptView, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;

                    setStudentInfo(data?.studentData || null);
                    setMainSchoolData(data?.mainSchoolData || null);
                    setSchoolData(data?.schoolData || null);
                    setStudentScores(data?.studentScores || null);

                    if (data?.studentData) {
                        QRCode.toDataURL('https://ulms.msue.edu.mn/student/qrcode/check/' + data?.studentData.studentCode, { version: 5 }, function (err, url) {
                            setQrCode(url);
                        })
                    }

                    // ############################ Дүнгийн тодорхойлолт ############################
                    if (langCode == 'en') {
                        if (type == 'SCORE') {
                            handleEnPrintScore();
                        } else if (type == 'SELF') {
                            handleEnPrintSelf();
                        }
                    } else {
                        if (type == 'SCORE') {
                            handleMnPrintScore();
                        } else if (type == 'SELF') {
                            handleMnPrintSelf();
                        }
                    }
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch((e) => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            });
    }

    const onClose = () => {
        setShowSurveyModal(false)
    }

    const onRedirectSurveyMenu = () => {
        history.push('/student/survey')
    }

    return (
        <>
            <div className='d-none'><TranscriptSelf ref={printMnRefSelf} studentInfo={studentInfo} mainSchoolData={mainSchoolData} schoolData={schoolData} qrCode={qrCode} /></div>
            <div className='d-none'><TranscriptScore ref={printMnRefScore} studentInfo={studentInfo} mainSchoolData={mainSchoolData} schoolData={schoolData} studentScores={studentScores} qrCode={qrCode} /></div>
            <div className='d-none'><TranscriptEnScore ref={printEnRefScore} studentInfo={studentInfo} mainSchoolData={mainSchoolData} schoolData={schoolData} studentScores={studentScores} qrCode={qrCode} /></div>
            <div className='d-none'><TranscriptEnSelf ref={printEnRefSelf} studentInfo={studentInfo} mainSchoolData={mainSchoolData} schoolData={schoolData} qrCode={qrCode} /></div>
            <div className='row' style={{ flexWrap: 'nowrap', paddingRight: 15, paddingLeft: 15 }}>
                <div className='' style={{ width: 320, display: 'inline' }}>
                    <Card>
                        <Card.Body style={{ padding: '1.5rem' }}>
                            <div>
                                <img
                                    width={'100%'}
                                    src={person && person.avatar ? person.avatar : toAbsoluteUrl("/media/users/avatar_male.png")}
                                    alt="Student Pic"
                                />
                                <div className="symbol-label" style={{
                                }} />
                            </div>
                            <div style={{ textAlign: 'center', fontSize: 16, fontWeight: 'bold', marginTop: 5 }}>
                                {person.code}
                            </div>
                            <div style={{ fontWeight: 'bold', marginTop: 5 }}>
                                {t('menu.generalInformation')}
                            </div>
                            <table className='home-screen-person-info'>
                                <tbody style={{ whiteSpace: 'nowrap' }}>
                                    <tr>
                                        <th><i className='fa fa-id-card' /></th>
                                        <td>{person.email} /TeamsID/</td>
                                    </tr>
                                    {
                                        person.isStudent && person.password &&
                                        <tr>
                                            <th><i className='fa fa-unlock' /></th>
                                            <td>{person.password}</td>
                                        </tr>
                                    }
                                    <tr>
                                        <th><i className='fa fa-phone-square' /></th>
                                        <td>{person.password} /TeamsPassword/</td>
                                    </tr>
                                    <tr>
                                        <th><i className='fa fa-user-graduate' /></th>
                                        <td>{person.lastName + ' ' + person.firstName}</td>
                                    </tr>
                                    <tr>
                                        <th><i className='fa fa-id-badge' /></th>
                                        <td>{person.code}</td>
                                    </tr>
                                    <tr>
                                        <th><i className='fa fa-registered' /></th>
                                        <td>{person.registerNumber}</td>
                                    </tr>
                                    <tr>
                                        <th><i className='fa fa-school' /></th>
                                        <td> {capitalizeFirstLetter(school.longName)}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ fontWeight: 'bold', marginTop: 5 }}>
                                {t('menu.form')}
                            </div>
                            <div style={{ background: '#a844b9', padding: 10, borderRadius: 8, marginTop: 10 }}>
                                <div style={{ color: '#fff', marginBottom: 10, whiteSpace: 'nowrap' }}>Сурдаг үнэн тодорхойлолт</div>
                                <div style={{ color: '#fff', fontSize: 12, fontWeight: 'bold' }}>Голч:{gpa}</div>
                                <div className='btn-group m-btn-group' style={{ float: 'right', position: 'relative', bottom: 25, left: 5 }}>
                                    <button onClick={() => onHandlerTranscript('SELF', 'en')} className='home-screen-self-button-en btn-shadow'>Англи</button>
                                    <button onClick={() => onHandlerTranscript('SELF', 'mn')} className='home-screen-self-button-mn btn-shadow'>Монгол</button>
                                </div>
                            </div>
                            <div className='mt-4' style={{ background: '#e68900', padding: 10, borderRadius: 8 }}>
                                <div style={{ color: '#fff', marginBottom: 10, whiteSpace: 'nowrap' }}>Дүнгийн тодорхойлолт</div>
                                <div style={{ color: '#fff', fontSize: 12, fontWeight: 'bold' }}>Кредит:{totalCredit}</div>
                                <div className='btn-group m-btn-group' style={{ float: 'right', position: 'relative', bottom: 25, left: 5 }}>
                                    <button onClick={() => onHandlerTranscript('SCORE', 'en')} className='home-screen-score-button-en btn-shadow'>Англи</button>
                                    <button onClick={() => onHandlerTranscript('SCORE', 'mn')} className='home-screen-score-button-mn btn-shadow'>Монгол</button>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
                <div className='' style={{ width: '100%', display: 'inline' }}>
                    <Card className='mb-4'>
                        <Card.Header>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <span className='french-blue fs-10'>
                                    <b>{t('newsfeed.title').toUpperCase()}</b>
                                </span>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <div>
                                {
                                    types && types.length > 0 &&
                                    <button onClick={() => onHandlerTypeClick('all')} className={typeCode == 'all' ?
                                        'btn btn-sm btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary me-2 mb-2 mr-2 active'
                                        :
                                        'btn btn-sm btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary me-2 mb-2 mr-2'
                                    }>
                                        {t('common.all')}
                                    </button>
                                }
                                {
                                    types && types.length > 0 &&
                                    types.map((type, index) => {
                                        return (
                                            <button onClick={() => onHandlerTypeClick(type.code)} key={'type_' + index} className={typeCode == type.code ?
                                                'btn btn-sm btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary me-2 mb-2 mr-2 active'
                                                :
                                                'btn btn-sm btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary me-2 mb-2 mr-2'
                                            }>
                                                {type.name}
                                            </button>
                                        )
                                    })
                                }
                            </div>
                            <div>
                                {
                                    newsfeeds && newsfeeds.length > 0
                                        ?
                                        renderNews(newsfeeds)
                                        :
                                        null
                                }
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
            <Modal
                show={showSurveyModal}
                onHide={onClose}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop={'static'} 
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div style={{ color: "#4a70ae", fontSize: "1.1rem" }}>
                            {t('survey.title')}
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <b>{t('survey.modalDescription')}</b>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={onClose} className="btn btn-link bolder">
                        {t("common.back")}
                    </button>
                    <button onClick={() => onRedirectSurveyMenu()} className="btn btn-success bolder">
                        {t("survey.fillOut")}
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
};

