import React, { useState } from "react";
import Checkbox from "../../../../../modules/Form/Checkbox";
import { useTranslation } from "react-i18next";

const CriteriaHealth = () => {
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState(false);

  return (
    <div className="row p-6 ">
      <div className="">
        <div className="m-auto text-center" style={{ width: "fit-content" }}>
          {/* <Checkbox
            key={0}
            label={t("enroll.checkPunishment")}
            // className="flex-center"
            onChange={() => setIsChecked(!isChecked)}
            checked={isChecked}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default CriteriaHealth;
