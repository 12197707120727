import React,{ useState,useRef,useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import Forms from '../../../modules/Form/Forms'
import {useTranslation} from "react-i18next";
export const AddDescription = ({
    close,
    onSubmit,
    schoolData,
    mainSchoolData,
    studentData,
    types
}) => {
    const ref = useRef();
    const { t } = useTranslation();

    const [amount] = useState(1000);
    const [days] = useState(7);

    const fields = [
        {
            key: 'type',
            label: t('description.type'),
            value: '',
            type: 'dropdown',
            options: types,
            required: true,
            clearable: true
        },
        {
            key: 'reason',
            label: t('description.whereFor'),
            value: '',
            type: 'text',
            required: true,
        },
    ];

    const onSave = () =>{
        const [isValid, states, values] = ref.current.validate();
        if(isValid){
            const params = {
                ...values
            };

            onSubmit(params);
        }
    };

    return (
        <Modal
            show={true}
            onHide={close}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('description.title').toUpperCase()}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Forms
                    fields={fields}
                    ref={ref}
                />
                <div className='row mt-5'>
                    <div className='col-12 text-center'>
                        {t('description.fee') + ': ' + amount + '₮'}
                        <p>{t('description.validDays') + ': ' + days}</p>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className='btn btn-link' onClick={close}>{t('common.back')}</button>
                <button className='btn btn-success' onClick={onSave}>{t('description.get')}</button>
            </Modal.Footer>
        </Modal>
    )
};
