import React, {useEffect, useState} from 'react'
import DTable from '../../../../modules/DataTable/DTable'
import {Button, ButtonToolbar, Card} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import DeleteModal from "../../../../modules/DeleteModal";
import Select from "../../../../modules/Form/Select";
import {setLoading} from "../../../../../redux/action";
import {fetchRequest} from "../../../../../utils/fetchRequest";
import {teacherQuestionsInit} from "../../../../../utils/fetchRequest/Urls";
import message from "../../../../modules/message";
import {Link} from "react-router-dom";
import { htmlDecode } from '../../../../../utils/utils';

export const TeacherQuestions = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const school = useSelector(state => state?.selectedSchool?.id || null);
    const [deleteModal, setDeleteModal] = useState(false);

    const [subjectId, setSubjectId] = useState(null);
    const [levelId, setLevelId] = useState(null);
    const [topicId, setTopicId] = useState(null);
    const [typeId, setTypeId] = useState(null);

    const [subjects, setSubjects] = useState([]);
    const [levels, setLevels] = useState([]);
    const [topics, setTopics] = useState([]);

    const [tempId,setTempId] = useState()

    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        let params = {
            school
        }
        init(params)
    }, [])

    const init = (params={},method = 'GET') => {
        dispatch(setLoading(true));
        fetchRequest(teacherQuestionsInit, method, params)
            .then(res => {
                if (res.success) {
                    const { data } = res;

                    if(data?.questions?.length){
                        const tempQuestions = []
                        data.questions.map(question=>{
                            tempQuestions.push({
                                ...question,
                                contextMenuKeys: question.status ? ['view','inactive'] : ['edit','delete']
                            })
                        })
                        setTableData(tempQuestions)
                    }
                    if(data?.subjects?.length){
                        setSubjects(data.subjects)
                    }
                    if(data?.levels?.length){
                        setLevels(data.levels)
                    }
                    if(data?.topics?.length){
                        setTopics(data.topics)
                    }

                    if(method === 'post'){
                        message(res?.data?.message || t('common.success'), true);
                        onModalClose();
                    }
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }

    const config = {
        showPagination: true,
        showFilter: true,
    };

    const columns = [
        {
            dataField: 'status',
            text: '',
            sort: false,
            headerStyle: () => ({
                width: 60,
            }),
            style: {textAlign: 'center'},
            formatter: (cell, row, rowIndex) => {
                if(cell === true){
                    return (
                        <div><span><i className="icon-1_5x main-green fa fa-circle"/></span></div>
                    )
                } else {
                    return (
                        <div><span><i className="icon-1_5x text-dark-50 fa fa-circle"/></span></div>
                    )
                }
            }
        },
        {
            dataField: 'text',
            text: t('question.title'),
            sort: true,
            formatter(cell,row){
                return <div className='w-100'>
                    <div dangerouslySetInnerHTML={{ __html: htmlDecode(cell)}}></div>
                </div>
            }
        },
        {
            dataField: 'type',
            text: t('common.type'),
            sort: true,
        },
        {
            dataField: 'level',
            text: t('common.level'),
            sort: true,
        },
        {
            dataField: 'score',
            text: t('teacher.score'),
            sort: true,
        },
        {
            dataField: 'topic',
            text: t('teacher.topic'),
            sort: true,
        },
    ];

    const contextMenus = [
        {
            key: 'edit',
            title: t('common.edit'),
            icon: <i className='las la-edit dt-cm-item-icon'/>
        },
        {
            key: 'delete',
            title: t('common.delete'),
            icon: <i className='las la-trash-alt dt-cm-item-icon' />,
        },
        {
            key: 'view',
            title: t('common.view'),
            icon: <i className='las la-search dt-cm-item-icon' />,
        },
        {
            key: 'inactive',
            title: t('action.setInactive'),
            icon: <i className='la la-eye-slash dt-cm-item-icon' />,
        },
    ];

    const handleContextMenuClick = (id, key, record) => {
        if(key === 'view'){

        } else if (key === 'delete'){
            setTempId(id)
            setDeleteModal(true);
        } else if (key === 'edit'){

        } else if (key === 'inactive'){

        }
    };

    const handlerOnSearchClick = () => {
        dispatch(setLoading(true));

        const params = {
            school,
        };

        if(subjectId){
            params.subject = subjectId
        }
        if(levelId){
            params.level = levelId
        }
        if(topicId){
            params.topic = topicId
        }
        if(typeId){
            params.status = typeId
        }

        init(params)
    };

    const handlerOnClearClick = () => {
        setSubjectId(null);
        setLevelId(null);
        setTopicId(null);
        setTypeId(null);
    };

    const onModalClose = () => {
        if(deleteModal) setDeleteModal(false);
    };

    const onDelete = () => {
        const bodyParams = {
            question: tempId
        };
        init(bodyParams,'POST')
    };

    return (
        <div className='sm-container'>
            <Card className='mb-4'>
                <Card.Body>
                    <div className='row'>
                        <div className='col pr-0'>
                            <label>{t('common.subject')}</label>
                            <Select
                                value={subjectId}
                                onChange={value => setSubjectId(value)}
                                options={subjects}
                                searchable
                            />
                        </div>
                        <div className='col pr-0'>
                            <label>{t('common.level')}</label>
                            <Select
                                value={levelId}
                                onChange={value => setLevelId(value)}
                                options={levels}
                                searchable
                            />
                        </div>
                        <div className='col pr-0'>
                            <label>{t('teacher.topic')}</label>
                            <Select
                                value={topicId}
                                onChange={value => setTopicId(value)}
                                options={topics}
                                searchable
                            />
                        </div>
                        <div className='col pr-0'>
                            <label>{t('common.status')}</label>
                            <Select
                                value={typeId}
                                onChange={value => setTypeId(value)}
                                options={[
                                    {
                                        value: '1',
                                        text: t('common.active')
                                    },
                                    {
                                        value: '0',
                                        text: t('common.inactive')
                                    }
                                ]}
                            />
                        </div>
                    </div>
                </Card.Body>
                <Card.Footer>
                    <div className='col-12 text-center'>
                        <button className="btn btn-link bolder" onClick={handlerOnClearClick}>{t('common.clear').toUpperCase()}</button>
                        <button className="btn btn-warning m-btn--icon m-btn--icon-only btn-shadow" onClick={handlerOnSearchClick}>
                            <i className="flaticon-search-1" style={{color: '#000'}}></i>
                            <span style={{color: '#000'}}>
                                {t('common.search').toUpperCase()}
                            </span>
                        </button>
                    </div>
                </Card.Footer>
            </Card>
            <Card>
                <Card.Body>
                    <div className='row'>
                        <div className='col-12'>
                            <ButtonToolbar className='d-inline'>
                                <Link
                                    to={{
                                        pathname: '/teacher/question-add',
                                    }}
                                    className="btn btn-primary btn-shadow"
                                >
                                    {t('common.add')}
                                </Link>
                            </ButtonToolbar>
                        </div>
                        <div className='col-12'>
                            <DTable
                                data={tableData}
                                columns={columns}
                                config={config}
                                totalDataSize={150}
                                contextMenus={contextMenus}
                                onContextMenuItemClick={handleContextMenuClick}
                                selectMode={'radio'}
                                individualContextMenus
                            />
                        </div>
                    </div>
                </Card.Body>
            </Card>
            {
                deleteModal && (
                    <DeleteModal
                        onClose={onModalClose}
                        onDelete={onDelete}
                        title={t('warning.delete')}>
                        {t('warning.delete_confirmation')}
                        <br />
                        <br />
                        {t('warning.delete_confirmation_description')}
                    </DeleteModal>
                )
            }
        </div>
    )
};
