import React, {useRef, useEffect, useState} from 'react';
import {Button, ButtonToolbar, Card} from "react-bootstrap";
import Forms from "../../../../modules/Form/Forms";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {teacherOnlineLessonTopicAdd} from "../../../../../utils/fetchRequest/Urls";
import { setLoading } from "../../../../../redux/action";
import { fetchRequest } from "../../../../../utils/fetchRequest";
import message from "../../../../modules/message";
import {Link, useHistory} from "react-router-dom";
import {AddFileModal} from "./AddFileModal";

export const TeacherOnlineLessonEditTopicAdd = ({
    location: {state: {id = null} = {}} = {},
}) => {
    const { t } = useTranslation();
    const formRef = useRef();
    const dispatch = useDispatch();
    const history = useHistory();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [topics, setTopics] = useState([]);
    const [pTimes, setPTimes] = useState([]);
    const [newFile, setNewFile] = useState([]);
    const [files, setFiles] = useState([]);
    const [fileNames, setFileNames] = useState('');
    const [addModal, setAddModal] = useState(false);

    useEffect(() => {
        const params = {
            school: schoolId,
            course: id
        };

        dispatch(setLoading(true));
        fetchRequest(teacherOnlineLessonTopicAdd, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setPTimes(data?.pTimes || []);
                    setFiles(data?.files || []);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }, []);

    const onButtonChange = () => {
        setAddModal(true);
    };

    useEffect(() => {
        formRef?.current?.updateFields && formRef.current?.updateFields(fields);
    }, [pTimes]);

    useEffect(() => {
        formRef?.current?.updateFields && formRef.current?.updateFields(fields, 'fileLink');
    }, [fileNames]);

    const onFileChange = (file, evt, action) => {
        if(action == 'clear'){
            setNewFile({})
        } else {
            setNewFile(file[0])
        }
    };

    const fields = [
        {
            key: 'time',
            label: t('subject.type'),
            labelWidth: 300,
            value: null,
            type: 'dropdown',
            required: true,
            clearable: true,
            searchable: true,
            options: pTimes
        },
        {
            key: 'topicName',
            label: t('teacher.topicName'),
            labelWidth: 300,
            value: '',
            type: 'text',
            required: true,
        },
        {
            key: 'title',
            label: t('teacher.shortDescription'),
            labelWidth: 300,
            labelAlign: 'top',
            value: '',
            type: 'textArea',
            rows: 3,
            required: true,
        },
        {
            key: 'description',
            label: t('teacher.courseContent'),
            labelWidth: 300,
            labelAlign: 'top',
            value: '',
            type: 'textArea',
            rows: 3,
            required: true,
        },
        {
            key: 'web',
            label: t('teacher.webLink'),
            labelWidth: 300,
            value: '',
            type: 'text',
            clearable: true,
            searchable: true,
            multiple: true
        },
        {
            key: 'file',
            label: t('teacher.subjectFile'),
            labelWidth: 300,
            value: undefined,
            type: 'fileUpload',
            IsExtendedButtonWithIcon: true,
            isExtendedButtonWithIconClass: 'btn btn-primary m-btn m-btn--icon m-btn--icon-only topic-add-icon-style',
            iconNameClass: 'la la-youtube-play',
            clearButton: true,
            clearable: true,
            searchable: true,
            multiple: false,
            onChange: onFileChange
        },
        {
            key: 'fileLink',
            label: t('teacher.selectFileDirectory'),
            labelWidth: 300,
            value: fileNames || '',
            type: 'text',
            disabled: true,
            isExtendedButton: true,
            isExtendedButtonText: t('newsfeed.chooseFile'),
            isExtendedButtonClass: 'btn btn-outline-success ml-2',
            onExtendedButtonChange: onButtonChange,
        },
    ];

    const onAddClose = () =>{
        setAddModal(false);
    };

    const handlerOnSaveClick = (active) => {
        const [ isValid, states, values ] = formRef.current.validate();
        if (isValid) {
            const formData = new FormData();
            formData.append('school', schoolId);
            formData.append('course', id);
            formData.append('time', values.time);
            formData.append('name', values.topicName);
            formData.append('title', values.title);
            formData.append('description', values.description);
            formData.append('web', values.web);
            formData.append('file', newFile);
            formData.append('active', active);

            let fileArray = [];
            if(files && files.length > 0){
                for(let i = 0; i < files.length; i++){
                    if(files[i].checked){
                        fileArray.push(files[i].id)
                    }
                }
            }

            if(fileArray && fileArray.length > 0){
                formData.append('fileIds', JSON.stringify(fileArray));
            }

            dispatch(setLoading(true));
            fetchRequest(teacherOnlineLessonTopicAdd, 'POST', formData, false, true)
                .then(res => {
                    if (res.success) {
                        const { data } = res;

                        history.push({
                            pathname: '/teacher/online/lesson-edit',
                            state: {
                                courseId: id,
                            }
                        });
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        }
    };

    const onFileSubmit = () => {
        let fileName = '';
        if(files && files.length > 0){
            for(let i = 0; i < files.length; i++){
                if(files[i].checked){
                    if(fileName){
                        fileName = fileName + ', ' + files[i].name;
                    } else {
                        fileName = files[i].name;
                    }
                }
            }
        }

        setFileNames(fileName);
        setAddModal(false);
    }

    const handleCheckboxClick = (e, index) => {
        let cloneFiles = [...files];

        if(cloneFiles && cloneFiles.length > 0 ){
            cloneFiles[index].checked = e.target.checked;
        }

        setFiles(cloneFiles);
    };

    return (
        <div className='sm-container'>
            <Card>
                <Card.Header>
                    <span className='french-blue fs-10'>
                        <b>{t('teacher.createTopic').toUpperCase()}</b>
                    </span>
                </Card.Header>
                <Card.Body>
                    <Forms
                        ref={formRef}
                        fields={fields}
                    />
                </Card.Body>
                <Card.Footer>
                    <div className='col-11 text-center'>
                        <Link
                            to={{
                                pathname: '/teacher/online/lesson-edit',
                                state: {
                                    courseId: id,
                                }
                            }}
                            className="btn btn-link bolder"
                        >
                            {t('common.back')}
                        </Link>
                        <Button variant="success btn-shadow" onClick={() => handlerOnSaveClick(0)}>{t('common.save')}</Button>
                        <Button variant="publish btn-shadow ml-2" onClick={() => handlerOnSaveClick(1)}>{t('common.open')}</Button>
                    </div>
                </Card.Footer>
            </Card>
            {
                addModal &&
                <AddFileModal
                    close={onAddClose}
                    onSubmit={onFileSubmit}
                    files={files}
                    title={t('newsfeed.chooseFile')}
                    handleCheckboxClick={handleCheckboxClick}
                />
            }
        </div>
    );
};