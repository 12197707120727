import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { setLoading } from 'redux/action';
import { fetchRequest } from 'utils/fetchRequest';
import message from 'app/modules/message';
import { graduationRemove } from 'utils/fetchRequest/Urls';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

const DeleteModal = ({
    open = false,
    onClose = () => {},
    id = null,
    school = null
}) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const onDelete = () => {
        try {
            dispatch(setLoading(true));
            const params = {
                id,
                school
            }
            fetchRequest(graduationRemove, 'POST', params)
                .then(res => {
                    if (res.success) {
                        onClose()
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                })
                .catch(() => {
                    message(t('errorMessage.title'))
                })
                .finally(()=>dispatch(setLoading(false)))
        } catch (e) {
            message(t('errorMessage.title'))
        }
    }

    return (
        <Modal
            show={open}
            onHide={onClose}
            size={'sm'}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {t('graduation.template')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {t('warning.delete_confirmation')}
                <br />
                <br />
                {t('warning.delete_confirmation_description')}
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className="btn btn-link bolder mr-2">
                    {t("common.close").toLocaleUpperCase()}
                </button>
                <Button variant="success btn-shadow mr-2" onClick={onDelete}>
                    {t('action.delete')}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteModal
