import React, { useEffect, useState } from 'react';
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import message from "../../../modules/message";
import { getStudentInfo, movementBetween } from "../../../../utils/fetchRequest/Urls";
import Select from "../../../modules/Form/Select";
import { TextareaAutosize } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import Instruction from "../../../modules/instruction";
import StudentInfoTable from '../StudentInfoTable';

const AddInternalMovement = ({
    location: { state: {
        studentCode = null,
        movement = null,
        level = null
    } = {} } = {}, }) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    const [code, setCode] = useState('');
    const [student, setStudent] = useState({});
    const [classrooms, setClassrooms] = useState([]);
    const [classroom, setClassroom] = useState(null);
    const [description, setDescription] = useState('');

    const handleCodeChange = e => {
        setCode(e.target.value);
        setStudent({});
        setClassroom(null);
    }

    const fetchData = params => {
        dispatch(setLoading(true));
        fetchRequest(getStudentInfo, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    if (data?.student) {
                        setStudent(data.student);

                        dispatch(setLoading(true));
                        fetchRequest(movementBetween, 'GET', {
                            school: schoolId,
                            student: data.student?.id,
                            movement,
                            level
                        })
                            .then(res => {
                                if (res.success) {
                                    const { data } = res;
                                    setClassrooms(data?.classes.map(classObj => ({ value: classObj.value, text: classObj.academicProgramName + ' - ' + classObj.programName })) || []);

                                    if (data?.movement) {
                                        setClassroom(data.movement.toClass)
                                        setDescription(data.movement.description)
                                    }
                                    dispatch(setLoading(false));
                                } else {
                                    message(res?.data?.message || t('errorMessage.title'))
                                }
                                dispatch(setLoading(false));
                            })
                            .catch(() => {
                                dispatch(setLoading(false));
                                message(t('errorMessage.title'))
                            })
                    }
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                    dispatch(setLoading(false));
                }

            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }

    const handleBlur = () => {
        const params = {
            school: schoolId,
            studentCode: code,
        }
        fetchData(params)
    };

    const handleClassroomChange = value => {
        setClassroom(value)
    };

    const handleDescriptionChange = e => {
        setDescription(e.target.value);
    }

    const onSave = () => {
        let error = false;
        if (!description) {
            error = true;
            message('Тайлбар оруулна уу', false);
        }
        if (!classroom) {
            error = true;
            message('Шилжих анги оруулна уу', false);
        }
        if (!error) {
            const params = {
                school: schoolId,
                description,
                class: classroom,
                student: student?.id,
                movement,
                level
            }
            dispatch(setLoading(true));
            fetchRequest(movementBetween, 'POST', params)
                .then(res => {
                    if (res.success) {
                        history.push({
                            pathname: '/movement/int',
                            state: {
                                tab: 'internal',
                            }
                        });
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        }
    };
    useEffect(() => {
        if (!movement && typeof level === 'string') {
            if (!level || level.substring(0, 6) == 'season') {
                history.push({
                    pathname: '/movement/int',
                    state: {
                        tab: 'school'
                    }
                });
                message(t('errorMessage.selectEducationLevel'))
            }
        }
    }, [level])

    useEffect(() => {
        if (studentCode) {
            fetchData({
                studentCode,
                school: schoolId,
                movement
            })
        }
    }, [studentCode])

    return (
        <Card>
            <Card.Body>
                <div className='text-right'>
                    <Instruction

                    />
                </div>
                {
                    studentCode
                        ? null
                        :
                        <div className='row'>
                            <div className='col-3' style={{ display: 'flex', alignSelf: 'center', justifyContent: 'flex-end' }}>
                                {t('student.code') + '*'}
                            </div>
                            <div className='col-3'>
                                <input
                                    className='form-control'
                                    onChange={handleCodeChange}
                                    value={code}
                                    onBlur={handleBlur}
                                />
                            </div>
                        </div>
                }
                <div className='row'>
                    <div className='col-2'>

                    </div>
                    <div className='col-10'>
                        <StudentInfoTable student={student} />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-3' style={{ display: 'flex', alignSelf: 'center', justifyContent: 'flex-end' }}>
                        {t('movement.enrollmentClass') + '*'}
                    </div>
                    <div className='col-3'>
                        <Select
                            searchable
                            value={classroom}
                            onChange={handleClassroomChange}
                            options={classrooms}
                        />
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-3' style={{ display: 'flex', alignSelf: 'center', justifyContent: 'flex-end' }}>
                        {t('common.description') + '*'}
                    </div>
                    <div className='col-6'>
                        <TextareaAutosize
                            className='msue-textarea-description'
                            value={description}
                            onChange={handleDescriptionChange}
                            rows={5}
                        />
                    </div>
                </div>
            </Card.Body>
            <Card.Footer>
                <div className='text-center'>
                    <Link
                        to={'/movement/int'}
                        className='btn btn-link bolder'
                    >
                        {t('common.back')}
                    </Link>
                    <button
                        className='btn btn-success'
                        onClick={onSave}
                    >
                        {t('common.save').toLocaleUpperCase()}
                    </button>
                </div>
            </Card.Footer>
        </Card>
    );
};

export default AddInternalMovement;