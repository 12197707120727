import React, {useRef, useState} from 'react'
import { Modal, Card, FormCheck } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Forms from '../../../../modules/Form/Forms'

export const AddFileModal = ({
    close = ()=>{},
    files = [],
    onSubmit = () =>{},
    handleCheckboxClick = (e, index) =>{},
}) => {
    const {t} = useTranslation();

    return (
        <Modal
            show={true}
            onHide={close}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('newsfeed.chooseFile')}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='container'>
                    <div className='row'>
                        {
                            files.map((file, index) => {
                                return (
                                    <div
                                        key={'col_key_' + index}
                                        className='col-md-3'
                                    >
                                        <Card className='mb-3'>
                                            <Card.Body className=' ml-2 mr-2'>
                                                <FormCheck
                                                    className='custom-cbox'
                                                    checked={file.checked || false}
                                                    onChange={(e) => handleCheckboxClick(e, index)}
                                                    label={file.name}
                                                />
                                            </Card.Body>
                                        </Card>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className='btn btn-link btn-shadow' onClick={close}>{t('common.back')}</button>
                <button className='btn btn-success btn-shadow' onClick={onSubmit}>{t('common.save')}</button>
            </Modal.Footer>
        </Modal>
    )
};
