import React, { useRef, useEffect, useState } from 'react';
import { Button, Modal } from "react-bootstrap";
import Forms from "../../../modules/Form/Forms";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { menuAdd } from "../../../../utils/fetchRequest/Urls";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import message from "../../../modules/message";

const MenuAdd = ({
    onClose,
    onSubmit
}) => {
    const { t } = useTranslation();
    const forms = useRef();
    const dispatch = useDispatch();
    const languages = useSelector(state => state?.languages?.languages || []).filter(lang => lang.code !== 'mn');
    const [roles, setRoles] = useState([]);
    const [positions, setPositions] = useState([]);
    const [parents, setParents] = useState([]);
    const fields = [];

    useEffect(() => {
        dispatch(setLoading(true));
        fetchRequest(menuAdd, 'GET', [])
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    const roleArray = data?.roles || [];
                    const positionArray = data?.positions || [];
                    const parentArray = data?.parents || [];

                    if(positionArray && positionArray.length > 0){
                        for (let i = 0; i < positionArray.length; i++)
                        {
                            positionArray[i]['text'] = positionArray[i]['text'] + ' - ' + positionArray[i]['code'];
                        }
                    }

                    setRoles(roleArray);
                    setPositions(positionArray);
                    setParents(parentArray);

                    forms?.current?.updateFields && forms.current.updateFields([
                        {
                            label: t('common.code'),
                            value: '',
                            type: 'nonCryllic',
                            inputWidth: 300,
                            labelWidth: 230,
                            required: true,
                            key: 'code',
                        },
                        {
                            label: t('common.name'),
                            value: '',
                            type: 'text',
                            inputWidth: 300,
                            labelWidth: 230,
                            required: true,
                            key: 'name',
                        },
                        {
                            label: 'URL',
                            value: '',
                            type: 'text',
                            inputWidth: 300,
                            labelWidth: 230,
                            required: true,
                            key: 'path',
                        },
                        {
                            label: 'Icon нэр',
                            value: '',
                            type: 'text',
                            inputWidth: 300,
                            labelWidth: 230,
                            required: true,
                            key: 'icon',
                        },
                        {
                            label: t('common.ordering'),
                            value: '',
                            type: 'numberOnly',
                            inputWidth: 300,
                            labelWidth: 230,
                            required: true,
                            key: 'ordering',
                        },
                        {
                            label: t('menu.parent'),
                            value: null,
                            type: 'dropdown',
                            inputWidth: 300,
                            labelWidth: 230,
                            required: false,
                            options: parentArray,
                            key: 'parent',
                            clearable: true,
                            searchable: true
                        },
                        {
                            label: t('menu.position'),
                            value: null,
                            type: 'dropdown',
                            inputWidth: 300,
                            labelWidth: 230,
                            required: true,
                            options: positionArray,
                            key: 'position',
                            clearable: true,
                            searchable: true
                        },
                        {
                            label: t('system.systemRole'),
                            value: null,
                            type: 'dropdown',
                            inputWidth: 300,
                            labelWidth: 230,
                            required: true,
                            options: roleArray,
                            key: 'roles',
                            multiple: true,
                            searchable: true
                        },
                        {
                            label: t('menu.isActive'),
                            type: 'checkbox',
                            value: false,
                            inputWidth: 300,
                            labelWidth: 230,
                            key: 'active',
                        },
                        {
                            label: t('menu.isMain'),
                            type: 'checkbox',
                            value: false,
                            inputWidth: 300,
                            labelWidth: 230,
                            key: 'main',
                        },
                        {
                            type: 'jsx',
                            value: <div key={'separator'} className="separator separator-dashed my-7"/>,
                        },
                        ...translationFields
                    ]);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }, []);

    const translationFields = languages.map(lang => {
        return {
            label: `${t('common.name')} - ${lang.name} (${lang.code})`,
            value: '',
            langCode: lang.code,
            type: 'text',
            inputWidth: 300,
            labelWidth: 230,
        }
    });

    const onSaveClick = () => {
        const [ isValid, states, values ] = forms.current.validate();
        if (isValid) {
            const translations = [];
            for (let i = 0; i < states.length; i++) {
                const val = states[i];
                if (i >= states.length - 2) {
                    translations.push({
                        langCode: val.langCode,
                        name: val.value,
                    });
                }
            }
            if(values.active === true){
                values.active = 1;
            } else {
                values.active = 0;
            }
            if(values.main === true){
                values.main = 1;
            } else {
                values.main = 0;
            }
            const params = {
                ...values,
                translations: JSON.stringify(translations)
            };
            onSubmit(params);
        }
    };

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('menu.add').toUpperCase()}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Forms
                    ref={forms}
                    fields={fields}
                />
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className='btn btn-link bolder'>{t('common.back')}</button>
                <Button variant='success btn-shadow' onClick={onSaveClick}>{t('common.save')}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default MenuAdd;