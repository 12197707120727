import React,{useState,useEffect} from 'react'
import { useDispatch, useSelector } from "react-redux";
import TreeView from '../../../modules/TreeView'
import { Card, Row, Col } from 'react-bootstrap'
import Instruction from '../../../modules/instruction';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import message from "../../../modules/message";
import StudentTable from './StudentTable';
import { fetchRequest } from "../../../../utils/fetchRequest";
import { setLoading } from "../../../../redux/action";
import { movementOut } from '../../../../utils/fetchRequest/Urls';

const SchoolMovementOut = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [nodeId,setNodeId] = useState(0)
    const [treeData,setTreeData] = useState([])
    const [treeIndex] = useState('movement_out_tree_index');
    const [isClick,setIsClick] = useState(false);
    const treeId = localStorage.getItem(treeIndex);
    const tree_Id = treeId ? treeId.split('_') : null;
    
    const onTreeSelect = array =>{
        if (array?.length) {
            setIsClick(true);
            setNodeId(array);
            localStorage.setItem(treeIndex,array[0])
        }
    }
    useEffect(() => {
        const params = {
            school: schoolId
        }

        if(tree_Id && tree_Id.length === 2){
            setNodeId(treeId)
        }else{
            setNodeId(parseInt(treeId))
        }
        
        dispatch(setLoading(true));
        fetchRequest(movementOut, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    if (data?.treeData?.length) {
                        setTreeData(data.treeData);
                    }
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(err => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }, [nodeId]);

    return (
        <Row>
            <Col md={3}>
                <Card>
                    <Card.Body>
                        <TreeView
                            treeData={treeData}
                            selectedNodes={[nodeId]}
                            onSelect={onTreeSelect}
                            defaultExpandAll={true}
                        />
                    </Card.Body>
                </Card>
            </Col>
            <Col md={9}>
                <Card>
                    <Card.Body>
                        <div style={{ display: 'flex' }}>
                            <Link
                                className='btn btn-primary mr-3'
                                to= {{
                                    pathname:'/movement/out/add',
                                    state:{
                                        level:nodeId,
                                    }
                                }}
                            >
                                {t('movement.out.remove')}
                            </Link>
                            <Instruction/>
                        </div>
                        <StudentTable nodeId={nodeId} schoolId={schoolId} isClick={isClick} tree_Id={tree_Id}/>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}

export default SchoolMovementOut