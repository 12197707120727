import React, { useState, useEffect } from 'react';
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import { getSystemLanguages, systemLanguageDelete, systemLanguageEdit } from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import DTable from "../../../modules/DataTable/DTable";
import LanguageAdd from "./LanguageAdd";
import { getLastOrderNumber } from "../../../../utils/utils";
import DeleteModal from "../../../modules/DeleteModal";

const SystemLanguage = () => {
    const [ languages, setLanguages ] = useState([]);
    const [ showModal, setShowModal ] = useState(false);
    const [ langId, setLangId ] = useState(null);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setLoading(true));
        fetchRequest(getSystemLanguages, 'GET')
            .then(res => {
                if (res.success) {
                    const { data } = res;

                    if (data?.languages?.length) {
                        setLanguages(data.languages);
                    }
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            })
    }, []);

    const handleAddClick = () => {
        setShowModal(true);
    };

    const onModalClose = () => {
        setShowModal(false);
        setLangId(null);
    };

    const config = {
        showPagination: false,
        showFilter: false,
        showAllData: true,
    };

    const columns = [
        {
            dataField: "code",
            text: t('common.code'),
        },
        {
            dataField: "name",
            text: t('common.language'),
            sort: true
        },
    ];

    const contextMenus = [
        {
            key: 'edit',
            title: t('common.edit'),
            icon: <i className='las la-edit dt-cm-item-icon'/>
        },
        {
            key: 'delete',
            title: t('common.delete'),
            icon: <i className='las la-trash-alt dt-cm-item-icon' />,
        },
    ];

    const handleContextMenuClick = (id, key) => {
        if (id && key) {
            setLangId(id);
            if (key === 'edit') {
                setShowModal(true);
            }
        }
    };

    const onSubmit = params => {
        const url = langId ? systemLanguageEdit : getSystemLanguages;
        const bodyParams = {
            ...params,
            usable: 1,
        };

        if (langId) {
            bodyParams.lang = langId;
            const lang = languages.find(l => l.id === langId);
            bodyParams.ordering = lang?.ordering || getLastOrderNumber(languages) + 1;
        } else {
            bodyParams.ordering = getLastOrderNumber(languages) + 1;
        }

        dispatch(setLoading(true));
        fetchRequest(url, 'POST', bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res;

                    setLanguages(data?.languages || []);

                    message(data?.message || t('common.success'), true);

                    onModalClose();
                } else {
                    message(res?.data?.message || t('errorMessage.title'));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const onDelete = () => {
        const params = {
            lang: langId
        };
        dispatch(setLoading(true));
        fetchRequest(systemLanguageDelete, 'POSt', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setLanguages(data?.languages || []);
                    message(data?.message || t('common.success'), true);
                    onModalClose();
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    return (
        <Card>
            <Card.Body>
                <button
                    className='btn btn-primary'
                    onClick={handleAddClick}
                >
                    {t('common.add')}
                </button>
                <DTable
                    columns={columns}
                    config={config}
                    data={languages}
                    contextMenus={contextMenus}
                    onContextMenuItemClick={handleContextMenuClick}
                    selectMode={'radio'}
                />
                {
                    showModal && (
                        <LanguageAdd
                            onClose={onModalClose}
                            onSubmit={onSubmit}
                            id={langId}
                        />
                    )
                }
                {
                    !showModal && langId && (
                        <DeleteModal
                            onClose={onModalClose}
                            onDelete={onDelete}
                            title={t('setting.language.system')}
                        >
                            {t('warning.delete_confirmation')}
                            <br/>
                            <br/>
                            {t('warning.delete_confirmation_description')}
                        </DeleteModal>
                    )
                }
            </Card.Body>
        </Card>
    );
};

export default SystemLanguage;