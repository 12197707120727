import React, { useState, useEffect } from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import Instruction from "../../../modules/instruction";
import { useTranslation } from "react-i18next";
import DTable from "../../../modules/DataTable/DTable";
import TreeView from "../../../modules/TreeView";
import { useSelector, useDispatch } from "react-redux";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import {schoolClassInit, schoolClassDelete, menuInit} from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";

const StudentList = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const [ seasons, setSeasons ] = useState([]);
    const [ seasonId, setSeasonId ] = useState(location.state && location.state.id ? location.state.id : null);
    const [ classes, setClasses] = useState([]);
    const [ totalCount, setTotalCount] = useState([]);
    const [treeIndex] = useState('movement_class_tree_index');

    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    const handleClassAddClick = () => {
        history.push({
            pathname: '/movement/class/add',
            state: {
                id: seasonId,
            }
        });
    };

    const { t } = useTranslation();

    useEffect(() => {
        const selectedTreeId = localStorage.getItem(treeIndex);
        if(selectedTreeId){
            setSeasonId(selectedTreeId);
        }

        const params = {
            school: schoolId,
            season: selectedTreeId ? selectedTreeId : seasonId ? seasonId : ''
        };
        dispatch(setLoading(true));
        fetchRequest(schoolClassInit, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    if (data.seasons?.length) {
                        setSeasons(data?.seasons || []);
                        setClasses(data?.classes || []);
                        setTotalCount(data?.totalCount || null)
                    }
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
            });
    }, []);

    const getChildrenRecursively = (array, id) => {
        const children = [];
        for (const item of array)
        {
            if (item.parent === id)
            {
                children.push({
                    ...item,
                    children: getChildrenRecursively(array, item.key),
                });
            }
        }

        return children;
    };

    const columns = [
        {
            dataField: "typeName",
            text: t('curriculum.type'),
            sort: true,
            classes: ''
        },
        {
            dataField: "departmentName",
            text: t('movement.class.type'),
            sort: true,
        },
        {
            dataField: "academicProgramName",
            text: t('curriculum.title'),
            sort: true,
        },
        {
            dataField: "programName",
            text: t('curriculum.profession'),
            sort: true,
        },
        {
            dataField: "teacherFirstName",
            text: t('movement.class.consultantTeacher'),
            sort: true,
        },
        {
            dataField: "approachName",
            text: t('curriculum.approach'),
            sort: true,
        },
        {
            dataField: "classLimit",
            text: t('movement.class.studentCount'),
            sort: true,
        },
    ];

    const contextMenus = [
        {
            key: 'add-student',
            title: t('movement.class.addStudent'),
            icon: <i className='las la-address-card dt-cm-item-icon' />,
        },
        {
            key: 'view',
            title: t('common.view'),
            icon: <i className='las la-search dt-cm-item-icon'/>
        },
        {
            key: 'edit',
            title: t('common.edit'),
            icon: <i className='las la-edit dt-cm-item-icon'/>
        },
        {
            key: 'delete',
            title: t('common.delete'),
            icon: <i className='las la-trash-alt dt-cm-item-icon' />,
        },
    ];

    const onUserInteraction = (object) => {
        let params = {
            school: schoolId,
            season: seasonId,
            ...object,
        };

        dispatch(setLoading(true));
        fetchRequest(schoolClassInit, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setClasses(data.classes);
                    setTotalCount(data.totalCount);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const handleTreeNodeClick = array => {
        if (array?.length > 0) {
            const [id] = array;
            if (id.toString().split('_').length === 4)
            {
                const params = {
                    school: schoolId,
                    season: id
                };
                dispatch(setLoading(true));
                fetchRequest(schoolClassInit, 'GET', params)
                    .then(res => {
                        if (res.success) {
                            const { data } = res;
                            if (data.seasons?.length) {
                                setSeasons(data.seasons);
                                setClasses(data.classes);
                                setTotalCount(data.totalCount);
                            }
                        } else {
                            message(res?.data?.message || t('errorMessage.title'))
                        }
                        dispatch(setLoading(false));
                    })
                    .catch(() => {
                        dispatch(setLoading(false));
                    });

                setSeasonId(id);
                localStorage.setItem(treeIndex, array[0]);
            }
        }
    };

    const handleContextMenuClick = (id, key) => {
        if (id && key) {
            if (key === 'add-student') {
                history.push({
                    pathname: '/movement/class/student/add',
                    state: {
                        classId: id,
                        path: history.location.pathname
                    }
                })
            } else if (key === 'view') {
                history.push({
                    pathname: '/movement/class/view',
                    state: {
                        classId: id,
                        path: history.location.pathname
                    }
                })
            } else if (key === 'edit') {
                history.push({
                    pathname: '/movement/class/edit',
                    state: {
                        classId: id,
                        selectedSeason: seasonId
                    }
                })
            } else if (key === 'delete'){
                const params = {
                    school: schoolId,
                    season: seasonId,
                    class: id
                };
                dispatch(setLoading(true));
                fetchRequest(schoolClassDelete, 'POST', params)
                    .then(res => {
                        const { data } = res;
                        if (res.success) {
                            message(data?.message, true);
                            setClasses(data.classes)
                        } else {
                            message(data?.message || t('errorMessage.title'))
                        }
                        dispatch(setLoading(false));
                    })
                    .catch(() => {
                        dispatch(setLoading(false));
                    });
            }
        }
    };

    return (
        <div className='row'>
            <div className='col-3'>
                <div className='card'>
                    <div className='card-body'>
                        <TreeView
                            treeData={seasons}
                            selectedNodes={[seasonId]}
                            onSelect={handleTreeNodeClick}
                            defaultExpandAll={true}
                            contextMenuKey='movement-class'
                        />
                    </div>
                </div>
            </div>
            <div className='col-9 pl-0'>
                <div className='card'>
                    <div className='card-body'>
                        {
                            seasonId
                            ?
                                <button
                                    className='btn btn-primary mr-3'
                                    style={{ paddingLeft: '2rem', paddingRight: '2rem', marginBottom: 10 }}
                                    onClick={handleClassAddClick}
                                >
                                    {t('movement.class.add')}
                                </button>
                            : null
                        }
                        <Instruction/>
                        <DTable
                            data={classes}
                            columns={columns}
                            remote
                            totalDataSize={totalCount}
                            onInteraction={onUserInteraction}
                            showOrdering={true}
                            contextMenus={contextMenus}
                            onContextMenuItemClick={handleContextMenuClick}
                            selectMode={'radio'}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default StudentList;