import React, {useRef, useEffect, useState} from 'react';
import { Button, Modal } from "react-bootstrap";
import Forms from "../../../modules/Form/Forms";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {regulationTypeEdit, regulationTypeAdd} from "../../../../utils/fetchRequest/Urls";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import message from "../../../modules/message";

const ManualSettingAdd = ({
    onClose,
    onSubmit,
    id
}) => {
    const { t } = useTranslation();
    const forms = useRef();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const parentId = useSelector(state => state?.selectedSchool?.parentId || null);
    const [types, setTypes] = useState([]);
    const [regulationTypeData, setRegulationTypeData] = useState([]);

    useEffect(() => {
        let url;
        const params = {
            school: schoolId,
        };

        if (id) {
            url = regulationTypeEdit;
            params.type = id;
        } else {
            url = regulationTypeAdd
        }

        dispatch(setLoading(true));
        fetchRequest(url, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    if(data.regulationData){
                        setRegulationTypeData(data.regulationData);
                    }
                    if(data.codes && data.codes.length > 0){
                        setTypes(data.codes.map(type => ({ value: type.CODE, text: type.NAME })));
                    }
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }, []);

    useEffect(() => {
        forms?.current?.updateFields && forms.current?.updateFields(fields);
    }, [types, regulationTypeData, id]);

    const fields = [
        {
            label: t('common.code'),
            value: regulationTypeData.code || null,
            type: 'dropdown',
            required: true,
            key: 'code',
            options: types,
            clearable: true,
            searchable: true,
            hidden: id ? true : false
        },
        {
            label: t('common.name'),
            value: regulationTypeData.name || '',
            type: 'text',
            required: true,
            key: 'name',
        },
        {
            label: t('common.ordering'),
            value: regulationTypeData.ordering || '',
            type: 'numberOnly',
            required: true,
            key: 'ordering',
        },
        {
            label: t('school.isOtherSchool'),
            type: 'checkbox',
            value: true,
            hidden: id ? true : parentId == null ? false : true,
            key: 'isSchool',
        },
    ];

    const onSaveClick = () => {
        const [ isValid, states, values ] = forms.current.validate();
        if (isValid) {
            if(id){
                values.isSchool = false
            } else {
                if(values.isSchool){
                    values.isSchool = 1
                } else {
                    values.isSchool = 0
                }
            }
            const params = {
                ...values,
            };
            onSubmit(params);
        }
    };

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('menu.regulation').toUpperCase()} {id ? t('common.edit').toUpperCase() : t('common.add').toUpperCase()}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Forms
                    ref={forms}
                    fields={fields}
                />
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className='btn btn-link bolder'>{t('common.back')}</button>
                <Button variant='success btn-shadow' onClick={onSaveClick}>{t('common.save')}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ManualSettingAdd;