import React, {useRef, useEffect, useState} from 'react';
import { Button, Modal } from "react-bootstrap";
import Forms from "../../../modules/Form/Forms";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {getAttendance, studentAttendanceSave} from "../../../../utils/fetchRequest/Urls";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import message from "../../../modules/message";
import DTable from "../../../modules/DataTable/DTable";
import {getDateByDateObject, getDatesBetweenDates} from "../../../../utils/utils";

const AddAttendance = ({
    timetableId,
    onClose,
    onSubmit,
}) => {
    const { t } = useTranslation();
    const formRef = useRef();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    const [weeks, setWeeks] = useState([]);
    const [students, setStudents] = useState([]);
    const [types, setTypes] = useState([]);
    const [date, setDate] = useState(new Date());
    const [selectedWeek, setSelectedWeek] = useState(null);
    const [selectedDays, setSelectedDays] = useState([]);
    const [selectedDay, setSelectedDay] = useState(null);
    const [selectedDayName, setSelectedDayName] = useState(null);
    const [accessableDates, setAccessableDates] = useState([]);
    const [teacherLog, setTeacherLog] = useState([]);

    useEffect(() => {
        const params = {
            school: schoolId,
            timetable: timetableId,
            date: getDateByDateObject(date)
        };
        init(params)
    }, []);

    const init = (params) => {
        dispatch(setLoading(true));
        fetchRequest(getAttendance, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    let weekOjb = data.weeks;
                    let accessableDayList = [];
                    let types = data.types;
                    let cameTypeInfo = [];
                    if(types && types.length > 0){
                        for(let i = 0; i < types.length; i++){
                            if(types[i].code.toLowerCase() == 'came'){
                                cameTypeInfo = types[i];
                            }
                        }
                    }
                    setTypes(types);
                    setTeacherLog(data.teacherLog)

                    if(data.weeks && data.weeks.length > 0){
                        setWeeks(data.weeks.map(week => ({value: week.week, text: week.week, dates: week.dates})))
                        if(weekOjb && weekOjb.length > 0){
                            for(let i = 0; i < weekOjb.length; i++){
                                if(weekOjb[i].dates && weekOjb[i].dates.length > 0){
                                    let dates = weekOjb[i].dates;
                                    for(let d = 0; d < dates.length; d++){
                                        accessableDayList.push(new Date(dates[d]))
                                    }
                                }
                            }
                        }
                        setAccessableDates(accessableDayList)
                    }

                    let students = data.students;
                    if(students && students.length > 0){
                        for(let i = 0; i < students.length; i++){
                            students[i].date = getDateByDateObject(new Date());
                            if(students[i].log === null){
                                students[i].log = {
                                    id: cameTypeInfo.id,
                                    code: cameTypeInfo.code,
                                    name: cameTypeInfo.name,
                                    color: cameTypeInfo.color
                                };
                            }
                        }
                    }
                    setStudents(students);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    useEffect(() => {
        formRef?.current?.updateFields && formRef.current?.updateFields(fields);
    }, [weeks, date, selectedWeek, accessableDates, students]);

    const onWeekChange = (id, options) => {
        if (id) {
            setSelectedWeek(id);
            setSelectedDays(options.dates.map((dayName, index) => ({value: index + 1, text: dayName})));
            setSelectedDay(null);
            setSelectedDayName(null);
        }
    };

    const onSelectedDaysChange = (id, options) => {
        if (id) {
            setSelectedDay(id);
            setSelectedDayName(options.text);

            let cloneData = [...students];
            if(cloneData && cloneData.length > 0) {
                for (let i = 0; i < cloneData.length; i++) {
                    cloneData[i].date = options.text;
                }

                setStudents(cloneData);
            }

            const params = {
                school: schoolId,
                timetable: timetableId,
                date: options.text
            };
            init(params)
        }
    };

    const onDateChange = (date) => {
        setDate(date);
        let cloneData = [...students];
        if(cloneData && cloneData.length > 0) {
            for (let i = 0; i < cloneData.length; i++) {
                cloneData[i].date = date;
            }

            setStudents(cloneData);
        }

        const params = {
            school: schoolId,
            timetable: timetableId,
            date: getDateByDateObject(date)
        };
        init(params);
    };

    const onButtonChange = () => {
        setSelectedWeek(null);
    };

    const fields = [
        {
            key: 'week',
            label: t('teacher.week'),
            value: selectedWeek,
            type: 'dropdown',
            onChange: onWeekChange,
            options: weeks,
            clearable: true,
            isExtendedButton: true,
            isExtendedButtonClass: 'btn btn-outline-primary ml-2',
            onExtendedButtonChange: onButtonChange,
            inputWidth: 300,
            labelWidth: 230,
        },
        {
            key: 'week',
            label: t('attendance.attendanceDay'),
            value: selectedDay,
            type: 'dropdown',
            onChange: onSelectedDaysChange,
            options: selectedDays,
            clearable: true,
            hidden: !selectedWeek,
            required: selectedWeek,
            inputWidth: 300,
            labelWidth: 230,
        },
        {
            key: 'date',
            label: t('common.date'),
            value: date,
            type: 'date',
            includeDays: accessableDates,
            onChange: onDateChange,
            clearable: true,
            dateCustomButton: false,
            hidden: selectedWeek ? true : false,
            inputWidth: 300,
            labelWidth: 230,
        }
    ];

    const config = {
        showPagination: false,
        showFilter: false,
        showAllData: true,
        tableMarginLess: true
    };

    const columns = [
        {
            dataField: "code",
            text: t('student.code'),
        },
        {
            dataField: "firstName",
            text: t('student.name'),
        },
        {
            dataField: "ordering",
            text: '',
            style: {padding: 3, textAlign: 'center'},
            headerStyle: () => ({
                width: 260,
            }),
            formatter(cell, row, index) {
                let buttons = [];
                if(types && types.length > 0){
                    for(let i = 0; i < types.length; i++){
                        let buttonName = '';
                        let style = {};
                        let code = null;
                        if(types[i].code){
                            code = types[i].code;
                        }

                        if(types[i].code.toLowerCase() === 'came'){
                            buttonName = t('attendance.cameCode');

                            if(row.log && row.log.code && row.log.code.toLowerCase() == 'came'){
                                style = {
                                    color: '#fff',
                                    backgroundColor: '#' + types[i].color,
                                    borderColor: '#' + types[i].color,
                                    borderRadius: '50%'
                                }
                            } else {
                                style = {
                                    color: '#' + types[i].color,
                                    backgroundColor: 'transparent',
                                    borderColor: '#' + types[i].color,
                                    borderRadius: '50%'
                                }
                            }
                        } else if(types[i].code.toLowerCase() === 'truant'){
                            buttonName = t('attendance.nonAttendanceCode');

                            if(row.log && row.log.code && row.log.code.toLowerCase() == 'truant'){
                                style = {
                                    color: '#fff',
                                    backgroundColor: '#' + types[i].color,
                                    borderColor: '#' + types[i].color,
                                    borderRadius: '50%'
                                }
                            } else {
                                style = {
                                    color: '#' + types[i].color,
                                    backgroundColor: 'transparent',
                                    borderColor: '#' + types[i].color,
                                    borderRadius: '50%'
                                }
                            }
                        } else if(types[i].code.toLowerCase() === 'late'){
                            buttonName = t('attendance.lateCode');

                            if(row.log && row.log.code && row.log.code.toLowerCase() == 'late'){
                                style = {
                                    color: '#fff',
                                    backgroundColor: '#' + types[i].color,
                                    borderColor: '#' + types[i].color,
                                    borderRadius: '50%'
                                }
                            } else {
                                style = {
                                    color: '#' + types[i].color,
                                    backgroundColor: 'transparent',
                                    borderColor: '#' + types[i].color,
                                    borderRadius: '50%'
                                }
                            }
                        } else if(types[i].code.toLowerCase() === 'sick'){
                            buttonName = t('attendance.sickCode');

                            if(row.log && row.log.code && row.log.code.toLowerCase() == 'sick'){
                                style = {
                                    color: '#fff',
                                    backgroundColor: '#' + types[i].color,
                                    borderColor: '#' + types[i].color,
                                    borderRadius: '50%'
                                }
                            } else {
                                style = {
                                    color: '#' + types[i].color,
                                    backgroundColor: 'transparent',
                                    borderColor: '#' + types[i].color,
                                    borderRadius: '50%'
                                }
                            }
                        } else if(types[i].code.toLowerCase() === 'absent'){
                            buttonName = t('attendance.absentCode');

                            if(row.log && row.log.code && row.log.code.toLowerCase() == 'absent'){
                                style = {
                                    color: '#fff',
                                    backgroundColor: '#' + types[i].color,
                                    borderColor: '#' + types[i].color,
                                    borderRadius: '50%'
                                }
                            } else {
                                style = {
                                    color: '#' + types[i].color,
                                    backgroundColor: 'transparent',
                                    borderColor: '#' + types[i].color,
                                    borderRadius: '50%'
                                }
                            }
                        }

                        buttons.push(
                            <Button
                                key={'button_' + i}
                                style={style}
                                onClick={(e, selectedWeek) => {buttonClick(cell, row, index, code, selectedWeek)}}
                                className={i === 0 ? "btn btn-outline-success m-btn m-btn--icon m-btn--icon-only" : "btn btn-outline-success m-btn m-btn--icon m-btn--icon-only ml-2"}
                                onMouseOut={(e) => row.log && row.log.code ? {} : mouseOut(e, '#' + types[i].color)}
                                onMouseOver={(e) => row.log && row.log.code ? {} : mouseOver(e, '#' + types[i].color)}
                            >
                                {buttonName}
                            </Button>
                        );
                    }
                }
                return (
                    buttons
                )
            },
        },
    ];

    const mouseOut = (e, color) => {
        e.target.style.color = color;
        e.target.style.backgroundColor = 'transparent';
        e.target.style.borderColor = color;
    };

    const mouseOver = (e, color) => {
        e.target.style.color = '#fff';
        e.target.style.backgroundColor = color;
        e.target.style.borderColor = color;
    };

    const buttonClick = (cell, row, index, code, week) => {
        let cloneData = [...students];
        let type = null;
        if(cloneData && cloneData.length > 0){
            if(types && types.length > 0){
                for (let i = 0; i < types.length; i++){
                    if(code == types[i].code){
                        type = types[i];
                    }
                }
            }

            let isShowButton = false;
            if(selectedWeek){
                if(accessableDates && accessableDates.length > 0){
                    for(let i = 0; i < accessableDates.length; i++){
                        if(getDateByDateObject(selectedDayName) == getDateByDateObject(accessableDates[i])){
                            isShowButton = true;
                        }
                    }
                }
            } else {
                if(accessableDates && accessableDates.length > 0){
                    for(let i = 0; i < accessableDates.length; i++){
                        if(getDateByDateObject(date) == getDateByDateObject(accessableDates[i])){
                            isShowButton = true;
                        }
                    }
                }
            }

            if(isShowButton){
                if(type){
                    cloneData[index].log.id = type.id;
                    cloneData[index].log.code = type.code;
                    cloneData[index].log.name = type.name;

                    setStudents(cloneData);

                    let bodyParams = {
                        school: schoolId,
                        timetable: timetableId,
                        student: cloneData[index].id,
                        type: type.id,
                        date: selectedWeek ? selectedDayName : getDateByDateObject(date)
                    };

                    dispatch(setLoading(true));
                    fetchRequest(studentAttendanceSave, 'POST', bodyParams)
                        .then(res => {
                            if (res.success) {
                                const { data } = res;
                            } else {
                                message(res?.data?.message || t('errorMessage.title'));
                            }
                            dispatch(setLoading(false));
                        })
                        .catch(() => {
                            dispatch(setLoading(false));
                            message(t('errorMessage.title'))
                        })
                }
            } else {
                message(t('errorMessage.attendance'))
            }
        }
    };

    const onSaveClick = () => {
        const [ isValid, states, values ] = formRef.current.validate();
        if (isValid) {
            if(values.date){
                values.date = selectedWeek ? selectedDayName : getDateByDateObject(date);
            }
            const params = {
                ...values,
                timetable: timetableId,
            };
            onSubmit(params);
        }
    };

    const renderSaveButton = () => {
        let isShowButton = false;
        if(selectedWeek){
            if(accessableDates && accessableDates.length > 0){
                for(let i = 0; i < accessableDates.length; i++){
                    if(getDateByDateObject(selectedDayName) == getDateByDateObject(accessableDates[i])){
                        isShowButton = true;
                    }
                }
            }
        } else {
            if(accessableDates && accessableDates.length > 0){
                for(let i = 0; i < accessableDates.length; i++){
                    if(getDateByDateObject(date) == getDateByDateObject(accessableDates[i])){
                        isShowButton = true;
                    }
                }
            }
        }

        if(isShowButton){
            return <Button variant='success btn-shadow' onClick={onSaveClick}>{t('common.save')}</Button>
        } else {
            return null
        }
    };

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('teacher.attendanceRegistration').toUpperCase()}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Forms
                    ref={formRef}
                    fields={fields}
                />
                {
                    teacherLog && teacherLog.createdDate && teacherLog.createdUser
                        ?
                        <p className='mt-5'>{t('attendance.sentTimeReport')}: <b>{teacherLog.createdDate.date.substring(0, 19)}</b>, {t('attendance.sent')}: <b>{teacherLog.createdUser}</b></p>
                        : null
                }
                <DTable
                    config={config}
                    columns={columns}
                    data={students}
                    selectMode={'ROW_SELECT_DISABLED'}
                />
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className='btn btn-link bolder'>{t('common.back')}</button>
                {renderSaveButton()}
            </Modal.Footer>
        </Modal>
    );
};

export default AddAttendance;