import React, { useEffect, useMemo, useState } from 'react'
import { Button, Col, Modal, Row} from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import Select from 'app/modules/Form/Select'
import DTable from 'app/modules/DataTable/DTable'
import { useDispatch } from 'react-redux';
import { setLoading } from 'redux/action'
import { fetchRequest } from 'utils/fetchRequest'
import { graduationCreate } from 'utils/fetchRequest/Urls'
import message from 'app/modules/message'

const StudentAddModal = ({
    open = false,
    onClose = () => {},
    school = null
}) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const [list,setList] = useState([])

    const [filter,setFilter] = useState({})
    const [selected,setSelected] = useState([])

    const [levelOptions,setLevelOptions] = useState([])
    const [programOptions,setProgramOptions] = useState([])
    const [curriculumOptions,setCurriculumOptions] = useState([])
    const [parentOptions,setParentOptions] = useState([])

    const tableConfig = useMemo(()=>{
        return { 
            showPagination: false, 
            showAllData: true
        }
    },[])

    const filterList = useMemo(()=>[
        {
            key: 'educationLevel',
            text: t('curriculum.educationLevel'),
            options: levelOptions
        },
        {
            key: 'season',
            text: t('student.enrollmentSeason'),
            options: parentOptions
        },
        {
            key: 'academicProgram',
            text: t('curriculum.title'),
            options: curriculumOptions
        },
        {
            key: 'lmProgram',
            text: t('curriculum.profession'),
            options: programOptions
        },
    ],[
        parentOptions,
        curriculumOptions,
        levelOptions,
        programOptions
    ])

    const columns = useMemo(()=>[
        {
            dataField: "code",
            text: t("student.code"),
            sort: false,
        },
        {
            dataField: "lastName",
            text: t("student.lastName"),
            sort: false,
        },
        {
            dataField: "firstName",
            text: t("student.name"),
            sort: false,
        }
    ],[])

    const onFilterChange = (key,value) => {
        setFilter(prev=>{
            let tempFilter = {...prev}
            if(key == 'educationLevel') {
                tempFilter = {
                    educationLevel: value,
                }
            } if(key == 'season') {
                tempFilter = {
                    season: value,
                    educationLevel: prev.educationLevel
                }
            } else {
                tempFilter[key] = value
    
                if(key == 'academicProgram') {
                    tempFilter['lmProgram'] = null
                }
            }
            init(tempFilter)
            return tempFilter
        })
    }

    const onTableSelect = (row = {})=>{
        const { id } = row || {}
        setSelected(prev=>{
            let tempList = []
            const exists = prev.find(val=>val == id)
            if(exists) {
                tempList = prev.filter(val=>val !== id)
            } else {
                tempList = [...prev, id]
            }
            return tempList
        })
    }

    const onTableSelectAll = (isSelect) => {
        if(!isSelect) {
            setSelected([])
            return;
        }

        setSelected(list.map(obj=>obj.id))
    }

    const submit = () => {
        try {
            dispatch(setLoading(true));
            
            const params = {
                school,
                students: selected,
                ...filter
            }
            fetchRequest(graduationCreate, 'POST', params)
                .then(res => {
                    if (res.success) {
                        clearClose()
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                })
                .catch(() => {
                    message(t('errorMessage.title'))
                })
                .finally(()=>dispatch(setLoading(false)))
        } catch (e) {
           console.log(e) 
           message(t('errorMessage.title'))
        }
    }

    const init = (filter = {}) => {
        try {
            dispatch(setLoading(true));
            const params = {
                school,
                ...filter
            }
            fetchRequest(graduationCreate, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const {data: {
                            seasons = [],
                            lmPrograms = [],
                            academicPrograms = [],
                            educationLevels = [],
                            students = []
                        } = {}} = res
                        setParentOptions(()=>{
                            const tempData = []
                            seasons.map(obj=>{
                                tempData.push({
                                    value: obj.id,
                                    text: obj.name
                                })
                            })
                            return tempData
                        })
                        setLevelOptions(()=>{
                            const tempData = []
                            educationLevels.map(obj=>{
                                tempData.push({
                                    value: obj.key,
                                    text: obj.title
                                })
                            })
                            return tempData
                        })
                        setCurriculumOptions(()=>{
                            const tempData = []
                            academicPrograms.map(obj=>{
                                tempData.push({
                                    value: obj.id,
                                    text: obj.name
                                })
                            })
                            return tempData
                        })
                        setProgramOptions(lmPrograms)
                        setList(students)
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                })
                .catch(() => {
                    message(t('errorMessage.title'))
                })
                .finally(()=>dispatch(setLoading(false)))
        } catch (e) {
            console.log(e)
            message(t('errorMessage.title'))
        }
    }

    const clearClose = () => {
        onClose()
        setFilter({})
    }

    useEffect(()=>{
        if(open) {
            init()
        }
    },[open])

    const renderFilterList = () => {
        return filterList.map((item,index)=>{
            return <Row key={index} className='d-flex align-items-center mb-2'>
                <Col className='d-flex justify-content-end'>
                    <label>{item.text}</label>
                </Col>
                <Col md={3}>
                    <Select
                        value={filter[item.key]}
                        onChange={value=>onFilterChange(item.key,value)}
                        options={item.options}
                        searchable
                    />
                </Col>
                <Col md={3}></Col>
            </Row>
        }) 
    }

    return (
        <Modal
            show={open}
            onHide={clearClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {t('graduation.addStudent')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {renderFilterList()}

                <DTable
                    remote
                    data={list}
                    config={tableConfig}
                    columns={columns}
                    isSelect={true}
                    onSelect={onTableSelect}
                    onSelectAll={onTableSelectAll}
                />
            </Modal.Body>
            <Modal.Footer>
                <button onClick={clearClose} className="btn btn-link bolder">
                    {t("common.back").toLocaleUpperCase()}
                </button>
                <Button variant="success btn-shadow" onClick={submit}>
                    {t('common.save')}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default StudentAddModal
