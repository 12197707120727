import React, { useState, useEffect, useRef } from 'react';
import { Card, Button, ButtonToolbar, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import Forms from "../../modules/Form/Forms";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { fetchRequest } from "../../../utils/fetchRequest";
import { setLoading } from "../../../redux/action";
import { useDispatch,useSelector } from "react-redux";
import { adminNewsfeedAdd } from "../../../utils/fetchRequest/Urls";
import message from "../../modules/message";
import draftToHtml from 'draftjs-to-html';

export const NewsfeedAdd = ({
    location: { state: { newsId = null, types = [] } = {} } = {},
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const [languages, setLanguages] = useState(useSelector(state => state?.languages?.languages || []).filter(lang => lang.code !== 'mn'));
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const forms = useRef();
    const formPhotoRef = useRef();

    const [isSchool, setIsSchool] = useState(true);
    const [isLanguage, setIsLanguage] = useState(false);
    const [coverPhoto, setCoverPhoto] = useState([]);
    const [photos, setPhotos] = useState({});
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

    useEffect(() => {
        if(languages && languages.length > 0){
            for(let i = 0; i < languages.length; i++){
                languages[i].title = '';
                languages[i].body = EditorState.createEmpty();
            }
        }
    }, []);

    const updateTextDescription = async (state) => {
        setEditorState(state);
    };

    const updateLangTextDescription = async (state, index) => {
        let cloneLanguage = [...languages];
        if(cloneLanguage && cloneLanguage.length > 0){
            cloneLanguage[index].body = state;
        }
        setLanguages(cloneLanguage)
    };

    const fields = [
        {
            label: t('common.title'),
            name: 'title',
            value: "",
            errorMessage: '',
            required: true,
            type: 'text',
            searchable: true,
            clearable: true,
            align: 'left',
            labelWidth: 120,
            inputStyle: {maxWidth: 350}
        },
        {
            label: t('common.type'),
            name: 'type',
            value: null,
            type: 'dropdown',
            options: types,
            searchable: true,
            clearable: true,
            align: 'left',
            labelWidth: 120,
            inputWidth: 350,
        }
    ];

    const onCheckboxSchoolChange = (val) => {
        setIsSchool(val)
    };

    const onCheckboxLanguageChange = (val) => {
        if(!val){
            let cloneLanguage = [...languages];
            if(cloneLanguage && cloneLanguage.length > 0){
                for(let i = 0; i < cloneLanguage.length; i++){
                    cloneLanguage[i].title = '';
                    cloneLanguage[i].body = EditorState.createEmpty();
                }
            }
            setLanguages(cloneLanguage);
        }
        setIsLanguage(val)
    };

    const onFileChange = (file, evt, action) => {
        if(action == 'clear'){
            setCoverPhoto([])
        } else {
            setCoverPhoto(file[0])
        }
    };

    const onMultipleFileChange = (files, evt, action) => {
        if(action == 'clear'){
            setPhotos([]);
        } else {
            let fileArray = [];
            if(files && files.length){
                for(let i = 0; i < files.length; i++){
                    fileArray.push(files[i])
                }
            }
            setPhotos(fileArray)
        }
    };

    const fieldPhotos = [
        {
            key: 'coverPhoto',
            label: t('common.coverPhoto'),
            value: "",
            type: 'fileUpload',
            isExtendedButton: true,
            isExtendedButtonText: t('newsfeed.chooseFile'),
            isExtendedButtonClass: 'btn btn-outline-success ml-2',
            accept: 'image/*',
            clearButton: true,
            labelWidth: 120,
            inputStyle: {maxWidth: 350},
            onChange: onFileChange
        },
        {
            key: 'otherPhoto',
            label: t('common.otherPhoto'),
            value: "",
            type: 'fileUpload',
            isExtendedButton: true,
            isExtendedButtonText: t('newsfeed.chooseFile'),
            isExtendedButtonClass: 'btn btn-outline-success ml-2',
            accept: 'image/*',
            multiple: true,
            clearButton: true,
            labelWidth: 120,
            inputStyle: {maxWidth: 350},
            onChange: onMultipleFileChange
        },
        // {
        //     key: 'isSchool',
        //     label: 'Зөвхөн тухайн сургууль дээр оруулах',
        //     value: isSchool || true,
        //     type: 'checkbox',
        //     inputWidth: 300,
        //     labelWidth: 120,
        //     onChange: onCheckboxSchoolChange,
        // },
        {
            key: 'isLanguage',
            label: 'Бусад хэлээр мэдээ оруулах',
            value: false,
            type: 'checkbox',
            inputWidth: 300,
            labelWidth: 120,
            onChange: onCheckboxLanguageChange,
        },
    ];

    const onInputChange = (e, index) => {
        let cloneLanguage = [...languages];
        if(cloneLanguage && cloneLanguage.length > 0){
            cloneLanguage[index].title = e.target.value;
        }
        setLanguages(cloneLanguage)
    };

    const renderTranslation = () => {
        let translationHtml = [];
        for(let i = 0; i < languages.length; i++){
            translationHtml.push(
                <div key={'language_' + i}>
                    <div key={'translation_' + i} style={{ display: 'flex', marginTop: '0.8rem' }}>
                        <label
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginRight : 10,
                                marginBottom: 0,
                                width: 140,
                                justifyContent: 'flex-end',
                            }}
                        >
                            {t('common.title') + ` - ${languages[i].name} (${languages[i].code})`}
                        </label>
                        <div
                            style={{
                                display: 'flex',
                                flex: 1,
                                flexDirection: 'column',
                                marginLeft: 10,
                                maxWidth: 350,
                            }}
                        >
                            <input
                                type='text'
                                className='form-control'
                                value={languages[i].title}
                                onChange={(e) => {onInputChange(e, i)}}
                                style={{border: '1px solid #919bc0'}}
                            />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flex: 0.8,
                                flexDirection: 'column',
                                marginLeft: 10,
                                width:'auto',
                            }}
                        />
                    </div>
                    <p/>
                    <Editor
                        editorState={languages[i].body}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={(e) => updateLangTextDescription(e, i)}
                        editorStyle={{ border: "1px solid rgba(0, 0, 0, .2)"}}
                    />
                </div>
            )
        }
        return translationHtml;
    };

    const handleSaveClick = (isPublish) => {
        const [formsValid, formValues] = forms.current.validate();
        if(editorState.getCurrentContent()){
            const bodyRaw = convertToRaw(editorState.getCurrentContent());
            const bodyHtml = draftToHtml(bodyRaw);
            if (formsValid && bodyRaw.blocks[0].text.trim().length !== 0) {
                let translations = [];
                if(languages && languages.length > 0){
                    for(let i = 0; i < languages.length; i++){
                        if(languages[i].body.getCurrentContent()){
                            let bodyTransRaw = convertToRaw(languages[i].body.getCurrentContent());
                            const bodyTransHtml = draftToHtml(bodyTransRaw);
                            if(bodyTransHtml){
                                translations.push({
                                    id: languages[i].id,
                                    langCode: languages[i].code,
                                    title: languages[i].title,
                                    body: bodyTransHtml,
                                })
                            }
                        }
                    }
                }

                const formData = new FormData();
                formData.append('title', formValues?.[0]?.value);
                formData.append('type', formValues?.[1]?.value);
                formData.append('body', JSON.stringify(bodyHtml));
                formData.append('publish', isPublish);
                formData.append('school', schoolId);
                formData.append('isSchool', isSchool ? 1 : 0);
                formData.append('translations', JSON.stringify(translations));
                formData.append('cover', coverPhoto);
                if(photos && photos.length > 0){
                    for(let i = 0; i < photos.length; i++){
                        formData.append('photos[]', photos[i]);
                    }
                }

                dispatch(setLoading(true));
                fetchRequest(adminNewsfeedAdd, 'POST', formData, false, true)
                    .then(res => {
                        if (res.success) {
                            history.push('/home');
                        } else {
                            message(res?.data?.message);
                            dispatch(setLoading(false));
                        }
                    })
                    .catch(() => {
                        dispatch(setLoading(false));
                        message(t('errorMessage.title'))
                    })
            }
        }
    };

    return (
        <>
            <Card>
                <Card.Header>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <span className='french-blue fs-10'>
                            <b>{t('newsfeed.title').toUpperCase()} {t('common.add').toUpperCase()}</b>
                        </span>
                    </div>
                </Card.Header>
                <Card.Body>
                    <div className='row newfeed-style'>
                        <div className='col-12'>
                            <div className='col-12'>
                                <Forms
                                    ref={forms}
                                    fields={fields}
                                />
                            </div>
                            <p></p>
                            <div className='col-12'>
                                <Editor
                                    editorState={editorState}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    onEditorStateChange={updateTextDescription}
                                    editorStyle={{ border: "1px solid rgba(0, 0, 0, .2)"}}
                                />
                            </div>
                            <div className='col-12'>
                                <Forms
                                    ref={formPhotoRef}
                                    fields={fieldPhotos}
                                />
                            </div>
                            <div className="separator separator-dashed my-7" />
                            <div className='col-12'>
                                {
                                    languages && languages.length > 0 && isLanguage
                                    ?
                                        renderTranslation()
                                    : null
                                }
                            </div>
                        </div>
                    </div>
                </Card.Body>
                <Card.Footer>
                    <div className='col-12 text-center'>
                        <Link
                            to='/home'
                            className="btn btn-link bolder"
                        >
                            {t('common.back')}
                        </Link>
                        <Button variant="success btn-shadow newsfeed-btn mr-2" onClick={() => handleSaveClick(0)}>{t('common.save')}</Button>
                        <Button variant="success btn-shadow " onClick={() => handleSaveClick(1)} >{t('action.post')}</Button>
                    </div>
                </Card.Footer>
            </Card>
        </>
    )
};

