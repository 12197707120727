import React, { useEffect, useRef } from 'react';
import { Button, Modal } from "react-bootstrap";
import Forms from "../../../modules/Form/Forms";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { movementTypeEdit, roomEdit } from "../../../../utils/fetchRequest/Urls";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import message from "../../../modules/message";

const AddRoom = ({
    onClose,
    onSubmit,
    id
}) => {
    const { t } = useTranslation();
    const forms = useRef();
    const dispatch = useDispatch();
    const languages = useSelector(state => state?.languages?.languages || []).filter(lang => lang.code !== 'mn');
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    useEffect(() => {
        if (id) {
            const params = {
                room: id,
                school: schoolId
            }
            dispatch(setLoading(true));
            fetchRequest(roomEdit, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res;

                        if (data?.room) {
                            const room = data?.room;
                            const translations = data?.room.translations;

                            if(translationFields && translationFields.length > 0 && translations && translations.length > 0){
                                for(let i = 0; i < translationFields.length; i++){
                                    for(let t = 0; t < translations.length; t++)
                                        if(translationFields[i]['langCode'] == translations[t]['langCode']){
                                            translationFields[i]['value'] = translations[t]['name'];
                                        }
                                }
                            }

                            if (room) {
                                forms?.current?.updateFields && forms.current.updateFields([
                                    {
                                        label: t('school.classroom.number'),
                                        value: room.roomNumber || '',
                                        type: 'text',
                                        inputWidth: 200,
                                        labelWidth: 300,
                                        required: true,
                                        key: 'roomNumber',
                                    },
                                    {
                                        label: t('school.classroom.seatCount'),
                                        value: room.hasOwnProperty('capacity') && room.capacity !== null ? room.capacity.toString() : '',
                                        type: 'numberOnly',
                                        inputWidth: 200,
                                        labelWidth: 300,
                                        required: true,
                                        key: 'capacity',
                                    },
                                    {
                                        type: 'jsx',
                                        value: <div key={'separator'} className="separator separator-dashed my-7"/>,
                                    },
                                    ...translationFields
                                ]);
                            }
                        }

                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'));
                })
        }
    }, []);

    const translationFields = languages.map(lang => {
        return {
            label: `${t('school.classroom.name')} - ${lang.name} (${lang.code})`,
            value: '',
            langCode: lang.code,
            type: 'text',
            labelWidth: 300,
            inputWidth: 200,
        }
    });

    const fields = [
        {
            label: t('school.classroom.number'),
            value: '',
            type: 'text',
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            key: 'roomNumber',
        },
        {
            label: t('school.classroom.seatCount'),
            value: '',
            type: 'numberOnly',
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            key: 'capacity',
        },
        {
            type: 'jsx',
            value: <div key={'separator'} className="separator separator-dashed my-7"/>,
        },
        ...translationFields
    ];

    const onSaveClick = () => {
        const [ isValid, states, values ] = forms.current.validate();
        if (isValid) {
            const translations = [];
            for (let i = 0; i < states.length; i++) {
                const val = states[i];
                if (i >= 3) {
                    translations.push({
                        langCode: val.langCode,
                        name: val.value,
                    });
                }
            }
            const params = {
                ...values,
                translations: JSON.stringify(translations)
            }
            onSubmit(params);
        }
    }

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('menu.classroom').toUpperCase()}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Forms
                    ref={forms}
                    fields={fields}
                />
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className='btn btn-link bolder'>{t('common.back')}</button>
                <Button variant='success btn-shadow' onClick={onSaveClick}>{t('common.save')}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddRoom;