import React, {useEffect, useState} from 'react'
import {Button, Card, Modal} from 'react-bootstrap'
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import DTable from "../../../../modules/DataTable/DTable";
import {setLoading} from "../../../../../redux/action";
import {fetchRequest} from "../../../../../utils/fetchRequest";
import {invoiceTypeCreate, invoiceTypeInit, invoiceTypeDelete, invoiceTypeEdit} from "../../../../../utils/fetchRequest/Urls";
import message from "../../../../modules/message";
import AddType from "./add";
import EditType from "./edit";

export const InvoiceType = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    const [showAddModal, setShowAddModal] = useState(false);
    const [invoiceTypes, setInvoiceTypes] = useState([]);
    const [showEditModal, setShowEditModal] = useState(false);
    const [typeId, setTypeId] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false);

    const parentSeason = useSelector(state => {
            let schoolList = state?.school?.schoolList;
            if(schoolList && schoolList.length > 0){
                for (let i = 0; i < schoolList.length; i++){
                    if(schoolId == schoolList[i]['id']){
                        return schoolList[i]['parentSeason'];
                    }
                }
            }
        }
    );

    useEffect(() => {
        const params = {
            school: schoolId,
            season: parentSeason,
        };
        init(params)
    }, []);

    const init = (params) => {
        dispatch(setLoading(true));
        fetchRequest(invoiceTypeInit, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setInvoiceTypes(data.invoiceTypes);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const handleAddClick = () => {
        setShowAddModal(true);
    };

    const config = {
        showPagination: true,
        showFilter: true,
    };

    const columns = [
        {
            dataField: "isConstantAmount",
            text: '',
            headerStyle: () => ({
                width: 80,
            }),
            style: {textAlign: 'center'},
            formatter: (cell, row) => {
                if(cell === true){
                    return (
                        <div><span><i className="icon-1_5x main-green fa fa-circle"/></span></div>
                    )
                } else {
                    return (
                        <div><span><i className="icon-1_5x text-dark-50 fa fa-circle"/></span></div>
                    )
                }
            }
        },
        {
            dataField: "code",
            text: t('common.code'),
        },
        {
            dataField: "name",
            text: t('invoiceType.paymentName'),
            sort: true
        },
        {
            dataField: "account",
            text: t('finance.account'),
            style: {textAlign: 'right'},
            sort: true
        },
        {
            dataField: "isEbarimt",
            text: t('invoiceType.isEbarimt'),
            style: {textAlign: 'right'},
            sort: true,
            formatter: (cell, row) => {
                if(cell === true){
                    return (
                        t('common.yes')
                    )
                } else {
                    return (
                        t('common.no')
                    )
                }
            }
        },
        {
            dataField: "ebarimtCode",
            text: t('invoiceType.ebarimtCode'),
            style: {textAlign: 'right'},
            sort: true
        },
    ];

    const contextMenus = [
        {
            key: 'edit',
            title: t('common.edit'),
            icon: <i className='las la-edit dt-cm-item-icon'/>
        },
        {
            key: 'delete',
            title: t('common.delete'),
            icon: <i className='las la-trash-alt dt-cm-item-icon' />,
        },
    ];

    const handleContextMenuClick = (id, key) => {
        if (id && key) {
            if (key === 'edit') {
                setShowEditModal(true);
            } else if (key === 'delete') {
                setDeleteModal(true);
            }
            setTypeId(id);
        }
    };

    const onModalClose = () => {
        setShowAddModal(false);
        setShowEditModal(false);
    };

    const onSubmit = params => {
        const bodyParams = {
            school: schoolId,
            season: parentSeason,
            ...params,
        };

        dispatch(setLoading(true));
        fetchRequest(invoiceTypeCreate, 'POST', bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    message(data?.message || t('common.success'), true);
                    onModalClose();
                    setInvoiceTypes(data.invoiceTypes)
                } else {
                    message(res?.data?.message || t('errorMessage.title'));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const onSubmitEdit = params => {
        const bodyParams = {
            id: typeId,
            school: schoolId,
            season: parentSeason,
            ...params,
        };

        dispatch(setLoading(true));
        fetchRequest(invoiceTypeEdit, 'POST', bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    message(data?.message || t('common.success'), true);
                    onModalClose();
                    setInvoiceTypes(data?.invoiceTypes || []);
                } else {
                    message(res?.data?.message || t('errorMessage.title'));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const handleDeleteModalClose = () => {
        setDeleteModal(false);
    };

    const handlerOnDeleteClick = () => {
        const params = {
            school: schoolId,
            season: parentSeason,
            id: typeId,
        };

        dispatch(setLoading(true));
        fetchRequest(invoiceTypeDelete, 'POST', params, false)
            .then(response => {
                if (response.success) {
                    setDeleteModal(false);
                    setInvoiceTypes(response.data.invoiceTypes);
                    message(response.data.message, true);
                } else {
                    message(response.data.message, false);
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    return (
        <div className='sm-container'>
            <Card>
                <Card.Body>
                    <div className='row'>
                        <div className='col-12'>
                            <button
                                className='btn btn-primary'
                                onClick={handleAddClick}
                            >
                                {t('common.add')}
                            </button>
                        </div>
                        <div className='col-12'>
                            <DTable
                                columns={columns}
                                config={config}
                                data={invoiceTypes}
                                contextMenus={contextMenus}
                                onContextMenuItemClick={handleContextMenuClick}
                                selectMode={'radio'}
                            />
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <Modal
                show={deleteModal}
                onHide={handleDeleteModalClose}
                size="sm"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t('warning.delete')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {t('warning.delete_confirmation')}
                        <br/>
                        <br/>
                        {t('warning.delete_confirmation_description')}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="btn btn-link bolder" onClick={handleDeleteModalClose}>{t('common.cancel')}</Button>
                    <Button variant="success btn-shadow" onClick={handlerOnDeleteClick}>{t('warning.delete')}</Button>
                </Modal.Footer>
            </Modal>
            {
                showAddModal && (
                    <AddType
                        onClose={onModalClose}
                        onSubmit={onSubmit}
                    />
                )
            }
            {
                showEditModal && (
                    <EditType
                        typeId={typeId}
                        parentSeason={parentSeason}
                        onClose={onModalClose}
                        onSubmit={onSubmitEdit}
                    />
                )
            }
        </div>
    )
};
