import React, { useEffect, useMemo, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import {
    certificateDelete,
    certificateList,
} from "../../../../utils/fetchRequest/Urls";
import DTable from "../../../modules/DataTable/DTable";
import DeleteModal from "../../../modules/DeleteModal";
import message from "../../../modules/message";
import AddModal from "./addModal";
import EditModal from "./editModal";
import ViewModal from "./viewModal";
import Select from "app/modules/Form/Select";

const EnrollCertificateModel = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector((state) => state?.selectedSchool?.id || null);

    const [showModal, setShowModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [viewModal, setViewModal] = useState(false);
    const [data, setData] = useState([]);
    const [certificateId, setCertificateId] = useState(null);
    const [searchValues, setSearchValues] = useState(null);
    const [totalCount, setTotalCount] = useState(0);
    const [filter, setFilter] = useState({})

    const [enrollOptions, setEnrollOptions] = useState([])
    const [programOptions, setProgramOptions] = useState([])
    const [phaseOptions, setPhaseOptions] = useState([])

    const onFilterChange = (key, value) => {
        if (key == 'enroll') {
            const { phases, programs } = enrollOptions.find(obj => obj.value == value)
            setPhaseOptions(() => {
                const tempData = []
                phases.map(obj => {
                    tempData.push({
                        value: obj.phaseId,
                        text: obj.phaseName
                    })
                })
                return tempData
            })
            setProgramOptions(() => {
                const tempData = []
                programs.map(obj => {
                    tempData.push({
                        value: obj.programId,
                        text: obj.programName
                    })
                })
                return tempData
            })
        }
        setFilter(prev => {
            let tempFilter = { ...prev }

            if (key == 'enroll') {
                tempFilter = { enroll: value }
            } else {
                tempFilter[key] = value
            }

            getData(searchValues, tempFilter);
            return tempFilter
        })
    }

    useEffect(() => {
        getData();
    }, []);

    const getData = (tableFilter = {}, filter = {}) => {
        dispatch(setLoading(true));
        const params = {
            school: schoolId,
            ...tableFilter,
            ...filter
        }
        fetchRequest(certificateList, "GET", params)
            .then((res) => {
                if (res.success) {
                    const { data: {
                        list = [],
                        totalCount = 0,
                        enrolls = []
                    } = {} } = res;
                    setData(list);
                    setTotalCount(totalCount);
                    setEnrollOptions(() => {
                        const tempData = []
                        enrolls.map(obj => {
                            tempData.push({
                                value: obj.id,
                                text: obj.name + ' ' + obj.educationLevel,
                                phases: obj.phases,
                                programs: obj.programs
                            })
                        })
                        return tempData
                    })
                } else {
                    message(res?.data?.message || t("errorMessage.title"));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t("errorMessage.title"));
            });
    };

    const handleContextMenuClick = (id, key) => {
        if (id && key) {
            if (key === "edit") {
                setCertificateId(id);
                setEditModal(true);
            } else if (key === "delete") {
                setDeleteModal(true);
                setCertificateId(id);
            } else if (key === "view") {
                setCertificateId(id);
                setViewModal(true);
            }
        }
    };

    const onModalClose = () => {
        setShowModal(false);
        setDeleteModal(false);
        setEditModal(false);
        setViewModal(false);
    };

    const handleClick = () => {
        if(filter){
            if(filter.phase){
                setShowModal(true);
            } else {
                message('Үе шат сонгоно уу', false);    
            }
        } else {
            message('Үе шат сонгоно уу', false);
        }
    };

    const onDelete = () => {
        const params = {
            school: schoolId,
            certificateTemplate: certificateId,
        };

        dispatch(setLoading(true));
        fetchRequest(certificateDelete, "POST", params, false)
            .then((res) => {
                if (res.success) {
                    const { data } = res;
                    message(data.message, true);
                    onModalClose();
                    handleOnFinish();
                } else {
                    message(res.data.message, false);
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t("errorMessage.title"));
            });
    };

    const onUserInteraction = (tableFilter) => {
        setSearchValues(tableFilter);
        getData(tableFilter, filter);
    };

    const handleOnFinish = () => {
        getData(searchValues, filter);
    };

    const columns = useMemo(() => [
        {
            dataField: "templateName",
            text: t("enroll.certificateType"),
            sort: true,
        },
        {
            dataField: "enrollName",
            text: t("enroll.enrollmentType"),
            sort: true,
        },
        {
            dataField: "programName",
            text: t('curriculum.profession'),
            sort: true,
        },
        {
            dataField: "phaseName",
            text: t("enroll.phase"),
            sort: false,
        },
    ], [])

    const contextMenus = useMemo(() => [
        {
            key: "view",
            title: t("common.view"),
            icon: <i className="las la-search dt-cm-item-icon" />,
        },
        {
            key: "edit",
            title: t("common.edit"),
            icon: <i className="las la-edit dt-cm-item-icon" />,
        },
        {
            key: "delete",
            title: t("common.delete"),
            icon: <i className="las la-trash-alt dt-cm-item-icon" />,
        },
    ], [])

    const config = useMemo(() => {
        return {
            showPagination: true,
            showFilter: true,
            excelExport: true,
            excelFileName: t("enroll.certificateModel"),
            excelFileRemote: true,
            excelFileRemoteUrl: `certificate/settings/excel`,
            params: {
                school: schoolId,
                ...searchValues,
            },
        }
    }, [])


    const filterOptions = useMemo(() => [
        {
            key: 'enroll',
            text: t('enroll.enrollmentType'),
            options: enrollOptions
        },
        {
            key: 'program',
            text: t('curriculum.profession'),
            options: programOptions
        },
        {
            key: 'phase',
            text: t('enroll.phase'),
            options: phaseOptions
        }
    ], [
        enrollOptions,
        programOptions,
        phaseOptions
    ])

    const renderFilter = () => {
        return filterOptions.map((item, index) => {
            return <Col key={index} md={3} className='mb-2'>
                <Row className='d-flex align-items-center justify-content-between'>
                    <Col md={4}>
                        <label className='mr-2'>{item.text}</label>
                    </Col>
                    <Col md={8}>
                        <Select
                            value={filter[item.key]}
                            onChange={value => onFilterChange(item.key, value)}
                            options={item.options}
                            searchable
                        />
                    </Col>
                </Row>
            </Col>
        })
    }

    return (
        <>
            <Card>
                <Card.Body>
                    <Row className='d-flex justify-content-between align-items-center'>
                        {renderFilter()}
                    </Row>
                </Card.Body>
            </Card>
            <Card>
                <Card.Body>
                    <button
                        className="btn btn-primary btn-shadow"
                        onClick={handleClick}
                    >
                        {t("common.add")}
                    </button>
                    <DTable
                        remote
                        columns={columns}
                        data={data}
                        totalDataSize={totalCount}
                        config={config}
                        showOrdering={true}
                        onInteraction={onUserInteraction}
                        contextMenus={contextMenus}
                        onContextMenuItemClick={handleContextMenuClick}
                        selectMode={"radio"}
                    />
                    {console.log(filter)}
                    {showModal && (
                        <AddModal 
                            onClose={onModalClose} 
                            schoolId={schoolId} 
                            onFinish={handleOnFinish} 
                            filter={filter} 
                        />
                    )}
                    {editModal && (
                        <EditModal
                            onClose={onModalClose}
                            certificateId={certificateId}
                            schoolId={schoolId}
                            onFinish={handleOnFinish}
                            filter={filter}
                        />
                    )}
                    {viewModal && (
                        <ViewModal
                            onClose={onModalClose}
                            certificateId={certificateId}
                            schoolId={schoolId}
                        />
                    )}

                    {!showModal && deleteModal && certificateId && (
                        <DeleteModal
                            onClose={onModalClose}
                            title={t("enroll.deleteCertificateModel")}
                            onDelete={onDelete}
                        >
                            {t("warning.delete_confirmation")}
                            <br />
                            <br />
                            {t("warning.delete_confirmation_description")}
                        </DeleteModal>
                    )}
                </Card.Body>
            </Card>
        </>
    );
};
export default EnrollCertificateModel;
