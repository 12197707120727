import React, {useRef, useEffect, useState} from 'react';
import { Button, Modal } from "react-bootstrap";
import Forms from "../../../modules/Form/Forms";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
    academicProgramList,
    getPrograms,
    invoiceCreate,
    lmsStudentSearch,
    movementCauseEdit, programList
} from "../../../../utils/fetchRequest/Urls";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import message from "../../../modules/message";
import Select from "../../../modules/Form/Select";
import DatePickerRange from "../../../modules/Form/DatePickerRange";

const AddSigning = ({
    selectedTreeId,
    onClose,
    onSubmit,
}) => {
    const { t } = useTranslation();
    const formRef = useRef();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    const [academicPrograms, setAcademicPrograms] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [discounts, setDiscounts] = useState([]);
    const [students, setStudents] = useState([]);
    const [discountId, setDiscountId] = useState(null);
    const [academicProgramId, setAcademicProgramId] = useState(null);
    const [programId, setProgramId] = useState(null);
    const [ studentId, setStudentId ] = useState(null);
    const [ value, setValue ] = useState('');
    const [ startDate, setStartDate ] = useState(null);
    const [ endDate, setEndDate ] = useState(null);

    const parentSeason = useSelector(state => {
            let schoolList = state?.school?.schoolList;
            if(schoolList && schoolList.length > 0){
                for (let i = 0; i < schoolList.length; i++){
                    if(schoolId == schoolList[i]['id']){
                        return schoolList[i]['parentSeason'];
                    }
                }
            }
        }
    );

    useEffect(() => {
        const params = {
            school: schoolId,
            season: parentSeason,
        };
        init(params)
    }, []);

    const init = (params) => {
        dispatch(setLoading(true));
        fetchRequest(academicProgramList, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    if(data.programs && data.programs.length > 0){
                        setAcademicPrograms(data.programs.map(program => ({value: program.id, text: program.name})));
                    }
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(err => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    useEffect(() => {
        formRef?.current?.updateFields && formRef.current?.updateFields(fields);
    }, [academicPrograms, programs]);

    const onAcademicProgramChange = (id, evt) => {
        if (id) {
            setAcademicProgramId(id);
            const params = {
                school: schoolId,
                academicProgram: id,
            };
            dispatch(setLoading(true));
            fetchRequest(programList, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res;
                        if(data.programs && data.programs.length > 0){
                            setPrograms(data.programs);
                        }
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        }
    };

    const onProgramChange = (id, evt) => {
        if(id){
            setProgramId(id);
        }
    };

    const fields = [
        {
            label: t('curriculum.title'),
            value: academicProgramId || null,
            type: 'dropdown',
            required: true,
            options: academicPrograms,
            onChange: onAcademicProgramChange,
            key: 'academicProgram',
            clearable: true
        },
        {
            label: t('curriculum.profession'),
            value: programId || null,
            type: 'dropdown',
            required: true,
            options: programs,
            onChange: onProgramChange,
            key: 'program',
            clearable: true,
            multiple: false,
            searchable: true
        },
    ];

    const onStudentChange = (value) => {
        if(discounts && discounts.length > 0){
            if(discounts.length == 1){
                setDiscountId(discounts[0].value)
            }
        }
        if(value){
            setStudentId(value);
        }
    };

    const onDiscountChange = (id) => {
        if(id){
            setDiscountId(id);
        }
    };

    const onStudentInputChange = (inputValue, actionMeta) => {
        if(inputValue){
            setValue(inputValue);
        }
    };

    useEffect(() => {
        let timeout;
        if (value) {
            timeout = setTimeout(() => {
                const params = {
                    school: schoolId,
                    program: programId,
                    code: value
                };
                dispatch(setLoading(true));
                fetchRequest(lmsStudentSearch, 'GET', params)
                    .then(res => {
                        if (res.success) {
                            const { data } = res;
                            if(data.students && data.students.length > 0){
                                setStudents(data.students.map(student => ({value: student.id, text: student.lastName + ' - ' + student.firstName})));
                            }
                        } else {
                            message(res?.data?.message || t('errorMessage.title'))
                        }
                        dispatch(setLoading(false));
                    })
                    .catch(() => {
                        dispatch(setLoading(false));
                        message(t('errorMessage.title'))
                    })
            }, 1000);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [value]);

    const handerRangePicker = (dates) => {
        if(dates && dates.length > 0){
            setStartDate(dates[0].startDate ? dates[0].startDate : null);
            setEndDate(dates[0].endDate ? dates[0].endDate : null);
        }
    };

    const onSaveClick = () => {
        const [ isValid, states, values ] = formRef.current.validate();
        if (isValid) {
            const params = {
                ...values,
                school: schoolId,
                student: studentId,
                discount: discountId,
                start: startDate,
                end: endDate,
                season: selectedTreeId && selectedTreeId.length > 0 ? selectedTreeId[0] : ''
            };
            onSubmit(params);
        }
    };

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('student.register').toUpperCase()}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Forms
                    ref={formRef}
                    fields={fields}
                />
                <div className='mt-3' style={{ display: 'flex', marginTop: '0.8rem' }}>
                    <label
                        style={{
                            display: 'flex',
                            flex: 1,
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            marginRight : 10,
                            marginBottom: 0,
                            width: 'auto',
                        }}
                    >
                        {t('student.title') + '*'}
                    </label>
                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'column',
                            marginLeft: 10,
                            width: 'auto',
                        }}
                    >
                        <Select
                            value={studentId}
                            searchable
                            onChange={onStudentChange}
                            onInputChange={onStudentInputChange}
                            options={students}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flex: 0.8,
                            flexDirection: 'column',
                            marginLeft: 10,
                            width: 'auto',
                        }}
                    />
                </div>
                <div className='mt-3' style={{ display: 'flex', marginTop: '0.8rem' }}>
                    <label
                        style={{
                            display: 'flex',
                            flex: 1,
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            marginRight : 10,
                            marginBottom: 0,
                            width: 'auto',
                        }}
                    >
                        {t('common.date') + '*'}
                    </label>
                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'column',
                            marginLeft: 10,
                            width: 'auto',
                        }}
                    >
                        <DatePickerRange
                            onChange={handerRangePicker}
                            firstPlaceHolder={t('common.startDate')}
                            lastPlaceHolder={t('common.endDate')}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flex: 0.66,
                            flexDirection: 'column',
                            marginLeft: 10,
                            width: 'auto',
                        }}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className='btn btn-link bolder'>{t('common.back')}</button>
                <Button variant='success btn-shadow' onClick={onSaveClick}>{t('common.create')}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddSigning;