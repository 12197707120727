import React, {useEffect, useState} from 'react'
import DTable from '../../../modules/DataTable/DTable'
import {Button, ButtonToolbar, Card, Nav} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import RenderTableList from "../../../modules/RenderTableList";
import {setLoading} from "../../../../redux/action";
import {fetchRequest} from "../../../../utils/fetchRequest";
import {getJournalDetail} from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import Topic from "./topic";
import Attendance from "./attendance";
import Score from "./score";

export const JournalView = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    const [stringArray, setStringArray] = useState([
        {
            code: 'seasonName',
            name: t('school.season.title'),
            value: ''
        },
        {
            code: 'season',
            name: t('subject.season'),
            value: ''
        },
        {
            code: 'code',
            name: t('subject.code'),
            value: ''
        },
        {
            code: 'name',
            name: t('subject.name'),
            value: ''
        },
        {
            code: 'type',
            name: t('common.type'),
            value: ''
        },
        {
            code: 'day',
            name: t('common.day'),
            value: ''
        },
        {
            code: 'time',
            name: t('common.time'),
            value: ''
        }
    ]);
    const [key, setKey] = useState('#TOPIC');
    const [timetableId, setTimetableId] = useState(null);
    const [types, setTypes] = useState([
        {
            id: 1,
            code: 'TOPIC',
            name: 'Сэдэв',
        },
        {
            id: 2,
            code: 'ATTENDANCE',
            name: 'Ирц',
        },
        {
            id: 3,
            code: 'RESULT',
            name: 'Дүн',
        }
    ]);

    useEffect(() => {
        let timetableId = null;
        let searchUrl = window.location.search;
        let paramString = searchUrl.split('?')[1];
        let paramsArr = paramString.split('&');
        for(let i = 0; i < paramsArr.length; i++) {
            let item = paramsArr[i].split('=');
            if(item[0] == 'timetable'){
                timetableId = item[1];
            }
        }

        setTimetableId(timetableId);

        let params = {
            school: schoolId,
            timetable: timetableId
        };

        init(params)
    },[]);

    const init = (params) => {
        dispatch(setLoading(true));
        fetchRequest(getJournalDetail, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    let journal = data.journal;
                    let stringArray = [];

                    stringArray.push(
                        {
                            code: 'seasonName',
                            name: t('school.season.title'),
                            value: journal?.parentSeason
                        },
                        {
                            code: 'season',
                            name: t('subject.season'),
                            value: journal?.season
                        },
                        {
                            code: 'code',
                            name: t('subject.code'),
                            value: journal?.subjectCode
                        },
                        {
                            code: 'name',
                            name: t('subject.name'),
                            value: journal?.subjectName
                        },
                        {
                            code: 'type',
                            name: t('common.type'),
                            value: journal?.pTime
                        },
                        {
                            code: 'day',
                            name: t('common.day'),
                            value: journal?.day
                        },
                        {
                            code: 'time',
                            name: t('common.time'),
                            value: journal?.timeTemplate + ' (' + journal?.startTime + '-' + journal?.endTime + ')'
                        }
                    );


                    setStringArray(stringArray);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const handleTab = (key, e) => {
        if(key){
            setKey(key);
        }
    };

    const renderTab = () => {
        if(key){
            let selectedTimetableId = null;
            if(!timetableId){
                let searchUrl = window.location.search;
                let paramString = searchUrl.split('?')[1];
                let paramsArr = paramString.split('&');
                for(let i = 0; i < paramsArr.length; i++) {
                    let item = paramsArr[i].split('=');
                    if(item[0] == 'timetable'){
                        selectedTimetableId = item[1];
                    }
                }
            } else {
                selectedTimetableId = timetableId;
            }
            const tabs = {
                TOPIC: <Topic
                    timetableId={selectedTimetableId}
                />,
                ATTENDANCE: <Attendance
                    timetableId={selectedTimetableId}
                />,
                RESULT: <Score
                    timetableId={selectedTimetableId}
                />
            };
            return tabs[key.substring(1)];
        }
    };

    return (
        <div className='sm-container'>
            <Card className='mb-3'>
                <Card.Header>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <span style={{ color: '#4a70ae'}}>
                            <b>{t('teacher.journal').toUpperCase()}</b>
                        </span>
                        <Link to={'/teacher/journal'}>
                            {t('common.backTo_list')}
                        </Link>
                    </div>
                </Card.Header>
                <Card.Body>
                    <RenderTableList
                        firstColNumber={3}
                        stringArray={stringArray}
                        rightStyle={{color: '#4a70ae'}}
                    />
                </Card.Body>
            </Card>
            <Card className='card-header-with-tab'>
                <Card.Header>
                    {
                        types && types.length > 0
                            ?
                            <Nav
                                variant="tabs"
                                defaultActiveKey={key}
                                onSelect={(key, e) => handleTab(key, e)}
                            >
                                {
                                    types.map(type => {
                                        return (
                                            <Nav.Item key={'type_' + type.id}>
                                                <Nav.Link href={`#${type.code}`}>{type.name}</Nav.Link>
                                            </Nav.Item>
                                        )
                                    })
                                }
                            </Nav>
                            : null
                    }
                </Card.Header>
                <Card.Body>
                    {renderTab()}
                </Card.Body>
            </Card>
        </div>
    )
};

export default JournalView;
