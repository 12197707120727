import React, { useRef, useEffect, useState } from 'react';
import { Button, Modal } from "react-bootstrap";
import Forms from "../../../modules/Form/Forms";
import { useTranslation } from "react-i18next";
import { compareAsc } from "date-fns";
import { useSelector, useDispatch } from "react-redux";
import { selectionConfigEdit, schoolClassesBySeasons } from "../../../../utils/fetchRequest/Urls";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import message from "../../../modules/message";

const SelectionEdit = ({
    onClose,
    onSubmit,
    seasonName,
    id,
    school,
    season,
    parentSeasons,
    selection,
    types
}) => {
    const { t } = useTranslation();
    const forms = useRef();
    const dispatch = useDispatch();

    const [seasonIds, setSeasonIds] = useState([]);
    const [classIds, setClassIds] = useState([]);
    const [classes, setClasses] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    useEffect(() => {
        forms?.current?.updateFields && forms.current.updateFields(fields);
    }, [seasonName, seasonIds, startDate, endDate, parentSeasons, classes, classIds]);

    useEffect(() => {
        if (id) {
            const params = {
                config: id,
                school: school,
                season: season,
                selection: selection,
            };
            dispatch(setLoading(true));
            fetchRequest(selectionConfigEdit, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res;
                        if (data?.config) {
                            setStartDate(data?.config?.startDate || null)
                            setEndDate(data?.config?.endDate || null)
                            setSeasonIds(data?.config?.selectedSeasonIds || [])
                            setClasses(data?.config?.classList || [])
                            setClassIds(data?.config?.selectedClassIds || [])
                            // forms?.current?.updateFields && forms.current.updateFields([
                            //     {
                            //         label: t('school.season.title'),
                            //         value: seasonIds,
                            //         type: 'dropdown',
                            //         inputWidth: 200,
                            //         labelWidth: 300,
                            //         multiple: true,
                            //         key: 'seasons',
                            //         options: parentSeasons,
                            //         onChange: onSeasonChange
                            //     },
                            //     {
                            //         label: t('common.className'),
                            //         value: classIds,
                            //         type: 'dropdown',
                            //         multiple: true,
                            //         inputWidth: 200,
                            //         labelWidth: 300,
                            //         key: 'classes',
                            //         options: classes,
                            //         onChange: onClassChange
                            //     },
                            // ]);
                        }
                    } else {
                        message(res?.data?.message || t('errorMessage.title'));
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'));
                })
        }
    }, []);

    function validateCloseDate(closeDate, state, values) {
        const { start } = values;
        if (!closeDate || !start) {
            return {
                valid: false
            }
        }
        const greater = compareAsc(new Date(closeDate), new Date(start));
        if (greater === 1 || greater === 0) {
            return {
                valid: true,
            }
        }
        return {
            valid: false,
            message: 'Хаагдах огноо нээгдэх огнооноос өмнө байж болохгүй'
        }
    }

    const onSeasonChange = (ids) => {
        setSeasonIds(ids)
        setClassIds([])
        let arrayKeys = season.split('_');

        let selectedLevelId = null;
        if(arrayKeys && arrayKeys.length > 0){
            selectedLevelId = arrayKeys[1];
        }

        const params = {
            seasons: JSON.stringify(ids),
            school: school,
            level: selectedLevelId,
        };

        dispatch(setLoading(true));
        fetchRequest(schoolClassesBySeasons, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;

                    setClasses(data?.classes || [])
                } else {
                    message(res?.data?.message || t('errorMessage.title'));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            })
    };

    const onStartChange = (date) => {
        setStartDate(date)
    }

    const onEndChange = (date) => {
        setEndDate(date)
    }

    const onClassChange = (ids) => {
        setClassIds(ids)        
    }

    const fields = [
        {
            label: `${t('subject.season')}`,
            value: seasonName,
            type: 'text',
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            disabled: true,
        },
        {
            key: 'start',
            label: t('setting.selection.openDate'),
            value: startDate,
            type: 'datetime',
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            onChange: onStartChange
        },
        {
            key: 'end',
            label: t('setting.selection.closeDate'),
            value: endDate,
            type: 'datetime',
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            validation: validateCloseDate,
            onChange: onEndChange
        },
        {
            label: t('school.season.title'),
            value: seasonIds,
            type: 'dropdown',
            inputWidth: 200,
            labelWidth: 300,
            multiple: true,
            key: 'seasons',
            options: parentSeasons,
            onChange: onSeasonChange
        },
        {
            label: t('common.className'),
            value: classIds,
            type: 'dropdown',
            multiple: true,
            inputWidth: 200,
            labelWidth: 300,
            key: 'classes',
            options: classes,
            onChange: onClassChange
        },
    ];

    const onSaveClick = () => {
        const [isValid, , values] = forms.current.validate();
        if (isValid) {
            onSubmit(values);
        }
    };

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('setting.selection.title').toUpperCase()}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Forms
                    ref={forms}
                    fields={fields}
                />
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className='btn btn-link bolder'>{t('common.back')}</button>
                <Button variant='success btn-shadow' onClick={onSaveClick}>{t('common.save')}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SelectionEdit;