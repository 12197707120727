import "./enroll.scss";
import Indicator from "./indicator";
import Profession from "./profession";
import CriteriaEnroll from "./criteriaEnroll";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import message from "../../../modules/message";
import Forms from "../../../modules/Form/Forms";
import { Card, Tabs, Tab } from "react-bootstrap";
import { setLoading } from "../../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect, useRef } from "react";
import { fetchRequest } from "../../../../utils/fetchRequest";
import {
    getAddSetting,
    getEditSetting,
} from "../../../../utils/fetchRequest/Urls";

const EditEntry = () => {
    const form = useRef();
    const history = useHistory();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [seasons, setSeasons] = useState([]);
    const [tabKey, setTabKey] = useState(0);
    const [endDate, setEndDate] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [enrollData, setEnrollData] = useState(null);
    const [educationLevels, setEducationLevels] = useState([]);
    const [enrollId, setEnrollId] = useState(
        history?.location?.state?.id || null
    );
    const [isEdit, setIsEdit] = useState(
        history?.location?.state?.isEdit || false
    );
    const [tabIndex] = useState("enroll_index");
    const [programs, setPrograms] = useState([]);
    const [childSeasons, setChildSeasons] = useState([]);
    const [criteria, setCriteria] = useState(null);
    const [attributes, setAttributes] = useState(null);
    const schoolId = useSelector((state) => state?.selectedSchool?.id || null);

    const [educationLevelId, setEducationLevelId] = useState(null)
    const [seasonId, setSeasonId] = useState(null)
    const [childSeasonId, setChildSeasonId] = useState(null)
    const [enrollName, setEnrollName] = useState('')
    const [enrollPhases, setEnrollPhases] = useState([]);

    useEffect(() => {
        getEditData(localStorage.getItem(tabIndex) || 'program');
    }, []);

    const getEditData = (key) => {
        dispatch(setLoading(true));
        const params = {
            school: schoolId,
            enroll: enrollId,
            type: key,
        };

        fetchRequest(getEditSetting, "GET", params)
            .then((res) => {
                if (res.success) {
                    const { data } = res;
                    if (data.enroll) {
                        setEducationLevelId(data?.enroll?.enrollData?.educationLevelId || null)
                        setSeasonId(data?.enroll?.enrollData?.seasonId || null)
                        setChildSeasonId(data?.enroll?.enrollData?.childSeasonId || null)
                        setEnrollName(data?.enroll?.enrollData?.name || '');
                        setStartDate(data?.enroll?.enrollData?.startDate || null);
                        setEndDate(data?.enroll?.enrollData?.endDate || null);
                    }

                    if (data?.enroll?.educationLevels) {
                        const educationLevelOptions = [];
                        data?.enroll?.educationLevels.forEach((level) => {
                            educationLevelOptions.push({ value: level.id, text: level.name });
                        });
                        setEducationLevels(educationLevelOptions);
                    }

                    if (data?.enroll?.seasons) {
                        const seasonsOptions = [];
                        data?.enroll?.seasons.forEach((season) => {
                            seasonsOptions.push({ value: season.id, text: season.name });
                        });
                        setSeasons(seasonsOptions);
                    }

                    if (data?.program.program) {
                        setPrograms(data?.program?.program);
                    }

                    if (data?.criteria) {
                        setCriteria(data?.criteria);
                    }
                    setEnrollPhases(data?.criteria?.enrollPhases || [])

                    if (data?.attribute) {
                        setAttributes(data?.attribute);
                    }

                    setChildSeasons(data?.enroll.childSeasons)
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
            });
    };

    useEffect(() => {
        form?.current?.updateFields && form.current.updateFields(forms);
    }, [educationLevels, seasons, attributes, criteria, childSeasons, educationLevelId, seasonId, childSeasonId, enrollName]);

    useEffect(() => {
        const selectedTab = localStorage.getItem(tabIndex);
        if (selectedTab) {
            setTabKey(selectedTab);
        }
    }, [tabIndex]);

    const onDateRangeChange = (dates) => {
        if (dates && dates.length > 0) {
            if (dates[0].startDate) {
                setStartDate(dates[0].startDate);
            }
            if (dates[0].endDate) {
                setEndDate(dates[0].endDate);
            }
        }
    };

    const handleSave = () => {
        const [formValid, formValue] = form.current.validate();
        if (formValid) {
            const params = {
                school: schoolId,
                educationLevel: educationLevelId,
                season: seasonId,
                childSeason: childSeasonId,
                name: enrollName,
                startDate: startDate,
                endDate: endDate,
                enroll: isEdit ? enrollId : null,
            };
            dispatch(setLoading(true));
            fetchRequest(getEditSetting, "POST", params, false )
                .then((response) => {
                    if (response.success) {
                        message(response.data.message, true);
                        history.push({
                            pathname: "/enroll/setting",
                        });
                    } else {
                        message(response.data.message, false);
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                });
        } else {
        }
    };

    const renderTab = () => {
        const tabs = {
            program: <Profession enrollId={enrollId} programs={programs} />,
            criteria: <CriteriaEnroll enrollId={enrollId} criteria={criteria} enrollPhases={enrollPhases} setSelectedCriteria={(data) => setEnrollPhases(data)}/>,
            attribute: <Indicator enrollId={enrollId} attributes={attributes} />,
        };
        return tabs[tabKey];
    };

    const onTabChange = (key) => {
        setTabKey(key);
        localStorage.setItem(tabIndex, key);
        getEditData(key);
    };

    const onEducationChange = (value) => {
        if(value){
            setEducationLevelId(value)
        }
    };

    const onSeasonChange = (value) => {
        if(value){
            dispatch(setLoading(true));
            const params = {
                school: schoolId,
                season: value
            };

            fetchRequest(getAddSetting, "GET", params)
                .then((res) => {
                    if (res.success) {
                        const { data } = res;
                        setChildSeasons(data?.childSeasons || [])
                        setSeasonId(value)
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                });
        }
    };

    const onChildSeasonChange = (value) => {
        if(value){
            setChildSeasonId(value)
        }
    };

    const onNameChange = (e) => {
        if(e){
            setEnrollName(e.target.value)
        }
    };

    const forms = [
        {
            required: true,
            type: "dropdown",
            multiple: false,
            clearable: true,
            inputWidth: 300,
            key: "educationLevel",
            label: "Боловсролын түвшин",
            options: educationLevels,
            value: educationLevelId,
            onChange: onEducationChange,
        },
        {
            key: "season",
            required: true,
            type: "dropdown",
            multiple: false,
            clearable: true,
            inputWidth: 300,
            options: seasons,
            label: "Хичээлийн жил",
            value: seasonId,
            onChange: onSeasonChange,
        },
        {
            key: "childSeason",
            required: true,
            type: "dropdown",
            multiple: false,
            clearable: true,
            inputWidth: 300,
            options: childSeasons,
            label: "Улирал",
            value: childSeasonId,
            onChange: onChildSeasonChange,
        },
        {
            key: "name",
            required: true,
            type: "text",
            multiple: false,
            clearable: true,
            inputWidth: 300,
            label: "Элсэлтийн төрөл",
            value: enrollName,
            onChange: onNameChange,
        },
        {
            inputWidth: 300,
            key: "dateRange",
            value: "",
            type: "dateTimeRange",
            label: "Элсэлтийн хугацаа",
            lastPlaceHolder: t("common.endDate"),
            firstPlaceHolder: t("common.startDate"),
            selectedStartDate: startDate ? startDate : null,
            selectedEndDate: endDate ? endDate : null,
            onChange: onDateRangeChange,
        },
    ];

    const onClose = () => {
        history.push({
            pathname: '/enroll/setting',
        });
    };

    return (
        <Card>
            <Card.Body>
                <div className="row mb-4">
                    <div className='col-12 d-flex justify-content-center'>
                        <Forms ref={form} fields={forms} />
                    </div>
                </div>
                <div className="row">
                    <div className="mx-auto mt-4">
                    <button onClick={onClose} className="btn btn-link bolder">
                        {t("common.back")}
                    </button>
                        <button
                            type="button"
                            onClick={handleSave}
                            className={`btn btn-success`}
                        >
                            {t("common.save")}
                        </button>
                    </div>
                </div>
            </Card.Body>
            {isEdit ? (
                <div>
                    <Card.Header>
                        <Tabs 
                            activeKey={tabKey} 
                            className='msue-tab'
                            onSelect={onTabChange}
                        >
                            <Tab key={0} title="Мэргэжил" eventKey={"program"} />
                            <Tab key={1} title="Шалгуур" eventKey={"criteria"} />
                            <Tab key={2} title="Шалгуурын үзүүлэлт" eventKey={"attribute"} />
                        </Tabs>
                    </Card.Header>
                    {renderTab()}
                </div>
            ) : (
                <></>
            )}
        </Card>
    );
};

export default EditEntry;
