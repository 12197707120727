import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import PaginationLinks from '../../../modules/DataTable/PaginationLinks';
import {linkify} from "../../../../utils/utils";
import ImageGallery from 'react-image-gallery';

const RenderNewsfeed = ({
    lessonNews = [],
    onDetail = ()=>{},
    totalSize = 1,
    page,
    onPageChange = () =>{}
}) => {
    const {t} = useTranslation();
    const [newsfeed, setNewsfeed] = useState([]);
    const [initLoader, setInitLoader] = useState(true);

    useEffect(() => {
        setNewsfeed(lessonNews);
    }, [lessonNews]);

    const toggleNewsContent = index => {
        const cloneNewsfeed = [...newsfeed];
        if(cloneNewsfeed && cloneNewsfeed.length > 0){
            cloneNewsfeed[index].readMore = false;
            setNewsfeed(cloneNewsfeed);
        }
    };

    const renderBody = (body, readMore, index) => {
        if(body && body.length > 500){
            if(readMore){
                return (
                    <>
                        <div style={{fontSize: 13}} dangerouslySetInnerHTML={ {__html: linkify(body.substring(0, 500), '#5867dd')} }/>
                        <span className='underline-button read-more-position' onClick={() => toggleNewsContent(index)}>
                            {t('common.readMore')}
                        </span>
                    </>
                )
            } else {
                return <div style={{fontSize: 13}} dangerouslySetInnerHTML={ {__html: linkify(body, '#5867dd')} }/>
            }
        } else {
            return <div style={{fontSize: 13}} dangerouslySetInnerHTML={ {__html: linkify(body, '#5867dd')} }/>
        }
    }

    const renderNews = () =>{
        if(lessonNews && lessonNews.length > 0){
            return lessonNews.map((news, index)=>{
                return(
                    <div className='w-100 align-items-center' key={index + '-' + news.id}>
                        <div className='w-100'>
                            <span>
                                <b>{news.subjectCode + ', ' + news.subjectName}</b>
                            </span>
                        </div>
                        <div className='w-100'>
                            <span  style={{ color: '#29569f',fontSize:'16px'}}>
                                <b>{news.title}</b>
                            </span>
                        </div>
                        <div className='w-100'>
                            <span>
                                {t('newsfeed.published') + ': ' + 
                                news.publishedDate?.date?.substring(0,19) + 
                                ', ' + news.firstName}
                            </span>
                        </div>
                        <div className='w-100 my-2'>
                            <div>
                                {
                                    news.isCoverPhoto && 
                                    <div className='mb-5 text-center'>
                                        <img src={news.isCoverPhoto} style={{cursor: 'pointer', maxWidth: 600, maxHeight: 600, height: 'auto', width: 'auto'}}/>
                                    </div>
                                }
                            </div>
                            {renderBody(news?.body, news?.readMore, index)}
                            {
                                news.files && news.files.length > 0 &&
                                <ImageGallery
                                    // ref={i => this._imageGallery = i}
                                    items={news.files} 
                                    showThumbnails={true}
                                    thumbnailPosition={'bottom'}
                                />
                            }
                        </div>
                        {
                            (index % 2) !== 1
                            ? 
                                <div className="separator separator-dashed my-10"></div>
                            : null
                        }
                    </div>
                )
            })
        }else{
            return <p>Зарлал оруулаагүй байна</p>
        }
    }

    return(
        <>
            {renderNews()}
            <div className='d-flex align-items-center justify-content-center'>
                <PaginationLinks 
                    paginationProps={{ 
                        totalSize, 
                        sizePerPage: 2, 
                        page, 
                        paginationSize: 6,
                        onPageChange,
                    }}
                />
            </div>
        </>
    )
}

export default RenderNewsfeed