import React, {useRef, useEffect, useState} from 'react';
import { Button, Modal } from "react-bootstrap";
import Forms from "../../../modules/Form/Forms";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {requestTypeEdit, requestTypeAdd} from "../../../../utils/fetchRequest/Urls";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import message from "../../../modules/message";

const RequestTypeAdd = ({
    onClose,
    onSubmit,
    id
}) => {
    const { t } = useTranslation();
    const forms = useRef();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const languages = useSelector(state => state?.languages?.languages || []).filter(lang => lang.code !== 'mn');

    const translationFields = languages.map(lang => {
        return {
            key: 'language',
            label: `${t('common.name')} - ${lang.name} (${lang.code})`,
            value: '',
            langCode: lang.code,
            type: 'text',
        }
    });

    useEffect(() => {
        let url;
        const params = {
            school: schoolId,
        };

        if (id) {
            url = requestTypeEdit;
            params.id = id;
        } else {
            url = requestTypeAdd
        }

        dispatch(setLoading(true));
        fetchRequest(url, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    const requestTypeObj = data?.requestType || null;
                    const translations = data?.requestType?.translations || [];

                    if(translationFields && translationFields.length > 0 && translations && translations.length > 0){
                        for(let i = 0; i < translationFields.length; i++) {
                            for (let t = 0; t < translations.length; t++){
                                if (translationFields[i]['langCode'] == translations[t]['langCode']) {
                                    translationFields[i]['value'] = translations[t]['name'];
                                }
                            }
                        }
                    }

                    if (requestTypeObj) {
                        forms?.current?.updateFields && forms.current.updateFields([
                            {
                                label: t('common.name'),
                                value: requestTypeObj.name || '',
                                type: 'text',
                                required: true,
                                key: 'name',
                            },
                            {
                                label: t('common.ordering'),
                                value: requestTypeObj.ordering || '',
                                type: 'numberOnly',
                                required: true,
                                key: 'ordering',
                            },
                            {
                                type: 'jsx',
                                value: <div key={'separator'} className="separator separator-dashed my-7"/>,
                            },
                            ...translationFields
                        ]);
                    }
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }, []);

    const fields = [
        {
            label: t('common.name'),
            value: '',
            type: 'text',
            required: true,
            key: 'name',
        },
        {
            label: t('common.ordering'),
            value: '',
            type: 'numberOnly',
            required: true,
            key: 'ordering',
        },
        {
            type: 'jsx',
            value: <div key={'separator'} className="separator separator-dashed my-7"/>,
        },
        ...translationFields
    ];

    const onSaveClick = () => {
        const [ isValid, states, values ] = forms.current.validate();
        if (isValid) {
            if(id){
                values.isSchool = false
            } else {
                if(values.isSchool){
                    values.isSchool = 1
                } else {
                    values.isSchool = 0
                }
            }
            const translations = [];
            for (let i = 0; i < states.length; i++) {
                if (states[i].key && states[i].key == 'language') {
                    translations.push({
                        langCode: states[i].langCode,
                        name: states[i].value,
                    });
                }
            }

            const params = {
                id: id,
                ...values,
                translations: JSON.stringify(translations)
            };
            onSubmit(params);
        }
    };

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('setting.request.type').toUpperCase()} {id ? t('common.edit').toUpperCase() : t('common.add').toUpperCase()}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Forms
                    ref={forms}
                    fields={fields}
                />
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className='btn btn-link bolder'>{t('common.back')}</button>
                <Button variant='success btn-shadow' onClick={onSaveClick}>{t('common.save')}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default RequestTypeAdd;