export const TRANSLATIONS_EN = {
    action: {
        edit: 'Edit',
        datatable_info: 'Showing _START_ to _END_ from _TOTAL_ results',
        delete: 'Delete',
        loading: 'Loading',
        next: 'Дараагийнх',
        post: 'Post',
        publish: 'Publish',
        recalculate: 'Recalculate',
        search: 'Search...',
        setInactive: 'Set inactive',
        typeHere: 'Type here',
        undo: 'Буцаах',
        view: 'View',
    },
    assessment: {
        gpa: 'Голч',
        maxPercent: 'Дээд хувь',
        minPercent: 'Доод хувь',
        quality: 'Чанар',
        rate: 'Эзлэх хувь',
        success: 'Амжилт',
        title: 'Үнэлгээ',
        totalCredit: 'Нийт кредит',
        totalGPA: 'Нийт голч',
        selection: 'Сонголт',
        specification: 'Үнэлгээний үзүүлэлт',
        stTemplate: 'Үнэлгээний бүтэц',
        totalScore: 'Нийт оноо',
        tuition: 'Төлбөр',
    },
    auth: {
        forgotPassword: 'Forgot password',
        loginName: 'Login name',
        password: 'Password',
        recoverPassword: 'Нууц үг сэргээх',
        recoverByEmail: 'Өөрийн бүртгэлтэй и-мэйл хаяг руу авах',
        recoverByPhone: 'Оюутанд олгогдсон (97xxxxxx) дугаараар авах'
    },
    common: {
        add: 'Add',
        addEntry: "Элсэлт нэмэх",
        amount: 'Amount',
        back: 'Back',
        back_to_list: 'Back to list',
        belongsTo: 'Харьяалах',
        cancel: 'Cancel',
        cause: 'Шалтгаан',
        change: 'Өөрчлөх',
        code: 'Code',
        confirmDate: 'Баталгаажуулсан огноо',
        confirmUser: 'Баталгаажуулсан хэрэглэгч',
        create: 'Create',
        createdDate: 'Бүртгэсэн огноо',
        createdUser: 'Бүртгэсэн хэрэглэгч',
        className: 'Class',
        clear: 'Clear',
        country: 'Country',
        close: 'Close',
        changeType: 'Change type',
        changePassword: 'Change password',
        currentType: 'Current type',
        date: 'Огноо',
        delete: 'Delete',
        description: 'Description',
        edit: 'Edit',
        email: 'Email',
        employmentDate: 'Date of employment',
        endDate: 'Дуусах огноо',
        endTime: 'Дуусах цаг',
        exit: 'Close',
        familyName: 'Family name',
        firstName: 'First name',
        gender: 'Gender',
        helper: 'Ашиглах зааварчилгааг энд дарж үзнэ үү',
        isEdit: 'Засах эсэх',
        language: 'Language',
        lastName: 'Parent name',
        movement: 'Movement',
        name: 'Name',
        no: 'No',
        notFound: "Page not found",
        notification: 'Notification',
        newPassword: 'New password',
        newRePassword: 'Repeat new password',
        ordering: 'Дараалал',
        paidAmount: 'Төлсөн дүн',
        paidDate: 'Төлсөн огноо',
        password: 'Password',
        phoneNumber: 'Phone number',
        registered: 'Бүртгэсэн',
        registerNumber: 'Register number',
        save: 'Save',
        select: 'Select',
        selectAll: 'Бүгдийг сонгох',
        selection: 'Сонголт',
        subject: 'Subject',
        success: 'Амжилттай',
        show_result: 'Show result',
        startDate: 'Эхлэх огноо',
        startTime: 'Эхлэх цаг',
        status: 'Status',
        total: 'Total',
        type: 'Type',
        view: 'View',
        weekday: 'Гараг',
        yes: 'Yes',
    },
    curriculum: {
        approach: 'Сургалтын хэлбэр',
        code: 'Curriculum code',
        curriculumType: 'Curriculum type',
        education: 'Education',
        educationLevel: 'Education level',
        educationLevelName: 'Боловсролын зэргийн нэр',
        difficulty: 'Difficulty',
        learningType: 'Хэлбэр',
        name: 'Curriculum name',
        profession: 'Profession',
        previousEducationLevel: 'Previous education level',
        scoreTemplate: 'Score template',
        scoreType: 'Score type',
        scheduleType: 'Type',
        title: 'Curriculum',
        totalCredits: 'Total credits',
        type: 'Сургалтын төрөл',
    },
    datatable:{
        add: 'Add',
        edit: 'Edit',
        delete: 'Delete',
    },
    errorMessage: {
        enterCode: 'Please enter code',
        enterName: 'Please enter name',
        enterSchoolName: 'Please enter school name',
        enterSchoolCode: 'Please enter school code',
        enterSchoolShortName: 'Please enter school short name',
        enterSubjectName: 'Please enter subject name',
        enterFamilyName: 'Please enter family name',
        enterLastName: 'Please enter lastname',
        enterFirstName: 'Please enter firstname',
        enterRegisterNumber: 'Please enter register number',
        enterPhoneNumber: 'Please enter phone number',
        enterEmail: 'Please enter email',
        enterPassword: 'Please enter password',
        enterNewPassword: 'Please enter new password',
        enterNewRePassword: 'Please enter repeat new password',
        enterProfession: 'Please enter profession',
        enterCurriculumSubjectType: 'Судлагдахууны төрөл оруулна уу',
        enterCurriculumCode: 'Хөтөлбөрийн код оруулна уу',
        enterCurriculumStatus: 'Хөтөлбөрийн статус оруулна уу',
        enterApproach: 'Сургалтын хэлбэр оруулна уу',
        enterSubjectCategory: 'Хичээлийн бүлэг оруулна уу',
        enterCurriculumLearningType: 'Судлах төрөл оруулна уу',
        selectDate: 'Select date',
        selectRelatedSchool: 'Please select related school',
        selectRelatedDepartment: 'Please select related department',
        selectSubjectType: 'Please select subject type',
        selectRoles: 'Please select role',
        selectCountry: 'Please select country',
        selectGender: 'Please select gender',
        selectEmploymentDate: 'Please select employment date',
        selectStatus: 'Please select status',
        selectCurriculumType: 'Please select curriculum type',
        selectEducationLevel: 'Please select education level',
        selectCurriculumName: 'Please select curriculum name',
        selectPreEducationLevel: 'Please select previous education level',
        selectScoreTemplate: 'Please select score template',
        selectScoreType: 'Please select score type',
        selectApproach: 'Please select approach',
        title: 'Алдаа гарлаа',
    },
    examType: {
        setting: 'Шалгалтын төрлийн тохиргоо',
        title: 'Шалгалтын төрөл',
    },
    menu: {
        aboutSchool: 'Сургуулийн дэлгэрэнгүй',
        approach: 'Сургалтын хэлбэр',
        department: 'Department',
        classroom: 'Анги, танхим',
        curriculum: 'Curriculum',
        curriculumConfig: 'Curriculum config',
        curriculumSubjectType: 'Судлагдахууны төрөл',
        curriculumStatus: 'Curriculum status',
        curriculumType: 'Curriculum type',
        evaluation: 'Evaluation',
        exam: 'Exam',
        generalInformation: 'General information',
        home: 'Home page',
        homework: 'Homework',
        learningPlan: 'Learning plan',
        learningType: 'Судлах төрөл',
        masterData: 'Master data',
        roles: 'Roles',
        report: 'Report',
        school: 'School',
        schoolComponent: 'Бүрэлдэхүүн сургууль',
        subject: 'Subject',
        subjectCategory: 'Subject category',
        subjectType: 'Subject type',
        structure: 'Structure, organization',
        schoolRoles: 'School roles',
        timetable: 'Timetable',
        teacherStaff: 'Teacher, staff',
    },
    movement: {
        admission: 'Элсэлт',
        cause: 'Шилжилт хөдөлгөөний шалтгаан',
        class: {
            add: 'Анги нэмэх',
            addStudent: 'Оюутан нэмэх',
            admissionCount: 'Авах элсэгчийн тоо',
            admissionSeason: 'Элсэлтийн улирал',
            approach: 'Хичээллэх хэлбэр',
            consultantTeacher: 'Зөвлөх багш',
            studentCount: 'Оюутны тоо',
            teamMember: 'Оюутан холбох',
            type: 'Тэнхим',
        },
        out: {
            remove: 'Student remove',
            prevClass: 'Previous class',
            cause: 'Dropout reason'
        },
        excused: {
            year: 'Жилийн чөлөө',
            add: 'Чөлөө бүртгэх',
            seasons: 'Excused seasons',
            excuseableSeasons: 'Excuseable seasons',
            semester: 'School semester'
        },
        enrolledClass: 'Орсон анги',
        enrollmentClass: 'Шилжих анги',
        enrolledSchool: 'Орсон сургууль',
        external: {
            title: '',
        },
        internal: {
            betweenSchool: 'Сургууль хоорондох шилжилт',
            betweenInfo: 'Сургууль хоорондох шилжилт мэдээлэл',
            inSchool: 'Сургууль доторх шилжилт',
            title: 'Дотоод шилжилт',
            info: 'Дотоод шилжилтийн мэдээлэл'
        },
        leftClass: 'Явсан анги',
        leftSchool: 'Явсан сургууль',
        move: 'Шилжүүлэх',
        movementCause: 'Хөдөлгөөний шалтгаан',
        movementType: 'Хөдөлгөөний төрөл',
        title: 'Шилжилт хөдөлгөөн',
        type: 'Шилжилт хөдөлгөөний төрөл',
    },
    person: {
        birthdate: 'Birth date',
        firstName: 'Нэр',
        lastName: 'Овог',
        registrationNumber: 'Регистрийн дугаар',
    },
    selection: {
        byClass: 'Ангиар',
        byStudent: 'Оюутнаар',
        bySubject: 'Хичээлээр',
        credit: 'Сонгосон кредит',
        date: 'Сонголт хийсэн огноо',
        enterFine: 'Торгууль бүртгэх',
        notCreated: 'Хичээл сонголт үүсээгүй байна',
        paid: 'Төлбөр төлсөн',
        paidDate: 'Төлбөр төлсөн огноо',
        payFine: 'Торгууль төлөх',
        payFineDescription: 'Хичээл сонголт №1 хийх хугацаа дууссан тул Торгууль төлж хичээл сонголтоо хийнэ үү.',
        selected: 'Сонголт хийсэн',
        student: 'Сонгосон оюутан',
    },
    setting: {
        addressSegment: {
            title: 'Аймаг, сум',
        },
        assessment: {
            title: 'Үнэлгээний тохиргоо',
        },
        class: {
            classType: 'Ангийн төрөл',
            classStatus: 'Ангийн төлөв',
            config: 'Ангийн тохиргоо',
        },
        contactType: {
            title: 'Холбоо барих төрөл',
        },
        employeeStatus: 'Ажилтны статус',
        language: {
            title: 'Хэлний тохиргоо',
            system: 'Системийн хэл',
        },
        movement: {
            title: 'Шилжилт хөдөлгөөний тохиргоо',
        },
        reference: 'Лавлах',
        selection: {
            closeDate: 'Хаагдах огноо',
            openDate: 'Нээгдэх огноо',
            title: 'Сонголтын тохиргоо',
        },
        schoolSetting: 'Сургуулийн тохиргоо',
        student: {
            enteringStatus: 'Очих төлөв',
            status: 'Оюутны төлвийн тохиргоо',
            title: 'Оюутны тохиргоо',
        },
        teacherSetting: 'Багшийн тохиргоо',
        teacherStatus: 'Багшийн төлөв',
        timetable: {
            title: 'Хичээлийн хуваарийн тохиргоо',
        },
        title: 'Тохиргоо',
    },
    social: {
        facebookDescription: 'facebook хуудас',
        twitterDescription: 'twitter хуудас',
        youtubeDescription: 'youtube хуудас',
    },
    subject: {
        add: 'Add subject',
        code: 'Subject code',
        credit: 'Credit',
        selected: 'Сонгосон хичээлүүд',
        generalAcademicSubject: 'Ерөнхий эрдмийн хичээл',
        grade: 'Grade',
        hoursPerWeek: 'hours per week',
        index: 'Subject index',
        isPrint: 'Is print',
        maximum: 'Дээд хязгаар',
        minimum: 'Доод хязгаар',
        name: 'Subject name',
        requiredSubjects: 'Заавал судлах хичээлүүд',
        ratingAboveC: 'С дээш үнэлгээ авах',
        season: 'Season',
        seasonOfStudy: 'Season of study',
        studyType: 'Хичээллэх төрөл',
        selectiveSubjects: 'Сонгон судлах хичээлүүд',
        selectSubject: 'Хичээл сонгох',
        subjects: 'Subjects',
        subjectType: 'Судлагдахууны төрөл',
        preSubject: 'Өмнөх холбоос',
        price: 'Price',
        teacher: 'Subject teacher',
        title: 'Subject',
        time: 'Time',
        type: 'Subject type',
        yearOfStudy: 'Season of year',
    },
    system: {
        title: 'School system',
        systemRole: 'Системийн дүр',
    },
    school: {
        title: 'School',
        classroom: {
            title: 'Өрөө',
            number: 'Өрөөний дугаар',
            seatCount: 'Суудлын тоо',
            name: 'Өрөөний нэр',
        },
        code: 'School code',
        shortName: 'School short name',
        name: 'School name',
        parentSchool: 'Related school',
        parentDepartment: 'Related department',
        schoolShift: 'Хичээлийн ээлж',
        shift: {
            name: 'Ээлжийн нэр',
            title: 'Ээлж',
        },
        timeTemplate: {
            title: 'Хонхны хуваарь',
        },
        season: {
            current: 'Энэ хичээлийн жил',
            isAllSeason: 'Бүх улирал эсэх',
            isCurrent: 'Одоогийн улирал эсэх',
            parent: 'Харъяалах жил',
            previous: 'Өмнөх хичээлийн жил',
            previousYear: 'Өмнөх хичээлийн жил',
            seasonType: 'Улирлын төрөл',
            title: 'Хичээлийн жил',
        },
    },
    student: {
        code: 'Оюутны код',
        currentClass: 'Одоо суралцаж байгаа анги',
        name: 'Оюутны нэр',
        schoolEnrollmentDate: 'Сургууль элссэн огноо',
        status: 'Оюутны төлөв',
        title: 'Оюутан',
    },
    teacher: {
        code: 'Teacher code',
        name: 'Teacher name',
        title: 'Teacher',
    },
    timetable: {
        bySubject: 'Хичээлээр',
        byTeacher: 'Багшаар',
        isOnline: 'Цахим эсэх',
        isSelective: 'Чөлөөт сонголт эсэх',
        penalty: 'Торгууль',
        selection: 'Хичээл сонголт',
        selectionOne: 'Хичээл сонголт 1',
        selectionTwo: 'Хичээл сонголт 2',
        title: 'Хичээлийн хуваарь',
        type: 'Хичээлийн хуваарийн төрөл',
    },
    total: {
        subject: 'Нийт хичээлийн тоо',
        credit: 'Нийт кредит',
        price: 'Нийт төлбөр',
    },
    department: {
        addPosition: 'Албан тушаал нэмэх',
        isUsedInCurriculum: 'Хөтөлбөрт ашиглагдах эсэх',
        position: 'Албан тушаал',
        title: 'Алба, нэгж',
    },
    warning: {
        approve: 'Approve',
        approve_confirmation: 'Are you sure?',
        changePasswordDescription: 'Нууц үг солиход хэрэглэгч хуучин нууц үгээрээ орох боломжгүй болохыг анхаарна уу.',
        delete: 'Delete',
        delete_confirmation: 'Are you sure you want to delete it?',
        delete_confirmation_description: 'Please note that one deleted information will not be restored!',
        invalid_parameter: 'There was an error during transmission the information.'
    }
};