import React, { useState, useEffect } from 'react'
import DTable from '../../../../modules/DataTable/DTable'
import {Button, ButtonToolbar, Card, Row, Col} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import DeleteModal from "../../../../modules/DeleteModal";
import {setLoading} from "../../../../../redux/action";
import {fetchRequest, fetchRequestFile} from "../../../../../utils/fetchRequest";
import {teacherDifficultyInit, teacherDifficultyAdd, teacherDifficultyDelete} from "../../../../../utils/fetchRequest/Urls";
import message from "../../../../modules/message";
import {Link} from "react-router-dom";
import { saveAs } from 'file-saver';
import { AddDifficulty } from './add';

export const TeacherExamDifficulty = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [difficulty, setDifficulty] = useState([]);
    const [deleteModal, setDeleteModal] = useState(false);
    const [addModal, setAddModal] = useState(false);
    const [difficultyId, setDifficultyId] = useState(0);
    const [totalData, setTotalData] = useState(0);
    const [code, setCode] = useState(undefined);
    const [name, setName] = useState(undefined);

    const config = {
        showPagination: true,
        showFilter: true,
    };

    const fetchData = (params) => {
        const bodyParams = {
            ...params,
            school: schoolId
        }
        dispatch(setLoading(true));
        fetchRequest(teacherDifficultyInit, 'GET', bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    if(data.difficulty){
                        setDifficulty(data.difficulty);
                        setTotalData(data.difficulty.length)
                    }
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            });
    }

    const inputStyle = {
        border: 'solid 1px #cccccc',
        borderRadius: '4px',
        alignItems: 'center',
        minHeight: '38px',
        width: '100%',
        outline: '0',
        padding: '2px 8px'
    }

    useEffect(() => {
        fetchData();
    },[]);
    const columns = [
        {
            dataField: 'code',
            text: t('common.code'),
            sort: true,
        },
        {
            dataField: 'name',
            text: t('exam.difficulty'),
            sort: true,
        }
    ];


    const contextMenus = [
        {
            key: 'edit',
            title: t('common.edit'),
            icon: <i className='las la-edit dt-cm-item-icon'/>
        },
        {
            key: 'delete',
            title: t('common.delete'),
            icon: <i className='las la-trash-alt dt-cm-item-icon' />,
        },
    ];

    const handleContextMenuClick = (id, key, row) => {
        if (key === 'delete'){
            setDifficultyId(id);
            setDeleteModal(true)
        } else if(key === 'edit'){
            setDifficultyId(id);
            setCode(row.code);
            setName(row.name);
            setAddModal(true);
        }
    };

    const onModalClose = () => {
        setDeleteModal(false);
        setDifficultyId(0);
        fetchData()
    };

    const onDelete = () => {
        const bodyParams = {
            school: schoolId,
            did: difficultyId
        };
        dispatch(setLoading(true));
        fetchRequest(teacherDifficultyDelete, 'POST', bodyParams)
            .then(res => {
                dispatch(setLoading(false));
                if (res.success) {
                    message(t('common.success'), true);
                    onModalClose();
                } else {
                    message(t('errorMessage.difficulty'));
                    //message(res.data.message, false);
                }
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            })
    };

    return (
        
        <div className='sm-container'>
            <Card>
                <Card.Body>
                    <div className='row'>
                        
                        <div className='col-12'>
                            <ButtonToolbar className='d-inline'>
                                <button style={{minWidth:'190px'}} className='btn btn-primary btn-shadow mr-6' onClick={()=>{setAddModal(true)}}>
                                    <b>{t('common.add')}</b>
                                </button>
                            </ButtonToolbar>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12'>
                            <DTable
                                data={difficulty}
                                columns={columns}
                                config={config}
                                totalDataSize={totalData}
                                showOrdering={true}
                                contextMenus={contextMenus}
                                onContextMenuItemClick={handleContextMenuClick}
                                selectMode={'radio'}
                            />
                        </div>
                    </div>
                </Card.Body>
            </Card>
            {
                deleteModal && difficultyId ? 
                    <DeleteModal
                        onClose={()=>{
                            onModalClose()
                        }}
                        onDelete={onDelete}
                        title={t('warning.delete')}>
                        {t('warning.delete_confirmation')}
                        <br />
                        <br />
                        {t('warning.delete_confirmation_description')}
                    </DeleteModal>
                : null
                
            }
            {
                addModal
                ?
                    <AddDifficulty
                        onClose={()=>{
                            setDifficultyId(0);
                            setCode(undefined);
                            setName(undefined);
                            setAddModal(false);
                            fetchData();
                        }}
                        did = {difficultyId}
                        school={schoolId}
                        code = {code}
                        name = {name}
                    />
                : null
            }
        </div>
        
         
    )
};
