import React,{useState,useEffect} from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'react-bootstrap'
import CheckBox from '../../../../modules/Form/Checkbox'
import message from "../../../../modules/message";
import { cloneDeep } from 'lodash';


const Order = ({
    onChange = () =>{}
}) => {
    const {t} = useTranslation()

    const [rows,setRows] = useState([{...defaultRow}])

    const addRow = () => {
        setRows([...rows, { ...defaultRow }])
        onChange([...rows, { ...defaultRow }])
    }

    const removeRow = index => {
        const cloneRows = [...rows]
        cloneRows.splice(index, 1)
        onChange(cloneRows)
        setRows(cloneRows)
    }

    const onRowChange = (index,name,value) =>{
        const tempRows = [...rows]
        if(tempRows[index]){
            tempRows[index][name] = value
        }
        setRows(tempRows)
        onChange(tempRows)
    }

    const onRowUp = (index) =>{
        const tempRows = cloneDeep(rows)
        if(tempRows?.length){
            if(tempRows[index-1]){
                tempRows[index-1].answer = rows[index].answer
                tempRows[index].answer = rows[index-1].answer
            }else{
                tempRows.at(-1).answer = rows[index].answer
                tempRows[index].answer = rows.at(-1).answer
            }
            setRows(tempRows)
            onChange(tempRows)
        }
    }

    const onRowDown = (index) =>{
        const tempRows = cloneDeep(rows)
        if(tempRows?.length){
            if(tempRows[index+1]){
                tempRows[index+1].answer = rows[index].answer
                tempRows[index].answer = rows[index+1].answer
            }else{
                tempRows[0].answer = rows[index].answer
                tempRows[index].answer = rows[0].answer
            }
            setRows(tempRows)
            onChange(tempRows)
        }
    }

    const renderRows = () =>{
        return rows.map((row, index) => {
            return (
                <tr key={'multi_' + index}>
                    <td className='text-center'>
                        <span className='mx-2'>
                            <b>{index+1}</b>
                        </span> 
                    </td>
                    <td style={{minWidth: '260px',position: 'relative'}}>
                        <span style={{position: 'absolute',top:'.35rem',right: '.4rem'}}>
                            <i className="las la-angle-up" onClick={()=>onRowUp(index)}/>
                        </span>
                        <span style={{position: 'absolute',bottom:'.35rem',right: '.4rem'}}>
                            <i className="las la-angle-down" onClick={()=>onRowDown(index)}/>
                        </span>
                        <input
                            className={row.answerErr ? 'form-control is-invalid' : 'form-control'}
                            type='text'
                            onChange={(e) => {
                                onRowChange(index,'answer',e.target.answer)
                            }}
                            value={row.answer}
                        />
                        {
                            row.answerErr
                                ?
                                <div style={style.invalid}>
                                    {t('question.insert')}
                                </div>
                                : null
                        }
                    </td>
                    <td style={style.tdWidth}>
                        {
                            rows.length > 1
                            ?
                                <button className='btn btn-danger row-action-button ml-2' onClick={() => removeRow(index)}>
                                    <i className='las la-times' style={{ fontSize: 14 }} />
                                </button>
                            :
                                null
                        }
                    </td>
                </tr>
            )
        })
    }

    return (
        <div style={style.container}>
            <label style={style.labelContainer}></label>
            <div style={style.inputContainer}>
                <div style={style.inputStyle}>
                    <table width='100%'>
                        <tbody>
                            {renderRows()}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>
                                    <button className='btn btn-primary row-action-button ml-2' onClick={addRow}>
                                        <i className='las la-plus' style={{ fontSize: 18 }} />
                                    </button>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            <div
                style={style.footerContainer}
            />
        </div>
    )
}

const style = {
    container: {
        display: 'flex',
        marginTop: '0.8rem'
    },
    labelContainer: {
        display: 'flex',
        flex:  0.8,
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginRight : 10,
        marginBottom: 0,
        width: 230,
    },
    inputContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        marginLeft: 10,
        width: 'auto',
    },
    inputStyle: {
        display: 'inline-flex',
        marginBottom: 10
    },
    radioStyle: {
        position: 'relative',
        top: 10,
        marginRight: 10,
    },
    footerContainer: {
        display: 'flex',
        flex: 0.6,
        flexDirection: 'column',
        marginLeft: 10,
        width: 'auto',
    },
    iconButtonStyle: {
        width: 36,
        height: 32,
        border: 'none',
        backgroundColor: '#00c4b9',
        alignItems: 'center'
    },
    iconStyle: {
        fontSize: '1.8rem',
        position: 'relative',
        right: 8,
        bottom: 2,
    },
    tdWidth: {
        width: 40
    },
    invalid: {
        width: '100%',
        fontSize: '0.9rem',
        color: '#F64E60',
    }
}

const defaultRow = {
    answer: '',
    value: '',
}

export default Order