import React,{useState,useEffect} from 'react'
import {Card} from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch,useSelector } from 'react-redux'
import { studentOnlineLessonInit } from '../../../../utils/fetchRequest/Urls'
import { fetchRequest } from '../../../../utils/fetchRequest'
import message from '../../../modules/message'
import { setLoading } from '../../../../redux/action'
import RenderNewsfeed from './RenderNewsfeed'
import { useHistory } from 'react-router-dom'

const StudentOnlineLesson = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const history = useHistory()

    const school = useSelector(state => state?.selectedSchool?.id || null)

    const [lessons,setLessons] = useState([])
    const [lessonNews,setLessonNews] = useState([])
    const [totalSize,setTotalSize] = useState(1)
    const [pageNumber,setPageNumber] = useState(1)

    const onDetail = id =>{
        const tempData = [...lessonNews]
        if(tempData.length){
            tempData.map(data=>{
                if(data.id === id){
                    if(data.isViewAll){
                        data.isViewAll = false
                    }else{
                        data.isViewAll = true
                    }
                }
            })
        }
        setLessonNews(tempData)
    }

    const onLessonClick = lesson =>{
        history.push({
            pathname: '/student/online-lesson/detail',
            state: {
                lesson,
            }
        })
    }

    const onPageChange = page =>{
        setPageNumber(page)
        fetchData(page)
    }

    const renderLessonCards = () =>{
        if(lessons.length){
            return lessons.map((lesson,index)=>{
                return(
                    <div style={lesson.isWatched ? isActiveBorder : inActiveBorder} className='my-4' key={index + '-' + lesson.id} onClick={()=>onLessonClick(lesson)}>
                        <div className='w-100 mb-2'>
                            <span>
                                <b>{lesson.subjectCode + ' ' + lesson.subjectName + ', ' + lesson.subjectType}</b>
                            </span>
                        </div>
                        <div className='w-100 my-2'>
                            <span>
                                <b>{lesson.teacherCode + ' ' + lesson.teacherName}</b>
                            </span>
                        </div>
                        <div className='w-100 my-2'>
                            <span>
                                {lesson.duration}
                            </span>
                        </div>
                    </div> 
                )
            })
        }else{
            return null
        }
    }

    const fetchData = page => {
        const bodyParams = {
            page,
            school,
            pageSize: 2
        }

        dispatch(setLoading(true));
        fetchRequest(studentOnlineLessonInit, 'GET', bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res;

                    let newsfeedObj = data?.lessonNews;

                    for (let i = 0; i < newsfeedObj.length; i++) {
                        if(newsfeedObj[i].body && newsfeedObj[i].body.length > 500){
                            newsfeedObj[i].readMore = true;
                        }
                    }

                    if(data?.lessons?.length){
                        setLessons(data.lessons)
                    }

                    setTotalSize(data.totalCount)
                    setLessonNews(newsfeedObj)
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }

    useEffect(()=>{
        const bodyParams = {
            school,
            page: pageNumber,
            pageSize: 2
        }

        fetchData(bodyParams)
    },[])

    return (
        <>
            <Card>
                <Card.Header>
                    <span style={{ color: '#29569f',fontSize:'14px'}}>
                        <b>{t('newsfeed.title').toLocaleUpperCase()}</b>
                    </span>
                </Card.Header>
                <Card.Body>
                    <RenderNewsfeed 
                        lessonNews={lessonNews} 
                        onDetail={onDetail}
                        onPageChange={onPageChange}
                        totalSize={totalSize}
                        page={pageNumber}
                    />
                </Card.Body>
            </Card>
            <Card className='my-4'>
                <Card.Header>
                    <span style={{ color: '#29569f',fontSize:'14px'}}>
                        <b>{t('onlineLesson.watchedLesson').toLocaleUpperCase()}</b>
                    </span>   
                </Card.Header>
                <Card.Body style={{maxHeight: '460px'}}>
                    {renderLessonCards()}
                </Card.Body>
            </Card>
        </>
    )
}

const isActiveBorder = {
    border: 'solid 2px #299f72',
    boxShadow: '0 2px 10px 0 #299f7233',
    borderRadius: '8px',
    padding: '1.5rem'
}

const inActiveBorder = {
    border: 'none',
    boxShadow: '0 2px 10px 0 #919bc033',
    borderRadius: '8px',
    padding: '1.5rem'
}

export default StudentOnlineLesson