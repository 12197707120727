import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import message from "../../../modules/message";
import Forms from "../../../modules/Form/Forms";
import { setLoading } from "../../../../redux/action";
import React, { useRef, useEffect, useState } from "react";
import { fetchRequest } from "../../../../utils/fetchRequest";
import { Button, Modal } from "react-bootstrap";
import {editCandidateProgram} from "../../../../utils/fetchRequest/Urls";
import Select from "app/modules/Form/Select";

const ProgramEdit = ({
    onClose,
    onFinish,
    program,
    id
}) => {
    const { t } = useTranslation();
    const form = useRef();
    const dispatch = useDispatch();
    const [candidateDetail, setCandidateDetail] = useState(null);
    const [candidatePrograms, setCandidatePrograms] = useState(null);
    const schoolId = useSelector((state) => state?.selectedSchool?.id || null);

    const init = () => {
        const params = {
            candidate: id,
            school: schoolId,
            program: program
        };

        dispatch(setLoading(true));
        fetchRequest(editCandidateProgram, "GET", params)
            .then((res) => {
                if (res.success) {
                    const { data } = res;
                    setCandidateDetail(data?.candidateData || null);
                    setCandidatePrograms(data?.candidatePrograms || null);
                } else {
                    message(res?.data?.message || t("errorMessage.title"));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t("errorMessage.title"));
            });
    };

    useEffect(() => {
        init();
    }, []);

    const onSaveClick = () => {
        const params = {
            candidate: id,
            school: schoolId,
            programs: JSON.stringify(candidatePrograms)
        };

        dispatch(setLoading(true));
        fetchRequest(editCandidateProgram, "POST", params)
            .then((res) => {
                if (res.success) {
                    message(res?.data?.message, true);
                    onClose();
                    onFinish(false);
                } else {
                    message(res.data.message, false);
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t("errorMessage.title"));
            });
    };

    const onProgramChange = (value, index) =>{
        let cloneCandidatePrograms = [...candidatePrograms]

        if(cloneCandidatePrograms && cloneCandidatePrograms.length > 0){
            cloneCandidatePrograms[index].programId = value
        }

        setCandidatePrograms(cloneCandidatePrograms)
    }

    const handleTextAreaChange = (e, index) => {
        let cloneCandidatePrograms = [...candidatePrograms]

        if(cloneCandidatePrograms && cloneCandidatePrograms.length > 0){
            cloneCandidatePrograms[index].description = e.target.value
        }

        setCandidatePrograms(cloneCandidatePrograms)
    };

    const renderCandidateProgram = () => {
        let programList = [];
        {
            candidatePrograms && candidatePrograms.length > 0 &&
            candidatePrograms.map((candidateProgram, index) => {
                programList.push(
                    <div key={'candidate_program_' + index} >
                        <div className='row mb-4'>
                            <div className='col-12' style={{display: 'flex', marginTop: 0.8}}>
                                <span style={{ flex: 4, textAlign: 'right', paddingRight: '1.5rem' }}>
                                    <div style={{ width: 300, position: "relative", top: 8}}>
                                        {t('school.title')}
                                    </div>
                                </span>

                                <div style={{ flex: 8 }}>
                                    <div style={{ width: 350 }}>
                                        <Select
                                            className={'default-input-border'}
                                            value={candidateProgram.schoolId}
                                            searchable
                                            options={candidateProgram.schoolList}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mb-4'>
                            <div className='col-12' style={{display: 'flex', marginTop: 0.8}}>
                                <span style={{ flex: 4, textAlign: 'right', paddingRight: '1.5rem' }}>
                                    <div style={{ width: 300, position: "relative", top: 8}}>
                                        {t('enroll.profession')}
                                    </div>
                                </span>

                                <div style={{ flex: 8 }}>
                                    <div style={{ width: 350 }}>
                                        <Select
                                            className={'default-input-border'}
                                            value={candidateProgram.programId}
                                            searchable
                                            onChange={(value) => onProgramChange(value, index)}
                                            options={candidateProgram.programList}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>      
                        <div className='row'>
                            <div className='col-12' style={{display: 'flex', marginTop: 0.8}}>
                                <span style={{ flex: 4, textAlign: 'right', paddingRight: '1.5rem' }}>
                                    <div style={{ width: 300, position: "relative", top: 8}}>
                                        {t('common.description')}
                                    </div>
                                </span>

                                <div style={{ flex: 8 }}>
                                    <div style={{ width: 350 }}>
                                        <textarea
                                            value={candidateProgram?.description || ''}
                                            className={'form-control'}
                                            onChange={(e) => handleTextAreaChange(e, index)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>  
                        {
                            candidatePrograms.length > 1 && <hr style={{ marginTop: 20, marginBottom: 20 }} />
                        }      
                    </div>
                )
            })
        }

        return programList;
    }

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: "#4a70ae", fontSize: "1.1rem" }}>Засах</div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="d-flex justify-content-end col-3 ml-10">
                        {
                            candidateDetail && 
                            <img
                                className="d-flex justify-content-end"
                                style={{
                                    objectFit: "contain",
                                    height: "105px",
                                    width: "93px",
                                    margin: "auto",
                                    marginTop: "0.5px",
                                    marginLeft: "10rem",
                                }}
                                src={
                                    candidateDetail.avatar === null ||
                                        candidateDetail.avatar === "" ||
                                        candidateDetail.avatar === undefined ||
                                        candidateDetail.avatar === "NULL"
                                        ? "/media/users/avatar_male.png"
                                        : "https://darkhanapi.nomch.mn/" + candidateDetail.avatar
                                }
                            />
                        }
                    </div>
                    <ul className="col-7" style={{ lineHeight: 0.5 }}>
                        <li className="d-flex justify-content-center row">
                            <div className="col">
                                <p
                                    style={{
                                        color: "#185BC5",
                                        fontWeight: "bold",
                                        fontSize: "normal",
                                    }}
                                    className=" d-flex justify-content-end"
                                >
                                    Бүртгэлийн дугаар
                                </p>
                            </div>
                            <div className="col justify-content-end">
                                <span style={{ fontWeight: "bold" }} className="text-dark">
                                    {candidateDetail?.candidateNo || ''}
                                </span>
                            </div>
                        </li>
                        <li className="d-flex justify-content-center row pb-3">
                            <div className="col">
                                <p
                                    style={{
                                        color: "#185BC5",
                                        fontWeight: "bold",
                                        fontSize: "normal",
                                    }}
                                    className=" d-flex justify-content-end"
                                >
                                    ЭЕШ бүртгэлийн дугаар
                                </p>
                            </div>
                            <div className="col justify-content-end">
                                <span style={{ fontWeight: "bold" }} className="text-dark">
                                    {candidateDetail?.butName || ''}
                                </span>
                            </div>
                        </li>
                        <li className="d-flex justify-content-center row">
                            <div className="col">
                                <p
                                    style={{
                                        color: "#185BC5",
                                        fontWeight: "bold",
                                    }}
                                    className=" d-flex justify-content-end"
                                >
                                    Овог
                                </p>
                            </div>
                            <div className="col justify-content-end">
                                <span style={{ fontWeight: "bold" }} className="text-dark">
                                    {candidateDetail?.lastName || ''}
                                </span>
                            </div>
                        </li>
                        <li className="d-flex justify-content-center row">
                            <div className="col">
                                <p
                                    style={{
                                        color: "#185BC5",
                                        fontWeight: "bold",
                                        fontSize: "normal",
                                    }}
                                    className=" d-flex justify-content-end"
                                >
                                    Нэр
                                </p>
                            </div>
                            <div className="col justify-content-end">
                                <span style={{ fontWeight: "bold" }} className="text-dark">
                                    {candidateDetail?.firstName || ''}
                                </span>
                            </div>
                        </li>
                        <li className="d-flex justify-content-center row">
                            <div className="col">
                                <p
                                    style={{
                                        color: "#185BC5",
                                        fontWeight: "bold",
                                        fontSize: "normal",
                                    }}
                                    className=" d-flex justify-content-end"
                                >
                                    Регистрийн дугаар
                                </p>
                            </div>
                            <div className="col justify-content-end">
                                <span style={{ fontWeight: "bold" }} className="text-dark">
                                    {candidateDetail?.registerNumber || ''}
                                </span>
                            </div>
                        </li>
                        {
                            candidateDetail && candidateDetail.gender &&
                            <li className="d-flex justify-content-center row">
                                <div className="col">
                                    <p
                                        style={{
                                            color: "#185BC5",
                                            fontWeight: "bold",
                                            fontSize: "normal",
                                        }}
                                        className=" d-flex justify-content-end"
                                    >
                                        Хүйс
                                    </p>
                                </div>
                                <div className="col justify-content-end">
                                    <span style={{ fontWeight: "bold" }} className="text-dark">
                                        {candidateDetail.gender === "M" ? "Эрэгтэй" : "Эмэгтэй"}
                                    </span>
                                </div>
                            </li>
                        }
                        <li className="d-flex justify-content-center row">
                            <div className="col">
                                <p
                                    style={{
                                        color: "#185BC5",
                                        fontWeight: "bold",
                                        fontSize: "normal",
                                    }}
                                    className=" d-flex justify-content-end"
                                >
                                    И-мэйл
                                </p>
                            </div>
                            <div className="col justify-content-end">
                                <span style={{ fontWeight: "bold" }} className="text-dark">
                                    {candidateDetail?.email || ''}
                                </span>
                            </div>
                        </li>
                        <li className="d-flex justify-content-center row">
                            <div className="col">
                                <p
                                    style={{
                                        color: "#185BC5",
                                        fontWeight: "bold",
                                        fontSize: "normal",
                                    }}
                                    className=" d-flex justify-content-end"
                                >
                                    Утасны дугаар
                                </p>
                            </div>
                            <div className="col justify-content-end">
                                <span style={{ fontWeight: "bold" }} className="text-dark">
                                    {candidateDetail?.phoneNumber || ''}
                                </span>
                            </div>
                        </li>
                    </ul>
                </div>
                {
                    renderCandidateProgram()
                }
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className="btn btn-link bolder">
                    {t("common.back")}
                </button>
                <Button variant="success btn-shadow" onClick={onSaveClick}>
                    {t("common.save")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ProgramEdit;
