import React, {useEffect, useState} from 'react'
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import { dateFormat, dateFormatFull } from 'utils/utils';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';

export const TimeSheets = React.forwardRef((props, ref) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {teacher, list, months, pTimeData, educationLevelData, teacherAdditional} = props;

    const getNorm = (text) => {
        if(text){
            if(text == 'Профессор'){
                return 11;    
            } else if(text == 'Дэд профессор'){
                return 13;
            } else if(text == 'Профессорын багын ахлагч' || text == 'Лабораторийн эрхлэгч'){
                return 9;
            } else if(text == 'Ахлах багш'){
                return 15;
            } else if(text == 'Багш'){
                return 16;
            } else if(text == 'Дадлагажигч багш'){
                return 18;
            } else if(text == 'Туслах багш'){
                return 6;
            }
        
            return 0
        }

        return 0
    }

    var date = new Date();

    let totalCredit = 0;
    let totalCreditByLearning = 0;
    let totalWeekCount = 0;
    let totalPerMonth = [];
    let totalEducation = [];
    let totalPTime = [];
    let totalAdditionalCounter = 0;
    let additionalTotalStudent = 0;
    let additionalTotalCreditByLearning = 0;

    return (
        <div 
            ref={ref} 
            style={{marginTop: 10, color: '#000', fontFamily: 'Times New Roman', fontSize: 12}}
        >
            {
                teacher &&
                <div>
                    <div style={{textAlign: 'center', fontFamily: 'Times New Roman', fontSize: 12, fontWeight: 'bold', margin: '15px 60px 10px 50px', textTransform: 'uppercase'}}>
                        БАГШИЙН ЦАГИЙН ТООЦООНЫ НЭГТГЭЛ ХУУДАС
                    </div>

                    <div className="row">
                        <div className="col">
                            <table className='teacher-time-sheet-info-table'>
                                <tbody>
                                    <tr>
                                        <td>{t('teacher.code')}</td>
                                        <th>{teacher?.code || '-'}</th>
                                    </tr>
                                    <tr>
                                        <td>{t('teacher.norm')}</td>
                                        <th>{getNorm(teacher?.position || '')}</th>
                                    </tr>
                                    <tr>
                                        <td>{t('common.date')}</td>
                                        <th>{dateFormat(date)}</th>
                                    </tr>
                                    <tr>
                                        <td>{t('school.season.title')}</td>
                                        <th>{teacher?.parentSeasonName || '-'}</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col">
                            <table className='teacher-time-sheet-info-table'>
                                <tbody>
                                    <tr>
                                        <td>{t('teacher.name')}</td>
                                        <th>{teacher?.firstName || '-'}</th>
                                    </tr>
                                    <tr>
                                        <td>{t('enroll.position')}</td>
                                        <th>{teacher?.position || '-'}</th>
                                    </tr>
                                    <tr>
                                        <td>{t('common.date')}</td>
                                        <th>{dateFormatFull(date)}</th>
                                    </tr>
                                    <tr>
                                        <td>{t('subject.season')}</td>
                                        <th>{teacher?.seasonName || '-'}</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col"></div>
                    </div>

                    <div className='row mt-4'>
                        <div className='col-12'>
                            <table className='table table-bordered teacher-time-sheet-main-table' style={{fontSize: 10}}>
                                <thead>
                                    <tr>
                                        <th rowSpan={2}>№</th>
                                        <th colSpan={3}>Хичээлийн</th>
                                        <th rowSpan={2}>Танхимд гүйцэтгэсэн цаг</th>
                                        <th colSpan={months.length}>Үүнээс</th>
                                        <th rowSpan={2}>Сургалтаар гүйцэтгэсэн кредит</th>
                                        <th colSpan={3}>Үүнээс</th>
                                        <th rowSpan={2}>Илүү кредит</th>
                                        <th colSpan={4}>Оюутны тоо</th>
                                        <th rowSpan={2}>Нэмэлт кредит</th>
                                    </tr>
                                    <tr>
                                        <th>Код</th>
                                        <th>Нэр</th>
                                        <th>Кр</th>
                                        {
                                            months && months.length > 0 &&
                                            months.map((month, index) => {
                                                return (
                                                    <th key={'month_' + index}>{month.name}</th>
                                                )
                                            })
                                        }
                                        {
                                            educationLevelData.map((educationLevel, eIndex) => {
                                                return (
                                                    <th key={'education_main_' + eIndex}>{educationLevel?.name || 0}</th>
                                                )
                                            })
                                        }
                                        {
                                            pTimeData.map((pTime, eIndex) => {
                                                return (
                                                    <th key={'p_time_main_' + eIndex}>{pTime?.name || 0}</th>
                                                )
                                            })
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        list && list.length > 0 &&
                                        list.map((groupData, index) => {
                                            let monthTD = [];

                                            totalCredit = totalCredit + groupData.credit;
                                            totalWeekCount = totalWeekCount + groupData.perWeekCount;
                                            totalAdditionalCounter = parseFloat(totalAdditionalCounter) + parseFloat(groupData.additionalCredit);
                                            totalCreditByLearning = parseFloat(totalCreditByLearning) + parseFloat(groupData.creditByLearning);

                                            groupData.perMonthTimetableCount && groupData.perMonthTimetableCount.length > 0 && months && months.length > 0 &&
                                            months.map((month, pIndex) => {
                                                let totalPerMonthValue = 0;

                                                groupData.perMonthTimetableCount.map((monthData, mIndex) => {
                                                    if(month.id == monthData.id){
                                                        totalPerMonthValue = totalPerMonthValue + monthData.counter;
                                                        monthTD.push(
                                                            <td key={'mount_' + pIndex + '_' + mIndex} className='text-right'>{monthData?.counter || 0}</td>
                                                        )
                                                    }
                                                })

                                                let findData = totalPerMonth.find(perMonth => perMonth.id == month.id)

                                                if(findData){
                                                    findData.totalCount += totalPerMonthValue;
                                                } else {
                                                    totalPerMonth.push({
                                                        id: month.id,
                                                        totalCount: totalPerMonthValue
                                                    })
                                                }
                                            })

                                            let educationTD = [];
                                            groupData.educationLevels && groupData.educationLevels.length > 0 && 
                                            groupData.educationLevels.map((education, eIndex) => {
                                                let totalPerEducationValue = 0;

                                                educationLevelData.map((educationLevel, lIndex) => {
                                                    if(educationLevel.code == education.code){
                                                        totalPerEducationValue = parseFloat(totalPerEducationValue) + parseFloat(education.counter);

                                                        educationTD.push(
                                                            <td key={'education_' + eIndex + '_' + lIndex} className='text-right'>{education?.counter || 0}</td>
                                                        )
                                                    }
                                                })

                                                let findData = totalEducation.find(perEducation => perEducation.code == education.code)

                                                if(findData){
                                                    findData.totalCount += totalPerEducationValue;
                                                } else {
                                                    totalEducation.push({
                                                        code: education.code,
                                                        totalCount: totalPerEducationValue
                                                    })
                                                }
                                            })

                                            let studentCountTD = [];
                                            groupData.pTimeCount && groupData.pTimeCount.length > 0 && 
                                            groupData.pTimeCount.map((pTimeC, cIndex) => {
                                                let totalPerPTimeValue = 0;

                                                pTimeData.map((pTimeD, dIndex) => {
                                                    if(pTimeD.code == pTimeC.code){
                                                        totalPerPTimeValue = totalPerPTimeValue + pTimeC.counter;
                                                        
                                                        studentCountTD.push(
                                                            <td key={'counter_' + cIndex + '_' + dIndex} className='text-right'>{pTimeC?.counter || 0}</td>
                                                        )
                                                    }
                                                })

                                                let findData = totalPTime.find(perPTime => perPTime.code == pTimeC.code)

                                                if(findData){
                                                    findData.totalCount += totalPerPTimeValue;
                                                } else {
                                                    totalPTime.push({
                                                        code: pTimeC.code,
                                                        totalCount: totalPerPTimeValue
                                                    })
                                                }
                                            })

                                            return (
                                                <tr key={'groupData_' + index}>
                                                    <td>{index + 1}</td>
                                                    <td>{groupData.subjectCode}</td>
                                                    <td>{groupData.subjectName}</td>
                                                    <td className='text-right'>{groupData.credit}</td>
                                                    <td className='text-right'>{groupData.perWeekCount}</td>
                                                    {monthTD}
                                                    <td className='text-right'>{groupData.creditByLearning}</td>
                                                    {educationTD}
                                                    <td className='text-right'>{0}</td>
                                                    {studentCountTD}
                                                    <td className='text-right'>{groupData.additionalCredit}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                                <tfoot>
                                    <tr className='text-center'>
                                        <td colSpan={3}><b>Дүн</b></td>
                                        <td className='text-right'>{totalCredit}</td>
                                        <td className='text-right'>{totalWeekCount}</td>
                                        {
                                            totalPerMonth.map((perMonth, index) => {
                                                return(
                                                    <td key={'per_month' + index} className='text-right'>{perMonth.totalCount}</td>
                                                )
                                            })
                                        }
                                        <td className='text-right'>{totalCreditByLearning}</td>
                                        {
                                            totalEducation.map((perEducation, index) => {
                                                return(
                                                    <td key={'per_education' + index} className='text-right'>{perEducation.totalCount}</td>
                                                )
                                            })
                                        }
                                        <td className='text-right'>{0}</td>
                                        {
                                            totalPTime.map((perPTime, index) => {
                                                return(
                                                    <td key={'per_p_time' + index} className='text-right'>{perPTime.totalCount}</td>
                                                )
                                            })
                                        }
                                        <td className='text-right'>{totalAdditionalCounter.toFixed(6)}</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        {
                            teacherAdditional && teacherAdditional.length > 0 &&
                            <div className='col-12'>
                                <div className="mt-3 mb-3 text-center"><b>Төгсөлтийн болон дадлагын кредитийн нэгтгэл</b></div>
                                <div className='table-responsive'>
                                    <table className='table table-bordered teacher-time-sheet-main-table' style={{fontSize: 10}}>
                                        <thead>
                                            <tr>
                                                <th>№</th>
                                                <th>Хичээлийн нэр</th>
                                                <th>Хичээлийн код</th>
                                                <th>Хичээлийн кредит</th>
                                                <th>Дадлагын төрөл</th>
                                                <th>Тайлбар</th>
                                                <th>Оюутны тоо</th>
                                                <th>Сургалтаар гүйцэтгэсэн кредит</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                
                                                teacherAdditional.map((additional, index) => {
                                                    additionalTotalStudent = additionalTotalStudent + additional.studentNumber;
                                                    additionalTotalCreditByLearning = parseFloat(additionalTotalCreditByLearning) + parseFloat(additional.creditByLearning);
                                                    return(
                                                        <tr>
                                                            <td>{index + 1}</td>
                                                            <td>{additional.subjectName}</td>
                                                            <td>{additional.subjectCode}</td>
                                                            <td className='text-right'>{additional.credit}</td>
                                                            <td>{additional.typeName}</td>
                                                            <td>{additional.description}</td>
                                                            <td className='text-right'>{additional.studentNumber}</td>
                                                            <td className='text-right'>{additional.creditByLearning}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                        <tfoot>
                                            <tr className='text-center'>
                                                <td colSpan={6}><b>Дүн</b></td>
                                                <td className='text-right'>{additionalTotalStudent}</td>
                                                <td className='text-right'>{additionalTotalCreditByLearning.toFixed(2)}</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        }
                    </div>
                    <div className='row mt-3'>
                        <div className='col-12 text-center'>
                            Хичээл заасан багш: _______________________________/{teacher.firstName}/
                        </div>
                    </div>
                    <div className='row mt-2'>
                        <div className='col-12 text-center'>
                            <b>Гүйцэтгэсэн хянасан</b>
                        </div>
                    </div>
                    <div className='row mt-2'>
                        <div className='col-12 text-center'>
                            Тэнхимийн эрхлэгч / профессорын багийн ахлагч: _______________________________
                        </div>
                    </div>
                </div>
            }
        </div>
    )
});
