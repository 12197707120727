import React, { useEffect, useMemo, useState } from 'react'
import { Card, Button, Row } from 'react-bootstrap';
import DTable from 'app/modules/DataTable/DTable'
import { useTranslation } from 'react-i18next';
import StudentAddModal from './modals/StudentAddModal';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRequest } from 'utils/fetchRequest';
import message from 'app/modules/message';
import { setLoading } from 'redux/action';
import { graduationCalculateResult, graduationMbContent } from 'utils/fetchRequest/Urls';

import { MAIN_URL } from 'utils/fetchRequest';
import ApproveModal from './modals/ApproveModal';
import DeleteModal from './modals/DeleteModal';

const StudentList = ({
    schoolId = null,
    onTableFilterChange = () => { },
    list = [],
    totalCount = 0,
    showMbHome = () => { },
    showMbAttachment = () => { },
    onCalculateAvg = () => {},
    avgGpa = null
}) => {
    const { t } = useTranslation()

    const dispatch = useDispatch();

    const [total, setTotal] = useState(null)

    const [viewModal, setViewModal] = useState(false)
    const [addModal, setAddModal] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [tableFilter, setTableFilter] = useState({})

    const [tempId, setTempId] = useState(null)

    const pdfDownload = (id) => {
        window.open(MAIN_URL + 'pdf/diploma/print?id=' + id + '&school=' + schoolId, "_blank", "noreferrer");
    }

    const onContextMenuItemClick = (id, key) => {
        if (key === "remove") {
            setDeleteModal(true)
            setTempId(id)
        } else if (key == 'approve') {
            setViewModal(true)
            setTempId(id)
        }
    }

    const onModalClose = () => {
        setTempId(null)
        setViewModal(false)
        setAddModal(false)
        setDeleteModal(false)
        onTableFilterChange(tableFilter)
    }

    const tableConfig = useMemo(() => {
        return {
            showPagination: true,
            excelExport: false,
        }
    }, [])

    const contextMenus = useMemo(() => [
        {
            key: "approve",
            title: t("graduation.approvePage"),
            icon: <i className='las la-search dt-cm-item-icon' />,
        },
        {
            key: "remove",
            title: t("common.remove"),
            icon: <i className="las la-trash-alt dt-cm-item-icon" />,
        },
    ], [])

    const columns = useMemo(() => [
        {
            dataField: "schoolName",
            text: t('graduation.subSchool'),
            sort: true,
        },
        {
            dataField: "programName",
            text: t('graduation.subject'),
            sort: false,
        },
        {
            dataField: "code",
            text: t("student.code"),
            sort: false,
        },
        {
            dataField: "firstName",
            text: t("student.name"),
            sort: false,
        },
        {
            dataField: "lastName",
            text: t("student.lastName"),
            sort: false,
        },
        {
            dataField: "totalCredit",
            text: t("total.credit"),
            sort: false,
        },
        {
            dataField: "studentGpa",
            text: t("assessment.gpa"),
            sort: true,
        },
        {
            dataField: "createdUser",
            text: t("common.registered"),
            sort: true,
        },
        {
            dataField: "id",
            text: '',
            width: 200,
            formatter: (cell, row) => {
                if (!row.isConfirmed) {
                    return null
                }
                return <Button variant="info btn-shadow" onClick={() => {
                    checkGraduationContent(cell)
                }}>
                    {t('enroll.downloadPdf')}
                </Button>
            }
        }
    ], [])

    const checkGraduationContent = (id) => {
        dispatch(setLoading(true));
        const params = {
            school: schoolId,
            id
        }
        fetchRequest(graduationMbContent, 'GET', params)
            .then(res => {
                if (res?.success) {
                    if (res?.data?.homeContent) {
                        if (res?.data?.additionalContent) {
                            pdfDownload(id)
                        } else {
                            showMbAttachment(id)
                        }
                    } else {
                        showMbHome(id)
                    }
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(e => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            });
    }

    return (
        <Card className='mt-4'>
            <Card.Body>
                <Row className='d-flex align-items-center mb-2'>
                    <Button variant="primary btn-shadow align-self-end mr-2" onClick={() => setAddModal(true)}>
                        {t('graduation.addStudent')}
                    </Button>
                    <Button variant="primary btn-shadow align-self-end mr-2" onClick={onCalculateAvg}>
                        {t('graduation.gpaCalculate')}
                    </Button>
                    {
                        avgGpa == null
                            ? null
                            : <h5 className="font-weight-bold">{t("graduation.calculatedGpa")}: {avgGpa}</h5>
                    }
                </Row>
                <DTable
                    remote
                    data={list}
                    config={tableConfig}
                    contextMenus={contextMenus}
                    onContextMenuItemClick={onContextMenuItemClick}
                    onInteraction={params => {
                        setTableFilter(params)
                        onTableFilterChange(params)
                    }}
                    columns={columns}
                    totalDataSize={totalCount}
                    individualContextMenus
                />
            </Card.Body>
            <StudentAddModal school={schoolId} open={addModal} onClose={onModalClose} />
            <ApproveModal school={schoolId} open={viewModal} onClose={onModalClose} id={tempId} />
            <DeleteModal school={schoolId} open={deleteModal} onClose={onModalClose} id={tempId} />
        </Card>
    )
}

export default StudentList
