import React, {useState, useEffect, useRef} from 'react';
import {Card, Button, ButtonToolbar, Modal} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {Link, useHistory, useLocation} from "react-router-dom";
import Forms from "../../../modules/Form/Forms";
import {setLoading} from "../../../../redux/action";
import {fetchRequest} from "../../../../utils/fetchRequest";
import { subjectEdit } from "../../../../utils/fetchRequest/Urls";
import {useDispatch, useSelector} from "react-redux";
import i18n from "i18next";
import message from "../../../modules/message";

const SubjectEdit = ({}) => {
    const { t } = useTranslation();
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();
    const selectedSchool = useSelector(state => state?.selectedSchool || []);
    const languages = useSelector(state => state?.languages?.languages || []).filter(lang => lang.code !== 'mn');

    const form = useRef();
    const translateForm = useRef();

    const [ helperModal, setHelperModal ] = useState(false);
    const [departments, setDepartments] = useState([]);
    const [subjectTypes, setSubjectTypes] = useState([]);
    const [selectedDepartmentId, setSelectedDepartmentId] = useState(location.state.selectedTreeId && location.state.selectedTreeId.length > 0 ? location.state.selectedTreeId[0] : null);
    const [selectedRecord, setSelectedRecord] = useState(location.state.record);

    const translationFields = languages.map(lang => {
        return {
            label: `${t('common.name')} - ${lang.name} (${lang.code})`,
            value: '',
            langCode: lang.code,
            type: 'text',
            inputStyle: lang.code == 'tr' ? {fontFamily: 'Urga'} : {}
        }
    });

    useEffect(() => {
        dispatch(setLoading(true));
        let params = {
            school: selectedSchool.id,
            subject: selectedRecord.id
        };

        fetchRequest(subjectEdit, 'GET', params)
            .then(response => {
                if (response.success) {
                    const { data } = response;
                    const translations = data.subjectData.translations;

                    if(data['departments'] && data['departments'].length){
                        setDepartments(data['departments']/*.map(depart => ({ value: depart.id, text: depart.name }))*/)
                    }
                    if(data['subjectTypes'] && data['subjectTypes'].length){
                        setSubjectTypes(data['subjectTypes'].map(type => ({ value: type.id, text: type.name })))
                    }

                    if (translationFields && translationFields.length > 0 && translations && translations.length > 0)
                    {
                        for (let i = 0; i < translationFields.length; i++)
                        {
                            for (let t = 0; t < translations.length; t++)
                            {
                                if (translationFields[i]['langCode'] == translations[t]['langCode'])
                                {
                                    translationFields[i]['value'] = translations[t]['name'];
                                }
                            }
                        }
                    }
                } else {
                    message(response?.data?.message, false)
                }
                dispatch(setLoading(false));
            });
    }, []);

    useEffect(() => {
        form?.current?.updateFields && form.current.updateFields(forms);
    }, [departments, subjectTypes, selectedDepartmentId, selectedDepartmentId]);

    const forms = [
        {
            label: t('school.parentDepartment') + '*',
            value: selectedDepartmentId,
            errorMessage: t('errorMessage.selectRelatedDepartment'),
            required: true,
            type: 'dropdown',
            options: departments,
            multiple: false,
            clearable: true
        },
        {
            label: t('subject.type') + '*',
            value: selectedRecord.typeId,
            errorMessage: t('errorMessage.selectSubjectType'),
            required: true,
            type: 'dropdown',
            options: subjectTypes,
            multiple: false,
            clearable: true
        },
        {
            key: 'code',
            label: t('common.code') + '*',
            value: selectedRecord && selectedRecord.code ? selectedRecord.code : '',
            errorMessage: t('errorMessage.enterCode'),
            required: true,
            type: 'nonCryllic',
            upperCase: true,
        },
        {
            label: t('subject.name') + '*',
            value: selectedRecord && selectedRecord.name ? selectedRecord.name : '',
            errorMessage: t('errorMessage.enterSubjectName'),
            required: true,
            type: 'text',
        },
    ];

    const handleHelperModal = () => {
        setHelperModal(true)
    }

    const handleHelperModalClose = () => {
        setHelperModal(false)
    }

    const handlerOnSaveClick = () => {
        const [ formValid, formValue ] = form.current.validate();
        const [ translateValid, translateValues ] = translateForm.current.validate();
        if (formValid) {
            dispatch(setLoading(true));

            const params = {
                subject: selectedRecord.id,
                school: selectedSchool.id,
                code: formValue?.[2]?.value,
                name: formValue?.[3]?.value,
            }
            if (formValue?.[0]?.value) {
                params.department = formValue[0].value;
            }
            if(formValue?.[1]?.value){
                params.subjectType = formValue[1].value
            }

            const translations = translateValues.map(lang => {
                return {
                    langCode: lang.langCode,
                    name: lang.value,
                }
            });
            params.translations = JSON.stringify(translations);

            fetchRequest(subjectEdit, 'POST', params)
                .then(response => {
                    if (response.success) {
                        history.push('/school/subject')
                    } else {
                        message(response?.data?.message, false)
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        }
    }

    return (
        <div className='row'>
            <div className='col-12'>
                <Card>
                    <Card.Body>
                        <div className='col-12 text-right'>
                            <Button className='bg-white border-white d-inline btn-hover-less' onClick={handleHelperModal}>
                                <i className="icon-2x text-danger flaticon-questions-circular-button d-inline"></i>
                                <p className='text-danger d-inline'><b>{t('common.helper')}</b></p>
                            </Button>
                        </div>
                        <div className='col-12'>
                            <Forms
                                ref={form}
                                fields={forms}
                            />
                        </div>
                        <div className="separator separator-dashed my-7"></div>
                        <div className='col-12'>
                            <Forms
                                ref={translateForm}
                                fields={translationFields}
                            />
                        </div>
                    </Card.Body>
                    <Card.Footer>
                        <div className='col-12 text-center'>
                            <Link
                                to='/school/subject'
                                className="btn btn-link bolder"
                            >
                                {t('common.back')}
                            </Link>
                            <Button variant="success btn-shadow" onClick={handlerOnSaveClick}>{t('common.save')}</Button>
                        </div>
                    </Card.Footer>
                </Card>
            </div>
            <Modal
                show={helperModal}
                onHide={handleHelperModalClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Modal heading
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Centered Modal</h4>
                    <p>
                        Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
                        dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta
                        ac consectetur ac, vestibulum at eros.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleHelperModalClose}>{t('common.close')}</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
};

export default SubjectEdit;