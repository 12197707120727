import React, {useEffect, useState} from 'react'
import DTable from '../../../../modules/DataTable/DTable'
import { useTranslation } from "react-i18next";
import DeleteModal from '../../../../modules/DeleteModal';
import {AddModal} from "./AddModal";
import {useDispatch, useSelector} from "react-redux";
import {setLoading} from "../../../../../redux/action";
import {fetchRequest} from "../../../../../utils/fetchRequest";
import {lmsAnketExperience} from "../../../../../utils/fetchRequest/Urls";
import message from "../../../../modules/message";

export const WorkExperienceInformation = ({
    studentId
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [experiences, setExperiences] = useState([]);
    const [addModal, setAddModal] = useState(false);
    const [deleteModal,setDeleteModal] = useState(false);
    const [tempId,setTempId] = useState(null);

    useEffect(() => {
        if(schoolId){
            let params = {
                school: schoolId,
                id: studentId
            };

            dispatch(setLoading(true));
            fetchRequest(lmsAnketExperience, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res;
                        let works = data?.works;
                        if(works && works.length > 0){
                            for(let i = 0; i < works.length; i++){
                                if(works[i]['startDate'] && works[i]['startDate'].date){
                                    works[i]['start'] = works[i]['startDate'].date.substring(0, 10);
                                }

                                if(works[i]['endDate'] && works[i]['endDate'].date){
                                    works[i]['end'] = works[i]['endDate'].date.substring(0, 10);
                                }
                            }
                            setExperiences(works);
                        } else {
                            setExperiences([]);
                        }
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'));
                })
        }
    }, []);

    const tableContextItem = [
        // {
        //     key: 'edit',
        //     title: t('common.edit'),
        //     icon: <i className='las la-edit dt-cm-item-icon'/>
        // },
        // {
        //     key: 'delete',
        //     title: t('action.delete'),
        //     icon: <i className='las la-trash-alt dt-cm-item-icon'/>
        // },
    ];

    const config ={
        showPagination: false,
        showFilter: false,
    };

    const columns = [
        {
            dataField: 'workName',
            text: t('questionnaire.employedOrganizations'),
            sort: true,
        },
        {
            dataField: 'position',
            text: t('questionnaire.role'),
            sort: true,
        },
        {
            dataField: 'start',
            text: t('common.employmentDate'),
            sort: true,
        },
        {
            dataField: 'end',
            text: t('questionnaire.employmentEndDate'),
            sort: true,
        },
    ];

    const fields = [
        {
            key: 'work',
            label: t('questionnaire.employedOrganizations') + '*',
            value: '',
            errorMessage: t('errorMessage.enterValue'),
            required: true,
            type: 'text',
        },
        {
            key: 'position',
            label: t('questionnaire.role') + '*',
            value: '',
            errorMessage: t('errorMessage.enterValue'),
            required: true,
            type: 'text',
        },
        {
            key: 'start',
            label: t('common.employmentDate') + '*',
            value: '',
            errorMessage: t('errorMessage.selectDate'),
            required: true,
            type: 'date',
            dateCustomButton: false,
        },
        {
            key: 'end',
            label: t('questionnaire.employmentEndDate') + '*',
            value: '',
            errorMessage: t('errorMessage.selectDate'),
            required: true,
            type: 'date',
            dateCustomButton: false,
        },
    ];

    const handleContextMenuClick = (id, key) => {
        if(id && key) {
            if(key === 'delete'){
                setDeleteModal(true);
                setTempId(id)
                
            }
            if(key === 'edit'){
                setTempId(id)
            }
        }
    };

    const onAddClose = () =>{
        setAddModal(false);
    };

    const onClose = () =>{
        setDeleteModal(false);
        setTempId(null)
    };

    const onDelete = () =>{
        if(tempId){
        }
    };

    const onSubmit = (params) => {
        dispatch(setLoading(true));
        fetchRequest(lmsAnketExperience, 'POST', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    let works = data?.works;
                    if(works && works.length > 0){
                        for(let i = 0; i < works.length; i++){
                            if(works[i]['startDate'] && works[i]['startDate'].date){
                                works[i]['start'] = works[i]['startDate'].date.substring(0, 10);
                            }

                            if(works[i]['endDate'] && works[i]['endDate'].date){
                                works[i]['end'] = works[i]['endDate'].date.substring(0, 10);
                            }
                        }
                        setExperiences(works);
                    } else {
                        setExperiences([]);
                    }
                    onAddClose();
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            });
    };

    return (
        <div style={{border: '1px solid #4a70ae'}}>
            <div className='m-8'>
                {/*<button className='btn btn-primary btn-shadow' onClick={()=>setAddModal(true)}>{t('common.add')}</button>*/}
                <DTable
                    columns={columns}
                    config={config}
                    data={experiences}
                    remote
                    contextMenus={tableContextItem}
                    onContextMenuItemClick={handleContextMenuClick}
                    selectMode={'radio'}
                />
            </div>
            <AddModal
                open={addModal}
                close={onAddClose}
                onSubmit={onSubmit}
                fields={fields}
                title={t('questionnaire.workTitle')}
            />
            {
                deleteModal
                &&
                <DeleteModal 
                    onClose={onClose} 
                    onDelete={onDelete}
                    title={t('questionnaire.deleteWork')}
                >
                    {t('warning.delete_confirmation')}
                    <br/>
                    <br/>
                    {t('warning.delete_confirmation_description')}
                </DeleteModal>
            }
        </div>
    )
};
