import React, { useEffect, useState } from 'react';
import { Card, Button, Col, Row } from 'react-bootstrap';
import { format } from 'date-fns';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from 'redux/action';
import { fetchRequest } from 'utils/fetchRequest';
import { surveyView } from 'utils/fetchRequest/Urls';
import showMessage from 'app/modules/message';
import 'app/css/dashboard.css';

const SurveyViewContainer = () => {
    const history = useHistory();
    const { id } = useParams();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [surveyData, setSurveyData] = useState();
    const { selectedSchool } = useSelector(state => state || null);
    const [surveyId] = useState(history?.location?.state?.survey || null);

    const fetchInfo = async () => {
        let params = {
            school: selectedSchool.id, 
            survey: surveyId,
        }

        dispatch(setLoading(true));
        fetchRequest(surveyView, 'POST', params)
            .then((res) => {
                if (res.success) {
                    const {data} = res;
                    setSurveyData(data.survey)
                } else {
                    showMessage(res.data.message || t('errorMessage.title'));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                showMessage(t('errorMessage.title'));
            });
    };

    useEffect(() => {
        fetchInfo();
    }, [id]);

    return (
        <Card>
            <Card.Header>
                <div className="fs-16 d-flex justify-content-between w-100 align-items-center">
                    <span>{t('survey.title')}</span>
                </div>
            </Card.Header>
            <Card.Body>
                <div className="custom-container">
                    <Row className="mb-1">
                        <Col md={5}>
                            <div className="text-right">{t('survey.status')}</div>
                        </Col>
                        <Col md={7}>
                            <strong className={`custom-badge ${surveyData?.statusCode === 'DRAFT' ? '' : 'custom-badge-active'}`}>{surveyData?.status}</strong> 
                        </Col>
                    </Row>
                    <Row className="mb-1">
                        <Col md={5}>
                            <div className="text-right">{t('common.code')}</div>
                        </Col>
                        <Col md={7}>
                            <strong>{surveyData?.code}</strong>
                        </Col>
                    </Row>
                    <Row className="mb-1">
                        <Col md={5}>
                            <div className="text-right">{t('survey.name')}</div>
                        </Col>
                        <Col md={7}>
                            <strong>{surveyData?.name}</strong>
                        </Col>
                    </Row>
                    <Row className="mb-1">
                        <Col md={5}>
                            <div className="text-right">{t('survey.category')}</div>
                        </Col>
                        <Col md={7}>
                            <strong>{surveyData?.categoryName || '-'}</strong>
                        </Col>
                    </Row>
                    <Row className="mb-1">
                        <Col md={5}>
                            <div className="text-right">{t('survey.date')}</div>
                        </Col>
                        <Col md={7}>
                            <strong>
                                {surveyData?.startDate} - {' '}
                                {surveyData?.endDate}
                            </strong>
                        </Col>
                    </Row>
                    {
                        surveyData?.categoryCode == 'SUBJECT_SURVEY' && 
                        <Row className="mb-1">
                            <Col md={5}>
                                <div className="text-right">{t('survey.participants')}</div>
                            </Col>
                            <Col md={7}>
                                <strong>{surveyData?.subjectName + 'хичээлийг сонгосон бүр сурагчид'}</strong>
                            </Col>
                        </Row>
                    }
                    {
                        surveyData?.categoryCode == 'STUDENT_SCORE' && 
                        <Row className="mb-1">
                            <Col md={5}>
                                <div className="text-right">{t('survey.participants')}</div>
                            </Col>
                            <Col md={7}>
                                <strong>{'Бүх сурагчид'}</strong>
                            </Col>
                        </Row>
                    }
                    <Row className="mb-1">
                        <Col md={5}>
                            <div className="text-right">{t('survey.goal')}</div>
                        </Col>
                        <Col md={7}>
                            <strong>{surveyData?.purpose || '-'}</strong>
                        </Col>
                    </Row>
                </div>
                <div className="my-4 text-right">
                    <strong className="fw-bold font-pd">Нийт: {surveyData?.questions?.length} асуумж</strong>
                </div>
                {surveyData?.questions?.map((q, i) => {
                    const type = surveyData?.questionTypes?.find((tmp) => tmp.id === q.type_id);
                    return (
                        <div className="custom-container mb-4" key={`questions-${i}`}>
                            <div className="custom-q">
                                <span>№{i + 1}.</span>
                                {
                                    q && q.filePath &&
                                    <img class="img-responsive mt-3 mb-4" width='100%' src={q.filePath} alt="Image"></img>
                                }
                                <h4>Асуумж: {q?.question}</h4>
                                <p className="mb-3">Тайлбар: {q?.description}</p>
                                <div className="text-red mb-2">{q.isRequired ? 'Заавал хариулт авна' : ''}</div>
                                <div className="mb-2">
                                    Асуумжийн төрөл: <span className="font-w-bold">{q?.typeName}</span>
                                </div>
                                <div className="d-flex flex-column px-4">
                                    {q.answers?.map((a, j) => (
                                        <label className="mb-2" key={`qa-${i}-${j}`}>
                                            {q?.is_multi_answer ? <input type="checkbox" className='mr-2' disabled name={`q-${i}-${j}`} /> : <input type="radio" className='mr-2' disabled name={`q-${i}-${j}`} />}
                                            {a?.answer}
                                        </label>
                                    ))}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </Card.Body>
            <Card.Footer>
                <div className="text-center">
                    <Link to="/survey/list">
                        <Button size="sm" variant="link">
                            {t('common.back')}
                        </Button>
                    </Link>
                </div>
            </Card.Footer>
        </Card>
    );
};

export default SurveyViewContainer;
