import React,{useEffect,useState, useRef} from 'react'
import {Button, Modal} from "react-bootstrap";
import { fetchRequest } from 'utils/fetchRequest';
import { teacherTimeSheetPrint, teacherTimeSheetView } from 'utils/fetchRequest/Urls';
import { useDispatch,useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setLoading } from "redux/action";
import message from 'app/modules/message';
import { useReactToPrint } from 'react-to-print'
import {TimeSheet} from './timeSheet';
import {TimeSheets} from './timeSheets';

const ViewModal = ({
    onClose = () => {},
    seasonId = null
}) => {
    const teacherTimeSheetRef = useRef();
    const teacherTimeSheetsRef = useRef();

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    const [list, setList] = useState([])
    const [teacherAdditional, setTeacherAdditional] = useState([])
    const [teacher, setTeacher] = useState(null)
    const [subjectData, setSubjectData] = useState(null)
    const [months, setMonths] = useState([])
    const [educationLevelData, setEducationLevelData] = useState([])
    const [pTimeData, setPTimeData] = useState([])
    const [mainSchoolData, setMainSchoolData] = useState([])
    const [schoolData, setSchoolData] = useState([])
    const [sheetList, setSheetList] = useState([])
    const [sheetPTimeData, setSheetPTimeData] = useState([])

    useEffect(()=>{
        if(seasonId){
            const params = {
                school: schoolId,
                season: seasonId
            }
            dispatch(setLoading(true));
            fetchRequest(teacherTimeSheetView, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res;
                        setTeacher(data?.teacher || null)
                        setList(data?.list || [])
                        setMonths(data?.months || [])
                        setEducationLevelData(data?.educationLevelData || [])
                        setPTimeData(data?.pTimeData || [])
                        setTeacherAdditional(data?.teacherAdditional || [])
                        dispatch(setLoading(false));
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                        dispatch(setLoading(false));
                    }
                })
                .catch(err => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        }
    },[seasonId])

    const config = {
        showPagination: false,
        showFilter: false,
        tableMarginLess: true
    };

    const columns = [
        {
            dataField: 'seasonName',
            text: t('subject.season'),
            sort: false,
        },
        {
            dataField: 'day',
            text: t('common.day'),
            sort: false,
        },
        {
            dataField: 'time',
            text: t('common.time'),
            sort: false,
            headerStyle: () => {
                return {
                    width: 170
                }
            },
        },
        {
            dataField: 'studentCount',
            text: t('movement.class.studentCount'),
            sort: false,
        }
    ];

    const handleTeacherTimeSheet = useReactToPrint({
        suppressErrors: true,
        content: () => teacherTimeSheetRef.current,
        onPrintError: () => { message(t('common.error')) },
        pageStyle: '@page{size: auto!important; margin: 0.5cm 1cm!important}',
        documentTitle: `${t('transcript.studentScoreDescription')}`
    })

    const handleTeacherTimeSheets = useReactToPrint({
        suppressErrors: true,
        content: () => teacherTimeSheetsRef.current,
        onPrintError: () => { message(t('common.error')) },
        pageStyle: '@page{size: landscape!important; margin: 0.5cm 1cm!important}',
        documentTitle: `${t('transcript.studentScoreDescription')}`
    })

    const printTimeSheets = () => {
        handleTeacherTimeSheets();
    }

    const teacherSheetPrint = (row) => {
        if(seasonId){
            const params = {
                school: schoolId,
                season: seasonId,
                subject: row.subjectId
            }
            dispatch(setLoading(true));
            fetchRequest(teacherTimeSheetPrint, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res;

                        setMainSchoolData(data?.mainSchoolData || [])
                        setSchoolData(data?.schoolData || [])
                        setSheetList(data?.list || [])
                        setSheetPTimeData(data?.pTimeData || [])
                        setSubjectData(data?.subjectData || null)
                        dispatch(setLoading(false));

                        handleTeacherTimeSheet();
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                        dispatch(setLoading(false));
                    }
                })
                .catch(err => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        }
    }

    const getNorm = (text) => {
        if(text){
            if(text == 'Профессор'){
                return 11;    
            } else if(text == 'Дэд профессор'){
                return 13;
            } else if(text == 'Профессорын багын ахлагч' || text == 'Лабораторийн эрхлэгч'){
                return 9;
            } else if(text == 'Ахлах багш'){
                return 15;
            } else if(text == 'Багш'){
                return 16;
            } else if(text == 'Дадлагажигч багш'){
                return 18;
            } else if(text == 'Туслах багш'){
                return 6;
            }
        
            return 0
        }

        return 0
    }

    let totalCredit = 0;
    let totalCreditByLearning = 0;
    let totalWeekCount = 0;
    let totalPerMonth = [];
    let totalEducation = [];
    let totalPTime = [];
    let totalAdditionalCounter = 0;
    let additionalTotalStudent = 0;
    let additionalTotalCreditByLearning = 0;
    return (
        <>
            <div className=''>
                <TimeSheet 
                    ref={teacherTimeSheetRef} 
                    teacherData={teacher}
                    subjectData={subjectData}
                    mainSchoolData={mainSchoolData}
                    schoolData={schoolData}
                    sheetList={sheetList}
                    sheetPTimeData={sheetPTimeData}
                />
            </div>
            <div className='d-none'>
                <TimeSheets 
                    ref={teacherTimeSheetsRef} 
                    teacher={teacher} 
                    pTimeData={pTimeData} 
                    list={list} 
                    months={months} 
                    educationLevelData={educationLevelData} 
                    teacherAdditional={teacherAdditional}
                />
            </div>
            <Modal
                show={true}
                onHide={onClose}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                            {t('teacher.timeSheets')}
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col">
                            <table className='info-table' style={{margin: 'auto'}}>
                                <tbody>
                                    <tr>
                                        <td>{t('teacher.code')}</td>
                                        <th>{teacher?.code || '-'}</th>
                                    </tr>
                                    <tr>
                                        <td>{t('teacher.norm')}</td>
                                        <th>{getNorm(teacher?.position || '')}</th>
                                    </tr>
                                    <tr>
                                        <td>{t('school.season.title')}</td>
                                        <th>{teacher?.parentSeasonName || '-'}</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col">
                            <table className='info-table' style={{margin: 'auto'}}>
                                <tbody>
                                    <tr>
                                        <td>{t('teacher.name')}</td>
                                        <th>{teacher?.firstName || '-'}</th>
                                    </tr>
                                    <tr>
                                        <td>{t('enroll.position')}</td>
                                        <th>{teacher?.position || '-'}</th>
                                    </tr>
                                    <tr>
                                        <td>{t('subject.season')}</td>
                                        <th>{teacher?.seasonName || '-'}</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col"></div>
                    </div>
                    <div className='row mt-4'>
                        <div className='col-12'>
                            <div className='table-responsive'>
                                <table className='table table-bordered'>
                                    <thead>
                                        <tr>
                                            <th rowSpan={2}>№</th>
                                            <th colSpan={3}>Хичээлийн</th>
                                            <th rowSpan={2}>Танхимд гүйцэтгэсэн цаг</th>
                                            <th colSpan={months.length}>Үүнээс</th>
                                            <th rowSpan={2}>Сургалтаар гүйцэтгэсэн кредит</th>
                                            <th colSpan={3}>Үүнээс</th>
                                            <th rowSpan={2}>Илүү кредит</th>
                                            <th colSpan={4}>Оюутны тоо</th>
                                            <th rowSpan={2}>Нэмэлт кредит</th>
                                            <th rowSpan={2}></th>
                                        </tr>
                                        <tr>
                                            <th>Код</th>
                                            <th>Нэр</th>
                                            <th>Кр</th>
                                            {
                                                months && months.length > 0 &&
                                                months.map((month, index) => {
                                                    return (
                                                        <th key={'month_' + index}>{month.name}</th>
                                                    )
                                                })
                                            }
                                            {
                                                educationLevelData.map((educationLevel, eIndex) => {
                                                    return (
                                                        <th key={'education_main_' + eIndex}>{educationLevel?.name || 0}</th>
                                                    )
                                                })
                                            }
                                            {
                                                pTimeData.map((pTime, eIndex) => {
                                                    return (
                                                        <th key={'p_time_main_' + eIndex}>{pTime?.name || 0}</th>
                                                    )
                                                })
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            list && list.length > 0 &&
                                            list.map((groupData, index) => {
                                                let monthTD = [];

                                                totalCredit = totalCredit + groupData.credit;
                                                totalWeekCount = totalWeekCount + groupData.perWeekCount;
                                                totalAdditionalCounter = parseFloat(totalAdditionalCounter) + parseFloat(groupData.additionalCredit);
                                                totalCreditByLearning = parseFloat(totalCreditByLearning) + parseFloat(groupData.creditByLearning);

                                                groupData.perMonthTimetableCount && groupData.perMonthTimetableCount.length > 0 && months && months.length > 0 &&
                                                months.map((month, pIndex) => {
                                                    let totalPerMonthValue = 0;
                                                    groupData.perMonthTimetableCount.map((monthData, mIndex) => {
                                                        if(month.id == monthData.id){
                                                            totalPerMonthValue = totalPerMonthValue + monthData.counter;
                                                            monthTD.push(
                                                                <td key={'mount_' + pIndex + '_' + mIndex} className='text-right'>{monthData?.counter || 0}</td>
                                                            )
                                                        }
                                                    })

                                                    let findData = totalPerMonth.find(perMonth => perMonth.id == month.id)

                                                    if(findData){
                                                        findData.totalCount += totalPerMonthValue;
                                                    } else {
                                                        totalPerMonth.push({
                                                            id: month.id,
                                                            totalCount: totalPerMonthValue
                                                        })
                                                    }
                                                })

                                                // хэрэв perMonthTimetableCount дата байхгүй бол хоосон render хийнэ
                                                groupData.perMonthTimetableCount && groupData.perMonthTimetableCount.length == 0 &&
                                                months.map((month, pIndex) => {
                                                    monthTD.push(
                                                        <td key={'mount_none_' + pIndex} className='text-right'>{0}</td>
                                                    )

                                                    let findData = totalPerMonth.find(perMonth => perMonth.id == month.id)

                                                    if(findData){
                                                        findData.totalCount = 0;
                                                    } else {
                                                        totalPerMonth.push({
                                                            id: month.id,
                                                            totalCount: 0
                                                        })
                                                    }
                                                })

                                                let educationTD = [];
                                                groupData.educationLevels && groupData.educationLevels.length > 0 && 
                                                groupData.educationLevels.map((education, eIndex) => {
                                                    let totalPerEducationValue = 0;

                                                    educationLevelData.map((educationLevel, lIndex) => {
                                                        if(educationLevel.code == education.code){
                                                            totalPerEducationValue = parseFloat(totalPerEducationValue) + parseFloat(education.counter);
                                                            educationTD.push(
                                                                <td key={'education_' + eIndex + '_' + lIndex} className='text-right'>{education?.counter || 0}</td>
                                                            )
                                                        }
                                                    })

                                                    let findData = totalEducation.find(perEducation => perEducation.code == education.code)

                                                    if(findData){
                                                        findData.totalCount += totalPerEducationValue;
                                                    } else {
                                                        totalEducation.push({
                                                            code: education.code,
                                                            totalCount: totalPerEducationValue
                                                        })
                                                    }
                                                })

                                                let studentCountTD = [];
                                                groupData.pTimeCount && groupData.pTimeCount.length > 0 && 
                                                groupData.pTimeCount.map((pTimeC, cIndex) => {
                                                    let totalPerPTimeValue = 0;

                                                    pTimeData.map((pTimeD, dIndex) => {
                                                        if(pTimeD.code == pTimeC.code){
                                                            totalPerPTimeValue = totalPerPTimeValue + pTimeC.counter;
                                                            studentCountTD.push(
                                                                <td key={'counter_' + cIndex + '_' + dIndex} className='text-right'>{pTimeC?.counter || 0}</td>
                                                            )
                                                        }
                                                    })

                                                    let findData = totalPTime.find(perPTime => perPTime.code == pTimeC.code)

                                                    if(findData){
                                                        findData.totalCount += totalPerPTimeValue;
                                                    } else {
                                                        totalPTime.push({
                                                            code: pTimeC.code,
                                                            totalCount: totalPerPTimeValue
                                                        })
                                                    }
                                                })

                                                return (
                                                    <tr key={'groupData_' + index}>
                                                        <td>{index + 1}</td>
                                                        <td>{groupData.subjectCode}</td>
                                                        <td>{groupData.subjectName}</td>
                                                        <td className='text-right'>{groupData.credit}</td>
                                                        <td className='text-right'>{groupData.perWeekCount}</td>
                                                        {monthTD}
                                                        <td className='text-right'>{groupData.creditByLearning}</td>
                                                        {educationTD}
                                                        <td className='text-right'>{0}</td>
                                                        {studentCountTD}
                                                        <td className='text-right'>{groupData.additionalCredit}</td>
                                                        <td className='text-right'><button onClick={() => teacherSheetPrint(groupData)} className='btn btn-purple'>{t('common.print')}</button></td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                    <tfoot>
                                        <tr className='text-center'>
                                            <td colSpan={3}><b>Дүн</b></td>
                                            <td className='text-right'>{totalCredit}</td>
                                            <td className='text-right'>{totalWeekCount}</td>
                                            {
                                                totalPerMonth.map((perMonth, index) => {
                                                    return(
                                                        <td key={'per_month' + index} className='text-right'>{perMonth.totalCount}</td>
                                                    )
                                                })
                                            }
                                            <td className='text-right'>{totalCreditByLearning}</td>
                                            {
                                                totalEducation.map((perEducation, index) => {
                                                    return(
                                                        <td key={'per_education' + index} className='text-right'>{perEducation.totalCount}</td>
                                                    )
                                                })
                                            }
                                            <td className='text-right'>{0}</td>
                                            {
                                                totalPTime.map((perPTime, index) => {
                                                    return(
                                                        <td key={'per_p_time' + index} className='text-right'>{perPTime.totalCount}</td>
                                                    )
                                                })
                                            }
                                            <td className='text-right'>{totalAdditionalCounter.toFixed(6)}</td>
                                            <td className='text-right'></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                        {
                            teacherAdditional && teacherAdditional.length > 0 &&
                            <div className='col-12'>
                                <div className="mt-3 mb-3"><b>Төгсөлтийн болон дадлагын кредитийн нэгтгэл</b></div>
                                <div className='table-responsive'>
                                    <table className='table table-bordered'>
                                        <thead>
                                            <tr>
                                                <th>№</th>
                                                <th>Хичээлийн нэр</th>
                                                <th>Хичээлийн код</th>
                                                <th>Хичээлийн кредит</th>
                                                <th>Дадлагын төрөл</th>
                                                <th>Тайлбар</th>
                                                <th>Оюутны тоо</th>
                                                <th>Сургалтаар гүйцэтгэсэн кредит</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                teacherAdditional.map((additional, index) => {
                                                    additionalTotalStudent = additionalTotalStudent + additional.studentNumber;
                                                    additionalTotalCreditByLearning = parseFloat(additionalTotalCreditByLearning) + parseFloat(additional.creditByLearning);
                                                    return(
                                                        <tr key={'additional_' + index}>
                                                            <td>{index + 1}</td>
                                                            <td>{additional.subjectName}</td>
                                                            <td>{additional.subjectCode}</td>
                                                            <td className='text-right'>{additional.credit}</td>
                                                            <td>{additional.typeName}</td>
                                                            <td>{additional.description}</td>
                                                            <td className='text-right'>{additional.studentNumber}</td>
                                                            <td className='text-right'>{additional.creditByLearning}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                        <tfoot>
                                            <tr className='text-center'>
                                                <td colSpan={6}><b>Дүн</b></td>
                                                <td className='text-right'>{additionalTotalStudent}</td>
                                                <td className='text-right'>{additionalTotalCreditByLearning.toFixed(2)}</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={onClose} className='btn btn-link bolder'>{t('common.back')}</button>
                    <button
                        onClick={printTimeSheets}
                        className='btn btn-purple'
                    >
                        {t('common.print')}
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ViewModal