import React, {useRef, useEffect, useState} from 'react';
import {Button, ButtonToolbar, Card, Col, Row, Nav, Tab, Modal} from "react-bootstrap";
import Forms from "../../../../modules/Form/Forms";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {teacherOnlineLessonResult, teacherOnlineLessonResultRecalculate, teacherOnlineLessonResultSave} from "../../../../../utils/fetchRequest/Urls";
import { setLoading } from "../../../../../redux/action";
import { fetchRequest } from "../../../../../utils/fetchRequest";
import message from "../../../../modules/message";
import {Link, useHistory} from "react-router-dom";
import DTable from "../../../../modules/DataTable/DTable";
import {cloneDeep} from "lodash";
import { select } from 'redux-saga/effects';

const style = {
    cardBody: {
        position: 'relative',
        bottom: '4px',
        zIndex: 10
    },
    cantEditPublish: {
        position: 'relative',
        top: 8,
        fontWeight: 'bold',
        color: '#d84434',
        marginLeft: 15
    }
}

export const Result = ({
    id
}) => {
    const { t } = useTranslation();
    const datatableRef = useRef();
    const history = useHistory();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [isEdit, setIsEdit] = useState(false);
    const [reload, setReload] = useState(false);
    const [publishModal, setPublishModal] = useState(false);
    const [recalculateModal, setRecalculateModal] = useState(false);
    const [groups, setGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(null);

    const [oldStudentsData, setOldStudentsData] = useState([
        {
            id: 1,
            firstName: 'galaa',
            score: '23'
        },
        {
            id: 2,
            firstName: 'galaa1',
            score: '232'
        },
        {
            id: 3,
            firstName: 'galaa2',
            score: '232',
            totalScore: 98
        }
    ]);

    const [students, setStudents] = useState([
        {
            id: 1,
            firstName: 'galaa',
            score: '23'
        },
        {
            id: 2,
            firstName: 'galaa1',
            score: '232'
        },
        {
            id: 3,
            firstName: 'galaa2',
            score: '232',
            totalScore: 98
        }
    ]);

    const [staticColumns] = useState([
        {
            dataField: "code",
            text: t('student.code'),
        },
        {
            dataField: "firstName",
            text: t('person.firstName'),
            sort: true
        },
        {
            dataField: "lastName",
            text: t('person.lastName'),
            sort: true
        },
        {
            dataField: "totalScore",
            text: t('common.total'),
            sort: false,
            style: isEdit ? {padding: 3, verticalAlign: 'middle'}  : {verticalAlign: 'middle'},
            extraData: students,
            formatter: (cell, row, rowIndex, extraData) => {
                if(isEdit){
                    return (
                        <input
                            autoFocus={true}
                            value={row.totalScore}
                            type="text"
                            style={{width: '100%', borderRadius: 3, border: '1px solid #ebedf2', height: 'fit-content', padding: 11}}
                            onChange={(e) => {onInputHandler(e, row.id, extraData)}}
                        />
                    );
                } else {
                    return row.totalScore
                }
            },
        }
    ]);

    const [columns, setColumns] = useState([
        {
            dataField: "code",
            text: t('student.code'),
        },
        {
            dataField: "firstName",
            text: t('person.firstName'),
            sort: true
        },
        {
            dataField: "lastName",
            text: t('person.lastName'),
            sort: true
        },
        {
            dataField: "totalScore",
            text: t('common.total'),
            sort: false,
            style: isEdit ? {padding: 3, verticalAlign: 'middle'}  : {verticalAlign: 'middle'},
            extraData: students,
            headerStyle: () => ({
                width: 80,
            }),
            formatter: (cell, row, rowIndex, extraData) => {
                if(isEdit){
                    return (
                        <input
                            autoFocus={true}
                            value={row.totalScore}
                            type="text"
                            style={{width: '100%', borderRadius: 3, border: '1px solid #ebedf2', height: 'fit-content', padding: 11}}
                            onChange={(e) => {onInputHandler(e, row.id, extraData)}}
                        />
                    );
                } else {
                    return row.totalScore
                }
            },
        }
    ]);

    useEffect(() => {
        const params = {
            school: schoolId,
            course: id
        };

        dispatch(setLoading(true));
        fetchRequest(teacherOnlineLessonResult, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setGroups(data?.groups || []);
                    if(data?.groups && data?.groups.length > 0){
                        setSelectedGroup(data.groups[0]);

                        let cloneColumns = cloneDeep(staticColumns);
                        if(cloneColumns && cloneColumns.length > 0){
                            for(let i = 0; i < cloneColumns.length; i++){
                                if(cloneColumns[i].dataField == 'totalScore'){
                                    cloneColumns.splice(i, 1)
                                }
                            }
                        }
                
                        if(data.groups[0].homeworkList && data.groups[0].homeworkList.length > 0){
                            let homeworks = data.groups[0].homeworkList;
                            for(let i = 0; i < homeworks.length; i++){
                                cloneColumns.push(
                                    {
                                        dataField: 'score_' + homeworks[i].id,
                                        text: homeworks[i].name,
                                        sort: true
                                    }
                                )
                            }
                        }
                        
                
                        cloneColumns.push({
                            dataField: "totalScore",
                            text: t('common.total'),
                            sort: false,
                            headerStyle: () => ({
                                width: 80,
                            }),
                            style: {verticalAlign: 'middle'},
                            extraData: students,
                            formatter: (cell, row) => {
                                return row.totalScore
                            },
                        })

                        setColumns(cloneColumns);
                    }
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }, []);

    const config = {
        showFilter: true,
        showPagination: false,
        showAllData: true,
        tableMarginLess: false
    }

    const onInputHandler = (e, studentId) => {
        const re = /^[0-9.\b]+$/;
        if(e.target.value === '' || re.test(e.target.value) ){
            if(('' == e.target.value) || (0 <= parseInt(e.target.value) && parseInt(e.target.value) <= 100)){
                let cloneGroups = [...groups];

                if(cloneGroups && cloneGroups.length > 0){
                    for(let i = 0; i < cloneGroups.length; i++){
                        if(selectedGroup.id == cloneGroups[i].id){
                            if(cloneGroups[i].studentList && cloneGroups[i].studentList.length > 0){
                                let studentList = cloneGroups[i].studentList;
    
                                for(let s = 0; s < studentList.length; s++){
                                    if(studentId == studentList[s].id){
                                        studentList[s].totalScore = e.target.value;
                                    }
                                }
                            }
                        }
                    }
                }
                
                setGroups(cloneGroups);
            } else {
                message(t('errorMessage.enterMinAndMax'));
            }
        }
    };

    const onTabClick = (groupId) => {
        if(groupId){
            let selectedGroupObj = [];
            if(groups && groups.length > 0){
                groups.filter(record => {
                    if(groupId == record.id){
                        selectedGroupObj = record;
                    }
                })
            }

            let cloneColumns = cloneDeep(staticColumns);
            if(cloneColumns && cloneColumns.length > 0){
                for(let i = 0; i < cloneColumns.length; i++){
                    if(cloneColumns[i].dataField == 'totalScore'){
                        cloneColumns.splice(i, 1)
                    }
                }
            }

            if(selectedGroupObj){
                let homeworks = selectedGroupObj.homeworkList;
                if(homeworks && homeworks.length > 0){
                    for(let i = 0; i < homeworks.length; i++){
                        cloneColumns.push(
                            {
                                dataField: 'score_' + homeworks[i].id,
                                text: homeworks[i].name,
                                sort: true
                            }
                        )
                    }
                }
            }
    
            cloneColumns.push({
                dataField: "totalScore",
                text: t('common.total'),
                sort: false,
                headerStyle: () => ({
                    width: 80,
                }),
                style: {verticalAlign: 'middle'},
                extraData: students,
                formatter: (cell, row) => {
                    return row.totalScore
                },
            })
    
            setSelectedGroup(selectedGroupObj);
            setColumns(cloneColumns);
        }
    }

    const totalScoreDetector = (checker) => {
        let cloneColumns = cloneDeep(columns);
        if(cloneColumns && cloneColumns.length > 0){
            for(let i = 0; i < cloneColumns.length; i++){
                if(cloneColumns[i].dataField === 'totalScore'){
                    cloneColumns.splice(i, 1)
                }
            }

            cloneColumns.push({
                dataField: "totalScore",
                text: t('common.total'),
                sort: false,
                headerStyle: () => ({
                    width: 80,
                }),
                style: checker ? {padding: 3, verticalAlign: 'middle'}  : {verticalAlign: 'middle'},
                extraData: students,
                formatter: (cell, row, rowIndex, extraData) => {
                    if(checker){
                        return (
                            <input
                                autoFocus={true}
                                value={row.totalScore}
                                type="text"
                                style={{width: '100%', borderRadius: 3, border: '1px solid #ebedf2', height: 'fit-content', padding: 5}}
                                onChange={(e) => {onInputHandler(e, row.id, extraData)}}
                            />
                        );
                    } else {
                        return row.totalScore
                    }
                },
            })
        }

        setColumns(cloneColumns);
    }

    const onAddScoreHandler = () => {
        const params = {
            school: schoolId,
            course: id,
            group: selectedGroup.id
        };

        dispatch(setLoading(true));
        fetchRequest(teacherOnlineLessonResult, 'POST', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setGroups(data?.groups || []);
                    setIsEdit(true);
                    totalScoreDetector(true, 'add')
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const handlerOnBackClick = () => {
        setIsEdit(false);
        const cloneOldStudentsData = cloneDeep(oldStudentsData);
        setStudents(cloneOldStudentsData);
        totalScoreDetector(false, 'back')
    };

    const closePublishModal = () => {
        setPublishModal(false);
        setRecalculateModal(false);
    };

    const handlerOnPublishClick = () => {
        setPublishModal(true);
    }

    const onRecalculateHandler = () => {
        setRecalculateModal(true);
    }

    const handlerOnSaveClick = (isPublish) => {
        let students = [];
        if(groups && groups.length > 0){
            for(let i = 0; i < groups.length; i++){
                if(selectedGroup.id == groups[i].id){
                    if(groups[i].studentList && groups[i].studentList.length > 0){
                        students = groups[i].studentList;
                    }
                }
            }
        }

        const params = {
            school: schoolId,
            course: id,
            group: selectedGroup.id,
            students: JSON.stringify(students),
            publish: isPublish
        };

        dispatch(setLoading(true));
        fetchRequest(teacherOnlineLessonResultSave, 'POST', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setGroups(data?.groups || []);
                    if(data?.groups && data?.groups.length > 0){
                        for(let i = 0; i < data?.groups.length; i++){
                            if(selectedGroup.id == data?.groups[i].id){
                                setSelectedGroup(data?.groups[i]);
                            }
                        }
                    }
                    
                    setIsEdit(false);
                    setPublishModal(false);
                    totalScoreDetector(false, 'back')
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const handlerOnRecalculate = () => {
        const params = {
            school: schoolId,
            course: id,
            group: selectedGroup.id,
        };

        dispatch(setLoading(true));
        fetchRequest(teacherOnlineLessonResultRecalculate, 'POST', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setGroups(data?.groups || []);
                    setRecalculateModal(false);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    return (
        <>
            <Card style={style.cardBody}>
                <Card.Body>
                    <div className='lesson-result-tab'>
                        {
                            selectedGroup &&
                            <Tab.Container defaultActiveKey={selectedGroup.id}>
                                <Row>
                                    <Col sm={12}>
                                        <Nav>
                                            {
                                                selectedGroup && groups && groups.length > 0 &&
                                                groups.map((group, index) => {
                                                    return (
                                                        <Nav.Item key={'group_' + index}>
                                                            <Nav.Link eventKey={group.id} onClick={() => onTabClick(group.id)}>{group.name}</Nav.Link>
                                                        </Nav.Item>
                                                    )
                                                })
                                            }
                                        </Nav>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        }
                    </div>
                    <ButtonToolbar className='d-inline'>
                        {
                            selectedGroup && selectedGroup.exam && selectedGroup.exam.isPublish 
                            ?
                                <>
                                    <button
                                        className='btn btn-purple mt-4 ml-2'
                                    >
                                        {t('common.print')}
                                    </button>
                                    <span style={style.cantEditPublish}>{t('warning.cantEditPublish')}</span>
                                </>
                            :
                            isEdit 
                            ?
                                <div className='mt-4'>
                                    <Button variant="link" onClick={handlerOnBackClick}>{t('common.back')}</Button>
                                    <Button variant="success btn-shadow" onClick={() => handlerOnSaveClick(0)}>{t('common.save')}</Button>
                                    <Button variant="publish btn-shadow ml-2" onClick={handlerOnPublishClick}>{t('action.publish')}</Button>
                                </div>
                            :
                            selectedGroup
                            ?
                                <>
                                    <button className="btn btn-primary btn-shadow mt-4" onClick={onAddScoreHandler}>
                                        {t('teacher.addScore')}
                                    </button>
                                    <button className="btn btn-warning btn-shadow mt-4 ml-2" onClick={onRecalculateHandler}>
                                        {t('action.recalculate')}
                                    </button>
                                </>
                            : null
                        }
                    </ButtonToolbar>
                    {
                        selectedGroup && groups && groups.length > 0 &&
                        groups.map((group, index) => {
                            if(selectedGroup.id == group.id){
                                return (
                                    <DTable
                                        key={'table_' + index}
                                        reload={reload}
                                        forwardRef={datatableRef}
                                        columns={columns}
                                        config={config}
                                        data={group.studentList}
                                        selectMode={'radio'}
                                    />
                                )
                            }
                        })    
                    }
                </Card.Body>
            </Card>
            <Modal
                size={'sm'}
                show={publishModal}
                onHide={closePublishModal}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                            {t('action.publish').toUpperCase()}
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {t('warning.publish_confirmation_description')}<br/>
                    {t('warning.publish_confirmation_score')}
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={closePublishModal} className='btn btn-link bolder'>{t('common.cancel')}</button>
                    <Button variant='success btn-shadow' onClick={() => handlerOnSaveClick(1)}>{t('action.publish')}</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                size={'sm'}
                show={recalculateModal}
                onHide={closePublishModal}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                            {t('action.recalculate').toUpperCase()}
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Дахин бодох үед таны оруулсан дүн устах ба дахин гэрийн даалгаварын оноог тооцон бодох юм
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={closePublishModal} className='btn btn-link bolder'>{t('common.cancel')}</button>
                    <Button variant='success btn-shadow' onClick={handlerOnRecalculate}>{t('action.recalculate')}</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};