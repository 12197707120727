import { cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../../../../redux/action";
import { fetchRequest } from "../../../../../../utils/fetchRequest";
import { getProgramAddEyeshValue } from "../../../../../../utils/fetchRequest/Urls";
import DTable from "../../../../../modules/DataTable/DTable";
import Dropdown from "../../../../../modules/Form/Dropdown";
import Select from "../../../../../modules/Form/Select";

const CriteriaEyeshValue = ({ enrollId }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [list, setList] = useState([]);
    const [oldList, setOldList] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [index, setIndex] = useState(null);

    useEffect(() => {
        getConfig();
    }, []);

    const getConfig = () => {
        dispatch(setLoading(true));
        const params = {
            enroll: enrollId,
        };

        fetchRequest(getProgramAddEyeshValue, "GET", params)
            .then((res) => {
                if (res.success) {
                    const { data } = res;
                    setList(data?.list || [])
                    setSubjects(data?.subjects || [])
                    setOldList(cloneDeep(data?.list || []))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
            });
    };

    const columns = [
        {
            dataField: "academicCode",
            text: "Хөтөлбөрийн индекс",
            sort: false,
        },
        {
            dataField: "academicName",
            text: "Хөтөлбөрийн нэр",
            sort: false,
        },
        {
            dataField: "programName",
            text: "Мэргэжил",
            sort: false,
        },
        {
            dataField: "mainSubjectNames",
            text: "Үндсэн",
            sort: false,
            style: { textAlign: "right", padding: 3, width: 200 },
            formatter: (cell, row, rowIndex, extraData) => {
                if (row.isEdit) {
                    return (
                        <Select
                            clearable={false}
                            multiple={true}
                            options={subjects}
                            value={row.mainSubject}
                            onChange={(e) => handlerDropdown(e, 'main', rowIndex, extraData)}
                        />
                    )
                } else {
                    return cell
                }
            }
        },
        {
            dataField: "mainMinScore",
            text: "Оноо",
            sort: false,
            style: { textAlign: "right", padding: 3, width: 80 },
            formatter: (cell, row, rowIndex) => {
                if (row.isEdit) {
                    return (
                        <input
                            autoFocus={index == rowIndex ? true : false}
                            value={row.mainMinScore || ''}
                            type="text"
                            style={{ width: '100%', borderRadius: 3, border: '1px solid #ebedf2', height: 'fit-content', padding: 7 }}
                            onChange={(e) => { handleInputChange(e, 'mainMinScore', row, rowIndex) }}
                        />
                    )
                } else {
                    return cell
                }
            }
        },
        {
            dataField: "optionalSubjectNames",
            text: "Хавсарга",
            sort: false,
            style: { textAlign: "right", padding: 3, width: 200 },
            formatter: (cell, row, rowIndex) => {
                if (row.isEdit) {
                    return (
                        <Select
                            clearable={false}
                            multiple={true}
                            options={subjects}
                            value={row.optionalSubject}
                            onChange={(e) => handlerDropdown(e, 'second', rowIndex, row)}
                        />
                    )
                } else {
                    return cell
                }
            }
        },
        {
            dataField: "optionalMinScore",
            text: "Оноо",
            sort: false,
            style: { textAlign: "right", padding: 3, width: 80 },
            formatter: (cell, row, rowIndex) => {
                if (row.isEdit) {
                    return (
                        <input
                            autoFocus={index == rowIndex ? true : false}
                            value={row.optionalMinScore || ''}
                            type="text"
                            style={{ width: '100%', borderRadius: 3, border: '1px solid #ebedf2', height: 'fit-content', padding: 7 }}
                            onChange={(e) => { handleInputChange(e, 'optionalMinScore', row, rowIndex) }}
                        />
                    )
                } else {
                    return cell
                }
            }
        },
    ];

    const handlerDropdown = (value, action, rowIndex, row) => {
        let cloneData = [...list];

        for (let i = 0; i < cloneData.length; i++) {
            if (i == rowIndex) {
                if (action == 'main') {
                    cloneData[i].mainSubject = value;
                } else if (action == 'second') {
                    cloneData[i].optionalSubject = value;
                }
            }
        }

        setList(cloneData)
    }

    const handleInputChange = (e, column, row, index) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            let cloneData = [...list];
            cloneData[index][column] = e.target.value;
            setList(cloneData);
            setIndex(index);
        }
    }

    const handleButton = () => {
        if (isEdit) {
            dispatch(setLoading(true));
            const params = {
                enroll: enrollId,
                list: JSON.stringify(list)
            };

            fetchRequest(getProgramAddEyeshValue, "POST", params)
                .then((res) => {
                    if (res.success) {
                        const { data } = res;
                        setIsEdit(false)
                        getConfig()
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                });
        } else {
            let cloneData = [...list];

            if (cloneData && cloneData.length > 0) {
                for (let i = 0; i < cloneData.length; i++) {
                    cloneData[i].isEdit = true;
                }
            }

            setList(cloneData);
            setIsEdit(true)
        }
    };

    const handleBackButton = () => {
        setIsEdit(false)
        let cloneData = cloneDeep(oldList);

        if (cloneData && cloneData.length > 0) {
            for (let i = 0; i < cloneData.length; i++) {
                cloneData[i].isEdit = false;
            }
        }

        setList(cloneData);
    }

    return (
        <Card>
            <Card.Body>
                <DTable
                    className="mb-2"
                    config={{
                        excelExport: true,
                        showPagination: false,
                        showLeftButton: true,
                        leftButtonText: t("common.edit"),
                        leftButtonClassName: "btn btn-primary btn-shadow",
                        showLeftButton2: isEdit ? true : false,
                        leftButtonText2: isEdit ? t("common.back") : null,
                        leftButtonClassName2: isEdit ? "btn btn-link btn-shadow" : "btn btn-primary btn-shadow",
                    }}
                    data={list}
                    columns={columns}
                    onLeftButtonClick={() => handleButton()}
                    onLeftButtonClick2={() => handleBackButton()}
                />
            </Card.Body>
        </Card>
    );
};

export default CriteriaEyeshValue;
