import "./reg.scss";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import Select from "../../../modules/Form/Select";

const RegSearch = ({ onSearch = () => {}, seasons = [], enrolls = [], programs = [], setSelectedSeasonId, setSelectedProgramId}) => {
    const { t } = useTranslation();
    
    const [seasonIndex] = useState('enroll_register_season_index');

    const [seasonId, setSeasonId] = useState(localStorage.getItem(seasonIndex) || null);
    const [enrollId, setEnrollId] = useState(null);
    const [programId, setProgramId] = useState(null);

    const handleSearch = (params = {}) => {
        
    };

    const handleSeasonChange = val => {
        if(val){
            setSeasonId(val);
            setSelectedSeasonId(val)
            localStorage.setItem(seasonIndex, val);
        } else {
            setSeasonId(null);
            setSelectedSeasonId(null)
            localStorage.setItem(seasonIndex, '');
        }
    };

    const handleEnrollChange = val => {
        const params = {
            season: seasonId,
            enroll: val,
            program: programId,
        };
        onSearch(params);
        setEnrollId(val);
        setSelectedProgramId(null)
        setProgramId(null)
    };

    const handleProgramChange = val => {
        const params = {
            season: seasonId,
            enroll: enrollId,
            program: val,
        };
        onSearch(params);
        setProgramId(val);
        setSelectedProgramId(val)
    };

    return (
        <Card>
            
            <Card.Body>
                <div className="row">
                    <div className="col d-flex">
                        <span style={{ paddingRight: '1rem', position: 'relative', top: 8, whiteSpace: 'nowrap' }}>Хичээлийн жил</span>
                        <Select
                            className={'dropdown w-100'}
                            searchable
                            options={seasons}
                            value={parseInt(seasonId)}
                            onChange={handleSeasonChange}
                        />
                    </div>
                    <div className="col d-flex">
                        <span style={{ paddingRight: '1rem', position: 'relative', top: 8, whiteSpace: 'nowrap' }}>Элсэлтийн төрөл</span>
                        <Select
                            className={'dropdown w-100'}
                            searchable
                            options={enrolls}
                            value={enrollId}
                            onChange={handleEnrollChange}
                        />
                    </div>
                    <div className="col d-flex">
                        <span style={{ paddingRight: '1rem', position: 'relative', top: 8, whiteSpace: 'nowrap' }}>Мэргэжил</span>
                        <Select
                            className={'dropdown w-100'}
                            searchable
                            options={programs}
                            value={programId}
                            onChange={handleProgramChange}
                        />
                    </div>
                    <div className="col d-flex">
                        <button
                            style={{height: 35}}
                            className="btn btn-warning m-btn--icon m-btn--icon-only btn-shadow"
                            onClick={handleSearch}
                        >
                            <i className="flaticon-search-1" style={{ color: "#000" }}></i>
                            <span style={{ color: "#000" }}>
                                {t("common.search").toUpperCase()}
                            </span>
                        </button>
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
};

export default RegSearch;
