import { useTranslation } from "react-i18next";
import message from "../../../modules/message";
import { Button, Modal } from "react-bootstrap";
import Forms from "../../../modules/Form/Forms";
import { setLoading } from "../../../../redux/action";
import { useSelector, useDispatch } from "react-redux";
import React, { useRef, useEffect, useState } from "react";
import { fetchRequest } from "../../../../utils/fetchRequest";
import {
    getAddEnrollPhase,
} from "../../../../utils/fetchRequest/Urls";

const AddCriteriaModal = ({ onClose, enrollId, phaseTypes, phases, setSelectedCriteria }) => {
    const form = useRef();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const languages = useSelector(
        (state) => state?.languages?.languages || []
    ).filter((lang) => lang.code !== "mn");

    useEffect(() => { }, []);

    const forms = [
        {
            label: "Төрөл",
            value: null,
            type: "dropdown",
            inputWidth: 300,
            labelWidth: 230,
            required: true,
            key: "phaseTypes",
            options: phaseTypes,
        },
        {
            label: "Шалгуур",
            value: null,
            type: "dropdown",
            inputWidth: 300,
            labelWidth: 230,
            required: true,
            key: "phases",
            options: phases,
        },
    ];

    const onSaveClick = () => {
        const [formValid, formValue] = form.current.validate();
        if (formValid) {
            const params = {
                enroll: enrollId,
                enrollPhaseType: formValue?.[0].value,
                phase: formValue?.[1].value,
                // ordering: formValue?.[2].value,
            };

            dispatch(setLoading(true));
            fetchRequest(getAddEnrollPhase, "POST", params, false)
                .then((response) => {
                    if (response.success) {
                        message(response.data.message, true);
                        setSelectedCriteria(response.data?.list || [])
                        onClose();
                    } else {
                        message(response.data.message, false);
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                });
        } else {
        }
    };

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: "#4a70ae", fontSize: "1.1rem" }}>
                        Шалгуур нэмэх
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Forms ref={form} fields={forms} />
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className="btn btn-link bolder">
                    {t("common.back")}
                </button>
                <Button variant="success btn-shadow" onClick={onSaveClick}>
                    {t("common.save")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddCriteriaModal;
