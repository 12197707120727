import { useTranslation } from "react-i18next";
import message from "../../../modules/message";
import Forms from "../../../modules/Form/Forms";
import { Card, Button, Modal } from "react-bootstrap";
import { setLoading } from "../../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import DTable from "../../../modules/DataTable/DTable";
import React, { useState, useEffect, useRef } from "react";
import { fetchRequest } from "../../../../utils/fetchRequest";
import {
    getEnrollList,
    getAthleteList,
    getAthleteChange,
    getAthleteConfirm,
    getAthleteCriteria,
} from "../../../../utils/fetchRequest/Urls";
import { sortArray } from '../../../../utils/utils'
import InventorySharpIcon from '@mui/icons-material/InventorySharp';

const EnrollBody = () => {
    const form = useRef();
    const [list, setList] = useState([]);
    const [enrolls, setEnrolls] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalCandidate, setModalCandidate] = useState(null);
    const [isConfirm, setIsConfirm] = useState(false);
    const [dynamicColumns, setDynamicColumns] = useState([]);
    const [athleteCriterias, setAthleteCriterias] = useState([]);
    const [candidateAthletes, setCandidateAthletes] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [selectedEnrollId, setSelectedEnrollId] = useState(null);
    const [programId, setProgramId] = useState(null);
    const schoolId = useSelector((state) => state?.selectedSchool?.id || null);

    const [updateView, setUpdateView] = useState(false)

    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        let params = {
            school: schoolId,
        };
        getEnrolls(params);
    }, []);

    useEffect(() => {
        initializeTable(list)
    }, [list]);

    useEffect(() => {
        form?.current?.updateFields && form.current.updateFields(forms);
    }, [enrolls, programs]);

    const columns = [
        {
            dataField: "candidateNo",
            text: "ID",
            sort: true,
            editable: false,
        },
        {
            dataField: "registerNumber",
            text: t("person.registrationNumber"),
            sort: true,
            editable: false,
        },
        {
            dataField: "lastName",
            text: t("person.lastName"),
            sort: true,
            editable: false,
        },
        {
            dataField: "firstName",
            text: t("common.firstName"),
            sort: true,
            editable: false,
        },
        {
            dataField: "gender",
            text: t("common.gender"),
            sort: true,
            editable: false,
        },
    ];

    async function getData(params) {
        setIsConfirm(false);
        dispatch(setLoading(true));
        fetchRequest(getAthleteList, "GET", params)
            .then((res) => {
                if (res.success) {
                    const { data } = res;
                    setIsConfirm(data?.isConfirm || false);
                    setDynamicColumns([]);
                    setCandidateAthletes(data?.candidateAthletes || []);
                    setList(data?.list || []);

                } else {
                    message(res?.data?.message || t("errorMessage.title"));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t("errorMessage.title"));
            });
    }

    const getEnrolls = (params) => {
        dispatch(setLoading(true));
        fetchRequest(getEnrollList, "GET", params).then((response) => {
            if (response.success) {
                if (response.data.list) {
                    const enrollOptions = [];
                    const enrollList = response.data.list;
                    enrollList.forEach((element) => {
                        enrollOptions.push({ value: element.id, text: element.name });
                    });
                    setEnrolls(enrollOptions);
                }
                setPrograms(response?.data?.programs || [])
                dispatch(setLoading(false));
            } else {
                message(response.data.message, false);
            }
            dispatch(setLoading(false));
        });
    };

    async function getAthleteCriteriaList(params) {
        dispatch(setLoading(true));
        fetchRequest(getAthleteCriteria, "GET", params)
            .then((res) => {
                if (res.success) {
                    const { data } = res;
                    setAthleteCriterias(data?.list || []);
                } else {
                    message(res?.data?.message || t("errorMessage.title"));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t("errorMessage.title"));
            });
    }

    const initializeTable = (list) => {
        const newColumns = [];

        const actionColumn = {
            isDummyField: true,
            dataField: "action",
            text: "",
            sort: false,
            editable: false,
            hidden: isConfirm,
            formatter: (cell, row, rowIndex, extraData) => {
                if (row.isPublish) {
                    // already published
                } else {
                    if (row.isEdit === null || !row.isEdit) {
                        return (
                            <>
                                <Button
                                    onClick={() => {
                                        handleEdit(row);
                                    }}
                                    title="Засварлах"
                                    className="btn btn-info m-btn m-btn--icon m-btn--icon-only m-btn--circle-30 mr-1"
                                >
                                    <i className="flaticon-edit"> </i>
                                </Button>
                                <Button
                                    onClick={() => {
                                        onClickRowPublish(row);
                                    }}
                                    title="Баталгаажуулах"
                                    className="btn btn-success m-btn m-btn--icon m-btn--icon-only m-btn--circle-30 p-0"
                                >
                                    <InventorySharpIcon />
                                </Button>
                            </>
                        );
                    } else if (row && row.isEdit === true) {
                        return (
                            <>
                                <Button
                                    onClick={() => {
                                        handleSaveRow(row);
                                    }}
                                    title="Хадгалах"
                                    className="btn btn-success m-btn m-btn--icon m-btn--icon-only m-btn--circle-30 mr-1"
                                >
                                    <i className="flaticon2-check-mark"> </i>
                                </Button>
                                <Button
                                    onClick={() => {
                                        handleEdit(row);
                                    }}
                                    title="Цуцлах"
                                    className="btn btn-danger m-btn m-btn--icon m-btn--icon-only m-btn--circle-30"
                                >
                                    <i className="flaticon2-cross"> </i>
                                </Button>
                            </>
                        );
                    }
                }

            },
        };
        if (list.length > 0) {
            athleteCriterias.forEach((item, index) => {
                const column = {
                    dataField: item + '_' + index,
                    sort: false,
                    text: item.name + ' (оноо:' + item.fMinScore + ')',
                    key: item.name + index,
                    hidden: !item.isActive,
                    editor: { type: "number" },
                    style: { padding: 3 },
                    formatter: (cell, row) => {
                        return (
                            <div className="">
                                {row.isEdit === null || !row.isEdit ? (
                                    <p className="text-center">
                                        {row.athletes ? row.athletes[index].score : ""}
                                    </p>
                                ) : (
                                    <input
                                        type="number"
                                        className=" form-control"
                                        value={row.athletes ? row.athletes[index].score : ''}
                                        onChange={(e) => {
                                            if (e.target.value) {
                                                let score = e.target.value;
                                                if (score >= 0 && score <= 800) {
                                                    handleChange(index, row, e.target.value)
                                                }
                                            } else {
                                                handleChange(index, row, e.target.value)
                                            }
                                        }}
                                    ></input>
                                )}
                            </div>
                        );
                    },
                };

                newColumns.push(column);
            });

            if (!isConfirm) {
                newColumns.push(actionColumn);
            }
            setDynamicColumns([...columns, ...newColumns]);
        } else {
            setDynamicColumns([...columns]);
        }
    };

    const handleChange = (index, row, value) => {
        const tempList = [...list];
        if (list && list.length > 0) {
            for (let i = 0; i < tempList.length; i++) {
                if (row.id === tempList[i].id) {
                    if (tempList[i].athletes) {
                        tempList[i].athletes[index].score = value;
                        setList(tempList);
                    }
                }
            }
        }
    };

    const handleSaveRow = (row) => {
        const athleteScores = [];
        if (row.athletes) {
            row.athletes.forEach((item) => {
                athleteScores.push({
                    criteriaId: item.criteriaId,
                    criteriaScore: item.genderMinScore,
                    score: item.score,
                });
            });
        }
        const criteriaList = [
            {
                candidateId: row.id,
                criterias: athleteScores,
            },
        ];
        const params = { candidateCriterias: criteriaList };
        handleSave(params);
    };

    const handleSave = (params) => {
        dispatch(setLoading(true));
        fetchRequest(getAthleteChange, "POST", params)
            .then((res) => {
                if (res.success) {
                    message(res.data.message, true);
                    let params = {
                        school: schoolId,
                        enroll: selectedEnrollId,
                        program: programId
                    }

                    getData(params);
                } else {
                    message(res?.data?.message || t("errorMessage.title"));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t("errorMessage.title"));
            });
    };

    const handleEdit = (row) => {
        const tempList = [...list];

        if (list && list.length > 0) {
            for (let i = 0; i < tempList.length; i++) {
                if (row.id == tempList[i].id) {
                    if (tempList[i].isEdit) {
                        tempList[i].isEdit = false;
                    } else {
                        tempList[i].isEdit = true;
                    }
                    break;
                }
            }
        }
        setList(tempList);
    };

    const onClickRowPublish = (row) => {
        setModalCandidate(row)
        setShowModal(true)
    }

    const onEnrollChange = async (id) => {
        setSelectedEnrollId(id);
        let params = {
            school: schoolId,
            enroll: id,
        }
        getEnrolls(params)
    };

    const onProgramChange = async (id) => {
        setProgramId(id);
        let params = {
            school: schoolId,
            enroll: selectedEnrollId,
            program: id
        }

        await getData(params);
        await getAthleteCriteriaList(params);
    };

    const handleModalClose = () => {
        setShowModal(false);
        setModalCandidate(null)
    };

    const handleOnPublishClick = () => {
        if (list.length > 0) {
            setShowModal(true);
        }
    };

    const handlePublish = () => {
        const params = {
            school: schoolId,
            enroll: selectedEnrollId,
            program: programId
        };
        if (modalCandidate) {
            params.candidate = modalCandidate?.candidateNo;
        }

        dispatch(setLoading(true));
        fetchRequest(getAthleteConfirm, "POST", params)
            .then((res) => {
                if (res.success) {
                    message(res.data.message, true);
                    setIsConfirm(true);
                    handleModalClose(false);
                    let params = {
                        school: schoolId,
                        enroll: selectedEnrollId,
                        program: programId
                    }

                    getData(params);
                } else {
                    message(res?.data?.message || t("errorMessage.title"));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t("errorMessage.title"));
            });
    };

    const forms = [
        {
            label: "Элсэлтийн төрөл",
            required: false,
            value: selectedEnrollId ?? null,
            type: "dropdown",
            multiple: false,
            clearable: true,
            inputWidth: 180,
            key: "enroll",
            options: enrolls,
            onChange: onEnrollChange,
        },
        {
            label: "Мэргэжил",
            required: false,
            value: programId ?? null,
            type: "dropdown",
            multiple: false,
            clearable: true,
            inputWidth: 180,
            key: "enroll",
            options: programs,
            onChange: onProgramChange,
        },
    ];

    const onUserInteraction = (params) => {
        let clone = [...list]

        if (params) {
            switch (params?.sort) {
                case 'candidateNo':
                    const noList = clone.sort((a, b) => {
                        if (a?.id < b?.id) {
                            return -1;
                        }
                        if (a?.id > b?.id) {
                            return 1;
                        }
                        return 0;
                    })
                    if (params?.order === 'asc') {
                        clone = noList;
                    } else {
                        clone = noList.reverse();
                    }
                    break;
                case 'registerNumber':
                    const registerList = sortArray(clone, 'registerNumber');
                    if (params?.order === 'asc') {
                        clone = registerList;
                    } else {
                        clone = registerList.reverse();
                    }
                    break;
                case 'firstName':
                    const fNameList = sortArray(clone, 'firstName');
                    if (params?.order === 'asc') {
                        clone = fNameList;
                    } else {
                        clone = fNameList.reverse();
                    }
                    break;
                case 'lastName':
                    const lNameList = sortArray(clone, 'lastName');
                    if (params?.order === 'asc') {
                        clone = lNameList;
                    } else {
                        clone = lNameList.reverse();
                    }
                    break;
                case 'gender':
                    const genderList = sortArray(clone, 'gender');
                    if (params?.order === 'asc') {
                        clone = genderList;
                    } else {
                        clone = genderList.reverse();
                    }
                    break;
            }
        }

        setList(clone)
    }

    return (
        <div>
            <Card>
                <Card.Body>
                    <div className="col-10 d-flex justify-content start align-items-start">
                        <div
                            style={{ alignItems: "flex-start", display: "flex" }}
                            className="form-component items-start d-flex justify-content-start"
                        >
                            <Forms ref={form} fields={forms} />
                        </div>
                    </div>
                    {isConfirm && list && list.length > 0 ? (
                        <div className="mt-8">
                            <p>
                                Шалгалтын дүнгүүд илгээгдсэн тул засах боломжгүй. Админд хандаж
                                засуулна уу!.
                            </p>
                        </div>
                    ) : null}
                    <DTable
                        // remote
                        config={{
                            showPagination: false,
                            showFilter: false,
                            excelExport: isConfirm ? false : true,
                            excelFileName: "Ур чадварын шалгалт",
                            showLeftButton: isConfirm ? false : true,
                            leftButtonText: t("action.publishAll"),
                            leftButtonClassName: "btn btn-success btn-shadow",
                            leftButtonStyle: { width: 250 },
                            showFile: isConfirm ? false : true,
                            rightButtonText: "Эксель имфорт",
                            rightButtonClassName: "btn btn-primary btn-shadow",
                            rightButtonStyle: { backgroundColor: "green" },
                            showAllData: true
                        }}
                        selectMode={"radio"}
                        columns={dynamicColumns}
                        onLeftButtonClick={handleOnPublishClick}
                        // onInteraction={onUserInteraction}
                        data={list}
                    />
                    {
                        showModal &&
                        <Modal show={showModal} onHide={handleModalClose} size="sm" centered>
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    {"Баталгаажуулах"}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {
                                    modalCandidate
                                        ?
                                        <>
                                            <p>{`Та ${modalCandidate?.firstName} (${modalCandidate?.registerNumber})-н үр дүнг баталгаажуулахдаа итгэлтэй байна уу?`}</p>
                                            {modalCandidate?.athletes && modalCandidate?.athletes.length > 0 && modalCandidate?.athletes.map(athleteObj => {
                                                return <p key={'candidate_athlete_' + athleteObj?.criteriaId}>
                                                    {athleteObj?.name}: {athleteObj?.score}
                                                </p>
                                            })}
                                        </>
                                        :
                                        <p>Та үр дүнг баталгаажуулахдаа итгэлтэй байна уу?</p>
                                }
                                <br />
                                <br />
                                <p>Үр дүнг баталгаажуулсан тохиолдолд засах боломжгүйг анхаарна уу!</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="btn btn-link bolder" onClick={handleModalClose}>
                                    {t("common.cancel")}
                                </Button>
                                <Button variant="success btn-shadow" onClick={handlePublish}>
                                    {t("action.publish")}
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    }
                </Card.Body>
            </Card>
        </div>
    );
};

export default EnrollBody;