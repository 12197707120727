import React, {useEffect, useState} from 'react'
import {Card} from 'react-bootstrap'
import RenderUserInfo from "../../../modules/RenderUserInfo";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {setLoading} from "../../../../redux/action";
import {fetchRequest} from "../../../../utils/fetchRequest";
import {studentFinanceProfile, studentGradeIndex} from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";

export const StudentInfo = ({
    labels,
    image
}) => {
    return (
        <div>
            <Card className='mb-5'>
                <Card.Body>
                    <RenderUserInfo
                        labels={labels}
                        image={image}
                        isImage={true}
                    />
                </Card.Body>
            </Card>
        </div>
    )
};
