import React, { useState, useEffect } from 'react';
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import { classStatusAdd, classStatusDelete, classStatusEdit } from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import DTable from "../../../modules/DataTable/DTable";
import ClassStatusAdd from "./ClassStatusAdd";
import DeleteModal from "../../../modules/DeleteModal";

const ClassStatus = () => {
    const [ statuses, setStatuses ] = useState([]);
    const [ showModal, setShowModal ] = useState(false);
    const [ statusId, setStatusId ] = useState(null);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setLoading(true));
        fetchRequest(classStatusAdd, 'GET')
            .then(res => {
                if (res.success) {
                    const { data } = res;

                    if (data?.statuses?.length) {
                        setStatuses(data.statuses);
                    }

                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            })
    }, []);

    const handleAddClick = () => {
        setShowModal(true);
    };

    const onModalClose = () => {
        setShowModal(false);
        setStatusId(null);
    };

    const config = {
        showPagination: false,
        showFilter: false,
        showAllData: true
    };

    const columns = [
        {
            dataField: "code",
            text: t('common.code'),
        },
        {
            dataField: "name",
            text: t('setting.class.classStatus'),
            sort: true
        },
    ];

    const contextMenus = [
        {
            key: 'edit',
            title: t('common.edit'),
            icon: <i className='las la-edit dt-cm-item-icon'/>
        },
        {
            key: 'delete',
            title: t('common.delete'),
            icon: <i className='las la-trash-alt dt-cm-item-icon' />,
        },
    ];

    const handleContextMenuClick = (id, key) => {
        if (id && key) {
            setStatusId(id);
            if (key === 'edit') {
                setShowModal(true);
            }
        }
    };

    const onSubmit = params => {
        const url = statusId ? classStatusEdit : classStatusAdd;
        const bodyParams = {
            ...params,
        };
        if (statusId) {
            bodyParams.classStatus = statusId;
        }
        dispatch(setLoading(true));
        fetchRequest(url, 'POST', bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res;

                    setStatuses(data?.statuses || []);

                    message(data?.message || t('common.success'), true);

                    onModalClose();
                } else {
                    message(res?.data?.message || t('errorMessage.title'));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            });
    };

    const onDelete = () => {
        const params = {
            classStatus: statusId
        };
        dispatch(setLoading(true));
        fetchRequest(classStatusDelete, 'POST', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setStatuses(data?.statuses || []);
                    message(data?.message || t('common.success'), true);
                    onModalClose();
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            })
    };

    return (
        <Card>
            <Card.Body>
                <button
                    className='btn btn-primary'
                    onClick={handleAddClick}
                >
                    {t('common.add')}
                </button>
                <DTable
                    columns={columns}
                    config={config}
                    data={statuses}
                    contextMenus={contextMenus}
                    onContextMenuItemClick={handleContextMenuClick}
                    selectMode={'radio'}
                />
                {
                    showModal && (
                        <ClassStatusAdd
                            onClose={onModalClose}
                            onSubmit={onSubmit}
                            id={statusId}
                        />
                    )
                }
                {
                    !showModal && statusId && (
                        <DeleteModal
                            onClose={onModalClose}
                            onDelete={onDelete}
                            title={t('warning.delete').toUpperCase()}
                        >
                            {t('warning.delete_confirmation')}
                            <br/>
                            <br/>
                            {t('warning.delete_confirmation_description')}
                        </DeleteModal>
                    )
                }
            </Card.Body>
        </Card>
    );
};

export default ClassStatus;