import React, {useRef, useEffect, useState} from 'react';
import {Button, Card} from "react-bootstrap";
import Forms from "../../../../modules/Form/Forms";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {teacherOnlineLessonAdd, teacherOnlineLessonGetGroups} from "../../../../../utils/fetchRequest/Urls";
import { setLoading } from "../../../../../redux/action";
import { fetchRequest } from "../../../../../utils/fetchRequest";
import message from "../../../../modules/message";
import {Link, useHistory} from "react-router-dom";

export const TeacherOnlineLessonAdd = ({
    onSubmit,
}) => {
    const { t } = useTranslation();
    const formRef = useRef();
    const history = useHistory();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [seasonId, setSeasonId] = useState(null);
    const [seasonName, setSeasonName] = useState(null);
    const [parentSeasonName, setParentSeasonName] = useState(null);
    const [subjectId, setSubjectId] = useState(null);
    const [subjects, setSubjects] = useState([]);
    const [groupId, setGroupId] = useState(null);
    const [groups, setGroups] = useState([]);
    const [courseTypeId, setCourseTypeId] = useState(null);
    const [courseTypes, setCourseTypes] = useState([]);
    const [educationLevelId, setEducationLevelId] = useState(null);
    const [educationLevels, setEducationLevels] = useState([]);

    useEffect(() => {
        const params = {
            school: schoolId,
        };

        dispatch(setLoading(true));
        fetchRequest(teacherOnlineLessonAdd, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setSubjects(data?.subjects || []);
                    setSeasonId(data?.seasonId || '');
                    setSeasonName(data?.seasonName || '');
                    setParentSeasonName(data?.parentSeasonName || '');
                    setCourseTypes(data?.courseTypes || []);
                    setEducationLevels(data?.educationLevels || []);

                    if(data?.educationLevels && data?.educationLevels.length > 0){
                        for(let i = 0; i < data?.educationLevels.length; i++){
                            if(data?.educationLevels[i].code == 'BACHELOR'){
                                setEducationLevelId(data?.educationLevels[i].value);
                            }
                        }
                    }
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }, []);

    useEffect(() => {
        formRef?.current?.updateFields && formRef.current?.updateFields(fields);
    }, [subjects, courseTypes, groups, educationLevels, educationLevelId, parentSeasonName, seasonName]);

    useEffect(() => {
        if(educationLevelId){
            onEducationLevelChange(educationLevelId)
        }
    }, [educationLevelId]);

    const onEducationLevelChange = (value) => {
        const params = {
            school: schoolId,
            educationLevel: value
        };

        dispatch(setLoading(true));
        fetchRequest(teacherOnlineLessonAdd, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setSeasonId(data?.seasonId || '');
                    setSeasonName(data?.seasonName || '');
                    setParentSeasonName(data?.parentSeasonName || '');
                    setEducationLevelId(value);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const onSubjectChange = (value) => {
        const params = {
            school: schoolId,
            subject: value
        };

        dispatch(setLoading(true));
        fetchRequest(teacherOnlineLessonGetGroups, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setSubjectId(value)
                    setGroups(data?.groups || []);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const fields = [
        {
            key: 'educationLevel',
            label: t('curriculum.educationLevel'),
            value: educationLevelId || '',
            type: 'dropdown',
            required: true,
            clearable: true,
            searchable: true,
            options: educationLevels,
            onChange: onEducationLevelChange,
        },
        {
            key: 'year',
            label: t('school.season.title'),
            value: parentSeasonName || '',
            type: 'text',
            disabled: true
        },
        {
            key: 'season',
            label: t('subject.season'),
            value: seasonName  || '',
            type: 'text',
            disabled: true
        },
        {
            key: 'subject',
            label: t('subject.title'),
            value: subjectId,
            type: 'dropdown',
            required: true,
            clearable: true,
            searchable: true,
            options: subjects,
            onChange: onSubjectChange,
        },
        // {
        //     key: 'subjectName',
        //     label: t('subject.name'),
        //     value: null,
        //     type: 'dropdown',
        //     disabled: true
        // },
        {
            key: 'courseType',
            label: t('subject.type'),
            value: courseTypeId,
            type: 'dropdown',
            required: true,
            clearable: true,
            searchable: true,
            options: courseTypes 
        },
        {
            key: 'groups',
            label: t('group.groups'),
            value: groupId || null,
            type: 'dropdown',
            required: true,
            clearable: true,
            searchable: true,
            multiple: true,
            options: groups
        },
        {
            key: 'checkbox',
            label: t('teacher.downloadPrevOnlineLesson'),
            value: null,
            type: 'checkbox',
            clearable: true,
            searchable: true,
        },
        {
            key: 'prevCourse',
            label: t('teacher.previousOnlineLesson'),
            value: null,
            type: 'dropdown',
            clearable: true,
            searchable: true,
        }
    ];

    const handlerOnSaveClick = () => {
        const [ isValid, states, values ] = formRef.current.validate();
        if (isValid) {
            if(seasonId){
                const params = {
                    school: schoolId,
                    educationLevel: values.educationLevel,
                    courseType: values.courseType,
                    subject: values.subject,
                    checkbox: values.checkbox ? 1 : 0,
                    groups: JSON.stringify(values.groups),
                    prevCourse: values.prevCourse,
                };
    
                dispatch(setLoading(true));
                fetchRequest(teacherOnlineLessonAdd, 'POST', params)
                    .then(res => {
                        if (res.success) {
                            const { data } = res;
    
                            history.push({
                                pathname: '/teacher/online/lesson-edit',
                                state: {
                                    courseId: data.course,
                                }
                            });
                        } else {
                            message(res?.data?.message || t('errorMessage.title'))
                        }
                        dispatch(setLoading(false));
                    })
                    .catch(() => {
                        dispatch(setLoading(false));
                        message(t('errorMessage.title'))
                    })
            } else {
                message('Тухайн боловсролын зэрэг дээр идэвхтэй улирлын мэдээлэл олдсонгүй, мэдээлэл технологийн ажилтантай холбогдоно уу')
            }
        }
    };

    return (
        <div className='sm-container'>
            <Card>
                <Card.Header>
                    <span className='french-blue fs-10'>
                        <b>{t('teacher.createOnlineLesson').toUpperCase()}</b>
                    </span>
                </Card.Header>
                <Card.Body>
                    <div className='col-12'>
                        <Forms
                            ref={formRef}
                            fields={fields}
                        />
                    </div>
                </Card.Body>
                <Card.Footer>
                    <div className='col-12 text-center'>
                        <Link
                            to='/teacher/online/lesson'
                            className="btn btn-link bolder"
                        >
                            {t('common.backTo_list')}
                        </Link>
                        <Button variant="success btn-shadow" onClick={handlerOnSaveClick}>{t('common.create')}</Button>
                    </div>
                </Card.Footer>
            </Card>
        </div>
    );
};