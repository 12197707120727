import React, {useRef, useEffect, useState} from 'react';
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {regulationEdit} from "../../../../../utils/fetchRequest/Urls";
import { setLoading } from "../../../../../redux/action";
import { fetchRequest } from "../../../../../utils/fetchRequest";
import message from "../../../../modules/message";
import {cloneDeep, isBoolean} from "lodash";

const style = {
    container: {
        display: 'flex',
        marginTop: '0.8rem'
    },
    labelContainer: {
        display: 'flex',
        flex:  1,
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginRight : 10,
        marginBottom: 0,
        width: 230,
    },
    inputContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        marginLeft: 10,
        width: 'auto',
    },
    inputStyle: {
        display: 'inline-flex',
        marginBottom: 10
    },
    radioStyle: {
        position: 'relative',
        top: 10,
        marginRight: 10
    },
    footerContainer: {
        display: 'flex',
        flex: 0.6,
        flexDirection: 'column',
        marginLeft: 10,
        width: 'auto',
    },
    invalid: {
        width: '100%',
        fontSize: '0.9rem',
        color: '#F64E60',
    }
}

const defaultRow = [
    {
        id: 1,
        radio: true,
        input: '',
    },
    {
        id: 2,
        radio: false,
        input: '',
    }
];

export const TrueFalse = ({
    checkRows,
    onChange
}) => {
    const {t} = useTranslation();
    const [rows, setRows] = useState(defaultRow);

    useEffect(() => {
        if(Number.isInteger(checkRows)){
            let cloneRows = cloneDeep(rows);
            setRows(cloneRows);
        }
    }, [checkRows])

    const onRadioChange = (e, index) => {
        let cloneRows = [...rows];
        if(cloneRows && cloneRows.length > 0){
            for(let i = 0; i < cloneRows.length; i++){
                if(index == i){
                    cloneRows[i].radio = e.target.checked;
                } else {
                    cloneRows[i].radio = false;
                }
            }
        }
        onChange(cloneRows);
        setRows(cloneRows);
    };

    const onInputChange = (e, index) => {
        let cloneRows = [...rows];
        cloneRows[index].input = e.target.value;
        cloneRows[index].inputError = false;
        onChange(cloneRows);
        setRows(cloneRows);
    };

    return (
        <>
            <div style={style.container}>
                <label style={style.labelContainer}></label>
                <div style={style.inputContainer}>
                    {
                        rows.map((row, index) => {
                            return(
                                <div
                                    key={'trueFalse_' + index}
                                    style={style.inputStyle}
                                >
                                    <div style={style.radioStyle}>
                                        <input
                                            type="radio"
                                            checked={row.radio}
                                            onChange={(e) => onRadioChange(e, index)}
                                        />
                                    </div>
                                    <div className='w-100'>
                                        <input
                                            className={row.inputError ? 'form-control is-invalid' : 'form-control'}
                                            type='text'
                                            onChange={(e) => {onInputChange(e, index)}}
                                            value={row.input}
                                            required
                                        />
                                        {
                                            row.inputError
                                            ?
                                                <div style={style.invalid}>
                                                    {t('question.insert')}
                                                </div>
                                            : null
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div
                    style={style.footerContainer}
                />
            </div>
        </>
    );
};