import React, {useEffect, useState, useRef} from 'react'
import { useTranslation } from "react-i18next";
import RenderTableList from "../../../modules/RenderTableList";
import {useDispatch, useSelector} from "react-redux";
import {setLoading} from "../../../../redux/action";
import {fetchRequest} from "../../../../utils/fetchRequest";
import {selectionFindStudents, lmsAnketInit, lmsStudentList} from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import {cloneDeep} from "lodash";
import Forms from "../../../modules/Form/Forms";

import DTable from '../../../modules/DataTable/DTable'

import DeleteModal from '../../../modules/DeleteModal';
import {AddModal} from "./AddModal";
import {studentAnketEducation, studentAnketRelatives} from "../../../../utils/fetchRequest/Urls";

export const MainInformation = () => {
    // const { t } = useTranslation();
    // const dispatch = useDispatch();
    // const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    // const [studentInfoArray, setStudentInfoArray] = useState([]);
    // const [closeStudentArray, setCloneStudentArray] = useState([]);
    // const [isEdit, setIsEdit] = useState(false);
    const gender = [
        {text: 'Эрэгтэй', value: 'm'},
        {text: 'Эмэгтэй', value: 'f'},
    ];
    const [tableData, setTableData] = useState([]);
    const [educationId, setEducationId] = useState(null);
    //const [educations, setEducations] = useState([]);
    const [curriculumId, setCurriculumId] = useState(null);
    const [curriculums, setCurriculums] = useState([]);
    const [countries, setCountries] = useState([]);
    const [programId, setProgramId] = useState(null);
    const [programs, setPrograms] = useState([]);
    const [typeId, setTypeId] = useState(null);
    //const [types, setTypes] = useState([]);
    const [year, setYear] = useState(null);
    const [students, setStudents] = useState([]);

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [search, setSearch] = useState('');
    const [tableTotalCount, setTableTotalCount] = useState([]);
    useEffect(() => {
        const params = {
            school: schoolId,
            page,
            pageSize,
            search,
            educationLevel: educationId,
            academicProgram: curriculumId,
            program: programId,
            status: typeId,
            startYear: year,
        };

       init(params)
    }, [page, pageSize, search, educationId, curriculumId, programId, typeId, year]);



    const onEducationSelect = (id) => {
        setEducationId(id)
    };

    const onCurriculumSelect = (id) => {
        setCurriculumId(id);

        const params = {
            school: schoolId,
        };

        

        //init(params)
    };

    const onProgramSelect = (id) => {
        setProgramId(id)
    };

    const onTypeSelect = (id) => {
        setTypeId(id)
    };

    const handleDateChange = (id) => {
        setYear(id)
    };

    const handlerOnClearClick = () => {
        setEducationId(null);
        setCurriculumId(null);
        setProgramId(null);
        setTypeId(null);
        setYear(null);
    };

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [educations, setEducations] = useState([]);
    const [addModal, setAddModal] = useState(false);
    const [deleteModal,setDeleteModal] = useState(false);
    const [tempId,setTempId] = useState(null);
    const [types, setTypes] = useState([]);

    useEffect(() => {
        // const params = {
        //     school: schoolId,
        //     page,
        //     pageSize,
        //     search
        // }
        // if(schoolId){
        //     dispatch(setLoading(true));
        //     fetchRequest(lmsAnketInit, 'GET', [params])
        //         .then(res => {
        //             if (res.success) {
        //                 const { data } = res;
        //                 let persons = data?.person || [];
        //                 if(persons && persons.length > 0){
        //                     for(let i = 0; i < persons.length; i++){
        //                         if(persons[i].birthDate && persons[i].birthDate.date){
        //                             persons[i].birthDate = persons[i].birthDate.date.substring(0, 10)
        //                         }
        //                     }
        //                 }
        //                 setTableData(persons);
        //                 //setTableData(data.person.map(level => ({ value: level.key, text: level.title })));
        //                 //setTypes(data.memberTypes.map(type => ({value: type.id, text: type.name})));
        //             } else {
        //                 message(res?.data?.message || t('errorMessage.title'))
        //             }
        //             dispatch(setLoading(false));
        //         })
        //         .catch(() => {
        //             dispatch(setLoading(false));
        //             message(t('errorMessage.title'));
        //         })
        // }
    }, []);
    // useEffect(() => {
    //     if(schoolId){
    //         dispatch(setLoading(true));
    //         fetchRequest(studentAnketEducation, 'GET', [])
    //             .then(res => {
    //                 if (res.success) {
    //                     const { data } = res;
    //                     setEducations(data?.eyeshScores);
    //                 } else {
    //                     message(res?.data?.message || t('errorMessage.title'))
    //                 }
    //                 dispatch(setLoading(false));
    //             })
    //             .catch(() => {
    //                 dispatch(setLoading(false));
    //                 message(t('errorMessage.title'));
    //             })
    //     }
    // }, []);

    const tableContextItem = [
        {
            key: 'edit',
            title: t('common.edit'),
            icon: <i className='las la-edit dt-cm-item-icon'/>
        },
        {
            key: 'delete',
            title: t('action.delete'),
            icon: <i className='las la-trash-alt dt-cm-item-icon'/>
        },
    ];

    const config = {
        showPagination: true,
        showFilter: false,
        tableMarginLess: true
    };

    const columns = [
        // {
        //     dataField: 'code',
        //     text: t('student.code'),
        //     sort: true,
        // },
        // {
        //     dataField: 'citizenship',
        //     text: t('questionnaire.citizenship'),
        //     sort: true,
        // },
        {
            dataField: 'registrationNumber',
            text: t('person.registrationNumber'),
            sort: true,
        },
        {
            dataField: 'parents',
            text: t('questionnaire.parents'),
            sort: true,
        },
        {
            dataField: 'firstName',
            text: t('common.firstName'),
            sort: true,
        },      
      
        
        {
            dataField: 'gender',
            text: t('common.gender'),
            sort: true,
        },
        
        {
            dataField: 'birthDate',
            text: t('questionnaire.birthDate'),
            sort: true,
        },
        
        {
            dataField: 'nationality',
            text: t('questionnaire.nationality'),
            sort: true,
        },        
        
        // {
        //     dataField: 'enrolledProgram',
        //     text: t('questionnaire.enrolledProgram'),
        //     sort: true,
        // },        
        
        {
            dataField: 'address',
            text: t('questionnaire.address'),
            sort: true,
        }
    ];

    const fields = [
       
        // {  
        //     key: 'citizenship',
        //     label: t('questionnaire.citizenship') + '*',
        //     value: '',
        //     errorMessage: t('errorMessage.enterValue'),
        //     type: 'dropdown',
        //     options: countries,
        //     clearable: true
        // },
        {
            key: 'registrationNumber',
            label: t('person.registrationNumber') + '*',
            value: '',
            errorMessage: t('errorMessage.selectDate'),
            required: true,
            type: 'text',
            //dateCustomButton: false,
        },
        {
            key: 'parents',
            label: t('questionnaire.parents') + '*',
            value: '',
            errorMessage: t('errorMessage.selectDate'),
            required: true,
            type: 'text',
            //dateCustomButton: false,
        },
        {
            key: 'firstName',
            label: t('common.firstName') + '*',
            value: '',
            errorMessage: t('errorMessage.enterValue'),
            required: true,
            type: 'text',
        },
       
        {
            key: 'gender',
            label: t('common.gender') + '*',
            value: '',
            errorMessage: t('errorMessage.enterValue'),
            required: true,
            type: 'dropdown',
            options: gender,
            clearable: true
        },
        {
            key: 'birthDate',
            label: t('questionnaire.birthDate') + '*',
            value: '',
            errorMessage: t('errorMessage.enterValue'),
            required: true,
            type: 'date',
            dateCustomButton: false
        },
        {
            key: 'nationality',
            label: t('questionnaire.nationality') + '*',
            value: '',
            errorMessage: t('errorMessage.enterValue'),
            required: true,
            type: 'text',
        },
     
        // {
        //     key: 'enrolledProgram',
        //     label: t('questionnaire.enrolledProgram') + '*',
        //     value: null,
        //     errorMessage: t('errorMessage.enterValue'),
        //     required: true,
        //     type: 'dropdown',
        //     options: curriculums,
        //     clearable: true
        // },     
        
        {
            key: 'address',
            label: t('questionnaire.address') + '*',
            value: '',
            errorMessage: t('errorMessage.enterValue'),
            required: true,
            type: 'textArea',
            typeStyle: {width: 200},
        }
    ];

    const handleContextMenuClick = (id, key) => {
        if(id && key) {
            if(key === 'delete'){
                setDeleteModal(true);
                setTempId(id)
            }
            if(key === 'edit'){
                setTempId(id)
            }
        }
    };

    const onAddClose = () =>{
        setAddModal(false);
    };

    const onClose = () =>{
        setDeleteModal(false);
        setTempId(null)
    };

    const onDelete = () =>{
        if(tempId){

        }
    };

    const handleInteraction = (params) => {
        if(params.search){
            setPage(1)
            setPageSize(params.pageSize)
            setSearch(params.search)           
            
        } else {
            setPage(params.page)
            setSearch(params.search)
        }
    }

    const onSubmit = (params) => {
        //init(params);
        dispatch(setLoading(true));
        fetchRequest(lmsAnketInit, 'POST', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setTableData(data.person);
                    onAddClose();
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            });
    };

    const init = (params) => {
        dispatch(setLoading(true));
        fetchRequest(lmsStudentList, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    if(data.educationLevels && data.educationLevels.length > 0){
                        setEducations(data.educationLevels.map(level => ({ value: level.key, text: level.title })));
                    }
                    if(data.academicPrograms && data.academicPrograms.length > 0){
                        setCurriculums(data.academicPrograms.map(academic => ({ value: academic.id, text: academic.name })));
                    }
                    if(data.countries && data.countries.length > 0){
                        setCountries(data.countries.map(country => ({ value: country.id, text: country.name })));
                    }
                    if(data.statuses && data.statuses.length > 0){
                        setTypes(data.statuses.map(status => ({ value: status.id, text: status.name })));
                    }
                    
                    if(data.programs && data.programs.length > 0){
                        setPrograms(data.programs.map(program => ({ value: program.id, text: program.name })));
                    }

                    let students = data?.students || [];
                    if(students && students.length > 0){
                        for(let i = 0; i < students.length; i++){
                            if(students[i].startDate && students[i].startDate.date){
                                students[i].startDate = students[i].startDate.date.substring(0, 10)
                            }
                        }
                    }
                    setStudents(students);
                    setTableTotalCount(data.totalPage)
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    return (
        <div style={{border: '1px solid #4a70ae'}}>
            <div className='m-8'>
                <button className='btn btn-primary btn-shadow mb-4' onClick={()=>setAddModal(true)}>{t('common.add')}</button>
                <p>{t('questionnaire.mainTitle')}</p>

                <DTable
                    columns={columns}
                    config={config}
                    //data={tableData}
                    remote
                    showOrdering={false}
                    contextMenus={tableContextItem}
                    onContextMenuItemClick={handleContextMenuClick}
                    selectMode={'radio'}
                    onInteraction={handleInteraction}
                />
            </div>
            <AddModal
                open={addModal}
                close={onAddClose}
                onSubmit={onSubmit}
                fields={fields}
                title={t('questionnaire.mainTitle')}
            />
            {
                deleteModal
                &&
                <DeleteModal 
                    onClose={onClose} 
                    onDelete={onDelete}
                    title={t('questionnaire.deleteEducation')}
                >
                    {t('warning.delete_confirmation')}
                    <br/>
                    <br/>
                    {t('warning.delete_confirmation_description')}
                </DeleteModal>
            }
        </div>
    )
};


