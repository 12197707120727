import React, { useEffect, useState } from 'react';
import { Button, Modal, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import message from "../../../modules/message";
import {selectionFindStudents} from "../../../../utils/fetchRequest/Urls";
import RenderTableList from "../../../modules/RenderTableList";

const StudentScoreViewModal = ({
   onClose,
   subjectId
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [subjectInfoArray, setSubjectInfoArray] = useState([]);

    useEffect(() => {
        let subjectArray = [];

        subjectArray.push(
            {
                code: 'code',
                name: t('subject.code'),
                value: 123
            },
            {
                code: 'lastName',
                name: t('subject.name'),
                value: 123
            },
            {
                code: 'name',
                name: t('subject.credit'),
                value: 123
            },
            {
                code: 'enrollmentDate',
                name: t('school.season.studied'),
                value: 123
            },
            {
                code: 'profession',
                name: t('teacher.code'),
                value: 123
            },
            {
                code: 'profession',
                name: t('teacher.name'),
                value: 123
            },
            {
                code: 'profession',
                name: t('assessment.gpa'),
                value: 123
            },
            {
                code: 'profession',
                name: t('assessment.title'),
                value: 123
            }
        );

        setSubjectInfoArray(subjectArray);

        if (subjectId) {
            const params = {
                school: schoolId,
                subject: subjectId,
            };
            dispatch(setLoading(true));
            fetchRequest(selectionFindStudents, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res;

                        if (data?.stItem) {
                            const item = data.stItem;
                        }


                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'));
                })
        }
    }, []);

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('subject.score').toUpperCase()}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{paddingBottom: '12.5px'}}>
                    <Card>
                        <Card.Body>
                            <RenderTableList
                                stringArray={subjectInfoArray}
                            />
                        </Card.Body>
                    </Card>
                </div>
                <Card>
                    <Card.Body>
                        <table className='table table-bordered'>
                            <thead>
                            <tr>
                                <th>{t('common.specification')}</th>
                                <th>{t('assessment.score')}</th>
                                <th>{t('assessment.takenScore')}</th>
                            </tr>
                            </thead>
                        </table>
                    </Card.Body>
                </Card>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className='btn btn-link bolder'>{t('common.close')}</button>
            </Modal.Footer>
        </Modal>
    );
};
export default StudentScoreViewModal