import React, {useEffect, useState} from 'react'
import DTable from '../../../modules/DataTable/DTable'
import { useTranslation } from "react-i18next";
import DeleteModal from '../../../modules/DeleteModal';
import {AddModal} from "./AddModal";
import {setLoading} from "../../../../redux/action";
import {fetchRequest} from "../../../../utils/fetchRequest";
import {studentAnketFamily} from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import {useDispatch, useSelector} from "react-redux";

export const FamilyInformation = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [families, setFamilies] = useState([]);
    const [addModal, setAddModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [tempId, setTempId] = useState(null);
    const [types, setTypes] = useState([]);

    useEffect(() => {
        if(schoolId){
            dispatch(setLoading(true));
            fetchRequest(studentAnketFamily, 'GET', [])
                .then(res => {
                    if (res.success) {
                        const { data } = res;
                        setFamilies(data.families);
                        setTypes(data.memberTypes.map(type => ({value: type.id, text: type.name})));
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'));
                })
        }
    }, []);

    const tableContextItem = [
        {
            key: 'edit',
            title: t('common.edit'),
            icon: <i className='las la-edit dt-cm-item-icon'/>
        },
        {
            key: 'delete',
            title: t('action.delete'),
            icon: <i className='las la-trash-alt dt-cm-item-icon'/>
        },
    ];

    const config ={
        showPagination: false,
        showFilter: false,
    };

    const columns = [
        {
            dataField: 'name',
            text: t('questionnaire.familyMember'),
            sort: true,
        },
        {
            dataField: 'lastName',
            text: t('questionnaire.parents'),
            sort: true,
        },
        {
            dataField: 'firstName',
            text: t('person.firstName'),
            sort: true,
        },
        {
            dataField: 'work',
            text: t('questionnaire.currentJob'),
            sort: true,
        },
        {
            dataField: 'mobile',
            text: t('questionnaire.mobile'),
            sort: true,
        },
    ];

    const fields = [
        {
            key: 'memberType',
            label: t('questionnaire.familyMember') + '*',
            value: null,
            type: 'dropdown',
            required: true,
            options: types,
            errorMessage: t('errorMessage.selectValue'),
            clearable: true
        },
        {
            key: 'lastName',
            label: t('questionnaire.parents') + '*',
            value: '',
            errorMessage: t('errorMessage.enterValue'),
            required: true,
            type: 'text',
        },
        {
            key: 'firstName',
            label: t('person.firstName') + '*',
            value: '',
            errorMessage: t('errorMessage.enterValue'),
            required: true,
            type: 'text',
        },
        {
            key: 'work',
            label: t('questionnaire.currentJob') + '*',
            value: '',
            errorMessage: t('errorMessage.enterValue'),
            required: true,
            type: 'text',
        },
        {
            key: 'mobile',
            label: t('questionnaire.mobile') + '*',
            value: '',
            errorMessage: t('errorMessage.enterValue'),
            required: true,
            type: 'numberOnly',
        },
    ];

    const handleContextMenuClick = (id, key) => {
        if(id && key) {
            if(key === 'delete'){
                setDeleteModal(true);
                setTempId(id)
                
            }
            if(key === 'edit'){
                setTempId(id)
            }
        }
    };

    const onAddClose = () =>{
        setAddModal(false);
    };

    const onClose = () =>{
        setDeleteModal(false);
        setTempId(null)
    };

    const onDelete = () =>{
        if(tempId){

        }
    };

    const onSubmit = (params) => {
        dispatch(setLoading(true));
        fetchRequest(studentAnketFamily, 'POST', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setFamilies(data.families);
                    onAddClose();
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            });
    };

    return (
        <div style={{border: '1px solid #4a70ae'}}>
            <div className='m-8'>
                <button className='btn btn-primary btn-shadow mb-4' onClick={()=>setAddModal(true)}>{t('common.add')}</button>
                <p>{t('questionnaire.onlyFamilyMembers')}</p>

                <DTable
                    columns={columns}
                    config={config}
                    data={families}
                    remote
                    showOrdering={false}
                    contextMenus={tableContextItem}
                    onContextMenuItemClick={handleContextMenuClick}
                    selectMode={'radio'}
                />
            </div>
            <AddModal
                open={addModal}
                close={onAddClose}
                onSubmit={onSubmit}
                fields={fields}
                title={t('questionnaire.familyTitle')}
            />
            {
                deleteModal
                &&
                <DeleteModal 
                    onClose={onClose} 
                    onDelete={onDelete}
                    title={t('questionnaire.deleteFamilyMember')}
                >
                    {t('warning.delete_confirmation')}
                    <br/>
                    <br/>
                    {t('warning.delete_confirmation_description')}
                </DeleteModal>
            }
        </div>
    )
};
