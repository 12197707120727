import React, {useEffect, useState} from 'react'
import DTable from '../../../../modules/DataTable/DTable'
import { useTranslation } from "react-i18next";
import DeleteModal from '../../../../modules/DeleteModal';
import {AddModal} from "./AddModal";
import {lmsAnketEducation} from "../../../../../utils/fetchRequest/Urls";
import {setLoading} from "../../../../../redux/action";
import {fetchRequest} from "../../../../../utils/fetchRequest";
import message from "../../../../modules/message";
import {useDispatch, useSelector} from "react-redux";

export const EducationInformation = ({
    studentId
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [educations, setEducations] = useState([]);
    const [addModal, setAddModal] = useState(false);
    const [deleteModal,setDeleteModal] = useState(false);
    const [tempId,setTempId] = useState(null);
    const [types, setTypes] = useState([]);

    useEffect(() => {
        if(schoolId){
            let params = {
                school: schoolId,
                id: studentId
            };

            dispatch(setLoading(true));
            fetchRequest(lmsAnketEducation, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res;
                        setEducations(data?.eyeshScores);
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'));
                })
        }
    }, []);

    const tableContextItem = [
        // {
        //     key: 'edit',
        //     title: t('common.edit'),
        //     icon: <i className='las la-edit dt-cm-item-icon'/>
        // },
        // {
        //     key: 'delete',
        //     title: t('action.delete'),
        //     icon: <i className='las la-trash-alt dt-cm-item-icon'/>
        // },
    ];

    const config ={
        showPagination: false,
        showFilter: false,
    };

    const columns = [
        {
            dataField: 'educationLevel',
            text: t('curriculum.educationLevel'),
            sort: true,
        },
        {
            dataField: 'schoolName',
            text: t('school.name'),
            sort: true,
        },
        {
            dataField: 'enrolledYear',
            text: t('questionnaire.entryDate'),
            sort: true,
        },
        {
            dataField: 'gradYear',
            text: t('questionnaire.completionDate'),
            sort: true,
        },
        {
            dataField: 'diploma',
            text: t('questionnaire.diplom'),
            sort: true,
        },
    ];

    const fields = [
        {
            key: 'code',
            label: t('curriculum.educationLevel') + '*',
            value: null,
            type: 'dropdown',
            required: true,
            options: types,
            errorMessage: t('errorMessage.enterValue'),
            clearable: true
        },
        {
            key: 'name',
            label: t('school.name') + '*',
            value: '',
            errorMessage: t('errorMessage.enterValue'),
            required: true,
            type: 'text',
        },
        {
            key: 'entryDate',
            label: t('questionnaire.entryDate') + '*',
            value: '',
            errorMessage: t('errorMessage.selectDate'),
            required: true,
            type: 'date',
            dateCustomButton: false,
        },
        {
            key: 'completionDate',
            label: t('questionnaire.completionDate') + '*',
            value: '',
            errorMessage: t('errorMessage.selectDate'),
            required: true,
            type: 'date',
            dateCustomButton: false,
        },
        {
            key: 'documentNumber',
            label: t('questionnaire.documentNumber') + '*',
            value: '',
            errorMessage: t('errorMessage.enterValue'),
            required: true,
            type: 'text',
        },
    ];

    const handleContextMenuClick = (id, key) => {
        if(id && key) {
            if(key === 'delete'){
                setDeleteModal(true);
                setTempId(id)
            }
            if(key === 'edit'){
                setTempId(id)
            }
        }
    };

    const onAddClose = () =>{
        setAddModal(false);
    };

    const onClose = () =>{
        setDeleteModal(false);
        setTempId(null)
    };

    const onDelete = () =>{
        if(tempId){

        }
    };

    const onSubmit = (params) => {
    };

    return (
        <div style={{border: '1px solid #4a70ae'}}>
            <div className='m-8'>
                {/*<button className='btn btn-primary btn-shadow mb-4' onClick={()=>setAddModal(true)}>{t('common.add')}</button>*/}
                {/*<p>{t('questionnaire.educationInfo')}</p>*/}

                <DTable
                    columns={columns}
                    config={config}
                    data={educations}
                    remote
                    showOrdering={false}
                    contextMenus={tableContextItem}
                    onContextMenuItemClick={handleContextMenuClick}
                    selectMode={'radio'}
                />
            </div>
            <AddModal
                open={addModal}
                close={onAddClose}
                onSubmit={onSubmit}
                fields={fields}
                title={t('questionnaire.educationTitle')}
            />
            {
                deleteModal
                &&
                <DeleteModal 
                    onClose={onClose} 
                    onDelete={onDelete}
                    title={t('questionnaire.deleteEducation')}
                >
                    {t('warning.delete_confirmation')}
                    <br/>
                    <br/>
                    {t('warning.delete_confirmation_description')}
                </DeleteModal>
            }
        </div>
    )
};
