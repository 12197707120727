import React, { useState, useEffect, useRef } from 'react';
import { Button, Card, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DTable from "../../../modules/DataTable/DTable";
import { fetchRequest } from "../../../../utils/fetchRequest";
import {useDispatch, useSelector} from "react-redux";
import { setLoading } from "../../../../redux/action";
import {
    subjectTypeAdd,
    subjectTypeDelete,
    subjectTypeEdit, subjectTypeReorder,
} from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import { getLastOrderNumber } from "../../../../utils/utils";
import Forms from "../../../modules/Form/Forms";
import qs from "qs";

const SubjectType = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const languages = useSelector(state => state?.languages?.languages || []);
    const selectedSchool = useSelector(state => state?.selectedSchool || []);
    const parentId = useSelector(state => state?.selectedSchool.parentId || null);
    const [ addModal, setAddModal ] = useState(false);
    const [ editModal, setEditModal ] = useState(false);
    const [ deleteModal, setDeleteModal ] = useState(false);
    const [ subjectTypes, setSubjectTypes ] = useState([]);
    const [ subjectTypeId, setSubjectTypeId ] = useState(null);

    const formRef = useRef();
    const translateFormRef = useRef();
    const editForm = useRef();
    const editTranslateFormRef = useRef();

    useEffect(() => {
        dispatch(setLoading(true));
        let params = {
            school: selectedSchool.id
        };

        fetchRequest(subjectTypeAdd + '?' + qs.stringify(params), 'GET')
            .then(response => {
                if (response.success) {
                    setSubjectTypes(response.data.subjectTypes);
                } else {
                    message(response?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
            })
    }, []);

    const config = {
        showPagination: false,
        showFilter: false,
        showAllData: true
    };

    const columns = [
        {
            dataField: "name",
            text: t('menu.curriculumSubjectType'),
            sort: true,
        },
        {
            dataField: "ordering",
            text: t('common.ordering'),
            style: {textAlign: 'right'},
            sort: true,
        },
    ];

    const contextMenus = [
        {
            key: 'edit',
            title: t('common.edit'),
            icon: <i className='las la-edit dt-cm-item-icon'/>
        },
        {
            key: 'delete',
            title: t('common.delete'),
            icon: <i className='las la-trash-alt dt-cm-item-icon' />,
        },
    ];

    const formFields = [
        {
            label: t('menu.curriculumSubjectType') + '*',
            value: '',
            type: 'text',
            required: true,
            key: 'name',
            errorMessage: t('errorMessage.enterCurriculumSubjectType'),
        },
        {
            label: t('school.isOtherSchool'),
            type: 'checkbox',
            value: true,
            hidden: parentId == null ? false : true,
            key: 'isSchool',
        },
    ];

    const getFormTranslations = () => {
        const array = [];
        for (const language of languages) {
            if('mn' !== language.code){
                array.push(
                    {
                        label: `${t('menu.curriculumSubjectType')} - ${language.name} (${language.code})`,
                        value: '',
                        required: false,
                        type: 'text',
                        langCode: language.code,
                        key: 'name',
                    }
                );
            }
        }
        return array;
    };

    const handleContextMenuClick = (id, key, record) => {
        setSubjectTypeId(id);
        if (key === 'edit'){
            handlerEditModal();
            const params = {
                subjectType: id,
                school: selectedSchool.id,
            };
            dispatch(setLoading(true));
            fetchRequest(subjectTypeEdit, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res;

                        if (data?.subjectType) {
                            editForm?.current?.updateFields && editForm.current.updateFields([
                                {
                                    label: t('menu.curriculumSubjectType') + '*',
                                    value: data.subjectType.name || '',
                                    type: 'text',
                                    required: true,
                                    key: 'name',
                                    errorMessage: t('errorMessage.enterCurriculumSubjectType'),
                                },
                            ]);

                            const array = [];
                            const translations = data.subjectType.translations || [];
                            for (const language of languages) {
                                if('mn' !== language.code){

                                    const translation = translations.find(tr => tr.langCode === language.code);
                                    const value = translation?.name || '';

                                    array.push(
                                        {
                                            label: `${t('menu.curriculumSubjectType')} - ${language.name}(${language.code})`,
                                            value,
                                            required: false,
                                            type: 'text',
                                            langCode: language.code,
                                            key: 'name',
                                        }
                                    );
                                }
                            }
                            editTranslateFormRef?.current?.updateFields && editTranslateFormRef.current.updateFields(array);
                        }
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        } else if (key === 'delete'){
            handlerDeleteModal();
        }
    };

    const handlerAddModal = () => {
        setAddModal(true);
    };

    const handlerAddModalClose = () => {
        setAddModal(false);
        setSubjectTypeId(null);
    };

    const handlerEditModal = () => {
        setEditModal(true);
    };

    const handlerEditModalClose = () => {
        setEditModal(false);
        setSubjectTypeId(null);
    };

    const handlerDeleteModal = () => {
        setDeleteModal(true);
    };

    const handlerDeleteModalClose = () => {
        setDeleteModal(false);
        setSubjectTypeId(null);
    };

    const onDrop = drop => {
        const formData = new FormData();

        formData.append('school', selectedSchool.id);
        formData.append('item', drop.itemId);
        formData.append('target', drop.targetId);

        dispatch(setLoading(true));
        fetchRequest(subjectTypeReorder, 'POST', formData, false, true)
            .then(res => {
                if (res.success) {
                    setSubjectTypes(res.data?.subjectTypes || []);
                    message(res?.data?.message || t('common.success'), true);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const handlerOnSaveClick = () => {
        const [ formValid, formValue ] = formRef.current.validate();
        const [ translateValid, translateValues ] = translateFormRef.current.validate();

        if (formValid) {
            dispatch(setLoading(true));
            const ordering = getLastOrderNumber(subjectTypes) + 1;
            const params = {
                school: selectedSchool.id,
                name: formValue?.[0]?.value,
                isSchool: formValue?.[1]?.value == true ? 1 : 0,
                ordering: ordering
            };

            let translations = [];
            if(languages && languages.length > 0){
                languages.map(language => {
                    if('mn' !== language.code){
                        const name = translateValues.find(val => val.key === 'name' && val.langCode === language.code && val.value);

                        if (name) {
                            translations.push({
                                langCode: language.code,
                                name: name.value
                            })
                        }
                    }
                });

                params.translations = JSON.stringify(translations);
            }

            fetchRequest(subjectTypeAdd, 'POST', { ...params })
                .then(response => {
                    if (response.success) {
                        message(response?.data?.message || t('common.success'), true);
                        setSubjectTypes(response.data?.subjectTypes || []);
                        handlerAddModalClose();
                    } else {
                        message(response?.data?.message || t('errorMessage.title'));
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'));
                })
        }
    };

    const handlerOnDeleteClick = () => {
        const params = {
            subjectType: subjectTypeId,
            school: selectedSchool.id,
        };
        dispatch(setLoading(true));
        fetchRequest(subjectTypeDelete, 'POST', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    message(data?.message || t('common.success'), true);
                    setSubjectTypes(data?.subjectTypes || []);
                    handlerDeleteModalClose();
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const saveEdited = () => {
        const [ formValid, formValue ] = editForm.current.validate();
        const [ translateValid, translateValues ] = editTranslateFormRef.current.validate();

        if (formValid) {
            dispatch(setLoading(true));
            const subjectType = subjectTypes.find(type => type.id === subjectTypeId);
            const params = {
                school: selectedSchool.id,
                name: formValue?.[0]?.value,
                subjectType: subjectTypeId,
                ordering: subjectType?.ordering || getLastOrderNumber(subjectTypes) + 1,
            };

            const translations = [];
            if(languages && languages.length > 0){
                languages.map(language => {
                    if('mn' !== language.code){
                        const name = translateValues.find(val => val.key === 'name' && val.langCode === language.code && val.value);

                        if (name) {
                            translations.push({
                                langCode: language.code,
                                name: name.value
                            })
                        }
                    }
                });

                params.translations = JSON.stringify(translations);
            }

            fetchRequest(subjectTypeEdit, 'POST', { ...params })
                .then(response => {
                    if (response.success) {
                        message(response?.data?.message || t('common.success'), true);
                        setSubjectTypes(response.data?.subjectTypes || []);
                        handlerEditModalClose();
                    } else {
                        message(response?.data?.message || t('errorMessage.title'));
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'));
                })
        }
    };

    return (
        <Card>
            <Card.Body>
                <button
                    className='btn btn-primary'
                    onClick={handlerAddModal}
                >
                    {t('common.add')}
                </button>
                <DTable
                    draggable
                    data={subjectTypes}
                    config={config}
                    columns={columns}
                    contextMenus={contextMenus}
                    onContextMenuItemClick={handleContextMenuClick}
                    onDrop={onDrop}
                    selectMode={'radio'}
                />
                <Modal
                    show={addModal}
                    onHide={handlerAddModalClose}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                                {t('menu.curriculumSubjectType').toUpperCase()}
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='col-12'>
                            <Forms
                                ref={formRef}
                                fields={formFields}
                            />
                        </div>
                        <div className="separator separator-dashed my-7"/>
                        <div className='col-12'>
                            <Forms
                                ref={translateFormRef}
                                fields={getFormTranslations()}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="btn btn-link bolder" onClick={handlerAddModalClose}>{t('common.back')}</Button>
                        <Button variant="success btn-shadow" onClick={handlerOnSaveClick}>{t('common.save')}</Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={editModal}
                    onHide={handlerEditModalClose}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                                {t('menu.curriculumSubjectType').toUpperCase()}
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='col-12'>
                            <Forms
                                ref={editForm}
                                fields={formFields}
                            />
                        </div>
                        <div className="separator separator-dashed my-7"/>
                        <div className='col-12'>
                            <Forms
                                ref={editTranslateFormRef}
                                fields={getFormTranslations()}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="btn btn-link bolder" onClick={handlerEditModalClose}>{t('common.cancel')}</Button>
                        <Button variant="success btn-shadow" onClick={saveEdited}>{t('common.save')}</Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={deleteModal}
                    onHide={handlerDeleteModalClose}
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                                {t('warning.delete').toUpperCase()}
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            {t('warning.delete_confirmation')}
                            <br/>
                            <br/>
                            {t('warning.delete_confirmation_description')}
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="btn btn-link bolder" onClick={handlerDeleteModalClose}>{t('common.cancel')}</Button>
                        <Button variant="danger btn-shadow" onClick={handlerOnDeleteClick}>{t('warning.delete')}</Button>
                    </Modal.Footer>
                </Modal>
            </Card.Body>
        </Card>
    );
};

export default SubjectType;