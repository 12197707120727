/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from "react";
import { useDispatch } from "react-redux";
import {setAuth, setInitData, setPersonInfo, setSelectedSchool, setUserMenus} from "../../../../redux/action";
import {Redirect, useHistory} from "react-router-dom";
import {} from '@azure/msal-browser';

export function LogoutPage() {
    const history = useHistory();
    const dispatch = useDispatch();
    const [ menuIndex ] = useState('menu_list_index');
    dispatch(setAuth(null));
    dispatch(setUserMenus([]));
    dispatch(setPersonInfo([]));
    dispatch(setInitData([]));
    dispatch(setSelectedSchool([]));
    localStorage.removeItem(menuIndex);

    return (
        <>
            <Redirect to="/auth/login"/>
        </>
    );
}
