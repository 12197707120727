import React, {useState, useEffect, useRef} from 'react';
import {Card, Button} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {Link, useHistory} from "react-router-dom";
import {setLoading} from "../../../../redux/action";
import Forms from "../../../modules/Form/Forms";
import {fetchRequest} from "../../../../utils/fetchRequest";
import {academicProgramEdit} from "../../../../utils/fetchRequest/Urls";
import qs from "qs";
import {useDispatch, useSelector} from "react-redux";
import i18n from "i18next";
import message from "../../../modules/message";

const AcademicEdit = ({}) => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const selectedSchool = useSelector(state => state?.selectedSchool || []);
    const languages = useSelector(state => state?.languages?.languages || []).filter(lang => lang.code !== 'mn');
    const [academicId] = useState(history?.location?.state?.id || null);
    const [academicData, setAcademicData] = useState(null)

    const formRef = useRef();
    const translateForm = useRef();

    const translationFields = languages.map(lang => {
        return {
            label: `${t('common.name')} - ${lang.name} (${lang.code})`,
            value: '',
            langCode: lang.code,
            type: 'text',
            key: 'name',
            inputStyle: lang.code == 'tr' ? {fontFamily: 'Urga'} : {}
        }
    });

    const forms = [
        {
            key: 'department',
            label: t('school.parentDepartment') + '*',
            value: null,
            errorMessage: t('errorMessage.selectRelatedDepartment'),
            required: true,
            type: 'dropdown',
            options: [],
            multiple: false,
            clearable: true
        },
        {
            key: 'academicCode',
            label: t('curriculum.code') + '*',
            value: '',
            errorMessage: t('errorMessage.enterCode'),
            required: true,
            type: 'nonCryllic',
            upperCase: true,
        },
        {
            key: 'name',
            label: t('curriculum.name') + '*',
            value: '',
            errorMessage: t('errorMessage.enterName'),
            required: true,
            type: 'text',
        },
    ];

    useEffect(() => {
        dispatch(setLoading(true));
        let params = {
            school: selectedSchool.id,
            academic: academicId
        };

        fetchRequest(academicProgramEdit + '?' + qs.stringify(params), 'GET')
            .then(response => {
                if (response.success) {
                    const { data } = response;
                    const translations = data.academicData.translations;

                    if (translationFields && translationFields.length > 0 && translations.length > 0)
                    {
                        for (let i = 0; i < translationFields.length; i++)
                        {
                            for (let t = 0; t < translations.length; t++)
                            {
                                if (translationFields[i]['langCode'] === translations[t]['langCode'])
                                {
                                    translationFields[i]['value'] = translations[t]['name'];
                                }
                            }
                        }
                    }

                    formRef?.current?.updateFields && formRef.current.updateFields([
                        {
                            key: 'department',
                            label: t('school.parentDepartment') + '*',
                            value: data.academicData.departmentId,
                            errorMessage: t('errorMessage.selectRelatedDepartment'),
                            required: true,
                            type: 'dropdown',
                            options: data.departments,
                            multiple: false,
                            clearable: true,
                            disabled: data.academicData.statusCode == 'PUBLISH' ? true : false,
                        },
                        {
                            key: 'code',
                            label: t('common.code') + '*',
                            value: data.academicData.code || '',
                            errorMessage: t('errorMessage.enterCode'),
                            required: true,
                            type: 'nonCryllic',
                            upperCase: true,
                            disabled: data.academicData.statusCode == 'PUBLISH' ? true : false,
                        },
                        {
                            key: 'academicCode',
                            label: t('curriculum.code') + '*',
                            value: data.academicData.academicCode || '',
                            errorMessage: t('errorMessage.enterCode'),
                            required: true,
                            type: 'nonCryllic',
                            upperCase: true,
                            disabled: data.academicData.statusCode == 'PUBLISH' ? true : false,
                        },
                        {
                            key: 'name',
                            label: t('curriculum.name') + '*',
                            value: data.academicData.name || '',
                            errorMessage: t('errorMessage.enterName'),
                            required: true,
                            type: 'text',
                        },
                    ]);

                    setAcademicData(data.academicData)
                } else {
                    message(response?.data?.message, false);
                }
                dispatch(setLoading(false));
            });
    }, []);

    const handlerOnSaveClick = (status) => {
        const [ formValid, formValue, values ] = formRef.current.validate();
        const [ translateValid, translateValues ] = translateForm.current.validate();

        if (formValid) {
            const params = {
                academic: academicId,
                school: selectedSchool.id,
                ...values,
                status: status
            };

            if (formValue?.[0]?.value) {
                params.department = formValue[0].value;
            }

            let translations = [];
            if(languages && languages.length > 0){
                languages.map(language => {
                    if(language.code !== 'mn'){
                        const name = translateValues.find(val => val.key === 'name' && val.langCode === language.code && val.value);

                        if (name) {
                            translations.push({
                                langCode: language.code,
                                name: name.value
                            })
                        }
                    }
                });

                params.translations = JSON.stringify(translations);
            }

            dispatch(setLoading(true));
            fetchRequest(academicProgramEdit, 'POST', params, false)
                .then(response => {
                    if (response.success) {
                        history.push('/school/academic')
                    } else {
                        message(response?.data?.message, false);
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                })
        }
    };

    return (
        <div className='row'>
            <div className='col-12'>
                <Card>
                    <Card.Body>
                        <div className='col-12'>
                            <Forms
                                ref={formRef}
                                fields={forms}
                            />
                        </div>
                        <div className="separator separator-dashed my-7"/>
                        <div className='col-12'>
                            <Forms
                                ref={translateForm}
                                fields={translationFields}
                            />
                        </div>
                    </Card.Body>
                    <Card.Footer>
                        <div className='col-12 text-center'>
                            <Link
                                to='/school/academic'
                                className="btn btn-link bolder"
                            >
                                {t('common.back')}
                            </Link>
                            {
                                academicData && academicData.statusCode == 'PUBLISH'
                                ?
                                    <Button variant="success btn-shadow" onClick={() => {handlerOnSaveClick(1)}}>{t('common.save')}</Button>
                                :   
                                    <>
                                        <Button variant="success btn-shadow" onClick={() => {handlerOnSaveClick(0)}}>{t('common.save')}</Button>
                                        <Button variant="publish btn-shadow ml-3" onClick={() => {handlerOnSaveClick(1)}}>{t('action.publish')}</Button>
                                    </>
                            }
                        </div>
                    </Card.Footer>
                </Card>
            </div>
        </div>
    )
};

export default AcademicEdit;