import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
    setPerson: "SET_PERSON_INFO",
};

const initState = {
    firstName: null,
    lastName: null,
    avatar: null,
    code: null,
    registerNumber: null,
    email: null,
    phone: null,
    isActive: false,
    isStudent: false,
    isSurvey: false,
    password: null,
};

export const personInfo = persistReducer(
    { storage, key: 'person', whitelist: ['firstName', 'lastName', 'avatar', 'email', 'phone', 'isActive', 'isStudent', 'isSurvey', 'password', 'code', 'registerNumber'] }, (state = initState, action) => {
        switch (action.type) {
            case actionTypes.setPerson: {
                return {
                    firstName: action.payload.firstName,
                    lastName: action.payload.lastName,
                    avatar: action.payload.avatar,
                    email: action.payload.email,
                    phone: action.payload.phone,
                    isActive: action.payload.isActive,
                    isStudent: action.payload.isStudent,
                    isSurvey: action.payload.isSurvey,
                    password: action.payload.password,
                    code: action.payload.code,
                    registerNumber: action.payload.registerNumber,
                };
            }
            default:
                return state;
        }
    }
);

export default personInfo;