import React from 'react';
import { makeStyles, Paper, InputBase, IconButton } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles({
    root: {
        padding: '0px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 220,
        borderColor: '#d9e0e6',
        borderWidth: 1,
        borderStyle: 'solid',
    },
    input: {
        marginLeft: 8,
        flex: 1,
    },
    iconButton: {
        padding: 8,
    },
});

const Search = ({
    onSearch,
    value,
    setter
}) => {
    const handleSearchClick = () => {
        onSearch(value);
    };

    const onHandlerKeyDown = (event) => {
        if(event.key == 'Enter'){
            onSearch(value);
        }
    }

    const classes = useStyles();
    return (
        <Paper className={classes.root}>
            <InputBase
                onKeyDown={onHandlerKeyDown}
                value={value}
                className={classes.input}
                placeholder={'Search...'}
                inputProps={{
                    'aria-label': 'Search...'
                }}
                onChange={e => setter(e.target.value)}
            />
            <IconButton
                className={classes.iconButton}
                aria-label="Search"
                onClick={handleSearchClick}
            >
                <SearchIcon />
            </IconButton>
        </Paper>
    )
};

export default Search;