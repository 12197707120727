import React, { useEffect, useState } from 'react';
import Instruction from "../../../modules/instruction";
import DTable from "../../../modules/DataTable/DTable";
import { useTranslation } from "react-i18next";
import { setLoading } from "../../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { fetchRequest } from "../../../../utils/fetchRequest";
import message from "../../../modules/message";
import { movementInit, movementBetweenUndo } from "../../../../utils/fetchRequest/Urls";
import { Link } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import InternalViewModal from './modals/InternalViewModal';
import UndoModal from './modals/UndoModal';

const Internal = ({
    educationLevel = null,
    isClick,
    split
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    const [movements, setMovements] = useState([]);
    const [totalCount, setTotalCount] = useState(0)
    const [viewModal, setViewModal] = useState(false)
    const [viewUndoModal, setViewUndoModal] = useState(false)
    const [tempId, setTempId] = useState()
    const [tableChangeParams, setTableChangeParams] = useState(null)

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [search, setSearch] = useState('');

    const config = {

    }

    const contextMenus = [
        {
            key: 'view',
            title: t('action.view'),
            icon: <i className='las la-eye dt-cm-item-icon' />,
        },
        {
            key: 'edit',
            title: t('common.edit'),
            icon: <i className='las la-edit dt-cm-item-icon' />
        },
        {
            key: 'undo',
            title: t('action.undo'),
            icon: <i className='las la-backspace dt-cm-item-icon' />,
        },
    ];

    const columns = [
        {
            dataField: 'createdDate',
            text: t('common.date'),
            sort: true,
            formatter(cell, row) {
                return (
                    <div className='msue-dt-clickable-cell' onClick={() => onDateClick(row)}>
                        {cell.date?.substring(0, 19)}
                    </div>
                )
            },
        },
        {
            dataField: "studentCode",
            text: t('student.code'),
            sort: true
        },
        {
            dataField: "firstName",
            text: t('student.name'),
            sort: true
        },
        {
            dataField: "toClass",
            text: t('movement.enrolledClass'),
            sort: true
        },
        {
            dataField: "fromClass",
            text: t('movement.leftClass'),
            sort: true
        },
        {
            dataField: "createdFirstName",
            text: t('common.createdUser'),
            sort: true
        },
    ];

    const contextMenuClick = (id, key, row) => {
        if (id && key) {
            if (key === 'edit') {
                if ((!isClick && split.length === 2) || split.length === 2) {
                    message(t('errorMessage.selectEducationLevel'))
                } else {
                    history.push({
                        pathname: '/movement/add-int',
                        state: {
                            movement: id,
                            studentCode: row.studentCode,
                            level: educationLevel
                        }
                    })
                }
            } else if (key === 'undo') {
                setViewUndoModal(true)
                setTempId(id)
            } else if (key === 'view') {
                setViewModal(true)
                setTempId(id)
            }
        }
    }

    const onDateClick = row => {
        setViewModal(true)
        setTempId(row.id)
    }

    const onUndo = () => {
        if (tempId) {
            const params = {
                movement: tempId,
                school: schoolId
            }
            dispatch(setLoading(true));
            fetchRequest(movementBetweenUndo, 'POST', params)
                .then(res => {
                    if (res.success) {
                        setViewUndoModal(false)
                        setTempId(null)
                        dispatch(setLoading(false))
                        fetchData(tableChangeParams)
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                        dispatch(setLoading(false))
                    }
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        }
    }

    const fetchData = params => {
        const bodyParams = {
            ...params,
            school: schoolId,
            educationLevel,
            movementType: 'INSIDE',
            page,
            pageSize,
            search
        }
        dispatch(setLoading(true));
        fetchRequest(movementInit, 'GET', bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setMovements(data?.movements)
                    setTotalCount(data?.totalCount || 0)
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const handleTableChange = data => {
        setPage(data.page);
        setPageSize(data.pageSize);
        setSearch(data.search);
        setTableChangeParams(data);
    };

    useEffect(() => {
        if (educationLevel) {
            fetchData();
        }
    }, [educationLevel, page, pageSize, search]);

    return (
        <div>
            <div style={{ display: 'flex' }}>
                <Link
                    className='btn btn-primary mr-3'
                    to={{
                        pathname: '/movement/add-int',
                        state: {
                            level: educationLevel
                        }
                    }}
                >
                    {t('movement.move')}
                </Link>
                <Instruction />
            </div>
            <DTable
                remote
                data={movements}
                config={config}
                columns={columns}
                contextMenus={contextMenus}
                onContextMenuItemClick={contextMenuClick}
                onInteraction={handleTableChange}
                totalDataSize={totalCount}
            />
            {
                viewModal
                    ?
                    <InternalViewModal
                        movementId={tempId}
                        onClose={() => {
                            setViewModal(false)
                            setTempId(null)
                        }}
                    />
                    : null
            }
            {
                viewUndoModal
                    ?
                    <UndoModal
                        onClose={() => {
                            setViewUndoModal(false)
                            setTempId(null)
                        }}
                        onDelete={onUndo}
                    />
                    : null
            }
        </div>
    );
};

export default Internal;