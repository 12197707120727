import React, { useState, useEffect } from 'react';
import DTable from "../../modules/DataTable/DTable";

const DataTableTest = ({

                       }) => {

    const [ data, setData ] = useState([]);

    useEffect(() => {
        async function getFakeData() {
            const response = await fetch('https://jsonplaceholder.typicode.com/posts');
            const q = await response.json();
            const w = q.map((obj, index) => {
                return {
                    ...obj,
                    contextMenuKeys: ['add', 'edit', 'delete'].slice(index % 3 * -1)
                }
            })
            setData(w);
        }
        getFakeData();
    }, []);

    const config = {
        showPagination: true,
    }

    const columns = [
        {
            dataField: "id",
            text: "ID",
            sort: true,
            // sortCaret: sortCaret,
            // headerSortingClasses,
        },
        {
            dataField: "title",
            text: "Firstname",
            sort: true,
            classes: ''
            // sortCaret: sortCaret,
            // headerSortingClasses,
        },
        {
            dataField: "body",
            text: "Lastname",
            sort: true,
            // sortCaret: sortCaret,
            // headerSortingClasses,
        },
        // {
        //     dataField: "email",
        //     text: "Email",
        //     sort: true,
        //     // sortCaret: sortCaret,
        //     // headerSortingClasses,
        // },
        // {
        //     dataField: "gender",
        //     text: "Gender",
        //     sort: false,
        //     // sortCaret: sortCaret,
        // },
        // {
        //     dataField: "status",
        //     text: "Status",
        //     sort: true,
        //     // sortCaret: sortCaret,
        //     // formatter: columnFormatters.StatusColumnFormatter,
        //     // headerSortingClasses,
        // },
        // {
        //     dataField: "type",
        //     text: "Type",
        //     sort: true,
        //     // sortCaret: sortCaret,
        //     // formatter: columnFormatters.TypeColumnFormatter,
        // },
        // {
        //     dataField: "action",
        //     text: "Actions",
        //     // formatter: columnFormatters.ActionsColumnFormatter,
        //     // formatExtraData: {
        //     //     openEditCustomerDialog: customersUIProps.openEditCustomerDialog,
        //     //     openDeleteCustomerDialog: customersUIProps.openDeleteCustomerDialog,
        //     // },
        //     classes: "text-right pr-0",
        //     headerClasses: "text-right pr-3",
        //     style: {
        //         minWidth: "100px",
        //     },
        // },
    ];

    const tableData = [
        {
            id: 1,
            code: 'MH-1',
            subjectName: 'Mongol Hel',
            type: 'Hel bichig',
            contextMenuKeys: ['delete', 'edit', 'add'],
        },
        {
            id: 2,
            code: 'Math-f',
            subjectName: 'Mathematics',
            type: 'QWer',
            contextMenuKeys: [],
        },
    ]

    const contextMenus = [
        {
            key: 'delete',
            title: 'Ustgah',
        },
        {
            key: 'edit',
            title: 'Zasah',
        },
        {
            key: 'add',
            title: 'Nemeh',
        },
    ];

    const handleContextMenuClick = (id, key, record) => {

    };

    const handleTableInteraction = (object) => {

    };

    return (
        <div>
            Data table Component
            <DTable
                data={data}
                // data={[]}
                columns={columns}
                onInteraction={handleTableInteraction}
                config={config}
                contextMenus={
                    [
                        {
                            key: 'add',
                            title: 'Nemeh',
                        },
                        {
                            key: 'edit',
                            title: 'Zasah',
                        },
                        {
                            key: 'delete',
                            title: 'Ustgah',
                        },
                    ]
                }
                onContextMenuItemClick={handleContextMenuClick}
                draggable
                // totalDataSize={null}
                // remote
                // loading={true}
            />
        </div>
    )
};

export default DataTableTest;