import React,{ useState,useRef,useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import Forms from '../../../modules/Form/Forms'
import {useTranslation} from "react-i18next";

export const UpdateModal = ({
    request,
    statuses,
    close,
    onSubmit
}) => {
    const formRef = useRef();
    const { t } = useTranslation();
    const [requestStatus, setRequestStatus] = useState(statuses);

    useEffect(() => {
        formRef?.current?.updateFields && formRef.current?.updateFields(fields);
    }, [request]);

    const fields = [
        {
            key: 'contact',
            label: t('common.phoneNumber'),
            value: request.contact || '',
            type: 'numberOnly',
            inputWidth: '220px',
            labelWidth: '30%',
            disabled: true
        },
        {
            key: 'requestType',
            label: t('common.requestType'),
            value: request.typeName || '',
            type: 'text',
            inputWidth: '300px',
            labelWidth: '30%',
            disabled: true
        },
        {
            key: 'content',
            label: t('common.cause'),
            value: request.content || '',
            type: 'textArea',
            inputWidth: '300px',
            labelWidth: '30%',
            rows: 5,
            disabled: true
        },
        {
            key: 'status',
            label: t('common.status'),
            value: '',
            type: 'dropdown',
            options: requestStatus,
            inputWidth: '300px',
            labelWidth: '30%',
            required: true,
            clearable: true
        },
        {
            key: 'description',
            label: t('common.answer'),
            value: '',
            type: 'textArea',
            inputWidth: '300px',
            labelWidth: '30%',
            rows: 5,
            required: false,
        },
    ];

    const onSave = () =>{
        const [isValid, states, values] = formRef.current.validate();
        if(isValid){
            const params = {
                request: request.id,
                ...values
            };

            onSubmit(params);
        }
    };

    return (
        <Modal
            show={true}
            onHide={close}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('common.requestSend').toUpperCase()}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Forms
                    ref={formRef}
                    fields={fields}
                />
            </Modal.Body>
            <Modal.Footer>
                <button className='btn btn-link' onClick={close}>{t('common.back')}</button>
                <button className='btn btn-success' onClick={onSave}>{t('common.send')}</button>
            </Modal.Footer>
        </Modal>
    )
};
