import React, {useRef, useEffect, useState} from 'react';
import {Button} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {regulationEdit} from "../../../../../utils/fetchRequest/Urls";
import { setLoading } from "../../../../../redux/action";
import { fetchRequest } from "../../../../../utils/fetchRequest";
import message from "../../../../modules/message";
import {cloneDeep} from "lodash";

const style = {
    container: {
        display: 'flex',
        marginTop: '0.8rem'
    },
    labelContainer: {
        display: 'flex',
        flex:  0.8,
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginRight : 10,
        marginBottom: 0,
        width: 230,
    },
    inputContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        marginLeft: 10,
        width: 'auto',
    },
    inputStyle: {
        display: 'inline-flex',
        marginBottom: 10
    },
    radioStyle: {
        position: 'relative',
        top: 10,
        marginRight: 10,
    },
    footerContainer: {
        display: 'flex',
        flex: 0.6,
        flexDirection: 'column',
        marginLeft: 10,
        width: 'auto',
    },
    iconButtonStyle: {
        width: 36,
        height: 32,
        border: 'none',
        backgroundColor: '#00c4b9',
        alignItems: 'center'
    },
    iconStyle: {
        fontSize: '1.8rem',
        position: 'relative',
        right: 8,
        bottom: 2,
    },
    tdWidth: {
        width: 40
    },
    invalid: {
        width: '100%',
        fontSize: '0.9rem',
        color: '#F64E60',
    }
}

const defaultRow = {
    id: 1,
    radio: false,
    input: '',
    img: null,
    imgName: ''
};

export const Radio = ({
    checkRows,
    onChange,
}) => {
    const {t} = useTranslation();
    const fileUploaderRef = useRef([]);
    const [rows, setRows] = useState([{ ...defaultRow }]);

    useEffect(() => {
        if(Number.isInteger(checkRows)){
            let cloneRows = cloneDeep([...rows]);
            setRows(cloneRows);
        }
    }, [checkRows])

    const verifyFile = (files, index) => {
        const clone = [...rows];
        const acceptedType = [
            'image/x-png',
            'image/png',
            'image/jpg',
            'image/jpeg',
            'image/gif',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
            'video/x-ms-wmv',
            'application/pdf',
            'audio/mpeg',
            'video/mpeg',
            'video/mp4',
            'video/quicktime',
            'video/x-ms-wmv',
        ];
        const acceptedSize = 52428800;

        if(files && files.length > 0){
            let isFalse = true;
            for(let i = 0; i < files.length; i++){
                let imageSize = files[i].size;
                let imageType = files[i].type;
                if (imageSize > acceptedSize) {
                    clone[index].showErrorMessage = true;
                    clone[index].errorMessage = t('newsfeed.fileSizeWarning');
                    setRows(clone);
                    isFalse = false;
                }

                if (!acceptedType.includes(imageType)) {
                    clone[index].showErrorMessage = true;
                    clone[index].errorMessage = t('newsfeed.imageTypeError');
                    setRows(clone);
                    isFalse = false;
                }
            }
            return isFalse;
        }
    };

    const onFileInputChange = (e, index) => {
        const clone = [...rows];
        if (e.target.files && e.target.files) {
            let files = e.target.files;
            const verified = verifyFile(files, index);

            if (verified) {
                let fileNames = '';
                for(let i = 0; i < files.length; i++){
                    if(files.length == 1){
                        fileNames = files[i].name;
                    } else if(files.length == i + 1){
                        fileNames = fileNames + files[i].name;
                    } else if (files.length > 1){
                        fileNames = fileNames + files[i].name + ', ';
                    }
                }

                clone[index].fileNames = fileNames;
                clone[index].showErrorMessage = false;
                clone[index].errorMessage = '';
                clone[index].files = files;
                clone[index].onChange?.(files, e, 'add');
                setRows(clone);
            }
        }
    };

    const onFileUploadButtonHandler = (index) => {
        if(fileUploaderRef && fileUploaderRef.current[index]){
            fileUploaderRef.current[index].click();
        }
    };

    const addRow = () => {
        setRows([...rows, { ...defaultRow }]);
        onChange([...rows, { ...defaultRow }]);
    };

    const removeRow = index => {
        const cloneRows = [...rows];
        cloneRows.splice(index, 1);
        onChange(cloneRows);
        setRows(cloneRows);
    };

    const onRadioChange = (e, index) => {
        let cloneRows = [...rows];
        if(cloneRows && cloneRows.length > 0){
            for(let i = 0; i < cloneRows.length; i++){
                if(index == i){
                    cloneRows[i].radio = e.target.checked;
                } else {
                    cloneRows[i].radio = false;
                }
            }
        }
        onChange(cloneRows);
        setRows(cloneRows);
    };

    const onInputChange = (e, index) => {
        let cloneRows = [...rows];
        cloneRows[index].input = e.target.value;
        cloneRows[index].inputError = false;
        onChange(cloneRows);
        setRows(cloneRows);
    };

    return (
        <>
            <div style={style.container}>
                <label style={style.labelContainer}></label>
                <div style={style.inputContainer}>
                    <div style={style.inputStyle}>
                        <table width='100%'>
                            <tbody>
                            {rows.map((row, index) => {
                                return (
                                    <tr
                                        key={'radio_' + index}
                                    >
                                        <td>
                                            <input
                                                type="radio"
                                                checked={row.radio}
                                                onChange={(e) => onRadioChange(e, index)}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                className={row.inputError ? 'form-control is-invalid' : 'form-control'}
                                                type='text'
                                                onChange={(e) => {
                                                    onInputChange(e, index)
                                                }}
                                                value={row.input}
                                            />
                                            {
                                                row.inputError
                                                    ?
                                                    <div style={style.invalid}>
                                                        {t('question.insert')}
                                                    </div>
                                                    : null
                                            }
                                        </td>
                                        <td style={style.tdWidth}>
                                            <input
                                                ref={el => fileUploaderRef.current[index] = el}
                                                style={{display: 'none'}}
                                                type='file'
                                                multiple={false}
                                                onChange={(e) => {
                                                    onFileInputChange(e, index);
                                                }}
                                                value={row.imgName}
                                            />
                                            <Button className='btn btn-success m-btn m-btn--icon m-btn--icon-only ml-2' style={style.iconButtonStyle} onClick={() => {onFileUploadButtonHandler(index)}}>
                                                <i className='fa fa-image' style={style.iconStyle}/>
                                            </Button>
                                        </td>
                                        <td style={style.tdWidth}>
                                            {
                                                index != 0
                                                ?
                                                    <button className='btn btn-danger row-action-button ml-2' onClick={() => removeRow(index)}>
                                                        <i className='las la-times' style={{ fontSize: 14 }} />
                                                    </button>
                                                :
                                                    null
                                            }
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <button className='btn btn-primary row-action-button ml-2' onClick={addRow}>
                                            <i className='las la-plus' style={{ fontSize: 18 }} />
                                        </button>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
                <div
                    style={style.footerContainer}
                />
            </div>
        </>
    );
};