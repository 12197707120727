import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { useDispatch } from 'react-redux';
import { setLoading } from 'redux/action'
import { MAIN_URL, fetchRequest } from 'utils/fetchRequest'
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import { graduationMbAttachmentContent } from 'utils/fetchRequest/Urls'
import { useScreenshot } from "use-screenshot-hook";
import message from 'app/modules/message'
import 'app/css/mbDiploma.css';

const MbAttachmentModal = ({
    open = false,
    onClose = () => { },
    school = null,
    content = {},
    onSubmit = () => { }
}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const mbImageRef = useRef(null);
    let { image, takeScreenshot, isLoading, clear } = useScreenshot({ ref: mbImageRef });

    const [attachmentContent, setAttachmentContent] = useState(() => EditorState.createEmpty())
    const [submitting, setSubmitting] = useState(false);
    const [diplomaRegistrationNo, setDiplomaRegistrationNo] = useState(content?.diplomaRegistrationNo)

    useEffect(() => {
        console.log('Content', content)
        // const contentState = ContentState.createFromBlockArray(
        //     convertFromHTML(content?.template).contentBlocks,
        //     convertFromHTML(content?.template).entityMap
        // );
        // setAttachmentContent(() => EditorState.createWithContent(contentState))
    }, [open])

    useEffect(() => {
        if (submitting && image) {
            submitMbAttachmentModal(content?.id, image)
        }
    }, [image])

    const submitMbAttachmentModal = (id, base64) => {
        dispatch(setLoading(true));
        const params = {
            school,
            id,
            snapshot: base64
        }
        fetchRequest(graduationMbAttachmentContent, 'POST', params)
            .then(res => {
                if (res?.success) {
                    onSubmit(res?.data)
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(e => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            });
    }

    return (
        <Modal
            show={open}
            onHide={onClose}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {t('graduation.infoApprovePage')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ overflow: 'auto' }}>
                <div ref={mbImageRef} className="diploma mb-renderer text-center" style={{ width: 1600, height: 1000 }}>
                    <Editor
                        editorState={EditorState.createWithContent(ContentState.createFromBlockArray(
                            convertFromHTML("Moehgo& folo_ oa BlbEsore& oa YekEt SorgegolI<br/>" + (content?.schoolNameMb) + "<br/>" +
                                (content?.diplomaNumber || '') + "dogerdeI be\Elew* oa dip;o^ oa Hebsorolda<br/>" +
                                "fcikEt :fkEt\" Jia Ne*T= " + (content?.lastName || '') + " Ne*T=" + (content?.firstName || '') + " RwkusD*=" + (content?.register || '') + "<br/>" +
                                "Doirohsea Geje* , foehne)T=" + (content?.birthPlace || '') + " " + (content?.birthDate || '') + "<br /> MerkEji&=" + (content?.program || '') +
                                "MerkEji& oa findw\_=" + (content?.programIndex || '') + " flsehsea foa=" + (content?.startYear || '') + " DeKsohsea foa=" + (content?.endYear || '') + "<br/>" +
                                "Neiida \rwdiDA=" + (content?.totalCredit || '') + " GoolcI doieit=" + (content?.gpa || '') + " flselda iia Silgelda iia fono) t=" + (content?.eyeshScore || '') + "<br/>" +
                                "fmonekuA Zedoa O BlbEsore& oa foinelekEa O DomdejI fono) t=" + (content?.previousLevelScore || '')).contentBlocks,
                            convertFromHTML("Moehgo& folo_ oa BlbEsore& oa YekEt SorgegolI<br/>" + (content?.schoolNameMb) + "<br/>" +
                                (content?.diplomaNumber || '') + "dogerdeI be\Elew* oa dip;o^ oa Hebsorolda<br/>" +
                                "fcikEt :fkEt\" Jia Ne*T= " + (content?.lastName || '') + " Ne*T=" + (content?.firstName || '') + " RwkusD*=" + (content?.register || '') + "<br/>" +
                                "Doirohsea Geje* , foehne)T=" + (content?.birthPlace || '') + " " + (content?.birthDate || '') + "<br /> MerkEji&=" + (content?.program || '') +
                                "MerkEji& oa findw\_=" + (content?.programIndex || '') + " flsehsea foa=" + (content?.startYear || '') + " DeKsohsea foa=" + (content?.endYear || '') + "<br/>" +
                                "Neiida \rwdiDA=" + (content?.totalCredit || '') + " GoolcI doieit=" + (content?.gpa || '') + " flselda iia Silgelda iia fono) t=" + (content?.eyeshScore || '') + "<br/>" +
                                "fmonekuA Zedoa O BlbEsore& oa foinelekEa O DomdejI fono) t=" + (content?.previousLevelScore || '')).entityMap
                        ))}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="diploma-content-container"
                        editorClassName="editorClassName"
                        onEditorStateChange={null}
                        editorStyle={{ fontFamily: 'Urga' }}
                        toolbar={{
                            options: []
                        }}
                        wrapperStyle={{
                            marginRight: 30
                        }}
                        toolbarStyle={{
                            display: 'none'
                        }}
                    />

                    <div style={{ clear: 'both', position: 'relative', margin: 'auto', width: '400pt' }}>
                        {
                            content.subjectColumns && content.subjectColumns.map((subjectColumnObj, sI) => {
                                return <div key={'subject-col-' + sI}
                                    style={{ position: 'absolute', left: sI === 0 ? 0 : '50%', height: '49%' }}>
                                    <table style={{ height: '100%' }}>
                                        <tbody>
                                            <tr>
                                                <td style={{ height: '10pt' }}>№</td>
                                                <td style={{ height: '50pt' }}>
                                                    <Editor
                                                        editorState={EditorState.createWithContent(ContentState.createFromBlockArray(
                                                            convertFromHTML("Sodoloeesea kuciye& oa Ne*T").contentBlocks,
                                                            convertFromHTML("Sodoloeesea kuciye& oa Ne*T").entityMap
                                                        ))}
                                                        toolbarClassName="toolbarClassName"
                                                        wrapperClassName="diploma-content-container"
                                                        editorClassName="editorClassName"
                                                        onEditorStateChange={null}
                                                        editorStyle={{ fontFamily: 'Urga' }}
                                                        toolbar={{
                                                            options: []
                                                        }}
                                                        toolbarStyle={{
                                                            display: 'none'
                                                        }}
                                                    />
                                                </td>
                                                <td style={{ height: '10pt' }}>
                                                    <Editor
                                                        editorState={EditorState.createWithContent(ContentState.createFromBlockArray(
                                                            convertFromHTML("Ce(").contentBlocks,
                                                            convertFromHTML("Ce(").entityMap
                                                        ))}
                                                        toolbarClassName="toolbarClassName"
                                                        wrapperClassName="diploma-content-container"
                                                        editorClassName="editorClassName"
                                                        onEditorStateChange={null}
                                                        editorStyle={{ fontFamily: 'Urga' }}
                                                        toolbar={{
                                                            options: []
                                                        }}
                                                        toolbarStyle={{
                                                            display: 'none'
                                                        }}
                                                    />
                                                </td>
                                                <td style={{ height: '15pt' }}>
                                                    <Editor
                                                        editorState={EditorState.createWithContent(ContentState.createFromBlockArray(
                                                            convertFromHTML("fono)T ").contentBlocks,
                                                            convertFromHTML("fono)T ").entityMap
                                                        ))}
                                                        toolbarClassName="toolbarClassName"
                                                        wrapperClassName="diploma-content-container"
                                                        editorClassName="editorClassName"
                                                        onEditorStateChange={null}
                                                        editorStyle={{ fontFamily: 'Urga' }}
                                                        toolbar={{
                                                            options: []
                                                        }}
                                                        toolbarStyle={{
                                                            display: 'none'
                                                        }}
                                                    />
                                                </td>
                                                <td style={{ height: '15pt' }}>
                                                    <Editor
                                                        editorState={EditorState.createWithContent(ContentState.createFromBlockArray(
                                                            convertFromHTML("doieit").contentBlocks,
                                                            convertFromHTML("doieit").entityMap
                                                        ))}
                                                        toolbarClassName="toolbarClassName"
                                                        wrapperClassName="diploma-content-container"
                                                        editorClassName="editorClassName"
                                                        onEditorStateChange={null}
                                                        editorStyle={{ fontFamily: 'Urga' }}
                                                        toolbar={{
                                                            options: []
                                                        }}
                                                        toolbarStyle={{
                                                            display: 'none'
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                            {
                                                subjectColumnObj.subjects && subjectColumnObj.subjects.length > 0 && subjectColumnObj.subjects.map((subjectObj, s) => {
                                                    return <tr key={'row_' + sI + '_subject_' + subjectObj?.id}>
                                                        <td style={{ height: '10pt' }}>
                                                            <Editor
                                                                editorState={EditorState.createWithContent(ContentState.createFromBlockArray(
                                                                    convertFromHTML((sI * 37) + (s + 1)).contentBlocks,
                                                                    convertFromHTML((sI * 37) + (s + 1)).entityMap
                                                                ))}
                                                                toolbarClassName="toolbarClassName"
                                                                wrapperClassName="diploma-content-container"
                                                                editorClassName="editorClassName"
                                                                onEditorStateChange={null}
                                                                editorStyle={{ fontFamily: 'Urga' }}
                                                                toolbar={{
                                                                    options: []
                                                                }}
                                                                toolbarStyle={{
                                                                    display: 'none'
                                                                }}
                                                            />
                                                        </td>
                                                        <td style={{ height: '50pt' }}>
                                                            <Editor
                                                                editorState={EditorState.createWithContent(ContentState.createFromBlockArray(
                                                                    convertFromHTML(subjectObj?.subjectNameMb).contentBlocks,
                                                                    convertFromHTML(subjectObj?.subjectNameMb).entityMap
                                                                ))}
                                                                toolbarClassName="toolbarClassName"
                                                                wrapperClassName="diploma-content-container"
                                                                editorClassName="editorClassName"
                                                                onEditorStateChange={null}
                                                                editorStyle={{ fontFamily: 'Urga' }}
                                                                toolbar={{
                                                                    options: []
                                                                }}
                                                                toolbarStyle={{
                                                                    display: 'none'
                                                                }}
                                                            />
                                                        </td>
                                                        <td style={{ height: '10pt' }}>
                                                            <Editor
                                                                editorState={EditorState.createWithContent(ContentState.createFromBlockArray(
                                                                    convertFromHTML(subjectObj?.credit).contentBlocks,
                                                                    convertFromHTML(subjectObj?.credit).entityMap
                                                                ))}
                                                                toolbarClassName="toolbarClassName"
                                                                wrapperClassName="diploma-content-container"
                                                                editorClassName="editorClassName"
                                                                onEditorStateChange={null}
                                                                editorStyle={{ fontFamily: 'Urga' }}
                                                                toolbar={{
                                                                    options: []
                                                                }}
                                                                toolbarStyle={{
                                                                    display: 'none'
                                                                }}
                                                            />
                                                        </td>
                                                        <td style={{ height: '15pt' }}>
                                                            <Editor
                                                                editorState={EditorState.createWithContent(ContentState.createFromBlockArray(
                                                                    convertFromHTML(subjectObj?.studentScore).contentBlocks,
                                                                    convertFromHTML(subjectObj?.studentScore).entityMap
                                                                ))}
                                                                toolbarClassName="toolbarClassName"
                                                                wrapperClassName="diploma-content-container"
                                                                editorClassName="editorClassName"
                                                                onEditorStateChange={null}
                                                                editorStyle={{ fontFamily: 'Urga' }}
                                                                toolbar={{
                                                                    options: []
                                                                }}
                                                                toolbarStyle={{
                                                                    display: 'none'
                                                                }}
                                                            />
                                                        </td>
                                                        <td style={{ height: '15pt' }}>
                                                            {subjectObj?.scoreType}
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            })
                        }
                    </div>

                    <Editor
                        editorState={EditorState.createWithContent(ContentState.createFromBlockArray(
                            convertFromHTML("DoeeeI iia Ji& oa fiji& MerhEji& oa DeKsohcioa oa GoolcI doieit oa DomdejI= " + (content?.avgGpa || '') + "<br/>" +
                                "Sorgelda YeBgolde( Bloa Silgelda febde( kEla= " + (content?.examSubject || '')).contentBlocks,
                            convertFromHTML("DoeeeI iia Ji& oa fiji& MerhEji& oa DeKsohcioa oa GoolcI doieit oa DomdejI= " + (content?.avgGpa || '') + "<br/>" +
                                "Sorgelda YeBgolde( Bloa Silgelda febde( kEla= " + (content?.examSubject || '')).entityMap
                        ))}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="diploma-content-container"
                        editorClassName="editorClassName"
                        onEditorStateChange={null}
                        editorStyle={{ fontFamily: 'Urga' }}
                        toolbar={{
                            options: []
                        }}
                        wrapperStyle={{
                            marginRight: 30
                        }}
                        toolbarStyle={{
                            display: 'none'
                        }}
                    />

                    {
                        content?.signatures && content?.signatures.length > 0 && (
                            content?.signatures.map((signatureObj, s) => {
                                if (!signatureObj.isHome) {
                                    return <Editor
                                        key={'sign_' + s}
                                        editorState={EditorState.createWithContent(ContentState.createFromBlockArray(
                                            convertFromHTML(signatureObj.mbSignature).contentBlocks,
                                            convertFromHTML(signatureObj.mbSignature).entityMap
                                        ))}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="diploma-content-container text-center"
                                        editorClassName="text-center"
                                        onEditorStateChange={null}
                                        editorStyle={{ fontFamily: 'Urga' }}
                                        toolbar={{
                                            options: []
                                        }}
                                        toolbarStyle={{
                                            display: 'none'
                                        }}
                                    />
                                }

                            })
                        )
                    }

                    <Editor
                        editorState={EditorState.createWithContent(ContentState.createFromBlockArray(
                            convertFromHTML("fna K Hebsorolda " + (content?.endYear || '') + " foa O " + (content?.diplomaNumber || '') + " dogerdeI dip;o^ oa HemdO KicoedeI.            BirioekE& oa doge*=" + (content?.diplomaRegistrationNo || '')).contentBlocks,
                            convertFromHTML("fna K Hebsorolda " + (content?.endYear || '') + " foa O " + (content?.diplomaNumber || '') + " dogerdeI dip;o^ oa HemdO KicoedeI.            BirioekE& oa doge*=" + (content?.diplomaRegistrationNo || '')).entityMap
                        ))}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="diploma-content-container"
                        editorClassName="editorClassName"
                        onEditorStateChange={null}
                        editorStyle={{ fontFamily: 'Urga' }}
                        toolbar={{
                            options: []
                        }}
                        wrapperStyle={{
                            marginLeft: 30,
                            marginRight: 10,
                        }}
                        toolbarStyle={{
                            display: 'none'
                        }}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className="btn btn-link bolder">
                    {t("common.back").toLocaleUpperCase()}
                </button>

                <button
                    disabled={isLoading}
                    className={'btn btn-success bolder'}
                    onClick={() => {
                        setSubmitting(true)
                        takeScreenshot('jpg', {
                            width: 1600,
                            height: 1000,
                            quality: 1,
                            backgroundColor: 'white'
                        })
                    }}>
                    {"Баталгаажуулах"}
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default MbAttachmentModal
