import React, {useEffect, useState} from 'react'
import {Button, Card, Modal} from 'react-bootstrap'
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import DTable from "../../../../modules/DataTable/DTable";
import {setLoading} from "../../../../../redux/action";
import {fetchRequest} from "../../../../../utils/fetchRequest";
import {
    financeConfigDiscounts,
    financeDiscountActive,
    financeDiscountDelete,
    financeDiscountEdit
} from "../../../../../utils/fetchRequest/Urls";
import message from "../../../../modules/message";
import AddDiscount from "./add";
import EditDiscount from "./edit";

export const InvoiceDiscount = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    const [showAddModal, setShowAddModal] = useState(false);
    const [discounts, setDiscounts] = useState([]);
    const [showEditModal, setShowEditModal] = useState(false);
    const [discountId, setDiscountId] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false);

    useEffect(() => {
        const params = {
            school: schoolId
        };
        init(params)
    }, []);

    const init = (params) => {
        dispatch(setLoading(true));
        fetchRequest(financeConfigDiscounts, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setDiscountList(data?.discounts || [])
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const setDiscountList = (discounts) => {
        if(discounts && discounts.length > 0){
            for(let i = 0; i < discounts.length; i++){
                if(discounts[i].isActive){
                    discounts[i].contextMenuKeys = ['edit', 'delete', 'unpublish'];
                } else {
                    discounts[i].contextMenuKeys = ['edit', 'delete', 'publish'];
                }
            }
        }
        setDiscounts(discounts)
    };

    const handleAddClick = () => {
        setShowAddModal(true);
    };

    const config = {
        showPagination: true,
        showFilter: true,
    };

    const columns = [
        {
            dataField: "isActive",
            text: '',
            headerStyle: () => ({
                width: 80,
            }),
            style: {textAlign: 'center'},
            formatter: (cell, row) => {
                if(cell === true){
                    return (
                        <div><span><i className="icon-1_5x main-green fa fa-circle"/></span></div>
                    )
                } else {
                    return (
                        <div><span><i className="icon-1_5x text-dark-50 fa fa-circle"/></span></div>
                    )
                }
            }
        },
        {
            dataField: "code",
            text: t('common.code'),
        },
        {
            dataField: "name",
            text: t('common.name'),
            sort: true
        },
        {
            dataField: "typeCode",
            text: t('common.type'),
            style: {textAlign: 'right'},
            sort: true
        },
        {
            dataField: "amount",
            text: t('common.amount'),
            style: {textAlign: 'right'},
            sort: true,
        },
    ];

    const contextMenus = [
        {
            key: 'edit',
            title: t('common.edit'),
            icon: <i className='las la-edit dt-cm-item-icon'/>
        },
        {
            key: 'delete',
            title: t('common.delete'),
            icon: <i className='las la-trash-alt dt-cm-item-icon' />,
        },
        {
            key: 'publish',
            title: t('action.setActive'),
            icon: <i className='fas fa-exchange-alt dt-cm-item-icon-18'/>
        },
        {
            key: 'unpublish',
            title: t('action.setInactive'),
            icon: <i className='fas fa-exchange-alt dt-cm-item-icon-18'/>
        }
    ];

    const handleContextMenuClick = (id, key) => {
        if (id && key) {
            if (key === 'edit') {
                setShowEditModal(true);
            } else if (key === 'delete') {
                setDeleteModal(true);
            } else if (key === 'publish'){
                const bodyParams = {
                    id: id,
                    school: schoolId,
                    active: 1
                };

                dispatch(setLoading(true));
                fetchRequest(financeDiscountActive, 'POST', bodyParams)
                    .then(res => {
                        if (res.success) {
                            const { data } = res;
                            message(data?.message || t('common.success'), true);
                            onModalClose();
                            setDiscountList(data?.discounts || [])
                        } else {
                            message(res?.data?.message || t('errorMessage.title'));
                        }
                        dispatch(setLoading(false));
                    })
                    .catch(() => {
                        dispatch(setLoading(false));
                        message(t('errorMessage.title'))
                    })
            } else if (key === 'unpublish'){
                const bodyParams = {
                    id: id,
                    school: schoolId,
                    active: 0
                };

                dispatch(setLoading(true));
                fetchRequest(financeDiscountActive, 'POST', bodyParams)
                    .then(res => {
                        if (res.success) {
                            const { data } = res;
                            message(data?.message || t('common.success'), true);
                            onModalClose();
                            setDiscountList(data?.discounts || [])
                        } else {
                            message(res?.data?.message || t('errorMessage.title'));
                        }
                        dispatch(setLoading(false));
                    })
                    .catch(() => {
                        dispatch(setLoading(false));
                        message(t('errorMessage.title'))
                    })
            }
            setDiscountId(id);
        }
    };

    const onModalClose = () => {
        setShowAddModal(false);
        setShowEditModal(false);
    };

    const onSubmit = params => {
        const bodyParams = {
            school: schoolId,
            ...params,
        };

        dispatch(setLoading(true));
        fetchRequest(financeConfigDiscounts, 'POST', bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    message(data?.message || t('common.success'), true);
                    onModalClose();
                    setDiscountList(data?.discounts || []);
                } else {
                    message(res?.data?.message || t('errorMessage.title'));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const onSubmitEdit = params => {
        const bodyParams = {
            id: discountId,
            school: schoolId,
            ...params,
        };

        dispatch(setLoading(true));
        fetchRequest(financeDiscountEdit, 'POST', bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    message(data?.message || t('common.success'), true);
                    onModalClose();
                    setDiscountList(data?.discounts || []);
                } else {
                    message(res?.data?.message || t('errorMessage.title'));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const handleDeleteModalClose = () => {
        setDeleteModal(false);
    };

    const handlerOnDeleteClick = () => {
        const params = {
            school: schoolId,
            id: discountId,
        };

        dispatch(setLoading(true));
        fetchRequest(financeDiscountDelete, 'POST', params, false)
            .then(response => {
                if (response.success) {
                    setDeleteModal(false);
                    message(response.data.message, true);
                    setDiscountList(response?.data?.discounts || []);
                } else {
                    message(response.data.message, false);
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    return (
        <div className='sm-container'>
            <Card>
                <Card.Body>
                    <div className='row'>
                        <div className='col-12'>
                            <button
                                className='btn btn-primary'
                                onClick={handleAddClick}
                            >
                                {t('common.add')}
                            </button>
                        </div>
                        <div className='col-12'>
                            <DTable
                                columns={columns}
                                config={config}
                                data={discounts}
                                contextMenus={contextMenus}
                                onContextMenuItemClick={handleContextMenuClick}
                                individualContextMenus={true}
                                selectMode={'radio'}
                            />
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <Modal
                show={deleteModal}
                onHide={handleDeleteModalClose}
                size="sm"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t('warning.delete')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {t('warning.delete_confirmation')}
                        <br/>
                        <br/>
                        {t('warning.delete_confirmation_description')}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="btn btn-link bolder" onClick={handleDeleteModalClose}>{t('common.cancel')}</Button>
                    <Button variant="success btn-shadow" onClick={handlerOnDeleteClick}>{t('warning.delete')}</Button>
                </Modal.Footer>
            </Modal>
            {
                showAddModal && (
                    <AddDiscount
                        onClose={onModalClose}
                        onSubmit={onSubmit}
                    />
                )
            }
            {
                showEditModal && (
                    <EditDiscount
                        discountId={discountId}
                        onClose={onModalClose}
                        onSubmit={onSubmitEdit}
                    />
                )
            }
        </div>
    )
};
