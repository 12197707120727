import React, {useRef, useEffect, useState} from 'react';
import {Button, Card} from "react-bootstrap";
import Forms from "../../../../modules/Form/Forms";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {teacherDifficultyAdd} from "../../../../../utils/fetchRequest/Urls";
import { setLoading } from "../../../../../redux/action";
import { fetchRequest } from "../../../../../utils/fetchRequest";
import message from "../../../../modules/message";
import {Link, useHistory} from "react-router-dom";
import { Modal,Row,Col,CloseButton} from 'react-bootstrap'


export const AddDifficulty = ({
    onClose = () =>{},
    school,
    did,
    code,
    name
}) => {
    
    const { t } = useTranslation();
    const formRef = useRef();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [difficulty, setDifficulty] = useState([]);

    useEffect(() => {
        
    }, []);

    useEffect(() => {
        formRef?.current?.updateFields && formRef.current?.updateFields(fields);
    }, []);

    const fields = [
        {
            key: 'code',
            label: t('common.code'),
            value: code ? code : undefined,
            type: 'text',
            required: true,
            labelWidth: 250,
        },
        {
            key: 'name',
            label: t('common.name'),
            value: name ? name : undefined,
            type: 'text',
            required: true,
            labelWidth: 250,
        }
        
    ];
    const onAdd = () =>{
        const [ isValid, states, values ] = formRef.current.validate();
        if (isValid) {
            const formData = new FormData();
            formData.append('code', states?.[0]?.value);
            formData.append('name', states?.[1]?.value);
            formData.append('school', schoolId);
            
            dispatch(setLoading(true));
            //edit
            if(did !== 0){
                formData.append('did', did);
            }
            fetchRequest(teacherDifficultyAdd, 'POST', formData, false, true)
            .then(res => {
                if (res.success) {
                    message(t('common.success'), true);
                    dispatch(setLoading(false));
                    onClose();
                } else {
                    message(res?.data?.message);
                    dispatch(setLoading(false));
                }
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
        }
    }

    return (
            <Modal
                show={true}
                onHide={onClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title>
                        <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                            {did == 0 ? t('exam.addDifficulty') : t('exam.editDifficulty')}
                        </div>
                    </Modal.Title>
                    <CloseButton onClick={onClose}/>
                </Modal.Header>
                <Modal.Body>
                    <div className='col-12 text-center'>
                        <Forms
                            ref={formRef}
                            fields={fields}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={onClose} className='btn btn-link bolder'>{t('common.cancel')}</button>
                    <Button className="btn btn-outline-success w-25" onClick={onAdd}>{t('common.save')}</Button>
                </Modal.Footer>
            </Modal>
    );
};