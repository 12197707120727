import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Forms from 'app/modules/Form/Forms';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRequest } from 'utils/fetchRequest';
import { setLoading } from 'redux/action';
import { graduationConfigView } from 'utils/fetchRequest/Urls';
import message from 'app/modules/message';

const ViewModal = ({
    id = null,
    open = false,
    onClose = () => { }
}) => {

    const { t } = useTranslation()

    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const dispatch = useDispatch();

    const ref = useRef()

    const [season, setSeason] = useState(null)
    const [educationLevel, setEducationLevel] = useState(null)
    const [orderDate, setOrderDate] = useState(null)
    const [decisionDate, setDecisionDate] = useState(null)

    const [homeSignatures, setHomeSignatures] = useState([
        {
            mn: EditorState.createEmpty(),
            en: EditorState.createEmpty(),
            mb: EditorState.createEmpty(),
        }
    ])
    const [additionalSignatures, setAdditionalSignatures] = useState([
        {
            mn: EditorState.createEmpty(),
            en: EditorState.createEmpty(),
            mb: EditorState.createEmpty(),
        }
    ])

    const [textValue, setTextValue] = useState(() => EditorState.createEmpty());
    const [textValueEn, setTextValueEn] = useState(() => EditorState.createEmpty());
    const [textValueTr, setTextValueTr] = useState(() => EditorState.createEmpty());

    const [updateView, setUpdateView] = useState(false)

    const init = (id) => {
        dispatch(setLoading(true));
        const params = {
            school: schoolId,
            id
        }
        fetchRequest(graduationConfigView, 'GET', params)
            .then(res => {
                if (res?.success) {
                    setSeason(res?.data?.season)
                    setEducationLevel(res?.data?.educationLevel)
                    setOrderDate(res?.data?.orderDate)
                    setDecisionDate(res?.data?.decisionDate)

                    const mnState = ContentState.createFromBlockArray(
                        convertFromHTML(res?.data?.orderNumberMn).contentBlocks,
                        convertFromHTML(res?.data?.orderNumberMn).entityMap
                    );
                    setTextValue(() => EditorState.createWithContent(mnState))

                    const enState = ContentState.createFromBlockArray(
                        convertFromHTML(res?.data?.orderNumberEn).contentBlocks,
                        convertFromHTML(res?.data?.orderNumberEn).entityMap
                    );
                    setTextValueEn(() => EditorState.createWithContent(enState))

                    const mbState = ContentState.createFromBlockArray(
                        convertFromHTML(res?.data?.orderNumberMb).contentBlocks,
                        convertFromHTML(res?.data?.orderNumberMb).entityMap
                    );
                    setTextValueTr(() => EditorState.createWithContent(mbState))

                    if (res?.data?.homeSignatures && res?.data?.homeSignatures.length > 0) {
                        const signatures = [];
                        for (let hs = 0; hs < res?.data?.homeSignatures.length; hs++) {
                            const signatureObj = res?.data?.homeSignatures[hs];
                            const mnState = ContentState.createFromBlockArray(
                                convertFromHTML(signatureObj.mnSignature).contentBlocks,
                                convertFromHTML(signatureObj.mnSignature).entityMap
                            );
                            const enState = ContentState.createFromBlockArray(
                                convertFromHTML(signatureObj.enSignature).contentBlocks,
                                convertFromHTML(signatureObj.enSignature).entityMap
                            );
                            const mbState = ContentState.createFromBlockArray(
                                convertFromHTML(signatureObj.mbSignature).contentBlocks,
                                convertFromHTML(signatureObj.mbSignature).entityMap
                            );

                            signatures.push({
                                mn: EditorState.createWithContent(mnState),
                                en: EditorState.createWithContent(enState),
                                mb: EditorState.createWithContent(mbState)
                            })
                        }
                        setHomeSignatures(signatures)
                    }
                    if (res?.data?.additionalSignatures && res?.data?.additionalSignatures.length > 0) {
                        const signatures = [];
                        for (let hs = 0; hs < res?.data?.additionalSignatures.length; hs++) {
                            const signatureObj = res?.data?.additionalSignatures[hs];
                            const mnState = ContentState.createFromBlockArray(
                                convertFromHTML(signatureObj.mnSignature).contentBlocks,
                                convertFromHTML(signatureObj.mnSignature).entityMap
                            );
                            const enState = ContentState.createFromBlockArray(
                                convertFromHTML(signatureObj.enSignature).contentBlocks,
                                convertFromHTML(signatureObj.enSignature).entityMap
                            );
                            const mbState = ContentState.createFromBlockArray(
                                convertFromHTML(signatureObj.mbSignature).contentBlocks,
                                convertFromHTML(signatureObj.mbSignature).entityMap
                            );

                            signatures.push({
                                mn: EditorState.createWithContent(mnState),
                                en: EditorState.createWithContent(enState),
                                mb: EditorState.createWithContent(mbState)
                            })
                        }
                        setAdditionalSignatures(signatures)
                    }

                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(e => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            });
    }

    useEffect(() => {
        init(id)
    }, [id])

    const onHomeEditorChange = (index, langKey, data) => {
        const clone = [...homeSignatures]
        for (let i = 0; i < clone.length; i++) {
            if (i === index) {
                clone[i][langKey] = data
            }
        }
        setHomeSignatures(clone)
        setUpdateView(!updateView)
    }

    const onAdditionalEditorChange = (index, langKey, data) => {
        const clone = [...additionalSignatures]
        for (let i = 0; i < clone.length; i++) {
            if (i === index) {
                clone[i][langKey] = data
            }
        }
        setAdditionalSignatures(clone)
        setUpdateView(!updateView)
    }

    const addHomeSignature = () => {
        const clone = [...homeSignatures]
        clone.push({
            mn: EditorState.createEmpty(),
            en: EditorState.createEmpty(),
            mb: EditorState.createEmpty(),
        })

        setHomeSignatures(clone)
        setUpdateView(!updateView)
    }

    const addAdditionalSignature = () => {
        const clone = [...additionalSignatures]
        clone.push({
            mn: EditorState.createEmpty(),
            en: EditorState.createEmpty(),
            mb: EditorState.createEmpty(),
        })

        setAdditionalSignatures(clone)
        setUpdateView(!updateView)
    }

    const removeHomeSignature = (index) => {
        const clone = [...homeSignatures]
        clone.splice(index, 1);
        setHomeSignatures(clone)
        setUpdateView(!updateView)
    }

    const removeAdditionalSignature = (index) => {
        const clone = [...additionalSignatures]
        clone.splice(index, 1);

        setAdditionalSignatures(clone)
        setUpdateView(!updateView)
    }

    return (
        <Modal
            show={open}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {t('graduation.config.title')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <form>
                    <div style={{ display: 'flex', marginTop: '0.8rem' }}>
                        <label style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginRight: 10, marginBottom: 0, width: 300 }}>Улирал</label>
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10, width: 200 }}>
                            {season}
                        </div>
                    </div>
                    <div style={{ display: 'flex', marginTop: '0.8rem' }}>
                        <label style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginRight: 10, marginBottom: 0, width: 300 }}>Боловсролын зэрэг</label>
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10, width: 200 }}>
                            {educationLevel}
                        </div>
                    </div>
                    <div style={{ display: 'flex', marginTop: '0.8rem' }}>
                        <label style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginRight: 10, marginBottom: 0, width: 300 }}>Тушаалын огноо</label>
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10, width: 200 }}>
                            {orderDate}
                        </div>
                    </div>
                    <div style={{ display: 'flex', marginTop: '0.8rem' }}>
                        <label style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginRight: 10, marginBottom: 0, width: 300 }}>Шийдвэрийн огноо</label>
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10, width: 200 }}>
                            {decisionDate}
                        </div>
                    </div>
                </form>


                <label className="font-weight-bold">Тушаалын дугаар</label>
                <div style={dottedLine} />
                <div style={{ display: 'inline-flex', width: '100%' }}>
                    <label style={{ width: 160 }}>Монгол:</label>
                    <div style={{ display: 'inline-block', width: '100%', marginLeft: 20 }}>
                        <Editor
                            editorState={textValue}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={null}
                            editorStyle={{ border: "1px solid rgba(0, 0, 0, .2)", fontFamily: 'Arial' }}
                            toolbar={{
                                options: []
                            }}
                            toolbarStyle={{
                                display: 'none'
                            }}
                        />
                    </div>
                </div>
                <div style={{ display: 'inline-flex', width: '100%', marginTop: 5 }}>
                    <label style={{ width: 160 }}>Англи:</label>
                    <div style={{ display: 'inline-block', width: '100%', marginLeft: 20 }}>
                        <Editor
                            editorState={textValueEn}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={null}
                            editorStyle={{ border: "1px solid rgba(0, 0, 0, .2)", fontFamily: 'Arial' }}
                            toolbar={{
                                options: []
                            }}
                            toolbarStyle={{
                                display: 'none'
                            }}
                        />
                    </div>
                </div>
                <div style={{ display: 'inline-flex', width: '100%', marginTop: 5 }}>
                    <label style={{ width: 160 }}>Монгол бичиг:</label>
                    <div style={{ display: 'inline-block', width: '100%', marginLeft: 20 }}>
                        <Editor
                            editorState={textValueTr}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={null}
                            editorStyle={{ border: "1px solid rgba(0, 0, 0, .2)", fontFamily: 'Urga' }}
                            toolbar={{
                                options: []
                            }}
                            toolbarStyle={{
                                display: 'none'
                            }}
                        />
                    </div>
                </div>
                <div style={dottedLine} />
                <label className="font-weight-bold">Гарын үсэг (Нүүр хуудас)</label>
                <div style={dottedLine} />
                {
                    homeSignatures && homeSignatures.map((homeSignatureObj, hs) => {
                        return <div key={'home_sign_' + hs} style={{ display: 'inline-flex', width: '100%', marginBottom: 10 }}>
                            <div style={{ width: '100%' }}>
                                <div style={{ display: 'inline-flex', width: '100%', marginTop: 5 }}>
                                    <label style={{ width: 160 }}>Монгол:</label>
                                    <div style={{ display: 'inline-block', width: '100%', marginLeft: 20 }}>
                                        <Editor
                                            editorState={homeSignatureObj?.mn}
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            onEditorStateChange={(data) => onHomeEditorChange(hs, 'mn', data)}
                                            editorStyle={{ border: "1px solid rgba(0, 0, 0, .2)", fontFamily: 'Arial' }}
                                            toolbar={{
                                                options: []
                                            }}
                                            toolbarStyle={{
                                                display: 'none'
                                            }}
                                        />
                                    </div>
                                </div>
                                <div style={{ display: 'inline-flex', width: '100%', marginTop: 5 }}>
                                    <label style={{ width: 160 }}>Англи:</label>
                                    <div style={{ display: 'inline-block', width: '100%', marginLeft: 20 }}>
                                        <Editor
                                            editorState={homeSignatureObj?.en}
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            onEditorStateChange={(data) => onHomeEditorChange(hs, 'en', data)}
                                            editorStyle={{ border: "1px solid rgba(0, 0, 0, .2)", fontFamily: 'Arial' }}
                                            toolbar={{
                                                options: []
                                            }}
                                            toolbarStyle={{
                                                display: 'none'
                                            }}
                                        />
                                    </div>
                                </div>
                                <div style={{ display: 'inline-flex', width: '100%', marginTop: 5 }}>
                                    <label style={{ width: 160 }}>Монгол бичиг:</label>
                                    <div style={{ display: 'inline-block', width: '100%', marginLeft: 20 }}>
                                        <Editor
                                            editorState={homeSignatureObj?.mb}
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            onEditorStateChange={(data) => onHomeEditorChange(hs, 'mb', data)}
                                            editorStyle={{ border: "1px solid rgba(0, 0, 0, .2)", fontFamily: 'Urga' }}
                                            toolbar={{
                                                options: []
                                            }}
                                            toolbarStyle={{
                                                display: 'none'
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    })
                }
                <div style={dottedLine} />
                <label className="font-weight-bold">Гарын үсэг (Хавсралт)</label>
                <div style={dottedLine} />

                {
                    additionalSignatures && additionalSignatures.map((additionalSignatureObj, hs) => {
                        return <div key={'home_sign_' + hs} style={{ display: 'inline-flex', width: '100%', marginBottom: 10 }}>
                            <div style={{ width: '100%' }}>
                                <div style={{ display: 'inline-flex', width: '100%', marginTop: 5 }}>
                                    <label style={{ width: 160 }}>Монгол:</label>
                                    <div style={{ display: 'inline-block', width: '100%', marginLeft: 20 }}>
                                        <Editor
                                            editorState={additionalSignatureObj?.mn}
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            onEditorStateChange={(data) => onAdditionalEditorChange(hs, 'mn', data)}
                                            editorStyle={{ border: "1px solid rgba(0, 0, 0, .2)", fontFamily: 'Arial' }}
                                            toolbar={{
                                                options: []
                                            }}
                                            toolbarStyle={{
                                                display: 'none'
                                            }}
                                        />
                                    </div>
                                </div>
                                <div style={{ display: 'inline-flex', width: '100%', marginTop: 5 }}>
                                    <label style={{ width: 160 }}>Англи:</label>
                                    <div style={{ display: 'inline-block', width: '100%', marginLeft: 20 }}>
                                        <Editor
                                            editorState={additionalSignatureObj?.en}
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            onEditorStateChange={(data) => onAdditionalEditorChange(hs, 'en', data)}
                                            editorStyle={{ border: "1px solid rgba(0, 0, 0, .2)", fontFamily: 'Arial' }}
                                            toolbar={{
                                                options: []
                                            }}
                                            toolbarStyle={{
                                                display: 'none'
                                            }}
                                        />
                                    </div>
                                </div>
                                <div style={{ display: 'inline-flex', width: '100%', marginTop: 5 }}>
                                    <label style={{ width: 160 }}>Монгол бичиг:</label>
                                    <div style={{ display: 'inline-block', width: '100%', marginLeft: 20 }}>
                                        <Editor
                                            editorState={additionalSignatureObj?.mb}
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            onEditorStateChange={(data) => onAdditionalEditorChange(hs, 'mb', data)}
                                            editorStyle={{ border: "1px solid rgba(0, 0, 0, .2)", fontFamily: 'Urga' }}
                                            toolbar={{
                                                options: []
                                            }}
                                            toolbarStyle={{
                                                display: 'none'
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    })
                }
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className="btn btn-link bolder mr-2">
                    {t("common.back").toLocaleUpperCase()}
                </button>
            </Modal.Footer>
        </Modal >
    )
}

const dottedLine = {
    borderTop: '1px dashed #000000',
    height: '1px',
    margin: '1rem'
}

export default ViewModal
