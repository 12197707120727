import React, { useRef, useEffect } from 'react';
import { Button, Modal } from "react-bootstrap";
import Forms from "../../../modules/Form/Forms";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import message from "../../../modules/message";
import { addressSegmentEdit } from "../../../../utils/fetchRequest/Urls";

const AddressSegmentAdd = ({
    onClose,
    onSubmit,
    addressSegments: allAddressSegments,
    id
}) => {
    const { t } = useTranslation();
    const forms = useRef();
    const dispatch = useDispatch();
    const addressSegments = allAddressSegments.filter(segment => segment.id !== id);
    const languages = useSelector(state => state?.languages?.languages || []).filter(lang => lang.code !== 'mn');

    const translationFields = languages.map(lang => {
        return {
            label: `${t('common.name')} - ${lang.name} (${lang.code})`,
            value: '',
            langCode: lang.code,
            type: 'text',
            labelWidth: 300,
            inputWidth: 200,
        }
    });

    useEffect(() => {
        const addressSegments = allAddressSegments.filter(segment => segment.value !== id);
        if (id) {
            const params = {
                addressSegment: id
            }
            dispatch(setLoading(true));
            fetchRequest(addressSegmentEdit, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res;

                        if (data?.addressSegmentData) {

                            const translations = data.addressSegmentData?.translations || [];

                            const trFields = languages.map(lang => {

                                let value = '';
                                const translation = translations.find(tr => tr.langCode === lang.code);

                                if (translation?.name) {
                                    value = translation.name;
                                }

                                return {
                                    label: `${t('common.name')} - ${lang.name} (${lang.code})`,
                                    value,
                                    langCode: lang.code,
                                    type: 'text',
                                    labelWidth: 300,
                                    inputWidth: 200,
                                }
                            });

                            forms?.current?.updateFields && forms.current.updateFields([
                                {
                                    label: t('common.code'),
                                    value: data.addressSegmentData.code || '',
                                    type: 'nonCryllic',
                                    inputWidth: 200,
                                    labelWidth: 300,
                                    required: true,
                                    key: 'code',
                                },
                                {
                                    label: t('common.name'),
                                    value: data.addressSegmentData.name || '',
                                    type: 'text',
                                    inputWidth: 200,
                                    labelWidth: 300,
                                    required: true,
                                    key: 'name',
                                },
                                {
                                    label: t('common.belongsTo'),
                                    value: data.addressSegmentData.parent || null,
                                    type: 'dropdown',
                                    inputWidth: 200,
                                    labelWidth: 300,
                                    options: addressSegments,
                                    key: 'parent',
                                    clearable: true
                                },
                                {
                                    type: 'jsx',
                                    value: <div key={'separator'} className="separator separator-dashed my-7"/>,
                                },
                                ...trFields
                            ]);
                        }

                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        } else {
            const fields = [
                {
                    label: t('common.code'),
                    value: '',
                    type: 'nonCryllic',
                    upperCase: true,
                    inputWidth: 200,
                    labelWidth: 300,
                    required: true,
                    key: 'code',
                },
                {
                    label: t('common.name'),
                    value: '',
                    type: 'text',
                    inputWidth: 200,
                    labelWidth: 300,
                    required: true,
                    key: 'name',
                },
                {
                    label: t('common.belongsTo'),
                    value: null,
                    type: 'dropdown',
                    inputWidth: 200,
                    labelWidth: 300,
                    options: addressSegments,
                    key: 'parent',
                    clearable: true
                },
                {
                    type: 'jsx',
                    value: <div key={'separator'} className="separator separator-dashed my-7"/>,
                },
                ...translationFields
            ];
            forms?.current?.updateFields && forms.current.updateFields(fields);
        }
    }, [ allAddressSegments, id ]);

    const onSaveClick = () => {
        const [ isValid, states, values ] = forms.current.validate();
        if (isValid) {
            const translations = [];
            for (let i = 0; i < states.length; i++) {
                const val = states[i];
                if (i >= 4) {
                    translations.push({
                        langCode: val.langCode,
                        name: val.value,
                    });
                }
            }
            const params = {
                ...values,
                translations: JSON.stringify(translations)
            }
            onSubmit(params);
        }
    }

    const fields = [
        {
            label: t('common.code'),
            value: '',
            type: 'text',
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            key: 'code',
        },
        {
            label: t('common.name'),
            value: '',
            type: 'text',
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            key: 'name',
        },
        {
            label: t('common.belongsTo'),
            value: null,
            type: 'dropdown',
            inputWidth: 200,
            labelWidth: 300,
            options: addressSegments,
            key: 'parent',
            clearable: true
        },
        {
            type: 'jsx',
            value: <div key={'separator'} className="separator separator-dashed my-7"/>,
        },
        ...translationFields
    ];

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('setting.addressSegment.title').toUpperCase()}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Forms
                    ref={forms}
                    fields={fields}
                />
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className='btn btn-link bolder'>{t('common.back')}</button>
                <Button variant='success btn-shadow' onClick={onSaveClick}>{t('common.save')}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddressSegmentAdd;