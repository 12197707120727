import React,{useEffect,useRef,useState} from 'react'
import { useDispatch,useSelector } from 'react-redux'
import {Button, Card, Row, Col, Image} from "react-bootstrap"
import { Doughnut } from 'react-chartjs-2'
import { Link,useHistory } from 'react-router-dom'
import { fetchRequest, ROOT_URL } from '../../../../utils/fetchRequest'
import { studentOnlineExamResult } from '../../../../utils/fetchRequest/Urls'
import message from '../../../modules/message'
import { setLoading } from '../../../../redux/action'
import Checkbox from '../../../modules/Form/Checkbox'
import { useTranslation } from 'react-i18next'
import { htmlDecode } from '../../../../utils/utils'
import { Chart, ArcElement, DoughnutController } from 'chart.js';
import ReactPlayer from 'react-player'
import { Radio as MUIRadio } from "@material-ui/core";
import styles from './../../exam/question/types/questionTypes.module.scss';
import clsx from "clsx";

Chart.register(ArcElement,DoughnutController);

const OnlineExamResult = ({
location: { state: {
    exam = 1,
    examDtl = 1
} = {} } = {}, }) => {
    
    const noAnswer = ['TEXT','CONNECT','MULTICHOICE','ORDER','TEXTINPUT']

    const types = [
        {
            value: 'true',
            label: 'Үнэн',
        },
        {
            value: 'false',
            label: 'Худал',
        },
    ]
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const chartRef = useRef()

    const school = useSelector(state => state?.selectedSchool?.id || null);

    const [examObj,setExamObj] = useState({})
    const [questionList,setQuestionList] = useState([])
    const [question,setQuestion] = useState({})

    const testDtlRows = [
        {
            key: 'takenScore',
            text: t('assessment.takenScore'),
            table: 1
        },
        {
            key: 'averageScore',
            text: t('onlineExam.averageScore'),
            table: 1
        },
        {
            key: 'rightQuestions',
            text: t('onlineExam.rightQuestions'),
            table: 1
        },
        {
            key: 'wrongQuestions',
            text: t('onlineExam.wrongQuestions'),
            table: 1
        },
        {
            key: 'noAnswer',
            text: t('onlineExam.noAnswerQuestions'),
            table: 1
        },
        {
            key: 'totalScore',
            text: t('onlineExam.testTotalScore'),
            table: 2
        },{
            key: 'totalQuestions',
            text: t('onlineExam.testTotalQuestions'),
            table: 2
        },
    ]

    const onNextQuestion = () =>{
        const tempData = questionList.find(q=>q.ordering === (question.ordering + 1))
        if(tempData){
            setQuestion(tempData)
        }
    }

    const onPrevQuestion = () =>{
        const tempData = questionList.find(q=>q.ordering === (question.ordering - 1))
        if(tempData){
            setQuestion(tempData)
        }
    }

    const renderTable = index =>{
        return (
            <table className='info-table'>
                <tbody>
                    {
                        testDtlRows.map((row,idx)=>{
                            if(row.table === index){
                                return (
                                    <React.Fragment key={idx + '-' + index}>
                                        <tr className='my-2'>
                                            <td>{row.text}</td>
                                            <th>{examObj[row.key] || '-'}</th>
                                        </tr>
                                    </React.Fragment>
                                )
                            }else{
                                return null
                            }
                        })
                    }
                </tbody>
            </table>
        )
    }

    const renderFile = (url) =>{
        if(typeof url == 'string'){
            if(/\.(jpg|jpeg|png|gif)$/.test(url)){
                return( 
                    <Image
                        src={ROOT_URL+ '/' +url}
                        height={'160px'}
                    />
                )
            }else{
                return (
                    <ReactPlayer
                        width={'320px'}
                        height={'160px'}
                        url={ROOT_URL+ '/' +url}
                    />
                )
            }
        }else{
            return null
        }
    }

    const renderAnswers = answer =>{
        if(question.type === 'MULTICHOICE'){
            const answerArr = question.answer || []
            if(answer.filePath){
                return <div className='p-4'>
                    <Image
                        src={ROOT_URL+ '/' +answer.filePath}
                        height={'160px'}
                        style={
                            answerArr.includes(answer.id)
                            ? 
                                (answer.isCorrect 
                                ?
                                    {opacity:'1',cursor:'pointer',boxShadow: '0 0 5px 5px #299f72'}
                                : answer.isCorrect == false ? {opacity:'1',cursor:'pointer'} : null)
                            : (answer.isCorrect 
                                ?
                                    {opacity:'.5',cursor:'pointer',boxShadow: '0 0 5px 5px #299f72'}
                                : answer.isCorrect == false ? {opacity:'.5',cursor:'pointer'} : null)
                        }
                    />
                    <Checkbox
                        disabled
                        label={answer.answer}
                        className='ml-10'
                        checked={answerArr?.includes(answer.id)}
                    />
                </div>
            }else{
                return (
                    <div style={answer.isCorrect ? correctBg : null}>
                        <Checkbox
                            disabled
                            label={answer.answer}
                            className='ml-10'
                            checked={answerArr?.includes(answer.id)}
                        />
                    </div>
                )
            }
        }
        else if(question.type === 'TRUEFALSE'){
            return (
                <div>
                    {
                        types.map(type => {
                            return (
                                <div
                                    key={type.value}
                                    className={styles.rowContainer}
                                >
                                    <MUIRadio
                                        disabled
                                        checked={answer.answer === type.value}
                                        className='ml-10'
                                    />
                                    <div className={clsx('form-control', styles.readOnly)}>
                                        {type.label}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            )
        }
        else if(question.type === 'RADIO'){
            const answerArr = question.answer || []
            if(answer.filePath){
                return (
                <div className='p-4'>
                    <Image
                        src={ROOT_URL +'/'+ answer.filePath}
                        height={'160px'}
                        style={
                            answerArr.includes(answer.id)
                            ? 
                                (answer.isCorrect 
                                ?
                                    {opacity:'1',cursor:'pointer',boxShadow: '0 0 5px 5px #299f72'}
                                : {opacity:'1',cursor:'pointer'})
                            : (answer.isCorrect 
                                ?
                                    {opacity:'.5',cursor:'pointer',boxShadow: '0 0 5px 5px #299f72'}
                                : {opacity:'.5',cursor:'pointer'})
                        }
                    />
                    <Checkbox
                        label={answer.answer}
                        className='ml-10'
                        checked={answerArr?.includes(answer.id)}
                    />                    
                </div>
                )
            }else{
                return (
                    <Checkbox
                        label={answer.answer}
                        className='ml-10'
                        checked={answerArr?.includes(answer.id)}
                    />
                )
            }
            
        }else if(question.type === 'CONNECT'){
            return (
                <Row className='align-items-center' >
                    <Col md={3} className='text-center' style={answer.isCorrect ? correctBg : answer.isCorrect == false ? wrongBg : null}>
                        <span style={{fontSize: '16px'}}>
                            <b>{answer.premise}</b>
                        </span>
                    </Col>
                    <Col md={3} className='text-center' style={answer.isCorrect ? correctBg : answer.isCorrect == false ? wrongBg : null}>
                        <span className='mx-2' style={{fontSize: '16px'}}>
                            {answer.response[1] || '-'}
                        </span>
                    </Col>
                    
                </Row>
            )
        }else if(question.type === 'ORDER'){
            return (
                <Row className='align-items-center' >
                    <Col md={3} className='text-center' style={answer.isCorrect ? correctBg : answer.isCorrect == false ? wrongBg : null}>
                        <span className='mx-2' style={{fontSize: '16px'}}>
                            {answer.answer || '-'}
                        </span>
                    </Col>
                </Row>
            )
        }
        else if(question.type === 'TEXTINPUT'){
            return (
                <Row className='align-items-center' >
                    <Col md={3} className='text-center' style={question.isCorrect ? correctBg : question.isCorrect == false ? wrongBg : null}>
                        <span className='mx-2' style={{fontSize: '16px'}}>
                            {question.answer || '-'}
                        </span>
                    </Col>
                </Row>
            )
        }else if(question.type === 'NUMBERINPUT'){
            return (
                <Row className='align-items-center'>
                    <Col md={3} className='text-center' style={question.isCorrect ? correctBg : question.isCorrect == false ? wrongBg : null}>
                        <span className='mx-2' style={{fontSize: '16px'}}>
                            {question.answer || '-'}
                        </span>
                    </Col>
                    {
                        question.answer2 ?
                            <Col md={3} className='text-center ml-2' style={question.isCorrect ? correctBg : question.isCorrect == false ? wrongBg : null}>
                                <span className='mx-2' style={{fontSize: '16px'}}>
                                    {question.answer2 || '-'}
                                </span>
                            </Col>
                            : null
                    }
                    
                </Row>
            )
        }else{
            return null
        }
    }

    useEffect(()=>{
        if(exam){
            const params = {
                exam,
                school,
                examDtl
            }
            dispatch(setLoading(true));
            fetchRequest(studentOnlineExamResult, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res
    
                        if(data?.exam){
                            setExamObj(data?.exam)
                        }

                        if(data?.questions?.length){
                            setQuestionList(data?.questions || [])
                            setQuestion(data.questions[0])
                        }
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        }
    },[exam])

    return (
        <Card>
            <Card.Header>
                <Row className='align-items-center'>
                    <Col md={6}>
                        <span style={{color:'#29569f',fontSize: '14px'}}>
                            {examObj.examName}
                        </span>
                    </Col>
                    <Col md={6} className='text-right'>
                        <Link
                            to='/student/online-exam'
                            className='btn btn-link bolder'
                        >
                            {t('common.back')}
                        </Link>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col md={3}>
                                        <Doughnut
                                            ref={chartRef}
                                            redraw={true}
                                            data={{
                                                datasets: [{
                                                    data: [examObj.success, 100 - examObj.success],
                                                    backgroundColor: [
                                                        '#4a70ae',
                                                        'transparent',
                                                    ],
                                                    borderJoinStyle: 'bevel',
                                                    borderRadius: 5
                                                }]
                                            }}
                                            options = {{
                                                responsive: true,
                                                maintainAspectRatio: false,
                                                animation: false,
                                                cutout: '85%',
                                                borderWidth: 0,
                                            }}
                                            plugins={[{
                                                beforeDraw: function(chart) {
                                                    const width = chart.width,
                                                    height = chart.height,
                                                    ctx = chart.ctx;
                                                    ctx.restore();
                                                    ctx.font = "bold 2.5em Arimo";
                                                    ctx.textBaseline = "top";
                                                    const text = examObj.success ? examObj.success + '%' : 0,
                                                    textX = Math.round((width - ctx.measureText(text).width) / 2),
                                                    textY = (height / 2.5);
                                                    ctx.fillText(text, textX, textY);
                                                    ctx.save();
                                                } 
                                            }]}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        {renderTable(1)} 
                                    </Col>
                                    <Col md={3}>
                                        {renderTable(2)} 
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <Col md={9}>
                        <Card>
                            <Card.Body>
                                {
                                    examObj.showAnswer ?
                                        question.id && !noAnswer.includes(question.type)
                                        ?
                                            <div className='w-100 mb-4 p-4' style={question.isCorrect ? correctBg : question.isCorrect == false ? wrongBg : null}>
                                                <span style={{fontSize:'14px'}}>
                                                    {
                                                        question.isCorrect
                                                        ? t('onlineExam.correct')
                                                        : question.isCorrect == false ?
                                                            t('onlineExam.incorrect') 
                                                            + ': ' 
                                                            + (
                                                                typeof question.correctAnswer === 'object' 
                                                                    ? question.correctAnswer.text 
                                                                    : question.correctAnswer
                                                                ) 
                                                            : null
                                                    }
                                                </span>
                                            </div>
                                        : null
                                    : null
                                }
                                <div className='w-100' style={{fontSize:'16px', color:'rgb(41, 86, 159)', fontWeight:'bold'}}>
                                    <div dangerouslySetInnerHTML={{ __html: question.question }}></div>
                                </div>
                                <div className='w-100'>
                                    <span style={{fontSize:'14px'}}>
                                        {t('onlineExam.questionScore') + ': '} <b>{question.score}</b>
                                    </span>
                                    <span className='ml-20' style={{fontSize:'14px'}}>
                                        {t('onlineExam.takenScore') + ': '} <b>{question.takenScore}</b>
                                    </span>
                                </div>
                                <div className='w-100 mt-4'>
                                    <span style={{fontSize:'14px'}}>
                                        {question.description}
                                    </span>
                                </div>
                                <div className='w-100 mt-5' style={{maxHeight:'160px'}}>
                                    {renderFile(question.path)}
                                </div>
                                {
                                    question.type === 'TEXT' || question.type === 'NUMBER'
                                    ?
                                        <div className='w-100 my-4'>
                                            {renderAnswers()}
                                        </div>
                                    : 
                                        (question.answers?.length
                                        ?
                                            question.answers.map(a=>{
                                                return (
                                                    <div 
                                                        key={a.id}
                                                        className='w-100 my-2'
                                                    >
                                                        {renderAnswers(a)}
                                                    </div>
                                                )
                                            })
                                        : null)
                                }
                            </Card.Body>
                        </Card>
                        <Row className='my-3'>
                            <Col md={6}>
                                <Button className="btn btn-outline-success" onClick={onPrevQuestion}>{'<< ' + t('onlineExam.before')}</Button>
                            </Col>
                            <Col md={6} className='text-right'>
                                <Button className="btn btn-outline-success" onClick={onNextQuestion}>{t('onlineExam.next') + ' >>'}</Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={3}>
                        <Card>
                            <Card.Header>
                                <span style={{ color: '#4a70ae',fontSize:'14px'}}>
                                    <b>{t('onlineExam.questions').toLocaleUpperCase()}</b>
                                </span>
                            </Card.Header>
                            <Card.Body style={{maxHeight: '450px',overflow:'auto'}}>
                                {
                                    questionList.length
                                    ?
                                        questionList.map((q,index)=>{
                                            const bgStyle = {
                                                boxShadow: '0 2px 10px 0 #014812',
                                                border: 'solid 1px #fff',
                                                borderRadius: '10px'
                                            }
                                            return (
                                                <div 
                                                    key={q.id + ' - ' + index}
                                                    style={q.id === question.id ? bgStyle : null}
                                                    className='m-2 p-3 align-items-center'
                                                >
                                                    <span style={{ color: '#4a70ae',fontSize:'14px'}} className='pr-2'>
                                                        <b>{q.order}</b>
                                                    </span>
                                                    <button 
                                                        style={q.isCorrect ? correctBtn : wrongBtn}
                                                        onClick={()=>{setQuestion(q)}}
                                                    >
                                                        {
                                                            q.isCorrect
                                                            ?
                                                                <i className='flaticon2-check-mark' style={{color:'#fff'}}/>
                                                            :
                                                                <i className='flaticon2-cross' style={{color:'#fff'}}/>
                                                        }
                                                    </button>
                                                </div>
                                            )
                                        })
                                    : null
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

const correctBtn = {
    width: '30px',
    height: '30px',
    borderRadius: '15px',
    backgroundColor: '#299f72',
    color: '#ffffff',
    boxShadow: '0 5px 15px 0 #299f7233',
    border: 'none'
}

const wrongBtn = {
    width: '30px',
    height: '30px',
    borderRadius: '15px',
    backgroundColor: '#f64e60',
    color: '#ffffff',
    boxShadow: '0 5px 15px 0 #f64e6033',
    border: 'none'
}

const correctBg = {
    backgroundColor: '#299f7233',
    border: 'none',
    borderRadius: '6px'
}

const wrongBg = {
    backgroundColor: '#f64e6033',
    border: 'none',
    borderRadius: '6px'
}
export default OnlineExamResult