import React,{useEffect,useState} from 'react'
import {Button, Modal,Row,Col} from "react-bootstrap";
import { fetchRequest } from '../../../../../utils/fetchRequest';
import { movementMissingChangeType } from '../../../../../utils/fetchRequest/Urls';
import { useDispatch,useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setLoading } from "../../../../../redux/action";
import message from '../../../../modules/message';
import Select from "../../../../modules/Form/Select";
import { TextareaAutosize } from "@material-ui/core";

const ChangeTypeModal = ({
    onClose = () => {},
    fetchList = () =>{},
    movementId = null
}) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    const [status,setStatus] = useState()
    const [statuses,setStatuses] = useState([])
    const [selectedSeasons,setSelectedSeasons] = useState([])
    const [seasons,setSeasons] = useState([])
    const [cause,setCause] = useState([])
    const [causes,setCauses] = useState([])
    const [student,setStudent] = useState({})
    const [ description, setDescription ] = useState('')

    const seasonOption = () =>{
        if(cause){
            const option = []
            seasons.map(s=>{
                if(cause === 'FULLYEAR'){
                    option.push({
                        value: s.key,
                        text: s.title
                    })
                }else{
                    if(s.children?.length){
                        s.children.map(c=>{
                            option.push({
                                value: c.key,
                                text: s.title + ' - ' + c.title
                            })
                        })
                    }
                }
            })
            return option
        }else{
            return []
        }
    }

    const onSaveClick = () =>{
        const params = {
            school: schoolId,
            description,
            movement:movementId,
            status,
            cause,
            seasons: selectedSeasons
        }
        dispatch(setLoading(true));
        fetchRequest(movementMissingChangeType, 'POST', params)
            .then(res => {
                if (res.success) {
                    fetchList()
                    onClose()
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                    dispatch(setLoading(false));
                }
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }

    useEffect(()=>{
        if(movementId){
            const params = {
                school:schoolId,
                movement:movementId
            }
            dispatch(setLoading(true));
            fetchRequest(movementMissingChangeType, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res;
                        const tempStatuses = []
                        if(data?.statuses?.length){
                            data?.statuses?.map(mc=>{
                                tempStatuses.push({
                                    value: mc.code,
                                    text: mc.name
                                })
                            })
                        }
                        setStatuses(tempStatuses)
                        if(data?.student){
                            setStudent(data?.student)
                        }
                        const tempCauses = []
                        if(data?.causes?.length){
                            data?.causes.map(c=>{
                                tempCauses.push({
                                    value: c.code,
                                    text: c.name
                                })
                            })
                        }
                        setCauses(tempCauses)
                        setSeasons(data?.seasons || [])
                        dispatch(setLoading(false));
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                        dispatch(setLoading(false));
                    }
                })
                .catch(err => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        }
    },[movementId])

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('movement.missing.statusChange')}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <table className='info-table' style={{margin: 'auto'}}>
                    <tbody>
                        <tr>
                            <td>{t('student.code')}</td>
                            <th>{student?.studentCode || '-'}</th>
                        </tr>
                        <tr>
                            <td>{t('common.lastName')}</td>
                            <th>{student?.lastName || '-'}</th>
                        </tr>
                        <tr>
                            <td>{t('common.firstName')}</td>
                            <th>{student?.firstName || '-'}</th>
                        </tr>
                        <tr>
                            <td>{t('student.status')}</td>
                            <th>{student?.status || '-'}</th>
                        </tr>
                    </tbody>
                </table>
                <Row className='mt-3'>
                    <Col md={4} style={{ display: 'flex', alignSelf: 'center', justifyContent: 'flex-end' }}>
                        {t('common.status') + '*'}
                    </Col>
                    <Col md={3}>
                        <Select
                            searchable
                            value={status}
                            onChange={value=>setStatus(value)}
                            options={statuses}
                        />
                    </Col>
                </Row>
                {
                    status === 'EXCUSED'
                    ?
                        <Row className='mt-3'>
                            <Col md={4} style={{ display: 'flex', alignSelf: 'center', justifyContent: 'flex-end' }}>
                                {t('common.type') + '*'}
                            </Col>
                            <Col md={3}>
                                <Select
                                    searchable
                                    value={cause}
                                    onChange={value=>setCause(value)}
                                    options={causes}
                                />
                            </Col>
                        </Row>
                    : null
                }
                {
                    status === 'EXCUSED'
                    ?
                        <Row className='mt-3'>
                            <Col md={4} style={{ display: 'flex', alignSelf: 'center', justifyContent: 'flex-end' }}>
                                {t('movement.excused.excuseableSeasons') + '*'}
                            </Col>
                            <Col md={3}>
                                <Select
                                    searchable
                                    multiple
                                    value={selectedSeasons}
                                    onChange={value=>setSelectedSeasons(value)}
                                    options={seasonOption()}
                                />
                            </Col>
                        </Row>
                    : null
                }
                <Row className='mt-3'>
                    <Col md={4} style={{ display: 'flex', alignSelf: 'center', justifyContent: 'flex-end' }}>
                        {t('common.description') + '*'}
                    </Col>
                    <Col md={3}>
                        <TextareaAutosize
                            className='msue-textarea-description'
                            value={description}
                            onChange={e => {
                                setDescription(e.target.value);
                            }}
                            rows={5}
                        />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className='btn btn-link bolder'>{t('common.back')}</button>
                <Button variant='success btn-shadow' onClick={onSaveClick}>{t('common.save')}</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ChangeTypeModal