import React,{useState,useEffect} from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { setLoading } from '../../../../redux/action';
import { fetchRequest } from '../../../../utils/fetchRequest';
import { useDispatch,useSelector } from 'react-redux';
import Instruction from '../../../modules/instruction';
import { Link,useHistory } from "react-router-dom";
import Select from "../../../modules/Form/Select";
import { TextareaAutosize } from "@material-ui/core";
import { getStudentInfo,movementExcuseAdd } from '../../../../utils/fetchRequest/Urls';
import message from '../../../modules/message';
import StudentInfoTable from '../StudentInfoTable';

const AddExcusedStudent = ({
    location: { state: {
        movement = null,
        tab = 'HALFYEAR'
    } = {} } = {}, }) => {

    const { t } = useTranslation();
    const dispatch = useDispatch()
    const history = useHistory()
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    const [ code, setCode ] = useState('');
    const [ student, setStudent ] = useState({});
    const [ seasons, setSeasons ] = useState([]);
    const [ season, setSeason ] = useState(null);
    const [ description, setDescription ] = useState('');

    const fetchData = params =>{
        dispatch(setLoading(true));
        fetchRequest(getStudentInfo, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;

                    if (data?.student) {
                        setStudent(data.student);

                        dispatch(setLoading(true));
                        fetchRequest(movementExcuseAdd, 'GET', {
                            school: schoolId,
                            student: data.student?.id,
                            movement,
                        })
                            .then(res => {
                                if (res.success) {
                                    const { data } = res;
                                    const tempSeasons = []

                                    if(data?.seasons?.length){
                                        data?.seasons.map(s=>{
                                            if(tab === 'YEARFULL'){
                                                tempSeasons.push({
                                                    text: s.title,
                                                    value: s.key
                                                })
                                            }else if(s.children?.length && tab === 'YEARHALF'){
                                                s.children.map(c=>{
                                                    tempSeasons.push({
                                                        text: c.title + ' - ' + s.title,
                                                        value: c.key
                                                    })
                                                })
                                            }
                                        })
                                    }

                                    setSeasons(tempSeasons);

                                    if(data?.movement){
                                        setDescription(data.movement.description)
                                        setSeason(data.movement.seasons)
                                    }
                                } else {
                                    message(res?.data?.message || t('errorMessage.title'))
                                }
                                dispatch(setLoading(false));
                            })
                            .catch(() => {
                                dispatch(setLoading(false));
                                message(t('errorMessage.title'))
                            })
                    }

                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }
    
    const handleBlur = () => {
        const params = {
            school: schoolId,
            studentCode: code,
        }
        fetchData(params)
    }

    const onSave = () =>{
        let error = false;
        if(!description){
            error = true;
            message('Тайлбар оруулна уу', false);
        }
        if(!season){
            error = true;
            message('Чөлөөлөгдөх улиралууд оруулна уу', false);
        }
        if(!error){
            const params = {
                school: schoolId,
                description,
                seasons: season,
                student: student?.id,
                movement
            }
            dispatch(setLoading(true));
            fetchRequest(movementExcuseAdd, 'POST', params)
                .then(res => {
                    if (res.success) {
                        history.push({
                            pathname: '/movement/excused',
                            state:{
                                tab
                            }
                        })
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        }
    }

    useEffect(()=>{
        if(movement){
            fetchData({
                school: schoolId,
                movement
            })
        }
    },[movement])

    return (
        <Card>
            <Card.Body>
                <div className='text-right'>
                    <Instruction
                    
                    />
                </div>
                {
                    movement
                    ? null
                    :
                        <Row>
                            <div className='col-3' style={{ display: 'flex', alignSelf: 'center', justifyContent: 'flex-end' }}>
                                {t('student.code') + '*'}
                            </div>
                            <div className='col-3'>
                                <input
                                    className='form-control'
                                    onChange={e=>{
                                        setCode(e.target.value)
                                        setStudent({});
                                        setSeason(null);
                                    }}
                                    value={code}
                                    onBlur={handleBlur}
                                />
                            </div>
                        </Row>
                }
                <Row className='mt-3'>
                    <Col md={2}>

                    </Col>
                    <Col>
                        <StudentInfoTable student={student}/>
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col md={3} style={{ display: 'flex', alignSelf: 'center', justifyContent: 'flex-end' }}>
                        {t('movement.excused.excuseableSeasons') + '*'}
                    </Col>
                    <Col md={3}>
                        <Select
                            searchable
                            multiple
                            value={season}
                            onChange={value=>setSeason(value)}
                            options={seasons}
                        />
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col md={3} style={{ display: 'flex', alignSelf: 'center', justifyContent: 'flex-end' }}>
                        {t('common.description') + '*'}
                    </Col>
                    <Col md={3}>
                        <TextareaAutosize
                            className='msue-textarea-description'
                            value={description}
                            onChange={e => {
                                setDescription(e.target.value);
                            }}
                            rows={5}
                        />

                    </Col>
                </Row>
            </Card.Body>
            <Card.Footer>
                <div className='text-center'>
                    <Link
                        to={'/movement/excused'}
                        className='btn btn-link bolder'
                    >
                        {t('common.back')}
                    </Link>
                    <button
                        className='btn btn-success'
                        onClick={onSave}
                    >
                        {t('common.save').toLocaleUpperCase()}
                    </button>
                </div>
            </Card.Footer>
        </Card>
    )
}

export default AddExcusedStudent