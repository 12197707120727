import React, {useEffect, useState} from 'react'
import {Button, Card} from 'react-bootstrap'
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import DTable from "../../../modules/DataTable/DTable";
import {setLoading} from "../../../../redux/action";
import {fetchRequest} from "../../../../utils/fetchRequest";
import TreeView from '../../../../app/modules/TreeView/index';
import {invoiceCreate, financeScholarshipInit} from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import AddScholarship from "./add";
import EditScholarship from "./edit";
import {priceFormat} from "../../../../utils/utils";

export const Scholarship = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    const [showAddModal, setShowAddModal] = useState(false);
    const [activeButton, setActiveButton] = useState('pay');
    const [scholarShips, setScholarShips] = useState([]);
    const [seasons, setSeasons] = useState([]);
    const [selectedTreeId, setSelectedTreeId] = useState(null);
    const [isEditModal, setIsEditModal] = useState(false);
    const [totalAmount, setTotalAmount] = useState('0');

    const parentSeason = useSelector(state => {
            let schoolList = state?.school?.schoolList;
            if(schoolList && schoolList.length > 0){
                for (let i = 0; i < schoolList.length; i++){
                    if(schoolId == schoolList[i]['id']){
                        return schoolList[i]['parentSeason'];
                    }
                }
            }
        }
    );

    useEffect(() => {
        const params = {
            school: schoolId,
            season: parentSeason,
            paid: activeButton == 'pay' ? 0 : 1
        };
        init(params)
    }, []);

    const init = (params) => {
        dispatch(setLoading(true));
        fetchRequest(financeScholarshipInit, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    let scholarShips = data.scholarShips;
                    if(scholarShips && scholarShips.length > 0){
                        for(let i = 0; i < scholarShips.length; i++){
                            if(scholarShips[i].createdDate){
                                scholarShips[i].createdDate = scholarShips[i].createdDate.date.substring(0, 10);
                            }
                            if(scholarShips[i].orderDate){
                                scholarShips[i].orderDate = scholarShips[i].orderDate.date.substring(0, 10);
                            }
                            if(scholarShips[i].typeCode && scholarShips[i].typeCode == 'PERCENT'){
                                scholarShips[i].amount = scholarShips[i].amount + '%';
                            }
                        }
                    }
                    setTotalAmount(data.totalAmount);
                    setScholarShips(scholarShips)
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const handleAddClick = () => {
        setShowAddModal(true);
    };

    const config = {
        showAllData: false,
        showPagination: true,
        showFilter: true,
        footer: true,
        footerStyle: {backgroundColor: '#ebecf5'}
    };

    const columns = [
        {
            dataField: "orderDate",
            text: t('scholarship.date'),
            footer: '',
        },
        {
            dataField: "orderNo",
            text: t('scholarship.number'),
            footer: '',
        },
        {
            dataField: "studentCode",
            text: t('student.code'),
            sort: true,
            footer: ''
        },
        {
            dataField: "lastName",
            text: t('student.lastName'),
            sort: true,
            footer: '',
        },
        {
            dataField: "firstName",
            text: t('student.name'),
            sort: true,
            footer: '',
        },
        {
            dataField: "typeName",
            text: t('common.type'),
            sort: true,
            footer: '',
        },
        {
            dataField: "amount",
            text: t('common.amount'),
            style: {textAlign: 'right'},
            sort: true,
            footerAlign: 'right',
            footer: priceFormat(totalAmount),
            // footerType: 'sum'
        },
        {
            dataField: "seasonName",
            text: t('school.season.title'),
            sort: true,
            footer: '',
        },
        {
            dataField: "createdDate",
            text: t('common.createdDate'),
            sort: true,
            footer: '',
        },
        {
            dataField: "createdUser",
            text: t('common.createdUser'),
            sort: true,
            footer: '',
        },
    ];

    const contextMenus = [
        {
            key: 'edit',
            title: t('common.edit'),
            icon: <i className='las la-edit dt-cm-item-icon'/>
        },
        {
            key: 'delete',
            title: t('common.delete'),
            icon: <i className='las la-trash-alt dt-cm-item-icon' />,
        },
    ];

    const handleContextMenuClick = (id, key) => {
        if (id && key) {
            if(key === 'edit'){
                setIsEditModal(true)
            } else if (key === 'delete') {
                
            }
        }
    };

    const onModalClose = () => {
        setShowAddModal(false);
    };

    const onEditModalClose = () => {
        setIsEditModal(false);
    };

    const onEditSubmit = params => {
        const bodyParams = {
            school: schoolId,
            season: parentSeason,
            ...params,
        };

        dispatch(setLoading(true));
        fetchRequest(financeScholarshipInit, 'POST', bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    message(data?.message || t('common.success'), true);
                    const params = {
                        school: schoolId,
                        season: parentSeason,
                        paid: activeButton == 'pay' ? 0 : 1
                    };
                    init(params);
                    onModalClose();
                } else {
                    message(res?.data?.message || t('errorMessage.title'));
                    dispatch(setLoading(false));
                }
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const onSubmit = params => {
        const bodyParams = {
            school: schoolId,
            season: parentSeason,
            ...params,
        };

        dispatch(setLoading(true));
        fetchRequest(financeScholarshipInit, 'POST', bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    message(data?.message || t('common.success'), true);
                    const params = {
                        school: schoolId,
                        season: parentSeason,
                        paid: activeButton == 'pay' ? 0 : 1
                    };
                    init(params);
                    onModalClose();
                } else {
                    message(res?.data?.message || t('errorMessage.title'));
                    dispatch(setLoading(false));
                }
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const handleChange = (idArray) => {
        if(idArray && idArray.length > 0){
            setSelectedTreeId(idArray);
            let params = {
                school: schoolId,
                // scholarship: selectedType ? selectedType.id : '',
                season:  idArray[0],
                pageSize: 10,
                page: 1,
                search: '',
                sort: [],
                order: 'asc'
            };

            // dispatch(setLoading(true));
            // fetchRequest(schoolScholarshipInit, 'GET', params)
            //     .then(response => {
            //         if (response.success) {
            //             setStudents(response.data.configs);
            //         } else {
            //             message(response.data.message, false);
            //         }
            //         dispatch(setLoading(false));
            //     })
            //     .catch(() => {
            //         dispatch(setLoading(false));
            //     });

            // localStorage.setItem(treeIndex, idArray[0]);
        }
    };

    const handleTableInteraction = (object) => {

    };

    return (
        <div className='sm-container'>
            <Card>
                <Card.Body>
                    <div className='row'>
                    <div className='col-4'>
                            <Card>
                                <Card.Body>
                                    <TreeView
                                        treeData={seasons}
                                        selectedNodes={selectedTreeId}
                                        onSelect={(e, data) => handleChange(e, data)}
                                        defaultExpandAll={true}
                                    />
                                </Card.Body>
                            </Card>
                        </div>
                        <div className='col-8 pl-0'>
                            <button
                                className='btn btn-primary'
                                onClick={handleAddClick}
                            >
                                {t('common.register')}
                            </button>

                        </div>
                        <div className='col-12'>
                            <DTable
                                remote
                                columns={columns}
                                config={config}
                                data={scholarShips}
                                contextMenus={contextMenus}
                                onContextMenuItemClick={handleContextMenuClick}
                                selectMode={'radio'}
                            />
                        </div>
                    </div>
                </Card.Body>
            </Card>
            {
                showAddModal && (
                    <AddScholarship
                        onClose={onModalClose}
                        onSubmit={onSubmit}
                    />
                )
            }
            {
                isEditModal && (
                    <EditScholarship
                        onClose={onEditModalClose}
                        onSubmit={onEditSubmit}
                    />
                )
            }
        </div>
    )
};
