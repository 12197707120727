import React, { useEffect, useState } from "react";
import styles from './questionTypes.module.scss';
import cloneDeep from 'lodash/cloneDeep'
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import Select from '../../../../modules/Form/Select'
import { Row, Col } from "react-bootstrap";

const Wrapper = (props) => {
    return (
        <DndProvider backend={HTML5Backend}>
            <NumberInput {...props}/>
        </DndProvider>
    )
}

const types = [
    {
        'value': 'equal',
        'text': 'Тэнцүү',
    },
    {
        'value': 'between',
        'text': 'Хооронд',
    },
]

const NumberInput = ({
    onChange,
    value
}) => {

    const { t } = useTranslation();
    const [type,setType] = useState('equal');

    const handleAnswerChange = (answer) => {
        let clone = cloneDeep(value);
        clone.answer = answer;
        onChange(clone);
    }

    const handleAnswerChange2 = (answer) => {
        let clone = cloneDeep(value);
        clone.answer2 = answer;
        onChange(clone);
    }

    return (
        <div style={{ width: 'auto' }}>
            {
                <div
                    id={0}
                    key={0}
                >
                    <div className={styles.rowContainer}>
                        <Select
                            className={clsx(styles.choiceInput)}
                            value={type}
                            onChange={value => setType(value)}
                            options={types}
                            clearable={false}
                            searchable
                        />
                        
                        <input
                            type='number'
                            className={clsx('form-control', styles.radioInput, 'ml-2')}
                            placeholder={t('exam.premiseResponse')}
                            value={value.answer}
                            onChange={(e) => handleAnswerChange(e.target.value)}
                        />
                        {
                            type == 'between' ?    
                                <input
                                type='number'
                                className={clsx('form-control', styles.radioInput ,'ml-2')}
                                placeholder={t('exam.premiseResponse')}
                                value={value.answer2}
                                onChange={(e) => handleAnswerChange2(e.target.value)}
                                />
                                : null 
                                
                        }
                    </div>
                            
              </div>
            }
        </div>
    );
};

export default Wrapper;