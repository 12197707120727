import React, { useState, useEffect } from 'react';
import { Button, ButtonToolbar, Card } from "react-bootstrap";
import TreeView from "../../../modules/TreeView";
import Instruction from "../../../modules/instruction";
import { useTranslation } from "react-i18next";
import { setLoading } from "../../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { fetchRequest } from "../../../../utils/fetchRequest";
import { hrSchoolPositions, hrSchoolPositionsDelete, programChangeStatus } from "../../../../utils/fetchRequest/Urls";
import PositionAddModal from "./PositionAddModal";
import message from "../../../modules/message";

const PositionSchool = () => {
    const { t } = useTranslation();
    const [ departments, setDepartments ] = useState([]);
    const [ departmentId, setDepartmentId ] = useState(null);
    const [ positions, setPositions ] = useState([]);
    const [ positionId, setPositionId ] = useState([]);
    const [ showModal, setShowModal ] = useState(false);
    const [ allPositions, setAllPositions ] = useState([]);

    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    useEffect(() => {
        dispatch(setLoading(true));
        const params = {
            school: schoolId,
        };
        fetchRequest(hrSchoolPositions, 'GET', params)
            .then(res => {
                const { data } = res;
                if (data?.departments?.length) {
                    setDepartments(data.departments);
                }
                if (data?.allPositions?.length) {
                    setAllPositions(data.allPositions);
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }, []);

    const handleTreeChange = ids => {
        if (ids?.length) {
            const [ id ] = ids;
            setDepartmentId(id);
            const params = {
                school: schoolId,
                department: id,
            };
            dispatch(setLoading(true));
            fetchRequest(hrSchoolPositions, 'GET', params)
                .then(res => {
                    const { data } = res;
                    if (data?.positions?.length) {
                        setPositions(data.positions);
                    } else {
                        setPositions([]);
                    }
                    if (data?.allPositions?.length) {
                        setAllPositions(data.allPositions);
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                })
        }
    };

    const onAddClick = () => {
        setShowModal(true);
    };

    const onRoleClick = id => {
        setPositionId(id[0]);
    };

    const handleContextMenuClick = (id, action) => {
        if(action.toLowerCase() === 'edit'){
        } else if(action.toLowerCase() === 'delete') {
            if (id) {
                const params = {
                    school: schoolId,
                    department: departmentId,
                    position: id,
                };

                dispatch(setLoading(true));
                fetchRequest(hrSchoolPositionsDelete, 'POST', params)
                    .then(res => {
                        const { data } = res;
                        if (res.success) {
                            setPositionId([]);
                            setPositions(data.positions);
                            setAllPositions(data.allPositions);
                            message(data?.message || t('common.success'), true);
                        } else {
                            message(data?.message || t('errorMessage.title'));
                        }
                        dispatch(setLoading(false));
                    })
                    .catch(() => {
                        dispatch(setLoading(false));
                    })
            }
        }
    };

    const onModalClose = (updatedValues) => {
        setShowModal(false);
        if (updatedValues) {
            setPositions(updatedValues);
        }
    };

    return (
        <div className='row'>
            <div className='col-4 p-0'>
                <Card>
                    <Card.Body>
                        <TreeView
                            treeData={departments}
                            selectedNodes={[departmentId]}
                            onSelect={handleTreeChange}
                            defaultExpandAll={true}
                        />
                    </Card.Body>
                </Card>
            </div>
            <div className='col-8 pr-0'>
                <Card>
                    <Card.Body>
                        <div className='col-12  p-0'>
                            {/*<ButtonToolbar className='d-inline'>*/}
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {
                                    departmentId
                                    ?
                                        <Button
                                            onClick={onAddClick}
                                            className="btn btn-primary btn-shadow mr-3"
                                        >
                                            {t('common.add')}
                                        </Button>
                                    : null
                                }
                                <Instruction/>
                            </div>
                        </div>
                        <TreeView
                            treeData={positions}
                            selectedNodes={[positionId]}
                            onSelect={onRoleClick}
                            defaultExpandAll={true}
                            contextMenus={{
                                treeActionKey: [
                                    // {
                                    //     key: 'EDIT',
                                    //     iconClassName: 'flaticon-edit',
                                    //     text: t('common.edit'),
                                    // },
                                    {
                                        key: 'DELETE',
                                        iconClassName: 'flaticon-delete-1',
                                        text: t('common.delete'),
                                    }
                                ]
                            }}
                            contextMenuKey={'treeActionKey'}
                            onContextMenuClick={handleContextMenuClick}
                        />

                    </Card.Body>
                </Card>
            </div>
            {
                showModal && (
                    <PositionAddModal
                        onClose={onModalClose}
                        departments={departments}
                        allPositions={allPositions}
                        depId={departmentId}
                        chosenPositions={positions}
                    />
                )
            }
        </div>
    )
};

export default PositionSchool;