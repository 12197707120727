import React, { useState } from 'react';
import { Button, Modal, ButtonGroup, ToggleButton, Form} from "react-bootstrap";
import Forms from "../../../modules/Form/Forms";
import { useTranslation } from "react-i18next";
import {useHistory} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {studentSelectionPaymentCheck, studentSelectionPayment} from "../../../../utils/fetchRequest/Urls";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import message from "../../../modules/message";
import { stItemEdit } from "../../../../utils/fetchRequest/Urls";

const radios = [
    /*{ name: "Khan", value: "KHAN"},*/
    { name: "qPay", value: "QPAY" },
  ];

const PaymentModal = ({
    onClose,
    school,
    config,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [radioValue, setRadioValue] = useState(false);
    const [qrImage, setQrImage] = useState(null);
    const [requestId, setRequestId] = useState(null);
    const [bankList, setBankList] = useState([]);
    const [isMobile, setIsMobile] = useState(false);

    const onSaveClick = () => {
        if(radioValue){
            const bodyParams = {
                config,
                school,
                type: radioValue
            };
    
            dispatch(setLoading(true));
            fetchRequest(studentSelectionPayment, 'GET', bodyParams)
                .then(res => {
                    if (res.success) {
                        const { data } = res;
                        setBankList(data?.bankList || [])
                        if(radioValue == 'QPAY'){
                            setQrImage(data?.qrImage);
                            setRequestId(data?.id);
                            if (
                                navigator.userAgent.match(/Android/i) ||
                                navigator.userAgent.match(/iPhone/i)
                            ) {
                                setIsMobile(true)
                            }
                        } else {
                            if(data?.redirectUrl){
                                localStorage.setItem('student_selection_two_back_url', '/student/selection/two');
                                window.location.href = data?.redirectUrl;
                            }
                        }
                    } else {
                        message(res?.data?.message || t('errorMessage.title'));
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        } else {
            message(t('warning.selectBankType'), false)
        }
    }

    const onCheckPayment = () => {
        if(requestId){
            const bodyParams = {
                request: requestId,
            };
    
            dispatch(setLoading(true));
            fetchRequest(studentSelectionPaymentCheck, 'GET', bodyParams)
                .then(res => {
                    if (res.success) {
                        window.location.reload()                        
                    } else {
                        message('Төлбөр төлөлт хийгдэж байна, та түр хүлээн дахин төлбөр төлсөн товчийг дарж шалгана уу' || t('errorMessage.title'));
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        } else {
            message(t('warning.selectBankType'), false)
        }
    }

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop='static'
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('invoiceType.pay')}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='col-12 text-center'>
                    <ButtonGroup toggle>
                        {radios.map((radio, index) => (
                            <ToggleButton
                                key={index}
                                type="radio"
                                name="radio"
                                value={radio.value}
                                checked={radioValue === radio.value}
                                onChange={(e)=> setRadioValue(e.target.value)}
                            >
                                {radio.name}
                            </ToggleButton>
                        ))}
                    </ButtonGroup>
                </div>
                <div className='col-12 text-center'>    
                    {
                        qrImage &&
                        <img src={'data:image/png;base64,' + qrImage} />
                    }
                </div>

                <div className='row col-12 text-center'>    
                    {
                        isMobile && bankList && bankList.length > 0 &&
                        bankList.map((bankData, index) => {
                            return(
                                <div key={'bank_' + index} className='col-3 mb-4' style={{display: 'grid'}}>
                                    <a href={bankData.link}>
                                        <img
                                            style={{borderRadius: 10}}
                                            src={bankData.logo} 
                                            width={50} 
                                        />
                                    </a>
                                    {bankData.name}
                                </div>
                            )
                        })
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className='btn btn-link bolder'>{t('common.close')}</button>
                {
                    qrImage
                    ?
                        <Button variant='success btn-shadow' onClick={onCheckPayment}>{t('invoiceType.paid')}</Button>
                    : 
                        <Button variant='success btn-shadow' onClick={onSaveClick}>{t('invoiceType.pay')}</Button>
                }
            </Modal.Footer>
        </Modal>
    );
};

export default PaymentModal;