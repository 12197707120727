import React, { useRef, useEffect, useState } from "react";
import { Button, Modal, View, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { TextareaAutosize } from "@material-ui/core";
import Forms from "app/modules/Form/Forms";
import { editStatus } from "utils/fetchRequest/Urls";
import { fetchRequest } from "utils/fetchRequest";
import message from "app/modules/message";
import { setLoading } from "redux/action";
import { useSelector, useDispatch } from "react-redux";

const ChangeStatus = ({
    onClose,
    onFinish,
    id,
    searchParams,
    stage,
    toStatus,
    program,
    enroll,
}) => {
    const { t } = useTranslation();
    const form = useRef();
    const dispatch = useDispatch();
    const [candidateDetail, setCandidateDetail] = useState([]);
    const [statusId, setStatusId] = useState([]);
    const [candidateLogDesc, setCandidateLogDesc] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [phaseData, setPhaseData] = useState(null);

    const forms = [
        {
            label: "Төлөв",
            required: true,
            type: "dropdown",
            multiple: false,
            clearable: true,
            inputWidth: 200,
            labelWidth: 200,
            value: statusId,
            options: statuses,
            key: "toStatus",
        },
        {
            label: "Тайлбар *",
            type: "textArea",
            inputWidth: 250,
            required: true,
            labelWidth: 200,
            maxLength: 160,
            key: "description",
            value: candidateLogDesc.description,
        },
    ];

    useEffect(() => {
        form?.current?.updateFields && form.current?.updateFields(forms);
    }, [statuses, candidateDetail]);

    useEffect(() => {
        const params = {
            candidate: id,
            toStatus: toStatus,
            enroll: enroll,
        };

        dispatch(setLoading(true));
        fetchRequest(editStatus, "GET", params)
            .then((res) => {
                if (res.success) {
                    const { data } = res;
                    setCandidateDetail(data?.candidateDetail || null);
                    setPhaseData(data?.phaseData || null);

                    if(data?.phaseData && data?.phaseData.code == 'EYESH'){
                        setStatusId(data?.candidateDetail && data.candidateDetail.programStatusId)
                    }

                    if (data?.statuses) {
                        const statusOptions = [];
                        const statuses = data.statuses;
                        statuses.forEach((element) => {
                            if(element.code == "RETURNED"){
                                statusOptions.push({
                                    code: element.code,
                                    value: element.id,
                                    text: element.name,
                                });
                            }
                        });
                        setStatuses(statusOptions);
                    }
                } else {
                    message(res?.data?.message || t("errorMessage.title"));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t("errorMessage.title"));
            });
    }, []);

    const onSaveClick = () => {
        const [formValid, formValues] = form.current.validate();
        if (formValid) {
            const params = {
                candidate: id,
                toStatus: formValues?.[0]?.value,
                description: formValues?.[1]?.value,
                enroll: enroll,
                program: program,
            };

            dispatch(setLoading(true));
            fetchRequest(editStatus, "POST", params)
                .then((res) => {
                    if (res.success) {
                        message(res?.data?.message, true);
                        onFinish();
                    } else {
                        message(res.data.message, false);
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t("errorMessage.title"));
                });
        }
    };

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: "#4a70ae", fontSize: "1.1rem" }}>Засах</div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="d-flex justify-content-end col-3 ml-10">
                        <img
                            className="d-flex justify-content-end"
                            style={{
                                objectFit: "contain",
                                height: "105px",
                                width: "93px",
                                margin: "auto",
                                marginTop: "0.5px",
                                marginLeft: "10rem",
                            }}
                            src={
                                candidateDetail.avatar === null ||
                                    candidateDetail.avatar === "" ||
                                    candidateDetail.avatar === undefined ||
                                    candidateDetail.avatar === "NULL"
                                    ? "/media/users/avatar_male.png"
                                    : "https://darkhanapi.nomch.mn/" + candidateDetail.avatar
                            }
                        />
                    </div>
                    <ul className="col-7" style={{ lineHeight: 0.5 }}>
                        <li className="d-flex justify-content-center row">
                            <div className="col">
                                <p
                                    style={{
                                        color: "#185BC5",
                                        fontWeight: "bold",
                                        fontSize: "normal",
                                    }}
                                    className=" d-flex justify-content-end"
                                >
                                    Бүртгэлийн дугаар
                                </p>
                            </div>
                            <div className="col justify-content-end">
                                <span style={{ fontWeight: "bold" }} className="text-dark">
                                    {candidateDetail.id}
                                </span>
                            </div>
                        </li>
                        <li className="d-flex justify-content-center row pb-3">
                            <div className="col">
                                <p
                                    style={{
                                        color: "#185BC5",
                                        fontWeight: "bold",
                                        fontSize: "normal",
                                    }}
                                    className=" d-flex justify-content-end"
                                >
                                    ЭЕШ бүртгэлийн дугаар
                                </p>
                            </div>
                            <div className="col justify-content-end">
                                <span style={{ fontWeight: "bold" }} className="text-dark">
                                    {candidateDetail.butName}
                                </span>
                            </div>
                        </li>
                        <li className="d-flex justify-content-center row">
                            <div className="col">
                                <p
                                    style={{
                                        color: "#185BC5",
                                        fontWeight: "bold",
                                    }}
                                    className=" d-flex justify-content-end"
                                >
                                    Овог
                                </p>
                            </div>
                            <div className="col justify-content-end">
                                <span style={{ fontWeight: "bold" }} className="text-dark">
                                    {candidateDetail.lastName}
                                </span>
                            </div>
                        </li>
                        <li className="d-flex justify-content-center row">
                            <div className="col">
                                <p
                                    style={{
                                        color: "#185BC5",
                                        fontWeight: "bold",
                                        fontSize: "normal",
                                    }}
                                    className=" d-flex justify-content-end"
                                >
                                    Нэр
                                </p>
                            </div>
                            <div className="col justify-content-end">
                                <span style={{ fontWeight: "bold" }} className="text-dark">
                                    {candidateDetail.firstName}
                                </span>
                            </div>
                        </li>
                        <li className="d-flex justify-content-center row">
                            <div className="col">
                                <p
                                    style={{
                                        color: "#185BC5",
                                        fontWeight: "bold",
                                        fontSize: "normal",
                                    }}
                                    className=" d-flex justify-content-end"
                                >
                                    Регистрийн дугаар
                                </p>
                            </div>
                            <div className="col justify-content-end">
                                <span style={{ fontWeight: "bold" }} className="text-dark">
                                    {candidateDetail.registrationNumber}
                                </span>
                            </div>
                        </li>
                        <li className="d-flex justify-content-center row">
                            <div className="col">
                                <p
                                    style={{
                                        color: "#185BC5",
                                        fontWeight: "bold",
                                        fontSize: "normal",
                                    }}
                                    className=" d-flex justify-content-end"
                                >
                                    Хүйс
                                </p>
                            </div>
                            <div className="col justify-content-end">
                                <span style={{ fontWeight: "bold" }} className="text-dark">
                                    {candidateDetail.gender === "M" ? "Эрэгтэй" : "Эмэгтэй"}
                                </span>
                            </div>
                        </li>
                        <li className="d-flex justify-content-center row">
                            <div className="col">
                                <p
                                    style={{
                                        color: "#185BC5",
                                        fontWeight: "bold",
                                        fontSize: "normal",
                                    }}
                                    className=" d-flex justify-content-end"
                                >
                                    И-мэйл
                                </p>
                            </div>
                            <div className="col justify-content-end">
                                <span style={{ fontWeight: "bold" }} className="text-dark">
                                    {candidateDetail.email}
                                </span>
                            </div>
                        </li>
                        <li className="d-flex justify-content-center row">
                            <div className="col">
                                <p
                                    style={{
                                        color: "#185BC5",
                                        fontWeight: "bold",
                                        fontSize: "normal",
                                    }}
                                    className=" d-flex justify-content-end"
                                >
                                    Утасны дугаар
                                </p>
                            </div>
                            <div className="col justify-content-end">
                                <span style={{ fontWeight: "bold" }} className="text-dark">
                                    {candidateDetail.gMobileNumber}
                                </span>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="d-flex justify-content-center">
                    <Forms ref={form} fields={forms} />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className="btn btn-link bolder">
                    {t("common.back")}
                </button>
                <Button variant="success btn-shadow" onClick={onSaveClick}>
                    {t("common.save")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ChangeStatus;
