import React, { useState, useEffect } from 'react';
import { Card, Tabs, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import { selectionConfigAdd, selectionConfigEdit, selectionConfigDelete } from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import DTable from "../../../modules/DataTable/DTable";
import SelectionAdd from "./SelectionAdd";
import SelectionEdit from "./SelectionEdit";
import TreeView from "../../../modules/TreeView";
import { getFlatTreeData } from "../../../../utils/utils";
import DeleteModal from "../../../modules/DeleteModal";

const Selection = () => {
    const [tabKey, setTabKey] = useState(1);
    const [configs, setConfigs] = useState([]);
    const [seasons, setSeasons] = useState([]);
    const [parentSeasons, setParentSeasons] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [nodeId, setNodeId] = useState(null);
    const [configId, setConfigId] = useState(null);
    const [canAdd, setCanAdd] = useState(false);
    const [selectionTypes, setSelectionTypes] = useState(false);
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        const params = {
            school: schoolId,
            season: nodeId,
            selection: tabKey,
        };
        fetchData(params);
    }, [nodeId, tabKey]);

    const handleAddClick = () => {
        setShowModal(true);
    };

    const onModalClose = () => {
        setShowModal(false);
        setConfigId(null);
    };

    const config = {
        showPagination: false,
        showFilter: false,
        showAllData: true,
    };

    const columns = [
        {
            dataField: "selectionType",
            text: t('common.type'),
            style: { textAlign: 'right' },
        },
        {
            dataField: "openDate",
            text: t('setting.selection.openDate'),
            style: { textAlign: 'right' },
        },
        {
            dataField: "closeDate",
            text: t('setting.selection.closeDate'),
            style: { textAlign: 'right' },
            sort: true
        },
        {
            dataField: "createdDate",
            text: t('common.createdDate'),
            style: { textAlign: 'right' },
            sort: true
        },
        {
            dataField: "firstName",
            text: t('common.createdUser'),
            sort: true
        },
    ];

    const contextMenus = [
        {
            key: 'edit',
            title: t('common.edit'),
            icon: <i className='las la-edit dt-cm-item-icon' />
        },
        {
            key: 'delete',
            title: t('common.delete'),
            icon: <i className='las la-trash-alt dt-cm-item-icon' />,
        },
    ];

    const handleContextMenuClick = (id, key) => {
        setConfigId(id);
        if (key == 'edit') {
            setShowEditModal(true);
        } else if (key == 'delete'){
            setShowDeleteModal(true);
        }
    };

    const handleNodeClick = array => {
        if (array?.length) {
            const [id] = array;
            const split = id.toString().split('_');
            if (split.length === 4) {
                setNodeId(id);
            }
        }
    };

    const fetchData = params => {
        dispatch(setLoading(true));
        fetchRequest(selectionConfigAdd, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    let codes = [];
                    let selectionType = data?.selectionTypeCodes;

                    setConfigs(data?.configs ? data.configs : []);
                    setSeasons(data?.seasons ? data.seasons : []);
                    setParentSeasons(data?.parentSeasons ? data.parentSeasons : []);

                    setCanAdd(data?.canAdd);
                    for (let key in selectionType) {
                        codes.push({
                            value: selectionType[key],
                            text: selectionType[key]
                        });
                    }
                    setSelectionTypes(codes);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const handleTabClick = key => {
        if (key !== tabKey) {
            setTabKey(key);
        }
    };

    const addPrefixParent = (list, id = null, string = '') => {
        if (!id) {
            return string;
        }
        const parent = list.find(item => item.key === id);
        if (parent) {
            return addPrefixParent(list, parent.parent, `${parent.title || '*'} - ${string}`);
        }
        return string;
    };

    let selectedSeasonName = '-';

    if (nodeId) {
        const flatTreeData = getFlatTreeData(seasons);
        const selectedSeason = flatTreeData.find(object => object.key === nodeId);
        if (selectedSeason) {
            selectedSeasonName = addPrefixParent(flatTreeData, selectedSeason.parent, selectedSeason.title);
        }
    }

    const onSubmit = params => {
        const url = configId ? selectionConfigEdit : selectionConfigAdd;
        const bodyParams = {
            ...params,
            classes: JSON.stringify(params.classes),
            school: schoolId,
            season: nodeId,
        };

        if (configId) {
            bodyParams.config = configId;
            bodyParams.selection = tabKey;
        }

        dispatch(setLoading(true));
        fetchRequest(url, 'POST', bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res;

                    setConfigs(data?.configs ? data?.configs : []);
                    setSeasons(data?.seasons ? data.seasons : []);

                    message(data?.message || t('common.success'), true);

                    onModalClose();
                } else {
                    message(res?.data?.message || t('errorMessage.title'));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const onDelete = () => {
        const params = {
            school: schoolId,
            season: nodeId,
            selection: tabKey,
            config: configId
        };
        dispatch(setLoading(true));
        fetchRequest(selectionConfigDelete, 'POST', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setConfigs(data?.configs ? data?.configs : []);
                    message(data?.message || t('common.success'), true);
                    onModalClose();
                } else {
                    message(res?.data?.message || t('errorMessage.title'));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            })
    };

    const formatDate = dateObject => {
        if (dateObject?.date) {
            return dateObject.date.substring(0, 19);
        }
        return '-';
    };

    const tableData = configs.map(cfg => {
        return {
            ...cfg,
            createdDate: formatDate(cfg.createdDate),
            openDate: formatDate(cfg.startDate),
            closeDate: formatDate(cfg.endDate),
        }
    });

    return (
        <div className='row'>
            <div className='col-3 pr-0'>
                <Card>
                    <Card.Body>
                        <TreeView
                            treeData={seasons}
                            selectedNodes={[nodeId]}
                            onSelect={handleNodeClick}
                            defaultExpandAll={true}
                        />
                    </Card.Body>
                </Card>
            </div>
            <div className='col-9'>
                <Card>
                    <Card.Header>
                        <Tabs
                            id={'selection-type'}
                            activeKey={tabKey}
                            onSelect={handleTabClick}
                            className='msue-tab'
                        >
                            <Tab eventKey={1} title={`${t('common.selection')} 1`} />
                            <Tab eventKey={2} title={`${t('common.selection')} 2`} />
                        </Tabs>
                    </Card.Header>
                    <Card.Body>
                        {
                            canAdd == true && nodeId
                                ? <button
                                    className='btn btn-primary'
                                    onClick={handleAddClick}
                                >
                                    {t('common.add')}
                                </button>
                                : null
                        }
                        <DTable
                            columns={columns}
                            config={config}
                            data={tableData}
                            contextMenus={contextMenus}
                            onContextMenuItemClick={handleContextMenuClick}
                            selectMode={'radio'}
                        />
                        {
                            showModal && (
                                <SelectionAdd
                                    onClose={onModalClose}
                                    onSubmit={onSubmit}
                                    seasonName={selectedSeasonName}
                                    id={configId}
                                    school={schoolId}
                                    season={nodeId}
                                    parentSeasons={parentSeasons}
                                    selection={tabKey}
                                    types={selectionTypes}
                                />
                            )
                        }
                        {
                            showEditModal && configId && (
                                <SelectionEdit
                                    onClose={onModalClose}
                                    onSubmit={onSubmit}
                                    seasonName={selectedSeasonName}
                                    id={configId}
                                    school={schoolId}
                                    season={nodeId}
                                    parentSeasons={parentSeasons}
                                    selection={tabKey}
                                    types={selectionTypes}
                                />
                            )
                        }
                        {
                            showDeleteModal && configId && (
                                <DeleteModal
                                    onClose={onModalClose}
                                    onDelete={onDelete}
                                    title={t('setting.selection.title')}
                                >
                                    {t('warning.delete_confirmation')}
                                    <br />
                                    <br />
                                    {t('warning.delete_confirmation_description')}
                                </DeleteModal>
                            )
                        }
                    </Card.Body>
                </Card>
            </div>
        </div>
    );
};

export default Selection;