import React, { useState, useEffect, useRef } from 'react';
import { Card, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import Forms from "../../../modules/Form/Forms";
import { fetchRequest } from "../../../../utils/fetchRequest";
import { departmentInit, departmentAdd, departmentEdit } from "../../../../utils/fetchRequest/Urls";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../../redux/action";
import { getFlatTreeData } from "../../../../utils/utils";
import message from "../../../modules/message";

const DepartmentNew = ({
    location: { state: { schoolId = null, departmentId = null } = {} } = {},
}) => {
    const { t, /*i18n*/ } = useTranslation();
    const forms = useRef();
    const translations = useRef();
    const dispatch = useDispatch();
    const history = useHistory();

    const [ departments, setDepartments ] = useState([]);

    const languages = useSelector(state => state?.languages?.languages || []).filter(lang => lang.code !== 'mn');

    useEffect(() => {
        if (departmentId) {
            const params = {
                school: schoolId,
                department: departmentId
            };
            dispatch(setLoading(true));
            fetchRequest(departmentEdit, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res;

                        if (data?.department) {

                            const deps = getFlatTreeData(data.departments || []);
                            const filteredDeps = deps.filter(dep => dep.id !== departmentId).map(dep => {
                                return {
                                    value: dep.key,
                                    text: dep.title
                                }
                            });

                            filteredDeps.unshift(
                                {
                                    value: null,
                                    text: '- ' + t('common.select') + ' -'
                                }
                            );

                            if(!data.department.parent){
                                forms?.current?.updateFields && forms.current.updateFields([
                                    {
                                        key: 'code',
                                        label: t('common.code') + '*',
                                        value: data.department.code || '',
                                        errorMessage: t('errorMessage.enterCode'),
                                        required: true,
                                        type: 'nonCryllic',
                                        upperCase: true,
                                        name: 'code'
                                    },
                                    {
                                        label: t('common.name') + '*',
                                        value: data.department.name || '',
                                        errorMessage: t('errorMessage.enterName'),
                                        required: true,
                                        type: 'text',
                                        name: 'name'
                                    },
                                    {
                                        label: t('department.isUsedInCurriculum'),
                                        type: 'checkbox',
                                        value: !!data.department.isAcademic,
                                        name: 'isCurriculum'
                                    }
                                ]);
                            } else {
                                forms?.current?.updateFields && forms.current.updateFields([
                                    {
                                        key: 'code',
                                        label: t('common.code') + '*',
                                        value: data.department.code || '',
                                        errorMessage: t('errorMessage.enterCode'),
                                        required: true,
                                        type: 'nonCryllic',
                                        upperCase: true,
                                        name: 'code'
                                    },
                                    {
                                        label: t('common.name') + '*',
                                        value: data.department.name || '',
                                        errorMessage: t('errorMessage.enterName'),
                                        required: true,
                                        type: 'text',
                                        name: 'name'
                                    },
                                    {
                                        label: t('school.parentDepartment') + '*',
                                        value: data.department.parent || '',
                                        required: true,
                                        errorMessage: t('errorMessage.selectRelatedDepartment'),
                                        type: 'dropdown',
                                        options: filteredDeps,
                                        multiple: false,
                                        clearable: true,
                                        name: 'parent'
                                    },
                                    {
                                        label: t('department.isUsedInCurriculum'),
                                        type: 'checkbox',
                                        value: !!data.department.isAcademic,
                                        name: 'isCurriculum'
                                    }
                                ]);
                            }


                            const trs = data.department.translations || [];

                            const trFields = languages.map(lang => {

                                const translation = trs.find(tr => tr.langCode === lang.code);
                                const value = translation?.name || '';

                                return {
                                    label: `${t('common.name')} - ${lang.name} (${lang.code})`,
                                    value,
                                    required: false,
                                    type: 'text',
                                    langCode: lang.code,
                                }
                            });
                            translations?.current?.updateFields && translations.current.updateFields(trFields);
                        }

                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'));
                })
        } else {
            dispatch(setLoading(true));
            const params = {
                school: schoolId,
            };
            fetchRequest(departmentInit, 'GET', params)
                .then(res => {
                    if (res.success) {
                        const { data } = res;
                        if (data?.departments?.length) {
                            const flatData = getFlatTreeData(data.departments);
                            const deps = flatData.map(dep => {
                                return {
                                    value: dep.key,
                                    text: dep.title
                                }
                            });
                            setDepartments(deps);
                        }

                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                })
        }
    }, []);

    useEffect(() => {
        forms?.current?.updateFields && forms.current.updateFields(formFields);
    }, [departments]);

    const formFields = [
        {
            key: 'code',
            label: t('common.code') + '*',
            value: '',
            errorMessage: t('errorMessage.enterCode'),
            required: true,
            type: 'nonCryllic',
            upperCase: true,
            name: 'code'
        },
        {
            label: t('common.name') + '*',
            value: '',
            errorMessage: t('errorMessage.enterName'),
            required: true,
            type: 'text',
            name: 'name'
        },
        {
            label: t('school.parentDepartment') + '*',
            value: '',
            required: true,
            errorMessage: t('errorMessage.selectRelatedDepartment'),
            type: 'dropdown',
            options: departments,
            multiple: false,
            clearable: true,
            name: 'parent'
        },
        {
            label: t('department.isUsedInCurriculum'),
            type: 'checkbox',
            value: false,
            name: 'isCurriculum'
        }
    ];

    const handleSaveClick = () => {
        const [ formsValid, formValues ] = forms.current.validate();
        const [ translationsValid, translationValues ] = translations.current.validate();
        if (formsValid && translationsValid) {
            const params = {
                school: schoolId,
            };
            if(formValues && formValues.length > 0){
                for(let i = 0; i < formValues.length; i++){
                    if(formValues[i].name === 'code'){
                        params.code = formValues[i].value;
                    } else if (formValues[i].name === 'name'){
                        params.name = formValues[i].value;
                    } else if (formValues[i].name === 'isCurriculum'){
                        params.academic = formValues[i].value === true ? 1: 0;
                    } else if (formValues[i].name === 'parent'){
                        if(formValues[i].value){
                            params.parent = formValues[i].value;
                        }
                    }
                }
            }

            const translations = translationValues.map(val => {
                return {
                    langCode: val.langCode,
                    name: val.value,
                }
            });

            params.translations = JSON.stringify(translations);

            dispatch(setLoading(true));

            const url = departmentId ? departmentEdit : departmentAdd;
            if (departmentId) {
                params.department = departmentId;
            }

            fetchRequest(url, 'POST', params)
                .then(res => {
                    if (res.success) {
                        history.push('/structure/department');
                    } else {
                        message(res?.data?.message);
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                })
        }
    };

    const getFormTranslations = () => {
        return languages.map(lang => {
            return {
                label: `${t('common.name')} - ${lang.name} (${lang.code})`,
                value: '',
                required: false,
                type: 'text',
                langCode: lang.code,
            }
        })
    };

    return (
        <div className='row'>
            <div className='col-12'>
                <Card>
                    <Card.Body>
                        <div className='col-12 text-right'>
                            <Button className='bg-white border-white d-inline btn-hover-less'
                                    /*onClick={handleHelperModal}*/>
                                <i className="icon-2x text-danger flaticon-questions-circular-button d-inline"/>
                                <p className='text-danger d-inline'><b>{t('common.helper')}</b></p>
                            </Button>
                        </div>
                        <div className='col-12'>
                            <Forms
                                ref={forms}
                                fields={formFields}
                            />
                        </div>
                        <div className="separator separator-dashed my-7"/>
                        <div className='col-12'>
                            <Forms
                                ref={translations}
                                fields={getFormTranslations()}
                            />
                        </div>
                    </Card.Body>
                    <Card.Footer>
                        <div className='col-12 text-center'>
                            <Link
                                to='/structure/department'
                                className="btn btn-link bolder"
                            >
                                {t('common.back')}
                            </Link>
                            <Button
                                onClick={handleSaveClick}
                                variant="success btn-shadow"
                            >
                                {t('common.save')}
                            </Button>
                        </div>
                    </Card.Footer>
                </Card>
            </div>
        </div>
    )
};

export default DepartmentNew;