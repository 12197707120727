import React from "react";
import { Checkbox } from "@material-ui/core";
import styles from './questionTypes.module.scss';
import cloneDeep from 'lodash/cloneDeep'
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { ROOT_URL } from "../../../../../utils/fetchRequest";

const MultipleChoice = ({
    onChange,
    value
}) => {

    const { t } = useTranslation();

    const handleCheck = index => {
        const clone = cloneDeep(value);
        clone[index].isCorrect = !clone[index].isCorrect;
        onChange(clone)
    }

    const handleAnswerChange = (index, answerValue) => {
        const clone = cloneDeep(value);
        clone[index].answer = answerValue;
        onChange(clone);
    }

    const handleScoreChange = (index, score) => {
        // const re = /[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)/;
        // const re = /^[+-]?\d+(\.\d+)?$/

        // if (score === '' || re.test(score)) {
            const clone = cloneDeep(value);
            clone[index].score = score;
            onChange(clone);
        // }
    }

    const handleFileChange = (event, index) => {
        if (event.target.files.length) {
            const file = event.target.files[0];
            const clone = cloneDeep(value);
            clone[index].filePath = null;
            clone[index].file = file;
            onChange(clone);
        }
        event.target.value = null;
    }

    const addRow = () => {
        const clone = cloneDeep(value);
        clone.push({
            isCorrect: false,
            answer: '',
            score: '',
            file: null,
        })
        onChange(clone);
    }

    const removeRow = index => {
        const clone = cloneDeep(value);
        clone.splice(index, 1);
        onChange(clone);
    }

    const removeImage = index => {
        const clone = cloneDeep(value);
        clone[index].file = null;
        onChange(clone);
    }

    const removeUrlImage = index => {
        const clone = cloneDeep(value);
        clone[index].filePath = null;
        onChange(clone);
    }

    return (
        <div style={{ width: 'min-content' }}>
            {
                value.map((row, index) => {
                    const id = `radio-file-${index}`;
                    return (
                        <div key={index}>
                            <div className={styles.rowContainer}>
                                <Checkbox
                                    checked={row.isCorrect}
                                    onChange={() => handleCheck(index)}
                                />
                                <input
                                    className={clsx('form-control', styles.radioInput)}
                                    onChange={(e) => handleAnswerChange(index, e.target.value)}
                                    value={row.answer}
                                />
                                <input
                                    className={clsx('form-control', styles.scoreInput)}
                                    onChange={(e) => handleScoreChange(index, e.target.value)}
                                    value={row.score}
                                    placeholder={t('exam.score')}
                                />
                                <input
                                    id={id}
                                    style={{ display: 'none' }}
                                    onChange={(e) => handleFileChange(e, index)}
                                    type='file'
                                    accept='image/png, image/gif, image/jpeg'
                                />
                                <label htmlFor={id} className={'btn btn-success mx-5'}>
                                    <i className='las la-image p-0' style={{ fontSize: 22 }} />
                                </label>
                                {
                                    index > 0
                                        ?   (
                                            <button className='btn btn-danger row-action-button' onClick={() => removeRow(index)}>
                                                <i className='las la-times' style={{ fontSize: 20 }} />
                                            </button>
                                        )
                                        :   <div style={{ width: 36 }}/>
                                }
                            </div>
                            {
                                row.file && (
                                    <div className={styles.imageContainer}>
                                        {
                                            <img
                                                className={styles.imagePreview}
                                                src={URL.createObjectURL(row.file)}
                                                alt=''
                                            />
                                        }
                                        <button className={clsx('btn btn-danger row-action-button mt-5')} onClick={() => removeImage(index)}>
                                            <i className='las la-trash' style={{ fontSize: 20 }} />
                                        </button>
                                    </div>
                                )
                            }
                            {
                                row.filePath && (
                                    <div className={styles.imageContainer}>
                                        {
                                            <img
                                                className={styles.imagePreview}
                                                src={ ROOT_URL + '/' + row.filePath}
                                                alt=''
                                            />
                                        }
                                        <button className={clsx('btn btn-danger row-action-button mt-5')} onClick={() => removeUrlImage(index)}>
                                            <i className='las la-trash' style={{ fontSize: 20 }} />
                                        </button>
                                    </div>
                                )
                            }
                        </div>
                    )
                })
            }
            <div className='text-right pr-1'>
                <button className='btn btn-primary row-action-button mr-1' onClick={addRow}>
                    <i className='las la-plus' style={{ fontSize: 20 }} />
                </button>
            </div>
        </div>
    );
};

export default MultipleChoice;