import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import Forms from 'app/modules/Form/Forms';
import CollectionsIcon from '@mui/icons-material/Collections';
import SouthSharpIcon from '@mui/icons-material/SouthSharp';
import NorthSharpIcon from '@mui/icons-material/NorthSharp';

const EditQuestion = (props, formRef) => {
    const { selectedData } = props;
    const { save } = props;
    const { t } = useTranslation();
    const [type, setType] = React.useState();
    const [answers, setAnswers] = React.useState([{ orderNumber: 1 }]);
    const [file, setFile] = React.useState();
    const [isMulti, setIsMulti] = React.useState(false);
    const [deleteIds, setDeleteIds] = React.useState([]);
    const { questionTypes } = props;
    const tmpType = questionTypes?.find((qt) => qt.id === type);

    const questionnaireFields = [
        {
            key: 'question',
            value: '',
            label: `${t('survey.questionnaire')}`,
            type: 'textArea',
            required: true,
            labelBold: true,
        },
        {
            key: 'description',
            value: '',
            label: `${t('survey.questionnaireDesc')}`,
            type: 'textArea',
            required: false,
            labelBold: true,
        },
        {
            key: 'image',
            label: '',
            value: '',
            type: 'fileUpload',
            required: false,
            fileName: '',
            multiple: false,
            isExtendedButton: true,
            isExtendedButtonText: (
                <>
                    <CollectionsIcon /> {t('survey.selectImage')}
                </>
            ),
            isExtendedButtonClass: 'btn btn-outline-warning mr-5',
            accept: 'image/*',
            clearButton: true,
            isClearButtonText: (
                <>
                    <CollectionsIcon style={{ opacity: 0, width: 0 }} /> {t('foodManagement.deletePhoto')}
                </>
            ),
            isClearButtonClass: 'btn btn-outline-danger m-btn m-btn--icon m-btn--icon-only m-btn--circle-30',
            onChange: (files) => {
                const [image] = !files ? [] : files;
                if (image) {
                    const reader = new FileReader();
                    reader.addEventListener(
                        'load',
                        () => {
                            setFile(reader.result);
                        },
                        false
                    );
                    reader.readAsDataURL(image);
                }
            },
        },
        {
            key: 'isRequired',
            value: true,
            label: `${t('survey.answerRequired')}`,
            type: 'checkbox',
            required: false,
            labelBold: true,
        },
        {
            key: 'type',
            value: null,
            label: `${t('survey.questionnaireType')}`,
            type: 'dropdown',
            required: true,
            labelBold: true,
            options: props?.questionTypes?.map((qt) => ({
                text: qt.name,
                value: qt.id,
            })),
            onChange: setType,
        },
    ];

    React.useEffect(() => {
        save({ answers: tmpType?.code === 'SELECT' ? [...answers] : [], image: file, isMultiAnswer: isMulti, delete_ids: deleteIds || [] });
    }, [isMulti, tmpType, answers, file]);

    return (
        <>
            <div className="custom-forms">
                <Forms key={selectedData ? 'q-edit-form' : props?.questionTypes?.length > 0 ? 'q-form-data' : 'no-data'} ref={formRef} fields={questionnaireFields} />
            </div>
            <br />
            <br />
            {tmpType?.code === 'SELECT' && (
                <div className="custom-container">
                    {answers
                        .sort((a, b) => {
                            if (a?.orderNumber < b?.orderNumber) {
                              return -1;
                            }
                            if (a?.orderNumber > b?.orderNumber) {
                              return 1;
                            }
                            return 0;
                        })
                        .map((a, i) => (
                        <div key={`a-${i}`} className="d-flex mb-1 space-x-4">
                            <div style={{ width: 100 }} className="d-flex justify-content-center">
                                {i !== 0 && (
                                    <Button
                                        style={{width: 30, height: 30}}
                                        variant="outline-secondary"
                                        type="button"
                                        size="sm"
                                        onClick={() => {
                                            const tmp = [...answers];
                                            
                                            const orderNumber = tmp[i - 1].orderNumber;
                                            tmp[i - 1].orderNumber = tmp[i].orderNumber;
                                            tmp[i].orderNumber = orderNumber;
                                            
                                            setAnswers(tmp);
                                        }}
                                    >
                                        <NorthSharpIcon className="text-black" fontSize="small" style={{position: 'relative', right: 7}}/>
                                    </Button>
                                )}
                                {i !== answers.length - 1 && (
                                    <Button
                                        style={{width: 30, height: 30}}
                                        variant="outline-secondary"
                                        type="button"
                                        size="sm"
                                        onClick={() => {
                                            const tmp = [...answers];
                                            const orderNumber = tmp[i + 1].orderNumber;
                                            tmp[i + 1].orderNumber = tmp[i].orderNumber;
                                            tmp[i].orderNumber = orderNumber;
                                            setAnswers(tmp);
                                        }}
                                    >
                                        <SouthSharpIcon className="text-black" fontSize="small" style={{position: 'relative', right: 7}}/>
                                    </Button>
                                )}
                            </div>
                            <input
                                className="form-control w-30"
                                placeholder="Асуумж"
                                value={a.answer || ''}
                                onChange={(e) => {
                                    const tmp = [...answers];
                                    tmp[i].answer = e.target.value;
                                    tmp[i].code = e.target.value;
                                    tmp[i].orderNumber = i + 1;
                                    setAnswers(tmp);
                                }}
                            />
                            <label className="btn btn-outline-warning btn-sm" style={{width: 30, height: 30, position: 'relative', top: 4}}>
                                <input
                                    className="form-control"
                                    style={{ display: 'none' }}
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => {
                                        const [answerFile] = e.target.files;
                                        if (answerFile) {
                                            const reader = new FileReader();
                                            reader.addEventListener(
                                                'load',
                                                () => {
                                                    const tmp = [...answers];
                                                    tmp[i].image = reader.result;
                                                    setAnswers(tmp);
                                                },
                                                false
                                            );
                                            reader.readAsDataURL(answerFile);
                                        } else {
                                            const tmp = [...answers];
                                            tmp[i].image = null;
                                            setAnswers(tmp);
                                        }
                                    }}
                                />
                                {a.image ? <img src={a.image} alt={a.answer} height={24} width={24} style={{position: 'relative', right: 8, bottom: 2}}/> : <CollectionsIcon style={{position: 'relative', right: 8, bottom: 2}}/>}
                            </label>
                            <Button
                                style={{width: 30, height: 30, position: 'relative', top: 4}}
                                type="button"
                                variant="danger"
                                size="sm"
                                onClick={() => {
                                    const tmp = [...answers];
                                    tmp.splice(i, 1);
                                    setAnswers(tmp);
                                }}
                                className=""
                            >
                                <i className="flaticon2-cross fs-08" style={{position: 'relative', right: 4}}/>
                            </Button>
                        </div>
                    ))}
                    <div style={{ paddingLeft: 130 }}>
                        <label className="my-3">
                            <input
                                type="checkbox"
                                checked={isMulti}
                                onChange={(e) => {
                                    setIsMulti(e.target.checked);
                                }}
                            />{' '}
                            Олон утга сонгоно
                        </label>
                        <div>
                            <Button
                                onClick={() => {
                                    setAnswers([...answers, { orderNumber: answers.length }]);
                                }}
                                variant="outline-alternate"
                                className="text-uppercase br-8 py-2 custom-blue-btn"
                            >
                                {t('common.add')}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default React.forwardRef(EditQuestion);
