import React, { useState, useEffect } from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import Instruction from "../../../modules/instruction";
import { useTranslation } from "react-i18next";
import DTable from "../../../modules/DataTable/DTable";
import { useSelector, useDispatch } from "react-redux";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import { schoolClassView } from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";

const ClassView = ({
    location: { state: {
        classId = null,
        path = ''
    } = {} } = {},
}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [ students, setStudents ] = useState([]);
    const [ classInfo, setClassInfo] = useState([]);
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    const handleClassBackClick = () => {
        history.push({
            pathname: path ? path : '/movement/class'
        });
    };

    useEffect(() => {
        const params = {
            school: schoolId,
            class: classId
        };
        dispatch(setLoading(true));
        fetchRequest(schoolClassView, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    setClassInfo(data.classInfo);
                    setStudents(data.students);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
            });
    }, []);

    const config = {
        showPagination: false,
        showFilter: true,
        showAllData: true,
        showLeftButton: true,
        leftButtonClassName: 'btn btn-primary',
        tableMarginLess: true,
        leftButtonText: t('movement.class.addStudent'),
        defaultSort: [
            {
                dataField: 'firstName',
                order: 'asc'
            },
        ],
    };

    const columns = [
        {
            dataField: "studentCode",
            text: t('student.code'),
            sort: true,
        },
        {
            dataField: "lastName",
            text: t('common.lastName'),
            sort: true,
        },
        {
            dataField: "firstName",
            text: t('common.firstName'),
            sort: true,
        },
        {
            dataField: "registrationNumber",
            text: t('common.registerNumber'),
            sort: true,
        },
        {
            dataField: "gender",
            text: t('common.gender'),
            sort: true,
            formatter: cell => {
                if(cell == 'F' || cell == 'f') {
                    return t('common.female')
                } else {
                    return t('common.male')
                }
            }
        },
        {
            dataField: "countryName",
            text: t('common.country'),
            sort: true,
        }
    ];

    const handleButtonClick = () => {
        history.push({
            pathname: '/movement/class/student/add',
            state: {
                classId: classId,
                path: history.location.pathname
            }
        })
    };

    return (
        <div className='row'>
            <div className='col-12 class-view-style'>
                <div className='card mb-4'>
                    <div className='card-body'>
                        <div className="col-12" align={"right"}>
                            <button
                                className='btn btn-link'
                                onClick={handleClassBackClick}
                            >
                                {t('common.backTo_list')}
                            </button>
                        </div>
                        <div className="col-12" align={"center"}>
                            {
                                classInfo
                                ?
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td className='text-right pr-3'>{t('school.title')}</td>
                                            <th>{classInfo?.schoolName}</th>
                                        </tr>
                                        <tr>
                                            <td className='text-right pr-3'>{t('movement.class.type')}</td>
                                            <th>{classInfo?.departmentName}</th>
                                        </tr>
                                        <tr>
                                            <td className='text-right pr-3'>{t('curriculum.title')}</td>
                                            <th>{classInfo?.academicProgramName}</th>
                                        </tr>
                                        <tr>
                                            <td className='text-right pr-3'>{t('curriculum.profession')}</td>
                                            <th>{classInfo?.programName}</th>
                                        </tr>
                                        <tr>
                                            <td className='text-right pr-3'>{t('movement.class.consultantTeacher')}</td>
                                            <th>{classInfo?.teacherCode + ' - ' + classInfo?.teacherName}</th>
                                        </tr>
                                        <tr>
                                            <td className='text-right pr-3'>{t('curriculum.type')}</td>
                                            <th>{classInfo?.typeName}</th>
                                        </tr>
                                        <tr>
                                            <td className='text-right pr-3'>{t('curriculum.approach')}</td>
                                            <th>{classInfo?.approachName}</th>
                                        </tr>
                                        <tr>
                                            <td className='text-right pr-3'>{t('school.season.title')}</td>
                                            <th>{classInfo?.seasonName}</th>
                                        </tr>
                                        </tbody>
                                    </table>
                                : null
                            }
                        </div>
                    </div>
                </div>
                <div className='card'>
                    <div className='card-body'>
                        <DTable
                            data={students}
                            columns={columns}
                            config={config}
                            showOrdering={true}
                            onLeftButtonClick={handleButtonClick}
                            selectMode={'radio'}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ClassView;