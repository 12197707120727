import { hexToRgb } from "@material-ui/core";

export const CandidateProcessDetail = ({
  data,
  timestampName = "sentDate",
  ownerName,
  textName = "toEmail",
  descriptionName,
}) => {
  return (
    <div
      className="mt15 column"
      style={{
        marginLeft: "30px",
        marginRight: "30px",
        paddingTop: "10px",
        paddingBottom: "10px",
        fontSize: "12px",
        borderRadius: "8px",
        alignItems: "center",
        justifyContent: "space-evenly",
        backgroundColor: hexToRgb("#D9D9D9"),
      }}
    >
      {data.map((item, i) => {
        return (
          <div
            key={i}
            style={{
              display: "flex",
              flex: "row",
              marginBottom: "5px",
            }}
          >
            <div
              style={{
                flex: 3,
                display: "flex",
                flexDirection: "row",
              }}
            >
              <p style={{ textAlign: "end", flex: 1.5, margin: "0px" }}>
                {item[timestampName]}
              </p>
              <p
                style={{
                  flex: 0.7,
                  paddingRight: "10px",
                  paddingLeft: "10px",
                  display: "flex",
                  justifyContent: "center",
                  margin: "0px",
                }}
              >
                {ownerName != null ? "" : item[ownerName]}
              </p>
              <p
                style={{
                  flex: 1,
                  textAlign: "start",
                  margin: "0px",
                }}
              >
                {item["isEmail"] != null
                  ? "И-мэйл"
                  : item["isSms"] != null
                  ? "SMS"
                  : item[textName]}
              </p>
            </div>

            <div
              style={{
                flex: 2,
                marginLeft: "15px",
              }}
            >
              <p style={{ textAlign: "start", flex: 2, margin: "0px" }}>
                {descriptionName === null
                  ? ""
                  : item[descriptionName] == null
                  ? ""
                  : item[descriptionName]}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};
