import React, { useState, useEffect } from 'react';
import { Card, Tabs, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import { seasonAdd, seasonEdit, seasonDelete } from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import DTable from "../../../modules/DataTable/DTable";
import SeasonAdd from "./SeasonAdd";
import TreeView from "../../../modules/TreeView";
import DeleteModal from "../../../modules/DeleteModal";

const Season = () => {
    const [ seasonTypes, setSeasonTypes ] = useState([]);
    const [ educationLevels, setEducationLevels ] = useState([]);
    const [ educationLevelId, setEducationLevelId ] = useState(null);
    const [ tabKey, setTabKey ] = useState('current');
    const [ seasons, setSeasons ] = useState([]);
    const [ parentSeasons, setParentSeasons ] = useState([]);
    const [ showModal, setShowModal ] = useState(false);
    const [ seasonId, setSeasonId ] = useState(null);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    useEffect(() => {
        const params = {
            school: schoolId,
            educationLevel: educationLevelId,
            key: tabKey == 'current' ? 1 : 0
        };
        dispatch(setLoading(true));
        fetchRequest(seasonAdd, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;

                    setSeasonTypes(data?.seasonTypes || []);

                    let eduLvls = [];

                    if (data?.educationLevels?.length) {
                        eduLvls = data.educationLevels.map(lvl => {
                            return {
                                key: lvl.id,
                                title: lvl.name
                            }
                        })
                    }

                    setEducationLevels(eduLvls);

                    setParentSeasons(data?.seasons ? formatSeasons(data.parentSeasons) : []);
                    setSeasons(data?.seasons ? data.seasons : []);

                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }, [ educationLevelId, tabKey ]);

    const handleAddClick = () => {
        setShowModal(true);
    };

    const onModalClose = () => {
        setSeasonId(null);
        setShowModal(false);
    };

    const config = {
        showPagination: false,
        showFilter: false,
        showAllData: true
    };

    const columns = [
        {
            dataField: "isCurrent",
            text: '',
            sort: false,
            headerStyle: () => ({
                width: 80,
            }),
            style: {textAlign: 'center'},
            formatter: (cell, row) => {
                if(row.isCurrent === true){
                    return (
                        <div><span><i className="icon-1_5x main-green fa fa-circle"/></span></div>
                    )
                } else {
                    return (
                        <div><span><i className="icon-1_5x text-dark-50 fa fa-circle"/></span></div>
                    )
                }
            }
        },
        {
            dataField: "code",
            text: t('common.code'),
            sort: true
        },
        {
            dataField: "name",
            text: t('common.name'),
            sort: true
        },
        {
            dataField: "startDate",
            text: t('common.startDate'),
            sort: true,
            style: {textAlign: 'right'}
        },
        {
            dataField: "endDate",
            text: t('common.endDate'),
            sort: true,
            style: {textAlign: 'right'}
        },
        {
            dataField: "parentSeason",
            text: t('school.season.parent'),
            sort: true
        },
        {
            dataField: "prevSeason",
            text: t('school.season.previousYear'),
            sort: true
        },
    ];

    const contextMenus = [
        {
            key: 'edit',
            title: t('common.edit'),
            icon: <i className='las la-edit dt-cm-item-icon'/>
        },
        {
            key: 'delete',
            title: t('common.delete'),
            icon: <i className='las la-trash-alt dt-cm-item-icon' />,
        },
    ];

    const formatSeasons = array => {
        return array.map(item => {
            return {
                ...item,
                startDate: item.startDate?.date ? item.startDate?.date.substring(0, 10) : '-',
                endDate: item.endDate?.date ? item.endDate?.date.substring(0, 10) : '-',
            }
        })
    };

    const handleContextMenuClick = (id, key) => {
        if (id && key) {
            setSeasonId(id);
            if (key === 'edit') {
                setShowModal(true);
            }
        }
    };

    const handleTabChange = key => {
        setTabKey(key);
    };

    const handleNodeClick = array => {
        if (array?.length) {
            const [ id ] = array;
            setEducationLevelId(id);
        }
    };

    const onSubmit = params => {
        const url = seasonId ? seasonEdit : seasonAdd;
        const bodyParams = {
            ...params,
            school: schoolId,
            educationLevel: educationLevelId,
        };

        if (seasonId) {
            bodyParams.season = seasonId;
        }

        dispatch(setLoading(true));
        fetchRequest(url, 'POST', bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res;

                    setSeasonTypes(data?.seasonTypes || []);

                    let eduLvls = [];

                    if (data?.educationLevels?.length) {
                        eduLvls = data.educationLevels.map(lvl => {
                            return {
                                key: lvl.id,
                                title: lvl.name
                            }
                        })
                    }

                    setEducationLevels(eduLvls);

                    setSeasons(data?.seasons ? data.seasons : []);
                    setParentSeasons(data?.parentSeasons);

                    message(data?.message || t('common.success'), true);

                    onModalClose();
                } else {
                    message(res?.data?.message || t('errorMessage.title'));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const onDelete = () => {
        dispatch(setLoading(true));
        const params =  {
            school: schoolId,
            educationLevel: educationLevelId,
            season: seasonId,
        };
        fetchRequest(seasonDelete, 'POST', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;

                    setSeasonTypes(data?.seasonTypes || []);

                    let eduLvls = [];

                    if (data?.educationLevels?.length) {
                        eduLvls = data.educationLevels.map(lvl => {
                            return {
                                key: lvl.id,
                                title: lvl.name
                            }
                        })
                    }

                    setEducationLevels(eduLvls);
                    setSeasons(data?.seasons ? data.seasons : []);
                    setParentSeasons(data?.parentSeasons);

                    message(data?.message || t('common.success'), true);
                    onModalClose();
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    return (
        <div>
            <div className='row'>
                <div className='col-3 pr-0'>
                    <Card>
                        <Card.Body>
                            <TreeView
                                treeData={educationLevels}
                                selectedNodes={[educationLevelId]}
                                onSelect={handleNodeClick}
                                defaultExpandAll
                            />
                        </Card.Body>
                    </Card>
                </div>
                <div className='col-9'>
                    <Card>
                        <Card.Header>
                            <Tabs
                                id={'msue-seasons'}
                                activeKey={tabKey}
                                className='msue-tab'
                                onSelect={handleTabChange}
                            >
                                <Tab eventKey={'current'} title={t('school.season.current')}/>
                                <Tab eventKey={'previous'} title={t('school.season.previous')}/>
                            </Tabs>
                        </Card.Header>
                        <Card.Body>
                            {
                                educationLevelId
                                ?
                                    <button
                                        className='btn btn-primary'
                                        onClick={handleAddClick}
                                    >
                                        {t('common.add')}
                                    </button>
                                : null
                            }
                            <DTable
                                columns={columns}
                                config={config}
                                data={seasons}
                                contextMenus={contextMenus}
                                onContextMenuItemClick={handleContextMenuClick}
                                selectMode={'radio'}
                            />
                            {
                                showModal && (
                                    <SeasonAdd
                                        onClose={onModalClose}
                                        onSubmit={onSubmit}
                                        seasons={parentSeasons}
                                        types={seasonTypes}
                                        educationLevel={educationLevelId}
                                        id={seasonId}
                                    />
                                )
                            }
                            {
                                !showModal && seasonId && (
                                    <DeleteModal
                                        onClose={onModalClose}
                                        title={t('school.season.title')}
                                        onDelete={onDelete}
                                    >
                                        {t('warning.delete_confirmation')}
                                        <br/>
                                        <br/>
                                        {t('warning.delete_confirmation_description')}
                                    </DeleteModal>
                                )
                            }
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default Season;