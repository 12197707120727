import React, {useRef, useEffect, useState} from 'react';
import { Button, Modal } from "react-bootstrap";
import Forms from "../../../modules/Form/Forms";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {bankInfoEdit} from "../../../../utils/fetchRequest/Urls";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import message from "../../../modules/message";
import RenderTableList from "../../../modules/RenderTableList";

const EditScholarship = ({
    bankId,
    onClose,
    onSubmit,
}) => {
    const { t } = useTranslation();
    const formRef = useRef();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [schools, setSchools] = useState([]);
    const [bankData, setBankData] = useState([]);
    const [stringArray, setStringArray] = useState([]);

    useEffect(() => {
        let params = {
            id: bankId
        };

        let stringArray = [];
        stringArray.push(
            {
                code: 'code',
                name: t('student.code'),
                value: 123
            },
            {
                code: 'lastName',
                name: t('student.lastName'),
                value: 123
            },
            {
                code: 'name',
                name: t('student.name'),
                value: 123
            },
            {
                code: 'enrollmentDate',
                name: t('student.enrollmentDate'),
                value: 123
            },
            {
                code: 'profession',
                name: t('curriculum.profession'),
                value: 123
            }
        );



        setStringArray(stringArray);

        // dispatch(setLoading(true));
        // fetchRequest(bankInfoEdit, 'GET', params)
        //     .then(res => {
        //         if (res.success) {
        //             const { data } = res;
        //             if(data.schools && data.schools.length > 0){
        //                 setSchools(data.schools.map(school => ({value: school.id, text: school.code + ' - ' + school.shortName})));
        //             }
        //             setBankData(data.bankData);
        //         } else {
        //             message(res?.data?.message || t('errorMessage.title'))
        //         }
        //         dispatch(setLoading(false));
        //     })
        //     .catch(() => {
        //         dispatch(setLoading(false));
        //         message(t('errorMessage.title'))
        //     })
    }, []);

    useEffect(() => {
        formRef?.current?.updateFields && formRef.current?.updateFields(fields);
    }, [schools, bankData]);

    const fields = [
        {
            label: t('finance.transactionDate'),
            value: bankData.schoolId || null,
            type: 'date',
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            options: schools,
            key: 'school',
            clearable: true
        },
        {
            label: t('common.type'),
            value: bankData.schoolId || null,
            type: 'dropdown',
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            options: schools,
            key: 'school',
            clearable: true
        },
        {
            label: t('common.amount'),
            value: bankData.merchantCode || '',
            type: 'text',
            inputWidth: 200,
            labelWidth: 300,
            required: true,
            key: 'merchantCode',
        },
    ];

    const onSaveClick = () => {
        const [ isValid, states, values ] = formRef.current.validate();
        if (isValid) {
            const params = {
                ...values,
            };
            onSubmit(params);
        }
    };

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('finance.governmentEdit').toUpperCase()}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="mb-10">
                    {
                        <RenderTableList
                            stringArray={stringArray}
                        />
                    }
                </div>
                <div className="mb-10">
                    <Forms
                        ref={formRef}
                        fields={fields}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className='btn btn-link bolder'>{t('common.back')}</button>
                <Button variant='success btn-shadow' onClick={onSaveClick}>{t('common.save')}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditScholarship;