import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import CategoryContainer from '../containers/Category';
import SurveyListContainer from '../containers/Survey';
import { useTranslation } from 'react-i18next';
import useLocalStorage from '../hooks/useLocalStorage';
import '../styles/app.css';

export const OfficerSurveyPage = () => {
    const { t } = useTranslation();
    
    const [category, setCategory] = useLocalStorage('category', '');
    return (
        <div>
            <Row>
                <Col md={4}>
                    <CategoryContainer setCategory={setCategory} />
                </Col>
                <Col md={8}>
                    <SurveyListContainer category={category} />
                </Col>
            </Row>
        </div>
    );
};
