import React from 'react'
import {Card} from 'react-bootstrap'
export const ComingSoon = () => {
    return (
        <div className='sm-container'>
            <Card>
                <Card.Body>
                    <p>Тун удахгүй ...</p>
                </Card.Body>
            </Card>
            {/*<Row>*/}
            {/*    <Col>*/}
            {/*        <BranchSchoolCal/>*/}
            {/*    </Col>*/}
            {/*    <Col>*/}
            {/*        <MainCalendar/>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
        </div>
    )
};
