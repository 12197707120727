import React, {useEffect, useState} from 'react'
import {Card, Button} from 'react-bootstrap'
import DTable from '../../../modules/DataTable/DTable'
import Instruction from "../../../modules/instruction";
import {useTranslation} from "react-i18next";
import {setLoading} from "../../../../redux/action";
import {fetchRequest} from "../../../../utils/fetchRequest";
import {requestIndex, requestUpdate} from "../../../../utils/fetchRequest/Urls";
import message from "../../../modules/message";
import {useDispatch, useSelector} from "react-redux";
import {UpdateModal} from "./updateModal";

export const OfficerRequest = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);
    const [requests, setRequests] = useState([]);
    const [selectedRequest, setSelectedRequest] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [viewModal, setViewModal] = useState(false);

    useEffect(() => {
        let params = {
            school: schoolId,
            page: 1,
            pageSize: 10,
        };

        init(params);
    }, []);

    const init = (params) => {
        dispatch(setLoading(true));
        fetchRequest(requestIndex, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                    let requestObj = data?.requests || [];
                    let requestStatusObj = data?.requestStatuses || [];
                    let filteredRequestStatuses = [];
                    if(requestObj && requestObj.length > 0){
                        for(let i = 0; i < requestObj.length; i++){
                            if(requestObj[i].createdDate && requestObj[i].createdDate.date){
                                requestObj[i].createdDate = requestObj[i].createdDate.date.substring(0, 19)
                            }
                            if(requestObj[i].content && requestObj[i].content.length > 50){
                                requestObj[i].subContent = requestObj[i].content.substring(0, 50) + ' ...';
                            }
                        }
                    }
                    setRequests(requestObj);

                    if(requestStatusObj && requestStatusObj.length > 0){
                        for(let i = 0; i < requestStatusObj.length; i++){
                            if(requestStatusObj[i].isComplete){
                                filteredRequestStatuses.push({
                                    value: requestStatusObj[i].id,
                                    text: requestStatusObj[i].name
                                })
                            }
                        }
                    }
                    setStatuses(filteredRequestStatuses);
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            });
    };

    const config = {
        showPagination: false,
        showFilter: false,
    };

    const columns = [
        {
            dataField: 'statusName',
            text: t('common.status'),
            sort: true,
        },
        {
            dataField: 'typeName',
            text: t('common.type'),
            sort: true,
        },
        {
            dataField: 'subContent',
            text: t('common.cause'),
            sort: true,
        },
        {
            dataField: 'createdDate',
            text: t('common.date'),
            sort: true,
        },
        {
            dataField: 'sent',
            text: '',
            headerStyle: () => {
                return {
                    width: 80
                }
            },
            formatter(cell, row, index) {
                return (
                    row.isComplete === true
                    ?
                        <div className='request-icons-style'>
                        </div>
                    :
                        <div className='request-icons-style'>
                            <Button onClick={() => {viewClick(cell, row, index)}} className="btn-primary btn-shadow m-btn m-btn--icon m-btn--icon-only m-btn--circle-30 mr-2">
                                <i className='far fa-eye'/>
                            </Button>
                        </div>
                )
            },
        },
    ];

    const viewClick = (cell, row, index) => {
        setSelectedRequest(row);
        setViewModal(true)
    };

    const onModalClose = () =>{
        setViewModal(false)
    };

    const onSubmit = params => {
        const bodyParams = {
            school: schoolId,
            ...params,
        };

        dispatch(setLoading(true));
        fetchRequest(requestUpdate, 'POST', bodyParams)
            .then(res => {
                dispatch(setLoading(false));
                if (res.success) {
                    onModalClose();
                    message(res?.data?.message || t('common.success'), true);

                    let params = {
                        school: schoolId,
                        page: 1,
                        pageSize: 10,
                    };

                    init(params);
                } else {
                    message(res?.data?.message || t('errorMessage.title'));
                }
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'));
            })
    };

    return (
        <Card>
            <Card.Body>
                <div className='ds-flex justify-content-between'>
                    <div/>
                    <Instruction/>
                </div>
                <DTable
                    data={requests}
                    config={config}
                    columns={columns}
                    selectMode={'radio'}
                />
                {
                    viewModal &&
                    <UpdateModal
                        request={selectedRequest}
                        statuses={statuses}
                        close={onModalClose}
                        onSubmit={onSubmit}
                    />
                }
            </Card.Body>
        </Card>
    )
};
