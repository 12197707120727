import React from 'react';
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const UndoModal = ({
    onClose = () => {},
    onDelete = () => {},
}) => {
    const { t } = useTranslation();

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('movement.undo')}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {t('movement.undoCheck')}
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className='btn btn-link bolder'>{t('common.cancel')}</button>
                <Button variant='success btn-shadow' onClick={onDelete}>{t('action.undo')}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default UndoModal;