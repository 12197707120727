import React, {useRef, useEffect, useState} from 'react';
import { Button, Modal, Card } from "react-bootstrap";
import Forms from "../../../modules/Form/Forms";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {getPrograms, invoiceCreate, movementCauseEdit} from "../../../../utils/fetchRequest/Urls";
import { setLoading } from "../../../../redux/action";
import { fetchRequest } from "../../../../utils/fetchRequest";
import message from "../../../modules/message";
import DTable from "../../../modules/DataTable/DTable";
import Checkbox from "../../../modules/Form/Checkbox";
import RenderTableList from "../../../modules/RenderTableList";

const ViewPayment = ({
    onClose,
    onOpen,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    const [subjectInfoArray, setSubjectInfoArray] = useState([]);
    const [students, setStudents] = useState([]);

    const parentSeason = useSelector(state => {
            let schoolList = state?.school?.schoolList;
            if(schoolList && schoolList.length > 0){
                for (let i = 0; i < schoolList.length; i++){
                    if(schoolId == schoolList[i]['id']){
                        return schoolList[i]['parentSeason'];
                    }
                }
            }
        }
    );

    useEffect(() => {
        const params = {
            school: schoolId,
            season: parentSeason,
        };
        // init(params);

        let subjectArray = [];

        subjectArray.push(
            {
                code: 'code',
                name: t('school.season.title'),
                value: 123
            },
            {
                code: 'lastName',
                name: t('subject.season'),
                value: 123
            },
            {
                code: 'name',
                name: t('total.credit'),
                value: 123
            },
            {
                code: 'enrollmentDate',
                name: t('total.pay'),
                value: 123
            }
        );

        setSubjectInfoArray(subjectArray);
    }, []);

    const init = (params) => {
        dispatch(setLoading(true));
        fetchRequest(invoiceCreate, 'GET', params)
            .then(res => {
                if (res.success) {
                    const { data } = res;
                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    };

    const config = {
        showPagination: false,
        showFilter: false,
        footer: true,
        footerStyle: {backgroundColor: '#ebecf5'}
    };

    const columns = [
        {
            dataField: "index",
            text: t('finance.index'),
            sort: false,
            footer: t('total.title')
        },
        {
            dataField: "name",
            text: t('subject.name'),
            sort: false,
            footer: '',
        },
        {
            dataField: "credit",
            text: t('subject.credit'),
            sort: false,
            footer: '',
            footerAlign: 'right',
            footerType: 'sum',
        },
        {
            dataField: "eachCredit",
            text: t('assessment.eachCredit'),
            sort: false,
            footer: '',
        },
        {
            dataField: "total",
            text: t('total.title'),
            sort: false,
            footer: '',
            footerAlign: 'right',
            footerType: 'sum',
        },
    ];

    return (
        <Modal
            show={onOpen}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div style={{ color: '#4a70ae', fontSize: '1.1rem' }}>
                        {t('finance.details').toUpperCase()}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='row'>
                    <div className='col-12'>
                        <Card>
                            <Card.Body>
                                <RenderTableList
                                    stringArray={subjectInfoArray}
                                />
                            </Card.Body>
                        </Card>
                    </div>
                    <div className='col-12 mt-4'>
                        <Card>
                            <Card.Body>
                                <p className='french-blue fs-11'><b>{t('subject.selected')}</b></p>
                                <DTable
                                    columns={columns}
                                    config={config}
                                    data={students}
                                    selectMode={'radio'}
                                />
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className='btn btn-link bolder'>{t('common.close')}</button>
            </Modal.Footer>
        </Modal>
    );
};

export default ViewPayment;