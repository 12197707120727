import { useTranslation } from "react-i18next";
import { Button, Modal } from "react-bootstrap";
import Forms from "../../../../../modules/Form/Forms";
import React, { useRef, useEffect, useState } from "react";

const AddBodyModal = ({ enrollId, onClose, onSubmit }) => {
  const form = useRef();
  const { t } = useTranslation();

  useEffect(() => {
    // getSchool();
  }, []);

  const forms = [
    {
      value: "",
      label: "Нэр",
      key: "name",
      type: "text",
      inputWidth: 300,
      labelWidth: 230,
      required: true,
      clearable: true,
    },

    {
      value: "",
      label: "Доод утга-эрэгтэй*",
      type: "number",
      inputWidth: 300,
      labelWidth: 230,
      required: true,
      multiple: true,
      searchable: true,
      key: "mMinScore",
    },
    {
      value: "",
      label: "Доод утга-эмэгтэй*",
      type: "number",
      inputWidth: 300,
      labelWidth: 230,
      required: true,
      multiple: true,
      searchable: true,
      key: "fMinScore",
    },
  ];

  const onSaveClick = () => {
    const [formValid, formValue] = form.current.validate();
    if (formValid) {
      const params = {
        enroll: enrollId,
        name: formValue?.[0].value,
        mMinScore: formValue?.[1].value,
        fMinScore: formValue?.[2].value,
      };

      onSubmit(params);
    }
  };
  return (
    <Modal
      show={true}
      onHide={onClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div style={{ color: "#4a70ae", fontSize: "1.1rem" }}>
            Бие бялдарын үзүүлэлт
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Forms ref={form} fields={forms} />
      </Modal.Body>
      <Modal.Footer>
        <button onClick={onClose} className="btn btn-link bolder">
          {t("common.back")}
        </button>
        <Button variant="success btn-shadow" onClick={onSaveClick}>
          {t("common.save")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default AddBodyModal;
