import React,{useState,useEffect} from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { setLoading } from '../../../../redux/action';
import { fetchRequest } from '../../../../utils/fetchRequest';
import { useDispatch,useSelector } from 'react-redux';
import Instruction from '../../../modules/instruction';
import { Link,useHistory } from "react-router-dom";
import Select from "../../../modules/Form/Select";
import { TextareaAutosize } from "@material-ui/core";
import { getStudentInfo,movementOutAdd } from '../../../../utils/fetchRequest/Urls';
import message from '../../../modules/message';
import StudentInfoTable from '../StudentInfoTable';

const AddOutStudent = ({
    location: { state: {
        movement = null,
        studentCode = null,
        level="",
        nodeId=null,
    } = {} } = {}, }) => {

    const { t } = useTranslation();
    const dispatch = useDispatch()
    const history = useHistory()
    const schoolId = useSelector(state => state?.selectedSchool?.id || null);

    const [ code, setCode ] = useState('');
    const [ student, setStudent ] = useState({});
    const [ causes, setCauses ] = useState([]);
    const [ cause, setCause ] = useState(null);
    const [ description, setDescription ] = useState('');

    const fetchData = params =>{
        const bodyParams={
            ...params,
            nodeId
        }
        dispatch(setLoading(true));
        fetchRequest(getStudentInfo, 'GET', bodyParams)
            .then(res => {
                if (res.success) {
                    const { data } = res;

                    if (data?.student) {
                        setStudent(data.student);

                        fetchRequest(movementOutAdd, 'GET', {
                            school: schoolId,
                            student: data.student?.id,
                            movement,
                        })
                            .then(res => {
                                if (res.success) {
                                    const { data } = res;
                                    const tempData = []
                                    if(data?.movementCauses?.length){
                                        data?.movementCauses.map(mc=>{
                                            tempData.push({
                                                value: mc.id,
                                                text: mc.name
                                            })
                                        })
                                    }
                                    setCauses(tempData);
                                    if(data?.movement){
                                        setCause(data?.movement.movementCauseId || null)
                                        setDescription(data?.movement.description || '')
                                    }
                                    dispatch(setLoading(false));
                                } else {
                                    message(res?.data?.message || t('errorMessage.title'))
                                }
                            })
                            .catch(err => {
                                dispatch(setLoading(false));
                                message(t('errorMessage.title'))
                            })
                    }

                } else {
                    message(res?.data?.message || t('errorMessage.title'))
                    dispatch(setLoading(false));
                }
                
            })
            .catch(err => {
                dispatch(setLoading(false));
                message(t('errorMessage.title'))
            })
    }
    
    const handleBlur = () => {
        fetchData({
            school: schoolId,
            studentCode: code,
        })
    }

    const onSave = () =>{
        let error = false;
        if(!description){
            error = true;
            message('Тайлбар оруулна уу', false);
        }
        if(!cause){
            error = true;
            message('Гарч байгаа шалтгаан оруулна уу', false);
        }
        if(!error){
            const params = {
                school: schoolId,
                description,
                movementCause: cause,
                student: student?.id,
                movement,
            }
            dispatch(setLoading(true));
            fetchRequest(movementOutAdd, 'POST', params)
                .then(res => {
                    if (res.success) {
                        history.push({
                            pathname: '/movement/out'
                        })
                    } else {
                        message(res?.data?.message || t('errorMessage.title'))
                    }
                    dispatch(setLoading(false));
                })
                .catch(() => {
                    dispatch(setLoading(false));
                    message(t('errorMessage.title'))
                })
        }
    }
    useEffect(()=>{
        if(!movement && typeof level === 'string'){
            if(!level || level.substring(0,6) == 'season'){
                history.push({
                    pathname: '/movement/out'
                });
                message(t('errorMessage.selectEducationLevel'))
            }
        }
    },[level])

    useEffect(()=>{
        if(studentCode && movement){
            fetchData({
                school: schoolId,
                studentCode,
                movement
            })
        }
    },[studentCode])
    
    useEffect(() => {
        if(nodeId){
            fetchData(null)
        }
    }, [ nodeId ])
    return (
        <Card>
            <Card.Body>
                <div className='text-right'>
                    <Instruction
                    
                    />
                </div>
                {
                    studentCode
                    ? null
                    :
                        <Row>
                            <div className='col-3' style={{ display: 'flex', alignSelf: 'center', justifyContent: 'flex-end' }}>
                                {t('student.code') + '*'}
                            </div>
                            <div className='col-3'>
                                <input
                                    className='form-control'
                                    onChange={e=>{
                                        setCode(e.target.value)
                                        setStudent({});
                                        setCause(null);
                                    }}
                                    value={code}
                                    onBlur={handleBlur}
                                />
                            </div>
                        </Row>
                }
                <Row className='mt-3'>
                    <Col md={2}>

                    </Col>
                    <Col>
                        <StudentInfoTable student={student}/>
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col md={3} style={{ display: 'flex', alignSelf: 'center', justifyContent: 'flex-end' }}>
                        {t('movement.out.cause') + '*'}
                    </Col>
                    <Col md={3}>
                        <Select
                            searchable
                            value={cause}
                            onChange={value=>setCause(value)}
                            options={causes}
                        />
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col md={3} style={{ display: 'flex', alignSelf: 'center', justifyContent: 'flex-end' }}>
                        {t('common.description') + '*'}
                    </Col>
                    <Col md={3}>
                        <TextareaAutosize
                            className='msue-textarea-description'
                            value={description}
                            onChange={e => {
                                setDescription(e.target.value);
                            }}
                            rows={5}
                        />

                    </Col>
                </Row>
            </Card.Body>
            <Card.Footer>
                <div className='text-center'>
                    <Link
                        to={'/movement/out'}
                        className='btn btn-link bolder'
                    >
                        {t('common.back')}
                    </Link>
                    <button
                        className='btn btn-success'
                        onClick={onSave}
                    >
                        {t('common.save').toLocaleUpperCase()}
                    </button>
                </div>
            </Card.Footer>
        </Card>
    )
}

export default AddOutStudent